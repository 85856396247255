import React, {Component} from 'react'
import Loader from "../../component/common/loader/loader";
import {getIvrList, getIvrDetail} from "../../dataParser/getIvrData"
import Frag from "../../utils/Frag/Frag";
import ListingView, {OneFourthField} from '../common/listing/ListingView'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFilter } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import { cleanStorage } from '../../helper';
import { digitalGroup } from '../../config/constant/crmRoleFunctionPermission';
import MainHeader from '../common/MainHeader';

import FilterDrawer from "@material-ui/core/Drawer";
import IvrFilter from './IvrFilter';
import DeleteAction from '../common/action/DeleteAction';

const ivrUpdateAccess = ["admin", "operationsmanager", "operationshead", ...digitalGroup]

class IvrManagement extends Component{
	constructor(props){
		super(props)
		this.state = {
			showLoader: false,
			data: [],
            activePage: 1,
			drawerOpen: false,
			filters: {}
		}
	}

	componentDidMount() {
		this.getUserDetail()
        let data = {};
        data.size = 24;
        data.pageId = 1;
		this.getListData(data)
	}

    handleChangePage = async (pageNumber) => {
        this.setState({
            showLoader: true,
            activePage: pageNumber
        })
        var listData = {};
        listData.size = 24;
        listData.pageId = pageNumber;
        Object.assign(listData, this.state.filters)
		this.getListData(listData)
    }

    getUserDetail = () => {
		var homesfy_lg = localStorage.getItem("homesfy_lg")
		if (homesfy_lg && homesfy_lg !== "") {
		  let user = JSON.parse(window.atob(homesfy_lg))
			this.setState({
				loggedInUser: user.id,
				role: user.role,
			});
		}
    }
    getListData = async (params) => {
        this.setState({ showLoader: true, });
        (async () => {
            try {
                let resData = await getIvrList(params);
                if (resData.meta.status === 200) {

                    await this.setState({
                        showLoader: false,
                        meta: resData.meta,
                        data: resData.data,
                    });
                    window.scrollTo(0, 0);

                }
                else if (resData.meta.status == 401) {
                    cleanStorage();
                    this.props.history.push("/login");
                }
                else {
                    this.setState({
                        showLoader: false,
                        meta: resData.meta,
                        data: resData.data,
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();

    }
	handleUpdate = async (id) => {
		this.setState({ showLoader: true });

		var resData = {};
		resData = await getIvrDetail(id);

		if (resData.meta.status === 200) {

			await this.setState({
				fullDetail: resData,
				showLoader: false
			})
			window.scrollTo(0, 0);
		} else if (resData.meta.status === 401) {
			cleanStorage();
			this.props.history.push("/login");
		} else {
			this.setState({
				fullDetail: resData,
				showLoader: false
			})
		}

		this.props.history.push(
            {
                pathname: '/ivr-management/add-update',
                state: { id:id, fullDetail: resData, }
            }
		// this.setState({ modalShow: !this.state.modalShow });
        )
	}
	handleDrawer = () => {
		this.setState({
			drawerOpen: !this.state.drawerOpen
		});
	  };
	  
	handleFilter = async (filterData, selectedVal) => {
        if(filterData !== undefined){
            await this.setState({ filters: {...filterData, selectedVal}});
        }
        else{
            filterData = {}
        }
        this.setState({ activePage: 1 });
        let data = {};
        
        data.size = 24;
        data.pageId = 1;
        Object.assign(data, {...this.state.filters}, filterData);
		this.getListData(data)
    };
	render(){
		const {showLoader, data, drawerOpen} = this.state
		const mainData = data.auto_assign_list

	    return (
	        <Frag>
        		<Loader show={showLoader} />
				<MainHeader list="IVR" count={this.state.meta?.count} 
				child={
					<>
					  <FontAwesomeIcon
						icon={faFilter}
						className="nav-icon"
						style={{ fontSize: "25px", cursor: "pointer" }}
						onClick={this.handleDrawer}
					  />
					  <FilterDrawer
						anchor="right"
						open={drawerOpen}
						onClose={() => {
						  this.setState({drawerOpen: false});
						}}
					  >
						<IvrFilter 
							show={drawerOpen} 
							filterData={this.handleFilter}
							changeFilter={this.handleDrawer}
							selectedFilters={this.state.filters}
						/>
					  </FilterDrawer>
					</>
				  }
				/>
				<div className="listContainer">
		            {mainData?.map((item, i)=>(
		            <ListingView key={i}>
		            	<OneFourthField title="ID" value={item?.id || '-'}/>
		            	<OneFourthField title="Project Name" value={item?.Project?.name || '-'}/>
		            	<OneFourthField title="Nationality" value={item?.nationality == 2 ? 'NRI' : 'Indian'}/>
		            	<OneFourthField title="Number" value={item?.number || '-'}/>
		            	<OneFourthField title="Status" value={item?.status ? 'Active' : 'In-Active'}/>
		            	<OneFourthField title="Is Magnet" value={item?.is_magnet ? 'Yes' : 'No'}/>
		            	<OneFourthField title="Region" value={item?.Project?.Region?.name || '-'}/>
		            	<OneFourthField title="Source" value={item?.Source?.name || '-'}/>
		            	<OneFourthField title="WhatsApp User" value={item?.whatsupUser?.name || '-'}/>
						<div className = "action_box" style={{width:'70px'}}>
							{ ivrUpdateAccess.includes(window.$user.role) &&
								<a className="ProjectUpdate Action"
									data-toggle="tooltip" data-placement="bottom" title="Update IVR"
									onClick={() => { this.handleUpdate(item?.id)}}
									data={item.id}
								>
									<FontAwesomeIcon icon={faEdit} className="text-white" />
								</a>
							}
							<DeleteAction listData={item} getAllList={this.handleFilter}/>
						</div>
		            </ListingView>
		            ))}
		            <div className="container-fluid">
	                    <div className="row justify-content-end">
	                        {this.state.meta?.count > 24 ? (
	                            <div className="paginationBlock">
	                                <Pagination
	                                    hideDisabled
	                                    activePage={this.state.activePage}
	                                    itemsCountPerPage={this.state.meta?.size}
	                                    totalItemsCount={this.state.meta?.count}
	                                    pageRangeDisplayed={5}
	                                    onChange={this.handleChangePage}
	                                />
	                            </div>
	                        ) : (
	                                ""
	                            )}
	                    </div>
                    </div>
	            </div>
	        </Frag>
	    )
	}
}

export default IvrManagement
