import React, { Component } from "react";
import errorImg from "../assets/image/404.gif"


class NotFoundComponent extends Component {
  render() {
    return (
      <div className="container-fluid text-center bg-white" style={{height: "100vh"}}>
        <div className="row">
          <div className="col-md-12 mt-5">
            <img src={errorImg} alt="Error Page"/>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundComponent;
