import { param } from "jquery";
import { get, post, Delete, patch, put } from "../utils/methods.js";

export const getUserListData = async value => {
  let getUserList = await get("/users/rmautocomplete?q=" + value);
  return getUserList;
};

export const getUsersDataList = async value => {
  let hashUrl = window.location.hash.replace("#", "").split("?")[0];
  if(hashUrl === "/magnet/magnet-new-users") value.is_new = 1;
  if(hashUrl === "/magnet/magnet-inactive-users") value.in_active = 1;
  let getUserDataList = await get("/users", value);
  return getUserDataList;
};

export const getTempMagentUsersList = async value => {
  let getTempMagentData = await get("/interested_magnets", value);
  return getTempMagentData;
};

export const getMagnetTempUserList = async id => {
  let getData = await get("/interested_magnets/"+id);
  return getData;
};
export const getApproveMagnetUser = async value => {
  let getApproveMagnetUserData = await put(
    "/interested_magnets/" + value.id,
    value.data
  );
  return getApproveMagnetUserData;
};

export const getDeleteUser = async value => {
  let getDeleteUser = await Delete("/users/" + value);
  return getDeleteUser;
};

export const getUpdateUser = async value => {
  let getUpdateUserData = await put("/users/" + value.id, value.data);
  return getUpdateUserData;
};

export const getSingleUser = async value => {
  let getSingleUserData = await get("/users/" + value);
  return getSingleUserData;
};

export const getAddUser = async value => {
  let getAddUser = await post("/users", value);
  return getAddUser;
};

export const getMagnetList = async value => {
  let getMagnetList = await get("/users/auto_complete/", value);
  return getMagnetList;
};

export const getMagnetMoneyExchange = async value => {
  let getMagnetMoneyExchange = await post(
    "/campaignMoneyExchange/getAllByMagentId",
    value
  );
  return getMagnetMoneyExchange;
};

export const saveMagnetMoneyExchange = async value => {
  let saveMagnetMoneyExchange = await post(
    "/campaignMoneyExchange/create",
    value
  );
  return saveMagnetMoneyExchange;
};

export const getFacebookAds = async value => {
  let getMagnetList = await get("/facebook/ads", value);
  return getMagnetList;
};

export const getCampaignSplits = async (query) => {
  let campaignSplits = await get(
    "/campaignMagnetsPercentages/",query
  );
  return campaignSplits;
};
export const getCampaignSplitsByAdId = async (value, query) => {
  let campaignSplitsByAdId = await get(
    `/campaignMagnetsPercentages/getByAdId/${value}`, query
  );
  return campaignSplitsByAdId;
};

export const saveCampaignSplits = async value => {
  let saveMagnetMoneyExchange = await post(
    "/campaignMagnetsPercentages/create",
    value
  );
  return saveMagnetMoneyExchange;
};

export const updateCampaignSplits = async value => {
  let saveMagnetMoneyExchange = await put(
    "/campaignMagnetsPercentages/update",
    value
  );
  return saveMagnetMoneyExchange;
};

export const deleteCampaignSplit = async id => {
  let deleteCampaignSplit = await Delete(
    `/campaignMagnetsPercentages/deleteCampaignSplits/${id}`
  );
  return deleteCampaignSplit;
};

export const sendFbAdLead = async value => {
  let getData = await post("/fb_ad_lead", value);
  return getData;
};

export const getBookingLeads = async (page = 1, size = 10, isInvoice = "") => {
  let bookingLeads = await get(
    `/booking?page=${page}&size=${size}${isInvoice}`
  );
  return bookingLeads.data;
};
export const getBookingLeadsData = async (data) => {
  let bookingLeads = await get(
    "/booking",data
  );
  return bookingLeads.data;
};
export const getBookingDocuments = async bookingId => {
  let bookingDocuments = await get(`/bookingDocument/${bookingId}`);
  return bookingDocuments.data;
};

export const saveBookingDocuments = async (value, bookingId) => {
  let saveMagnetMoneyExchange = await post(
    `/bookingDocument/uploadDocuments/${bookingId}`,
    value
  );
  return saveMagnetMoneyExchange;
};

export const saveDocumentVerification = async (value, bookingId) => {
  let saveMagnetMoneyExchange = await post(
    `/bookingDocument/verify/${bookingId}`,
    value
  );
  return saveMagnetMoneyExchange;
};

export const deleteBookingDocument = async (value, bookingId) => {
  let saveMagnetMoneyExchange = await post(
    `/bookingDocument/deleteDocument/${bookingId}`,
    value
  );
  return saveMagnetMoneyExchange;
}

export const getBookingIncentives = async (bookingId,query={}) => {
  let bookingIncentives = await get(`/bookingIncentive/${bookingId}`,query);
  return bookingIncentives.data;
};

export const saveBookingIncentives = async (param, bookingId) => {
  let saveMagnetMoneyExchange = await post(
    `/bookingIncentive/${bookingId}`,
    param
  );
  return saveMagnetMoneyExchange.data;
};

export const getIncentiveReport = async (id, fromDate, toDate, parent = 0,preSale = 0,role_id,year,month) => {
  let query = [`parent=${parent}`];

  if (fromDate) {
    query.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    query.push(`toDate=${toDate}`);
  }
  if (preSale) {
    query.push(`isPresale=${preSale}`);
  }
  if(role_id){
    query.push(`role_id=${role_id}`);
  }
  if(year){
    query.push(`year=${year}`);
  }
  if(month){
    query.push(`month=${month}`);
  }
  let bookingIncentives = await get(
    `/bookingIncentive/report/${id}${
      query.length > 0 ? `?${query.join("&")}` : ``
    }`
  );
  return bookingIncentives.data;
};

export const getUsers = async (searchString, roles) => {
  let userList = await get(`/users/auto_complete?q=${searchString}&roles=${roles}`);
  return userList;
};

export const getUserIncentiveReport = async (id, month, year) => {
  let userIncentives = await get(
    `/bookingIncentive/user/report?user_id=${id}${month && `&month=${month}`}${
      year && `&year=${year}`
    }`
  );

  return userIncentives;
};

export const getCompleteIncentiveReport = async (fromDate, toDate, type,year,month) => {
  let query = [];

  if (fromDate) {
    query.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    query.push(`toDate=${toDate}`);
  }
  if(year){
    query.push(`year=${year}`);
  }
  if(month){
    query.push(`month=${month}`);
  }
  let promises = [
    get(
      `/bookingIncentive/completeReport/sales${
      query.length > 0 ? `?${query.join("&")}` : ``
    }`),
    get(
      `/bookingIncentive/completeReport/presales${
      query.length > 0 ? `?${query.join("&")}` : ``
    }`)
  ];
   
  let bookingIncentives = await Promise.all(promises);

  return bookingIncentives;
};

export const getSalesTeamMembers = async (q, parent = 0,booking_tab =0 ) => {
  let foundSalesRm = await get(
    `/booking/salesteam?page=1&size=10&q=${q}&parent=${parent}&booking_tab=${booking_tab}`
  );
  return foundSalesRm.data;
};

export const getDownloadLink = async id => {
  let downloadLink = await get(`/bookingDocument/download/${id}`);
  return downloadLink.data;
};

export const getAllDocDownloadLink = async id =>{
  let downloadLink = await get(`/bookingDocument/downloadAllDoc/${id}`);
  return downloadLink.data;
}
export const filterBooking = async params => {
  let q = "";
  Object.keys(params).forEach(o => (q += `${o}=${params[o]}&`));
  q = q.substr(0, q.length - 1);

  let bookingLeads = await get(`/booking?${q}`);
  return bookingLeads.data;
};


export const saveGoogleSplits = async value => {
  let saveData = await post(
    "/campaignMagnetsPercentages/googlesplitcreate",
    value
  );
  return saveData;
};
export const getGoogleSplitsByProjectId = async (value, query) => {
  let getData = await get(
    `/campaignMagnetsPercentages/getByProject/${value}`, query
  );
  return getData;
};
export const updateGoogleSplits = async value => {
  let updateData = await put(
    "/campaignMagnetsPercentages/googlesplitupdate",
    value
  );
  return updateData;
};
export const deleteGoogleSplit = async id => {
  let deleteData = await Delete(
    `/campaignMagnetsPercentages/deleteGoogleSplits/${id}`
  );
  return deleteData;
};
export const checkDublicateFbAdLead = async value => {
  let getData = await get("/fb_ad_lead/dublicate/lead", value);
  return getData;
};

export const updateIncentives = async (bookingId, params) => {
  let getData = await post(
    `/bookingIncentive/${bookingId}`,
    params
  );
  return getData;
};

export const getSalesTeamUsers = async (q, parent = 0,booking_tab =0 ) => {
  let getRes = await get(
    `/booking/salesteam?page=1&size=10&q=${q}&parent=${parent}&booking_tab=${booking_tab}`
  );
  return getRes;
};

export const getMagnetBrokerage = async (id,query) => {
  let getRes = await get("/magnetBrokerage/"+id, query);
  return getRes
};

export const updateMagnetBrokerage = async value => {
  let getRes = await post("/magnetBrokerage/" + value.id, value);
  return getRes;
};

export const getEligibilityPercentagesData = async (query) => {
  let getRes = await get(
    `/eligibilityPercentage`,query
  );
  return getRes;
};

export const postEligibilityPercentages = async (body) => {
  let postRes = await post(
    `/eligibilityPercentage`,body
  );
  return postRes;
};

export const getFinanceCalendarData = async (query) => {
  let getRes = await get(
    `/financeCalender`,query
  );
  return getRes;
};

export const getFinanceCalendarRecordData = async (id) => {
  let getRes = await get(
    `/financeCalender/${id}`
  );
  return getRes;
};

export const updateFinanceCalendar = async (id, body) => {
  let saveFinanceCalendar = await put(
    `/financeCalender/${id}`, body
  );
  return saveFinanceCalendar;
};

export const deleteFinanceCalendar = async id => {
  let deleteFinanceCalendar = await put(
    `/financeCalender/${id}/delete`
  );
  return deleteFinanceCalendar;
};
export const postFinanceCalendar = async (body) => {
  let postRes = await post(
    `/financeCalender`,body
  );
  return postRes;
};

export const viewAllUsers = async (id, query) => {
  let getRes = await get(
    `/users/admin-login-as-user/${id}`,query
  );
  return getRes;
};
export const revertCancallation = async (lead_id,stage) => {
  let postRes = await put(
  `leads/${lead_id}/under_cancellation/${stage}`
  );
  return postRes;
};
