import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag.js";
import {
  getAllFocusProject
  // getAddUpdateFocusProject
} from "../../dataParser/getProjectData";
import Loader from "../../component/common/loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import ProjectFilter from "../filter/projectFilter";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
// import MagnetLeadModel from "../../component/common/modal/magnetLeadModal.js";
import AddLeadModal from "../../component/common/modal/addLeadModal.js";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Footer from "../../component/footer/footer";
import { getEmailTamplateData } from "../../dataParser/magnetUser";
import { magnet_admin_bcc_mail_id } from "../../config/config";
import Button from "@material-ui/core/Button";
import ReactGA from "react-ga";
import ProjectPdfPreview from "../pdfGenrete/ProjectPdfPreview";
import ProjectPdfContainer from "../pdfGenrete/ProjectPdfContainer.js";
import { DigitalForm } from "../common/form/digitalForm.js";
import { InvestNow } from "../common/Button.js";
import FactSheetPreview from "../project/FactSheetPreview.js";
import DownloadFactsheet from "../project/DownloadFactsheet.js";
// import {cleanStorage} from "../../helper";

class FocusProjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      sweetShow: false,
      type: "success",
      title: "",
      showLoader: false,
      addModalShow: false,
      showFilter: false,
      meta: props.projectDataValue.meta,
      data: props.projectDataValue.data,
      modalShow: false,
      fullDetail: "",
      updateModalShow: false,
      isDesktop: 0,
      showModal: false,
      previewProjectId: false,
      downloadPdf: 0,
      downloadProjectId: null,
      path: this.props.path,
      copyText: "",
      digiInterestShow: false
    };
    window.addEventListener("resize", this.update);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      // this.setState({nextProps});
      this.setState({
        meta: nextProps.projectDataValue.meta,
        data: nextProps.projectDataValue.data,
        activePage: 1
      });
    }
  }
 
  goDigital = () => {
    ReactGA.event({
      category: "Focus Project",
      action: "Go Digital"
    });
  };

  interestedProject = () => {
    ReactGA.event({
      category: "Focus Project",
      action: "I am Interested"
    });
  };

  addLead = () => {
    ReactGA.event({
      category: "Focus Project",
      action: "Add Lead"
    });
  };


  update = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };
/* 
  handleEmail = async (project_id, subject) => {
    var emailData = {};
    emailData.mail_details = {};

    emailData.mail_details.to = magnet_admin_bcc_mail_id;
    emailData.mail_details.subject = subject;
    emailData.member_id = window.$user.id;
    emailData.project_id = project_id;
    emailData.email_type = "USER_INT_INVEST";

    var resData = await getEmailTamplateData(emailData);

    if (resData.meta.status === 503) {
      this.setState({
        showLoader: false,
        sweetShow: true,
        type: "error",
        title: "Mail not send"
      });
    }
  };
 */
  /* 
    handleFocusInvest = async(id,invest) =>{
        this.setState({showLoader : true});

        let investment = !invest;

        let mainData = {};
        mainData["project_id"] = id ;  
        mainData["invest"] = investment === true ? 1 : 0 ;
        
        const resData = await getAddUpdateFocusProject(mainData);

        if(resData.meta.status == 201){
            
            this.setState({
                showLoader : false,
                sweetShow:true,
                type : "success",   
                title : "Invested Successfully!!!"
            })
            // this.handleEmail(id,"Magnet Invested");
            window.scrollTo(0, 0);
        }else if(resData.meta.status == 200){
            
            this.setState({
                showLoader : false,
                sweetShow:true,
                type : "success",   
                title : "Updated Successfully!!!"
            })
            // this.handleEmail(id,"Magnet Invested");

        }else{
            this.setState({
                showLoader : false,
                sweetShow:true,
                type : "error",   
                title : "Something went wrong!!!"
            })
        }
    }
*/

  /* 
    handleFocusInterested = async(id,Interested) =>{
        this.setState({showLoader : true});

        let interest = !Interested;

        let mainData = {};
        mainData["project_id"] = id ;  
        mainData["is_interested"] = interest === true ? 1 : 0 ;
        
        const resData = await getAddUpdateFocusProject(mainData);

        if(resData.meta.status == 201){
            
            this.setState({
                showLoader : false,
                sweetShow:true,
                type : "success",   
                title : "Interested Successfully!!!"
            })
            // this.handleEmail(id,"Magnet Interested");
            window.scrollTo(0, 0);
        }else if(resData.meta.status == 200){
            
            this.setState({
                showLoader : false,
                sweetShow:true,
                type : "success",   
                title : "Updated Successfully!!!"
            })
            // this.handleEmail(id,"Magnet Interested");

        }else if(resData.meta.status == 401){
            
            cleanStorage();
            this.props.history.push("/login");

        }else{
            this.setState({
                showLoader : false,
                sweetShow:true,
                type : "error",   
                title : "Something went wrong!!!"
            })
        }

    }
*/

  genericGetListData = async params => {
    this.setState({ showLoader: true });
    (async () => {
      try {
        let resData = await getAllFocusProject(params);
        if (resData.meta.status === 200) {
          resData.data = resData.data.filter(item => {
            if (item.ProjectMeta.length) {
              item.ProjectMeta = this.unique(item.ProjectMeta);
            }
            return item;
          });
        }
        await this.setState({
          meta: resData.meta,
          data: resData.data,
          showLoader: false
        });
        window.scrollTo(0, 0);
        /*         if(resData.meta.count >= 0 && resData.meta.status === 200){
                this._isMounted = true;                      
                    this.setState({
                     projectDetail : resData,
                     showLoader : false,
                    }) ;
                    // this.setState({})

             }else if(resData.meta.status === 401){
                 cleanStorage();
                 this.props.history.push("/login");
             }else{
             } */
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  handleChangePage = async pageNumber => {
    this.setState({
      showLoader: true,
      activePage: pageNumber
    });
    var listData = {};
    listData.filters = [];
    listData.size = 8;
    listData.pageId = pageNumber;
    listData.filters = { is_focus: true };

    this.genericGetListData(listData);
  };

  handleModal = (id, name) => {
    this.setState({ addModalShow: !this.state.addModalShow });
    this.setState({
      proc_id: id,
      proc_name: name
    });
  };

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    this.genericGetListData();
    // window.location.reload();
  };

  pdfPreview = projectId => {
    this.setState({ showModal: true, previewProjectId: projectId });
  };

  pdfGenrete = projectId => {
    this.setState({ downloadPdf: 1, downloadProjectId: projectId });
    // <ProjectPdfContainer projectId={projectId} />
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  detailView(id) {
    if (this.state.listDetail == id) {
      this.setState({ listDetail: null });
    } else {
      this.setState({ listDetail: id });
    }
  }

  handlePopUp = () => {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter
    }));
  };

  handleFilter = async filterData => {
    this.setState({ showLoader: true });
    this.setState({ filters: filterData, activePage: 1 });
    let listData = {};
    // listData.size = 8;
    // listData.pageId = 1;
    Object.assign(listData, filterData);
    this.genericGetListData(listData);
  };

  handleUpdateFilter = async () => {
    this.setState({
      showLoader: true,
      fullDetail: {}
    });
    let listData = {};
    Object.assign(listData, this.state.filters);

    this.genericGetListData(listData);
  };
  unique = arr => {
    //To store the unique sub arrays
    let uniques = [];
    arr.map(item => {
      if (uniques.findIndex(itemObj => itemObj.bhk === item.bhk) === -1) {
        uniques.push(item);
      }
    });
    //Return the unique list
    return uniques;
  };
  handleDigiInterest = (data, type) => {
    this.setState({
      digiInterestShow: !this.state.digiInterestShow,
      fullDetail: data,
      digiRestType: type
    });
  };
  render() {
    const { status } = this.state.meta;
    const { sweetShow, type, title, data, copyText } = this.state;
    const isDesktop = this.state.screenWidth;

    return (
      <Frag>
        {isDesktop > 991 ? (
          <div className="content-wrapper">
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <Loader show={this.state.showLoader} />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Projects : </span>
                <span className="">{status === 400 ? "0" : data.length}</span>
              </div>
              <div className="d-inline ml-3 filterHeader">
                <span className="filter" onClick={this.handlePopUp}>
                  <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                </span>
                <div
                  className={
                    "filterMenu" +
                    " " +
                    (this.state.showFilter ? "filterMenuShow" : "")
                  }
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <ProjectFilter
                    filterData={this.handleFilter}
                    changeFilter={this.handlePopUp}
                  />
                </div>
              </div>
              {/*<SpeedDialProject/>*/}
            </div>
            {status === 200 && data.length ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {data.map((projectsList, index) => (
                      <div className="MainList" key={projectsList.id}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3">
                            <div className="row mb-2 pb-2 pr-0">
                              <div className="col-md-1">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="text-muted">ID </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="valueText">
                                      {projectsList.id ? projectsList.id : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 pl-0">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead pl-0">
                                    <span className="text-muted">
                                      Project Name
                                    </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 fold-lead pl-0">
                                    {projectsList.name !== null ? (
                                      <span className="valueText text-capitalize">
                                        {projectsList.name}
                                      </span>
                                    ) : (
                                      <span>{"-"}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 pl-2">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead px-0">
                                    <span className="text-muted">
                                      Builder Name
                                    </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 fold-lead px-0">
                                    {projectsList.builder_name ? (
                                      <span className="valueText text-capitalize">
                                        {projectsList.builder_name}
                                      </span>
                                    ) : (
                                      <span>{"-"}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 pl-4">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead">
                                    <span className="text-muted">Price</span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="valueText">
                                      {projectsList?.ProjectMeta.length === 1
                                        ? "Rs. " +
                                          projectsList?.ProjectMeta[0].price
                                        : Math.min(
                                            ...projectsList?.ProjectMeta.map(
                                              item => item?.price
                                            )
                                          ) == "Infinity"
                                        ? "-"
                                        : "Rs. " +
                                          Math.min(
                                            ...projectsList?.ProjectMeta.map(
                                              item => item?.price
                                            )
                                          ) +
                                          " Onwards"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead">
                                    <span className="text-muted">Config</span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="valueText">
                                      {projectsList.ProjectMeta.length > 0
                                        ? projectsList?.ProjectMeta.map(
                                            item => item?.bhk
                                          )
                                            .sort(function (a, b) {
                                              return a - b;
                                            })
                                            .join(", ") + " BHK"
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 px-0">
                                <div className="row pt-3">
                                  <div className="col-md-12 col-sm-12 pl-0 detailText  d-flex justify-content-center">
                                    <Tooltip title="Add Lead">
                                      <button
                                        className="btn btn-info p-1"
                                        onClick={() => {
                                          this.handleModal(
                                            projectsList.id,
                                            projectsList.name
                                          );
                                          this.addLead();
                                        }}
                                      >
                                        <AddIcon />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                              {projectsList.FocusProjects.length ? (
                                <Frag>
                                  <div className="col-md-2">
                                    <div className="row pt-3">
                                      <div className="col-12">
                                        <InvestNow
                                          disabled={
                                            projectsList?.is_adwords &&
                                            window.$role === "magnet"
                                              ? false
                                              : true
                                          }
                                          onClick={() => {
                                            this.handleDigiInterest(
                                              projectsList,
                                              "digital"
                                            );
                                            this.goDigital();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 pt-3 px-0">
                                    <div className="col-md-12 col-sm-12 pr-0 mr-0">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className="px-3 bg-success"
                                        disabled={
                                          projectsList?.is_adwords &&
                                          window.$role === "magnet"
                                            ? false
                                            : true
                                        }
                                        onClick={() =>{
                                          this.handleDigiInterest(
                                            projectsList,
                                            "interest"
                                          );
                                          this.interestedProject();
                                        }}
                                      >
                                        <span className="text-capitalize">
                                          I'm Interested
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                </Frag>
                              ) : (
                                <Frag>
                                  <div className="col-md-2 px-0">
                                    <div className="row pt-3">
                                      <div className="col-12">
                                        <InvestNow
                                          disabled={
                                            projectsList?.is_adwords &&
                                            window.$role === "magnet"
                                              ? false
                                              : true
                                          }
                                          onClick={() => {
                                            this.handleDigiInterest(
                                              projectsList,
                                              "digital"
                                            );
                                            this.goDigital();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 pt-3 px-0">
                                    <div className="col-md-12 col-sm-12 pr-0 mr-0">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className="px-3 bg-success"
                                        disabled={
                                          projectsList?.is_adwords &&
                                          window.$role === "magnet"
                                            ? false
                                            : true
                                        }
                                        onClick={() =>{
                                          this.handleDigiInterest(
                                            projectsList,
                                            "interest"
                                          );
                                          this.interestedProject();
                                        }}
                                      >
                                        <span className="text-capitalize">
                                          I'm Interested
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                </Frag>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead">
                                    <span className="text-muted">Location</span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    {projectsList.location ? (
                                      <span className="valueText text-capitalize">
                                        {projectsList.location}
                                      </span>
                                    ) : (
                                      <span>{"-"}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 px-md-0">
                                <div className="row">
                                  {projectsList?.FocusProjects &&
                                    projectsList?.fact_sheet && (
                                      <Frag>
                                        <div className="col-md-1 col-2 mr-md-2">
                                          <FactSheetPreview
                                            id={projectsList.id}
                                            icon={true}
                                          />
                                        </div>
                                        <div className="col-md-1 col-2">
                                          <DownloadFactsheet
                                            id={projectsList.id}
                                          />
                                        </div>
                                      </Frag>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No Projects Found !!!</h5>
              </div>
            )}
          </div>
        ) : (
          <div className="content-wrapper">
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <Loader show={this.state.showLoader} />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Projects : </span>
                <span className="">{status === 400 ? "0" : data.length}</span>
              </div>
              <div className="d-inline ml-3 filterHeader">
                <span className="filter" onClick={this.handlePopUp}>
                  <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                </span>
                <div
                  className={
                    "filterMenu" +
                    " " +
                    (this.state.showFilter ? "filterMenuShow" : "")
                  }
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <ProjectFilter
                    filterData={this.handleFilter}
                    changeFilter={this.handlePopUp}
                  />
                </div>
              </div>
              {/*<SpeedDialProject/>*/}
            </div>
            {status === 200 && data.length ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {data.map((projectsList, index) => (
                      <div className="MainList" key={projectsList.id}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3">
                            <div className="row  pb-2">
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-12 fold-lead">
                                    <span className="text-muted">ID </span>
                                  </div>
                                  <div className="col-12 fold-lead">
                                    <span className="valueText">
                                      {projectsList.id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-12 fold-lead">
                                    <span className="text-muted">
                                      Project Name
                                    </span>
                                  </div>
                                  <div className="col-12 fold-lead pr-0">
                                    {projectsList.name !== null ? (
                                      <span className="valueText text-capitalize">
                                        {projectsList.name}
                                      </span>
                                    ) : (
                                      <span>{"-"}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-12 fold-lead">
                                    <span className="text-muted">
                                      Builder Name
                                    </span>
                                  </div>
                                  <div className="col-12 fold-lead">
                                    {projectsList.builder_name !== null ? (
                                      <span className="valueText text-capitalize">
                                        {projectsList.builder_name}
                                      </span>
                                    ) : (
                                      <span>{"-"}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-12 fold-lead">
                                    <span className="text-muted">Location</span>
                                  </div>
                                  <div className="col-12 fold-lead">
                                    {projectsList.location !== null ? (
                                      <span className="valueText text-capitalize">
                                        {projectsList.location}
                                      </span>
                                    ) : (
                                      <span>{"-"}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-12 fold-lead">
                                    <span className="text-muted">Price</span>
                                  </div>
                                  <div className="col-12 fold-lead">
                                    <span className="valueText">
                                      {projectsList?.ProjectMeta.length === 1
                                        ? "Rs. " +
                                          projectsList?.ProjectMeta[0].price
                                        : Math.min(
                                            ...projectsList?.ProjectMeta.map(
                                              item => item?.price
                                            )
                                          ) == "Infinity"
                                        ? "-"
                                        : "Rs. " +
                                          Math.min(
                                            ...projectsList?.ProjectMeta.map(
                                              item => item?.price
                                            )
                                          ) +
                                          " Onwards"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-12 fold-lead">
                                    <span className="text-muted">Config</span>
                                  </div>
                                  <div className="col-12 fold-lead">
                                    <span className="valueText">
                                      {projectsList.ProjectMeta.length > 0
                                        ? projectsList?.ProjectMeta.map(
                                            item => item?.bhk
                                          )
                                            .sort(function (a, b) {
                                              return a - b;
                                            })
                                            .join(", ") + " BHK"
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="row">
                                  <div className="col-2 pt-2">
                                    <Tooltip title="Add Lead">
                                      <button
                                        className="btn btn-info p-1"
                                        onClick={() => {
                                          this.handleModal(
                                            projectsList.id,
                                            projectsList.name
                                          );
                                          this.addLead();
                                        }}
                                      >
                                        <AddIcon />
                                      </button>
                                    </Tooltip>
                                  </div>
                                  {projectsList.FocusProjects.length ? (
                                    <Frag>
                                      <div className="col-5 pt-2">
                                        <InvestNow
                                          disabled={
                                            projectsList?.is_adwords &&
                                            window.$role === "magnet"
                                              ? false
                                              : true
                                          }
                                          onClick={() => {
                                            this.handleDigiInterest(
                                              projectsList,
                                              "digital"
                                            );
                                            this.goDigital();
                                          }}
                                        />
                                      </div>
                                      <div className="col-5  pt-2 pl-1">
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          className="px-3 bg-success"
                                          disabled={
                                            projectsList?.is_adwords &&
                                            window.$role === "magnet"
                                              ? false
                                              : true
                                          }
                                          onClick={() =>{
                                            this.handleDigiInterest(
                                              projectsList,
                                              "interest"
                                            );
                                            this.interestedProject();
                                          }}
                                        >
                                          <span className="text-capitalize">
                                            I'm Interested
                                          </span>
                                        </Button>
                                      </div>
                                    </Frag>
                                  ) : (
                                    <Frag>
                                      <div className="col-5 pt-2">
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          className="px-3"
                                          disabled={
                                            projectsList?.is_adwords &&
                                            window.$role === "magnet"
                                              ? false
                                              : true
                                          }
                                          onClick={() =>
                                            this.handleDigiInterest(
                                              projectsList,
                                              "digital"
                                            )
                                          }
                                        >
                                          <span className="text-capitalize">
                                            Invest Now
                                          </span>
                                        </Button>
                                      </div>
                                      <div className="col-5  pt-2 pl-1">
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          className="px-3 bg-success"
                                          disabled={
                                            projectsList?.is_adwords &&
                                            window.$role === "magnet"
                                              ? false
                                              : true
                                          }
                                          onClick={() =>{
                                            this.handleDigiInterest(
                                              projectsList,
                                              "interest"
                                            );
                                            this.interestedProject();
                                          }}
                                        >
                                          <span className="text-capitalize">
                                            I'm Interested
                                          </span>
                                        </Button>
                                      </div>
                                    </Frag>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 pb-1 pt-3">
                                <div className="row">
                                  {projectsList?.FocusProjects &&
                                    projectsList?.fact_sheet && (
                                      <Frag>
                                        <div className="col-md-1 col-2 mr-md-2">
                                          <FactSheetPreview
                                            id={projectsList.id}
                                            icon={true}
                                          />
                                        </div>
                                        <div className="col-md-1 col-2">
                                          <DownloadFactsheet
                                            id={projectsList.id}
                                          />
                                        </div>
                                      </Frag>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>Projects not Found</h5>
              </div>
            )}
          </div>
        )}

        <Footer expand={this.state.sideBar} />
        <DigitalForm
          show={this.state.digiInterestShow}
          changeModal={this.handleDigiInterest}
          fullDetail={this.state.fullDetail}
          handleProjectData={this.genericGetListData}
          digiRestType={this.state.digiRestType}
        />
        {/* <MagnetLeadModel show={this.state.addModalShow} changeModal={this.handleModal} proc_id={this.state.proc_id} proc_name={this.state.proc_name} getList={this.handleUpdateFilter}/> */}
        <AddLeadModal
          show={this.state.addModalShow}
          changeModal={this.handleModal}
          proc_id={this.state.proc_id}
          proc_name={this.state.proc_name}
          getList={this.handleUpdateFilter}
        />
        {this.state.downloadPdf === 1 && this.state.downloadProjectId > 0 && (
          <ProjectPdfContainer projectId={this.state.downloadProjectId} />
        )}
        {this.state.previewProjectId ? (
          <ProjectPdfPreview
            showModal={this.state.showModal}
            handleModalClose={this.handleModalClose}
            projectId={this.state.previewProjectId}
          />
        ) : (
          ""
        )}
      </Frag>
    );
  }
}

export default FocusProjectTable;