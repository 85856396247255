import React, { Component } from "react";
import "../filter/filter.css";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { getAutoCompleteBuilder, getAutoCompleteProject } from "../../dataParser/getProjectData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { autoComplete } from "../../dataParser/commomDataApi";
import { REVERT_ROLES } from "../../config/constant";
import { cleanStorage } from "../../helper";

class ProjectFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            projects: [],
            project_id: '',
            magnetName: [],
            requested_by: "",
            builders: [],
            builder_name: "",
        };
    }

    handleAutoChange = async (e) => {
        this.setState({ isLoading: true });
        let value = e.target.value
        let projectListData = await getAutoCompleteProject(value);
        if (projectListData.meta.status === 200) {
            this.setState({ isLoading: false });
            this.setState({ projects: projectListData.data })
        } else if (projectListData.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");
        } else {

            this.setState({ isLoading: false });
            this.setState({ projects: [] })
        }

    }

    handleAutoBuilderChange = async (e) => {
        // this.setState({ isLoading: true });
        let value = e.target.value
        let getData = await getAutoCompleteBuilder(value);
        if (getData.meta.status === 200) {
            // this.setState({ isLoading: false });
            this.setState({ builders: getData.data })
        } else if (getData.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");
        } else {

            // this.setState({ isLoading: false });
            this.setState({ builders: [] })
        }

    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleAutoMagnetChange = async (e) => {
        let data = {
            q: e.target.value,
            roles: [REVERT_ROLES.MAGNET]
        };
        let resData = await autoComplete('users', data);
        if (resData.meta.status === 200) {

            this.setState({ magnetName: resData.data })
        } else if (resData.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");

        } else {

            this.setState({ magnetName: [] })
        }
    }

    applyFilter = () => {
        let filterData = {};
        const { project_id, builder_name, requested_by } = this.state;
        if (!!project_id) {
            filterData["ids"] = project_id;
        } 
        if (!!builder_name) {
            filterData["builder_name"] = builder_name;
        }
        if (!!requested_by) {
            filterData["requested_by"] = requested_by.toString();
        }
        this.props.filterData(filterData);
    }

    resetFilter = () => {
        this.setState({
            autoKey: !this.state.autoKey,
            project_id: "",
            builder_name: "",
            requested_by: ""
        })
    }

    render() {
        const { option, autoKey, isLoading, magnetName, builders, projects } = this.state;
        return (
            <Frag>
                <div className="container filter_lead filterMenuCard pt-3 p-0" ref={this.props.fltr}>
                    <div className="row filterRow">
                        <div className="col-lg-6 col-sm-6 col-6">
                            <div className="form-group">
                                <Autocomplete
                                    key={autoKey}
                                    id="projectData"
                                    getOptionSelected={(projects, value) => projects.name == value.name}
                                    getOptionLabel={projects => projects.name}
                                    options={projects}
                                    loading={isLoading}
                                    onChange={(_event, projects) => {
                                        if (option === null) {
                                            this.setState({
                                                option: [],
                                                project_id: '',
                                            })
                                        } else {
                                            var projectId = projects?.id;
                                            this.setState({
                                                project_id: projectId,
                                            })
                                        }

                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Project Name"
                                            fullWidth
                                            onChange={this.handleAutoChange}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isLoading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-6">
                            <div className="form-group">
                                <Autocomplete
                                    key={autoKey}
                                    getOptionSelected={(builders, value) => builders.builder_name == value.builder_name}
                                    getOptionLabel={builders => builders.builder_name}
                                    options={builders}
                                    loading={isLoading}
                                    onChange={(_event, builders) => {
                                        if (builders === null) {
                                            this.setState({
                                                builders: [],
                                                builder_name: '',
                                            })
                                        } else {
                                            var builder = builders?.builder_name;
                                            this.setState({
                                                builder_name: builder,
                                            })
                                        }

                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Builder Name"
                                            fullWidth
                                            onChange={this.handleAutoBuilderChange}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                       {/*  {isLoading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null} */}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <Autocomplete
                                    key={autoKey}
                                    getOptionSelected={(magnetName, value) => magnetName.name == value.name}
                                    getOptionLabel={magnetName => magnetName.name}
                                    options={magnetName}
                                    onChange={(_event, magnetName) => {
                                        if (magnetName === null) {
                                            this.setState({
                                                magnetName: [],
                                                requested_by: '',
                                            })
                                        } else {
                                            // let id = magnetName.map( ops => ops.id);
                                            let id = magnetName.id;
                                            this.setState({
                                                requested_by: id,
                                            })
                                        }

                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Requested By"
                                            fullWidth
                                            onChange={this.handleAutoMagnetChange}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                       {/*  {isLoading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null} */}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-md-12 bgCommon">
                        <div className="row">
                            <div className="col-sm-6 text-right col-6 mt-3">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mt-3">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Frag>
        );
    };
};

export default withRouter(ProjectFilter)
