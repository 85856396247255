import React, { Component } from 'react';
import { deleteDocDataApi, updateDocDataApi, showDocDataApi } from "../../dataParser/hrDocs";
import { getRoleList } from "../../dataParser/commomDataApi";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import pdf2base64 from 'pdf-to-base64';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import Tooltip from '@material-ui/core/Tooltip';
import { Multiselect } from 'multiselect-react-dropdown';
import HrDocPreviewer from './hrDocPreviewer.js'

class HrDocsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            role: [[]],
            selectedRoleId: [[]],
            data: props.docListData,
            type: "success",
            showLoader: false,
            showModal: false,
            open: false,
            curListOption: '',
            msg: '',
            listOptions: null,
        };
        this.roleRef = React.createRef();
    }

    componentDidUpdate(nextProps) {
        if (this.props !== nextProps) {
            this.setDynamicSwitchState(this.props.docListData);
        }
    }

    componentDidMount() {
        this.update();
        (async () => {
            var roleListData = await getRoleList();
            this.setState({ roles: roleListData })
        })()
        this.setDynamicSwitchState(this.props.docListData);
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSelect = (selectedList, selectedItem) => {
        let signleRole = [...this.state.role];
        signleRole[this.state.curListOption] = selectedList;
        this.setState({
            role: [...signleRole]
        })
    }

    onRemove = (selectedList, selectedItem) => {
        setTimeout(() => {
            let signleRole = [...this.state.role];
            signleRole[this.state.curListOption] = selectedList;
            this.setState({
                role: [...signleRole]
            })
        }, 1000)
    }

    setDynamicSwitchState = (list) => {
        if (!list) {
            return
        }

        const role = [];
        list.forEach((item, index) => {
            let arr = [];
            item.HrMappings.map((cur) => {
                arr.push(cur.Role)
            })
            role.splice(index, 0, arr);
        });

        this.setState({
            role: role,
        })
    }
    handleSelect = (index) => {
        this.setState({ curListOption: index });
    }

    handleDeleteDoc = async (id) => {
        let deleteRes = await deleteDocDataApi(id);
        if (deleteRes.meta.status === 200) {
            this.setState({
                showLoader: false,
                open: true,
                type: "success",
                msg: "Document Deleted Successfully",
            });
        } else {
            this.setState({
                showLoader: false,
                open: true,
                type: "error",
                msg: "Some Error Happend!!!",
            });
        }
    }
    updateDocValue = async (id, index) => {
        let roleData = this.state.role[index];
        let role_ids = [];
        roleData.map((cur) => { role_ids.push(cur.id) });
        let value = {};
        value.data = {};
        value.id = id;
        value.data["role_id"] = role_ids;
        let updateDoc = await updateDocDataApi(value);
        if (updateDoc.meta.status === 201) {
            // this.props.getListData();
            this.setState({
                showLoader: false,
                open: true,
                type: "success",
                msg: "Document Updated Successfully",
            });
        } else {
            this.setState({
                showLoader: false,
                open: true,
                type: "error",
                msg: "Some Error Happend!!!",
            });
        }
    }

    viewDocData = async (id) => {
        let resData = await showDocDataApi(id);
        if (resData.meta.status === 201) {
            if (resData.data.fileUrl !== "https://s3.amazonaws.com/") {
                pdf2base64(resData.data.fileUrl).then((response) => {
                    this.setState({ 'View_file_path': response });
                    this.setState({ showModal: true });
                }).catch((error) => {
                    console.error(error); //Exepection error....
                })
            }
        }
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.getListData()
    }
    handleModalClose = () => {
        this.setState({ showModal: false });
    }

    handleClickOption = (event) => {
        // setAnchorEl(event.currentTarget);
        this.setState({ listOptions: event.currentTarget })
    };

    handleCloseOption = () => {
        // setAnchorEl(null);
        this.setState({ listOptions: null })
    };

    render() {
        const { type, msg, open } = this.state;
        const isDesktop = this.state.screenWidth;
        return (
            <>
                <PopUpAlert type={type} msg={msg} open={open} handleClose={this.handleClose} />
                <div className="container">
                    <div className="row mb-3 p-3 tableHead">
                        <div className="col-md-4 col-4 text-center">
                            <span className="fs-18">Document Name</span>
                        </div>
                        <div className="col-md-4 col-4 text-center">
                            <span className="fs-18">Available For</span>
                        </div>
                        <div className="col-md-4 col-4 text-center">
                            <span className="fs-18">File</span>
                        </div>
                    </div>
                    {this.props.docListData.map((cur, index) =>
                        <div className="row mb-4 pb-2 docsRow" key={cur.id}>
                            <div className="col-md-4 col-4">
                                <div className="doc-name">
                                    <div className="serial-num">{cur.id}.</div>
                                    <div className="doc-name text-capitalize">{cur.file_name}</div>
                                </div>
                            </div>
                            <div className="col-md-4 col-7" onClick={(e) => this.handleSelect(index)}>
                                <FormControl>
                                    <Multiselect
                                        options={this.state.roles}
                                        selectedValues={this.state.role[index]}
                                        onSelect={this.onSelect}
                                        onRemove={this.onRemove}
                                        displayValue="alias"
                                    />
                                </FormControl>
                            </div>
                            {isDesktop > 991 ?
                                <div className="col-md-4">
                                    <div className="action-container">
                                        <div className="row">
                                            <div className="col-4 text-center">
                                                <Tooltip title="Update Users">
                                                    <IconButton aria-label="delete" className="">
                                                        <PersonAddIcon onClick={() => this.updateDocValue(cur.id, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="col-4 text-center">
                                                <Tooltip title="View Document">
                                                    <IconButton aria-label="delete" className="">
                                                        <VisibilityIcon onClick={() => { this.viewDocData(cur.id) }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="col-4 text-center">
                                                <Tooltip title="Delete Document">
                                                    <IconButton aria-label="delete" className="">
                                                        <DeleteIcon onClick={() => this.handleDeleteDoc(cur.id)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={this.handleClickOption}
                                        className="listOptionBtn"
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.listOptions}
                                        keepMounted
                                        open={Boolean(this.state.listOptions)}
                                        onClose={this.handleCloseOption}
                                    >
                                        <MenuItem className="singleMenu" onClick={() => { this.updateDocValue(cur.id, index); this.handleCloseOption() }}>
                                            <PersonAddIcon />Update User
                                        </MenuItem>
                                        <MenuItem className="singleMenu" onClick={() => { this.viewDocData(cur.id); this.handleCloseOption() }}>
                                            <VisibilityIcon />View Doc
                                        </MenuItem>
                                        <MenuItem className="singleMenu" onClick={() => { this.handleDeleteDoc(cur.id); this.handleCloseOption() }}>
                                            <DeleteIcon />Delete Doc
                                        </MenuItem>
                                    </Menu>
                                </>
                            }
                        </div>
                    )}
                </div>
                <HrDocPreviewer
                    showModal={this.state.showModal}
                    handleModalClose={this.handleModalClose}
                    ViewFilePath={this.state.View_file_path}
                />
            </>
        );

    }
}

export default HrDocsTable;