import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import Frag from "../../../utils/Frag/Frag.js";
import RadioGroup from '@material-ui/core/RadioGroup';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { getUpdateCpUser } from "../../../dataParser/getCpUserData";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { FilePond } from "react-filepond";
import { autoComplete } from "../../../dataParser/commomDataApi";
import { cleanStorage, objEmpty } from "../../../helper";
import "filepond/dist/filepond.min.css";
import Loader from "../../../component/common/loader/loader";
import FormHelperText from '@material-ui/core/FormHelperText';
import LockIcon from '@material-ui/icons/Lock';
import { REVERT_ROLES } from "../../../config/constant/index.js";
import ChangeUserPassModal from "../../../component/common/modal/changesUserpassModal";
import { primary, operate, leads, alternateBusiness, businessOperate, resaleRental } from "../datas/data"
import PopUpAlert from '../sweetAlert/popUpAlert.js';
import SelectOption from '../controls/SelectOption.js';
import { getRegionParentsData } from '../../../dataParser/getCpUserData.js';
import { magnetCallRoles } from '../../../config/constant/crmRoleFunctionPermission.js';
import RadioGroupOperate from '../controls/RadioGroup.js';
const validateName = RegExp(/^[a-zA-Z ]+$/);

class UpdateCpUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      email: '',
      user_id: '',
      isLoading: false,
      is_active: false,
      upload_limit: '',
      first_name: '',
      last_name: '',
      pancard_no: '',
      adharcard_no: '',
      gst_no: '',
      adhar_file: [],
      pen_file: [],
      profile_pic: [],
      visiting_card: [],
      have_team: null,
      total_experience: null,
      service_rm: [],
      assign_rm_id: '',
      operate_business: '',
      deal_business_types: [],
      plateform_present_in: [],
      genrate_leads: [],
      alternate_business_us: [],
      devote_time: '',
      office_location: '',
      rera_no: '',
      list_properties: null,
      own_state_business: null,
      changePassModalShow: false,
      sweetShow: false,
      type: "success",
      title: "",
      phone: '',
      old_phone: '',
      errors: {
        first_name: '',
        phone: '',
        email: '',
        last_name: '',
        adharcard_no: '',
        pancard_no: '',
        p_message: '',
        pen_file: '',
        profile_pic: '',
        visiting_card: '',
        adhar_file: '',
      }
      , alertShow: false
      , alertType: "success"
      , alertMsg: ""
			, region_option: []
			, work_in_region: ""
      , servicerm_assign: false
      , selectedVal: {}
      , selectedRegionVal: {}
      , parent_region_ids: []

      , pincode: ""
    };
  }

  componentDidMount() {

    this.setState({
      role: window.$role
    });

    let CpData = this.props.CpUpdateDetail.data.UserInfo;
    let cpBasic = this.props.CpUpdateDetail.data;

    let arrParent = cpBasic.UserRegions !== null ? cpBasic.UserRegions : [];

    let userDataArr = [];
    let parentUser = [];
    arrParent.map((cur, index) => {
        let parent = {};
        parent.id = cur.RegionParent?.id
        parent.name = cur.RegionParent?.name
        userDataArr.push(parent);
        parentUser.push(cur.RegionParent?.id);
    })
    if (CpData !== null) {
      let alternate_business_us = typeof CpData.alternate_business_us === 'string' ? JSON.parse(CpData.alternate_business_us) : CpData.alternate_business_us
      let deal_business_types = typeof CpData.deal_business_types === 'string' ? JSON.parse(CpData.deal_business_types) : CpData.deal_business_types
      let genrate_leads = typeof CpData.genrate_leads === 'string' ? JSON.parse(CpData.genrate_leads) : CpData.genrate_leads
      let plateform_present_in = typeof CpData.plateform_present_in === 'string' ? JSON.parse(CpData.plateform_present_in) : CpData.plateform_present_in

      this.setState({
        email: cpBasic.email,
        upload_limit: CpData.max_lead === null ? "20" : CpData.max_lead,
        service_rm: CpData.UserServiceRm === null ? [] : [CpData.UserServiceRm],
        assign_rm_id: CpData.UserServiceRm === null ? '' : CpData?.UserServiceRm?.id,
        servicerm_assign:CpData.UserServiceRm === null ? false : true,
        selectedVal: CpData?.UserServiceRm || {},
        is_active: cpBasic.is_active === null ? "" : cpBasic.is_active,
        first_name: cpBasic.first_name === null ? "" : cpBasic.first_name,
        last_name: cpBasic.last_name === null ? "" : cpBasic.last_name,
        phone: cpBasic.phone === null ? "" : cpBasic.phone,
        old_phone: cpBasic.phone === null ? "" : cpBasic.phone,
        email: cpBasic.email === null ? "" : cpBasic.email,
        pancard_no: CpData.pancard_no === null ? "" : CpData.pancard_no,
        adharcard_no: CpData.adharcard_no === null ? "" : CpData.adharcard_no,
        gst_no: CpData.gst_no === null ? "" : CpData.gst_no,
        rera_no: CpData.rera_no === null ? "" : CpData.rera_no,
        devote_time: CpData.devote_time === null ? "" : CpData.devote_time,
        have_team: CpData.have_team === null ? null : CpData.have_team,
        user_id: cpBasic.id === null ? "" : cpBasic.id,
        total_experience: CpData.total_experience === null ? "" : CpData.total_experience,
        list_properties: CpData.list_properties === null ? null : CpData.list_properties,
        own_state_business: CpData.own_state_business === null ? null : CpData.own_state_business,
        office_location: CpData.office_location === null ? "" : CpData.office_location,
        operate_business: CpData.operate_business === null ? "" : CpData.operate_business,
        deal_business_types: deal_business_types === null ? [] : deal_business_types,
        plateform_present_in: plateform_present_in === null ? [] : plateform_present_in,
        genrate_leads: genrate_leads === null ? [] : genrate_leads,
        alternate_business_us: alternate_business_us === null ? [] : alternate_business_us,
        work_in_region: CpData.WorkRegion ? CpData.WorkRegion.id : "" ,
        parent_region_ids: parentUser,
        selectedRegionVal: userDataArr || [],
        pincode: CpData?.pincode || ""
      })
    } else {
      this.setState({
        user_id: cpBasic.id === null ? "" : cpBasic.id,
        first_name: cpBasic.first_name === null ? "" : cpBasic.first_name,
        last_name: cpBasic.last_name === null ? "" : cpBasic.last_name,
        phone: cpBasic.phone === null ? "" : cpBasic.phone,
        old_phone: cpBasic.phone === null ? "" : cpBasic.phone,
        email: cpBasic.email === null ? "" : cpBasic.email,
      })
    }
		this.getRegionOptions();	
	}

	getRegionOptions = async() =>{
		this.setState({showLoader:true})
		let getRes = await getRegionParentsData();
		this.setState({
			showLoader: false,
			region_option: getRes.data
		})
	}

  onChange = (e) => {
    let {name, value} = e.target;
    if(name === "pincode"){
      value = value.replace(/\D/g, "")
    }
    this.setState({ [name]: value });
  }

  handleSubmit = async (e) => {

    e.preventDefault();

    const {
      upload_limit, assign_rm_id, is_active, first_name, last_name, pancard_no, adharcard_no, gst_no
      , adhar_file, pen_file, profile_pic, rera_no, devote_time, have_team, total_experience
      , list_properties, own_state_business, office_location, operate_business, deal_business_types
      , plateform_present_in, genrate_leads, alternate_business_us, phone, old_phone, email, work_in_region, parent_region_ids
      , pincode, visiting_card
    } = this.state;

    let addUserData = {};
    let updateCpData = {};
    addUserData.data = {};
    addUserData.data.Usermeta = {};
  
    if (upload_limit) addUserData.data.Usermeta["max_lead"] = parseInt(upload_limit);
    if (first_name) addUserData.data["first_name"] = first_name;
    if (last_name) addUserData.data["last_name"] = last_name;
    if (phone && old_phone !== phone) addUserData.data["phone"] = phone;
    if (email) addUserData.data["email"] = email;
    addUserData.data["role_id"] = REVERT_ROLES.MAGNET;
    if (is_active) addUserData.data["is_active"] = is_active === true ? 1 : 0;
    addUserData.data['magnet_status'] = 1;
    addUserData.data['self_assigned'] = this.state.servicerm_assign ? true : false;
    if (pancard_no) addUserData.data.Usermeta["pancard_no"] = pancard_no;
    if (adharcard_no) addUserData.data.Usermeta["adharcard_no"] = adharcard_no;
    if (gst_no) addUserData.data.Usermeta["gst_no"] = gst_no;
    if (rera_no) addUserData.data.Usermeta["rera_no"] = rera_no;
    if (devote_time) addUserData.data.Usermeta["devote_time"] = devote_time;

    if (assign_rm_id) addUserData.data.Usermeta["service_rm_id"] = assign_rm_id;

    if (have_team !== null) addUserData.data.Usermeta["have_team"] = parseInt(have_team);
    if (total_experience) addUserData.data.Usermeta["total_experience"] = parseFloat(total_experience);
    if (list_properties !== null) addUserData.data.Usermeta["list_properties"] = parseInt(list_properties);
    if (own_state_business !== null) addUserData.data.Usermeta["own_state_business"] = own_state_business == "1" ? true : false;
    if (office_location) addUserData.data.Usermeta["office_location"] = office_location;
    if (operate_business) addUserData.data.Usermeta["operate_business"] = operate_business;
		if (work_in_region) addUserData.data.Usermeta["work_in_region"] = work_in_region;
		if (pincode) addUserData.data.Usermeta["pincode"] = pincode;

    if (deal_business_types.length > 0) {
      addUserData.data.Usermeta["deal_business_types"] = deal_business_types;
    }
    if (plateform_present_in.length > 0) {
      addUserData.data.Usermeta["plateform_present_in"] = plateform_present_in;
    }
    if (genrate_leads.length > 0) {
      addUserData.data.Usermeta["genrate_leads"] = genrate_leads;
    }

    if (alternate_business_us.length > 0) {
      addUserData.data.Usermeta["alternate_business_us"] = alternate_business_us;
    }
    if (adhar_file.length > 0) {
      addUserData['adhar_file'] = adhar_file[0];
    }
    if (pen_file.length > 0) {
      addUserData["pen_file"] = pen_file[0];
    }
    if (profile_pic.length > 0) {
      addUserData["profile_pic"] = profile_pic[0];
    }
    if (visiting_card.length > 0) {
      addUserData["visiting_card"] = visiting_card[0]
    }
    if (parent_region_ids.length > 0) {
      addUserData.data.Usermeta["parent_region_ids"] = parent_region_ids;
    }
    if (objEmpty(addUserData.data.Usermeta)) {
      delete addUserData.data.Usermeta;
    }
    addUserData.data = JSON.stringify(addUserData.data);

    updateCpData.data = addUserData;
    updateCpData.id = this.state.user_id;

    if (first_name && last_name && this.state.deal_business_types.length>0&&((this.props.profileUpdate !== 'profile' || window.$role === 'magnet') ?
      (phone && email && devote_time && operate_business &&
      have_team !== null && own_state_business !== null && list_properties !== null && office_location  && work_in_region && alternate_business_us && parent_region_ids.length > 0): true)) {

      if (first_name === "" || validateName.test(first_name) === false) {
        this.setState({
          alertShow: true,
          alertType: "error",
          alertMsg: "*Please enter valid first name!"
        })
        return false;
      }

      if (last_name === "" || validateName.test(last_name) === false) {
        this.setState({
          alertShow: true,
          alertType: "error",
          alertMsg: "*Please enter valid Last name!"
        })
        return false;
      }
      if(upload_limit < 20 || upload_limit > 400){
        this.setState({
          alertShow: true,
          alertType: "error",
          alertMsg: "*Value of daily lead limit should be between 20 and 400 "  
        })
        return false;
      }
      if (pincode !== "" && pincode.length !== 6) {
        this.setState({
          alertShow: true,
          alertType: "error",
          alertMsg: "*Please enter valid Pin Code!"
        })
        return false;
      }

      this.setState({ showLoader: true });
      let addLeadRes = await getUpdateCpUser(updateCpData);

      if (addLeadRes.meta.status === 201) {
        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "success",
          title: "User Updated Successfully!!!"
        });
      } else if (addLeadRes.meta.status === 401) {

        cleanStorage();
        this.props.history.push("/login");

      } else {

        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "error",
          title: "Something went wrong !!!"
        });
      }
    } else {
      this.setState({
        alertShow: true,
        alertType: "error",
        alertMsg: "Above * fields are mandatory, Please fill the details*"
      })
    }
  }

  handleAutoChange = async (e) => {
    this.setState({ isLoading: true });
    let data = {
      q: e.target.value,
			users_active: 1,
      roles: [REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.CUSTOMER_SUCCESS, REVERT_ROLES.MAGNET_PNL]
    };
    let resData = await autoComplete('users', data);

    this.setState({ isLoading: false });
    this.setState({ service_rm: resData.data })
  }

  handleMultiSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    this.props.changeModal()
    if (this.props.profileUpdate !== 'profile') this.props.getCpList();
  }

  handleModal = (id, name) => {
    this.setState({ changePassModalShow: !this.state.changePassModalShow });
  }

  handleInit() {
  }

  handleActiveChange = () => {
    this.setState({ is_active: !this.state.is_active });
  };
  handleserviceRmChange = () => {
    this.setState({ servicerm_assign: !this.state.servicerm_assign });
  };
  handleClose = () => {
    this.setState({
      alertShow: false
    })
  }
  handleNumberChange = (e) => {
    let { value } = e.target
    value = value.replace(/\D/g, "")
    this.setState({
      phone: value
    })
  }
  render() {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const { sweetShow, type, title, service_rm, isLoading, selectedVal, alertShow, alertType, alertMsg 
      , region_option, work_in_region, servicerm_assign, operate_business, selectedRegionVal } = this.state;
    return (

      <React.Fragment>
        <Container maxWidth="lg">
          <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
          <Loader show={this.state.showLoader} />
          <div className="row">

            <div className="col-md-12 p-0">
              <Button variant="contained" color="primary" onClick={this.handleModal} className="text-capitalize mb-4 float-right fs-14"><LockIcon />change password</Button>
            </div>

            <form className="w-100">
              <div className="row mb-3">
                <div className="col-md-6 mb-3">
                  <TextField
                    error={this.state.errors.first_name !== '' ? true : false}
                    required
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    value={this.state.first_name ? this.state.first_name : ''}
                    helperText={this.state.errors.first_name !== '' ? this.state.errors.first_name : ''}
                    onChange={this.onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <TextField
                    error={this.state.errors.last_name !== '' ? true : false}
                    required
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    value={this.state.last_name ? this.state.last_name : ''}
                    helperText={this.state.errors.last_name !== '' ? this.state.errors.last_name : ''}
                    onChange={this.onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {this.props.profileUpdate !== 'profile' || window.$role === 'magnet' ?
                  <Frag>
                    <div className="col-sm-6 mb-3">
                      <div className="form-group">
                        <TextField
                          error={this.state.errors.phone !== '' ? true : false}
                          required
                          id="phone"
                          name="phone"
                          value={this.state.phone ? this.state.phone : ''}
                          helperText={this.state.errors.phone !== '' ? this.state.errors.phone : ''}
                          label="Mobile Number"
                          onChange={this.handleNumberChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIphoneIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 mb-1">
                      <div className="form-group">
                        <TextField
                          error={this.state.errors.email !== '' ? true : false}
                          required
                          id="email"
                          name="email"
                          label="Email"
                          value={this.state.email ? this.state.email : ''}
                          helperText={this.state.errors.email !== '' ? this.state.errors.email : ''}
                          onChange={this.onChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Frag>
                  : ''
                }
              </div>
              {this.props.profileUpdate !== 'profile' || window.$role === 'magnet' ?
                <Frag>
                  {window.$role !== 'magnet' &&
                    <>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField label="PAN ID No."
                            error={!!this.state.pancard_no ? false : true}
                            value={this.state.pancard_no ? this.state.pancard_no : ''}
                            id="pancard_no"
                            name="pancard_no"
                            onChange={this.onChange}
                            inputProps={{
                              maxLength: "10",
                              id: "pen_card",
                              className: "text-uppercase"
                            }}
                          />
                        </div>
                        <div className="col-md-6 ">
                          <FormControl error={this.state.errors.pen_file !== '' && true}>
                            <FilePond
                              ref={ref => (this.pond = ref)}
                              pen_file={this.state.pen_file}
                              labelIdle='Drag & Drop your PAN Image or <span class="filepond--label-action">Browse</span>'
                              allowMultiple={false}
                              server={{
                                process: (fieldName, file, metadata, load) => {
                                  setTimeout(() => {
                                    load(Date.now())
                                  }, 1500);
                                },
                                load: (source, load) => {
                                  fetch(source).then(res => res.blob()).then(load);
                                }
                              }}
                              oninit={() => this.handleInit()}
                              onupdatefiles={fileItems => {
                                this.setState({
                                  pen_file: fileItems.map(fileItem => fileItem.file)
                                });
                              }}
                            />
                            {this.state.errors.pen_file !== '' &&
                              <FormHelperText>{this.state.errors.pen_file}</FormHelperText>
                            }
                          </FormControl>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField id="aadhar_card" label="Aadhar No. (12 digit)"
                            error={!!this.state.adharcard_no ? false : true}
                            name="adharcard_no"
                            value={this.state.adharcard_no ? this.state.adharcard_no : ''}
                            onChange={this.onChange}
                            inputProps={{
                              maxLength: "12",
                            }}
                          />
                        </div>
                        <div className="col-md-6 ">
                          <FormControl error={this.state.errors.adhar_file !== '' && true}>
                            <FilePond
                              ref={ref => (this.pond = ref)}
                              adhar_file={this.state.adhar_file}
                              labelIdle='Drag & Drop your Aadhar Image or <span class="filepond--label-action">Browse</span>'
                              allowMultiple={false}
                              server={{
                                process: (fieldName, file, metadata, load) => {
                                  setTimeout(() => {
                                    load(Date.now())
                                  }, 1500);
                                },
                                load: (source, load) => {
                                  fetch(source).then(res => res.blob()).then(load);
                                }
                              }}
                              oninit={() => this.handleInit()}
                              onupdatefiles={fileItems => {
                                this.setState({
                                  adhar_file: fileItems.map(fileItem => fileItem.file)
                                });
                              }}
                            />
                            {this.state.errors.adhar_file !== '' &&
                              <FormHelperText>{this.state.errors.adhar_file}</FormHelperText>
                            }
                          </FormControl>
                        </div>
                      </div>
                    </>
                  }
                  <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                      <TextField
                        error={!!this.state.gst_no ? false : true}
                        label="GSTIN (If Applicable)"
                        value={this.state.gst_no ? this.state.gst_no : ''}
                        id="gst_no"
                        name="gst_no"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        error={!!this.state.rera_no ? false : true}
                        label="RERA No."
                        value={this.state.rera_no}
                        id="rera_no"
                        name="rera_no"
                        onChange={this.onChange} />
                    </div>
                  </div>
                  <div className="row mb-3">

                  <div className="col-md-6">
                      <FormControl error={this.state.errors.visiting_card !== '' && true}>
                        <FilePond
                          ref={ref => (this.pond = ref)}
                          visiting_card={this.state.visiting_card}
                          labelIdle='Drag & Drop your Visiting Card or <span class="filepond--label-action">Browse</span>'
                          allowMultiple={false}
                          server={{
                            process: (fieldName, file, metadata, load) => {
                              setTimeout(() => {
                                load(Date.now())
                              }, 1500);
                            },
                            load: (source, load) => {
                              fetch(source).then(res => res.blob()).then(load);
                            }
                          }}
                          oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            this.setState({
                              visiting_card: fileItems.map(fileItem => fileItem.file)
                            });
                          }}
                        />
                        {this.state.errors.visiting_card !== '' &&
                          <FormHelperText>{this.state.errors.visiting_card}</FormHelperText>
                        }
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl error={this.state.errors.profile_pic !== '' && true}>
                        <FilePond
                          ref={ref => (this.pond = ref)}
                          profile_pic={this.state.profile_pic}
                          labelIdle='Drag & Drop your Profile Image or <span class="filepond--label-action">Browse</span>'
                          allowMultiple={false}
                          server={{
                            process: (fieldName, file, metadata, load) => {
                              setTimeout(() => {
                                load(Date.now())
                              }, 1500);
                            },
                            load: (source, load) => {
                              fetch(source).then(res => res.blob()).then(load);
                            }
                          }}
                          oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            this.setState({
                              profile_pic: fileItems.map(fileItem => fileItem.file)
                            });
                          }}
                        />
                        {this.state.errors.profile_pic !== '' &&
                          <FormHelperText>{this.state.errors.profile_pic}</FormHelperText>
                        }
                      </FormControl>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <FormControl>
                        <InputLabel id="primary-business">Type of Real Estate Business you deal with</InputLabel>
                        <Select
                          labelId="primary-business"
                          id="demo-mutiple-checkbox"
                          multiple
                          name="deal_business_types"
                          value={this.state.deal_business_types}
                          onChange={this.handleMultiSelect}
                          input={<Input id="primary-multiple-chip" />}
                          renderValue={selected => (
                            <div>
                              {selected.map(value => (
                                <Chip key={value} label={value} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {primary.map(item => (
                            <MenuItem key={item.id} value={item.name}>
                              <Checkbox checked={this.state.deal_business_types.indexOf(item.name) > -1} />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}
                    <div className="col-md-6 p-0">
                      <div className="col-md-12">
                        <FormLabel component="legend">How do you devote time to business? <span className="Astrik">*</span></FormLabel>
                      </div>
                      <RadioGroup row aria-label="" className="W-100" name="devote_time" onChange={this.onChange}>
                        <div className=" col-6">
                          <FormControlLabel value="Full Time" control={<Radio color="primary" checked={this.state.devote_time == "Full Time"} />} label="Full Time" />
                        </div>
                        <div className="col-6">
                          <FormControlLabel value="Part Time" control={<Radio color="primary" checked={this.state.devote_time == "Part Time"} />} label="Part Time" />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-md-6">
                      <RadioGroupOperate
                        required
                        name="operate_business"
                        value={operate_business}
                        label="How do you operate your business?"
                        onChange={this.onChange}
                        items={businessOperate}
                      />
                    </div>
                    <div className="col-md-6 p-0">
                      <div className="col-md-12">
                        <FormLabel component="legend">Do you have a team ? <span className="Astrik">*</span></FormLabel>
                      </div>
                      <RadioGroup row aria-label="" className="W-100" name="have_team" onChange={this.onChange}>
                        <div className=" col-6">
                          <FormControlLabel value="1" control={<Radio color="primary" checked={this.state.have_team == 1} />} label="Yes" />
                        </div>
                        <div className="col-6">
                          <FormControlLabel value="0" control={<Radio color="primary" checked={this.state.have_team == 0} />} label="No" />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextField
                        value={this.state.total_experience}
                        id="total_experience"
                        name="total_experience"
                        type="number"
                        onChange={this.onChange}
                        label="Total Experience In Years"
                        inputProps={{
                          min: "0"
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextField
                        id="pincode"
                        name="pincode"
                        value={this.state.pincode}
                        label="Pin Code"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-md-6 p-0">
                      <div className="col-md-12">
                        <FormLabel component="legend">Do you currently own a real estate business?<span className="Astrik">*</span></FormLabel>
                      </div>
                      <RadioGroup row aria-label="" name="own_state_business" onChange={this.onChange}>
                        <div className="col-6">
                          <FormControlLabel value="1" control={<Radio color="primary" checked={this.state.own_state_business == true} />} label="Yes" />
                        </div>
                        <div className="col-6">
                          <FormControlLabel value="0" control={<Radio color="primary" checked={this.state.own_state_business == false} />} label="No" />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-md-6 mb-3">
                      <FormControl>
                        <InputLabel id="secondary-business">What platform are you present in?</InputLabel>
                        <Select
                          labelId="operate-business"
                          id="operate-checkbox"
                          multiple
                          name="plateform_present_in"
                          value={this.state.plateform_present_in}
                          onChange={this.handleMultiSelect}
                          input={<Input id="operate-multiple-chip" />}
                          renderValue={selected => (
                            <div>
                              {selected.map(value => (
                                <Chip key={value} label={value} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {operate.map(item => (
                            <MenuItem key={item.id} value={item.name}>
                              <Checkbox checked={this.state.plateform_present_in.indexOf(item.name) > -1} />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                      <FormControl>
                        <InputLabel id="generate-business">How do you Generate Leads ?</InputLabel>
                        <Select
                          labelId="generate-business"
                          id="generate-checkbox"
                          multiple
                          name="genrate_leads"
                          value={this.state.genrate_leads}
                          onChange={this.handleMultiSelect}
                          input={<Input id="generate-multiple-chip" />}
                          renderValue={selected => (
                            <div>
                              {selected.map(value => (
                                <Chip key={value} label={value} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {leads.map(item => (
                            <MenuItem key={item.id} value={item.name}>
                              <Checkbox checked={this.state.genrate_leads.indexOf(item.name) > -1} />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <FormControl>
                        <InputLabel id="Alternate-business">Alternate business with us</InputLabel>
                        <Select
                          labelId="Alternate-business"
                          id="Alternate-checkbox"
                          multiple
                          name="alternate_business_us"
                          value={this.state.alternate_business_us}
                          onChange={this.handleMultiSelect}
                          input={<Input id="Alternate-multiple-chip" />}
                          renderValue={selected => (
                            <div>
                              {selected.map(value => (
                                <Chip key={value} label={value} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {alternateBusiness.map(item => (
                            <MenuItem key={item.id} value={item.name}>
                              <Checkbox checked={this.state.alternate_business_us.indexOf(item.name) > -1} />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}
                    <div className="col-md-6 p-0">
                      <div className="col-md-12">
                        <FormLabel component="legend">Would you be interested in Listing of Properties?<span className="Astrik">*</span></FormLabel>
                      </div>
                      <RadioGroup row aria-label="" name="list_properties" onChange={this.onChange}>
                        <div className="col-6">
                          <FormControlLabel value="1" control={<Radio color="primary" checked={this.state.list_properties == 1} />} label="Yes" />
                        </div>
                        <div className="col-6">
                          <FormControlLabel value="0" control={<Radio color="primary" checked={this.state.list_properties == 0} />} label="No" />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-md-6 mb-3">
                      <FormControl>
                        <InputLabel required>Type of Real Estate Business you perform</InputLabel>
                        <Select
                          multiple
                          name="deal_business_types"
                          value={this.state.deal_business_types}
                          onChange={this.handleMultiSelect}
                          input={<Input id="Alternate-multiple-chip" />}
                          renderValue={selected => (
                            <div>
                              {selected.map(value => (
                                <Chip key={value} label={value} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {resaleRental.map(item => (
                            <MenuItem key={item.id} value={item.name}>
                              <Checkbox checked={this.state.deal_business_types.indexOf(item.name) > -1} />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Autocomplete
                            multiple
                            getOptionSelected={(region, value) =>
                                region.name === value.name
                            }
                            getOptionLabel={region => region.name}
                            options={region_option}
                            value={selectedRegionVal}
                            renderTags={(value, getTagProps) =>
                                value.map((region, index) => (
                                    <Chip
                                        label={region.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            onChange={(_event, region) => {
                                let regionIds = region.map(ops => ops.id);
                                this.setState({
                                    parent_region_ids: regionIds,
                                    selectedRegionVal: region
                                });

                                if (region === null) {
                                    this.setState([]);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required
                                    label="Select the (multiple) regions you are active in"
                                    fullWidth
                                    onChange={this.onChange}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextField
                        required
                        id="office_location"
                        name="office_location"
                        value={this.state.office_location}
                        label="Office Location"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
												<SelectOption
													variant="standard"
													required
													name="work_in_region"
													options={region_option}
													value={work_in_region}
													label="Parent Region"
													onChange={this.onChange}
												/>
											</div>
                    {this.state.role === 'admin' || this.state.role === 'magnetadmin' || this.state.role === "magnetpnl" ?
                      <Frag>
                        <div className="col-md-6 mb-3">
                          <TextField
                            value={this.state.upload_limit}
                            id="upload_limit"
                            name="upload_limit"
                            type="number"
                            onChange={this.onChange}
                            label="Daily Lead Limit"
                            inputProps={{
                              min: "0"
                            }}
                            
                          />
                        </div>
                        <div className="col-sm-6 px-0">
                          <div className="form-group">
                            <FormControlLabel
                              value={`${this.state.is_active}`}
                              checked={this.state.is_active}
                              name="is_active"
                              control={<Switch color="primary" />}
                              label="User Status"
                              labelPlacement="top"
                              onChange={this.handleActiveChange}
                            />
                          </div>
                        </div>
                      </Frag>
                      :
                      ''
                    }
                    {magnetCallRoles.includes(window.$user.role) &&
                        <>
                        <div className="col-sm-6 mb-3">
                          <FormControl error={this.state.errors.assign_rm_id !== '' && true}>
                            <Autocomplete
                              id="asynchronous-demo"
                              getOptionSelected={(service_rm, value) => service_rm.name === value.name}
                              getOptionLabel={service_rm => service_rm?.name ? service_rm.name + " (" + service_rm.phone + ")" : ""}
                              options={service_rm}
                              loading={isLoading}
                              value={selectedVal}
                              onChange={(_event, service_rm) => {
                                if (service_rm === null) {
                                  this.setState({ service_rm: [] })
                                } else {
                                  this.setState({
                                    assign_rm_id: service_rm.id,
                                    service_rm: service_rm,
                                    selectedVal: {name: service_rm.name, id: service_rm.id, phone: service_rm.phone}
                                  })
                                }
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Select Service RM"
                                  fullWidth
                                  onChange={this.handleAutoChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <BusinessRoundedIcon />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <React.Fragment>
                                        {isLoading ? (
                                          <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    )
                                  }}
                                />
                              )}
                            />
                            {this.state.errors.assign_rm_id !== '' &&
                              <FormHelperText>{this.state.errors.assign_rm_id}</FormHelperText>
                            }
                          </FormControl>
                        </div>
                        <div className="col-sm-6 px-0">
                          <div className="form-group">
                            <FormControlLabel
                              value={servicerm_assign}
                              checked={servicerm_assign}
                              name="servicerm_assign"
                              control={<Switch color="primary" />}
                              label="Self Assign"
                              labelPlacement="top"
                              onChange={this.handleserviceRmChange}
                            />
                          </div>
                        </div>
                        </>
                        }
                  </div>
                </Frag>
                : ''
              }

              <div className="row">
                <div className="col-md-12 text-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <ChangeUserPassModal show={this.state.changePassModalShow} changeModal={this.handleModal} fromType="changePass" />
          <PopUpAlert type={alertType} msg={alertMsg} open={alertShow} handleClose={this.handleClose} hideTime={6000} />
        </Container>
      </React.Fragment>

    );

  }
}

export default withRouter(UpdateCpUserInfo);