import {post,get,put,registerPost,registerPatch} from "../utils/methods.js";


export const getUserInfoData = async (value) =>{

	let AddUserData = await registerPatch('/magnet/details/'+value.id.toString(),value.data)
	return  AddUserData;

}

export const userRegister = async (value) =>{


	let commentData = await post('/interested_magnets/register',value)
	return  commentData;

}

export const getEmailTamplateData = async (value) =>{


	let getEmailTamplate = await post('/sendemail',value)
	return  getEmailTamplate;

}

export const getEmailValidation = async (value) =>{


	let getEmailValidationData = await put('/interested_magnets/email-verify/'+value)
	return  getEmailValidationData;

}

export const getFromStage = async (value) =>{


	let getFromStageData = await get('/magnet/status/'+value);
	return  getFromStageData;
}

export const getReferalUser = async (value) =>{


	let getReferalUserData = await get('/referrals', value);
	return  getReferalUserData;
}
