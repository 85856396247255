import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { REVERT_ROLES } from '../../../config/constant';
import { autoComplete } from "../../../dataParser/commomDataApi";
import { assignLeads } from "../../../dataParser/getListData";

class CrossSaleLeadForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: this.props.leadId,
			rm_name: [],
			assign_rm_id: '',
			comment: '',
			sweetShow: false,
			type: "success",
			title: "",
			isError: false,
		};
	}

	componentDidMount() {
		this.setState({
			role: window.$role,
			group: window.$user.group.toLowerCase(),
		});
	}

	handleCrossLead = async (e) => {

		e.preventDefault();

		if (!!(this.state.assign_rm_id) && !!(this.state.id)) {

			let mainData = {};
			mainData["ids"] = [this.state.id];
			mainData["assign_rm_id"] = parseInt(this.state.assign_rm_id);
			mainData["type"] = "cross";
			if (this.state.comment.trim().length) mainData["comment"] = this.state.comment;

			var addLeadRes = await assignLeads(mainData);
			if (addLeadRes.meta.status === 201) {
				this.setState({
					sweetShow: true,
					type: "success",
					title: "Lead assigned successfully!!!"
				});
			}else if (addLeadRes.meta.status === 422) {
                this.setState({
                    sweetShow: true,
                    type: "warning",
                    title: addLeadRes.meta.message,
                });
            } else {
				this.setState({
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});
			}
		}
		else {
			this.setState({ isError: true });
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	AutoUserChangeUser = async (e) => {
		this.setState({ isLoading: true });

		(async () => {
			try {
				let data = {
					q: e.target.value,
					region_id : window.$user.region_id,
					type : 'cross_sale_lead',
					roles: [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.CLOSING_S1]
				};
				let resData = await autoComplete('users', data);
				await this.setState({
					rm_name: resData.data,
					isLoading: false
				})
			} catch (e) {

			}
		})();
	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();
	}

	render() {

		const { sweetShow, type, title, rm_name, isLoading } = this.state;

		return (
			<Frag>
				<div className="formContainer">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row mb-3">
								<div className="col-md-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(rm_name, value) => rm_name.name === value.name}
										getOptionLabel={rm_name => rm_name.name}
										options={rm_name}
										loading={isLoading}
										onChange={(_event, rm_name) => {
											if (rm_name === null) {
												this.setState({ rm_name: [] })
											} else {
												this.setState({
													assign_rm_id: rm_name.id,
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												required
												label="Cross Sale RM"
												fullWidth
												onChange={this.AutoUserChangeUser}
												helperText={this.state.isError !== '' ? this.state.isError : ''}
											/>
										)}
									/>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<TextField
											id="standard-multiline-flexible"
											label="Comments"
											name="comment"
											multiline
											value={this.state.comment}
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.handleCrossLead}>Assign</button>
									</div>
								</div>
								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(CrossSaleLeadForm);
