import React, { useState } from 'react'
import { campaignType } from '../datas/data'
import { MyModal } from '../modal/MyModal'
import InputField from '../controls/InputField'
import PopUpAlert from '../../common/sweetAlert/popUpAlert'
import Loader from '../loader/loader'
import { getAddUpdateFocusProject } from '../../../dataParser/getProjectData'
import { Payment } from './Payment'
import Frag from '../../../utils/Frag/Frag'
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core'
import { InvestNow } from '../Button'
import { autoComplete } from '../../../dataParser/commomDataApi'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const DigitalForm = ({ show, changeModal, fullDetail, handleProjectData, digiRestType }) => {
    const [state, setState] = useState({
        campaign_type_id: [],
        amount: "",
        open: false,
        showLoader: false,
        paymentShow: false,
        payment_url: null,
        project: [],
        project_id: null,
        selectedVal: {}
    })

    const { campaign_type_id, amount, msg, open, alertType,
        showLoader, paymentShow, payment_url, project, project_id, selectedVal } = state;

    const handleSubmit = () => {
        if (campaign_type_id && campaign_type_id.length > 0 && !!amount && (fullDetail?.id ? !!fullDetail?.id : !!project_id)) {
            handleDigiInvest(fullDetail.id, campaign_type_id, amount, digiRestType)
        }
        else {
            setState({
                ...state,
                alertType: "error",
                open: true,
                msg: "Above all fields are required !"
            })
        }
    }
    const handleChange = e => {
        let { name, value } = e.target;
        if (name === "amount") {
            value = value.replace(/\D/g, "")
        }
        setState({
            ...state,
            [name]: value
        })
    }
    const handleClose = () => {
        setState({
            ...state,
            open: false
        })
    }
    const handleDigiInvest = async (id, campaign_type_id, amount, type) => {
        // debugger;
        setState({ ...state, showLoader: true });
        let { first_name, last_name } = window?.$user;
        let name = `${first_name} ${last_name}`;
        let mainData = {};
            if (type == 'interest') {
                mainData["is_interested"] = 1;
            }
            else{
                mainData["invest"] = 1;
            }
            if (campaign_type_id.length > 0) { mainData["campaign_type_id"] = campaign_type_id };
            if (!!amount) { mainData["amount"] = amount };
            if(!!id){
                mainData["project_id"] = id;
            }
            else if(!!project_id){
                mainData["project_id"] = project_id;
            }
            mainData["name"] = name.toUpperCase();
            
            const resData = await getAddUpdateFocusProject(mainData);
            setState({ ...state, showLoader: false });
            if (resData.meta.status == 201) {
                setState({
                    ...state,
                    showLoader: false,
                    payment_url: resData.data.payment_url,
                })
                changeModal();
            } else if (resData.meta.status == 200) {
                setState({
                    ...state,
                    showLoader: false,
                open: true,
                alertType: "success",
                msg: "Updated Successfully!!!"
            })
        } else {
            setState({
                ...state,
                showLoader: false,
                alertType: "error",
                open: true,
                msg: "Something went wrong!!!"
            })
        }

    }
    const handlePayment = () => {
        setState({ paymentShow: !paymentShow });
        handleProjectData && handleProjectData();
    }

	const handleAutoChangeProject = async (e) => {
		(async () => {
			try {
				let data = {
					q: e.target.value,
                    is_adwords: 1,
                    is_focus: 1,
                    is_active: 1
				};
				let resData = await autoComplete('projects', data);
				await setState({
                    ...state,
					project: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}
    return (
        <Frag>
            <MyModal title="Digital Campaign" size="md" show={show} changeModal={changeModal}>
                <div className="row">
                    <Loader show={showLoader} />
                    {/* <SweetAlert show={sweetShow} type={type} title={title} changeSweet={handleSweet} /> */}
                    <PopUpAlert type={alertType} msg={msg} open={open} handleClose={handleClose} hideTime={6000} />
                    { !!fullDetail?.id ?
                    <div className="col-12 mb-3">
                        <InputField
                            label="Project Name"
                            value={fullDetail?.name}
                            disabled={true}
                        />
                    </div>
                    :
                    <div className="col-12 mb-3">
                        <FormControl variant="outlined">
                            <Autocomplete
                                getOptionSelected={(project, value) => project.name === value.name}
                                getOptionLabel={project => project.name}
                                options={project}
                                value={selectedVal}
                                onChange={(_event, project) => {
                                    if (project === null) {
                                        setState({
                                            ...state,
                                            project: [] })
                                    } else {
                                        setState({
                                            ...state,
                                            project_id: project.id,
                                            selectedVal: { name: project.name, id: project.id }
                                        });
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Project Name"
                                        fullWidth
                                        onChange={handleAutoChangeProject}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </div>  
                    }  
                    <div className="col-12 mb-3">
                        <FormControl variant="outlined">
                            <InputLabel>Campaign Type</InputLabel>
                            <Select
                                multiple
                                name="campaign_type_id"
                                value={campaign_type_id ? campaign_type_id : []}
                                onChange={handleChange}
                                inputProps={{
                                    name: "campaign_type_id",
                                    id: "campaign_type_id"
                                }}
                            >
                                {campaignType.map((reason, i) => (
                                    <MenuItem key={i} value={reason.id}>
                                        {reason.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-12 mb-3">
                        <InputField
                            label="Amount"
                            name="amount"
                            value={amount}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-12 mb-3">
                        <InvestNow onClick={handleSubmit} />
                    </div>
                </div>
            </MyModal>
            <Payment show={!!payment_url ? true : false} changeModal={handlePayment} payment_url={payment_url} />
        </Frag>
    )
}