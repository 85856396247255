import React from 'react';
import Box from '@material-ui/core/Box';
import './SocialBlockGroup.sass';
import clsx from 'clsx';
import IconButton, {WhatsAppIcon, CallIcon, ChatBubbleIcon, HeadsetMicIcon  } from './Icons';
import Menu from './Menu';
import MenuItem from './MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {UserPortContext} from './Providers';
import { getCallData } from "../dataParser/getListData";
import PopUpAlert from "../component/common/sweetAlert/popUpAlert";
import { LeadUpdateModal } from '../component/common/modal/leadUpdateMOdal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit} from "@fortawesome/free-solid-svg-icons";

const defaultState = {
    type: 'success',
    open: false,
    msg: ''
}

const callRoles = ["admin", "presalehead", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "presalestl"];

const getNumber = (data) => {
        let number = (data.Client !== null && data.Client?.country_code !== null && data.Client?.country_code !== '' ? (data.Client?.country_code.includes('+') ? data.Client?.country_code : '+' + data.Client?.country_code) : '+91') + ' ' + (data.Client !== null && data.Client?.number)
        return number
    }

const SocialBlockGroup = props => {
    const role = window.$user.role;
    const {active, pushChange, phone, lead, makeApiCalls} = {...props}

    const [state, setState] = React.useState({
        ...defaultState
        , active
        , lead
        , currentActive: ''
    });

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = (event) => {
        setActive(event, null);
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if(!state.currentActive){
            return
        }

		if(pushChange){
			pushChange(state.currentActive)
            setState({...state, currentActive: ''})
        }
    }, [state.currentActive])

    React.useEffect(() => {
        let number = getNumber(lead).split(" ").join("")
		setState({...state, number: number, lead: lead })
    }, [lead])

    const matches = React.useContext(UserPortContext)

	if(["magnet", "magnettl", "magnetrm", "mediamanager"].includes(window.$user.role)){
		return <></>
	}

    const handleMenu = e => {
        setAnchorEl(e.currentTarget);
    }

    const setActive = (e, active) => {
        if(active === null){
            setAnchorEl(null);
        }else if(matches !== "xs"){
            setAnchorEl(e.currentTarget);
        }
        setState({...state, active})
    }

    const handleCall = async () => {
        let number = getNumber(lead).split(" ").join("")

        if (['admin', 'presale'].indexOf(window.$user.group.toLowerCase()) !== -1){
            const agent_number = window.$user.phone ? '+91' + window.$user.phone : '9111111111';
            const object_id= null;
            const user_id= window.$user.id;

            let data = {}

            if (agent_number !== '') {
                data["agent_number"] = agent_number
            }
            data["customer_number"] = number
			data["object_id"] = state.lead.id
			data["type"] = 1
			data["user_id"] = user_id
			data['stage_id'] = state.lead.ObjectStage.stage_id;
            data["calling_type"] = "SALES";

            let resData = await getCallData(data)
            if (resData.meta.status === 201) {
                let message = resData.data?.message
                setState({
                    ...state,
                    open: true,
                    type: message.includes('Failed') ? 'warning' : "success",
                    msg: resData.data?.message,
                });
            } else {
                setState({
                    ...state,
                    open: true,
                    type: "error",
					msg: resData.meta.message,
				});
			}
		}
	}


	if(matches === "xs"){
		return<> <Box className="SocialBlockGroup" align="center" position="fixed" bottom={16} left={16} zIndex={99}>
			<IconButton onClick={handleMenu} className="corner-button">
				<ChatBubbleIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<Box className="SocialBlockGroup-Menu">
					<MenuItem className="button" variant="contained">
						<IconButton 
							href={`https://api.whatsapp.com/send?phone=${state.number}&text=hi!`} 
							className='whatsapp-icon icon-ph'
						>

							<WhatsAppIcon />
						</IconButton>
					</MenuItem>
					{(['admin', 'presale'].indexOf(window.$user.group.toLowerCase()) !== -1) && <MenuItem className="button" variant="contained">
						<IconButton
							onClick={handleCall}
							className='m-call icon-ph'>

							<HeadsetMicIcon />
						</IconButton>
					</MenuItem>}
					<MenuItem className="button" variant="contained">
						<IconButton
							href={`tel:${state.number}`} 
							className='call icon-ph'
						>

							<CallIcon />
						</IconButton>
					</MenuItem>
					{ ["mediamanagers", "customerexperiencemanager", "operationsanalyst", "operationshead", "magnet"].indexOf(window.$user.role) == -1 ?  <MenuItem className="button" variant="contained">
						<IconButton onClick={e => {setActive(e, 'UpdateLead'); setAnchorEl(null); }} className={clsx('call', 'icon-ph', {['active']: state.active === 'UpdateLead'})}>
							<FontAwesomeIcon icon={faEdit} className="text-white" />
						</IconButton>
					</MenuItem> : <></>}
				</Box>
			</Menu>
		</Box>
			<PopUpAlert type={state.type} msg={state.msg} open={state.open} handleClose={() => setState({...state, open:false })} hideTime={6000} />
			{state.active === "UpdateLead" && <LeadUpdateModal 
				lead_id={lead.id}
				show={state.active === "UpdateLead"}
				onHide={() => setState({...state, active: ""})}
				onSuccess={() => {makeApiCalls(); setState({...state, active: ""})}}
				formState="1"
			/>}
			</>
    }

    return <>
            <div>
                <Box className="SocialBlockGroup" align="center" px={4} py={1}>
                    <Box display="flex">
                        <Box mr={2}>
								<IconButton target="_blank" href={`https://api.whatsapp.com/send?phone=${state.number}&text=Hi!`} className={clsx('whatsapp-icon', 'icon-ph', {['active']: state.active === 'WhatsApp'})} onClick={(e) => setActive(e, 'WhatsApp')} ><WhatsAppIcon />
								</IconButton>
                        </Box>
                        {(['admin', 'presale'].indexOf(window.$user.group.toLowerCase()) !== -1) && <Box mr={2}>
								<IconButton 
									className={clsx('m-call', 'icon-ph', {['active']: state.active === 'Call'})} 
									onClick={handleCall}>

									<HeadsetMicIcon />
								</IconButton>
                        </Box>}
                        <Box mr={2}>
								<IconButton href={`tel:${state.number}`} className={clsx('call', 'icon-ph', {['active']: state.active === 'Call'})}><CallIcon /></IconButton>
                        </Box>
						{ ["mediamanagers", "customerexperiencemanager", "operationsanalyst", "operationshead", "magnet"].indexOf(window.$user.role) == -1 
								&& lead.ObjectStage?.Stage?.name?.toLowerCase() !== 'closed' ? <Box>
								<IconButton onClick={e => setActive(e, 'UpdateLead')} className={clsx('call', 'icon-ph', {['active']: state.active === 'UpdateLead'})}>
									<FontAwesomeIcon icon={faEdit} className="text-white" />
								</IconButton>
								</Box> : <></>
						}
                    </Box>
                </Box>
                <PopUpAlert type={state.type} msg={state.msg} open={state.open} handleClose={() => setState({...state, open:false })} hideTime={6000} />
            </div>
		{state.active === "UpdateLead" && <LeadUpdateModal 
			lead_id={lead.id}
			show={state.active === "UpdateLead"}
			onHide={() => setState({...state, active: ""})}
			onSuccess={() => {makeApiCalls(); setState({...state, active: ""})}}
			formState="1"
		/>}
    </>
}


export default SocialBlockGroup;
