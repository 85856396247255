import React from "react";
import { Route, Redirect } from "react-router-dom";
import { user_access } from "../config/config";

export const PrivateRoute = ({ component: Component, type, ...rest }) => {
  let role = window?.$user?.role;
  let ftype = user_access.filter(userData => userData.name == "Dashboard")
  if (type === ftype[0].name && !ftype[0].user_role.includes(role)) {
    if (role === "admin" || role === "magnetplatinum") {
      return <Redirect to="/leads/new" />;
    } else if (role === "hrhead") {
      return <Redirect to="/users" />;
    }else if (role === "financehead" || role === "financeanalyst" || role === "financeassociate" || role === "operationshead") {
      return <Redirect to="/leads/booked" />;
    } else if (role === "operationsanalyst") {
      return <Redirect to="/leads/audit" />;
    }
  }
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("homesfy_lg") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: props.location }} />
        )
      }
    />
  )
};
