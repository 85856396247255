import React from 'react';

import BoxComponent from '@material-ui/core/Box';

import './Box.sass'
import clsx from 'clsx';


const defaultState = {
}


const Box = props => {
	const {children , ..._props } = props;
	return <BoxComponent className="Box" {...props} />
}

export default Box;
