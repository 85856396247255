import React, { Component } from 'react';
import AutoAssignModel from "../../component/common/modal/autoAssignModel.js";
import Footer from "../../component/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import AutoAssignFilter from "../filter/autoAssignFilter.js";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Pagination from "react-js-pagination";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SelectOption from '../common/controls/SelectOption';
import RadioGroup from '../common/controls/RadioGroup';
import { leadType, national } from '../common/datas/data';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from '@material-ui/core/Paper';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Button } from '@material-ui/core';
import { getLeadSourceData } from "../../dataParser/commomDataApi.js";
import AddRmModal from '../common/modal/addRmModal';
import { getAllProjectData } from '../../dataParser/getProjectData';
import { deleteAutoAssign, getAutoAssign } from '../../dataParser/getAutoAssignData';
import Loader from "../../component/common/loader/loader";
import { HomesfyRM, MagnetRM } from './AutoAssignRM';
import SweetWarnAlert from '../common/sweetAlert/sweetAlertWarning';
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import { cleanStorage } from '../../helper/index.js';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textTransform: "capitalize"
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const ReverseBuildOutlinedIcon = withStyles((theme) => ({
    root: {
        transform: theme.direction === "ltr" ? "scaleX(-1)" : undefined,
        marginLeft: "1em"
    }
}))(BuildOutlinedIcon);

class AutoAssignList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideBar: false,
            meta: props.userDetail.meta,
            data: props.userDetail.data,
            mobileSideBar: false,
            changeUserPassModal: false,
            showFilter: false,
            activePage: 1,
            sweetShow: false,
            sweetWarnShow: false,
            sweetType: "success",
            title: "",
            listDetail: -1,
            source: [],
            source_id: '',
            source_name: '',
            rmModalShow: false,
            auto_assign_data: [],
            type: "assign",
            alertType: "success",
            nationality: 1,
            open: false,
            msg: '',
        };

    }

    componentDidUpdate(nextProps) {
        // console.log('componentWillReceiveProps xxxxxxxxxx', nextProps.userDetail.data);
        if (this.props !== nextProps) {
            // this.setState({nextProps});
            this.setState({
                meta: nextProps.userDetail.meta,
                // data: nextProps.userDetail.data["auto_assign_list"],
                data: nextProps.userDetail.data,
                activePage: 1,
            });

            // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx',nextProps);
        }
    }

    async componentDidMount() {
        this.update();
    }

    handleModal = (id, project_name, project_id, is_magnet, source_name, source_id, assign_rm, assign_rm_id, nationality, region_id, type) => {
        let formData = {};
        formData.id = id;
        formData.project_name = project_name;
        formData.project_id = project_id;
        formData.is_magnet = is_magnet;
        formData.source_id = source_id;
        formData.source_name = source_name;
        formData.assign_rm_id = assign_rm_id;
        formData.assign_rm = assign_rm;
        formData.nationality = nationality;
        formData.region_id = region_id;
        formData.type = type;
        // console.log(formData);
        this.setState({
            modalShow: !this.state.modalShow,
            fullDetail: formData
        });
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };

    changeButtonState(event) {
        this.setState({ sideBar: !this.state.sideBar })
    }

    changeSideBarState(event) {
        this.setState({ mobileSideBar: !this.state.mobileSideBar })
    }

    handleSweet = () => {
        this.setState({ sweetShow: !this.state.sweetShow });
    }

    handlePopUp = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter,
            listDetail: -1
        }));
    }

    handleAddTempCp = () => {
        this.setState({ changeUserPassModal: !this.state.changeUserPassModal });
    }

    genericGetListData = async (params) => {
        this.setState({ showLoader: true, });

        // console.log("this.state.filters",this.state.filters);

        (async () => {
            try {
                let resData = await getAllProjectData(params);
                if (resData.meta.status === 200) {

                    await this.setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false,
                    });
                    window.scrollTo(0, 0);

                } else if (resData.meta.status == 401) {
                    cleanStorage();
                    this.props.history.push("/login");
                } else {
                    this.setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();

    }

    handleFilter = async (filterData) => {
        this.setState({ showLoader: true })
        this.setState({ filters: filterData, activePage: 1 })
        let listData = {};
        listData.size = 8;
        listData.pageId = 1;
        listData.is_active = 1;
        Object.assign(listData, filterData)

        this.genericGetListData(listData);

    }

    handleUpdateFilter = async () => {
        this.setState({
            showLoader: true,
        })
        let listData = {};
        listData.size = 8;
        listData.pageId = this.state.activePage;
        listData.is_active = 1;
        Object.assign(listData, this.state.filters);

        this.genericGetListData(listData);
    }

    handleChangePage = async (pageNumber) => {
        this.setState({
            showLoader: true,
            activePage: pageNumber,
            listDetail: -1
        })
        var listData = {};
        listData.size = 8;
        listData.pageId = pageNumber;
        listData.is_active = 1;
        Object.assign(listData, this.state.filters)

        this.genericGetListData(listData);
    }

    detailView(id) {
        if (this.state.listDetail == id) {
            this.setState({ listDetail: null })

        } else {
            this.setState({ listDetail: id })
        }
    }

    handleRmModal = (i, is_magnet, project_id, source_id, nationality, type) => {
        let formData = {};
        formData.project_id = project_id;
        formData.source_id = source_id;
        formData.nationality = nationality;
        formData.type = type;
        formData.created_by = window.$user.id
        this.setState({
            rmModalShow: !this.state.rmModalShow,
            index: i,
            is_magnet: is_magnet,
            projectInfo: {...formData},
        }
        )
    }
    valChange = async (e, i, project_id) => {
        const { name, value } = e.target;
        await this.setState({ [name]: value })
        if (['nationality', 'type'].includes(name)) {
            this.getDetails(project_id, i)
        }

    }

    handleAddInput = (e) => {
        const auto_assign_data = [...this.state.auto_assign_data];
        let len = auto_assign_data.length
        let prev = len - 1
        if(auto_assign_data[prev].AutoAssigns.length > 0){
            auto_assign_data.push({ Source: {}, AutoAssigns: [] });
            this.setState({ auto_assign_data: auto_assign_data, source_id: '' });
        }
        else {
            this.setState({
                open: true,
                alertType: "error",
                msg: "Please fill earlier details to add more",
            });
        }
    }
    sentData = (val) => {
        console.log(val);
    }

    getDetails = async (id, i) => {
        const { nationality, type, filters } = this.state
        this.setState({ showLoader: true })
        let q = {
            project_id: id, nationality: nationality, type: type
        }
        if(filters?.assign_to){
            q.assign_to = filters.assign_to;
        }
        let getData = await getAutoAssign(q)
        if (getData.meta.status === 200) {
            let data = getData.data.AutoAssigns
            data.length === 0 && data.push({ Source: {}, AutoAssigns: [] });

            const allSourceData = await getLeadSourceData();
            let source1 = allSourceData.data;
            let source2 = data.map(s=>{
                if(s.Source.length !== 0){
                    s.Source.isDisabled = true
                }
                return s.Source
            })
            let result = source1.map(item => ({ ...item, ...source2.find(s => s.id === item.id) }));
            this.setState({
                auto_assign_data: data,
                showLoader: false,
                source: result
            })
        }
        else {
            this.setState({
                auto_assign_data: [{ Source: {}, AutoAssigns: [] }],
                source_id: "",
                showLoader: false
            })
        }
    }

    handleSweetWarn = (id, source_id, i) => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow,
            deleteRow: [id, source_id, i]
        });
    };

    handleUpdateUser = () => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow
        });
        this.deleteRow(this.state.deleteRow);
    };

    deleteRow = async (row) => {
        this.setState({ showLoader: true })
        const { nationality, type } = this.state
        let data = {
            project_id: row[0], nationality: nationality, type: type, source_id: row[1]
        }
        let resData = await deleteAutoAssign(data)
        if (resData.meta.status === 200) {
            this.setState({
                showLoader: false,
                open: true,
                alertType: "success",
                msg: "Deleted Successfully !",
            });
        } else {
            this.setState({
                showLoader: false,
                open: true,
                alertType: "error",
                msg: resData.meta.message,
            });
        }
        this.getDetails(row[0], row[2])
    }

    handleClose = () => {
        this.setState({ open: false });
    }
    render() {

        const { meta, data, sweetShow, sweetWarnShow, sweetType, title, source, source_id, type, nationality, auto_assign_data, alertType, msg, open } = this.state;
        const { count, size } = this.state.meta;
        return (
            <div>
                <div className="content-wrapper">
                    <PopUpAlert type={alertType} msg={msg} open={open} handleClose={this.handleClose} hideTime={6000} />
                    <Loader show={this.state.showLoader} />
                    <SweetAlert show={sweetShow} type={sweetType} title={title} changeSweet={this.handleSweet} />
                    <SweetWarnAlert show={sweetWarnShow} changeSweet={this.handleSweetWarn} message="Want to delete!!!" handleUpdateUser={this.handleUpdateUser} />
                    <div className="p-3 leadMainHeader">
                        <div className="headerCount">
                            <span className="totalCount">Total Auto Assign Lead : </span>
                            <span className="totalCount">{meta.status === 200 ? count : 0}</span>
                        </div>
                        <div className="d-inline ml-3 filterHeader">
                            <span className="filter" onClick={this.handlePopUp}>
                                <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                            </span>
                            <div className={"filterMenu" + " " + (this.state.showFilter ? 'filterMenuShow' : '')} ref={node => { this.node = node; }}>
                                <AutoAssignFilter filterData={this.handleFilter} changeFilter={this.handlePopUp} magnetEnroll="enrollFilter" />
                            </div>
                        </div>
                    </div>
                    <div>
                        {meta.status === 200 && data.length ?
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((projectsList, index) =>
                                            <div className="MainList" key={projectsList.id}>
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-6 col-11">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <span className="valueText">Project Name: </span>
                                                                        <span className="text-capitalize valueText">{projectsList.name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row  align-items-center AutoAssign">
                                                                    <div className={"col-md-4" + " " + (this.state.listDetail === index ? "visible" : "invisible")}>
                                                                        <SelectOption
                                                                            name="type"
                                                                            value={type}
                                                                            options={leadType}
                                                                            onChange={(e) => this.valChange(e, index, projectsList.id)}
                                                                        />
                                                                    </div>
                                                                    <div className={"col-md-4 col-7" + " " + (this.state.listDetail === index ? "visible" : "invisible")}>
                                                                        <RadioGroup
                                                                            name="nationality"
                                                                            value={nationality}
                                                                            items={national}
                                                                            onChange={(e) => this.valChange(e, index, projectsList.id)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-1 col-5">
                                                                        <div style={{ cursor: "pointer" }} className="detailText col-md-12 d-flex viewMore align-items-center" onClick={() => this.detailView(index)}>
                                                                            {this.state.listDetail === index ?
                                                                                <div className="less_Detail">
                                                                                    <ExpandLessIcon fontSize="large" />
                                                                                </div>
                                                                                :
                                                                                <div className="" onClick={() => { this.detailView(index); this.getDetails(projectsList.id, index) }}>
                                                                                    <ExpandMoreIcon fontSize="large" />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"row align-items-center" + " " + (this.state.listDetail === index ? "show" : "hide")}>
                                                            <div className="col-md-12 pl-0 mb-3">
                                                                <TableContainer component={Paper}>
                                                                    <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableCell>Source</StyledTableCell>
                                                                                <StyledTableCell colSpan="2">Homesfy</StyledTableCell>
                                                                                <StyledTableCell colSpan="2">Magnet</StyledTableCell>
                                                                                <StyledTableCell>Action</StyledTableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {auto_assign_data.map((item, i) => (
                                                                                <TableRow key={i}>
                                                                                    <TableCell>
                                                                                        <SelectOption
                                                                                            options={source}
                                                                                            variant="outlined"
                                                                                            name="source_id"
                                                                                            value={item?.Source?.id ? item?.Source?.id : source_id}
                                                                                            onChange={this.valChange}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell colSpan="2">
                                                                                        <HomesfyRM data={item} />
                                                                                        {["financeanalyst","financeassociate","operationsanalyst","operationshead"].indexOf(window.$role) === -1 && <ReverseBuildOutlinedIcon
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => this.handleRmModal(i, false, projectsList.id, item?.Source?.id || source_id, nationality, type)}
                                                                                        />}
                                                                                    </TableCell>
                                                                                    <TableCell colSpan="2">
                                                                                        <MagnetRM data={item} />
                                                                                        {["financeanalyst","financeassociate","operationsanalyst","operationshead"].indexOf(window.$role) === -1 && <ReverseBuildOutlinedIcon
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => this.handleRmModal(i, true, projectsList.id, item?.Source?.id || source_id, nationality, type)}
                                                                                        />}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                    {item.AutoAssigns.length > 0 && ["financeanalyst","financeassociate","operationsanalyst","operationshead"].indexOf(window.$role) === -1 ?
                                                                                        <DeleteOutlinedIcon
                                                                                            className="cursor-pointer"
                                                                                            onClick={() => { this.handleSweetWarn(projectsList.id, item?.Source?.id, i) }}
                                                                                        />
                                                                                        :
                                                                                        <DeleteOutlinedIcon
                                                                                            className="cursor-notallowed"
                                                                                        />
                                                                                    }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow>
                                                                                <StyledTableCell colSpan="2">
                                                                                    {["financeanalyst","financeassociate","operationsanalyst","operationshead"].indexOf(window.$role) === -1 && <Button variant="contained"
                                                                                        style={{ backgroundColor: '#007bff', color: '#fff', margin: "1em .5em" }}
                                                                                        onClick={this.handleAddInput}>
                                                                                        Add More
                                                                                    </Button>}
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <span className="col-md-12 text-muted fs-14 text-center">No Data available !!!</span>
                            </div>
                        }
                    </div>
                </div>
                <Footer expand={this.state.sideBar} />
                <AddRmModal show={this.state.rmModalShow} changeModal={this.handleRmModal} isMagnet={this.state.is_magnet} getData={this.sentData} projectInfo={this.state.projectInfo} auto_assign_data={auto_assign_data} index={this.state.index} getDetails={this.getDetails} />
                <AutoAssignModel show={this.state.modalShow} getList={this.handleUpdateFilter} formData={this.state.fullDetail} fromType='updateAutoAssign' changeModal={this.handleModal} />
                <AutoAssignModel show={this.state.changeUserPassModal} getList={this.handleUpdateFilter} fromType='addAutoAssign' changeModal={this.handleAddTempCp} />

            </div>
        );

    }
}

export default AutoAssignList;