import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faFilter,
    faPhoneAlt
} from "@fortawesome/free-solid-svg-icons";
import { getbulkLeadDataList } from "../../dataParser/bulkLeadData";
import Loader from "../common/loader/loader";
import "./bulkList.css";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Pagination from "react-js-pagination";
import BulkLeadModal from "../../component/common/modal/BulkModal";
import BulkLeadFilter from "../../component/filter/bulkLeadFilter";
import { changeDate, cleanStorage } from "../../helper";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
class BulkLeadList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            filters: "",
            bulkId: "",
            listDetail: -1,
            showLoader: false,
            showFilter: false,
            sideBar: false,
            meta: [],
            data: [],
            sweetShow: false,
            type: "success",
            title: "",
            modalShow: false,
            addModalShow: false
        };
        window.addEventListener("resize", this.update);
    }

    async componentDidMount() {
        this.setState({
            showLoader: true
        });
        var bulkLeadData = {};
        bulkLeadData.size = 8;
        bulkLeadData.pageId = 1;

        var resData = {};
        resData = await getbulkLeadDataList(bulkLeadData);
        setTimeout(() => {
            if (resData.meta.status === 200) {
                this.setState({
                    showLoader: false,
                    meta: resData.meta,
                    data: resData.data["bulkLeads"],
                    listDetail: -1
                });
                window.scrollTo(0, 0);
            } else {
                this.setState({
                    showLoader: false
                });
            }
        }, 500);
        this.update();
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };



    detailView(id) {

        if (this.state.listDetail == id) {
            this.setState({ listDetail: null });
        } else {
            this.setState({ listDetail: id });
        }
    }

    genericGetListData = async params => {
        this.setState({ showLoader: true });

        let resData = await getbulkLeadDataList(params);
        setTimeout(() => {
            if (resData.meta.status == 200) {
                this.setState({
                    meta: resData.meta,
                    data: resData.data["bulkLeads"],
                    listDetail: -1,
                    showLoader: false
                });
                window.scrollTo(0, 0);
            } else if (resData.meta.status == 401) {
                cleanStorage();
                this.props.history.push("/login");
            } else {
                this.setState({
                    meta: resData.meta,
                    data: resData.data,
                    showLoader: false
                });
            }
        }, 500);
    };

    handleChangePage = async pageNumber => {
        this.setState({
            showLoader: true,
            activePage: pageNumber
        });
        var listData = {};
        listData.size = 8;
        listData.pageId = pageNumber;
        Object.assign(listData, this.state.filters);

        this.genericGetListData(listData);
    };

    handleFilter = async filterData => {
        this.setState({ showLoader: true });
        this.setState({ filters: filterData });
        let listData = {};
        listData.filters = [];
        listData.size = 8;
        listData.pageId = 1;
        Object.assign(listData, filterData);

        this.genericGetListData(listData);
    };

    handlePopUp = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    };

    handleAddBulkModal = (value) => {
        this.setState({ addModalShow: !this.state.addModalShow });
    }

    handleSweet = () => {
        this.setState({ sweetShow: !this.state.sweetShow });
    };

    handleModal = (
        id,
        project_name,
        username,
        mobile_number,
        project_id,
        city,
        source
    ) => {
        this.setState({
            modalShow: !this.state.modalShow,
            bulkId: id,
            leadType: project_name,
            userName: username,
            mobileNumber: mobile_number,
            projectId: project_id,
            city: city,
            source: source
        });
    };
    getNumber = data => {
        let number = (data.country_code
            ? (data.country_code.includes('+')
                ? data.country_code
                : '+' + data.country_code)
            : '+91') + ' ' + (data.phone);
        return number
    }
    render() {
        const { count, size, status, } = this.state.meta;
        const { data, type, title, sweetShow } = this.state;
        const isDesktop = this.state.screenWidth;
        const roles = ['admin', 'presaleshead', 'saleshead', 'pnlhead', 'salesmanager', 'salestl', 'seniorsalestl', 'presalestl'
            , 'sourcings2', "magnet s2", 'magnetadmin', "magnetmis", "magnetpnl", 'magnettl', 'magnetrm', 'magnet', "mediamanagers", "campaignmanagers"
            , "campaignoperations", "leadoperations", "operationshead", "operationsmanager"]
        return (
            <Frag>
                {isDesktop > 991 ? (
                    <div
                        className="content-wrapper">
                        <SweetAlert
                            show={sweetShow}
                            type={type}
                            title={title}
                            changeSweet={this.handleSweet}
                        />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total Bulk Leads : </span>
                                <span className="">{status === 400 ? "0" : count}</span>
                            </div>
                            <div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div
                                    className={
                                        "filterMenu" +
                                        " " +
                                        (this.state.showFilter ? "filterMenuShow" : "")
                                    }
                                    ref={node => {
                                        this.node = node;
                                    }}
                                >
                                    <BulkLeadFilter
                                        filterData={this.handleFilter}
                                        changeFilter={this.handlePopUp}
                                    />
                                </div>
                            </div>
                            {roles.includes(window.$role) ?
                                <AddCircleOutlineIcon className="addLeadIcon" onClick={this.handleAddBulkModal} />
                                : ''
                            }
                        </div>
                        <Loader show={this.state.showLoader} />
                        {status === 200 && data.length && data.length ? (
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((bulkData, index) => (
                                            <div
                                                className="MainList"
                                                key={index}
                                                onClick={() => this.detailView(index)}
                                            >
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">ID</span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.id !== null
                                                                                        ? bulkData.id
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">Name</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize  valueText">
                                                                                    {bulkData.name !== null
                                                                                        ? bulkData.name
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Email
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.email !== null
                                                                                        ? bulkData.email
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Mobile Number
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.phone !== null
                                                                                        ? bulkData.phone
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Project Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.Project !== null ? (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        {bulkData.Project.name !== null &&
                                                                                            bulkData.Project.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Region{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.Project !== null ? (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        {bulkData.Project.Region !== null &&
                                                                                            bulkData.Project.Region.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Builder Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.Project !== null ? (
                                                                                    <span className="text-capitalize valueText">
                                                                                        {bulkData.Project.builder_name !=
                                                                                            "" ||
                                                                                            bulkData.Project.builder_name != ""
                                                                                            ? bulkData.Project.builder_name
                                                                                            : "-"}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="valueText">-</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    City{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData?.Project?.Region?.city || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Closing Reason{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.closing_reason !== null
                                                                                        ? bulkData.closing_reason
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Status{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.is_moved !== null ? (
                                                                                    <span className="valueText">
                                                                                        {" "}
                                                                                        {bulkData.is_moved === 0
                                                                                            ? "Not-Activated"
                                                                                            : bulkData.is_moved === 1
                                                                                                ? "Activated"
                                                                                                : "Disqualify"}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="valueText">-</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Created Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.created_at !== null
                                                                                        ? changeDate(bulkData.created_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Updated Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.updated_at !== null
                                                                                        ? changeDate(bulkData.updated_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Lead ID{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.lead_id !== null
                                                                                        ? bulkData.lead_id
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Uploaded By
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData?.BulkLeadUploader && bulkData?.BulkLeadUploader
                                                                                        ? bulkData?.BulkLeadUploader?.name
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Comment{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.comment !== null
                                                                                        ? bulkData.comment
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div
                                                                className={
                                                                    "ShortContent" +
                                                                    " " +
                                                                    (this.state.listDetail === index
                                                                    ? "show"
                                                                    : "hide")
                                                                }
                                                                >
                                                                
                                                                </div> */}
                                                                {/* <div className="row">
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    className="detailText col-md-12 d-flex  justify-content-center align-items-center"
                                                                    onClick={() => this.detailView(index)}
                                                                >
                                                                    {this.state.listDetail === index ? (
                                                                    <div>
                                                                        <span className="">Less Details</span>{" "}
                                                                        <ExpandLessIcon />
                                                                    </div>
                                                                    ) : (
                                                                        <div>
                                                                        <span className="">More Details</span>{" "}
                                                                        <ExpandMoreIcon />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="action_box">
                                                            {bulkData.is_moved === null && (
                                                                <a
                                                                    className="ProjectDetail Action"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Update Bulk Lead"
                                                                    onClick={value =>
                                                                        this.handleModal(
                                                                            bulkData.id,
                                                                            bulkData.Project.name,
                                                                            bulkData.name,
                                                                            bulkData.phone,
                                                                            bulkData.project_id,

                                                                        )
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white"
                                                                    />
                                                                </a>
                                                            )}
                                                            <Tooltip title={this.getNumber(bulkData)}>
                                                                <a href={"tel:" + this.getNumber(bulkData)} className="callBG Action" target="_blank">
                                                                    <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="main_area_display shadow card p-3">
                                <h5>No Bulk Lead Found !!!</h5>
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className="content-wrapper">
                        <SweetAlert
                            show={sweetShow}
                            type={type}
                            title={title}
                            changeSweet={this.handleSweet}
                        />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total Bulk Leads : </span>
                                <span className="">{status === 400 ? "0" : count}</span>
                            </div>
                            <div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div
                                    className={
                                        "filterMenu" +
                                        " " +
                                        (this.state.showFilter ? "filterMenuShow" : "")
                                    }
                                    ref={node => {
                                        this.node = node;
                                    }}
                                >
                                    <BulkLeadFilter
                                        filterData={this.handleFilter}
                                        changeFilter={this.handlePopUp}
                                    />
                                </div>
                            </div>
                            {roles.includes(window.$role) ?
                                <AddCircleOutlineIcon className="addLeadIcon" onClick={this.handleAddBulkModal} />
                                : ''
                            }
                        </div>
                        <Loader show={this.state.showLoader} />
                        {status === 200 && data.length ? (
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((bulkData, index) => (
                                            <div
                                                className="MainList"
                                                key={index}
                                                onClick={() => this.detailView(index)}
                                            >
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">ID </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.id !== null
                                                                                        ? bulkData.id
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1 pr-0">
                                                                        <div className="row">
                                                                            <div className="col-md-12 pr-0 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Project Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                {bulkData.Project !== null ? (
                                                                                    <span className="text-capitalize valueText">
                                                                                        {bulkData.Project.name !== null &&
                                                                                            bulkData.Project.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">Name</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.name !== null
                                                                                        ? bulkData.name
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Email
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className=" valueText">
                                                                                    {bulkData.email !== null
                                                                                        ? bulkData.email
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Mobile Number
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.phone !== null
                                                                                        ? bulkData.phone
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Region{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                {bulkData.Project !== null ? (
                                                                                    <span className="text-capitalize valueText">
                                                                                        {bulkData.Project.Region !== null &&
                                                                                            bulkData.Project.Region.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1 pr-0">
                                                                        <div className="row">
                                                                            <div className="col-md-12 pr-0 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Builder Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12">
                                                                                {bulkData.Project !== null ? (
                                                                                    <span className="text-capitalize valueText">
                                                                                        {bulkData.Project.builder_name !=
                                                                                            "" ||
                                                                                            bulkData.Project.builder_name != ""
                                                                                            ? bulkData.Project.builder_name
                                                                                            : "-"}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    City{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.city !== null
                                                                                        ? bulkData.city
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Closing Reason{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.closing_reason !== null
                                                                                        ? bulkData.closing_reason
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-6 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Status{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                {bulkData.is_moved !== null ? (
                                                                                    <span className="valueText">
                                                                                        {" "}
                                                                                        {bulkData.is_moved === 0
                                                                                            ? "Not-Activated"
                                                                                            : bulkData.is_moved === 1
                                                                                                ? "Activated"
                                                                                                : "Disqualify"}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="valueText">-</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Comment{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText text-capitalise">
                                                                                    {bulkData.comment !== null
                                                                                        ? bulkData.comment
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Created Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.created_at !== null
                                                                                        ? changeDate(bulkData.created_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Updated Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.updated_at !== null
                                                                                        ? changeDate(bulkData.updated_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div
                                                                        className={
                                                                            "ShortContent" +
                                                                            " " +
                                                                            (this.state.listDetail === index
                                                                                ? "show"
                                                                                : "hide")
                                                                        }
                                                                    >
                                                                        <div className="row">
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-block">
                                                                        <div
                                                                            style={{ cursor: "pointer" }}
                                                                            className="detailText col-md-12 d-flex  justify-content-center align-items-center"
                                                                            onClick={() => this.detailView(index)}
                                                                        >
                                                                            {this.state.listDetail === index ? (
                                                                                <div>
                                                                                    <span className="">Less Details</span>{" "}
                                                                                    <ExpandLessIcon />
                                                                                </div>
                                                                            ) : (
                                                                                    <div>
                                                                                        <span className="">More Details</span>{" "}
                                                                                        <ExpandMoreIcon />
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="action_box">
                                                            {bulkData.is_moved === null && (
                                                                <a
                                                                    className="ProjectDetail Action"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Update Bulk Lead"
                                                                    onClick={value =>
                                                                        this.handleModal(
                                                                            bulkData.id,
                                                                            bulkData.Project.name,
                                                                            bulkData.name,
                                                                            bulkData.phone,
                                                                            bulkData.project_id,

                                                                        )
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white"
                                                                    />
                                                                </a>
                                                            )}
                                                            <Tooltip title={this.getNumber(bulkData)}>
                                                                <a href={"tel:" + this.getNumber(bulkData)} className="callBG Action" target="_blank">
                                                                    <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="main_area_display shadow card p-3">
                                <h5>No Bulk Lead Found !!!</h5>
                            </div>
                        )}
                    </div>
                )
                }

                <BulkLeadModal
                    getList={this.handleFilter}
                    show={this.state.modalShow}
                    fromType="updateBulkForm"
                    changeModal={this.handleModal}
                    bulkId={this.state.bulkId}
                    leadType={this.state.leadType}
                    userName={this.state.userName}
                    mobileNumber={this.state.mobileNumber}
                    projectId={this.state.projectId}
                />
                <BulkLeadModal getList={this.handleFilter} show={this.state.addModalShow} fromType="bulkLead" changeModal={this.handleAddBulkModal} />
            </Frag >
        );
    }
}

export default BulkLeadList;
