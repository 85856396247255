import React, { Component } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Modal } from "react-bootstrap";

import Loader from "../common/loader/loader";

import {
  indianRupeeCommaSeparated,
  removeCommas,
  roundToTwoDecimalPlaces
} from "../../helper";

import {
  getBookingIncentives,
  saveBookingIncentives
} from "../../dataParser/getListUserData";

import { AV_LIMIT_LOWER, AV_LIMIT_UPPER, BOOKING_AMOUNT_LIMIT, AV_VALIDATION_MSG_UPPER, BOOKING_AMOUNT_MSG, AV_VALIDATION_MSG_LOWER, GROUPS } from "../../config/constant";
import InputField from "../common/controls/InputField";
import Button from '@material-ui/core/Button'
import PopUpAlert from "../common/sweetAlert/popUpAlert";
import InputUnitField from "../common/controls/InputUnitField";

const FIRST_PAYMENT_CLIENT_PAYMENT_PERCENTAGE = 5;
const SECOND_PAYMENT_CLIENT_PAYMENT_PERCENTAGE = 10;
let FIRST_INCENTIVE_PERCENTAGE = 20;
let SECOND_INCENTIVE_PERCENTAGE = 20;
let THIRD_INCENTIVE_PERCENTAGE = 60;

// decimal value upto 2 places
const decimal_regex = /^\d*\.?\d{0,2}$/;
export default class BookingIncentive extends Component {
  constructor(props) {
    super();

    this.state = {
      showLoader: true,
      firstIncentive: {
        amount: 0,
        paidOn: null,
        spot: 0,
        spotOn: null,
        comment: ""
      },
      
      secondIncentive: {
        amount: 0,
        paidOn: null,
        spot: 0,
        spotOn: null,
        comment: ""
      },
      thirdIncentive: {
        amount: 0,
        paidOn: null,
        spot: 0,
        spotOn: null,
        comment: ""
      },
      ar:[false,false,false,false,false,false,false,false,false],
      crossfirstIncentive: { amount: 0, paidOn: null },
      crosssecondIncentive: { amount: 0, paidOn: null },
      crossthirdIncentive: { amount: 0, paidOn: null },
      agreementValue: 0,
      bookingAmount: 0,
      clientPaymentPercentage: 0,
      clientPaymentDate: null,
      salesAgreementStatus:null,
      sdrPaymentStatus: null,
      registrationStatus: null,
      eligibleForInvoiceStatus: null,
      invoiceNotRaisedStatus:null,
      partialInvoiceRaisedStatus:null,
      invoiceRaisedStatus: null,
      invoiceClearedStatus: null,
      brokerage: 0,
      bookingIncentive: 0,
      incentivePercentage: 0,
      magnetSplitPercentage: 0,
      incentiveMagnetSplitPercentage:0,
      blockFirstIncentivePayout: true,
      blockSecondIncentivePayout: true,
      blockThirdIncentivePayout: true,
      firstIncentiveIsSaved: false,
      secondIncentiveIsSaved: false,
      thirdIncentiveIsSaved: false,
      magnetAgreementValue: 0,
      passbackDiscount: 0,
      passback: 0,
      userInfo: null,
      is_crosssale: 0,
      crossBookingIncentive: 0,
      is_magnet: 0,
      magnet_invoice_cleared_status: null,
      alertShow: false,
      alertMsg: ""
    };
  }

  componentDidMount() {
    this.getBookingIncentive();
  }

  getBookingIncentive = async () => {
    let query = {};
    if (
      this.props.selectedBooking &&
      this.props.selectedBooking.cross_sale_incetive
    ) {
      query.cross_sale_incetive = 1;
    } else {
      query.cross_sale_incetive = 0;
    }
    if (this.props.selectedBooking && this.props.selectedBooking.presale) {
      query.is_presale = 1;
    } else {
      query.is_presale = 0;
    }
    let bookingIncentives = await getBookingIncentives(
      this.props.selectedBooking.id,
      query
    );

    if (bookingIncentives) {
      let firstIncentiveIsSaved = false;
      let secondIncentiveIsSaved = false;
      let thirdIncentiveIsSaved = false;
      if (
        bookingIncentives.incentives[0] &&
        bookingIncentives.incentives[0].payment_date &&
        (bookingIncentives.incentives[0].payment_amount ||
          bookingIncentives.incentives[0].payment_amount === 0)
      ) {
        firstIncentiveIsSaved = true;
      }
      if (
        bookingIncentives.incentives[1] &&
        bookingIncentives.incentives[1].payment_date &&
        (bookingIncentives.incentives[1].payment_amount ||
          bookingIncentives.incentives[1].payment_amount === 0)
      ) {
        secondIncentiveIsSaved = true;
      }
      if (
        bookingIncentives.incentives[2] &&
        bookingIncentives.incentives[2].payment_date &&
        (bookingIncentives.incentives[2].payment_amount ||
          bookingIncentives.incentives[2].payment_amount === 0)
      ) {
        thirdIncentiveIsSaved = true;
      }

      await this.setState({
        showLoader: false,
        firstIncentive: {
          amount: bookingIncentives.incentives[0]
            ? bookingIncentives.incentives[0].payment_amount
            : 0,
          paidOn: bookingIncentives.incentives[0]
            ? bookingIncentives.incentives[0].payment_date
            : null,
          spot: bookingIncentives.incentives[0]
            ? bookingIncentives.incentives[0].spot_adjustment_amount
            : 0,
          spotOn: bookingIncentives.incentives[0]
            ? bookingIncentives.incentives[0].spot_adjustment_date
            : null,
          comment: bookingIncentives.incentives[0]
            ? bookingIncentives.incentives[0].comments
            : ""
        },
        secondIncentive: {
          amount: bookingIncentives.incentives[1]
            ? bookingIncentives.incentives[1].payment_amount
            : 0,
          paidOn: bookingIncentives.incentives[1]
            ? bookingIncentives.incentives[1].payment_date
            : null,
          spot: bookingIncentives.incentives[1]
            ? bookingIncentives.incentives[1].spot_adjustment_amount
            : 0,
          spotOn: bookingIncentives.incentives[1]
            ? bookingIncentives.incentives[1].spot_adjustment_date
            : null,
          comment: bookingIncentives.incentives[1]
            ? bookingIncentives.incentives[1].comments
            : ""
        },
        thirdIncentive: {
          amount: bookingIncentives.incentives[2]
            ? bookingIncentives.incentives[2].payment_amount
            : 0,
          paidOn: bookingIncentives.incentives[2]
            ? bookingIncentives.incentives[2].payment_date
            : null,
          spot: bookingIncentives.incentives[2]
            ? bookingIncentives.incentives[2].spot_adjustment_amount
            : 0,
          spotOn: bookingIncentives.incentives[2]
            ? bookingIncentives.incentives[2].spot_adjustment_date
            : null,
          comment: bookingIncentives.incentives[2]
            ? bookingIncentives.incentives[2].comments
            : ""
        },
        leadId:bookingIncentives.lead_id,
        aggregateAgreementValue: bookingIncentives.aggregate_agreement_value,
        agreementValue: bookingIncentives.agreement_value,
        bookingAmount: bookingIncentives.booking_amount,
        clientPaymentPercentage: bookingIncentives.client_payment_percentages
          .percentage
          ? bookingIncentives.client_payment_percentages.percentage
          : 0,
        clientPaymentDate: bookingIncentives.client_payment_percentages
          .date_of_payment
          ? bookingIncentives.client_payment_percentages.date_of_payment
          : null,
        salesAgreementStatus:bookingIncentives.sales_agreement_status,
        sdrPaymentStatus: bookingIncentives.sdr_payment_status,
        registrationStatus: bookingIncentives.registration_status,
        eligibleForInvoiceStatus: bookingIncentives.eligible_for_invoice_status,
        invoiceNotRaisedStatus:bookingIncentives.invoice_not_raised_status,
        partialInvoiceRaisedStatus:bookingIncentives.partial_invoice_raised_status,
        invoiceRaisedStatus: bookingIncentives.invoice_raised_status,
        invoiceClearedStatus: bookingIncentives.invoice_cleared_status,
        brokerage: bookingIncentives.brokerage,
        bookingIncentive: bookingIncentives.booking_incentive,
        incentivePercentage: bookingIncentives.incentive_percentage,
        firstIncentiveIsSaved,
        secondIncentiveIsSaved,
        thirdIncentiveIsSaved,
        isMagnet: bookingIncentives.is_magnet,
        magnetSplitPercentage: bookingIncentives.magnet_percentage_split
          ? bookingIncentives.magnet_percentage_split
          : 0,
          incentiveMagnetSplitPercentage: bookingIncentives.incentive_magnet_split
          ? bookingIncentives.incentive_magnet_split
          : 0,
        magnetAgreementValue: bookingIncentives.magnetAgreementValue,
        passbackDiscount: bookingIncentives.passback_discount,
        passback: bookingIncentives.passback,
        userInfo: bookingIncentives.bookingUserInfo,
        is_crosssale: bookingIncentives.is_crosssale,
        crossBookingIncentive:
          bookingIncentives.is_crosssale && bookingIncentives.crossSaleIncetive
            ? bookingIncentives.crossSaleIncetive.booking_incentive
            : 0,
        is_magnet: bookingIncentives.is_magnet,
        magnet_invoice_cleared_status:
          bookingIncentives.magnet_invoice_cleared_status
      });
      this.checkIncentivePayouts();
    } else {
      this.setState({ showLoader: false });
    }
  };
 
  checkIncentivePayouts = () => {
    let updatedState = {};
    if (GROUPS[this.state.userInfo.group_id] === "PRESALE") {
      FIRST_INCENTIVE_PERCENTAGE = 40;
      SECOND_INCENTIVE_PERCENTAGE = 40;
      THIRD_INCENTIVE_PERCENTAGE = 20;
    } else {
      FIRST_INCENTIVE_PERCENTAGE = 20;
      SECOND_INCENTIVE_PERCENTAGE = 20;
      THIRD_INCENTIVE_PERCENTAGE = 60;
    }
    if (
      this.state.salesAgreementStatus &&
      this.state.sdrPaymentStatus &&
      this.state.registrationStatus &&
      this.state.eligibleForInvoiceStatus &&
      this.state.invoiceNotRaisedStatus &&
      this.state.partialInvoiceRaisedStatus &&
      this.state.invoiceRaisedStatus &&
      this.state.invoiceClearedStatus &&
      this.state.clientPaymentPercentage >=
        SECOND_PAYMENT_CLIENT_PAYMENT_PERCENTAGE
    ) {
      updatedState.blockFirstIncentivePayout = false;
      updatedState.blockSecondIncentivePayout = false;
      updatedState.blockThirdIncentivePayout = false;
      updatedState = this.calculateIncentive([1, 2, 3], updatedState);
    } else if (
      this.state.sdrPaymentStatus &&
      this.state.registrationStatus &&
      this.state.clientPaymentPercentage >=
        SECOND_PAYMENT_CLIENT_PAYMENT_PERCENTAGE
    ) {
      updatedState.blockFirstIncentivePayout = false;
      updatedState.blockSecondIncentivePayout = false;
      updatedState.blockThirdIncentivePayout = true;
      updatedState = this.calculateIncentive([1, 2], updatedState);
      updatedState = this.resetIncentive([3], updatedState);
    } else if (
      this.state.clientPaymentPercentage >=
      FIRST_PAYMENT_CLIENT_PAYMENT_PERCENTAGE
    ) {
      updatedState.blockFirstIncentivePayout = false;
      updatedState.blockSecondIncentivePayout = true;
      updatedState.blockThirdIncentivePayout = true;
      updatedState = this.calculateIncentive([1], updatedState);
      updatedState = this.resetIncentive([2, 3], updatedState);
    } else {
      updatedState.blockFirstIncentivePayout = true;
      updatedState.blockSecondIncentivePayout = true;
      updatedState.blockThirdIncentivePayout = true;
      updatedState = this.resetIncentive([1, 2, 3], updatedState);
    }

    this.setState(updatedState);
  };

  calculateIncentive = (phase = [], updatedState) => {
    if (GROUPS[this.state.userInfo.group_id] === "PRESALE") {
      FIRST_INCENTIVE_PERCENTAGE = 40;
      SECOND_INCENTIVE_PERCENTAGE = 40;
      THIRD_INCENTIVE_PERCENTAGE = 20;
    }
    if (phase.includes(1) && !this.state.firstIncentiveIsSaved) {
      let firstIncentive = { ...this.state.firstIncentive };
      firstIncentive.amount = roundToTwoDecimalPlaces(
        (this.state.bookingIncentive * FIRST_INCENTIVE_PERCENTAGE) / 100
      );
      updatedState.firstIncentive = firstIncentive;
      if (this.state.is_crosssale) {
        let crossfirstIncentive = { ...this.state.crossfirstIncentive };
        crossfirstIncentive.amount = roundToTwoDecimalPlaces(
          (this.state.crossBookingIncentive * FIRST_INCENTIVE_PERCENTAGE) / 100
        );
        updatedState.crossfirstIncentive = crossfirstIncentive;
      }
    }

    if (phase.includes(2) && !this.state.secondIncentiveIsSaved) {
      let secondIncentive = { ...this.state.secondIncentive };
      secondIncentive.amount = roundToTwoDecimalPlaces(
        (this.state.bookingIncentive * SECOND_INCENTIVE_PERCENTAGE) / 100
      );
      updatedState.secondIncentive = secondIncentive;
      if (this.state.is_crosssale) {
        let crosssecondIncentive = { ...this.state.crosssecondIncentive };
        crosssecondIncentive.amount = roundToTwoDecimalPlaces(
          (this.state.crossBookingIncentive * SECOND_INCENTIVE_PERCENTAGE) / 100
        );
        updatedState.crosssecondIncentive = crosssecondIncentive;
      }
    }

    if (phase.includes(3) && !this.state.thirdIncentiveIsSaved) {
      let thirdIncentive = { ...this.state.thirdIncentive };
      thirdIncentive.amount = roundToTwoDecimalPlaces(
        (this.state.bookingIncentive * THIRD_INCENTIVE_PERCENTAGE) / 100
      );
      updatedState.thirdIncentive = thirdIncentive;
      if (this.state.is_crosssale) {
        let crossthirdIncentive = { ...this.state.crossthirdIncentive };
        crossthirdIncentive.amount = roundToTwoDecimalPlaces(
          (this.state.crossBookingIncentive * THIRD_INCENTIVE_PERCENTAGE) / 100
        );
        updatedState.crossthirdIncentive = crossthirdIncentive;
      }
    }

    return updatedState;
  };

  resetIncentive = (phase = [], updatedState) => {
    if (phase.includes(1) && !this.state.firstIncentiveIsSaved) {
      let firstIncentive = { ...this.state.firstIncentive };
      firstIncentive.amount = 0;
      firstIncentive.paidOn = null;
      updatedState.firstIncentive = firstIncentive;
      if (this.state.is_crosssale) {
        let crossfirstIncentive = { ...this.state.crossfirstIncentive };
        crossfirstIncentive.amount = 0;
        crossfirstIncentive.paidOn = null;
        updatedState.crossfirstIncentive = crossfirstIncentive;
      }
    }

    if (phase.includes(2) && !this.state.secondIncentiveIsSaved) {
      let secondIncentive = { ...this.state.secondIncentive };
      secondIncentive.amount = 0;
      secondIncentive.paidOn = null;
      updatedState.secondIncentive = secondIncentive;
      if (this.state.is_crosssale) {
        let crosssecondIncentive = { ...this.state.crosssecondIncentive };
        crosssecondIncentive.amount = 0;
        crosssecondIncentive.paidOn = null;
        updatedState.crosssecondIncentive = crosssecondIncentive;
      }
    }

    if (phase.includes(3) && !this.state.thirdIncentiveIsSaved) {
      let thirdIncentive = { ...this.state.thirdIncentive };
      thirdIncentive.amount = 0;
      thirdIncentive.paidOn = null;
      updatedState.thirdIncentive = thirdIncentive;
      if (this.state.is_crosssale) {
        let crossthirdIncentive = { ...this.state.crossthirdIncentive };
        crossthirdIncentive.amount = 0;
        crossthirdIncentive.paidOn = null;
        updatedState.crossthirdIncentive = crossthirdIncentive;
      }
    }

    return updatedState;
  };

  getPhaseIncentives = () => {
    let firstIncentive = {};
    let crossfirstIncentive = {};
    if (
      !this.state.blockFirstIncentivePayout &&
      this.state.firstIncentive.amount &&
      this.state.firstIncentive.paidOn
    ) {
      firstIncentive = {
        payment_amount: this.state.firstIncentive.amount,
        payment_date: this.state.firstIncentive.paidOn,
        spot_adjustment_amount: this.state.firstIncentive.spot,
        spot_adjustment_date: this.state.firstIncentive.spotOn,
        comments: this.state.firstIncentive.comment
      };
      if (this.state.is_crosssale) {
        crossfirstIncentive = {
          payment_amount: this.state.crossfirstIncentive.amount,
          payment_date: this.state.crossfirstIncentive.paidOn
        };
      }
    }

    let secondIncentive = {};
    let crosssecondIncentive = {};
    if (
      !this.state.blockSecondIncentivePayout &&
      this.state.secondIncentive.amount &&
      this.state.secondIncentive.paidOn
    ) {
      secondIncentive = {
        payment_amount: this.state.secondIncentive.amount,
        payment_date: this.state.secondIncentive.paidOn,
        spot_adjustment_amount: this.state.secondIncentive.spot,
        spot_adjustment_date: this.state.secondIncentive.spotOn,
        comments: this.state.secondIncentive.comment
      };
      if (this.state.is_crosssale) {
        crosssecondIncentive = {
          payment_amount: this.state.crosssecondIncentive.amount,
          payment_date: this.state.crosssecondIncentive.paidOn
        };
      }
    }

    let thirdIncentive = {};
    let crossthirdIncentive = {};
    if (
      !this.state.blockThirdIncentivePayout &&
      this.state.thirdIncentive.amount &&
      this.state.thirdIncentive.paidOn
    ) {
      thirdIncentive = {
        payment_amount: this.state.thirdIncentive.amount,
        payment_date: this.state.thirdIncentive.paidOn,
        spot_adjustment_amount: this.state.thirdIncentive.spot,
        spot_adjustment_date: this.state.thirdIncentive.spotOn,
        comments: this.state.thirdIncentive.comment
      };
      if (this.state.is_crosssale) {
        crossthirdIncentive = {
          payment_amount: this.state.crossthirdIncentive.amount,
          payment_date: this.state.crossthirdIncentive.paidOn
        };
      }
    }

    return {
      firstIncentive,
      secondIncentive,
      thirdIncentive,
      crossfirstIncentive,
      crosssecondIncentive,
      crossthirdIncentive
    };
  };

  saveBookingIncentive = async (toggele = null) => {
    const phaseIcentives = this.getPhaseIncentives();

    let param = {
      first_incentive: phaseIcentives.firstIncentive,
      second_incentive: phaseIcentives.secondIncentive,
      third_incentive: phaseIcentives.thirdIncentive,
      client_payment_percentages: {
        percentage: this.state.clientPaymentPercentage,
        date_of_payment: this.state.clientPaymentDate
      },
      lead_id : this.state.leadId,
      agreement_value: removeCommas(this.state.agreementValue) !== "" ? removeCommas(this.state.agreementValue) : 0 ,
      booking_amount :removeCommas(this.state.bookingAmount) !== "" ? removeCommas(this.state.bookingAmount) : 0,
      special_discount : removeCommas(this.state.passbackDiscount) !=="" ? removeCommas(this.state.passbackDiscount) : 0,
      brokerage: this.state.brokerage,
      incentive_percentage: this.state.incentivePercentage,
      booking_incentive: this.state.bookingIncentive,
      sales_agreement_status:this.state.salesAgreementStatus,
      sdr_payment_status: this.state.sdrPaymentStatus,
      registration_status: this.state.registrationStatus,
      eligible_for_invoice_status: this.state.eligibleForInvoiceStatus,
      invoice_not_raised_status:this.state.invoiceNotRaisedStatus,
      partial_invoice_raised_status:this.state.partialInvoiceRaisedStatus,
      invoice_raised_status: this.state.invoiceRaisedStatus,
      invoice_cleared_status: this.state.invoiceClearedStatus,
      magnet_percentage_split: this.state.magnetSplitPercentage !=="" ? this.state.magnetSplitPercentage : 0,
      incentive_magnet_split: this.state.incentiveMagnetSplitPercentage !=="" ? this.state.incentiveMagnetSplitPercentage : 0,
      magnet_invoice_cleared_status: this.state.magnet_invoice_cleared_status
    };
    if (this.state.is_crosssale) {
      param.is_crosssale = this.state.is_crosssale;
      param.crossfirst_incentive = phaseIcentives.crossfirstIncentive;
      param.crosssecond_incentive = phaseIcentives.crosssecondIncentive;
      param.crossthird_incentive = phaseIcentives.crossthirdIncentive;
    }
    if (this.props.selectedBooking && this.props.selectedBooking.presale) {
      param.is_presale = 1;
      param.presale_rm = this.props.selectedBooking.presale_rm;
    } else {
      param.is_presale = 0;
    }
    if(removeCommas(this.state.agreementValue) < AV_LIMIT_LOWER){
      this.setState({
        alertShow: true,
        alertType: "error",
        alertMsg: AV_VALIDATION_MSG_LOWER
      })
      return false
    }
    else if(removeCommas(this.state.agreementValue) > AV_LIMIT_UPPER){
      this.setState({
        alertShow: true,
        alertType: "error",
        alertMsg: AV_VALIDATION_MSG_UPPER
      })
      return false
    }else if(removeCommas(this.state.bookingAmount) > BOOKING_AMOUNT_LIMIT){
      this.setState({
        alertShow: true,
        alertType: "error",
        alertMsg: BOOKING_AMOUNT_MSG
      })
      return false
    }
    await this.setState({ showLoader: true }, async () => {
      let saveResponse = await saveBookingIncentives(
        param,
        this.props.selectedBooking.id
      );
      if (saveResponse.success) {
        await this.getBookingIncentive();
        // this.props.getBookingLeadsList();
        this.props.getList();
      }
      this.setState({ showLoader: false });
      if (toggele && toggele !== "open") {
        this.props.toggleBookingIncentiveModal();
      }
    });
  };

  handleValueChange = (e) => {
    let { name, value } = e.target
    if (value === '' || decimal_regex.test(value)) {
      if(name === "clientPaymentPercentage"){
        this.setState(
          { [name]: value },
          () => this.checkIncentivePayouts()
          );
        }
        else{
          this.setState({ [name]: value })
        }
      }
  }

	handleNumberChange = (e) => {
		let {name, value, validity: {valid}} = e.target;
		 if (valid || value === ""){
      if(name === "passbackDiscount" || name === "bookingAmount"){
        this.setState({ [name]: removeCommas(value) });
      }else{
			  this.setState({ [name]: value });
      }
		}
	};	

	handleAlertClose = () =>{
		this.setState({ alertShow: false })
	}
  
  
   handleMultiSelect= (seq,flag) => {
     var i;
     if (flag) {
       for (i = 0; i <= seq; i++) {
         this.state.ar[i] = flag;
       }
     }
     else {
       for (i = 0; i < seq; i++) {
         this.state.ar[i] = !flag;
       }
       for (i = this.state.ar.length - 1; i >= seq; i--) {
         this.state.ar[i] = flag;
       }
     }
     this.setState({
      invoiceNotRaisedStatus:this.state.ar[0]
      },
      () => this.checkIncentivePayouts())
     this.setState({
      sdrPaymentStatus:this.state.ar[1]
      },
       () => this.checkIncentivePayouts())
      this.setState({
        registrationStatus:this.state.ar[2]
      },
       () => this.checkIncentivePayouts())
      this.setState({
        salesAgreementStatus:this.state.ar[3]
      },
        ()=>this.checkIncentivePayouts())
      this.setState({
        eligibleForInvoiceStatus:this.state.ar[4]
      },
       () => this.checkIncentivePayouts())
      this.setState({
        partialInvoiceRaisedStatus:this.state.ar[5]
      },
       () => this.checkIncentivePayouts())
      this.setState({
        invoiceRaisedStatus:this.state.ar[6]
      },
       () => this.checkIncentivePayouts())
      this.setState({
        invoiceClearedStatus:this.state.ar[7]
      },
      () => this.checkIncentivePayouts())

      if(this.state.is_magnet){
        this.setState({
       magnet_invoice_cleared_status:this.state.ar[8]
      },
      () => this.checkIncentivePayouts())
    }
  }

  render() {
    const {agreementValue, bookingAmount, passbackDiscount, magnetSplitPercentage, clientPaymentPercentage, firstIncentive, secondIncentive, thirdIncentive, alertShow, alertType, alertMsg, incentiveMagnetSplitPercentage} = this.state;
    const variant = "standard"
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.toggleBookingIncentiveModal(false, null)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="unselectable modal-width-90"
      >
        <Loader show={this.state.showLoader} />
        <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={this.handleAlertClose} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Booking Incentives
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-2">
            <div className="col-md-2 mb-3 pr-md-5">
              <InputUnitField
                variant={variant}
                name="agreementValue"
                value={agreementValue}
                label="Agreement Value"
                onChange={this.handleNumberChange}
                />
            </div>
            <div className="col-md-2 mb-3 pr-md-5">
              <InputUnitField
                variant={variant}
                name="bookingAmount"
                value={bookingAmount}
                label="Booking Amount"
                onChange={this.handleNumberChange}
                />
            </div>
            {this.state.passback === 1 ? (
            <div className="col-md-2 mb-3 pr-md-5">
              <InputUnitField
                variant={variant}
                name="passbackDiscount"
                value={passbackDiscount}
                label="Special Discount"
                onChange={this.handleNumberChange}
                />
            </div>
            ) : null}
            {this.state.is_magnet === 1 ? (
              <>
                <div className="col-md-2 mb-3 pr-md-5">
                <InputField
                  variant={variant}
                  name="magnetSplitPercentage"
                  value={magnetSplitPercentage}
                  label="Magnet Split %"
                  onChange={this.handleValueChange}
                  />
                </div>
                <div className="col-md-2 mb-3 pr-md-5">
                <InputField
                  variant={variant}
                  name="incentiveMagnetSplitPercentage"
                  value={incentiveMagnetSplitPercentage}
                  label="Incentive Magnet Split %"
                  onChange={this.handleValueChange}
                  />
                </div>
              </>
            ) : null}
            <div className="col-md-2">
              <Button
                variant="contained" 
                onClick={e => this.saveBookingIncentive("open")}
                color="primary"
                >
                Save
              </Button>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row mb-3 align-items-end">
                <div className="col-md-4 col-5 pr-0">
                  <InputField
                    variant={variant}
                    name="clientPaymentPercentage"
                    value={clientPaymentPercentage}
                    label="Client Payment"
                    onChange={this.handleValueChange}
                    />
                </div>
                <div className="col-2 pl-md-2 px-0">% as of</div>
                <div className="col-md-4 col-5 px-0 ">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="Date"
                      disableToolbar
                      variant="inline"
                      format="dd-MM-yyyy"
                      margin="normal"
                      value={
                        this.state.clientPaymentDate
                        ? this.state.clientPaymentDate
                        : null
                      }
                      autoOk={true}
                      onChange={date => {
                        this.setState({ clientPaymentDate: date }, () =>
                        this.checkIncentivePayouts()
                        );
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="col-md-8 pl-md-0">
              <div className="row align-items-end">
                <div className="col-md-4 pl-md-0">
                  <div className="row align-items-end">
                    <div className="col-6 pl-md-0 pr-md-1 mb-2">
                      <InputField
                        variant={variant}
                        value={firstIncentive.amount}
                        label="Incentive Amount"
                        onChange={e => {
                          let firstIncentive = { ...this.state.firstIncentive };
                          let {value} = e.target;
                          if (value === '' || decimal_regex.test(value)) {
                            firstIncentive.amount = value;
                            this.setState({ firstIncentive });
                          }
                        }}
                        disabled={
                          this.state.blockFirstIncentivePayout ||
                          this.state.firstIncentiveIsSaved
                        }
                      />
                    </div>
                    <div className="col-6 pl-md-1 pr-md-0 mb-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Date"
                        disableToolbar
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={
                          this.state.firstIncentive.paidOn
                            ? this.state.firstIncentive.paidOn
                            : null
                        }
                        autoOk={true}
                        onChange={date => {
                          let firstIncentive = { ...this.state.firstIncentive };
                          firstIncentive.paidOn = date;
                          let setState = { firstIncentive: firstIncentive };
                          if (this.state.is_crosssale) {
                            let crossfirstIncentive = {
                              ...this.state.crossfirstIncentive
                            };
                            crossfirstIncentive.paidOn = date;
                            setState.crossfirstIncentive = crossfirstIncentive;
                          }
                          this.setState(setState);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        disabled={
                          this.state.blockFirstIncentivePayout ||
                          this.state.firstIncentiveIsSaved
                        }
                      />
                    </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="row align-items-end">
                    <div className="col-6 mb-2">
                      <InputField
                        variant={variant}
                        value={firstIncentive.spot}
                        label="Spot Amount"
                        onChange={e => {
                          let firstIncentive = { ...this.state.firstIncentive };
                          let {value} = e.target;
                          if (value === '' || decimal_regex.test(value)) {
                            firstIncentive.spot = value;
                            this.setState({ firstIncentive });
                          }
                        }}
                        disabled={
                          this.state.blockFirstIncentivePayout ||
                          this.state.firstIncentiveIsSaved
                        }
                      />
                    </div>
                    <div className="col-6 pl-md-1 pr-md-0 mb-2">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          label="Date"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          value={
                            this.state.firstIncentive.spotOn
                              ? this.state.firstIncentive.spotOn
                              : null
                          }
                          autoOk={true}
                          onChange={date => {
                            let firstIncentive = { ...this.state.firstIncentive };
                            firstIncentive.spotOn = date;
                            let setState = { firstIncentive: firstIncentive };
                            if (this.state.is_crosssale) {
                              let crossfirstIncentive = {
                                ...this.state.crossfirstIncentive
                              };
                              crossfirstIncentive.spotOn = date;
                              setState.crossfirstIncentive = crossfirstIncentive;
                            }
                            this.setState(setState);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          disabled={
                            this.state.blockFirstIncentivePayout ||
                            this.state.firstIncentiveIsSaved
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <InputField
                    variant={variant}
                    value={firstIncentive.comment}
                    label="Comment"
                    onChange={e => {
                      let firstIncentive = { ...this.state.firstIncentive };
                      firstIncentive.comment = e.target.value;
                      this.setState({ firstIncentive });
                    }}
                    disabled={
                      this.state.blockFirstIncentivePayout ||
                      this.state.firstIncentiveIsSaved
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end">
                <div className="col-6">
                  Invoice Not Raised
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.invoiceNotRaisedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(0,!this.state.invoiceNotRaisedStatus)
                          }
                        }
                      />
                      <label
                         onClick={()=>
                          {
                            this.handleMultiSelect(0,!this.state.invoiceNotRaisedStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.invoiceNotRaisedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(0,!this.state.invoiceNotRaisedStatus)
                          }
                        }
                      />
                      <label
                         onClick={()=>
                          {
                            this.handleMultiSelect(0,!this.state.invoiceNotRaisedStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        No
                      </label>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end mb-3 mb-md-0">
                <div className="col-6">
                  Stamp Duty Status
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.sdrPaymentStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(1,!this.state.sdrPaymentStatus)
                          }
                        }
                      />
                      <label
                        className="form-check-label"
                        onClick={()=>
                          {
                            this.handleMultiSelect(1,!this.state.sdrPaymentStatus)
                          }
                        }
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.sdrPaymentStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(1,!this.state.sdrPaymentStatus)
                          }
                        }
                      />
                      <label
                       onClick={()=>
                        {
                          this.handleMultiSelect(1,!this.state.sdrPaymentStatus)
                        }
                      }
                        className="form-check-label"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end mb-3 mb-md-0">
                <div className="col-6">
                  Registration Status
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                    <input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.registrationStatus ? true : false}
                    onClick={()=>
                      {
                        this.handleMultiSelect(2,!this.state.registrationStatus)
                      }
                    }
                  />
                  <label
                    onClick={()=>
                      {
                        this.handleMultiSelect(2,!this.state.registrationStatus)
                      }
                    }
                    className="form-check-label"
                  >
                    Yes
                  </label>
                    </div>
                    <div className="col-6">
                    <input
                    className="form-check-input"
                    type="checkbox"
                    checked={!this.state.registrationStatus ? true : false}
                    onClick={()=>
                      {
                        this.handleMultiSelect(2,!this.state.registrationStatus)
                      }
                    }
                    
                  />
                  <label
                    onClick={()=>
                      {
                        this.handleMultiSelect(2,!this.state.registrationStatus)
                      }
                    }
                    className="form-check-label"
                  > 
                    No
                  </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 pl-md-0">
              <div className="row align-items-end">
                <div className="col-md-4 pl-md-0">
                  <div className="row align-items-end">
                    <div className="col-6 pl-md-0 pr-md-1 mb-2">
                      <InputField
                        variant={variant}
                        value={secondIncentive.amount}
                        label="Incentive Amount"
                        onChange={e => {
                          let secondIncentive = { ...this.state.secondIncentive };
                          let {value} = e.target;
                          if (value === '' || decimal_regex.test(value)) {
                            secondIncentive.amount = value;
                            this.setState({ secondIncentive });
                          }
                        }}
                        disabled={
                          this.state.blockSecondIncentivePayout ||
                          this.state.secondIncentiveIsSaved
                        }
                      />
                    </div>
                    <div className="col-6 pl-md-1 pr-md-0 mb-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Date"
                        disableToolbar
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={
                          this.state.secondIncentive.paidOn
                            ? this.state.secondIncentive.paidOn
                            : null
                        }
                        autoOk={true}
                        onChange={date => {
                          let secondIncentive = { ...this.state.secondIncentive };
                          secondIncentive.paidOn = date;
                          let setState = { secondIncentive: secondIncentive };
                          if (this.state.is_crosssale) {
                            let crosssecondIncentive = {
                              ...this.state.crosssecondIncentive
                            };
                            crosssecondIncentive.paidOn = date;
                            setState.crosssecondIncentive = crosssecondIncentive;
                          }
                          this.setState(setState);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        disabled={
                          this.state.blockSecondIncentivePayout ||
                          this.state.secondIncentiveIsSaved
                        }
                      />
                    </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="row align-items-end">
                    <div className="col-6 mb-2">
                      <InputField
                        variant={variant}
                        value={secondIncentive.spot}
                        label="Spot Amount"
                        onChange={e => {
                          let secondIncentive = { ...this.state.secondIncentive };
                          let {value} = e.target;
                          if (value === '' || decimal_regex.test(value)) {
                            secondIncentive.spot = value;
                            this.setState({ secondIncentive });
                          }
                        }}
                        disabled={
                          this.state.blockSecondIncentivePayout ||
                          this.state.secondIncentiveIsSaved
                        }
                      />
                    </div>
                    <div className="col-6 pl-md-1 pr-md-0 mb-2">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          label="Date"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          value={
                            this.state.secondIncentive.spotOn
                              ? this.state.secondIncentive.spotOn
                              : null
                          }
                          autoOk={true}
                          onChange={date => {
                            let secondIncentive = { ...this.state.secondIncentive };
                            secondIncentive.spotOn = date;
                            let setState = { secondIncentive: secondIncentive };
                            if (this.state.is_crosssale) {
                              let crosssecondIncentive = {
                                ...this.state.crosssecondIncentive
                              };
                              crosssecondIncentive.spotOn = date;
                              setState.crosssecondIncentive = crosssecondIncentive;
                            }
                            this.setState(setState);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          disabled={
                            this.state.blockSecondIncentivePayout ||
                            this.state.secondIncentiveIsSaved
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <InputField
                    variant={variant}
                    value={secondIncentive.comment}
                    label="Comment"
                    onChange={e => {
                      let secondIncentive = { ...this.state.secondIncentive };
                      secondIncentive.comment = e.target.value;
                      this.setState({ secondIncentive });
                    }}
                    disabled={
                      this.state.blockSecondIncentivePayout ||
                      this.state.secondIncentiveIsSaved
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end">
                <div className="col-6">
                  Sales Agreement Status
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.salesAgreementStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(3,!this.state.salesAgreementStatus)
                          }
                        }
                      />
                      <label
                        onClick={()=>
                          {
                            this.handleMultiSelect(3,!this.state.salesAgreementStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.salesAgreementStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(3,!this.state.salesAgreementStatus)
                          }
                        }
                      />
                      <label
                        onClick={()=>
                          {
                            this.handleMultiSelect(3,!this.state.salesAgreementStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end">
                <div className="col-6">
                  Eligible for Invoice
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.eligibleForInvoiceStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(4,!this.state.eligibleForInvoiceStatus)
                          }
                        }
                      />
                      <label
                         onClick={()=>
                          {
                            this.handleMultiSelect(4,!this.state.eligibleForInvoiceStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.eligibleForInvoiceStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(4,!this.state.eligibleForInvoiceStatus)
                          }
                        }
                      />
                      <label
                         onClick={()=>
                          {
                            this.handleMultiSelect(4,!this.state.eligibleForInvoiceStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        

          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end">
                <div className="col-6">
                  Partial Invoice Raised
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.partialInvoiceRaisedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(5,!this.state.partialInvoiceRaisedStatus)
                          }
                        }
                      />
                      <label
                         onClick={()=>
                          {
                            this.handleMultiSelect(5,!this.state.partialInvoiceRaisedStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.partialInvoiceRaisedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(5,!this.state.partialInvoiceRaisedStatus)
                          }
                        }
                      />
                      <label
                       onClick={()=>
                        {
                          this.handleMultiSelect(5,!this.state.partialInvoiceRaisedStatus)
                        }
                      }
                        className="form-check-label"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end mb-3 mb-md-0">
                <div className="col-6">
                  Invoice Raised
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.invoiceRaisedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(6,!this.state.invoiceRaisedStatus)
                          }
                        }
                      />
                      <label
                        onClick={() =>
                          this.setState(
                            {
                              invoiceRaisedStatus: !this.state.invoiceRaisedStatus
                            },
                            () => this.checkIncentivePayouts()
                          )
                        }
                        className="form-check-label"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.invoiceRaisedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(6,!this.state.invoiceRaisedStatus)
                          }
                        }
                      />
                      <label
                        onClick={()=>
                          {
                            this.handleMultiSelect(6,!this.state.invoiceRaisedStatus)
                          }
                        }
                        className="form-check-label"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="row align-items-end">
            <div className="col-md-4 pr-md-0">
              <div className="row align-items-end mb-3 mb-md-0">
                <div className="col-6">
                  Invoice Cleared
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.invoiceClearedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(7,!this.state.invoiceClearedStatus)
                          }

                        }
                      />
                      <label
                        onClick={()=>
                          {
                            this.handleMultiSelect(7,!this.state.invoiceClearedStatus)
                          }

                        }
                        className="form-check-label"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!this.state.invoiceClearedStatus ? true : false}
                        onClick={()=>
                          {
                            this.handleMultiSelect(7,!this.state.invoiceClearedStatus)
                          }

                        }
                      />
                      <label
                        onClick={()=>
                          {
                            this.handleMultiSelect(7,!this.state.invoiceClearedStatus)
                          }

                        }
                        className="form-check-label"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 pl-md-0">
              <div className="row align-items-end">
                <div className="col-md-4 pl-md-0">
                  <div className="row align-items-end">
                    <div className="col-6 pl-md-0 pr-md-1 mb-2">
                      <InputField
                        variant={variant}
                        value={thirdIncentive.amount}
                        label="Incentive Amount"
                        onChange={e => {
                          let thirdIncentive = { ...this.state.thirdIncentive };
                          let {value} = e.target;
                          if (value === '' || decimal_regex.test(value)) {
                            thirdIncentive.amount = value;
                            this.setState({ thirdIncentive });
                          }
                        }}
                        disabled={
                          this.state.blockThirdIncentivePayout ||
                          this.state.thirdIncentiveIsSaved
                        }
                      />
                    </div>
                    <div className="col-6 pl-md-1 pr-md-0 mb-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Date"
                        disableToolbar
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={
                          this.state.thirdIncentive.paidOn
                            ? this.state.thirdIncentive.paidOn
                            : null
                        }
                        autoOk={true}
                        onChange={date => {
                          let thirdIncentive = { ...this.state.thirdIncentive };
                          thirdIncentive.paidOn = date;
                          let setState = { thirdIncentive: thirdIncentive };
                          if (this.state.is_crosssale) {
                            let crossthirdIncentive = {
                              ...this.state.crossthirdIncentive
                            };
                            crossthirdIncentive.paidOn = date;
                            setState.crossthirdIncentive = crossthirdIncentive;
                          }
                          this.setState(setState);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        disabled={
                          this.state.blockThirdIncentivePayout ||
                          this.state.thirdIncentiveIsSaved
                        }
                      />
                    </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row align-items-end">
                    <div className="col-6 mb-2">
                      <InputField
                        variant={variant}
                        value={thirdIncentive.spot}
                        label="Spot Amount"
                        onChange={e => {
                          let thirdIncentive = { ...this.state.thirdIncentive };
                          let {value} = e.target;
                          if (value === '' || decimal_regex.test(value)) {
                            thirdIncentive.spot = value;
                            this.setState({ thirdIncentive });
                          }
                        }}
                        disabled={
                          this.state.blockThirdIncentivePayout ||
                          this.state.thirdIncentiveIsSaved
                        }
                      />
                    </div>
                    <div className="col-6 pl-md-1 pr-md-0 mb-2">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          label="Date"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          value={
                            this.state.thirdIncentive.spotOn
                              ? this.state.thirdIncentive.spotOn
                              : null
                          }
                          autoOk={true}
                          onChange={date => {
                            let thirdIncentive = { ...this.state.thirdIncentive };
                            thirdIncentive.spotOn = date;
                            let setState = { thirdIncentive: thirdIncentive };
                            if (this.state.is_crosssale) {
                              let crossthirdIncentive = {
                                ...this.state.crossthirdIncentive
                              };
                              crossthirdIncentive.spotOn = date;
                              setState.crossthirdIncentive = crossthirdIncentive;
                            }
                            this.setState(setState);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          disabled={
                            this.state.blockThirdIncentivePayout ||
                            this.state.thirdIncentiveIsSaved
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <InputField
                    variant={variant}
                    value={thirdIncentive.comment}
                    label="Comment"
                    onChange={e => {
                      let thirdIncentive = { ...this.state.thirdIncentive };
                      thirdIncentive.comment = e.target.value;
                      this.setState({ thirdIncentive });
                    }}
                    disabled={
                      this.state.blockThirdIncentivePayout ||
                      this.state.thirdIncentiveIsSaved
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.is_magnet ? (
            <div className="row mt-3">
              <div className="col-md-4 pr-md-0">
                <div className="row align-items-end mb-3 mb-md-0">
                  <div className="col-6">
                    Magnet Invoice Cleared
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            this.state.magnet_invoice_cleared_status ? true : false
                          }
                          onClick={()=>
                            {
                              this.handleMultiSelect(8,!this.state.magnet_invoice_cleared_status)
                            }
  
                          }
                        />
                        <label
                         onClick={()=>
                          {
                            this.handleMultiSelect(8,!this.state.magnet_invoice_cleared_status)
                          }

                        }
                          className="form-check-label"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="col-6">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            !this.state.magnet_invoice_cleared_status ? true : false
                          }
                          onClick={()=>
                            {
                              this.handleMultiSelect(8,!this.state.magnet_invoice_cleared_status)
                            }
  
                          }
                        />
                        <label
                          onClick={()=>
                            {
                              this.handleMultiSelect(8,!this.state.magnet_invoice_cleared_status)
                            }
  
                          }
                          className="form-check-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row pt-4">
            <div className="col-md-2 col-6 text-center px-md-0">
              <div className="row flex-column">
                <div className="col-12 text-muted">
                  Aggregate Agreement Value
                </div>
                <div className="col-md-12">
                  {indianRupeeCommaSeparated(
                  this.state.aggregateAgreementValue
                    ? this.state.aggregateAgreementValue.toFixed(0)
                    : this.state.aggregateAgreementValue
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 text-center px-md-0">
            <div className="row flex-column">
                <div className="col-12 text-muted">
                  Agreement Value
                </div>
                <div className="col-md-12">
                  {indianRupeeCommaSeparated(
                this.state.magnetAgreementValue
                  ? this.state.magnetAgreementValue.toFixed(0)
                  : this.state.magnetAgreementValue
              )}
                </div>
              </div>
              </div>
            <div className="col-md-2 col-6 text-center px-md-0">
            <div className="row flex-column">
                <div className="col-12 text-muted">
                  Brokerage
                </div>
                <div className="col-md-12">
                  {indianRupeeCommaSeparated(
                this.state.brokerage
                  ? this.state.brokerage.toFixed(0)
                  : this.state.brokerage
              )}
                </div>
              </div>
              </div>
            {this.state.passback === 1 ? (
              <div className="col-md-2 col-6 text-center px-md-0">
                <div className="row flex-column">
                <div className="col-12 text-muted">
                  Passback
                </div>
                <div className="col-md-12">
                  {indianRupeeCommaSeparated(
                  this.state.passbackDiscount
                    ? ((this.state.is_magnet === 1) ? (this.state.passbackDiscount/2) : this.state.passbackDiscount )
                    : this.state.passbackDiscount
                )}
                </div>
              </div>
                </div>
            ) : (
              ""
            )}
            <div className="col-md-2 col-6 text-center px-md-0">
              <div className="row flex-column">
                <div className="col-12 text-muted">
                  Booking Incentive
                </div>
                <div className="col-md-12">
                  {indianRupeeCommaSeparated(
                this.state.bookingIncentive
                  ? this.state.bookingIncentive.toFixed(0)
                  : this.state.bookingIncentive
              )}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 text-center px-md-0">
              <div className="row flex-column">
                <div className="col-12 text-muted">
                  Incentive %
                </div>
                <div className="col-md-12">
                  {this.state.incentivePercentage} %
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="text-center col-12">
              <Button
                variant="contained" 
                onClick={this.saveBookingIncentive}
                color="primary"
                >
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
