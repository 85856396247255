import React, { useState, useEffect } from 'react'
import { getRatiosData } from '../../../dataParser/getDashboardData'
import RatioCard from '../cards/RatioCard'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { roleWiseAutoComplete } from '../../../dataParser/commomDataApi';
import { REVERT_ROLES } from '../../../config/constant';
import TextField from '@material-ui/core/TextField';
import Loader from '../loader/loader';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { cleanStorage , generateMonths, generateYears } from '../../../helper';

let d = new Date();

d.setDate(d.getDate() - (d.getDay() + 6) % 7);

let p_monday=d.setDate(d.getDate() - 7);

let sunday = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);

const RatioChart = () => {
    const [state, setState] = useState({
        data: [],
        meta: {},
        created_at_from: p_monday,
        created_at_to: sunday,
        option: [],
        user: window.$user,
        user_id: window.$user.id ,
        months:generateMonths(),
        month: '',
        years: generateYears(),
        year: '',
    })
    const [isLoading, setLoading] = useState(false)
    
    const {created_at_from, created_at_to, user,year,month} = state;

    useEffect(() => {
        (async () => {
            let id = state.user_id
            let data = {}
            let todayDate = new Date();

            let fromDate = moment(created_at_from).format("YYYY-MM-DD");
            let toDate = moment(created_at_to).format("YYYY-MM-DD");
    
            if (fromDate) {
                data["created_at_from"] = fromDate.split("/").join("-");
            } if (toDate) {
                data["created_at_to"] = toDate.split("/").join("-");
            }
            getRatiosListing(id, data)
        })();
    }, [])

    const getRatiosListing = async (id, data) => {
        setState({ ...state, showLoader: true})
        let user_id = id;
        let user_data = data;
        let getData = await getRatiosData(user_id, user_data);
        setState({
            ...state,
            user_id: user_id, 
            created_at_from: data.created_at_from, 
            created_at_to: data.created_at_to,
            showLoader: false,
            data: getData.data.ratioData,
            meta: getData.meta
        })
    }

    const handleDateChange = (key, date) => {
        setState({ ...state, [key]: date });
    }   

    const handleRatioFilter = (roleId) => {
        let id = roleId ? roleId : user.id;
        let data = {};
        if (created_at_from) {
            data["created_at_from"] = created_at_from.split("/").join("-");
        } if (created_at_to) {
            data["created_at_to"] = created_at_to.split("/").join("-");
        }
        if(year && month){
           data['year'] = year;
           data['month'] = month; 
        }   
        getRatiosListing(id, data)
    }
    const handleAutoChange = async (e) => {
        (async () => {
            try {
                setLoading(true)
                const {user} = state
                let data = {};
                data.q = e.target.value;

                // commented for safe side

                /* if(user.role === "sourcings2"){
                    data.roles = [REVERT_ROLES.SOURCING_S3]
                }
                else if(user.role === "salestl" || user.role === "seniorsalestl"){
                    data.roles = [REVERT_ROLES.CLOSING_S1]
                }
                else if(user.role === "salesmanager"){
                    data.roles = [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
                }
                else if(user.role === "saleshead"){
                    data.roles = [REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
                }
                else if(user.role === "pnlhead"){
                    data.roles = [REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
                }
                else if(user.role === "magnetadmin" || user.role === "magnetpnl"){
                    data.roles = [REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
                } */

                let resData = await roleWiseAutoComplete(data);

                await setState({
                    ...state,
                    option: resData.data,
                })
                if(resData.meta.status === 200){
                    setLoading(false)
                    setState({
                        ...state
                        , option: resData.data
                    });
                }else if(resData.meta.status === 401){
                    cleanStorage();
                    this.props.history.push("/login");
        
                }else{
                setLoading(true)

                    this.setState({
                        ...state
                        , option: []
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();
    }
    const handleChange = event => {
        setState({...state, [event.target.name]: event.target.value });
    };
    const { option, showLoader, user_id } = state
    const ratioDisplayRoles = ["admin", "sourcings2", "magnet s2", "salesmanager", "salestl", "seniorsalestl", "saleshead", "pnlhead", "magnetadmin", "magnetpnl", "operationshead", "operationsmanager", "financeassociate"]
    return (
        <div className="container">
            <Loader show={showLoader} />
            <div className="row">
                <div className="row filterRow mb-2 mt-3">
                    {
                        ratioDisplayRoles.includes(user.role) ?
                    <div className="col-lg-2 col-sm-2 mb-3">
                        <Autocomplete
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={option => option.name}
                            options={option}
                            onChange={(_event, option) => {
                                if (option === null) {
                                    setState({
                                        ...state,
                                        option: [],
                                        user_id: null,
                                    })
                                    // handleRatioFilter(user.id)
                                } else {
                                    let user_id = option.id;
                                    // handleRatioFilter(user_id)
                                    setState({
                                        ...state,
                                        user_id: user_id,
                                    })
                                }

                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Select RM"
                                    reqiured
                                    fullWidth
                                    onChange={handleAutoChange}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {isLoading ? (
                                              <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        )
                                      }}
                                />
                            )}
                        />
                    </div>
                   : <div className="col-lg-2 col-sm-2 mb-3"></div>
                    }
                   <div className="col-md-2 col-6 pr-1">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                label="From"
                                format="yyyy/MM/dd"
                                maxDate={new Date()}
                                name="created_at_from"
                                value={created_at_from}
                                onChange={(key, date) => handleDateChange('created_at_from', date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-2 col-6 pl-1">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                label="To"
                                format="yyyy/MM/dd"
                                maxDate={new Date()}
                                name="created_at_to"
                                value={created_at_to}
                                onChange={(key, date) => handleDateChange('created_at_to', date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-2 col-6">
                        <FormControl>
                            <InputLabel id="Presale-Lead-type">
                            Year
                            </InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            value={state.year}
                            onChange={(e) => handleChange(e)}
                            inputProps={{
                                name: "year",
                                id: "year"
                            }}
                            required
                            >
                            {state.years.map(year => (
                                <MenuItem key={year} value={year}>
                                {year}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2 col-6">
                        <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">
                            Month
                            </InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            value={state.month}
                            onChange={(e) => handleChange(e)}
                            inputProps={{
                                name: "month",
                                id: "month"
                            }}
                            required
                            >
                            {state.months.map(month => (
                                <MenuItem key={month.key} value={month.key}>
                                {month.value}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-1 col-6 pt-3 pl-3">
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={()=>handleRatioFilter(user_id)}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center my-3">
                <div className="">
                    <h5>Lead Count : {state.data.Lead_count || "0"}</h5>
                </div>
            </div>
            <div className="row">
                {state?.data?.data?.map((cols, idx) => (
                    <div className="col-md-3 col-6" key={idx}>
                        <div className="row flex-column">
                            {cols.map((rows, j) => (

                                <div className="col-md-12 mb-3" key={j}>
                                    <RatioCard data={rows} />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default RatioChart