import {get,patch,post,put} from "../utils/methods.js";

export const getUpdatedumpLead = async (value) =>{

    let getUpdatedumpData = await patch('/leads/dump_upload/update/'+value.id,value.data)
    return  getUpdatedumpData;
}

export const getDumpLeadDataList = async (value) =>{

    let getdumpLeadData = await get('/dump_lead',value)
    return  getdumpLeadData;
}

export const getdumpLeadUpload = async (value) =>{

    let getdumpLeadUploadData = await post('/dump_leads/upload',value)
    return  getdumpLeadUploadData;
}

export const getDumpWithdrawCount = async (value) =>{
    let getdumpWithDrawCount = await get('dump_lead/team_lead_status/' + value)
    return  getdumpWithDrawCount;
}
export const getWithdrawLead = async (value) =>{
    let getdumpWithDrawLead = await put('dump_lead/withdraw_leads/'+value.userId, value.data)
    return  getdumpWithDrawLead;
}
