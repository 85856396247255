import React from "react"
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import Button from '@material-ui/core/Button'

const AutoDialer = () => {
    if (["admin", "sourcings2", "sourcings3", "magnet s2", "magnet s3"].includes(window?.$user?.role)) {
        return (
            <a href="/auto-dialer.html" target="_blank">
                <Button variant="outlined" color="primary" style={{ textTransform: "capitalize" }} className="rectangle pulse px-1">
                    <PlayCircleFilledWhiteIcon />
                    <span className="ml-md-2 ml-1">Auto Dialer</span>
                </Button>
            </a>
        )
    }
    else {
        return null
    }
}
export default AutoDialer