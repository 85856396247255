import React, { Component } from "react";

import BookingList from "../../component/booking/bookingList";
import IncentiveReport from "../../component/booking/incentiveReport";
import PaidIncentiveReport from "../../component/booking/paidIncentiveReport";

import "./booking.scss";

export default class VerifyBooking extends Component {
  loadComponent = () => {
    if (
      this.props.match.params.section === "verify" ||
      this.props.match.params.section === "raise-invoice" || this.props.match.params.section === "passback"
    ) {
      return <BookingList section={this.props.match.params.section} />;
    } else if (this.props.match.params.section === "report") {
      return <IncentiveReport />;
    } else if (this.props.match.params.section === "paid-report") {
      return <PaidIncentiveReport />;
    }
  };

  render() {
    return (
      <div
        className={`content-wrapper ${
          this.props.sideBar ? "main-header-collapsed" : ""
        }`}
      >
        {this.loadComponent()}
      </div>
    );
  }
}
