import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getIncentiveReport,
  getCompleteIncentiveReport,
  getSalesTeamMembers
} from "../../dataParser/getListUserData";

import Loader from "../common/loader/loader";

import {
  indianRupeeCommaSeparated,
  roundToTwoDecimalPlaces,
  generateMonths,
  generateYears
} from "../../helper";

import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { GROUPS, REVERT_GROUPS } from "../../config/constant";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const FIRST_PAYMENT_CLIENT_PAYMENT_PERCENTAGE = 5;
const SECOND_PAYMENT_CLIENT_PAYMENT_PERCENTAGE = 10;
const group = { 4: "- Presale", 2: "- Sales", 1: "- Admin" };

export default class IncentiveReport extends Component {
  constructor() {
    super();

    this.state = {
      showLoader: false,
      fromDate: null,
      toDate: null,
      searchterm: "",
      saleTeamMembers: [],
      incentives: [],
      totalLeadsCount: 0,
      selectedSalesTeamMember: {},
      childrenIncentives: [],
      totalAgreement: 0,
      totalBookedIncentive: 0,
      totalPartOnePaid: 0,
      totalPartTwoPaid: 0,
      totalPartThreePaid: 0,
      totalChildrenAgreement: 0,
      totalChildrenBookedIncentive: 0,
      totalChildrenPartOnePaid: 0,
      totalChildrenPartTwoPaid: 0,
      totalChildrenPartThreePaid: 0,
      totalChildA1BookingIncetive: 0,
      totalChildS1BookingIncetive: 0,
      totalPart1A1BookingIncetive: 0,
      totalPart2A1BookingIncetive: 0,
      totalPart3A1BookingIncetive: 0,
      totalPart1S1BookingIncetive: 0,
      totalPart2S1BookingIncetive: 0,
      totalPart3S1BookingIncetive: 0,
      reportMyteamLabel: "sale",
      parentTotalPaidAmount: 0,
      parentTotalDueAmount: 0,
      childTotalPaidAmount: 0,
      childTotalDueAmount: 0,
      totalCancellation: 0,
      totalMyTeamCancellation: 0,
      totalPreviewIncetive: 0,
      totalMyTeamPreviewIncetive: 0,
      totalParentBookingAmount: 0,
      totalChildBookingAmount: 0,
      months:generateMonths(),
      month: `0${Number(moment().format('MM')) - 1}`,
      years: generateYears(),
      year: moment().format('YYYY'),
    };
  }

  async componentDidMount() {
    var homesfy_lg = localStorage.getItem("homesfy_lg");
    if (homesfy_lg && homesfy_lg !== "") {
      let user = JSON.parse(window.atob(homesfy_lg));
      await this.setState({
        loggedInUser: user.id,
        role: user.role,
        fromDate: moment().subtract(1, "months").startOf("month"),
        toDate: moment().subtract(1, "months").endOf("month")
      });
    }
    if (
      ![
        "admin",
        "financehead",
        "financeanalyst",
        "financeassociate",
        "operationshead"
      ].includes(this.state.role)
    ) {
      this.setDefaulReport();
    }
  }

  checkDues = incentive => {
    if (
      incentive.sales_agreement_status &&
      incentive.sdr_payment_status &&
      incentive.registration_status &&
      incentive.eligible_for_invoice_status &&
      incentive.invoice_not_raised_status &&
      incentive.partial_invoice_raised_status &&
      incentive.invoice_raised_status &&
      incentive.invoice_cleared_status &&
      incentive.ClientPaymentPercentages.length &&
      incentive.ClientPaymentPercentages[0].percentage >=
      SECOND_PAYMENT_CLIENT_PAYMENT_PERCENTAGE
    ) {
      return [1, 2, 3];
    } else if (
      incentive.sdr_payment_status &&
      incentive.registration_status &&
      incentive.ClientPaymentPercentages.length &&
      incentive.ClientPaymentPercentages[0].percentage >=
      SECOND_PAYMENT_CLIENT_PAYMENT_PERCENTAGE
    ) {
      return [1, 2];
    } else if (
      incentive.ClientPaymentPercentages.length &&
      incentive.ClientPaymentPercentages[0].percentage >=
      FIRST_PAYMENT_CLIENT_PAYMENT_PERCENTAGE
    ) {
      return [1];
    } else {
      return [];
    }
  };

  getIncentiveReport = async (
    salesTeamMemberId,
    fromDate = null,
    toDate = null,
    year = null , 
    month = null
  ) => {
    let totalAgreement = 0;
    let totalBookedIncentive = 0;
    let totalPartOnePaid = 0;
    let totalPartTwoPaid = 0;
    let totalPartThreePaid = 0;
    let totalChildrenAgreement = 0;
    let totalChildrenBookedIncentive = 0;
    let totalChildrenPartOnePaid = 0;
    let totalChildrenPartTwoPaid = 0;
    let totalChildrenPartThreePaid = 0;
    let incentiveChildrenReport = [];
    let totalChildA1BookingIncetive = 0;
    let totalChildS1BookingIncetive = 0;
    let totalPart1A1BookingIncetive = 0;
    let totalPart2A1BookingIncetive = 0;
    let totalPart3A1BookingIncetive = 0;
    let totalPart1S1BookingIncetive = 0;
    let totalPart2S1BookingIncetive = 0;
    let totalPart3S1BookingIncetive = 0;
    let parentTotalPaidAmount = 0;
    let parentTotalDueAmount = 0;
    let childTotalPaidAmount = 0;
    let childTotalDueAmount = 0;
    let totalCancellation = 0;
    let totalPreviewIncetive = 0;
    let totalMyTeamCancellation = 0;
    let totalMyTeamPreviewIncetive = 0;
    let isPresale = 0;
    let totalParentBookingAmount = 0;
    let totalChildBookingAmount = 0;
    if (
      this.state.selectedSalesTeamMember &&
      this.state.selectedSalesTeamMember.is_presale
    ) {
      isPresale = 1;
    }
    let role_id = null;

    if (["sourcings3", "sourcings2", "magnet s2", "magnet s3"].includes(this.state.role)) {
      isPresale = 1;
    }
    if (
      this.state.selectedSalesTeamMember &&
      this.state.selectedSalesTeamMember.role_id
    ) {
      role_id = this.state.selectedSalesTeamMember.role_id;
    }
    let incentiveReport = await getIncentiveReport(
      salesTeamMemberId,
      fromDate,
      toDate,
      this.state.parent,
      isPresale,
      role_id,
      year
      ,month
    );
    if(!incentiveReport){
      this.setState({showLoader: false});
      return false;
    }  
    if (this.state.parent) {
      incentiveChildrenReport = [...incentiveReport.children];
      incentiveReport = incentiveReport.parent;
    }

    let incentives = incentiveReport.map(incentive => {
      let bookingIncentive = [];
      let dues = this.checkDues(incentive);
      let paidAmount = 0;
      let dueAmount = 0;

      let cancelledIncentive = _.findIndex(incentive.BookingIncentives, [
        "payment_phase",
        -1
      ]);

      cancelledIncentive =
        cancelledIncentive >= 0
          ? incentive.BookingIncentives.splice(cancelledIncentive, 1)
          : [];
      totalCancellation += cancelledIncentive.length
        ? cancelledIncentive[0].payment_amount
        : 0;
      let totalIncetiveDiff = 0;

      [0, 1, 2].forEach(key => {
        if (
          incentive.BookingIncentives &&
          incentive.BookingIncentives[key] &&
          incentive.BookingIncentives[key].payment_date &&
          (incentive.BookingIncentives[key].payment_amount ||
            incentive.BookingIncentives[key].payment_amount === 0)
        ) {
          bookingIncentive[key] = {
            amount: incentive.BookingIncentives[key].payment_amount,
            status: "PAID"
          };

          if (key === 0) {
            totalPartOnePaid +=
              incentive.is_lead_cancelled === 1
                ? 0
                : incentive.BookingIncentives[key].payment_amount;
            if (cancelledIncentive.length === 0) {
              totalIncetiveDiff +=
                incentive.BookingIncentives[key].payment_amount -
                incentive.totalCurrentPartOne;
            }
          } else if (key === 1) {
            totalPartTwoPaid +=
              incentive.is_lead_cancelled === 1
                ? 0
                : incentive.BookingIncentives[key].payment_amount;
            if (cancelledIncentive.length === 0) {
              totalIncetiveDiff +=
                incentive.BookingIncentives[key].payment_amount -
                incentive.totalCurrentPartTwo;
            }
          } else if (key === 2) {
            totalPartThreePaid +=
              incentive.is_lead_cancelled === 1
                ? 0
                : incentive.BookingIncentives[key].payment_amount;
            if (cancelledIncentive.length === 0) {
              totalIncetiveDiff +=
                incentive.BookingIncentives[key].payment_amount -
                incentive.totalCurrentPartThree;
            }
          }
          paidAmount += incentive.BookingIncentives[key]
            ? incentive.BookingIncentives[key].payment_amount
            : 0;
        } else if (dues.includes(key + 1)) {
          let dueFirstSecondPercentage = 20;
          let dueThirdPercentage = 60;
          if (
            incentive.PresaleRm &&
            REVERT_GROUPS.PRESALE === incentive.PresaleRm.group_id
          ) {
            dueFirstSecondPercentage = 40;
            dueThirdPercentage = 20;
          }
          bookingIncentive[key] = {
            amount:
              key === 0 || key === 1
                ? (incentive.booking_incentive * dueFirstSecondPercentage) / 100
                : (incentive.booking_incentive * dueThirdPercentage) / 100,
            status: "DUE"
          };
          dueAmount += bookingIncentive[key].amount
            ? bookingIncentive[key].amount
            : 0;
        } else {
          bookingIncentive[key] = {
            amount: "-",
            status: "NOT DUE"
          };
        }
      });
      if (totalIncetiveDiff) {
        totalCancellation += totalIncetiveDiff;
      }
      totalAgreement +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.agreement_value;
      totalBookedIncentive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.booking_incentive;
      /* let documentUploadStatus = {
        applicationForm: 0,
        costSheet: 0,
        swipeDetails: 0,
        emailDetails: 0,
        otherDoc: 0
      };
      if (incentive?.BookingDocuments?.length) {
        incentive.BookingDocuments.map(docObj => {
          switch (docObj.document_type) {
            case 0:
              documentUploadStatus.otherDoc = docObj.document_name ? 1 : 0;
              break;
            case 1:
              documentUploadStatus.applicationForm = docObj.document_name
                ? 1
                : 0;
              break;
            case 2:
              documentUploadStatus.costSheet = docObj.document_name ? 1 : 0;
              break;
            case 3:
              documentUploadStatus.swipeDetails = docObj.document_name ? 1 : 0;
              break;
            case 4:
              documentUploadStatus.emailDetails = docObj.document_name ? 1 : 0;
              break;
            default:
              break;
          }
        });
      } */
      if (incentive.BookingIncentives.length === 0) {
        dueAmount = incentive.booking_incentive;
      }
      parentTotalPaidAmount += paidAmount;
      parentTotalDueAmount += incentive.is_lead_cancelled === 1 ? 0 : dueAmount;
      totalPreviewIncetive += incentive.previous_booking_incentive;
      totalParentBookingAmount += incentive.booking_amount ? incentive.booking_amount : 0;
      return {
        lead_id: incentive.lead_id,
        parent_name: incentive.parent_info ? incentive.parent_info.name : "",
        name: incentive.Client.name,
        client_pyment_percentage: incentive.ClientPaymentPercentages.length
          ? incentive.ClientPaymentPercentages[0].percentage
          : 0,
        property: incentive.Project ? incentive.Project.name : null,
        config: incentive.flat_type,
        parent_id: incentive.parent_info ? incentive.parent_info.id : "",
        rmId: incentive.SalesRMBooking ? incentive.SalesRMBooking.id : "",
        agreementValue: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.agreement_value)
        ),
        eligibilityPercentage: incentive.eligibility_percentage,
        previousEligibilityPercentage:
          incentive.previous_eligibility_percentage,
        sdr: incentive.sdr_payment_status ? true : false,
        invoiceRaised: incentive.invoice_raised_status ? true : false,
        invoiceCleared: incentive.invoice_cleared_status ? true : false,
        bookedIncentive: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.booking_incentive)
        ),
        bookingDate: incentive.booking_date,
        incentive: bookingIncentive,
        is_magnet: incentive.is_magnet,
        incentive_magnet_split: incentive.incentive_magnet_split,
        magnet_percentage_split: incentive.magnet_percentage_split,
        special_discount: indianRupeeCommaSeparated(incentive.special_discount),
        paidAmount: paidAmount,
        dueAmount: dueAmount,
        cancelledIncentive: cancelledIncentive.length
          ? cancelledIncentive[0].payment_amount
          : totalIncetiveDiff,
        is_lead_cancelled: incentive.is_lead_cancelled,
        previous_booking_incentive: incentive.previous_booking_incentive,
        is_crosssale: incentive.is_crosssale,
        document_status: incentive.documet_status,
        booking_amount: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.booking_amount)
        )
      };
    });

    let childrenIncentives = incentiveChildrenReport.map(incentive => {
      let bookingIncentive = [];
      let dues = this.checkDues(incentive);
      /* let checkPartOne = false;
      let checkPartTwo = false;
      let checkPartThree = false; */
      let paidAmount = 0;
      let dueAmount = 0;
      if (incentive.BookingIncentives.length) {
        let IncentiveCal = [];
        incentive.BookingIncentives.map(item => {
          let partIndex = IncentiveCal.findIndex(
            index => index.payment_phase === item.payment_phase
          );
          if (partIndex != -1) {
            IncentiveCal[partIndex].payment_amount += item.payment_amount;
          } else {
            IncentiveCal.push(item);
          }
        });
        incentive.BookingIncentives = IncentiveCal;
      }
      let cancelledIncentive = _.findIndex(incentive.BookingIncentives, [
        "payment_phase",
        -1
      ]);
      cancelledIncentive =
        cancelledIncentive >= 0
          ? incentive.BookingIncentives.splice(cancelledIncentive, 1)
          : [];
      totalMyTeamCancellation += cancelledIncentive.length
        ? cancelledIncentive[0].payment_amount
        : 0;
      let totalIncetiveDiff = 0;

      [0, 1, 2].forEach(key => {
        if (
          incentive.BookingIncentives &&
          incentive.BookingIncentives[key] &&
          incentive.BookingIncentives[key].payment_amount &&
          incentive.BookingIncentives[key].payment_date
        ) {
          bookingIncentive[key] = {
            amount: incentive.BookingIncentives[key].payment_amount,
            status: "PAID"
          };
          paidAmount += incentive.BookingIncentives[key].payment_amount;
          if (key === 0 && cancelledIncentive.length === 0) {
            totalIncetiveDiff +=
              incentive.BookingIncentives[key].payment_amount -
              incentive.totalCurrentPartOne;
          } else if (key === 1 && cancelledIncentive.length === 0) {
            totalIncetiveDiff +=
              incentive.BookingIncentives[key].payment_amount -
              incentive.totalCurrentPartTwo;
          } else if (key === 2 && cancelledIncentive.length === 0) {
            totalIncetiveDiff +=
              incentive.BookingIncentives[key].payment_amount -
              incentive.totalCurrentPartThree;
          }
        } else if (dues.includes(key + 1)) {
          let dueFirstSecondPercentage = 20;
          let dueThirdPercentage = 60;
          if (
            incentive.PresaleRm &&
            REVERT_GROUPS.PRESALE === incentive.PresaleRm.group_id
          ) {
            dueFirstSecondPercentage = 40;
            dueThirdPercentage = 20;
          }
          bookingIncentive[key] = {
            amount:
              key === 0 || key === 1
                ? (incentive.booking_incentive * dueFirstSecondPercentage) / 100
                : (incentive.booking_incentive * dueThirdPercentage) / 100,
            status: "DUE"
          };
          dueAmount += bookingIncentive[key] ? bookingIncentive[key].amount : 0;
        } else {
          bookingIncentive[key] = {
            amount:
              incentive.BookingIncentives[key] &&
                incentive.BookingIncentives[key].payment_amount
                ? incentive.BookingIncentives[key].payment_amount
                : "-",
            status: "NOT DUE"
          };
          dueAmount +=
            incentive.BookingIncentives[key] &&
              incentive.BookingIncentives[key].amount != "-"
              ? incentive.BookingIncentives[key].payment_amount
              : 0;
        }
        if (incentive.BookingIncentives.length === 0) {
          dueAmount = incentive.booking_incentive;
        }
        // if (
        //   incentive.BookingIncentives &&
        //   incentive.BookingIncentives[key] &&
        //   incentive.BookingIncentives[key].payment_amount
        // ) {
        //   if (key === 0) {
        //     totalChildrenPartOnePaid +=
        //       incentive.BookingIncentives[key].payment_amount;
        //     checkPartOne = true;
        //   } else if (key === 1) {
        //     totalChildrenPartTwoPaid +=
        //       incentive.BookingIncentives[key].payment_amount;
        //     checkPartTwo = true;
        //   } else if (key === 2) {
        //     checkPartThree = true;
        //     totalChildrenPartThreePaid +=
        //       incentive.BookingIncentives[key].payment_amount;
        //   }
        // }
      });
      childTotalPaidAmount += paidAmount ? paidAmount : 0;
      childTotalDueAmount += incentive.is_lead_cancelled === 1 ? 0 : dueAmount;
      totalChildrenAgreement +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.agreement_value;
      totalChildrenBookedIncentive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.booking_incentive;
      //if (checkPartOne) {
      totalPart1A1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.par_1_a1_incentive;
      totalPart1S1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.par_1_s1_incentive;
      //}
      // if(!incentive.BookingIncentives.length){
      //   totalChildrenPartOnePaid += incentive.par_1_a1_incentive + incentive.par_1_s1_incentive;
      // }
      //if (checkPartTwo) {
      totalPart2A1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.par_2_a1_incentive;
      totalPart2S1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.par_2_s1_incentive;
      //}
      // if(!incentive.BookingIncentives.length){
      //   totalChildrenPartTwoPaid += incentive.par_2_a1_incentive + incentive.par_2_s1_incentive;
      // }
      // if (checkPartThree) {
      totalPart3A1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.par_3_a1_incentive;
      totalPart3S1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.par_3_s1_incentive;
      //}
      // if(!incentive.BookingIncentives.length){
      //   totalChildrenPartThreePaid += incentive.par_3_a1_incentive + incentive.par_3_s1_incentive;
      // }
      totalChildA1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.booking_a1_incentive;
      totalChildS1BookingIncetive +=
        incentive.is_lead_cancelled === 1 ? 0 : incentive.booking_s1_incentive;
      /* let documentUploadStatus = {
        applicationForm: 0,
        costSheet: 0,
        swipeDetails: 0,
        emailDetails: 0,
        otherDoc: 0
      };
      if (incentive?.BookingDocuments?.length) {
        incentive.BookingDocuments.map(docObj => {
          switch (docObj.document_type) {
            case 0:
              if (documentUploadStatus.otherDoc === 0) {
                documentUploadStatus.otherDoc = docObj.document_name ? 1 : 0;
              }
              break;
            case 1:
              documentUploadStatus.applicationForm = docObj.document_name
                ? 1
                : 0;
              break;
            case 2:
              documentUploadStatus.costSheet = docObj.document_name ? 1 : 0;
              break;
            case 3:
              documentUploadStatus.swipeDetails = docObj.document_name ? 1 : 0;
              break;
            case 4:
              documentUploadStatus.emailDetails = docObj.document_name ? 1 : 0;
              break;
            default:
              break;
          }
        });
      } */
      if (totalIncetiveDiff) {
        totalMyTeamCancellation += totalIncetiveDiff;
      }
      totalMyTeamPreviewIncetive += incentive.previous_booking_incentive;
      totalChildBookingAmount += incentive.booking_amount ? incentive.booking_amount : 0;
      return {
        lead_id: incentive.lead_id,
        name: incentive.Client.name,
        client_pyment_percentage: incentive.ClientPaymentPercentages.length
          ? incentive.ClientPaymentPercentages[0].percentage
          : 0,
        property: incentive.Project ? incentive.Project.name : null,
        config: incentive.flat_type,
        parent_name: incentive.parent_info ? incentive.parent_info.name : "",
        parent_id: incentive.parent_info ? incentive.parent_info.id : "",
        rmName: incentive.SalesRMBooking ? incentive.SalesRMBooking.name : "",
        rmId: incentive.SalesRMBooking ? incentive.SalesRMBooking.id : "",
        agreementValue: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.agreement_value)
        ),
        eligibilityPercentage: incentive.eligibility_percentage,
        sdr: incentive.sdr_payment_status ? true : false,
        invoiceRaised: incentive.invoice_raised_status ? true : false,
        invoiceCleared: incentive.invoice_cleared_status ? true : false,
        bookedIncentive: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.booking_incentive)
        ),
        bookingDate: incentive.booking_date,
        incentive: bookingIncentive,
        booking_a1_incentive: incentive.booking_a1_incentive,
        booking_s1_incentive: incentive.booking_s1_incentive,
        is_magnet: incentive.is_magnet,
        incentive_magnet_split: incentive.incentive_magnet_split,
        magnet_percentage_split: incentive.magnet_percentage_split,
        special_discount: indianRupeeCommaSeparated(incentive.special_discount),
        par_1_a1_incentive: incentive.par_1_a1_incentive,
        par_2_a1_incentive: incentive.par_2_a1_incentive,
        par_3_a1_incentive: incentive.par_3_a1_incentive,
        par_1_s1_incentive: incentive.par_1_s1_incentive,
        par_2_s1_incentive: incentive.par_2_s1_incentive,
        par_3_s1_incentive: incentive.par_3_s1_incentive,
        paidAmount: paidAmount,
        dueAmount: dueAmount,
        is_lead_cancelled: incentive.is_lead_cancelled,
        previousEligibilityPercentage:
          incentive.previous_eligibility_percentage,
        cancelledIncentive: cancelledIncentive.length
          ? cancelledIncentive[0].payment_amount
          : totalIncetiveDiff,
        previous_booking_incentive: incentive.previous_booking_incentive,
        is_crosssale: incentive.is_crosssale,
        document_status: incentive.documet_status,
        booking_amount: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.booking_amount)
        )
      };
    });

    let myTotalColumn = {
      Agreement_Value: totalAgreement,
      Booking_Amount: totalParentBookingAmount,
      Previous_Booked_Incentive: totalPreviewIncetive,
      Booked_Incentive: totalBookedIncentive,
      Paid_Amount: parentTotalPaidAmount,
      Due_Amount: parentTotalDueAmount,
      Part1: totalPartOnePaid,
      Part2: totalPartTwoPaid,
      Part3: totalPartThreePaid,
      Total_Cancellation: totalCancellation,
    };
    let my_total_incentive_report = {};
    for (const [key, value] of Object.entries(myTotalColumn)) {
      my_total_incentive_report[key] = indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(value)
      );
    }

    let myteamTotalColumn = {
      Agreement_Value: totalChildrenAgreement,
      Booking_Amount: totalChildBookingAmount,
      Previous_Booked_Incentive: totalMyTeamPreviewIncetive,
      Booked_Incentive: totalChildrenBookedIncentive,
      Paid_Amount: childTotalPaidAmount,
      Due_Amount: childTotalDueAmount,
      Booked_A1_Incentive: totalChildA1BookingIncetive,
      Booked_S1_Incentive: totalChildS1BookingIncetive,
      Part1_A1: totalPart1A1BookingIncetive,
      Part1_S1: totalPart1S1BookingIncetive,
      Part1_Total: totalChildrenPartOnePaid,
      Part2_A1: totalPart2A1BookingIncetive,
      Part2_S1: totalPart2S1BookingIncetive,
      Part2_Total: totalChildrenPartTwoPaid,
      Part3_A1: totalPart3A1BookingIncetive,
      Part3_S1: totalPart3S1BookingIncetive,
      Part3_Total: totalChildrenPartThreePaid,
      Total_Cancellation: totalMyTeamCancellation,
    };
    let myteam_total_incentive_report = {};
    for (const [key, value] of Object.entries(myteamTotalColumn)) {
      myteam_total_incentive_report[key] = indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(value)
      );
    }

    this.setState({
      my_incentive_report: this.downloadMyIncentiveData(
        incentives,
        my_total_incentive_report
      ),
      myteam_incentive_report: this.downloadMyteamIncentiveData(
        childrenIncentives,
        myteam_total_incentive_report
      ),
      incentives,
      childrenIncentives,
      totalLeadsCount: incentives.length,
      totalAgreement,
      totalBookedIncentive,
      totalPartOnePaid,
      totalPartTwoPaid,
      totalPartThreePaid,
      totalChildrenAgreement,
      totalChildrenBookedIncentive,
      totalChildrenPartOnePaid,
      totalChildrenPartTwoPaid,
      totalChildrenPartThreePaid,
      showLoader: false,
      totalChildA1BookingIncetive: totalChildA1BookingIncetive,
      totalChildS1BookingIncetive: totalChildS1BookingIncetive,
      totalPart1A1BookingIncetive: totalPart1A1BookingIncetive,
      totalPart2A1BookingIncetive: totalPart2A1BookingIncetive,
      totalPart3A1BookingIncetive: totalPart3A1BookingIncetive,
      totalPart1S1BookingIncetive: totalPart1S1BookingIncetive,
      totalPart2S1BookingIncetive: totalPart2S1BookingIncetive,
      totalPart3S1BookingIncetive: totalPart3S1BookingIncetive,
      parentTotalPaidAmount: parentTotalPaidAmount,
      parentTotalDueAmount: parentTotalDueAmount,
      childTotalPaidAmount: childTotalPaidAmount,
      childTotalDueAmount: childTotalDueAmount,
      totalMyTeamCancellation: totalMyTeamCancellation,
      totalMyTeamPreviewIncetive: totalMyTeamPreviewIncetive,
      totalCancellation,
      totalPreviewIncetive,
      totalParentBookingAmount,
      totalChildBookingAmount
    });
  };

  generateCompleteIncentiveReport = async (fromDate, toDate, year, month) => {
    this.setState({ showLoader: true });
    let incentiveReport = await getCompleteIncentiveReport(
      fromDate,
      toDate,
      "sales",
      this.state.year,
      this.state.month
    );

    let downloadSalesIncentives = incentiveReport[0]?.data?.map(incentive => {
      let cancelledIncentive = _.findIndex(incentive.BookingIncentives, [
        "payment_phase",
        -1
      ]);
      cancelledIncentive =
        cancelledIncentive >= 0
          ? incentive.BookingIncentives.splice(cancelledIncentive, 1)
          : [];

      let s1CalculatedIncentive = {};
      let a1CalculatedIncentive = {};
      if (incentive.BookingIncentives?.length) {
        let s1Incentives = _.sortBy(
          incentive.BookingIncentives.filter(
            o => o.user_id === incentive.SalesRMBooking.id
          ),
          ["payment_phase"]
        );
        s1CalculatedIncentive = this.getIncentives(
          incentive,
          s1Incentives,
          incentive.booking_s1_incentive
        );

        let a1Incentives = _.sortBy(
          incentive.BookingIncentives.filter(
            o => o.user_id === incentive.ParentRMBooking?.id
          ),
          ["payment_phase"]
        );
        a1CalculatedIncentive = this.getIncentives(
          incentive,
          a1Incentives,
          incentive.booking_a1_incentive
        );
      } else {
        s1CalculatedIncentive = this.getIncentives(
          incentive,
          [],
          incentive.booking_s1_incentive
        );
        a1CalculatedIncentive = this.getIncentives(
          incentive,
          [],
          incentive.booking_a1_incentive
        );
      }

      return {
        lead_id: incentive.lead_id,
        parent_name: incentive.parent_info ? incentive.parent_info.name : "",
        name: incentive.Client.name,
        client_pyment_percentage: incentive.ClientPaymentPercentages.length
          ? incentive.ClientPaymentPercentages[0].percentage
          : 0,
        property: incentive.Project ? incentive.Project.name : null,
        config: incentive.flat_type,
        agreementValue: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.agreement_value)
        ),
        booking_amount: incentive.booking_amount,
        eligibilityPercentage: incentive.eligibility_percentage,
        previousEligibilityPercentage:
          incentive.previous_eligibility_percentage,
        sdr: incentive.sdr_payment_status ? true : false,
        invoiceRaised: incentive.invoice_raised_status ? true : false,
        invoiceCleared: incentive.invoice_cleared_status ? true : false,
        bookedIncentive: indianRupeeCommaSeparated(
          roundToTwoDecimalPlaces(incentive.booking_incentive)
        ),
        bookingDate: incentive.booking_date,
        is_magnet: incentive.is_magnet,
        magnet_percentage_split: incentive.magnet_percentage_split,
        incentive_magnet_split: incentive.incentive_magnet_split,
        special_discount: indianRupeeCommaSeparated(incentive.special_discount),
        S1_Name: incentive.SalesRMBooking?.name || "",
        S1_Paid_Amount: s1CalculatedIncentive.paidAmount || 0,
        S1_Due_Amount: s1CalculatedIncentive.dueAmount || 0,
        S1_Part_1: s1CalculatedIncentive.bookingIncentive[0]?.amount,
        S1_Part_1_Status: s1CalculatedIncentive.bookingIncentive[0]?.status,
        S1_Part_2: s1CalculatedIncentive.bookingIncentive[1]?.amount,
        S1_Part_2_Status: s1CalculatedIncentive.bookingIncentive[1]?.status,
        S1_Part_3: s1CalculatedIncentive.bookingIncentive[2]?.amount,
        S1_Part_3_Status: s1CalculatedIncentive.bookingIncentive[2]?.status,
        A1_Name: incentive.ParentRMBooking?.name || "",
        A1_Paid_Amount: a1CalculatedIncentive.paidAmount || 0,
        A1_Due_Amount: a1CalculatedIncentive.dueAmount || 0,
        A1_Part_1: a1CalculatedIncentive.bookingIncentive[0]?.amount,
        A1_Part_1_Status: a1CalculatedIncentive.bookingIncentive[0]?.status,
        A1_Part_2: a1CalculatedIncentive.bookingIncentive[1]?.amount,
        A1_Part_2_Status: a1CalculatedIncentive.bookingIncentive[1]?.status,
        A1_Part_3: a1CalculatedIncentive.bookingIncentive[2]?.amount,
        A1_Part_3_Status: a1CalculatedIncentive.bookingIncentive[2]?.status,
        cancelledIncentive: cancelledIncentive.length
          ? cancelledIncentive[0].payment_amount
          : 0,
        is_lead_cancelled: incentive.is_lead_cancelled,
        previous_booking_incentive: incentive.previous_booking_incentive,
        is_crosssale: incentive.is_crosssale,
        document_status: incentive.documet_status,
      };
    });

    let downloadPresalesIncentives = incentiveReport[1]?.data?.map(
      incentive => {
        let cancelledIncentive = _.findIndex(incentive.BookingIncentives, [
          "payment_phase",
          -1
        ]);
        cancelledIncentive =
          cancelledIncentive >= 0
            ? incentive.BookingIncentives.splice(cancelledIncentive, 1)
            : [];

        let s3CalculatedIncentive = {};
        let s2CalculatedIncentive = {};
        if (incentive.BookingIncentives?.length) {
          let s3Incentives = _.sortBy(
            incentive.BookingIncentives.filter(
              o => o.user_id === incentive.SalesRMBooking.id
            ),
            ["payment_phase"]
          );
          s3CalculatedIncentive = this.getIncentives(
            incentive,
            s3Incentives,
            incentive.booking_s1_incentive
          );

          let s2Incentives = _.sortBy(
            incentive.BookingIncentives.filter(
              o => o.user_id === incentive.parent_info.id
            ),
            ["payment_phase"]
          );
          s2CalculatedIncentive = this.getIncentives(
            incentive,
            s2Incentives,
            incentive.booking_a1_incentive
          );
        } else {
          s3CalculatedIncentive = this.getIncentives(
            incentive,
            [],
            incentive.booking_s1_incentive
          );
          s2CalculatedIncentive = this.getIncentives(
            incentive,
            [],
            incentive.booking_a1_incentive
          );
        }

        return {
          lead_id: incentive.lead_id,
          parent_name: incentive.parent_info ? incentive.parent_info.name : "",
          name: incentive.Client.name,
          client_pyment_percentage: incentive.ClientPaymentPercentages.length
            ? incentive.ClientPaymentPercentages[0].percentage
            : 0,
          property: incentive.Project ? incentive.Project.name : null,
          config: incentive.flat_type,
          agreementValue: indianRupeeCommaSeparated(
            roundToTwoDecimalPlaces(incentive.agreement_value)
          ),
          booking_amount: incentive.booking_amount,
          eligibilityPercentage: incentive.eligibility_percentage,
          previousEligibilityPercentage:
            incentive.previous_eligibility_percentage,
          sdr: incentive.sdr_payment_status ? true : false,
          invoiceRaised: incentive.invoice_raised_status ? true : false,
          invoiceCleared: incentive.invoice_cleared_status ? true : false,
          bookedIncentive: indianRupeeCommaSeparated(
            roundToTwoDecimalPlaces(incentive.booking_incentive)
          ),
          bookingDate: incentive.booking_date,
          is_magnet: incentive.is_magnet,
          magnet_percentage_split: incentive.magnet_percentage_split,
          incentive_magnet_split: incentive.incentive_magnet_split,
          special_discount: indianRupeeCommaSeparated(
            incentive.special_discount
          ),
          S3_Name: incentive.PresaleRm?.name || "",
          S3_Paid_Amount: s3CalculatedIncentive.paidAmount || 0,
          S3_Due_Amount: s3CalculatedIncentive.dueAmount || 0,
          S3_Part_1: s3CalculatedIncentive.bookingIncentive[0]?.amount,
          S3_Part_1_Status: s3CalculatedIncentive.bookingIncentive[0]?.status,
          S3_Part_2: s3CalculatedIncentive.bookingIncentive[1]?.amount,
          S3_Part_2_Status: s3CalculatedIncentive.bookingIncentive[1]?.status,
          S3_Part_3: s3CalculatedIncentive.bookingIncentive[2]?.amount,
          S3_Part_3_Status: s3CalculatedIncentive.bookingIncentive[2]?.status,
          S2_Name: incentive.ParentPresaleRMBooking?.name || "",
          S2_Paid_Amount: s2CalculatedIncentive.paidAmount || 0,
          S2_Due_Amount: s2CalculatedIncentive.dueAmount || 0,
          S2_Part_1: s2CalculatedIncentive.bookingIncentive[0]?.amount,
          S2_Part_1_Status: s2CalculatedIncentive.bookingIncentive[0]?.status,
          S2_Part_2: s2CalculatedIncentive.bookingIncentive[1]?.amount,
          S2_Part_2_Status: s2CalculatedIncentive.bookingIncentive[1]?.status,
          S2_Part_3: s2CalculatedIncentive.bookingIncentive[2]?.amount,
          S2_Part_3_Status: s2CalculatedIncentive.bookingIncentive[2]?.status,
          cancelledIncentive: cancelledIncentive.length
            ? cancelledIncentive[0].payment_amount
            : 0,
          is_lead_cancelled: incentive.is_lead_cancelled,
          previous_booking_incentive: incentive.previous_booking_incentive,
          is_crosssale: incentive.is_crosssale,
          document_status: incentive.documet_status
        };
      }
    );

    this.setState(
      {
        downloadSalesIncentives,
        downloadPresalesIncentives
      },
      () => {
        let downloadSalesReport = document.getElementById(
          "download-sales-report"
        );

        let downloadPresalesReport = document.getElementById(
          "download-presales-report"
        );
        setTimeout(() => {
          downloadSalesReport && downloadSalesReport.click();
          downloadPresalesReport && downloadPresalesReport.click();
          this.setState({
            downloadSalesIncentives: [],
            downloadPresalesIncentives: [],
            showLoader: false
          });
        }, 20000);
      }
    );
  };

  getIncentives = (incentive, bookingIncentives, partIncentive) => {
    let bookingIncentive = [];
    let paidAmount = 0;
    let dueAmount = 0;

    let dues = this.checkDues(incentive);

    [0, 1, 2].forEach(key => {
      if (
        bookingIncentives &&
        bookingIncentives[key] &&
        bookingIncentives[key].payment_date &&
        (bookingIncentives[key].payment_amount ||
          bookingIncentives[key].payment_amount === 0)
      ) {
        bookingIncentive[key] = {
          amount: bookingIncentives[key].payment_amount,
          status: "PAID"
        };

        paidAmount += bookingIncentives[key]
          ? bookingIncentives[key].payment_amount
          : 0;
      } else if (dues.includes(key + 1)) {
        let dueFirstSecondPercentage = 20;
        let dueThirdPercentage = 60;
        if (
          incentive.PresaleRm &&
          REVERT_GROUPS.PRESALE === incentive.PresaleRm.group_id
        ) {
          dueFirstSecondPercentage = 40;
          dueThirdPercentage = 20;
        }
        bookingIncentive[key] = {
          amount:
            key === 0 || key === 1
              ? (partIncentive * dueFirstSecondPercentage) / 100
              : (partIncentive * dueThirdPercentage) / 100,
          status: "DUE"
        };
        dueAmount += bookingIncentive[key].amount
          ? bookingIncentive[key].amount
          : 0;
      } else {
        bookingIncentive[key] = {
          amount: "-",
          status: "NOT DUE"
        };
      }
    });

    return { paidAmount, dueAmount, bookingIncentive };
  };

  getSalesTeamMembers = async q => {
    let foundSalesTeamMembers = await getSalesTeamMembers(q, this.state.parent);

    this.setState({
      saleTeamMembers: foundSalesTeamMembers ? foundSalesTeamMembers : []
    });
  };

  callTeam = async e => {
    this.setState(
      {
        parent: e.target.checked ? 1 : 0,
        saleTeamMembers: [],
        incentives: [],
        // selectedSalesTeamMember: {},
        totalLeadsCount: 0,
        childrenIncentives: []
      },
      () => {
        if (
          this.state.searchterm !== "" &&
          this.state.selectedSalesTeamMember &&
          this.state.selectedSalesTeamMember.hasOwnProperty("sales_rm")
        ) {
          this.setState({ showLoader: true });
          if (this.state.selectedSalesTeamMember.parent_rm) {
            this.getIncentiveReport(
              this.state.selectedSalesTeamMember.parent_rm,
              this.state.fromDate
                ? moment(this.state.fromDate).format("YYYY-MM-DD")
                : null,
              this.state.toDate
                ? moment(this.state.toDate).format("YYYY-MM-DD")
                : null,
                this.state.year,
                this.state.month
            );
          } else {
            this.getIncentiveReport(
              this.state.selectedSalesTeamMember.sales_rm,
              this.state.fromDate
                ? moment(this.state.fromDate).format("YYYY-MM-DD")
                : null,
              this.state.toDate
                ? moment(this.state.toDate).format("YYYY-MM-DD")
                : null,
                this.state.year,
                this.state.month
            );
          }
        }
      }
    );
  };

  filterSalesTeamMembers = e => {
    if (e.target.value) {
      this.setState({ searchterm: e.target.value });
      this.getSalesTeamMembers(e.target.value);
    } else {
      this.setState({ searchterm: "", saleTeamMembers: [] });
    }
  };

  onSalesTeamMemberSelect = value => {
    let incetiveType = null;
    if (value && typeof value === "object") {
      if (
        GROUPS[value["PresaleRm.group_id"]] === "PRESALE" ||
        GROUPS[value["ParentPresaleRMBooking.group_id"]] === "PRESALE" ||
        GROUPS[value["CrossSalesRMBooking.group_id"]] === "PRESALE"
      ) {
        incetiveType = "presale";
      } else {
        incetiveType = "sale";
      }
    }
    if (value && !value.parent_rm && value.cross_sale_parent_id) {
      value.parent_rm = value.cross_sale_parent_id;
    }
    if (value && !value.sales_rm && value.cross_rm_id_from) {
      value.sales_rm = value.cross_rm_id_from;
    }
    if (value && !value.parent_rm && value.parent_presale) {
      value.parent_rm = value.parent_presale;
      value.is_presale = 1;
    }
    if (value && !value.sales_rm && value.presale_rm) {
      value.sales_rm = value.presale_rm;
      value.is_presale = 1;
    }
    if (value && value.is_presale && value.parent_presale) {
      value.role_id = value["ParentPresaleRMBooking.role_id"];
    } else if (value && value.parent_rm) {
      value.role_id = value["ParentRMBooking.role_id"];
    } else if (value && (value.parent_rm || value.parent_presale)) {
      value.role_id = value["CrossParentRMBooking.role_id"];
    }

    if (value && value.sales_rm && value["SalesRMBooking.role_id"]) {
      value.role_id = value["SalesRMBooking.role_id"];
    } else if (value && value.presale_rm && value["PresaleRm.role_id"]) {
      value.role_id = value["PresaleRm.role_id"];
    } else if (value && value.cross_rm_id_from && value["CrossSalesRMBooking.role_id"]) {
      value.role_id = value["CrossSalesRMBooking.role_id"];
    }

    if (value) {
      this.setState(
        {
          selectedSalesTeamMember: value,
          showLoader: true,
          reportMyteamLabel: incetiveType
        },
        () => {
          if (this.state.parent) {
            this.getIncentiveReport(
              this.state.selectedSalesTeamMember.parent_rm,
              this.state.fromDate
                ? moment(this.state.fromDate).format("YYYY-MM-DD")
                : null,
              this.state.toDate
                ? moment(this.state.toDate).format("YYYY-MM-DD")
                : null,
                this.state.year,
                this.state.month
            );
          } else {
            this.getIncentiveReport(
              this.state.selectedSalesTeamMember.sales_rm,
              this.state.fromDate
                ? moment(this.state.fromDate).format("YYYY-MM-DD")
                : null,
              this.state.toDate
                ? moment(this.state.toDate).format("YYYY-MM-DD")
                : null,
                this.state.year,
                this.state.month
            );
          }
        }
      );
    } else {
      this.setState({
        selectedSalesTeamMember: null,
        incentives: [],
        totalLeadsCount: 0,
        childrenIncentives: []
      });
    }
  };

  downloadCompleteReport = () => {
    this.generateCompleteIncentiveReport(
      moment(this.state.fromDate).format("YYYY-MM-DD"),
      moment(this.state.toDate).format("YYYY-MM-DD"),
      this.state.year,
      this.state.month
    );
  };

  searchBookingIncentives = () => {
    if (Object.keys(this.state.selectedSalesTeamMember).length) {
      this.setState({ showLoader: true }, () => {
        if ((this.state.fromDate && this.state.toDate) || (this.state.year && this.state.month)) {
          this.getIncentiveReport(
            this.state.selectedSalesTeamMember.parent_rm
              ? this.state.selectedSalesTeamMember.parent_rm
              : this.state.selectedSalesTeamMember.sales_rm,
            this.state.fromDate
              ? moment(this.state.fromDate).format("YYYY-MM-DD")
              : null,
            this.state.toDate
              ? moment(this.state.toDate).format("YYYY-MM-DD")
              : null,
            this.state.year ,
            this.state.month  
          );
        } else {
          this.getIncentiveReport(
            this.state.loggedInUser
              ? this.state.selectedSalesTeamMember.parent_rm
              : this.state.selectedSalesTeamMember.sales_rm,
            null,
            null,
            this.state.year,
            this.state.month
          );
        }
      });
    } else if (["closings1", "sourcings3", "magnet s3"].includes(this.state.role)) {
      if ((this.state.fromDate && this.state.toDate) || (this.state.year && this.state.month)) {
        let incetiveType = ["sourcings3"].includes(this.state.role)
          ? "presale"
          : "sale";
        this.setState(
          { showLoader: true, reportMyteamLabel: incetiveType },
          () => {
            this.getIncentiveReport(
              this.state.loggedInUser,
              moment(this.state.fromDate).format("YYYY-MM-DD"),
              moment(this.state.toDate).format("YYYY-MM-DD"),
              this.state.year,this.state.month
            );
          }
        );

      }
    }
  };

  setDefaulReport = () => {
    let userId = null;
    if (
      ![
        "admin",
        "financehead",
        "financeanalyst",
        "financeassociate",
        "operationshead"
      ].includes(this.state.role)
    ) {
      userId = this.state.loggedInUser;
    }
    if (userId) {
      this.getIncentiveReport(
        userId,
        this.state.fromDate
          ? moment(this.state.fromDate).format("YYYY-MM-DD")
          : null,
        this.state.toDate
          ? moment(this.state.toDate).format("YYYY-MM-DD")
          : null,
        this.state.year,
        this.state.month  
      );
    }
  };

  downloadMyIncentiveData = (data, totalData) => {
    let my_incentive_report = data.map(res => ({
      Lead_Id: res.lead_id,
      Name: res.name,
      Client_Paymet_Percent: res.client_pyment_percentage,
      Property: res.property,
      Config: res.config,
      Parent_id: res.parent_id,
      Rm_Id: res.rmId,
      Parent_Name: res.parent_name,
      Booking_Date: res.bookingDate,
      Agreement_Value: res.agreementValue,
      Booking_Amount: res.booking_amount,
      Cross_Sale_Lead: res.is_crosssale ? "Yes" : "No",
      Magnet: res.is_magnet ? "Yes" : "No",
      Magnet_Percent: res.magnet_percentage_split,
      Passback: res.special_discount,
      Previous_Eligibility_Percent: res.previousEligibilityPercentage,
      Eligible_Percent: res.eligibilityPercentage,
      SDR: res.sdr ? "YES" : "NO",
      Invoice_Raised: res.invoiceRaised ? "YES" : "NO",
      Invoice_Cleared: res.invoiceCleared ? "YES" : "NO",
      Previous_Booked_Incentive: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.previous_booking_incentive)
      ),
      Booked_Incentive: res.bookedIncentive,
      Paid_Amount: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.paidAmount)
      ),
      Due_Amount: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.dueAmount)
      ),

      Part1:
        res.incentive[0].amount === "-"
          ? res.incentive[0].amount
          : indianRupeeCommaSeparated(
            roundToTwoDecimalPlaces(res.incentive[0].amount)
          ),
      Part1_Status: res.incentive[0].status,
      Part2:
        res.incentive[1].amount === "-"
          ? res.incentive[1].amount
          : indianRupeeCommaSeparated(
            roundToTwoDecimalPlaces(res.incentive[1].amount)
          ),
      Part2_Status: res.incentive[1].status,
      Part3:
        res.incentive[2].amount === "-"
          ? res.incentive[2].amount
          : indianRupeeCommaSeparated(
            roundToTwoDecimalPlaces(res.incentive[2].amount)
          ),
      Part3_Status: res.incentive[2].status,
      Total_Cancellation: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.cancelledIncentive)
      ),
      Document_Status: `${res.document_status}`
    }));
    my_incentive_report = [...my_incentive_report, totalData];

    return my_incentive_report;
  };

  downloadMyteamIncentiveData = (data, totalData) => {
    let booking_a1_incentive = `Booked_${this.state.reportMyteamLabel === "presale" ? "S2" : "A1"
      }_Incentive`;
    let booking_s1_incentive = `Booked_${this.state.reportMyteamLabel === "presale" ? "S3" : "S1"
      }_Incentive`;
    let par_1_a1_incentive = `Part1_${this.state.reportMyteamLabel === "presale" ? "S2" : "A1"
      }`;
    let par_1_s1_incentive = `Part1_${this.state.reportMyteamLabel === "presale" ? "S3" : "S1"
      }`;
    let par_2_a1_incentive = `Part2_${this.state.reportMyteamLabel === "presale" ? "S2" : "A1"
      }`;
    let par_2_s1_incentive = `Part2_${this.state.reportMyteamLabel === "presale" ? "S3" : "S1"
      }`;
    let par_3_a1_incentive = `Part3_${this.state.reportMyteamLabel === "presale" ? "S2" : "A1"
      }`;
    let par_3_s1_incentive = `Part3_${this.state.reportMyteamLabel === "presale" ? "S3" : "S1"
      }`;

    let myteam_incentive_report = data.map(res => ({
      Lead_Id: res.lead_id,
      Name: res.name,
      Client_Paymet_Percent: res.client_pyment_percentage,
      Property: res.property,
      Config: res.config,
      Parent_id: res.parent_id,
      Parent_Name: res.parent_name,
      RM_Name: res.rmName,
      Rm_Id: res.rmId,
      Booking_Date: res.bookingDate,
      Agreement_Value: res.agreementValue,
      Booking_Amount: res.booking_amount,
      Cross_Sale_Lead: res.is_crosssale ? "Yes" : "No",
      Magnet: res.is_magnet ? "Yes" : "No",
      Magnet_Percent: res.magnet_percentage_split,
      Passback: res.special_discount,
      Previous_Eligibility_Percent: res.previousEligibilityPercentage,
      Eligible_Percent: res.eligibilityPercentage,
      SDR: res.sdr ? "YES" : "NO",
      Invoice_Raised: res.invoiceRaised ? "YES" : "NO",
      Invoice_Cleared: res.invoiceCleared ? "YES" : "NO",
      Previous_Booked_Incentive: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.previous_booking_incentive)
      ),
      Booked_Incentive: res.bookedIncentive,
      Paid_Amount: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.paidAmount)
      ),
      Due_Amount: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.dueAmount)
      ),

      [booking_a1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.booking_a1_incentive)
      ),
      [booking_s1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.booking_s1_incentive)
      ),
      [par_1_a1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.par_1_a1_incentive)
      ),
      [par_1_s1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.par_1_s1_incentive)
      ),
      Part1_Total: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.incentive[0].amount)
      ),
      Part1_Status: res.incentive[0].status,
      [par_2_a1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.par_2_a1_incentive)
      ),
      [par_2_s1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.par_2_s1_incentive)
      ),
      Part2_Total: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.incentive[1].amount)
      ),
      Part2_Status: res.incentive[1].status,
      [par_3_a1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.par_3_a1_incentive)
      ),
      [par_3_s1_incentive]: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.par_3_s1_incentive)
      ),
      Part3_Total: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.incentive[2].amount)
      ),
      Part3_Status: res.incentive[2].status,

      Total_Cancellation: indianRupeeCommaSeparated(
        roundToTwoDecimalPlaces(res.cancelledIncentive)
      ),
      Document_Status: `${res.document_status}`,
    }));

    myteam_incentive_report = [...myteam_incentive_report, totalData];
    return myteam_incentive_report;
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    return (
      <>
        <Loader show={this.state.showLoader} />
        <div className="container-fluid mt-3">
          <div className="row pt-2">
            <div className="col-xl-1 pt-xl-2 d-none d-sm-block pr-0">
              Total: {this.state.totalLeadsCount}
            </div>
            <div className="col-xl-4">
              <div className="row">
                {/* <div className="col-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd-MM-yyyy"
                      margin="normal"
                      value={this.state.fromDate ? this.state.fromDate : null}
                      autoOk={true}
                      onChange={date => {
                        this.setState({ fromDate: date });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      style={{ width: "100%" }}
                      placeholder="From Date"
                    />
                  </MuiPickersUtilsProvider>
                </div> */}
                {/* <div className="col-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd-MM-yyyy"
                      margin="normal"
                      value={this.state.toDate ? this.state.toDate : null}
                      autoOk={true}
                      onChange={date => {
                        this.setState({ toDate: date });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      style={{ width: "100%" }}
                      placeholder="To Date"
                    />
                  </MuiPickersUtilsProvider>
                </div> */}
                <div className="col-6">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      Year
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={this.state.year}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "year",
                        id: "year"
                      }}
                      className={"mt-0"}
                    >
                      {this.state.years.map(year => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                     Month
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={this.state.month}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "month",
                        id: "month"
                      }}
                      className={"mt-0"}
                    >
                      {this.state.months.map(month => (
                        <MenuItem key={month.key} value={month.key}>
                          {month.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="col-xl-3 pb-xl-0 pb-4 pt-xl-0 pt-2">
              <div className="row">
                <div className="col-md-5 col-6">
                  <button
                    type="submit"
                    className="btn btn-success text-capitalize w-100"
                    onClick={this.searchBookingIncentives}
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-7 col-6 px-md-0">
                  {(window.$user.group === "finance" ||
                    window.$user.role === "admin") && (
                      <button
                        type="submit"
                        className="btn btn-success text-capitalize w-100"
                        onClick={() => this.downloadCompleteReport()}
                      >
                        Download All
                      </button>
                    )}
                </div>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="row">
                {!["sourcings3", "magnet s3", "closings1"].includes(this.state.role) && (
                  <div className="col-4 pt-2 pr-0">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        onChange={e => {
                          this.callTeam(e);
                        }}
                        checked={this.state.parent}
                      />
                      <label className="form-check-label" htmlFor="defaultCheck1">
                        My Team
                      </label>
                    </div>
                  </div>
                )}
                <div className="col-8">
                  {!["sourcings3", "magnet s3", "closings1"].includes(this.state.role) && (
                    <Autocomplete
                      options={this.state.saleTeamMembers}
                      getOptionLabel={option =>
                        this.state.parent
                          ? `${option["ParentRMBooking.first_name"]
                            ? option["ParentRMBooking.first_name"]
                            : ""
                          } ${option["ParentRMBooking.last_name"]
                            ? option["ParentRMBooking.last_name"]
                            : ""
                          } ${option["CrossParentRMBooking.first_name"]
                            ? option["CrossParentRMBooking.first_name"]
                            : ""
                          } ${option["CrossParentRMBooking.last_name"]
                            ? option["CrossParentRMBooking.last_name"] +
                            " " +
                            group[option["CrossParentRMBooking.group_id"]]
                            : ""
                          } ${option["ParentPresaleRMBooking.first_name"]
                            ? option["ParentPresaleRMBooking.first_name"]
                            : ""
                          } ${option["ParentPresaleRMBooking.last_name"]
                            ? option["ParentPresaleRMBooking.last_name"]
                            : ""
                          }`
                          : `${option["SalesRMBooking.first_name"]
                            ? option["SalesRMBooking.first_name"]
                            : ""
                          } ${option["SalesRMBooking.last_name"]
                            ? option["SalesRMBooking.last_name"]
                            : ""
                          } ${option["CrossSalesRMBooking.first_name"]
                            ? option["CrossSalesRMBooking.first_name"]
                            : ""
                          } ${option["CrossSalesRMBooking.last_name"]
                            ? option["CrossSalesRMBooking.last_name"] +
                            " " +
                            group[option["CrossSalesRMBooking.group_id"]]
                            : ""
                          } ${option["PresaleRm.first_name"]
                            ? option["PresaleRm.first_name"]
                            : ""
                          } ${option["PresaleRm.last_name"]
                            ? option["PresaleRm.last_name"]
                            : ""
                          }`
                      }
                      onChange={(e, value) => this.onSalesTeamMemberSelect(value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Search"
                          variant="outlined"
                          onChange={e => this.filterSalesTeamMembers(e)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-md-10 col-8 text-center font-weight-bold">
              My Incentives Report
            </div>
            {(window.$user.group === "finance" ||
              window.$user.role === "admin") && (
                <div className="col-md-2 col-2 px-md-4 px-2">
                  {this.state.incentives?.length > 0 && (
                    <>
                      <Tooltip title="Download Report">
                        <Button
                          className="float-right"
                          variant="contained"
                          color="primary"
                        >
                          <CSVLink
                            data={this.state.my_incentive_report}
                            filename={`incentive-report.csv`}
                          >
                            <GetAppIcon className="text-white" />
                          </CSVLink>
                        </Button>
                      </Tooltip>
                    </>
                  )}

                  {this.state.downloadSalesIncentives?.length > 0 && (
                    <Button
                      className="float-right"
                      variant="contained"
                      color="primary"
                    >
                      <CSVLink
                        data={this.state.downloadSalesIncentives}
                        filename={`sales-incentive-report.csv`}
                        id="download-sales-report"
                      >
                        <GetAppIcon className="text-white" />
                      </CSVLink>
                    </Button>
                  )}

                  {this.state.downloadPresalesIncentives?.length > 0 && (
                    <Button
                      className="float-right"
                      variant="contained"
                      color="primary"
                    >
                      <CSVLink
                        data={this.state.downloadPresalesIncentives}
                        filename={`presales-incentive-report.csv`}
                        id="download-presales-report"
                      >
                        <GetAppIcon className="text-white" />
                      </CSVLink>
                    </Button>
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-12">
              <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                <Table
                  aria-label="sticky table"
                  className="tableCustom"
                  style={{ minWidth: "650px" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Lead Id</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>
                        Client Paymet<sup>%</sup>
                      </StyledTableCell>
                      <StyledTableCell>Property</StyledTableCell>
                      <StyledTableCell>Config</StyledTableCell>
                      <StyledTableCell>Parent Name</StyledTableCell>
                      <StyledTableCell>Booking Date</StyledTableCell>
                      <StyledTableCell>Agreement Value</StyledTableCell>
                      <StyledTableCell>Booking Amount</StyledTableCell>
                      <StyledTableCell>Cross Sale Lead</StyledTableCell>
                      <StyledTableCell>Magnet</StyledTableCell>
                      <StyledTableCell>
                        Magnet<sup>%</sup>
                      </StyledTableCell>
                      <StyledTableCell>
                        Incentive Magnet<sup>%</sup>
                      </StyledTableCell>
                      <StyledTableCell>Passback</StyledTableCell>
                      <StyledTableCell>
                        Previous Eligibility <sup>%</sup>
                      </StyledTableCell>
                      <StyledTableCell>
                        Eligible<sup>%</sup>
                      </StyledTableCell>
                      <StyledTableCell>SDR</StyledTableCell>
                      <StyledTableCell>Invoice Raised</StyledTableCell>
                      <StyledTableCell>Invoice Cleared</StyledTableCell>
                      <StyledTableCell>Previous Booked Incentive</StyledTableCell>
                      <StyledTableCell>Booked Incentive</StyledTableCell>
                      <StyledTableCell>Paid Amount</StyledTableCell>
                      <StyledTableCell>Due Amount</StyledTableCell>
                      <StyledTableCell>Part1</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Part2</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Part3</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Total Cancellation</StyledTableCell>
                      <StyledTableCell>Document Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.incentives.map((o, key) => (
                      <TableRow key={key} className={o.is_lead_cancelled === 1 ? `bg-lead-cancelled` : ''}>
                        <TableCell
                          className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.lead_id}
                        </TableCell>
                        <TableCell
                          className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.name}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.client_pyment_percentage}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.property}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.config}
                        </TableCell>
                        <TableCell
                          className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.parent_name}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.bookingDate}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.agreementValue}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.booking_amount}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.is_crosssale ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.is_magnet ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.magnet_percentage_split}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive_magnet_split}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.special_discount}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.previousEligibilityPercentage}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.eligibilityPercentage}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.sdr ? "YES" : "NO"}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.invoiceRaised ? "YES" : "NO"}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.invoiceCleared ? "YES" : "NO"}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(o.previous_booking_incentive)
                          )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.bookedIncentive}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(o.paidAmount)
                          )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(o.dueAmount)
                          )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive[0].amount === "-"
                            ? o.incentive[0].amount
                            : indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.incentive[0].amount)
                            )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive[0].status}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive[1].amount === "-"
                            ? o.incentive[1].amount
                            : indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.incentive[1].amount)
                            )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive[1].status}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive[2].amount === "-"
                            ? o.incentive[2].amount
                            : indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.incentive[2].amount)
                            )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o.incentive[2].status}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(o.cancelledIncentive)
                          )}
                        </TableCell>
                        <TableCell
                          className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                            }`}
                        >
                          {o?.document_status}
                        </TableCell>
                      </TableRow>
                    ))}
                    {this.state.incentives.length ? (
                      <TableRow key={"total"} className="font-weight-bold">
                        <TableCell className="text-capitalize"></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(this.state.totalAgreement)
                          )}
                        </TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(
                              this.state.totalParentBookingAmount
                            )
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(
                              this.state.totalPreviewIncetive
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(
                              this.state.totalBookedIncentive
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(
                              this.state.parentTotalPaidAmount
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(
                              this.state.parentTotalDueAmount
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(this.state.totalPartOnePaid)
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(this.state.totalPartTwoPaid)
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(this.state.totalPartThreePaid)
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {indianRupeeCommaSeparated(
                            roundToTwoDecimalPlaces(this.state.totalCancellation)
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        {this.state.parent ? (
          <>
            <div className="row mt-3">
              <div className="col-md-10 col-8 text-center font-weight-bold">
                My Team Incentives Report
              </div>
              {(window.$user.group === "finance" ||
                window.$user.role === "admin") && (
                  <div className="col-md-2 col-2 px-md-4 px-2">
                    {this.state.childrenIncentives?.length > 0 && (
                      <Tooltip title="Download Report">
                        <Button
                          className="float-right"
                          variant="contained"
                          color="primary"
                        >
                          <CSVLink
                            data={this.state.myteam_incentive_report}
                            filename={`myteam-incentive-report.csv`}
                          >
                            <GetAppIcon className="text-white" />
                          </CSVLink>
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                )}
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                  <Table
                    aria-label="sticky table"
                    className="tableCustom"
                    style={{ minWidth: "650px" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Lead Id</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>
                          Client Paymet<sup>%</sup>
                        </StyledTableCell>
                        <StyledTableCell>Property</StyledTableCell>
                        <StyledTableCell>Config</StyledTableCell>
                        <StyledTableCell>Parent Name</StyledTableCell>
                        <StyledTableCell>RM Name</StyledTableCell>
                        <StyledTableCell>Booking Date</StyledTableCell>
                        <StyledTableCell>Cross Sale Lead</StyledTableCell>
                        <StyledTableCell>Agreement Value</StyledTableCell>
                        <StyledTableCell>Booking Amount</StyledTableCell>
                        <StyledTableCell>Magnet</StyledTableCell>
                        <StyledTableCell>
                          Magnet<sup>%</sup>
                        </StyledTableCell>
                        <StyledTableCell>
                          Incentive Magnet<sup>%</sup>
                        </StyledTableCell>
                        <StyledTableCell>Passback</StyledTableCell>
                        <StyledTableCell>
                          Previous Eligible <sup>%</sup>
                        </StyledTableCell>
                        <StyledTableCell>
                          Eligible <sup>%</sup>
                        </StyledTableCell>
                        <StyledTableCell>SDR</StyledTableCell>
                        <StyledTableCell>Invoice Raised</StyledTableCell>
                        <StyledTableCell>Invoice Cleared</StyledTableCell>
                        <StyledTableCell>
                          Previous Booked Incentive
                        </StyledTableCell>
                        <StyledTableCell>Booked Incentive</StyledTableCell>
                        <StyledTableCell>Paid Amount</StyledTableCell>
                        <StyledTableCell>Due Amount</StyledTableCell>
                        <StyledTableCell>
                          Booked{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S2"
                            : "A1"}{" "}
                          Incentive
                        </StyledTableCell>
                        <StyledTableCell>
                          Booked{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S3"
                            : "S1"}{" "}
                          Incentive
                        </StyledTableCell>
                        <StyledTableCell>
                          Part1{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S2"
                            : "A1"}
                        </StyledTableCell>
                        <StyledTableCell>
                          Part1{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S3"
                            : "S1"}{" "}
                        </StyledTableCell>
                        <StyledTableCell>Part1 Total</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>
                          Part2{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S2"
                            : "A1"}
                        </StyledTableCell>
                        <StyledTableCell>
                          Part2{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S3"
                            : "S1"}
                        </StyledTableCell>
                        <StyledTableCell>Part2 Total</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>
                          Part3{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S2"
                            : "A1"}
                        </StyledTableCell>
                        <StyledTableCell>
                          Part3{" "}
                          {this.state.reportMyteamLabel === "presale"
                            ? "S3"
                            : "S1"}
                        </StyledTableCell>
                        <StyledTableCell>Part3 Total</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Total Cancellation</StyledTableCell>
                        <StyledTableCell>
                          Document Status
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.childrenIncentives.map((o, key) => (
                        <TableRow key={key} className={o.is_lead_cancelled === 1 ? `bg-lead-cancelled` : ''}>
                          <TableCell
                            className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.lead_id}
                          </TableCell>
                          <TableCell
                            className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.name}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.client_pyment_percentage}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.property}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.config}
                          </TableCell>
                          <TableCell
                            className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.parent_name}
                          </TableCell>
                          <TableCell
                            className={`text-capitalize ${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.rmName}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.bookingDate}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.is_crosssale ? "Yes" : "No"}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.agreementValue}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.booking_amount}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.is_magnet ? "Yes" : "No"}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.magnet_percentage_split}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.incentive_magnet_split}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.special_discount}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.previousEligibilityPercentage}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.eligibilityPercentage}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.sdr ? "YES" : "NO"}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.invoiceRaised ? "YES" : "NO"}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.invoiceCleared ? "YES" : "NO"}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                o.previous_booking_incentive
                              )
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.bookedIncentive}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.paidAmount)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.dueAmount)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.booking_a1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.booking_s1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.par_1_a1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.par_1_s1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.incentive[0].amount)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.incentive[0].status}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.par_2_a1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.par_2_s1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.incentive[1].amount)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.incentive[1].status}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.par_3_a1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.par_3_s1_incentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.incentive[2].amount)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o.incentive[2].status}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(o.cancelledIncentive)
                            )}
                          </TableCell>
                          <TableCell
                            className={`${o.is_lead_cancelled ? "lead-cancelled" : ""
                              }`}
                          >
                            {o?.document_status}
                          </TableCell>
                        </TableRow>
                      ))}
                      {this.state.childrenIncentives.length ? (
                        <TableRow key={"total"} className="font-weight-bold">
                          <TableCell className="text-capitalize"></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildrenAgreement
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildBookingAmount
                              )
                            )}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalMyTeamPreviewIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildrenBookedIncentive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.childTotalPaidAmount
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.childTotalDueAmount
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildA1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildS1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalPart1A1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalPart1S1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildrenPartOnePaid
                              )
                            )}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalPart2A1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalPart2S1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildrenPartTwoPaid
                              )
                            )}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalPart3A1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalPart3S1BookingIncetive
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalChildrenPartThreePaid
                              )
                            )}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {indianRupeeCommaSeparated(
                              roundToTwoDecimalPlaces(
                                this.state.totalMyTeamCancellation
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
