import { Paper } from '@material-ui/core'
import React from 'react'

const ComingSoon = (props) => {
    return (
        <div>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-md-6 px-0">
                        <Paper className="small_box popup_hover py-5">
                            <div className="p-4">
                                <p className="mb-0 text-center text-capiitalize">Coming Soon...</p>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon
