import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ButtonComponent from '@material-ui/core/Button';

import './Button.sass'
import clsx from 'clsx';


const defaultState = {
}


const useButtonStyles = makeStyles(theme => {
    return {
    ".MuiButton-containedPrimary": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    }
}})

const Button = props => {
    const buttonStyles = useButtonStyles();

    return <ButtonComponent variant="contained" color="primary" size="large" {...props}>{props.children}</ButtonComponent>
}

Button.propTypes = {
  /**
   Content within Button
  */
  children: PropTypes.string.isRequired,
};

Button.defaultProps = {
  children: 'Submit'
};

export default Button;
