import React, { Component } from "react";
import BootModal from "../../component/common/modal/modal.js";

const year = new Date().getFullYear();
// const prevyear = year-1

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetShow: false,
      sweetShowCancel: false,
      modalShow: false
    };
  }

  render() {
    return (
      <footer
        className={
          "main-footer" +
          " " +
          (this.props.expand ? "main-header-collapsed" : "")
        }
      >
        <strong>
          Copyright &copy; {year} <a className="text-success">Homesfy</a>.
        </strong>
        All rights reserved.
      </footer>
    );
  }
}

export default Footer;
