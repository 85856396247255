import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap';
import Frag from "../../utils/Frag/Frag.js";
import "./detailLead.css";
import {getCommentData} from "../../dataParser/getListData";
import PerfectScrollbar from 'react-perfect-scrollbar';

class DetailProjectModal extends Component {

	constructor(props) {
    	super(props);
    	this.state = {
      		showLoader : false,
    	};
  	}

    render() {

    	let projectDetail = this.props.leadDetailProp;
    	let projectDetailMeta = projectDetail.meta;
    	let projectDetailData = projectDetail.data;

    	// console.log("wwwwwwwwwwwwwwwwwwwwwww",projectDetailMeta.status);
        return (
            <Frag>
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size="lg"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			          Project detail
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			      	{projectDetailMeta.status == 200 ? 
			      	<div className="detailProject">
			      		<div className="row">
			      			<div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Project ID : </span>
		                                {projectDetailData.id !=null  ? 
		                                	<span className="valueText">{projectDetailData.id}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }	
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Project Name : </span>
		                                {projectDetailData.name !=null ? 
		                                	<span className="valueText text-capitalize">{projectDetailData.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }	
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Project Addedby : </span>
		                                {projectDetailData.ProjectCreator !=null ? 
		                                	<span className="valueText text-capitalize">{projectDetailData.ProjectCreator?.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }	
		                            </div>
		                        </div>
		                    </div>
			      		</div>

			      		<div className="row">
			      			<div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Added Date : </span>
		                                {projectDetailData.added_date !=null ? 
		                                	<span className="valueText">{projectDetailData.added_date}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }	
		                            </div>
		                        </div>
		                    </div>
			      			<div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Builder Name : </span>
		                                {projectDetailData.builder_name != "" ? 
		                                	<span className="valueText text-capitalize">{projectDetailData.builder_name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }	
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Region : </span>
		                                {projectDetailData.location != "" ? 
		                                	<span className="valueText text-capitalize">{projectDetailData.location}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      		</div>

			      		<div className="row">
			      			<div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">project 99 ID : </span>
		                                {projectDetailData.project_id_99 !=null ? 
		                                	<span className="valueText">{projectDetailData.project_id_99}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Region : </span>
		                                {projectDetailData.region !=null ? 
		                                	<span className="valueText text-capitalize">{projectDetailData.region.region_name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Status : </span>
		                                {projectDetailData.project_status !=null ? 
		                                	<span className="valueText">{projectDetailData.project_status === true ? 'Active' : 'In-active'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Focus Project : </span>
		                                {projectDetailData.is_focus !=null ? 
		                                	<span className="valueText">{projectDetailData.is_focus === true ? 'Yes' : 'No'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      		</div>

			      	</div>  	
			      	:
			      		<div className="row">
			      			<div className="col-md-12">
			      				<span className="mr-2">Couldn't get detail, Please try again !!!</span>
		      				</div>
			      		</div>
			      	}	
			      </Modal.Body>
			      
			    </Modal>
            </Frag>
        );
    };
};    

export default DetailProjectModal;