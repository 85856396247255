import React, { Component } from "react";
import * as _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-regular-svg-icons";

const clubbedPlans = ["basic", "lite", "plus"];

export default class MagnetPlanCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availablePlans: this.props.magnetPlans
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.magnetPlans, this.props.magnetPlans)) {
      this.setState({ availablePlans: this.props.magnetPlans });
    }
  }

  render() {
    return (
      <div className={`container ${this.props.minified ? `mb-4 px-0` : `p-3`}`}>
        <div className="row">
          {Object.values(this.state.availablePlans).length
            ? Object.values(this.state.availablePlans)
                .filter(plan => {
                  if (
                    this.props.magnetSelectedPlan?.key === "pro" &&
                    plan.key === "pro" &&
                    this.props.minified
                  ) {
                    return true;
                  } else if (
                    clubbedPlans.includes(plan.key) &&
                    this.props.magnetSelectedPlan?.key !== "pro" &&
                    this.props.minified
                  ) {
                    return true;
                  } else if (!this.props.minified) {
                    return true;
                  }

                  return false;
                })
                .map(plan => {
                  return plan.name === "Pro" && !this.props.minified ? null : (
                    <div
                      className={`${
                        this.props.minified
                          ? `col-md-3 col-4 cursor-pointer pl-0`
                          : `col-sm-12 col-lg-4 mb-sm-3 mb-3`
                      }`}
                      onClick={
                        this.props.minified
                          ? () => this.props.selectPlan(plan)
                          : null
                      }
                      key={plan.key}
                    >
                      <div
                        className={`card ${
                          this.props.minified ? `text-center` : `arrow-image`
                        } ${
                          plan.key === this.props.magnetSelectedPlan?.key
                            ? `plan-selected`
                            : ``
                        }`}
                      >
                        <div
                          className={`card-body ${
                            this.props.minified ? `p-2` : ``
                          }`}
                        >
                          {this.props.minified ? null : (
                            <h5 className="card-title">
                              {plan.name}
                              {plan.key === "plus" ? (
                                <span class="badge badge-warning ml-2">
                                  Popular
                                </span>
                              ) : null}
                            </h5>
                          )}

                          <p className="card-text">
                            {this.props.minified ? (
                              `${plan.name} - ${plan.percentage}%`
                            ) : (
                              <span className="font-weight-bold">
                                {`${plan.percentage}% share`}
                              </span>
                            )}

                            {this.props.minified ? null : (
                              <ul className="list-group">
                                <li className="list-group-item plan-pointers">
                                  {["lite"].includes(plan.key) ? (
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={{ color: "#28a745" }}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      style={{ color: "#dc3545" }}
                                    />
                                  )}{" "}
                                  Lead Qualification
                                </li>
                                <li className="list-group-item plan-pointers strike">
                                  {["lite", "basic"].includes(plan.key) ? (
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={{ color: "#28a745" }}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      style={{ color: "#dc3545" }}
                                    />
                                  )}{" "}
                                  Lead Servicing
                                </li>
                                <li className="list-group-item plan-pointers">
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ color: "#28a745" }}
                                  />{" "}
                                  Closing Support
                                </li>
                              </ul>
                            )}
                          </p>

                          {this.props.minified ? null : (
                            <>
                              <div className={" d-none d-lg-block text-center"}>
                                <a
                                  onClick={() => this.props.selectPlan(plan)}
                                  className="btn btn-dark cursor-pointer"
                                >
                                  Select
                                </a>
                              </div>
                              <div>
                                <a
                                  onClick={() => this.props.selectPlan(plan)}
                                  className="stretched-link"
                                ></a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
            : null}
        </div>

        {this.props.minified ? null : Object.values(this.state.availablePlans)
            .length ? (
          <div className="row">
            <div className="col-12 text-center pt-2 mt-3">
              {this.state.availablePlans.pro?.percentage}% share with{" "}
              {this.state.availablePlans.pro?.name} (Self-Service):{" "}
              {/* <a
                onClick={() =>
                  this.props.selectPlan(this.state.availablePlans.pro)
                }
                className="text-decoration-none cursor-pointer"
              >
                Select
              </a> */}
              <span className="text-muted">Contact your service RM</span>
              <div className="col-12 text-center pt-2 mt-3">
                All <a target="_blank" rel="noopener noreferrer" href="#/terms-and-conditions">
                  Terms &amp; Conditions
                </a>
                <span> accepted</span>
              </div>
            </div>
          </div>
        ) : null}

      </div>
    );
  }
}
