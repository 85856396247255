import React, { Component } from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { roleWiseAutoComplete } from "../../dataParser/commomDataApi";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField"
import { cleanStorage } from "../../helper";

class knowlarityFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			autoKey: true,
			object_id: "",
			calling_type: '',
			call_date_to: null,
			call_date_from: null,
			allRm: [],
			rm_id: null,
			vendor: '',
		};
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	applyFilter = () => {

		let filterData = {};
		const { object_id, calling_type, vendor, rm_id, call_date_from, call_date_to } = this.state;

		if (object_id) filterData["object_id"] = this.state.object_id;
		if (calling_type) filterData["type"] = calling_type;
		if (vendor) filterData["vendor_type"] = vendor;
		if (rm_id) filterData["rm_id"] = rm_id;
		if (call_date_from) filterData["call_date_from"] = call_date_from.split("/").join("-");
		if (call_date_to) filterData["call_date_to"] = call_date_to.split("/").join("-");

		this.props.changeFilter();
		this.props.filterData(filterData);

	}

	resetFilter = () => {

		this.setState({
			autoKey: !this.state.autoKey,
			object_id: "",
			calling_type: '',
			allRm: [],
			rm_id: null,
			call_date_to: null,
			call_date_from: null,
			vendor: ''
		})
	}
	handleDateChange = (key, date) => {
		this.setState({ [key]: date });
	}


	handleRmChange = async (e) => {
		this.setState({ rmLoading: true });
		let data = {
			q: e.target.value,
		};
		let resData = await roleWiseAutoComplete(data);;

		if (resData.meta.status === 200) {
			this.setState({ rmLoading: false, allRm: resData.data });
		} else if (resData.meta.status === 401) {
			cleanStorage();
			this.props.history.push("/login");
		} else {
			this.setState({ rmLoading: false, allRm: [] });
		}
	}

	render() {

		const { object_id, calling_type, vendor, call_date_from, call_date_to, allRm, rmLoading, autoKey } = this.state;

		return (

			<Frag>
				<div className="container filter_lead filterMenuCard pt-3 p-0" ref={this.props.fltr}>
					<div className="row filterRow">
						<div className="col-6 mb-3">
							<FormControl>
								<TextField
									label="Lead ID"
									value={object_id}
									name="object_id"
									onChange={this.handleChange}
								/>
							</FormControl>
						</div>
						<div className="col-6 mb-3">
							<FormControl>
								<InputLabel id="demo-controlled-open-select-label">Calling Type</InputLabel>
								<Select
									labelId="demo-controlled-open-select-label"
									value={calling_type}
									onChange={this.handleChange}
									inputProps={{
										name: 'calling_type',
										id: 'calling_type',
									}}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									<MenuItem value="in">in</MenuItem>
									<MenuItem value="out">out</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="col-6 mb-3">
							<FormControl>
								<Autocomplete
									key={autoKey}
									getOptionSelected={(option, value) =>
										option.name === value.name
									}
									getOptionLabel={option => option.name}
									options={allRm}
									loading={rmLoading}
									renderTags={(value, getTagProps) =>
										value.map((option, index) => (
											<Chip label={option.name} {...getTagProps({ index })} />
										))
									}
									onChange={(_event, allRm) => {
										let rmId = allRm.id;
										this.setState({
											rm_id: rmId,
										})

										if (allRm === null) {
											this.setState({ allRm: [] })
										}
									}}
									renderInput={params => (
										<TextField
											{...params}
											label="Search RM"
											fullWidth
											onChange={this.handleRmChange}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{rmLoading ? (
															<CircularProgress color="inherit" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</div>
						<div className="col-6 mb-3">
							<FormControl>
								<InputLabel id="demo-controlled-open-select-label">Vendor</InputLabel>
								<Select
									labelId="demo-controlled-open-select-label"
									value={vendor}
									onChange={this.handleChange}
									inputProps={{
										name: 'vendor',
										id: 'vendor',
									}}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									<MenuItem value="0">knowlarity</MenuItem>
									<MenuItem value="1">salestrail</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="row filterRow mb-2">
							<div className="col-md-12 text-center">Call Log </div>
							<div className="col-6">
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										autoOk={true}
										margin="normal"
										id="lastUpdate_date_form"
										label="From"
										variant="inline"
										format="yyyy/MM/dd"
										maxDate={new Date()}
										name="call_date_from"
										value={call_date_from}
										onChange={(key, date) => this.handleDateChange('call_date_from', date)}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="col-6">
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										autoOk={true}
										margin="normal"
										id="lastUpdate_date_to"
										label="to"
										variant="inline"
										format="yyyy/MM/dd"
										maxDate={new Date()}
										name="call_date_to"
										value={call_date_to}
										onChange={(key, date) => this.handleDateChange('call_date_to', date)}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
					</div>
					<div className="col-md-12 bgCommon">
						<div className="row">
							<div className="col-sm-6 text-right col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
								</div>
							</div>
							<div className="col-sm-6 col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Frag >
		);
	};
};

export default withRouter(knowlarityFilter)
