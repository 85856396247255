import {get, post} from "../utils/methods.js";

export const addLoanDetail = async (value) =>{
	let getData = await post('/homeLoan',value)
	return  getData;
}

export const getHomeLoan = async (value) =>{
	let getData = await get('/homeLoan',value)
	return  getData;
}