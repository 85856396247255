import React, {Component} from "react";
import {getAllFocusProject} from "../../dataParser/getProjectData";
import FocusProject from "../../component/focusProject/focusProject";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";

class FocusProjectList extends Component {

	constructor(props) {
    	super(props);
		this.state = {
		    projectDetail : {},
        	showLoader : false,
        	sideBar: false,
      		mobileSideBar: false,
		};
	}

	async componentDidMount () {
		this.setState({showLoader : true});
		// var projectData = {};
  //       projectData.size = 8;
  //       projectData.pageId = 1;
  		(async () => {
            try {
  				// console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ")
    			let resData = await getAllFocusProject();
				if(resData.meta.status === 200){
					resData.data = resData.data.filter((item) => {
						if(item.ProjectMeta.length){
							item.ProjectMeta = this.unique(item.ProjectMeta);
						}
						return item;
					})
				}
		        await this.setState({
		        	projectDetail : resData,
		        	showLoader : false,
		        }) ;
	        	window.scrollTo(0, 0);
            } catch (e) {	
                //...handle the error...
            }
        })();
	}

	changeButtonState(event) {
	    this.setState({sideBar: !this.state.sideBar})
	}

	changeSideBarState(event) {
	    this.setState({mobileSideBar: !this.state.mobileSideBar})
	}
	unique = (arr) => { 
        //To store the unique sub arrays
        let uniques = [];
        arr.map((item) => {
            if(uniques.findIndex((itemObj) => itemObj.bhk === item.bhk) === -1){
                uniques.push(item);
            }
        });
        //Return the unique list
        return uniques;
    }

	render(){

		return(
			<Frag>
				<Loader show={this.state.showLoader}/>
				{this.state.projectDetail.meta &&
					<FocusProject expand ={this.state.sideBar} projectDataValue = {this.state.projectDetail} path={this.props.path}/>
				}
			</Frag>
		);

	};
};

export default FocusProjectList;
