import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFilter,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import {
  getTempMagentUsersList,
  getApproveMagnetUser,
  getMagnetTempUserList
} from "../../dataParser/getListUserData";
import Loader from "../common/loader/loader";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Pagination from "react-js-pagination";
import CpUserFilter from "../../component/filter/CpUserFilter.js";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Checkbox from "@material-ui/core/Checkbox";
import BulkLeadModal from "../../component/common/modal/BulkModal";
import ModalAssignRM from "../../component/common/modal/modal";
import Tooltip from "@material-ui/core/Tooltip";
import ChangeUserPassModal from "../../component/common/modal/changesUserpassModal";
import { changeDate, cleanStorage } from "../../helper";
import Footer from "../../component/footer/footer";
import "../userList/usersList.css";
import { MagnetDetail } from "../detailLead/MagnetDetail";
import CallAction from "../common/action/CallAction";
import { magnetApproveRoles, magnetCallRoles } from "../../config/constant/crmRoleFunctionPermission";

const magnetDetailRoles = [...magnetCallRoles]

class MagnetTempUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_focus: {},
      ids: [],
      listDetail: -1,
      activePage: 1,
      showLoader: false,
      showFilter: false,
      modalCpUpdateShow: false,
      changeUserPassModal: false,
      sideBar: false,
      meta: props.userDetail.meta,
      data: props.userDetail.data["interestedMagnets"],
      mobileSideBar: false,
      sweetShow: false,
      type: "success",
      title: "",
      modalShow: false,
      infoModalShow: false,
    };

    window.addEventListener("resize", this.update);
    this.fltr = React.createRef()
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    this.update();
    this.setDynamicSwitchState(this.state.data);
  }

  update = () => {
    this.setState({
      role: window.$role,
      screenWidth: window.innerWidth
    });
  };

  handleClickOutside = (e) => {
    if (this.node !== undefined && this.node !== null &&
      !this.node.contains(e.target) && this.state.showFilter &&
      e.target.className !== 'MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button'
      && e.target.className !== 'MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit'
      && e.target.className !== 'MuiAutocomplete-option' && e.target.className !== 'MuiAutocomplete-noOptions') {
      this.setState({
        showFilter: false,
      });
    }

  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        meta: nextProps.userDetail.meta,
        data: nextProps.userDetail.data["interestedMagnets"],
        activePage: 1
      });
    }
  }

  genericGetListData = async params => {
    this.setState({ showLoader: true });

    (async () => {
      try {
        let resData = await getTempMagentUsersList(params);
        if (resData.meta.count >= 0 && resData.meta.status === 200) {
          this.setDynamicSwitchState(resData.data["interestedMagnets"]);

          await this.setState({
            meta: resData.meta,
            data: resData.data["interestedMagnets"],
            listDetail: -1,
            showLoader: false
          });
          window.scrollTo(0, 0);
        } else if (resData.meta.status == 401) {
          cleanStorage();
          this.props.history.push("/login");
        } else {
          this.setState({
            meta: resData.meta,
            data: resData.data,
            showLoader: false
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  handleChangePage = async pageNumber => {
    this.setState({
      showLoader: true,
      activePage: pageNumber
    });
    var listData = {};
    listData.size = 8;
    listData.pageId = pageNumber;
    Object.assign(listData, this.state.filters);

    this.genericGetListData(listData);
  };

  handleChange = async (index, event, checked) => {
    var joined = this.state.ids.concat(index);

    const list = Object.assign({}, this.state.is_focus);

    list["switch-" + index] = checked;

    this.setState({
      is_focus: list,
      ids: joined
    });
  };

  handleFilter = async filterData => {
    this.setState({ showLoader: true });
    this.setState({ filters: filterData, activePage: 1 });
    let listData = {};
    listData.size = 8;
    listData.pageId = 1;
    Object.assign(listData, filterData);

    this.genericGetListData(listData);
  };

  setDynamicSwitchState = list => {

    if (!list) {
      return;
    }

    const is_focus = {};

    list.forEach((item, index) => {
      is_focus["switch-" + item.id] = item.assign_rm_id != null ? true : false;
    });

    this.setState({
      is_focus: is_focus
    });
  };

  handleApproveMagnet = async (id, status) => {
    let addUserData = {};
    addUserData.data = {};

    addUserData.data["is_approved"] = !status === true ? 1 : 0;
    addUserData.data["id"] = id;

    addUserData.data = JSON.stringify(addUserData.data);

    var addLeadRes = await getApproveMagnetUser(addUserData);

    if (addLeadRes.meta.status === 200) {
      this.setState({
        showLoader: false,
        sweetShow: true,
        type: "success",
        title: "Approved successfully !!!"
      });
      this.handleEmail(addLeadRes.data);
    } else if (addLeadRes.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({
        showLoader: false,
        sweetShow: true,
        type: "error",
        title: "Something went wrong !!!"
      });
    }
  };

  handlePopUp = () => {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter
    }));
  };

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
  };

  handleAddTempCp = () => {
    this.setState({ changeUserPassModal: !this.state.changeUserPassModal });
  };

  handleAssingUser = () => {
    if (this.state.ids.length > 0) {
      this.setState({ modalCpUpdateShow: true });
    }
  };

  handleModal = data => {
    this.setState({
      modalShow: !this.state.modalShow,
      rmSetUserId: data?.id,
      userInfo:data
    });
  };

  handleUpdateCpModal = () => {
    this.setState({
      modalCpUpdateShow: !this.state.modalCpUpdateShow,
      ids: []
    });
    this.handleFilter();
  };

  handleInfoModal = () =>{
    this.setState({
      infoModalShow: !this.state.infoModalShow
    })
  }

  fullLeadDetail = async id => {
    this.setState({ showLoader: true });

    (async () => {
        try {
            let resData = await getMagnetTempUserList(id);
            await this.setState({
                fullDetail: resData,
                showLoader: false
            });
            // window.scrollTo(0, 0);
        } catch (e) {
            //...handle the error...
        }
    })();
}
  render() {
    const { count, size, status, } = this.state.meta;
    const {
      type,
      data,
      title,
      sweetShow,
      role,
    } = this.state;
    const isDesktop = this.state.screenWidth;
    return (
      <Frag>
        {isDesktop > 991 ? (
          <div
            className="content-wrapper">
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Users : </span>
                <span className="">{status !== 200 ? "0" : count}</span>
              </div>
              <div className="d-inline ml-3 filterHeader">
                <span className="filter" onClick={this.handlePopUp}>
                  <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                </span>
                <div
                  className={
                    "filterMenu" +
                    " " +
                    (this.state.showFilter ? "filterMenuShow" : "")
                  }
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <CpUserFilter
                    fltr={this.fltr}
                    filterData={this.handleFilter}
                    changeFilter={this.handlePopUp}
                    magnetEnroll="enrollFilter"
                  />
                </div>
              </div>
              {this.state.role === "magnetadmin" || this.state.role === "magnetpnl" || this.state.role === "admin" ? (
                <span
                  className="assign_magnet filter"
                  onClick={this.handleAssingUser}
                >
                  <Tooltip title="Assign RM">
                    <AssignmentIndIcon />
                  </Tooltip>
                </span>
              ) : (
                ""
              )}
              {this.state.role === "magnettl" ||
                this.state.role === "magnetrm" ? (
                <span
                  className="assign_magnet filter"
                  onClick={() => this.setState({ changeUserPassModal: true })}
                >
                  <Tooltip title="Add Magnet">
                    <AddCircleIcon />
                  </Tooltip>
                </span>
              ) : (
                ""
              )}
            </div>
            <Loader show={this.state.showLoader} />
            {status === 200 && this.state.data.length ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {data.map((userData, index) => (
                      <div className="MainList" key={index}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3">
                            <div className="row mb-1">
                              <div className="col-12">
                                <div className="row align-items-center">
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">ID</span>
                                      </div>
                                      <div className="col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.id != null
                                            ? userData.id
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">Name</span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.first_name != null ||
                                            userData.last_name != null
                                            ? userData.first_name +
                                            " " +
                                            userData.last_name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Email
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.email != null
                                            ? userData.email
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Mobile Number
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData?.phone != null
                                            ? userData.phone
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Assigned RM
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData?.IntEnrollRm != null
                                            ? userData?.IntEnrollRm?.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Updated By
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.IntUpdator != null
                                            ? userData.IntUpdator.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead text-capitalize">
                                        <span className="text-muted">
                                          RM Approval Status
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.rm_approved != null
                                            ? userData.rm_approved === 1
                                              ? "Approved"
                                              : userData.rm_approved === 0
                                                ? "disapproved"
                                                : "Not interested"
                                            : "Pending"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Source
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        {userData.Source != null ? (
                                          <span className="text-capitalize valueText">
                                            {userData.Source.name == "referal"
                                              ? userData.Source.name +
                                              (userData.IntRefBy != null
                                                ? `(${userData.IntRefBy.name})`
                                                : "")
                                              : userData.Source.name}
                                          </span>
                                        ) : (
                                          <span className="text-capitalize valueText">
                                            -
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Created Date
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.created_at != null
                                            ? changeDate(userData.created_at)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Updated Date
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.updated_at != null
                                            ? changeDate(userData.updated_at)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead text-capitalize">
                                        <span className="text-muted">
                                          Admin Approval Status
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.admin_approved != null
                                            ? userData.admin_approved === true
                                              ? "Approved"
                                              : "disapproved"
                                            : "Pending"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="action_icons">
                                      {magnetDetailRoles.includes(role) &&
                                        <Tooltip title="Magnet Detail">
                                          <span
                                            className="Action fs-16"
                                            onClick={e => {this.handleInfoModal(userData.id);this.fullLeadDetail(userData.id)}}
                                          >
                                            <FontAwesomeIcon
                                              icon={faInfo}
                                              className="text-white"
                                            />
                                          </span>
                                        </Tooltip>
                                      }
                                      {magnetApproveRoles.includes(role) ? (userData.admin_approved === false || userData.admin_approved === null ? (
                                        <a
                                          className="ProjectDetail Action"
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Update User"
                                          onClick={e => this.handleModal(userData)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faEdit}
                                            className="text-white"
                                          />
                                        </a>
                                      ) : (
                                        ""
                                      )
                                      ) : (
                                        ""
                                      )}
                                      {userData.rm_approved === 1 &&
                                        (this.state.role === "admin" ||
                                          this.state.role === "magnetadmin" || this.state.role === "magnetpnl" ? (
                                          <Tooltip title="Approve Magnet">
                                            <span
                                              className="Action fs-16"
                                              onClick={e => this.handleModal(userData)}
                                            >
                                              <FontAwesomeIcon
                                                icon={faEdit}
                                                className="text-white"
                                              />
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          ""
                                        ))}
                                      <CallAction data={userData} roleAccess={magnetCallRoles} type={2} callingType="magnet" stage={1} device="desktop" />
                                      {this.state.role === "magnetadmin" || this.state.role === "magnetpnl" || this.state.role === "admin" ? (
                                        <Checkbox
                                          value={
                                            this.state.is_focus[
                                            "switch-" + userData.id
                                            ] || false
                                          }
                                          checked={
                                            this.state.is_focus["switch-" + userData.id]
                                          }
                                          onChange={(event, checked) =>
                                            this.handleChange(userData.id, event, checked)
                                          }
                                          color="primary"
                                          name="is_focus"
                                          inputProps={{
                                            "aria-label": "secondary checkbox"
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Comment
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.comment != null
                                            ? userData.comment
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-end">
                    {count > 8 ? (
                      <div className="paginationBlock">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={size}
                          totalItemsCount={count}
                          pageRangeDisplayed={5}
                          onChange={this.handleChangePage}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No User Found !!!</h5>
              </div>
            )}
          </div>
        ) : (
          <div
            className="content-wrapper">
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Users : </span>
                <span className="">{status !== 200 ? "0" : count}</span>
              </div>
              <div className="d-inline ml-3 filterHeader">
                <span className="filter" onClick={this.handlePopUp}>
                  <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                </span>
                <div
                  className={
                    "filterMenu" +
                    " " +
                    (this.state.showFilter ? "filterMenuShow" : "")
                  }
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <CpUserFilter
                    fltr={this.fltr}
                    filterData={this.handleFilter}
                    changeFilter={this.handlePopUp}
                    magnetEnroll="enrollFilter"
                  />
                </div>
              </div>
              {this.state.role === "magnetadmin" || this.state.role === "magnetpnl" || this.state.role === "admin" ? (
                <span
                  className="assign_magnet filter"
                  onClick={this.handleAssingUser}
                >
                  <Tooltip title="Assign RM">
                    <AssignmentIndIcon />
                  </Tooltip>
                </span>
              ) : (
                ""
              )}
              {this.state.role === "magnettl" ||
                this.state.role === "magnetrm" ? (
                <span
                  className="assign_magnet filter"
                  onClick={() => this.setState({ changeUserPassModal: true })}
                >
                  <Tooltip title="Add Magnet">
                    <AddCircleIcon />
                  </Tooltip>
                </span>
              ) : (
                ""
              )}
            </div>
            <Loader show={this.state.showLoader} />
            {status === 200 && this.state.data.length ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {data.map((userData, index) => (
                      <div className="MainList" key={index}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3">
                            <div className="row mb-1">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">ID</span>
                                      </div>
                                      <div className="col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.id != null
                                            ? userData.id
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">Name</span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.first_name != null ||
                                            userData.last_name != null
                                            ? userData.first_name +
                                            " " +
                                            userData.last_name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Email
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.email != null
                                            ? userData.email
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Mobile Number
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData?.phone != null
                                            ? userData?.phone
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Assigned RM
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData?.IntEnrollRm != null
                                            ? userData?.IntEnrollRm?.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Updated By
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className=" text-capitalize valueText">
                                          {userData.IntUpdator != null
                                            ? userData.IntUpdator.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead text-capitalize">
                                        <span className="text-muted">
                                          RM Approval Status
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.rm_approved != null
                                            ? userData.rm_approved === 1
                                              ? "Approved"
                                              : userData.rm_approved === 0
                                                ? "disapproved"
                                                : "Not interested"
                                            : "Pending"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Source
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        {userData.Source != null ? (
                                          <span className="text-capitalize valueText">
                                            {userData.Source.name == "referal"
                                              ? userData.Source.name +
                                              (userData.refby != null
                                                ? `(${userData.refby.name})`
                                                : "")
                                              : userData.Source.name}
                                          </span>
                                        ) : (
                                          <span className="text-capitalize valueText">
                                            -
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Created Date
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.created_at != null
                                            ? changeDate(userData.created_at)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Updated Date
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.updated_at != null
                                            ? changeDate(userData.updated_at)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Admin Approval Status
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.admin_approved != null
                                            ? userData.admin_approved === true
                                              ? "Approved"
                                              : "disapproved"
                                            : "Pending"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Comment
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.comment != null
                                            ? userData.comment
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="action_box">
                              {magnetDetailRoles.includes(role) &&
                                <Tooltip title="Magnet Detail">
                                  <span
                                    className="Action fs-16"
                                    onClick={e => {this.handleInfoModal(userData.id);this.fullLeadDetail(userData.id)}}
                                  >
                                    <FontAwesomeIcon
                                      icon={faInfo}
                                      className="text-white"
                                    />
                                  </span>
                                </Tooltip>
                              }
                              {magnetApproveRoles.includes(role) ? (
                                userData.admin_approved === false ||
                                  userData.admin_approved === null ? (
                                  <a
                                    className="ProjectDetail Action"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Update User"
                                    onClick={e => this.handleModal(userData)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="text-white"
                                    />
                                  </a>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {userData.rm_approved === 1 &&
                                (this.state.role === "admin" ||
                                  this.state.role === "magnetadmin" || this.state.role === "magnetpnl" ? (
                                  <Tooltip title="Approve Magnet">
                                    <span
                                      className="Action fs-16"
                                      onClick={e => this.handleModal(userData)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="text-white"
                                      />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  ""
                                ))}
                              {this.state.role === "magnetadmin" || this.state.role === "magnetpnl" || this.state.role === "admin" ? (
                                <Checkbox
                                  value={
                                    this.state.is_focus[
                                    "switch-" + userData.id
                                    ] || false
                                  }
                                  checked={
                                    this.state.is_focus["switch-" + userData.id]
                                  }
                                  onChange={(event, checked) =>
                                    this.handleChange(userData.id, event, checked)
                                  }
                                  color="primary"
                                  name="is_focus"
                                  inputProps={{
                                    "aria-label": "secondary checkbox"
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <CallAction data={userData} roleAccess={magnetCallRoles} type={2} callingType="magnet" stage={1} device="mobile" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-end">
                    {count > 8 ? (
                      <div className="paginationBlock">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={size}
                          totalItemsCount={count}
                          pageRangeDisplayed={5}
                          onChange={this.handleChangePage}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No User Found !!!</h5>
              </div>
            )}
          </div>
        )}
        <Footer expand={this.props.expand} />

        {this.state.ids.length > 0 && (
          <ModalAssignRM
            show={this.state.modalCpUpdateShow}
            changeModal={this.handleUpdateCpModal}
            handleInterestUser={this.state.ids}
            getList={this.handleFilter}
          />
        )}
        <ChangeUserPassModal
          show={this.state.changeUserPassModal}
          changeModal={this.handleAddTempCp}
          fromType="addTempCp"
        />
        <BulkLeadModal
          show={this.state.modalShow}
          fromType="updateBulkForm"
          changeModal={this.handleModal}
          userId={this.state.rmSetUserId}
          getList={this.handleFilter}
          userInfo={this.state.userInfo}
        />
        <MagnetDetail  show={this.state.infoModalShow} changeModal={this.handleInfoModal} fullDetail={this.state.fullDetail}/>
      </Frag>
    );
  }
}

export default MagnetTempUserList;
