import React, { useState, useEffect } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from "@material-ui/core/TableCell";
import TableBody from '@material-ui/core/TableBody'
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box'
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import { getCampaignSplits } from '../../dataParser/getListUserData'
import moment from 'moment'
import Loading from '../common/loader/loader'
import Pagination from "react-js-pagination";

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textTransform: "capitalize"
    },
    body: {
        fontSize: 14
    }
}))(TableCell);
let pageSize = 8
const ProjectSplit = ({ splitType }) => {
    const [state, setState] = useState({
        campaignSplits: []
        , showLoader: false
        , open: []
        , totalCount: 0
    })
    const [activePage, setActivePage] = React.useState(1)

    useEffect(() => {
        let query={};

        query.size = pageSize;
        query.pageId = 1
        handleCampaignSplits(query);
    }, [])

    const handleCampaignSplits = async (params) => {
        // debugger;
        setState({
            ...state
            , showLoader: true
        })
        let query = {};
        if (splitType === "facebook") {
            query.campaign_type = 2;
            
        }
        if (splitType === "google") {
            query.campaign_type = 1;
        }
        Object.assign(query,params)
        let getData = await getCampaignSplits(query);
        let splitData = getData.data.data;
        let splitMeta = getData.meta
        
        setState({
            ...state
            , campaignSplits: splitData
            , showLoader: false
            , splitMeta: splitMeta
            , totalCount: getData.data.count
        })
    }
    const handleChangePage= async (pageNumber)=>{
        setState({
            ...state,
            showLoader : true,
        })
        setActivePage(pageNumber)
        let listData = {};
        listData.size = pageSize;
        listData.pageId = pageNumber;
        
        handleCampaignSplits(listData);

    }
    const changeDate = date => {
        if (date !== null && date !== undefined) return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        return null;
    }
    const setOpen = (newFlag, index) => {
        let open = { ...state.open };
        open[index] = newFlag;

        setState({
            ...state
            , open
        });
    };

    const { campaignSplits, showLoader, totalCount } = state;
    return (
        <div>
            <Loading show={showLoader} />
            {campaignSplits.length ? (
                <>
                <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell />
                                {Object.keys(campaignSplits[0]).map(
                                    (values, index) =>
                                        values === "magnets" ||
                                            values === "id" ||
                                            values === "ad_id" ||
                                            values === "ad_name" ? null : (
                                            <StyledTableCell key={index} align="left">
                                                {values.replace("_", " ")}
                                            </StyledTableCell>
                                        )
                                )}
                                <StyledTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaignSplits.map((ad, index) => (
                                <>
                                    <TableRow key={index}>
                                        <StyledTableCell>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() =>
                                                    setOpen(!state.open[index], index)
                                                }
                                            >
                                                {state.open[index] ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </IconButton>
                                        </StyledTableCell>
                                        {Object.keys(ad).map((key, idx) =>
                                            key === "magnets" ||
                                                key === "id" ||
                                                key === "ad_id" ||
                                                key === "ad_name" ? null : (
                                                <StyledTableCell key={idx} align="left">
                                                    {
                                                        key === "project"
                                                            ? <span className="text-capitalize">{ad[key]?.name}</span>
                                                            :
                                                            key === "campaign_type"
                                                                ? Number(ad[key]) === 2
                                                                    ? "Facebook"
                                                                    : "Google"
                                                                : key === "nationality"
                                                                    ? Number(ad[key]) === 1
                                                                        ? "Domestic"
                                                                        : "International"
                                                                    : key === 'from_date' || key === 'to_date'
                                                                        ? changeDate(ad[key])
                                                                        : ad[key] || "-"}
                                                </StyledTableCell>
                                            )
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                            colSpan={6}
                                        >
                                            <Collapse
                                                in={state.open[index]}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <Box margin={1}>
                                                    <Table aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Magnet</TableCell>
                                                                <TableCell>Magnet Name</TableCell>
                                                                <TableCell align="right">
                                                                    Percentage
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {ad.magnets.map((magnet, ky) => (
                                                                <TableRow key={ky}>
                                                                    <TableCell component="th" scope="row">
                                                                        {magnet.magnet_id}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        style={{
                                                                            textTransform: "capitalize"
                                                                        }}
                                                                    >
                                                                        {magnet.magnet_name}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {magnet.percentages}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="row justify-content-end">
                    {totalCount > pageSize &&
                        <div className="paginationBlock">
                            <Pagination
                                hideDisabled    
                                activePage={activePage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={totalCount}
                                pageRangeDisplayed={5}
                                onChange={handleChangePage}
                            />
                        </div>
                    }
                </div>
                </>
            ) :
                <h4 className="text-muted">Oops! No data found.</h4>
            }
        </div>
    )
}

export default ProjectSplit
