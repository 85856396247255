import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import { getAutoCompleteProject } from "../../../dataParser/getProjectData";
import { getLeadSourceData, autoComplete } from "../../../dataParser/commomDataApi.js";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { addLeadAutoAssignList } from "../../../dataParser/getListData.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Loader from "../../../component/common/loader/loader";
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { cleanStorage, empty } from "../../../helper";

class AddAutoAssignForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			project_id: '',
			project_name: '',
			option: [],
			source: [],
			userName: [],
			source_id: '',
			source_name: '',
			nationality: '',
			is_magnet: '',
			sweetShow: false,
			showLoader: false,
			type: "success",
			title: "",
			error: "",
			isLoading: false,
			isError: {
				project_name: '',
				region_id: '',
				type: '',
				source_id: ''
			}
		};
	}

	async componentDidMount() {
		var homesfy_lg = (localStorage.getItem("homesfy_lg"));
		var addedBy = '';

		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg));
			addedBy = user.id;
			this.setState({ project_addedby: addedBy })

		} else {
			this.setState({ project_addedby: addedBy })
		}

		var allSourceData = await getLeadSourceData();
		this.setState({ source: allSourceData.data })

	}

	formValid = ({ isError, ...rest }) => {
		let isValid = false;

		if (empty(rest.project_id) || empty(rest.source_id) || empty(rest.is_magnet) || empty(rest.assign_rm_id) || empty(rest.type) || empty(rest.nationality)) {
			return false;
		}

		Object.values(isError).forEach(val => {
			if (val.length > 0) {
				return false;
			} else {
				isValid = true
			}
		});

		return isValid;
	};

	formValChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}

	updateProjectForm = async (e) => {
		e.preventDefault();

		if (this.formValid(this.state)) {

			let data = {}
			if (this.state.project_id != '') data['project_id'] = this.state.project_id;
			if (this.state.source_id != '') data['source_id'] = this.state.source_id;
			if (this.state.assign_rm_id != '') data['assign_to'] = this.state.assign_rm_id;
			if (this.state.type != '') data['type'] = this.state.type;
			if (this.state.is_magnet != '') data['is_magnet'] = parseInt(this.state.is_magnet);
			if (this.state.nationality != '') data['nationality'] = parseInt(this.state.nationality);
			data['auto_assign_status'] = 1;

			this.setState({
				showLoader: true,
			})

			var addLeadRes = await addLeadAutoAssignList(data);

			if (addLeadRes.meta.status === 503) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "!!! Oooops Network Error..."
				});

			} else if (addLeadRes.meta.status === 201) {


				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Added successfully"
				});
			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});

			}
		} else {
			this.setState({
				showLoader: false,
				error: "*Some error in the above fields or fileds are reqiured !!!",
			})
		}
	};




	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			q: e.target.value,
			users_active: 1
		};
		let resData = await autoComplete('users', data);


		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	handleAutoChange = async (e) => {
		this.setState({ isLoading: true });
		let value = e.target.value


		var projectListData = await getAutoCompleteProject(value);


		if (projectListData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData.data })
		} else if (projectListData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData })
		}

	}

	handleSweet = () => {

		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();
	}

	render() {

		const { error, source, sweetShow, type, title, option, isLoading, source_id, userName, is_magnet } = this.state;


		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<Loader show={this.state.showLoader} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6 project_name">
									<div className="form-group">
										<Autocomplete
											id="projectData"
											getOptionSelected={(option, value) => option.name === value.name}
											getOptionLabel={option => option.name}
											options={option}
											loading={isLoading}
											onChange={(_event, option) => {
												if (option === null) {
													this.setState({
														option: [],
														project_id: '',
													})
												} else {
													var projectId = option.id;
													this.setState({
														project_id: projectId,
													})
												}

											}}
											renderInput={params => (
												<TextField
													{...params}
													label="Project Name"
													reqiured
													fullWidth
													onChange={this.handleAutoChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{isLoading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" reqiured>Select Source</InputLabel>
										<Select
											error={this.state.isError.source_id !== '' ? 'error' : ''}
											labelId="demo-controlled-open-select-label"
											value={source_id}
											onChange={this.formValChange}
											inputProps={{
												name: 'source_id',
												id: 'source_id',
											}}
											helperText={this.state.isError.source_id !== '' ? this.state.isError.source_id : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{source ?
												(source.map(reg =>
													<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
												))
												:
												''
											}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 col-12">
									<div className="form-group">
										<Autocomplete
											id="UserData"
											getOptionSelected={(userName, value) => userName.name === value.name}
											getOptionLabel={userName => userName.name}
											options={userName}
											loading={isLoading}
											onChange={(_event, userName) => {
												if (userName === null) {
													this.setState({
														userName: [],
														assign_rm_id: '',
													})
												} else {
													this.setState({
														assign_rm_id: userName.id,
													})
												}

											}}
											renderInput={params => (
												<TextField
													{...params}
													label="User Name"
													reqiured
													fullWidth
													onChange={this.handleAutoUserChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{isLoading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" reqiured>Select Type</InputLabel>
										<Select
											error={this.state.isError.type !== '' ? 'error' : ''}
											labelId="demo-controlled-open-select-label"
											value={type}
											onChange={this.formValChange}
											inputProps={{
												name: 'type',
												id: 'type',
											}}
											helperText={this.state.isError.type !== '' ? this.state.isError.type : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="assign">Assign</MenuItem>
											<MenuItem value="sourcing">Sourcing</MenuItem>
											<MenuItem value="reactivation">Reactivation</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-md-6">
									<div className="col-md-12 p-0">
										<FormLabel component="legend" reqiured>Magnet Lead</FormLabel>
									</div>
									<RadioGroup row aria-label="" name="is_magnet" onChange={this.formValChange}>
										<div className="col-6 p-0">
											<FormControlLabel value="1" control={<Radio color="primary" checked={is_magnet == "1"} />} label="Yes" />
										</div>
										<div className="col-6 p-0">
											<FormControlLabel value="0" control={<Radio color="primary" checked={is_magnet == "0"} />} label="No" />
										</div>
									</RadioGroup>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend" reqiured>Nationality</FormLabel>
										<RadioGroup row aria-label="nationality" name="nationality" value={this.state.nationality} onChange={this.formValChange}>
											<FormControlLabel value="1" control={<Radio color="primary" checked={this.state.nationality == 1} />} label="Indian" />
											<FormControlLabel value="2" control={<Radio color="primary" checked={this.state.nationality == 2} />} label="NRI" />
										</RadioGroup>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateProjectForm}>Submit</button>
										<p className="mb-0">
											<span className="text-center text-danger small">{error != '' ? error : ''}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(AddAutoAssignForm);
