import {get, post, Delete,put} from "../utils/methods.js";

export const getAllProjectData = async (value) => {
	// console.log("Get All PROJECT Data");
	let allProjectData = await get('/projects',value)
	// console.log('allProjectData ===>', allProjectData);
	allProjectData.data = allProjectData.data.projects;
	return  allProjectData;

}

export const getAllFocusProject = async (value) =>{

	// console.log("valuexxxxxxxxxxxx",value);

	let getAllFocusProjectData = await get('/users/focus_projects',value);
	// console.log('getAllFocusProjectData ===>', getAllFocusProjectData);
	return  getAllFocusProjectData;
}

export const getAddUpdateFocusProject = async (value) =>{

	// console.log("valuexxxxxxxxxxxx",value);

	let getAddUpdateFocusProjectData = await post('/focus_projects/focus_project_upsert',value);
	// console.log('getAddUpdateFocusProjectData ===>', getAddUpdateFocusProjectData);
	return  getAddUpdateFocusProjectData;
} 

export const getUserFocus = async (value) =>{

	// console.log("valuexxxxxxxxxxxx",value);

	let getUserFocusData = await get('/focus_projects',value);
	// console.log('getUserFocusData ===>', getUserFocusData);
	return  getUserFocusData;
} 

export const addProject = async (value) =>{
	// console.log("valuexxxxxxxxxxxx",value);
	let addProjectData = await post('/projects',value)
	// console.log('addProjectData ===>', addProjectData);
	return  addProjectData;
}

export const updateProject = async (value) =>{

	let updateProjectData = await put('/projects/'+value.id, value.data)
	// console.log('updateProjectData ===>', updateProjectData);
	return  updateProjectData;
} 

export const getMagnetRequest = async (value) =>{
	// console.log("valuexxxxxxxxxxxx",value);
	let resData = await get('/projects/magnet/projects',value)
	return  resData;
}
export const deleteProject = async (id) =>{
	let deleteProjectData = await Delete('/projects/'+id)
	return  deleteProjectData;
} 

export const getAutoCompleteBuilder = async (value) =>{
	let getData = await get('/projects/builder_auto_complete?q='+value)

	return  getData;
}

export const addProjectFloor = async (value) =>{
	let addFloorData = await post('/project_meta/',value)
	return  addFloorData;
} 

export const updateFloorPlan = async (id,value) =>{

	let updateFloorData = await put('/project_meta/'+id, value)
	return  updateFloorData;
} 

export const removeFloorPlan = async (id) =>{
	let removeFloorData = await put('/project_meta/'+id+'/delete')
	return removeFloorData;
}

// export const getFloorDetail = async (id) =>{
// 	let getFloorData = await get('/project_meta/'+id)
// 	return getFloorData;
// }
export const addAmenities = async (value) =>{
	let addAmenitiesData = await post('/amenities',value)
	return  addAmenitiesData;
}

export const updateAmenities = async (id,value) =>{

	let updateAmenitiesData = await put('/project_amenities/'+id, value)
	return  updateAmenitiesData;
} 

export const getAmenities = async () =>{
	let getAmenitiesData = await get('/amenities')
	// console.log('getAmenitiesData ===>', addAmenitiesData);
	return getAmenitiesData;
}

// export const getAmenitiesDetail = async (id) =>{
// 	let getAmenitiesData = await get('/amenities/'+id)
// 	// console.log('getAmenitiesData ===>', addAmenitiesData);
// 	return getAmenitiesData;
// }

export const removeAmenities = async (id) =>{
	let removeAmenitiesData = await put('/amenities/'+id+'/delete')
	// console.log('removeAmenitiesData ===>', addAmenitiesData);
	return removeAmenitiesData;
}

export const selectedAmenities = async (value) =>{
	let selectedAmenitiesData = await post('/project_amenities',value)
	// console.log('selectedAmenitiesData ===>', addAmenitiesData);
	return selectedAmenitiesData;
}

export const getProjectDetail = async (value) =>{
	// console.log("valuexxxxxxxxxxxx",value);

	let getAutoProjectData = await get('/projects/'+value)
	// console.log('getAutoProjectData ===>', getAutoProjectData);
	return  getAutoProjectData;
}

export const getAutoCompleteProject = async (value) =>{
	// console.log("valuexxxxxxxxxxxx",value);

	let getAutoProjectData = await get('/projects/auto_complete?q='+value)
	// console.log('getAutoProjectData ===>', getAutoProjectData);
	return  getAutoProjectData;
}

export const getRegion = async () =>{

	var listData = {};
    listData.limit = 50;	

	let getRegionData = await get('/regions', listData)
	// console.log('getRegionData ===>', getRegionData);
	if(getRegionData.meta.status === 200){
        return  getRegionData.data;
    }
    return [];
    
}

// export const getChangePwdRes = async (value) => {
	
// 	console.log("the in the parser value");
// 	let changepwData = await patch('/auth/change_password',value)
// 	console.log('changepwData ===>', changepwData);
// 	return  changepwData;

// }

export const projectImages = async (value) => {
	return await post('/projectImages',value);
}
export const deleteProjectImages = async (value) => {
	return await put('/projectImages',value);
}

export const saveProjectDocuments = async (value) => {
	let saveMagnetMoneyExchange = await post(
	  `/projectImages`,
	  value
	);
	return saveMagnetMoneyExchange;
  };

export const getProjectDocuments = async (project_id, year, quarter, type) => {
	return await get(`projectImages/view_document/${project_id}?quarter=${quarter}&year=${year}&type=${type}`);
}