import React, { Component } from 'react';
// import { renderToStaticMarkup } from 'react-dom/server';
import SweetAlert from 'react-bootstrap-sweetalert'; // eslint-disable-line import/no-extraneous-dependencies
// import 'sweetalert/dist/sweetalert.css';
import Frag from "../../../utils/Frag/Frag.js";

class sweetAlert extends Component {

	// constructor(props){
	// 	super(props);
	// 	this.state = {
	//       sweetShow: false,
	// 	};
	// };

	// onOutsideClick={this.props.changeSweet} ===== For Making the alert hide onClick out Side.
   // onCancel={this.props.changeSweet}
                  // showCancelButton

	render(){
		// console.log("this.props.showXXXXXXXXXXXX",this.props);
		// console.log("this.props.Sweetshow",this.props.changeSweet);
		return(

			<Frag>
				<SweetAlert
		          type={this.props.type}
		          show={this.props.show}
		          title={this.props.type}
		          html
				  showConfirm={this.props.showConfirm}
				  showCancel={this.props.showCancel}
		          onConfirm={this.props.changeSweet}
				  onCancel={this.props.onCancel}
		          confirmButtonColor="#a5dc85"
				  confirmBtnText={this.props.confirmBtnText}
				  cancelBtnText={this.props.cancelBtnText}
		        >
		        {this.props.title}
		        </SweetAlert>	
			</Frag>

		);
	};

};

export default sweetAlert;