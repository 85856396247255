import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { user_access } from "../../config/config.js";
import { AMAZON_URL } from "../../config/serverKey.js";
import ProfileModal from "../../component/common/modal/CpUserModel.js";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./side_header.css";
import "../../assets/css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faUserPlus,
    faChartLine,
    faPaperclip,
    faPowerOff,
    faCrosshairs,
    faBuilding,
    faLink,
    faAngleLeft,
    faCircle,
    faTachometerAlt,
    faPlusSquare,
    faAngleDown,
    faBookReader,
    faUsersCog,
    faUser,
    faPhoneVolume,
    faBug,
    faHome,
} from "@fortawesome/free-solid-svg-icons";
import { getLogout } from "../../dataParser/auth";
import { getSingleCpUser } from "../../dataParser/getCpUserData";
import { cleanStorage } from "../../helper";
import Frag from "../../utils/Frag/Frag.js";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { getMe } from "../../dataParser/commomDataApi.js";
import { faFacebook, faTelegram } from "@fortawesome/free-brands-svg-icons";
import MagnetModel from "../common/modal/magnets/MagnetModel.js";
// import { CRM_VIDEOS } from "../../config/constant/index.js";
import ReactDOM from "react-dom";
import TCModel from "../common/modal/termsConditionModel.js";


class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: false,
            leadShow: false,
            outSideClick: false,
            modalCpUpdateShow: false,
            singleUser: "",
            user_id: "",
            userName: "",
            role: "",
            locationName: "",
            roleShow: false,
            magnetBudgetShow: false,
            bookingShow: false,
            leadPoolShow: false,
            hrDocShow: false,
            reportShow: false,
            projectShow: false,
            ivrShow: false,
            referShow: false,
            is_user_rating: false
            , magnetModalShow: false
			, tcModalShow :false
            , profileModalShow: false
        };
    }

    async componentDidMount() {
        window?.$user?.id && this.handleSingleUser(window?.$user?.id)
        this.setState({
            user_id: window.$user?.id,
            role: window.$role,
            group: window.$user?.group.toLowerCase(),
            userName: window.$user?.first_name,
            user:window.$user
            , activeTab: 1
            , hideInactiveTab: true
        });
            var tlUser = await getMe();

            if(tlUser.meta.status === 200){
                if(tlUser.data.UserInfo !== null){
                    this.setState({
                        tlName: (tlUser?.data?.UserInfo?.UserServiceRm?.name),
                        tlFirstName: (tlUser?.data?.UserInfo?.UserServiceRm?.first_name),
                        tlPhone: (tlUser?.data?.UserInfo?.UserServiceRm?.phone),
                        tlId: (tlUser?.data?.UserInfo?.UserServiceRm?.id)
                    })
                }
            }

    }

    handleTabShow = name => {
        if (name == "Leads") {
            this.setState({ leadShow: !this.state.leadShow });
        } else if (name == "Reports") {
            this.setState({ reportShow: !this.state.reportShow });
        } else if (name == "Miscellaneous") {
            this.setState({ roleShow: !this.state.roleShow });
        } else if (name == "Magnet Budget") {
            this.setState({ magnetBudgetShow: !this.state.magnetBudgetShow });
        } else if (name == "Post Sales") {
            this.setState({ bookingShow: !this.state.bookingShow });
        } else if (name == "Open Leads") {
            this.setState({ openShow: !this.state.openShow });
        } else if (name == "Projects") {
            this.setState({ projectShow: !this.state.projectShow });
        } else if (name == "HR Docs") {
            this.setState({ hrDocShow: !this.state.hrDocShow });
        }
        else if (name == "IVR Management") {
            this.setState({ ivrShow: !this.state.ivrShow });
        }else if (name == "Referrals") {
            this.setState({ referShow: !this.state.referShow });
        }else if (name == "Activity") {
            this.setState({ activityShow: !this.state.activityShow });
        }else {
            this.setState({ leadPoolShow: !this.state.leadPoolShow });
        }
    };

    logout = async () => {
        var resData = {};
        resData = await getLogout();
        cleanStorage();

        if (resData.meta.status === 200) {
            cleanStorage();
        } else if (resData.meta.status === 401) {
            cleanStorage();
        } else {
        }
    };

    handleUpdateCpModal = () => {
        this.setState({
            modalCpUpdateShow: !this.state.modalCpUpdateShow,
            singleUser: ""
        });
    };

    handleMagnetModal = (index) =>{
        this.setState({
            magnetModalShow: !this.state.magnetModalShow
            , activeTab: index
            , hideInactiveTab: false
        })
    }

    handleProfileModal = () => {
        this.setState({
            profileModalShow: !this.state.profileModalShow,
            // singleUser: ""
        });
    };

    handleSingleUser = async id => {
        this.setState({ showLoader: true });
        let singleUser = await getSingleCpUser(id);

        let getData = singleUser.data;
        let CpData = getData.UserInfo;
        let arrParent = getData.UserRegions !== null ? getData.UserRegions : [];

        let userDataArr = [];
        let parentUser = [];
        arrParent.map((cur, index) => {
            let parent = {};
            parent.id = cur.RegionParent?.id
            parent.name = cur.RegionParent?.name
            userDataArr.push(parent);
            parentUser.push(cur.RegionParent?.id);
        })
        let alternate_business_us = CpData.alternate_business_us === null ? "" : CpData.alternate_business_us || [];
        let parent_region_ids = parentUser;
        let office_location = CpData.office_location === null ? "" : CpData.office_location;

        if(!(alternate_business_us && parent_region_ids.length > 0 && office_location)){
            this.setState({
                magnetModalShow :  true
            })
        }
        if(window.$user?.role =="magnet" && window.$user?.is_tc_agree == 0)
		{
			this.setState ({tcModalShow :true});
        }
        if (singleUser.meta.status === 200) {
            this.setState({
                showLoader: false,
                sweetShow: false,
                singleUser: singleUser
            });
        } else {
            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "error",
                title: "User not found !!!"
            });
        }
    };

    goToPage = (url, name) => {
        if (name === 'Library') {
            window.open(url, '_blank')
        }
        else {
            this.setState({ locationName: url });
            document.getElementById("myHeader").classList.remove("mobile-side-open");
            this.props.history.push(url);
        }
    };

    handleDrawer = event => {
        this.setState({ sideBar: !this.state.sideBar });
        document.body.classList.add("sidebar-enable");
    };

    handleTcModal=() => {
		this.setState({tcModalShow : !this.state.tcModalShow});
    };

    render() {

        const { userName, singleUser, role, tlFirstName, tlName,tlPhone, tlId, group, user, activeTab, hideInactiveTab, tcModalShow } = this.state;
        const icons = {
            faUserPlus: faUserPlus,
            faChartLine: faChartLine,
            faPaperclip: faPaperclip,
            focus: faCrosshairs,
            DashboardIcon: faTachometerAlt,
            Link: faLink,
            PeopleIcon: faUser,
            atlassian: faAddressBook,
            ApartmentIcon: faPlusSquare,
            SupervisorAccountIcon: faUser,
            Building: faBuilding,
            Phone: faPhoneVolume,
            Error: faBug,
            Home: faHome
        };
        const vCardUrl = `https://www.homesfy.in/vcard/?me=${tlFirstName}&user=${tlId}`

        const ISSUE_SUPPORT =  {
            magnet: "partnersupport@mymagnet.io",
            digital: "querydigital@homesfy.in",
            crmtech: "querycrm@homesfy.in"
        }
        const join = {
            telegram: "https://t.me/MAGNETindia",
            facebook: "https://www.facebook.com/magnethomesfy"
        }
        return (
            <aside
                className={
                    "main-sidebar sidebar-dark-primary elevation-4" +
                    " " +
                    (this.state.sideBar ? "main-sidebar-mini" : "") +
                    " " +
                    (this.props.mobileBar ? "main-sidebar-open" : "")
                }
                onMouseEnter={this.props.buttonClick}
                onMouseLeave={this.props.buttonClick}
                ref={this.setWrapperRef}
            >
                <div className="brand-link d-none d-lg-flex">
                    {this.state.sideBar ? (
                        this.state.group === "magnet" ? (
                            <Link style={{width:"100%"}} to={role !== "hrhead" && role !== "mediamanagers" && role !== "financehead" ? "/": "#"}>
                                <img
                                    className="small_site_img"
                                    src={`${AMAZON_URL}/ColorIcon.svg`}
                                    alt="small_site_logo"
                                />
                            </Link>
                        ) : (
                            <Link style={{width:"100%"}} to={role !== "hrhead" && role !== "mediamanagers" && role !== "financehead" ? "/": "#"}>
                                <img
                                    className="small_site_img"
                                    src={`${AMAZON_URL}/HomesfyCirclewhiteBorder.png`}
                                    alt="small_site_logo"
                                />
                            </Link>
                        )
                    ) : this.state.group === "magnet" ? (
                        <Link style={{width:"100%"}} to={role !== "hrhead" && role !== "mediamanagers" && role !== "financehead" ? "/": "#"}>
                            <img
                                className="site_img"
                                src={`${AMAZON_URL}/whitelogo.svg`}
                                alt=" site_image"
                            />
                        </Link>
                    ) : (
                        <Link style={{width:"100%"}}
                              to={role !== "hrhead" && role !== "mediamanagers" && role !== "financehead" ? "/": "#"} >
                            <img
                                className="site_img"
                                src={`${AMAZON_URL}/WhiteHomesfyLogo.svg`}
                                alt=" site_image"
                            />
                        </Link>
                    )}
                    <span className="p-3 cursor-point" data-widget="pushmenu" onClick={this.handleDrawer}>
            {this.state.sideBar ? <CloseIcon/> : <MenuIcon/>}
          </span>
                </div>
                <div className="sidebar">
                    <div className="user-panel my-1 py-1 d-flex" 
                        onClick={ ()=> role == "magnet" && this.handleMagnetModal(0)}
                    >
                        <div className="image">
                            <AccountCircleIcon className="userIcon" />
                        </div>
                        <div
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={ "Update Profile" }
                            className="info"
                            style={{ cursor: "pointer" }}
                            onClick={e => {
                                this.handleUpdateCpModal();
                                this.handleSingleUser(this.state.user_id);
                            }}
                        >
              <span className="d-block text-capitalize userName">
                {userName}
              </span>
                        </div>
                        <div
                            data-toggle="tooltip"
                            data-placement="bottom"
                            className="info"
                            title={
                                "Update Profile"
                            }
                            onClick={e => {
                                this.handleUpdateCpModal();
                                this.handleSingleUser(this.state.user_id);
                            }}
                        >
                            <SettingsIcon className="text-white mr-4 mt-1 cursor-point" />
                        </div>
                    </div>
                    <nav className="mt-1 small_link_hover">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                        {role == "magnet" &&
                            <li
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={ "Update Profile" }
                                className="nav-item"
                                style={{ cursor: "pointer" }}
                                onClick={e => {
                                    this.handleProfileModal();
                                    this.handleSingleUser(this.state.user_id);
                                }}
                            >
                                <a className="text-capitalize nav-link text-white">
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="nav-icon"
                                    />
                                    <p className="inlineShow">My Profile</p>
                                </a>
                            </li>
                        }
                            <Frag>
                                {(window.$role !== "magnet" && window.$role !== "magnetplatinum") &&
                                <li className="user-panel nav-item mb-1 pb-1">
                                    <a className="nav-link text-white" href="https://dashboard.truein.com/login.php" target="_blank">
                                        <FontAwesomeIcon
                                            icon={faUsersCog}
                                            className="nav-icon"
                                        />
                                        <p className="inlineShow">
                                            TrueIn
                                        </p>
                                    </a>
                                    <a className="nav-link text-white" href="https://app.keka.com/Account/Login" target="_blank">
                                        <FontAwesomeIcon
                                            icon={faUsersCog}
                                            className="nav-icon"
                                        />
                                        <p className="inlineShow">
                                            Keka
                                        </p>
                                    </a>
                                </li>
                                }
                                {window.$role !== "magnetplatinum" &&
                                    <li className="nav-item">
                                        <a 
                                            className="nav-link text-white" 
                                            // href={CRM_VIDEOS[role]} 
                                            target="_blank" 
                                            title="Coming Soon.."
                                        >
                                            <FontAwesomeIcon
                                                icon={faBookReader}
                                                className="nav-icon"
                                                />
                                            <p className="inlineShow">
                                                {this.state.group !== 'magnet' ?
                                                    "Knowledge" : "Magnet Shala"
                                                }
                                            </p>
                                        </a>
                                    </li>
                                }
                                {user_access.length > 0 &&
                                user_access.map(
                                    (userData, index) =>
                                        userData.user_role.includes(role) && (
                                            <li className={"nav-item has-treeview" + " " + (userData?.has_group == 1 ? "user-panel": "")} key={index}>
                                                <a to={userData.url} className={"nav-link text-white" + " " + (this.state.locationName === userData.url ? "active": "") } data-toggle="tooltip"data-placement="bottom"title={userData.name} onClick={e => {userData.sub_url.length > 0 ? this.handleTabShow(userData.name) : this.goToPage(userData.url); }} >
                                                    <FontAwesomeIcon
                                                        icon={icons[userData.icon]}
                                                        className="nav-icon"
                                                    />
                                                    {userData.sub_url.length > 0 && (
                                                        <FontAwesomeIcon
                                                            icon={
                                                                (
                                                                    userData.name == "Leads"
                                                                        ? this.state.leadShow
                                                                        : userData.name == "Reports"
                                                                        ? this.state.reportShow
                                                                        : userData.name == "Miscellaneous"
                                                                            ? this.state.roleShow
                                                                            : userData.name == "Magnet Budget"
                                                                                ? this.state.magnetBudgetShow
                                                                                : userData.name == "Projects"
                                                                                    ? this.state.projectShow
                                                                                    : userData.name == "IVR Management"
                                                                                        ? this.state.ivrShow
                                                                                        : userData.name == "Referrals"
                                                                                        ? this.state.referShow
                                                                                        : userData.name == "Activity"
                                                                                        ? this.state.activityShow
                                                                                        : userData.name == "HR Docs"
                                                                                            ? this.state.hrDocShow
                                                                                            : userData.name == "Post Sales"
                                                                                                ? this.state.bookingShow
                                                                                                : this.state.leadPoolShow
                                                                )
                                                                    ? faAngleDown
                                                                    : faAngleLeft
                                                            }
                                                            className="right leftSideIcon"
                                                        />
                                                    )}
                                                    <p className="inlineShow" >{userData.name}
                                                    </p>
                                                </a>
                                                {userData.sub_url.length > 0 &&
                                                (
                                                    <ul className={"nav nav-treeview" + " " + ((userData.name == "Leads"? this.state.leadShow :
                                                        userData.name == "Projects"? this.state.projectShow :
                                                            userData.name == "Reports"? this.state.reportShow :
                                                                userData.name == "Miscellaneous"? this.state.roleShow :
                                                                    userData.name == "Magnet Budget" ? this.state.magnetBudgetShow :
                                                                        userData.name == "HR Docs" ? this.state.hrDocShow :
                                                                            userData.name == "Post Sales" ? this.state.bookingShow :
                                                                                userData.name == "IVR Management" ? this.state.ivrShow :
                                                                                userData.name == "Referrals" ? this.state.referShow :
                                                                                userData.name == "Activity" ? this.state.activityShow :
                                                                                    this.state.leadPoolShow ) ? "show": "hide") } >
                                                        {userData.sub_url.map(
                                                            (subLink, index) =>
                                                                subLink.user_role.includes(role) && (
                                                                    <li className="nav-item" key={index}>
                                                                        <a to={subLink.url} data-toggle="tooltip"data-placement="bottom"title={subLink.name} className={"nav-link subLinkTitle" + " " + (this.state.locationName === subLink.url ? "active": "") } onClick={e => {subLink.sub_url?.length > 0 ? this.handleTabShow(subLink.name) : this.goToPage(subLink.url, subLink.name); }} >
                                                                            <FontAwesomeIcon icon={faCircle} className="nav-icon"style={{ color: subLink.color }} />
                                                                            <p
                                                                                className="inlineShow sublinkName"
                                                                            >
                                                                               {subLink.name}
                                                                            </p>
                                                                            {subLink.sub_url?.length > 0 && (
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        (
                                                                                            subLink.name == "Open Leads"
                                                                                                ? this.state.openShow
                                                                                                : ''
                                                                                        )
                                                                                            ? faAngleDown
                                                                                            : faAngleLeft
                                                                                    }
                                                                                    className="right leftAngleIcon"
                                                                                />
                                                                            )}
                                                                        </a>
                                                                        {subLink.sub_url?.length > 0 &&
                                                                        (
                                                                            <ul
                                                                                className={
                                                                                    "nav nav-treeview" +
                                                                                    " " +
                                                                                    ( (
                                                                                        subLink.name == "Open Leads"
                                                                                            ? this.state.openShow
                                                                                            : ''
                                                                                    )
                                                                                        ? "show"
                                                                                        : "hide")
                                                                                }
                                                                            >
                                                                                {subLink.sub_url?.map(
                                                                                    (childLink, index) =>
                                                                                        childLink.user_role?.includes(role) && (
                                                                                            <li className="nav-item ml-2" key={index}>
                                                                                                <Link
                                                                                                    to={childLink.url}
                                                                                                    data-toggle="tooltip"
                                                                                                    data-placement="bottom"
                                                                                                    title={childLink.name}
                                                                                                    className={
                                                                                                        "nav-link childLinkTitle d-flex" +
                                                                                                        " " +
                                                                                                        (this.state.locationName ===
                                                                                                        childLink.url
                                                                                                            ? "active"
                                                                                                            : "")
                                                                                                    }
                                                                                                    onClick={e =>
                                                                                                        this.goToPage(childLink.url)
                                                                                                    }
                                                                                                >
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={faCircle}
                                                                                                        className="nav-icon"
                                                                                                        style={{ color: childLink.color }}
                                                                                                    />
                                                                                                    <p className="childLinkName sublinkName fs-14">
                                                                                                        {childLink.name}
                                                                                                    </p>
                                                                                                </Link>
                                                                                            </li>
                                                                                        )
                                                                                )}
                                                                            </ul>
                                                                        )}
                                                                    </li>
                                                                )
                                                        )}
                                                    </ul>
                                                )}
                                            </li>
                                        )
                                )}
                            </Frag>
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={this.logout}>
                                    <FontAwesomeIcon
                                        icon={faPowerOff}
                                        className="nav-icon text-danger"
                                    />
                                    <p className= "inlineShow">
                                        Log Out
                                    </p>
                                </a>
                            </li>
                            { group === "magnet" ?
                                <li className="nav-item text-white ServiceRm px-1">
                                    <div className={role === "magnet" ? "RmDetail mt-5" : "RmDetail"}>
                                    {(role === "magnet" && tlName && tlPhone) &&
                                        <Frag>
                                            <div className="">RM</div>
                                            <div className="py-1 fs-14 text-capitalize"><a href={vCardUrl} target="_blank">{tlName}</a></div>
                                            <div className="fs-14">{tlPhone}</div>
                                        </Frag>
                                    }
                                        <a href={`mailto: ${ISSUE_SUPPORT[group]}`}>{ISSUE_SUPPORT[group]}</a>
                                        <div className="JoinGroup">
                                            <div className="JoinTitle mb-2">Join group for latest updates</div>
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <a href={join.telegram} target="_blank" className="px-2 mx-1"><FontAwesomeIcon icon={faTelegram} size="lg" className="text-white" /></a>
                                                    <a href={join.facebook} target="_blank" className="px-2 mx-1"><FontAwesomeIcon icon={faFacebook} size="lg" className="text-white" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                : 
                                <li className="nav-item text-white px-1">
                                    <div className="IssueSupport mt-">
                                        {group === "digital" ?
                                        <a href={`mailto: ${ISSUE_SUPPORT[group]}`}>{ISSUE_SUPPORT[group]}</a>
                                        :
                                        <a href={`mailto: ${ISSUE_SUPPORT["crmtech"]}`}>{ISSUE_SUPPORT["crmtech"]}</a>
                                    }
                                    </div>
                                </li>
                            }
                        </ul>
                    </nav>
                    {singleUser.meta && (
                        role !== "magnet"
                        ?<ProfileModal
                            show={this.state.modalCpUpdateShow}
                            changeModal={this.handleUpdateCpModal}
                            CpUpdateDetail={singleUser}
                            profileUpdate="profile"
                            user={this.state.user}
                        />
                        :
                        role === "magnet" && 
                        <>
                        <MagnetModel
                            show={this.state.magnetModalShow}
                            changeModal={this.handleMagnetModal}
                            activeTab={activeTab}
                            hideInactiveTab={hideInactiveTab}
                            />
                        <ProfileModal
                            show={this.state.profileModalShow}
                            changeModal={this.handleProfileModal}
                            CpUpdateDetail={singleUser}
                            profileUpdate="profile"
                            user={this.state.user}
                            />
                        </>
                    )
                    }
                    {tcModalShow &&
                        <TCModelPortal  show={tcModalShow} fromType="termsCondition" changeModal={this.handleTcModal}   />
                    }
                </div>
            </aside>
        );
    }
}
const TCModelPortal = (props) =>{
  return ReactDOM.createPortal(
    <TCModel {...props} />
    , document.getElementsByTagName('body')[0]
    )
}
export default withRouter(SideBar);
