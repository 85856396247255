import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
// import {Form} from 'react-bootstrap';
// import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from "../../../component/common/loader/loader";
import { addAmenities, getAmenities, removeAmenities, selectedAmenities } from "../../../dataParser/getProjectData";
// import CustomizedSnackbars from '../../autoLogout/snackBar';
import PopUpAlert from "../sweetAlert/popUpAlert";
import Button from '@material-ui/core/Button'

class AddProjectAmenities extends Component {

	constructor(props) {
		super(props)
		this.state = {
			amenities_addedby: '',
			showLoader: false,
			alertShow: false,
			type: 'success',
			title: '',
			isDisabled: false,
			btnText: 'submit',
			amenityName: '',
			amenityType: '',
			indoorAmenitiesData: [],
			outdoorAmenitiesData: [],
			// selected: [],
			project_id: this.props.updateProjectId,
			amenitiesUnder: this.props.amenitiesDetail?.map(item => item?.Amenity) ? this.props.amenitiesDetail?.map(item => item?.Amenity) : [],
			allAmenities: [],
		}
		this.handleChange = this.handleChange.bind(this)
	}

	async componentDidMount() {

		// this.state.project_id ? 
		// this.fullProjectDetail(this.state.project_id) 
		// : 
		this.getAmenitiesData()

		var homesfy_lg = (localStorage.getItem("homesfy_lg"));
		var addedBy = '';
		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg));
			addedBy = user.id;
			this.setState({ amenities_addedby: addedBy })

		} else {
			this.setState({ amenities_addedby: addedBy })
		}

	}

/* 	fullProjectDetail = async (id) => {
		this.setState({ showLoader: true });
		var resData = {};
		resData = await getProjectDetail(id);
		let data = resData.data.ProjectAmenities;

		let indoor = data.filter(item => item?.Amenity?.type === 'indoor').map(item=> item?.Amenity);
		let outdoor = data.filter(item => item?.Amenity?.type === 'outdoor').map(item=> item?.Amenity);

	       await this.setState({
			indoorAmenitiesData: [...indoor],
			outdoorAmenitiesData: [...outdoor],
	           showLoader: false
	       });
		if (resData.meta.status === 200) {
			await this.setState({
				showLoader: false
			})
		} 
	} */

	getAmenitiesData = async () => {
		this.setState({ showLoader: true });

		let resData = await getAmenities();

		let indoor = resData.data.filter(item => item.type === 'indoor')
		let outdoor = resData.data.filter(item => item.type === 'outdoor')

		await this.setState({
			indoorAmenitiesData: [...indoor],
			outdoorAmenitiesData: [...outdoor],
			allAmenities: resData.data,
			showLoader: false
		});
	}

	handleCheck = (id) => {
		const { allAmenities, amenitiesUnder } = this.state;
		const amenitiesUnderIds = amenitiesUnder?.map(
			(amenities) => amenities?.id
		);

		if (amenitiesUnderIds?.includes(id)) {
			//remove employee from employeesUnder list
			const newArrWithRemovedAmenities = amenitiesUnder.filter((amenities) => {
				return amenities?.id !== id;
			});
			this.setState(
				{
					...this.state,
					amenitiesUnder: newArrWithRemovedAmenities
				}
			);
		} else {
			//add employee to employeesUnder list
			const amenitiesIndex = allAmenities.findIndex(
				(amenities) => amenities?.id == id
			);
			const newArrWithAddedAmenities = [
				...amenitiesUnder,
				allAmenities[amenitiesIndex]
			];

			this.setState(
				{
					...this.state,
					amenitiesUnder: [...newArrWithAddedAmenities]
				}
			);
		}
	};

	handleChange(e) {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	addNewAmenities = async () => {
		let { amenityName, amenityType,
			indoorAmenitiesData, outdoorAmenitiesData
		} = this.state
		let values;
		if (amenityType !== '' && amenityName !== '') {
			if (amenityType == 'indoor') values = [...indoorAmenitiesData];
			if (amenityType == 'outdoor') values = [...outdoorAmenitiesData];
			// const values = [...this.state.allAmenities]
			this.setState({
				showLoader: true,
			})

			let data = {
				"name": amenityName,
				"type": amenityType,
			}

			let resData = await addAmenities(data);

			!this.state.project_id && this.getAmenitiesData()

			if (resData.meta.status === 201) {
				values.push(resData.data)
				this.setState({
					showLoader: false,
					alertShow: true,
					title: "Added amenities successfully !"
				})
			}
			else {
				this.setState({
					showLoader: false,
					alertShow: true,
					type: "error",
					title: resData.meta.message
				});
			}
			(amenityType == 'indoor') ? this.setState({ indoorAmenitiesData: values, amenityName: "" }) :
				this.setState({ outdoorAmenitiesData: values, amenityName: "" })
			this.setState({ allAmenities: values, amenityName: "" })
		}
	}

	handleClick = (id) => {
		const selected = [...this.state.selected];
		selected.push(id);
		this.setState({ selected: selected });
	}
	handleRemoveAmenities = async (i, id, type) => {
		this.setState({ showLoader: true });
		const indoors = [...this.state.indoorAmenitiesData];
		const outdoors = [...this.state.outdoorAmenitiesData];
		if (type === 'indoor') indoors.splice(i, 1)
		if (type === 'outdoor') outdoors.splice(i, 1)
		const allAmenities = [...this.state.allAmenities]
		var deleteRes = await removeAmenities(id);

		if (deleteRes.meta.status === 200) {
			allAmenities.splice(i, 1)

			this.setState({
				showLoader: false,
				alertShow: true,
				title: "Removed amenities successfully !"
			});

		}
		else {
			this.setState({
				showLoader: false,
				alertShow: true,
				type: "error",
				title: deleteRes.meta.message
			});
		}
		this.setState({ indoorAmenitiesData: indoors, outdoorAmenitiesData: outdoors });
		// this.setState({ allAmenities: allAmenities });
	}

	addSelectedAmenities = async (e) => {
		e.preventDefault()
		this.setState({
			showLoader: true,
		})
		let data = {}
		data["ids"] = this.state.amenitiesUnder?.map((amenities) => amenities?.id)
		data["created_by"] = this.state.amenities_addedby
		if (this.props.projectId) data["project_id"] = parseInt(this.props.projectId)
		var addLeadRes = await selectedAmenities(data);
		if (addLeadRes.meta.status === 201) {

			this.setState({
				showLoader: false,
				alertShow: true,
				// isDisabled: true,
				// btnText: 'submitted',
				title: "Submitted amenities successfully !"
			})
		}
	}

	updateSelectedAmenities = async (e) => {
		e.preventDefault()
		this.setState({
			showLoader: true,
		})
		let data = {}
		data["ids"] = this.state.amenitiesUnder?.map((amenities) => amenities?.id)
		data["updated_by"] = this.state.amenities_addedby
		if (this.props.projectId) data["project_id"] = parseInt(this.props.projectId)
		var addLeadRes = await selectedAmenities(data);
		if (addLeadRes.meta.status === 201 || addLeadRes.meta.status === 200) {

			this.setState({
				showLoader: false,
				alertShow: true,
				// isDisabled: true,
				// btnText: 'submitted',
				title: "Submitted amenities successfully !"
			});
		}
	}

	handleClose = () => {
        this.setState({ alertShow: false });
	}

	render() {
		const { amenitiesUnder } = this.state;
		const amenitiesUnderIds = amenitiesUnder?.map((amenities) => amenities?.id);
		let { amenityName, amenityType,
			indoorAmenitiesData, outdoorAmenitiesData,
			showLoader, alertShow, type, title, isDisabled, btnText, } = this.state
		// const amenities = [{type: 'indoor',value: indoorAmenitiesData}, {type: 'outdoor', value: outdoorAmenitiesData}]
		return (
			<Frag>
				<div className="projectForm">
					<Loader show={showLoader} />
					<PopUpAlert type={type} msg={title} open={alertShow} handleClose={this.handleClose}/>
					<form role="form" onSubmit={this.handleSubmit}>
						<div className="card-body">
							<div className="row">
								<div className="col-md-6 col-10">
									<TextField
										id="outlined-basic"
										name='amenityName'
										value={amenityName}
										variant="outlined"
										label="Amenity Name"
										onChange={this.handleChange}
									/>
								</div>
								<div className="col-md-4 col-10 mt-3 mt-md-0">
									<FormControl variant="outlined">
										<InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
										<Select
											label="Type"
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={amenityType} name="amenityType" onChange={this.handleChange}>
											<MenuItem value="">None</MenuItem>
											<MenuItem value="indoor">Indoor</MenuItem>
											<MenuItem value="outdoor">Outdoor</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-md-2 col-2 pl-0">
									<div className="row justify-content-center">
										{/* {alertShow && <CustomizedSnackbars type={type} msg={title} />} */}
										<Tooltip title="Add Amenities">
											<AddCircleIcon
												fontSize='large'
												type="button"
												style={{ color: '#007bff' }}
												onClick={() => this.addNewAmenities()} />
										</Tooltip>
									</div>
								</div>
							</div>

							<div className="row mt-2">
								<div className="col-md-6">
									<b><span className="text-capitalize">Indoor</span> Amenities</b>
									{indoorAmenitiesData?.map((item, i) => (
										<div key={i}>
											<label>
												<input
													type="checkbox"
													value={item.id}
													checked={amenitiesUnderIds?.includes(item.id)}
													onChange={() => this.handleCheck(item.id)}
												/>
												<span className="text-capitalize ml-2">{item?.name}</span>
											</label>
											<button
												className="btn btn-link"
												type="button"
												onClick={() => this.handleRemoveAmenities(i, item?.id, 'indoor')}
											>
												<CancelIcon
													style={{ color: "#c2c2c2" }}
												/>
											</button>
										</div>
									))}
								</div>
								<div className="col-md-6">
									<b><span className="text-capitalize">Outdoor</span> Amenities</b>
									{outdoorAmenitiesData?.map((item, i) => (
										<div key={i}>
											<label>
												<input
													type="checkbox"
													value={item.id}
													checked={amenitiesUnderIds?.includes(item.id)}
													onChange={() => this.handleCheck(item.id)}
												/>
												<span className="text-capitalize ml-2">{item?.name}</span>
											</label>
											<button
												className="btn btn-link"
												type="button"
												onClick={() => this.handleRemoveAmenities(i, item?.id, 'outdoor')}
											>
												<CancelIcon
													style={{ color: "#c2c2c2" }}
												/>
											</button>
										</div>
									))}
								</div>
								{/*amenities.map(({type,value},index)=>
								<div className="col-md-6" key={index}>
									<b><span className="text-capitalize">{type}</span> Amenities</b>
										{value.map((item,idx) => (
											<div key={idx}>
												<label>
													<input 
														type="checkbox" 
														name={item?.name} 
														value={item?.name}
														className="mr-1"
														onClick={()=>this.handleClick(item?.id)}
														/>
												    <span className="text-capitalize">{item?.name}</span>
												</label>
												<button
												  className="btn btn-link"
												  type="button"
												  onClick={() => this.handleRemoveInput(idx,type,item?.id)}
												>
												  <CancelIcon 
												    style={{ color: "#c2c2c2" }}
												  />
												</button>
											</div>
										))}
								</div>
								)*/}
							</div>
							<div className="row justify-content-center my-2">
								<Button
									disabled={isDisabled}
									variant="contained"
									// onClick={this.handleSubmit} 
									onClick={this.props.updateProjectId ? this.updateSelectedAmenities : this.addSelectedAmenities}
									style={{ backgroundColor: '#007bff', color: '#fff' }}
								>
									{btnText}
								</Button>
							</div>

						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(AddProjectAmenities);
