import React, { useState, useEffect } from 'react'
import Frag from '../../utils/Frag/Frag'
import { Paper, Grid } from '@material-ui/core'
import InputField from '../common/controls/InputField'
import SelectOption from '../common/controls/SelectOption'
import { addProject, getRegion } from '../../dataParser/getProjectData'
import GoogleMaps from '../common/googleMap/googleMap'
import ButtonSubmit from '../common/controls/ButtonSubmit'
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import PopUpAlert from '../common/sweetAlert/popUpAlert'
import Loader from '../common/loader/loader'

const RequestProject = () => {
    const [state, setState] = useState({
        project_name: "",
        builder_name: "",
        region: [],
        region_id: "",
        location: "",
        rera_no: '',
        land_area: '',
        towers: '',
        elevation_struc: '',
        possession_date: '',
        possession: '',
        showLoader: false,
        alertShow: false,
        title: "",
        type: 'success'
    });

    const { project_name, builder_name, region_id, region, location, rera_no, land_area, towers, elevation_struc, possession_date, possession, showLoader, alertShow, title, type } = state;

    useEffect(() => {
        (async () => {
            let regionData = await getRegion();
            setState({ region: regionData })
        })();

    }, [])

    const handleChange = e => {
        let { name, value } = e.target;
        if (["land_area","towers","elevation_struc"].includes(name)) {
            value = value.replace(/\D/g, "");
        }
        setState({
            ...state,
            [name]: value
        })
    }

    const handleGeoLocation = async (location) => {
        setState({ ...state, location: location });
    }

    const handleClose = () => {
        setState({ ...state, alertShow: false });
    }

    const handleRequest = async (e) => {
        setState({
            ...state,
            showLoader: true,
        })
        let { project_name, builder_name, location, rera_no, region_id, land_area, towers, elevation_struc, possession_date, possession } = state
        if (!!project_name && !!builder_name && !!location) {
            let data = {}

            if (project_name) { data["name"] = project_name }
            if (builder_name) { data["builder_name"] = builder_name }
            if (region_id) { data["region_id"] = region_id }
            if (location) { data["location"] = location }
            if (rera_no) { data["rera_no"] = rera_no}
            if (land_area) { data["land_area"] = parseInt(land_area) }
            if (towers) { data["tower_no"] = parseInt(towers) }
            if (elevation_struc) { data["storeys"] = parseInt(elevation_struc) }
            if (possession === 'date') {
                data.possession_date = possession_date
            }
            else {
                data.ready_to_move = possession
            }
            data.is_magnet = 1;
            data.is_active = 0;

            let { first_name, last_name } = window?.$user;
            let name = `${first_name} ${last_name}`;
            data["created_by"] = name;
            
            let addLeadRes = await addProject(data);
            if (addLeadRes.meta.status === 201) {
                await setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    type: "success",
                    title: "Project Requested Successfully !!!",
                });
                setTimeout(()=>{
                    window.location.reload();
                }, 500)
            }
            else {
                setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    type: "error",
                    title: addLeadRes.meta.message
                });
            }

        } else {
            setState({
                ...state,
                showLoader: false,
                alertShow: true,
                type: "error",
                title: "Above all * fields are reqiured !!!",
            })
        }
    }

    const variant = "outlined"

    return (
        <Frag>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Paper>
                        <div className="card-body px-5">
                            <div className="row">
                                <div className="col-md-12 my-2">
                                    <h3>Request Project</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-2">
                                    <InputField
                                        required={true}
                                        label="Project Name"
                                        name="project_name"
                                        value={project_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 my-2">
                                    <InputField
                                        required={true}
                                        label="Builder Name"
                                        name="builder_name"
                                        value={builder_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className="col-md-6 mt-2">
                                    <SelectOption
                                        required={true}
                                        label="Select Region"
                                        name="region_id"
                                        value={region_id}
                                        options={region}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className="col-md-6 mt-2">
                                    {/* <div className="form-group"> */}
                                        <GoogleMaps variant={variant} handleLocation={handleGeoLocation} autoLocation={location} />
                                    {/* </div> */}
                                </div>
                                <div className="col-md-6 my-2">
                                    <InputField
                                        label="RERA Number"
                                        name="rera_no"
                                        value={rera_no}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 my-2">
                                    <InputField
                                        label="Land Area(Acre)"
                                        name="land_area"
                                        value={land_area}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 my-2">
                                    <InputField
                                        label="Number of Towers"
                                        name="towers"
                                        value={towers}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 my-2">
                                    <InputField
                                        label="Number of Floors"
                                        name="elevation_struc"
                                        value={elevation_struc}
                                        onChange={handleChange}
                                    />
                                </div>
                                {possession === 'date' &&
                                    <div className="col-md-6 my-2">
                                        <FormControl>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    variant="inline"
                                                    inputVariant={variant}
                                                    margin="normal"
                                                    format="yyyy/MM/dd"
                                                    name="possession_date"
                                                    label="Possesion Date"
                                                    value={possession_date}
                                                    onChange={(key, date) => setState({ ...state, ['possession_date']: date })}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </div>
                                }

                                <div className="col-md-6 my-2">
                                    <FormControl variant={variant}>
                                        <InputLabel id="demo-simple-select-outlined-label">Possession Date</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={possession} name="possession" onChange={handleChange}>
                                            <MenuItem value="">None</MenuItem>
                                            <MenuItem value="ready">Ready to move</MenuItem>
                                            <MenuItem value="date">Select date</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-12 mt-4 mb-3">
                                    <ButtonSubmit onClick={handleRequest} />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Loader show={showLoader} />
            <PopUpAlert type={type} msg={title} open={alertShow} handleClose={handleClose} />
        </Frag>
    )
}
export default RequestProject
