import React, { Component } from "react";
import BulkLeadList from "../../component/bulkList/bulkList";
import Loader from "../../component/common/loader/loader";
import DumpLeadList from "../../component/dumpList/dumpList";
import DumpLeadAssign from "../../component/dumpAssign/dumpAssign.js";
import DumpWithdraw from "../../component/dumpAssign/dumpWithdraw";

class UserListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkLeadDetail: "",
      showLoader: false
    };
  }

  async componentDidMount() {
    let leadName = this.props.match.params.poolType;
    this.setState({
      showLoader: false,
      leadInfo: leadName
    });
  }

  render() {
    return (
      <div>
        <Loader show={this.state.showLoader} />
        {this.state.leadInfo === "bulk-leads" && (
          <BulkLeadList expand={this.props.sideBar} />
        )}
        {this.state.leadInfo === "dump-leads" && (
          <DumpLeadList expand={this.props.sideBar} />
        )}
        {this.state.leadInfo === "dump-leads-assign" && (
          <DumpLeadAssign expand={this.props.sideBar} />
        )}
          {this.state.leadInfo === "dump-leads-withdraw" && (
              <DumpWithdraw expand={this.props.sideBar} />
          )}
      </div>
    );
  }
}

export default UserListPage;
