import React from 'react';
import {Link} from 'react-router-dom';
import Portal from '@material-ui/core/Portal';
import { Formik, Form } from 'formik';
import moment from 'moment';

import Box from '../../core/Box';
import Grid from '../../core/Grid';
import Button from '../../core/Button';
import TextField, {FormikTextField} from '../../core/TextField';
import Typography from '../../core/Typography';


import NavigationButtonHeaderGroup from '../../core/NavigationButtonHeaderGroup';
import ActivityBlock from '../../core/ActivityBlock';
import EmailActivityBlock from '../../core/EmailActivityBlock';
import SmsActivityBlock from '../../core/SmsActivityBlock';
import VoiceActivityBlock from '../../core/VoiceActivityBlock';
import CallRecordBlock from '../../core/CallRecordBlock';
import api from '../../api';

const defaultState = {
}


const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    } else if (values.name.length > 15) {
        errors.name = 'Must be 15 characters or less';
    }
    return errors;
};

const nationality = {"1": "Indian", "2": "NRI"};
const marriage_status = {1: "Yes", 0: "No"};

const ProfilePage = ({clientId, profile, handleProfileChange}) => {
    const [state, setState] = React.useState({...defaultState, ...profile});

    const enableForm = (e) =>{
        e.preventDefault();
        setState({...state, isEditable: true})
    }

    React.useEffect(() => {
        setState({...state, ...profile})
    }, [profile])

    const save = (e) =>{
    }


    if(!profile.id) return <></>

    return <Box className="ProfilePage">
        <Formik enableReinitialize initialValues={{
                name: state.name,
                number: state.number,
                email: state.email,
                alternate_number: state.alternate_number,
                country: state.country,
                nationality: state.nationality,
                city: state.city,
                current_location: state.current_location,
                company: state.company,
                occupation: state.occupation,
                designation: state.designation,
                language: state.language,
                country_code: state.country_code,
                birth_date: state.birth_date,
                anniversary_date: state.anniversary_date,
                is_married: state.is_married
            }} 
            validate= {values => {
            }}
            onSubmit= {(values, { resetForm }) => {
                const payload ={
                    ...values
                }
                if(values.birth_date){
                    payload['birth_date'] = parseInt(moment(values.birth_date).format('X'))
                }
                if(values.anniversary_date){
                    payload['anniversary_date'] = parseInt(moment(values.anniversary_date).format('X'))
                }

                /*
                setState({...state, ...values, isEditable: false})
                handleProfileChange(values)
                /*/
                api.put(`/clients/${profile.id}`, values).then(res => {
                    
                    setState({...state, ...values, isEditable: false})
                    // resetForm();
                    handleProfileChange(values)
                })
                //*/
            }}
        >
            <Form autocomplete="off">
               <Box pl={2}><Typography variant="h5">Profile</Typography></Box>
                <Grid container spacing={4}>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Name" name="name" isRequired /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="E-mail" name="email" isEmail/> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Country Code" name="country_code" isCountryCode /> </Box>
                        <Box mt={3}> <FormikTextField label="Number" name="number" isPhone isRequired /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Alternate Number" name="alternate_number" isPhone /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Language" name="language" isLanguage /> </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Company" name="company" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Occupation" name="occupation" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Designation" name="designation" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Birth Date" name="birth_date" isDate /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Is Married" name="is_married" isDropDown options={marriage_status}/> </Box>
                        {/*<Box mt={2}> <FormikTextField  label="Created Date" name="created_date" isDate /> </Box>*/} 
                        {/*<Box mt={2}> <FormikTextField isEditable={state.isEditable} label="Mobile Number DND" name="mobile_number_dnd" isPhone /> </Box>*/} 
                        {/*<Box mt={2}> <FormikTextField isEditable={state.isEditable} label="Alternate Number DND" name="alternate_number_dnd" isPhone /> </Box>*/} 
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Anniversary" name="anniversary_date" isDate /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Nationality" name="nationality" isDropDown options={nationality} /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Country" name="country" isCountry /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="City" name="city" isCity/> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Current Location" name="current_location" isCity /> </Box>
                    </Grid>
                </Grid>

                {/* <Box align="center" mt={6}> */}
                {/*     {state.isEditable */} 
                {/*         ? <Button type="submit" onClick={save}>Confirm</Button> */}
                {/*         :<Button type="button" onClick={enableForm}>Edit</Button> */}
                {/*     } */}
                {/* </Box> */}
            </Form>
        </Formik>
    </Box>
}


export default ProfilePage;
