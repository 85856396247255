import React from 'react';

import FormControl_ from '@material-ui/core/FormControl';

import './FormControl.sass'
import clsx from 'clsx';


const defaultState = {
}


const FormControl = props => <FormControl_ className="FormControl" {...props}>{props.children}</FormControl_>

export default FormControl;
