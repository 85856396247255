import React from 'react';

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './ProfileBox.sass';
import Avatar from '@material-ui/core/Avatar';
import { plotCommercial } from '../helper';


const defaultState = {
}


const ProfileBox = props => {
    const {project, name, leadId} = {...props}

    /*
    const [state, setState] = React.useState({
        ...defaultState
        ,  ...project
        , name
    });
    */

    return <Box className="ProfileBox">
		<Card>
			<CardContent style={{padding: 0}}>
                <Avatar className="avatar">{name?.[0].toUpperCase()}</Avatar>
                <Box align="center" mt={3} mb={2}>
                    <Box>
                        <Typography variant="h6" style={{textTransform: 'capitalize'}}>
                            {name}
                        </Typography>
                        <Typography variant="caption">{leadId}</Typography>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box align="center">
                            <Box>
                                <Typography variant="caption">Project</Typography>
                            </Box>
                            <Box>
								<Typography title={project.name} className="project-name" variant="subtitle1">{Boolean(project.name) === true ? project.name : "-"}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align="center">
                            <Box>
                                <Typography variant="caption">Configuration</Typography>
                            </Box>
                            <Box>
								<Typography variant="subtitle1">{project?.type ? plotCommercial(project?.type): "-" }</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align="center">
                            <Box>
                                <Typography variant="caption">Budget</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1">{project.budget}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align="center">
                            <Box>
                                <Typography variant="caption">Lead Status</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1">{project.leadStatus}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Box>
}


export default ProfileBox;
