import React, { Component } from "react";

import MagnetTempUserList from "../../component/magnetTempComp/magnetTempUser.js";
import { getTempMagentUsersList } from "../../dataParser/getListUserData";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";
import { cleanStorage } from "../../helper";

class TempUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: "",
      showLoader: false
    };
  }

  async componentDidMount() {
    this.setState({ showLoader: true });

    var userData = {};
    userData.size = 8;
    userData.pageId = 1;
    userData.filters = [];

    var resData = {};
    resData = await getTempMagentUsersList(userData);
    // console.log(" int the  GET prospects ===========>", resData);
    setTimeout(() => {
      if (resData.meta.status === 200) {
        this.setState({
          userDetail: resData,
          showLoader: false
        });
        window.scrollTo(0, 0);
        this.setState({});
      } else if (resData.meta.status == 401) {
        cleanStorage();
        this.props.history.push("/login");
      } else {
        this.setState({
          userDetail: resData,
          showLoader: false
        });
      }
    }, 500);
  }

  render() {
    return (
      <Frag>
        <Loader show={this.state.showLoader} />
        {this.state.userDetail.meta && (
          <MagnetTempUserList
            expand={this.props.sideBar}
            userDetail={this.state.userDetail}
          />
        )}
      </Frag>
    );
  }
}

export default TempUserPage;
