import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFilter, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { getdumpLeadDataList } from "../../dataParser/bulkLeadData";
import Loader from "../common/loader/loader";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Pagination from "react-js-pagination";
import BulkLeadModal from "../../component/common/modal/BulkModal";
import DumpLeadFilter from "../../component/filter/dumpLeadFilter";
import { changeDate } from "../../helper";
import { DUMP_LEAD, DUMP_STATUS } from "../../config/constant/index.js";
import Tooltip from '@material-ui/core/Tooltip';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { getCallData, getCallWithKaleyra } from "../../dataParser/getListData";
import { callRoles } from "../../config/constant/crmRoleFunctionPermission";
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import { CALLER_TYPE, KALEYRA_CLICK_TO_CALL_ROLES } from '../../config/constant';
import AutoDialer from "../common/action/AutoDialer";

class DumpList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            filters: "",
            bulkId: "",
            listDetail: -1,
            showLoader: false,
            showFilter: false,
            sideBar: false,
            meta: [],
            data: [],
            sweetShow: false,
            type: "success",
            title: "",
            dumptype: "",
            modalShow: false,
            role: window.$user.role,
            agent_number: window.$user.phone ? '+91' + window.$user.phone : '9111111111',
            customer_number: '',
            object_id: null,
            user_id: window.$user.id,
            open: false,
            msg: '',
        };
        window.addEventListener("resize", this.update);
    }

    async componentDidMount() {
        this.setState({
            showLoader: true
        });
        var bulkLeadData = {};
        bulkLeadData.size = 8;
        bulkLeadData.pageId = 1;


        var resData = {};
        resData = await getdumpLeadDataList(bulkLeadData);
        setTimeout(() => {
            if (resData.meta.status === 200) {

                this.setState({
                    showLoader: false,
                    meta: resData.meta,
                    data: resData.data["dumpLead"],
                    listDetail: -1
                });
                window.scrollTo(0, 0);
            } else {
                this.setState({

                    showLoader: false
                });
            }
        }, 500);
        this.update();
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };

    detailView(id) {


        if (Number(this.state.listDetail) === Number(id)) {
            this.setState({ listDetail: null });
        } else {
            this.setState({ listDetail: id });
        }
    }

    getNumber = (data) => {
        let number = (data.Client && data.Client?.country_code && data.Client?.country_code ? (data.Client?.country_code.includes('+') ? data.Client?.country_code : '+' + data.Client?.country_code) : '+91') + ' ' + (data.Client !== null ? data.Client?.number : data.BulkLead && data.BulkLead.phone)
        return number
    }

    handleCall = async (list) => {
        const { role, } = this.state
        let number = this.getNumber(list).split(" ").join("")
        if (callRoles.includes(role)) {
            this.setState({ showLoader: true })

            const { agent_number, object_id, type, user_id } = this.state

            let data = {}

            if (agent_number !== '') {
                data["agent_number"] = agent_number
            }
            data["customer_number"] = number
            if (object_id !== '') {
                data["object_id"] = list.id
            }
            if (user_id !== '') {
                data["user_id"] = user_id
            }
            data["calling_type"] = CALLER_TYPE.DUMP;
            data["type"] = 3
            // in Progress Stage ID = 13
            data['stage_id'] = 13;

            let resData = KALEYRA_CLICK_TO_CALL_ROLES.includes(role) ? await getCallWithKaleyra(data) : await getCallData(data);
            if (resData.meta.status === 201) {
                let message = resData.data?.message
                this.setState({
                    showLoader: false,
                    open: true,
                    type: message?.includes('Failed') ? 'warning' : "success",
                    msg: resData.data?.message || "Call placed successfully!",
                });
            } else {
                this.setState({
                    showLoader: false,
                    open: true,
                    type: "error",
                    msg: resData.meta?.message || "Sorry something went wrong!",
                });
            }
        }
        else {
            this.setState({ tel: "tel:" + number })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    genericGetListData = async params => {
        this.setState({ showLoader: true });



        let resData = await getdumpLeadDataList(params);

        setTimeout(() => {
            if (Number(resData.meta.status) === 200) {
                this.setState({
                    meta: resData.meta,
                    data: resData.data["dumpLead"],
                    listDetail: -1,
                    showLoader: false
                });
                window.scrollTo(0, 0);
            } else {
                this.setState({
                    meta: resData.meta,
                    data: resData.data,
                    showLoader: false
                });
            }
        }, 500);
    };

    handleChangePage = async pageNumber => {


        this.setState({
            showLoader: true,
            activePage: pageNumber
        });
        var listData = {};
        listData.size = 8;
        listData.pageId = pageNumber;
        Object.assign(listData, this.state.filters);

        this.genericGetListData(listData);
    };

    handleFilter = async filterData => {
        this.setState({ showLoader: true });
        this.setState({ filters: filterData });

        let listData = {};

        listData.size = 8;
        listData.pageId = 1;
        Object.assign(listData, filterData);

        this.genericGetListData(listData);
    };

    handleUpdateFilter = async () => {
        this.setState({
            showLoader: true,
        })
        let listData = {};
        listData.size = 8;
        listData.pageId = this.state.activePage;
        Object.assign(listData, this.state.filters);

        this.genericGetListData(listData);
    }
    handlePopUp = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    };

    handleSweet = () => {
        this.setState({ sweetShow: !this.state.sweetShow });
    };

    handleModal = (
        id,
        project_name,
        client_id,
        mobile_number,
        project_id,
        lead_id,
        dumptype
    ) => {

        this.setState({
            modalShow: !this.state.modalShow,
            bulkId: id,
            userId: window.$user.id,
            leadType: project_name,
            clientId: client_id,
            mobileNumber: mobile_number,
            projectId: project_id,
            leadId: lead_id,
            dumptype: dumptype
        });
    };

    render() {
        const { count, size, status, } = this.state.meta;
        const { data, type, title, sweetShow, msg, open, role, } = this.state;
        const isDesktop = this.state.screenWidth;

        return (
            <Frag>
                <PopUpAlert type={type} msg={msg} open={open} handleClose={this.handleClose} hideTime={6000} />
                {isDesktop > 991 ? (
                    <div
                        className="content-wrapper">
                        <SweetAlert
                            show={sweetShow}
                            type={type}
                            title={title}
                            changeSweet={this.handleSweet}
                        />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total {DUMP_LEAD} Leads : </span>
                                <span className="">{status === 400 ? "0" : count}</span>
                            </div>
                            <AutoDialer/>
                            <div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div
                                    className={
                                        "filterMenu" +
                                        " " +
                                        (this.state.showFilter ? "filterMenuShow" : "")
                                    }
                                    ref={node => {
                                        this.node = node;
                                    }}
                                >
                                    <DumpLeadFilter
                                        filterData={this.handleFilter}
                                        changeFilter={this.handlePopUp}
                                    />
                                </div>
                            </div>
                        </div>
                        <Loader show={this.state.showLoader} />
                        {status === 200 && data.length && data.length ? (
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((bulkData, index) => (
                                            <div
                                                className="MainList"
                                                key={index}
                                                onClick={() => this.detailView(index)}
                                            >
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1">
                                                            <div className="col-md-12">
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">ID</span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.id !== null
                                                                                        ? bulkData.id
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">Name</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize  valueText">
                                                                                    {bulkData.lead_id !== null && bulkData.Client !== null &&
                                                                                        bulkData.Client.name !== null
                                                                                        ? bulkData.Client?.name
                                                                                        : bulkData.BulkLead?.name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Email
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.Client?.email ? bulkData.Client?.email : (bulkData.BulkLead?.email ? bulkData.BulkLead?.email : "-")}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Mobile Number
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.Client !== null &&
                                                                                        bulkData.Client.number !== null
                                                                                        ? bulkData.Client?.number
                                                                                        : bulkData.BulkLead?.phone}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Project Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.DumpLeadProject !== null ? (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        {bulkData.DumpLeadProject.name !== null &&
                                                                                            bulkData.DumpLeadProject?.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Region{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.DumpLeadProject !== null ? (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        {bulkData.DumpLeadProject?.Region?.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize  valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Builder Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText text-capitalize">{bulkData?.DumpLeadProject?.builder_name || "-"}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Status{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                {bulkData.status !== null ? (
                                                                                    <span className="valueText">
                                                                                        {" "}
                                                                                        {DUMP_STATUS[bulkData.status]}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="valueText">-</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Assigned To{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.DumpLeadAssign !== null
                                                                                        ? bulkData.DumpLeadAssign?.name
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Created Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.created_at !== null
                                                                                        ? changeDate(bulkData.created_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Updated Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.updated_at !== null
                                                                                        ? changeDate(bulkData.updated_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    { window.$user.role !== "magnet" && <div className="col-md-2 mt-1">
                                                                        <div className="action_icons">
                                                                            {
                                                                                (Number(bulkData.status) === 12 || Number(bulkData.status) === 13) ?
                                                                                    bulkData.BulkLead !== null ? (
                                                                                        <a
                                                                                            className="ProjectDetail Action"
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="bottom"
                                                                                            title={`Update ${DUMP_LEAD}`}
                                                                                            onClick={value =>
                                                                                                this.handleModal(
                                                                                                    bulkData.id,
                                                                                                    bulkData.DumpLeadProject?.name,
                                                                                                    '',
                                                                                                    bulkData.BulkLead?.phone,
                                                                                                    bulkData.project_id,
                                                                                                    bulkData.BulkLead?.id,
                                                                                                    "bulk"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={faEdit}
                                                                                                className="text-white"
                                                                                            />
                                                                                        </a>
                                                                                    ) : (
                                                                                        bulkData.Client !== null &&
                                                                                        <a
                                                                                            className="ProjectDetail Action"
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="bottom"
                                                                                            title={`Update ${DUMP_LEAD}`}
                                                                                            onClick={value =>
                                                                                                this.handleModal(
                                                                                                    bulkData.id,
                                                                                                    bulkData.DumpLeadProject?.name,
                                                                                                    bulkData.Client?.id,
                                                                                                    bulkData.Client?.number,
                                                                                                    bulkData.project_id,
                                                                                                    bulkData.lead_id,
                                                                                                    "old_lead"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={faEdit}
                                                                                                className="text-white"
                                                                                            />
                                                                                        </a>
                                                                                    )
                                                                                    :
                                                                                    ""
                                                                            }
                                                                            <Frag>
                                                                                <Tooltip title="Whatsapp">
                                                                                    <a href={"https://api.whatsapp.com/send?phone=" + (bulkData.Client !== null && bulkData.Client.country_code !== null && bulkData.Client.country_code !== '' ? (bulkData.Client.country_code.includes('+') ? bulkData.Client.country_code : '+' + bulkData.Client.country_code) : '+91') + (bulkData.Client !== null ? bulkData.Client.number : bulkData?.BulkLead?.phone) + "&text=Hi!"} className="WhatsAppBG Action d-none d-md-block" target="_blank">
                                                                                        <FontAwesomeIcon icon={faWhatsapp} className="text-white" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                                <Tooltip title={this.getNumber(bulkData)}>
                                                                                    <a
                                                                                        href={this.state.tel}
                                                                                        className="callBG Action d-none d-md-block"
                                                                                        target="_blank"
                                                                                        onClick={() => { this.handleCall(bulkData) }}>
                                                                                        <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </Frag>
                                                                        </div>
                                                                    </div> }
                                                                </div>

                                                                {/* comments removed */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="main_area_display shadow card p-3">
                                <h5>No {DUMP_LEAD} Lead Found !!!</h5>
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className="content-wrapper">
                        <SweetAlert
                            show={sweetShow}
                            type={type}
                            title={title}
                            changeSweet={this.handleSweet}
                        />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total {DUMP_LEAD} Leads : </span>
                                <span className="">{status === 400 ? "0" : count}</span>
                            </div>
                            <AutoDialer/>
                            <div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div
                                    className={
                                        "filterMenu" +
                                        " " +
                                        (this.state.showFilter ? "filterMenuShow" : "")
                                    }
                                    ref={node => {
                                        this.node = node;
                                    }}
                                >
                                    <DumpLeadFilter
                                        filterData={this.handleFilter}
                                        changeFilter={this.handlePopUp}
                                    />
                                </div>
                            </div>
                        </div>
                        <Loader show={this.state.showLoader} />
                        {status === 200 && data.length ? (
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((bulkData, index) => (
                                            <div
                                                className="MainList"
                                                key={index}
                                                onClick={() => this.detailView(index)}
                                            >
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">ID </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.id !== null
                                                                                        ? bulkData.id
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1 pr-0">
                                                                        <div className="row">
                                                                            <div className="col-md-12 pr-0 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Project Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                {bulkData.DumpLeadProject !== null ? (
                                                                                    <span className="text-capitalize valueText">
                                                                                        {bulkData.DumpLeadProject.name !== null &&
                                                                                            bulkData.DumpLeadProject?.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">Name</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.lead_id !== null && bulkData.Client !== null &&
                                                                                        bulkData.Client?.name !== null
                                                                                        ? bulkData.Client?.name
                                                                                        : bulkData.BulkLead?.name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Email
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.Client?.email ? bulkData.Client?.email
                                                                                        : (bulkData.BulkLead?.email ? bulkData.BulkLead?.email : "-")}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Mobile Number
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.lead_id !== null && bulkData.Client !== null &&
                                                                                        bulkData.Client.number !== null
                                                                                        ? bulkData.Client?.number
                                                                                        : bulkData.BulkLead?.phone}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Region{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                {bulkData.DumpLeadProject !== null ? (
                                                                                    <span className="text-capitalize valueText">
                                                                                        {bulkData.DumpLeadProject?.Region?.name}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="text-capitalize valueText">
                                                                                        -
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1 pr-0">
                                                                        <div className="row">
                                                                            <div className="col-md-12 pr-0 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Builder Name{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12">
                                                                                <span className="valueText text-capitalize">{bulkData?.DumpLeadProject?.builder_name || "-"}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-6 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Status{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                {bulkData.status !== null ? (
                                                                                    <span className="valueText">
                                                                                        {" "}
                                                                                        {DUMP_STATUS[bulkData.status]}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="valueText">-</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/*comments removed*/}
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Assigned To{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {bulkData.DumpLeadAssign !== null
                                                                                        ? bulkData.DumpLeadAssign?.name
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Created Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.created_at !== null
                                                                                        ? changeDate(bulkData.created_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Updated Date{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {bulkData.updated_at !== null
                                                                                        ? changeDate(bulkData.updated_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* comments removed */}
                                                            </div>
                                                        </div>
                                                        { window.$user.role !== "magnet" && <div className="action_box">
                                                            {
                                                                (Number(bulkData.status) === 12 || Number(bulkData.status) === 13) ?
                                                                    bulkData.BulkLead !== null ? (
                                                                        <a
                                                                            className="ProjectDetail Action"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom"
                                                                            title={`Update ${DUMP_LEAD}`}
                                                                            onClick={value =>
                                                                                this.handleModal(
                                                                                    bulkData.id,
                                                                                    bulkData.DumpLeadProject?.name,
                                                                                    '',
                                                                                    bulkData.BulkLead?.phone,
                                                                                    bulkData.project_id,
                                                                                    bulkData.BulkLead?.id,
                                                                                    "bulk"
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                className="text-white"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        bulkData.Client !== null &&
                                                                        <a
                                                                            className="ProjectDetail Action"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom"
                                                                            title={`Update ${DUMP_LEAD}`}
                                                                            onClick={value =>
                                                                                this.handleModal(
                                                                                    bulkData.id,
                                                                                    bulkData.DumpLeadProject?.name,
                                                                                    bulkData.Client?.id,
                                                                                    bulkData.Client?.number,
                                                                                    bulkData.project_id,
                                                                                    bulkData.lead_id,
                                                                                    "old_lead"
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                className="text-white"
                                                                            />
                                                                        </a>
                                                                    )
                                                                    :
                                                                    ""
                                                            }
                                                        </div> }
                                                       { window.$user.role !== "magnet" && <div className="row">
                                                            {(callRoles.includes(role)) ?
                                                                <Frag>
                                                                    <div className="col-4 mb-1">
                                                                        <a href={this.state.tel}>
                                                                            <Button variant="contained" color="primary" style={{ width: "100px", }} onClick={() => { this.handleCall(bulkData) }}>
                                                                                <PhoneIcon /><span className="text-capitalize">W-Call</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-4 mb-1">
                                                                        <a href={"https://api.whatsapp.com/send?phone=" + (bulkData.Client !== null && bulkData.Client.country_code !== null && bulkData.Client.country_code !== '' ? (bulkData.Client.country_code.includes('+') ? bulkData.Client.country_code : '+' + bulkData.Client.country_code) : '+91') + (bulkData.Client !== null ? bulkData.Client.number : bulkData?.BulkLead?.phone) + "&text=Hi!"} target="_blank">
                                                                            <Button variant="contained" color="primary" className="bg-success" style={{ padding: "6px", }} >
                                                                                <WhatsAppIcon /><span className="text-capitalize">WhatsApp</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-4 mb-1">
                                                                        <a href={"tel:" + this.getNumber(bulkData)}>
                                                                            <Button variant="contained" color="primary" style={{ width: "70px", marginLeft: "6px", }}>
                                                                                <PhoneIcon /><span className="text-capitalize">Call</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                </Frag>
                                                                :
                                                                <Frag>
                                                                    <div className="col-6 mb-1 pr-2">
                                                                        <a href={"https://api.whatsapp.com/send?phone=" + (bulkData.Client !== null && bulkData.Client.country_code !== null && bulkData.Client.country_code !== '' ? (bulkData.Client.country_code.includes('+') ? bulkData.Client.country_code : '+' + bulkData.Client.country_code) : '+91') + (bulkData.Client !== null ? bulkData.Client.number : bulkData?.BulkLead?.phone) + "&text=Hi!"} target="_blank">
                                                                            <Button variant="contained" color="primary" className="w-100 bg-success">
                                                                                <WhatsAppIcon /><span className="text-capitalize">WhatsApp</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-6 mb-1 pl-2">
                                                                        <a href={this.state.tel}>
                                                                            <Button variant="contained" color="primary" className="w-100 " onClick={() => { this.handleCall(bulkData) }}>
                                                                                <PhoneIcon /><span className="text-capitalize">Call</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                </Frag>
                                                            }
                                                        </div> }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="main_area_display shadow card p-3">
                                <h5>No Bulk Lead Found !!!</h5>
                            </div>
                        )}
                    </div>
                )}

                <BulkLeadModal
                    show={this.state.modalShow}
                    fromType="updateDumpForm"
                    getList={this.handleUpdateFilter}
                    changeModal={this.handleModal}
                    leadID={this.state.leadId}
                    leadType={this.state.leadType}
                    userName={this.state.userName}
                    mobileNumber={this.state.mobileNumber}
                    projectId={this.state.projectId}
                    bulkId={this.state.bulkId}
                    userId={this.state.userId}
                    dumptype={this.state.dumptype}
                />
            </Frag>
        );
    }
}

export default DumpList;
