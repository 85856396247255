import React from 'react'
import './ListingStyle.css'

function ListingView(props){
	const {children, extraClass} = props
    return (
		<div className="container-fluid">
			<div className="MainList">
				<div className={`container-fluid ListContent card mb-3 ${extraClass ? extraClass : ''}`}>
					<div className="row mb-1">
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export function OneFourthField(props){
	const {title, value} = props
	return(
		<div className="listing col-md-3 col-6 mb-1">
			<div className="row">
			  <div className="col-md-12">
			    <span className="text-muted text-capitalize">{title}</span>
			  </div>
			  <div className="col-md-12">
			    <span className="valueText text-capitalize">{value}</span>
			  </div>
			</div>
		</div>
	)
}

export function OneThirdField(props){
	const {title, value} = props
	return(
		<div className="col-md-4 col-6 mb-1">
			<div className="row">
			  <div className="col-md-12">
			    <span className="text-muted text-capitalize">{title}</span>
			  </div>
			  <div className="col-md-12">
			    <span className="valueText text-capitalize">{value}</span>
			  </div>
			</div>
		</div>
	)
}

export function OneSixthField(props){
	const {title, value} = props
	return(
		<div className="col-md-2 col-6 mb-1">
			<div className="row">
			  <div className="col-md-12">
			    <span className="text-muted text-capitalize">{title}</span>
			  </div>
			  <div className="col-md-12">
			    <span className="valueText text-capitalize">{value}</span>
			  </div>
			</div>
		</div>
	)
}

export function HalfField(props){
	const {title, value} = props
	return(
		<div className="col-6 mb-1">
			<div className="row">
			  <div className="col-md-12">
			    <span className="text-muted text-capitalize">{title}</span>
			  </div>
			  <div className="col-md-12">
			    <span className="valueText text-capitalize">{value}</span>
			  </div>
			</div>
		</div>
	)
}
export default ListingView
