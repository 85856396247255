import React from "react";
import "./magnetRegister.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {slideData} from "./mgData";
import { AMAZON_URL } from "../../config/serverKey";

export default function MgSlider() {
  const renderSlides = () =>
    slideData.map(item => (
      <div key={item.id}>
        <div className="slideImg"><img src={`${AMAZON_URL}/${item.slideImg}.png`} alt={item.slideImg} className="img-fluid"/></div>
        <h4 className="text-mg mt-4 pt-1 font-bold">{item.title}</h4>
        <p>{item.description}</p>
      </div>
    ));
    var settings = {
      arrows: false,
      dots: true,
      pauseOnHover: false,
      infinite: true,
      speed: 4000,
      autoplay: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
      
  return (
    <div className="mgSlider" id="bgBlob">
      <Slider {...settings}>{renderSlides()}</Slider>
    </div>
  );
}