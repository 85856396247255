import React, { useState, useEffect } from 'react'
import Frag from "../../utils/Frag/Frag.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputField from "../common/controls/InputField"
import ButtonSubmit from "../common/controls/ButtonSubmit"
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import Loader from "../../component/common/loader/loader";
import CommonMails from '../common/table/CommonMails.js';
import { getReportError } from '../../dataParser/commomDataApi.js';
import SelectOption from '../common/controls/SelectOption.js';
import { emailSupport } from '../common/datas/data.js';
import { titleCase } from '../../helper/index.js';

export const ReportError = (props) => {
    const [state, setState] = useState({
        taskName: "",
        addedBy: "",
        to_email: "",
        taskDesc: "",
        showLoader: false,
        type: "success",
        open: false,
        msg: '',
    })

    const { taskName, addedBy, to_email, taskDesc, type, msg, open, showLoader } = state;
    const teamSupport = window.$user.role === "magnet" ? emailSupport.filter(x => !x.mgSee) : emailSupport.filter(x => x.name !== "Magnet")
   
    useEffect(() => {
        const name = window.$user.first_name +" "+ window.$user.last_name
        const your_name = titleCase(name) + " - " + window.$user.id
        setState({
            ...state,
            addedBy: your_name
        })
    }, [])
    
    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async () => {
        setState({ ...state, showLoader: true })
        let data = {
            "completed": false,
            "name": taskName,
            "notes": `${taskDesc} - added by ${addedBy}`,
            "projects": [
                "1198993812904269"
            ]
        }
        
        let trelloMailTo = [
            "queryaccounts@homesfy.in", "queryops@homesfy.in", "queryadmin@homesfy.in", "queryhr@homesfy.in"
        ]
        let trelloMail = "sandeepvadnere3+epr5y1suqwo9duwd4pnl@boards.trello.com";
        let finalMail = "";
        if(trelloMailTo.includes(to_email)){
            finalMail = `${to_email}, ${trelloMail}`;
        }
        else{
            finalMail = to_email;
        }

        if (taskName !== "" && addedBy !== "" && to_email !== "" && taskDesc !== "") {
            await sendData({ data: data, to_email: finalMail });
        }
        else {
            setState({ ...state, showLoader: false, type: "error", open: true, msg: "Above all fields are required!" })
        }
    }

    const sendData = async (data) => {
        let res = await getReportError(data);
                if (res.meta.status == 200) {
                    setState({ showLoader: false, type: "success", open: true, msg: "Task added successfully in Asana !",                
                        taskName: "", addedBy: "", to_email: "", taskDesc: ""
                    })
                }
                else {
                    setState({ ...state, showLoader: false, type: "error", open: true, msg: "Something went wrong !" })
                }
    }

    const handleClose = () => {
        setState({ ...state, open: false });
    }

    return (
        <Frag>
            <Loader show={showLoader} />
            <PopUpAlert type={type} msg={msg} open={open} handleClose={handleClose} hideTime={6000} />
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Paper>
                        <div className="container py-4">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <h4>Report Issue</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <InputField
                                        required={true}
                                        label="Issue short description"
                                        name="taskName"
                                        value={taskName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <InputField
                                        required={true}
                                        disabled
                                        label="Your Name"
                                        name="addedBy"
                                        value={addedBy}
                                        // onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <SelectOption
                                        required={true}
                                        label="Team"
                                        name="to_email"
                                        value={to_email}
                                        options={teamSupport}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <InputField
                                        required={true}
                                        label="Description"
                                        name="taskDesc"
                                        value={taskDesc}
                                        onChange={handleChange}
                                        multiline
                                    />
                                </div>
                            </div>
                            <ButtonSubmit
                                onClick={handleSubmit}
                            />
                        </div>
                    </Paper>
                </Grid>
                <div className="col-12">
                    <CommonMails />
                </div>
            </Grid>
        </Frag>
    )
}
