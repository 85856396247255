import { instance as API, engageInstance as ENAPI, instanceBasic as APIBasic } from './api';
import OLD_API from './oldApi';
import { cleanStorage, getToken } from "../helper/";
import { NO_HEADER_PAGES } from '../config/constant';
import errorCodes from '../config/constant/errorCodes';

const handleError = (err) => {
  let result = {
    meta: {
      status: 400,
      error: 0,
      message: 'Not Found',
    },
    data: {},
  }
  result.meta.error = 1;
  if (err.response !== undefined) {
    if (err.response.status === 401) {
      cleanStorage();
    } else {
      result.meta.status = err.response.status;
      result.data = err.response.data.data;
      result.meta.count = 0;
      result.meta.message = err.response.data.message || errorCodes[err.response.status];
    }

  } else if (err.code === 'ECONNABORTED') {
    result.meta.status = 408;
    result.meta.count = 0;
    result.meta.message = err.message || errorCodes[err.response.status];
  } else if (err.request) {
    result.meta.status = 500;
    result.meta.count = 0;
    result.meta.message = err.message || errorCodes[err.response.status];
  } else {
    result.meta.status = 503;
    result.meta.count = 0;
    result.meta.message = err.message || errorCodes[err.response.status];
  }

  return result;
}

export const getBasic = async (url, options) => {
  let user = JSON.parse(window.atob(localStorage.getItem("homesfy_lg")));
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': "bearer " + user.token,
  };
  const data = await APIBasic.get(url, { headers })
    .then(response => {
      let result = {
        meta: {
          status: 200,
          error: 0,
          message: 'OK',
        },
        data: {},
      }
      if (response.data) result.data = response.data;
      result.meta.status = response.status;
      result.meta.message = errorCodes[response.status];

      return result;

    })
    .catch(error => {
      return handleError(error);
    });
  return data;
}

export const getComment = async (url, options) => {
  if (options === null || options === '' || options === undefined) {
      const data = await ENAPI.get(url)
        .then(response => {
          let result = {
            meta: {
              status: 200,
              error: 0,
              message: 'OK',
            },
            data: {},
          }
          if (response.data) result.data = response.data;
          result.meta.status = response.status;
          result.meta.message = errorCodes[response.status];

          return result;

        })
        .catch(error => {
          return handleError(error);
        });
      return data;
  }else{
      let queryParams = Object.entries(options).map(([key, value]) => {
        return key + "=" + value;
      }).join("&");
      const data = await ENAPI.get(url + '?' + queryParams)
        .then(response => {
          let result = {
            meta: {
              status: 200,
              error: 0,
              message: 'OK',
              count : 0
            },
            data: {},
          }
          if (response.data) result.data = response.data;
          result.meta.status = response.status;
          result.meta.message = errorCodes[response.status];

          return result;

        })
        .catch(error => {
          return handleError(error);
        });
      return data;
  }  
}

export const getEngageCall = async (url, options) => {
  const data = await ENAPI.post(url, options)
    .then(response => {
      let result = {
        meta: {
          status: 201,
          error: 0,
          message: 'Created',
        },
        data: {},
      }
      if (response.data) result.data = response.data;
      result.meta.status = response.status;
      result.meta.message = errorCodes[response.status];

      return result;

    })
    .catch(error => {
      return handleError(error);
    });
  return data;
}

export const putEngageCall = async (url, options) => {
  const data = await ENAPI.put(url, options)
    .then(response => {
      let result = {
        meta: {
          status: 201,
          error: 0,
          message: 'Created',
        },
        data: {},
      }
      if (response.data) result.data = response.data;
      result.meta.status = response.status;
      result.meta.message = errorCodes[response.status];

      return result;

    })
    .catch(error => {
      return handleError(error);
    });
  return data;
}

export const getEngageComment = async (url, options) => {
  const data = await ENAPI.post(url, options)
    .then(response => {
      let result = {
        meta: {
          status: 201,
          error: 0,
          message: 'Created',
        },
        data: {},
      }
      if (response.data) result.data = response.data;
      result.meta.status = response.status;
      result.meta.message = errorCodes[response.status];

      return result;

    })
    .catch(error => {
      return handleError(error);
    });
  return data;
}

export const get = async (url, options) => {
  let user = localStorage.getItem("homesfy_lg") ? JSON.parse(window.atob(localStorage.getItem("homesfy_lg"))):{};
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': "bearer " + user.token,
  };
  if (options === null || options === '' || options === undefined) {
    return await APIBasic.get(url, { headers })
      .then(response => {
        let result = {
          meta: {
            status: 200,
            error: 0,
            message: 'OK',
          },
          data: {},
        }
        if (response.data) result.data = response.data;

        if (response.data.count !== undefined || response.data.count !== '') {
          result.meta.count = response.data.count;
        }

        result.meta.status = response.status;
        result.meta.message = errorCodes[response.status];
        return result;

      })
      .catch(error => {
        return handleError(error);
      });
  } else {

    let queryParams = Object.entries(options).map(([key, value]) => {
      return key + "=" + value;
    }).join("&");
    return await APIBasic.get(url + '?' + queryParams, { headers })

      .then(response => {
        let result = {
          meta: {
            status: 200,
            error: 0,
            message: 'OK',
          },
          data: {},
        }
        result.meta.status = response.status;
        result.meta.message = errorCodes[response.status];

        if (response.data) result.data = response.data;
        result.meta.count = response.data.count || 0;
        if (options.size) result.meta.size = options.size;
        if (options.pageId) result.meta.pageId = options.pageId;
        return result;
      })
      .catch(error => {
        return handleError(error);
      });

  }
}

export const post = async (url, options) => {
  if (options === null && options === '') {

    let errorRes = "Data is empty";
    return errorRes

  } else {
    var headers = {};
    if (NO_HEADER_PAGES.includes((window.location.hash).split("?")[0])) {
      headers = {
        'Content-Type': 'application/json',
      }
    } else {

      const user = localStorage.getItem("homesfy_lg")?JSON.parse(window.atob(localStorage.getItem("homesfy_lg"))):{};
      headers = {
        'Content-Type': 'application/json',
        'Authorization': "bearer " + user.token,
      };
    }
    return await APIBasic.post(url, options, { headers: headers })
      .then(response => {
        let result = {
          meta: {
            status: 201,
            error: 0,
            message: 'Created',
          },
          data: {},
        }
        if (response.data) result.data = response.data;
        result.meta.status = response.status;
        result.meta.message = errorCodes[response.status];
        return result;

      })
      .catch(error => {
        return handleError(error);
      });


  }

};

export const put = async (url, options) => {
  var headers;
  if (options === null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {

    if (NO_HEADER_PAGES.includes(window.location.hash)) {
      headers = {
        'Content-Type': 'application/json',
      }
    } else {

      const user = localStorage.getItem("homesfy_lg") ? JSON.parse(window.atob(localStorage.getItem("homesfy_lg"))):{};
      headers = {
        'Content-Type': 'application/json',
        'Authorization': "bearer " + user.token,
      };
    };

    return await APIBasic.put(url, options, { headers })
      .then(response => {
        let result = {
          meta: {
            status: 200,
            error: 0,
            message: 'OK',
          },
          data: {},
        }
        if (response.data) result.data = response.data;
        result.meta.status = response.status;
        result.meta.message = errorCodes[response.status];

        return result;

      })
      .catch(error => {
        return handleError(error);
      });
  }

};

export const Delete = async (url) => {
  let user = JSON.parse(window.atob(localStorage.getItem("homesfy_lg")));
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': "bearer " + user.token,
  };
  return await APIBasic.delete(url, { headers })
    .then(response => {
      let result = {
        meta: {
          status: 200,
          error: 0,
          message: 'OK',
        },
        data: {},
      }
      if (response.data) result.data = response.data;
      result.meta.status = response.status;
      result.meta.message = errorCodes[response.status];

      return result;

    })
    .catch(error => {
      return handleError(error);
    });
};

export const formPut = async (url, options) => {
  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    var bodyFormData = new FormData();
    bodyFormData.set('data', options.data);

    if (options.adhar_file !== undefined) bodyFormData.append('adharcard_img', options.adhar_file);
    if (options.pen_file !== undefined) bodyFormData.append('pancard_img', options.pen_file);
    if (options.profile_pic !== undefined) bodyFormData.append('profile_pic', options.profile_pic);
    if (options.visiting_card !== undefined) bodyFormData.append('visiting_card', options.visiting_card);

    let user = JSON.parse(window.atob(localStorage.getItem("homesfy_lg")));
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "bearer " + user.token,
    };

    return await APIBasic.put(url, bodyFormData, { headers })
      .then(response => {
        let result = {
          meta: {
            status: 200,
            error: 0,
            message: 'OK',
          },
          data: {},
        }
        if (response.data) result.data = response.data;
        result.meta.status = response.status;
        result.meta.message = errorCodes[response.status];

        return result;

      })
      .catch(error => {
        return handleError(error);
      });
  }

};



export const OldPost = async (url, options) => {
  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    return await OLD_API.post(url, options, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response !== undefined) {
          return error.response.data;
        } else {
          let data = {};
          data.data = {};
          data.meta = {};
          data.meta.status = 503;
          return data;
        }
      });
  }
};

export const registerPost = async (url, options) => {
  var bodyFormData = new FormData();
  bodyFormData.set('first_name', options.first_name);
  bodyFormData.set('adhar_file', options.adhar_file);
  bodyFormData.set('pancard_file', options.pen_file);
  bodyFormData.set('last_name', options.last_name);
  bodyFormData.set('mobile_number', options.mobile_number);
  bodyFormData.set('email', options.email);
  bodyFormData.set('pancard_no', options.pancard_no);
  bodyFormData.set('adharcard_no', options.adharcard_no);
  if (options.gst_no !== "") {
    bodyFormData.set('gst_no', options.gst_no);
  }
  if (options.rera_no !== "") {
    bodyFormData.set('rera_no', options.rera_no);
  }
  bodyFormData.set('is_tc_agree', options.is_tc_agree);


  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    return await API.post(url, bodyFormData)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response !== undefined) {
          return error.response.data;
        } else {
          let data = {};
          data.data = {};
          data.meta = {};
          data.meta.status = 503;
          return data;
        }
      });
  }

};

export const bulkUploadPost = async (url, options) => {
  const tokenData = getToken();
  var headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': tokenData.token,
  }

  var bodyFormData = new FormData();
  bodyFormData.set('bulk_leads', options.bulk_file);


  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    return await API.post(url, bodyFormData, { headers: headers })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response !== undefined) {
          return error.response.data;
        } else {
          let data = {};
          data.data = {};
          data.meta = {};
          data.meta.status = 503;
          return data;
        }
      });
  }

};

export const patch = async (url, options) => {
  let result = {
    meta: {
      status: 200,
      error: 0,
      message: errorCodes[200],
    },
    data: {},
  };

  let bodyFormData = new FormData();
  bodyFormData.set('first_name', options.first_name);

  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    return await API.patch(url, options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response !== undefined) {
          result.meta.status = error.response.status;
          result.meta.status = error.response.message || errorCodes[error.response.status];

        } else if (error.code === 'ECONNABORTED') {
          result.meta.status = 408;
          result.meta.message = error.message || errorCodes[error.response.status];


        } else if (error.request) {
          result.meta.status = 500;
          result.meta.message = error.message;
        } else {
          result.meta.status = 503;
          result.meta.message = error.message;
        }

        return result;

      });
  }

};

export const addDocPost = async (url, options) => {
  const tokenData = getToken();
  var headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': tokenData.token,
  }

  var bodyFormData = new FormData();
  bodyFormData.append('data', options.data);
  bodyFormData.append('hr_doc', options.hr_doc);


  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    return await API.post(url, bodyFormData, { headers: headers })
      .then(response => {
        let result = {
          meta: {
            status: 200,
            error: 0,
            message: 'OK',
          },
          data: {},
        }
        if (response.data) result.data = response.data;

        if (response.data.count !== undefined || response.data.count !== '') {
          result.meta.count = response.data.count;
        }

        result.meta.status = response.status;
        result.meta.message = errorCodes[response.status];

        return result;
      })
      .catch(error => {
        if (error.response !== undefined) {
          return error.response.data;
        } else {
          let data = {};
          data.data = {};
          data.meta = {};
          data.meta.status = 503;
          return data;
        }
      });
  }

};

export const registerPatch = async (url, options) => {


  var bodyFormData = new FormData();
  bodyFormData.set('data', options.data);

  if (options.adhar_file !== undefined) {
    if (options.adhar_file !== '') {
      bodyFormData.append('adhar_file', options.adhar_file);
    }
  }
  if (options.pen_file !== undefined) {
    if (options.pen_file !== '') {
      bodyFormData.append('pancard_file', options.pen_file);
    }
  }
  if (options.user_picture !== undefined) {
    if (options.user_picture !== '') {
      bodyFormData.append('user_picture', options.user_picture);
    }
  }

  if (options == null && options === '') {

    let errorRes = "Data field is empty";
    return errorRes

  } else {
    return await API.patch(url, bodyFormData, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response !== undefined) {
          return error.response.data;
        } else {
          let data = {};
          data.data = {};
          data.meta = {};
          data.meta.status = 503;
          return data;
        }
      });
  }

};