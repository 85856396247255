import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import BulkLeadForm from "../form/bulkUpload";
import UpdateBulkForm from "../form/updateBulkUpload";
import UpdateDumpForm from "../form/updateDumpUpload";
import Loader from "../../common/loader/loader";
class BulkModalFrom extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			showLoader:false
		}
	}
	showLoader = () => {
		this.setState({showLoader:true});
	}
	hideLoader = () => {
		this.setState({showLoader:false});
	} 
    render() {

    	const FROMS_TYPES = {
			"bulkLead" : BulkLeadForm,
			"updateBulkForm" : UpdateBulkForm,
			"updateDumpForm" : UpdateDumpForm,
		}
		const Form_name = FROMS_TYPES[this.props.fromType];
        return (
            <Frag>
				<Loader show={this.state.showLoader} />
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size="lg"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			          {Form_name === BulkLeadForm ? "Bulk Lead Upload" : this.props.userId ? "Update Details" : "Update Lead"}
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			        <Form_name 
			        	getList={this.props.getList}
			        	userId={this.props.userId}
		        	    leadID={this.props.leadID} 
				        leadType={this.props.leadType} 
				        Name={this.props.userName}
				        mobileNumber={this.props.mobileNumber}
				  		projectId={this.props.projectId}
				        city={this.props.city}
				        source={this.props.source}
				        bulkId={this.props.bulkId}
				        dumptype={this.props.dumptype}
				        changeModal={this.props.changeModal}
						userInfo={this.props.userInfo}
						showLoader={this.showLoader}
						hideLoader={this.hideLoader}
			        />
			      </Modal.Body>
			      
			    </Modal>
            </Frag>
        );
    };
};    

export default BulkModalFrom;