import React from 'react'
import { Modal } from 'react-bootstrap';

export const MyModal = ({show, changeModal, title, size, children}) => {
  
    return (
      <> 
        <Modal show={show} onHide={changeModal} centered size={size}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {children}
          </Modal.Body>
        </Modal>
      </>
    );
}
