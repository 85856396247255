import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FilePreviewer from 'react-file-previewer';
// const customStyle = {
// 	width: "100%",
// 	height: "70vh",
// 	border: "1px solid lightgray"
// }
class HrDocsPreviewer extends Component {
	constructor(props) {
		super(props);
	}
	
    render()  {
        return (
            <>
                <Modal
					show={this.props.showModal}
					onHide={this.props.handleModalClose}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="viewPdf"
				>
					<style>
					    {`@media print {*{display: none;}}`}
				  	</style>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							HR Docs Preview
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="viewPdfBody">
						{this.props.staticView ? <iframe scrolling="no" className="fp-iframe HandBook" src={this.props.ViewFilePath}></iframe> :
						<FilePreviewer 
				            file={{
				                data: this.props.ViewFilePath,
				                mimeType: 'application/pdf',
				                name: 'sample.pdf' // for download
				            }}
				            hideControls
				        /> }
					</Modal.Body>
				</Modal>
            </>
        );

    }
}

export default HrDocsPreviewer;