import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import { UpdateLeadData, addLeadDataOld } from "../../../dataParser/getListData";
import { CLOSING_REASON, REVERT_ROLES, STAGES } from "../../../config/constant";
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { autoComplete } from '../../../dataParser/commomDataApi';
import CircularProgress from "@material-ui/core/CircularProgress";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import PopUpAlert from '../sweetAlert/popUpAlert.js';
import ButtonSubmit from '../controls/ButtonSubmit.js';
import AutocompleteField from '../controls/Autocomplete'

class closeUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lead_id: '',
      name: '',
      number: '',
      email: '',
      comment: '',
      // lead_status_id: '',
      closing_reason: '',
      leadDetail: {},
      isError: false,
      sweetShow: false,
      type: "success",
      title: "",
      userName: [],
      assign_id: "",
      alertShow: false,
      alertMsg: ""
			, project: []
			, project_id: ''
    };
  }

  componentDidMount() {
    this.props.leadDetail.data.closing_reason = this.props.leadDetail.data && this.props.leadDetail.data.closing_reason ? this.titleCase(this.props.leadDetail.data.closing_reason) : this.props.leadDetail.data.closing_reason;
    this.setState({
      lead_id: this.props.leadDetail.data.id,
      leadDetail: this.props.leadDetail.data,
      name: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.name || '',
      number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.number || '',
      email: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.email || '',
      closing_reason: this.props.leadDetail.data !== null && this.props.leadDetail.data.closing_reason || '',
      comment: this.props.leadDetail.data.ObjectStage !== null && this.props.leadDetail.data.ObjectStage.comments || '',
    });

  }

  createNewLead = async (e) => {
    e.preventDefault();
    let data = {};
    let {lead_id, name, number, email, closing_reason, comment, project_id, assign_id} = this.state;
    if (lead_id != '') data["auditLeadId"] = lead_id;
    if (name != '') data["name"] = name;
    if (number != '') data["number"] = number;
    if (email != '') data["email"] = email;
    if (closing_reason != '') data["closing_reason"] = closing_reason;
    if (assign_id !== "") data["assign_to"] = assign_id;
    if (comment != ''){
      data["audit_comment"] = comment;
      data["admin_message"] = this.props.leadDetail.data.admin_message;
    } 
    data["is_audit_new_lead"] = 1;
    data['source_id'] = this.props.leadDetail.data.LeadSource.id;
    data['project_id'] = project_id !== '' ? project_id : this.props.leadDetail?.data?.Project?.id;
    if (name !== '' && number !== '' && closing_reason !== '') {

      let leadRes = await addLeadDataOld(data);

      if (leadRes.meta.status === 201) {
        this.setState({
          sweetShow: true,
          type: "success",
          title: "New Lead Created Successfully!!!"

        });

      } else {

        this.setState({
          sweetShow: true,
          type: "error",
          title: leadRes.meta.message
        });
      }
    } else {
      this.setState({
				showLoader: false,
				alertShow: true,
				alertType: "error",
				alertMsg: "*Above all * fileds are reqiured !!!",
			})
    }
  }

  closeLead = async (e) => {
    e.preventDefault();
    let data = {};
    data.Client = {};
    data.ObjectStage = {};
    let {lead_id, name, number, email, closing_reason, comment} = this.state;
    if (lead_id != '') data["lead_id"] = lead_id;
    if (name != '') data.Client["name"] = name;
    if (number != '') data.Client["number"] = number;
    if (email != '') data.Client["email"] = email;
    if (closing_reason != '') data["closing_reason"] = closing_reason;
    //if (comment != '') data.ObjectStage["comment"] = comment;
    data.ObjectStage['stage_id'] = STAGES.CLOSED;
    data.is_audit_close = 1;
    data.audit_comment = comment;
    if (name !== '' && number !== '' && closing_reason !== '') {

      let leadRes = await UpdateLeadData(lead_id, data);

      if (leadRes.meta.status === 201) {
        this.setState({
          sweetShow: true,
          type: "success",
          title: "Lead Closed Successfully!!!"

        });

      } else {

        this.setState({
          sweetShow: true,
          type: "error",
          title: leadRes.meta.message
        });
      }
    } else {
      {
        this.setState({
          showLoader: false,
          alertShow: true,
          alertType: "error",
          alertMsg: "*Above all * fileds are reqiured !!!",
        })
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    this.props.changeModal()
    this.props.getList();

  }
  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }

  AutoUserChangeUser = async e => {
    this.setState({ isLoading: true });

    (async () => {
      try {
        let data = {};
        let resData = {};

        data.q = e.target.value;
        data.users_active = 1;
        data.roles = [
          REVERT_ROLES.SALES_TL,
          REVERT_ROLES.SENIOR_SALES_TL,
          REVERT_ROLES.CLOSING_S1,
          REVERT_ROLES.PRESALES_TL,
          REVERT_ROLES.MAGNET_RM,
          REVERT_ROLES.SALES_MANAGER,
          REVERT_ROLES.SOURCING_S2,
          REVERT_ROLES.SOURCING_S3,
          REVERT_ROLES.MAGNET_TL,
          REVERT_ROLES.PNL_HEAD,
          REVERT_ROLES.SALES_HEAD,
          REVERT_ROLES.MAGNET_S2,
          REVERT_ROLES.MAGNET_S3,
        ];
        resData = await autoComplete("users", data);

        await this.setState({
          userName: resData.data,
          isLoading: false
        });
      } catch (e) {
        //...handle the error...
      }
    })();
  };
  handleClose = () =>{
    this.setState({alertShow: false})
  }
  changeProjectData = (project) => {
		if (project === null) {
			project = {}
		}
		this.setState({
			project_id: project.id,
			showLoader: true,
		})
	}
  handleAutoProjectChange = async (e) => {
		(async () => {
			try {
				let data = {
					q: e.target.value,
				};
				var resData = await autoComplete('projects', data);
				this.setState({
					project: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}
  render() {

    const { sweetShow, type, title, isLoading, closing_reason, comment, lead_id, userName, alertShow, alertType, alertMsg} = this.state;

    return (
      <Frag>
        <div className="auditForm">
          <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
          <form role="form">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <TextField
                      required
                      id="name"
                      name="name"
                      label="Client Name"
                      value={this.state.name ? this.state.name : ''}
                      onChange={this.onChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <TextField
                    disabled
                    id="number"
                    name="number"
                    value={this.state.number ? this.state.number : ''}
                    label="Client Number"
                    onChange={this.onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <TextField
                      id="email"
                      name="email"
                      label="Client Email"
                      value={this.state.email ? this.state.email : ''}
                      onChange={this.onChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label" required>Closing Reason</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={closing_reason}
                      onChange={this.onChange}
                      inputProps={{
                        name: 'closing_reason',
                        id: 'closing_reason',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {CLOSING_REASON ?
                        (CLOSING_REASON.map((reason, index) =>
                          <MenuItem value={reason} key={index}>{reason}</MenuItem>
                        ))
                        :
                        ''
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="col-sm-6 mb-3">
                  <Autocomplete
                    getOptionSelected={(userName, value) =>
                      userName.name === value.name
                    }
                    getOptionLabel={userName => userName.name}
                    options={userName}
                    loading={isLoading}
                    onChange={(_event, userName) => {
                      if (userName === null) {
                        this.setState({
                          userName: [],
                        });
                      } else {
                        this.setState({
                          assign_id: userName.id,
                        });
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select RM"
                        fullWidth
                        onChange={this.AutoUserChangeUser}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <BusinessRoundedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-sm-6 mb-3">
									<AutocompleteField
										label='Interested Project'
										variant="standard"
										options={this.state.project}
										onChange={this.changeProjectData}
										handleAutoChange={this.handleAutoProjectChange}
									/>
                </div>
                <div className="col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      id="standard-multiline-flexible"
                      label="Comments"
                      name="comment"
                      value={this.state.comment}
                      multiline
                      rowsMax="4"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 text-center mb-3 mb-md-0">
                    <ButtonSubmit onClick={this.closeLead} lable="Close Lead" />
                </div>
                <div className="col-md-6 text-center ">
                    <ButtonSubmit onClick={this.createNewLead} lable="Create New Lead" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={this.handleClose} />
      </Frag>
    );
  };
};

export default withRouter(closeUpdateForm);
