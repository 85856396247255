import React, { Component } from 'react';
import Frag from "../../utils/Frag/Frag";
import Paper from '@material-ui/core/Paper';
import { getDumpWithdrawCount, getWithdrawLead } from "../../dataParser/dumpLeadData";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import SweetWarnAlert from "../../component/common/sweetAlert/sweetAlertWarning";
import "date-fns";
import Loader from "../common/loader/loader";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { cleanStorage } from '../../helper';
import { DUMP_LEAD } from '../../config/constant';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class DumpWithdraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetShow: false,
            sweetWarnShow: false,
            showLoader: false,
            dumpMeta: {},
            dumpData: [],
            type: "success",
            title: "",

        };
    }

    async componentDidMount() {
        let dumpObj = {};
        dumpObj.data = {};
        let user = window.$user.id;
        var dumpDataRes = await getDumpWithdrawCount(user);
        if (dumpDataRes.meta.status === 204) {

            this.setState({
                isLoading: false,
                showLoader: false,
                dumpData: dumpDataRes.data,
                dumpMeta: dumpDataRes.meta
            })
        } else if (dumpDataRes.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");

        } else {
            this.setState({
                isLoading: false,
                showLoader: false,
                dumpData: dumpDataRes.data,
                dumpMeta: dumpDataRes.meta
            })
        }

    }

    withdrawBucket = async (userId) => {
        this.setState({ showLoader: true });
        let withdrawData = {}
        withdrawData.withdraw_by = window.$user.id;
        withdrawData.status = 14;
        let upadateWithdrawReq = {};
        upadateWithdrawReq.userId = userId;
        upadateWithdrawReq.data = withdrawData;
        var withdrawDataRes = await getWithdrawLead(upadateWithdrawReq);
        if (withdrawDataRes.meta.status === 201) {
            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "success",
                title: "Lead Withdraw !!"
            });
        }
        else {
            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "error",
                title: "Something went wrong !!!"

            });
        }


    }
    handleSweetWarn = value => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow,
            withdrawUser: value
        });
    };

    handleUpdateUser = () => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow
        });
        this.withdrawBucket(this.state.withdrawUser);
    };


    handleSweet = () => {
        this.setState({ sweetShow: !this.state.sweetShow });
        window.location.reload();
    };

    render() {

        const { sweetShow, sweetWarnShow, type, title, dumpData } = this.state;
        // const { count, size, status} = this.state.dumpMeta;
        return (
            <Frag>
                <div className="content-wrapper">
                    <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
                    <SweetWarnAlert show={sweetWarnShow} changeSweet={this.handleSweetWarn} message={`${DUMP_LEAD} bucket withdraw permanently !!!`} handleUpdateUser={this.handleUpdateUser} />
                    <Loader show={this.state.showLoader} />
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-md-12 mt-3 mb-2 text-left">
                                {dumpData?.length > 0 &&
                                    <Frag>
                                        <span className="fs-16">
                                            {`${DUMP_LEAD} Withdraw Panel`}
                                            </span>
                                    </Frag>
                                }
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <TableContainer component={Paper}>
                                    <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left">Name</StyledTableCell>
                                                <StyledTableCell align="left">DumpCount</StyledTableCell>
                                                <StyledTableCell align="left">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.dumpData.map((row, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell className="text-capitalize" align="left">{row?.DumpLeadAssign?.name}</TableCell>
                                                    <TableCell align="left">{row.dump_count}</TableCell>
                                                    <TableCell><Button variant="contained" color="primary" onClick={() => { this.handleSweetWarn(row?.DumpLeadAssign?.id) }} >
                                                        Withdraw
                                                    </Button></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>


                                    </Table>
                                </TableContainer>
                            </div>


                        </div>
                    </div>
                </div>
            </Frag>
        );
    };

}

export default DumpWithdraw;
