import React from 'react'
import Loader from './common/loader/loader'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import { getFinanceCalendarData, deleteFinanceCalendar } from '../dataParser/getListUserData';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import PopUpAlert from "./common/sweetAlert/popUpAlert";
import moment from "moment";
import SweetWarnAlert from "./common/sweetAlert/sweetAlertWarning";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MonthCalendar from './common/form/MonthCalendar';
import { MONTHS, QUARTERS } from '../config/constant';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const fields = ["ID", "Year", "Month", "Quarter", "Start Date", "End Date", "Action"]

let initialState = {
    data: []
    , fullData: null
    , count: 0
    , size: 12
}


const FinanceCalendar = () => {
    const [state, setState] = React.useState(initialState);
    const { data, count, size } = state;

    const [activePage, setActivePage] = React.useState(1);
    const [showLoader, setShowLoader] = React.useState(false);
    const [sweetWarnShow, setSweetWarnShow] = React.useState(false);
    const [itemId, setItemId] = React.useState(null);

    const [alertState, setAlertState] = React.useState({
        alertShow: false
        , alertMsg: ""
    });

    const { alertShow, alertType, alertMsg } = alertState;

    React.useEffect(() => {

        let listData = {};
        listData.size = size;
        listData.page = 1;
        getApi(listData);
    }, [])

    const getApi = async (queryParam) => {
        setShowLoader(true)
        let getRes = await getFinanceCalendarData(queryParam);
        if (getRes.meta.status === 201) {
            setShowLoader(false)        
            setState({
                ...state
                , data: getRes?.data?.rows
                , count: getRes?.data.count
                , size: getRes.meta.size
            })
        }
        else {
            setShowLoader(false)
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: getRes.meta?.message || "Something went wrong!"
            });
        }
    }

    const handleEdit = async (id) => {
        setItemId(id)
    }

    const handleUpdateUser = () => {
        setSweetWarnShow(!sweetWarnShow)
        handleDeleteUser(state.deleteUser);
    };

    const handleDeleteUser = async id => {
        setShowLoader(true);
        var deleteRes = await deleteFinanceCalendar(id);

        if (deleteRes.meta.status === 200) {
            setAlertState({
                alertShow: true,
                alertType: "success",
                alertMsg: "Deleted successfully!!!"
            });
        } else {
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: deleteRes.meta?.message || "Something went wrong!"
            });
        }
        setShowLoader(false);
        setSweetWarnShow(!sweetWarnShow)
    };

    const handleSweetWarn = value => {
        setSweetWarnShow(!sweetWarnShow)
        setState({
            ...state,
            deleteUser: value
        })
    }

    const handleChangePage = (pageNumber) => {
        setActivePage(pageNumber)
        let listData = {};
        listData.size = size;
        listData.page = pageNumber;
        getApi(listData);
    }

    const handleAlertClose = () => {
        setAlertState({
            ...alertState
            , alertShow: !alertShow
        })
        handleChangePage(activePage);
    }

    return (
        <>
            <Loader show={showLoader} />
            <MonthCalendar onSuccess={handleChangePage} recordId={itemId}/>
            <div className="listContainer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                                {data?.length > 0 ?
                                    <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }}>
                                        <TableHead>
                                            <TableRow>
                                                {fields.map((item, idx) => (
                                                    <StyledTableCell key={idx}>{item}</StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((item, idx) => (
                                                <TableRow key={idx} className="text-capitalize">
                                                    <TableCell>{item.id || "-"}</TableCell>
                                                    <TableCell>{item?.year}</TableCell>
                                                    <TableCell>{item?.month ? MONTHS[Number(item?.month)] : "-"}</TableCell>
                                                    <TableCell>{item?.quarter ? QUARTERS.filter(x=>x.id === item?.quarter)[0].name : "-"}</TableCell>
                                                    <TableCell>
                                                        {item.start_date ? moment(item.start_date).format("DD/MM/YYYY") : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.end_date ? moment(item.end_date).format("DD/MM/YYYY") : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip
                                                            title="Update Record"
                                                            onClick={() => handleEdit(item.id)}
                                                        >
                                                            <IconButton>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    className="fs-16 text-warning"
                                                                /></IconButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title="Delete Record"
                                                            onClick={value => {
                                                                handleSweetWarn(item.id);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    className="fs-16 text-danger"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    : <div className="main_area_display shadow card p-3">
                                        <h5>{showLoader ? "Loading.." : "No Data Found !!!"}</h5>
                                    </div>
                                }
                            </TableContainer>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        {count > size ?
                            <div className="paginationBlock">
                                <Pagination
                                    hideDisabled
                                    activePage={activePage}
                                    itemsCountPerPage={size}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={5}
                                    onChange={handleChangePage}
                                />
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>

            <SweetWarnAlert
                show={sweetWarnShow}
                changeSweet={handleSweetWarn}
                message="Record will be deleted successfully!!!"
                handleUpdateUser={handleUpdateUser}
            />
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
        </>
    )
}

export default FinanceCalendar