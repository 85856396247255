import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { getAddUser } from "../../../dataParser/getListUserData";
import { getRoleList, autoComplete } from "../../../dataParser/commomDataApi";
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { REVERT_ROLES } from "../../../config/constant/index.js";
import Loader from "../../../component/common/loader/loader";
import { phoneCheck, emailCheck, nameCheck, letterCase, passwordCheck, empty, cleanStorage, objEmpty } from "../../../helper";
import PopUpAlert from "../sweetAlert/popUpAlert";
import Chip from '@material-ui/core/Chip';
import { getRegion } from "../../../dataParser/getProjectData";
import { getRegionParentsData } from '../../../dataParser/getCpUserData.js';
import { showMagnetPlatinum } from '../../../config/constant/crmRoleFunctionPermission.js';
class UserAddFrom extends Component {

	constructor(props) {
		super(props);
		this.state = {
			role: '',
			userName: [],
			user_region : '',
			region_id: '',
			region: [],
			selectedVal: [],
			name: '',
			email: '',
			role_id: '',
			user_id: '',
			phone: '',
			is_active: 1,
			group_id: 1,
			isLoading: false,
			sweetShow: false,
			type: "success",
			title: "",
			error: "",
			isError: {
				email: '',
				first_name: '',
				last_name: '',
				phone: '',
				region_id: '',
				role_id: '',
				confirmpassword: '',
				password: '',
			},
			alertShow: false, 
			alertType: "success",
			alertMsg: "",
			parent_region_ids: []
		};
	}

	async componentDidMount() {
		let roleListData = await getRoleList();
		if(!showMagnetPlatinum.includes(window?.$user?.role)) {
			roleListData = roleListData.filter(item=>item.id !== 36)
		}
		this.setState({ role: roleListData });
		var regionData = await getRegion();
		this.setState({ user_region: regionData })
		this.getRegionData();
	}

	handleUpdateUser = async (e) => {
		e.preventDefault();
		this.setState({
			showLoader: true,
		})
		// console.log(this.formValid(this.state))
		if (this.formValid(this.state)) {
			if(this.state.password !== this.state.confirmpassword){
				this.setState({
					showLoader: false,
					alertShow: true,
					alertType: "error",
					alertMsg: "*Password & confirm password does not match !!!",
				})
				return false;
			}
			if(!this.state.region_id || this.state.region_id === null){
				this.setState({
					showLoader: false,
					alertShow: true,
					alertType: "error",
					alertMsg: "region feild is required !!!",
				})
				return false;
			}
			if (this.state.role_id === 21 && this.state.parent_region_ids.length < 1) {
				this.setState({
					showLoader: false,
					alertShow: true,
					alertType: "error",
					alertMsg: "work region field is required!!"
				});

				return false;
			}

			let data = {}
			if (this.state.first_name) data["first_name"] = this.state.first_name
			if (this.state.last_name) data["last_name"] = this.state.last_name
			if (this.state.email) data["email"] = this.state.email
			if (this.state.password) data["password"] = this.state.password
			if (this.state.phone) data["phone"] = parseInt(this.state.phone)
			if (this.state.role_id) data["role_id"] = this.state.role_id
			if (this.state.user_id) data["parent_id"] = this.state.user_id
			if (this.state.region_id) data["region_id"] = this.state.region_id
			if (this.state.parent_region_ids) data["parent_region_ids"] = this.state.parent_region_ids
			let addLeadRes = await getAddUser(data);

			if (addLeadRes.meta.status === 503) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "!!! Oooops Network Error..."
				});

			} else if (addLeadRes.meta.status === 201) {

				// console.log("User Register successfully !!!");
				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Registered successfully"
				});
			} else if (addLeadRes.meta.status === 406 || addLeadRes.meta.status === 400) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "warning",
					title: "Can't add magnet user !!!"
				});

			} else if (addLeadRes.meta.status === 409) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "warning",
					title: "User already created!!"
				});

			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});
				
			}
		} else {
			this.setState({
				showLoader: false,
				alertShow: true,
				alertType: "error",
				alertMsg: "Above all * fields are required !!!",
			})
		}
	};

	formValid = ({ isError, ...rest }) => {
		let isValid = false;

		if (empty(rest.first_name) || empty(rest.last_name) || empty(rest.email) || empty(rest.role_id) || empty(rest.phone) || empty(rest.password)) {
			return false;
		}

		Object.values(isError).forEach(val => {
			if (val.length > 0) {
				return false;
			} else {
				isValid = true
			}
		});

		return isValid;
	};

	formValChange = e => {
		e.preventDefault();
		let { name, value } = e.target;
		let isError = { ...this.state.isError };
		// console.log("in the change  ===MMMMMM====",isError);

		switch (name) {
			case "first_name":
				isError.first_name =
					nameCheck(value) ? "" : "Please enter valid name";
				break;
			case "last_name":
				isError.last_name = "" ; // removed validation for last name
					// value.length < 4 && nameCheck(value) ? "Atleast 4 characaters required" : "";
					// nameCheck(value) ? "" : "Please enter valid name";
				break;
			case "password":
				isError.password =
					passwordCheck(value) ? "" : "Please include number & special characters and aleast 6 characters";
				break;
			case "email":
				isError.email = emailCheck(value)
					? ""
					: "Email address is invalid";
				break;
			case "phone":
				isError.phone = phoneCheck(value)
					? ""
					: "phone is invalid";
				break;
			default:
				break;
		}
		this.setState({
			isError,
		})
		// console.log("in the passwwooopppppp",name)
		if (name === 'password' || name === 'confirmpassword') {
			// console.log("in the passwwooopppppp")
			this.setState({
				[name]: (value)
			})
		} else if (name === "phone") {
			value = e.target.value.replace(/\D/g, "")
			this.setState({
				[name]: (value)
			})
		} else if (typeof value !== 'number') {
			this.setState({
				[name]: letterCase(value, true)
			})
		} else {
			this.setState({
				[name]: (value)
			})
		}
	};

	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			users_active : 1,
			q: e.target.value,
		};

		if(this.state.role_id == 36){
			data.roles = [REVERT_ROLES.MAGNET];
		}
		else{
		data.roles = [ REVERT_ROLES.ADMIN,REVERT_ROLES.SALES_HEAD, REVERT_ROLES.PNL_HEAD,REVERT_ROLES.SALES_MANAGER,REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL,
			REVERT_ROLES.FINANCE_HEAD,REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.PRESALES_TL,REVERT_ROLES.SOURCING_S2, REVERT_ROLES.MEDIA_MANAGERS,REVERT_ROLES.HR_HEAD,  REVERT_ROLES.MAGNET_ADMIN,
			REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.OPERATIONS_HEAD, REVERT_ROLES.OPERATIONS_ANALYST,
			REVERT_ROLES.FINANCE_ANALYST, REVERT_ROLES.CAMPAIGN_MANAGERS,REVERT_ROLES.CAMPAIGN_OPERATIONS,REVERT_ROLES.OPERATIONS_MANAGER, REVERT_ROLES.MAGNET_PNL]
		}
			let resData = await autoComplete('users', data);

		// console.log("resData.dataXXXXXXXXXXXXXXXX",resData.data);
		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList()
		// window.location.reload();
	}
	handleClose = () => {
		this.setState({
			alertShow: false
		})
	}

	getRegionData = async () => {
        let regionData = await getRegionParentsData();
        this.setState({
			region: regionData.data
		})
    }

	render() {

		const { error,user_region,region_id,isLoading, userName, sweetShow, type, title, role_id, role, phone, alertType, alertMsg, alertShow, selectedVal ,parent_region_ids} = this.state;
		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<Loader show={this.state.showLoader} />
					<PopUpAlert type={alertType} msg={alertMsg} open={alertShow} handleClose={this.handleClose} hideTime={3000} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.first_name !== '' ? true : false}
											required
											id="first_name"
											name="first_name"
											label="First Name"
											helperText={this.state.isError.first_name !== '' ? this.state.isError.first_name : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.last_name !== '' ? true : false}
											required
											id="last_name"
											name="last_name"
											label="Last Name"
											helperText={this.state.isError.last_name !== '' ? this.state.isError.last_name : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.password !== '' ? true : false}
											required
											id="password"
											name="password"
											type="password"
											label="Password"
											helperText={this.state.isError.password !== '' ? this.state.isError.password : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<VpnKeyIcon />
													</InputAdornment>
												)
											}}
											autoComplete="new-password"
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.confirmpassword !== '' ? true : false}
											required
											id="confirmpassword"
											// type="password"
											name="confirmpassword"
											label="Confirm Password"
											helperText={this.state.isError.confirmpassword !== '' ? this.state.isError.confirmpassword : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<VpnKeyIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.phone !== '' ? true : false}
											required
											id="phone"
											name="phone"
											value={phone}
											helperText={this.state.isError.phone !== '' ? this.state.isError.phone : ''}
											label="Mobile Number"
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<PhoneIphoneIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.email !== '' ? true : false}
											required
											id="email"
											name="email"
											label="Email"
											helperText={this.state.isError.email !== '' ? this.state.isError.email : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Select Role</InputLabel>
										<Select
											error={this.state.isError.role_id !== '' ? true : false}
											labelId="demo-controlled-open-select-label"
											value={role_id}
											onChange={this.formValChange}
											inputProps={{
												name: 'role_id',
												id: 'role_id',
											}}
											helperText={this.state.isError.role_id !== '' ? this.state.isError.role_id : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{role ?
												(role.map(reg =>
													<MenuItem className="text-capitalize" value={reg.id} key={reg.id}>{reg.name} ({reg.alias})</MenuItem>
												))
												:
												''
											}
										</Select>
									</FormControl>
								</div>
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<Autocomplete
											id="UserData"
											getOptionSelected={(userName, value) => userName.name === value.name}
											getOptionLabel={userName => userName.name}
											options={userName}
											loading={isLoading}
											onChange={(_event, userName) => {
												if (userName === null) {
													this.setState({
														userName: [],
														user_id: '',
													})
												} else {
													this.setState({
														user_id: userName.id,
													})
												}

											}}
											renderInput={params => (
												<TextField
													{...params}
													label="Parent Name"
													fullWidth
													onChange={this.handleAutoUserChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{isLoading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel 
											id="demo-controlled-open-select-label"
											// required={["sales","presale"].includes(group) ? true : false}
											required
										>
											Select Region
										</InputLabel>
										<Select
											error={this.state.isError.region_id !== '' ?  true :  false}
											labelId="demo-controlled-open-select-label"
											value={region_id}
											onChange={this.formValChange}
											inputProps={{
												name: 'region_id',
												id: 'region_id',
											}}
											helperText={this.state.isError.region_id !== '' ? this.state.isError.region_id : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{user_region ?
												(user_region.map(reg =>
													<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
												))
												:
												''
											}
										</Select>
									</FormControl>
								</div>
								{role_id == "21" &&
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<Autocomplete
											multiple
											getOptionSelected={(region, value) =>
												region.name === value.name
											}
											getOptionLabel={region => region.name}
											options={this.state.region}
											value={this.state.selectedVal}
											renderTags={(value, getTagProps) =>
												value.map((region, index) => (
													<Chip
														label={region.name}
														{...getTagProps({ index })}
													/>
												))
											}
											onChange={(_event, region) => {
												let regionIds = region.map(ops => ops.id);
												this.setState({
													// ...state,
													parent_region_ids: regionIds,
													selectedVal: region
												});

												if (region === null) {
													this.setState([]);
												}
											}}
											renderInput={params => (
												<TextField
													{...params}
													required
													label="Working Regions"
													fullWidth
													onChange={this.onChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
                                </div>
	                            }
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.handleUpdateUser}>Submit</button>
										<p className="mb-0">
											<span className="text-center text-danger small">{error != '' ? error : ''}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(UserAddFrom);
