import React, { Component } from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getAutoCompleteProject, getRegion } from "../../dataParser/getProjectData";
import { cleanStorage, objEmpty } from "../../helper";
import { leadInterautoComplete, autoCompleteClientMobile, autoComplete, getLeadStatusData, getLeadSourceData, getClientListRolewise, roleWiseAutoComplete } from "../../dataParser/commomDataApi";
import { REVERT_ROLES , CLOSING_REASON} from "../../config/constant/index.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Popper from "@material-ui/core/Popper";
import GoogleMaps from "../common/googleMap/googleMap.js";
import { MenuProps } from "../common/datas/data";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import Input from "@material-ui/core/Input";
import SelectOption from "../common/controls/SelectOption"
import { fieldHiddenSales } from "../../config/constant/crmRoleFunctionPermission";

const styles = (theme) => ({
  popper: {
    maxidth: "fit-content",
  }
});
const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const closingReasonAccess = ["audit", "closed"];

class Filter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: [],
      autoKey: true,
      presaleTl: [],
      userName: [],
      internal_st: [],
      magnetName: [],
      magnetRmName: [],
      magnet_ids: [],
      client: [],
      status: [],
      status_ids: '',
      source_ids: [],
      source: [],
      isLoading: false,
      isClientLoading: false,
      assigned_date_from: null,
      assigned_date_to: null,
      created_at_from: null,
      created_at_to: null,
      closed_date_from: null,
      closed_date_to: null,
      sitevisit_date_from: null,
      sitevisit_date_to: null,
      activity_date_from: null,
      activity_date_to: null,
      hpop_date_from: null,
      hpop_date_to: null,
      updated_at_from: null,
      updated_at_to: null,
      booking_date_from: null,
      booking_date_to: null,
      closeReason: '',
      project_name: '',
      project_ids: [],
      ids: '',
      region: [],
      region_ids: [],
      assigned_to: '',
      mobileNumber: [],
      client_number: '',
      client_ids: '',
      magnet_lead_rm: '',
      pre_sale_rm: '',
      magnet_rm_ids:'',
      internal_status_id: '',
      is_magnet: '',
      is_presale: '',
      client_type: '',
      role: '',
      nationality: '',
      current_location: '',
      is_audit: '',
      lead_percentage: '',
      selectedValName: [],
      selectedValProj: [],
      selectedValSource: [],
      selectedValStatus: [],
      selectedValActivity: [],
      selectedValLeadRM: [],
      selectedValMagnet: [],
      selectedValMagnetRm:[],
      selectedValPresale: [],
      selectedValNumber: {},
      selectedValRegion: [],
      closing_reasons:[],
      followup_time:null,
      selectedActivity: []
      , user_group: ""
    };
  }

  async componentDidMount() {
    let numArr = [{ name: this.props.filters?.selectedValNumber?.name, number: this.props.filters?.selectedValNumber?.number }]
    this.setState({
      ...this.props.filters,
      role: window.$role,
      group: window.$user.group.toLowerCase(),
      selectedValName: this.props?.filters?.selectedValName || [],
      selectedValProj: this.props?.filters?.selectedValProj || [],
      selectedValSource: this.props?.filters?.selectedValSource || [],
      selectedValStatus: this.props?.filters?.selectedValStatus || [],
      selectedValActivity: this.props?.filters?.selectedValActivity || [],
      selectedValLeadRM: this.props?.filters?.selectedValLeadRM || [],
      selectedValMagnet: this.props?.filters?.selectedValMagnet || [],
      selectedValPresale: this.props?.filters?.selectedValPresale || [],
      mobileNumber: numArr[0].name !== undefined ? numArr : [],
      selectedValNumber: numArr[0].name !== undefined ? numArr[0] : {},
      selectedValRegion: this.props?.filters?.selectedValRegion || [],
      
    });

    var regionData = await getRegion();
    this.setState({ region: regionData })

    var getStatusData = await getLeadStatusData();

    let resData = await leadInterautoComplete('lead_internal_status');
      await this.setState({
        internal_st: resData.data
      });
      
    if (getStatusData.meta.status === 200) {
      if (window.$role !== 'admin' || window.$role !== 'saleshead' || window.$role !== 'pnlhead' || window.$role !== 'presalehead') {
        delete getStatusData.data[2]
      }
      this.setState({ status: getStatusData.data })

    } else {

      this.setState({ status: [] })
    }

    var allSourceData = await getLeadSourceData();
    this.setState({ source: allSourceData.data })

  }

  handleGeoLocation = async (current_location) => {

    this.setState({ current_location: current_location });
  }

  handleAutoClientMobileChange = async (e) => {

    this.setState({ isMobileNoLoading: true });
    let data = {
      q: e.target.value,
    };
    let resData = await autoCompleteClientMobile(data);

    if (resData.meta.status === 200) {

      this.setState({ isMobileNoLoading: false });
      this.setState({ mobileNumber: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isMobileNoLoading: false });
      this.setState({ mobileNumber: [] })
    }
  }

  handleAutoUserChange = async (e) => {
    this.setState({ isLoading: true });
    let data = {};
    let resData = {};

    if (this.state.group === 'magnet') {
      data.q = e.target.value;
      data.roles = [REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.MAGNET_TL]
      resData = await autoComplete('users', data);
    } else {
      data.q = e.target.value;
      resData = await roleWiseAutoComplete(data);
    }

    if (resData.meta.status === 200) {

      this.setState({ isLoading: false });
      this.setState({ userName: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isLoading: false });
      this.setState({ userName: [] })
    }
  }

  handleAutoPresaleRmUser = async (e) => {
    this.setState({ isMagnetLoading: true });
    let data = {};

    let resData = {};

    data.q = e.target.value;
    data.roles = [REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
    resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isMagnetLoading: false });
      this.setState({ presaleTl: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isMagnetLoading: false });
      this.setState({ presaleTl: [] })
    }
  }

  handleAutoMagnetChange = async (e) => {
    this.setState({ isMagnetLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET]
    };
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnetName: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnetName: [] })
    }
  }
  
  handleAutoMagnetRmChange = async (e) => {
    this.setState({ isMagnetLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET_RM, REVERT_ROLES.MAGNET_TL, REVERT_ROLES.CUSTOMER_SUCCESS]
    };
    let resData = await autoComplete('users', data);
    if (resData.meta.status === 200) {
      this.setState({ isMagnetLoading: false });
      this.setState({ magnetRmName: resData.data })
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isMagnetLoading: false });
      this.setState({ magnetRmName: [] })
    }
  }
  /* handleActivityStatus = async (e) => {
    this.setState({ isLoading: true });

    (async () => {
      try {
        let data = {
          q: e.target.value
        };
        let resData = await leadInterautoComplete('lead_internal_status', data);
        await this.setState({
          isLoading: false,
          internal_st: resData.data
        });
      } catch (e) {
        //...handle the error...
      }
    })();
  } */

  handleAutoClient = async (e) => {
    this.setState({ isClientLoading: true });
    let value = e.target.value
    var getUserData = await getClientListRolewise(value);

    if (getUserData.meta.status === 200) {

      this.setState({ isClientLoading: false });
      this.setState({ client: getUserData.data })
    } else if (getUserData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isClientLoading: false });
      this.setState({ client: [] })
    }
  }

  handleDateChange = (key, date) => {
    this.setState({ [key]: date });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  applyFilter = () => {

    let filterData = {};

    if (this.state.project_ids.length > 0) {

      filterData["project_ids"] = this.state.project_ids;
    }
    if (this.state.magnet_ids.length > 0) {

      filterData["magnet_id"] = this.state.magnet_ids;
    }
    if (this.state.status_ids.length > 0) {

      filterData["stage_ids"] = this.state.status_ids;
    }
    if (this.state.source_ids.length > 0) {

      filterData["source_ids"] = this.state.source_ids;
    }
    if (this.state.ids) {

      filterData["ids"] = parseInt(this.state.ids);
    }

    if (this.state.client_ids.length > 0) {

      filterData["client_ids"] = this.state.client_ids;
    }

    if (this.state.selectedActivity.length > 0) {

      filterData["internal_status_ids"] = this.state.selectedActivity;
    }
    if(this.state.region_ids.length > 0){
      filterData["region_ids"] = this.state.region_ids;
    }

    if (this.state.assigned_to) {
      filterData["assign_to"] = this.state.assigned_to;
    }

    if (this.state.group !== 'magnet') {
      if (this.state.is_magnet != '') {
        filterData["is_magnet"] = parseInt(this.state.is_magnet);
      }
    } else {
      filterData["is_magnet"] = 1
    }

    if (this.state.is_presale) {

      filterData["is_presale"] = parseInt(this.state.is_presale);
    }
    if (this.state.client_number) {

      filterData["client_number"] = this.state.client_number;
    }
    if (this.state.magnet_lead_rm.length > 0) {

      filterData["assign_id"] = this.state.magnet_lead_rm;
    }
    if (this.state.magnet_rm_ids.length > 0) {
      filterData["magnet_rm_id"] = this.state.magnet_rm_ids;
    }
    if (this.state.pre_sale_rm.length > 0) {
      
      filterData["presale_rm_ids"] = this.state.pre_sale_rm;
    }
    if (this.state.nationality != '') {

      filterData["nationality"] = (this.state.nationality);
    }

    if (this.state.updated_at_from) {

      filterData["updated_at_from"] = this.state.updated_at_from.split("/").join("-");
    }

    if (this.state.updated_at_to) {

      filterData["updated_at_to"] = this.state.updated_at_to.split("/").join("-");
    }


    if (this.state.booking_date_from) {

      filterData["booking_date_from"] = this.state.booking_date_from.split("/").join("-");
    }

    if (this.state.booking_date_to) {

      filterData["booking_date_to"] = this.state.booking_date_to.split("/").join("-");
    }

    if (this.state.created_at_from) {

      filterData["created_at_from"] = this.state.created_at_from.split("/").join("-");
    }
    if (this.state.created_at_to) {

      filterData["created_at_to"] = this.state.created_at_to.split("/").join("-");
    }

    if (this.state.hpop_date_from) {

      filterData["hpop_date_from"] = this.state.hpop_date_from.split("/").join("-");
    }
    if (this.state.hpop_date_to) {

      filterData["hpop_date_to"] = this.state.hpop_date_to.split("/").join("-");
    }

    if (this.state.sitevisit_date_from) {

      filterData["sitevisit_date_from"] = this.state.sitevisit_date_from.split("/").join("-");
    }
    if (this.state.sitevisit_date_to) {

      filterData["sitevisit_date_to"] = this.state.sitevisit_date_to.split("/").join("-");
    }

    if (this.state.activity_date_from) {

      filterData["activity_date_from"] = this.state.activity_date_from.split("/").join("-");
    }
    if (this.state.activity_date_to) {

      filterData["activity_date_to"] = this.state.activity_date_to.split("/").join("-");
    }

    if (this.state.closed_date_from) {

      filterData["closed_date_from"] = this.state.closed_date_from.split("/").join("-");
    }
    if (this.state.closed_date_to) {

      filterData["closed_date_to"] = this.state.closed_date_to.split("/").join("-");
    }

    if (this.state.assigned_date_from) {

      filterData["assigned_date_from"] = this.state.assigned_date_from.split("/").join("-");
    }
    if (this.state.assigned_date_to) {

      filterData["assigned_date_to"] = this.state.assigned_date_to.split("/").join("-");
    }

    if (this.state.current_location) {
      filterData["current_location"] = this.state.current_location;
    }

    if (this.state.is_audit != '') {
      filterData["is_audit"] = parseInt(this.state.is_audit);
    }
    if (this.state.lead_percentage != '') {
      filterData["lead_percentage"] = parseInt(this.state.lead_percentage);
    }
    if(this.state.closing_reasons.length > 0 ){
      filterData['closing_reasons'] = this.state.closing_reasons;
    }
    if(this.state.user_group){
      filterData['user_group'] = this.state.user_group;
    }
    this.props.changeFilter();
    this.props.filterData(filterData, this.state);
  }

  resetFilter = () => {

    this.setState({
      autoKey: !this.state.autoKey,
      option: [],
      isLoading: false,
      status_ids: [],
      internal_status_id: '',
      presaleTl: [],
      userName: [],
      magnetName: [],
      magnet_ids: [],
      source_ids: [],
      assigned_date_from: null,
      assigned_date_to: null,
      created_at_from: null,
      created_at_to: null,
      closed_date_from: null,
      closed_date_to: null,
      sitevisit_date_from: null,
      sitevisit_date_to: null,
      activity_date_from: null,
      activity_date_to: null,
      hpop_date_from: null,
      hpop_date_to: null,
      updated_at_from: null,
      updated_at_to: null,
      booking_date_from: null,     
      booking_date_to: null,
      closeReason: '',
      project_name: '',
      project_ids: [],
      ids: '',
      region_ids: [],
      assigned_to: '',
      client_number: '',
      // mobileNumber: [],
      client_ids: '',
      magnet_lead_rm: '',
      pre_sale_rm: '',
      is_magnet: '',
      is_presale: '',
      client_type: '',
      nationality: '',
      current_location: '',
      is_audit: '',
      lead_percentage: '',
      selectedValName: [],
      selectedValProj: [],
      selectedValSource: [],
      selectedValStatus: [],
      selectedValActivity: [],
      selectedValLeadRM: [],
      selectedValMagnet: [],
      selectedValPresale: [],
      selectedValNumber: {},
      selectedValRegion: [],
      closing_reasons:[],
      followup_time:null,
      selectedActivity: []
      , user_group: ""
    })
  }

  handleAutoChange = async e => {
    this.setState({ isLoading: true });
    let value = e.target.value;
    let getData = await getAutoCompleteProject(value);
    if (getData.meta.status === 200) {
      this.setState({ isLoading: false, option: getData.data })
    } else if (getData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isLoading: false, option: [] })
    }
  };

  getSelectValues = (selectedActivity) => {
    const { internal_st } = this.state;
    return internal_st
      .filter((name) => selectedActivity.includes(name.id))
      .map((record) => record.name)
      .join(", ");
  }
  handleActivityChange = (event) => {
    const { selectedActivity, internal_st } = this.state;
    if (
      event.target.value.includes("all") &&
      selectedActivity.length !== internal_st.length
    ) {
      this.setState({ selectedActivity: internal_st.map((name) => name.id) });
    } else if (event.target.value.includes("all")) {
      this.setState({ selectedActivity: [] });
    } else {
      this.setState({ selectedActivity: event.target.value });
    }
  };
  render() {
  
    const { selectedValRegion, selectedValNumber, selectedValPresale, selectedValMagnet, selectedValMagnetRm, selectedValLeadRM
      , selectedValStatus, selectedValSource, selectedValProj, selectedValName, presaleTl, internal_st, client
      , isClientLoading, option, client_type, autoKey, userName, magnetName, magnetRmName, source, status, isLoading, region
      , assigned_date_from, assigned_date_to, created_at_from, created_at_to, closed_date_from, closed_date_to
      , activity_date_from, activity_date_to
      , updated_at_from, updated_at_to, booking_date_from, booking_date_to, closeReason, is_magnet, is_presale, mobileNumber
      , nationality, current_location, is_audit,closing_reasons, selectedActivity, user_group,lead_percentage, role } = this.state;
    const isAllSelected =
    internal_st.length > 0 && selectedActivity.length === internal_st.length;
    let AUDIT_FILTER = [{id:"sales", name:"sales"},{id:"presales", name:"presales"}];

    return (

      <Frag>
        <div className="container filter_lead filterMenuCard pt-3 p-0" ref={this.props.fltr}>
          <div className="row filterRow">
          {this.props.urlPath === 'audit' &&
           <div className="col-6 mb-2">
              <SelectOption
                variant="standard"
                label="Presale Audit"
                name="user_group"
                options={AUDIT_FILTER}
                value={user_group}
                onChange={this.handleChange}
              />
              </div>
            }
            <div className="col-6 mb-2">
              <GoogleMaps
                resetKey={autoKey}
                handleLocation={this.handleGeoLocation}
                autoLocation={current_location}
                value={current_location}
                label="Location"
                required={false}
                PopperComponent={PopperMy}
                className="stopClosing"
              />
            </div>
            {/*(this.state.role === 'sourcings2' || this.state.role === 'presalestl' || this.state.role === 'presalehead' || this.state.role === 'salestl' || this.state.role === 'salesmanager' || this.state.role === 'pnlhead' || this.state.role === 'saleshead') &&
              <div className="col-6 mb-2">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Assigned To</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={assigned_to}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'assigned_to',
                      id: 'assigned_to',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="myself">Myself</MenuItem>
                    <MenuItem value="myteam">MyTeam</MenuItem>
                    {(this.state.role !== 'sourcings2' && this.state.role !== 'presalestl' && this.state.role !== 'presalehead') &&
                      <MenuItem value="presale">Presales</MenuItem>
                    }
                  </Select>
                </FormControl>
              </div>
            */}
            <div className="col-6 mb-2">
              <div className="form-group">
                <TextField
                  value={this.state.ids}
                  id="ids"
                  name="ids"
                  label="Lead ID"
                  onChange={this.handleChange}
                  defaultValue=""
                  helperText=""
                />
              </div>
            </div>
            <div className="col-6 mb-2">
              <div className="form-group">
                {/* <TextField
                  value={this.state.client_number}
                  id="client_number"
                  name="client_number"
                  onChange={this.handleChange}
                  label="Mobile Number"
                  defaultValue=""

                  helperText=""
                /> */}

                <Autocomplete
                  id="client_number"
                  key={autoKey}
                  PopperComponent={PopperMy}
                  getOptionSelected={(mobileNumber, value) => mobileNumber.number === value.number}
                  getOptionLabel={mobileNumber => {
                    return objEmpty(mobileNumber) ? "" : mobileNumber.number + " (" + mobileNumber.name + ")"
                  }}
                  options={mobileNumber}
                  value={selectedValNumber}
                  loading={isLoading}
                  clearOnBlur={false}
                  onChange={(_event, mobileNumber) => {
                    if (mobileNumber === null) {
                      this.setState({
                        mobileNumber: [],
                        client_number: '',
                        selectedValNumber: {}
                      })
                    } else {
                      this.setState({
                        client_number: mobileNumber.number,
                        selectedValNumber: mobileNumber
                      })
                    }

                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Client Number"
                      fullWidth
                      onChange={this.handleAutoClientMobileChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-6 mb-3">
              <Autocomplete
                multiple
                key={autoKey}
                PopperComponent={PopperMy}
                id="client_auto"
                getOptionSelected={(client, value) => client.name === value.name}
                getOptionLabel={client => client.name + "(" + client.number + ")"}
                options={client}
                value={selectedValName}
                loading={isLoading}
                renderTags={(value, getTagProps) =>
                  value.map((client, index) => (
                    <Chip label={client.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_event, client) => {
                  var clientName = client.map((ops) => ops.id);
                  this.setState({
                    client_ids: clientName,
                    selectedValName: client
                  })

                  if (client === null) {
                    this.setState({ client: [] })
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Client Name"
                    fullWidth
                    onChange={this.handleAutoClient}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isClientLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
            <div className="col-6 mb-2">
              <div className="form-group">
                <Autocomplete
                  multiple
                  key={autoKey}
                  id="projectData"
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  options={option}
                  value={selectedValProj}
                  loading={isLoading}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(_event, option) => {
                    var projectId = option.map((ops) => ops.id);
                    this.setState({
                      project_ids: projectId,
                      selectedValProj: option
                    })

                    if (option === null) {
                      this.setState({ option: [] })
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Project Name"
                      fullWidth
                      onChange={this.handleAutoChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
                <span id="firstName-error" className="error invalid-feedback">Please enter First Name</span>
              </div>
            </div>
            <div className="col-6 mb-2">
            <Autocomplete
              multiple
              key={autoKey}
              getOptionSelected={(region, value) =>
                region.name === value.name
              }
              getOptionLabel={region => region.name}
              options={region}
              value={selectedValRegion}
              renderTags={(value, getTagProps) =>
                value.map((region, index) => (
                  <Chip
                  label={region.name}
                  {...getTagProps({ index })}
                  />
                  ))
                }
                onChange={(_event, region) => {
                  var regionId = region.map(ops => ops.id);
                  this.setState({
                    region_ids: regionId,
                    selectedValRegion: region
                  });
                  
                  if (region === null) {
                    this.setState({ region: [] });
                  }
                }}
                renderInput={params => (
                  <TextField
                  {...params}
                  label="Region"
                  fullWidth
                  onChange={this.handleAutoChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  />
                  )}
                  />
                </div>
            {closingReasonAccess.includes(this.props.urlPath) && 
            <div className="col-6 mb-2">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">Closing Reason</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  value={closing_reasons ? closing_reasons : []}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'closing_reasons',
                    id: 'closing_reasons',
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {CLOSING_REASON.map((reason, i) => (
                    <MenuItem key={i} value={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>}
            {!fieldHiddenSales.includes(role) &&
            <div className="col-6 mb-3">
              <Autocomplete
                multiple
                key={autoKey}
                id="source-demo"
                getOptionSelected={(source, value) => source.name === value.name}
                getOptionLabel={source => source.name}
                options={source}
                value={selectedValSource}
                renderTags={(value, getTagProps) =>
                  value.map((source, index) => (
                    <Chip label={source.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_event, source) => {
                  var projectId = source.map((ops) => ops.id);
                  this.setState({
                    source_ids: projectId,
                    selectedValSource: source
                  })

                  if (source === null) {
                    this.setState({ source: [] })
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Source"
                    fullWidth
                    // onChange={this.handleAutoUserChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
            }
            {this.props.urlPath !== 'audit' &&
            <div className="col-6 mb-2">
              <Autocomplete
                multiple
                key={autoKey}
                id="status-demo"
                getOptionSelected={(status, value) => status.name === value.name}
                getOptionLabel={status => status.name}
                options={status}
                value={selectedValStatus}
                renderTags={(value, getTagProps) =>
                  value.map((status, index) => (
                    <Chip label={status.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_event, status) => {
                  var projectId = status.map((ops) => ops.id);
                  this.setState({
                    status_ids: projectId,
                    selectedValStatus: status
                  })

                  if (status === null) {
                    this.setState({ status: [] })
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    fullWidth
                    // onChange={this.handleAutoUserChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
            }
            <div className="col-6 mb-3">
              <FormControl>
                <InputLabel>Activity Status</InputLabel>
                <Select
                  name="selected"
                  multiple
                  value={selectedActivity}
                  onChange={this.handleActivityChange}
                  // input={<Input />}
                  renderValue={this.getSelectValues}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? '' : ""
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: '' }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedActivity.length > 0 && selectedActivity.length < internal_st.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: '' }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {internal_st.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox checked={selectedActivity.indexOf(item.id) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <div className="col-sm-6 col-6 mb-3">
              <Autocomplete
                multiple
                id="internalStatus"
                key={autoKey}
                getOptionSelected={(internal_st, value) => internal_st.name === value.internal_status_name}
                getOptionLabel={internal_st => internal_st.name}
                options={internal_st}
                value={selectedValActivity}
                renderTags={(value, getTagProps) =>
                  value.map((internal_st, index) => (
                    <Chip label={internal_st.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_event, internal_st) => {
                  var projectId = internal_st.map((ops) => ops.id);
                  this.setState({
                    internal_status_id: projectId,
                    selectedValActivity: internal_st
                  })

                  if (option === null) {
                    this.setState({ internal_st: [] })
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Activity Status"
                    fullWidth
                    onChange={this.handleActivityStatus}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div> */}
            <div className="col-6 mb-3">
              <Autocomplete
                multiple
                key={autoKey}
                id="magnetRmName"
                getOptionSelected={(userName, value) => userName.name === value.name}
                getOptionLabel={userName => userName.name + " (" + userName.phone + ")"}
                options={userName}
                value={selectedValLeadRM}
                renderTags={(value, getTagProps) =>
                  value.map((userName, index) => (
                    <Chip label={userName.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_event, userName) => {
                  var projectId = userName.map((ops) => ops.id);
                  this.setState({
                    magnet_lead_rm: projectId,
                    selectedValLeadRM: userName
                  })

                  if (option === null) {
                    this.setState({ userName: [] })
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Lead RM"
                    fullWidth
                    onChange={this.handleAutoUserChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
            {!fieldHiddenSales.includes(role) &&
            <div className="col-6 mb-3">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">Magnet Lead</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  value={is_magnet}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'is_magnet',
                    id: 'is_magnet_status',
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            }
            {(this.state.role === 'magnetadmin' || this.state.role === 'magnetpnl' || this.state.role === "magnettl" || this.state.role === "magnetrm" || this.state.role === 'admin' || this.state.role === 'presalehead' || this.state.role === "sourcings2") &&
              <div className="col-sm-6 col-6 mb-3">
                <Autocomplete
                  multiple
                  key={autoKey}
                  id="magnetName"
                  getOptionSelected={(magnetName, value) => magnetName.name === value.name}
                  getOptionLabel={magnetName => magnetName.name + " (" + magnetName.phone + ")"}
                  options={magnetName}
                  value={selectedValMagnet}
                  renderTags={(value, getTagProps) =>
                    value.map((magnetName, index) => (
                      <Chip label={magnetName.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(_event, magnetName) => {
                    var projectId = magnetName.map((ops) => ops.id);
                    this.setState({
                      magnet_ids: projectId,
                      selectedValMagnet: magnetName
                    })

                    if (option === null) {
                      this.setState({ magnetName: [] })
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Magnet Name"
                      fullWidth
                      onChange={this.handleAutoMagnetChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </div>
            }
            <div className="col-6 mb-3">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">Presale Lead</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  value={is_presale}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'is_presale',
                    id: 'is_presale_status',
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mb-3">
              <Autocomplete
                multiple
                key={autoKey}
                id="presaleRmName"
                getOptionSelected={(presaleTl, value) => presaleTl.name === value.name}
                getOptionLabel={presaleTl => presaleTl.name + " (" + presaleTl.phone + ")"}
                options={presaleTl}
                value={selectedValPresale}
                renderTags={(value, getTagProps) =>
                  value.map((presaleTl, index) => (
                    <Chip label={presaleTl.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_event, presaleTl) => {
                  var projectId = presaleTl.map((ops) => ops.id);
                  this.setState({
                    pre_sale_rm: projectId,
                    selectedValPresale: presaleTl
                  })

                  if (option === null) {
                    this.setState({ presaleTl: [] })
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Presale RM"
                    fullWidth
                    onChange={this.handleAutoPresaleRmUser}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
            <div className="col-6 mb-2">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">Nationality</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  value={nationality}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'nationality',
                    id: 'nationality',
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Indian</MenuItem>
                  <MenuItem value="2">NRI</MenuItem>
                </Select>
              </FormControl>
            </div>
            {client_type === "close" ?
              <div className="col-6 mb-2">
                <div className="form-group">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">Close Reason</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={closeReason}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'closeReason',
                        id: 'closeReason',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Broking Advisor">Broking Advisor</MenuItem>
                      <MenuItem value="Low Budget">Low Budget</MenuItem>
                      <MenuItem value="Invalid Number">Invalid Number</MenuItem>
                      <MenuItem value="Not Enquired">Not Enquired</MenuItem>
                      <MenuItem value="Wrong Number">Wrong Number</MenuItem>
                      <MenuItem value="Already Bought">Already Bought</MenuItem>
                      <MenuItem value="Duplicate Lead">Duplicate Lead</MenuItem>
                      <MenuItem value="Budget Issue">Budget Issue</MenuItem>
                      <MenuItem value="Location Issue">Location Issue</MenuItem>
                      <MenuItem value="Layout Issue">Layout Issue</MenuItem>
                      <MenuItem value="Test Lead">Test Lead</MenuItem>
                      <MenuItem value="Possession Issue">Possession Issue</MenuItem>
                      <MenuItem value="Payment Plane">Payment Plane</MenuItem>
                      <MenuItem value="Decision Delayed">Decision Delayed</MenuItem>
                      <MenuItem value="Looking in Resale">Looking in Resale</MenuItem>
                      <MenuItem value="Not Contactable">Not Contactable</MenuItem>
                      <MenuItem value="Looking in Rental">Looking in Rental</MenuItem>
                      <MenuItem value="Looking in Commercial">Looking in Commercial</MenuItem>
                      <MenuItem value="Builder's Employee">Builder's Employee</MenuItem>
                      <MenuItem value="Not Responding (4 Weeks)">Not Responding (4 Weeks)</MenuItem>
                      <MenuItem value="Interested In Other Location">Interested In Other Location</MenuItem>
                      <MenuItem value="Interested In Other Project">Interested In Other Project</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              :
              ''
            }
            {this.props.urlPath !== "audit" &&
              <div className="col-6 mb-3">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Audit Lead</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={is_audit}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'is_audit',
                      id: 'is_audit_status',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </div>}
              {(!fieldHiddenSales.includes(role) && this.props.urlPath !== "audit") &&
              <div className="col-6 mb-3">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Magnet Percentage Lead</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={lead_percentage}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'lead_percentage',
                      id: 'lead_percentage',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="50">50 %</MenuItem>
                    <MenuItem value="60">60 %</MenuItem>
                    <MenuItem value="70">70 %</MenuItem>
                  </Select>
                </FormControl>
              </div>
              }
              {((this.props.urlPath === "booked" && (this.state.role === 'magnetadmin' || this.state.role === 'magnetpnl' || this.state.role === "magnettl" || this.state.role === "magnet s2" || this.state.role === 'admin')) || this.state.role === 'sourcings2') &&
               <div className="col-6 mb-3">
                <Autocomplete
                  multiple
                  key={autoKey}
                  id="magnetRm"
                  getOptionSelected={(magnetRmName, value) => magnetRmName.name === value.name}
                  getOptionLabel={magnetRmName => magnetRmName.name + " (" + magnetRmName.phone + ")"}
                  options={magnetRmName}
                  value={selectedValMagnetRm}
                  renderTags={(value, getTagProps) =>
                  value.map((magnetRmName, index) => (
                    <Chip label={magnetRmName.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(_event, magnetRmName) => {
                    var projectId = magnetRmName.map((ops) => ops.id);
                    this.setState({
                      magnet_rm_ids: projectId,
                      selectedValMagnetRm: magnetRmName
                    })
                    if (option === null) {
                      this.setState({ magnetRmName: [] })
                    }
                  }}
                  renderInput={params => (
                   <TextField
                    {...params}
                    label="Magnet RM"
                    fullWidth
                    onChange={this.handleAutoMagnetRmChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                   />
                  )}
                />
              </div>}
          </div>
          {this.props.urlPath === "booked" &&
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Booked Date</div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="booking_date_form"
                  variant="inline"
                  label="From"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="booking_date_from"
                  value={booking_date_from}
                  onChange={(key, date) => this.handleDateChange('booking_date_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="booking_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="booking_date_to"
                  value={booking_date_to}
                  onChange={(key, date) => this.handleDateChange('booking_date_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          }
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Assigned Date</div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  autoOk={true}
                  id="assigned_date_form"
                  variant="inline"
                  label="From"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="assigned_date_from"
                  value={assigned_date_from}
                  onChange={(key, date) => this.handleDateChange('assigned_date_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  autoOk={true}
                  id="assigned_date_to"
                  variant="inline"
                  label="to"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="assigned_date_to"
                  value={assigned_date_to}
                  onChange={(key, date) => this.handleDateChange('assigned_date_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Closed Date</div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="close_date_form"
                  variant="inline"
                  label="From"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="closed_date_from"
                  value={closed_date_from}
                  onChange={(key, date) => this.handleDateChange('closed_date_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="close_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="closed_date_to"
                  value={closed_date_to}
                  onChange={(key, date) => this.handleDateChange('closed_date_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Coming Date</div>
            <div className="col-6 mb-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="comming_date_form"
                  label="From"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="created_at_from"
                  value={created_at_from}
                  onChange={(key, date) => this.handleDateChange('created_at_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="comming_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="created_at_to"
                  value={created_at_to}
                  onChange={(key, date) => this.handleDateChange('created_at_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          {/* <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Visit Date</div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="visit_date_form"
                  label="From"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="sitevisit_date_from"
                  value={sitevisit_date_from}
                  onChange={(key, date) => this.handleDateChange('sitevisit_date_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="visit_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="sitevisit_date_to"
                  value={sitevisit_date_to}
                  onChange={(key, date) => this.handleDateChange('sitevisit_date_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div> */}
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Activity Date</div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="activity_date_from"
                  label="From"
                  variant="inline"
                  format="yyyy/MM/dd"
                  // maxDate={new Date()}
                  name="activity_date_from"
                  value={activity_date_from}
                  onChange={(key, date) => this.handleDateChange('activity_date_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="activity_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  // maxDate={new Date()}
                  name="activity_date_to"
                  value={activity_date_to}
                  onChange={(key, date) => this.handleDateChange('activity_date_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          {/* <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">HP/OP Date</div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="hpOp_date_form"
                  variant="inline"
                  label="From"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="hpop_date_from"
                  value={hpop_date_from}
                  onChange={(key, date) => this.handleDateChange('hpop_date_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="hpOp_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="hpop_date_to"
                  value={hpop_date_to}
                  onChange={(key, date) => this.handleDateChange('hpop_date_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div> */}

          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Last Updated Date</div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="lastUpdate_date_form"
                  label="From"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="updated_at_from"
                  value={updated_at_from}
                  onChange={(key, date) => this.handleDateChange('updated_at_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="lastUpdate_date_to"
                  label="to"
                  variant="inline"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="updated_at_to"
                  value={updated_at_to}
                  onChange={(key, date) => this.handleDateChange('updated_at_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        <div className="col-md-12 bgCommon">
          <div className="row">
            <div className="col-sm-6 text-right col-6 mt-3">
              <div className="form-group">
                <button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
              </div>
            </div>
            <div className="col-sm-date col-6 mt-3">
              <div className="form-group">
                <button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
              </div>
            </div>
          </div>
        </div>
      </Frag>
    );
  };
};

export default withStyles(styles)(Filter);
