import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import { auditBack } from '../../../dataParser/getListData';
import PopUpAlert from '../sweetAlert/popUpAlert';
import ReactDOM from 'react-dom';
import Loader from '../loader/loader';

const ReopenAudit = (props) => {
    const [state, setState] = React.useState({
        alertShow: false,
        alertMsg: "",
    })
    const [loading, setLoading] = React.useState(false);
    const { leadId, getList } = props;
    const handleClick = async (id) => {
        console.log(id, "leadid....");
        const res = await auditBack(id);
        setLoading(true)

        if (res.meta.status === 201) {
            setLoading(false)
            setState({
                alertShow: true,
                alertType: "success",
                alertMsg: res?.data?.message || "Sent to Audit Successfully!!!",
            });
        }
        else {
            setLoading(false)
            setState({
                alertShow: true,
                alertType: "error",
                alertMsg: res?.meta?.message || "Something went wrong!!!"
            });
        }
    }
    const handleClose = () => {
        setState({ ...state, alertShow: false })
        getList();
    }
    const { alertType, alertMsg, alertShow } = state
    return (
        <>
            <Tooltip title="Send to Audit">
                <span className="revertLead Action"
                    onClick={() => handleClick(leadId)}
                >
                    <RefreshIcon className="text-white" />
                </span>
            </Tooltip>
            <Loader show={loading} />
            <PopUpPortal type={alertType} msg={alertMsg} open={alertShow} handleClose={handleClose} />
        </>
    )
}
const PopUpPortal = (props) =>{
    if(!props.open){
        return <></>
    }
    return ReactDOM.createPortal(
        <PopUpAlert {...props}/>
        , document.getElementsByTagName('body')[0]
    )
}
export default ReopenAudit