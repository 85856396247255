import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
// import { Form } from 'react-bootstrap';
// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAutoCompleteProject } from "../../../dataParser/getProjectData";
// import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
// import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardDateTimePicker
} from '@material-ui/pickers';
import { UpdateLeadData } from "../../../dataParser/getListData";
import { AV_LIMIT_LOWER, AV_LIMIT_UPPER, AV_VALIDATION_MSG_LOWER, AV_VALIDATION_MSG_UPPER, BED_CONFIG, FOLLOWUP_DATE_TIME_FORMAT, MIN_MAX_BUDGET, STAGES } from "../../../config/constant";
import GoogleMaps from "../googleMap/googleMap.js";
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import EmailIcon from '@material-ui/icons/Email';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import GoogleMapsIntLoc from "../googleMap/googleMapIntLoc";
import { autoComplete } from "../../../dataParser/commomDataApi.js";
import moment from 'moment';
import PopUpAlert from '../sweetAlert/popUpAlert.js';
import Loader from '../loader/loader.js';
import { cleanStorage, dateTimeConverter, removeCommas } from '../../../helper';
import FollowUpControl from '../../../core/FollowUpControl.js';
import RadioButtonGroup from '../controls/RadioGroup';
import { yesNoType } from '../datas/data.js';
import InputUnitField from '../controls/InputUnitField.js';
import { leadOwnerAccess } from '../../../config/constant/crmRoleFunctionPermission.js';

const validateName = RegExp(/^[a-zA-Z ]+$/);
const validateNumber = RegExp(/^(\d{6}|\d{7})$/);

class eoiUpdateForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: '',
			number: '',
			alternate_number: '',
			email: '',
			project_id: '',
			project: this.props.leadDetail.data.Project,
			option: [],
			alternetProject: [],
			alternate_project_id: '',
			selectedVal: {},
			property_for: '',
			max_budget: '',
			min_budget: '',
			required_bhk: '',
			current_location: '',
			// closing_reason :'',
			interested_location: '',
			agreement_value: '',
			comment: '',
			nationality: null,
			followup_date: null,
			isError: false,
			isLoading: false,
			userName: [],
			sweetShow: false,
			type: "success",
			title: "",
			errors: false
			, showLoader: false
			, alertShow: false
			, loan_required: 0
		};
	}

	async componentDidMount() {
		var userDataArr = [{ name: this.props.leadDetail.data?.AlternateProject?.name, id: this.props.leadDetail.data?.AlternateProject?.id }]

		this.setState({
			leadDetail: this.props.leadDetail.data,
			name: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.name,
			number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.number,
			alternate_number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.alternate_number,
			email: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.email,
			lead_status_id: STAGES.GROSS_EOI,
			property_for: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.property_for || '',
			min_budget: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.min_budget || '',
			max_budget: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.max_budget || '',
			required_bhk: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.required_bhk || '',
			interested_location: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.interested_location || '',
			agreement_value: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.agreement_value || '',
			nationality: this.props.clientData.data.nationality || null,
			current_location: this.props.clientData.data.current_location || '',
			project_id: this.props.leadDetail.data.project_id,
			followup_date: this.props.leadDetail.data.InternalStatusHistory?.followup_date ? moment(this.props.leadDetail.data.InternalStatusHistory?.followup_date).format(FOLLOWUP_DATE_TIME_FORMAT) : null,
			alternetProject: userDataArr[0].name !== undefined ? userDataArr : [],
			selectedVal: userDataArr[0].name !== undefined ? userDataArr[0] : {},
			comment: this.props.leadDetail.data.ObjectStage !==null && this.props.leadDetail.data.ObjectStage.comments || '',
			comment_added: this.props.leadDetail.data.ObjectStage !==null ? this.props.leadDetail.data.ObjectStage.comments : '',
			// nationality : this.props.leadDetail.data.Client.nationality,
			// lead_status_id : this.props.leadDetail.data.ObjectStage.stage_id,
			loan_required: this.props.leadDetail.data.loan_required ? 1 : 0,
		});

		// (async () => {
		//        try {
		// 		let resData = await getClientData(this.props.leadDetail.data.Client.id);
		//      console.log("!!!!!!!!!!!!!!!!!!!!!!",resData)
		//      await this.setState({
		//      	nationality : resData.data.nationality|| null,
		//      	current_location : resData.data.current_location|| '',
		//      }) ;
		//      // await this.setState({
		//      // }) ;
		//        } catch (e) {	
		//            //...handle the error...
		//        }
		//    })();
	}

	handleAutoChange = async (e) => {
		this.setState({ isLoading: true });
		let value = e.target.value

		var projectListData = await getAutoCompleteProject(value);

		if (projectListData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData.data })
		} else if (projectListData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData })
		}

	}


	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			q: e.target.value,
		};
		let resData = await autoComplete('users', data);

		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	updateLeadFrom = async (e) => {

		e.preventDefault();
		let updateData = {};
		updateData.Client = {};
		updateData.Client.ClientBudgetInfo = {};
		updateData.ObjectStage = {};

		if (this.state.max_budget !== '') updateData.Client.ClientBudgetInfo["max_budget"] = this.state.max_budget;
		if (this.state.min_budget !== '') updateData.Client.ClientBudgetInfo["min_budget"] = this.state.min_budget;
		if (this.state.required_bhk !== '') updateData.Client.ClientBudgetInfo["required_bhk"] = this.state.required_bhk;
		if (this.state.property_for !== '') updateData.Client.ClientBudgetInfo["property_for"] = this.state.property_for;
		if (this.state.nationality !== null) updateData.Client["nationality"] = this.state.nationality;
		if (this.state.current_location !== '') updateData.Client["current_location"] = this.state.current_location;
		if (this.state.interested_location !== '') updateData.Client.ClientBudgetInfo["interested_location"] = this.state.interested_location;
		if (this.state.agreement_value !== '') updateData.Client.ClientBudgetInfo["agreement_value"] = removeCommas(this.state.agreement_value);
		if (this.state.name !== '') updateData.Client["name"] = this.state.name;
		if (this.state.number !== '') updateData.Client["number"] = this.state.number;
		if (this.state.alternate_number !== '') updateData.Client["alternate_number"] = this.state.alternate_number;
		if (this.state.email !== '') updateData.Client["email"] = this.state.email;
		if (this.state.comment !== '') updateData.ObjectStage["comment"] = this.state.comment;
		// if(this.state.closing_reason !== '') updateData["closing_reason"] = this.state.closing_reason;
		updateData.ObjectStage['stage_id'] = STAGES.GROSS_EOI;
		if (this.state.owner_id !== '') updateData.owner_id = this.state.owner_id;
		if (this.state.followup_date !== null) updateData.followup_date = dateTimeConverter(this.state.followup_date);
		if (this.state.project_id !== '') updateData['project_id'] = this.state.project_id;
		if (this.state.alternate_project_id !== '') updateData['alternate_project_id'] = this.state.alternate_project_id;
		updateData["loan_required"] = parseInt(this.state.loan_required) ? 1 : 0;



		// console.log("addLeadRequest XXXXXXXXXXXX",updateData)


		// upadateLeadReq.id = leadId;
		// upadateLeadReq = updateData;
		const {agreement_value, followup_date, number, name, current_location, property_for, interested_location, comment} = this.state
		if (agreement_value && followup_date !== null && number !== "" && name !== '' && current_location !== '' && property_for !== '' && interested_location !== '' && comment !== '') {
			if(removeCommas(agreement_value) < AV_LIMIT_LOWER){
				this.setState({
					alertShow: true,
					alertType: "error",
					alertMsg: AV_VALIDATION_MSG_LOWER
				})
				return false
			}
			else if(removeCommas(agreement_value) > AV_LIMIT_UPPER){
				this.setState({
					alertShow: true,
					alertType: "error",
					alertMsg: AV_VALIDATION_MSG_UPPER
				})
				return false
			}
			this.setState({showLoader: true})
			let addLeadRes = await UpdateLeadData(this.state.leadDetail.id, updateData);
			// console.log("addLeadRes XXXXXXXXXXXX",addLeadRes);

			if (addLeadRes.meta.status === 201) {

				// console.log("Lead insert successfully !!!");

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Lead Updated Successfully!!!"

				});

			} else if (addLeadRes.meta.status === 409) {
				this.setState({
					showLoader: false,
					alertShow: true,
					alertType: "warning",
					alertMsg: addLeadRes.meta.message

				});

			}else if (addLeadRes.meta.status === 401) {
				this.setState({showLoader: false,})
				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: addLeadRes.meta.message
				});
			}
		} else {
			this.setState({
				alertShow: true,
				alertType: "error",
				alertMsg: "Above all * fields are reqiured !!!"
			});
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	handleNumberChange = (e) => {
		let {name, value, validity: {valid}} = e.target;
		if (valid || value === ""){
			this.setState({ [name]: value });
		}
	};	

	handleGeoLocation = async (current_location) => {

		// console.log("<=============in the current_location============>",current_location);
		this.setState({ current_location: current_location });
	}

	handleGeoLocationIntrest = async (interested_location) => {

		// console.log("<=============in the interested_location============>",interested_location);
		this.setState({ interested_location: interested_location });
	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();

		// window.location.reload();
	}

	AutoChangeProject = async (e) => {
		this.setState({ isLoading: true });
		// let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				await this.setState({
					isLoading: false,
					alternetProject: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}
	handleAlertClose = () =>{
		this.setState({ alertShow: false })
	}
	render() {
		const { sweetShow, type, title, alternetProject, min_budget, max_budget, selectedVal, required_bhk, nationality, closing_reason, isLoading, userName, followup_date, option 
			,showLoader , alertShow, alertType,alertMsg, loan_required, agreement_value } = this.state;

		// const {sweetShow, type, title,sitevisit_date,lead_sattus,followup_date,property_type,booking_date} = this.state;
		// console.log("in the render", this.state);

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<PopUpAlert open={alertShow} type={alertType} msg={alertMsg} hideTime={6000} handleClose={this.handleAlertClose}/>
					<Loader show={showLoader} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											required
											id="name"
											name="name"
											label="Client Name"
											value={this.state.name ? this.state.name : ''}
											helperText={this.state.isError.name !== '' ? this.state.isError.name : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircle />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										required
										disabled
										id="number"
										name="number"
										value={this.state.number ? this.state.number : ''}
										label="Client Number"
										helperText={this.state.isError.number !== '' ? this.state.isError.number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											// required
											id="email"
											name="email"
											label="Client Email"
											value={this.state.email ? this.state.email : ''}
											// helperText={this.state.isError.email !== '' ? this.state.isError.email : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										id="alternate_number"
										name="alternate_number"
										value={this.state.alternate_number ? this.state.alternate_number : ''}
										label="Alternate Number"
										helperText={this.state.isError.alternate_number !== '' ? this.state.isError.alternate_number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
							</div>
							<div className="row">
								{this.props.leadDetail.data.source_id === 13 ?
									<div className="col-sm-6 project_name">
										<div className="form-group">
											<Autocomplete
												id="projectData"
												getOptionSelected={(option, value) => option.name === value.name}
												getOptionLabel={option => option.name}
												options={option}
												loading={isLoading}
												value={this.state.project}
												onChange={(_event, option) => {
													if (option === null) {
														this.setState({
															option: [],
															project_id: '',
														})
													} else {
														var projectId = option.id;
														this.setState({
															project_id: projectId,
															project: option
														})
													}

												}}
												renderInput={params => (
													<TextField
														{...params}
														label="Project Name"
														reqiured
														fullWidth
														onChange={this.handleAutoChange}
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{isLoading ? (
																		<CircularProgress color="inherit" size={20} />
																	) : null}
																	{params.InputProps.endAdornment}
																</React.Fragment>
															)
														}}
													/>
												)}
											/>
										</div>
									</div> : ''}
								<div className="col-sm-6">
									<div className="form-group">
										<GoogleMaps handleLocation={this.handleGeoLocation} autoLocation={this.state.current_location} errors={this.state.errors.current_location} />
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Bed Config</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={required_bhk}
											onChange={this.onChange}
											inputProps={{
												name: 'required_bhk',
												id: 'required_bhk',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{BED_CONFIG.map(item => (
												<MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                              				))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<GoogleMapsIntLoc handleLocation={this.handleGeoLocationIntrest} autoLocation={this.state.interested_location} errors={this.state.errors.interested_location} />
									</div>
								</div>
								<div className="col-sm-6">
									<RadioButtonGroup
										name="loan_required"
										value={loan_required}
										label="Loan Required"
										onChange={this.onChange}
										items={yesNoType}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend">Nationality</FormLabel>
										<RadioGroup row aria-label="nationality" name="nationality" value={nationality} onChange={this.onChange}>
											<FormControlLabel value="1" control={<Radio color="primary" />} label="Indian" />
											<FormControlLabel value="2" control={<Radio color="primary" />} label="NRI" />
										</RadioGroup>
									</div>
								</div>
								{leadOwnerAccess.includes(window.$role) ?
									<div className="col-sm-6 mb-3">
										<Autocomplete
											id="UserData"
											getOptionSelected={(userName, value) => userName.name === value.name}
											getOptionLabel={userName => userName.name}
											options={userName}
											loading={isLoading}
											onChange={(_event, userName) => {
												if (userName === null) {
													this.setState({
														userName: [],
														owner_id: '',
													})
												} else {
													this.setState({
														owner_id: userName.id,
													})
												}

											}}
											renderInput={params => (
												<TextField
													{...params}
													label="Lead Owner"
													fullWidth
													onChange={this.handleAutoUserChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{isLoading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
									:
									''
								}
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<FollowUpControl
												disabled={this.state.lead_status_id === this.props.selectedFormId ? true : false}
												margin="normal"
												name="followup_date"
												value={followup_date}
												onChange={(date) => this.handleDateChange('followup_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Min budget</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={min_budget}
											onChange={this.onChange}
											inputProps={{
												name: 'min_budget',
												id: 'min_budget',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{MIN_MAX_BUDGET.map(item=>(
												<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Max budget</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={max_budget}
											onChange={this.onChange}
											inputProps={{
												name: 'max_budget',
												id: 'max_budget',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{MIN_MAX_BUDGET.map(item=>(
												<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="col-md-6 mb-3">
									<InputUnitField 
										name="agreement_value" 
										value={agreement_value} 
										required 
										label="Agreement Value" 
										onChange={this.handleNumberChange}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(alternetProject, value) => alternetProject.name === value.name}
										getOptionLabel={alternetProject => alternetProject.name}
										options={alternetProject}
										value={selectedVal}
										loading={isLoading}
										onChange={(_event, alternetProject) => {
											if (alternetProject === null) {
												this.setState({ alternetProject: [] })
											} else {
												this.setState({
													alternet_project_name: alternetProject.name,
													alternate_project_id: alternetProject.id,
													selectedVal: { name: alternetProject.name, id: alternetProject.id }
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												label="Alternate Project"
												fullWidth
												onChange={this.AutoChangeProject}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											required
											select
											id="property_for"
											name="property_for"
											value={this.state.property_for ? this.state.property_for : ''}
											label="Property For"
											onChange={this.onChange}
										>
											<MenuItem value="Self">Self</MenuItem>
											<MenuItem value="Investment">Investment</MenuItem>
										</TextField>
									</div>
								</div>
								<div className="col-sm-12 col-12">
									<div className="form-group">
										<TextField
											disabled={(this.state.lead_status_id === this.props.selectedFormId && this.state.comment_added) ? true : false}
											id="standard-multiline-flexible"
											required
											label="Comments"
											name="comment"
											value={this.state.comment}
											// helpertext={this.state.isError.comment !== '' ? this.state.isError.comment : ''}
											multiline
											rowsMax="4"
											onChange={this.onChange}
										/>
									</div>
								</div>

							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>Submit</button>
									</div>
								</div>
								<div className="col-md-12 text-center mt-2">
									{this.state.errors === true &&
										<span className="text-danger">Above all * fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(eoiUpdateForm);
