import React, {Component} from 'react'
import IvrManagement from '../../component/IvrManagement/IvrManagement'

class IvrManagementPage extends Component {

	constructor(props){
		super(props)
		this.state={
		}
	}

	render(){
	    return (
			<div
				className={`content-wrapper ${
				this.props.sideBar ? "main-header-collapsed" : ""
				}`}
				>
				<IvrManagement history={this.props.history}/>
	        </div>
	    )
	}
}

export default IvrManagementPage

