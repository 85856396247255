import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Frag from "../../../utils/Frag/Frag.js";
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import CircularProgress from "@material-ui/core/CircularProgress";
import { assignClosedLead } from "../../../dataParser/getListData";
import { autoComplete,  } from "../../../dataParser/commomDataApi";
import Loader from "../../../component/common/loader/loader";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { REVERT_ROLES } from "../../../config/constant/index.js";
import "./form.css";
import ButtonSubmit from '../controls/ButtonSubmit'
import { cleanStorage } from '../../../helper';

class AssignForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rmName:[],
            assign_rm_id: null,
            sweetShow: false,
            type: "success",
            title: "",
            isError: false
        }
    }

    componentDidMount() {
        this.setState({ group: window.$user.group.toLowerCase(),})
    }

    handleReopen = async (e) => {

        if (this.state.assign_rm_id !== '' && this.state.assign_rm_id !== null) {

            this.setState({ showLoader: true });

            let data = {}
            data["assign_rm_id"] = this.state.assign_rm_id
            let id = this.props.leadId

            var res = await assignClosedLead(id, data);

            if (res.meta.status === 201) {
                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "success",
                    // title: "Re-Open Assigned successfully !!!",
                    title: res.data.message,
                });
            }
            // else if (res.meta.status === 422) {
            //     this.setState({
            //         showLoader: false,
            //         sweetShow: true,
            //         type: "warning",
            //         title: res.meta.message,
            //     });
            // }
            // else if (res.meta.status === 404) {
            //     this.setState({
            //         showLoader: false,
            //         sweetShow: true,
            //         type: "warning",
            //         title: res.meta.message,
            //     });
            // }
            else {
                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "error",
                    title: res.meta.message
                });
            }
        } 
    }

    handleAutoChange = async (e) => {

        this.setState({ isLoading: true });

        let data = {};
        data.q = e.target.value;
        data.roles = [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.SALES_MANAGER ,REVERT_ROLES.SOURCING_S2 ,REVERT_ROLES.SOURCING_S3 ,REVERT_ROLES.MAGNET_TL, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD
        , REVERT_ROLES.MAGNET_S2, REVERT_ROLES.MAGNET_S3]

        let resData = await autoComplete('users', data);

        if (resData.meta.status === 200) {
            this.setState({ isLoading: false, rmName: resData.data });
        } 

        else if (resData.meta.status === 401) {
            cleanStorage();
            this.props.history.push("/login");

        } 

        else {
            this.setState({ isLoading: false, rmName: resData.data });
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

	handleSweet=()=>{
        this.setState({sweetShow : !this.state.sweetShow});
    	this.props.changeModal()
	    this.props.getList();
    }

    render() {
        const { sweetShow, type, title, rmName, isLoading } = this.state;

        return (
            <Frag>
                <div className="formContainer">
                    <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
                    <Loader show={this.state.showLoader} />
                    <form role="form">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <FormControl>
                                        <Autocomplete
                                            id="asynchronous-demo"
                                            getOptionSelected={(rmName, value) => rmName.name === value.name}
                                            getOptionLabel={rmName => rmName.name + " (" + rmName.phone + ")"}
                                            options={rmName}
                                            loading={isLoading}
                                            onChange={(_event, rmName) => {
                                                if (rmName === null) {
                                                    this.setState({ rmName: [] })
                                                } else {
                                                    this.setState({
                                                        assign_rm_id: rmName.id,
                                                    })
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label="RM Name"
                                                    fullWidth
                                                    onChange={this.handleAutoChange}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <BusinessRoundedIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {isLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <ButtonSubmit
                                    onClick={this.handleReopen}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Frag>
        );
    };
};

export default withRouter(AssignForm);
