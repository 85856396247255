import React from 'react';
import {Link} from 'react-router-dom';
import Portal from '@material-ui/core/Portal';

import Box from '../../core/Box';
import Grid from '../../core/Grid';
import Button from '../../core/Button';
import TextField, {FormikTextField} from '../../core/TextField';
import Typography from '../../core/Typography';
import { Formik, Form } from 'formik';

/*
Digital:{
 "user_device":"",
 "user_browser":"",
 "campaign_type":"",
 "launch_name":"",
 "client_ipaddress":"",
 "client_pref":""
},
Utm:{
 "utm_medium":"",
 "utm_source":"",
 "utm_term":"",
 "utm_content":"",
 "utm_campaign":"",
 "utm_type":""
}
*/


const defaultState = {
}

const MarketingPage = ({lead}) => {
    const [state, setState] = React.useState({...defaultState, lead});

    const enableForm = (e) =>{
        e.preventDefault();
        setState({...state, isEditable: true})
    }

    React.useEffect(() => {
        setState({...state, lead})
    }, [lead])

    const save = () =>{
        setState({...state, isEditable: false})
    }

    return <Box className="MarketingPage">
       <Box pl={2}><Typography variant="h5">Marketing</Typography></Box>
        <Grid container spacing={4}>
            <Grid item sm={4} xs={12}>
                <Box mt={3}> <TextField label="Campaign Type" name="campaign_type" value={state.lead.Digital?.campaign_type} /> </Box>
                <Box mt={3}> <TextField label="IP Address" name="ip_address" value={state.lead.Digital?.client_ipaddress} isIPAddress /> </Box>
                <Box mt={3}> <TextField label="Utm Source" name="utm_source" value={state.lead.Utm?.utm_source} isUtmSource /> </Box>
                <Box mt={3}> <TextField label="Utm Content" name="utm_content" value={state.lead.Utm?.utm_content} isUtmContent /> </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Box mt={3}> <TextField label="Source" name="source" value={state.lead.LeadSource?.name} /> </Box>
                <Box mt={3}> <TextField label="Browser" name="browser" value={state.lead.Digital?.user_browser} isBrowser /> </Box>
                <Box mt={3}> <TextField label="Utm Term" name="utm_term" value={state.lead.Utm?.utm_term} isUtmTerm /> </Box>
                <Box mt={3}> <TextField label="Utm Campaign" name="utm_campaign" value={state.lead.Utm?.utm_campaign} isUtmCampaign /> </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Box mt={3}> <TextField label="Preference" name="preference" value={state.lead.Digital?.client_pref} /> </Box>
                <Box mt={3}> <TextField label="Device" name="device" value={state.lead.Digital?.user_device} /> </Box>
                <Box mt={3}> <TextField label="Utm Medium" name="utm_medium" value={state.lead.Utm?.utm_medium}/> </Box>
            </Grid>
        </Grid>
    </Box>
}


export default MarketingPage;
