import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import Refer from "../../component/referCompo/refer.js";

class ReferPage extends Component {

	constructor(props){
		super(props);
		this.state = {
      		sideBar: false,
      		mobileSideBar: false
    	};
	}

	changeButtonState(event) {
	    this.setState({sideBar: !this.state.sideBar});
	    document.body.classList.add('sidebar-enable');
	}

	changeSideBarState(event) {
	    this.setState({mobileSideBar: !this.state.mobileSideBar})
	}

	render(){
		return( 
			<div>
				<Refer expand ={this.props.sideBar} />
			</div>	
		);
	};
};	
                                                     
export default withRouter(ReferPage);	