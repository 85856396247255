import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
// import { withStyles } from "@material-ui/core/styles";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
// import {getUserRegisterData} from "../../dataParser/magnetUser"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { FilePond } from "react-filepond";
import FormHelperText from '@material-ui/core/FormHelperText';
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Loader from "../../component/common/loader/loader";
import {getUserInfoData} from "../../dataParser/magnetUser";

// const validateName = RegExp(/^[a-zA-Z ]+$/);
// const validateNumber = RegExp(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/);

class SecondStep extends Component {

    constructor(props){
        super(props);
        this.state = {
            // isLoading : false,
            showLoader : false,
            sweetShow:false,
            type : "success",
            title : "",
            user_details_id : '',
            own_state_business:'',
            have_team :'',
            listproperties :'',
            total_experience:'',
            devote_time : '',
            user_picture:[],
            plateformpresentin:[],
            genrateleads : [],
            operate_business : [],
            alternate_business_us : [],
            deal_business_types : [],
            errors: {                
                       total_experience:'',
                       have_team : '',
                   },
            isError : false       
        }
    }

    componentDidMount(){
        let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
        if(homesfy_lg && homesfy_lg !== ""){
            let user = JSON.parse(homesfy_lg);
            this.setState({
                user_details_id: user.id,
            });
        }
    }

    handleSubmit = async (e) =>{

        // e.preventDefault();

       const addUserRequest = (({user_details_id,user_picture,alternate_business_us,listproperties,own_state_business,plateformpresentin,genrateleads,operate_location,have_team,total_experience,devote_time,operate_business,deal_business_types}) => ({
            user_details_id,
            own_state_business,
            have_team,
            user_picture,
            genrateleads,
            devote_time,
            listproperties,
            total_experience,
            plateformpresentin,
            operate_business,
            operate_location,
            alternate_business_us,
            deal_business_types
       }))(this.state);
       let addUserData = {};
       addUserData = {};
       let mainData = {};

        if(addUserRequest.own_state_business != ""){
            addUserData.own_state_business = parseInt(addUserRequest.own_state_business);             
        }

        if(addUserRequest.have_team != ""){
            addUserData.have_team = parseInt(addUserRequest.have_team);             
        }

        if(addUserRequest.total_experience != ""){
            addUserData.total_experience = parseFloat(addUserRequest.total_experience);             
        }

        if(addUserRequest.listproperties != ""){
            addUserData.listproperties = parseFloat(addUserRequest.listproperties);             
        }

        if(addUserRequest.devote_time != ""){
            addUserData.devote_time = addUserRequest.devote_time;             
        }

        if(addUserRequest.operate_location != ""){
            addUserData.operate_location = addUserRequest.operate_location;             
        }

        if(addUserRequest.operate_business != ""){
            addUserData.operate_business = addUserRequest.operate_business;             
        }

        if(addUserRequest.genrateleads.length > 0){
            addUserData.genrateleads = addUserRequest.genrateleads;             
        }

        if(addUserRequest.deal_business_types.length > 0){
            addUserData.deal_business_types = addUserRequest.deal_business_types;             
        }

        if(addUserRequest.plateformpresentin.length > 0){
            addUserData.plateformpresentin = addUserRequest.plateformpresentin;             
        }

        if(addUserRequest.alternate_business_us.length > 0){
            addUserData.alternate_business_us = addUserRequest.alternate_business_us;             
        }
        addUserData.reg_stage = 1;             


        var temp ={data :JSON.stringify(addUserData)}

        if(addUserRequest.user_picture != ''){
            temp.user_picture = addUserRequest.user_picture[0];             
        }
        
       mainData.data = temp;
       mainData.id = addUserRequest.user_details_id;

        // console.log("======>",mainData);

       if(addUserRequest.own_state_business != "" && addUserRequest.plateformpresentin.length > 0 && addUserRequest.genrateleads.length > 0 && addUserRequest.deal_business_types.length > 0 && addUserRequest.operate_location != "") {
            this.setState({showLoader:true});
           var addLeadRes = await getUserInfoData(mainData);

           if(addLeadRes.meta.status === 503){
               
                this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "error",    
                   title : "!!! Oooops Network Error..." 
                });
               
           }else if(addLeadRes.meta.status === 200){
               
                this.setState({showLoader:false});

                this.props.handleNext();
           }else{
               
               this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "error",
                   title : "Something went wrong !!!" 
                });
                console.log(addLeadRes.meta.message); 
           }
       }else{

            this.setState({isError : true});

           if(this.state.own_state_business == ""){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       own_state_business:"*please enter select Exprience"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       own_state_business:""
                   }
               }))
           }
           
           if(addUserRequest.plateformpresentin.length > 0){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       plateformpresentin:"*please select have_team"
                   }
               }))
           }else{

              this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       plateformpresentin:""
                   }
               }))
           }
           
           if(addUserRequest.genrateleads.length > 0){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       genrateleads:"*please select opratinal bussiness"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       genrateleads:""
                   }
               }))
           }
       }
    }

    handleInit() {
        // console.log("FilePond instance has initialised", this.pond);
    }

    handleSweet=(type)=>{
        this.setState({sweetShow : !this.state.sweetShow});
    }

    onChange = (f) => {

        this.setState({ [f.target.name]: f.target.value });
     }

    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value});
    } 

    handleMultiSelect=(e)=>{
      this.setState({[e.target.name]: e.target.value})
    }

    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }
    render()  {
        const {sweetShow,type,title}=this.state;

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        };

        const primary = [
            { title: 'New Launches', id:"1"},
            { title: 'Under Construction', id:"2"},
            { title: 'Resale', id:"3"},
            { title: 'RTMI', id:"4"},
            { title: 'Rental', id:"5"},
        ]

        const plateform = [
            { title: 'Website', id:"1" },
            { title: 'Facebook', id:"2" },
            { title: 'Instagram', id:"3" },
            { title: 'LinkedIn.', id:"4" },
            { title: 'Twitter', id:"5" },
            { title: 'Others', id:"6" },
        ]

        const Leads = [
            { title: 'Reference', id:"1" },
            { title: 'Purchase Data', id:"2" },
            { title: 'Digital Leads', id:"3" },
        ]

        const Alternate_business = [
            { title: 'Resale', id:"1" },
            { title: 'RTMI', id:"2" },
            { title: 'Rental', id:"3" },
        ]

        // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        // const checkedIcon = <CheckBoxIcon fontSize="small" />;

        
        return (

          <React.Fragment>
          <Container maxWidth="lg">
            <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
            <Loader show={this.state.showLoader}/>
            <div className="row">
                <form className="w-100">
                    <div className="row mb-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12">
                                <FormLabel component="legend">Do you Currently own a real estate business?<span className="Astrik">*</span></FormLabel> 
                            </div>
                            <RadioGroup row aria-label="" name="own_state_business"  onChange={this.onChange}>
                                <div className="col-6">
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="YES" />
                                </div>
                                <div className="col-6">
                                    <FormControlLabel value="0" control={<Radio color="primary" />} label="NO" />
                                </div> 
                            </RadioGroup> 
                        </div>
                        <div className="col-md-6 mb-3">
                            <FormControl>
                              <InputLabel id="primary-business">Type of Real Estate Business You deal In*</InputLabel>
                              <Select
                                labelId="primary-business"
                                id="demo-mutiple-checkbox"
                                multiple
                                name="deal_business_types"
                                value={this.state.deal_business_types}
                                onChange={this.handleMultiSelect}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={selected => (
                                  <div>
                                    {selected.map(value => (
                                      <Chip key={value} label={value}/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {primary.map(name => (
                                  <MenuItem key={name.id} value={name.title}>
                                    <Checkbox checked={this.state.deal_business_types.indexOf(name.title) > -1} />
                                    <ListItemText primary={name.title} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <TextField
                                        required
                                        id="operate_location"
                                        name="operate_location"
                                        label="Location/Areas – You Operate in"
                                        onChange={this.onChange}
                                      /> 
                                </div> 
                            {this.state.own_state_business == 1 && 
                                <div className="col-md-12 mb-3">
                                    <TextField id="standard-basic"
                                        value={this.state.total_experience}
                                        id="total_experience"
                                        name="total_experience"
                                        type="number"
                                        onChange={this.onChange} 
                                        label = "Total Experience In Years"
                                     />
                                </div> 
                            }    
                            </div>  
                        </div>  
                        {this.state.own_state_business == 1 && 
                            <div className="col-md-6 mb-3 p-0">
                                <div className="col-md-12">
                                    <FormLabel component="legend">How do you operate your business?<span className="Astrik">*</span></FormLabel> 
                                </div>
                                <RadioGroup row aria-label="" className="W-100" name="operate_business"  onChange={this.onChange}>
                                    <div className=" col-6">
                                        <FormControlLabel value="Sole Prop" control={<Radio color="primary"/>} label="Sole Prop" />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel value="Partnership" control={<Radio color="primary" />} label="Partnership" />
                                    </div> 
                                    <div className="col-6">
                                        <FormControlLabel value="Pvt Ltd" control={<Radio color="primary" />} label="Pvt Ltd" />
                                    </div> 
                                    <div className="col-6">
                                        <FormControlLabel value="Public Ltd" control={<Radio color="primary" />} label="Public Ltd" />
                                    </div> 
                                    <div className="col-6">
                                        <FormControlLabel value="others" control={<Radio color="primary" />} label="others" />
                                    </div> 
                                </RadioGroup> 
                            </div>
                         } 
                         <div className="col-md-6">
                            <FormControl error={this.state.errors.user_picture !== '' && 'error'}>
                             <FilePond
                                ref={ref => (this.pond = ref)}
                                user_picture={this.state.user_picture}
                                labelIdle='Drag & Drop your Photo or <span class="filepond--label-action">Browse</span>'
                                allowMultiple={false}
                                 server={{
                                      process: (fieldName, file, metadata, load) => {
                                          setTimeout(() => {
                                              load(Date.now())
                                          }, 1500);
                                      },
                                      load: (source, load) => {
                                          fetch(source).then(res => res.blob()).then(load);
                                      }
                                  }}
                                oninit={() => this.handleInit()}
                                onupdatefiles={fileItems => {
                                  this.setState({
                                    user_picture: fileItems.map(fileItem => fileItem.file)
                                  });
                                }}
                              />
                              {this.state.errors.user_picture !== '' &&
                                  <FormHelperText>{this.state.errors.user_picture}</FormHelperText>
                              } 
                            </FormControl>
                          </div>  
                    </div>  
                    {this.state.own_state_business == 1 && 
                        <div className="row mb-3">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12">
                                    <FormLabel component="legend">Do you Devote full time to business? <span className="Astrik">*</span></FormLabel> 
                                </div>
                                <RadioGroup row aria-label="" className="W-100" name="devote_time"  onChange={this.onChange}>
                                    <div className=" col-6">
                                        <FormControlLabel value="Full Time" control={<Radio color="primary"/>} label="Full Time" />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel value="Part Time" control={<Radio color="primary" />} label="Part Time" />
                                    </div> 
                                </RadioGroup> 
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12">
                                    <FormLabel component="legend">Do you have a team ? <span className="Astrik">*</span></FormLabel> 
                                </div>
                                <RadioGroup row aria-label="" className="W-100" name="have_team"  onChange={this.onChange}>
                                    <div className=" col-6">
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Yes" />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </div> 
                                </RadioGroup> 
                            </div>
                        </div>
                    }
                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <FormControl>
                              <InputLabel id="secondary-business">What Platform are you present in?</InputLabel>
                              <Select
                                labelId="operate-business"
                                id="operate-checkbox"
                                multiple
                                name="plateformpresentin"
                                value={this.state.plateformpresentin}
                                onChange={this.handleMultiSelect}
                                input={<Input id="plateform-multiple-chip" />}
                                renderValue={selected => (
                                  <div>
                                    {selected.map(value => (
                                      <Chip key={value} label={value}/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {plateform.map(name => (
                                  <MenuItem key={name.id} value={name.title}>
                                    <Checkbox checked={this.state.plateformpresentin.indexOf(name.title) > -1} />
                                    <ListItemText primary={name.title} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl>
                              <InputLabel id="generate-business">How do you Generate Leads ?</InputLabel>
                              <Select
                                labelId="generate-business"
                                id="generate-checkbox"
                                multiple
                                name="genrateleads"
                                value={this.state.genrateleads}
                                onChange={this.handleMultiSelect}
                                input={<Input id="generate-multiple-chip" />}
                                renderValue={selected => (
                                  <div>
                                    {selected.map(value => (
                                      <Chip key={value} label={value}/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {Leads.map(name => (
                                  <MenuItem key={name.id} value={name.title}>
                                    <Checkbox checked={this.state.genrateleads.indexOf(name.title) > -1} />
                                    <ListItemText primary={name.title} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>    
                        </div>
                   </div>
                    {this.state.own_state_business == 1 && 
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <FormControl>
                                  <InputLabel id="Alternate-business">Alternate business with us</InputLabel>
                                  <Select
                                    labelId="Alternate-business"
                                    id="Alternate-checkbox"
                                    multiple
                                    name="alternate_business_us"
                                    value={this.state.alternate_business_us}
                                    onChange={this.handleMultiSelect}
                                    input={<Input id="Alternate-multiple-chip" />}
                                    renderValue={selected => (
                                      <div>
                                        {selected.map(value => (
                                          <Chip key={value} label={value}/>
                                        ))}
                                      </div>
                                    )}
                                    MenuProps={MenuProps}
                                  >
                                    {Alternate_business.map(name => (
                                      <MenuItem key={name.id} value={name.title}>
                                        <Checkbox checked={this.state.alternate_business_us.indexOf(name.title) > -1} />
                                        <ListItemText primary={name.title} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>  
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-12">
                                    <FormLabel component="legend">Would you be interested in Listing of Properties?<span className="Astrik">*</span></FormLabel> 
                                </div>
                                <RadioGroup row aria-label="" name="listproperties"  onChange={this.onChange}>
                                    <div className="col-6">
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="YES" />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="NO" />
                                    </div> 
                                </RadioGroup> 
                            </div>
                        </div>
                    }    
                   <div className="row">
                        <div className="col-md-12 text-center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </div>
                        {this.state.isError === true &&
                            <div className="col-md-12 text-center mt-2">
                                <span className="text-danger">Above all fields are mandatory, Please fill the details*</span>
                            </div>
                        }
                   </div>
                </form>
            </div>
          </Container>
        </React.Fragment>

        );

    }
}

export default SecondStep;