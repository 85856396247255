import React from 'react'
import PopUpAlert from '../sweetAlert/popUpAlert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { updateIvr } from '../../../dataParser/getIvrData';
import Loader from '../loader/loader';

const DeleteAction = (props) => {
    const { listData, getAllList } = props;
    const [showLoader, setLoader] = React.useState(false)

    const [alertState, setAlertState] = React.useState({
        alertShow: false,
        alertMsg: ""
    })

    const { alertShow, alertType, alertMsg } = alertState;

    const handleResponse = (res, action) => {
        if (res.meta.status === 201) {
            setAlertState({
                alertShow: true,
                alertType: "success",
                alertMsg: `IVR ${action} successfully !!!`,
            })
        }
        else {
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: res.meta.message
            });
        }
        setLoader(false)
    }
    const handleDelete = async (data) => {
        let mainData = {};
        mainData["project_id"] = data?.Project?.id;
        mainData["deleted_at"] = new Date();
        setLoader(true);

        const res = await updateIvr(data?.id, mainData);
        handleResponse(res, "Deleted")
    }
    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertShow: false })
        getAllList();
    }
    return (
        <>
            <Loader show={showLoader} />
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
            <a
                className="deleteUser Action"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Delete IVR"
                onClick={() => handleDelete(listData)}
            >
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-white"
                />
            </a>
        </>
    )
}

export default DeleteAction