import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { numDifferentiation, timeConverter } from '../../../helper';

export const LeaderBoardCard = (props) => {
    const { role, status, data, type, tab } = props;
    const showBoxData = value => {
        let outer = value?.first_name + " " + (value.last_name && value?.last_name[0])
        let inner = "-"
        if (type === "lead_to_booking") {
            inner = value?.lead_to_booking && (value?.lead_to_booking + " %");
        }
        if (type === "lead_to_activity") {
            inner = value?.lead_to_activity && (value?.lead_to_activity + " %");
        }
        if (type === "lead_to_opportunity") {
            inner = value?.lead_to_opportunity && (value?.lead_to_opportunity + " %");
        }
        if (type === "activity_to_booking") {
            inner = value?.activity_to_booking && (value?.activity_to_booking + " %");
        }
        if (type === "lead_to_update_time") {
            // inner = timeConverter(value.lead_to_update_time);
            inner = value?.lead_to_update_time && (value?.lead_to_update_time + " Mins");
        }
        if (type === "lead_to_followup_delay_time") {
            // inner = timeConverter(value.lead_to_followup_delay_time)
            inner = value?.lead_to_followup_delay_time && (value?.lead_to_followup_delay_time + " Mins");
        }
        if (type === "followup_overdue_count") {
            inner = value?.lead_count !== undefined ? value?.lead_count + value?.lead_count : "";

        }
        if (type === "business") {
            inner = value?.amount && numDifferentiation(value?.amount);
        }
        if (type === "activities") {
            inner = value?.visitDone !== undefined ? value?.visitDone + value?.revisitDone : "";
        }
        if (inner !== undefined && inner !== "") {
            inner = ` (${inner})`
        }
        else {
            inner = ""
        }
        return `${outer}${inner}`
    }
    return (
        <>
            {status === 200
                ?
                (data.length ?
                    <div className="col-md-4 col-10 mb-3">
                        <div className="border border-1 p-2 mb-2">{role}</div>
                        <div className="card scrollCard mb-2">
                            {
                                data.map((x, idx) => (
                                    /* x.id !== window?.$user?.id
                                    ? <span className='text-capitalize border border-bottom p-2'  key={idx}>{showBoxData(x)}</span>
                                    : <span className='text-capitalize border border-bottom p-2'  key={idx}>{showBoxData(x)} Rank : {x.rank}</span> */
                                    <span className='text-capitalize border border-bottom p-2 d-flex align-items-center' key={idx}>
                                        {tab === "team" &&
                                            <span className='rank-position mr-2'>{x.rank}</span>
                                        }
                                        {showBoxData(x)}

                                    </span>
                                ))
                            }
                        </div>
                    </div>
                    : ""
                )
                :
                <div className="col-md-4 col-10 mb-3">
                    <div className="border border-1 p-2 mb-2"><Skeleton /></div>
                    <div className="card scrollCard p-2 mb-2">
                        {[1, 2, 3, 4].map((x, idx) => (
                            <div className="rounded border border-1 p-2 my-2" key={idx}><Skeleton /></div>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}
