import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { getAutoCompleteProject } from "../../../dataParser/getProjectData";
// import { Form } from 'react-bootstrap';
// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
// import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
// import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import GoogleMaps from "../googleMap/googleMap.js";
// import EmailIcon from '@material-ui/icons/EmailIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardDateTimePicker
} from '@material-ui/pickers';
// import {getUpdateLeadData} from "../../../dataParser/getListData";
// import {phoneCheck, emailCheck} from "../../../helper";
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import EmailIcon from '@material-ui/icons/Email';
import { UpdateLeadData, getClientData } from "../../../dataParser/getListData";
import { 
	cleanStorage,
//empty, letterCase, nameCheck, emailCheck, 
phoneCheck } from "../../../helper";
import { FOLLOWUP_DATE_TIME_FORMAT, STAGES } from "../../../config/constant";
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormLabel from '@material-ui/core/FormLabel';
import { autoComplete } from "../../../dataParser/commomDataApi.js";
import moment from 'moment';
import { leadOwnerAccess } from '../../../config/constant/crmRoleFunctionPermission.js';

class DesDelayUpdateForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			// project_addedby: '',
			lead_status_id: '',
			// leadId : '',
			// sitevisit_date :null,
			// followup_date :null,
			followup_date: null,
			revert_status: null,
			lead_sattus: '',
			isError: false,
			isLoading: false,
			userName: [],
			option: [],
			alternetProject: [],
			alternate_project_id: '',
			selectedVal: {},
			sweetShow: false,
			type: "success",
			project_id: '',
			project: this.props.leadDetail.data.Project,
			title: "",
			name: "",
			comment: "",
			number: "",
			alternate_number: "",
			email: "",
			current_location: "",
			property_for: "",
			errors: {
				name: "",
				number: "",
				alternate_number: "",
				email: "",
				// comment: "",
				current_location: "",
				property_for: "",
				revert_status: "",
			}
		};
		// console.log("AAAAAAAAAAAAAAAAAAAAAAA",this.props.leadDetail)
	}

	async componentDidMount() {
		var userDataArr = [{ name: this.props.leadDetail.data?.AlternateProject?.name, id: this.props.leadDetail.data?.AlternateProject?.id }]

		this.setState({
			leadDetail: this.props.leadDetail.data,
			lead_status_id: STAGES.DECISION_DELAYED,
			revert_status: this.props.leadDetail.data.revert_status || '',
			property_for: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.property_for || '',
			current_location: this.props.clientData.data.current_location || '',
			email: this.props.clientData.data.email || '',
			number: this.props.clientData.data.number || '',
			alternate_number: this.props.clientData.data.alternate_number || '',
			name: this.props.clientData.data.name || '',
			project_id: this.props.leadDetail.data.project_id,
			followup_date: this.props.leadDetail.data.InternalStatusHistory?.followup_date ? moment(this.props.leadDetail.data.InternalStatusHistory?.followup_date).format(FOLLOWUP_DATE_TIME_FORMAT) : null,
			alternetProject: userDataArr[0].name !== undefined ? userDataArr : [],
			selectedVal: userDataArr[0].name !== undefined ? userDataArr[0] : {},
			comment: this.props.leadDetail.data.ObjectStage !==null && this.props.leadDetail.data.ObjectStage.comments || '',
			comment_added: this.props.leadDetail.data.ObjectStage !==null ? this.props.leadDetail.data.ObjectStage.comments : '',
		});
		// (async () => {
		//        try {
		// 		let resData = await getClientData(this.props.leadDetail.data.Client.id);
		//      console.log("!!!!!!!!!!!!!!!!!!!!!!",resData)
		//      await this.setState({
		//      	current_location : resData.data.current_location ||'',
		//          email : resData.data.email || '',
		//          number : resData.data.number || '',
		//          name : resData.data.name || '',
		//      }) ;
		//        } catch (e) {	
		//            //...handle the error...
		//        }
		//    })();
	}

	handleGeoLocation = async (current_location) => {

		// console.log("<=============in the current_location============>",current_location);
		this.setState({ current_location: current_location });
	}

	updateLeadFrom = async (e) => {

		e.preventDefault();
		// let upadateLeadReq = {};
		// let leadId = this.state.leadId;

		const addLeadRequest = (({ comment, lead_status_id, name, alternate_number, number, email, property_for, current_location, revert_status, project_id }) => ({
			lead_status_id,
			name,
			comment,
			number,
			alternate_number,
			email,
			property_for,
			current_location,
			revert_status,
			project_id,
		}))(this.state);

		// console.log("addLeadRequest XXXXXXXXXXXX",addLeadRequest)

		let upadateLeadReq = {};
		upadateLeadReq.Client = {};
		upadateLeadReq.Client.ClientBudgetInfo = {};
		upadateLeadReq.ObjectStage = {};
		if (addLeadRequest.name !== null && addLeadRequest.name !== "") {

			upadateLeadReq.Client["name"] = addLeadRequest.name;
		}
		if (addLeadRequest.comment !== null && addLeadRequest.comment !== "") {

			upadateLeadReq.ObjectStage["comment"] = addLeadRequest.comment;
		}
		if (addLeadRequest.revert_status !== null && addLeadRequest.revert_status !== "") {

			upadateLeadReq["revert_status"] = parseInt(addLeadRequest.revert_status);
		}

		if (addLeadRequest.number !== null && addLeadRequest.number !== "") {

			upadateLeadReq.Client["number"] = addLeadRequest.number;
		}
		if (addLeadRequest.alternate_number !== null && addLeadRequest.alternate_number !== "") {

			upadateLeadReq.Client["alternate_number"] = addLeadRequest.alternate_number;
		}
		if (addLeadRequest.email !== null && addLeadRequest.email !== "") {

			upadateLeadReq.Client["email"] = addLeadRequest.email;
		}
		if (addLeadRequest.current_location !== "") {

			upadateLeadReq.Client["current_location"] = addLeadRequest.current_location;
		}
		if (addLeadRequest.property_for !== null && addLeadRequest.property_for !== "") {

			upadateLeadReq.Client.ClientBudgetInfo["property_for"] = addLeadRequest.property_for;
		}
		upadateLeadReq.ObjectStage["stage_id"] = addLeadRequest.lead_status_id;
		if (this.state.owner_id !== '') upadateLeadReq.owner_id = this.state.owner_id;
		if (this.state.followup_date !== null) upadateLeadReq.followup_date = this.state.followup_date;
		if (this.state.project_id !== '') upadateLeadReq['project_id'] = this.state.project_id;
		if (this.state.alternate_project_id !== '') upadateLeadReq['alternate_project_id'] = this.state.alternate_project_id;


		// if(addLeadRequest.followup_date != null){

		//    	upadateLeadReq.data.followup_date = addLeadRequest.followup_date.split("/").join("-");
		// }

		// if(addLeadRequest.sitevisit_date != null){

		//    	upadateLeadReq.data.sitevisit_date = addLeadRequest.sitevisit_date.split("/").join("-");
		// }

		// if(addLeadRequest.lead_sattus != ''){

		//    	upadateLeadReq.data.lead_sattus = addLeadRequest.lead_sattus;
		// }

		// upadateLeadReq.id = this.props.leadDetail.data.id;
		// upadateLeadReq.data = bookupdateData;
		// upadateLeadReq.data.lead_status_id = addLeadRequest.lead_status_id;
		const {name, number, followup_date, current_location, comment} = this.state
		if (name !== '' && number !== '' && followup_date !== null && current_location !== '' && comment !== '') {

			var addLeadRes = await UpdateLeadData(this.props.leadDetail.data.id, upadateLeadReq);
			// console.log("addLeadRes XXXXXXXXXXXX",addLeadRes);

			if (addLeadRes.meta.status === 201) {

				// console.log("Lead insert successfully !!!");

				this.setState({
					sweetShow: true,
					type: "success",
					title: "Lead Updated Successfully!!!"

				});

			} else {

				this.setState({
					sweetShow: true,
					type: "error",
					title: addLeadRes.meta.message
				});
			}
		} else {
			if (this.state.name == "") {

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						name: "*please enter valid client name"
					}
				}))
			} else {

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						name: ""
					}
				}))
			}

			if (this.state.number == "" || phoneCheck(this.state.number) === false) {

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						number: "*please enter valid client number"
					}
				}))
			} else {

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						number: ""
					}
				}))
			}
			/*if (this.state.email == "" || emailCheck(this.state.email) === false) {

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						email: "*please enter valid client email"
					}
				}))
			} else {

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						email: ""
					}
				}))
			}*/
			// if (this.state.location == "" || phoneCheck(this.state.location) === false) {

			// 	this.setState(prevState => ({
			// 		errors: {
			// 			...prevState.errors,
			// 			location: "*please enter valid client location"
			// 		}
			// 	}))
			// } else {

			// 	this.setState(prevState => ({
			// 		errors: {
			// 			...prevState.errors,
			// 			location: ""
			// 		}
			// 	}))
			// }
			//  	if(this.state.property_for == "" || phoneCheck(this.state.property_for)=== false){

			//          this.setState(prevState => ({
			//     errors: {                
			//         ...prevState.errors,    
			//         property_for:"*please enter valid client property_for"
			//     }
			// }))
			//  	}else{

			//  		this.setState(prevState => ({
			//     errors: {                
			//         ...prevState.errors,    
			//         property_for:""
			//     }
			// }))
			//  	}

			this.setState({ isError: true });
		}
	}

	handleAutoChange = async (e) => {
		this.setState({ isLoading: true });
		let value = e.target.value

		var projectListData = await getAutoCompleteProject(value);

		if (projectListData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData.data })
		} else if (projectListData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData })
		}

	}
	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			q: e.target.value,
		};
		let resData = await autoComplete('users', data);

		// console.log("resData.dataXXXXXXXXXXXXXXXX",resData.data);
		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	onChange = (e) => {
		if (e.target.name == 'phone' && !phoneCheck(e.target.value)) {
			this.setState(prevState => ({
				errors: {
					...prevState.errors,
					number: "*please enter valid client number"
				}
			}))
		}
		this.setState({ [e.target.name]: e.target.value });
	}	

	handleNumberChange = (e) => {
		const {name, value} = e.target
		if(e.target.validity.valid || value === "")
			this.setState({ [name]: value })
	}	

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();

		// window.location.reload();
	}

	AutoChangeProject = async (e) => {
		this.setState({ isLoading: true });
		// let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				await this.setState({
					isLoading: false,
					alternetProject: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

	render() {

		const { sweetShow, type, title, alternetProject, selectedVal, revert_status, leadDetail, isLoading, userName, followup_date, current_location, option } = this.state;
		// console.log("in the render", this.state);

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row mb-3">
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											required
											error={this.state.errors.name !== '' ? 'error' : ''}
											id="name"
											name="name"
											label="Client Name"
											value={this.state.name ? this.state.name : ''}
											helperText={this.state.errors.name !== '' ? this.state.errors.name : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircle />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										required
										error={this.state.errors.number !== '' ? 'error' : ''}
										id="number"
										name="number"
										value={this.state.number ? this.state.number : ''}
										label="Client Number"
										helperText={this.state.errors.number !== '' ? this.state.errors.number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											// required
											// error={this.state.errors.email !== '' ? 'error' : ''}
											id="email"
											name="email"
											label="Client Email"
											value={this.state.email ? this.state.email : ''}
											// helperText={this.state.errors.email !== '' ? this.state.errors.email : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										id="alternate_number"
										name="alternate_number"
										value={this.state.alternate_number ? this.state.alternate_number : ''}
										label="Alternate Number"
										helperText={this.state.isError.alternate_number !== '' ? this.state.isError.alternate_number : ''}
										onChange={this.handleNumberChange}	
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										select
										id="property_for"
										name="property_for"
										value={this.state.property_for ? this.state.property_for : ''}
										label="Property For"
										onChange={this.onChange}
									>
										<MenuItem value="Self">Self</MenuItem>
										<MenuItem value="Investment">Investment</MenuItem>
									</TextField>
								</div>
								{leadOwnerAccess.includes(window.$role) ?
									<div className="col-sm-6 mb-3">
										<Autocomplete
											id="UserData"
											getOptionSelected={(userName, value) => userName.name === value.name}
											getOptionLabel={userName => userName.name}
											options={userName}
											loading={isLoading}
											onChange={(_event, userName) => {
												if (userName === null) {
													this.setState({
														userName: [],
														owner_id: '',
													})
												} else {
													this.setState({
														owner_id: userName.id,
													})
												}

											}}
											renderInput={params => (
												<TextField
													{...params}
													label="Lead Owner"
													fullWidth
													onChange={this.handleAutoUserChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{isLoading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
									:
									''
								}
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDateTimePicker
												required
												margin="normal"
												id="followup_date"
												label="Follow Up Date"
												ampm={false}
												format="yyyy/MM/dd HH:mm"
												minDate={moment().subtract(1, "days")}
												name="followup_date"
												value={followup_date}
												onChange={(key, date) => this.handleDateChange('followup_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
								{this.props.leadDetail.data.source_id === 13 ?
									<div className="col-sm-6 project_name">
										<div className="form-group">
											<Autocomplete
												id="projectData"
												getOptionSelected={(option, value) => option.name === value.name}
												getOptionLabel={option => option.name}
												options={option}
												loading={isLoading}
												value={this.state.project}
												onChange={(_event, option) => {
													if (option === null) {
														this.setState({
															option: [],
															project_id: '',
														})
													} else {
														var projectId = option.id;
														this.setState({
															project_id: projectId,
															project: option
														})
													}

												}}
												renderInput={params => (
													<TextField
														{...params}
														label="Project Name"
														reqiured
														fullWidth
														onChange={this.handleAutoChange}
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{isLoading ? (
																		<CircularProgress color="inherit" size={20} />
																	) : null}
																	{params.InputProps.endAdornment}
																</React.Fragment>
															)
														}}
													/>
												)}
											/>
										</div>
									</div> : ''}
								<div className="col-sm-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(alternetProject, value) => alternetProject.name === value.name}
										getOptionLabel={alternetProject => alternetProject.name}
										options={alternetProject}
										value={selectedVal}
										onChange={(_event, alternetProject) => {
											if (alternetProject === null) {
												this.setState({ alternetProject: [] })
											} else {
												this.setState({
													alternet_project_name: alternetProject.name,
													alternate_project_id: alternetProject.id,
													selectedVal: { name: alternetProject.name, id: alternetProject.id }
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												label="Alternate Project"
												fullWidth
												onChange={this.AutoChangeProject}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<GoogleMaps handleLocation={this.handleGeoLocation} autoLocation={this.state.current_location} isError={this.state.isError.current_location} />
								</div>
								{/*<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend">Revert Status</FormLabel>
									      <RadioGroup row aria-label="revert_status" name="revert_status" value={revert_status} onChange={this.onChange}>
									        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Yes" />
									        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
									      </RadioGroup> 
									</div>
								</div>*/}
								<div className="col-sm-12 col-12">
									<div className="form-group">
										<TextField
											disabled={this.state.lead_status_id === this.props.selectedFormId && !!(this.state.comment_added) ? true : false}
											// error={this.state.errors.comment !== '' ? 'error' : ''}
											id="standard-multiline-flexible"
											required
											label="Comments"
											name="comment"
											value={this.state.comment}
											// helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
											multiline
											rowsMax="4"
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>Submit</button>
									</div>
								</div>
								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Above all * fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(DesDelayUpdateForm);
