import React from 'react'
import "./termsConditions.css";
import { AMAZON_URL } from '../../config/serverKey';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const spanstyle = {
	borderWidth: "1px",
	borderColor: "#aaaaaa",
	borderStyle: "solid",
};

class TermsCondition extends React.Component {
	render() {
		return (
			<div className="TCPage">
				<div className="row align-items-center">
					<div className="col-md-5 col-6">
						<img className="magnet_img" src={`${AMAZON_URL}/logo.svg`} alt="small_site_logo" />
					</div>
				</div><br />
				<div className="content-terms">
					<h3 className="Title">TERMS & CONDITIONS</h3>
					<p className="text-justify">(Magnet enrolled will be referred to as - Magnet Partner )</p>

					<p className="text-justify">In consideration of the services provided by the Magnet Partner, the HOMESFY shall pay the Brokerage Fee on the following terms and condition:</p>
					<ol className="pl-3">
						<li className="mb-3 text-justify">The Magnet Partner shall be entitled to the brokerage fee only on customers introduced by them with supporting documents & have no claim on HOMESFY’S direct bookings done through In-house Sales Team and shall have no claim for any additional amount with respect to the sale of the property.
							The Partner-Exclusive will mention HOMESFY’s name and REGISTRATION ID in the customer application and visit form.<br />“Craft Financial Advisors Ltd. RERA Reg No.  <b>A51900000136”</b></li>
						<li className="mb-3 text-justify">It is hereby clarified that the Partner-Exclusive Services shall not be limited to referring details of Clients interesting in buying units/flats in the Property only and the actual conveyance/transfer/execution of papers in this regard.</li>
						<li className="mb-3 text-justify">The magnet partner can avail the digital marketing services by making a payment through online transfer only to Homesfy( Craft Financial Advisors Ltd). All Payments are subject to 10% service charge and GST ( wherever applicable)</li>
						<li className="mb-3 text-justify">
							<ol type="a">
								<li>The Brokerage sharing slab has to be selected by the Magnet Partner at the time of lead addition in CRM as per services availed in lead qualification and servicing. Illustrated in Table below<br /></li>
								<div className="row" >
									<div className="col-md-7 ">
										<TableContainer component={Paper}>
											<Table aria-label="simple table">
												<TableHead style={spanstyle}>
													<TableRow>
														<TableCell align="right" style={spanstyle}></TableCell>
														<TableCell align="right" style={spanstyle}>
															50%
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															60%
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															70%
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align="right" style={spanstyle}>
															Plan
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															lite
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															Basic
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															Plus
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow style={spanstyle}>
														<TableCell align="right" style={spanstyle}>
															Lead Qualification
														</TableCell>
														<TableCell style={spanstyle} align="right">
															✓
														</TableCell>
														<TableCell align="right" style={spanstyle}></TableCell>
														<TableCell align="right" style={spanstyle}></TableCell>
													</TableRow>
													<TableRow style={spanstyle}>
														<TableCell align="right" style={spanstyle}>
															Lead Servicing
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															✓
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															✓
														</TableCell>
														<TableCell align="right" style={spanstyle}></TableCell>
													</TableRow>
													<TableRow style={spanstyle}>
														<TableCell align="right" style={spanstyle}>
															Closing Support
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															✓
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															✓
														</TableCell>
														<TableCell align="right" style={spanstyle}>
															✓
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</div>
								</div>
								<b><i>*For 80% - contact your Service RM</i></b>
								<li>The Brokerage slab Payable on the clients introduced and generated by availing digital marketing services of   HOMESFY, will be in the sharing ratio of 60:40 - – (Partner: Homesfy).<br />
									<b><i>Note: The sharing ratio will be on the brokerage slab earned by HOMESFY in the project and within the time period of     booking. All brokerage percentage will be calculated on basic flat cost.</i></b>
								</li>
							</ol>
						</li>
						<li className="mb-3 text-justify"> The Partner-Exclusive shall raise an invoice for the amounts payable by the HOMESFY. Each invoice shall be delivered to the address of the HOMESFY as specified by the HOMESFY, through courier or by hand delivery and the HOMESFY agrees to acknowledge the same.</li>
						<li className="mb-3 text-justify"> Payments to the magnet will be released post receipt of the same from builder/developer. It will be processed in the same tranches as received from the developer.</li>
						<li className="mb-3 text-justify"> A brokerage shall be paid after the deduction of all applicable taxes. The payment shall at all times be subject to applicable policies, law, orders, and regulations.</li>
						<li className="mb-3 text-justify"> The client introduced by the magnet partner will remain tagged to him maximum upto 45 days from the date of closure of the lead. Post that the magnet partner will have no right and cannot claim the prospect/client.</li>
						<li className="mb-3 text-justify"> The travel expenses incurred (if any ) to arrange and facilitate the site visit of the clients introduced by the magnet partner will be borne by the magnet partner and will be deducted and/or adjusted against the payouts to the magnet.</li>
						<li className="mb-3 text-justify"> The HOMESFY shall attempt to pay the brokerage fee within 60 days of receipt of the invoice. All payments shall be paid subject to applicable taxes.</li>
						<li className="mb-3 text-justify"> If the referred/introduced customer of Partner-Exclusive cancels or terminates agreement of sale/ Allotment letter, in such event, the Partner-Exclusive shall be liable to refund the brokerage fee paid to the HOMESFY, within 15 days from the date of such cancellation or termination. In the event, the Partner-Exclusive does not refund the brokerage fee to the HOMESFY, the HOMESFY shall be entitled to deduct the refundable amount from any other brokerage fee payable to the Partner-Exclusive by the HOMESFY.</li>
						<li className="mb-3 text-justify"> If any kind of discount or benefit or pass back offered to customers , Final brokerage payout will be calculated post adjustment of the same.</li>
						<li className="mb-3 text-justify"> The Partner-Exclusive is aware and agrees that as per HOMESFY’s policy a background verification of the Magnet Partner through the third party and or HOMESFY employee, will be conducted to verify and confirm the personal, educational, work experience and like the information provided by the Magnet Partner for the purpose of REGISTRATION OF Magnet Partner. In the event it is brought to the notice of the HOMESFY that any of the information provided by the broker is false or incomplete or incorrect, the HOMESFY, without prejudice to its other rights and remedies, be entitled to cancel the registration of the Magnet Partner</li>
						<li className="mb-3 text-justify"> The Magnet Partner shall take necessary approvals or licenses as per the applicable laws to carry out such business and HOMESFY shall not be responsible in any manner.</li>
						<li className="mb-3 text-justify"> Any dispute arising out of the covenants of this transaction shall be referred to the courts at Mumbai only.</li>
						<li className="mb-3 text-justify"> In case of any misrepresentation of HOMESFY or any data breach which could be done by activities such as copying data from the platform or hacking data from the platform/database, HOMESFY reserves the right to terminate the agreement with immediate effect. Also, a legal action can be initiated against the Magnet Partner.</li>
						<li className="mb-3 text-justify"> The HOMESFY reserves the right, in its sole discretion, to modify or suspend the terms & conditions of the registration form. </li>
					</ol>
					<div className="px-3">
						<h4>Refund Policy :- </h4>
						<p className="mb-3"> If the subscription or product you purchased is not what you were expecting, you can request, within 30 days of your purchase of the product or subscription, that Magnet apply a refund to your account. <b>This refund option does not apply to Digital Marketing Services, policy of which is detailed below.</b> We reserve the right to apply your refund as a refund credit or a refund to your original payment method, at our discretion, depending on capabilities of our payment service providers, and other factors. No refund is due to you if you request it after the 30-day guarantee time limit has passed. </p>

						<p className="mb-3"> <b>Due to the digital nature of digital marketing services, wherein we pay partners such as Google and Facebook for their ads, we are unable to provide refunds on any purchases of digital campaigns,</b> if the money has already been spent. However, if the money has not entirely been spent, then Team Magnet reserves the right to refund the pending amount sans the service fee based on Team Magnet’s understanding of the case at that point in time. </p>

						<p className="mb-3"> In most cases you have paid for services and seen the results of using them and received benefit from the efforts or deployment and usage of services or products. If you do not see the benefit that was promised, Team magnet will do everything in its power to ensure that the lack is made up to you in some way. However, as Google and other online entities are constantly changing their services, which influences everything online, there can be no iron-clad promises made or returns expected. That would be beyond the scope and ability of the Magnet team to control.</p>

						<p className="mb-3"> However, we do suggest that if you ARE having issues with our product or services, you let us try to help you – submit an email support ticket to partnersupport@mymagnet.io and we would be happy to resolve it. </p>

						<p className="mb-3"> Although it has actually never happened, accounts cancelled/terminated by mymagnet.io, for violating our terms of service do not qualify for a refund. For example, if your account is cancelled due to spamming, you will not be given any refund. We believe strongly in upholding good and moral etiquette in ALL of our online proceedings and encourage our clients to do the same.</p>

					</div>
				</div>
			</div>
		)
	}
}

export default TermsCondition