import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import Chip from '@material-ui/core/Chip';
import { addleadinterntal } from "../../../dataParser/getListData";
import { getAutoCompleteProject } from "../../../dataParser/getProjectData";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { leadInterautoComplete, autoComplete } from "../../../dataParser/commomDataApi";
import TextField from '@material-ui/core/TextField';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { cleanStorage, dateTimeConverter, empty } from "../../../helper";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { REVERT_ROLES } from "../../../config/constant/index.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PopUpAlert from '../sweetAlert/popUpAlert.js';
import FollowUpControl from '../../../core/FollowUpControl.js';

class LeadInternalStForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      internal_st: [],
      selectedValProj : [],
      project_ids : [],
      project: [],
      userName: [],
      user_id: "",
      RmName: [],
      presale_id: null,
      selectedRm: {},
      internal_status_id: '',
      data: this.props.leadDetail.data,
      lead_id: '',
      comment: '',
      companion: '',
      action_date: null,
      followup_date: null,
      sweetShow: false,
      isLoading: false,
      type: "success",
      title: "",
      error: "",
      isError: {
        companion: '',
      }
      , alertShow: false
    };
  }
  componentDidMount() {
    const { data } = this.state
    let assignedRm = []
    if (data.PresaleRm !== null && data.PresaleRm !== undefined) {
      assignedRm = [{ name: data?.PresaleRm?.name, id: data?.PresaleRm?.id }]
    }
    else {
      assignedRm = []
    }
    this.setState({
      lead_id: this.props.leadDetail.data.id,
      RmName: assignedRm[0]?.name !== undefined ? assignedRm : [],
      selectedRm: assignedRm[0]?.name !== undefined ? assignedRm[0] : {},
      presale_id: assignedRm[0]?.id !== undefined ? assignedRm[0]?.id : null,
    });
    (async () => {
      try {
        var resData = await leadInterautoComplete('lead_internal_status');
        await this.setState({
          isLoading: false,
          internal_st: resData.data
        });
      } catch {
        //...handle the error...
      }
    })();
  }

  AutoChangeProject = async (e) => {
    this.setState({ isLoading: true });
    (async () => {
      try {
        let data = e.target.value;
        var resData = await getAutoCompleteProject(data);
        await this.setState({
          isLoading: false,
          project: resData.data
        });
      } catch (e) {
        //...handle the error...
      }
    })();
  }

  handleAutoUserChange = async (e) => {
    let data = {};
    data.q = e.target.value;
    data.roles = [REVERT_ROLES.SALES_HEAD, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1,]
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isLoading: false });
      this.setState({ userName: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isLoading: false });
      this.setState({ userName: resData.data })
    }
  }
  handleAutoRmChange = async (e) => {
    let data = {};
    data.q = e.target.value;
    data.roles = [REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ RmName: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {
      this.setState({ RmName: resData.data })
    }
  }

  onChange = (e) => {

    this.setState({ [e.target.name]: e.target.value });
  }

  formValid = ({ isError, ...rest }) => {
    let isValid = false;
    if (rest.action_date === null) {
      this.setState(prevState => ({
        isError: {
          ...prevState.isError,
          location: '*please select valid Date'
        }
      }))
    }
    if (rest.followup_date === null) {
      this.setState(prevState => ({
        isError: {
          ...prevState.isError,
          location: '*please select valid Date'
        }
      }))
    }

    if (rest.followup_date === null || rest.action_date === null || empty(rest.internal_status_id)) {
      return false;
    }

    Object.values(isError).forEach(val => {
      if (val.length > 0) {
        return false;
      } else {
        isValid = true
      }
    });

    return isValid;
  };

  handleDateChange = (key, date) => {

    this.setState({ [key]: date });
  }

  formValChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    this.setState({
      isError,
    })
    this.setState({
      [name]: (value)
    })
  };

  addNewProject = async (e) => {
    e.preventDefault();

    if (this.formValid(this.state) && this.state.comment !== "") {
      let data = {};
      if (this.state.action_date !== null) data["action_date"] = this.state.action_date;
      if (this.state.followup_date !== null){
        data["followup_date"] = dateTimeConverter(this.state.followup_date);
      }
      // if (this.state.followup_date !== null) data["followup_date"] = this.state.followup_date;
      if (this.state.comment !== '') data["comment"] = this.state.comment;
      if (this.state.companion !== '') data["companion"] = this.state.companion;
      if (this.state.lead_id !== '') data["lead_id"] = this.state.lead_id;
      if (this.state.internal_status_id !== '') data["internal_status_id"] = this.state.internal_status_id;
      if (this.state.project_ids.length !== 0) data["project_id"] = this.state.project_ids;
      if (this.state.user_id !== '') data["user_id"] = this.state.user_id;
      if (this.state.presale_id) data["presale_id"] = this.state.presale_id;

      if (this.state.comment !== '') {
        data.comment = this.state.comment;
      }
      if (this.state.companion !== '') {
        data.companion = this.state.companion;
      }

      var addLeadRes = await addleadinterntal(data);

      if (addLeadRes.meta.status === 503) {

        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "error",
          title: "!!! Oooops Network Error..."
        });

      } else if (addLeadRes.meta.status === 201) {

        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "success",
          title: "Updated successfully"
        });
      } else if (addLeadRes.meta.status === 404 || addLeadRes.meta.status === 400) {

        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "warning",
          title: addLeadRes.meta.message
        });

      } else if (addLeadRes.meta.status === 409) {
        this.setState({
          showLoader: false,
          alertShow: true,
          alertType: "warning",
          alertMsg: addLeadRes.meta.message
        });
      } else {

        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "error",
          title: "Something went wrong !!!"
        });
      }
    } else {
      this.setState({
        showLoader: false,
        error: "*Some error in the above fields or fileds are reqiured !!!",
      })
    }
  };



  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    this.props.changeModal()
    this.props.getList()
  }
  handleClose = () => {
    this.setState({ alertShow: false })
  }
  handleVisitDone = () => {
    if([2,4,6,8].includes(this.state.internal_status_id)){
      let d = new Date();
        d.setDate(d.getDate());
        return d;
    }
  }
  render() {
    const {selectedValProj, internal_status_id, error, sweetShow, type, title, internal_st, isLoading, action_date, followup_date, companion, project, userName, RmName, selectedRm
      , alertShow, alertType, alertMsg } = this.state;

    return (
      <Frag>
        <div className="projectForm">
          <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
          <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={this.handleClose} hideTime={6000} />
          <form role="form">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">Activity Status*</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={internal_status_id}
                      onChange={this.formValChange}
                      inputProps={{
                        name: 'internal_status_id',
                        id: 'internal_status_id',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {internal_st ?
                        (internal_st.map(reg =>
                          <MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
                        ))
                        :
                        ''
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="col-sm-6 mb-3">
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        margin="normal"
                        id="action_date_form"
                        label="Activity Date*"
                        ampm={false}
                        format="yyyy/MM/dd HH:mm"
                        minDate={moment().subtract(15, "days")}
                        maxDate={this.handleVisitDone()}
                        name="action_date"
                        value={action_date}
                        onChange={(key, date) => this.handleDateChange('action_date', date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </div>


              </div>
              <div className="row mb-3">
                <div className="col-sm-6 mb-3">
                  <div className="form-group"> 
                  <Autocomplete
                    multiple
                    id="asynchronous-demo"
                    getOptionSelected={(project, value) => project.name === value.name}
                    getOptionLabel={project => project.name + " (" + project.Region.name + ")"}
                    options={project}
                    value={selectedValProj}
                    loading={isLoading}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(_event, project) => {
                      if (project === null) {
                        this.setState({ project: [] })
                      } else {
                        var projectId = project.map((ops) => ops.id);
                        this.setState({
                          project_ids : projectId,
                          selectedValProj: project
                        })    
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Visited Project"
                        fullWidth
                        onChange={this.AutoChangeProject}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  </div>  
                </div>
                <div className="col-sm-6 mb-1">
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FollowUpControl
                        required
                        margin="normal"
                        name="followup_date"
                        value={followup_date}
                        onChange={(date) => this.handleDateChange('followup_date', date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="form-group">
                    <Autocomplete
                      id="UserData"
                      getOptionSelected={(userName, value) => userName.name === value.name}
                      getOptionLabel={userName => userName.name + " (" + userName.phone + ")"}
                      options={userName}
                      loading={isLoading}
                      onChange={(_event, userName) => {
                        if (userName === null) {
                          this.setState({
                            userName: [],
                            user_id: '',
                          })
                        } else {
                          this.setState({
                            user_id: userName.id,
                          })
                        }

                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Activity Done By(Sales)"
                          fullWidth
                          onChange={this.handleAutoUserChange}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                {(this.state.data.PresaleRm !== null && this.state.data.PresaleRm !== undefined) &&
                  <div className="col-sm-6 mb-3">
                    <div className="form-group">
                      <Autocomplete
                        getOptionSelected={(RmName, value) => RmName.name === value.name}
                        getOptionLabel={RmName => RmName.name}
                        options={RmName}
                        value={selectedRm}
                        loading={isLoading}
                        onChange={(_event, RmName) => {
                          if (RmName === null) {
                            this.setState({
                              RmName: [],
                              presale_id: '',
                            })
                          } else {
                            this.setState({
                              presale_id: RmName.id,
                              selectedRm: { name: RmName.name, id: RmName.id }

                            })
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="PreSalesRM"
                            fullWidth
                            onChange={this.handleAutoRmChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                }
                {internal_status_id === 4 || internal_status_id === 6 || internal_status_id === 2 ?
                  <div className="col-sm-6">
                    <div className="form-group">
                      <FormLabel component="legend">Companion</FormLabel>
                      <RadioGroup row aria-label="companion" name="companion" value={companion} onChange={this.onChange}>
                        <FormControlLabel value="alone" control={<Radio color="primary" />} label="Alone" />
                        <FormControlLabel value="group" control={<Radio color="primary" />} label="Group" />
                        <FormControlLabel value="family" control={<Radio color="primary" />} label="Family" />
                      </RadioGroup>
                    </div>
                  </div>
                  :
                  ''
                }
                <div className="col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      id="standard-multiline-flexible"
                      required
                      label="Comments"
                      name="comment"
                      value={this.state.comment}
                      multiline
                      rowsMax="4"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 text-center col-12">
                  <div className="form-group">
                    <button type="submit" className="btn btn-success" onClick={this.addNewProject}>Submit</button>
                    <p className="mb-0">
                      <span className="text-center text-danger small">{error != '' ? error : ''}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Frag>
    );
  };
};

export default withRouter(LeadInternalStForm);
