import React, { useState, useEffect } from 'react'
import ButtonSubmit from '../controls/ButtonSubmit'
import Grid from "@material-ui/core/Grid";
import Form from "@material-ui/core/FormControl";
import InputField from '../controls/InputField'
import { updateAuditLead } from '../../../dataParser/commomDataApi';
import PopUpAlert from '../sweetAlert/popUpAlert';
import Loader from '../loader/loader';
import { cleanStorage } from '../../../helper';

const initialValues = {
    name: ""
    , alternate_number: ""
    , clientId: ""
}

const initialState = {
    alertShow: false
    , alertType: "success"
    , alertMsg: ""
}
const AuditUpdateForm = ({ leadId, leadDetailProp, changeModal, getList }) => {

    const [values, setValues] = useState(initialValues)
    const [state, setState] = useState(initialState)
    const [showLoader, setLoaderStatus] = useState(false);

    const { name, alternate_number, clientId } = values;
    const { alertShow, alertType, alertMsg } = state;

    useEffect(() => {
        if (leadDetailProp.meta.status === 200) {
            let data = leadDetailProp.data;
            setValues({
                name: data.Client.name !== null ? data.Client?.name : ""
                , alternate_number: data.Client.alternate_number !== null ? data.Client?.alternate_number : ""
                , clientId: data.Client.id !== null ? data.Client?.id : ""
            })
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let id = clientId;
        let data = {};
        data["name"] = name;
        data["alternate_number"] = alternate_number;
        setLoaderStatus(true)
        let getRes = await updateAuditLead(id, data)
        if (getRes.meta.status === 201) {

            setLoaderStatus(false)

            setState({
                ...state,
                alertShow: true,
                alertType: "success",
                alertMsg: "User Updated Successfully!!!"
            });
        } else if (getRes.meta.status === 401) {

            setLoaderStatus(false)

            cleanStorage();
            this.props.history.push("/login");

        } else {

            setLoaderStatus(false)

            setState({
                ...state,
                alertShow: true,
                alertType: "error",
                alertMsg: getRes.meta.message || "Something went wrong !!!"
            });
        }
        getList();
    }
    
    const handleClose = () => {
        setState({ ...state, alertShow: false })
        changeModal();
    }

    const handleChange = (e) => {
        let { name, value } = e.target;

        setValues({
            ...values,
            [name]: value
        })
    }
    const handleNumberChange = (e) => {
        let { name, value } = e.target
        value = value.replace(/\D/g, "")
        setValues({
            ...values,
            [name]: value
        })
    }
    return (
        <>

            <Form>
                <Grid container spacing={2}>
                    <Grid item sm={6} m={2} pt={3}
                    >
                        <InputField
                            name="name"
                            label="Name"
                            value={name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item sm={6} m={2} pt={3}
                    >
                        <InputField
                            name="alternate_number"
                            label="Alternate Number"
                            value={alternate_number}
                            onChange={handleNumberChange}
                        />
                    </Grid>
                    <Grid item sm={12} m={2} pt={3}>
                        <ButtonSubmit onClick={handleSubmit} />
                    </Grid>
                </Grid>

            </Form >
            <PopUpAlert type={alertType} msg={alertMsg} open={alertShow} handleClose={handleClose} hideTime={6000} />
            <Loader show={showLoader} />
        </>
    )
}

export default AuditUpdateForm
