import React from 'react';
import FinanceCalendar from "../component/FinanceCalendar";

const FinanceCalendarPage = () => {
    return (
        <div className="content-wrapper">
            <FinanceCalendar />
        </div>
    )
}

export default FinanceCalendarPage