import {get, post, put} from "../utils/methods.js";

export const getAutoAssign = async (value) =>{

	let getData = await get('/auto_assign',value)
	return  getData;
}

export const createAutoAssign = async (value) =>{

	let setData = await post('/auto_assign',value)
    console.log(setData,"setData")
	return  setData;
}

export const deleteAutoAssign = async (value) =>{
	let deleteData = await put('/auto_assign/list/delete',value)
    console.log(deleteData,'deleteData')
	return deleteData;
}