import {post,get,put,Delete,addDocPost} from "../utils/methods.js";


export const addDocumentApi = async (value) =>{
	let addDocData = await addDocPost('/hrDocument',value)
	return  addDocData;
}

export const getDocDataApi = async (value) =>{
	let getDocData = await get('/hrDocument')
	return  getDocData;
}

export const showDocDataApi = async (value) =>{
	let showDocData = await get('/hrDocument/'+value)
	return  showDocData;
}

export const updateDocDataApi = async (value) =>{
	let updateDocData = await put('/hrDocument/'+value.id,value.data)
	return  updateDocData;
}

export const deleteDocDataApi = async (value) =>{
	let deleteDocData = await Delete('/hrDocument/'+value)
	return  deleteDocData;
}

export const userWiseDocDataApi = async (value) =>{
	let userWiseDocData = await get('/hrDocument/index',value)
	return  userWiseDocData;
}
