import React from 'react';

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Chip from '@material-ui/core/Chip';
import {UserPortContext} from './Providers';

import './ActivityBlock.sass'
import clsx from 'clsx';


const defaultState = {
}


const ActivityBlock = props => {
    const {header, date, author, style} = {...props}

    const [state, setState] = React.useState({
        ...defaultState,
    });
    const matches = React.useContext(UserPortContext)

    // if(matches === "xs"){
    //     return <Box className="ActivityBlock" style={style}>
    //         <Card onClick={() => {}}>
    //             <Box className="user">
    //                 <Chip label={date} />
    //             </Box>
    //             <Box display="flex">
    //                 <CardHeader title={header} subheader={author} subheaderTypographyProps={{className:"sub-header"}} className="header-w" />
    //                 <IconButton>
    //                     <AssignmentIcon fontSize="large"/>
    //                 </IconButton>
    //             </Box>
    //             <CardContent>
    //                 <Box display="flex" justifyContent="space-between">
    //                     <Typography variant="h6">
    //                         {props.children}
    //                     </Typography>
    //                     <Box align="center">
    //                     </Box>
    //                 </Box>
    //             </CardContent>
    //         </Card>
    //     </Box>
    // }

    return <Box className="ActivityBlock" style={style}>
        <Card onClick={() => {}}>
            <Box className="user">
                <Chip label={author} />
            </Box>
            <CardHeader title={header} subheader={date} subheaderTypographyProps={{className:"sub-header"}} className="header-w" />
            <CardContent>
                <Box display="flex" justifyContent="space-between">
					{Boolean(props.children) && props.children}
                </Box>
            </CardContent>
        </Card>
    </Box>
}


export default ActivityBlock;
