import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FormGroup, FormControlLabel } from "@material-ui/core";
import {
  getAutoCompleteProject,
  getRegion
} from "../../dataParser/getProjectData";
import {
  getLeadSourceData,
  getUserAutoComplete,
  getReportData,
  autoComplete,
  leadInterautoComplete,
  // roleWiseAutoComplete
} from "../../dataParser/commomDataApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Loader from "../../component/common/loader/loader";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import {
  REVERT_ROLES,
  LEAD_INTERNAL_STATUS,
  REPORT_STAGE_IDS_STRING,
  REPORT_FOLLOWUP_KEYS,
  CLOSING_REASON
} from "../../config/constant/index.js";
import Tooltip from "@material-ui/core/Tooltip";
import { changeDate, cleanStorage, objEmpty , generateMonths, generateYears} from "../../helper";
import moment from "moment";
import asc from "../../assets/image/asc.svg";
import desc from "../../assets/image/desc.svg";
import { MenuProps } from "../common/datas/data";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { getCallWithKaleyra } from '../../dataParser/getListData';
import PopUpPortal from "../common/sweetAlert/popUpAlert";
import SelectOption from "../common/controls/SelectOption";
import { fieldHiddenSales, magnetGroup } from "../../config/constant/crmRoleFunctionPermission";
import { CORE_API_URL  } from "../../config/serverKey";

const source_group_list = [
  { name: "Google", value: [5, 31, 13, 33, 30, 34, 43] },
  { name: "Facebook", value: [39, 12, 10, 32, 37] },
  { name: "SMS", value: [23, 24, 40, 14] },
  { name: "Agency", value: [1, 7] },
  { name: "LinkedIn", value: [15] },
  { name: "Magnet", value: [17, 18, 41] }
];

const source_group_access = ["project-rm", "project-activity-rm"]
const MgLead_SourceSlug = ["activity", "booking", "current-status", "followup", "lost-sales", "project-rm"]
const MgLead_SourceRole = [...fieldHiddenSales, ...magnetGroup]
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

/* const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow); */

const CustomStyle = {
  backgroundColor: "#B0C4DE"
};

var filterData;
let dateColor = { color: "" }

let bookingDateColor = (date1) => {
  const date2 = new Date();
  const booking_date = moment(date1, "YYYY-MM-DD");
  const curr_date = moment(date2, "YYYY-MM-DD");
  let d = curr_date.diff(booking_date, "days");
  if (d > 60) {
    dateColor.color = "red";
  } if (d <= 60) {
    dateColor.color = "yellow";
  } if (d <= 45) {
    dateColor.color = "green";
  }
  return { ...dateColor };
};

class LeadReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportInfo: "",
      showLoader: false,
      reportData: [],
      reportMeta: {},
      selected: [],
      region: [],
      magnetName: [],
      region_id: "",
      source: [],
      presaleTl: [],
      salesTl: [],
      salesName: [],
      sale_rm: "",
      userName: [],
      user_id: "",
      SalesTlName: [],
      SrSalesTlName: [],
      sales_id: "",
      sr_sales_id:"",
      magnet: [],
      pre_sale_rm: "",
      magnet_rm_id: "",
      magnet_tl_id: "",
      magnet_ids: "",
      lead_type: [{id: "1", name: "digital", value: false},{id: "2", name: "added", value: false},{id: "3", name: "bulk", value:false}],
      updated_lead_type: [],
      option: [],
      project_ids: "",
      city: "",
      autoKey: true,
      is_presale: "",
      assign_type: "",
      is_revert: "",
      nationality: "",
      assignedDate_from: null,
      assignedDate_to: null,
      comingDate_from: null,
      comingDate_to: null,
      videoDate_from: null,
      videoDate_to: null,
      bookDate_from: null,
      bookDate_to: null,
      followupDate_from: null,
      followupDate_to: null,
      dumpDate_from: null,
      dumpDate_to: null,
      dumpAssignedDate_from: null,
      dumpAssignedDate_to: null,
      closed_from: null,
      closed_to: null,
      visitDate_from: null,
      visitDate_to: null,
      update_from: null,
      update_to: null,
      activity_from: null,
      activity_to: null,
      is_magnet: "",
      setPage: 0,
      isFetching: false,
      showFilter: true,
      showMaterialLoader: false,
      serviceRm: [],
      lead_percent:"",
      auditRm: [],
      audit_rm_id: "",
      service_rm_id: "",
      enrollRm: [],
      enroll_rm_id: "",
      uploadedBy: [],
      uploaded_by_id: "",
      is_opp:"",
      email: "",
      phone: "",
      resSize: 12,
      reportFltr: {},
      closing_reasons: [],
      is_audit: "",
      stage_ids: "",
      object_stage_name: (this.props.match.params.reportType === 'booking-detail' || this.props.match.params.reportType === 'digital-booking') ? "7" : "", 
      sort: {
        by: "",
        direction: ""
      },
      source_group: "",
      booking_project_id: "",
      agent_number: window.$user.phone ? '+91' + window.$user.phone : '+919111111111',
      alertShow: false,
      alertType: "success",
      alertMsg: "",
      activity_type: ""
      , activity_status_option: []
      , PreSalesTlName: []
      , presales_id: "",
      months:generateMonths(),
      month: '',
      years: generateYears(),
      year: '',
    };
  }

  reportFiterData = () => {
    let filterData = {};
    if (this.state.project_ids) filterData["project_ids"] = this.state.project_ids;
    if (this.state.booking_project_id) filterData["booking_project_id"] = this.state.booking_project_id;
    if (this.state.pre_sale_rm) filterData["presale_rm_ids"] = this.state.pre_sale_rm;
    if (this.state.magnet_rm_id) filterData["assign_id"] = this.state.magnet_rm_id;
    if (this.state.magnet_tl_id) filterData["magnet_tl_ids"] = this.state.magnet_tl_id;
    if (this.state.magnet_ids) filterData["magnet_id"] = this.state.magnet_ids;
    if (this.state.sale_rm) filterData["sale_rm_ids"] = this.state.sale_rm;
    if (this.state.service_rm_id) filterData["service_rm_ids"] = this.state.service_rm_id;
    if (this.state.audit_rm_id) filterData["audit_rm_ids"] = this.state.audit_rm_id;
    if (this.state.enroll_rm_id) filterData["enroll_rm_ids"] = this.state.enroll_rm_id;
    if (this.state.uploaded_by_id) filterData["uploaded_by"] = this.state.uploaded_by_id;
    if (this.state.is_opp) filterData["is_opp"] = this.state.is_opp;
    if(this.state.updated_lead_type.length > 0)
    { 
      filterData["lead_type"] = this.state.updated_lead_type;
    }
    if (this.state.selected.length > 0) {
      filterData["source_ids"] = this.state.selected;
    }
    if (this.state.source_group.length > 0) {
      filterData["source_ids"] = this.state.source_group;
    }

    if (this.state.city) filterData["city"] = this.state.city;

    if (this.state.region_id) filterData["region_ids"] = this.state.region_id;
    // fincial data filter
    if (this.state.year) filterData["year"] = this.state.year;
    if (this.state.month) filterData["month"] = this.state.month;
    
    if (this.state.is_presale) filterData["is_presale"] = parseInt(this.state.is_presale);
    if (this.state.nationality) filterData["nationality"] = parseInt(this.state.nationality);
    if (this.state.assign_type) filterData["assign_type"] = this.state.assign_type;
    if (this.state.is_revert) filterData["is_revert"] = parseInt(this.state.is_revert);
    if (this.state.is_magnet) filterData["is_magnet"] = parseInt(this.state.is_magnet);
    if (this.state.lead_percent) filterData["lead_percent"] = parseInt(this.state.lead_percent);
    if (this.state.user_id) filterData["assign_to"] = this.state.user_id;
    if (this.state.sales_id) filterData["sales_tl"] = this.state.sales_id;
    if (this.state.sr_sales_id) filterData["sr_sales_tl"] = this.state.sr_sales_id;
    if (this.state.presales_id) filterData["presales_tl"] = this.state.presales_id;
    if (this.state.activity_type) filterData["internal_status_id"] = this.state.activity_type;
    if (this.state.comingDate_from !== null) {
      filterData["created_at_from"] = this.state.comingDate_from
        .split("/")
        .join("-");
    }
    if (this.state.comingDate_to !== null) {
      filterData["created_at_to"] = this.state.comingDate_to
        .split("/")
        .join("-");
    }

    if (this.state.assignedDate_from !== null) {
      filterData["assigned_date_from"] = this.state.assignedDate_from
        .split("/")
        .join("-");
    }
    if (this.state.assignedDate_to !== null) {
      filterData["assigned_date_to"] = this.state.assignedDate_to
        .split("/")
        .join("-");
    }

    if (this.state.bookDate_from !== null) {
      filterData["booking_date_from"] = this.state.bookDate_from
        .split("/")
        .join("-");
    }
    if (this.state.bookDate_to !== null) {
      filterData["booking_date_to"] = this.state.bookDate_to
        .split("/")
        .join("-");
    }

    if (this.state.followupDate_from !== null) {
      filterData["followup_date_from"] = this.state.followupDate_from
        .split("/")
        .join("-");
    }
    if (this.state.followupDate_to !== null) {
      filterData["followup_date_to"] = this.state.followupDate_to
        .split("/")
        .join("-");
    }

    if (this.state.dumpDate_from !== null) {
      filterData["dump_at_from"] = this.state.dumpDate_from
        .split("/")
        .join("-");
    }
    if (this.state.dumpDate_to !== null) {
      filterData["dump_at_to"] = this.state.dumpDate_to.split("/").join("-");
    }
    if (this.state.dumpAssignedDate_from !== null) {
      filterData["dump_assigned_date_from"] = this.state.dumpAssignedDate_from
        .split("/")
        .join("-");
    }
    if (this.state.dumpAssignedDate_to !== null) {
      filterData["dump_assigned_date_to"] = this.state.dumpAssignedDate_to
        .split("/")
        .join("-");
    }
    if (this.state.update_from !== null) {
      filterData["updated_at_from"] = this.state.update_from
        .split("/")
        .join("-");
    }
    if (this.state.update_to !== null) {
      filterData["updated_at_to"] = this.state.update_to.split("/").join("-");
    }
    if (this.state.closed_from !== null) {
      filterData["closed_at_from"] = this.state.closed_from
        .split("/")
        .join("-");
    }
    if (this.state.closed_to !== null) {
      filterData["closed_at_to"] = this.state.closed_to.split("/").join("-");
    }

    if (this.state.activity_from !== null) {
      filterData["activity_date_from"] = this.state.activity_from
        .split("/")
        .join("-");
    }
    if (this.state.activity_to !== null) {
      filterData["activity_date_to"] = this.state.activity_to
        .split("/")
        .join("-");
    }

    if (this.state.email) filterData["email"] = this.state.email;
    if (this.state.phone) filterData["phone"] = parseInt(this.state.phone);

    if (this.state.closing_reasons.length > 0) {
      filterData["closing_reasons"] = this.state.closing_reasons;
    }

    if (this.state.videoDate_from && this.state.videoDate_to) {
      filterData["video_date"] = {
        to: this.state.videoDate_to.split("/").join("-"),
        from: this.state.videoDate_from.split("/").join("-")
      };
    }
    if (this.state.is_audit) filterData["is_audit"] = this.state.is_audit;
    if (this.state.stage_ids) filterData["stage_ids"] = this.state.stage_ids;
    if (this.state.object_stage_name) filterData["object_stage_name"] = this.state.object_stage_name;
    return filterData;
  };

  async componentDidMount() {
    let reportObj = {};
    reportObj.data = {};
    let reportInfo = this.props.match.params.reportType;
    localStorage.removeItem("rprtfltr");
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let todayDate = new Date();
    todayDate = moment(todayDate).format("YYYY-MM-DD");
    firstDay = moment(firstDay).format("YYYY-MM-DD");

    if (
      reportInfo === "activity" ||
      reportInfo === "project-activity-rm" ||
      reportInfo === "source-activity" ||
      reportInfo === "presale-activity" ||
      reportInfo === "magnet-activity" ||
      reportInfo === "digital-activity"
    ) {
      await this.setState({ activity_from: firstDay, activity_to: todayDate });
    } else if (
      reportInfo === "update" ||
      reportInfo === "source-update" ||
      reportInfo === "presale-update" ||
      reportInfo === "hourly-update"
    ) {
      await this.setState({ update_from: todayDate, update_to: todayDate });
    } else if (reportInfo === "followup") {
      await this.setState({
        followupDate_from: firstDay,
        followupDate_to: todayDate
      });
    } else if (
      reportInfo === "booking" ||
      reportInfo === "booking-detail" ||
      reportInfo === "digital-booking" ||
      reportInfo === "source-booking" ||
      reportInfo === "presale-booking"
    ) {
      await this.setState({ bookDate_from: firstDay, bookDate_to: todayDate });
    } else if (reportInfo === "databank" || reportInfo === "magnet-databank") {
      await this.setState({
        dumpAssignedDate_from: firstDay,
        dumpAssignedDate_to: todayDate
      });
    } else if (
      reportInfo === "lost-sales" ||
      reportInfo === "presale-lost-sales" ||
      reportInfo === "project-lost-sales"
    ) {
      await this.setState({ closed_from: firstDay, closed_to: todayDate });
    } else {
      await this.setState({
        comingDate_from: firstDay,
        comingDate_to: todayDate
      });
    }

    this.setState({
      showLoader: true,
      reportInfo: reportInfo
    });
    let filterData = this.reportFiterData();
    reportObj["reportType"] = reportInfo;
    reportObj["id"] = window.$user.id;
    reportObj["filters"] = filterData;
    this.setState({
      reportFltr: { filter: filterData, reportType: this.state.reportInfo }
    });
    if (reportInfo === "magnet-list") {
      reportObj["data"]["page_id"] = 1;
      reportObj["data"]["size"] = 24;
    }

    var regionData = await getRegion();
    this.setState({ region: regionData });

    var allSourceData = await getLeadSourceData();
    this.setState({ source: allSourceData.data });

    var leadReportRes = await getReportData(reportObj);

    if (leadReportRes.meta.status === 200) {
      if(this.props.match.params.reportType && (this.props.match.params.reportType !== 'digital-booking' && this.props.match.params.reportType !== 'booking-detail' && this.props.match.params.reportType !== 'digital-activity')){
        leadReportRes.data = this.setTotalRows(leadReportRes.data);
      }
      this.setState({
        isLoading: false,
        showLoader: false,
        reportData: leadReportRes.data,
        reportMeta: leadReportRes.meta
      });
    } else if (leadReportRes.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      leadReportRes.data = this.setTotalRows(leadReportRes.data);
      this.setState({
        isLoading: false,
        showLoader: false,
        reportData: leadReportRes.data,
        reportMeta: leadReportRes.meta
      });
    }
    if (reportInfo === "magnet-list") {
      this.setState({ setPage: this.state.setPage + 1 });
    }
    if (reportInfo === "audit") {
      await this.handleAutoAuditChange();
    }
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);

    let getActivity = await leadInterautoComplete('lead_internal_status');
    this.setState({
      activity_status_option: getActivity.data
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isFetching !== this.state.isFetching &&
      this.state.resSize >= 12
    ) {
      if (this.state.reportInfo === "magnet-list")
        this.fetchMoreListItems(filterData);
    }
  }

  setTotalRows = reportData => {
    if (reportData?.length > 0 && (reportData[0].user_name !== "Total" || reportData[0]?.Project !== "Total" )) {
      let totalColumn = [...reportData].slice(-1).pop();

      if (totalColumn && totalColumn.user_name !== undefined) {
        totalColumn.user_name = "Total";        
      } else if (totalColumn && totalColumn?.Project !== undefined) {
        totalColumn.Project = "Total";
      }
      totalColumn.id = " ";

        return [totalColumn, ...reportData];
    }

    return reportData;
  };

  fetchMoreListItems = async mgfilterData => {
    let reportObj = {};
    reportObj.data = {};
    let reportInfo = this.props.match.params.reportType;

    this.setState({
      showMaterialLoader: true,
      reportInfo: reportInfo
    });
    let page = this.state.setPage + 1;
    reportObj["reportType"] = reportInfo;
    reportObj["data"]["page_id"] = page;
    reportObj["data"]["size"] = 12;
    reportObj["id"] = window.$user.id;

    Object.assign(reportObj["data"], mgfilterData);
    var leadReportRes = await getReportData(reportObj);

    if (leadReportRes.meta.status === 200) {
      let leadReport = this.setTotalRows([
        ...this.state.reportData,
        ...leadReportRes.data
      ]);
      this.setState({
        isLoading: false,
        showMaterialLoader: false,
        setPage: page,
        resSize: leadReportRes.data.length,
        reportData: leadReport,
        reportMeta: leadReportRes.meta
      });
    } else if (leadReportRes.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      leadReportRes.data = this.setTotalRows(leadReportRes.data);
      this.setState({
        isLoading: false,
        resSize: 0,
        showMaterialLoader: false,
        reportData: leadReportRes.data,
        reportMeta: leadReportRes.meta
      });
    }
    this.setState({ isFetching: false });
  };

  handleAutoUserChange = async e => {
    this.setState({ isLoading: true });
    let data = {
      q: e.target.value,
      users_active: 1
    };
    data.roles = [REVERT_ROLES.SALES_HEAD, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3, REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM]
    if(this.props.match.params.reportType === 'digital-booking'){
      data.roles = [REVERT_ROLES.PNL_HEAD,REVERT_ROLES.CLOSING_S1,REVERT_ROLES.SALES_TL,REVERT_ROLES.SALES_MANAGER]
    }
    if(this.props.match.params.reportType === 'digital-activity'){
       data.roles = [REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.SALES_TL, REVERT_ROLES.CLOSING_S1]
    }
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isLoading: false });
      this.setState({ userName: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isLoading: false });
      this.setState({ userName: resData.data })
    }
  };

  handleCommonAutoUserChange = async e => {
    this.setState({ isSalesTlLoading: true });

    let data = {
      q: e.target.value,
      roles: [
        REVERT_ROLES.SALES_TL,
        REVERT_ROLES.SALES_MANAGER,
      ]
    };

    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isSalesTlLoading: false });
      this.setState({ SalesTlName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isSalesTlLoading: false });
      this.setState({ SalesTlName: resData.data });
    }
  };
  handleCommonAutosrSalesTlChange = async e => {
    this.setState({ isSalesTlLoading: true });

    let data = {
      q: e.target.value,
      roles: [
        REVERT_ROLES.SENIOR_SALES_TL,
      ]
    };

    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isSalesTlLoading: false });
      this.setState({ SrSalesTlName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isSalesTlLoading: false });
      this.setState({ SrSalesTlName: resData.data });
    }
  };

  handleCommonAutoPresaleUserChange = async e => {
    this.setState({ isPreSalesTlLoading: true });

    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.SOURCING_S2, REVERT_ROLES.MAGNET_S2]
    };
    if(this.props.match.params.reportType === 'digital-booking'){
      data.roles = [REVERT_ROLES.SOURCING_S2,REVERT_ROLES.SOURCING_S3]
    }

    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isPreSalesTlLoading: false });
      this.setState({ PreSalesTlName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isPreSalesTlLoading: false });
      this.setState({ PreSalesTlName: resData.data });
    }
  };

  handleAutoChange = async e => {
    this.setState({ isLoading: true });
    let value = e.target.value;

    var projectListData = await getAutoCompleteProject(value);

    if (projectListData.meta.status === 200) {
      this.setState({ isLoading: false });
      this.setState({ option: projectListData.data });
    } else if (projectListData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isLoading: false });
      this.setState({ option: [] });
    }
  };

  handleAutoMagnetRM = async e => {
    this.setState({ isMagnetRMLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET_RM]
    };
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ magnet: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ magnet: [] });
    }
  };

  handleAutoMagnetTL = async e => {
    this.setState({ isMagnetRMLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET_TL]
    };
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ magnet: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ magnet: [] });
    }
  };


  handleAutoMagnetChange = async e => {
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET]
    };
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ magnetName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ magnetName: [] });
    }
  };

  handleAutoPresaleRmUser = async e => {
    let data = {
      q: e.target.value,
      roles: [
        REVERT_ROLES.SOURCING_S2,
        REVERT_ROLES.SOURCING_S3,
        REVERT_ROLES.MAGNET_S2,
        REVERT_ROLES.MAGNET_S3,
      ]
    };
    if(this.props.match.params.reportType === 'digital-activity'){
      data.roles = [ REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3 ] 
    }
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ presaleTl: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ presaleTl: [] });
    }
  };

  handleAutoSalesChange = async e => {
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1]
    };
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ salesName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ salesName: resData.data });
    }
  };

  handleAutoServiceChange = async e => {
    this.setState({ isServiceLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM]
    };
    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isServiceLoading: false });
      this.setState({ serviceRm: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isServiceLoading: false });
      this.setState({ serviceRm: resData.data });
    }
  };

  handleAutoAuditChange = async e => {
    this.setState({ isAuditLoading: true });
    let data = {
      // q: e.target.value,
      q: '',
      roles: [REVERT_ROLES.OPERATIONS_ANALYST]
    };
    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isAuditLoading: false });
      this.setState({ auditRm: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isAuditLoading: false });
      this.setState({ auditRm: resData.data });
    }
  };

  handleAutoEnrollChange = async e => {
    this.setState({ isEnrollLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM]
    };
    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isEnrollLoading: false });
      this.setState({ enrollRm: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isEnrollLoading: false });
      this.setState({ enrollRm: resData.data });
    }
  };

  handleAutoUploadedChange = async e => {
    this.setState({ isLoading: true });
    let data = {
      q: e.target.value,
      roles: [
        REVERT_ROLES.MAGNET_TL,
        REVERT_ROLES.MAGNET_RM,
        REVERT_ROLES.MAGNET
      ]
    };
    let resData = await getUserAutoComplete(data);

    if (resData.meta.status === 200) {
      this.setState({ isLoading: false });
      this.setState({ uploadedBy: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isLoading: false });
      this.setState({ uploadedBy: resData.data });
    }
  };

  handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
      document.documentElement.offsetHeight ||
      this.state.isFetching
    )
      return;
    this.setState({ isFetching: true });
  };

  applyFilter = async () => {
    this.setState({
      showFilter: false,
      showLoader: true,
      sort: {
        by: "",
        direction: ""
      }
    });

    filterData = this.reportFiterData();
    let activityFilter = {};

    activityFilter["filters"] = filterData;
    activityFilter["reportType"] = this.state.reportInfo;
    activityFilter["id"] = window.$user.id;
    this.setState({
      reportFltr: { filter: filterData, reportType: this.state.reportInfo }
    });
    var leadReportRes = await getReportData(activityFilter);
    this.setState({ setPage: 0 });
    if (this.state.reportInfo === "magnet-list") {
      if (leadReportRes.meta.status === 200) {
        leadReportRes.data = this.setTotalRows(leadReportRes.data);
        this.setState({
          isLoading: false,
          showLoader: false,
          reportData: leadReportRes.data,
          reportMeta: leadReportRes.meta
        });
        this.fetchMoreListItems(filterData);
      }
    } else {
      if (leadReportRes.meta.status === 200) {
        if(this.props.match.params.reportType && (this.props.match.params.reportType != 'booking-detail' && this.props.match.params.reportType != 'digital-detail' && this.props.match.params.reportType != 'digital-activity')){
          leadReportRes.data = this.setTotalRows(leadReportRes.data);
        }
        this.setState({
          isLoading: false,
          showLoader: false,
          reportData: leadReportRes.data,
          reportMeta: leadReportRes.meta
        });
        window.scrollTo(0, 0);
      } else if (leadReportRes.meta.status === 401) {
        cleanStorage();
        this.props.history.push("/login");
      } else {
        leadReportRes.data = this.setTotalRows(leadReportRes.data);
        this.setState({
          isLoading: false,
          showLoader: false,
          reportData: leadReportRes.data,
          reportMeta: leadReportRes.meta
        });
        window.scrollTo(0, 0);
      }
    }
  };

  redirect =  (id, key, val) => {
    if(key === 'Magnet' && this.state.reportInfo === "magnet-current-status"){
      this.handleCall(id,val);
      return true;
    }
    console.log('ocean >>>>');
    let reportFltr = this.state.reportFltr;
    if (objEmpty(reportFltr)) {
      reportFltr.filter = {};
      reportFltr.filter.assign_id = id;
    } else {
      reportFltr.filter.assign_id = id;
    }
    reportFltr.filter.redirect_from = "reports";

    if (this.state.reportInfo === "current-status") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.stage_ids;
      delete reportFltr.filter.internal_status_ids;
      if (key === "total") {
        reportFltr.filter["stage_ids"] = [2, 7, 8, 9, 7, 6, 15, 1, 3, 5, 10];
      } else if (key === "total open") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 10, 15];
      } else if (key === "HPOP arranged") {
        reportFltr.filter["internal_status_ids"] = [1];
      } else if (key === "HPOP Done") {
        reportFltr.filter["internal_status_ids"] = [2];
      } else if (key === "Visit arranged") {
        reportFltr.filter["internal_status_ids"] = [3];
      } else if (key === "Visit Done") {
        reportFltr.filter["internal_status_ids"] = [4];
      } else if (key === "Revisit arranged") {
        reportFltr.filter["internal_status_ids"] = [5];
      } else if (key === "Revisit Done") {
        reportFltr.filter["internal_status_ids"] = [6];
      } else if (key === "video call arranged") {
        reportFltr.filter["internal_status_ids"] = [7];
      } else if (key === "video call done") {
        reportFltr.filter["internal_status_ids"] = [8];
      } else if (key === "total Activity done") {
        reportFltr.filter["internal_status_ids"] = [[2, 4, 6, 8]];
      } else if (key === "total assigned") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 9, 10, 15];
      } else if (key in REPORT_STAGE_IDS_STRING) {
        reportFltr.filter["stage_ids"] = REPORT_STAGE_IDS_STRING[key];
      }
    } else if (this.state.reportInfo === "project-rm") {
      if (
        reportFltr.filter.assign_to === null ||
        reportFltr.filter.assign_to === undefined
      ) {
        delete reportFltr.filter.assign_id;
      } else {
        reportFltr.filter.assign_id = reportFltr.filter.assign_to;
      }

      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;

      reportFltr.filter.project_ids = id;

      if (key === "total") {
        reportFltr.filter["stage_ids"] = [2, 7, 8, 9, 7, 6, 15, 1, 3, 5, 10];
      } else if (key === "total open") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 10, 15];
      } else if (key in REPORT_STAGE_IDS_STRING) {
        reportFltr.filter["stage_ids"] = REPORT_STAGE_IDS_STRING[key];
      }
    } else if (this.state.reportInfo === "project-activity-rm") {
      if (
        reportFltr.filter.assign_to === null ||
        reportFltr.filter.assign_to === undefined
      ) {
        delete reportFltr.filter.assign_id;
      } else {
        reportFltr.filter.assign_id = reportFltr.filter.assign_to;
      }

      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;

      delete reportFltr.filter.internal_status_ids;
      delete reportFltr.filter.stage_ids;

      reportFltr.filter.project_ids = id;

      if (key in LEAD_INTERNAL_STATUS) {
        reportFltr.filter["internal_status_ids"] = LEAD_INTERNAL_STATUS[key];
      } else if (key === "total Activity") {
        reportFltr.filter["internal_status_ids"] = [2, 4, 6, 8];
      } else if (key === "total assigned") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 9, 10, 15];
      }
    } else if (this.state.reportInfo === "followup") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;
      for (let followupKey in REPORT_FOLLOWUP_KEYS) {
        delete reportFltr.filter[REPORT_FOLLOWUP_KEYS[followupKey]];
      }
      if (key in REPORT_FOLLOWUP_KEYS) {
        reportFltr.filter[REPORT_FOLLOWUP_KEYS[key]] =
          REPORT_FOLLOWUP_KEYS[key];
      }
    } else if (this.state.reportInfo === "magnet-current-status") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.magnet_rm_id;
      delete reportFltr.filter.magnet_tl_ids;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.assign_id;
      delete reportFltr.filter.stage_ids;
      delete reportFltr.filter.magnet_id;
      delete reportFltr.filter.internal_status_ids;
      delete reportFltr.filter.is_opp;
      delete reportFltr.filter.is_pipe;
      reportFltr.filter.magnet_id = id;

      if (key === "total") {
        reportFltr.filter["stage_ids"] = [2, 7, 8, 9, 7, 6, 15, 1, 3, 5, 10];
      } else if (key === "total open") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 10, 15];
      } else if (key in REPORT_STAGE_IDS_STRING) {
        reportFltr.filter["stage_ids"] = REPORT_STAGE_IDS_STRING[key];
      }
      if(key === 'is_opp')
      {
        reportFltr.filter.is_opp = val;
      }
      if(key === 'is_pipe'){
        reportFltr.filter.is_pipe = val;
      }
    } else if (this.state.reportInfo === "presale-current-status") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.assign_id;
      delete reportFltr.filter.stage_ids;
      delete reportFltr.filter.internal_status_ids;
      reportFltr.filter.presale_rm_ids = id;

      if (key === "total") {
        reportFltr.filter["stage_ids"] = [2, 7, 8, 9, 7, 6, 15, 1, 3, 5, 10];
      } else if (key === "total open") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 10, 15];
      } else if (key === "HPOP arranged") {
        reportFltr.filter["internal_status_ids"] = [1];
      } else if (key === "HPOP Done") {
        reportFltr.filter["internal_status_ids"] = [2];
      } else if (key === "Visit arranged") {
        reportFltr.filter["internal_status_ids"] = [3];
      } else if (key === "Visit Done") {
        reportFltr.filter["internal_status_ids"] = [4];
      } else if (key === "Revisit arranged") {
        reportFltr.filter["internal_status_ids"] = [5];
      } else if (key === "Revisit Done") {
        reportFltr.filter["internal_status_ids"] = [6];
      } else if (key === "video call arranged") {
        reportFltr.filter["internal_status_ids"] = [7];
      } else if (key === "video call done") {
        reportFltr.filter["internal_status_ids"] = [8];
      } else if (key === "total activity done") {
        reportFltr.filter["internal_status_ids"] = [2, 4, 6, 8];
      } else if (key in REPORT_STAGE_IDS_STRING) {
        reportFltr.filter["stage_ids"] = REPORT_STAGE_IDS_STRING[key];
      }
    } else if (this.state.reportInfo === "update") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.stage_ids;

      if (key === "total") {
        reportFltr.filter["stage_ids"] = [2, 7, 8, 9, 7, 6, 15, 1, 3, 5, 10];
      } else if (key === "open") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 10, 15];
      } else if (key in REPORT_STAGE_IDS_STRING) {
        reportFltr.filter["stage_ids"] = REPORT_STAGE_IDS_STRING[key];
      }
    } else if (this.state.reportInfo === "booking") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.is_magnet;
      delete reportFltr.filter.is_presale;
      delete reportFltr.filter.is_crosssale;
      if(window.$role === "presalehead"){
        delete reportFltr.filter.assign_id;
        reportFltr.filter.presale_rm_ids = id;
      }
      reportFltr.filter.stage_ids = 7;
      if (key === "Booked") {
        reportFltr.filter["stage_ids"] = [7];
      } else if (key === "Non-Magnet") {
        reportFltr.filter.is_magnet = 0;
      } else if (key === "Magnet") {
        reportFltr.filter.is_magnet = 1;
      } else if (key === "Pre-sale") {
        reportFltr.filter.is_presale = 1;
      } else if (key === "Cross-sale") {
        reportFltr.filter.is_crosssale = 1;
      }
    } else if (this.state.reportInfo === "presale-booking") {
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.assign_id;
      delete reportFltr.filter.is_magnet;
      delete reportFltr.filter.is_presale;
      delete reportFltr.filter.is_crosssale;
      reportFltr.filter.presale_rm_ids = id;

      reportFltr.filter.stage_ids = 7;
      if (key === "Booked") {
        reportFltr.filter["stage_ids"] = [7];
      } else if (key === "Non Magnet") {
        reportFltr.filter.is_magnet = 0;
      } else if (key === "Magnet") {
        reportFltr.filter.is_magnet = 1;
      } else if (key === "pre sale") {
        reportFltr.filter.is_presale = 1;
      } else if (key === "cross sale") {
        reportFltr.filter.is_crosssale = 1;
      }
    } else if (this.state.reportInfo === "audit") {
      // delete reportFltr.filter.region_ids;
      delete reportFltr.filter.project_ids;
      delete reportFltr.filter.audit_rm_ids;
      delete reportFltr.filter.assign_id;
      reportFltr.filter.region_ids = id;
      if (key === "Total") {
        delete reportFltr.filter.is_audit_close
        delete reportFltr.filter.is_audit_new_lead
        delete reportFltr.filter.stage_ids
        delete reportFltr.filter.audit_followup
        reportFltr.filter["is_audit"] = 1;
      } else if (key === "closed") {
        delete reportFltr.filter.is_audit
        delete reportFltr.filter.is_audit_new_lead
        delete reportFltr.filter.stage_ids
        delete reportFltr.filter.audit_followup
        reportFltr.filter["is_audit_close"] = 1;
      } else if (key === "reactivate") {
        delete reportFltr.filter.is_audit
        delete reportFltr.filter.is_audit_close
        delete reportFltr.filter.stage_ids
        delete reportFltr.filter.audit_followup
        reportFltr.filter["is_audit"] = 1;
        reportFltr.filter["is_audit_new_lead"] = 1;
      } else if (key === "reactivated closed") {
        delete reportFltr.filter.is_audit
        delete reportFltr.filter.is_audit_close
        delete reportFltr.filter.is_audit_new_lead
        delete reportFltr.filter.audit_followup
        reportFltr.filter["is_audit"] = 1;
        reportFltr.filter["stage_ids"] = [9];
      }
      else if (key === "followup count") {
        delete reportFltr.filter.is_audit
        delete reportFltr.filter.is_audit_close
        delete reportFltr.filter.is_audit_new_lead
        delete reportFltr.filter.stage_ids
        delete reportFltr.filter.audit_followup
        reportFltr.filter["audit_followup"] = 1;
      }

    } else if (this.state.reportInfo === "magnet-activity") {
      delete reportFltr.filter.assign_id;
      delete reportFltr.filter.presales_tl;
      delete reportFltr.filter.sales_tl;
      delete reportFltr.filter.presale_rm_ids;
      delete reportFltr.filter.assign_to;
      delete reportFltr.filter.magnet_tl_ids;
      delete reportFltr.filter.internal_status_ids;
      delete reportFltr.filter.stage_ids;
      delete reportFltr.filter.magnet_id;


      reportFltr.filter.magnet_id = id;
      if (key in LEAD_INTERNAL_STATUS) {
        reportFltr.filter["internal_status_ids"] = LEAD_INTERNAL_STATUS[key];
      } else if (key === "total") {
        reportFltr.filter["internal_status_ids"] = [1, 2, 3, 4, 5, 6, 7, 8];
      } else if (key === "total Assigned") {
        reportFltr.filter["stage_ids"] = [1, 2, 3, 4, 5, 6, 9, 10, 15];
      }   
    }
    reportFltr.reportType = this.state.reportInfo;

    localStorage.setItem("rprtfltr", btoa(JSON.stringify(reportFltr)));
    let goToUrl = `${window.location.origin}/#/leads/all/report`;
    window.open(goToUrl, "_blank");
  };

  resetFilter = () => {
    this.setState({
      autoKey: !this.state.autoKey,
      project_ids: "",
      pre_sale_rm: "",
      magnet_rm_id: "",
      magnet_tl_id: "",
      magnet_ids: "",
      sale_rm: "",
      region_id: "",
      isLoading: false,
      userName: [],
      user_id: "",
      SalesTlName: [],
      sales_id: "",
      sr_sales_id: "",
      selected: [],
      assignedDate_from: null,
      assignedDate_to: null,
      comingDate_from: null,
      comingDate_to: null,
      activity_from: null,
      activity_to: null,
      bookDate_from: null,
      bookDate_to: null,
      followupDate_from: null,
      followupDate_to: null,
      dumpDate_from: null,
      dumpDate_to: null,
      dumpAssignedDate_from: null,
      dumpAssignedDate_to: null,
      closed_from: null,
      closed_to: null,
      update_from: null,
      update_to: null,
      visitDate_from: null,
      visitDate_to: null,
      hpOpDate_from: null,
      hpOpDate_to: null,
      videoDate_from: null,
      videoDate_to: null,
      city: "",
      is_presale: "",
      assign_type: "",
      is_revert: "",
      nationality: "",
      is_magnet: "",
      lead_percent:"",
      lead_type:[{id: "1", name: "digital"},{id: "2", name: "added"},{id: "3", name: "bulk",}],
      updated_lead_type:[],
      service_rm_id: "",
      audit_rm_id: "",
      enroll_rm_id: "",
      uploaded_by_id: "",
      email: "",
      phone: "",
      closing_reasons: [],
      is_audit: "",
      stage_ids: "",
      source_group: "",
      booking_project_id: ""
      , activity_type: ""
      , PreSalesTlName: []
      , presales_id: "",
      year:'',
      month:'',
      is_opp:'',
    });
  };

  handleDateChange = (key, date) => {
    this.setState({ [key]: date });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleCheckboxChange = (event) => {
    let {updated_lead_type} = this.state;
    let tempArr = [...updated_lead_type];
    tempArr.push(event.target.value);
    this.setState({
      updated_lead_type: event.target.value
   });
  }
  getStartDateMin = () => {
    if (this.state.editAd) {
      return null;
    } else {
      let d = new Date();
      d.setDate(d.getDate() - 15);
      return d;
    }
  };

  getStartDateMax = () => {
    if (this.state.editAd) {
      return null;
    } else {
      let d = new Date();
      d.setDate(d.getDate() + 30);
      return d;
    }
  };

  sortReport = (sortBy, direction) => {
    if (this.state.reportData.length) {
      let reportData = [...this.state.reportData];
      let firstRow;
      let lastRow;
      if (
        reportData[0].user_name &&
        reportData[0].user_name.toLowerCase() === "total"
      ) {
        firstRow = { ...reportData[0] };
        reportData.shift();
      }

      if (
        reportData[reportData.length - 1].user_name &&
        reportData[reportData.length - 1].user_name.toLowerCase() === "total"
      ) {
        lastRow = { ...reportData[reportData.length - 1] };
        reportData.pop();
      }

      reportData = _.sortBy(reportData, [sortBy]);

      if (direction === "desc") {
        reportData.reverse();
      }

      if (firstRow && Object.values(firstRow).length) {
        reportData = [firstRow, ...reportData];
      }

      if (lastRow && Object.values(lastRow).length) {
        reportData = [...reportData, lastRow];
      }

      this.setState({
        reportData,
        sort: { by: sortBy, direction },
        showLoader: false
      });
    }
  };


  handleSourceChange = (event) => {
    const { selected, source } = this.state;
    if (
      event.target.value.includes("all") &&
      selected.length !== source.length
    ) {
      this.setState({ selected: source.map((name) => name.id) });
    } else if (event.target.value.includes("all")) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: event.target.value });
    }
  };

  getSelectValues = (selected) => {
    const { source } = this.state;
    return source
      .filter((name) => selected.includes(name.id))
      .map((record) => record.name)
      .join(", ");
  }
  getSelectedLeadId = (updated_lead_type) => {
   const {lead_type} = this.state;
   return lead_type
          .filter((type) => updated_lead_type.includes(type.id))
          .map((record) => record.name)
          .join(", ");
        }
  handleCall = async (magnetNumber,magnetId) => {
    const roleAccess = ["magnettl", "magnetrm", "magnetadmin", "magnetpnl", "admin"];
    const { agent_number } = this.state;
    if (roleAccess.includes(window.$user.role)) {
      let data = {}
      if (window.$user.id) {
        data["user_id"] = window.$user.id;
      }
      data["object_id"] = magnetId;
      data["agent_number"] = agent_number
      data["customer_number"] = magnetNumber.length == 10 ? `+91${magnetNumber}`:magnetNumber; 
      data["type"] = 2;
      data["calling_type"] = 'magnet';
      data['stage_id'] = 1;
      this.setState({showLoader:true});
      let resData = await getCallWithKaleyra(data)
      if (resData.meta.status === 201) {
        let message = resData.data?.message;
        this.setState({
          showLoader: false,
          alertShow: true,
          alertType: message?.includes('Failed') ? 'warning' : "success",
          alertMsg: resData.data?.message || "Call placed successfully!",
        });
      } else {
        this.setState({
          showLoader: false,
          alertShow: true,
          alertType: "error",
          alertMsg: resData.meta?.message || "Sorry something went wrong!",
        });
      }
    }
  }
  handleClose = () => {
    this.setState({alertShow: false })
  }

  onDownload = (e) => {
    const link = document.createElement("a");
    let queryParams = Object.entries(this.reportFiterData()).map(([key, value]) => {
      return key + "=" + value;
    }).join("&");
    link.href = CORE_API_URL +`/reports/${e}/${window?.$user?.id}/download`+ '?' + queryParams;
    link.click();
  };
	
  render() {
    const { count, status } = this.state.reportMeta;
    const {
      showMaterialLoader,
      update_from,
      update_to,
      activity_from,
      activity_to,
      salesName,
      presaleTl,
      magnet,
      magnetName,
      reportData,
      source,
      userName,
      SalesTlName,
      SrSalesTlName,
      autoKey,
      is_presale,
      assign_type,
      is_revert,
      nationality,
      assignedDate_from,
      assignedDate_to,
      comingDate_from,
      comingDate_to,
      bookDate_from,
      bookDate_to,
      followupDate_from,
      followupDate_to,
      dumpDate_from,
      dumpDate_to,
      dumpAssignedDate_from,
      closed_from,
      closed_to,
      dumpAssignedDate_to,
      region,
      is_magnet,
      is_opp,
      lead_percent,
      lead_type,
      updated_lead_type,
      option,
      isLoading,
      reportInfo,
      serviceRm,
      auditRm,
      enrollRm,
      uploadedBy,
      email,
      phone,
      closing_reasons,
      is_audit,
      stage_ids,
      selected,
      source_group,
      object_stage_name,
      alertType, alertMsg, alertShow
      , activity_type
      , activity_status_option
      , PreSalesTlName
      , isSalesTlLoading
      , isPreSalesTlLoading
    } = this.state;
    let isAllSelected = source.length > 0 && selected.length === source.length;
    const sortAvailableOn = [
      "activity",
      "booking",
      "current-status",
      "databank",
      "followup",
      "hourly-update",
      "update",
      "presale-activity",
      "presale-booking",
      "presale-current-status"
      , "audit"
    ];

    return (
      <Frag>
        <div className="content-wrapper">
          <Loader show={this.state.showLoader} />
          <PopUpPortal type={alertType} msg={alertMsg} open={alertShow} handleClose={this.handleClose} hideTime={6000} />
          <div className="container-fluid mb-3">
            <div className="row">
              <div className="col-md-12 mt-3 mb-2 text-center">
                <div
                  className="fs-16 cursor-point text-center"
                  onClick={() => {
                    this.setState({ showFilter: !this.state.showFilter });
                  }}
                >
                  {this.props.match.params.reportType
                    .split("-")
                    .join(" ")
                    .toUpperCase()}{" "}
                  FILTER
                  {this.state.showFilter === true ? (
                    <ExpandLessIcon className="fs-26" />
                  ) : (
                    <ExpandMoreIcon className="fs-26" />
                  )}
                </div>
              </div>
            </div>
            <Paper
              elevation={3}
              className={"px-2 " + (this.state.showFilter ? "show" : "hide")}
            >
              <div className="row pt-3">
                {this.state.reportInfo === "current-status" && (
                  <div className="col-md-2 col-6 mb-3">
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Closing Reason
                      </InputLabel>
                      <Select
                        multiple
                        labelId="demo-controlled-open-select-label"
                        name="closings"
                        value={closing_reasons ? closing_reasons : []}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "closing_reasons",
                          id: "closing_reasons"
                        }}
                      >
                        <MenuItem value="low budget">
                          <em>None</em>
                        </MenuItem>
                        {CLOSING_REASON.map((reason, i) => (
                          <MenuItem key={i} value={reason}>
                            {reason}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {(window.$user.role.toLowerCase() !== "closings1" &&
                  window.$user.role.toLowerCase() !== "financehead" &&
                  window.$user.role.toLowerCase() !== "sourcings3" &&
                  window.$user.role.toLowerCase() !== "magnet s3" &&
                  window.$user.group.toLowerCase() !== "magnet" ||
                  ((window.$user.role.toLowerCase() === "magnetadmin" || window.$user.role.toLowerCase() === "magnetpnl") &&
                    this.state.reportInfo === "update") &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "presale-activity" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "magnet-spend")
                  && (
                    <div className="col-md-2 col-6 mb-3">
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="UserData"
                        getOptionSelected={(userName, value) =>
                          userName.name === value.name
                        }
                        getOptionLabel={userName =>
                          userName.name + " (" + userName.phone + ")"
                        }
                        options={userName}
                        loading={isLoading}
                        renderTags={(value, getTagProps) =>
                          value.map((userName, index) => (
                            <Chip
                              className="text-capitalize"
                              label={userName.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, userName) => {
                          if (userName === null) {
                            this.setState({
                              userName: [],
                              user_id: ""
                            });
                          } else {
                            var projectId = userName.map(ops => ops.id);
                            this.setState({
                              user_id: projectId
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={
                              this.state.reportInfo === "activity"
                                ? "Activity Done By"
                                : this.state.reportInfo === "magnet-databank"
                                  ? "Assigned Caller"
                                  : "RM Name"
                            }
                            fullWidth
                            onChange={this.handleAutoUserChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                {window.$user.role.toLowerCase() !== "financehead" &&
                  this.state.reportInfo !== "current-status" &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "activity" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-databank" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "followup" &&
                  this.state.reportInfo !== "project-rm" &&
                  this.state.reportInfo !== "project-activity-rm" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "databank" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "magnet-spend" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <Autocomplete
                          multiple
                          key={autoKey}
                          id="presaleRmName"
                          getOptionSelected={(presaleTl, value) =>
                            presaleTl.name === value.name
                          }
                          getOptionLabel={presaleTl =>
                            presaleTl.name + " (" + presaleTl.phone + ")"
                          }
                          options={presaleTl}
                          renderTags={(value, getTagProps) =>
                            value.map((presaleTl, index) => (
                              <Chip
                                label={presaleTl.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(_event, presaleTl) => {
                            var presaleId = presaleTl.map(ops => ops.id);
                            this.setState({
                              pre_sale_rm: presaleId
                            });

                            if (option === null) {
                              this.setState({ presaleTl: [] });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Presales"
                              fullWidth
                              onChange={this.handleAutoPresaleRmUser}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                {this.state.reportInfo !== "presale-current-status" &&
                  this.state.reportInfo !== "presale-lost-sales" &&
                  this.state.reportInfo !== "project-lost-sales" &&
                  this.state.reportInfo !== "presale-followup" &&
                  this.state.reportInfo !== "presale-activity" &&
                  this.state.reportInfo !== "presale-booking" &&
                  this.state.reportInfo !== "presale-update" &&
                  window.$user.role.toLowerCase() !== "closings1" &&
                  window.$user.role.toLowerCase() !== "financehead" &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-databank" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "project-activity-rm" &&
                  this.state.reportInfo !== "project-rm" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "magnet-spend" &&
                  this.state.reportInfo !== "digital-activity" && (
                    <div className="col-md-2 col-6 mb-3">
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="SalesTlData"
                        getOptionSelected={(SalesTlName, value) =>
                          SalesTlName.name === value.name
                        }
                        getOptionLabel={SalesTlName =>
                          SalesTlName.name + " (" + SalesTlName.phone + ")"
                        }
                        options={SalesTlName}
                        loading={isLoading}
                        renderTags={(value, getTagProps) =>
                          value.map((SalesTlName, index) => (
                            <Chip
                              className="text-capitalize"
                              label={SalesTlName.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, SalesTlName) => {
                          if (SalesTlName === null) {
                            this.setState({
                              SalesTlName: [],
                              sales_id: ""
                            });
                          } else {
                            var projectId = SalesTlName.map(ops => ops.id);
                            this.setState({
                              sales_id: projectId
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Sales TL"
                            fullWidth
                            onChange={this.handleCommonAutoUserChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isSalesTlLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  { ["activity","current-status","booking","databank","hourly-update"].includes(this.state.reportInfo) &&
                    ["salesmanager","pnlhead","saleshead","admin"].includes(window.$user.role) &&
                   (
                  <div className="col-md-2 col-2 mb-3">
                    <Autocomplete
                      multiple
                      key={autoKey}
                      id="sr_sales_tl_data"
                      getOptionSelected={(SrSalesTlName, value) =>
                        SrSalesTlName.name === value.name
                      }
                      getOptionLabel={SrSalesTlName =>
                        SrSalesTlName.name + " (" + SrSalesTlName.phone + ")"
                      }
                      options={SrSalesTlName}
                      loading={isLoading}
                      renderTags={(value, getTagProps) =>
                        value.map((SrSalesTlName, index) => (
                          <Chip
                            className="text-capitalize"
                            label={SrSalesTlName.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(_event, SrSalesTlName) => {
                        if (SrSalesTlName === null) {
                          this.setState({
                            SrSalesTlName: [],
                            sr_sales_id: ""
                          });
                        } else {
                          var srStlId = SrSalesTlName.map(ops => ops.id);
                          this.setState({
                            sr_sales_id: srStlId
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Sr. Sales TL"
                          fullWidth
                          onChange={this.handleCommonAutosrSalesTlChange}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isSalesTlLoading ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                  )
                  }
                {
                  /*
                  this.state.reportInfo === "presale-current-status" ||
                  this.state.reportInfo === "presale-lost-sales" ||
                  this.state.reportInfo === "presale-followup" ||
                  this.state.reportInfo === "presale-activity" ||
                  this.state.reportInfo === "presale-booking" ||
                  this.state.reportInfo === "presale-update" ||
                  this.state.reportInfo === "current-status"
                  
                  this.state.reportInfo !== "project-lost-sales" &&
                  this.state.reportInfo !== "digital-activity"
                  ) && (
                    <div className="col-md-2 col-6 mb-3">
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="PreSalesTlData"
                        getOptionSelected={(PreSalesTlName, value) =>
                          PreSalesTlName.name === value.name
                        }
                        getOptionLabel={PreSalesTlName =>
                          PreSalesTlName.name + " (" + PreSalesTlName.phone + ")"
                        }
                        options={PreSalesTlName}
                        loading={isLoading}
                        renderTags={(value, getTagProps) =>
                          value.map((PreSalesTlName, index) => (
                            <Chip
                              className="text-capitalize"
                              label={PreSalesTlName.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, PreSalesTlName) => {
                          if (PreSalesTlName === null) {
                            this.setState({
                              PreSalesTlName: [],
                              presales_id: ""
                            });
                          } else {
                            var projectId = PreSalesTlName.map(ops => ops.id);
                            this.setState({
                              presales_id: projectId
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="PresaleTL"
                            fullWidth
                            onChange={this.handleCommonAutoPresaleUserChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isPreSalesTlLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                    */
                  }
                {window.$user.role.toLowerCase() !== "financehead" &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-databank" &&
                  this.state.reportInfo !== "project-rm" &&
                  this.state.reportInfo !== "project-activity-rm" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "databank" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "magnet-spend" &&
                  this.state.reportInfo !== "digital-activity" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Presale-Lead-type">
                          PreSale Lead
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={is_presale}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "is_presale",
                            id: "is_presale_status"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {(this.state.reportInfo === "presale-activity" ||
                  this.state.reportInfo === "activity" ||
                  this.state.reportInfo === "presale-current-status" ||
                  this.state.reportInfo === "current-status" ||
                  this.state.reportInfo === "followup") && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Assign-Lead-type">
                          Assign Lead Type
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={assign_type}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "assign_type",
                            id: "assign_type"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="assign">Assign</MenuItem>
                          <MenuItem value="reactivation">Reactivation</MenuItem>
                          <MenuItem value="sourcing">Sourcing</MenuItem>
                          <MenuItem value="cross">Cross</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {(this.state.reportInfo === "activity" ||
                  this.state.reportInfo === "current-status" ||
                  this.state.reportInfo === "presale-activity" ||
                  this.state.reportInfo === "presale-current-status" ||
                  this.state.reportInfo === "followup") && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="demo-controlled-open-select-label">
                          Nationality
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={nationality}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "nationality",
                            id: "nationality"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Indian</MenuItem>
                          <MenuItem value="2">NRI</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {window.$user.group.toLowerCase() !== "magnet" &&
                  this.state.reportInfo !== "databank" &&
                  this.state.reportInfo !== "presale-booking" &&
                  this.state.reportInfo !== "presale-update" &&
                  this.state.reportInfo !== "followup" &&
                  this.state.reportInfo !== "lost-sales" &&
                  this.state.reportInfo !== "project-lost-sales" &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "activity" &&
                  this.state.reportInfo !== "current-status" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "booking" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-databank" &&
                  this.state.reportInfo !== "project-activity-rm" &&
                  this.state.reportInfo !== "project-rm" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "digital-activity" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "magnet-spend" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Revert-Lead-type">
                          Revert Lead
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={is_revert}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "is_revert",
                            id: "is_revert"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {!(MgLead_SourceRole.includes(window.$user.role.toLowerCase()) && MgLead_SourceSlug.includes(this.state.reportInfo)) &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-spend" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "digital-activity" &&
                  this.state.reportInfo !== "databank" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="demo-mutiple-checkbox-label">Source</InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          name="selected"
                          multiple
                          value={selected}
                          onChange={this.handleSourceChange}
                          input={<Input />}
                          renderValue={this.getSelectValues}
                          MenuProps={MenuProps}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root: isAllSelected ? '' : ""
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                classes={{ indeterminate: '' }}
                                checked={isAllSelected}
                                indeterminate={
                                  selected.length > 0 && selected.length < source.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: '' }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {source.map((item, index) => (
                            <MenuItem key={item.id} value={item.id}>
                              <Checkbox checked={selected.indexOf(item.id) > -1} />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {source_group_access.includes(this.state.reportInfo) &&
                  <div className="col-md-2 col-6 mb-3">
                    <FormControl>
                      <InputLabel>
                        Source Group
                      </InputLabel>
                      <Select
                        value={source_group}
                        onChange={this.handleChange}
                        name="source_group"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {source_group_list.map((i, idx) => (
                          <MenuItem value={i.value}>{i.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                }
                {this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "digital-activity" &&
                  // ["databank","hourly-update"].includes(this.state.reportInfo) &&
                  //   ["salesmanager","pnlhead","saleshead","admin"].includes(window.$user.role) &&
                  (
                    <Frag>
                      {
                        (["databank","hourly-update"].includes(this.state.reportInfo) &&
                        !["salesmanager","pnlhead","saleshead","admin"].includes(window.$user.role)) ? null :
                        <div className="col-md-2 col-6 mb-3">
                        <Autocomplete
                          multiple
                          key={autoKey}
                          id="regions"
                          getOptionSelected={(region, value) =>
                            region.name === value.name
                          }
                          getOptionLabel={region => region.name}
                          options={region}
                          renderTags={(value, getTagProps) =>
                            value.map((region, index) => (
                              <Chip
                                label={region.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(_event, region) => {
                            var regionId = region.map(ops => ops.id);
                            this.setState({
                              region_id: regionId
                            });

                            if (region === null) {
                              this.setState({ region: [] });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Region"
                              fullWidth
                              onChange={this.handleAutoChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </div>}
                      <div className="col-md-2 col-6 mb-3">
                        <Autocomplete
                          multiple
                          key={autoKey}
                          id="projectData"
                          getOptionSelected={(option, value) =>
                            option.name === value.name
                          }
                          getOptionLabel={option => option.name}
                          options={option}
                          loading={isLoading}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                label={option.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(_event, option) => {
                            var projectId = option.map(ops => ops.id);
                            this.setState({
                              project_ids: projectId
                            });

                            if (option === null) {
                              this.setState({ option: [] });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Project Name"
                              fullWidth
                              onChange={this.handleAutoChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </div>
                    </Frag>
                  )}
                {!(MgLead_SourceRole.includes(window.$user.role.toLowerCase()) && MgLead_SourceSlug.includes(this.state.reportInfo)) &&
                  this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-databank" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "booking-detail" &&
                  this.state.reportInfo !== "digital-booking" &&
                  this.state.reportInfo !== "digital-activity" &&
                  this.state.reportInfo !== "databank" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "magnet-current-status"&&
                  this.state.reportInfo !== "magnet-spend" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Presale-Lead-type">
                          Magnet Lead
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={is_magnet}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "is_magnet",
                            id: "is_magnet"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        {this.state.reportInfo !== "project-activity-rm" &&
                          <MenuItem value="2">Yes-exclude MLMB</MenuItem>
                        }
                        {this.state.reportInfo !== "project-activity-rm" &&
                          <MenuItem value="3">Yes-Only MLMB</MenuItem>
                        }
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {this.state.reportInfo === "magnet-list" && (
                  <Frag>
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <TextField
                          value={email}
                          id="email"
                          name="email"
                          label="Email"
                          onChange={this.handleChange}
                          defaultValue=""
                          helperText=""
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <TextField
                          value={phone}
                          id="phone"
                          name="phone"
                          label="Phone"
                          onChange={this.handleChange}
                          defaultValue=""
                          helperText=""
                        />
                      </FormControl>
                    </div>
                  </Frag>
                )}
                {(this.state.reportInfo === "audit") && (
                  <div className="col-md-2 col-6 mb-3">
                    <FormControl>
                      <InputLabel
                        id="demo-controlled-open-select-label"
                      >
                        Audit RM
                      </InputLabel>
                      <Select
                        /* error={
                          this.state.errors.audit_rm_id !== "" ? true : false
                        } */
                        labelId="demo-controlled-open-select-label"
                        value={this.state.audit_rm_id}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "audit_rm_id",
                          id: "audit_rm_id"
                        }}
                      /* helperText={
                        this.state.errors.audit_rm_id !== ""
                          ? this.state.errors.audit_rm_id
                          : ""
                      } */
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.state.auditRm
                          ? this.state.auditRm.map(auditRm => (
                            <MenuItem
                              className="text-capitalize"
                              value={auditRm.id}
                              key={auditRm.id}
                            >
                              {auditRm.name}
                            </MenuItem>
                          ))
                          : ""}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {window.$user.group.toLowerCase() === "magnet" ||
                  window.$user.group.toLowerCase() === "admin"
                  ? (this.state.reportInfo === "magnet-activity" ||
                    this.state.reportInfo === "magnet-spend" ||
                    this.state.reportInfo === "magnet-current-status" ||
                    this.state.reportInfo === "magnet-pipeline" ||
                    this.state.reportInfo === "magnet-booking" ||
                    this.state.reportInfo === "magnet-bulk-upload" ||
                    this.state.reportInfo === "magnet-lead" ||
                    this.state.reportInfo === "magnet-list" ||
                    this.state.reportInfo === "project-rm" ||
                    this.state.reportInfo === "magnet-databank" ||
                    this.state.reportInfo === "project-activity-rm") && (
                    <div className="col-md-2 col-6 mb-3">
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="magnetName"
                        getOptionSelected={(magnetName, value) =>
                          magnetName.name === value.name
                        }
                        getOptionLabel={magnetName =>
                          magnetName.name + " (" + magnetName.phone + ")"
                        }
                        options={magnetName}
                        renderTags={(value, getTagProps) =>
                          value.map((magnetName, index) => (
                            <Chip
                              label={magnetName.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, magnetName) => {
                          var magnetId = magnetName.map(ops => ops.id);
                          this.setState({
                            magnet_ids: magnetId
                          });

                          if (option === null) {
                            this.setState({ magnetName: [] });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Magnet Name"
                            fullWidth
                            onChange={this.handleAutoMagnetChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                  )
                  : ""}
                {window.$role.toLowerCase() === "magnetadmin" ||
                  window.$role.toLowerCase() === "magnetmis" ||
                  window.$user.role.toLowerCase() === "magnetpnl" ||
                  window.$user.group.toLowerCase() === "admin"
                  ? (this.state.reportInfo === "magnet-activity" ||
                    this.state.reportInfo === "magnet-spend" ||
                    this.state.reportInfo === "magnet-current-status" ||
                    this.state.reportInfo === "magnet-pipeline" ||
                    this.state.reportInfo === "magnet-booking" ||
                    this.state.reportInfo === "magnet-bulk-upload" ||
                    this.state.reportInfo === "project-rm" ||
                    this.state.reportInfo === "magnet-lead" ||
                    this.state.reportInfo === "magnet-list" ||
                    this.state.reportInfo === "magnet-databank") && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <Autocomplete
                          multiple
                          key={autoKey}
                          id="magnet-demo"
                          getOptionSelected={(magnet, value) =>
                            magnet.name === value.name
                          }
                          getOptionLabel={magnet =>
                            magnet.name + " (" + magnet.phone + ")"
                          }
                          options={magnet}
                          renderTags={(value, getTagProps) =>
                            value.map((magnet, index) => (
                              <Chip
                                label={magnet.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(_event, magnet) => {
                            var magnetRmId = magnet.map(ops => ops.id);
                            this.setState({
                              magnet_tl_id: magnetRmId
                            });

                            if (magnet === null) {
                              this.setState({ magnet: [] });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Magnet TL"
                              fullWidth
                              onChange={this.handleAutoMagnetTL}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )
                  : ""}
                {window.$user.group.toLowerCase() === "magnet"
                  ? (this.state.reportInfo === "magnet-activity" ||
                    this.state.reportInfo === "magnet-current-status" ||
                    this.state.reportInfo === "magnet-pipeline" ||
                    this.state.reportInfo === "magnet-booking") && (
                    <Frag>
                      <div className="col-md-2 col-6 mb-3">
                        <FormControl>
                          <Autocomplete
                            multiple
                            key={autoKey}
                            id="magnet-demo"
                            getOptionSelected={(magnet, value) =>
                              magnet.name === value.name
                            }
                            getOptionLabel={magnet =>
                              magnet.name + " (" + magnet.phone + ")"
                            }
                            options={magnet}
                            renderTags={(value, getTagProps) =>
                              value.map((magnet, index) => (
                                <Chip
                                  label={magnet.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(_event, magnet) => {
                              var magnetRmId = magnet.map(ops => ops.id);
                              this.setState({
                                magnet_rm_id: magnetRmId
                              });

                              if (magnet === null) {
                                this.setState({ magnet: [] });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Magnet RM"
                                fullWidth
                                onChange={this.handleAutoMagnetRM}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="col-md-2 col-6 mb-3">
                        <FormControl>
                          <Autocomplete
                            multiple
                            key={autoKey}
                            id="saleRmName"
                            getOptionSelected={(salesName, value) =>
                              salesName.name === value.name
                            }
                            getOptionLabel={salesName =>
                              salesName.name + " (" + salesName.phone + ")"
                            }
                            options={salesName}
                            renderTags={(value, getTagProps) =>
                              value.map((salesName, index) => (
                                <Chip
                                  label={salesName.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(_event, salesName) => {
                              var salesRmId = salesName.map(ops => ops.id);
                              this.setState({
                                sale_rm: salesRmId
                              });

                              if (option === null) {
                                this.setState({ salesName: [] });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Sales RM"
                                fullWidth
                                onChange={this.handleAutoSalesChange}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </Frag>
                  )
                  : ""}

                {(this.state.reportInfo === "magnet-bulk-upload" ||
                  this.state.reportInfo === "magnet-lead" ||
                  this.state.reportInfo === "magnet-list" ||
                  this.state.reportInfo === "project-rm" ||
                  this.state.reportInfo === "magnet-databank"||
                  this.state.reportInfo === "project-activity-rm") && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <Autocomplete
                          multiple
                          key={autoKey}
                          id="serviceRmName"
                          getOptionSelected={(serviceRm, value) =>
                            serviceRm.name === value.name
                          }
                          getOptionLabel={serviceRm =>
                            serviceRm.name + " (" + serviceRm.phone + ")"
                          }
                          options={serviceRm}
                          renderTags={(value, getTagProps) =>
                            value.map((serviceRm, index) => (
                              <Chip
                                label={serviceRm.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(_event, serviceRm) => {
                            var serviceRmId = serviceRm.map(ops => ops.id);
                            this.setState({
                              service_rm_id: serviceRmId
                            });

                            if (option === null) {
                              this.setState({ serviceRm: [] });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Service RM"
                              fullWidth
                              onChange={this.handleAutoServiceChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                  {(this.state.reportInfo == "magnet-lead" ||
                  this.state.reportInfo == "magnet-current-status") && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Presale-Lead-type">
                          Lead Percentage
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={lead_percent}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "lead_percent",
                            id: "lead_percent"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="50">50%</MenuItem>
                          <MenuItem value="60">60%</MenuItem>
                          <MenuItem value="70">70%</MenuItem> 
                       </Select>
                      </FormControl>
                    </div>
                  )}
                {this.state.reportInfo === "magnet-list" && (
                  <div className="col-md-2 col-6 mb-3">
                    <FormControl>
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="enrollName"
                        getOptionSelected={(enrollRm, value) =>
                          enrollRm.name === value.name
                        }
                        getOptionLabel={enrollRm =>
                          enrollRm.name + " (" + enrollRm.phone + ")"
                        }
                        options={enrollRm}
                        renderTags={(value, getTagProps) =>
                          value.map((enrollRm, index) => (
                            <Chip
                              label={enrollRm.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, enrollRm) => {
                          var enrollRmId = enrollRm.map(ops => ops.id);
                          this.setState({
                            enroll_rm_id: enrollRmId
                          });

                          if (option === null) {
                            this.setState({ enrollRm: [] });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Enroll RM"
                            fullWidth
                            onChange={this.handleAutoEnrollChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
                {this.state.reportInfo === "magnet-bulk-upload" && (
                  <div className="col-md-2 col-6 mb-3">
                    <FormControl>
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="uploadedByName"
                        getOptionSelected={(uploadedBy, value) =>
                          uploadedBy.name === value.name
                        }
                        getOptionLabel={uploadedBy =>
                          uploadedBy.name + " (" + uploadedBy.phone + ")"
                        }
                        options={uploadedBy}
                        renderTags={(value, getTagProps) =>
                          value.map((uploadedBy, index) => (
                            <Chip
                              label={uploadedBy.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, uploadedBy) => {
                          var uploadedById = uploadedBy.map(ops => ops.id);
                          this.setState({
                            uploaded_by_id: uploadedById
                          });

                          if (option === null) {
                            this.setState({ uploadedBy: [] });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Uploaded By"
                            fullWidth
                            onChange={this.handleAutoUploadedChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
                {(this.state.reportInfo === "magnet-activity" || this.state.reportInfo === "presale-activity") &&(
                  <div className="col-md-2 col-6 mb-3">
                    <SelectOption
                      variant="standard"
                      label="Activity Type"
                      name="activity_type"
                      options={activity_status_option}
                      value={activity_type}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
                
                {this.state.reportInfo === "magnet-current-status" && (
                
                  <div className="col-md-2 col-6 mb-3">
                  <FormControl>
                  <InputLabel id="demo-mutiple-checkbox-label">Lead Type</InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          name="leadType"
                          multiple
                          value={updated_lead_type}
                          onChange={this.handleCheckboxChange}
                          input={<Input />}
                           renderValue={this.getSelectedLeadId}
                          MenuProps={MenuProps}
                        >
                          {lead_type.map((item, index) => (
                            <MenuItem key={item.id} value={item.id}>
                              <Checkbox checked={updated_lead_type.indexOf(item.id) > -1}/>
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                   </FormControl>
                </div>
                )}
                {this.state.reportInfo !== "magnet-bulk-upload" &&
                  this.state.reportInfo !== "magnet-lead" &&
                  this.state.reportInfo !== "magnet-list" &&
                  this.state.reportInfo !== "magnet-databank" &&
                  this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "databank" &&
                  this.state.reportInfo !== "audit" &&
                  this.state.reportInfo !== "project-lost-sales" &&
                  this.state.reportInfo !== "magnet-spend" && (
                    <Frag>
                      <div className="col-md-2 col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="assigned_date_form"
                            label="Assigned From"
                            variant="inline"
                            format="yyyy/MM/dd"
                            maxDate={new Date()}
                            name="assignedDate_from"
                            value={assignedDate_from}
                            onChange={(key, date) =>
                              this.handleDateChange("assignedDate_from", date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-2 col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="assigned_date_to"
                            label="Assigned To"
                            variant="inline"
                            format="yyyy/MM/dd"
                            maxDate={new Date()}
                            name="assignedDate_to"
                            value={assignedDate_to}
                            onChange={(key, date) =>
                              this.handleDateChange("assignedDate_to", date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Frag>
                  )}
                {this.state.reportInfo !== "hourly-update" &&
                  this.state.reportInfo !== "update" &&
                  this.state.reportInfo !== "databank" &&
                  this.state.reportInfo !== "magnet-databank" && (
                    <div className="col-md-4 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="coming_date_form"
                              label={
                                this.state.reportInfo === "magnet-lead"
                                  ? "Added From"
                                  : this.state.reportInfo ===
                                    "magnet-bulk-upload"
                                    ? "Uploaded From"
                                    : "Coming From"
                              }
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="comingDate_from"
                              value={comingDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange("comingDate_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="coming_date_to"
                              label={
                                this.state.reportInfo === "magnet-lead"
                                  ? "Added To"
                                  : this.state.reportInfo ===
                                    "magnet-bulk-upload"
                                    ? "Uploaded To"
                                    : "Coming To"
                              }
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="comingDate_to"
                              value={comingDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("comingDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}
                {(this.state.reportInfo === "booking" ||
                  this.state.reportInfo === "current-status" ||
                  this.state.reportInfo === "presale-current-status" ||
                  this.state.reportInfo === "presale-activity" ||
                  this.state.reportInfo === "activity" ||
                  this.state.reportInfo === "source-booking" ||
                  this.state.reportInfo === "magnet-booking" ||
                  this.state.reportInfo === "presale-booking" ||
                  this.state.reportInfo === "booking-detail" ||
                  this.state.reportInfo === "digital-booking" ||
                  this.state.reportInfo === "project-rm") && (
                    <div className="col-md-4 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="booked_date_form"
                              label="Booking From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="bookDate_from"
                              value={bookDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange("bookDate_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="booked_date_to"
                              label="Booking To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="bookDate_to"
                              value={bookDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("bookDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}
                 {(this.state.reportInfo === "booking" ||
                  this.state.reportInfo === "current-status" ||
                  this.state.reportInfo === "activity" ||
                  this.state.reportInfo === "digital-booking" ||
                  this.state.reportInfo === "booking-detail" ||
                  this.state.reportInfo === "digital-activity") && (
                    <div className="col-md-4 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                        <FormControl>
                          <InputLabel id="Presale-Lead-type">
                            Year
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            value={this.state.year}
                            onChange={this.handleChange}
                            inputProps={{
                              name: "year",
                              id: "year"
                            }}
                            required
                          >
                            {this.state.years.map(year => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        </div>
                        <div className="col-sm-6 col-6">
                            <FormControl>
                              <InputLabel id="demo-controlled-open-select-label">
                              Month
                              </InputLabel>
                              <Select
                                labelId="demo-controlled-open-select-label"
                                value={this.state.month}
                                onChange={this.handleChange}
                                inputProps={{
                                  name: "month",
                                  id: "month"
                                }}
                                required
                              >
                                {this.state.months.map(month => (
                                  <MenuItem key={month.key} value={month.key}>
                                    {month.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                {(this.state.reportInfo === "project-activity-rm" ||
                  this.state.reportInfo === "activity" ||
                  this.state.reportInfo === "source-activity" ||
                  this.state.reportInfo === "current-status" ||
                  this.state.reportInfo === "presale-current-status" ||
                  this.state.reportInfo === "presale-activity" ||
                  this.state.reportInfo === "magnet-activity" ||
                  this.state.reportInfo === "digital-activity") && (
                    <div className="col-md-4 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="activity_date_form"
                              label="Activity From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={this.getStartDateMax()}
                              name="activity_from"
                              value={activity_from}
                              onChange={(key, date) =>
                                this.handleDateChange("activity_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="activity_date_to"
                              label="Activity To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={this.getStartDateMax()}
                              name="activity_to"
                              value={activity_to}
                              onChange={(key, date) =>
                                this.handleDateChange("activity_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}

                {(this.state.reportInfo === "followup" ||
                  this.state.reportInfo === "presale-followup") && (
                    <div className="col-md-4 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="followup_date_form"
                              label="Followup From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="followupDate_from"
                              value={followupDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange("followupDate_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="followup_date_to"
                              label="Followup To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={this.getStartDateMax()}
                              name="followupDate_to"
                              value={followupDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("followupDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}
                {(this.state.reportInfo === "databank" ||
                  reportInfo === "magnet-databank") && (
                    <div className="col-md-5 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="dump_date_form"
                              label="Databank Update From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="dumpDate_from"
                              value={dumpDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange("dumpDate_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="dump_date_to"
                              label="Databank Update To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="dumpDate_to"
                              value={dumpDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("dumpDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}
                {(this.state.reportInfo === "databank" ||
                  reportInfo === "magnet-databank") && (
                    <div className="col-md-5 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="dump_assigned_date_from"
                              label="Databank Assign From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="dumpAssignedDate_from"
                              value={dumpAssignedDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange(
                                  "dumpAssignedDate_from",
                                  date
                                )
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="dump_assigned_date_to"
                              label="Databank Assign To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="dumpAssignedDate_to"
                              value={dumpAssignedDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("dumpAssignedDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}
                {(this.state.reportInfo === "lost-sales" ||this.state.reportInfo === "project-lost-sales" ||
                  this.state.reportInfo === "presale-lost-sales") && (
                    <div className="col-md-4 mb-3">
                      <div className="row">
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="closed_at_from"
                              label="Closed From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="closed_from"
                              value={closed_from}
                              onChange={(key, date) =>
                                this.handleDateChange("closed_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-sm-6 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="closed_at_to"
                              label="Closed To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="closed_to"
                              value={closed_to}
                              onChange={(key, date) =>
                                this.handleDateChange("closed_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.reportInfo === "presale-update" ||
                  this.state.reportInfo === "update" ||
                  this.state.reportInfo === "source-update" ||
                  this.state.reportInfo === "hourly-update" ? (
                  <div className="col-md-4 mb-3">
                    <div className="row">
                      <div className="col-sm-6 col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="update_date_form"
                            label="Update From"
                            variant="inline"
                            format="yyyy/MM/dd"
                            maxDate={new Date()}
                            name="update_from"
                            value={update_from}
                            onChange={(key, date) =>
                              this.handleDateChange("update_from", date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-6 col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="update_date_to"
                            label="Update To"
                            variant="inline"
                            format="yyyy/MM/dd"
                            maxDate={new Date()}
                            name="update_to"
                            value={update_to}
                            onChange={(key, date) =>
                              this.handleDateChange("update_to", date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {window.$user.group.toLowerCase() !== "magnet" &&
                  this.state.reportInfo === "current-status" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Presale-Lead-type">
                          Audit Lead
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={is_audit}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "is_audit",
                            id: "is_audit"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {window.$user.group.toLowerCase() !== "magnet" &&
                  this.state.reportInfo === "current-status" && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel id="Presale-Lead-type">
                          Total/Open toggle swith
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={stage_ids}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "stage_ids",
                            id: "stage_ids"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Total</MenuItem>
                          <MenuItem value="2">Open</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {(this.state.reportInfo === "booking-detail" || this.state.reportInfo === "digital-booking") && (
                  <div className="col-md-2 col-6 mb-3">
                    <FormControl>
                      <InputLabel id="Presale-Lead-type">
                        Booked/cancel Lead
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        value={object_stage_name}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "object_stage_name",
                          id: "object_stage_name"
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="7">Booked</MenuItem>
                        <MenuItem value="8">Cancel</MenuItem>
                        <MenuItem value="6">Gross EOI Application</MenuItem>
                        <MenuItem value="15">Gross EOI Non-Refundable</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
                {(this.state.reportInfo === "current-status" ||
                  this.state.reportInfo === "booking" ||
                  this.state.reportInfo === "project-rm") && (
                    <div className="col-md-2 col-6 mb-3">
                      <Autocomplete
                        multiple
                        key={autoKey}
                        id="projectData"
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={option => option.name}
                        options={option}
                        loading={isLoading}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_event, option) => {
                          var projectId = option.map(ops => ops.id);
                          this.setState({
                            booking_project_id: projectId
                          });

                          if (option === null) {
                            this.setState({ option: [] });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Booked Project Name"
                            fullWidth
                            onChange={this.handleAutoChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {
                  (["admin", "magnetadmin", "presalehead", "sourcings2", "magnet s2"].includes(window.$user.role) && this.state.reportInfo === "presale-current-status") && (
                    <div className="col-md-2 col-6 mb-3">
                      <FormControl>
                        <InputLabel>
                          IsOpp
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          value={is_opp}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "is_opp",
                            id: "is_opp"
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-6 text-right col-6 mt-3">
                    <div className="form-group">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.applyFilter}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                  <div className="col-sm-6 col-6 mt-3">
                    <div className="form-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.resetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </div>

          <div className="container-fluid">
            <div className="row">
              {this.state.reportInfo !== "magnet-list" ? (
                <div className="col-md-12 mt-3 mb-2 text-left">
                  {reportData?.length > 0 && (
                    <Frag>
                      <span className="fs-16">
                        {this.props.match.params.reportType
                          .split("-")
                          .join(" ")
                          .toUpperCase()}{" "}
                        REPORT
                      </span>
                      {
                        (this.state.reportInfo === "booking-detail" || this.state.reportInfo === "digital-booking")
                        ?
                        <Tooltip title="Download Report" placement="bottom" arrow>
                          <Button
                            className="float-right"
                            variant="contained"
                            color="primary"
                            onClick={() => this.state.reportInfo === "booking-detail" ? this.onDownload("booking_details_report") : this.onDownload("digital_booking_report")} 
                          >
                            <GetAppIcon/>
                          </Button>
                        </Tooltip>
                        : 
                        (!["magnettl", "magnetrm"].includes(window.$user.role) &&
                          <Tooltip title="Download Report">
                            <Button
                              className="float-right"
                              variant="contained"
                              color="primary"
                            >
                              <CSVLink
                                data={reportData}
                                filename={`${this.props.match.params.reportType}-report.csv`}
                              >
                                <GetAppIcon className="text-white" />
                              </CSVLink>
                            </Button>
                          </Tooltip>
                        )
                      }
                    </Frag>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              {this.state.reportInfo === "magnet-list" ? (
                status === 200 && this.state.reportData?.length > 0 ? (
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className="tableCustom"
                        style={{ minWidth: "650px" }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Sr.No
                            </StyledTableCell>
                            <StyledTableCell align="left">ID</StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            <StyledTableCell align="left">
                              Email
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Phone
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Service RM
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Enroll RM
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Coming Date
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.reportData.map((row, index) => (
                            <TableRow key={row.name}>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell
                                className="text-capitalize"
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">{row.phone}</TableCell>
                              <TableCell
                                className="text-capitalize"
                                align="left"
                              >
                                {row?.UserInfo?.UserServiceRm?.name}
                              </TableCell>
                              <TableCell
                                className="text-capitalize"
                                align="left"
                              >
                                {row?.InterestedMagnet?.IntEnrollRm?.name}
                              </TableCell>
                              <TableCell
                                className="text-capitalize"
                                align="left"
                              >
                                {changeDate(row?.InterestedMagnet?.created_at)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        {showMaterialLoader ? (
                          <CircularProgress
                            className="text-center"
                            style={{ position: "absolute", left: "50%" }}
                            color="secondary"
                            size={30}
                          />
                        ) : null}
                      </Table>
                    </TableContainer>
                  </div>
                ) : status !== 200 || this.state.reportData?.length <= 0 ? (
                  <div className="col-md-12 text-center errorMsg">
                    <span className="text-muted">
                      Ooops Report Not Found !!!
                    </span>
                  </div>
                ) : (
                  ""
                )
              ) : this.state.reportInfo === "ratio" ? (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <Paper className="small_box p-3">
                        <div className="pl-2 deskColor">
                          <p className="card_font_25 mb-0">Ratio Report</p>
                        </div>
                        <div className="px-4 pt-2 pb-4">
                          <p className="desk_head mb-0 text-center">
                            Coming Soon...
                          </p>
                        </div>
                        <div className="icon pb-3">
                          <i className="ion ion-person-add"></i>
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div>
              ) : status === 200 && this.state.reportData?.length > 0 ? (
                <div className="col-md-12">
                  <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="tableCustom"
                      style={{ minWidth: "650px" }}
                    >
                      <TableHead>
                        <TableRow>
                          {Object?.keys(this.state.reportData[0]).map(
                            (values, index) => {
                              if (values !== "id" && values !== "magnet_phone") {
                                return (
                                  <StyledTableCell
                                    key={index}
                                    align="left"
                                    className={
                                      sortAvailableOn.includes(
                                        this.state.reportInfo
                                      )
                                        ? `pointer`
                                        : ""
                                    }
                                  >
                                    {sortAvailableOn.includes(
                                      this.state.reportInfo
                                    ) ? (
                                      <>
                                        <span
                                          onClick={e =>
                                            this.sortReport(
                                              values,
                                              this.state.sort.direction === ""
                                                ? "asc"
                                                : this.state.sort.direction ===
                                                  "asc"
                                                  ? "desc"
                                                  : "asc"
                                            )
                                          }
                                        >
                                          {values.replace(/(^|_)./g, s =>
                                            s.slice(-1).toUpperCase()
                                          )}
                                        </span>
                                        {this.state.sort.by === values ? (
                                          <span
                                            onClick={e =>
                                              this.sortReport(
                                                values,
                                                this.state.sort.direction === ""
                                                  ? "asc"
                                                  : this.state.sort
                                                    .direction === "asc"
                                                    ? "desc"
                                                    : "asc"
                                              )
                                            }
                                            style={{ marginLeft: "0.25rem" }}
                                          >
                                            <img
                                              src={
                                                this.state.sort.direction ===
                                                  "asc"
                                                  ? asc
                                                  : desc
                                              }
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    ) : (
                                      values.replace(/(^|_)./g, s =>
                                        s.slice(-1).toUpperCase()
                                      )
                                    )}
                                  </StyledTableCell>
                                );
                              }
                            }
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.reportData.map((report, index1) => (
                          <TableRow key={index1}>
                            {Object.keys(report).map((values, index) => {
                              if (
                                values !== "id" && values !== "magnet_phone" &&
                                (report["id"] !== null || report["Magnet"] === null) &&
                                report["user_name"] !== null &&
                                report["Project"] !== null
                              ) {
                                /*if (this.state.reportInfo === 'activity') {
                                    if (values === 'user_name' || values === 'activity booking ratio' || values === 'is_opp'|| values === 'is_pipe' || values === 'lead activity ratio' || values === 'lead opp ratio' || values === 'opp booking ratio' || values === 'total assigned' || report['user_name'] === 'Total') {
                                        return (<StyledTableCell className="text-capitalize" key={index} align="left" >
                                            {this.state.reportData?.length - 1 !== index1 ? (report[values] !== null ? (index === 0 ? report[values] : report[values]) : '--') : (index === 0 ? 'Total' : report[values])}

                                        </StyledTableCell>);
                                    } else {
                                        return (<StyledTableCell style={{ color: "blue", cursor: "pointer" }} className="text-capitalize" key={index} align="left" onClick={() => this.redirect(report['id'], values, report[values])}>
                                            {this.state.reportData?.length - 1 !== index1 ? (report[values] !== null ? (index === 0 ? report[values] : report[values]) : '--') : (index === 0 ? 'Total' : report[values])}

                                        </StyledTableCell>);

                                    }
                                } else*/
                                if (this.state.reportInfo === "followup") {
                                  if (
                                    values === "user_name" ||
                                    report["user_name"] === "Total"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo === "current-status"
                                ) {
                                  if (
                                    values === "user_name" ||
                                    report["user_name"] === "Total" ||
                                    values === "activity booking ratio" ||
                                    values === "is_opp" ||
                                    values === "is_pipe" ||
                                    values === "lead activity ratio" ||
                                    values === "lead opp ratio" ||
                                    values === "opp booking ratio" ||
                                    values === "total assigned" ||
                                    values === "lead booking ratio"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo === "project-rm"
                                ) {
                                  if (
                                    values === "Project" ||
                                    report["Project"] === "Total"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo ===
                                  "project-activity-rm"
                                ) {
                                  if (
                                    values === "Project" ||
                                    report["Project"] === "Total" ||
                                    values === "activity booking ratio" ||
                                    values === "is_opp" ||
                                    values === "is_pipe" ||
                                    values === "lead activity ratio" ||
                                    values === "lead opp ratio" ||
                                    values === "opp booking ratio" ||
                                    values === "total assigned"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo ===
                                  "presale-current-status"
                                ) {
                                  if (
                                    values === "user_name" ||
                                    report["user_name"] === "Total" ||
                                    values === "activity booking ratio" ||
                                    values === "is_opp" ||
                                    values === "is_pipe" ||
                                    values === "lead activity ratio" ||
                                    values === "lead opp ratio" ||
                                    values === "opp booking ratio" ||
                                    values === "total assigned" ||
                                    values === "lead sourcing ratio" ||
                                    values === "sourcing activity ratio" ||
                                    values === "Revert"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo === "booking" && window.$user.role !== "hrhead"
                                ) {
                                  
                                  if (
                                    values === "user_name" ||
                                    values === "last_book_lead_date" ||
                                    report["user_name"] === "Total" ||
                                    values === "Total_CV" ||
                                    values === "Magnet_CV" ||
                                    values === "Non_Magnet_CV"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : values === "last_book_lead_date"
                                                ? <span style={bookingDateColor(report[values])}>{report[values]}</span>
                                                : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo === "audit"
                                ) {
                                  if (
                                    values === "region" ||
                                    values === "closed" ||
                                    values === "Total"  ||
                                    values === 'reactivate' ||
                                    values === 'reactivated closed' ||
                                    values === 'followup count' ||
                                    values === 'Calls Attempted' ||
                                    report["region"] === null
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo === "presale-booking"
                                ) {
                                  if (
                                    values === "user_name" ||
                                    report["user_name"] === "Total"
                                  ) {
                                    return (
                                      <StyledTableCell
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        style={{
                                          color: "blue",
                                          cursor: "pointer"
                                        }}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                        onClick={() =>
                                          this.redirect(
                                            report["id"],
                                            values,
                                            report[values]
                                          )
                                        }
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "--"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  }
                                } else if (
                                  this.state.reportInfo === "magnet-booking" ||
                                  this.state.reportInfo === "magnet-activity" ||
                                  this.state.reportInfo === "magnet-current-status" ||
                                  this.state.reportInfo === "magnet-databank" ||
                                  this.state.reportInfo === "magnet-bulk-upload" ||
                                  this.state.reportInfo === "magnet-lead"
                                ) {
                                  if (report["Magnet"] === null) {
                                    return (
                                      <StyledTableCell
                                        style={CustomStyle}
                                        className="text-capitalize"
                                        key={index}
                                        align="left"
                                      >
                                        {this.state.reportData?.length - 1 !==
                                          index1
                                          ? report[values] !== null
                                            ? index === 0
                                              ? report[values]
                                              : report[values]
                                            : "Subtotal"
                                          : index === 0
                                            ? "Total"
                                            : report[values]}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    if (this.state.reportInfo === "magnet-current-status" || this.state.reportInfo === 'magnet-activity') {
                                      if (values === "Magnet_Rm" || values === "total Magnet"
                                          || values === 'lead opp ratio' || values === 'opp booking ratio'
                                           || values === 'activity booking ratio') {
                                        return (
                                          <StyledTableCell
                                            className="text-capitalize"
                                            key={index}
                                            align="left"
                                          >
                                            {this.state.reportData?.length - 1 !==
                                              index1
                                              ? report[values] !== null
                                                ? index === 0
                                                  ? report[values]
                                                  : report[values]
                                                : "--"
                                              : index === 0
                                                ? "Total"
                                                : report[values]}
                                          </StyledTableCell>
                                        );
                                      }
                                      else {
                                        return (
                                          <StyledTableCell
                                            className="text-capitalize"
                                            key={index}
                                            align="left"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer"
                                            }}
                                            onClick={() =>
                                              this.redirect(
                                                report[`${values === 'Magnet' ? 'magnet_phone': 'id'}`],
                                                values,
                                                report[`${values === 'Magnet' ? 'id': values}`]
                                              )
                                            }
                                          >
                                            {this.state.reportData?.length - 1 !==
                                              index1
                                              ? report[values] !== null
                                                ? index === 0
                                                  ? report[values]
                                                  : report[values]
                                                : "Subtotal"
                                              : index === 0
                                                ? "Total"
                                                : report[values]}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                }

                                return (
                                  <StyledTableCell
                                    className="text-capitalize"
                                    key={index}
                                    align="left"
                                  >
                                    {this.state.reportData?.length - 1 !==
                                      index1
                                      ? report[values] !== null
                                        ? report[values]
                                        : "--"
                                      : this.state.reportInfo != 'booking-detail' && this.state.reportInfo != 'digital-booking' && this.state.reportInfo != 'digital-activity' && index === 0
                                        ? "Total"
                                        : report[values] !== null
                                        ? report[values] 
                                        : "--"
                                        }
                                  </StyledTableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : status !== 200 || this.state.reportData?.length <= 0 ? (
                <div className="col-md-12 text-center errorMsg">
                  <span className="text-muted">Ooops Report Not Found !!!</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Frag>
    );
  }
}
export default withRouter(LeadReport);
