import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import React from 'react'
import { Component } from 'react'
import ProjectPdfContainer from '../component/pdfGenrete/ProjectPdfContainer'
import Frag from '../utils/Frag/Frag'

export class DownloadFactsheet extends Component{

    constructor(props) {
        const hashUrl = props.location.pathname
        let projectId = hashUrl.split('/');
        projectId = projectId[projectId.length-1];  
        projectId = window.atob(projectId)

        super(props)

        this.state = {
            downloadPdf:0,
            downloadProjectId:null,
            project_id: projectId
        }
    }
    componentDidMount(){
        this.pdfGenrete(this.state.project_id);
    }
    
    pdfGenrete = (projectId) => {
        this.setState({downloadPdf:1,downloadProjectId:projectId});
    }

    refreshPage = () => window.location.reload();

    render() {
        const {project_id, downloadPdf, downloadProjectId} = this.state
        return (
            <div>
                <Frag>
                <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
                    <Button 
                        onClick={this.refreshPage}
                        variant="outlined"
                        size="small"
                        color="primary" 
                        className="mb-2 text-capitalize"
                        style={{color:"#17839d"}}
                        >
                        {<FontAwesomeIcon icon={faDownload} className="nav-icon" />} &nbsp;&nbsp; Factsheet
                    </Button>
                </div>
                {(downloadPdf === 1 && downloadProjectId > 0) &&
                    <ProjectPdfContainer projectId={downloadProjectId} />
                }
                </Frag>
            </div>
        )
    }
}