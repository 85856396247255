import React, { useState } from 'react'
import { MyModal } from "../common/modal/MyModal";
import InputField from "../common/controls/InputField";
import { yesNoType } from '../common/datas/data';
import RadioGroup from "../common/controls/RadioGroup";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonSubmit from "../common/controls/ButtonSubmit"
import { getMagnetBrokerage, updateMagnetBrokerage } from '../../dataParser/getListUserData';
import Loader from '../common/loader/loader';
import PopUpAlert from '../common/sweetAlert/popUpAlert';
import { Box, TextField } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

const decimal_regex = /^\d*\.?\d{0,2}$/;

const getBrokerageAmount = (value, brokeragePercent) => {
    let brokerageAmount = value * (brokeragePercent / 100);
    brokerageAmount = Number(brokerageAmount.toFixed(2));
    return brokerageAmount;
}

const MagnetBrokerageModal = (props) => {
    const { show, changeModal, bookingId, leadId,magnetId, getList } = props;
    const [showLoader, setLoader] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [state, setState] = React.useState({
         agreement_value: 0
        , brokeragePart1: 0
        , amountPart1: 0
        , brokeragePart2: 0
        , amountPart2: 0
        , eligibleGST: false
        , brokerageGST:0
        , amountGST: 0
        , amountTDS:0
        , brokerageTDS:5
        , amountPayable:0
        , amountPaid:0
        , amountPending:0      
        , totalBrokerage:0
        , paymentValues: [{payment_phase : 1,payment_amount : 0,payout_done : 0, payment_date : null
                           ,booking_id : bookingId , magnet_id : magnetId, lead_id : leadId, status:1}],
    })
    const {agreement_value, brokeragePart1,  brokeragePart2, amountPart1, amountPart2,brokerageGST,amountGST,brokerageTDS,amountTDS
        ,eligibleGST,amountPayable,amountPaid,amountPending, totalBrokerage ,paymentValues} = state;
    
    const [alertState, setAlertState] = React.useState({
            alertShow: false
            , alertMsg: ""
    });
    const { alertShow, alertType, alertMsg } = alertState;
    const handleAlertClose = () => {
        setAlertState({
            ...alertState
            , alertShow: !alertShow
        })
        getList();
    }

    const getFullData = async () => {
        setLoader(true);
        let getRes = await getMagnetBrokerage(bookingId);
        let fullData = getRes.data;
        let infoData = fullData?.MagnetBrokerages;
        let paymentInfo = fullData?.MagnetBrokeragePaymentHistories;
        if (getRes.meta.status === 200) {
            setLoader(false);
            setData(fullData);
            setState({
                ...state
                , agreement_value: fullData?.agreement_value
                , brokeragePart1: infoData[0]?.brokerage_percent || 0
                , amountPart1: infoData[0]?.brokerage_ammount || 0
                , brokeragePart2: infoData[1]?.brokerage_percent || 0
                , amountPart2: infoData[1]?.brokerage_ammount || 0
                , brokerageGST : infoData[2]?.brokerage_percent || 0
                , amountGST : infoData[2]?.brokerage_ammount || 0
                , eligibleGST : infoData[2]?.brokerage_ammount > 0 ? true : false
                , paymentValues : paymentInfo[0]?.payment_phase ? paymentInfo : paymentValues
            })
        }
        else {
            setLoader(false);
            setData([]);
        }
    }
    React.useEffect(() => {
        getFullData();
    }, [])
    React.useEffect(() => {
        if (data?.magnet_percentage_split) {
            let amount = agreement_value * (data?.magnet_percentage_split / 100);
            let amountPart1 = getBrokerageAmount(amount, brokeragePart1);
            let amountPart2 = getBrokerageAmount(amount, brokeragePart2);
            let sum = amountPart1 + amountPart2;
            let amountGST=0;
            if(eligibleGST == true){
                amountGST = getBrokerageAmount(sum, brokerageGST);
            }else if(eligibleGST == false){
                amountGST = 0;
            }
            let totalBrokerage = sum ;
            let amountTDS = getBrokerageAmount(totalBrokerage, brokerageTDS);
            let amountPayable = totalBrokerage - amountTDS + amountGST;
            let paid = 0;
            for (let i=0;i<paymentValues.length;i++){
               if(paymentValues[i].payout_done){
                 paid = paid + Number(paymentValues[i].payment_amount);
               } 
            }          
            let amountPaid = Number(paid.toFixed(2));
            let amountPending = Number(amountPayable.toFixed(2))-amountPaid;
            totalBrokerage = Number(totalBrokerage.toFixed(2))
            setState({
                ...state, amountPart1, amountPart2, amountGST,amountTDS,
                amountPayable, totalBrokerage,amountPending,amountPaid
            })
        }
        }, [agreement_value, brokeragePart1, brokeragePart2, brokerageGST,paymentValues])

    const handleAddPayment= async() => {
        const val = [...paymentValues];
        var phase = val.length + 1;
        var i = val.length - 1;

        if (Number(val[i].payment_amount)) {
            setState({
                    ...state,
                    showLoader:true,
            })
            const value = [...paymentValues];
            if(val.length >= 4){
                setLoader(false)
                setAlertState({
                    ...alertState
                    , alertShow: true
                    , alertType: "error"
                    , alertMsg: "payment options limit exceed"
                })
            }else{
                value.push({payment_phase:phase,payment_amount:0,payout_done:0,payment_date:null,booking_id : bookingId,lead_id : leadId, magnet_id:magnetId, status : 1});
                setState({ 
                    ...state,
                    paymentValues: value, 
                });
            }
        }else{
            setLoader(false)
                setAlertState({
                    ...alertState
                    , alertShow: true
                    , alertType: "warning"
                    , alertMsg: "Please fill Payment amount"
                })
            }   
    }

    const handleChangeInput = (i, event) => {
        let val = [...paymentValues]
        let { name, value ,checked} = event.target
        val[i][name]=value? value : null;
        if(decimal_regex.test(value)){
            setState({
                ...state,
                paymentValues:val,
            }) 
        }
    
        if (name.includes("payout")) {
            val[i][name]=checked;
            val[i].payment_date=null;
        }
    }
    
    const handleDateChange = (date,idx) => { 
        let val=[...paymentValues]
        val[idx].payment_date = date
         setState({ 
             ...state,
              paymentValues:val,
             });
     }

    const handleGstStatus = (e) =>{
        let { name } = e.target;
        if (name.includes("eligible")) {
            if(eligibleGST === false){
                setState({
                    ...state, 
                    [name]: true,
                    brokerageGST:18,      
                })
            }else if(eligibleGST === true){
                setState({
                    ...state, 
                    [name]: false,
                    brokerageGST:0,      
                })
            }
        }
     }

    const handleChange = (e) => {
        let { name, value} = e.target;
         if (value === '' || decimal_regex.test(value)) {
                setState({
                    ...state,
                    [name]: value
                })
            }
        
    }

    const handleSubmit = async () => {
        let paid = 0;
        for (let i=0;i<paymentValues.length;i++){
                if(paymentValues[i].payout_done && paymentValues[i].payment_date == null){
                    setLoader(false)
                    setAlertState({
                        ...alertState
                        , alertShow: true
                        , alertType: "warning"
                        , alertMsg: "Please select DATE"
                    })
                    return false;
                }else if(paymentValues[i].payout_done){
                    paid = paid + parseInt(paymentValues[i].payment_amount);
                }   
        }
        const amountPaid = paid ;
        if(amountPaid > amountPayable){
            setLoader(false)
            setAlertState({
                ...alertState
                , alertShow: true
                , alertType: "error"
                , alertMsg: "PAID amount can not be more than PAYABLE amount"
            })
            return false;
        } 
        let payload = {
            id : bookingId,
            paymentValues:paymentValues,
            brokerage:[
                {  
                    brokerage_phase:1,    
                    booking_id : bookingId,
                    magnet_id : magnetId,
                    lead_id : leadId,
                    brokerage_percent:brokeragePart1 ? brokeragePart1 : 0, 
                    brokerage_ammount:amountPart1,
                    status:1,
                },
                {
                    brokerage_phase:2,   
                    booking_id :bookingId,
                    magnet_id : magnetId,
                    lead_id : leadId,
                    brokerage_percent:brokeragePart2 ? brokeragePart2 : 0,
                    brokerage_ammount:amountPart2,
                    status:1,
                },
                { 
                    brokerage_phase:3,    
                    booking_id : bookingId,
                    magnet_id : magnetId,
                    lead_id : leadId,
                    brokerage_percent:brokerageGST,
                    brokerage_ammount:amountGST,
                    status:1,
                },
                {
                    brokerage_phase:4,    
                    booking_id : bookingId,
                    magnet_id : magnetId,
                    lead_id : leadId,
                    brokerage_percent:brokerageTDS,
                    brokerage_ammount:amountTDS,
                    status:1,
                }
            ],
        };
        setLoader(true)
        let sendData = await updateMagnetBrokerage(payload);
        if (sendData.meta.status === 201) {
            getFullData();
            setLoader(false)
            setAlertState({
                ...alertState
                , alertShow: true
                , alertType: "success"
                , alertMsg: sendData?.data?.message || "Updated successfully"
            })
        }
        else{
            setLoader(false)
            setAlertState({
                ...alertState
                , alertShow: true
                , alertType: "error"
                , alertMsg: sendData?.meta?.message || "Something went wrong !"
            })
        }  
    }

    const variant = "standard"
    return (
        <div className='my-3'>
            <Loader show={showLoader} />
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
            <MyModal size="xl" title="Magnet Brokerage" show={show} changeModal={changeModal}>
                <>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-4 mb-3">
                                <InputField
                                    disabled
                                    variant={variant}
                                    name="agreement_value"
                                    value={agreement_value}
                                    label="Agreement Value"
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <InputField
                                    variant={variant}
                                    disabled
                                    value={data?.magnet_percentage_split || 0}
                                    label="Magnet Split %"
                                />
                            </div>
                        </div>

                        <div className="row justify-content-between">
                            <div className="col-md-5 col-5 mb-2">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-6 mb-2">
                                        <InputField
                                            variant={variant}
                                            name="brokeragePart1"
                                            value={brokeragePart1}
                                            endIcon="%"
                                            label="Base Brokerage"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-6 mb-3">
                                        <InputField
                                            variant={variant}
                                            disabled
                                            name="amountPart1"
                                            value={amountPart1}
                                            label="Amount"
                                        />
                                    </div>
                                    <div className="col-md-6 col-6 mb-2">
                                            <InputField
                                            variant={variant}
                                            name="brokeragePart2"
                                            value={brokeragePart2}
                                            endIcon="%"
                                            label="Ladder Brokerage"
                                            onChange={handleChange}
                                        />      
                                    </div>
                                    <div className="col-md-6 col-6 mb-3">
                                        <InputField
                                            variant={variant}
                                            disabled
                                            name="amountPart2"
                                            value={amountPart2}
                                            label="Amount"
                                       />  
                                    </div>
                                    <div className="col-md-3 col-3 mb-3">
                                        <InputField
                                            variant={variant}
                                            disabled
                                            name="brokerageGST"
                                            value={brokerageGST}
                                            endIcon="%"
                                            label="GST"
                                        />
                                    </div>
                                    <div className="col-md-4 col-4 mb-3">
                                        <RadioGroup
                                            name="eligibleGST"
                                            value={eligibleGST}
                                            items={yesNoType}
                                            onChange={handleGstStatus}                                          
                                        />
                                    </div>
                                    <div className="col-md-5 col-5 mb-3">
                                        <InputField
                                            variant={variant}
                                            disabled
                                            name="amountGST"
                                            value={amountGST}
                                            label="Amount"
                                        />
                                    </div>
                                </div>
                            </div> 
                   
                            <div className="col-md-6 col-6 mb-2">
                                {paymentValues.map((field, idx) => (
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-4 pl-0">
                                        <FormGroup>
                                            <FormControlLabel
                                                className="ml-0"
                                                control={
                                                        <InputField
                                                            type="number"
                                                            variant={variant}
                                                            name={"payment_amount"}
                                                            value={field.payment_amount}
                                                            label={idx === 0 ? "Advance payment":"Payment "+ idx}
                                                            onChange={e => handleChangeInput(idx, e)}
                                                        />
                                                }
                                            labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-2 col-2 pl-0">
                                        <FormGroup>
                                            <FormControlLabel
                                                className="ml-0"
                                                control={<Checkbox
                                                    name="payout_done"
                                                    checked={field.payout_done}
                                                    onChange={e => handleChangeInput(idx, e)}
                                                    color="primary"
                                            />}
                                            label="Paid"
                                            labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4 col-4 ">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disabled={Number(field.payout_done) ? false : true}
                                            name="payment_date"
                                            value={field.payment_date}
                                            label="Date"
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            autoOk={true}
                                            onChange={(date) => handleDateChange(date,idx)}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-2 col-2 ">
                                    {(paymentValues.length-1==idx) ? 
                                        (<Box  width={1} my={1}>
                                            <Box width="50%" className="ButtonPart">
                                                <button
                                                    className="btn btn-link bg-blue"
                                                    type="button"
                                        onClick={() => handleAddPayment()}
                                                >
                                                    <AddCircle />
                                                </button>
                                            </Box>
                                        </Box> )
                                    : (null)}
                                     </div>
                                </div>
                                ))}
                            </div>
                        </div>  

                        <div className="row justify-content-between">
                            <div className='col mb-4'></div>
                        </div>

                        <div className="row justify-content-between">
                            <div className='col-md-3 col-3 mb-3'>
                            <InputField
                                    variant={variant}
                                    disabled
                                    name="totalBrokerage"
                                    value={totalBrokerage}
                                    label="Total Brokerage"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='col-md-3 col-3 mb-3'>
                            <TextField 
                                    disabled
                                    variant={variant}
                                    value={amountTDS}
                                    label='TDS'
                                    />
                            </div>
                            <div className='col-md-3 col-3 mb-3'>
                            <TextField 
                                    disabled
                                    variant={variant}
                                    value={amountGST}
                                    label='GST'
                                    />
                            </div>
                            <div className='col-md-3 col-3 mb-3'>
                            </div>
                        </div>                
                        <div className='row justify-content-between'>
                            <div className='col-md-3 col-3 mb-2'>
                            <TextField 
                                    disabled
                                    variant={variant}
                                    value={amountPayable}
                                    label='Payable'
                                    />
                            </div>
                            <div className='col-md-3 col-3 mb-2'>
                            <TextField 
                                    disabled
                                    variant={variant}
                                    label='Paid'
                                    value={amountPaid}
                                    />
                            </div>
                            <div className='col-md-3 col-3 mb-2'>
                            <TextField 
                                    disabled
                                    variant={variant}
                                    label='Pending'
                                    value={amountPending}
                                    />
                            </div>
                            <div className='col-md-3 col-3 mb-2'>
                            </div>
                        </div>  
                         <ButtonSubmit onClick={handleSubmit} /> 
                    </div> 
                </>
            </MyModal>
        </div>  
    )
}

export default MagnetBrokerageModal