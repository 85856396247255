import React from 'react'
import {FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio} from '@material-ui/core'

function RadioGroup(props) {
	const {label, name, value, onChange, items, required} = props
	return (
		<div>
			<FormControl>
				<FormLabel required={required}>{label}</FormLabel>
				<MuiRadioGroup 
					row
					name={name}
					value={value}
					onChange={onChange}>
					{items.map((item, index)=>(
						<FormControlLabel 
							className="mr-1"
							key={index} 
							value={item.id} 
							control={<Radio color="primary" checked={value == item.id}/>} 
							label={item.name}/>
					))}
				</MuiRadioGroup>
			</FormControl>
		</div>
	)
}

export default RadioGroup