import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ProjectPdfPreview from "../../component/pdfGenrete/ProjectPdfPreview";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  factsheetIcon: {
    padding: 0,
    "& svg": {
      fontSize: 32,
      color: "#245983"
    }
  }
}));
const FactSheetPreview = ({ id, icon }) => {
  const classes = useStyles();
  const [state, setState] = useState({});
  const handleModalClose = () => {
    setState({
      ...state,
      showModal: false
    });
  };

  const pdfPreview = projectId => {
    setState({
      ...state,
      showModal: true,
      previewProjectId: projectId
    });
  };
  return (
    <>
      {icon ? (
        <Tooltip title="Factsheet" arrow className="cursor-pointer">
          <span onClick={() => pdfPreview(id)}>
            <IconButton className={classes.factsheetIcon}>
              <AssignmentIcon />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Button
          className="text-capitalize py-1 mb-2"
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => pdfPreview(id)}
          style={{ color: "#0E3746" }}
        >
          Factsheet
        </Button>
      )}
      {state.previewProjectId ? (
        <ProjectPdfPreview
          showModal={state.showModal}
          handleModalClose={handleModalClose}
          projectId={state.previewProjectId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FactSheetPreview;
