import React, {useEffect, useState} from 'react'
import MgRegisterHeader from './MgRegisterHeader'
import RegisterForm from './RegisterForm';
import "./magnetRegister.css"
import MgSlider from './MgSlider';
import MetaData from '../common/datas/MetaData';

const MagnetRegister = () => {
    const [magnetDomain, setDomain] = useState(false);
    useEffect(() => {
        let DYNAMIC_METADATA_URL = window.location.host
        if (DYNAMIC_METADATA_URL.includes("mymagnet.io")) {
            setDomain(true)
        }
    })
    return (
        <div id="bgImage" className="MainSection">
            <MetaData/>
            <MgRegisterHeader />
            <div className="container-fluid w-90 mt-5 pt-4">
                <div className="row align-items-center mt-3">
                    <div className="col-md-4">
                        <RegisterForm />
                    </div>
                   {magnetDomain && <div className="offset-1 col-md-5 d-none d-md-block">
                        <MgSlider/>
                    </div> }
                </div>
            </div>
        </div>
    )
}
export default MagnetRegister
