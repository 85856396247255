import React from 'react';

import Menu_ from '@material-ui/core/Menu';

import './Menu.sass'
import clsx from 'clsx';


const defaultState = {
}


const Menu = props => <Menu_ className="Menu" {...props}>{props.children}</Menu_>

export default Menu;
