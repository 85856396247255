import React, { Component } from 'react';
import {getLeadinternalHistroyData} from "../../dataParser/getListData";
import {changeDate} from "../../helper";
import Footer from "../../component/footer/footer";  

class LeadInternalHistory extends Component {
    constructor(props) {
      super(props);
        this.state = {
          sideBar: false,
          meta: {},
          data: [],
          mobileSideBar: false,
      };

    }

    async componentDidMount() {
        this.setState({showLoader : true})
        const leadId  = window.location.pathname.split('/');
        let id = leadId[2];

        (async () => {
            try {
                let resData = await getLeadinternalHistroyData(id);
                if(resData.meta.status == 200){
                    await this.setState({
                            meta: resData.meta,     
                            data : resData.data,
                            showLoader : false
                        })
                    window.scrollTo(0, 0);
                }else{
                    this.setState({
                        meta: resData.meta,
                        data : resData.data,
                        showLoader : false
                    })
                }
            } catch (e) {   
                //...handle the error...
            }
        })();
        this.update();
    }

    update = () => {
        this.setState({
          screenWidth: window.innerWidth
        });
    };

    changeButtonState(event) {
        this.setState({sideBar: !this.state.sideBar})
    }

    changeSideBarState(event) {
        this.setState({mobileSideBar: !this.state.mobileSideBar})
    }

    render()  {

        const {meta,data} = this.state;        

        return (
            <div>
                <div className="content-wrapper">
                    <div className="listContainer">
                        <div className="p-3 leadMainHeader">
                            <span className="totalCount">Lead Internal History </span>
                        </div>    
                        {meta.status === 200 && data.length ? 
                            (data.map((comment,index) =>
                                <div className="card shadow pt-2 pb-2 mb-2" key={index}>
                                    <div className="container">
                                        <div className="Comment_row row mb-2">
                                            <div className="col-md-2 col-12">
                                                <span className="font-small text-muted">Action By : </span>
                                                <span className="text-capitalize font-small valueText">{comment.User != null ? comment.User.name : "-"}</span>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <span className="font-small text-muted">Lead Internal Status : </span>
                                                <span className="text-capitalize font-small valueText">{comment.LeadInternalStatus != null ? comment.LeadInternalStatus.name : "-"}</span>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <span className="font-small text-muted">Action Date : </span>
                                                <span className="valueText">{comment.action_date !== null ? changeDate(comment.action_date) : "-"}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="font-small text-muted">Comment : </span>
                                                <span className="textValue commentText">{comment.comment !== null ? comment.comment : "-"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))    
                            :
                            <div>
                                <span className="col-md-12 text-muted fs-14 text-center">History not available !!!</span>
                            </div>
                        }
                    </div>
                </div>
                <Footer expand ={this.state.sideBar} />

            </div>
        );

    }
}

export default LeadInternalHistory;





















