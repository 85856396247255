import React, {Component} from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import {withRouter} from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {userRegister} from "../../../dataParser/magnetUser";
import Loader from "../../../component/common/loader/loader";
import { cleanStorage } from '../../../helper';

const validateEmail = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
const validateName = RegExp(/^[a-zA-Z ]+$/);
const validateMobileNum = RegExp(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/);

class AddTempCpFrom extends Component {

	constructor(props) {
    	super(props);
		this.state = {
			assign_rm_id : '',
			first_name :'',
            last_name :'',
            phone :'',
            email :'',
            sweetShow:false,
            type : "success",
            title : "",
            errors : {
            	first_name : '',
                last_name : '',
                phone : '',
                p_message : '',
                email : '',
                comment : ''
            } 
		};
	}

	async componentDidMount(){

		let homesfy_lg =(localStorage.getItem("homesfy_lg"));
        let addedBy;
		if(homesfy_lg && homesfy_lg !== ""){
	        let user = JSON.parse(window.atob(homesfy_lg));
	        addedBy = user.id;
	        this.setState({assign_rm_id : addedBy})

	    }else{
	        addedBy = '';
	        this.setState({assign_rm_id : addedBy})
	    }

	}

	handleUpdateUser = async (e) =>{

        e.preventDefault();

       const addUserRequest = (({assign_rm_id,first_name,last_name,phone,email,comment}) => ({
           assign_rm_id ,
           first_name ,
           last_name ,
           phone ,
           email ,
           comment
       }))(this.state);

       let addUserData = {};

        if(addUserRequest.assign_rm_id != ""){
            addUserData["enroll_rm_id"] = addUserRequest.assign_rm_id;             
        }
        if(addUserRequest.first_name != ""){
            addUserData["first_name"] = addUserRequest.first_name;             
        }

        if(addUserRequest.last_name != ""){
            addUserData["last_name"] = addUserRequest.last_name;             
        }

        if(addUserRequest.phone != ""){
            addUserData["phone"] = addUserRequest.phone;             
        }

        if(addUserRequest.email != ""){
            addUserData["email"] = addUserRequest.email;             
        }

        if(addUserRequest.comment != ""){
            addUserData["comment"] = addUserRequest.comment;             
        }
        addUserData["source"] = "crm";  


       if(addUserRequest.first_name != "" && addUserRequest.last_name != "" && addUserRequest.email != "" &&  addUserRequest.phone != "" && validateMobileNum.test(addUserRequest.phone)) {

           this.setState({showLoader:true}); 
           let addLeadRes = await userRegister(addUserData);

           if(addLeadRes.meta.status === 503){
               
                this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "error",    
                   title : "!!! Oooops Network Error..." 
                });
               
           }else if(addLeadRes.meta.status === 201){
               
               this.setState({
                    showLoader:false,
                    sweetShow:true,
                    type : "success", 
                    title : "Registered successfully!!!"               
                });
           }else if(addLeadRes.meta.status === 208){
               
               this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "warning",    
                   title : "User with same number already exists!!!"               
               });
               
           }else if(addLeadRes.meta.status === 401){
               
               cleanStorage();
               this.props.history.push("/login");
               
           }else{
               
                this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "error",
                   title : "Something went wrong !!!" 
                });
                console.log(addLeadRes.meta.message); 
           }
       }else{

          this.setState({isError:true});

           if(this.state.first_name == "" || validateName.test(this.state.first_name) === false){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       first_name:"*please enter valid client name"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       first_name:""
                   }
               }))
           }

           if(this.state.last_name == "" || validateName.test(this.state.last_name) === false){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       last_name:"*please enter valid Last name"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       last_name:""
                   }
               }))
           }
           
           if(this.state.phone == "" || validateMobileNum.test(this.state.phone)=== false){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       phone:"*please enter valid client number"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       phone:""
                   }
               }))
           }

           if(addUserRequest.email == "" || validateEmail.test(this.state.email)=== false){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       email:"*please enter valid Email"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       email:""
                   }
               }))
           }
       }
    }

	onChange = (e) => {

	    this.setState({ [e.target.name]: e.target.value });
 	}


 	handleSweet=()=>{
        this.setState({sweetShow : !this.state.sweetShow});
    	this.props.changeModal()
    }

    render() {
	 	
	    const {sweetShow,type,title} = this.state;

        return (
            <Frag>
            	<div className="projectForm">
                    <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
					<Loader show={this.state.showLoader}/>
                    <form role="form">
						<div className="card-body">
							<div className="row">
                                <div className="col-md-12">
                                    <form className="w-100">
                                       <div className="row mb-3">
                                          <div className="col-md-6 mb-3">
                                            <TextField
                                                error = {this.state.errors.first_name !== '' ? 'error' : ''}
                                                required
                                                id="first_name"
                                                name="first_name"
                                                label="First Name"
                                               helperText={this.state.errors.first_name !== '' ? this.state.errors.first_name : ''}
                                                onChange={this.onChange}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <AccountCircle />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              /> 
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    error = {this.state.errors.last_name !== '' ? 'error' : ''}
                                                    required
                                                    id="last_name"
                                                    name="last_name"
                                                    label="Last Name"
                                                   helperText={this.state.errors.last_name !== '' ? this.state.errors.last_name : ''}
                                                    onChange={this.onChange}
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          <AccountCircle />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                            </div>
                                       </div>
                                       <div className="row mb-3">
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                    error = {this.state.errors.phone !== '' ? 'error' : ''}
                                                    required
                                                    id="phone"
                                                    name="phone"
                                                    label="Contact Number"
                                                    helperText={this.state.errors.phone !== '' ? this.state.errors.phone : ''}
                                                    onChange={this.onChange}
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          <PhoneIphoneRoundedIcon />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField id="standard-basic" label="Email"
                                                    required
                                                    error = {this.state.errors.email !== '' ? 'error' : ''}
                                                    value={this.state.email}
                                                    id="email"
                                                    name="email"
                                                    helperText={this.state.errors.email !== '' ? this.state.errors.email : ''}
                                                    onChange={this.onChange} 
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          <MailOutlineIcon />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                 />
                                            </div>
                                            <div className="col-sm-6 col-12">
												<div className="form-group">
													<TextField
														error = {this.state.errors.comment !== '' ? 'error' : ''}
											        	id="standard-multiline-flexible"
											          	label="Comments"
											          	name="comment"
											          	helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''} 
											          	multiline
											          	rowsMax="4"
											          	value={this.state.comment}
											          	onChange={this.onChange}
											        />
												</div>
											</div>
                                       </div>
                                    </form>
                                </div>
                            </div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.handleUpdateUser}>Submit</button>
                                        <p className="mb-0">
                                            <span className="text-center text-danger small">{this.state.isError === true ? '*Some error in the above fields or fileds are reqiured !!!' : ''}</span>
                                        </p>
									</div>
								</div>
							</div>	
						</div>
					</form>
	            </div>
            </Frag>
        );
    };
};

export default withRouter(AddTempCpFrom);
