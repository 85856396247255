import moment from "moment";
import {BED_CONFIG, ENGAGE_TOKEN, FOLLOWUP_DATE_TIME_FORMAT} from "../config/constant";
import {createClient} from 'contentful';

export const client = createClient({
    space: 'p1hzdyj788ix',
    accessToken: '_hrhj-22YevEMuqmkBTVD1wOZ9ajbLMe7DQwpKWlPTM'
})

export const changeDate = date => {
  if (date !== null && date !== undefined) return moment(date).format("llll");
  return null;
};

export const letterCase = (str, lower = false) => {
  if (str !== "")
    return lower
      ? str.toLowerCase()
      : str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  // return false;
};

export const phoneCheck = phone => {
  // const regExp = RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
  const regExp = RegExp(/^[6-9]\d{9}$/);

  if (phone.length > 6 && phone.length < 11 && regExp.test(phone)) return true;
  return false;
};

export const emailCheck = email => {
  const regExp = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);

  if (regExp.test(email)) return true;
  return false;
};

export const passwordCheck = pass => {
  const regExp = RegExp(
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
  );

  if (regExp.test(pass)) return true;
  return false;
};

export const aadharCheck = data => {
  const regExp = RegExp(/^\d{4}\d{4}\d{4}$/);

  if (regExp.test(data)) return true;
  return false;
};
export const nameCheck = data => {
  const regExp = RegExp(/^[a-zA-Z ]+$/);

  if (regExp.test(data)) return true;
  return false;
};

export const objEmpty = obj => {
  // console.log(Object.keys(obj));
  return Object.keys(obj).length == 0;
};

export const arrayEmpty = obj => {
  return obj.length === 0;
};

export const empty = obj => {
  return obj === "" || obj === undefined;
};

export const getMe = (key = false) => {
  let user = null;
  console.log("++++++++++++", window.$role);
  if (key) {
    if (window.$user !== null) return window.$user[key];
    return user;
  }
  return window.$user;
  // var homesfy_lg =(localStorage.getItem("homesfy_lg"));
  //  if(homesfy_lg && homesfy_lg !== ""){
  //     let user = JSON.parse(window.atob(homesfy_lg));
  //     let token = {token: "bearer "+ user.token};

  //     return token;
  // }else{
  //     let token = {token: ''};
  //     return token;
  // }
};

export const getToken = () => {
  var homesfy_lg = localStorage.getItem("homesfy_lg");
  if (homesfy_lg && homesfy_lg !== "") {
    let user = JSON.parse(window.atob(homesfy_lg));
    let token = { token: "bearer " + user.token };

    return token;
  } else {
    // console.log("got THE TOKEN Else");
    let token = { token: "" };
    return token;
  }
};

export const cleanStorage = () => {
  window.$user = null;
  window.$role = null;
  let tours = localStorage.getItem('tours');

  localStorage.clear();
  
  tours && localStorage.setItem("tours", tours);

  window.location.href = "/";
};

export const getBhk = value => {
  // let bhk =this.state.required_bhk.split(' ');
  return parseInt(value);
};

export const priceConverter = value => {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = val / 10000000 + " Crores";
  } else if (val >= 100000) {
    val = val / 100000 + " Lacs";
  } else if (val >= 1000) {
    val = val / 1000 + " K";
  }
  //jsfiddle.net/33nw5jcd/1/#
  /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
  https: return val;
};

export const priceReverser = value => {
  // var val = Math.abs(value)

  let price = value.split(" ");
  if (price[1] === "Crores") {
    price = (price[0] * 10000000).toFixed(2);
  } else if (price[1] === "Lacs") {
    price = (price[0] * 100000).toFixed(2);
  }
  return price;
};
// text capitalize,
// phone number check,
// email check,
// password,
// model popup function,
// sweet popup,
// response check

export const checkExpiry = () => {
  let now = new Date();
  let homesfy_lg = localStorage.getItem("homesfy_lg");
  if (homesfy_lg && homesfy_lg !== "") {
    let user = JSON.parse(window.atob(homesfy_lg));
    if (now.getTime() > user.expire) {
      window.$user = null;
      window.$role = null;
      cleanStorage()
      window.location.href = "/";
      return null;
    }
  }
};

export const dataSort = property => {
  return function (a, b) {
    if (a[property] < b[property]) {
      return -1;
    } else if (a[property] > b[property]) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const priceUnit = value => {
  let unit = Math.abs(value);
  if (unit >= 10000000) {
    unit ="Cr";
  }
  else if (unit >= 100000) {
    unit ="Lac";
  }
  else if (unit >= 1000) {
    unit ="Th";
  }
  else{
    unit=""
  }
  return unit;
};

export const indianRupeeCommaSeparated = x => {
  if (x) {
    x = x.toString();
    let afterPoint = "";
    if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    let res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;

    return res + " " +priceUnit(x);
  } else {
    return 0;
  }
};

export const roundToTwoDecimalPlaces = number => {
  if(!Number(number)){
    number = 0;
  }
  return (Math.round(number * 100) / 100).toFixed(2);
};

export const getEngageToken = () =>{
    var url = window.location.href
    var arr = url.split("/");
    const domain = arr[2];
    const ENV = domain.includes('dev') ? 'staging' : 'production';
    const token = ENGAGE_TOKEN[ENV];
    return token;
}

export const randomNumber = (min, max) => { 
  let number = Math.random() * (max - min) + min;
  return number.toFixed(0);
}

export const getStartDateMax = () => {
  let d = new Date();
  d.setDate(d.getDate() + 60);
  return d;
};

export const numDifferentiation = value =>{
  var val = Math.abs(Number(value))
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  }
  return val;
}

export const timeConverter = time =>{
  let val = (time / 60).toFixed(2) + " mins";
  return val
}

export const dateTimeConverter = datetime =>{
  // let date = moment(datetime, 'YYYY/MM/DD h:m A').format('YYYY/MM/DD HH:mm'); 
  let date = moment(datetime, FOLLOWUP_DATE_TIME_FORMAT).format('YYYY/MM/DD HH:mm')
  return date
}

export const titleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const redirectLogin = (redirect_url, login_key) => {
  if(["magnet", "magnets2", "magnets3"].includes(window?.$user?.role)) {
    let URL = `${redirect_url}?homesfy_lg=${login_key}`;
    cleanStorage()
    window.location.replace(URL);
  }
}

export const plotCommercial = (value) =>{
  let bed_config = value;
  bed_config = BED_CONFIG.filter(config=>config.key === bed_config)[0]?.value;
  return bed_config;
}

export const generateMonths = () => {
  let months = [];
  for (let i = 1; i < 13; i++) {
    months.push({
      key: `${i}`.padStart(2, "0"),
      value: new Date(i.toString()).toLocaleString("default", {
        month: "long"
      })
    });
  }

  return months;
};

export const generateYears = () => {
  let years = [];
  for (
    let i = new Date().getFullYear() - 15;
    i <= new Date().getFullYear();
    i++
  ) {
    years.push(i);
  }

  return years;
};

export const removeCommas = (value) =>{
  let val = value
  if (val?.toString() && val?.toString().includes(',')) {
    val = val.split(",").join("")
  }
  return val
}

export const getCurrentQuarter = () => {
  const quarter =  [4,4,4,1,1,1,2,2,2,3,3,3]
  let month = Number(new Date().getMonth());
  return quarter[month];
}