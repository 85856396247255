import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  getAllProjectData,
  getProjectDetail
} from "../../dataParser/getProjectData";
import Loader from "../../component/common/loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faEdit, faUpload,faEye } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import "./projectDetail.css";
import ProjectModel from "../../component/common/modal/projectModel";
import DetailProjectModal from "../../component/detailLead/projectDetailModal";
import ProjectFilter from "../filter/projectFilter";
import Frag from "../../utils/Frag/Frag";
import Footer from "../../component/footer/footer";
import { changeDate, cleanStorage } from "../../helper";
import AutoLogout from "../../component/autoLogout/logout.js";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Tooltip from "@material-ui/core/Tooltip";
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import ListingView from "../common/listing/ListingView";
import { OneSixthField, HalfField } from "../common/listing/ListingView";
import FactSheetPreview from "./FactSheetPreview";
import DownloadFactsheet from "./DownloadFactsheet";
import ProjectDocModal from "./projectDocument";

const projectEdit = [
  "admin",
  "magnetadmin",
  "magnetpnl",
  "saleshead",
  "mediamanagers",
  "campaignmanagers",
  "campaignoperations",
  "leadoperations",
  "pnlhead"
];
const projectDocAccessUpload = [
  "admin",
  "magnetadmin",
  "saleshead",
  "pnlhead",
  "salesmanager"
]
const projectDocAccessView = [
  "admin",
  "magnetadmin",
  "saleshead",
  "pnlhead",
  "salesmanager",
  "financehead",
  "financeanalyst",
  "financeassociate",
]

class projectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      alertShow: false,
      type: "success",
      title: "",
      showLoader: false,
      showFilter: false,
      meta: props.projectDataValue.meta,
      data: props.projectDataValue.data,
      modalShow: false,
      fullDetail: "",
      updateModalShow: false,
      addModalShow: false,
      is_active: {},
      isDesktop: 0,
      showMoreDetailsForProject: [],
      showDocumentModal: false,
      selectedProject: '',
      documentModalType: ''
    };
    this.fltr = React.createRef();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (
      this.node !== undefined &&
      this.node !== null &&
      !this.node.contains(e.target) &&
      this.state.showFilter &&
      e.target.className !==
        "MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button" &&
      e.target.className !==
        "MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit" &&
      e.target.className !== "MuiAutocomplete-option" &&
      e.target.className !== "MuiAutocomplete-noOptions"
    ) {
      this.setState({
        showFilter: false
      });
    }
  };

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        meta: nextProps.projectDataValue.meta,
        data: nextProps.projectDataValue.data,
        activePage: 1
      });
    }
  }

  genericGetListData = async params => {
    this.setState({ showLoader: true });

    (async () => {
      try {
        let resData = await getAllProjectData(params);
        if (resData.meta.count >= 0 && resData.meta.status == 200) {
          await this.setState({
            meta: resData.meta,
            data: resData.data,
            showLoader: false
          });
          window.scrollTo(0, 0);
        } else {
          this.setState({
            meta: resData.meta,
            data: resData.data,
            showLoader: false
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  handleChangePage = async pageNumber => {
    this.setState({
      showLoader: true,
      activePage: pageNumber
    });
    var listData = {};
    listData.size = 8;
    listData.pageId = pageNumber;
    Object.assign(listData, this.state.filters);

    this.genericGetListData(listData);
  };

  fullProjectDetail = async id => {
    this.setState({ showLoader: true });

    var resData = {};
    resData = await getProjectDetail(id);

    if (resData.meta.status === 200) {
      await this.setState({
        fullDetail: resData,
        showLoader: false
      });
      window.scrollTo(0, 0);
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({
        fullDetail: resData,
        showLoader: false
      });
    }
  };

  handleModal = value => {
    this.setState({ modalShow: !this.state.modalShow });
  };
  handleEditProject = async id => {
    this.setState({ showLoader: true });

    var resData = {};
    resData = await getProjectDetail(id);

    if (resData.meta.status === 200) {
      await this.setState({
        fullDetail: resData,
        showLoader: false
      });
      window.scrollTo(0, 0);
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({
        fullDetail: resData,
        showLoader: false
      });
    }

    this.props.history.push({
      pathname: "/projects/add-update",
      state: {
        projectId: id,
        fullDetail: resData,
        filterData: this.state.filters
      }
    });
  };

  handleDocumentModal = (id, modalType) => {
    this.setState({showDocumentModal: true, selectedProject: id, documentModalType: modalType})
  }
  handleDocumentClose = () => {
    this.setState({showDocumentModal: !this.state.showDocumentModal})
  }
  handleAddProject = () => {
    this.props.history.push({
      pathname: "/projects/add-update"
    });
  };
  handleAddModal = value => {
    this.setState({ addModalShow: !this.state.addModalShow });
  };
  updateHandleModal = value => {
    this.setState({
      updateModalShow: !this.state.updateModalShow,
      fullDetail: ""
    });
  };

  handleFilter = async filterData => {
    this.setState({ showLoader: true });
    this.setState({ filters: filterData, activePage: 1 });
    let listData = {};
    listData.size = 8;
    listData.pageId = 1;
    Object.assign(listData, filterData);

    this.genericGetListData(listData);
  };

  handlePopUp = () => {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter
    }));
  };

  handleClose = () => {
    this.setState({ alertShow: false });
  };

  toggleMoreDetails = projectId => {
    let index = this.state.showMoreDetailsForProject.findIndex(
      o => o === projectId
    );
    if (index > -1) {
      let showMoreDetailsForProject = [...this.state.showMoreDetailsForProject];
      showMoreDetailsForProject.splice(index, 1);
      this.setState({ showMoreDetailsForProject });
    } else {
      this.setState({
        showMoreDetailsForProject: [
          ...this.state.showMoreDetailsForProject,
          projectId
        ]
      });
    }
  };

  sourcingManagerGrid = external_project_rm => {
    let external_project_rms = [];

    try {
      external_project_rms = JSON.parse(external_project_rm);
    } catch (error) {
      console.log(error);
    }

    return (
      <table className="table table-sm table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">
              <span>Name</span>
            </th>
            <th scope="col">
              <span>Number</span>
            </th>
            <th scope="col">
              <span>Designation</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {external_project_rms.map(o => (
            <tr>
              <td>
                <span>{o.name}</span>
              </td>
              <td>
                <span>{o.number}</span>
              </td>
              <td>
                <span>{o.designation}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const { count, size, status } = this.state.meta;
    const { fullDetail, data, alertShow, type, title, showDocumentModal } = this.state;
    const isDesktop = this.state.screenWidth;
    return (
      <Frag>
        <div className="content-wrapper">
          <AutoLogout />
          <Loader show={this.state.showLoader} />
          <PopUpAlert
            type={type}
            msg={title}
            open={alertShow}
            handleClose={this.handleClose}
          />
          <div className="p-3 leadMainHeader">
            <div className="headerCount">
              <span className="totalCount">Total Projects : </span>
              <span className="">
                {status === 400 && this.state.data.length ? "0" : count}
              </span>
            </div>
            <div className="d-inline ml-3 filterHeader">
              <span className="filter" onClick={this.handlePopUp}>
                <FontAwesomeIcon icon={faFilter} className="nav-icon" />
              </span>
              <div
                className={
                  "filterMenu" +
                  " " +
                  (this.state.showFilter ? "filterMenuShow" : "")
                }
                ref={node => {
                  this.node = node;
                }}
              >
                <ProjectFilter
                  fltr={this.fltr}
                  filterData={this.handleFilter}
                  changeFilter={this.handlePopUp}
                />
              </div>
            </div>
            {window.$role !== "magnet" && (
              <div
                className="assign_magnet filter"
                onClick={this.handleAddProject}
              >
                <Tooltip title="Add Project">
                  <AddCircleIcon />
                </Tooltip>
              </div>
            )}
          </div>
          {status === 200 && this.state.data.length ? (
            <div className="listContainer">
              <div className="container-fluid">
                <div className="row ProjectDetail">
                  {data.map((projectsList, index) => (
                    <ListingView key={projectsList.id}>
                      <OneSixthField title="ID" value={projectsList.id} />
                      <OneSixthField
                        title="Project Name"
                        value={projectsList?.name || "-"}
                      />
                      <OneSixthField
                        title="Builder Name"
                        value={projectsList?.builder_name || "-"}
                      />
                      <OneSixthField
                        title="Region"
                        value={projectsList?.Region?.name || "-"}
                      />
                      <OneSixthField
                        title="Project Status"
                        value={
                          projectsList?.is_active === true
                            ? "Active"
                            : "In-active" || "-"
                        }
                      />
                      <OneSixthField
                        title="Date"
                        value={changeDate(projectsList?.created_at) || "-"}
                      />
                      <OneSixthField
                        title="Added By"
                        value={projectsList?.ProjectCreator?.name || "-"}
                      />
                      <HalfField
                        title="Location"
                        value={projectsList?.location || "-"}
                      />
                      {this.state.showMoreDetailsForProject.findIndex(
                        o => o === projectsList.id
                      ) > -1 && projectsList.external_project_rm ? (
                        <div className="col-md-6 col-12 mt-3">
                          <span className="text-muted text-capitalize">
                            Sourcing Manager
                          </span>
                          {this.sourcingManagerGrid(
                            projectsList.external_project_rm
                          )}
                        </div>
                      ) : null}
                      <div className="col-md-12 col-12 mt-2">
                        <div className="row">
                          <div className="offset-md-8"></div>
                          <div className="col-md-2 col-6 mt-2">
                            {projectsList.external_project_rm && (
                              <div className="row">
                                <div className="detailText col-md-12">
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block"
                                    }}
                                    onClick={() =>
                                      this.toggleMoreDetails(projectsList.id)
                                    }
                                  >
                                    <span className="">
                                      {this.state.showMoreDetailsForProject.findIndex(
                                        o => o === projectsList.id
                                      ) > -1
                                        ? "Less Details"
                                        : "More Details"}
                                    </span>
                                    {this.state.showMoreDetailsForProject.findIndex(
                                      o => o === projectsList.id
                                    ) > -1 ? (
                                      <ExpandLessIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-2 col-6 mt-2">
                            <div className="action_icons">
                              {projectDocAccessView.includes(window.$role) && (
                                <a
                                  className="ProjectUpdate Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="View Ladder Document"
                                  onClick={() => {
                                    this.handleDocumentModal(projectsList.id, "view");
                                  }}
                                  data={projectsList.id}
                                >
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="text-white"
                                  />
                                </a>
                              )}
                              {projectDocAccessUpload.includes(window.$role) && (
                                <a
                                  className="ProjectUpdate Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Upload Ladder Document"
                                  onClick={() => {
                                    this.handleDocumentModal(projectsList.id, "upload");
                                  }}
                                  data={projectsList.id}
                                >
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    className="text-white"
                                  />
                                </a>
                              )}
                              {projectsList?.fact_sheet && (
                                <>
                                  <FactSheetPreview
                                    id={projectsList.id}
                                    icon={true}
                                  />
                                  <DownloadFactsheet id={projectsList.id} />
                                </>
                              )}
                              {projectEdit.includes(window.$role) && (
                                <a
                                  className="ProjectUpdate Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Edit Project"
                                  onClick={() => {
                                    this.handleEditProject(projectsList.id);
                                  }}
                                  data={projectsList.id}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="text-white"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="action_box d-md-none pr-2">
                            {projectEdit.includes(window.$role) && (
                              <a
                                className="ProjectUpdate Action"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Edit Project"
                                onClick={() => {
                                  this.handleEditProject(projectsList.id);
                                }}
                                data={projectsList.id}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="text-white"
                                />
                              </a>
                            )}
                            {projectsList?.fact_sheet && (
                              <>
                                <FactSheetPreview
                                  id={projectsList.id}
                                  icon={true}
                                />
                                <DownloadFactsheet id={projectsList.id} />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </ListingView>
                  ))}
                </div>
                <div className="row justify-content-end">
                  {this.state.meta["count"] > 8 ? (
                    <div className="paginationBlock">
                      <Pagination
                        hideDisabled
                        activePage={this.state.activePage}
                        itemsCountPerPage={size}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        onChange={this.handleChangePage}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="main_area_display shadow card p-3">
              <h5>No Projects Found !!!</h5>
            </div>
          )}
        </div>
        <Footer expand={this.props.expand} />
        {fullDetail.meta && (
          <ProjectModel
            show={this.state.updateModalShow}
            formData={fullDetail}
            fromType="updateProject"
            changeModal={this.updateHandleModal}
            getList={this.genericGetListData}
          />
        )}
        <ProjectModel
          show={this.state.addModalShow}
          getList={this.genericGetListData}
          fromType="addProject"
          changeModal={this.handleAddModal}
        />
        {fullDetail.meta && (
          <DetailProjectModal
            show={this.state.modalShow}
            changeModal={this.handleModal}
            leadDetailProp={fullDetail}
          />
        )}
        {showDocumentModal && (
          <ProjectDocModal
          show={showDocumentModal}
          project_id={this.state.selectedProject}
          documentModalType= {this.state.documentModalType}
          handleDocumentClose={this.handleDocumentClose}
          />
        )}
      </Frag>
    );
  }
}

export default withRouter(projectDetail);
