import React, {Component} from 'react';
import {Row, Col, Card, Table} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import {getReferalUser} from "../../../dataParser/magnetUser.js";
import {changeDate} from "../../../helper";
import Loader from "../../../component/common/loader/loader";
import Pagination from "react-js-pagination";
import Footer from "../../../component/footer/footer";  

class userFocusProjectTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage : 1,
            sweetShow:false,
            type : "success",   
            title : "",
            showLoader : false,
            meta : props.refDataValue.meta,
            data : props.refDataValue.data["referrals"],
        };
    }

    componentDidUpdate(nextProps) {
     // console.log('componentWillReceiveProps xxxxxxxxxx', nextProps);
        if (this.props !== nextProps) {
        // this.setState({nextProps});
        this.setState({
            meta:nextProps.refDataValue.meta,
            data:nextProps.refDataValue.data["referrals"],
            activePage : 1,
         });
        // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx',nextProps);
        }
    }

    genericGetListData = async (...params) =>{
        this.setState({showLoader : true});
        // console.log("genericGetListData......",params)

        // console.log("this.state.filters",this.state.filters);
        var listData = {};
        listData.size = 20;
        listData.pageId = params[0].pageNumber || params[0].pageId || 1;

        (async () => {
            try {
                let resData = await getReferalUser(listData);
                await this.setState({
                        meta: resData.meta,
                        data : resData.data["referrals"],
                        showLoader : false
                    })
                window.scrollTo(0, 0);
            } catch (e) {   
                //...handle the error...
            }
        })();
    }

    handleChangePage= async (pageNumber)=>{
        // console.log(`active page is ${pageNumber}`);
        // console.log("this.state.filters pagination", this.state.filters);
        this.setState({
            showLoader : true,
            activePage: pageNumber
        })
        let listData = {};
        listData.size = 20;
        listData.pageId = pageNumber;
        
        this.genericGetListData(listData);
    }

    handleSweet=()=>{
        this.setState({sweetShow : !this.state.sweetShow});
    }

    render() {

        const {count, size, status, } = this.state.meta;
        const {data, activePage} = this.state;
        // console.log("this.state",this.state.meta);

        return (
            <Frag>
                <div className="content-wrapper">
                    <Loader show={this.state.showLoader}/>
                    <div className="p-3 leadMainHeader">
                        <div className="headerCount">
                            <span className="totalCount">Total Referred : </span>
                            <span>{status===400 ? "0": count}</span>
                        </div>
                    </div>
                    {status === 200 ?
                        <div className="listContainer">
                            <div className="container-fluid">
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Header>
                                                <Card.Title as="h5">Referred Users</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Table responsive hover>
                                                    <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Name</th>
                                                        <th>Registration Date</th>
                                                    </tr>
                                                    </thead>
                                                        <tbody>
                                                            {data.map((referralsList,index) => 
                                                                <tr key={index}>
                                                                    <th scope="row">{activePage === 1 ? index+1 : (activePage-1)*20 + (index+1)}</th>
                                                                    <td className="text-capitalize">
                                                                        {referralsList.ReferredUser !== null ? referralsList.ReferredUser.name : "-"}
                                                                    </td>
                                                                    <td className="text-capitalize">
                                                                        {changeDate(referralsList.created_at)}
                                                                    </td>
                                                                </tr>
                                                            )}    
                                                        </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className="row justify-content-end">
                                        {count > 20 ? 
                                                <div className="paginationBlock">
                                                    <Pagination
                                                        hideDisabled    
                                                        activePage={this.state.activePage}
                                                        itemsCountPerPage={size}
                                                        totalItemsCount={count}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handleChangePage}
                                                    />
                                                </div>
                                            :
                                            ''
                                        }
                                    </div>
                            </div>    
                        </div>
                        :
                        <div className="main_area_display shadow card p-3">
                            <h5>No User Found !!!</h5>
                        </div>  
                    }   
                </div>            
                <Footer expand ={this.state.sideBar} />
            </Frag>
        );
    }
}

export default userFocusProjectTable;