import React from 'react';

import Grid_ from '@material-ui/core/Grid';

import './Grid.sass'
import clsx from 'clsx';


const defaultState = {
}


const Grid = props => <Grid_ className="Grid" {...props}>{props.children}</Grid_>

export default Grid;
