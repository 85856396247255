import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import ChangesUserPass from "../form/changeUserPass";
import AddTempCPForm from "../form/addTempCPForm";


class CpUserModal extends Component {
    render() {

    	const FROMS_TYPES = {
			"changePass" : ChangesUserPass,
			"addTempCp" : AddTempCPForm,
		}
		 // console.log(this.props.fromType,"check form type");
		const Form_name = FROMS_TYPES[this.props.fromType];

        return (
            <Frag>
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size={Form_name === ChangesUserPass ? "md" : "lg"}
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			          {Form_name === ChangesUserPass ? "Change Password" : "Add Magnet" }
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			        <Form_name changeModal={this.props.changeModal}/>
			      </Modal.Body>
			      
			    </Modal>
            </Frag>
        );
    };
};    

export default CpUserModal;