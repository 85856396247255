import React, { Component } from "react";

import LeadReoprt from "../../component/leadReport/leadReport";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";
import { Helmet } from "react-helmet";
import { colorConfig } from "../../config/colorConstant";
import { APP_BASE_URL } from "../../config/serverKey.js";

class UserListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: "",
      showLoader: false
    };
  }

  render() {
    const leadInfo = this.props.match.params.reportType;
    const LeadValue = {
      "current-status": "all_leads",
      booking: "new_leads",
      activity: "assign_leads",
      update: "open_leads"
    };

    return (
      <Frag>
        <Helmet>
          <title>Homesfy Platform</title>
          <meta
            name="description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta name="theme-color" content={colorConfig[LeadValue[leadInfo]]} />
          <meta property="og:url" content={APP_BASE_URL} />
        </Helmet>
        <Loader show={this.state.showLoader} />
        <LeadReoprt expand={this.props.sideBar} />
      </Frag>
    );
  }
}

export default UserListPage;
