import React, { Component } from "react";

import List from "../../component/Leads/leadList.js";
import { getListData } from "../../dataParser/getListData";
import { Helmet } from "react-helmet";
import { colorConfig } from "../../config/colorConstant";
import { APP_BASE_URL } from "../../config/serverKey.js";
import Loader from "../../component/common/loader/loader";

class list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSideBar: false,
      listDetail: {},
      leadInfo: "",
      role: window.$user.role,
      phone: ""
    };
  }

  async componentDidMount() {
    // console.log("wwwwwwwwwwwwwwwwwwwwwwwwwww");
    var leadDataType = {};
    let leadName = this.props.match.params.leadType;
    let phone = this.props.match.params.contactNumber;
    this.setState({
      showLoader: true,
      leadInfo: leadName,
      role: window.$user.role,
      phone: phone
    });
    // let leadType  = queryString.parse(this.props.location.search);
    // leadType = leadType.q;
    // this.setState({listTitle : leadType});

    var listData = {};
    if (
      leadName === "sourcing" ||
      leadName === "disinterested" ||
      leadName === "reactivation" ||
      leadName === "disqualify" ||
      leadName === "cross_sale_in" ||
      leadName === "cross_sale_out"
    ) {
      // console.log("44444444444444444444444", leadName);
      listData.tab = leadName;
    }
    listData.size = 24;
    listData.pageId = 1;
    // console.log(leadName)
    if ((this.state.role === 'salestl' || this.state.role === 'seniorsalestl' || this.state.role === 'sourcings2' || this.state.role === 'magnet s2') && (leadName === "all" || leadName === "open")) listData.assign_to = 'myself';
    leadDataType.name = leadName;
    if(leadName === 'home-loan-leads'){
      leadDataType.name = leadName;
    }
    if (localStorage.getItem("rprtfltr") && phone === "report") {
      let reportFltr = JSON.parse(atob(localStorage.getItem("rprtfltr")));

      if (reportFltr.reportType !== null) {
        delete listData.assign_to;
      }
      if (
        reportFltr.reportType === "activity" ||
        reportFltr.reportType === "followup" ||
        reportFltr.reportType === "project-activity-rm" ||
        reportFltr.reportType === "current-status" ||
        reportFltr.reportType === "presale-current-status" ||
        reportFltr.reportType === "update" ||
        reportFltr.reportType === "presale-activity" ||
        reportFltr.reportType === "project-rm" ||
        reportFltr.reportType === "booking" ||
        reportFltr.reportType === "presale-booking" ||
        reportFltr.reportType === "magnet-current-status" ||
        reportFltr.reportType === "audit" ||
        reportFltr.reportType === "magnet-activity"
      ) {
        Object.assign(listData, reportFltr.filter);
      }
    } else if (
      this.props.location.state?.fltr !== undefined &&
      this.props.location.state?.fltr !== null
    ) {
      Object.assign(listData, this.props.location.state?.fltr.filter);
    }

    if (Number.isInteger(Number(phone))) {
      listData.client_number = phone;
    }

    leadDataType.data = listData;
    // console.log("ocean");
    (async () => {
      try {
        // console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ");
        let resData = await getListData(leadDataType);
        // console.log("resData >>>>",resData);
        if (resData.meta.status === 200) {
          if (phone !== "report") {
            delete leadDataType.data.reportFltr;
            localStorage.removeItem("rprtfltr");
          }
        }
        await this.setState({
          listDetail: resData,
          showLoader: false
        });
        window.scrollTo(0, 0);
      } catch (e) {
        //...handle the error...
      }
    })();
  }

  // async componentDidUpdate(prevProps) {
  //   // console.log("prevProps", prevProps.location.pathname);
  //   // console.log("prevProps", this.props.location.pathname);
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     // console.log("wwwwwwwwwwwwwwwwwwwwwwwwwww");
  //     var leadDataType = {};

  //     let leadName = this.props.match.params.leadType;
  //     // console.log("44444444444444444444444", leadName);

  //     this.setState({
  //       showLoader: true,
  //       leadInfo: leadName
  //     });
  //     // let leadType  = queryString.parse(this.props.location.search);
  //     // leadType = leadType.q;
  //     // this.setState({listTitle : leadType});

  //     var listData = {};
  //     if (
  //       leadName === "sourcing" ||
  //       leadName === "disinterested" ||
  //       leadName === "reactivation" ||
  //       leadName === "disqualify"
  //     ) {
  //       console.log("44444444444444444444444", leadName);
  //       listData.tab = leadName;
  //     }
  //     listData.size = 8;
  //     listData.pageId = 1;

  //     leadDataType.name = leadName;
  //     leadDataType.data = listData;

  //     (async () => {
  //       try {
  //         console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ");
  //         let resData = await getListData(leadDataType);
  //         await this.setState({
  //           listDetail: resData,
  //           showLoader: false
  //         });
  //         window.scrollTo(0, 0);
  //       } catch (e) {
  //         //...handle the error...
  //       }
  //     })();
  //   }
  // }

  render() {
    // console.log("sidebarrrrrr");
    const { leadInfo } = this.state;
    const LeadValue = {
      all: "all_leads",
      new: "new_leads",
      assigned: "assign_leads",
      booked: "booked_leads",
      closed: "closed_leads",
      cancel: "cancel_leads",
      open: "open_leads",
      home_loan_leads:"home_loan_leads"
    };
    return (
      <div>
        <Helmet>
          <title>Homesfy Platform</title>
          <meta
            name="description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta name="theme-color" content={colorConfig[LeadValue[leadInfo]]} />
          <meta property="og:url" content={APP_BASE_URL} />
          <meta property="og:title" content="Magnet CRM" />
          <meta
            property="og:description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta property="og:site_name" content="MagnetRefer" />
          <meta
            property="og:image"
            content="https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/MobReferBannerMagnet.svg"
          />
          <meta http-equiv="cache-control" content="max-age=0" />
          <meta
            http-equiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </Helmet>
        <Loader show={this.state.showLoader} />
        {this.state.listDetail.meta && (
          <List
            expand={this.props.sideBar}
            listValue={this.state.listDetail}
            leadInfo={this.state.leadInfo}
            phone={this.state.phone}
          />
        )}
      </div>
    );
  }
}

export default list;
