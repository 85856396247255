import React, { Component } from 'react';
import {userWiseDocDataApi,showDocDataApi} from "../../dataParser/hrDocs";
import Footer from "../../component/footer/footer";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
// import Pagination from "react-js-pagination";
import Loader from "../../component/common/loader/loader";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import pdf2base64 from 'pdf-to-base64';
import "./hrDocs.css";
import HrDocPreviewer from './hrDocPreviewer.js'
class ViewHrDocs extends Component {
    constructor(props) {
      super(props);
        this.state = {
            type: "success",
            title: "",
            sweetShow: false,
            showLoader: false,
            showModal: false,
            userDocData : {},
            staticView:false
       };

    }

    async componentDidMount() {
        this.update();
        let filter = {};
        if(window.location.href.includes('hr-knowledge-piece')){
            filter['document_type'] = 'hrknowledgepiece';
        }else if(!window.location.href.includes('hr-knowledge-piece')){
            filter['document_type'] = 'hrdocument';
        }
        let userDocRes = await userWiseDocDataApi(filter);
        if(userDocRes.meta.status === 200){
            if(!window.location.href.includes('hr-knowledge-piece')){
                userDocRes.data.myDocument = [{file_name:'Employee Handbook',file_path:"https://heyzine.com/flip-book/c31e14adec.html",fileType:"static"},...userDocRes.data.myDocument];
                userDocRes.data.myTeamDocument = [{file_name:'Employee Handbook',file_path:"https://heyzine.com/flip-book/c31e14adec.html",fileType:"static"},...userDocRes.data.myTeamDocument];
            }
            this.setState({userDocData : userDocRes.data})
            window.scrollTo(0, 0);
        }else{
            this.setState({userDocData : []})
        }
    }

    update = () => {
        this.setState({
          screenWidth: window.innerWidth
        });
    };

    detailView (id){
        // console.log("id==========>",id)
        if(this.state.listDetail == id){
            this.setState({listDetail : null})
        }else{
            this.setState({listDetail : id})
        }
    }

    viewDocData = async(id)=>{
        let resData = await showDocDataApi(id);
        if(resData.meta.status === 201){
            if (resData.data.fileUrl !== "https://s3.amazonaws.com/") {
                pdf2base64(resData.data.fileUrl).then((response) => {
                    this.setState({'View_file_path':response,showModal:true,staticView:false});
                }).catch((error) => {
                    console.error(error); //Exepection error....
                })
            }
        }
    }
    staticViewDoc = async(filePth)=>{
        console.log("filePth >>>>>",filePth);
        await this.setState({showModal:true,staticView:true,'View_file_path':filePth});
    }
    handleModalClose = () => {
        this.setState({ showModal: false,staticView:false });
    }
    
    render()  {
        const {sweetShow, type, title, userDocData} = this.state;
        const {myDocument,myTeamDocument} = userDocData;       

        return (
            <div>
                <div className="content-wrapper">
                    <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
                    <Loader show={this.state.showLoader} />
                    <div className="p-3 leadMainHeader">
                        <div className="headerCount">
                            <span className="hrHeader">{window.location.href.includes('hr-knowledge-piece') ? 'HR Knowledge Piece':'HR Documents' }: </span>
                            {/*<span className="totalCount">{meta.status === 200 ? count : 0}</span>*/}
                        </div>
                    </div> 
                    <div>   
                        <div className="listContainer">
                            <div className="container-fluid">
                                <div className="row">
                                   <div className="MainContainer">
                                        <div className="container-fluid holderContainer">
                                            <div className="row mb-4">
                                                <div className="col-md-12 quesAns" onClick={() => this.detailView(5)}>
                                                    <span className="ques">
                                                        My Documents
                                                    </span>
                                                    <span className="showDocIcon">
                                                        {this.state.listDetail === 5 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                                    </span>
                                                </div>
                                                <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 5 ? "show" : "hide")}>
                                                   {myDocument?.length ?
                                                        <>
                                                           <div className="filename mb-3">File Name</div>
                                                           {myDocument && myDocument.map((cur,index)=>
                                                               <div className="row mb-4 docsRow pb-3" key={index}>
                                                                   <div className="col-md-6 col-6 text-capitalize">{cur.file_name}</div>
                                                                   <div className="col-md-6 col-6 text-center">
                                                                       {cur.hasOwnProperty('fileType') ? <button className="btn btn-secondary" onClick={()=>{this.staticViewDoc(cur.file_path)}}>View</button> : <button className="btn btn-secondary" onClick={()=>{this.viewDocData(cur.id)}}>View</button>}
                                                                   </div>
                                                               </div>
                                                            )}
                                                        </>   
                                                       :
                                                       <div className="noDoc pt-4 pb-4 text-center">No Documents Available !!!</div>
                                                    }   
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-12 quesAns" onClick={() => this.detailView(2)}>
                                                    <span className="ques">
                                                        My Team Documents
                                                    </span>
                                                    <span className="showDocIcon">
                                                        {this.state.listDetail === 2 ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                                    </span>
                                                </div>
                                                <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 2 ? "show" : "hide")}>
                                                   {myTeamDocument?.length ?
                                                        <>
                                                           <div className="filename mb-3">File Name</div>
                                                           {myTeamDocument && myTeamDocument.map((cur,index)=>
                                                               <div className="row mb-4 docsRow pb-3">
                                                                   <div className="col-md-6 col-6 text-capitalize">{cur.file_name}</div>
                                                                   <div className="col-md-6 col-6 text-center">
                                                                   {cur.hasOwnProperty('fileType') ? <button className="btn btn-secondary" onClick={()=>{this.staticViewDoc(cur.file_path)}}>View</button> : <button className="btn btn-secondary" onClick={()=>{this.viewDocData(cur.id)}}>View</button>}
                                                                   </div>
                                                               </div>
                                                            )}
                                                        </>   
                                                       :
                                                       <div className="noDoc pt-4 pb-4 text-center">No Documents Available !!!</div>
                                                    }   
                                                </div>
                                            </div>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer expand ={this.state.sideBar} />
                <HrDocPreviewer 
                    showModal={this.state.showModal}
                    handleModalClose={this.handleModalClose} 
                    ViewFilePath={this.state.View_file_path} staticView={this.state.staticView}
                />
            </div>
        );

    }
}

export default ViewHrDocs;