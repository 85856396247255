import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { CssBaseline } from '@material-ui/core';
import { AMAZON_URL } from '../../config/serverKey';

export const ThankYou = () => {
    return (
        <CssBaseline>
            <div className="bg-white" style={{overflowX:"hidden",height:"100vh"}}>
                <div className="row pl-md-5 pt-4 pb-2">
                    <div className="col-md-4 p-5">
                        <img src={`${AMAZON_URL}/logo.svg`} alt="small_site_logo" className="img-fluid"/>
                    </div>
                </div>
                <div className="row px-3 justify-content-center text-center">
                    <div className="col-md-12">
                        <h1>Thank You !</h1>
                        <FontAwesomeIcon icon={faCheck} style={{
                            marginBottom: "6px",
                            width: "100%",
                            fontSize: "80px",
                            color: "#458700",
                        }}/>
                        <div className="h6 mb-3">Your Registration is Completed.</div>
                        <div className="h6 mb-3">We are happy to have you as a member and hope you soon experience the benefits of being a Magnet.</div>
                        <div className="h6 mb-3">Your remaining on-boarding process will be completed within 36 hours.</div>
                        <div className="h6">Thank You!</div>
                    </div>
                </div>
            </div>
        </CssBaseline>
    )
}
