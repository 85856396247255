import React from 'react'
import Button from '@material-ui/core/Button'
import "./TryNewCRM.css"
import { NEW_CRM_URL } from '../../../../config/serverKey'

const TryNewCRM = ({text}) => {
  const handleClick = () =>{
    const login_key = localStorage.getItem('homesfy_lg');
    let URL = `${NEW_CRM_URL}?homesfy_lg=${login_key}`;
    localStorage.removeItem('homesfy_lg');
    window.location.replace(URL);
  }
  return (
    <Button variant="outlined" onClick={handleClick} color="primary" style={{textTransform: "capitalize"}} className="rectangle pulse">
      {text}
    </Button>
  )
}

export default TryNewCRM