import React, { Component } from "react";

import CpUserList from "../../component/CpUserList/CpUserList";
import { getUsersDataList } from "../../dataParser/getListUserData";
import { REVERT_ROLES } from "../../config/constant/index.js";
import Frag from "../../utils/Frag/Frag";
import Loader from "../../component/common/loader/loader";
// import {cleanStorage} from "../../helper";

class CpUserListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSideBar: false,
      cpUserDetail: "",
      showLoader: false,
      magnetType: ""
    };
  }

  async componentDidMount() {
    let type = this.props.match.params?.magnetType;
    this.setState({ showLoader: true, magnetType: type });

    let userData = {};
    let role = [];
    // if (window.$role === "admin" || window.$role === "magnetadmin" || window.$role === "magnetpnl" || window.$role === 'magnetmis') {
    //   role = [
    //     REVERT_ROLES.MAGNET,
    //     REVERT_ROLES.MAGNET_TL,
    //     REVERT_ROLES.MAGNET_RM
    //   ];
    // } else if (window.$role === "magnettl") {
      //role = [REVERT_ROLES.MAGNET, REVERT_ROLES.MAGNET_RM];
    // } else if (window.$role === "magnetrm") {
       role = [REVERT_ROLES.MAGNET];
    // } else {
    //   role = [REVERT_ROLES.MAGNET, REVERT_ROLES.MAGNET_TL];
    // }
    userData.size = 8;
    userData.pageId = 1;
    userData.role_ids = role;
    userData.type = 'magnet';

    // userData = JSON.stringify(userData);

    // console.log(userData, "gghhhhhhhhh");
    let resData = {};
    resData = await getUsersDataList(userData);
    // console.log("========================================",resData);
    // setTimeout(()=>{
    // 	if(resData.meta.status === 200){

    //      this.setState({
    //      	cpUserDetail : resData,
    //      	showLoader : false,
    //      }) ;
    // 		window.scrollTo(0, 0);
    //      this.setState({})

    // 	}else if(resData.meta.status == 401){
    // 		cleanStorage();
    // 		this.props.history.push("/login");
    // 	}else{
    // 		this.setState({
    //      	cpUserDetail: resData,
    //      	showLoader : false
    //      })
    // 	}
    // },500)
    await this.setState({
      cpUserDetail: resData,
      showLoader: false
    });
    window.scrollTo(0, 0);
  }

  render() {
    // console.log("sidebarrrrrr");
    // console.log(this.state.mobileSideBar);

    return (
      <Frag>
        <Loader show={this.state.showLoader} />
        {this.state.cpUserDetail.meta && (
          <CpUserList
            expand={this.props.sideBar}
            userDetail={this.state.cpUserDetail}
            magnetType={this.state.magnetType}
          />
        )}
      </Frag>
    );
  }
}

export default CpUserListPage;
