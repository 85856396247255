import React, { useState } from 'react'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@material-ui/core/Tooltip'
import Frag from '../../../utils/Frag/Frag'
import { getCallData } from '../../../dataParser/getListData'
import PopUpAlert from "../sweetAlert/popUpAlert"
import Button from '@material-ui/core/Button'
import PhoneIcon from '@material-ui/icons/Phone';
import ReactDOM from 'react-dom'

const CallAction = ({ data, roleAccess, type, callingType, stage, device }) => {
    const [state, setState] = useState({
        agent_number: window.$user.phone ? '+91' + window.$user.phone : '+91 9111111111',
        user_id: window.$user.id,
        alertShow: false,
        alertType: "success",
        alertMsg: "",
        role: window.$user.role
    })

    const getNumber = (data) => {
        // let number = (data.Client !== null && data.Client?.country_code !== null && data.Client?.country_code !== '' ? (data.Client?.country_code.includes('+') ? data.Client?.country_code : '+' + data.Client?.country_code) : '+91') + ' ' + (data.Client !== null ? data.Client?.number : data.BulkLead && data.BulkLead.phone)
        let number = (data.phone.includes('+91') ? data.phone.replace("+91","+91 ") : `+91 ${data.phone}`)
        return number
    }

    const handleCall = async (listData, roleAccess, type, callingType, stage) => {
        const { agent_number, user_id, role } = state
        let number = getNumber(listData).split(" ").join("")
        if (roleAccess.includes(role)) {
            setState({ ...state, showLoader: true })

            let data = {}
            if (user_id) {
                data["user_id"] = user_id
            }
            data["object_id"] = listData.id
            data["agent_number"] = agent_number
            data["customer_number"] = number
            data["type"] = type
            data["calling_type"] = callingType;
            data['stage_id'] = stage;

            let resData = await getCallData(data)
            if (resData.meta.status === 201) {
                let message = resData.data?.message
                setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    alertType: message?.includes('Failed') ? 'warning' : "success",
                    alertMsg: resData.data?.message || "Call placed successfully!",
                });
            } else {
                setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    alertType: "error",
                    alertMsg: resData.meta?.message || "Sorry something went wrong!",
                });
            }
        }
        else {
            setState({ ...state, tel: "tel:" + number })
        }
    }

    const handleClose = () => {
        setState({ ...state, alertShow: false })
    }

    const { alertType, alertMsg, alertShow, tel } = state
    return (
        <Frag>
            { device == "desktop" &&
                <Tooltip title={getNumber(data)}>
                    <a
                        href={tel}
                        className="callBG Action d-none d-md-block"
                        target="_blank"
                        onClick={() => handleCall(data, roleAccess, type, callingType, stage)}>
                        <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                    </a>
                </Tooltip>
            }
            { device == "mobile" &&
            <div className="row">
                <div className="col-6 mb-1">
                    <a href={tel}>
                        <Button variant="contained" color="primary" className="w-100" onClick={() => handleCall(data, roleAccess, type, callingType, stage)}>
                            <PhoneIcon /><span className="text-capitalize">W-Call</span>
                        </Button>
                    </a>
                </div>
                <div className="col-6 mb-1">
                    <a href={"tel:" + getNumber(data)}>
                        <Button variant="contained" color="primary" className="w-100">
                            <PhoneIcon /><span className="text-capitalize">Call</span>
                        </Button>
                    </a>
                </div>
            </div>
            }
            <PopUpPortal type={alertType} msg={alertMsg} open={alertShow} handleClose={handleClose} hideTime={6000} />
        </Frag>
    )
}
const PopUpPortal = (props) =>{
    if(!props.open){
        return <></>
    }
    return ReactDOM.createPortal(
        <PopUpAlert {...props}/>
        , document.getElementsByTagName('body')[0]
    )
}
export default CallAction
