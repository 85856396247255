import React, { Component } from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import CircularProgress from "@material-ui/core/CircularProgress";
import { autoComplete, interestedMagnet , getAutoCompleteParentRegion } from "../../dataParser/commomDataApi";
import { REVERT_ROLES } from "../../config/constant/index.js";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { cleanStorage } from "../../helper";


class CpUserFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      role_ids: '',
      createdAt_from: null,
      createdAt_to: null,
      updatedAt_from: null,
      updatedAt_to: null,
      is_approved: null,
      rm_approved: '',
      enroll_rm_status: '',
      admin_approved: null,
      is_active: null,
      magnet: [],
      magnetUser: [],
      parentRegion:[],
      parent_region_id:'',
      assign_rm_id: '',
      user_id: '',
      member_id: '',
      mobile_number: '',
      interestedMg: []
      , isProspectLoading: false
      , magnetId: ""
    };
  }

  componentDidMount() {
    this.setState({ urlPath: this.props.match.path })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value });
  };

  handleAutoChange = async (e) => {
    this.setState({ isMagnetLoading: true });
    let roles = [];
    roles.push(REVERT_ROLES.MAGNET_TL,REVERT_ROLES.MAGNET_RM);
    let data = {
      q: e.target.value,
      roles: roles
    };
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnet: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnet: [] })
    }
  }
  handleAutoParentRegionChange = async e => {
    this.setState({ isLoading: true });
    let value = e.target.value;
    let getData = await getAutoCompleteParentRegion(value);
    
    if (getData.meta.status === 200) {
      this.setState({ isLoading: false, parentRegion: getData.data })
    } else if (getData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isLoading: false, parentRegion: [] })
    }
  };

  handleAutoMagnetChange = async (e) => {
    this.setState({ isMagnetLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET],
      is_active_inactive : true
    };
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnetUser: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnetUser: [] })
    }

  }

  handleInterestedMgChange = async (e) => {
    this.setState({ isProspectLoading: true });
    let data = {
      q: e.target.value,
    };
    let resData = await interestedMagnet(data);

    if (resData.meta.status === 200) {

      this.setState({ isProspectLoading: false });
      this.setState({ interestedMg: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isProspectLoading: false });
      this.setState({ interestedMg: [] })
    }

  }

  applyFilter = () => {

    let filterData = {};

    if (this.state.role_ids !== '' && this.state.role_ids !== 'all') {

      filterData["role_ids"] = parseInt(this.state.role_ids);
    }

    if (this.state.is_approved !== null) {

      filterData["is_approved"] = parseInt(this.state.is_approved);
    }

    if (this.state.is_active !== null) {

      filterData["is_active"] = parseInt(this.state.is_active);
    }

    if (this.state.user_id !== '') {

      filterData["user_id"] = parseInt(this.state.user_id);
    }

    if (this.state.member_id !== '') {

      filterData["ids"] = parseInt(this.state.member_id);
    }

    if (this.state.mobile_number !== '') {

      filterData["phone"] = parseInt(this.state.mobile_number);
    }

    if (this.state.parent_region_id !== ''){
      filterData["parent_region_id"] = parseInt(this.state.parent_region_id);
    }

    if (this.state.assign_rm_id !== '') {
      this.props.location.pathname === "/magnet/magnet-users" ? filterData["service_rm_ids"] = this.state.assign_rm_id : filterData["enroll_rm_id"] = this.state.assign_rm_id
    }
    if (this.state.admin_approved !== null) {

      filterData["admin_approved"] = parseInt(this.state.admin_approved);
    }

    if (this.state.rm_approved !== '') {

      filterData["rm_approved"] = parseInt(this.state.rm_approved);
    }

    if (this.state.enroll_rm_status !== '') {

      filterData["enroll_rm_status"] = parseInt(this.state.enroll_rm_status);
    }

    if (this.state.updatedAt_from) {

      filterData["updated_at_from"] = this.state.updatedAt_from.split("/").join("-");
    }

    if (this.state.updatedAt_to) {

      filterData["updated_at_to"] = this.state.updatedAt_to.split("/").join("-");
    }

    if (this.state.createdAt_from) {

      filterData["created_at_from"] = this.state.createdAt_from.split("/").join("-");
    }
    if (this.state.createdAt_to) {

      filterData["created_at_to"] = this.state.createdAt_to.split("/").join("-");
    }
    if(this.state.magnetId) filterData["magnetId"] = this.state.magnetId;
    this.props.changeFilter();

    this.props.filterData(filterData);

  }
  handleDateChange = (key, date) => {
    this.setState({ [key]: date });
  }

  resetFilter = () => {

    this.setState({
      autoKey: !this.state.autoKey,
      role_ids: 'all',
      is_approved: null,
      is_active: null,
      admin_approved: null,
      rm_approved: "",
      enroll_rm_status: "",
      assign_rm_id: '',
      user_id: '',
      member_id: '',
      mobile_number: '',
      createdAt_from: null,
      createdAt_to: null,
      updatedAt_from: null,
      updatedAt_to: null,
      magnetId: "",
      parent_region_id:'',
    })
  }

  render() {

    const { autoKey, magnet, isLoading, magnetUser,parentRegion, parent_region_id,enroll_rm_status, rm_approved, createdAt_from, createdAt_to, updatedAt_from, updatedAt_to, interestedMg, isProspectLoading, magnetId } = this.state;

    return (

      <Frag>
        <div className="container filter_lead filterMenuCard pt-3 p-0" ref={this.props.fltr}>
          {this.props.magnetEnroll ?
            <div className="row filterRow mt3">
              <div className="col-md-6 col-6 mb-3">
                <FormControl>
                  <Autocomplete
                    multiple
                    key={autoKey}
                    id="magnet-demo"
                    getOptionSelected={(magnet, value) => magnet.name === value.name}
                    getOptionLabel={magnet => magnet.name + " (" + magnet.phone + ")"}
                    options={magnet}
                    renderTags={(value, getTagProps) =>
                      value.map((magnet, index) => (
                        <Chip label={magnet.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(_event, magnet) => {
                      var projectId = magnet.map((ops) => ops.id);
                      this.setState({
                        assign_rm_id: projectId,
                      })

                      if (magnet === null) {
                        this.setState({ magnet: [] })
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Magnet RM/TL"
                        fullWidth
                        onChange={this.handleAutoChange}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="col-md-6 col-6">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">RM Approved Status</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={rm_approved}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'rm_approved',
                      id: 'rm_approved',
                    }}
                  >
                    <MenuItem value="1">Approve</MenuItem>
                    <MenuItem value="0">Disapprove</MenuItem>
                    <MenuItem value="2">Not Interested</MenuItem>
                    <MenuItem value="3">Pending</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-12">
                <div className="col-md-12 p-0">
                  <FormLabel component="legend">Admin Approved Status</FormLabel>
                </div>
                <RadioGroup row aria-label="" key={autoKey} name="admin_approved" onChange={this.handleChange}>
                  <div className="col-4">
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Approved" />
                  </div>
                  <div className="col-4">
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Disapproved" />
                  </div>
                  <div className="col-4">
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Pending" />
                  </div>
                </RadioGroup>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <TextField
                    value={this.state.mobile_number}
                    id="mobile_number"
                    name="mobile_number"
                    label="Mobile Number"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-6 mb-3">
                <FormControl>
                  <Autocomplete
                    key={autoKey}
                    id="autoMagnet-demo"
                    getOptionSelected={(interestedMg, value) => interestedMg.name === value.name}
                    getOptionLabel={interestedMg => interestedMg.name}
                    options={interestedMg}
                    loading={isLoading}
                    onChange={(_event, interestedMg) => {
                      if (interestedMg === null) {
                        this.setState({ interestedMg: [], member_id: '' })
                      } else {
                        this.setState({
                          member_id: interestedMg.id,
                        })
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Magnet Name"
                        fullWidth
                        onChange={this.handleInterestedMgChange}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <BusinessRoundedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {isProspectLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="col-md-6 col-6">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label"> Assign Enroll RM</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={enroll_rm_status}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'enroll_rm_status',
                      id: 'enroll_rm_status',
                    }}
                  >
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            :
            <div className="row filterRow mt-3">
              {this.props.location.pathname === "/magnet/magnet-users" &&
              <div className="col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <TextField
                    value={this.state.magnetId}
                    id="magnetId"
                    name="magnetId"
                    label="Magnet Id"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              }
           { /*  <div className="col-md-6 col-6 mb-3">
                <FormControl>
                  <InputLabel id="role-magnet" htmlFor="role-magnet">Select Users</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={this.state.role_ids}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'role_ids',
                      id: 'role-native',
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="22">Magnet</MenuItem>
                    <MenuItem value="20">Magnet TL</MenuItem>
                    <MenuItem value="21">Magnet RM</MenuItem>
                  </Select>
                </FormControl>
                  </div>*/}
              <div className="col-md-6 col-6 mb-3">
                <FormControl>
                  <Autocomplete
                    multiple
                    key={autoKey}
                    id="magnet-demo"
                    getOptionSelected={(magnet, value) => magnet.name === value.name}
                    getOptionLabel={magnet => magnet.name + " (" + magnet.phone + ")"}
                    options={magnet}
                    renderTags={(value, getTagProps) =>
                      value.map((magnet, index) => (
                        <Chip label={magnet.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(_event, magnet) => {
                      console.log("magnet >>>>>", magnet);
                      if (magnet === null) {
                        this.setState({ magnet: [] })
                      } else {
                        var magnetRmId = magnet.map((ops) => ops.id);
                        this.setState({
                          assign_rm_id: magnetRmId,
                        })
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Magnet RM"
                        fullWidth
                        onChange={this.handleAutoChange}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <TextField
                    value={this.state.mobile_number}
                    id="mobile_number"
                    name="mobile_number"
                    label="Mobile Number"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-6 mb-3">
                <FormControl>
                  <Autocomplete
                    key={autoKey}
                    id="autoMagnet-demo"
                    getOptionSelected={(magnetUser, value) => magnetUser.name === value.name}
                    getOptionLabel={magnetUser => magnetUser.name}
                    options={magnetUser}
                    loading={isLoading}
                    onChange={(_event, magnetUser) => {
                      if (magnetUser === null) {
                        this.setState({ magnetUser: [], member_id: '' })
                      } else {
                        this.setState({
                          member_id: magnetUser.id,
                        })
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Magnet Name"
                        fullWidth
                        onChange={this.handleAutoMagnetChange}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <BusinessRoundedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="col-md-6 col-6 mb-3">
                <FormControl>
                  <Autocomplete
                    key={autoKey}
                    id="autoMagnet-demo"
                    getOptionSelected={(parentRegion, value) => parentRegion.name === value.name}
                    getOptionLabel={parentRegion => parentRegion.name}
                    options={parentRegion}
                    loading={isLoading}
                    onChange={(_event, parentRegion) => {
                      if (parentRegion === null) {
                        this.setState({ parentRegion: [] , parent_region_id: ''})
                      } else {
                        this.setState({
                          parent_region_id : parentRegion.id,
                        });
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Parent region"
                        fullWidth
                        onChange={this.handleAutoParentRegionChange}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="col-md-12">
                <div className="col-md-12 p-0">
                  <FormLabel component="legend">Active Status</FormLabel>
                </div>
                <RadioGroup row aria-label="" key={autoKey} name="is_active" onChange={this.handleChange}>
                  <div className="col-6">
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Active" />
                  </div>
                  <div className="col-6">
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="InActive" />
                  </div>
                </RadioGroup>
              </div>
              {/* <div className="col-md-12">
                                <div className="col-md-12 p-0">
                                    <FormLabel component="legend">Approved Status</FormLabel>
                                </div>
                                <RadioGroup row aria-label="" key={autoKey} name="is_approved" onChange={this.handleChange}>
                                    <div className="col-6">
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Done" />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Pending" />
                                    </div>
                                </RadioGroup>
                            </div> */}
            </div>
          }
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Created Date</div>
            <div className="col-lg-6 col-sm-6 col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="created_form"
                  variant="inline"
                  label="From"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="updatedAt_from"
                  value={createdAt_from}
                  onChange={(key, date) => this.handleDateChange('createdAt_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="createdAt_to"
                  variant="inline"
                  label="to"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="createdAt_to"
                  value={createdAt_to}
                  onChange={(key, date) => this.handleDateChange('createdAt_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row filterRow mb-2">
            <div className="col-md-12 text-center">Updated Date</div>
            <div className="col-lg-6 col-sm-6 col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="updated_form"
                  variant="inline"
                  label="From"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="updatedAt_from"
                  value={updatedAt_from}
                  onChange={(key, date) => this.handleDateChange('updatedAt_from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  margin="normal"
                  id="updatedAt_to"
                  variant="inline"
                  label="to"
                  format="yyyy/MM/dd"
                  maxDate={new Date()}
                  name="updatedAt_to"
                  value={updatedAt_to}
                  onChange={(key, date) => this.handleDateChange('updatedAt_to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        <div className="col-md-12 bgCommon">
          <div className="row mt-3">
            <div className="col-sm-6 text-right col-6 mt-3">
              <div className="form-group">
                <button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
              </div>
            </div>
            <div className="col-sm-6 col-6 mt-3">
              <div className="form-group">
                <button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
              </div>
            </div>
          </div>
        </div>
      </Frag>
    );
  };
};

export default withRouter(CpUserFilter)
