import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import CarftTab from "../../component/common/tabs/craftTab.js"
import MagnetTab from "../../component/common/tabs/magnetTab.js"
import "./dashboard_body.css";
import "../../assets/css/main.css";
import {getOpenLeadActivityData,getLeadRatioData,getOpenLeadData,
getDigitalSpendData,
getFutureActivityData
} from "../../dataParser/getDashboardData";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import {getListCount} from "../../dataParser/getListData";
//import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {roleWiseAutoComplete} from "../../dataParser/commomDataApi";
import {GROUPS} from "../../config/constant/index.js";
import Button from '@material-ui/core/Button';
import BulkLeadModal from "../../component/common/modal/BulkModal.js";
import AddLeadModal from "../../component/common/modal/addLeadModal.js";
// import TCModel from "../../component/common/modal/termsConditionModel.js";
import {Card} from 'react-bootstrap';
import Frag from "../../utils/Frag/Frag.js";
import SpendToolTip from "./spendToolTip";
import {changeDate, cleanStorage} from "../../helper";
import moment from "moment";
import LeadProgress from "./LeadProgress.js";
import { DigitalForm } from "../common/form/digitalForm.js";
import {LeaderBoard} from "./LeaderBoard.js";

class DashboardBody extends Component {

	constructor(props) {
    	super(props);
		this.state = {
			showLoader : false,
			// tcModalShow :false,
			showLeadActivtyLoader : false,
			networkError : false,
			addModalShow : false,
			addLeadShow : false,
			isLoading : false,
			homeData : {},
			OpenLeadData : [],
			spendData: [],
			ratioData : [],
			magnet : [],
			tab : 0,
			meetingData: [],
			digiInterestShow: false,
			bookingdate : []
		};
	}

	async componentDidMount () {
		this.setState({
			showLeadActivtyLoader : true,
			showLoader : true,
			group: window.$user?.group.toLowerCase(),
			role: window.$role,
		})
		let id = window.$user?.id;
		this.getApi(id);
    }

	/* handleTcModal=() => {
		this.setState({tcModalShow : !this.state.tcModalShow});
	  }; */

    handleRoleWise = async(roldId) => {
		this.setState({
			showLeadActivtyLoader : true,
			showLoader : true,
		})
		let id = roldId;
        this.getApi(id);
    }
	
    handleChange = (event, newValue) => {
	    this.setState({tab : newValue});
  	};

  	handleAutoChange = async (e) =>{
        this.setState({isLoading:true});
        let data = {};

        data.q = e.target.value;
        let resData = await roleWiseAutoComplete(data);

        if(resData.meta.status === 200){

            this.setState({isLoading:false});
            this.setState({magnet : resData.data})
        }else if(resData.meta.status === 401){

            cleanStorage();
            this.props.history.push("/login");

        }else{

            this.setState({isLoading:false});
            this.setState({magnet : []})
        }

    }

    callChildFunction = (userId) => {
       this.child.handleActionParent(userId);  ///calling a child function here
  	}

  	handleModal=(value)=>{
	    this.setState({addModalShow : !this.state.addModalShow});
	}
	handleLeadModal=(value)=>{
	    this.setState({addLeadShow : !this.state.addLeadShow});
	}

  	// callMagnetTabFunction = (userId) => {
   //     this.child.handleMagnetCall(userId);  ///calling a child function here
  	// }

	handleDigiInterest = (data) =>{
        this.setState({
            digiInterestShow: !this.state.digiInterestShow,
            fullDetail: data,
        })
    }
		
	getApi = async (id) =>{
		if(window.$user?.group.toLowerCase() !== 'magnet'){
			
			let resData = await getOpenLeadActivityData(id);
			if(resData.meta.status === 503){		
				this.setState({
					networkError : true,
					showLeadActivtyLoader:false
				})		
			}else{
				this.setState({
					showLeadActivtyLoader : false,
					homeData : resData.data
				});
			}

			let resRatio = await getLeadRatioData(id);
	        if(resRatio.meta.status === 503){
	    		this.setState({
	    			networkError : true,
	    			showLoader:false
	    		})
	    	}else{
	   			this.setState({
	   				showLoader : false,
	   				ratioData : resRatio.data
	   			});
	    	}
			window.scrollTo(0, 0);
		}
		else{
			let spendRes = await getDigitalSpendData(id);
			if(spendRes.meta.status === 200){
				this.setState({spendData : spendRes.data,showLoader : false}) ;
			}else{
				this.setState({spendData : []}) ;
			}

			this.setState({showAllLeadLoader : true,showOpenLeadLoader : true,showBookLeadLoader : true,showCloseLeadLoader : true});
	    	let leadAllCount = await getListCount({name:"all",data:{api_location:'dashboard'}});
	    	if(leadAllCount.meta?.status === 200) this.setState({allCount:leadAllCount.data,showAllLeadLoader : false})

	    	let leadOpenCount = await getListCount({name:"open",data:{}});
	    	if(leadOpenCount?.meta?.status === 200) this.setState({openCount:leadOpenCount.data,showOpenLeadLoader : false})

	    	let leadBookedCount = await getListCount({name:"booked",data:{}});
	    	if(leadBookedCount.meta?.status === 200) this.setState({bookedCount:leadBookedCount.data,showBookLeadLoader : false})

	    	let leadClosedCount = await getListCount({name:"dashboard",data:{}});
	    	if(leadClosedCount.meta?.status === 200) this.setState({closedCount:leadClosedCount.data,showCloseLeadLoader : false})

		}

    	// this.setState({showLoader : true})
        // let id = window.$user.id;
        let OpenLeadData = await getOpenLeadData(id);
		if(OpenLeadData.meta.status === 200){
			this.setState({OpenLeadData : OpenLeadData.data,showLoader : false}) ;
		}else{
			this.setState({OpenLeadData : []}) ;
		}

        let meetingData = await getFutureActivityData(id);
		this.setState({meetingData: meetingData.data})
	}
	
	render(){

		const {
			// tcModalShow,
			OpenLeadData,homeData,showLeadActivtyLoader,showLoader,tab,ratioData,allCount,openCount,bookedCount,closedCount,magnet,isLoading,spendData, meetingData} = this.state;
		const leadBlock = [
			{title: "Not Update", type: "notUpdate"},
			{title: "Suspect", type: "rml"}
		]
		return(
			<div>
			{this.state.networkError === false ?
			<div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-3 mb-3">
                            	<span className="m-0 text-dark card_font_25">Dashboard</span>
                            </div>
                            <div className="col-sm-9 pl-md-1 px-1">
                            	<div className="row">
                            		{this.state.role !== 'magnet' && this.state.role !== 'closings1' && this.state.role !== 'sourcings2' && this.state.role !== 'magnet s2' && this.state.role !== 'sourcings3'  && this.state.role !== 'magnet s3' ?
			                            <div className="col-sm-6 pr-md-0 mb-3 mb-md-0">
				                            <FormControl>
				                                <Autocomplete
				                                  id="asynchronous-demo"
				                                  getOptionSelected={(magnet, value) => magnet.name === value.name}
				                                  getOptionLabel={magnet => magnet.name +" ("+magnet.phone+")"}
				                                  options={magnet}
				                                  loading={isLoading}
				                                  onChange={(_event, magnet) => {
				                                    if (magnet === null) {
				                                        this.setState({
				                                        	magnet: [],
				                                        	assign_rm_id : window.$user?.id,
				                                            group : window.$user?.group.toLowerCase(),
				                                        })
				                                        this.handleRoleWise(window.$user?.id)
				                                        if(this.state.group !== 'magnet') {
				                                        	this.callChildFunction(window.$user?.id)
				                                        }
				                                    }else{
				                                        this.setState({
				                                            assign_rm_id : magnet.id,
				                                            group : GROUPS[magnet.Role?.group_id].toLowerCase(),
				                                        })
				                                        this.handleRoleWise(magnet.id)
				                                        if(this.state.group !== 'magnet') {
				                                        	this.callChildFunction(magnet.id)
				                                        }
				                                    }
				                                  }}
				                                  renderInput={params => (
				                                    <TextField
				                                      {...params}
				                                      label="Select User"
				                                      variant="outlined"
				                                      fullWidth
				                                      onChange={this.handleAutoChange}
				                                      InputProps={{
				                                        ...params.InputProps,
				                                        endAdornment: (
				                                          <React.Fragment>
				                                            {isLoading ? (
				                                              <CircularProgress color="inherit" size={20} />
				                                            ) : null}
				                                            {params.InputProps.endAdornment}
				                                          </React.Fragment>
				                                        )
				                                      }}
				                                    />
				                                  )}
				                                />
				                            </FormControl>
				                        </div>
				                        :
				                        <div className="col-sm-6 pr-md-0 mb-3 mb-md-0"></div>
				                    }
				                    {(this.state.group === 'magnet' && this.state.role !== "magnet s2" && this.state.role !== "magnet s3")&&
						                <Frag>
						                    <div className="col-sm-2 col-4 mt-1 pr-0">
												<Button
					                                variant="contained"
					                                size="small"
					                                color="primary"
													disabled={window.$role === "magnet" ? false : true}
					                                className="w-100 mx-auto"
													onClick={()=>this.handleDigiInterest([])} 
													style={{textTransform: 'capitalize'}}
					                              >
					                                Invest Now
					                            </Button>
											</div>
						                    <div className="col-sm-2 col-4 mt-1 pr-0">
				                            	<Button
					                                variant="contained"
					                                size="small"
					                                color="primary"
					                                className="w-100 mx-auto"
					                                onClick={this.handleLeadModal}
													style={{textTransform: 'capitalize'}}
					                              >
					                                Add Lead
					                            </Button>
				                            </div>
				                            {
					                            <Frag>
						                            <div className="col-sm-2 col-4 mt-1">
						                            	<Button
							                                className="w-100"
							                                variant="outlined"
							                                size="small"
							                                color="primary"
							                                onClick={this.handleModal}
															style={{textTransform: 'capitalize'}}
							                              >
							                                Bulk Lead
							                            </Button>
						                            </div>
						                        </Frag>
					                        }
				                        </Frag>
			                        }
                            	</div>
                            </div>
                        </div>
                    </div>
                </div>
				<section className="content">
                	<div className="container-fluid">
                		<div className="row mb-3">
                			{(this.state.group === 'magnet' && this.state.role !== 'magnet s2' && this.state.role !== "magnet s3") ?
								<div className="col-md-3 col-12 pr-md-1">
									<Card className="popup_hover mb-3 pb-3">
			                            <Card.Body  style={{padding: '15px 15px 10px'}}>
			                                <div className="row">
			                                    <span className='col-sm card_font_25 col-4'>Leads</span>
			                                    <div className="col-sm col-8">
			                                        <h3 className="f-w-300 d-flex align-items-center float-right">{OpenLeadData?.open + OpenLeadData?.closed + OpenLeadData?.booked}</h3>
			                                    </div>
			                                </div>
			                                <div className="row">
			                                	<div className="col-md-12">
				                                    <span className="p-1 col-sm-4 float-left leadsBlock">Closed</span>
				                                    <div className="progress col-sm-8 mt-2" style={{height: '15px', padding: '0'}}>
                                                        <div className="progress-bar progress-c-theme" role="progressbar" style={{width: OpenLeadData?.grandTotal !== 0 ? Math.round((OpenLeadData?.closed/OpenLeadData?.grandTotal)*100) : 0+'%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                                            <span className="text-left pl-2 progressCount">{OpenLeadData?.closed}</span>
                                                        </div>
				                                    </div>
			                                    </div>
			                                </div>
			                                <div className="row">
			                                	<div className="col-md-12">
				                                    <span className="p-1 col-sm-4 float-left leadsBlock">Open</span>
				                                    <div className="progress col-sm-8 mt-2" style={{height: '15px', padding: '0'}}>
                                                        <div className="progress-bar progress-c-theme" role="progressbar" style={{width: OpenLeadData?.grandTotal !== 0 ? Math.round((OpenLeadData?.open/OpenLeadData?.grandTotal)*100) : 0+'%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                                            <span className="text-left pl-2 progressCount">{OpenLeadData?.open}</span>
                                                        </div>
				                                    </div>
			                                    </div>
			                                </div>
			                                <div className="row">
			                                	<div className="col-md-12">
				                                    <span className="p-1 col-sm-4 float-left leadsBlock">Booked</span>
				                                    <div className="progress col-sm-8 mt-2" style={{height: '15px', padding: '0'}}>
                                                        <div className="progress-bar progress-c-theme" role="progressbar" style={{width: OpenLeadData?.grandTotal !== 0 ? Math.round((OpenLeadData?.booked/OpenLeadData?.grandTotal)*100) : 0+'%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                                            <span className="text-left pl-2 progressCount">{OpenLeadData?.booked}</span>
                                                        </div>
				                                    </div>
			                                    </div>
			                                </div>
			                            </Card.Body>
			                        </Card>
								</div>
								:
								<div className="col-md-3 col-12 pr-md-1">
									<Paper className="small_box py-3 popup_hover mb-3">
										<div className="pl-2 deskColor">
											<p className="card_font_25 mb-0 pl-2">Ratios</p>
										</div>
										<div className="row px-3 pb-1">
											<div className="col-4">
												{showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
												{!showLoader &&
													<div className="desk_count text-center ">
														<span className="">{ratioData?.lead_to_opportunity}<b className="fs-20">%</b></span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">L to O</span>
												</div>
											</div>
											<div className="col-4">
												{showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
												{!showLoader &&
													<div className="desk_count text-center ">
														<span className="">{ratioData?.opportunity_to_pipeline}<b className="fs-20">%</b></span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">O to P</span>
												</div>
											</div>
											<div className="col-4">
												{showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
												{!showLoader &&
													<div className="desk_count text-center ">
														<span className="">{ratioData?.lead_to_loss}<b className="fs-20">%</b></span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">L to LS</span>
												</div>
											</div>
											<div className="col-4">
												{showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
												{!showLoader &&
													<div className="desk_count text-center ">
														<span className="">{ratioData?.activity_to_booking}<b className="fs-20">%</b></span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">A to B</span>
												</div>
											</div>
											{this.state.group !== 'presale' &&
												<>
													<div className="col-4">
														{showLoader ?
															(<CircularProgress color="primary" size={20} />
															): null
														}
														{!showLoader &&
															<div className="desk_count text-center ">
																<span className="">{ratioData?.lead_to_activity}<b className="fs-20">%</b></span>
															</div>
														}
														<div className="fs-12 text-center text-muted">
															<span className="fs-12">L to A</span>
														</div>
													</div>
													<div className="col-4">
														{showLoader ?
															(<CircularProgress color="primary" size={20} />
															): null
														}
														{!showLoader &&
															<div className="desk_count text-center ">
																<span className="">{ratioData?.lead_to_booking}<b className="fs-20">%</b></span>
															</div>
														}
														<div className="fs-12 text-center text-muted">
															<span className="fs-12">L to B</span>
														</div>
													</div>
												</>
											}
											{this.state.group === 'presale' &&
												<>
													<div className="col-4">
														{showLoader ?
															(<CircularProgress color="primary" size={20} />
															): null
														}
														{!showLoader &&
															<div className="desk_count text-center ">
																<span className="">{ratioData?.reactivation_to_activity}<b className="fs-20">%</b></span>
															</div>
														}
														<div className="fs-12 text-center text-muted">
															<span className="fs-12">R to A</span>
														</div>
													</div>
													<div className="col-4">
														{showLoader ?
															(<CircularProgress color="primary" size={20} />
															): null
														}
														{!showLoader &&
															<div className="desk_count text-center ">
																<span className="">{ratioData?.lead_to_reactivation}<b className="fs-20">%</b></span>
															</div>
														}
														<div className="fs-12 text-center text-muted">
															<span className="fs-12">L to R</span>
														</div>
													</div>
												</>
											}
										</div>
										<div className="icon">
											<i className="ion ion-person-add"></i>
										</div>
									</Paper>
									{/* <Paper className="small_box p-3">
										<div className="pl-2 deskColor">
											<p className="card_font_25 mb-0">Ratios</p>
										</div>
										<div className="px-4 pt-2 pb-4">
											<p className="desk_head mb-0 text-center">Coming Soon...</p>
										</div>
										<div className="icon pb-3">
											<i className="ion ion-person-add"></i>
										</div>
									</Paper> */}
								</div>
							}
			        		<div className="col-lg-6 col_padding col-12 pr-md-0">
								<Card className="popup_hover mb-3">
		                            <Card.Body style={{padding: '10px 15px'}}>
		                                <div className="row">
		                                    <div className="col-xl-4 col-md-6">
		                                        <div className="row">
		                                            <span className='col-md-12 mb-2 card_font_25 col-8'>Open + <br/> New Leads</span>
		                                            <div className="col-md-12 mb-2 col-4">
		                                                <h3 className="f-w-300 ml-md-3">{OpenLeadData?.total}</h3>
		                                            </div>
		                                        </div>
		                                    </div>
		                                    <div className="col-xl-8 col-md-6">
												<LeadProgress title="Not Update" total={OpenLeadData?.total} lead={OpenLeadData?.notUpdate} />
												<LeadProgress title="Suspect" total={OpenLeadData?.total} lead={OpenLeadData?.rml} />
												<LeadProgress title="Opportunity" total={OpenLeadData?.total} lead={OpenLeadData?.opportunity} />
												<LeadProgress title="Pipeline" total={OpenLeadData?.total} lead={OpenLeadData?.pipeline} />
												<LeadProgress title="EOI" total={OpenLeadData?.total} lead={OpenLeadData?.other} />
	                                        </div>
		                                </div>
		                            </Card.Body>
		                        </Card>
							</div>
							<div className="col-lg-3 col-12 mb-3">
                				{(this.state.group !== 'magnet' || this.state.role === 'magnet s2' || this.state.role === "magnet s3") ?
									<Paper className="small_box popup_hover pt-3 pb-2">
										<div className="pl-2 deskColor">
											<p className="card_font_25 mb-0 pl-0">Planned Activities</p>
										</div>
										<div className="row px-3">
											<div className="col-6">
												{showLeadActivtyLoader ?
													(<CircularProgress color="secondary" size={20} />
													): null
												}
												{!showLeadActivtyLoader &&
													<div className="desk_count text-center ">
														<span className="">{homeData[0]?.sv_arranged}</span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">SV Planned</span>
												</div>
											</div>
											<div className="col-6">
												{showLeadActivtyLoader ?
													(<CircularProgress color="secondary" size={20} />
													): null
												}
												{!showLeadActivtyLoader &&
													<div className="desk_count text-center ">
														<span className="">{homeData[0]?.vc_arranged}</span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">VC Planned</span>
												</div>
											</div>
										</div>
										<div className="row px-3 pb-2">
											<div className="col-6">
												{showLeadActivtyLoader ?
													(<CircularProgress color="secondary" size={20} />
													): null
												}
												{!showLeadActivtyLoader &&
													<div className="desk_count text-center ">
														<span className="">{homeData[0]?.future_followup}</span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">FU Pending</span>
												</div>
											</div>
											<div className="col-6">
												{showLeadActivtyLoader ?
													(<CircularProgress color="secondary" size={20} />
													): null
												}
												{!showLeadActivtyLoader &&
													<div className="desk_count text-center ">
														<span className="">{homeData[0]?.past_followup}</span>
													</div>
												}
												<div className="fs-12 text-center text-muted">
													<span className="fs-12">FU Overdue</span>
												</div>
											</div>
										</div>
										<div className="icon">
											<i className="ion ion-person-add"></i>
										</div>
									</Paper>
									:
			                        <Frag>
			                        <Card className='small_box popup_hover mb-3 pb-2'>
			                            <Card.Body className='border-bottom' style={{padding: '12px 15px'}}>
			                                <div className="row">
			                                    <div className="col-auto d-flex flex-column">
			                                        <span className="card_font_25">FB Spends</span>
			                                        {/* <span className="card_font_25">Spends <sub className="font-small text-muted">(FB Only)</sub></span> */}
			                                        <span className="font-small text-muted">{moment().format('MMMM YYYY')}</span>
			                                    </div>
												<div className="col-auto">
			                                        <span className="card_font_25"><SpendToolTip /></span>
			                                    </div>
			                                  	{showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
												{!showLoader &&
			                                    <div className="col pt-2 pr-1">
			                                        <h3 className="spends_value">{`₹ ${spendData.spend_amount}`}</h3>
			                                    </div>
			                                    }
			                                </div>
			                            </Card.Body>
			                            <Card.Body style={{padding: '10px 15px'}}>
			                                <div className="row align-items-center justify-content-center card-active">
			                                    {showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
			                                    <div className="col-6 text-center ">
			                                        <span className="m-r-5 spends_text">Leads</span><br/>
												{!showLoader &&
			                                        <h6 className="m-b-10 spends_value">{` ${spendData.leadCount}`}</h6>
			                                	}
			                                    </div>
			                                    {showLoader ?
													(<CircularProgress color="primary" size={20} />
													): null
												}
			                                    <div className="col-6 left_border text-center ">
			                                        <span className="m-r-5 spends_text">CPL</span><br/>
												{!showLoader &&
			                                        <h6 className="spends_value m-b-10">{`₹ ${spendData.cpl}`}</h6>
			                                	}
			                                    </div>
			                                </div>
			                            </Card.Body>
			                        </Card>
			                       {/*  <Paper className="small_box p-3">
										<div className="pl-2 deskColor">
											<p className="card_font_25 mb-0">Spends</p>
										</div>
										<div className="px-4 pt-2 pb-4">
											<p className="desk_head mb-0 text-center">Coming Soon...</p>
										</div>
										<div className="icon pb-3">
											<i className="ion ion-person-add"></i>
										</div>
									</Paper> */}
									</Frag>
			                    }
							</div>
						</div>
						<div className="row">
							<div className="col-md-9 pr-md-0 order-md-1 order-2">
								{(this.state.group === 'magnet' && this.state.role !== 'magnet s2' && this.state.role !== "magnet s3")?
									<MagnetTab/>
									:
									<CarftTab  ref={(cd) => this.child = cd}/>
								}
							</div>
							<div className="col-md-3 px-0  col-12 order-md-2 order-1 mb-4">
								{this.state.role !== "magnet" && <div className="mb-2">
									<LeaderBoard/>	
								</div>
								}
								<Paper className="popup_hover small_box p-3 mx-3">
									<div className="">
										<p className="card_font_20 mb-1 tab_text_sub_head">Activities</p>
									</div>
									{meetingData.length>0 ?
										(meetingData.map((item, index) => (
											<div className="row py-2 border-top">
												<div className="col-12">
													<p className="tab_text_head">{changeDate(item.InternalStatusHistory.action_date)}</p>
													<p className="tab_text_sub_head text-capitalize">{item?.InternalStatusHistory?.LeadInternalStatus?.name || "Meeting"} with <b>{item?.Client?.name ? item?.Client?.name : "Someone"}</b></p>
												</div>
											</div>
										)))
										:
										<div className="row p-3 border-top text-center">
											No meetings !!!
										</div>
									}
								</Paper>
								{/*<div className="row">
									<div className="col-lg-6 col-12">
										<Paper className="small_box">
											<div className="pl-2 deskColor">
												<p className="desk_head mb-0">Meetings</p>
											</div>
											<Divider/>
											<div className="p-4">
												<p className="desk_head mb-0 text-center">Coming Soon...</p>
											</div>
											<div className="icon">
												<i className="ion ion-person-add"></i>
											</div>
										</Paper>
									</div>
									<div className="col-md-12 col-12">
										<Paper className="small_box">
											<div className="pl-2 deskColor">
												<p className="mb-1 text-center fs-20">Meetings</p>
											</div>
											<Divider/>
											<div className="row px-3">
												<div text-muted className="col-6">
													<div className="desk_count text-center"														<span className="">20</span>
													</div>
													<div className="fs-12 text-center text-muted">
														<span className="fs-12">SV Planned</span>
													</div>
												</div>
												<div className="col-6">
													<div className="desk_count text-center"														<span className="">40</span>
													</div>
													<div className="fs-12 text-center text-muted">
														<span className="fs-12">VC Planned</span>
													</div>
												</div>
											</div>
											<div className="row px-3 pb-2">
												<div className="col-6">
													<div className="desk_count text-center"														<span className="">20</span>
													</div>
													<div className="fs-12 text-center text-muted">
														<span className="fs-12">FU Pending</span>
													</div>
												</div>
												<div className="col-6">
													<div className="desk_count text-center"														<span className="">40</span>
													</div>
													<div className="fs-12 text-center text-muted">
														<span className="fs-12">FU Overdue</span>
													</div>
												</div>
											</div>
											<div className="icon">
												<i className="ion ion-person-add"></i>
											</div>
										</Paper>
									</div>
									<div className="col-md-6 col-12">
										<Paper className="small_box">
											<div className="pl-2 deskColor">
												<p className="desk_head mb-0">To Do List</p>
											</div>
											<Divider/>
											<div className="p-4">
												<p className="desk_head mb-0 text-center">Coming Soon...</p>
											</div>
											<div className="icon">
												<i className="ion ion-person-add"></i>
											</div>
										</Paper>
									</div>
								</div>*/}
							</div>
						</div>
                	</div>
                </section>
				</div>
                :
				<div className="content-wrapper">
	                <div className="container-fluid">
						<div className="row">
							<div className="col-md-12 col-12">
								!!! Ooops network Error...
							</div>
						</div>
					</div>
				</div>
            }

        	<BulkLeadModal show={this.state.addModalShow} callFrom="dashboard" fromType="bulkLead" changeModal={this.handleModal}/>
        	{/* <LeadModel show={this.state.addLeadShow} from="addLead" callFrom="dashboard" getList={this.props.getList} fromType="addLead" changeModal={this.handleLeadModal}/> */}
			<AddLeadModal show={this.state.addLeadShow} from={"addLead"} callFrom="dashboard" getList={this.props.getList} fromType="addLead" changeModal={this.handleLeadModal} />
			{/* {tcModalShow &&
            <TCModel  show={tcModalShow} fromType="termsCondition" changeModal={this.handleTcModal}   />
            } */}
            <DigitalForm show={this.state.digiInterestShow} changeModal={this.handleDigiInterest} fullDetail={this.state.fullDetail} handleProjectData={this.genericGetListData} digiRestType={this.state.digiRestType} />
		    </div>

		);
	};
};

export default withRouter(DashboardBody);
