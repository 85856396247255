import { get, post, getComment, getEngageComment, put, getEngageCall } from "../utils/methods.js";
import { STAGES,LEAD_INTERNAL_STATUSID } from "../config/constant";

export const getCallData = async (value) => {
	let getData = await getEngageCall('/call', value);
	return getData;
}
export const getCallWithKaleyra = async (value) => {
	let getData = await getEngageCall('/call/kaleyraClickToCall', value);
	return getData;
}
export const getListData = async (value) => {

	if (value.size == undefined || value.size == null) {
		value.size = 10;
	}

	if (value.pageId == undefined || value.pageId == null) {
		value.pageId = 1;
	}

	if (value.name === 'all') {
		value.data.type = 'all_leads';

		if (value.data.redirect_from && value.data.redirect_from === 'reports') {
			delete value.data.redirect_from
			value.data.type = "reports"

		}
		let listData = await get('/leads', value.data)

		return listData;
	}
	else if (value.name === 'new') {

		value.data.stage_ids = STAGES.NOT_UPDATED;
		value.data.type = 'new';
		if (window.$user.group === 'admin') value.data.assign_leads = 'no';
		let listData = await get('/leads', value.data)
		return listData;
	}
	else if (value.name === 'assigned') {
		value.data.type = 'assigned';

		let listData = await get('/leads', value.data)
		return listData;
	}
	else if (value.name === 'booked') {
		value.data.stage_ids = STAGES.BOOKED;
		let listData = await get('/leads', value.data)
		return listData;
	}
	else if (value.name === 'closed') {
		value.data.stage_ids = STAGES.CLOSED;
		let listData = await get('/leads', value.data)
		return listData;
	}
	else if (value.name === 'cancel') {
		value.data.stage_ids = STAGES.CANCEL;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'suspect') {
		value.data.stage_ids = STAGES.SUSPECT;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'prospect') {
		value.data.stage_ids = STAGES.REMIND_ME_LATER;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'decision-delayed') {
		value.data.stage_ids = STAGES.DECISION_DELAYED;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'opportunity') {
		value.data.stage_ids = STAGES.OPPORTUNITY;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'pipeline') {
		value.data.stage_ids = STAGES.PIPELINE;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'gross-eoi-application') {
		value.data.stage_ids = STAGES.GROSS_EOI;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'gross-eoi-non-refundable') {
		value.data.stage_ids = STAGES.GROSS_EOI_NON_REFUNDABLE;
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'sitevisit') {
		value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.VISIT_ARRANGED]
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'videocall') {
		value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.VIDEO_CALL_ARRANGED];
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'hpop') {
		value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.HPOP];
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'revisit') {
		value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.REVISIT_ARRANGED]
		let listData = await get('/leads', value.data)
		return listData;
	} else if (value.name === 'open') {
		if (value.data.stage_ids == undefined || value.data.stage_ids.length <= 0) {
			value.data.stage_ids = [STAGES.SUSPECT, STAGES.REMIND_ME_LATER, STAGES.DECISION_DELAYED, STAGES.OPPORTUNITY, STAGES.PIPELINE, STAGES.GROSS_EOI, STAGES.GROSS_EOI_NON_REFUNDABLE];
		}
		let listData = await get('/leads', value.data)
		return listData;
	}
	else if (value.name === 'sourcing') {
		let listData = await get('/leads/list/revert', value.data)
		return listData;
	}
	else if (value.name === 'disinterested') {
		let listData = await get('/leads/list/revert', value.data)
		return listData;
	}
	else if (value.name === 'reactivation') {
		let listData = await get('/leads/list/revert', value.data)
		return listData;
	}
	else if (value.name === 'disqualify') {
		let listData = await get('/leads/list/revert', value.data)
		return listData;
	}
	else if (value.name === 'cross_sale_in') {
		let listData = await get('/leads/list/cross_sale', value.data)
		return listData;
	}
	else if (value.name === 'cross_sale_out') {
		let listData = await get('/leads/list/cross_sale', value.data)
		return listData;
	}
	else if (value.name === 'audit') {
		// if (value.data.followup_time === undefined) {
		// 	value.data.followup_time = 'past'
		// }
		let listData = await get('/leads/list/audit-leads', value.data)
		return listData;
	}else if(value.name === 'home-loan-leads'){
		value.data.loan_required = 1;
		let listData = await get('/leads', value.data)
		return listData;
	}
}

export const getListCount = async (value) => {

	let listCount = {};
	switch (value.name) {
		case "all":
			if (value.data.redirect_from && value.data.redirect_from === 'reports') {
				delete value.data.redirect_from
				value.data.type = "reports"
			}
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "new":
			value.data.stage_ids = STAGES.NOT_UPDATED;
			value.data.type = 'new';
			if (window.$user.group === 'admin') value.data.assign_leads = 'no';
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "assigned":
			value.data.type = 'assigned';
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "booked":
			value.data.stage_ids = STAGES.BOOKED;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "audit":
			value.data.type = 'audit';
			value.data.stage_ids = STAGES.CLOSED;
			// if (value.data.followup_time === undefined) {
			// 	value.data.followup_time = 'past'
			// }
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "closed":
			value.data.stage_ids = STAGES.CLOSED;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "dashboard":
			value.data.stage_ids = [STAGES.DECISION_DELAYED, STAGES.CLOSED];
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "cancel":
			value.data.stage_ids = STAGES.CANCEL;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "suspect":
			value.data.stage_ids = STAGES.SUSPECT;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "prospect":
			value.data.stage_ids = STAGES.REMIND_ME_LATER;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "decision-delayed":
			value.data.stage_ids = STAGES.DECISION_DELAYED;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "opportunity":
			value.data.stage_ids = STAGES.OPPORTUNITY;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "pipeline":
			value.data.stage_ids = STAGES.PIPELINE;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "gross-eoi-application":
			value.data.stage_ids = STAGES.GROSS_EOI;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "gross-eoi-non-refundable":
			value.data.stage_ids = STAGES.GROSS_EOI_NON_REFUNDABLE;
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "open":
			if (value.data.stage_ids == undefined || value.data.stage_ids.length <= 0) {
				// console.log(value.data.stage_ids,"stage in count kkkkkkkk")
				value.data.stage_ids = [STAGES.SUSPECT, STAGES.REMIND_ME_LATER, STAGES.DECISION_DELAYED, STAGES.OPPORTUNITY, STAGES.PIPELINE, STAGES.GROSS_EOI, STAGES.GROSS_EOI_NON_REFUNDABLE];
			}
			listCount = await get('/leads/index/count', value.data)
			return listCount;
			break;
		case "sitevisit":
			    value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.VISIT_ARRANGED]
				listCount = await get('/leads/index/count', value.data)
				return listCount;
				break;
		case "videocall":
			value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.VIDEO_CALL_ARRANGED];
			listCount = await get('/leads/index/count', value.data)
				return listCount;
				break;
		case "hpop":
			value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.HPOP];
			listCount = await get('/leads/index/count', value.data)
				return listCount;
				break;
		case "revisit":
			value.data.internal_status_ids = [LEAD_INTERNAL_STATUSID.REVISIT_ARRANGED];
			listCount = await get('/leads/index/count', value.data)
				return listCount;
				break;
		case "home-loan-leads":
			value.data.loan_required = 1;
			listCount = await get('/leads/index/count', value.data)
				return listCount;
				break;		
		default:
			break;
	}

}

export const getOverdueData = async (value, assign) => {
	// console.log(value, assign);
	if (value.type === 'all') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=ALL')
		}
		else {
			assign.bucket = "ALL"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'open') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=OPEN')
		}
		else {
			assign.bucket = "OPEN"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}

	else if (value.type === 'suspect') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=SUSPECT')
		}
		else {
			assign.bucket = "SUSPECT"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'prospect') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=PROSPECT')
		}
		else {
			assign.bucket = "PROSPECT"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'decision-delayed') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=DECISION_DELAYED')
		}
		else {
			assign.bucket = "DECISION_DELAYED"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'opportunity') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=OPPORTUNITY')
		}
		else {
			assign.bucket = "OPPORTUNITY"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'pipeline') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=PIPELINE')
		}
		else {
			assign.bucket = "PIPELINE"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'gross-eoi-application') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=GROSS_EOI_APPLICATION')
		}
		else {
			assign.bucket = "GROSS_EOI_APPLICATION"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'gross-eoi-non-refundable') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=GROSS_EOI_NON_REFUNDABLE')
		}
		else {
			assign.bucket = "GROSS_EOI_NON_REFUNDABLE"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}
	else if (value.type === 'audit') {
		let listData;
		if (assign === undefined) {
			listData = await get('/leads/followup/count?bucket=audit')
		}
		else {
			assign.bucket = "audit"
			listData = await get('/leads/followup/count', assign)
		}
		return listData;
	}else if(value.type === 'home-loan-leads'){
		let listData;
		if(!assign){
		 assign = {};	
		}
		assign.loan_required = 1;
		listData = await get('/leads/followup/count', assign)
		return listData;
	} 

}

export const getFullLeadData = async (value) => {

	let fullListData = await get('/leads/' + value)
	return fullListData;

}
export const getTenseCount = async (value) => {

	let fullListData = await get('/leads', value)
	return fullListData;

}
export const getTenseLeadCount = async (value) => {

	let fullListData = await get('/leads/index/count', value)
	return fullListData;

}
export const getClientData = async (value) => {

	let getClientData = await get('/clients/' + value)
	return getClientData;

}

export const getKnowlarityData = async (value) => {

	let getKnowlarityData = await getComment('/callDetail', value)
	return getKnowlarityData;

}

export const getAddLeadData = async (value) => {

	let AddLeadData = await post('/leads/create', value)
	return AddLeadData;

}

export const addleadinterntal = async (value) => {

	let internalStatusHistory = await post('/internal_status_history', value)
	return internalStatusHistory;

}

export const addLeadFollowUp = async (id, data) => {

	let internalStatusHistory = await put('/internal_status_history/leads/' + id, data)
	return internalStatusHistory;
}

export const assignLeads = async (value) => {

	let assignLeads = await post('/leads/assign', value)
	return assignLeads;

}

export const deleteLead = async (value, data) => {
	let deleteLead = await put('/leads/' + value + '/delete', data)
	return deleteLead;

}

export const getCommentData = async (value) => {

	let commentData = '';
	if (value.data == 'SHORT') {
		commentData = await getComment('/1/' + value.id + '/comments?index_type=SHORT')
	} else {
		commentData = await getComment('/1/' + value.id + '/comments')
	}

	return commentData;

}

export const postEngageComment = async (value) => {

	let commentData = await getEngageComment('/1/' + value.id + '/comments', value.data)

	return commentData;

}

export const getUpdateLeadData = async (value) => {


	let commentData = await get('/leads/' + value.id, value.data)
	return commentData;

}

export const getLeadinternalHistroyData = async (value) => {


	let commentData = await get('/leads/' + value + '/internal_status_history')
	return commentData;

}

export const getLeadAutoAssignList = async (value) => {


	let getLeadAutoAssignData = await get('/auto_assign', value)
	return getLeadAutoAssignData;

}

export const addLeadAutoAssignList = async (value) => {


	let addLeadAutoAssignData = await post('/auto_assign', value)
	return addLeadAutoAssignData;

}

export const updateLeadAutoAssign = async (value) => {


	let updateLeadAutoAssignData = await put('/auto_assign/' + value.id, value.data)
	return updateLeadAutoAssignData;

}

export const UpdateLeadData = async (id, value) => {


	let data = await put('/leads/' + id, value)
	return data;

}

export const UpdateRevertData = async (value) => {


	let data = await put('/leads/' + value.id + '/revert', value.data)
	return data;

}

export const assignClosedLead = async (id, value) => {
	try {
		let leadRes = await put('/leads/' + id + '/reopen', value)
		return leadRes;
	} catch (e) {
		console.log('==== ERROR ====>', e)
	}

}

export const getKnowlarityDataWithIvr = async (value) => {

	let getKnowlarityData = await get('/ivrLog/logs', value);
	return getKnowlarityData;

}

export const checkDublicateLead = async (value) => {
	try {
		let leadRes = await get('/leads/check_duplicate/lead', value)
		return leadRes;
	} catch (e) {
		console.log('==== ERROR ====>', e)
	}

}

export const addLeadDataOld = async (value) => {

	let AddLeadData = await post('/leads', value)
	return AddLeadData;

}

export const auditBack = async (id) => {
	try {
		let leadRes = await put('/leads/closed_to_audit_open/' + id)
		return leadRes;
	} catch (e) {
		console.log('==== ERROR ====>', e)
	}

}

export const bookingRolesDetail = async (leadId) =>{
	try {
		let bookingRoles = await get('/bookingRoles/'+leadId)
		return bookingRoles;
	}catch (error){
		console.log(error)
	}
}