import React from 'react';

import Select_ from '@material-ui/core/Select';

import './Select.sass'
import clsx from 'clsx';


const defaultState = {
}


const Select = props => <Select_ className="Select" {...props}>{props.children}</Select_>

export default Select;
