import React, { Component } from "react";
import { withRouter , Link } from "react-router-dom";
import "./magnetList.css";
import Frag from "../../utils/Frag/Frag.js";
import { getListData, getFullLeadData, deleteLead, postEngageComment, getOverdueData, getListCount, addLeadFollowUp } from "../../dataParser/getListData";
import { changeDate, dateTimeConverter, letterCase, objEmpty } from "../../helper";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserEdit, faFilter, faPhoneAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import DetailLead from "../detailLead/detailLead";
import Loader from "../../component/common/loader/loader";
import LeadUpdateModel from "../../component/common/modal/leadUpdateMOdal";
import LeadModel from "../../component/common/modal/leadModal.js";
import LeadInternalState from "../../component/common/modal/leadInternalStModel.js";
import Filter from "../filter/filter";
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { getCommentData, getCallData,getCallWithKaleyra } from "../../dataParser/getListData";
import CircularProgress from '@material-ui/core/CircularProgress';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import Checkbox from '@material-ui/core/Checkbox';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Tooltip from '@material-ui/core/Tooltip';
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import ModalAssignRM from "../../component/common/modal/modal";
import SweetWarnAlert from "../../component/common/sweetAlert/sweetAlertWarning";
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RedoIcon from '@material-ui/icons/Redo';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Footer from "../../component/footer/footer";
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import AuditLeadModal from "../../component/common/modal/AuditLeadModal.js";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AddLeadModal from "../../component/common/modal/addLeadModal";
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import LaunchIcon from '@material-ui/icons/Launch';
import AssignModalForm from "../../component/common/modal/assignModal";
import { mySelfRoles, assignToTabs, reOpenRoles, fieldHiddenSales, sendToAuditAccess } from "../../config/constant/crmRoleFunctionPermission.js";
import { Drawer as FilterDrawer } from "@material-ui/core";
import { CALLER_TYPE , KALEYRA_CLICK_TO_CALL_ROLES } from "../../config/constant";
import AuditUpdateModal from "../common/modal/AuditUpdateModal";
import FollowUpControl from "../../core/FollowUpControl";
import ReopenAudit from "../common/action/ReopenAudit";
// import SelectOption from "../common/controls/SelectOption";
// import VisibilityIcon from '@material-ui/icons/Visibility';

const assignedTo = {
    position: 'absolute',
    right: '12%',
    top: '25%',
    width: '10.25%'
}
var globalFilter = {}
// var max_chars = 200;

/* const handleDefaultPM = () =>{
    if(moment().format('a') === 'am'){
        return moment().add(12, 'hours')
    }
} */
class magnetList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listDetail: -1,
            addComment: '',
            reAssign: null,
            lead_assign: null,
            reAssignLeadID: null,
            formType: '',
            formStatus: '',
            meta: props.listValue.meta,
            data: props.listValue.data['leads'],
            leadCount: 0,
            activePage: 1,
            filters: [],
            modalShow: false,
            modalLeadShow: false,
            showOpenLeadLoader: false,
            modalLeadInternalShow: false,
            sweetWarnShow: false,
            auditModalShow: false,
            fullDetail: {},
            revertLead: '',
            commentState: '',
            showLoader: false,
            showFilter: false,
            isLoading: false,
            commentStatus: false,
            revertModalShow: false,
            isDesktop: 0,
            is_focus: {},
            overDueCount: {},
            followup_date: null,
            ids: [],
            // modalCpUpdateShow: false,
            isError: false,
            sweetShow: false,
            reportFltr: {},
            type: "success",
            alertShow: false,
            alertMsg: '',
            title: "",
            role: window.$user.role,
            agent_number: window.$user.phone ? '+91' + window.$user.phone : '9111111111',
            customer_number: '',
            object_id: null,
            user_id: window.$user.id,
            callRoles: this.props.leadInfo === "audit" ? ["admin", "financehead", "operationshead", "operationsanalyst", "operationshead"] : ["admin", "presalehead", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "presalestl",'salestl','closings1','salesmanager'],
            addLeadModalShow: false,
            checkSameNumberLeads: true,
            selectedFilters: {},
            // chars_left: max_chars
            // presale_audit: "sales"
            // , current_lead_id: null
            auditUpdateModalShow: false
            , auditUpdateId: ""
        };
        this.fltr = React.createRef()
        window.addEventListener("resize", this.update);
        // document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidMount() {
        globalFilter={}
        this.update();
        this.setState({ LeadTab: this.props.leadInfo, showOpenLeadLoader: true });
        this.setState({ group: window.$user.group.toLowerCase(), role: window.$user.role });
        this.setDynamicSwitchState(this.state.data);
        (async () => {
            try {

                let listData = {};
                listData.data = {};
                listData.data.size = 24;
                listData.data.pageId = 1;
                listData.name = this.props.leadInfo;

                if ((this.state.role === 'salestl' || this.state.role === 'seniorsalestl' || this.state.role === 'sourcings2' || this.state.role === 'magnet s2') && (this.props.leadInfo === "all" || this.props.leadInfo === "open")) listData.data.assign_to = 'myself';

                if (localStorage.getItem("rprtfltr")) {
                    let rprtfltr = JSON.parse(atob(localStorage.getItem("rprtfltr")));
                    localStorage.removeItem("rprtfltr");

                    this.setState({ reportFltr: rprtfltr });
                    const reportFltr = rprtfltr;
                    if (reportFltr.reportType !== null) {
                        delete listData.data.assign_to;
                    }

                    if (
                        reportFltr.reportType === "activity" ||
                        reportFltr.reportType === "followup" ||
                        reportFltr.reportType === "project-activity-rm" ||
                        reportFltr.reportType === "current-status" ||
                        reportFltr.reportType === "project-rm" ||
                        reportFltr.reportType === "update" ||
                        reportFltr.reportType === "presale-current-status" ||
                        reportFltr.reportType === "presale-activity" ||
                        reportFltr.reportType === "booking" ||
                        reportFltr.reportType === "presale-booking" ||
                        reportFltr.reportType === "magnet-current-status" ||
                        reportFltr.reportType === "audit" ||
                        reportFltr.reportType === "magnet-activity"
                    ) {
                        Object.assign(listData.data, reportFltr.filter);
                        this.setState({ filters: reportFltr.filter });
                    }
                } else if (
                    this.props.location.state !== undefined &&
                    this.props.location.state.fltr !== undefined &&
                    this.props.location.state.fltr !== null
                ) {
                    Object.assign(listData.data, this.props.location.state.fltr.filter);
                    this.setState({ filters: this.props.location.state.fltr.filter, reportFltr: this.props.location.state.fltr });
                } else {
                    this.handleAssignToChange(globalFilter)
                }

                if (Number.isInteger(Number(this.props.phone))) {
                    listData.data.client_number = this.props.phone;
                }

                let leadCount = await getListCount(listData);
                if (leadCount.meta.status === 200) {
                    await this.setState({
                        leadCount: leadCount.data,
                        showOpenLeadLoader: false
                    })
                    this.props.location.state = undefined;
                    if (this.props.phone !== "report") {
                        localStorage.removeItem("rprtfltr");
                    }
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        leadCount: 0,
                        showOpenLeadLoader: false
                    })
                }

            } catch (e) {
                //...handle the error...
            }
            this.setState({ showOpenLeadLoader: false });
        })();

        if (this.props.leadInfo !== 'sourcing' || this.props.leadInfo !== 'disinterested' || this.props.leadInfo !== 'reactivation' || this.props.leadInfo !== 'disqualify') {
            this.handleOverDueCount()
        }
        /* if(this.props.leadInfo == 'audit'){
            globalFilter["user_group"] = this.state.presale_audit // i.e "sales"
            this.handleFilter(globalFilter)
        } */
    }

    /* handleClickOutside = (e) => {
        const { className } = e.target
        if (this.node !== undefined && this.node !== null &&
            !this.node.contains(e.target) && this.state.showFilter &&
            className !== 'MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button'
            && className !== 'MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit'
            && className !== 'MuiAutocomplete-option'
            && className !== 'MuiAutocomplete-noOptions'
            && className !== 'MuiTypography-root MuiTypography-body2 MuiTypography-colorTextSecondary'
            && className !== 'MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true'
            && className.baseVal !== 'MuiSvgIcon-root makeStyles-icon-141'
            && className !== 'stopClosing') {
            this.setState({
                showFilter: false,
            });
        }
    } */

    handleOverDueCount = (assign) => {
        (async () => {
            try {
                let overDue = {};
                overDue.type = this.props.leadInfo;
                let overDueRes = await getOverdueData(overDue, assign);
                if (overDueRes.meta.status === 200) {
                    this.setState({ overDueCount: overDueRes.data });
                } else {
                    this.setState({ overDueCount: {} });
                }

            } catch (e) {
                //...handle the error...
            }
        })();
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth,
            role: window.$role
        });
    };

    componentDidUpdate(nextProps, prevState) {
        if (this.props !== nextProps) {

            this.setState({
                meta: nextProps.listValue.meta,
                data: nextProps.listValue.data['leads'],
                activePage: 1,
                listDetail: false,
            });
        }
        /* if(this.state.current_lead_id !== prevState.current_lead_id){
            this.commentsData(this.state.current_lead_id)
            this.setState({ isError: false })
        } */
    }

    setDynamicSwitchState = (list) => {

        if (!list) {
            return
        }

        const is_focus = {};

        list.forEach((item, index) => {
            is_focus['switch-' + item.id] = false;
        });

        this.setState({
            is_focus: is_focus
        })
    }

    commentsData = async (id) => {
        this.setState({
            isLoading: true,
            commentState: '',
            commentStatus: false,
            addComment: '',
            followup_date: null
        });

        let commentReq = {};
        let leadId = id;

        commentReq.id = leadId;
        commentReq.data = 'SHORT';

        // let commentRes = await getCommentData(commentReq);
        getCommentData(commentReq).then(commentRes=>{
            if (commentRes.meta.status === 200) {
                this.setState({
                    isLoading: false,
                    commentState: commentRes,
                    commentStatus: true,
                })
            }
            else {
                this.setState({
                    isLoading: false,
                    commentState: commentRes,
                    commentStatus: true,
                })
            }
        });
    }

    addCommentsData = async (id, nationality) => {

        let leadId = id;
        // if(this.state.addComment.length <= max_chars){
            if (window.$role !== "magnet") {
                if (this.state.addComment.trim().length && this.state.followup_date !== null) {
                    let followup = this.state.followup_date
                    let hour = moment(followup, 'YYYY/MM/DD h:m A').hour(); 
                    let time = moment(followup, 'YYYY/MM/DD h:m A').format('LT'); 
                    if(nationality == 1 && !(hour >= 8 && hour < 22)) {
                        this.setState({
                            alertShow:true,
                            alertType: "error",
                            alertMsg: `Please re-confirm the follow-up time selected. It is ${time}`
                        })
                        return
                    }

                    this.setState({ showLoader: true });
                    // followup = moment(followup, 'YYYY/MM/DD h:m A').format('YYYY/MM/DD HH:mm'); 
                    followup = dateTimeConverter(followup); 
                    let follow = await addLeadFollowUp(leadId, { followup_date: followup, comment: this.state.addComment });
                    if (follow.meta.status === 409) {
                        this.setState({ 
                            showLoader: false
                            ,alertShow: true
                            ,alertType: "warning"
                            ,alertMsg: follow.meta.message
                        })
                    }
                    else if (follow.meta.status === 201) {
                        this.detailView(id); // closes try
                        this.setState({ showLoader: false, 
                            // chars_left: max_chars 
                        });
                        this.commentsData(id);
                        if (objEmpty(this.state.overDueCount) === false) {
                            this.handleOverDueCount(this.state.filters);
                        }
                        this.handleChangePage(1); // listing call
                    }
                    else {
                        this.detailView(id); // closes try
                        this.setState({
                            showLoader: false,
                            sweetShow: true,
                            type: "error",
                            title: follow.meta.message
                        });
                        this.handleChangePage(1);  // listing call
                    }
                } else {
                    this.setState({
                        alertShow: true,
                        alertType: "error",
                        alertMsg: "Above all * fields are required !!!"
                    })
                }
            }
            else {
                if (this.state.addComment.trim().length) {
                    this.setState({ showLoader: true });
                    let commentReq = {};
                    commentReq.data = {};
                    commentReq.id = leadId;
                    commentReq.data["user_id"] = window.$user.id;
                    commentReq.data["comments"] = this.state.addComment;
                    let commentRes = await postEngageComment(commentReq);
    
                    if (commentRes.meta.status === 201) {
    
                        this.setState({ showLoader: false, 
                            // chars_left: max_chars 
                        });
                        this.commentsData(id);
                    }
                    else {
    
                        this.setState({
                            showLoader: false,
                            sweetShow: true,
                            type: "error",
                            title: commentRes.meta.message
                        });
                    }
                } else {
                    this.setState({
                        alertShow: true,
                        alertType: "error",
                        alertMsg: "Above all * fields are required !!!"
                    })
                }
            }
        /* }
        else {
            this.setState({
                open: true,
                type: "error",
                msg: "Please Enter 200 or Less Character !!!"
            })
        } */
    }

    onChange = (e) => {
        let {name, value} = e.target;
        /* if(name == "addComment"){
            this.setState({
                chars_left: max_chars - value.length
            })
        } */
        this.setState({ 
            [name]: value,
        });
    }

    detailView(id) {

        if (this.state.listDetail == id) {
            this.setState({ listDetail: null })

        } else {
            this.setState({ listDetail: id })
        }

    }

    handleAssingUser = () => {
        if (this.state.ids.length > 0) {
            this.setState({ modalCpUpdateShow: true, AssignUser: 'lead_assign' });
        }
    }

    handleRevertLead = (id) => {
        this.setState({ modalCpUpdateShow: true, reAssign: 'reAssign', reAssignLeadID: id });
    }

    handleUpdateCpModal = () => {
        this.setState({
            modalCpUpdateShow: !this.state.modalCpUpdateShow,
            ids: [],
            AssignUser: null,
            reAssign: null,
            reAssignLeadID: null,
            is_focus: {}
        });
    }

    handleChange = async (index, event, checked) => {
        var joined;
        if (checked) {
            joined = this.state.ids.concat(index);
        } else {
            var idIndex = this.state.ids.indexOf(index);

            if (idIndex !== -1) {
                this.state.ids.splice(idIndex, 1);
                joined = this.state.ids;
            }
        }

        const list = Object.assign({}, this.state.is_focus);

        list['switch-' + index] = checked;

        this.setState({
            is_focus: list,
            ids: joined
        })
    }

    genericGetListData = async (params) => {
        await this.setState({ showLoader: true, showOpenLeadLoader: true });
        var leadDataType = {};
        leadDataType.data = {};


        leadDataType.name = this.state.LeadTab;

        if (leadDataType.name === 'sourcing' || leadDataType.name === 'disinterested' || leadDataType.name === 'reactivation' || leadDataType.name === 'disqualify' || leadDataType.name === 'cross_sale_in' || leadDataType.name === 'cross_sale_out') {

            params["tab"] = leadDataType.name;
        }
        leadDataType.data = params;

        (async () => {
            try {
                let resData = await getListData(leadDataType);
                if (resData.meta.status === 200) {
                    this.setDynamicSwitchState(resData.data['leads']);

                    await this.setState({
                        meta: resData.meta,
                        data: resData.data['leads'],
                        listDetail: -1,
                        showLoader: false
                    })
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false
                    })
                }
                let leadCount = await getListCount(leadDataType);
                if (leadCount.meta.status === 200) {
                    await this.setState({
                        leadCount: leadCount.data,
                        showOpenLeadLoader: false
                    })
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        leadCount: 0,
                        showOpenLeadLoader: false
                    })
                }

            } catch (e) {
                //...handle the error...
            }
            this.setState({ showOpenLeadLoader: false });
        })();
    }

    handleChangePage = async (pageNumber) => {
        this.setState({
            showLoader: true,
            activePage: pageNumber
        })
        var listData = {};
        listData.size = 24;
        listData.pageId = pageNumber;
        Object.assign(listData, this.state.filters)
        try{
            var length = Object.keys(this.state.filters).length
            if ((this.state.role === 'salestl' || this.state.role === 'seniorsalestl' || this.state.role === 'sourcings2' || this.state.role === 'magnet s2') && (this.props.leadInfo === "all" || this.props.leadInfo === "open")) {
                if (length === 0) listData.assign_to = 'myself';
            }
        }
        catch(e){
            console.log(e,"Error for object")
        }
        this.genericGetListData(listData);
    }

    fullLeadDetail = async (id) => {
        this.setState({ showLoader: true });

        (async () => {
            try {
                let resData = await getFullLeadData(id);
                await this.setState({
                    fullDetail: resData,
                    showLoader: false
                });
                // window.scrollTo(0, 0);
            } catch (e) {
                //...handle the error...
            }
        })();
    }

    handleModal = (value) => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    handleReOpenModal = (id) => {
        this.setState({ modalReOpenShow: !this.state.modalReOpenShow, reOpenId: id });
    }

    handleRevertModal = (value, type = null) => {
        let checkFormVal = (typeof value === 'number' && type === null) ? "revertLead" : "crossLead";
        let tempVal = (this.state.revertModalShow == false) ? value : '';
        this.setState({
            revertModalShow: !this.state.revertModalShow,
            revertLead: tempVal,
            formCheck: checkFormVal
        });
    }

    handleLeadModal = (value, type) => {

        this.setState({ modalLeadShow: !this.state.modalLeadShow, fullDetail: {} });
        this.setState({ formType: value });
        this.setState({ formStatus: type });
    }
    handleAddModal = () => {
        this.setState({
            addLeadModalShow: !this.state.addLeadModalShow,
        });
    }
    handleLeadInternalModal = () => {
        this.setState({

            modalLeadInternalShow: !this.state.modalLeadInternalShow,
            fullDetail: {}
        });
    }
    handleAuditModal = () => {
        let detail = (this.state.auditModalShow == false) ? this.state.fullDetail : {};
        this.setState({
            auditModalShow: !this.state.auditModalShow,
            fullDetail: detail,
        });
    }
    handlePopUp = () => {
        if (this.state.reportFltr.follow && this.state.reportFltr.filter.redirect_from === 'report') {
            this.setState(prevState => ({
                showFilter: !prevState.showFilter,
                filters: []
            }));
        } else {
            this.setState(prevState => ({
                showFilter: !prevState.showFilter,
                // selectedFilters: this.state.selectedFilters
            }));

        }
    }

    handleSweetWarn = (value) => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow,
            deleteLead: value
        });
    }

    handleSweet = () => {
        this.setState({ sweetShow: !this.state.sweetShow });
        this.handleFilter();
    }

    handleUpdateUser = (reason) => {

        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow,
        });
        let data = {}
        data['deleted_reason'] = reason
        this.handleDeleteLead(this.state.deleteLead, data);
    }

    handleDeleteLead = async (id, data) => {

        this.setState({ showLoader: true });
        var deleteRes = await deleteLead(id, data);

        if (deleteRes.meta.status === 201) {

            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "success",
                title: "Lead Deactivated successfully!!!"
            });

        } else {

            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "error",
                title: deleteRes.meta.message
            });
        }
    }
    handleAssignTo = (e) => {
        globalFilter['assign_to'] = e.target.value
        delete globalFilter.followup_time
        this.handleFilter(globalFilter)
        this.handleOverDueCount(globalFilter)
    }
    handleAssignToChange = (globalFilter) => {
        if (mySelfRoles.includes(this.state.role) && assignToTabs.includes(this.props.leadInfo)) {
            delete globalFilter.followup_time
            globalFilter["assign_to"] = "myself";
            this.handleFilter(globalFilter)
            this.handleOverDueCount(globalFilter)
        }

    }
    handleFilter = async (filterData = {...this.state.filters}, filteredData = {...this.state.selectedFilters}) => {
        if(filteredData === true){
            let filters = await this.state.filters;
            filters.followup_time = filterData.followup_time;
            Object.assign(filterData,filters);
            filteredData = this.state.selectedFilters; 
        }else if(globalFilter.followup_time){
            filterData.followup_time = globalFilter.followup_time;
        }
        Object.assign(this.state.filters, globalFilter, filterData);
        await this.setState({
            showLoader: true,
            filters: filterData,
            selectedFilters: filteredData,
            activePage: 1
        })
        let listData = {};
        listData.size = 24;
        listData.pageId = 1;

        //if (filterData !== undefined && filterData !== null) { Object.assign(filterData, globalFilter) }
        this.handleOverDueCount(filterData)
        if (filterData?.bucket) { delete filterData?.bucket }
        Object.assign(listData, filterData)
        this.genericGetListData(listData);
    }

    handleUpdateFilter = async () => {
        this.setState({
            showLoader: true,
            fullDetail: {}
        })
        let listData = {};
        listData.size = 24;
        listData.pageId = this.state.activePage;
        Object.assign(listData, this.state.filters);

        this.genericGetListData(listData);
    }

    dateColorCode = (fDate) => {

        if (fDate !== null && fDate?.followup_date) {
            if (moment(fDate.followup_date).isSame(moment(), 'day')) {
                if (moment(fDate.followup_date).isBefore(moment())) {
                    return "today-past-strip";
                } else {
                    return "today-future-strip";
                }
            } else if (moment().diff(fDate.followup_date) >= 0) {
                return "past-strip";
            } else {
                return "future-strip";
            }
        }

        return "default-strip";
    }

    handleDateChange = (key, date) => {
        this.setState({ [key]: date });
    }

    handleCall = async (list) => {
        const { callRoles, role, } = this.state
        let number = this.getNumber(list).split(" ").join("")
        let builder_id = list.Project.builder_id;
        if (callRoles.includes(role)) {
            this.setState({ showLoader: true })

            const { agent_number, object_id, type, user_id } = this.state

            let data = {}

            if (agent_number !== '') {
                data["agent_number"] = agent_number
            }
            data["customer_number"] = number
            data["builder_id"] = builder_id
            if (object_id !== '') {
                data["object_id"] = list.id
            }
            if (type !== '') {
                data["type"] = 1
            }
            if (user_id !== '') {
                data["user_id"] = user_id
            }
            data['stage_id'] = list.ObjectStage.stage_id;
            data["calling_type"] = this.props.leadInfo === "audit" ? CALLER_TYPE.AUDIT : CALLER_TYPE.SALES;
            let resData;
            if(this.props.leadInfo === "audit" || KALEYRA_CLICK_TO_CALL_ROLES.includes(role)){
                resData = await getCallWithKaleyra(data);
            } else {
                resData = await getCallData(data)
            }
            if (resData.meta.status === 201) {
                let message = resData.data?.message
                this.setState({
                    showLoader: false,
                    alertShow: true,
                    alertType: message?.includes('Failed') ? 'warning' : "success",
                    alertMsg: resData.data?.message || "Call placed successfully!",
                });
            } else {
                this.setState({
                    showLoader: false,
                    alertShow: true,
                    alertType: "error",
                    alertMsg: resData.meta?.message || "Sorry something went wrong!",
                });
            }
        }
        else {
            this.setState({ tel: "tel:" + number })
        }
    }

    handleClose = () => {
        this.setState({ alertShow: false });
    }

    getNumber = (data) => {
        let number = (data.Client !== null && data.Client?.country_code !== null && data.Client?.country_code !== '' ? (data.Client?.country_code.includes('+') ? data.Client?.country_code : '+' + data.Client?.country_code) : '+91') + ' ' + (data.Client !== null && data.Client?.number)
        return number
    }
    getStartDateMax = () => {
        let d = new Date();
        d.setDate(d.getDate() + 60);
        return d;
    };

    /* handlePresaleAuditLead = async (e) =>{
        await this.setState({
            presale_audit: e.target.value
        });
        globalFilter["user_group"] = this.state.presale_audit;
        this.handleFilter(globalFilter)
    } */
/*     handleMoreDetails = (idx, id) =>{
        this.setState({
            listDetail: idx 
        });
        this.setState({ current_lead_id: id })
    } */
    handleFullDetail = (id) => { 
        this.handleModal(); 
        this.fullLeadDetail(id);
    }
    handleAuditUpdateModal = (id) =>{
        this.changeAuditUpdateModal();
        this.setState({
            auditUpdateId: id
        })
        this.fullLeadDetail(id);

    }

    changeAuditUpdateModal = () =>{
        let detail = (this.state.auditUpdateModalShow == false) ? this.state.fullDetail : {};
        this.setState({
            auditUpdateModalShow: !this.state.auditUpdateModalShow
            , fullDetail: detail
        })
    }

    handleDuplicateLeadCount = async (id) =>{
        let goToUrl = `${window.location.origin}/#/search/genericSearch/${id}&is_audit_close=0`;
        window.open(goToUrl, "_blank");  
    }
    
    render() {
        const { size, status, } = this.state.meta;
        let { followup_date, commentState, isLoading, commentStatus, sweetWarnShow, sweetShow, type, title, overDueCount, showOpenLeadLoader, alertType, alertMsg, alertShow, role, callRoles
            ,auditUpdateModalShow, auditUpdateId } = this.state;
        // console.log(this.state.filters,">>>filter")
        let count = this.state.leadCount !== 0 ? this.state.leadCount : this.state.meta;
        let leadFullData = this.state.fullDetail;
        const isDesktop = this.state.screenWidth;
        let commentStateData = commentState?.data;
        let commentStateMeta = commentState?.meta;
        // let auditFilter = [{id:"sales", name:"sales"},{id:"presales", name:"presales"}];
        return (
            <Frag>
                <PopUpAlert type={alertType} msg={alertMsg} open={alertShow} handleClose={this.handleClose} hideTime={10000} />
                {isDesktop > 991 ?
                    <div className="content-wrapper">
                        <Loader show={this.state.showLoader} />
                        <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
                        <SweetWarnAlert show={sweetWarnShow} changeSweet={this.handleSweetWarn} message="Lead get deleted permanently!!!" handleUpdateUser={this.handleUpdateUser} closingReason='true' />
                        <div className="p-3 pt-4 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total Leads : </span>
                                {showOpenLeadLoader ?
                                    (<CircularProgress color="secondary" size={15} />
                                    ) :
                                    <span className="">{status === 400 ? "0" : objEmpty(count) === false ? count.count : '0'}</span>
                                }
                            </div>
                            {this.props.phone !== "report" && (
                                <Frag>
                                    {(this.state.role === 'admin' ||this.state.role === 'magnettl'|| this.state.role === 'magnetrm' || this.state.role === 'sourcings2' || this.state.role === 'magnet s2' || this.state.role === 'presalestl' || this.state.role === 'presalehead' || this.state.role === 'salestl' || this.state.role === 'seniorsalestl' || this.state.role === 'salesmanager' || this.state.role === 'pnlhead' || this.state.role === 'saleshead') &&
                                        (this.props.leadInfo == "all" || this.props.leadInfo == "assigned" || this.props.leadInfo == "open" || this.props.leadInfo == "suspect" || this.props.leadInfo == "prospect" || this.props.leadInfo == "decision-delayed" || this.props.leadInfo == "opportunity" || this.props.leadInfo == "pipeline" || this.props.leadInfo == "gross-eoi-application" || this.props.leadInfo == "gross-eoi-non-refundable" || this.props.leadInfo == "booked") &&
                                        <div className="text-secondary" style={assignedTo}>
                                            <FormControl variant="outlined">
                                                <InputLabel id="" variant="outlined">Assigned To</InputLabel>
                                                <Select
                                                    labelId=""
                                                    onChange={this.handleAssignTo}
                                                    id=""
                                                >
                                                    <MenuItem value="myself">Myself</MenuItem>
                                                    <MenuItem value="myteam">MyTeam</MenuItem>
                                                    {(this.state.role === 'pnlhead') &&
                                                        <MenuItem value="presale">Presales</MenuItem>
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                    {/* {this.props.leadInfo == "audit" && 
                                    (
                                    <div className="HeaderDropdown">
                                    <SelectOption
                                        name="presale_audit"
                                        value={presale_audit}
                                        options={auditFilter}
                                        onChange={this.handlePresaleAuditLead}
                                    />
                                    </div>
                                    )} */}
                                    {objEmpty(overDueCount) === false && window.$role !== "magnet" ?
                                        <div className="text-white">
                                            <Tooltip title="Past Overdue">
                                                <span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'past' }),true)} className="overDue border cursor-point mr-2 bg-danger">{overDueCount.past_due}</span>
                                            </Tooltip>
                                            {/*comments removed*/}
                                            <Tooltip title="Future Follow Up">
                                                <span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'future' }),true)} className="overDue border cursor-point mr-2 bg-dark">{overDueCount.future_due}</span>
                                            </Tooltip>
                                            <Tooltip title="Today Past">
                                                <span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'today_past' }),true)} className="overDue border cursor-point mr-2 bg-warning">{overDueCount.today_past_due}</span>
                                            </Tooltip>
                                            <Tooltip title="Today Future">
                                                <span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'today_future' }),true)} className="overDue border cursor-point bg-success">{overDueCount.today_future_due}</span>
                                            </Tooltip>
                                        </div>
                                        :
                                        ''
                                    }
                                </Frag>
                            )}
                            <div className="d-inline ml-3 filterHeader">
                                {this.props.phone !== "report" && (
                                    <>
                                        <span className="filter" onClick={this.handlePopUp}>
                                            <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                        </span>
                                        <FilterDrawer
                                            anchor="right"
                                            open={this.state.showFilter}
                                            onClose={() => this.setState({ showFilter: false })}
                                        >
                                            <div className={"filterMenu" + " " + (this.state.showFilter ? 'filterMenuShow' : '')} ref={node => { this.node = node; }}>
                                                <Filter filters={this.state.selectedFilters} urlPath={this.props.leadInfo} fltr={this.fltr} reportFltr={this.state.reportFltr} filterData={this.handleFilter} changeFilter={this.handlePopUp} filter_type="lead" />
                                            </div>
                                        </FilterDrawer>
                                    </>
                                )}
                                {this.state.role !== "magnetplatinum" && this.state.role !== "closings1" && this.state.role !== "sourcings3" && this.state.role !== "magnet s3" && this.state.role !== "magnet" && this.state.role !== "customerexperiencemanager" && this.state.role !== "magnetrm" && this.state.role !== "mediamanagers" && this.state.role !== 'operationshead' && this.state.role !== 'operationsanalyst' && (this.state.group == 'sales' && this.props.leadInfo == 'reactivation' ? false : true) && (this.state.group == 'presale' && this.props.leadInfo == 'sourcing' ? false : true) && this.props.leadInfo !== 'cross_sale_out' && this.props.leadInfo !== 'closed' && this.props.leadInfo !== 'booked' && this.props.leadInfo !== 'cancel' ?
                                    <span className="assign_rm filter" onClick={this.handleAssingUser}>
                                        <Tooltip title="Assign RM">
                                            <AssignmentIndIcon />
                                        </Tooltip>
                                    </span>
                                    :
                                    ''
                                }
                                {/* comments removed*/}
                                {(this.props.phone !== "report" && this.state.role !== "customerexperiencemanager" && this.state.role !== 'operationsanalyst') && (
                                    <Tooltip title="Add Lead">
                                        <AddCircleOutlineIcon className="addLeadIcon" onClick={this.handleAddModal} />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                        {status === 200 && this.state.data.length ?
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {this.state.data.map((listVal, index) =>
                                            <div className="MainList" key={listVal.id}>
                                                <div className="container-fluid">
                                                    <div className={"ListContent card mb-3 " + this.dateColorCode(listVal.InternalStatusHistory)}>
                                                        <div className="row mb-1">
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">ID </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <span className="valueText">{listVal.id !== null ? listVal.id : '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="font-small text-muted">Assigned Date </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.AssignLead !== null && ("created_at" in listVal.AssignLead) ?
                                                                            <span className="valueText">{!!(listVal.AssignLead) ? changeDate(listVal.AssignLead?.created_at) : '-'}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Project Name</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.Project !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.Project.name}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Status</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.ObjectStage !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.ObjectStage?.Stage?.name}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.role !== "magnet" &&
                                                                <div className="col-md-2 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <span className="text-muted">RM Name</span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            {listVal.AssignLead !== null && listVal.AssignLead.Assignee !== null ?
                                                                                <span className="text-capitalize valueText">{listVal.AssignLead.Assignee.name}</span>
                                                                                :
                                                                                <span className="valueText">-</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Client Name</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.Client ?
                                                                            <span className="text-capitalize valueText">{listVal.Client.name || "-"}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!fieldHiddenSales.includes(role) &&
                                                                <div className="col-md-2 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <span className="text-muted">Source</span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            {listVal.LeadSource !== null ?
                                                                                <span className="text-capitalize valueText">{listVal.LeadSource.name}</span>
                                                                                :
                                                                                <span className="valueText">-</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Follow Up Date</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <span className="valueText">{listVal.InternalStatusHistory !== null ? changeDate(listVal.InternalStatusHistory?.followup_date) : '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Region  </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.Project !== null ?
                                                                            <span className="font-small text-capitalize valueText">{listVal.Project.Region !== null ? listVal.Project.Region.name : '-'}</span>
                                                                            :
                                                                            <span className="font-small">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Lead Activity Status</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.InternalStatusHistory !== null ?
                                                                            <span className="text-capitalize valueText">{!!(listVal.InternalStatusHistory?.LeadInternalStatus) ? listVal.InternalStatusHistory?.LeadInternalStatus.name : '-'}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!fieldHiddenSales.includes(role) && 
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12 fold-lead">
                                                                        <span className="text-muted">Added By</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {listVal.LeadCreator !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.LeadCreator.name}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            <div className="col-md-2 mb-1">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="text-muted">Number</span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {
                                                                            (this.state.role !== "mediamanagers") ?
                                                                                (listVal.Client !== null && listVal.Client.country_code !== null && listVal.Client.country_code !== '' ?
                                                                                    (listVal.Client.country_code.includes('+') ? listVal.Client.country_code : '+' + listVal.Client.country_code) : '+91') + ' ' +
                                                                                (listVal.Client !== null && listVal.Client.number)
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"ShortContent" + " " + (this.state.listDetail === index ? "show" : "hide")}>
                                                            <div className="row mb-1">
                                                                {((this.state.role === "admin" || this.state.role === "salesmanager") && !fieldHiddenSales.includes(role)) &&
                                                                    <div className="col-md-2 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <span className="text-muted">Is Magnet  </span>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <span className="valueText">{listVal.is_magnet === 1 ? 'Yes' : 'No'}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-md-2 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <span className="text-muted">Coming Date</span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <span className="valueText">{listVal.created_at !== null ? changeDate(listVal.created_at) : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*comments removed*/}
                                                                <div className="col-md-2 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <span className="text-muted">Update Date  </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <span className="font-small valueText">{listVal.updated_at !== null ? changeDate(listVal.updated_at) : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <span className="text-muted">Lead Activity Date  </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            {listVal.InternalStatusHistory !== null ?
                                                                                <span className="text-capitalize valueText">{!!(listVal.InternalStatusHistory?.action_date) ? changeDate(listVal.InternalStatusHistory?.action_date) : '-'}</span>
                                                                                :
                                                                                <span className="valueText">-</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*comments removed*/}
                                                                {this.state.role !== "magnet" &&
                                                                    <Frag>
                                                                        <div className="col-md-2 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <span className="text-muted">PreSale RM  </span>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <span className="">
                                                                                        {listVal.PresaleRm !== null ?
                                                                                            <span className="font-small text-capitalize valueText">{listVal.PresaleRm.name}</span>
                                                                                            :
                                                                                            <span className="font-small valueText">-</span>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*comments removed*/}
                                                                    </Frag>
                                                                }

                                                                <div className="col-md-2 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <span className="text-muted">Admin Comments  </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <span className="font-small text-capitalize valueText"> {listVal.admin_message ? listVal.admin_message : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row shortLeadComment mx-0 pt-2 mb-2">
                                                                {isLoading ? (
                                                                    <CircularProgress color="primary" size={20} className="comment_loader" />
                                                                ) : null}
                                                                <div className="col-md-12 text-muted fs-14 text-center">Comments</div>
                                                                {commentStatus === true ?
                                                                    <div className="sortComment filter_lead">
                                                                        {commentStateMeta?.status === 200 && commentStateData?.length ?
                                                                            commentStateData?.map((comment, index) =>
                                                                                <div className="card pt-2 pb-2 mb-2 col-md-12" key={index}>
                                                                                    <div className="container">
                                                                                        <div className="Comment_row row mb-2">
                                                                                            <div className="col-md-6 col-12 pl-2">
                                                                                                <span className="font-small text-muted">User : </span>
                                                                                                <span className="text-capitalize font-small">{comment.User !== null ? comment.User.name : "-"}</span>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-12">
                                                                                                <span className="font-small text-muted">Created Date : </span>
                                                                                                <span className="font-small">{changeDate(comment.created_at)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <div className="col-md-12 pl-2">
                                                                                            <span className="font-small text-muted">Comment : </span>
                                                                                            <span className="fs-14">{comment.comments ? comment.comments : comment.comment}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            <div className="col-md-12 text-center border noComment">
                                                                                <span className="col-md-12 text-muted fs-14">No comments available !!!</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                                <div className="border p-0 commentAdd">
																	{window.$role !== "magnet" && this.state.listDetail === index &&
                                                                        <div className="col-sm-7 FollowupDate px-0">
                                                                            <FormControl>
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <FollowUpControl
                                                                                        required
                                                                                        autoOk={true}
                                                                                        error={this.state.isError ? true : false}
                                                                                        margin="normal"
                                                                                        id={listVal.id}
                                                                                        maxDate={this.getStartDateMax()}
                                                                                        name="followup_date"
                                                                                        value={followup_date}
                                                                                        // initialFocusedDate={handleDefaultPM()}
                                                                                        onChange={(date) => this.handleDateChange('followup_date', date)}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            </FormControl>
                                                                        </div>
                                                                    }
                                                                    <form className="text-center">
                                                                        <TextField
                                                                            name="addComment"
                                                                            // error={chars_left < 0 ? true : false}
                                                                            value={this.state.addComment}
                                                                            className="inputComment"
                                                                            required
                                                                            label="Add comments"
                                                                            multiline
                                                                            onChange={this.onChange}
                                                                            // helperText={chars_left > 0 ? `${chars_left} out of ${max_chars} characters` : `0 out of ${max_chars} characters`}
                                                                            rows={3}
                                                                        />
                                                                        <Button variant="contained" color="primary" className="commentBtn" onClick={() => { this.addCommentsData(listVal.id, listVal.Client?.nationality) }}>
                                                                            Submit
                                                                        </Button>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-md-2  mt-1">
                                                                {(this.props.leadInfo !== "audit" && listVal?.is_audit_new_lead) ?
                                                                    <div className="row">
                                                                        <div className="detailText col-md-12" >
                                                                            <div>
                                                                                <span className="">Audit Lead</span> <VerifiedUserIcon />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ((this.state.role === "admin" || this.state.role === "financehead" || this.state.role === "operationsanalyst" || this.state.role === "operationshead") && this.props.leadInfo === "audit") &&
                                                                    <div className="row">
                                                                        <div className="detailText col-md-12" >
                                                                            <div style={{ cursor: "pointer" }} onClick={() => { this.handleAuditModal(); this.fullLeadDetail(listVal.id) }}>
                                                                                <span className="">Audit Lead</span> <VerifiedUserIcon />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="col-md-2  mt-1">
                                                                <div className="row">
                                                                    <div className="detailText col-md-12">
                                                                        {this.state.listDetail === index
                                                                            ? <div style={{ cursor: "pointer", display: "inline-block" }} onClick={() => this.detailView(index)}><span className="">Less Details</span> <ExpandLessIcon />
                                                                            </div>
                                                                            : <div style={{ cursor: "pointer", display: "inline-block" }} onClick={() => { this.detailView(index); this.commentsData(listVal.id); this.setState({ isError: false }) }}>
                                                                                <span className="">More Details</span>
                                                                                <ExpandMoreIcon />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2  mt-1">
                                                                <div className="row">
                                                                    <div className="detailText col-md-12" >
                                                                        <div className="cursor-pointer" onClick={() => this.handleFullDetail(listVal.id)} data={listVal.id}>
                                                                            <span className="">Full Details</span> <InfoIcon />
                                                                        </div>
                                                                    </div>
                                                                </div>
															</div>
                                                            <div className="col-md-2  mt-1">
                                                                <div className="row">
                                                                    <div className="detailText col-md-12" >
                                                                        <div className="cursor-pointer" data={listVal.id}>
                                                                            <Link target="_blank" to={`/lead/details/${listVal.Client.id}/${listVal.id}`} style={{textDecoration: "none", color: "inherit"}}>
                                                                            <span className="mr-2">
                                                                                Assign History
                                                                            </span>
                                                                                <LaunchIcon />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1 pr-0  mt-1">
                                                                {this.state.role === "magnetadmin" || this.state.role === "magnetpnl" || this.state.role === "saleshead" || this.state.role === "admin" && letterCase(listVal.ObjectStage?.Stage?.name) !== 'closed' ?
                                                                    <div className="detailText" >
                                                                        <Tooltip title="Delete Lead">
                                                                            <div className="delete_Lead"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={(value) => { this.handleSweetWarn(listVal.id) }}
                                                                            >
                                                                                <span className="">Delete</span><DeleteIcon />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                            {/* <div className="col-md-1  mt-1">
                                                                <div className="row">
                                                                    <div className="detailText col-md-12 pr-0">
                                                                        <div style={{ cursor: "pointer" }} onClick={() => { this.handleModal(); this.fullLeadDetail(listVal.id) }} data={listVal.id}>
                                                                            <span className="">Preview</span> <VisibilityIcon />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            {!((this.state.role === "admin" || this.state.role === "financehead") && this.props.leadInfo === "audit") ?
                                                                <div className="col-md-3 d-md-block d-none px-0">
                                                                    <div className="action_icons">
                                                                        {this.state.role !== "magnet" && this.state.role !== "magnettl" && this.state.role !== "magnetrm" && this.state.role !== "mediamanagers" ?
                                                                            <Frag>
                                                                                <Tooltip title="Whatsapp">
                                                                                    <a href={"https://api.whatsapp.com/send?phone=" + (listVal.Client !== null && listVal.Client.country_code !== null && listVal.Client.country_code !== '' ? (listVal.Client.country_code.includes('+') ? listVal.Client.country_code : '+' + listVal.Client.country_code) : '+91') + (listVal.Client !== null && listVal.Client.number) + "&text=Hi!"} className="WhatsAppBG Action d-none d-md-block" target="_blank">
                                                                                        <FontAwesomeIcon icon={faWhatsapp} className="text-white" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                                <Tooltip title={this.getNumber(listVal)}>
                                                                                    <a
                                                                                        href={this.state.tel}
                                                                                        className="callBG Action d-none d-md-block"
                                                                                        target="_blank"
                                                                                        onClick={() => { this.handleCall(listVal) }}>
                                                                                        <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </Frag>
                                                                            :
                                                                            ''
                                                                        }
                                                                        {this.state.role === "admin" || ["mediamanagers", "customerexperiencemanager", "operationsanalyst", "operationshead", "magnet"].indexOf(this.state.role) == -1 && letterCase(listVal.ObjectStage?.Stage?.name, true) !== 'closed' && this.props.leadInfo !== 'cross_sale_out' ?
                                                                            <Frag>
                                                                                <Tooltip title="Update Lead">
                                                                                    <a className="leadDetail leadDetailBg Action" onClick={() => { this.handleLeadModal('1', listVal.ObjectStage?.Stage?.name); this.fullLeadDetail(listVal.id) }}>
                                                                                        <FontAwesomeIcon icon={faEdit} className="text-white" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                                <Tooltip title="Update Activity Status">
                                                                                    <a className="leadInternalStatus leadInternalStatusBg Action" onClick={() => { this.handleLeadInternalModal(); this.fullLeadDetail(listVal.id) }}>
                                                                                        <FontAwesomeIcon icon={faUserEdit} className="text-white" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </Frag>
                                                                            :    
                                                                            (this.props.leadInfo === "audit" &&
                                                                            <Tooltip title="Update Lead">
                                                                                <a className="leadDetail leadDetailBg Action" onClick={() => this.handleAuditUpdateModal(listVal.id) }>
                                                                                    <FontAwesomeIcon icon={faEdit} className="text-white" />
                                                                                </a>
                                                                            </Tooltip>)
                                                                        }
                                                                        {(this.state.role == "admin" || this.state.role == "saleshead" || this.state.role == "pnlhead" || this.state.role == "salesmanager" || this.state.role == "presalehead" || this.state.role == "presalestl" || this.state.role == "salestl" || this.state.role === 'seniorsalestl' || this.state.role == "closings1" || this.state.role == "sourcings2" || this.state.role == "sourcings3" || this.state.role == "magnet s2" || this.state.role == "magnet s3") ?
                                                                            (this.props.leadInfo == "open" || this.props.leadInfo == "suspect" || this.props.leadInfo == "prospect" || this.props.leadInfo == "decision-delayed" || this.props.leadInfo == "opportunity" || this.props.leadInfo == "pipeline" || this.props.leadInfo == "gross-eoi-application" || this.props.leadInfo == "gross-eoi-non-refundable") &&
                                                                            <Tooltip title="Cross Sale Lead">
                                                                                <a className="CrossSale Action" onClick={() => { this.handleRevertModal(listVal.id, 'crossLead'); }}>
                                                                                    <ShuffleIcon />
                                                                                </a>
                                                                            </Tooltip>
                                                                            :
                                                                            ''
                                                                        }
                                                                        {/* comments removed */}
                                                                        {this.state.group == "sales" && this.props.leadInfo == "sourcing" || this.state.group == "presale" && this.props.leadInfo == "reactivation" || (this.state.group == "presale" || this.state.group == "sales") && letterCase(listVal.ObjectStage.Stage.name) !== 'closed' && listVal.ObjectStage.Stage.id !== '1' && listVal.ObjectStage.Stage.id !== '7' && listVal.ObjectStage.Stage.id !== '8' ?
                                                                            (!(this.state.group === "presale" && this.props.leadInfo === "sourcing") && this.props.leadInfo !== 'cross_sale_in' && this.props.leadInfo !== 'cross_sale_out') &&
                                                                            <Tooltip title={this.state.group == "sales" ? "Send for Reactivation" : "Send to Sales Team"}>
                                                                                <a className="revertLead Action"
                                                                                    onClick={(value) => this.handleRevertModal(listVal.id)}
                                                                                >
                                                                                    <RedoIcon className="text-white" />
                                                                                </a>
                                                                            </Tooltip>

                                                                            :
                                                                            ""
                                                                        }
                                                                        {(this.props.leadInfo == "closed" && reOpenRoles.includes(role)) &&
                                                                            <Tooltip title="Re-Open">
                                                                                <a className="revertLead Action"
                                                                                    onClick={(value) => this.handleReOpenModal(listVal.id)}
                                                                                >
                                                                                    <LaunchIcon className="text-white" />
                                                                                </a>
                                                                            </Tooltip>
                                                                        }
                                                                        {(this.props.leadInfo == "closed" && sendToAuditAccess.includes(role) && (listVal?.is_audit_close && listVal?.is_audit_use_lead)) &&
                                                                            <ReopenAudit leadId={listVal.id} getList={this.handleFilter}/>
                                                                        }
                                                                        {this.state.role !== "magnetplatinum" && this.state.role !== "mediamanagers" && this.state.role !== "closings1" && this.state.role !== "sourcings3" && this.state.role !== "magnet s3" && this.state.role !== "magnet" && this.state.role !== "magnetrm" && this.state.role !== "customerexperiencemanager" && this.state.role !== "operationsanalyst" && this.state.role !== "operationshead" && letterCase(listVal.ObjectStage?.Stage?.name) !== 'closed' && (this.state.group == 'sales' && this.props.leadInfo == 'reactivation' ? false : true) && (this.state.group == 'presale' && this.props.leadInfo == 'sourcing' ? false : true) && this.props.leadInfo !== 'cross_sale_out' && this.props.leadInfo !== 'closed' && this.props.leadInfo !== 'booked' && this.props.leadInfo !== 'cancel' ?
                                                                            <Checkbox
                                                                                className="lead_check"
                                                                                value={this.state.is_focus['switch-' + listVal.id] || false}
                                                                                checked={this.state.is_focus['switch-' + listVal.id]}
                                                                                onChange={(event, checked) => this.handleChange(listVal.id, event, checked)}
                                                                                color="primary"
                                                                                name="is_focus"
                                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                            />
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="col-md-3 d-md-block d-none">
                                                                    <div className="action_icons">
                                                                        <Tooltip title={this.getNumber(listVal)}>
                                                                            <a
                                                                                href={this.state.tel}
                                                                                className="callBG Action d-none d-md-block"
                                                                                target="_blank"
                                                                                onClick={() => { this.handleCall(listVal) }}>
                                                                                <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                                                                            </a>
                                                                        </Tooltip>
                                                                        <Tooltip title="Update Lead">
                                                                            <a className="leadDetail leadDetailBg Action" onClick={() => this.handleAuditUpdateModal(listVal.id) }>
                                                                                <FontAwesomeIcon icon={faEdit} className="text-white" />
                                                                            </a>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {!fieldHiddenSales.includes(role) && listVal.lead_percentage && <div className={`percent_bar bg-${listVal?.lead_percentage}`}></div>}
                                                        {(this.props.leadInfo == "audit" && listVal.duplicate_lead_count) ? <div className="audit_count" style={{color : "black" , backgroundColor :"cyan" , cursor:"pointer"}} onClick={() => this.handleDuplicateLeadCount(listVal.Client.id)} >{listVal?.duplicate_lead_count}</div>:""}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count?.count > 24 ?
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count?.count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="main_area_display shadow card p-3">
                                <h5>No Leads Found !!!</h5>
                            </div>
                        }
                    </div>
                    :
                    <div className="content-wrapper">
                        <Loader show={this.state.showLoader} />
                        <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
                        <SweetWarnAlert show={sweetWarnShow} changeSweet={this.handleSweetWarn} message="Lead get deleted permanently!!!" handleUpdateUser={this.handleUpdateUser} closingReason='true' />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Leads : </span>
                                {showOpenLeadLoader ?
                                    (<CircularProgress color="secondary" size={15} />
                                    ) :
                                    <span className="">{status === 400 ? "0" : objEmpty(count) === false ? count.count : '0'}</span>
                                }
                            </div>
                            {this.props.phone !== "report" && (
                                <Frag>
                                    {(this.state.role === 'admin' || this.state.role === 'magnettl'|| this.state.role === 'magnetrm' || this.state.role === 'sourcings2' || this.state.role === 'magnet s2' || this.state.role === 'presalestl' || this.state.role === 'presalehead' || this.state.role === 'salestl' || this.state.role === 'seniorsalestl' || this.state.role === 'salesmanager' || this.state.role === 'pnlhead' || this.state.role === 'saleshead') &&
                                        (this.props.leadInfo == "all" || this.props.leadInfo == "assigned" || this.props.leadInfo == "open" || this.props.leadInfo == "suspect" || this.props.leadInfo == "prospect" || this.props.leadInfo == "decision-delayed" || this.props.leadInfo == "opportunity" || this.props.leadInfo == "pipeline" || this.props.leadInfo == "gross-eoi-application" || this.props.leadInfo == "gross-eoi-non-refundable") &&
                                        <div className="text-secondary col-6 pl-0 mr-5 pr-5">
                                            <FormControl variant="outlined">
                                                <InputLabel id="" variant="outlined">AssignedTo</InputLabel>
                                                <Select
                                                    labelId=""
                                                    onChange={this.handleAssignTo}
                                                    id=""
                                                >
                                                    <MenuItem value="myself">Myself</MenuItem>
                                                    <MenuItem value="myteam">MyTeam</MenuItem>
                                                    {(this.state.role === 'pnlhead') &&
                                                        <MenuItem value="presale">Presales</MenuItem>
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                    {/* {this.props.leadInfo == "audit" && (
                                        <div className="HeaderDropdown">
                                            <SelectOption
                                                name="presale_audit"
                                                value={presale_audit}
                                                options={auditFilter}
                                                onChange={this.handlePresaleAuditLead}
                                            />
                                        </div>
                                    )} */}
                                </Frag>
                            )}
                            <div className="d-inline ml-3 filterHeader">
                                {this.props.phone !== "report" && (
                                    <>
                                        <span className="filter" onClick={this.handlePopUp}>
                                            <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                        </span>
                                        <FilterDrawer
                                            anchor="right"
                                            open={this.state.showFilter}
                                            onClose={() => this.setState({ showFilter: false })}
                                        >
                                            <div className={"filterMenu" + " " + (this.state.showFilter ? 'filterMenuShow' : '')} ref={node => { this.node = node; }}>
                                                <Filter filters={this.state.selectedFilters} urlPath={this.props.leadInfo} fltr={this.fltr} reportFltr={this.state.reportFltr} filterData={this.handleFilter} changeFilter={this.handlePopUp} filter_type="lead" />
                                            </div>
                                        </FilterDrawer>
                                    </>
                                )}
                                {this.state.role !== "magnetplatinum" && this.state.role !== "closings1" && this.state.role !== "sourcings3" && this.state.role !== "magnet s3" && this.state.role !== "magnet" && this.state.role !== "customerexperiencemanager" && this.state.role !== "magnetrm" && this.state.role !== "mediamanagers" && (this.state.group == 'sales' && this.props.leadInfo == 'reactivation' ? false : true) && (this.state.group == 'presale' && this.props.leadInfo == 'sourcing' ? false : true) && this.props.leadInfo !== 'cross_sale_out' && this.props.leadInfo !== 'closed' && this.props.leadInfo !== 'booked' && this.props.leadInfo !== 'cancel' ?
                                    <span className="assign_rm filter" onClick={this.handleAssingUser}>
                                        <Tooltip title="Assign RM">
                                            <AssignmentIndIcon />
                                        </Tooltip>
                                    </span>
                                    :
                                    ''
                                }
                                {(this.props.phone !== "report" && this.state.role !== "customerexperiencemanager" && this.state.role !== 'operationsanalyst') && (
                                    <Tooltip title="Add Lead">
                                        <AddCircleOutlineIcon className="addLeadIcon" onClick={this.handleAddModal} />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                        <div className="headerCount text-center border-top">
                            {objEmpty(overDueCount) === false && window.$role !== "magnet" && this.props.phone !== "report" ?
                                <div className="container">
                                    <div className="row">
                                        <div className="col-3 pr-1">PO<span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'past' }))} className="text-white d-block w-100 overDue border mr-2 bg-danger">{overDueCount.past_due}</span></div>
                                        {/*comments removed*/}
                                        <div className="col-3 px-1">FF<span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'future' }))} className="text-white d-block w-100 overDue border mr-2 bg-dark">{overDueCount.future_due}</span></div>
                                        <div className="col-3 px-1">TP<span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'today_past' }))} className="text-white d-block w-100 overDue border mr-2 bg-warning">{overDueCount.today_past_due}</span></div>
                                        <div className="col-3 pl-1">TF<span onClick={e => this.handleFilter(Object.assign(globalFilter, { 'followup_time': 'today_future' }))} className="text-white d-block w-100 overDue border bg-success">{overDueCount.today_future_due}</span></div>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                        {status === 200 && this.state.data.length > 0 ?
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {this.state.data.map((listVal, index) =>
                                            <div className="MainList" key={listVal.id}>
                                                <div className="container-fluid">
                                                    <div className={"ListContent card mb-3 " + this.dateColorCode(listVal.InternalStatusHistory)}>
                                                        <div className="row mb-1">
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">ID</span>
                                                                    </div>
                                                                    <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                        <span className=" valueText">{listVal.id !== null ? listVal.id : '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">Project Name</span>
                                                                    </div>
                                                                    <div className="col-12 fold-lead">
                                                                        {listVal.Project !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.Project.name}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">RM Name</span>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {listVal.AssignLead !== null && listVal.AssignLead.Assignee !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.AssignLead.Assignee.name}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <span className="font-small text-muted">Assigned Date </span>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <span className="valueText">{!!(listVal.AssignLead) ? changeDate(listVal.AssignLead?.created_at) : '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">Region  </span>
                                                                    </div>
                                                                    <div className="col-12 fold-lead">
                                                                        {listVal.Project !== null ?
                                                                            <span className="font-small text-capitalize valueText">{listVal.Project.Region !== null ? listVal.Project.Region.name : '-'}</span>
                                                                            :
                                                                            <span className="font-small">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">Client Name</span>
                                                                    </div>
                                                                    <div className="col-12 fold-lead">
                                                                        {listVal.Client !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.Client.name || "-"}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">Follow Up Date</span>
                                                                    </div>
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="valueText">{listVal.InternalStatusHistory !== null ? changeDate(listVal.InternalStatusHistory?.followup_date) : '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">Status  </span>
                                                                    </div>
                                                                    <div className="col-12 fold-lead">
                                                                        {listVal.ObjectStage !== null ?
                                                                            <span className="text-capitalize valueText">{listVal.ObjectStage?.Stage?.name}</span>
                                                                            :
                                                                            <span className="valueText">-</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-1">
                                                                <div className="row">
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="text-muted">Number</span>
                                                                    </div>
                                                                    <div className="col-12 fold-lead">
                                                                        <span className="valueText">
                                                                            {
                                                                                (this.state.role !== "mediamanagers") ?
                                                                                    (listVal.Client !== null && listVal.Client.country_code !== null && listVal.Client.country_code !== '' ?
                                                                                        (listVal.Client.country_code.includes('+') ? listVal.Client.country_code : '+' + listVal.Client.country_code) : '+91') + ' ' +
                                                                                    (listVal.Client !== null && listVal.Client.number)
                                                                                    : ''
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!fieldHiddenSales.includes(role) &&
                                                                <div className="col-6 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-12 fold-lead">
                                                                            <span className="text-muted">Source</span>
                                                                        </div>
                                                                        <div className="col-12 fold-lead">
                                                                            {listVal.LeadSource !== null ?
                                                                                <span className="text-capitalize valueText">{listVal.LeadSource.name}</span>
                                                                                :
                                                                                <span className="valueText">-</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {!((this.state.role === "admin" || this.state.role === "financehead") && this.props.leadInfo === "audit") ?
                                                                <div className="action_box">
                                                                    {this.state.role === "admin" || this.state.role !== "mediamanagers" && this.state.role !== "magnet" && this.state.role !== "customerexperiencemanager" && letterCase(listVal.ObjectStage?.Stage?.name, true) !== 'closed' && this.props.leadInfo !== 'cross_sale_out' ?
                                                                        <Frag>
                                                                            {/* <a className="Action leadDetailBg" data-toggle="tooltip" data-placement="bottom" title="Preview" onClick={() => { this.handleModal(); this.fullLeadDetail(listVal.id) }} data={listVal.id}>
                                                                                <VisibilityIcon className="fs-20" />
                                                                            </a> */}
                                                                            <a className="leadDetail leadDetailBg Action" data-toggle="tooltip" data-placement="bottom" title="View detail" onClick={() => { this.handleLeadModal('1', listVal.ObjectStage?.Stage?.name); this.fullLeadDetail(listVal.id) }}>
                                                                                <FontAwesomeIcon icon={faEdit} className="text-white" />
                                                                            </a>
                                                                            <a className="leadInternalStatus leadInternalStatusBg Action" data-toggle="tooltip" data-placement="bottom" title="Update Activity Status" onClick={() => { this.handleLeadInternalModal(); this.fullLeadDetail(listVal.id) }}>
                                                                                <FontAwesomeIcon icon={faUserEdit} className="text-white" />
                                                                            </a>
                                                                        </Frag>
                                                                        :
                                                                        (this.props.leadInfo === "audit" &&
                                                                        <>
                                                                            <Tooltip title="Update Lead">
                                                                                <a className="leadDetail leadDetailBg Action" onClick={() => this.handleAuditUpdateModal(listVal.id) }>
                                                                                    <FontAwesomeIcon icon={faEdit} className="text-white" />
                                                                                </a>
                                                                            </Tooltip>
                                                                            {listVal?.duplicate_lead_count?<div className="audit_count">{listVal?.duplicate_lead_count}</div>:""}
                                                                        </>
                                                                        )
                                                                    }
                                                                    {(this.state.role == "admin" || this.state.role == "saleshead" || this.state.role == "pnlhead" || this.state.role == "salesmanager" || this.state.role == "presalehead" || this.state.role == "presalestl" || this.state.role == "salestl" || this.state.role === 'seniorsalestl' || this.state.role == "closings1" || this.state.role == "sourcings2" || this.state.role == "sourcings3" || this.state.role == "magnet s2" || this.state.role == "magnet s3") ?
                                                                        (this.props.leadInfo == "open" || this.props.leadInfo == "suspect" || this.props.leadInfo == "prospect" || this.props.leadInfo == "decision-delayed" || this.props.leadInfo == "opportunity" || this.props.leadInfo == "pipeline" || this.props.leadInfo == "gross-eoi-application" || this.props.leadInfo == "gross-eoi-non-refundable") &&
                                                                        <Tooltip title="Cross Sale Lead">
                                                                            <a className="CrossSale Action" onClick={() => { this.handleRevertModal(listVal.id, 'crossLead'); }}>
                                                                                <ShuffleIcon />
                                                                            </a>
                                                                        </Tooltip>
                                                                        :
                                                                        ''
                                                                    }
                                                                    {/*comments removed*/}
                                                                    {this.state.group == "sales" && this.props.leadInfo == "sourcing" || this.state.group == "presale" && this.props.leadInfo == "reactivation" || (this.state.group == "presale" || this.state.group == "sales") && letterCase(listVal.ObjectStage.Stage.name) !== 'closed' && listVal.ObjectStage.Stage.id !== '1' && listVal.ObjectStage.Stage.id !== '7' && listVal.ObjectStage.Stage.id !== '8' ?
                                                                        (!(this.state.group === "presale" && this.props.leadInfo === "sourcing") && this.props.leadInfo !== 'cross_sale_in' && this.props.leadInfo !== 'cross_sale_out') &&
                                                                        <Tooltip title={this.state.group == "sales" ? "Send for Reactivation" : "Send to Sales Team"}>
                                                                            <a className="revertLead Action"
                                                                                onClick={(value) => this.handleRevertModal(listVal.id)}
                                                                            >
                                                                                <RedoIcon className="text-white" />
                                                                            </a>
                                                                        </Tooltip>

                                                                        :
                                                                        ""
                                                                    }
                                                                    {(this.props.leadInfo == "closed" && reOpenRoles.includes(role)) &&
                                                                        <Tooltip title="Re-Open">
                                                                            <a className="revertLead Action"
                                                                                onClick={(value) => this.handleReOpenModal(listVal.id)}
                                                                            >
                                                                                <LaunchIcon className="text-white" />
                                                                            </a>
                                                                        </Tooltip>
                                                                    }
                                                                    {(this.props.leadInfo == "closed" && sendToAuditAccess.includes(role) && (listVal?.is_audit_close && listVal?.is_audit_use_lead)) &&
                                                                        <ReopenAudit leadId={listVal.id} getList={this.handleFilter}/>
                                                                    }
                                                                    {this.state.role !== "magnetplatinum" && this.state.role !== "closings1" && this.state.role !== "mediamanagers" && this.state.role !== "sourcings3" && this.state.role !== "magnet s3" && this.state.role !== "customerexperiencemanager" && this.state.role !== "magnet" && this.state.role !== "magnetrm" && letterCase(listVal.ObjectStage?.Stage?.name) !== 'closed' && (this.state.group == 'sales' && this.props.leadInfo == 'reactivation' ? false : true) && (this.state.group == 'presale' && this.props.leadInfo == 'sourcing' ? false : true) && this.props.leadInfo !== 'cross_sale_out' && this.props.leadInfo !== 'closed' && this.props.leadInfo !== 'booked' && this.props.leadInfo !== 'cancel' ?
                                                                        <Checkbox
                                                                            className="lead_check"
                                                                            value={this.state.is_focus['switch-' + listVal.id] || false}
                                                                            checked={this.state.is_focus['switch-' + listVal.id]}
                                                                            onChange={(event, checked) => this.handleChange(listVal.id, event, checked)}
                                                                            color="primary"
                                                                            name="is_focus"
                                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                        />
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="action_box">
                                                                    <Tooltip title="Update Lead">
                                                                        <a className="leadDetail leadDetailBg Action" onClick={() => this.handleAuditUpdateModal(listVal.id) }>
                                                                            <FontAwesomeIcon icon={faEdit} className="text-white" />
                                                                        </a>
                                                                    </Tooltip>
                                                                {(this.props.leadInfo == "audit" && listVal.duplicate_lead_count) ? <div className="audit_count">{listVal?.duplicate_lead_count}</div>:""}
                                                                </div>
                                                            }
                                                            {!fieldHiddenSales.includes(role) && listVal.lead_percentage && <div className={`percent_bar bg-${listVal?.lead_percentage}`}></div>}
                                                        </div>

                                                        <div className={"ShortContent" + " " + (this.state.listDetail === index ? "show" : "hide")}>
                                                            <div className="row">
                                                                {((this.state.role === "admin" || this.state.role === "salesmanager") && !fieldHiddenSales.includes(role)) &&
                                                                    <div className="col-6 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <span className="text-muted">Is Magnet  </span>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <span className="valueText">{listVal.is_magnet === 1 ? 'Yes' : 'No'}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-6 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <span className="text-muted">Coming Date</span>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <span className="valueText">{listVal.created_at !== null ? changeDate(listVal.created_at) : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        {/*comments removed*/}
                                                                        <div className="col-6 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <span className="text-muted">Update Date  </span>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <span className="font-small valueText">{listVal.updated_at !== null ? changeDate(listVal.updated_at) : '-'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {!fieldHiddenSales.includes(role) && 
                                                                        <div className="col-6 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-12 fold-lead">
                                                                                    <span className="text-muted">Added By</span>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    {listVal.LeadCreator !== null ?
                                                                                        <span className="text-capitalize valueText">{listVal.LeadCreator.name}</span>
                                                                                        :
                                                                                        <span className="valueText">-</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                        <div className="col-6 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <span className="text-muted">Lead Activity Status</span>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    {listVal.InternalStatusHistory !== null ?
                                                                                        <span className="text-capitalize valueText">{!!(listVal.InternalStatusHistory?.LeadInternalStatus) ? listVal.InternalStatusHistory?.LeadInternalStatus.name : '-'}</span>
                                                                                        :
                                                                                        <span className="valueText">-</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <span className="text-muted">Lead Activity Date  </span>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    {listVal.InternalStatusHistory !== null ?
                                                                                        <span className="text-capitalize valueText">{!!(listVal.InternalStatusHistory?.action_date) ? changeDate(listVal.InternalStatusHistory?.action_date) : '-'}</span>
                                                                                        :
                                                                                        <span className="valueText">-</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*comments removed*/}
                                                                        <div className="col-6 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-12 fold-lead">
                                                                                    <span className="text-muted">PreSale RM  </span>
                                                                                </div>
                                                                                <div className="col-12 fold-lead">
                                                                                    <span className="text-muted">
                                                                                        {listVal.presalerm !== null ?
                                                                                            <span className="font-small text-capitalize valueText">{listVal.presalerm?.name}</span>
                                                                                            :
                                                                                            <span className="font-small valueText">-</span>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mb-1">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-sm-12 col-12 mb-2">
                                                                                    <span className="text-muted">Admin Comments  </span>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <span className="font-small text-capitalize valueText"> {listVal.admin_message ? listVal.admin_message : '-'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*comments removed*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row shortLeadComment mx-0 pt-2 mb-2">
                                                                {isLoading ? (
                                                                    <CircularProgress color="primary" size={20} className="comment_loader" />
                                                                ) : null}
                                                                <div className="col-md-12 text-muted fs-14 text-center">Comments</div>
                                                                <div className="col-md-12 my-3 p-0">
                                                                    <Paper component="form" className="text-center" variant="outlined" square>
                                                                        {window.$role !== "magnet" && this.state.listDetail === index &&
                                                                            <div className="col-sm-6 FollowupDate mb-2">
                                                                                <FormControl>
                                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <FollowUpControl
                                                                                        required
                                                                                        autoOk={true}
                                                                                        error={this.state.isError ? true : false}
                                                                                        margin="normal"
                                                                                        id={listVal.id}
                                                                                        maxDate={this.getStartDateMax()}
                                                                                        name="followup_date"
                                                                                        value={followup_date}
                                                                                        // initialFocusedDate={handleDefaultPM()}
                                                                                        onChange={(date) => this.handleDateChange('followup_date', date)}
                                                                                    />
                                                                                    </MuiPickersUtilsProvider>
                                                                                </FormControl>
                                                                            </div>
                                                                        }
                                                                        <TextField
                                                                            name="addComment"
                                                                            // error={chars_left < 0 ? true : false}
                                                                            value={this.state.addComment}
                                                                            className="inputComment"
                                                                            required
                                                                            label="Add comments"
                                                                            multiline
                                                                            onChange={this.onChange}
                                                                            // helperText={chars_left > 0 ? `${chars_left} out of ${max_chars} characters` : `0 out of ${max_chars} characters`}
                                                                            rows={1}
                                                                        />
                                                                        <Button variant="contained" color="primary" className="commentBtn" onClick={() => { this.addCommentsData(listVal.id, listVal.Client?.nationality) }}>
                                                                            Submit
                                                                        </Button>
                                                                    </Paper>
                                                                </div>
                                                                {commentStatus === true ?
                                                                    <div className="sortComment filter_lead col-md-12 p-0">
                                                                        {commentStateMeta?.status === 200 && commentStateData?.length ?
                                                                            commentStateData?.map((comment, index) =>
                                                                                <div className="card pt-2 pb-2 mb-2 col-md-12" key={index}>
                                                                                    <div className="container">
                                                                                        <div className="Comment_row row mb-0">
                                                                                            <div className="col-md-2 col-12 p-0">
                                                                                                <span className="font-small text-muted">User : </span>
                                                                                                <span className="text-capitalize font-small valueText">{comment.User !== null ? comment.User.name : "-"}</span>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-12 p-0">
                                                                                                <span className="font-small text-muted">Created Date : </span>
                                                                                                <span className="valueText">{changeDate(comment.created_at)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <div className="col-md-12 p-0">
                                                                                            <span className="font-small text-muted">Comment : </span>
                                                                                            <span className="fs-14">{comment.comments ? comment.comments : comment.comment}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            <div className="col-md-12 text-center border">
                                                                                <span className="col-md-12 text-muted fs-14">No comments available !!!</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </div>
                                                        {((this.state.role === "admin" || this.state.role === "financehead" || this.state.role === "operationsanalyst" || this.state.role === "operationshead") && this.props.leadInfo === "audit") &&
                                                            <div className="row text-center">
                                                                <div className="detailText col-md-12 mb-2" >
                                                                    <div style={{ cursor: "pointer" }} onClick={() => { this.handleAuditModal(); this.fullLeadDetail(listVal.id) }}>
                                                                        <span className="">Audit Lead</span> <VerifiedUserIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="row mb-3">
                                                            <div style={{ cursor: "pointer" }} className="detailText col-md-12 d-flex viewMore align-items-center">
                                                                {this.state.listDetail === index ?
                                                                    <div className="less_Detail" onClick={() => this.detailView(index)}>
                                                                        <span >Less Details</span> <ExpandLessIcon className="fs-20" />
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <span className="" onClick={() => { this.detailView(index); this.commentsData(listVal.id); this.setState({ isError: false }) }}>More Details</span>
                                                                        <ExpandMoreIcon className="fs-20" />
                                                                    </div>
                                                                }
																{/*<div className="pl-2">
                                                                    <span className="" onClick={() => { this.handleModal(); this.fullLeadDetail(listVal.id) }} data={listVal.id}>Full Details</span> <InfoIcon className="fs-20" />
																</div>*/}
																<div className="pl-2" onClick={() => this.handleFullDetail(listVal.id)} data={listVal.id}>
                                                                    <span className="mr-2">
                                                                        Full Details 
                                                                    </span>
                                                                    <InfoIcon />
                                                                </div>
                                                                {this.state.role === "magnetadmin" || this.state.role === "magnetpnl" || this.state.role === "saleshead" || this.state.role === "admin" && letterCase(listVal.ObjectStage?.Stage?.name) !== 'closed' ?
                                                                    <div className="col-3 mt-1 pr-0">
                                                                        <div className="delete_lead fs-12"
                                                                            data-toggle="tooltip" data-placement="bottom" title="Delete Lead"
                                                                            onClick={(value) => { this.handleSweetWarn(listVal.id) }}
                                                                        >
                                                                            Delete <FontAwesomeIcon icon={faTrashAlt} className="fs-14" />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 text-center">
                                                            <div className="detailText col-md-12" >
                                                                <div className="cursor-pointer" data={listVal.id}>
                                                                    <Link target="_blank" to={`/lead/details/${listVal.Client.id}/${listVal.id}`} style={{textDecoration: "none", color: "inherit"}}>
                                                                    <span className="mr-2">
                                                                        Assign History
                                                                    </span>
                                                                        <LaunchIcon />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!((this.state.role === "admin" || this.state.role === "financehead") && this.props.leadInfo === "audit") ?
                                                            <div className="row mb-4">
                                                                {(this.state.role !== "magnet" && this.state.role !== "magnettl" && this.state.role !== "magnetrm" && this.state.role !== "mediamanagers") &&
                                                                    (callRoles.includes(role)) ?
                                                                    <Frag>
                                                                        <div className="col-4 mb-1">
                                                                            <a href={this.state.tel}>
                                                                                <Button variant="contained" color="primary" style={{ width: "100px", }} onClick={() => { this.handleCall(listVal) }}>
                                                                                    <PhoneIcon /><span className="text-capitalize">W-Call</span>
                                                                                </Button>
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-4 mb-1">
                                                                            <a href={"https://api.whatsapp.com/send?phone=" + (listVal.Client !== null && listVal.Client.country_code !== null && listVal.Client.country_code !== '' ? (listVal.Client.country_code.includes('+') ? listVal.Client.country_code : '+' + listVal.Client.country_code) : '+91') + (listVal.Client !== null && listVal.Client.number) + "&text=Hi!"}>
                                                                                <Button variant="contained" color="primary" className="bg-success" style={{ padding: "6px", }} >
                                                                                    <WhatsAppIcon /><span className="text-capitalize">WhatsApp</span>
                                                                                </Button>
                                                                            </a>
                                                                        </div>
                                                                       {this.state.role === "sourcings3" || this.state.role === "magnet s3"? '' :<div className="col-4 mb-1">
                                                                            <a href={"tel:" + this.getNumber(listVal)}>
                                                                                <Button variant="contained" color="primary" style={{ width: "70px", marginLeft: "6px", }}>
                                                                                    <PhoneIcon /><span className="text-capitalize">Call</span>
                                                                                </Button>
                                                                            </a>
                                                                        </div>}
                                                                    </Frag>
                                                                    :
                                                                    <Frag>
                                                                        <div className="col-6 mb-1 pr-2">
                                                                            <a href={"https://api.whatsapp.com/send?phone=" + (listVal.Client !== null && listVal.Client.country_code !== null && listVal.Client.country_code !== '' ? (listVal.Client.country_code.includes('+') ? listVal.Client.country_code : '+' + listVal.Client.country_code) : '+91') + (listVal.Client !== null && listVal.Client.number) + "&text=Hi!"}>
                                                                                <Button variant="contained" color="primary" className="w-100 bg-success">
                                                                                    <WhatsAppIcon /><span className="text-capitalize">WhatsApp</span>
                                                                                </Button>
                                                                            </a>
                                                                        </div>
                                                                        {this.state.role === "sourcings3" || this.state.role === "magnet s3" ? '' : <div className="col-6 mb-1 pl-2">
                                                                            <a href={this.state.tel}>
                                                                                <Button variant="contained" color="primary" className="w-100 " onClick={() => { this.handleCall(listVal) }}>
                                                                                    <PhoneIcon /><span className="text-capitalize">Call</span>
                                                                                </Button>
                                                                            </a>
                                                                        </div> }
                                                                    </Frag>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="row mb-4">
                                                                <Frag>
                                                                    <div className="col-6 mb-1">
                                                                        <a href={this.state.tel}>
                                                                            <Button variant="contained" color="primary" onClick={() => { this.handleCall(listVal) }} className="w-100">
                                                                                <PhoneIcon /><span className="text-capitalize">W-Call</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-6 mb-1">
                                                                        <a href={"tel:" + this.getNumber(listVal)}>
                                                                            <Button variant="contained" color="primary" className="w-100">
                                                                                <PhoneIcon /><span className="text-capitalize">Call</span>
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                </Frag>
                                                            </div>    
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count?.count > 24 ?
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count?.count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="main_area_display errorMsg shadow card p-3">
                                <h5>No Leads Found !!!</h5>
                            </div>
                        }
                    </div>
                }
                <Footer expand={this.props.expand} />

                {objEmpty(this.state.fullDetail) === false &&

                    <LeadUpdateModel show={this.state.modalLeadShow} fromType={this.state.formType} getList={this.handleFilter} formStatus={this.state.formStatus} changeModal={this.handleLeadModal} leadDetail={this.state.fullDetail} />
                }
                {objEmpty(this.state.fullDetail) === false &&
                    <LeadInternalState show={this.state.modalLeadInternalShow} changeModal={this.handleLeadInternalModal} getList={this.handleFilter} leadDetail={this.state.fullDetail} />
                }
                {objEmpty(this.state.fullDetail) === false &&
                    <AuditLeadModal show={this.state.auditModalShow} changeModal={this.handleAuditModal} getList={this.handleFilter} leadDetail={this.state.fullDetail} />
                }
                {leadFullData.meta &&
                    <DetailLead show={this.state.modalShow} changeModal={this.handleModal} leadDetailProp={leadFullData} />
                }
                <AssignModalForm show={this.state.modalReOpenShow} changeModal={this.handleReOpenModal} leadId={this.state.reOpenId} getList={this.handleUpdateFilter} />
                <ModalAssignRM show={this.state.modalCpUpdateShow} reAssign={this.state.reAssign} getList={this.handleUpdateFilter} reAssignLeadID={this.state.reAssignLeadID} changeModal={this.handleUpdateCpModal} handleInterestUser={this.state.ids} from={this.state.AssignUser} checkSameNumberLeads={this.state.checkSameNumberLeads} directAssign={true} leadAssign={true} />
                <LeadModel show={this.state.revertModalShow} from={this.props.leadInfo} leadId={this.state.revertLead} getList={this.handleFilter} fromType={this.state.formCheck === 'crossLead' ? 'crossLead' : 'revertLead'} changeModal={this.handleRevertModal} />
                <AddLeadModal show={this.state.addLeadModalShow} from={this.props.leadInfo} leadId={this.state.revertLead} getList={this.handleFilter} fromType="addFbAdLead" changeModal={this.handleAddModal} />
                {objEmpty(this.state.fullDetail) === false &&
                <AuditUpdateModal show={auditUpdateModalShow} changeModal={this.changeAuditUpdateModal} leadId={auditUpdateId} getList={this.handleFilter} leadDetailProp={leadFullData}/>
                }
                </Frag>
        );
    };
};

export default withRouter(magnetList);
