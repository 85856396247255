
import React, { useState } from 'react'
import { FileCopyOutlined } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button } from '@material-ui/core';

const CopyText = (props) => {
    const [copyText, setCopyText] = useState(false)
    return (
        <CopyToClipboard text={props.text} onCopy={() => setCopyText(true)}>
            <Tooltip title={copyText ? "Copied" : "Copy My VCard"}>
                <Button variant="contained" color="primary"><FileCopyOutlined /></Button>
                {/* <a href={props.text} target="_blank" type="submit" className="btn btn-success"><FileCopyOutlined /></a> */}
            </Tooltip>
        </CopyToClipboard>
    )
}
export default CopyText
