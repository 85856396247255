import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import AssignRmForm from "../form/form.js";


class BasicForm extends Component {
	constructor(props){
		super(props)
		this.state = {
			directAssign:this.props.hasOwnProperty('directAssign')? this.props.directAssign :true
		}
	}
	render() {
		return (
			<Frag>
				<Modal
					show={this.props.show}
					onHide={this.props.changeModal}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="unselectable"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{this.props.reAssign === 'reAssign' ? 'Revert Lead' : 'Assign User'}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AssignRmForm handleInterestUser={this.props.handleInterestUser} reAssign={this.props.reAssign} reAssignLeadID={this.props.reAssignLeadID} getList={this.props.getList} userId={this.props.userId} userEmail={this.props.userEmail} changeModal={this.props.changeModal} from={this.props.from} directAssign={this.state.directAssign} checkSameNumberLeads={this.props.checkSameNumberLeads} leadAssign={this.props.leadAssign}/>
					</Modal.Body>

				</Modal>
			</Frag>
		);
	};
};

export default BasicForm;