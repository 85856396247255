import { Button } from '@material-ui/core'
import React from 'react'

export const PreviewButton = ({onClick,btnText}) => {
    return (
        <Button
            className="text-capitalize py-1 mb-2"
            variant="outlined"
            size="small"
            color="primary"
            onClick={onClick}
            style={{color: "#0E3746"}}
        >
            {btnText}
        </Button>
    )
}
