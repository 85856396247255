import React from 'react';
import {AMAZON_URL} from "../../../config/serverKey.js";
import {Card} from 'react-bootstrap';

const Cards=()=>{
	return (
	<>		
		<div className="container-fluid bg-light">
		<h3	 className="text-center">Here's how it works</h3>
			<div className="row m-md-5 text-center">
				<div className="col-md-4 px-4">
					<Card className="shadow p-3 mb-5 bg-white rounded m-0">
					  <Card.Img src={(AMAZON_URL)+"/SpreadTheWord.svg"} />
					  <Card.Body>
					    <Card.Title>Invite your friends OR colleagues by sharing your unique code</Card.Title>
					  </Card.Body>
					</Card>
				</div>
				<div className="col-md-4 px-4">
					<Card className="shadow p-3 mb-5 bg-white rounded m-0">
					  <Card.Img src={(AMAZON_URL)+"/HelpFriend.svg"} />
					  <Card.Body>
					    <Card.Title>They enroll themselves and get their first booking</Card.Title>
					  </Card.Body>
					</Card>
				</div>
				<div className="col-md-4 px-4">
					<Card className="shadow p-3 mb-5 bg-white rounded m-0">
					  <Card.Img src={(AMAZON_URL)+"/Reward YourSelf.svg"} />
					  <Card.Body>
					    <Card.Title>You earn ₹7500* cash on their  booking</Card.Title>
					  </Card.Body>
					</Card>
				</div>
			</div>
		</div>
	</>
	)

}
export default Cards;