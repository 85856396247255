const slideData = [
    {
        id: 1,
        slideImg: "technology",
        title: "Technology - CRM",
        description: "Your one-step solution for all ongoing leads. Manage, monitor, and measure your lead flow and conversations from one dashboard"
    },
    {
        id: 2,
        slideImg: "support",
        title: "Sale Support",
        description: "From Lead prospecting, nurturing, engaging, and closing, we support you at each step of the funnel and ensure you make bookings."
    },
    {
        id: 3,
        slideImg: "globe",
        title: "Wider Reach",
        description: "Reach to multiple cities and leverage clients with different budgets, configurations and have increased choice of builders."
    },
    {
        id: 4,
        slideImg: "training",
        title: "Trainings",
        description: "Keeping your updates with industry trends, upcoming projects, negotiation methods and so much more"
    },
    {
        id: 5,
        slideImg: "kingdom", 
        title: "Payouts",
        description: "Enjoy higher than usual payouts because of a higher number of booking and expect your payments to be cleared sooner"
    },
]

const mgHeader = [
    {
        link: "Features", 
        heading: "Features"
    },
    {
        link: "WhyMagnetSection", 
        heading: "Why Magnet"
    },
    {
        link: "HowItWorks", 
        heading: "How It Works"
    },
    {
        link: "Reviews", 
        heading: "Testimonials"
    },
]
const MAGNET_APP_URL = "https://www.mymagnet.io"
export {slideData, mgHeader, MAGNET_APP_URL};