import React from 'react'
import { Button } from '@material-ui/core'

const InvestNow = ({disabled, onClick}) => {
    return (
        <div>
            <Button 
                variant="contained" 
                color="primary" 
                className="w-100 mx-auto"
                disabled={disabled ? disabled : false}
                onClick={onClick}
            >
                <span className="text-capitalize">Invest Now</span>
            </Button>
        </div>
    )
}

export {InvestNow}
