import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngry, faSmile
    // , faGrinAlt, faSurprise, faSadTear, faFlushed
    , faFrown
}
    from "@fortawesome/free-solid-svg-icons";
import { getUserFeedback } from "../../../dataParser/commomDataApi";
import PopUpAlert from "../sweetAlert/popUpAlert"
import { Tooltip } from "@material-ui/core";
// import Button from '@material-ui/core/Button'
import Loader from '../loader/loader';

const emojis = [
    { id: 1, name: faAngry, title: "Angry" }
    , { id: 3, name: faFrown, title: "Sad" }
    // , { id: 4, name: faFlushed, title: "Bored" }
    // , { id: 5, name: faSadTear, title: "Sick" }
    , { id: 7, name: faSmile, title: "Happy" }
    // , { id: 9, name: faSurprise, title: "Surprised" }
    // , { id: 10, name: faGrinAlt, title: "Excited" }
]

const FeedbackRating = ({ user }) => {
    const [state, setState] = useState({
        rating: null
        , showEmoji: false
        , showLoader: false
    })

    useEffect(() => {
        setTimeout(() => setState({
            ...state
            , showEmoji: true
        }), 6000);
    }, [])

    const handleClick = (id) => {
        setState({
            ...state
            , rating: id
        })
    }

    const handleFeedback = async () => {
        setState({ ...state, showLoader: true })
        let data = {};
        data["user_rating"] = state.rating;
        if (state.rating) {
            let getRes = await getUserFeedback(data);
            if (getRes.meta.status === 200) {
                setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    alertType: "success",
                    alertMsg: "Thanks For Your Feedback!!!"
                })
            }
            else if (getRes.meta.status === 409) {
                setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    alertType: "warning",
                    alertMsg: "Feedback Already Exist!!!"
                })
            }
            else {
                setState({
                    ...state,
                    showLoader: false,
                    alertShow: true,
                    alertType: "error",
                    alertMsg: "Something Went Wrong !!!"
                })
            }
            localStorageSet()
        }
        else {
            setState({
                ...state,
                showLoader: false,
                alertShow: true,
                alertType: "error",
                alertMsg: "Express Your Feeling!!!"
            })
        }
    }
    const localStorageSet = () => {
        let homesfy_lg = localStorage.getItem("homesfy_lg");
        let user = JSON.parse(window.atob(homesfy_lg));
        user.is_user_rating = true;
        window.$user = user;
        localStorage.removeItem("homesfy_lg");
        localStorage.setItem("homesfy_lg", window.btoa(JSON.stringify(user))
        );
    }
    const handleClose = () => {
        setState({ ...state, alertShow: false })
    }
    const { showLoader, alertShow, alertType, alertMsg, showEmoji } = state;
    return (
        <>
            {(window.$user?.role !== "magnet" && window.$user?.is_user_rating == false && showEmoji == true) &&
                <div className="RatingSection">
                    <div className="card py-1 mb-2 d-flex flex-column justify-content-center slide-left ">
                        <p className="mb-2 text-center">How are you feeling today ???</p>
                        <div className="TodaysFeeling d-flex justify-content-center">
                            {
                                emojis.map((e, i) => (
                                    <Tooltip key={e.id} title={e.title} placement="top" arrow className="Feeling text-capitalize cursor-pointer" onClick={() => handleClick(e.id)}>
                                        <label>
                                            <input type="radio" name="rating" value={e.id}
                                                onClick={() => handleFeedback()}
                                            />
                                            <FontAwesomeIcon
                                                icon={e.name}
                                                className="feedback px-1 mx-1"
                                                size="3x"
                                            />
                                        </label>
                                    </Tooltip>
                                ))
                            }
                        </div>
                        {/* <div className="row justify-content-center">
                            <Button
                                variant="contained"
                                className="text-capitalize"
                                onClick={() => handleFeedback()}
                                style={{ backgroundColor: '#0E3746', color: '#fff' }}
                            >
                                Send Feedback
                            </Button>
                        </div> */}
                    </div>
                </div>
            }
            <PopUpAlert type={alertType} msg={alertMsg} open={alertShow} hideTime={2000} handleClose={handleClose} />
            <Loader show={showLoader} />
        </>
    )
}

export default FeedbackRating
