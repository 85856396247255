import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Frag from '../../../utils/Frag/Frag';
import { emailSupport } from '../datas/data';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const CommonMails = () => {
    const teamSupport = window.$user.role === "magnet" ? emailSupport.filter(x=>x.name === "Magnet") : emailSupport.filter(x => x.name !== "Magnet")
    
    return (

        <div className="row mb-4">
            <div className="col-12">
                <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Team</StyledTableCell>
                                <StyledTableCell>Emails</StyledTableCell>
                                <StyledTableCell>Kind of issues begin handled</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamSupport.map((o, key) => (
                                <Frag>
                                    <TableRow key={key}>
                                        <TableCell className="text-capitalize">{o.name}</TableCell>
                                        <TableCell>{o.id}</TableCell>
                                        <TableCell>{o.issues}</TableCell>
                                    </TableRow>
                                </Frag>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2}><b>Note: </b><small>Connect with given mail ids for specific concern/queries.</small></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>

    )
}

export default CommonMails
