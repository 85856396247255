import React, { useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { BULK_FILE_STATUS } from '../../../config/constant';
import Modal, { ModalTitle, ModalBody } from '../../../core/Dialog'
import { updateBulkFiles } from '../../../dataParser/bulkLeadData';
import SelectOption from '../controls/SelectOption';
import InputField from '../controls/InputField';
import Loading from '../loader/loader';
import PopUpAlert from '../sweetAlert/popUpAlert';

const numericFields = [
    , "no_calls_connected"
    , "no_leads_qualified"
]
const BulkFileStatusModal = (props) => {
    const { show, changeModal, fullData, getListingData } = props;

    const [state, setState] = useState({
        status: ""
        , showLoader: false
        , alertShow: false
        , alertType: "success"
        , alertMsg: ""
        , no_calls_connected: null
        , no_leads_qualified: null

    })
    const { status, alertShow, alertType, alertMsg, showLoader, no_leads_qualified, no_calls_connected } = state;
    useEffect(() => {
        setState({
            ...state
            , status: fullData?.status || ""
            , no_calls_connected: fullData?.no_calls_connected || null
            , no_leads_qualified: fullData?.no_leads_qualified || null
        })
    }, [])
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (numericFields.includes(name)) value = value.replace(/\D/g, "")
        setState({
            ...state
            , [name]: value
        })
    }
    const handleClose = () => {
        setState({
            ...state,
            alertShow: false
        })
        changeModal();
    }
    const handleUpdateStatus = async (id) => {
        setState({
            ...state
            , showLoader: true
        })
        let mainData = {}
        mainData.id = fullData.id;
        mainData.data = {}
        if(status) mainData.data.status = status;
        if(no_calls_connected) mainData.data.no_calls_connected = no_calls_connected;
        if(no_leads_qualified) mainData.data.no_leads_qualified = no_leads_qualified;
        let getRes = await updateBulkFiles(mainData);
        if (getRes.meta.status === 201) {
            setState({
                ...state
                , showLoader: false
                , alertShow: true
                , alertType: "success"
                , alertMsg: getRes.data.message
            })
        }
        else {
            setState({
                ...state
                , showLoader: false
                , alertShow: true
                , alertType: "error"
                , alertMsg: getRes.meta.message
            })
        }
        getListingData();
    }
    return (
        <>
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleClose} />
            <Loading show={showLoader} />
            <Modal
                show={show}
                onHide={changeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalTitle id="contained-modal-title-vcenter" onHide={changeModal} >
                    Update Bulk File Status
                </ModalTitle>
                <ModalBody>
                    <SelectOption
                        label="Select Status"
                        name="status"
                        value={status}
                        options={BULK_FILE_STATUS}
                        onChange={handleChange}
                    />
                    {
                        // status === "Calling Completed" &&
                        <>
                            <FormControl className='mt-3'>
                                <InputField
                                    name="no_calls_connected"
                                    value={no_calls_connected}
                                    label="No. of Calls Connected"
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl className='mt-3'>
                                <InputField
                                    name="no_leads_qualified"
                                    value={no_leads_qualified}
                                    label="Leads Qualified"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </>
                    }

                    <Button onClick={handleUpdateStatus} variant="contained"
                        color="primary"
                        className="w-100 mx-auto mt-3">Submit</Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BulkFileStatusModal