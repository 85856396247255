import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import AddRmForm from '../form/addRmForm';

export default class AddRmModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    getData=(val)=>{
        this.props.getData(val)
    }    
    render() {
        return (
            <div>
                <Modal 
                    show={this.props.show}
                    onHide={this.props.changeModal}
                    centered
					className="unselectable"
					aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add RM
						</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddRmForm changeModal={this.props.changeModal} sendData={this.getData} isMagnet={this.props.isMagnet} projectInfo={this.props.projectInfo} auto_assign_data={this.props.auto_assign_data} index={this.props.index} fullDetail={this.props.getDetails}/>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
