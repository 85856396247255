const national = [
	{ id: 1, name: 'Indian' },
	{ id: 2, name: 'NRI' }
]

const ivrStatus = [
	{ id: 0, name: 'In-Active' },
	{ id: 1, name: 'Active' }
]

const isMagnet = [
	{ id: 0, name: 'No' },
	{ id: 1, name: 'Yes' }
]

const facebookPlatform = [
	{ id: '10', name: 'Facebook' },
	{ id: '12', name: 'Instagram' },
	{ id: '37', name: 'FB-WhatsApp' },
	{ id: '39', name: 'FB-Comments' }
]

const googlePlatform = [
	{ id: '31', name: 'Website' },
	{ id: '5', name: 'Chat' },
]

const yesNoType = [
	{ id: 1, name: "Yes" },
	{ id: 0, name: "No" }
]

const primary = [
	{ name: 'New Launches', id: "1" },
	{ name: 'Under Construction', id: "2" },
	{ name: 'Resale', id: "3" },
	{ name: 'RTMI', id: "4" },
	{ name: 'Rental', id: "5" },
]
const operate = [
	{ name: 'Website', id: "1" },
	{ name: 'Facebook', id: "2" },
	{ name: 'Instagram', id: "3" },
	{ name: 'LinkedIn.', id: "4" },
	{ name: 'Twitter', id: "5" },
	{ name: 'Others', id: "6" },
]
const leads = [
	{ name: 'Reference', id: "1" },
	{ name: 'Purchase Data', id: "2" },
	{ name: 'Digital Leads', id: "3" },
]
const alternateBusiness = [
	{ name: 'Resale', id: "1" },
	{ name: 'RTMI', id: "2" },
	{ name: 'Rental', id: "3" },
]
const resaleRental = [
	{ name: 'Resale', id: "1" },
	{ name: 'Rental', id: "2" },
	{ name: 'Primary', id: "3" },
]
const mgOperateIn = [
	{ name: "Resale", id: "Resale" }
	, { name: "Rental", id: "Rental" }
	, { name: "Both", id: "Both" }
	, { name: "None", id: "" }
]
const businessTime = [
	{ id: "Full Time", name: "Full Time" },
	{ id: "Part Time", name: "Part Time" }
]
const businessOperate = [
	{ id: "Sole Prop", name: "Sole Prop" },
	{ id: "Partnership", name: "Partnership" },
	{ id: "Pvt Ltd", name: "Pvt Ltd" },
	{ id: "Public Ltd", name: "Public Ltd" },
	{ id: "Others", name: "Others" }
]
const leadType = [
	{ id: "assign", name: "Assign" },
	{ id: "sourcing", name: "Sourcing" },
	{ id: "reactivation", name: "Reactivation" }
]

const campaignType = [
	{ id: 1, name: 'Google Ads' },
	{ id: 2, name: 'FB Ads' },
	{ id: 3, name: 'SMS' },
	{ id: 4, name: 'Portals' },
	{ id: 5, name: 'Emails' }
];

const emailSupport = [
	{ name: "Accounts", id: "queryaccounts@homesfy.in", issues: "Issues with respect to Incentives, Payments to Vendors/Magnets, collections etc" }
	, { name: "Operations", id: "queryops@homesfy.in", issues: "Issues related to audit, post-sales, telephone(Salestrail/Knowlarity) etc" }
	, { mgSee: "no", name: "Admin", id: "queryadmin@homesfy.in", issues: "Issues related to laptops, sim-cards, cab, office-premise issues etc" }
	, { mgSee: "no", name: "HR", id: "queryhr@homesfy.in", issues: "Issues with respect to HR - Salaries, Incentives, letters, growth path etc" }
	, { name: "Digital", id: "querydigital@homesfy.in", issues: "Issues with respect to campaigns, project updates, factsheets, whatsapp bot etc" }
	, { name: "CRM", id: "querycrm@homesfy.in", issues: "Issues that are technical in nature related to CRM, Magnet Platform, Integrations etc." }
	, { mgSee: "no", name: "Magnet", id: "partnersupport@mymagnet.io", issues: "Issues that are related to CRM, Leads status, Digital Campaign payments etc" }
]
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "center"
	},
	variant: "menu"
};
const documentStatus = [
	{id: -1, name: "Not Upload"}
	, {id: 0, name: "Pending"}
	, {id: 1, name: "Approved"}
	, {id: 2, name: "Reject"}
]
const othersType = [
	{id:"faq" , name: "Faq"},
	{id:"location_highlight" , name: "Location Highlight"},
	{id:"project_highlight" , name: "Project Highlight"},
]
export { national, ivrStatus, isMagnet, facebookPlatform, googlePlatform, yesNoType, primary, operate, leads, alternateBusiness, businessTime, businessOperate, leadType, campaignType, emailSupport, MenuProps, mgOperateIn, resaleRental, documentStatus, othersType }
