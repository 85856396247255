import React, {Component} from 'react'
import AddProject from '../../component/project/addProject'

class AddProjectPage extends Component {

	constructor(props){
		super(props)
		this.state={
			projectId: this.props.location?.state?.projectId,
			fullDetail: this.props.location?.state?.fullDetail?.data,
			filterData: this.props.location?.state?.filterData,
			mgProjStatus: this.props.location?.state?.request
		}
	}

	render(){
		const {projectId, fullDetail, filterData, mgProjStatus} = this.state;
		return (
			<div
				className={`bookings-container content-wrapper ${
				this.props.sideBar ? "main-header-collapsed" : ""
					}`}
			>
				<AddProject updateProjectId={projectId} fullDetail={fullDetail} filterData={filterData} mgProjStatus={mgProjStatus}/>
			</div>
		)
	}
}

export default AddProjectPage

