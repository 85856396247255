import React from 'react';
import Box from '../../core/Box';
import Grid from '../../core/Grid';
import {FormikTextField} from '../../core/TextField';
import Typography from '../../core/Typography';
import { Formik, Form } from 'formik';
import api from '../../api';
import moment from 'moment';

const BookingPage = ({lead, profile, handleBookingChange }) => {
    const [state, setState] = React.useState({lead, profile});

    React.useEffect(() => {
        setState({...state,profile, lead, isEditable: false})
    }, [profile, lead])

    return <Box className="BookingPage">
        <Formik enableReinitialize initialValues={{
            booking_amount: state.lead.Booking?.booking_amount,
            booking_amount_detail: state.lead.Booking?.booking_amount_detail,
            booking_date: state.lead?.Booking?.booking_date,
            booking_in_project: state.lead.Booking?.BookingProject,
            booking_rm: state.lead.AssignLead?.Assignee,
            building_number: state.lead.Booking?.building_number,
            closing_rm: state.lead?.PresaleRm,
            flat_no: state.lead.Booking?.flat_no,
            flat_type: state.lead.Booking?.flat_type,
            saleable_area: state.lead.Booking?.carpet_area,
            special_discount: state.lead.Booking?.special_discount,
        }} 
            validate= {values => {
            }}
            onSubmit= {(values, {resetForm})=> {
                const payload = {
                    Client:{
                        name: state.lead.Client.name,
                        id: state.lead.Client.id,
                    },
                    Booking:{ 
                        booking_amount: Number(values.booking_amount),
                        booking_amount_detail: values.booking_amount_detail?.toString(),
                        booking_date: moment(values.booking_date).format(),
                        booking_project_id: Number(values.booking_in_project?.id),
                        booking_rm_id: Number(values.booking_rm?.id),
                        building_number: values.building_number?.toString(),
                        carpet_area: values.saleable_area?.toString(),
                        flat_no: values.flat_no?.toString(),
                        flat_type: values.flat_type?.toString(),
                        special_discount: Number(values.special_discount),
                        presale_rm: Number(values.closing_rm.id)
                    }
                    , ObjectStage: {
                        "stage_id": lead.ObjectStage.stage_id
                    }
                }

                api.put(`/leads/${lead.id}`, payload).then(res => {
                    /*
                    * This will show newly updated data from state loaded from handleBookingChange()
                    */
                    resetForm({})

                    handleBookingChange()
                })
                //*/
            }}
        >
            <Form autoComplete="off">
               <Box pl={2}><Typography variant="h5">Booking</Typography></Box>
                <Grid container spacing={4}>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}>
                            <FormikTextField
                                isEditable={state.isEditable}
                                isAutocomplete
                                optionPresenter={ option => option.name }
                                callBackUrl={query => `/users/auto_complete?q=${query}&roles=5,6,7,8,10,13,14,15,20,21`}
                                autoCompleteText={option => option?.name}
                                label="Booking RM"
                                name="booking_rm" /> 
                        </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Book Amount Detail" name="booking_amount_detail" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Building/ Wing No." name="building_number" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Saleable Area" name="saleable_area" /> </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}>
                            <FormikTextField
                                isEditable={state.isEditable}
                                isAutocomplete
                                optionPresenter={ option => option.name }
                                callBackUrl={query => `projects/auto_complete/?q=${query}`}
                                autoCompleteText={option => option?.name}
                                label="Booking In Project"
                                name="booking_in_project" /> 
						</Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Booking Amount" name="booking_amount" isMoney /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Flat No." name="flat_no" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Booking Date" name="booking_date" isDateTime/> </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> 
                            <FormikTextField
                                isEditable={state.isEditable}
                                isAutocomplete
                                optionPresenter={ option => option.name }
                                callBackUrl={query => `/users/auto_complete?q=${query}&roles=5,6,7,8,10,13,14,15,20,21`}
                                autoCompleteText={option => option?.name}
                                label="Closing RM"
                                name="closing_rm" /> 
						</Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Flat Type" name="flat_type" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Special Discount" name="special_discount" /> </Box>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    </Box>
}


export default BookingPage;
