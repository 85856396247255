import React from 'react'
import { ReportError } from '../../component/reportError/ReportError'

export const RerportErrorPage = (props) => {
    return (
        <div className={`bookings-container content-wrapper ${
            props.sideBar ? "main-header-collapsed" : ""
            }`}
        >
            <ReportError />
        </div>
    )
}
