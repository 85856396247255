import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCheckCircle,
  faMoneyBill,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import {
  getBookingLeads,
  getBookingLeadsData,
  filterBooking,revertCancallation
} from "../../dataParser/getListUserData";
import { changeDate, indianRupeeCommaSeparated } from "../../helper";
import BookingDocumentsModal from "./bookingDocumentsModal";
import Loader from "../../component/common/loader/loader";
import BookingIncentive from "./bookingIncentiveModal";
import UpdateRm from "./updateRmModal";
import BookingFilter from "./bookingFilter";
import ListingView, { OneSixthField } from "../common/listing/ListingView";
import PopUpAlert from "../common/sweetAlert/popUpAlert";
import MagnetBrokerageModal from "../booking/MagnetBrokerageModal";
import SweetWarnAlert from "../../component/common/sweetAlert/sweetAlertWarning";

const common_roles = ["admin", "financehead", "financeassociate"];
const presale_incentive_access_roles = [...common_roles, "operationshead"];
const crosssale_incentive_access_roles = [...common_roles, "operationshead"];
const incentive_access_roles = [...common_roles, "operationshead", "magnetmis"];
const updateRm_access_roles =[...common_roles,"financeanalyst"];
const view_access_roles = ["saleshead","salesmanager","salestl", "seniorsalestl", "closings1","sourcings2","sourcings3", "magnet s2", "magnet s3", "pnlhead","presalehead","presalestl"];
const verify_access_roles = [...common_roles, "operationshead", "financeanalyst", ...view_access_roles];
const upload_access_roles = [...common_roles
  , "pnlhead", "saleshead", "salesmanager", "salestl", "seniorsalestl",  "closings1"
]
const preSaleRmRole = [...common_roles,"pnlhead", "saleshead", "salesmanager", "salestl", "seniorsalestl", "admin"];
const revertCancelAccessRole = [...common_roles, "financeanalyst"];
const magnet_brokerage_access_role = [...revertCancelAccessRole, "operationshead", "magnetmis"]

export default class BookingList extends Component {
  constructor(props) {
    super();

    this.state = {
      showLoader: true,
      bookingLeads: [],
      page: 1,
      total: 100,
      size: 10,
      documentModalType: "",
      selectedBooking: null,
      showUpdateRmModel:false,
      showDocumentUploadModal: false,
      showBookingIncentiveModal: false
      , filters: {}
      , alertShow: false
      , alertType: "success"
      , alertMsg: ""
      , showBrokerageModal: false,
      sweetWarnShow:false,
      under_canlation_message:'',
      confirmBtnText:'',
      cancelBtnText:'',
      confirmBtnBsStyle:'danger',
      cancelBtnBsStyle:'info',
      showCancel : true,
      showConfirm : true,
      customeBtn:false,
      showCancelTxt:''
    };
  }

  componentDidMount() {
    var homesfy_lg = localStorage.getItem("homesfy_lg");
    if (homesfy_lg && homesfy_lg !== "") {
      let user = JSON.parse(window.atob(homesfy_lg));

      this.setState({
        loggedInUser: user.id,
        role: user.role
        , group: user.group
      });
    }

    this.getBookingLeads();
  }

  getBookingLeads = async (page = 1) => {
    let isInvoice = "";
    if (this.props.section === "raise-invoice") {
      isInvoice = "&isInvoice=1";
    }
    if (this.props.section === 'passback') {
      isInvoice = "&passback=1&invoice_cleared=1";
    }
    let bookingLeads = await getBookingLeads(page, 10, isInvoice);
    this.setState({
      bookingLeads: bookingLeads.results,
      total: bookingLeads.meta.totalElements,
      showLoader: false
    });
  };

  handlePageChange = page => {
    this.setState({ showLoader: true }, async () => {
      let bookingLeads;
      let listData = {};
      listData.size = 10;
      listData.page = page;
      this.state.filters && Object.assign(this.state.filters, listData)
      bookingLeads = await getBookingLeadsData(this.state.filters);
      this.setState({
        page: page,
        bookingLeads: bookingLeads.results,
        total: bookingLeads.meta.totalElements,
        showLoader: false
      });
    });
  };

  toggleUpdateRM = async (booking) => {
   let state = {
     showUpdateRmModel: !this.state.showUpdateRmModel,
     lead_id: booking.lead_id,
   };
     this.setState(state);
 };

  toggleDocumentModal = (booking, documentModalType, toggle) => {
    
    let state = {
      showDocumentUploadModal: !this.state.showDocumentUploadModal,
      documentModalType: documentModalType,
      selectedBooking: booking
    };
    this.setState(state)
    
    /* if (!toggle) {
      state.showLoader = true;
    } */

    /* () => {
    if (!toggle) {
      this.getBookingLeads(this.state.page);
    }
  }); */
  };

  toggleBookingIncentiveModal = async (booking, crossSale,preSale) => {
    if(crossSale){
      booking.cross_sale_incetive = true;
      booking.presale = false;
    }
    if(preSale){
      booking.presale = true;
      booking.cross_sale_incetive = false;
    }
    if(booking && !crossSale && !preSale){
      booking.presale = false;
      booking.cross_sale_incetive = false;
    }
    let state = {
      showBookingIncentiveModal: !this.state.showBookingIncentiveModal,
      selectedBooking: booking
    };

    await this.setState(state);
  };

  toggleFilter = toggle => {
    this.setState({ toggleFilter: toggle });
  };

  filterBookings = params => {
    params = params !== undefined ? params : this.state.filters;
    this.setState({ showLoader: true, filters: params}, async () => {
      let bookings;
      if (params) {
        bookings = await filterBooking(params);
        this.setState({
          bookingLeads: bookings.results,
          total: bookings.meta.totalElements,
        });
      }else{
        this.getBookingLeads();  
      }
      this.setState({
        showLoader: false
      })
    });
  };

  filterReset = () => {
    this.setState({ showLoader: true, filters: {} }, () => {
      this.getBookingLeads();
    });
  };
  invoiceStatus(bookingObj) {
    let status = 'Not Eligible for Invoice';
    if (Number(bookingObj.eligible_for_invoice_status) === 1) {
      status = 'Eligible for Invoice';
    }
    if(Number(bookingObj.invoice_not_raised_status)===1){
      status='Invoice not Raised';
    }
    if(Number(bookingObj.partial_invoice_raised_status)===1){
      status='Partial Invoice Raised'
    }
    if (Number(bookingObj.invoice_raised_status) === 1) {
      status = 'Invoice Raised';
    }
    if (Number(bookingObj.invoice_cleared_status) === 1) {
      status = 'Invoice Cleared';
    }
    return status;
  }
  handleClose = () => {
    this.setState({
      alertShow: false,
    })
  }
  handleCancel = (e) => { 
    if(this.state.cancelBtnText === 'Booking To Cancel'){
      this.setState({
        sweetWarnShow: !this.state.sweetWarnShow
      });
      let stage = 1;
      this.revertCancallation(this.state.lead_id,stage);
    }
  }
  handleBrokerageModal = (booking) =>{
    this.setState({ bookingId: booking?.id, leadId: booking?.lead_id, magnetId: booking?.magnet_id})
    this.changeBrokerageModal()
  }
  changeBrokerageModal = () => {
    this.setState({
      showBrokerageModal: !this.state.showBrokerageModal
    })
  }
  handleSweetWarn = (value, stage) => {
    let under_canlation_message = ``;
    if(stage){
      stage = Number(stage);
    }
    if(stage === -1){
      under_canlation_message = `Do you want to move this lead under cancellation ?`;
    }else{
      under_canlation_message = `Would you like to mark this lead as booked or cancelled ?`;
    }
    this.setState({
      sweetWarnShow: !this.state.sweetWarnShow,
      lead_id: value,
      under_canlation_message:under_canlation_message,
      confirmBtnText: stage === -1 ? 'Yes' : 'Keep To Booking',
      cancelBtnText: stage === -1 ? 'No' :'Booking To Cancel',
      confirmBtnBsStyle: stage === -1 ? 'info' : 'danger', 
      cancelBtnBsStyle: stage === -1 ? 'danger' : 'info',
      showCancelTxt: stage === -1 ? 'No' : 'Close',
      showConfirm: stage === -1 ? true : false,
      customeBtn: stage === -1 ? false : true
    });
  };
  handleUpdateBooking = () => {
    this.setState({
      sweetWarnShow: !this.state.sweetWarnShow
    });
    let stage = '';
    if(this.state.confirmBtnText === 'Keep To Booking'){
      stage = 0;
    }else if(this.state.confirmBtnText === 'Yes'){
      stage = -1;
    }
    this.revertCancallation(this.state.lead_id,stage);
  };
  revertCancallation = async (lead_id,stage) => {
    this.setState({
      showLoader: true
    });
    let revertCancallationData = await revertCancallation(lead_id,stage);
    if (revertCancallationData.meta.status === 201) {
      this.getBookingLeads();
      this.setState({
        showLoader: false,
        alertShow: true,
        alertType: "success",
        alertMsg: "Under cancellation successfully!!!"
      });
    } else {
      this.setState({
        showLoader: false,
        alertShow: true,
        alertType: "error",
        alertMsg: revertCancallationData.meta.message
      });
    }
  }
  setWarningMsg=(e)=>{this.setState(e)}
  render() {
    const { total
      , alertShow
      , alertMsg
      , alertType
      , group,sweetWarnShow } = this.state;

    return (
      <>
      <SweetWarnAlert
              show={sweetWarnShow}
              handleCancel={this.handleCancel}
              changeSweet={this.handleSweetWarn}
              showCancel = {this.state.showCancel}
              showConfirm={this.state.showConfirm}
              message={this.state.under_canlation_message}
              handleUpdateUser={this.handleUpdateBooking} confirmBtnText = {this.state.confirmBtnText}
              cancelBtnText = {this.state.cancelBtnText} confirmBtnBsStyle = {this.state.confirmBtnBsStyle}
              cancelBtnBsStyle = {this.state.cancelBtnBsStyle} customeBtn={this.state.customeBtn} showCancelTxt={this.state.showCancelTxt}
            />
        <Loader show={this.state.showLoader} />
        <PopUpAlert open={alertShow} msg={alertMsg} type={alertType} handleClose={this.handleClose} />
        <BookingFilter
          show={this.state.toggleFilter}
          toggleFilter={this.toggleFilter}
          filterBookings={this.filterBookings}
          filterReset={this.filterReset}
        />
        <div className="px-3 py-2 leadMainHeader">
          <div className="headerCount my-auto">
            <span className="totalCount">Total: </span>
            <span>{total ? total : "0"}</span>
          </div>

          {this.props.section != 'passback' &&
            <div className="row">
              <div className="col-12 text-right p-2 pr-4">
                <FontAwesomeIcon
                  icon={faFilter}
                  className="nav-icon"
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={e => this.toggleFilter(!this.state.toggleFilter)}
                />
              </div>
            </div>
          }
        </div>
        <div className="listContainer">
          {this.state.bookingLeads.length > 0 ? (
            this.state.bookingLeads.map((booking, index) => (
              <ListingView extraClass={`booking-item card mb-3 p-4 ${this.props.section === "raise-invoice"
                ? ""
                : (booking.booking_document_stage === 1 && booking.email_confirmation === 1) ? `approved` : booking.booking_document_stage === -1
                  ? `pending-documents`
                  : booking.booking_document_stage === 0
                    ? `pending-confirmation`
                    : booking.booking_document_stage === 1
                      ? `approved`
                      : booking.booking_document_stage === 2
                        ? `rejected`
                        : ``
                }`}
                key={index}>
                <OneSixthField title="ID" value={booking.Lead?.id ? booking.Lead?.id : booking.lead_id} />
                <OneSixthField title="Booking Date" value={changeDate(booking.booking_date)} />
                <OneSixthField title="Agreement Amount" value={indianRupeeCommaSeparated(booking.agreement_value)} />
                <OneSixthField
                  title="RM Name"
                  value={["sourcings2","sourcings3", "magnet s2", "magnet s3"].includes(this.state.role) ? booking.PresaleRm ? booking.PresaleRm?.name : "" :booking.SalesRMBooking ? booking.SalesRMBooking?.name : "-"}
                />
                { booking.is_crosssale ? 
                <OneSixthField
                title="Cross RM Name"
                value={booking.CrossSalesRMBooking ? booking.CrossSalesRMBooking?.name : "-"}
              />
                : null }
                {this.props.section != 'passback' && preSaleRmRole.includes(this.state.role) && <OneSixthField title="Presale RM Name" value={booking.PresaleRm ? booking.PresaleRm?.name : "-"} />}
                <OneSixthField title="Booking ID" value={booking.id} />
                <OneSixthField title="Project Name" value={booking.Project?.name} />
                <OneSixthField title="Client Name" value={booking.Client?.name} />
                {this.props.section != 'passback' && (
                  <>
                    <OneSixthField title="Invoice Status" value={this.invoiceStatus(booking)} />
                  </>
                )}
                {group !== "magnet" && 
                  <>
                    <OneSixthField
                      title={this.props.section != 'passback' ? 'Eligibility Percentage' : 'Passback Amount'}
                      value={this.props.section != 'passback'
                        ? booking.eligibility_percentage
                        : indianRupeeCommaSeparated(booking.special_discount)}
                    />
                    {this.props.section != 'passback' && preSaleRmRole.includes(this.state.role) && <OneSixthField title="Presale Eligibility Percentage" value={booking.presale_eligibility_percentage} />}
                    <OneSixthField title="Old Crm Lead" value={booking.is_old_sys_lead === 1 ? 'Yes' : 'No'} />
                  </>
                }
                <div className="col-12">
                  {this.props.section === "raise-invoice" ? null : (
                    <div className="row">
                      <OneSixthField title="Cross Sale Lead" value={booking?.is_crosssale ? 'Yes' : 'No'} />
                      <div className="col-lg-2 col-12 text-left">
                        { booking.exception_booking_status ?
                        <>
                            <span className="text-muted">Exception Reason: </span>
                            {<span className="ml-2">{booking.exception_booking_comment}</span>} 
                          </>
                        : 
                        booking.rejection_reason ? (
                          <>
                            <span className="text-muted">Rejection Reason: </span>
                            {booking.rejection_reason?.endsWith("Comment: ")
                              ? <span className="ml-2">{booking.rejection_reason?.replace("Comment: ","")}</span>
                              : booking.rejection_reason.includes("Comment: ")
                                ? <>
                                  <span className="ml-2">{booking.rejection_reason.split("Comment: ")[0]}</span>
                                  <br/><span>Comment: {booking.rejection_reason.split("Comment: ")[1]}</span>
                                  </>
                                : <span className="ml-2">{booking.rejection_reason}</span>
                              } 
                          </>
                        ) : null
                        }
                      </div>

                      <div className="col-lg-12 col-12 text-right">
                      {(magnet_brokerage_access_role.includes(this.state.role) && booking?.is_magnet) && (
                        <span
                          className="booking-action mr-4 valueText cursor-pointer"
                          onClick={() =>
                            this.handleBrokerageModal(booking)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faMoneyBill}
                            className="nav-icon"
                          />{" "}
                          Magnet Brokerage
                        </span>
                      )}
                      {revertCancelAccessRole.includes(this.state.role) ? (
                        <span
                          className="booking-action mr-4 valueText cursor-pointer"
                          onClick={value => {
                            this.handleSweetWarn(booking.lead_id,`${booking?.is_lead_cancelled === 0 ? -1 : 0}`);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMoneyBill}
                            className="nav-icon"
                          />{" "}
                          {booking?.is_lead_cancelled === 0 ? `Move Under Cancellation` : `Under Cancellation Lead`}
                        </span>
                      ) : booking?.is_lead_cancelled === -1 ? <span
                      className="booking-action mr-4 valueText cursor-pointer"> Lead Under Canclation </span>: null}
                      {booking.is_presale && presale_incentive_access_roles.includes(this.state.role) && this.props.section != 'passback' ? (
                          <span
                            className="booking-action mr-4 valueText cursor-pointer"
                            onClick={() =>
                              this.toggleBookingIncentiveModal(booking,false,true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="nav-icon"
                            />{" "}
                            Presale Incentives
                          </span>
                        ) : ''}
                      {(booking.invoice_cleared_status && booking.invoice_raised_status) && booking.is_crosssale && crosssale_incentive_access_roles.includes(this.state.role) && this.props.section != 'passback' ? (
                          <span
                            className="booking-action mr-4 valueText cursor-pointer"
                            onClick={() =>
                              this.toggleBookingIncentiveModal(booking,true,false)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="nav-icon"
                            />{" "}
                            Cross Incentives
                          </span>
                        ) : ''}
                        {incentive_access_roles.includes(this.state.role) && this.props.section != 'passback' ? (
                          <span
                            className="booking-action mr-4 valueText cursor-pointer"
                            onClick={() =>
                              this.toggleBookingIncentiveModal(booking,false,false)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="nav-icon"
                            />{" "}
                            Incentives
                          </span>
                        ) : ''}
                       {updateRm_access_roles.includes(this.state.role) && this.props.section != 'passback' ? (
                          <span
                            className="booking-action mr-4 valueText cursor-pointer"
                            onClick={() =>this.toggleUpdateRM(booking)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="nav-icon"
                            />{" "}
                            Update RM
                          </span>
                        ) : ''} 
                        {verify_access_roles.includes(this.state.role) && this.props.section != 'passback' && (
                          <span
                            className="booking-action mr-4 valueText cursor-pointer"
                            onClick={() =>
                              this.toggleDocumentModal(booking, view_access_roles.includes(this.state.role) ? "viewDoc":"verify")
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="nav-icon"
                            />
                            {view_access_roles.includes(this.state.role) ? "View Document" : "Verify"}
                          </span>
                        )}
                        {upload_access_roles.includes(this.state.role) && this.props.section != 'passback' && (
                          <span
                            className="booking-action valueText cursor-pointer"
                            onClick={() =>
                              this.toggleDocumentModal(booking, "upload")
                            }
                          >
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="nav-icon"
                            />{" "}
                            Upload
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </ListingView>
            ))
          )
            : (
              <div className="row text-center">
                <div className="col-12">No Booking Leads found</div>
              </div>
            )}

          {/* ###### Previous code commented for safer side ######## */}
          {/*{this.state.bookingLeads.length > 0 ? (
            this.state.bookingLeads.map((booking, key) => (

              <div
                className={`booking-item card mb-3 p-4 ${this.props.section === "raise-invoice"
                  ? ""
                  : (booking.booking_document_stage === 1 && booking.email_confirmation === 1) ? `approved` : booking.booking_document_stage === -1
                    ? `pending-documents`
                    : booking.booking_document_stage === 0
                      ? `pending-confirmation`
                      : booking.booking_document_stage === 1
                        ? `approved`
                        : booking.booking_document_stage === 2
                          ? `rejected`
                          : ``
                  }`}
                key={key}
              >
                <div className="row mb-1">
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">ID </span>
                      </div>
                      <div className="col-md-12">
                        <span className="valueText">{booking.Lead?.id ? booking.Lead?.id : booking.lead_id}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">Booking Date</span>
                      </div>
                      <div className="col-md-12">
                        <span className="valueText">
                          {changeDate(booking.booking_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">Agreement Amount</span>
                      </div>
                      <div className="col-md-12">
                        <span className="text-capitalize valueText">
                          {indianRupeeCommaSeparated(booking.agreement_value)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">RM Name</span>
                      </div>
                      <div className="col-md-12">
                        <span className="text-capitalize valueText">
                          {booking.SalesRMBooking
                            ? booking.SalesRMBooking?.name
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">Booking ID</span>
                      </div>
                      <div className="col-md-12">
                        <span className="text-capitalize valueText">
                          {booking.id}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">Project Name</span>
                      </div>
                      <div className="col-md-12">
                        <span className="text-capitalize valueText">
                          {booking.Project?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">Client Name</span>
                      </div>
                      <div className="col-md-12">
                        <span className="text-capitalize valueText">
                          {booking.Client?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">
                          {this.props.section != 'passback' ? 'Eligibility Percentage' : 'Passback Amount'}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <span className="font-small text-capitalize valueText">
                          {this.props.section != 'passback' ? booking.eligibility_percentage : indianRupeeCommaSeparated(booking.special_discount)}
                        </span>
                      </div>
                    </div>
                  </div>
                  {(this.props.section != 'passback' && <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">
                          Invoice Status
                        </span>
                      </div>
                      <div className="col-md-12">
                        <span className="font-small text-capitalize valueText">
                          {this.invoiceStatus(booking)}
                        </span>
                      </div>
                    </div>
                  </div>)}
                  {(this.props.section != 'passback' && <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted">
                          Old Crm Lead
                        </span>
                      </div>
                      <div className="col-md-12">
                        <span className="font-small text-capitalize valueText">
                          {booking.is_old_sys_lead === 1 ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                  </div>)}
                  <div className="col-md-3 mb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="text-muted"></span>
                      </div>
                      <div className="col-md-12"></div>
                    </div>
                  </div>
                </div>
                {this.props.section === "raise-invoice" ? null : (
                  <div className="row">
                    <div className="col-lg-8 col-12 text-left">
                      {booking.rejection_reason ? (
                        <>
                          <span className="text-muted">Rejection Reason</span>
                          <span className="ml-2">
                            {booking.rejection_reason}
                          </span>
                        </>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-12 text-right">
                      {["admin", "financehead", "operationshead", "financeassociate"].includes(this.state.role) && this.props.section != 'passback' ? (
                        <span
                          className="booking-action mr-4"
                          onClick={() =>
                            this.toggleBookingIncentiveModal(booking)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faMoneyBill}
                            className="nav-icon"
                          />{" "}
                          Incentives
                        </span>
                      ) : null}

                      {["admin", "financehead", "operationshead", "financeassociate", "financeanalyst"].includes(this.state.role) && this.props.section != 'passback' ? (
                        <span
                          className="booking-action mr-4"
                          onClick={() =>
                            this.toggleDocumentModal(booking, "verify")
                          }
                        >
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="nav-icon"
                          />{" "}
                          Verify
                        </span>
                      ) : null}

                      {[
                        "admin",
                        "financehead",
                        "pnlhead",
                        "financeassociate",
                        "saleshead",
                        "salesmanager",
                        "salestl", "seniorsalestl", 
                        "closings1",
                        "sourcings2",
                        "sourcings3"
                      ].includes(this.state.role) && this.props.section != 'passback' ? (
                        <span
                          className="booking-action"
                          onClick={() =>
                            this.toggleDocumentModal(booking, "upload")
                          }
                        >
                          <FontAwesomeIcon
                            icon={faUpload}
                            className="nav-icon"
                          />{" "}
                          Upload
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="row text-center">
              <div className="col-12">No Booking Leads found</div>
            </div>
          )} */}
        </div>
        <div className="container-fluid">
          <div className="row float-right">
            <div className="col-lg-4 col-12">
              {this.state.total > 10 ? (
                <Pagination
                  hideDisabled
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.size}
                  totalItemsCount={this.state.total}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                />
              ) : null}
            </div>
          </div>
        </div>

        {this.state.showDocumentUploadModal ? (
          <BookingDocumentsModal
            show={this.state.showDocumentUploadModal}
            toggleDocumentModal={this.toggleDocumentModal}
            documentModalType={this.state.documentModalType}
            selectedBooking={this.state.selectedBooking}
            getList={this.filterBookings}
            role={this.state.role}
          />
        ) : null}
         {this.state.showUpdateRmModel ? (
          <UpdateRm 
            show={this.state.showUpdateRmModel}
            toggleUpdateRM={this.toggleUpdateRM}
            lead_id={this.state.lead_id}
            booking_id ={this.state.bookingLeads[0].id}
            setWarningMsg={this.setWarningMsg}
          />
        ) : null}
        {this.state.showBookingIncentiveModal ? (
          <BookingIncentive
            show={this.state.showBookingIncentiveModal}
            toggleBookingIncentiveModal={this.toggleBookingIncentiveModal}
            selectedBooking={this.state.selectedBooking} 
            getBookingLeadsList={this.getBookingLeads}
            getList={this.filterBookings}
          />
        ) : null}
        {this.state.showBrokerageModal && 
        <MagnetBrokerageModal
          show={this.state.showBrokerageModal} 
          changeModal={this.changeBrokerageModal}
          bookingId={this.state.bookingId} 
          magnetId={this.state.magnetId}
          leadId={this.state.leadId} 
          getList={this.filterBookings}
        />}
      </>
    );
  }
}
