import React from 'react';

import TypographyComponent from '@material-ui/core/Typography';

import './Typography.sass'
import clsx from 'clsx';


const defaultState = {
}


const Typography = props => <TypographyComponent className="Typography" {...props}>{props.children}</TypographyComponent>

export default Typography;
