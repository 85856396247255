import React, { useState, useEffect } from 'react'
import { getCliManagement } from '../../dataParser/getIvrData';
import Loader from "../common/loader/loader"
import MainHeader from './MainHeader';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import InputField from '../common/controls/InputField';
import CliAction from './CliAction';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const fields = ["", "CLI Account Name", "Created At", "Updated At", "Updated By", "Number", "Action"];

let initialState = {
    showLoader: false
    , data: []
    , number: ""
}
const CliManagement = () => {

    const [state, setState] = useState(initialState);

    useEffect(() => {
        getCliData();
    }, [])

    const getCliData = async () => {
        setState({ ...state, showLoader: true })
        let getRes = await getCliManagement();
        if (getRes.meta.status === 200) {
            setState({
                ...state
                , showLoader: false
                , data: getRes.data.rows
                , count: getRes.data.count
                , editNumberId: null
            })
        }
        else {
            setState({
                ...state
                , showLoader: false
            })
        }
    }

    const handleChange = (e, data) => {
        let { name, value } = e.target;
        if (name === "number") {
            value = value.replace(/\D/g, "");
            value = value.replace(value, "+" + value)
        }
        setState({
            ...state,
            [name]: value
        })
    }

    const handleEdit = async (cli) => {
        setState({
            ...state
            , editNumberId: cli.id
            , number: cli.knowlarity_caller_id
        })
    }

    const { showLoader, data, count, editNumberId, number } = state;

    return (
        <>
            <Loader show={showLoader} />
            <MainHeader list="CLI Management" count={count || "0"} />
            <div className="p-4">
                <div className="row">
                    <div className="col-md-12">
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }} >
                                <TableHead>
                                    <TableRow>
                                        {fields.map((f, i) => (
                                            <StyledTableCell key={i}>{f}</StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, i) => (
                                        <TableRow key={item.id} className="text-capitalize ">
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{item.builder_name || "-"}</TableCell>
                                            <TableCell>{moment(item?.created_at).format('L') || "-"}</TableCell>
                                            <TableCell>{moment(item?.updated_at).format('L') || "-"}</TableCell>
                                            <TableCell>{(item.createdInfo?.name ? item.createdInfo?.name : item.updatedInfo?.name) || "-"}</TableCell>
                                            <TableCell>
                                                {editNumberId === item.id
                                                    ? <InputField name="number" onChange={(e) => { handleChange(e, number) }} value={number} />
                                                    : item.knowlarity_caller_id || "-"
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <CliAction
                                                    editId={editNumberId}
                                                    itemId={item.id}
                                                    onEdit={() => handleEdit(item)}
                                                    number={number}
                                                    getListingData={getCliData}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CliManagement
