import React from 'react';
import DialogMui from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const Modal = styled((props) => {
	const {show, onHide, size, backdrop} = props;
	return <DialogMui scroll="body" open={show} onClose={onHide} maxWidth={size} disableBackdropClick={backdrop} {...props} /> 
})``

export const ModalTitle = styled((props) => {
	const {onHide, children , ...others} = props;
	return <DialogTitle classes={{root: 'title'}} {...others}>{children} <IconButton onClick={onHide}><CloseIcon /></IconButton></DialogTitle> 
})`
	&.title  h2{
		display: flex;
		justify-content: space-between;
	}
	& .MuiButtonBase-root {
		padding: 0;
	}
`

export const ModalBody = styled((props) => {
	return <DialogContent dividers  {...props} /> 
})``
export default Modal;
