import "../pdfGenrete/ProjectPdf.css";
// import '../pdfGenrete/pdfCss.css';
import { Card, Container, CardContent, Grid } from "@material-ui/core";
import * as React from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { getProjectWiseData } from "../../dataParser/getDashboardData";
import { AMAZON_URL } from "../../config/serverKey";

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1
  },
  BrandLogo: {
    width: 150
  }
}));
class ProjectPdfContainer extends React.Component {
  pdfExportComponent;
  constructor(props) {
    super(props);
    this.state = {
      projectData: {}
    };
  }
  componentDidMount() {
    (async () => {
      try {
        let projectRes = await getProjectWiseData(this.props.projectId);
        if (projectRes && projectRes.meta.status === 200 && projectRes.data) {
          projectRes.data.otherFields = projectRes.data.others
            ? JSON.parse(projectRes.data.others)
            : [];
          let gallaryImges = [];
          projectRes.data.ProjectImages.map(item => {
            if (item.type === "baner") {
              projectRes.data.banerImages = item.fileUrl;
            } else if (item.type === "highlight") {
              projectRes.data.highlightsImages = item.fileUrl;
            } else if (item.type === "gallary") {
              gallaryImges.push(item.fileUrl);
            }
          });
          projectRes.data.gallaryImges = gallaryImges;
          var lowest = Number.POSITIVE_INFINITY;
          var highest = Number.NEGATIVE_INFINITY;
          var tmp;
          for (var i = projectRes.data.ProjectMeta.length - 1; i >= 0; i--) {
            tmp = projectRes.data.ProjectMeta[i].price;
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
          }
          projectRes.data.startingPrice = this.numDifferentiation(lowest);
          projectRes.data.landPrice = this.numDifferentiation(highest);
        }
        await this.setState({ projectData: projectRes ? projectRes.data : {} });
        this.pdfExportComponent.save();
        if (this.props.hideLoader) {
          this.props.hideLoader();
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.projectId !== this.props.projectId ||
      (this.props.clickId && prevProps.clickId != this.props.clickId)
    ) {
      let projectRes = await getProjectWiseData(this.props.projectId);
      if (projectRes && projectRes.meta.status === 200 && projectRes.data) {
        projectRes.data.otherFields = projectRes.data.others
          ? JSON.parse(projectRes.data.others)
          : [];
        let gallaryImges = [];
        projectRes.data.ProjectImages.map(item => {
          if (item.type === "baner") {
            projectRes.data.banerImages = item.fileUrl;
          } else if (item.type === "highlight") {
            projectRes.data.highlightsImages = item.fileUrl;
          } else if (item.type === "gallary") {
            gallaryImges.push(item.fileUrl);
          }
        });
        projectRes.data.gallaryImges = gallaryImges;
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = projectRes.data.ProjectMeta.length - 1; i >= 0; i--) {
          tmp = projectRes.data.ProjectMeta[i].price;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        projectRes.data.startingPrice = this.numDifferentiation(lowest);
        projectRes.data.landPrice = this.numDifferentiation(highest);
      }
      await this.setState({ projectData: projectRes ? projectRes.data : {} });
      this.pdfExportComponent.save();
      if (this.props.hideLoader) {
        this.props.hideLoader();
      }
    }
  }
  numDifferentiation(value) {
    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    }
    return val;
  }
  render() {
    return (
      <div>
        <div
          component={Paper}
          className="pdfSection"
          style={{
            position: "absolute",
            left: "-10000px",
            top: 0
          }}
        >
          <PDFExport
            paperSize="auto"
            fileName={`magnet-${this.state.projectData?.name}.pdf`}
            ref={component => (this.pdfExportComponent = component)}
            margin={{ top: 30, left: 0, right: 0, bottom: 30 }}
          >
            <>
              <Box className="PdfPreiview">
                {/* <Container maxWidth="lg">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={8}
                  >
                    {window.$user.role !== "magnet" && (
                      <Grid item xs={6}>
                        <img
                          src={`${AMAZON_URL}/HomesfyLogo.svg`}
                          width="100%"
                          alt=" site_image"
                          className="BrandLogo"
                          crossorigin="anonymous"
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={6}
                      className={
                        window.$user.role !== "magnet" ? "TextRight" : ""
                      }
                    >
                      <img
                        src={`${AMAZON_URL}/logo.svg`}
                        width="100%"
                        alt=" site_image"
                        className="BrandLogo"
                        crossorigin="anonymous"
                      />
                    </Grid>
                  </Grid>
                </Container> */}
                <Box width={1} className="FactBanner">
                  <img
                    src={this.state.projectData?.banerImages}
                    width="100%"
                    alt=" site_image"
                    crossorigin="anonymous"
                  />
                  <Box className="ProjectName">
                    <Box
                      className="Heading"
                      style={{ fontSize: "14pt", fontWeight: "bold" }}
                    >
                      {this.state.projectData?.name}
                    </Box>
                    <p style={{ fontSize: "10pt" }}>
                      Rera Number- {this.state.projectData?.rera_no}
                    </p>
                  </Box>
                </Box>
                <Box width={1} my={2}>
                  <Container maxWidth="lg">
                    <Grid container direction="row">
                      <Grid item md="12">
                        <p className="Overview">
                          {this.state.projectData?.overview}
                        </p>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Box width={1} my={2}>
                  <Container maxWidth="lg">
                    <Grid container direction="row">
                      <Box
                        width={1}
                        mb={1}
                        className="Heading"
                        style={{ fontWeight: "bold", fontSize: "14pt" }}
                      >
                        Project Highlights
                      </Box>
                    </Grid>
                    <Grid container direction="row" spacing={4}>
                      <Grid
                        item
                        xs="6"
                        lg="6"
                        md="6"
                        m={4}
                        className="ProjectHeightImage"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={this.state.projectData?.highlightsImages}
                          width="100%"
                          alt=" site_image"
                          style={{ borderRadius: "10px" }}
                          crossorigin="anonymous"
                        />
                        <img
                          src="https://refrance.s3.ap-south-1.amazonaws.com/homesfy/AfterStyle_02.png"
                          width="60"
                          alt=" site_image"
                          height="60"
                          style={{
                            position: "absolute",
                            content: "",
                            width: "60px",
                            height: "60px",
                            right: "-30px",
                            top: "10%"
                          }}
                          crossorigin="anonymous"
                        />
                      </Grid>
                      <Grid item md="6" xs="6" lg="6" m={4}>
                        <Card className="FactCard" variant="outlined">
                          <CardContent>
                            <h4
                              style={{ fontWeight: "bold", fontSize: "10pt" }}
                            >
                              No. of Towers
                            </h4>
                            <h6 style={{ fontWeight: "8pt" }}>
                              {this.state.projectData?.tower_no}
                            </h6>
                          </CardContent>
                        </Card>
                        <Card className="FactCard" variant="outlined">
                          <CardContent>
                            <h4
                              style={{ fontWeight: "bold", fontSize: "10pt" }}
                            >
                              Land Parcel
                            </h4>
                            <h6 style={{ fontWeight: "8pt" }}>
                              {this.state.projectData?.land_area
                                ? this.state.projectData?.land_area + " Acre"
                                : "-"}
                            </h6>
                          </CardContent>
                        </Card>
                        <Card className="FactCard" variant="outlined">
                          <CardContent>
                            <h4
                              style={{ fontWeight: "bold", fontSize: "10pt" }}
                            >
                              Possession Date
                            </h4>
                            <h6 style={{ fontWeight: "8pt" }}>
                              {this.state.projectData?.possession_date &&
                              !this.state.projectData.ready_to_move
                                ? moment(
                                    this.state.projectData?.possession_date
                                  ).format("MMMM-YYYY")
                                : this.state.projectData?.ready_to_move}
                            </h6>
                          </CardContent>
                        </Card>
                        <Card className="FactCard" variant="outlined">
                          <CardContent>
                            <h4
                              style={{ fontWeight: "bold", fontSize: "10pt" }}
                            >
                              Starting Price
                            </h4>
                            <h6 style={{ fontWeight: "8pt" }}>
                              {this.state.projectData?.startingPrice
                                ?.toString()
                                .includes("Infinity")
                                ? "-"
                                : this.state.projectData?.startingPrice}{" "}
                              {/* (All Inclusive) */}
                            </h6>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Box width={1} my={2} style={{ marginTop: "30pt" }}>
                  <Container maxWidth="lg">
                    <Grid container direction="row">
                      <Box
                        width={1}
                        mb={1}
                        className="Heading"
                        style={{ fontWeight: "bold", fontSize: "14pt" }}
                      >
                        Floor Plans
                      </Box>
                    </Grid>
                    <Grid container direction="row" spacing={8}>
                      <Grid
                        item
                        md="12"
                        xs="12"
                        lg="12"
                        m={4}
                        style={{ position: "relative", margin: "0 0 30px 0" }}
                      >
                        <TableContainer component={Paper}>
                          <Table
                            className=""
                            aria-label="simple table"
                            style={{
                              fontSize: "8pt",
                              border: "1px solid #F59A00"
                            }}
                          >
                            <TableHead>
                              <TableRow
                                style={{ borderBottom: "1px solid #F59A00" }}
                              >
                                <TableCell
                                  align="center"
                                  style={{
                                    borderRight: "1px solid #F59A00",
                                    textAlign: "center",
                                    color: "#F59A00",
                                    fontSize: "8pt"
                                  }}
                                >
                                  Type
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    borderRight: "1px solid #F59A00",
                                    textAlign: "center",
                                    color: "#F59A00",
                                    fontSize: "8pt"
                                  }}
                                >
                                  Carpet Area (Sq.Ft.)
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    borderRight: "1px solid #F59A00",
                                    textAlign: "center",
                                    color: "#F59A00",
                                    fontSize: "8pt"
                                  }}
                                >
                                  Price (all inclusive) in Rs.
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.projectData?.ProjectMeta &&
                                this.state.projectData?.ProjectMeta.length &&
                                this.state.projectData.ProjectMeta.map(row => (
                                  <TableRow
                                    key={row.Type}
                                    align="center"
                                    style={{
                                      borderBottom: "1px solid #F59A00"
                                    }}
                                  >
                                    <TableCell
                                      className="heading-td"
                                      component="th"
                                      scope="row"
                                      align="center"
                                      style={{
                                        borderRight: "1px solid #F59A00",
                                        textAlign: "center",
                                        fontSize: "8pt"
                                      }}
                                    >
                                      {row.bhk}BHK
                                    </TableCell>
                                    <TableCell
                                      className="heading-td"
                                      align="center"
                                      style={{
                                        borderRight: "1px solid #F59A00",
                                        fontSize: "8pt",
                                        textAlign: "center"
                                      }}
                                    >
                                      {row.area}
                                    </TableCell>
                                    <TableCell
                                      className="heading-td"
                                      align="center"
                                      style={{
                                        fontSize: "8pt",
                                        textAlign: "center"
                                      }}
                                    >
                                      {this.numDifferentiation(row.price)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Box width={1}>
                  <Container maxWidth="lg">
                    <List
                      component="list"
                      className="GallerySection"
                      aria-label="Gallery Section"
                    >
                      {this.state.projectData?.gallaryImges &&
                      this.state.projectData?.gallaryImges.length
                        ? this.state.projectData?.gallaryImges.map(item => (
                            <ListItem>
                              <img
                                src={item}
                                width="100%"
                                alt=" site_image"
                                style={{ borderRadius: "10px" }}
                                crossorigin="anonymous"
                              />
                            </ListItem>
                          ))
                        : ""}
                    </List>
                  </Container>
                </Box>
                <Box width={1} my={4}>
                  <Container maxWidth="lg">
                    <Grid container direction="row">
                      <Box
                        width={1}
                        mb={1}
                        className="Heading"
                        style={{ fontWeight: "bold", fontSize: "14pt" }}
                      >
                        Amenities
                      </Box>
                    </Grid>
                    <Grid container direction="row">
                      <List component="list" className="AmenitiesSection">
                        {this.state.projectData?.ProjectAmenities &&
                          this.state.projectData?.ProjectAmenities.map(
                            (item, index) =>
                              item?.Amenity && (
                                <ListItem
                                  className="PDFList"
                                  style={{ fontSize: "8pt" }}
                                  key={index}
                                >
                                  {item?.Amenity?.name}
                                </ListItem>
                              )
                          )}
                      </List>
                    </Grid>
                  </Container>
                </Box>
                <Box width={1} my={4}>
                  <Container maxWidth="lg">
                    <Grid container direction="row">
                      <Box
                        width={1}
                        mb={1}
                        className="Heading"
                        style={{ fontWeight: "bold", fontSize: "14pt" }}
                      >
                        Location
                      </Box>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      style={{ marginBottom: "0" }}
                    >
                      {this.state?.projectData?.otherFields &&
                        this.state?.projectData?.otherFields.map(
                          (item, index) => (
                            <Grid container direction="row" key={index}>
                              <Grid item md={4}>
                                <h4 className="Title" key={index}>
                                  {item.lable !== "" && item.lable + " :"}
                                </h4>
                              </Grid>
                              <Grid item md={8}>
                                <h5
                                  className="Content text-capitalize"
                                  key={index}
                                >
                                  {item.value !== "" && item.value}
                                </h5>
                              </Grid>
                            </Grid>
                          )
                        )}
                    </Grid>
                  </Container>
                </Box>
                <Box width={1} my={4}>
                  <Container maxWidth="lg">
                    <Grid container direction="row">
                      <Box
                        width={1}
                        mb={1}
                        className="Heading"
                        style={{ fontWeight: "bold", fontSize: "14pt" }}
                      >
                        Project Link
                      </Box>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item md={4} xs={12}>
                        <h4 className="LocationTitle">RERA # :</h4>
                      </Grid>

                      <Grid item md={8} xs={12}>
                        <h4 className="LocationTitle">
                          {this.state?.projectData?.rera_no}
                        </h4>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      {this.state.projectData?.map && (
                        <Grid item md={4} xs={12}>
                          <h4 className="Title">Location Map :</h4>
                        </Grid>
                      )}
                      {this.state.projectData?.map && (
                        <Grid item md={8} xs={12}>
                          <a
                            href={this.state.projectData?.map}
                            className="Content"
                          >
                            Click Here To View Map
                          </a>
                        </Grid>
                      )}
                      <>
                        <Grid item md={4} xs={12}>
                          <h4 className="Title">Contact Us :</h4>
                        </Grid>
                        <Grid item md={8} xs={12}>
                          <a
                            href={"tel:" + this.state.projectData?.ivr_no || ""}
                            style={{ fontSize: "8pt" }}
                          >
                            {this.state.projectData?.ivr_no || "-"}
                          </a>
                        </Grid>
                      </>
                      {window.$user.role === "magnet" && (
                        <>
                          <Grid item md={4} xs={12}>
                            <h4 className="Title">Contact Number :</h4>
                          </Grid>
                          <Grid item md={8} xs={12}>
                            <a
                              href={"tel:" + window.$user?.phone}
                              style={{ fontSize: "8pt" }}
                            >
                              {window.$user?.phone || "-"}
                            </a>
                          </Grid>
                        </>
                      )}
                      {window.$user.role === "magnet" && (
                        <>
                          <Grid item md={4} xs={12}>
                            <h4 className="Title">Contact Name :</h4>
                          </Grid>
                          <Grid item md={8} xs={12} className="text-capitalize">
                            {window.$user?.first_name +
                              " " +
                              window.$user?.last_name || "-"}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Container>
                </Box>
                <Box width={1} my={4}>
                  <Container maxWidth="lg">
                    <p className="Disclaimer">
                      Disclaimer:The content is for information purposes only
                      and does not constitute an offer to avail of any service.
                      Prices mentioned are subject to change without notice and
                      properties mentioned are subject to availability. Images
                      for representation purpose only.
                    </p>
                  </Container>
                </Box>
              </Box>
            </>
            {/* </div> */}
          </PDFExport>
        </div>
      </div>
    );
  }
}

export default ProjectPdfContainer;
