import React, {Component} from 'react';
import {Modal,} from 'react-bootstrap';
import Frag from "../../utils/Frag/Frag.js";
import {withRouter} from "react-router-dom";
import "./detailLead.css";
import {getCommentData,getLeadinternalHistroyData,getKnowlarityData,getKnowlarityDataWithIvr} from "../../dataParser/getListData";
import {changeDate, cleanStorage} from "../../helper";
import Backdrop from '@material-ui/core/Backdrop';
import 'react-h5-audio-player/lib/styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";
import incomingC from '../../assets/image/incoming_connected.png';
import incomingM from '../../assets/image/incoming_missed.png';
import outC from '../../assets/image/outgoing_connect.png';
import outM from '../../assets/image/outgoing_missed.png';
import { fieldHiddenSales } from '../../config/constant/crmRoleFunctionPermission.js';

class DetailLead extends Component {

	constructor(props) {
    	super(props);
    	this.state = {
      		isLoading : false,
      		showLoader : false,
      		commentState : '',
      		meta : {},
      		data : [],
      		commentStuts : false,
      		InternalStuts : false,
      		pageId : '',
      		knowlarityPageId : '',
      		showCount : 0,
      		knowlarityCount : 0,
      		knowlarityMeta: {},
      		knowlarityData: [],
      		knowlarityStuts: false,
      		isKnowlarity: false,
      		showKnowlarityLoader: false,
      		role: window.$role
    	};
  	}

 //  	componentDidMount() {
 //    	let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
 //            // console.log(homesfy_lg);
 //            if(homesfy_lg && homesfy_lg !== ""){
 //                // console.log("===========Local in the sidebar ==========")
 //                let user = JSON.parse(homesfy_lg);
 //                this.setState({
 //                    role : user.role,
 //                });
 //            }
	// }

	leadInternalCall = async (id)=>{

      	this.setState({isInternal : true});
		(async () => {
            try {
                let resData = await getLeadinternalHistroyData(id);
                if(resData.meta.status == 200){
                    await this.setState({
                            meta: resData.meta,
                            data : resData.data,
	 						InternalStuts : true,
                            isInternal : false,
                            showLoader : false
                        })
                    window.scrollTo(0, 0);
                }else{
                    this.setState({
                        meta: resData.meta,
                        data : resData.data,
 						InternalStuts : true,
                        isInternal : false,
                        showLoader : false
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();

	}
	leadKnowlarityCall = async (id)=>{

      	this.setState({isKnowlarity : true});
      	let listData = {};
        listData.object_id = id;
		listData.leadId = id;
        listData.size = 4;
        listData.pageId = 1;
		(async () => {
			let finalLogRes = [];
			let resData = null;
			let incomingIVrLog = null;
			try {
				resData = await getKnowlarityData(listData);
				incomingIVrLog = await getKnowlarityDataWithIvr(listData);
				if(incomingIVrLog.meta.status === 200){
					finalLogRes = [...incomingIVrLog.data.rows];
				}
			} catch (error) {
				incomingIVrLog = await getKnowlarityDataWithIvr(listData);
				if(incomingIVrLog.meta.status === 200){
					finalLogRes = [...incomingIVrLog.data.rows];
				}
			}

			if(resData.meta.status == 200 || incomingIVrLog.meta.status === 200){
				if(resData.meta.status == 200){
					finalLogRes = [...finalLogRes,...resData.data.rows];
				}
				console.log(finalLogRes)
				await this.setState({
						knowlarityMeta: resData.meta,
						knowlarityData : finalLogRes,
						knowlarityStuts : true,
						isKnowlarity : false,
						showKnowlarityLoader : false,
						knowlarityPageId : listData.pageId,
						knowlaritySize : listData.size,
						knowlarityCount : resData.meta.status == 200 ? resData.data.count : 0,
					})
				window.scrollTo(0, 0);
			}else{
				this.setState({
					knowlarityMeta: {},
					knowlarityData : [],
					knowlarityStuts : true,
					isKnowlarity : false,
					showKnowlarityLoader : false
				})
			}

        })();
	}

	showMoreleadKnowlarityCall = async (id)=>{

      	this.setState({isLoading : true});
		let moreAudioData = {};
		// console.log("YYYYYYYYYYYYYYY=====>",leadId);

        let listData = {};
		listData.object_id = id;
        listData.size = 4;
        listData.pageId = this.state.knowlarityPageId+1;

		let showMoreAudio = await getKnowlarityData(listData);

		// console.log("XXXXXXXXXXXXXXXXXXXX=====>",showMoreAudio);
		if(showMoreAudio.meta.status === 200){

			this.setState({
	        	isLoading : false,
	 			knowlarityMeta: showMoreAudio.meta,
				knowlarityPageId : listData.pageId,
		        knowlarityData : [...this.state.knowlarityData,...showMoreAudio.data.rows],
				knowlarityCount : showMoreAudio.data.count,
				knowlaritySize : listData.size + this.state.knowlaritySize,
	        })
	        this.setState(prevState=>({
	        }))
		}else if(showMoreAudio.meta.status === 401){

    		cleanStorage();
    		this.props.history.push("/login");

    	}
		else{

			this.setState({
	        	knowlarityData : showMoreAudio.data.rows,
	        	knowlarityMeta : showMoreAudio.meta,
	        	isLoading : false
	        })
		}
	}

	commentsData = async (id)=>{

      	this.setState({isLoading : true});
		// let commentRes = {};
		// let commentReq = {};
		let leadId = id;
		// console.log("YYYYYYYYYYYYYYY=====>",leadId);

        let data = {};
        data.size = 4;
        data.pageId = 0;
        // listData.filters = [];

        // commentReq.id = leadId;
        // commentReq.data = listData;

		let commentRes = await getCommentData({id});

		// console.log("XXXXXXXXXXXXXXXXXXXX=====>",commentRes);
		if(commentRes.meta.status === 200){

			this.setState({
	        	commentState :commentRes,
	 			commentStuts : true,
	 			pageId : commentRes.meta.pageId,
	 			showCount : commentRes.meta.count < commentRes.meta.size ? commentRes.meta.count : commentRes.meta.size,
	        	isLoading : false,
	        })
		}
		else{

			this.setState({
	        	commentState :commentRes,
	        	isLoading : false
	        })
		}
	}

	showMoreCommentsData = async (id)=>{

      	this.setState({isLoading : true});
		let showMoreCommentRes = {};
		let showMoreCommentReq = {};
		let leadId = id;
		// console.log("YYYYYYYYYYYYYYY=====>",leadId);

        let listData = {};
        listData.size = 4;
        listData.pageId = this.state.pageId+1;
        listData.filters = [];

        showMoreCommentReq.id = leadId;
        showMoreCommentReq.data = listData;

		showMoreCommentRes = await getCommentData(showMoreCommentReq);

		// console.log("XXXXXXXXXXXXXXXXXXXX=====>",showMoreCommentRes);
		if(showMoreCommentRes.meta.status === 200){

			this.setState({
	        	commentState :showMoreCommentRes,
	        	isLoading : false,
	 			commentStuts : true,
	 			pageId : showMoreCommentRes.meta.pageId,
	 			showCount : showMoreCommentRes.meta.size + this.state.showCount,
	        })
		}else if(showMoreCommentRes.meta.status === 401){

    		cleanStorage();
    		this.props.history.push("/login");

    	}
		else{

			this.setState({
	        	commentState :showMoreCommentRes,
	        	isLoading : false
	        })
		}
	}

	handleClose =(e)=>{

  		// console.log("=====================in detail close form====================");
  		this.setState({
	    	showLoader : false,
      		commentState : '',
      		commentStuts : false,
      		InternalStuts : false,
      		knowlarityStuts : false,
      		meta : {},
      		data : [],
      		pageId : '',
      		showCount : 0,
	    });
	}
	callIcon(obj){
		let imageUrl = null;
		if(!obj.CallLog){
			if(Number.isInteger(Number(obj.caller))){
				imageUrl = incomingC;
			}else{
				imageUrl = incomingM;
			}
		}else{
			if(obj.call_transfer_status === 'Connected'){
				imageUrl = outC;
			}else{
				imageUrl = outM;
			}
		}
		return imageUrl;
	}

    render() {

    	let leadDetail = this.props.leadDetailProp;
    	let leadDetailMeta = leadDetail.meta;
    	let leadDetailData = leadDetail.data;

    	const {commentState,commentStuts,showCount,isLoading,data,meta,InternalStuts, role} = this.state;
    	// console.log("wwwwwwwwwwwwwwwwwwwwwww",leadDetailData);
    	// console.log("==============-------------->>>>>",showCount);
    	let commentStateMeta = commentState.meta;
    	let commentStateData = commentState.data;
		// {commentStateMeta.status == 200 ?

		let clientnumber =(leadDetailData?.Client?.number) ? leadDetailData.Client.number : "";

		if(window.$role ==='mediamanagers')
		{
			var number = clientnumber.replace(/\d(?=\d{4})/g, "*");

			leadDetailData.Client.number =number
		}
		else {
			clientnumber =leadDetailData?.Client?.number;
		}

        return (
            <Frag>
 				<Modal
 				  show={this.props.show}
 				  onHide={(e)=>{this.props.changeModal(); this.handleClose();}}
			      size="xl"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			          Lead Details
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			      	{leadDetailMeta.status == 200 ?
			      	<div className="detailLead">
			      		<div className="row">
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">LeadID : 	</span>
		                                {leadDetailData.id !=null  ?
		                                	<span className="valueText">{leadDetailData.id}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Name : </span>
		                                {leadDetailData.Client !=null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.Client.name !=null ? leadDetailData.Client.name : "-" }</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Country Code : </span>
		                                {leadDetailData.Client != null ?
		                                	<span className="valueText">{leadDetailData.Client.country_code != null && leadDetailData.Client.country_code != "" ? (leadDetailData.Client.country_code.includes('+') ? leadDetailData.Client.country_code : '+' + leadDetailData.Client.country_code) : '+91' }</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row" id="clientnumber">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Number : </span>
		                                {leadDetailData.Client !=null  ?
		                                	<span className="valueText">{leadDetailData.Client.number !=null ? leadDetailData.Client.number : "-" }</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
							<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row"  >
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Alternate Number : </span>
		                                {leadDetailData.Client !=null  ?
		                                	<span className="valueText">{leadDetailData.Client.alternate_number !=null ? leadDetailData.Client.alternate_number : "-" }</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Nationality : </span>
		                                	<span className="valueText">{leadDetailData.Client.nationality !== null ? (leadDetailData.Client.nationality == 1 ? "Indian" : "NRI") : "-" }</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Email : </span>
		                                {leadDetailData.Client !=null ?
		                                	<span className="valueText ">{!!(leadDetailData.Client?.email) ? leadDetailData.Client.email : "-"}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>

			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Update Date : </span>
	                                	<span className="valueText">{leadDetailData.updated_at != null ? changeDate(leadDetailData.updated_at) : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Booking Date : </span>
	                                	<span className="valueText">{leadDetailData.Booking?.booking_date ? moment(leadDetailData.Booking.booking_date).format('ddd, ll') : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted text-capitalize">Agreement Value : </span>
	                                	<span className="valueText">{leadDetailData.ClientBudgetInfo?.agreement_value ? leadDetailData.ClientBudgetInfo.agreement_value : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Coming Date : </span>
		                                <span className="valueText">{leadDetailData.created_at != null ? changeDate(leadDetailData.created_at) : '-' }</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Lead Activity Status : </span>
		                                {leadDetailData.InternalStatusHistory != null ?
		                                	<span className="text-capitalize valueText">{leadDetailData.InternalStatusHistory.LeadInternalStatus != null ? leadDetailData.InternalStatusHistory.LeadInternalStatus.name : '-'}</span>
		                                	:
		                                	<span className="valueText">-</span>
                                		}
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Activity Date : </span>
		                                {leadDetailData.InternalStatusHistory != null ?
		                                	<span className="text-capitalize valueText">{leadDetailData.InternalStatusHistory.action_date != null ? changeDate(leadDetailData.InternalStatusHistory.action_date) : '-'}</span>
		                                	:
		                                	<span className="valueText">-</span>
                                		}
		                            </div>
		                        </div>
		                    </div>

			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Project Name : </span>
		                                {leadDetailData.Project !=null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.Project.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Alternate Project : </span>
		                                	<span className="valueText text-capitalize">{leadDetailData.AlternateProject?.name ? leadDetailData.AlternateProject?.name : "-"}</span>
									</div>
		                        </div>
		                    </div>
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Builder Name : </span>
		                                {leadDetailData.Project !=null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.Project.builder_name != null ? leadDetailData.Project.builder_name : '-'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Region : </span>
		                                {leadDetailData.Project !=null ?
		                                	<span className="valueText">{leadDetailData.Project.Region != null ? leadDetailData.Project.Region.name : '-'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    {!fieldHiddenSales.includes(role) &&
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Source : </span>
		                                {leadDetailData.LeadSource !=null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.LeadSource.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      			}
			      			{!fieldHiddenSales.includes(role) &&
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Added By : </span>
		                                {leadDetailData.LeadCreator != null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.LeadCreator.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
							}
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Revert Status: </span>
		                                {leadDetailData.revert_status != null ?
	                                		<span className="valueText text-capitalize"> {leadDetailData.revert_status === true ?  'Yes': 'No'} </span>
	                                		:
	                                		<span className="valueText text-capitalize">-</span>
	                                	}
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Reverted By Sales: </span>
	                                		<span className="valueText text-capitalize"> {leadDetailData.SalesRevertRm !== null ? leadDetailData.SalesRevertRm?.name : '-'} </span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Reverted By Presale: </span>
	                                		<span className="valueText text-capitalize"> {leadDetailData.PresaleRevertRm !== null ? leadDetailData.PresaleRevertRm?.name : '-'} </span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Lead Owner : </span>
		                                {leadDetailData.LeadOwner != null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.LeadOwner.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-capitalize text-muted">Lead State : </span>
		                                {leadDetailData.ObjectStage != null ?
		                                	<span className="text-capitalize valueText">{leadDetailData.ObjectStage.Stage.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
                                		}
		                            </div>
		                        </div>
		                    </div>

                    		{this.state.role !== "magnet" &&
				      			<div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">RM Name : </span>
			                                {leadDetailData.AssignLead != null  && leadDetailData.AssignLead.Assignee != null ?
			                                	<span className="valueText text-capitalize">{leadDetailData.AssignLead.Assignee.name}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                }
			      			{!fieldHiddenSales.includes(role) &&
			                <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Assigned By : </span>
		                                {leadDetailData.AssignLead != null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.AssignLead?.Assigneer?.name ? leadDetailData.AssignLead.Assigneer.name : '-'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
							}
			                <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Type : </span>
		                                {leadDetailData.AssignLead != null ?
		                                	<span className="valueText text-capitalize">{!!(leadDetailData.AssignLead.type) ? leadDetailData.AssignLead.type : '-'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			                <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Assigned Date : </span>
		                                <span className="valueText">{leadDetailData.AssignLead !=null ? changeDate(leadDetailData.AssignLead?.created_at) : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Follow Up Date : </span>
		                                <span className="valueText">{leadDetailData.InternalStatusHistory !=null ? changeDate(leadDetailData.InternalStatusHistory?.followup_date) : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Deleted By : </span>
		                                <span className="valueText">{leadDetailData.deleted_by != null ? leadDetailData.deleted_by : '-'}</span>
		                            </div>
		                        </div>
		                    </div>

			      			{!fieldHiddenSales.includes(role) &&
							  <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Is Magnet : </span>
		                                <span className="valueText">{leadDetailData.is_magnet == true ? 'Yes' : 'No'}</span>
		                            </div>
		                        </div>
		                    </div>
							}
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Closed/Booked : </span>
		                                <span className="valueText">{/*Tue,Mar 3,2020*/}-</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Visited Project : </span>
		                                <span className="valueText">{leadDetailData.InternalStatusHistory !== null ? (leadDetailData.InternalStatusHistory?.OtherProject?.name) : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Closing Reason : </span>
		                                <span className="valueText">{leadDetailData.closing_reason!= null ? leadDetailData.closing_reason:"-"}</span>
										{(leadDetailData.closing_reason!= null && leadDetailData.closing_reason === "Duplicate Lead" && leadDetailData.duplicate_lead_id) &&
									     	<span className="valueText">{` (${leadDetailData.duplicate_lead_id})`}</span>}
		                            </div>
		                        </div>
		                    </div>

		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Presale Lead : </span>
		                                {leadDetailData.is_presale !=null ?
		                                	<span className="valueText">{leadDetailData.is_presale === true ? 'Yes' : 'No'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
                    		{this.state.role !== "magnet" &&
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Pre Sales RM : </span>
			                                {leadDetailData.PresaleRm !=null ?
			                                	<span className="valueText text-capitalize">{leadDetailData.PresaleRm.name}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                }
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Cross Sales Lead : </span>
		                                <span className="valueText">{leadDetailData.is_crosssale == true ? 'Yes' : 'No'}</span>
		                            </div>
		                        </div>
		                    </div>
	                		{this.state.role !== "magnet" &&
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Closing RM : </span>
			                                {leadDetailData.ObjectStage.Stage.id === 7 || leadDetailData.ObjectStage.Stage.id === 9 ?
			                                	<span className="valueText text-capitalize">{leadDetailData.AssignLead != null && leadDetailData.AssignLead.Assignee.name}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                }


			      			{!fieldHiddenSales.includes(role) &&
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Magnet : </span>
		                                {leadDetailData.LeadMagnet !=null ?
		                                	<span className="valueText text-capitalize">{leadDetailData.LeadMagnet?.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
							}
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Loan Required : </span>
		                                <span className="valueText">{leadDetailData.loan_required === true ? 'Yes' : 'No'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Loan Amount : </span>
		                                {leadDetailData.loan_amount !=null ?
			                                <span className="valueText">{leadDetailData.loan_amount}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">UTM ID : </span>
		                                <span className="valueText">{leadDetailData.utm_id !=null ? leadDetailData.utm_id : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Digital ID : </span>
		                                <span className="valueText">{leadDetailData.digital_id !=null ? leadDetailData.digital_id : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Client Preference: </span>
		                                {leadDetailData.Digital !== null ?
			                                <span className="valueText">{leadDetailData.Digital.client_pref !== null ? leadDetailData.Digital?.client_pref : '-'}</span>
			                                :
			                                <span className="valueText">-</span>
		                            	}
		                            </div>
		                        </div>
		                    </div>
							<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Client Referal ID : </span>
		                                <span className="valueText">{leadDetailData.referal_lead_id !=null ? leadDetailData.referal_lead_id : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
							{!fieldHiddenSales.includes(role) &&
							<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Chat Source: </span>
		                                <span className="valueText">{leadDetailData.chat_source !=null ? leadDetailData.chat_source : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
	                        }
							<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row"  >
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Location of Client : </span>
		                                {leadDetailData.Client !=null  ?
		                                	<span className="valueText">{leadDetailData.Client.current_location !=null ? leadDetailData.Client.current_location : "-" }</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
							<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Closed Date : </span>
                                        { leadDetailData.ObjectStage.Stage.id === 9 ?                                                                                  
										   <span className="valueText">{leadDetailData.ObjectStage.created_at != null ? changeDate(leadDetailData.ObjectStage.created_at) : '-'}</span>
											:
		                                	<span className="valueText">-</span>
                                        }
		                            </div>
		                        </div>
		                    </div>
			      		</div>
			      		<div className="row mb-2">
			      			<div className="col-md-12 commnets">
	            				{/*<div className="p-2">
	            					<Link className="font-small" onClick={()=>this.leadInternalCall(leadDetailData.id)}>Lead Activity History</Link>
            					</div>*/}
			      				<button className="btn btn-info mr-3" onClick={()=>this.commentsData(leadDetailData.id)} disabled = {leadDetailData.total_comments === 0 && "disabled"}>Comments({leadDetailData.total_comments})</button>
		      				</div>
			      		</div>
		      			<div className="col-md-12 py-2 px-0">
		      				{isLoading ? (
			                  <CircularProgress color="primary" size={40} className="comment_loader"/>
			                ) : null}
				      		{commentStuts === true ?
				      			(commentStateMeta.status === 200  && commentStateData.length ?
					      			<div className="commentBox filter_lead">
					      				{commentStateData.map((comment,index) =>
								      		<div className="card shadow pt-2 pb-2 mb-2" key={index}>
								      			<div className="container">
									      			<div className="Comment_row row mb-2">
									      				<div className="col-md-5 col-12">
									      					<span className="font-small text-muted">User : </span>
									      					<span className="text-capitalize font-small valueText">{comment.User != null ? comment.User.name : "-"}</span>
									      				</div>
									      				<div className="col-md-4 col-12">
									      					<span className="font-small text-muted">Created Date : </span>
									      					<span className="valueText">{changeDate(comment.created_at)}</span>
									      				</div>
									      			</div>
								      			</div>
								      			<div className="d-flex">
								      				<div className="col-md-12">
								      					<span className="font-small text-muted">Comment : </span>
								      					<span className="textValue commentText">{comment.comments}</span>
								      				</div>
								      			</div>
								      		</div>
					      					)}
					      					{commentStateMeta.count != showCount ?
									      		<div className="col-md-12 commnets" onClick={()=> this.showMoreCommentsData(leadDetailData.id)}>
								      				<span className="mr-3 text-info">Show more</span>
							      				</div>
							      				:
							      				''
						      				}
							      		</div>
						      		:
					      			<div className="commentBox">
							      		<div className="card shadow pt-2 pb-2 mb-2">
							      			<div className="Comment_row d-flex mb-2">
							      				<div className="col-md-12">
							      					<span className="font-small">Ooops No comments!!!</span>
							      				</div>
							      			</div>
							      		</div>
						      		</div>
				      			)
					      		:
					      		''
					      	}
				      	</div>
				      	<div className="row mb-2">
			      			<div className="col-md-12 commnets">
			      				<button className="btn btn-info mr-3" onClick={()=>this.leadInternalCall(leadDetailData.id)}>Lead Activity History</button>
		      				</div>
			      		</div>
				      	<div className="col-md-12 p-0 commentBox filter_lead">
		      				{this.state.isInternal ? (
			                  <CircularProgress color="primary" size={40} className="comment_loader"/>
			                ) : null}
				      		{InternalStuts === true ?
				      			(meta.status === 200 && data.length ?
		                            (data.map((comment,index) =>
		                                <div className="card shadow pt-2 pb-2 mb-2" key={index}>
		                                    <div className="container">
		                                        <div className="Comment_row row mb-2">
		                                            <div className="col-md-3 col-12">
		                                                <span className="font-small text-muted">Action By : </span>
		                                                <span className="text-capitalize font-small valueText">{comment.ActivityActionUser != null ? comment.ActivityActionUser.name : "-"}</span>
		                                            </div>
		                                            <div className="col-md-3 col-12">
		                                                <span className="font-small text-muted">Lead Activity Status : </span>
		                                                <span className="text-capitalize font-small valueText">{comment.LeadInternalStatus != null ? comment.LeadInternalStatus.name : "-"}</span>
		                                            </div>
		                                            <div className="col-md-2 col-12">
		                                                <span className="font-small text-muted">Companion : </span>
		                                                <span className="text-capitalize font-small valueText">{comment.companion != null ? comment.companion : "-"}</span>
		                                            </div>

		                                            <div className="col-md-4 col-12">
		                                                <span className="font-small text-muted">Action Date : </span>
		                                                <span className="valueText">{comment.action_date !== null ? changeDate(comment.action_date) : "-"}</span>
		                                            </div>
		                                            {/*
		                                            <div className="col-md-4 col-12">
		                                                <span className="font-small text-muted">Follow Up Date : </span>
		                                                <span className="valueText">{comment.followup_date !== null ? changeDate(comment.followup_date) : "-"}</span>
		                                            </div>
		                                        	*/}
		                                        </div>
		                                        <div className="row">
		                                            <div className="col-md-12">
		                                                <span className="font-small text-muted">Comment : </span>
		                                                <span className="textValue commentText">{comment.comment !== null ? comment.comment : "-"}</span>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                            ))
		                            :
		                            <div className="commentBox">
							      		<div className="card shadow pt-2 pb-2 mb-2">
							      			<div className="Comment_row d-flex mb-2">
							      				<div className="col-md-12">
							      					<span className="font-small">History not available !!!</span>
							      				</div>
							      			</div>
							      		</div>
						      		</div>
		                        )
					      		:
					      		''
					      	}
				      	</div>
				      	<KnowlarityList
				      		isLoading= {this.state.isKnowlarity}
				      		meta={this.state.knowlarityMeta}
				      		data={this.state.knowlarityData}
				      		knowlarityStuts={this.state.knowlarityStuts}
				      		knowlarityCount={this.state.knowlarityCount}
				      		knowlaritySize={this.state.knowlaritySize}
				      		leadId={leadDetailData.id}
				      		leadKnowlarityCall={this.leadKnowlarityCall}
				      		showMoreleadKnowlarityCall={this.showMoreleadKnowlarityCall}
							callIcon={this.callIcon}
			      		/>
			      	</div>
			      	:
			      		<div className="row">
			      			<div className="col-md-12">
			      				<span className="mr-2">Couldn't get detail, Please try again !!!</span>
		      				</div>
			      		</div>
			      	}
			      </Modal.Body>
			      <Backdrop className="backDoor" open={this.state.showLoader}>
			        <CircularProgress color="inherit" />
			      </Backdrop>
			    </Modal>
            </Frag>
        );
    };
};

export default withRouter(DetailLead);

const KnowlarityList= (props)=>{
	document.addEventListener('play', function(e){
	    var audios = document.getElementsByTagName('audio');
	    for(var i = 0, len = audios.length; i < len;i++){
	        if(audios[i] != e.target){
	            audios[i].pause();
	        }
	    }
	}, true);

	return(
		<>
			<div className="row mb-2 mt-3">
	  			<div className="col-md-12 commnets">
	  				<button className="btn btn-info mr-3" onClick={()=>props.leadKnowlarityCall(props.leadId)}>Load Audio</button>
				</div>
	  		</div>
			<div className="col-md-12 py-2 px-0">
				{props.isLoading ? (
	              <CircularProgress color="primary" size={40} className="comment_loader"/>
	            ) : null}
	      		{props.knowlarityStuts === true ?
	      			(props.data.length ?
		      			<div className="commentBox filter_lead">
		      				{props.data.map((knowlarity,index) =>
					      		<div className="card shadow pt-2 pb-2 mb-2" key={index}>
					      			<div className="container">
						      			<div className="Comment_row row justify-content-center align-items-center">
						      				<div className="col-md-3 col-12">
						      					<span className="font-small text-muted">Call Status : </span> {props.callIcon(knowlarity) ? <img src={props.callIcon(knowlarity)} alt="Call Icon"/> : ''}
						      					<span className="text-capitalize font-small valueText">{knowlarity?.CallLog ? knowlarity.call_status : Number.isInteger(Number(knowlarity?.caller)) ? 'Conected' : knowlarity?.caller}</span>
						      				</div>
						      				<div className="col-md-3 col-12">
						      					<span className="font-small text-muted">Call Date-Time : </span>
						      					<span className="valueText">{(knowlarity.call_date)},   {knowlarity?.call_time ? moment(knowlarity.call_time, 'HH:mm:ss').format('h:mm:ss A'): ''}</span>
						      				</div>
						      				<div className="col-md-3 col-12">
						      					<span className="font-small text-muted">{!knowlarity?.CallLog ? 'Caller Number' : 'Caller Name' }: </span>
						      					<span className="valueText text-capitalize">{knowlarity?.CallLog ? knowlarity.CallLog?.agentInfo?.name: knowlarity.caller}</span>
						      				</div>
											<div className="col-md-3 col-12">
						      					<span className="font-small text-muted">Calling Type : </span>
						      					<span className="valueText text-capitalize">{knowlarity?.calling_type}</span>
						      				</div>
						      				<div className="col-md-3 col-12">
						      					<audio controls controlsList="nodownload">
												  	<source
													  	src={knowlarity.recording_url}
													  	type="audio/mpeg"
											  		/>
												</audio>
						      				</div>
						      			</div>
					      			</div>

					      		</div>
		      					)}
		      					{props.knowlarityCount >= props.knowlaritySize ?
						      		<div className="col-md-12 commnets" onClick={()=> props.showMoreleadKnowlarityCall(props.leadId)}>
					      				<span className="mr-3 text-info">Show more</span>
				      				</div>
				      				:
				      				''
			      				}
				      		</div>
			      		:
		      			<div className="commentBox">
				      		<div className="card shadow pt-2 pb-2 mb-2">
				      			<div className="Comment_row d-flex mb-2">
				      				<div className="col-md-12">
				      					<span className="font-small">Ooops No Audio Found!!!</span>
				      				</div>
				      			</div>
				      		</div>
			      		</div>
	      			)
		      		:
		      		''
		      	}
	      	</div>
	  	</>
	)
}
