import React, {Component} from "react";

import siteImage from "../../assets/image/HomesfyLogo.jpg";
import "./forget.css";
import {AMAZON_URL, APP_BASE_URL} from "../../config/serverKey.js"
import {Link} from "react-router-dom";
import "../../assets/css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMobileAlt, faLock} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../component/common/loader/loader.js";
import { getFrogetPwd, getChangePwdRes, sendOtp } from "../../dataParser/auth";
import { userLogin } from "../../dataParser/auth.js";
import { getEmailValidation } from "../../dataParser/magnetUser";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import { GROUPS,ROLES } from "../../config/constant";

class Forget extends Component {

	constructor(props) {
	    super(props);

		this.state = {
			mobile_number: "",
			showLoader: false,
			error: false,
			meta: "",
			password: "",
			otp: "",
			sweetShow: false,
			type: "success",
			title: "",
			confirmpassword: "",
			token: null
		};
	}
	async componentDidMount() {
		const token = this.props.match.params.token;
		if (token) {
			let resData = await getEmailValidation(token);
			if (resData.meta.status === 201) {
				if (resData.data.res) {
					await this.setState({ token: token, mobile_number: resData.data.res.phone });
					await this.forgetPass();
				}
				// this.setState({
				// 	showLoader:false,
				// 	sweetShow:true,
				// 	type : "success",    
				// 	title : "Email varification Done" 
				// });
			}
		}
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	getForgetRes = async e =>{
		e.preventDefault();

	    this.setState({ showLoader : true});
	   	let forgetData = {};
	   	forgetData.phone = this.state.mobile_number;

	   	if(forgetData.phone.length === 10 && forgetData.phone.length !=""){

		    let resData = await getFrogetPwd(forgetData);
	    	if(resData.meta.status === 201){
	    		this.setState({ 
		    		showLoader : false,
	    			meta : resData.meta,
		    		sweetShow:true,
                    type : "success",    
                    title : "OTP sent to the registered mobile number",
		    	});
		    }else{
		    	this.setState({
		    		showLoader : false,
		    		error : "Phone number not found !!!"
		    	})
		    }

	   	}else{
	   		this.setState({
	    		showLoader : false,
   				error : "please enter valid mobile number"
	    	})
	   	}
	}

	getChangeRes = async e =>{
		e.preventDefault();

		const changePwdRequest = (({ mobile_number, password, confirmpassword,otp }) => ({
	      mobile_number,
	      password,
	      confirmpassword,
	      otp
	    }))(this.state);

	    let data = {};
		data["password"] = changePwdRequest.password	    
		data["phone"] = changePwdRequest.mobile_number	    
		data["otp"] = changePwdRequest.otp	    
		if (changePwdRequest.password != "" && changePwdRequest.password.length >= 6 && changePwdRequest.confirmpassword != "" && changePwdRequest.confirmpassword.length >= 6 && changePwdRequest.password === changePwdRequest.confirmpassword) {
			this.setState({ showLoader: true });
			let resData = await getChangePwdRes(data);
			if (resData.meta.status === 201) {
				
				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Password reset successfully!!!",
					isChangePass: true
				});
				if(this.state.token){
					await this.onLogin(this.state.mobile_number,this.state.password);
					
				}
			}
			else {

				if(resData.meta.status === 201){
					this.setState({ 
						showLoader : false,
						sweetShow:true,
						type : "success",    
						title : "Password reset successfully!!!",
						isChangePass : true
					});
				}
				else{
					
					this.setState({
						showLoader : false,
						error : resData.meta.message
					})
				}
			}
	    }else{
	    	this.setState({
	    		showLoader : false,
	    		error : "*please enter same password as above !!!",
	    	})
	    }
	}

	handleSweet = () => {
		// console.log("type On the first =========>",type)
		this.setState({ sweetShow: !this.state.sweetShow });
		if (this.state.isChangePass === true) {
			window.location = APP_BASE_URL + "/login";
		}
	}
	handleClick = async (e) => {
		e.preventDefault();
		await this.forgetPass();
	};
	onLogin = async (phone,password) => {
		const loginRequest = {phone:phone,password:password};

		if (
			loginRequest.phone !== "" &&
			loginRequest.phone.length === 10 &&
			loginRequest.password !== "" &&
			loginRequest.password.length >= 6
		) {
			console.log("loginRequest >>>>>>>>",loginRequest);
			loginRequest.phone = parseInt(loginRequest.phone);
			this.setState({ showLoader: true });
			var resData = await userLogin(loginRequest);
			console.log("resData >>>>>>>>>",resData);
			if (resData.meta.status === 200) {
				resData.data.userData.role = ROLES[resData.data.userData.role]
					.split("_")
					.join("")
					.toLowerCase();
				resData.data.userData.group = GROUPS[
					resData.data.userData.group
				].toLowerCase();
				// console.log(resData.data)
				let time = new Date();
				resData.data.userData.expire = time.setMinutes(time.getMinutes() + 60);

				window.$user = resData.data.userData;
				window.$role = window.$user.role.toLowerCase();
				localStorage.setItem(
					"homesfy_lg",
					window.btoa(JSON.stringify(resData.data.userData))
				);
				this.setState({
					showLoader: false,
				});
				return true;
			}else{
				return false;
			}
		}
	};
	forgetPass = async () => {
		this.setState({ showLoader: true });
		let forgetData = {};
		forgetData.phone = this.state.mobile_number;

		if (forgetData.phone.length === 10 && forgetData.phone.length != "") {

			let resData = await getFrogetPwd(forgetData);
			if (resData.meta.status === 201) {
				this.setState({
					showLoader: false,
					meta: resData.meta,
					sweetShow: true,
					type: "success",
					title: "OTP sent to the registered mobile number",
				});
			} else {
				this.setState({
					showLoader: false,
					error: "Phone number not found !!!"
				})
			}

		} else {
			this.setState({
				showLoader: false,
				error: "please enter valid mobile number"
			})
		}
	}
	render() {

		const { error, meta, sweetShow, type, title } = this.state;
		// let logoImg = this.state.token ? `${AMAZON_URL}/logo.svg` : siteImage;
		let logoImg = `${AMAZON_URL}/logo.svg`
		return (
			<div className="login-page">
                <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
	            <Loading show={this.state.showLoader} />
				<div className="login-box">
					<div className="card">
						<div className="card-header p-0">
							<div className="login-logo pt-3 pb-3">
								<img className="form_img" src={logoImg} alt="site-image" />
							</div>
						</div>
						{meta.status === 201 ?

							<div className="card-body login-card-body">
								<p className="login-box-msg"> {this.state.token ? "You are only one step away from starting your Homesfy journey" : "You are only one step a way from your new password, recover your password now."}</p>

								<form action="login.html" method="post">
									<div className="input-group mb-3">
										<input
											type="text"
											className="form-control"
											placeholder="Mobile Numbert"
											value={this.state.mobile_number}
											name="mobile_number"
											required
											autoComplete="off"
										/>
										<div className="input-group-append">
											<div className="input-group-text">
												<FontAwesomeIcon icon={faMobileAlt} className="text-success" />
											</div>
										</div>
									</div>
									{this.state.token && <div className="input-group mb-3">
										<input
											type="text"
											name="otp"
											onChange={this.onChange}
											className="form-control"
											placeholder="OTP"
											required
											autoComplete="off"
										/>
										<div className="input-group-append">
											<div className="input-group-text">
												<FontAwesomeIcon icon={faLock} className="text-success" />
											</div>
										</div>
									</div>}
									<div className="input-group mb-3">
										<input
											type="password"
											name="password"
											className="form-control"
											placeholder="New Password"
											onChange={this.onChange}
											required
											autoComplete="off"
										/>
										<div className="input-group-append">
											<div className="input-group-text">
												<FontAwesomeIcon icon={faLock} className="text-success" />
											</div>
										</div>
									</div>
									<div className="input-group mb-3">
										<input
											type="password"
											name="confirmpassword"
											onChange={this.onChange}
											className="form-control"
											placeholder="Confirm Password"
											required
											autoComplete="off"
										/>
										<div className="input-group-append">
											<div className="input-group-text">
												<FontAwesomeIcon icon={faLock} className="text-success" />
											</div>
										</div>
									</div>
									{!this.state.token && <div className="input-group mb-3">
										<input
											type="text"
											name="otp"
											onChange={this.onChange}
											className="form-control"
											placeholder="OTP"
											required
											autoComplete="off"
										/>
										<div className="input-group-append">
											<div className="input-group-text">
												<FontAwesomeIcon icon={faLock} className="text-success" />
											</div>
										</div>
									</div>}
									<div className="row">
										{this.state.token ?
											<div className="col-12">
												<button type="submit" className="btn btn-submit btn-block" onClick={this.getChangeRes}>Set Password</button>
											</div>
											:
											<div className="col-12">
												<button type="submit" className="btn btn-success btn-block" onClick={this.getChangeRes}>Change password</button>
											</div>
										}

									</div>
								</form>

								<p className="mt-3 mb-1">
									{this.state.token && <a href="#" style={{ marginLeft: 36 }} onClick={this.handleClick}>Resend Otp</a>} &nbsp;
									{this.state.token ? <Link to="/login" style={{ marginLeft: 52 }}>Login</Link> : <Link to="/login">Login</Link>}
								</p>

								<p className="mb-0">
									<span className="text-center text-danger small">{error}</span>
								</p>
							</div>
							:
							<div className="card-body login-card-body">
								<p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>

					      <form action="#" method="#">
					        <div className="input-group mb-3">
					          	<input 
					          		type="text" 
					          		className="form-control" 
					          		placeholder="Mobile Number" 
					          		name = "mobile_number"
		                            onChange={this.onChange}
                          			value={this.state.mobile_number} 
                          			required	
				          		/>
					          <div className="input-group-append">
					            <div className="input-group-text">
					              <FontAwesomeIcon icon={faMobileAlt} className="text-success" />
					            </div>
					          </div>
					        </div>
					        <div className="row">
					          <div className="col-12">
					            <button type="submit" className="btn btn-success btn-block" onClick={this.getForgetRes}>Request new password</button>
					          </div>
					        </div>
					      </form>

					      <p className="mt-3 mb-1">
					        <Link to="/login">Login</Link>
					      </p>
					      <p className="mb-0">
					        <span className="text-center text-danger small">{error != '' ? error : ''}</span>
					      </p>
					    </div>
					}    
					 </div>
				</div>	 
			</div>	 
		);

	};
};

export default Forget;