import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import AutoAssignUpdate from "../form/autoAssignUpdate.js";
import AddAutoAssignForm from "../form/addAutoAssign.js";
import AssignRmForm from "../form/form.js";


class AutoAssignModel extends Component {
    render() {
        const FROMS_TYPES = {
			"addAutoAssign" : AddAutoAssignForm,
			"updateAutoAssign" : AutoAssignUpdate,
		}
		const Form_name = FROMS_TYPES[this.props.fromType];

        return (
            <Frag>
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size="lg"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			          	{Form_name === AutoAssignUpdate ? "Update Auto Assign Lead" : "Add Auto Assign Lead"}
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			        <Form_name changeModal={this.props.changeModal} getList={this.props.getList} formData={this.props.formData}/>
			      </Modal.Body>
			    </Modal>
            </Frag>
        );
    };
};    

export default AutoAssignModel;