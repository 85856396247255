import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, CssBaseline, Drawer } from "@material-ui/core";
import { MAGNET_APP_URL, mgHeader } from "./mgData";
import { AMAZON_URL, APP_BASE_URL } from "../../config/serverKey";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    brandLogo: {
        flexGrow: 1,
        maxWidth: "45%"
    },
    deskBlock: {
        display: "none",
        [theme.breakpoints.up("md")]: { display: "flex" }
    },
    mobBlock: {
        display: "flex",
        [theme.breakpoints.up("md")]: { display: "none" }
    }
}));

const HOMESFY_LOGO = "https://refrance.s3.ap-south-1.amazonaws.com/homesfy/HomesfyLogo.svg";
const HOMESFY_APP_URL = "https://www.homesfy.in"
export default function MgRegisterHeader() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [magnetDomain, setDomain] = useState(false);
    useEffect(() => {
        let DYNAMIC_METADATA_URL = window.location.host
        if (DYNAMIC_METADATA_URL.includes("mymagnet.io")) {
            setDomain(true)
        }
    })

    const handleDrawer = () => {
        setOpen(true);
    };
    return (
        <>
        {!magnetDomain ?
            <div className={classes.root}>
                <CssBaseline />
                <Box boxShadow={1}>
                    <AppBar position="static" className="bg-light pt-2 pb-3 pl-5 pr-3">
                        <Toolbar>
                            <a target="_blank" href={HOMESFY_APP_URL} className={classes.brandLogo}>
                                <img
                                    className="brandImg"
                                    src={HOMESFY_LOGO}
                                    alt="small_site_logo"
                                />
                            </a>
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>
            :
            <div className={classes.root}>
                <CssBaseline />
                <Box boxShadow={1}>
                    <AppBar position="static" className="bg-light pt-2 pb-3 pl-5 pr-3">
                        <Toolbar>
                            <a target="_blank" href={MAGNET_APP_URL} className={classes.brandLogo}>
                                <img
                                    className="brandImg"
                                    src={AMAZON_URL + "/logo.svg"}
                                    alt="small_site_logo"
                                />
                            </a>
                            {/* <div className={classes.deskBlock}>
                                {
                                    mgHeader.map((item, i) => (
                                        <Button key={i}><a target="_blank" href={`${MAGNET_APP_URL}/#${item.link}`} className="headerLink">{item.heading}</a></Button>
                                    ))
                                }
                                <Button variant="contained" className="ml-5 bg-mg"><a target="_blank" href={`${APP_BASE_URL}/login`} className="px-3 text-white">Sign In</a></Button>
                            </div>
                            <IconButton
                                onClick={handleDrawer}
                                edge="start"
                                className={`${classes.menuButton} ${classes.mobBlock} menuIcon`}
                                color="inherit"
                                aria-label="menu"
                            >
                                <MenuIcon />
                            </IconButton> */}
                        </Toolbar>
                    </AppBar>
                </Box>
                {/* <Drawer
                    anchor="right"
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    <div style={{ height: "100vh", padding: "20px" }}>
                        {
                            mgHeader.map((item, i) => (
                                <div key={i}>
                                    <Button><a target="_blank" href={`${MAGNET_APP_URL}/#${item.link}`} className="headerLink">{item.heading}</a></Button>
                                </div>
                            ))
                        }
                        <div>
                            <Button variant="contained" className="ml-3 mt-2 px-4 bg-mg"><a target="_blank" href={`${APP_BASE_URL}/login`} className="text-white">Sign In</a></Button>
                        </div>
                    </div>
                </Drawer> */}
            </div >
    }
        </>
    );
}
