import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp, faLongArrowAltDown, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Frag from '../../../utils/Frag/Frag';
import Tooltip from '@material-ui/core/Tooltip';

const RatioCard = ({ data }) => {
    return (
        <Tooltip title={<div className="customTooltip">{`${data.from.long} to ${data.to.long}`}</div>}>
            <div className="card RatioCard py-3 align-items-center shadow-sm bg-white text-capitalize">
                {
                    data.label ?
                        <div className="dashboard_label">{data.label}</div>
                        :
                        <div className="dashboard_label">{data.from.short} <FontAwesomeIcon icon={faLongArrowAltRight} /> {data.to.short}</div>
                }
                <div className="Cvalue text-bold">{data?.numerator ? `${data.value} (${data?.numerator})`: data.value}</div>
                <div className="CPercent">
                    {data.peak.type === "high"
                        ?
                        <Frag>
                            <span className="bg-white"><FontAwesomeIcon icon={faLongArrowAltUp} className="text-success" />&nbsp;</span>
                            <span className="text-success"> {data.peak.value}</span>
                        </Frag>
                        : <Frag>
                            <span className="bg-white"><FontAwesomeIcon icon={faLongArrowAltDown} className="text-danger" />&nbsp;</span>
                            <span className="text-danger"> {data.peak.value}</span>
                        </Frag>
                    }
                </div>
                {data.denominator ? <div className="Cvalue text-bold">{data.to.long === "followup" ? "Follow-Up" : data.from.long} Count : {data.denominator}</div> : ""}
            </div>
        </Tooltip>
    )
}

export default RatioCard
