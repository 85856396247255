import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../component/common/loader/loader";
import { getKnowlarityData } from "../../dataParser/getListData"
import { REVERT_STAGES , REVERT_GROUPS , REVERT_ROLES } from "../../config/constant";
import Frag from "../../utils/Frag/Frag";
import ListingView, { OneSixthField } from '../common/listing/ListingView'
import moment from 'moment';
import Pagination from "react-js-pagination";
import KnowlarityFilter from "../filter/knowlarityFilter";
import { cleanStorage } from '../../helper';
class KnowlarityCallLog extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showLoader: false,
			data: [],
			activePage: 1,
			showFilter: false,
			alertShow: false,
			filters: {},
			loggedInUser:'',
			role :'',
			group :''
		}
		this.fltr = React.createRef()
	}

	componentDidMount() {
		
		this.getUserDetail()
		let data = {};
		data.size = 24;
		data.pageId = 1;
		this.getListData(data)
	}

	handleChangePage = async (pageNumber) => {
		this.setState({
			showLoader: true,
			activePage: pageNumber
		})
		let listData = {};
		listData.size = 24;
		listData.pageId = pageNumber;
		Object.assign(listData, this.state.filters)
		this.getListData(listData)
	}

	getUserDetail = async () => {
		let homesfy_lg = localStorage.getItem("homesfy_lg")
		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg))
			await this.setState({
				loggedInUser: user.id,
				role : user.role,
				group : user.group
			});
		}
	}
	getListData = async (params) => {
		this.setState({ showLoader: true, });
		(async () => {
			try {
				if(params){
					Object.assign(params,{user_id:window.$user.id,role_id:REVERT_ROLES[window.$user.role.toUpperCase()],group_id: REVERT_GROUPS[window.$user.group.toUpperCase()]});	
				}
				let resData = await getKnowlarityData(params);
				if (resData.meta.status === 200) {
					resData.meta.count = resData.data.count;
					resData.meta.size = 24;
					await this.setState({
						showLoader: false,
						meta: resData.meta,
						data: resData.data.rows,
					});
					window.scrollTo(0, 0);

				}
				else if (resData.meta.status == 401) {
					cleanStorage();
					this.props.history.push("/login");
				}
				else {
					this.setState({
						showLoader: false,
						meta: resData.meta,
						data: resData.data,
					})
				}
			} catch (e) {
				//...handle the error...
			}
		})();

	}
	pad(num) {
		return ("0" + num).slice(-2);
	}
	hhmmss(secs) {
		var a = secs.split(':'); // split it at the colons
		var secs = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		var minutes = Math.floor(secs / 60);
		secs = secs % 60;
		var hours = Math.floor(minutes / 60)
		minutes = minutes % 60;
		return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
		// return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
	}
	handlePopUp = () => {
		this.setState(prevState => ({
			showFilter: !prevState.showFilter,
		}));
	}
	handleClose = () => {
		this.setState({ alertShow: false });
	}
	handleFilter = async (filterData) => {
		this.setState({ showLoader: true })
		this.setState({ filters: filterData, activePage: 1 })
		let listData = {};
		listData.size = 24;
		listData.pageId = 1;
		Object.assign(listData, filterData)
		this.getListData(listData);
	}
	render() {

		document.addEventListener('play', function (e) {
			var audios = document.getElementsByTagName('audio');
			for (var i = 0, len = audios.length; i < len; i++) {
				if (audios[i] != e.target) {
					audios[i].pause();
				}
			}
		}, true);

		const { showLoader, data } = this.state
		const mainData = data;

		return (
			<Frag>
				<Loader show={showLoader} />
				<div className="p-3 leadMainHeader">
					<div className="headerCount">
						<span className="totalCount">Total IVR Logs : </span>
						<span className="">{this.state.meta?.status === 400 && this.state.data.length ? "0" : this.state.meta?.count}</span>
					</div>
					<div className="d-inline ml-3 filterHeader">
						<span className="filter" onClick={this.handlePopUp}>
							<FontAwesomeIcon icon={faFilter} className="nav-icon" />
						</span>
						<div className={"filterMenu" + " " + (this.state.showFilter ? 'filterMenuShow' : '')} ref={node => { this.node = node; }}>
							<KnowlarityFilter fltr={this.fltr} filterData={this.handleFilter} changeFilter={this.handlePopUp} />
						</div>
					</div>
				</div>
				<div className="listContainer">
					{mainData.length && mainData?.map((item, i) => (
						<ListingView key={i}>
							<OneSixthField title="Lead Id" value={item?.object_id || '-'} />
							<OneSixthField title="Knowlarity Number" value={item?.called_number || '-'} />
							<OneSixthField title="Agent Name" value={item?.CallLog?.agentInfo?.name || '-'} />
							<OneSixthField title="Agent Number" value={item?.agent_number || "-"} />
							<OneSixthField title="Client Number" value={item?.caller_number || '-'} />
							<OneSixthField title="Calling Type" value={item?.calling_type || '-'} />
							<OneSixthField title="Lead Status" value={REVERT_STAGES[item?.CallLog?.stage_id] || '-'} />
							<OneSixthField title="Call Start" value={moment(`${item?.call_date} ${item?.call_time}`).format("llll")} />
							<OneSixthField title="Call Status" value={item?.call_status} />
							<OneSixthField title="Duration" value={item?.conversation_duration ? this.hhmmss(item?.conversation_duration) : '-'} />
							<div className="col-md-4 col-12 order-1 order-md-0">
								{(item.recording_url && item?.recording_url !== "None") &&
									<audio controls controlsList="nodownload">
										<source
											src={item?.recording_url}
											type="audio/mpeg"
											/>
									</audio>
								}
							</div>
							<OneSixthField title="Vendor Type" value={item?.vendor ? "salestrail" : "knowlarity" || '-'} />
						</ListingView>
					))}
					<div className="container-fluid">
						<div className="row justify-content-end">
							{this.state.meta?.count > 24 ? (
								<div className="paginationBlock">
									<Pagination
										hideDisabled
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.meta?.size}
										totalItemsCount={this.state.meta?.count}
										pageRangeDisplayed={5}
										onChange={this.handleChangePage}
									/>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</Frag>
		)
	}
}

export default KnowlarityCallLog