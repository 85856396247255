import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { getRegion } from "../../../dataParser/getProjectData";
import { getRegionParentsData, getUpdateCpUser } from "../../../dataParser/getCpUserData";
import { getRoleList, autoComplete } from "../../../dataParser/commomDataApi";
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from "../../../component/common/loader/loader";
import { phoneCheck, emailCheck, nameCheck, letterCase, objEmpty, empty, cleanStorage } from "../../../helper";
import { showMagnetPlatinum, updateRolePermission } from "../../../config/constant/crmRoleFunctionPermission.js";
import FormControl from '@material-ui/core/FormControl';
import { DUMP_LEAD, REVERT_ROLES } from "../../../config/constant/index.js";
import PopUpAlert from "../sweetAlert/popUpAlert"

class UpdateUserFrom extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userName: [],
			region: '',
			multi_region: [],
			role: '',
			user_id: '',
			first_name: '',
			last_name: '',
			email: '',
			role_id: '',
			max_lead: '',
			dump_lead_count: '',
			overdue_count:'',
			city: '',
			region_id: '',
			phone: '',
			parent_region_ids: [],
			selectedVal: {},
			selectedRegionVal: [],
			old_phone: '',
			deleted_by: '',
			isLoading: false,
			sweetShow: false,
			showLoader: false,
			is_active: false,
			parent_id: [],
			type: "success",
			title: "",
			error: "",
			isError: {
				email: '',
				first_name: '',
				last_name: '',
				location: '',
				phone: '',
				old_phone: '',
				region_id: '',
				role_id: '',
				max_lead: '',
				dump_lead_count: '',
				overdue_count:'',
				newlead_count:'',
				booking_role_id:''
			},
			newlead_count:'',
			group: window.$user.group,
			alertShow: false,
            alertType : "success",
            alertTitle : "",
			booking_role_id:''
		};
	}

	async componentDidMount() {

		var homesfy_lg = (localStorage.getItem("homesfy_lg"));
		let addedBy;
		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg));
			addedBy = user.id;
			this.setState({ project_addedby: addedBy })

		} else {
			addedBy = '';
			this.setState({ project_addedby: addedBy })
		}

		var regionData = await getRegion();
		this.setState({ region: regionData })
		this.getRegionData();

		var roleListData = await getRoleList();
		if(!showMagnetPlatinum.includes(window?.$user?.role)) {
			roleListData = roleListData.filter(item=>item.id !== 36)
		}

		this.setState({ role: roleListData })

		if (this.props.singleUserData.data.id !== this.state.user_id) {
			this.setState({ showLoader: true });

			if (this.props.singleUserData.meta.status === 200) {
				let autoFill = this.props.singleUserData.data;

				if (autoFill.region === null || autoFill.region === undefined) {
					autoFill.region = { "region_id": "" };
				}

				let arrParent = autoFill.ParentUser !== null ? autoFill.ParentUser : [];


				let userDataArr = [];
				let parentUser = [];
				arrParent.map((cur, index) => {
					let parent = {};
					parent.id = cur.ParentUserInfo?.id
					parent.name = cur.ParentUserInfo?.name
					userDataArr.push(parent);
					parentUser.push(cur.ParentUserInfo?.id);
				})
				let regionParentMulti = autoFill.UserRegions !== null ? autoFill.UserRegions : [];
				let regionDataArr = [];
				let parentRegion = [];
				regionParentMulti.map((cur, index) => {
					let parent = {};
					parent.id = cur.RegionParent?.id
					parent.name = cur.RegionParent?.name
					regionDataArr.push(parent);
					parentRegion.push(cur.RegionParent?.id);
				})
				this.setState({
					user_id: autoFill.id === null ? '' : autoFill.id,
					first_name: autoFill.first_name === null ? '' : autoFill.first_name,
					last_name: autoFill.last_name === null ? '' : autoFill.last_name,
					email: autoFill.email === null ? '' : autoFill.email,
					phone: autoFill.phone === null ? '' : autoFill.phone,
					old_phone: autoFill.phone === null ? '' : autoFill.phone,
					is_active: autoFill.is_active === null ? null : autoFill.is_active,
					role_id: autoFill.role_id === null ? '' : autoFill.role_id,
					userName: userDataArr || [],
					selectedVal: userDataArr || [],
					selectedRegionVal: regionDataArr || [],
					parent_id:parentUser,
					booking_role_id:autoFill.booking_role ? autoFill.booking_role : '' 
				})
				if (autoFill.UserInfo !== null) {
					this.setState({
						region_id: autoFill.UserInfo.region_id === null || autoFill.UserInfo.region_id === "" ? '' : autoFill.UserInfo.region_id,
						city: autoFill.UserInfo.city === null || autoFill.UserInfo.city === "" ? '' : autoFill.UserInfo.city,
						max_lead: autoFill.UserInfo.max_lead === null || autoFill.UserInfo.max_lead === "" ? '' : autoFill.UserInfo.max_lead,
						dump_lead_count: autoFill.UserInfo.dump_lead_count === null || autoFill.UserInfo.dump_lead_count === "" ? '' : autoFill.UserInfo.dump_lead_count,
						overdue_count: autoFill.UserInfo.overdue_count === null || autoFill.UserInfo.overdue_count === "" ? '' : autoFill.UserInfo.overdue_count,
						newlead_count: autoFill.UserInfo.new_lead_count === null || autoFill.UserInfo.new_lead_count === "" ? '' : autoFill.UserInfo.new_lead_count,
					})
				}
			}
		} else {

			this.setState({
				user_id: "",
				name: "",
				email: "",
				city: "",
				phone: "",
				is_active: "",
				region_id: "",
				max_lead: "",
				dump_lead_count: "",
				overdue_count: "",
				parent_id: [],
				newlead_count:"",
				parent_region_ids: []
			})
		}
		this.setState({ showLoader: false });
	}

	handleUpdateUser = async (e) => {
		e.preventDefault();
		let {max_lead, dump_lead_count, overdue_count, newlead_count, role_id} = this.state;
		if (this.formValid(this.state) && (max_lead && dump_lead_count && overdue_count && newlead_count)) {
			let mainData = {};
			let addUserData = {};
			addUserData.data = {};
			addUserData.data.Usermeta = {};
			if (this.state.first_name) addUserData.data["first_name"] = this.state.first_name;
			if (this.state.last_name) addUserData.data["last_name"] = this.state.last_name;
			if (this.state.email) addUserData.data["email"] = this.state.email;
			if (this.state.phone && this.state.phone !== this.state.old_phone) addUserData.data["phone"] = this.state.phone;
			if (this.state.parent_id) addUserData.data["parent_id"] = this.state.parent_id
			if (this.state.role_id) addUserData.data["role_id"] = this.state.role_id;
			if (this.state.booking_role_id) addUserData.data["booking_role"] = this.state.booking_role_id;
			addUserData.data["is_active"] = this.state.is_active ? true : false;
     		if (this.state.parent_region_ids.length > 0) addUserData.data.Usermeta["parent_region_ids"] = this.state.parent_region_ids
			if (this.state.region_id) {
				addUserData.data.Usermeta["region_id"] = this.state.region_id;
			}
			if (this.state.city) {
				addUserData.data.Usermeta["city"] = this.state.city;
			}
			addUserData.data.Usermeta["max_lead"] = this.state.max_lead;
			addUserData.data.Usermeta["dump_lead_count"] = this.state.dump_lead_count;
			addUserData.data.Usermeta["overdue_count"] = this.state.overdue_count;
			addUserData.data.Usermeta['new_lead_count'] = this.state.newlead_count; 
			addUserData.data = JSON.stringify(addUserData.data);

			mainData.data = addUserData;
			mainData.id = this.state.user_id;



			this.setState({
				showLoader: true,
			})
			let selected_user_ids = this.state.selectedVal.map(x=>x?.id)
			if(selected_user_ids.includes(this.state.user_id)){
				this.handleSubmitError("User Name and Parent Name can't be same!");
				return false;
			}
			if([10,15, 36].includes(this.state.role_id) && this.state.selectedVal.length > 1){
				this.handleSubmitError("You can select only one Parent!");
				return false;
			}
			if (objEmpty(this.state.selectedVal)) {
				this.handleSubmitError("Minimum One Parent Required!");
				return false;
			}
			if (role_id === 21 && objEmpty(this.state.selectedRegionVal)) {
				this.handleSubmitError("Work Region field is required!");
				return false;
			}
			if (['admin','hrhead'].includes(window.$role) && empty(this.state.booking_role_id)) {
				this.handleSubmitError("Booking Role field is required!");
				return false;
			}
			let addLeadRes = await getUpdateCpUser(mainData);

			if (addLeadRes.meta.status === 503) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "!!! Oooops Network Error..."
				});

			} else if (addLeadRes.meta.status === 201) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Updated successfully"
				});
			} else if (addLeadRes.meta.status === 404 || addLeadRes.meta.status === 400 || addLeadRes.meta.status === 409) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "warning",
					title: addLeadRes.meta.message
				});

			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});
			}
		} else {
			this.setState({
				showLoader: false,
				alertShow: true,
				alertType: "error",
				alertTitle: "*Above all * fileds are reqiured !!!",
			})
		}
	};

	formValid = ({ isError, ...rest }) => {
		let isValid = false;
		// if (empty(rest.first_name) || empty(rest.last_name) || empty(rest.email) || empty(rest.phone) || (["sales","presale"].includes(rest.group) && !!rest.region_id === false)) {
		if (empty(rest.first_name) || empty(rest.last_name) || empty(rest.email) || empty(rest.phone) || empty(rest.region_id)) {	
			return false;
		}

		Object.values(isError).forEach(val => {
			if (val.length > 0) {
				return false;
			} else {
				isValid = true
			}
		});

		return isValid;
	};

	formValChange = e => {
		e.preventDefault();
		let { name, value } = e.target;
		let isError = { ...this.state.isError };

		switch (name) {
			case "first_name":
				isError.first_name =
					nameCheck(value) ? "" : "Please enter valid name";
				break;
			case "last_name":
				isError.last_name = "" ; // removed validation for last name
					// value.length < 4 && nameCheck(value) ? "Atleast 4 characaters required" : "";
					// nameCheck(value) ? "" : "Please enter valid name";
				break;
			case "email":
				isError.email = emailCheck(value)
					? ""
					: "Email address is invalid";
				break;
			case "phone":
				isError.phone = phoneCheck(value)
					? ""
					: "phone is invalid";
				break;	
			default:
				break;
		}
		this.setState({
			isError,
		})
		
		if (name === "phone") {
			value = e.target.value.replace(/\D/g, "")
			this.setState({
				[name]: (value)
			})
		}
		else if (typeof value !== 'number') {
			this.setState({
				[name]: letterCase(value, true)
			})
		}
		 else {
			this.setState({
				[name]: (value)
			})
		}
	};

	onChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}
	getRegionData = async () => {
        let regionData = await getRegionParentsData();
        this.setState({
			multi_region: regionData.data
		})
    }
	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			users_active : 1,
			q: e.target.value,
		};
		if(this.state.role_id == 36){
			data.roles = [REVERT_ROLES.MAGNET];
		}
		else{
			data.roles = [ REVERT_ROLES.ADMIN,REVERT_ROLES.SALES_HEAD, REVERT_ROLES.PNL_HEAD,REVERT_ROLES.SALES_MANAGER,REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, 
				REVERT_ROLES.FINANCE_HEAD,REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.PRESALES_TL,REVERT_ROLES.SOURCING_S2, REVERT_ROLES.MEDIA_MANAGERS,REVERT_ROLES.HR_HEAD,  REVERT_ROLES.MAGNET_ADMIN,
				REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.OPERATIONS_HEAD, REVERT_ROLES.OPERATIONS_ANALYST,REVERT_ROLES.MAGNET_S2,
				REVERT_ROLES.FINANCE_ANALYST, REVERT_ROLES.CAMPAIGN_MANAGERS,REVERT_ROLES.CAMPAIGN_OPERATIONS,REVERT_ROLES.OPERATIONS_MANAGER, REVERT_ROLES.MAGNET_PNL]
			}
		let resData = await autoComplete('users', data);

		// console.log("resData.dataXXXXXXXXXXXXXXXX",resData.data);
		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList()
	}

	handleChange = (event) => {
		this.setState({ is_active: !this.state.is_active });
	};
	handleClose = () => {
		this.setState({alertShow: false})
	}
	handleSubmitError = (msg) =>{
		this.setState({
			showLoader: false,
			alertShow: true,
			alertType: "error",
			alertTitle: msg
		});
	}
	render() {
		const { error, region, sweetShow, type, title, region_id, role_id, role, city, userName, isLoading, selectedVal, group, alertShow, alertType, alertTitle, selectedRegionVal, multi_region,booking_role_id} = this.state;
		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<Loader show={this.state.showLoader} />
					<PopUpAlert type={alertType} msg={alertTitle} open={alertShow} handleClose={this.handleClose}/>
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.first_name !== '' ?  true :  false}
											required
											id="first_name"
											name="first_name"
											label="First Name"
											value={this.state.first_name}
											helperText={this.state.isError.first_name !== '' ? this.state.isError.first_name : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.last_name !== '' ?  true :  false}
											required
											id="last_name"
											name="last_name"
											label="Last Name"
											value={this.state.last_name}
											helperText={this.state.isError.last_name !== '' ? this.state.isError.last_name : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel 
											id="demo-controlled-open-select-label"
											// required={["sales","presale"].includes(group) ? true : false}
											required
										>
											Select Region
										</InputLabel>
										<Select
											error={this.state.isError.region_id !== '' ?  true :  false}
											labelId="demo-controlled-open-select-label"
											value={region_id}
											onChange={this.formValChange}
											inputProps={{
												name: 'region_id',
												id: 'region_id',
											}}
											helperText={this.state.isError.region_id !== '' ? this.state.isError.region_id : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{region ?
												(region.map(reg =>
													<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
												))
												:
												''
											}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">City</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={city}
											onChange={this.formValChange}
											inputProps={{
												name: 'city',
												id: 'city',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="mumbai">Mumbai</MenuItem>
											<MenuItem value="pune">Pune</MenuItem>
											<MenuItem value="bangalore">Bangalore</MenuItem>
											<MenuItem value="delhi">Delhi</MenuItem>
											<MenuItem value="kolkata">Kolkata</MenuItem>
											<MenuItem value="goa">Goa</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.phone !== '' ?  true :  false}
											required
											id="phone"
											name="phone"
											value={this.state.phone}
											helperText={this.state.isError.phone !== '' ? this.state.isError.phone : ''}
											label="Mobile Number"
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<PhoneIphoneIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-1">
									<div className="form-group">
										<TextField
											error={this.state.isError.email !== '' ?  true :  false}
											required
											id="email"
											name="email"
											label="Email"
											value={this.state.email}
											helperText={this.state.isError.email !== '' ? this.state.isError.email : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								{updateRolePermission.includes(window.$role) &&
									<div className="col-sm-6 mb-3">
										<FormControl>
											<InputLabel id="demo-controlled-open-select-label">Select Role</InputLabel>
											<Select
												error={this.state.isError.role_id !== '' ?  true :  false}
												labelId="demo-controlled-open-select-label"
												value={role_id}
												onChange={this.formValChange}
												inputProps={{
													name: 'role_id',
													id: 'role_id',
												}}
												helperText={this.state.isError.role_id !== '' ? this.state.isError.role_id : ''}
											>
												<MenuItem value="">
													<em>None</em>
												</MenuItem>
												{role ?
													(role.map(reg =>
														<MenuItem className="text-capitalize" value={reg.id} key={reg.id}>{reg.name} ({reg.alias})</MenuItem>
													))
													:
													''
												}
											</Select>
										</FormControl>
									</div>
								}
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.max_lead !== '' ?  true :  false}
											required
											id="max_lead"
											name="max_lead"
											label="Max Lead"
											value={this.state.max_lead}
											helperText={this.state.isError.max_lead !== '' ? this.state.isError.max_lead : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AcUnitIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.dump_lead_count !== '' ?  true :  false}
											required
											id="dump_lead_count"
											name="dump_lead_count"
											label={DUMP_LEAD}
											value={this.state.dump_lead_count}
											helperText={this.state.isError.dump_lead_count !== '' ? this.state.isError.dump_lead_count : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AcUnitIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.overdue_count !== '' ?  true :  false}
											required
											id="overdue_count"
											name="overdue_count"
											label="Overdue Count"
											value={this.state.overdue_count}
											helperText={this.state.isError.overdue_count !== '' ? this.state.isError.overdue_count : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AcUnitIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.newlead_count !== '' ?  true :  false}
											required
											type="number"
											id="newlead_count"
											name="newlead_count"
											label="New Lead Count"
											value={this.state.newlead_count}
											helperText={this.state.isError.newlead_count !== '' ? this.state.isError.newlead_count : ''}
											onChange={this.formValChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AcUnitIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 pl-0 text-muted ">
									<div className="form-group">
										<FormControlLabel
											value={`${this.state.is_active}`}
											checked={this.state.is_active}
											name="is_active"
											control={<Switch color="primary" />}
											label="User Status"
											labelPlacement="top"
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<Autocomplete
											multiple
											id="userData"
											getOptionSelected={(userName, value) => userName.name === value.name}
											getOptionLabel={userName => userName.name}
											options={userName}
											value={selectedVal}
											loading={isLoading}
											renderTags={(value, getTagProps) =>
												value.map((userName, index) => (
													<Chip label={userName.name} {...getTagProps({ index })} />
												))
											}
											onChange={(_event, userName) => {
												let userId = userName.map((ops) => ops.id);
												this.setState({
													parent_id: userId,
													selectedVal: userName
												})

												if (userName === null) {
													this.setState({ userName: [] })
												}
											}}
											renderInput={params => (
												<TextField
													{...params}
													required
													label="Parent Name"
													fullWidth
													onChange={this.handleAutoUserChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
								</div>
							{role_id === 21 &&
									<div className="col-lg-6 col-sm-6">
										<div className="form-group">
											<Autocomplete
												multiple
												getOptionSelected={(multi_region, value) =>
													multi_region.name === value.name
												}
												getOptionLabel={multi_region => multi_region.name}
												options={multi_region}
												value={selectedRegionVal}
												renderTags={(value, getTagProps) =>
													value.map((multi_region, index) => (
														<Chip
															label={multi_region.name}
															{...getTagProps({ index })}
														/>
													))
												}
												onChange={(_event, multi_region) => {
													let regionIds = multi_region.map(ops => ops.id);
													this.setState({
														//...state,
														parent_region_ids: regionIds,
														selectedRegionVal: multi_region
													});

													if (multi_region === null) {
														this.setState({multi_region: [] });
													}
												}}
												renderInput={params => (
													<TextField
														{...params}
														required
														label="Working Regions"
														fullWidth
														// onChange={this.onChange}
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{params.InputProps.endAdornment}
																</React.Fragment>
															)
														}}
													/>
												)}
											/>
										</div>
									</div>
	                        }
							{['admin','hrhead'].includes(window.$role) && 
								<div className="col-sm-6 mb-3">
								<FormControl>
									<InputLabel id="demo-controlled-open-select-label" required>Select Booking Role</InputLabel>
									<Select
										error={this.state.isError.booking_role_id !== '' ?  true :  false}
										labelId="demo-controlled-open-select-label"
										value={booking_role_id}
										onChange={this.formValChange}
										inputProps={{
											name: 'booking_role_id',
											id: 'booking_role_id',
										}}
										helperText={this.state.isError.booking_role_id !== '' ? this.state.isError.booking_role_id : ''}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{role ?
											(role.map(reg =>
												<MenuItem className="text-capitalize" value={reg.id} key={reg.id}>{reg.name} ({reg.alias})</MenuItem>
											))
											:
											''
										}
									</Select>
								</FormControl>
							</div>
							}
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.handleUpdateUser}>Submit</button>
										<p className="mb-0">
											<span className="text-center text-danger small">{error != '' ? error : ''}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(UpdateUserFrom);
