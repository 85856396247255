import { get, post, put, getBasic, getEngageComment } from "../utils/methods.js";
import { ROLES } from "../config/constant/index.js";

export const getLeadStatusData = async () => {

	let getLeadStatus = await get('users/stages?type=LEAD')
	return getLeadStatus;
}
export const getMe = async () => {

	let getMe = await getBasic('/users/me')
	return getMe;
}

export const getLeadSourceData = async () => {

	let getLeadSource = await get('/sources')
	return getLeadSource;
}

export const getSourceData = async (value) => {

	let getSource = await get('/sources/' + value.toString())
	return getSource;
}

export const getRoleList = async () => {

	let getRoleListData = await get('/roles?size=30&pageId=1')
	if (getRoleListData.meta.status === 201) {
		return getRoleListData.data;
	}
	return [];
}

export const genericSearch = async (value) => {
	// let role = 'MAGNET_TL';
	let AutoComplete = await get('/leads/generic_search?q=' + value)
	return AutoComplete;
}
export const genericSearchResult = async (value) => {
	// let role = 'MAGNET_TL';
	let genericSearchResult = await get('/leads/generic_search_result', value)
	return genericSearchResult;
}

export const genericSearchResultCount = async (value) => {
	// let role = 'MAGNET_TL';
	let genericSearchResultCount = await get('/leads/generic_search_result/count', value)
	return genericSearchResultCount;
}

export const leadIdClientNameSearch = async (value) => {
	let result = await get('/leads/clientName_leadId_search?q=' + value)
	return result;
}

export const getAutoCompleteParentRegion = async (value) => {
	let ParentRegionAutocomplete = await get('regionparents/auto_complete?q='+ value)
	return ParentRegionAutocomplete;
}

export const getMagnetRMListData = async (value) => {
	// let role = 'MAGNET_TL';
	let userAutoComplete = await get('/users/auto_complete?q=' + value.val + "&roles=" + value.role)
	return userAutoComplete;
}

export const getUserAutoComplete = async (value) => {
	let userAutoComplete = await get('/users/auto_complete?q=' + value.q + "&roles=" + value.roles)
	return userAutoComplete;
	// console.log
}

export const autocompleterolewise = async (api = false, value) => {
	let autoComplete = await get('/' + api + '/auto_complete_rolewise', value);
	return autoComplete;
}

export const autoComplete = async (api = false, value) => {
	let autoComplete = await get('/' + api + '/auto_complete/', value);
	return autoComplete;
}

export const autoCompleteClientMobile = async (value) => {
	let autoComplete = await get('/clients/auto_complete_by_number?q=' + value.q);
	return autoComplete;
}

export const roleWiseAutoComplete = async (value) => {
	let autoComplete = await get('/users/auto_complete_rolewise', value);
	return autoComplete;
}
export const leadInterautoComplete = async (api = false, value) => {
	let leadInterautoComplete = await get('/' + api, value);
	return leadInterautoComplete;
}

export const userleadstats = async (api = false, value) => {
	let leadInterautoComplete = await get('/' + api + '/user_lead_stats/' + value);
	return leadInterautoComplete;
}

export const assignLeads = async (api = false, value) => {
	let leadInterautoComplete = await post('/' + api + '/assign_leads', value);
	return leadInterautoComplete;
}

export const getMagnetNameAuto = async (value) => {

	let getMagnetNameAutoData = await get('/users/autocomplete/' + ROLES[22].toLowercase() + '?q=' + value)
	return getMagnetNameAutoData;
}

export const getClientList = async (value) => {

	let getClientListData = await get('/clients/auto_complete?q=' + value)
	return getClientListData;
}
export const getClientListRolewise = async (value) => {

	let getClientListRolewiseData = await get('/clients/auto_complete_team_wise?q=' + value)
	return getClientListRolewiseData;
}

export const getReportData = async (value) => {

	let getReport = {};

	switch (value.reportType) {
		case "current-status":
			getReport = await get('/reports/current_status_report/' + value.id, value.filters)
			return getReport;
			break;
		case "booking":
			getReport = await get('/reports/booking_report/' + value.id, value.filters)
			return getReport;
			break;
		case "activity":
			getReport = await get('/reports/activity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "update":
			getReport = await get('/reports/update_report/' + value.id, value.filters)
			return getReport;
			break;
		case "source-activity":
			getReport = await get('/reports/source_activity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "source-booking":
			getReport = await get('/reports/source_booking_report/' + value.id, value.filters)
			return getReport;
			break;
		case "source-current-status":
			getReport = await get('/reports/source_current_status_report/' + value.id, value.filters)
			return getReport;
			break;
		case "source-update":
			getReport = await get('/reports/source_update_report/' + value.id, value.filters)
			return getReport;
			break;
		case "lost-sales":
			getReport = await get('/reports/lost_sales_report/' + value.id, value.filters)
			return getReport;
			break;
		case "project-lost-sales":
			getReport = await get('/reports/project_lost_Sales_Report/' + value.id, value.filters)
			return getReport;
			break;
		case "pipeline":
			getReport = await get('/reports/pipeline_report/' + value.id, value.filters)
			return getReport;
			break;
		case "opportunity":
			getReport = await get('/reports/opportunity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-activity":
			getReport = await get('/reports/presale_activity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-activity":
			getReport = await get('/reports/magnet_activity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-current-status":
			getReport = await get('/reports/magnet_current_status_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-pipeline":
			getReport = await get('/reports/magnet_pipeline_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-booking":
			getReport = await get('/reports/magnet_booking_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-databank":
			getReport = await get('/reports/magnet_databank_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-pipeline":
			getReport = await get('/reports/presale_pipeline_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-booking":
			getReport = await get('/reports/presale_booking_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-update":
			getReport = await get('/reports/presale_update_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-current-status":
			getReport = await get('/reports/presale_current_status_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-lost-sales":
			getReport = await get('/reports/presale_lost_sales_report/' + value.id, value.filters)
			return getReport;
			break;
		case "databank":
			getReport = await get('/reports/databank_report/' + value.id, value.filters)
			return getReport;
			break;
		case "ratio":
			getReport = await get('/reports/ratio_report/' + value.id, value.filters)
			return getReport;
			break;
		case "followup":
			getReport = await get('/reports/followup_report/' + value.id, value.filters)
			return getReport;
			break;
		case "presale-followup":
			getReport = await get('/reports/presale_followup_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-bulk-upload":
			getReport = await get('/reports/bulk_upload_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-lead":
			getReport = await get('/reports/magnet_lead_report/' + value.id, value.filters)
			return getReport;
			break;
		case "project-rm":
			getReport = await get('/reports/project_report/' + value.id, value.filters)
			return getReport;
			break;
		case "project-activity-rm":
			getReport = await get('/reports/project_activity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "booking-detail":
			getReport = await get('/reports/booking_details_report/' + value.id, value.filters)
			return getReport;
			break;
		case "digital-booking":
			getReport = await get('/reports/digital_booking_report/' + value.id, value.filters)
			return getReport;
			break;
		case "digital-activity":
			getReport = await get('/reports/digital_activity_report/' + value.id, value.filters)
			return getReport;
			break;
		case "hourly-update":
			getReport = await get('/reports/hour_wise_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-spend":
			getReport = await get('/reports/magnet_spend_report/' + value.id, value.filters)
			return getReport;
			break;
		case "audit":
			getReport = await get('/reports/audit_report/' + value.id, value.filters)
			return getReport;
			break;
		case "magnet-list":
			getReport = await get('/users/magnet_users', value.data)
			return getReport;
			break;
		default:
			break;
	}

}

export const getReportError = async (value) => {
	let errorData = await getEngageComment('/ses/sendAsanaEmail', value)
	return errorData;
}

export const getUserFeedback = async (value) => {
	let getData = await post('/userRating', value)
	return getData;

}

export const getMagnetPlans = async () => {
	let magnetPlan = await get('/magnetPlan')
	return magnetPlan;
}

export const updateAuditLead = async (id,value) =>{

	let getRes = await put('/clients/clientupdate/'+id, value)
	return  getRes;
} 

export const interestedMagnet = async (value) => {
	let interestedMg = await get('/interested_magnets/auto_complete/', value);
	return interestedMg;
}

export const updateRm = async (id,value) =>{

	let getRes = await put('/bookingRoles/'+id+'/update',value)
	return  getRes;
} 

export const CreateRm = async (value) =>{

	let getRes = await post('/bookingRoles',value)
	return  getRes;
} 