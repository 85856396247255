import React, {Component} from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import {withRouter} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {getRoleList,autoComplete,roleWiseAutoComplete} from "../../dataParser/commomDataApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import { ROLES } from '../../config/constant/index'
import { cleanStorage } from "../../helper";

class UserFilter extends Component { 

	constructor(props) {
    	super(props);
		this.state = {
            role: [],
            userName: [],
            email : '',
            is_active : '',
            role_ids : '',
            phone : '',
            user_id : '',
            deletedby_id : '',
            createdAt_from : null,
            createdAt_to : null,
            updatedAt_from : null,
            updatedAt_to : null,
		};
	}

	async componentDidMount(){


	    var roleListData = await getRoleList();
        this.setState({role : roleListData})

	}

	handleAutoUserChange = async (e) =>{
        this.setState({isMagnetLoading:true});
		let resData ;
		let data = {};
		if ( window.$user.role === 'admin'){
		let roles = Object.keys(ROLES).filter((role) => role != 22).join(',');
        data = {
                 q:e.target.value,
				roles:roles,
				is_active_inactive:1
         };
		 
		resData = await autoComplete('users',data);
		} else {
			data.q = e.target.value;
			resData = await roleWiseAutoComplete(data);
		}

        if(resData.meta.status === 200){

            this.setState({isMagnetLoading:false});
            this.setState({userName : resData.data})
        }else if(resData.meta.status === 401){
            
            cleanStorage();
            this.props.history.push("/login");
            
        }else{

            this.setState({isMagnetLoading:false});
            this.setState({userName : []})
        }
    }
	
	handleAutoChange = async (e) =>{

	}

	handleDateChange = (key, date) =>{

		this.setState({[key] : date});
	}

	handleChange = event => {
		this.setState({[event.target.name]: event.target.value});
  	};

  	applyFilter= ()=>{

    	let filterData = {};

		if(this.state.email != ''){
        	
        	filterData["email"] = this.state.email;
    	}

    	if(this.state.role_ids != ''){
        	
        	filterData["role_ids"] = this.state.role_ids;
    	}

    	if(this.state.phone != ''){
        	
        	filterData["phone"] = parseInt(this.state.phone);
    	}

    	if(this.state.user_id != ''){
        	
        	filterData["ids"] = this.state.user_id;
    	}

    	if(this.state.deletedby_id != ''){
        	
        	filterData["deleted_by_id"] = parseInt(this.state.deletedby_id);
    	}

    	if(this.state.is_active != ''){
        	
        	filterData["is_active"] = parseInt(this.state.is_active);
    	}

    	if(this.state.updatedAt_from){
        	
        	filterData["updated_at_from"] =this.state.updatedAt_from.split("/").join("-");
    	}

    	if(this.state.updatedAt_to){
        	
        	filterData["updated_at_from"] =this.state.updatedAt_to.split("/").join("-");
    	}

    	if(this.state.createdAt_from){
        	
        	filterData["created_at_from"] =this.state.createdAt_from.split("/").join("-");
    	}
    	if(this.state.createdAt_to){
        	
        	filterData["created_at_to"] =this.state.createdAt_to.split("/").join("-");
    	}

    	this.setState({userName: []});
    	// this.setState({activeClear : false});

    	this.props.changeFilter();
        this.props.filterData(filterData);  
    }

    handleDateChange = (key, date) =>{

		this.setState({[key] : date});
	}

  	resetFilter = () =>{
		this.setState({userName: []});
  		this.setState({
			autoKey: !this.state.autoKey,
            email : '',
            is_active : '',
            role_ids : '',
            phone : '',
            user_id : '',
            deletedby_id : '',
            createdAt_from :null,
            createdAt_to : null,
            updatedAt_from : null,
            updatedAt_to : null,
  		})
  	}

	render(){

		const {userName,createdAt_to,autoKey,updatedAt_from,updatedAt_to,isLoading,createdAt_from,role} = this.state;

		return(

			<Frag>
				<div className="container filter_lead filterMenuCard pt-3 p-0" ref={this.props.fltr}>
					<div className="row filterRow">
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<Autocomplete
								  multiple
								  key={autoKey} 
							      id="roleData"
							      getOptionSelected={(role, value) => role.name === value.name}
							      getOptionLabel={role => role.name}
							      options={role}
							      renderTags={(value, getTagProps) =>
								    value.map((role, index) => (
								      <Chip className="text-capitalize" label={role.name} {...getTagProps({ index })}/>
								    ))
								  }
							      onChange={(_event, role) => {
							        if (role === null) {
							        	this.setState({
							        		role: [],
								        	role_ids : '',
							        	})
							        }else{
								        var projectId = role.map((ops) => ops.id);
								        this.setState({
								        	role_ids : projectId,
								        })
							        }

							      }}
							      renderInput={params => (
							        <TextField
							          {...params}
							          label="Select Role"
							          fullWidth
							          onChange={this.handleAutoChange}
							          InputProps={{
							            ...params.InputProps,
							            endAdornment: (
							              <React.Fragment>
							                {params.InputProps.endAdornment}
							              </React.Fragment>
							            )
							          }}
							        />
							      )}
							    />	
							</div>
						</div>
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
						        <Autocomplete
								  multiple
								  key={autoKey} 
							      id="UserData"
							      getOptionSelected={(userName, value) => userName.name === value.name}
							      getOptionLabel={userName => userName.name +" ("+userName.phone+")"}
							      options={userName}
							      loading={isLoading}
							      renderTags={(value, getTagProps) =>
								    value.map((userName, index) => (
								      <Chip className="text-capitalize" label={userName.name} {...getTagProps({ index })}/>
								    ))
								  }
							      onChange={(_event, userName) => {
							        if (userName === null) {
							        	this.setState({
							        		userName: [],
								        	user_id : '',
							        	})
							        }else{
								        var projectId = userName.map((ops) => ops.id);
								        this.setState({
								        	user_id : projectId,
								        })
							        }

							      }}
							      renderInput={params => (
							        <TextField
							          {...params}
							          label="User Name"
							          fullWidth
							          onChange={this.handleAutoUserChange}
							          InputProps={{
							            ...params.InputProps,
							            endAdornment: (
							              <React.Fragment>
							                {isLoading ? (
							                  <CircularProgress color="inherit" size={20} />
							                ) : null}
							                {params.InputProps.endAdornment}
							              </React.Fragment>
							            )
							          }}
							        />
							      )}
							    />	
							</div>
						</div>
					</div>
					<div className="row filterRow">
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<TextField
						          value={this.state.email}
						          id="email"
						          name="email"
						          label="Email"
						          onChange={this.handleChange}
						          defaultValue=""
						          helperText=""
						        />
							</div>
						</div>
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<TextField
						          value={this.state.phone}
						          id="phone"
						          name="phone"
						          label="Mobile Number"
						          onChange={this.handleChange}
						          defaultValue=""
						          helperText=""
						        />
							</div>
						</div>
					</div>
					<div className="row filterRow">
						{window.$role !== "presalestl" &&
							<div className="col-lg-6 col-sm-6 col-6">
								<div className="form-group">
									<TextField
							          value={this.state.deletedby_id}
							          id="deletedby_id"
							          name="deletedby_id"
							          label="Deleted By ID"
							          onChange={this.handleChange}
							          defaultValue=""
							          helperText=""
							        />
								</div>
							</div>
						}	
						<div className="col-lg-6 col-sm-6 col-6 mb-2">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={this.state.is_active}
						          onChange={this.handleChange}
						          inputProps={{
						            name: 'is_active',
						            id: 'is_active',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Active</MenuItem>
									<MenuItem value="0">In-Active</MenuItem>
						        </Select>
							</FormControl>
						</div>
					</div>
					{window.$role !== "presalestl" &&
						<Frag>
							<div className="row filterRow">
								<div className="col-md-12 text-center">Creation Date</div>
								<div className="col-lg-6 col-sm-6 col-6">
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											autoOk={true}
								          margin="normal"
								          variant="inline"
								          id="createdAt_from"
								          label="From"
								          format="yyyy/MM/dd"
											maxDate={new Date()}						          
											name="createdAt_from"
								          value={createdAt_from}
								          onChange={(key,date)=>this.handleDateChange('createdAt_from',date)}
								          KeyboardButtonProps={{
								            'aria-label': 'change date',
								          }}
								        />
								    </MuiPickersUtilsProvider> 
								</div>
								<div className="col-lg-6 col-sm-6 col-6">
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											autoOk={true}
								          margin="normal"
								          id="createdAt_to"
								          variant="inline"
								          label="to"
								          format="yyyy/MM/dd"
											maxDate={new Date()}				          
											name="createdAt_to"
								          value={createdAt_to}
								          onChange={(key,date)=>this.handleDateChange('createdAt_to',date)}
								          KeyboardButtonProps={{
								            'aria-label': 'change date',
								          }}
								        />
								    </MuiPickersUtilsProvider> 
								</div>				
							</div>
							<div className="row filterRow">
								<div className="col-md-12 text-center">Update Date</div>
								<div className="col-lg-6 col-sm-6 col-6">
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											autoOk={true}
								          margin="normal"
								          variant="inline"
								          id="updatedAt_from"
								          label="From"
								          format="yyyy/MM/dd"
											maxDate={new Date()}						          
											name="updatedAt_from"
								          value={updatedAt_from}
								          onChange={(key,date)=>this.handleDateChange('updatedAt_from',date)}
								          KeyboardButtonProps={{
								            'aria-label': 'change date',
								          }}
								        />
								    </MuiPickersUtilsProvider> 
								</div>
								<div className="col-lg-6 col-sm-6 col-6">
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											autoOk={true}
								          margin="normal"
								          id="updatedAt_to"
								          variant="inline"
								          label="to"
								          format="yyyy/MM/dd"
											maxDate={new Date()}				          
											name="updatedAt_to"
								          value={updatedAt_to}
								          onChange={(key,date)=>this.handleDateChange('updatedAt_to',date)}
								          KeyboardButtonProps={{
								            'aria-label': 'change date',
								          }}
								        />
								    </MuiPickersUtilsProvider> 
								</div>				
							</div>
						</Frag>	
					}	
				</div>
				<div className="col-md-12 bgCommon">
					<div className="row">
						<div className="col-sm-6 text-right col-6 mt-3">
							<div className="form-group">
								<button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
							</div>
						</div>
						<div className="col-sm-6 col-6 mt-3">	
							<div className="form-group">
								<button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
							</div>
						</div>	
					</div>	
				</div>	
			</Frag>
		);
	};
};

export default withRouter(UserFilter)
