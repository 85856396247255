import React from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { priceUnit } from '../../../helper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";

const InputUnitField = (props) => {
    const { name, value, label, required, onChange, disabled} = props;
    const [unit, setUnit] = React.useState('')
    const [amount, setAmount] = React.useState(value);
    React.useEffect(() => {
        if(!value) return
        let val = value;
        if (val?.toString() && val?.toString()?.includes(',')) {
            val = val?.split(",")?.join("")
        }
        val = Number(val)
        let unit = priceUnit(val);
        val = val?.toLocaleString('en-IN', { maximumFractionDigits: 0 })
        setAmount(val)
        setUnit(unit)
    }, [value])

    return (
        <FormControl>
            <InputLabel required={required ? true : false}>{label}</InputLabel>
            <Input
                name={name}
                value={amount}
                onChange={onChange}
                endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                startAdornment={
                    <InputAdornment position="start">
                        <FontAwesomeIcon icon={faRupeeSign} className="nav-icon" />
                    </InputAdornment>
                }
                inputProps={{
                    pattern: "[0-9]([,0-9])*"
                }}
               disabled={disabled}
            />
        </FormControl>
    )
}

export default InputUnitField