import React, { useState } from 'react'
import InputField from "../common/controls/InputField"
import { Paper } from '@material-ui/core'
import { Button } from '@material-ui/core';
import { AMAZON_URL, APP_BASE_URL } from '../../config/serverKey';
import { getFrogetPwd, getChangePwdRes } from "../../dataParser/auth";
import Loader from '../common/loader/loader';
import PopUpAlert from "../common/sweetAlert/popUpAlert"
import queryString from "query-string";
const RegisterForm = (props) => {
    const [field, setField] = useState({
        phone: "",
        password: "",
        confirm_password: "",
        otp: "",
        failureMsg: ""
    })

    const [state, setState] = useState({
        showLoader: false,
        open: false,
        type: "success",
        msg: "",
        sendOtp: "no",
        isChangePass: false ,
        formType: "create",
        mgName: "there"
    })

    const { phone, password, confirm_password, otp, } = field;
    const { showLoader, open, type, msg, sendOtp, formType, mgName, failureMsg } = state;

    const getForgetRes = async e => {
        e.preventDefault();

        setState({ ...field, ...state, showLoader: true});
        let forgetData = {};
        forgetData.phone = phone;

        if(forgetData.phone.length === 10 && forgetData.phone.length !== ""){

            let resData = await getFrogetPwd(forgetData);
            if (resData.meta.status === 201) {
                setState({
                    ...field, 
                    ...state, 
                    showLoader: false,
                    open: true,
                    type: "success",    
                    msg: "OTP sent to the registered mobile number",
                    sendOtp: "yes"
                });
            } else if(resData.meta.status === 404){
                setState({
                    ...field, 
                    ...state, 
                    showLoader: false,
                    failureMsg: resData.meta.message,
                    formType: "fail"
                })
            }
        }else{
            setState({
                ...field, 
                ...state,
                showLoader: false,
                open: true,
                type: "error",
                msg: "Please enter valid mobile number"
        })
        }
    }
    const handleChange = e => {
        let { name, value } = e.target;
        if(name === "phone"){
            value = value.replace(/\D/g, "")
            setField({ 
                ...field,
                [name]: (value)
            })
          }
        else{
            setField({ ...field, [name]: value })
        }
    }

    const getChangeRes = async e => {
        e.preventDefault();

        let data = {};
        data["password"] = password;
        data["phone"] = phone;
        data["otp"] = otp;
        let queryParams = queryString.parse(`?${window.location.href.split("?")[1]}`);
        if(queryParams && queryParams.utm_campaign && queryParams.utm_content && queryParams.utm_medium && queryParams.utm_source && queryParams.utm_term){
            queryParams.utm_type = "Set Password";
            data["Utm"] = queryParams;
        }
        if (password !== "" && password.length >= 6 && confirm_password !== "" && confirm_password.length >= 6 && password === confirm_password) {
            setState({ ...field, ...state, showLoader: true });
            let resData = await getChangePwdRes(data);
            if (resData.meta.status === 201) {
                setState({
                    ...field, 
                    ...state, 
                    showLoader: false,
                    open: true,
                    type: "success",
                    msg: "Password reset successfully!!!",
                    formType: "success",
                    mgName: resData.data.name
                });
            }else {
                setState({
                    ...field, 
                    ...state, 
                    showLoader: false,
                    open: true,
                    type: "error",
                    msg: resData.meta.message
                })
            }
         }else{
             setState({
                  ...field, 
                  ...state, 
                 showLoader: false,
                 open: true,
                 type: "error",
                 msg: "*Please enter same password as above !!!",
             })
         }
    }
    const handleClose = () => {
        setState({ ...field, ...state, open: false });
    }

    const customBorder = {
        border: "1px solid",
        color: "#1A3460"
    } 
    if(formType === "success"){
        customBorder.border = "3px solid";
        customBorder.color = "#039849"
    }
    else if(formType === "fail"){
        customBorder.border = "3px solid";
        customBorder.color = "#ED2926"
    }
    return (
        <Paper elevation={3}  style={customBorder}>
            <div className="container px-4 py-5">
                <PopUpAlert type={type} msg={msg} open={open} handleClose={handleClose} hideTime={6000} />
                <Loader show={showLoader} />

                <div className="row" id="CreateAccount" style={formType === "create" ? {display: "block"} : {display: "none"}}>
                    <div className="col-12 mb-5">
                        <h4 className="text-center">To Login Enter Your <br /> Pre-Registered Mobile Number</h4>
                    </div>
                    <div className="col-12 mb-3 pb-2">
                        <InputField
                            disabled={sendOtp === "yes" ? true : false}
                            label="Mobile Number"
                            name="phone"
                            value={phone}
                            onChange={handleChange}
                        />
                    </div>
                    {sendOtp == "yes" &&
                        <>
                            <div className="col-12 mb-3 pb-2">
                                <InputField
                                    type="password"
                                    label="New Password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 mb-3 pb-2">
                                <InputField
                                    type="password"
                                    label="Confirm Password"
                                    name="confirm_password"
                                    value={confirm_password}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 mb-3 pb-2">
                                <InputField
                                    label="OTP"
                                    name="otp"
                                    value={otp}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    }
                    <div className="col-12 mt-4">
                        {sendOtp === "no" ?
                            <Button
                                variant="contained"
                                onClick={getForgetRes}
                                className="bg-mg"
                            >
                                Get OTP
                            </Button>
                           :
                            <Button
                                variant="contained"
                                onClick={getChangeRes}
                                className="bg-mg"
                            >
                                Submit
                            </Button>
                        }
                    </div>
                </div>

                <div className="row  text-center" id="RegisterationSuccess" style={formType === "success" ? {display: "block"} : {display: "none"}}>
                    <div className="col-12">
                        <p className="h3 text-uppercase">Thank you ! {mgName}</p>
                    </div>
                    <div className="col-12 mb-3 pb-2">
                        <img src={AMAZON_URL+"/success-bg.svg"} alt="" />
                    </div>
                    <div className="col-12">
                        <p className="RegisterMsg px-1 text-mg">Your Number has been verified and you can be using our service</p>
                    </div>
                    <div className="col-12 my-2">
                        <a className="btn btn-success btn-block text-white text-uppercase" href={APP_BASE_URL+"/login"}>Get Started</a>
                    </div>
                </div>
                <div  className="row text-center" id="RegisterationFail" style={formType === "fail" ? {display: "block"} : {display: "none"}}>
                    <div className="col-12">
                        <p className="h3 text-uppercase text-mg">Oops!</p>
                    </div>
                    <div className="col-12 mb-3 pb-2">
                        <img src={AMAZON_URL+"/fail-bg.svg"} alt="" />
                    </div>
                    <div className="col-12">
                        <p className="RegisterMsg px-1 text-mg">{failureMsg} !!<br/> Re-enter the pre-registered number, <br/> or contact your RM for help</p>
                    </div>
                    <div className="col-12 my-2">
                        <a className="btn bg-mg btn-block text-white text-uppercase" href={APP_BASE_URL+"/register"} target="_blank">Register Now</a>
                    </div>
                    <div className="col-12 my-2">
                        <a className="btn btn-success btn-block text-white text-uppercase" href="https://api.whatsapp.com/send?phone=+919321271986&amp;text=Hi!">
                            <img src={AMAZON_URL+"/whatsapp.png"} alt="whatsapp" className="whatsapp mr-4"/>
                            I am not able to login
                        </a>
                    </div>
                </div>

            {/*     <div className="row">
                    <div className="col-2 pr-0">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={is_tc_agree}
                                    onChange={handleCheckbox}
                                    name="is_tc_agree"
                                    color="primary"
                                />
                            }
                        />
                    </div>
                    <div className="col-10 mt-2 pt-1 pl-0 text-mg">
                        I agree to <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer" >terms </Link>& conditions.
                    </div>
                </div>
             */}
            </div>
        </Paper>
    )
}
export default RegisterForm