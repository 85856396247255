import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
// import {Form} from 'react-bootstrap';
// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
// import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
// import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import { autoComplete, leadIdClientNameSearch } from "../../../dataParser/commomDataApi.js";
import { UpdateLeadData, getClientData } from "../../../dataParser/getListData";
// import {empty,letterCase,nameCheck, emailCheck, phoneCheck} from "../../../helper";
import { leadAssignCheck, STAGES } from "../../../config/constant";
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import EmailIcon from '@material-ui/icons/Email';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormLabel from '@material-ui/core/FormLabel';

class closeUpdateForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: '',
			number: '',
			alternate_number: '',
			email: '',
			comment: '',
			alternetProject: [],
			alternate_project_id: '',
			selectedVal: {},
			lead_status_id: '',
			closing_reason: '',
			leadDetail: {},
			duplicate_lead: '',
			option:[],
			isError: false,
			sweetShow: false,
			type: "success",
			title: "",
			errors: {
				name: '',
				number: '',
				alternate_number: '',
				email: '',
				comment: '',
			}
		};
	}

	componentDidMount() {
		var userDataArr = [{ name: this.props.leadDetail.data?.AlternateProject?.name, id: this.props.leadDetail.data?.AlternateProject?.id }]

		this.setState({
			leadDetail: this.props.leadDetail.data,
			name: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.name || '',
			number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.number || '',
			alternate_number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.alternate_number || '',
			email: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.email || '',
			closing_reason: this.props.leadDetail.data !== null && this.props.leadDetail.data.closing_reason || '',
			comment: this.props.leadDetail.data.ObjectStage !== null && this.props.leadDetail.data.ObjectStage.comments || '',
			comment_added: this.props.leadDetail.data.ObjectStage !== null ? this.props.leadDetail.data.ObjectStage.comments : '',
			alternetProject: userDataArr[0].name !== undefined ? userDataArr : [],
			selectedVal: userDataArr[0].name !== undefined ? userDataArr[0] : {},
			lead_status_id: STAGES.CLOSED
			// nationality : this.props.leadDetail.data.Client.nationality,
			// lead_status_id : this.props.leadDetail.data.ObjectStage.stage_id,
		});

		// (async () => {
		//        try {
		// 		let resData = await getClientData(this.props.leadDetail.data.Client.id);
		//      console.log("!!!!!!!!!!!!!!!!!!!!!!",resData)
		//      await this.setState({
		//      	max_budget : resData.data.max_budget,
		//      	min_budget : resData.data.min_budget,
		//      	required_bhk : resData.data.required_bhk,
		//      	property_for : resData.data.property_for,
		//      	nationality : resData.data.nationality,
		//      	current_location : resData.data.current_location,
		//      	locality : resData.data.locality,
		//      }) ;
		//        } catch (e) {	
		//            //...handle the error...
		//        }
		//    })();
	}

	updateLeadFrom = async (e) => {

		e.preventDefault();


		let bookupdateData = {};
		// let upadateLeadReq = {};
		bookupdateData.Client = {};
		bookupdateData.ObjectStage = {};
		if (this.state.name !== '') bookupdateData.Client["name"] = this.state.name;
		if (this.state.number !== '') bookupdateData.Client["number"] = this.state.number;
		if (this.state.alternate_number !== '') bookupdateData.Client["alternate_number"] = this.state.alternate_number;
		if (this.state.email !== '') bookupdateData.Client["email"] = this.state.email;
		if (this.state.closing_reason !== '') bookupdateData["closing_reason"] = this.state.closing_reason;
		if(this.state.duplicate_lead !== '') bookupdateData['duplicate_lead'] = this.state.duplicate_lead;
		if (this.state.alternate_project_id !== '') bookupdateData['alternate_project_id'] = this.state.alternate_project_id;
		if (this.state.comment !== '') bookupdateData.ObjectStage["comment"] = this.state.comment;
		bookupdateData.ObjectStage['stage_id'] = STAGES.CLOSED;

		const {name, number, closing_reason, comment} = this.state
		if (name !== '' && number !== '' && closing_reason !== '' && comment !== '') {

			let addLeadRes = await UpdateLeadData(this.state.leadDetail.id, bookupdateData);
			// console.log("addLeadRes XXXXXXXXXXXX",addLeadRes);

			if (addLeadRes.meta.status === 201) {

				// console.log("Lead insert successfully !!!");

				this.setState({
					sweetShow: true,
					type: "success",
					title: "Lead Updated Successfully!!!"

				});

			} else {

				this.setState({
					sweetShow: true,
					type: "error",
					title: addLeadRes.meta.message
				});
			}
		} else {

			this.setState({ isError: true });
		}
	}

	onChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}
	
	handleNumberChange = (e) => {
		const {name, value} = e.target
		if(e.target.validity.valid || value === "")
			this.setState({ [name]: value })
	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();

		// window.location.reload();
	}

	AutoChangeProject = async (e) => {
		this.setState({ isLoading: true });
		// let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				await this.setState({
					isLoading: false,
					alternetProject: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

	handleDuplicateLead = async (e) => {
		const val = e.target.value.trim();
		if(val !== ""){
			const leadDetail = await leadIdClientNameSearch(val)
			if(leadDetail.meta.status == 200){
				this.setState({option: leadDetail.data})
			}
		}
	}

	render() {

		const { sweetShow, type, title, alternetProject, selectedVal, isLoading, closing_reason, duplicate_lead, option, comment } = this.state;
		// console.log("in the render", this.state);

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											required
											id="name"
											name="name"
											label="Client Name"
											value={this.state.name ? this.state.name : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircle />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										required
										disabled
										id="number"
										name="number"
										value={this.state.number ? this.state.number : ''}
										label="Client Number"
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											// required
											id="email"
											name="email"
											label="Client Email"
											value={this.state.email ? this.state.email : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										id="alternate_number"
										name="alternate_number"
										value={this.state.alternate_number ? this.state.alternate_number : ''}
										label="Alternate Number"
										helperText={this.state.isError.alternate_number !== '' ? this.state.isError.alternate_number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Closing Reason</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={closing_reason}
											onChange={this.onChange}
											inputProps={{
												name: 'closing_reason',
												id: 'closing_reason',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="Broking Advisor">Broking Advisor</MenuItem>
											<MenuItem value="Low Budget">Low Budget</MenuItem>
											<MenuItem value="Invalid Number">Invalid Number</MenuItem>
											<MenuItem value="Not Enquired">Not Enquired</MenuItem>
											<MenuItem value="Wrong Number">Wrong Number</MenuItem>
											<MenuItem value="Already Bought">Already Bought</MenuItem>
											<MenuItem value="Duplicate Lead">Duplicate Lead</MenuItem>
											<MenuItem value="Budget Issue">Budget Issue</MenuItem>
											<MenuItem value="Location Issue">Location Issue</MenuItem>
											<MenuItem value="Layout Issue">Layout Issue</MenuItem>
											<MenuItem value="Test Lead">Test Lead</MenuItem>
											<MenuItem value="Possession Issue">Possession Issue</MenuItem>
											<MenuItem value="Not Contactable">Not Contactable</MenuItem>
											<MenuItem value="Payment Plan">Payment Plan</MenuItem>
											<MenuItem value="Decision Delayed">Decision Delayed</MenuItem>
											<MenuItem value="Looking in Resale">Looking in Resale</MenuItem>
											<MenuItem value="Looking in Rental">Looking in Rental</MenuItem>
											<MenuItem value="Looking in Commercial">Looking in Commercial</MenuItem>
											<MenuItem value="Builder's Employee">Builder's Employee</MenuItem>
											<MenuItem value="Not Responding (4 Weeks)">Not Responding (4 Weeks)</MenuItem>
											<MenuItem value="Interested In Other Location">Interested In Other Location</MenuItem>
											<MenuItem value="Interested In Other Project">Interested In Other Project</MenuItem>
											<MenuItem value="Not interested">Not interested</MenuItem>
											<MenuItem value="Plan On Hold">Plan On Hold</MenuItem>
											<MenuItem value="Purchase In Some Other Location">Purchase In Some Other Location</MenuItem>
											<MenuItem value="Not Looking For Property">Not Looking For Property</MenuItem>
											<MenuItem value="Not Inquired">Not Inquired</MenuItem>
											<MenuItem value="Dropped The Plan">Dropped The Plan</MenuItem>
											<MenuItem value="Financial Problem">Financial Problem</MenuItem>
											<MenuItem value="Incoming Facility Not Available">Incoming Facility Not Available</MenuItem>
											<MenuItem value="Switched off">Switched off</MenuItem>
											<MenuItem value="Incomplete Number">Incomplete Number</MenuItem>
											<MenuItem value="Blank">Blank</MenuItem>
											<MenuItem value="Ringing">Ringing</MenuItem>
											<MenuItem value="Call Back">Call Back</MenuItem>
											<MenuItem value="Call Disconnected">Call Disconnected</MenuItem>
											<MenuItem value="Busy">Busy</MenuItem>
										</Select>
									</FormControl>
								</div>
								{this.state.closing_reason == "Duplicate Lead" &&
								<div className="col-sm-6 mb-3">
                                     <Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(option, value) => option.id === value.id}
										getOptionLabel={option => `${option ? option.id : ''} ${option.Client ? option.Client.name : ''}`}
										options={option}
										onChange={(_event, option) => {
											if (option === null) {
											  this.setState({
												option: [],
											  })
											}else{
											  let duplicateLead = option.id;
											  this.setState({
												duplicate_lead : duplicateLead,
											  })
											}
				
										  }}
										renderInput={params => (
											<TextField
												{...params}
												label="Duplicate Lead"
												fullWidth
												onChange={this.handleDuplicateLead}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>}
								<div className="col-sm-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(alternetProject, value) => alternetProject.name === value.name}
										getOptionLabel={alternetProject => alternetProject.name}
										options={alternetProject}
										value={selectedVal}
										loading={isLoading}
										onChange={(_event, alternetProject) => {
											if (alternetProject === null) {
												this.setState({ alternetProject: [] })
											} else {
												this.setState({
													alternet_project_name: alternetProject.name,
													alternate_project_id: alternetProject.id,
													selectedVal: { name: alternetProject.name, id: alternetProject.id }
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												label="Alternate Project"
												fullWidth
												onChange={this.AutoChangeProject}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>
								<div className="col-sm-12 col-12">
									<div className="form-group">
										<TextField
											disabled={(this.state.lead_status_id === this.props.selectedFormId && this.state.comment_added) ? true : false}
											// error={this.state.errors.comment !== '' ? 'error' : ''}
											id="standard-multiline-flexible"
											required
											label="Comments"
											name="comment"
											value={this.state.comment}
											// helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
											multiline
											rowsMax="4"
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>Submit</button>
									</div>
								</div>
								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Above all * fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(closeUpdateForm);
