import React, { Component } from "react";

import UserList from "../../component/userList/usersListing";
import { getUsersDataList } from "../../dataParser/getListUserData";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";
import {cleanStorage} from "../../helper";

class UserListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: "",
      showLoader: false
    };
  }

  async componentDidMount() {
    this.setState({ showLoader: true });
    if(!localStorage.getItem('reload') && window.$user){
      window.location.reload(true);
      localStorage.setItem('reload',true);
      return false;
    }
    var userData = {};
    userData.size = 8;
    userData.pageId = 1;
    userData.filters = [];

    var resData = {};
    // resData = await getUsersDataList(userData);
    // console.log("========================================",resData);
    //    setTimeout(()=>{
    // 	if(resData.meta.status === 200){

    //      this.setState({
    //      	userDetail : resData,
    //      	showLoader : false,
    //      }) ;
    // 		window.scrollTo(0, 0);
    //      this.setState({})

    // 	}else if(resData.meta.status == 401){
    // 		cleanStorage();
    // 		this.props.history.push("/login");
    // 	}else{
    // 		this.setState({
    //      	userDetail: resData,
    //      	showLoader : false
    //      })
    // 	}
    // },500)

    (async () => {
      try {
        resData = await getUsersDataList(userData);
        if (resData.meta.count >= 0 && resData.meta.status === 200) {
          this.setState({
            userDetail: resData,
            showLoader: false
          });
          window.scrollTo(0, 0);
          this.setState({});
        } else if (resData.meta.status == 401) {
          cleanStorage();
          this.props.history.push("/login");
        } else {
          this.setState({
            userDetail: resData,
            showLoader: false
          });
        }
      } catch (e) {
       
        //...handle the error...
      }
    })();
  }

  render() {
    return (
      <Frag>
        <Loader show={this.state.showLoader} />
        {this.state.userDetail.meta && (
          <UserList
            expand={this.props.sideBar}
            userDetail={this.state.userDetail}
            location={this.props.location}
          />
        )}
      </Frag>
    );
  }
}

export default UserListPage;
