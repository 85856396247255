import purple from '@material-ui/core/colors/purple';
import blueGrey from '@material-ui/core/colors/blueGrey';

const themeConfig = {
    palette: {
        primary: blueGrey,
        secondary: purple,
        background: "#FFF"
    },
    status: {
        danger: 'orange',
    },
    overrides: {
        MuiFormControl: {
            marginNormal: {
                marginTop: 0
                , marginBottom: 0
            }
        }
        , MuiAutocomplete: {
            popper: {
                backgroundColor: "#fff"
            }
        }
        , MuiPaper: {
            root: {
                backgroundColor: "#fff"
            }
        }
    }
}

export default themeConfig;
