import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Card} from 'react-bootstrap';
import Frag from "../../utils/Frag/Frag";
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import CloseIcon from '@material-ui/icons/Close';
import Footer from "../../component/footer/footer";
import {APP_BASE_URL} from "../../config/serverKey.js";
import {InputGroup,FormControl} from 'react-bootstrap';
import Cards from '../../component/common/cards/Cards';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import { FacebookShareButton, FacebookIcon,WhatsappShareButton, WhatsappIcon} from "react-share"
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Helmet} from "react-helmet";


class Refer extends Component {

	constructor(props) {
    	super(props);
		this.state = {
	  		user_id : window.$user.id,
		};
	}

	copyToClipboard = (e) => {
		// console.log(this.input.value);	
	    this.input.select();
	    document.execCommand('copy');
	    e.target.focus();
	    this.setState({ copySuccess: 'Copied!' });
  	};
    detailView (id){
	    // console.log("id==========>",id)
	    if(this.state.listDetail === id){
	        this.setState({listDetail : null})
	    }else{
	        this.setState({listDetail : id})
	    }
	}

	render(){

		const {user_id} = this.state;
		// console.log("######################################################",fullDetail);

		return(
			<Frag>
				<Helmet>
					<title>Refer & Earn</title>
					<meta name="theme-color" content="#000000" />
		            <meta name="description" content="There is no limit to earn, so invite..all of yours friends!" />
		            <meta property="og:url" content={APP_BASE_URL+"/register?ref_id="+user_id}/>
		            <meta property="og:title" content="Magnet Refer & Earn"/>
		            <meta property="og:description" content="There is no limit to earn, so invite..all of yours friends!" />
		            <meta property="og:site_name" content="MagnetRefer " />
		            <meta property="og:image" content="https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/MobReferBannerMagnet.svg" />
	          	</Helmet>
                <div className="content-wrapper">
					<div className="referHeader">
							<div className="container-fluid bg-light">
								<div className="row">
									<div className="col-md-12 d-flex text-white ReferBanner justify-content-center">
										<div className="col-md-6 text-center">
											<h1 className="title">Spread the Word, Spread Your Network and Get Rewarded</h1>
											<p className="content">Earn cash amount of ₹7500* per referral.</p>
										</div>
									</div>
								</div>
							</div>
						<section className="bg-light">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-10 col-sm-12 pl-0 pr-0">
				                        <Card className="shadow bg-white rounded InputField ">
											<div className="d-md-flex justify-content-center align-items-center py-4 pl-md-5 px-3">
						                        <InputGroup className="m-0 d-flex justify-content-center align-items-center">
												    <FormControl
												      	aria-label="Default"
												      	aria-describedby="reference url"
					                    				ref={(input) => this.input = input}
						                                id="operate_location"
						                                name="refer"
						                                value={APP_BASE_URL+"/register?ref_id="+user_id}
												    />
												    <InputGroup.Append>
                                                        <Tooltip title="Copy Link">
															<button type="submit" className="btn btn-success" onClick={this.copyToClipboard}><FileCopyIcon/></button>
                                                        </Tooltip>
												    </InputGroup.Append>
											  	</InputGroup>
				                        		<div className="col-md-2 col-sm-12  pl-4 mt-3 mt-md-0">
							                        <InputGroup className="d-flex justify-content-center align-items-center">
                                                        <Tooltip title="Share Link">
															<button className="btn btn-primary rounded" onClick={() => this.detailView(5)}>
																{this.state.listDetail === 5 ? <CloseIcon/> : <ShareSharpIcon/>}
															</button>
                                                        </Tooltip>
												  	</InputGroup>
				                        		</div> 
				                        	</div> 
									 	</Card>
									 	<div className={"col-10 offset-1 col-md-3 offset-md-9 mt-2 py-3 socialCard shadow-lg bg-white rounded bg-white "+" "+ (this.state.listDetail === 5 ? "show" : "hide")}>
										    <div className="container">
									 			<div className="row">
										 			<div className="col text-center">
														<FacebookShareButton
														    url={APP_BASE_URL+"/register?ref_id="+user_id}
														    quote="Magnet Refer & Earn.."
														    hashtag="#Magnet Refer & Earn"
														    >
														    <FacebookIcon logoFillColor="white" size={50} round={true}/>
														</FacebookShareButton>
													</div>
													<div className="col text-center">
														<WhatsappShareButton
														    url={APP_BASE_URL+"/register?ref_id="+user_id}
														    title="Magnet Refer & Earn"
														    separator="  "
														    >
														    <WhatsappIcon logoFillColor="white" size={50} round={true}/>
														</WhatsappShareButton>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<div className="container-fluid">
							<div className="row">
			                    <div className="col-md-12 px-0">
									<div className="work">
										<Cards/>
									</div>
								</div>
							</div>
							<div className="row mb-5 ">
		                        <div className="col-md-12">
									<div className="container">
						                <h3 className="text-center py-3">FAQs</h3>
						                <div className="row">
						                    <div className="col-md-12 quesAns" onClick={() => this.detailView(0)}>
						                        <span className="quesIcon position-relative">
						                        	{this.state.listDetail === 0 ? <RemoveSharpIcon/> : <AddSharpIcon/>}
						                        </span>
						                        <span className="ques">
						                            1.What are the benefits of being a MAGNET Member of Homesfy?
						                        </span>
						                    </div>
						                    <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 0 ? "show" : "hide")}>
						                        <p>
						                        	If you have strong client relationships, Homesfy through its MAGNET initiative offers brokers and other CPs an exciting opportunity to scale up their business in the rapidly growing real estate industry.
						                        </p>
						                    </div>
						                </div>
						                <div className="row">
						                    <div className="col-md-12 quesAns" onClick={() => this.detailView(1)}>
						                        <span className="quesIcon">
						                        	{this.state.listDetail === 1 ? <RemoveSharpIcon/> : <AddSharpIcon/>}
						                        </span>
						                        <span className="ques">
						                            2.What is the MAGNET Referral Program?
						                        </span>
						                    </div>
						                    <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 1 ? "show" : "hide")}>
						                        <p>
						                        	It is an exclusive membership program that extends special benefits like the entitlement of cash amount on the first two transactions done by the referred magnet.
						                        </p>
						                    </div>
						                </div>
						                <div className="row">
						                    <div className="col-md-12 quesAns" onClick={() => this.detailView(2)}>
						                        <span className="quesIcon">
						                        	{this.state.listDetail === 2 ? <RemoveSharpIcon/> : <AddSharpIcon/>}
						                        </span>
						                        <span className="ques">
						                            3.Who is eligible for the MAGNET Referral Program?
						                        </span>
						                    </div>
						                    <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 2 ? "show" : "hide")}>
						                        <p>
						                        	Every enrolled Magnet member is eligible to grab the benefits of the MAGNET referral program.
						                        </p>
						                    </div>
						                </div>
						                <div className="row">
						                    <div className="col-md-12 quesAns" onClick={() => this.detailView(3)}>
						                        <span className="quesIcon">
						                        	{this.state.listDetail === 3 ? <RemoveSharpIcon/> : <AddSharpIcon/>}
						                        </span>
						                        <span className="ques">
						                            4.How much is the reward and is there a limit on how much I can earn?
						                        </span>
						                    </div>
						                    <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 3 ? "show" : "hide")}>
						                        <p>
						                        	There are no limits to how much you can earn So, invite as many friends as you love to.
						                        </p>
						                    </div>
						                </div>
						                <div className="row">
						                    <div className="col-md-12 quesAns" onClick={() => this.detailView(4)}>
						                        <span className="quesIcon">
						                        	{this.state.listDetail === 4 ? <RemoveSharpIcon/> : <AddSharpIcon/>}
						                        </span>
						                        <span className="ques">
						                            5.How can I track my reward process and by when will I get my reward post referral?
						                        </span>
						                    </div>
						                    <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 4 ? "show" : "hide")}>
						                        <p>
						                        	The process is relatively simple and starts once you refer a person and they join with us it shows in your panel. The status and the first two bookings are also shared in the system. Once the successful booking is done by your referred MAGNET then your reward status will be updated in the panel too.
						                        </p>
						                    </div>
						                </div>
						                <div className="row">
						                    <div className="col-md-12 quesAns" onClick={() => this.detailView(5)}>
						                        <span className="quesIcon">
						                        	{this.state.listDetail === 5 ? <RemoveSharpIcon/> : <AddSharpIcon/>}
						                        </span>
						                        <span className="ques">
						                            6.Whom should I contact for having any issue in the whole process?
						                        </span>
						                    </div>
						                    <div className={"col-md-12 quesAns"+" "+ (this.state.listDetail === 5 ? "show" : "hide")}>
						                        <p>
						                        	Regarding any queries or if you encounter any problem during the process you can directly contact any of them from the MAGNET team. They will be happy to assist you.
						                        </p>
						                    </div>
						                </div>         
						            </div> 
		                    	</div>
		                    </div>
					</div>
				</div>
				</div>
				<Footer expand ={this.props.expand} />
			</Frag>		  
		);

	};
};

export default withRouter(Refer);
