import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  getAutoCompleteProject,
  getRegion
} from "../../dataParser/getProjectData";
import { getClosedLeadCount, getBulkLeadCount } from "../../dataParser/bulkLeadData";
import {
  leadInterautoComplete,
  getLeadSourceData
} from "../../dataParser/commomDataApi";
import { getUserListData } from "../../dataParser/getListUserData";
import CircularProgress from "@material-ui/core/CircularProgress";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Loader from "../../component/common/loader/loader";
import Paper from "@material-ui/core/Paper";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import Button from "@material-ui/core/Button";
import {
  autoComplete,
  userleadstats,
  autocompleterolewise,
  assignLeads
} from "../../dataParser/commomDataApi.js";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BED_CONFIG, CLOSING_REASON, DUMP_LEAD, MIN_MAX_BUDGET, REVERT_ROLES } from "../../config/constant/index.js";
import SelectOption from "../common/controls/SelectOption";
import { yesNoType } from "../common/datas/data";
import { cleanStorage } from "../../helper";

class LeadReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetShow: false,
      type: "success",
      title: "",
      showLoader: false,
      reportData: [],
      reportMeta: {},
      source_ids: [],
      magnet: [],
      region: [],
      region_id: "",
      source: [],
      userName: [],
      option: [],
      project_ids: "",
      magnet_id: "",
      ids: "",
      nationality: "",
      internal_st: [],
      internal_status_id: '',
      autoKey: true,
      commingDate_from: null,
      commingDate_to: null,
      dumpDate_from: null,
      dumpDate_to: null,
      min_budget: "",
      max_budget:"",
      isError: false,
      showFilter: true,
      assign_type: '',
      fields: [{ user_id: "", max_leads: "", old: "", assign: "" }],
      values: [{ user_id: "", max_leads: "", old: "", assign: "" }],
      bed_config: "",
      closing_reason: "",
      closing_date_from: null,
      closing_date_to: null,
      is_opp: "",
      is_pipe: "",
      is_magnet: "",
      closed_user_id : "",
      closeRmName: [],
     // closed_user_id: actingUserId,
    };
  }

  async componentDidMount() {
    let reportObj = {};

    var regionData = await getRegion();
    this.setState({ region: regionData });

    var allSourceData = await getLeadSourceData();
    this.setState({ source: allSourceData.data });
  }

  handleAutoUserChange = async e => {
    let value = e.target.value;

    var getUserData = await getUserListData(value);
    this.setState({ isLoading: false });
    this.setState({ userName: getUserData.data });

  };

  AutoChangeProject = async (e) => {
    this.setState({ isLoading: true });
    (async () => {
      try {
        let data = {
          q: e.target.value
        };
        var resData = await leadInterautoComplete('lead_internal_status', data);
        await this.setState({
          isLoading: false,
          internal_st: resData.data
        });
      } catch (e) {
        //...handle the error...
      }
    })();
  }


  handleAutoChange = async e => {
    this.setState({ isLoading: true });
    let value = e.target.value;

    var projectListData = await getAutoCompleteProject(value);

    if (projectListData.meta.status === 200) {
      this.setState({ isLoading: false });
      this.setState({ option: projectListData.data });
    } else {
      this.setState({ isLoading: false });
      this.setState({ option: projectListData });
    }
  };

  formValChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  applyFilter = async () => {
    this.setState({
      showFilter: false,
      showLoader: true
    });

    let filterData = {};
    let activityFilter = {};

    if (this.state.project_ids !== "") {
      filterData["project_ids"] = this.state.project_ids;
    }
    if (this.state.magnet_id !== "") {
      filterData["magnet_ids"] = this.state.magnet_id;
    }
    if (this.state.assign_type !== "") {
      filterData["assign_type"] = this.state.assign_type;
    }

    if (this.state.source_ids !== "") {
      filterData["source_ids"] = this.state.source_ids;
    }


    if (this.state.region_id) {
      filterData["region_id"] = this.state.region_id;
    }

    if (this.state.nationality !== '') {

      filterData["nationality"] = (this.state.nationality);
    }

    if (this.state.internal_status_id !== '') {

      filterData["internal_status_ids"] = this.state.internal_status_id;
    }
    if (this.state.closed_user_id !== '') {

      filterData["closed_user_id"] = this.state.closed_user_id;
    }

    if (this.state.commingDate_from) {
      filterData["created_at_from"] = this.state.commingDate_from
        .split("/")
        .join("-");
    }
    if (this.state.commingDate_to) {
      filterData["created_at_to"] = this.state.commingDate_to
        .split("/")
        .join("-");
    }
    if (this.state.dumpDate_from) {
      filterData["dump_at_from"] = this.state.dumpDate_from
        .split("/")
        .join("-");
    }
    if (this.state.dumpDate_to) {
      filterData["dump_at_to"] = this.state.dumpDate_to
        .split("/")
        .join("-");
    }
    if (this.state.min_budget) {
      filterData["min_budget"] = this.state.min_budget
    }if (this.state.max_budget) {
      filterData["max_budget"] = this.state.max_budget
    }

    if (this.state.bed_config) {
      filterData["bed_config"] = this.state.bed_config;
    }
    if (this.state.closing_reason) {
      filterData["closing_reason"] = this.state.closing_reason;
    }
    if (this.state.closing_date_from) {
      filterData["closing_date_from"] = this.state.closing_date_from
        .split("/")
        .join("-");
    }
    if (this.state.closing_date_to) {
      filterData["closing_date_to"] = this.state.closing_date_to
        .split("/")
        .join("-");
    }
    if (this.state.is_opp !== "") {
      filterData["is_opp"] = this.state.is_opp;
    }
    if (this.state.is_pipe !== "") {
      filterData["is_pipe"] = this.state.is_pipe;
    }

    if (this.state.is_magnet !== "") {
      filterData["is_magnet"] = this.state.is_magnet;
    }

    if (this.state.assign_type === 'old_leads') {
      var leadReportRes = await getClosedLeadCount(filterData);

    } else {
      var leadReportRes = await getBulkLeadCount(filterData);

    }

    this.setState({
      isLoading: false,
      showLoader: false,
      reportData: leadReportRes.data,
      reportMeta: leadReportRes.meta
    });
  };

  resetFilter = () => {
    this.setState({
      autoKey: !this.state.autoKey,
      option: [],
      project_ids: "",
      region_id: "",
      isLoading: false,
      isMagnetLoading: false,
      userName: [],
      source_ids: "",
      dumpDate_from: null,
      dumpDate_to: null,
      min_budget: "",
      max_budget: "",
      commingDate_from: null,
      commingDate_to: null,
      nationality: "",
      internal_status_id: '',
      assign_type: '',
      bed_config: "",
      closing_reason: "",
      closing_date_from: null,
      closing_date_to: null,
      is_opp: "",
      is_pipe: "",
      is_magnet: "",
      closed_user_id : "",
    });
  };

  handleDateChange = (key, date) => {
    this.setState({ [key]: date });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeInput = (i, event) => {
    const val = [...this.state.values];
    const { name, value } = event.target;
    if (value) {
      const max_leads_assign = val[i]["max_leads"] - val[i]["old"];
      if (max_leads_assign >= value && this.state.reportData.count >=  value) {
        val[i][name] = parseInt(value);

        this.setState({ isError: false, values: val });
      } else {
        this.setState({ isError: true });
      }
    }
  };

  handleAddInput = () => {
    const val = [...this.state.values];

    var row = val.length - 1;

    if (!this.state.isError && val[row].assign !== "") {

      const values = [...this.state.values];
      values.push({
        user_id: "",
        max_leads: "",
        old: "",
        assign: ""
      });

      this.setState({ values: values });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({
      autoKey: !this.state.autoKey,
      option: [],
      project_ids: "",
      region_id: "",
      isLoading: false,
      isMagnetLoading: false,
      userName: [],
      source_ids: "",
      dumpDate_from: null,
      dumpDate_to: null,
      min_budget: "",
      max_budget: "",
      commingDate_from: null,
      commingDate_to: null,
    });

  }

  handleRemoveInput = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values: values });
  };

  handleAutoUserDumpChange = async e => {
    this.setState({ isLoading: true });
    let data = {
      q: e.target.value
    };
    let resData = await autocompleterolewise("users", data);
    this.setState({ isLoading: false, userName: resData.data });
  };

  handleRmAutoChange = async (e) => {
    this.setState({ isLoading: true });
    let data = {};
    let resData = {};
    data.users_active = 1;        
    if (this.state.group === 'magnet') {
        data.q = e.target.value;
        data.roles = [REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.MAGNET_TL]
        resData = await autoComplete('users', data);
    } else {
        data.q = e.target.value;
        if(this.state.role === 'saleshead') {
            data.roles = [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_MANAGER ,REVERT_ROLES.SOURCING_S2 ,REVERT_ROLES.SOURCING_S3 , REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD] 
        }
        else{
            data.roles = [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.SALES_MANAGER ,REVERT_ROLES.SOURCING_S2 ,REVERT_ROLES.SOURCING_S3 ,REVERT_ROLES.MAGNET_TL, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD]
        }
        resData = await autoComplete('users', data);
    }

    if (resData.meta.status === 200) {

        this.setState({ isLoading: false });
        this.setState({ closeRmName: resData.data })
    } else if (resData.meta.status === 401) {

        cleanStorage();
        this.props.history.push("/login");

    } else {

        this.setState({ isLoading: false });
        this.setState({ closeRmName: [] })
    }

  };
  userSat = async (id, e) => {
    let resData = await userleadstats("dump_lead", id);
    const val = [...this.state.values];
    val[e]["user_id"] = id;
    val[e]["max_leads"] = resData.data.dump_lead_count;
    val[e]["old"] = resData.data.assign_count;
    this.setState({ values: val });
    document.querySelector("input.max_leads_" + e).value =
      resData.data.dump_lead_count;
    document.querySelector("input.old_" + e).value = resData.data.assign_count;
  };
  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    window.location.reload();
  };
  handleAutoMagnetChange = async (e) => {
    this.setState({ isMagnetLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET]
    };
    let resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isMagnetLoading: false });
      this.setState({ magnet: resData.data })
    }

  }

  submit = async () => {
    const val = [...this.state.values];

    var row = val.length - 1;
    if (!this.state.isError && val[row].assign !== "") {
      this.setState({ showLoader: true });
      let filter = {};
      filter['assign_type'] = this.state.assign_type;
      if (this.state.project_ids !== "")
        filter["project_ids"] = this.state.project_ids;
      if (this.state.source_ids !== "")
        filter["source_ids"] = this.state.source_ids;
      if (this.state.region_id) filter["region_id"] = this.state.region_id;
      if (this.state.commingDate_from) {
        filter["created_at_from"] = this.state.commingDate_from
          .split("/")
          .join("-");
      }
      if (this.state.commingDate_to) {
        filter["created_at_to"] = this.state.commingDate_to
          .split("/")
          .join("-");
      }
      if (this.state.dumpDate_from) {
        filter["dump_at_from"] = this.state.dumpDate_from
          .split("/")
          .join("-");
      }
      if (this.state.dumpDate_to) {
        filter["dump_at_to"] = this.state.dumpDate_to
          .split("/")
          .join("-");
      }
      if (this.state.min_budget) {
        filter["min_budget"] = this.state.min_budget
      }
      if (this.state.max_budget) {
        filter["max_budget"] = this.state.max_budget
      }

      if (this.state.magnet_id !== "") {
        filter["magnet_ids"] = this.state.magnet_id;
      }
      if (this.state.nationality !== '') {
  
        filter["nationality"] = (this.state.nationality);
      }
      if (this.state.internal_status_id !== '') {
  
        filter["internal_status_ids"] = this.state.internal_status_id;
      }
      if (this.state.closed_user_id !== '') {
        filter["closed_user_id"] = this.state.closed_user_id;
      }
      if (this.state.bed_config) {
        filter["bed_config"] = this.state.bed_config;
      }
      if (this.state.closing_reason) {
        filter["closing_reason"] = this.state.closing_reason;
      }
      if (this.state.closing_date_from) {
        filter["closing_date_from"] = this.state.closing_date_from
          .split("/")
          .join("-");
      }
      if (this.state.closing_date_to) {
        filter["closing_date_to"] = this.state.closing_date_to
          .split("/")
          .join("-");
      }
      if (this.state.is_opp !== "") {
        filter["is_opp"] = this.state.is_opp;
      }
      if (this.state.is_pipe !== "") {
        filter["is_pipe"] = this.state.is_pipe;
      }
      if (this.state.is_magnet !== "") {
        filter["is_magnet"] = this.state.is_magnet;
      }
      let data = { filter, assign_to: this.state.values };
      let resData = await assignLeads("dump_lead", data);
      if (resData.meta.status === 200) {
        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "success",
          title: "Leads Assigned"
        });
      } else {
        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "error",
          title: resData.meta.message
        });
      }
    }
  };

  render() {
    const { count, status } = this.state.reportMeta;

    const {
      sweetShow,
      type,
      title,
      source,
      userName,
      nationality,
      internal_st,
      autoKey,
      dumpDate_from,
      dumpDate_to,
      min_budget,
      max_budget,
      commingDate_from,
      commingDate_to,
      region_id,
      region,
      option,
      isLoading,
      assign_type,
      magnet,
      isMagnetLoading,
      bed_config,
      closing_reason,
      closing_date_from,
      closing_date_to,
      is_opp,
      is_pipe,
      is_magnet,
      closed_user_id,
      closeRmName,
    } = this.state;


    return (
      <Frag>
        <div
          className="content-wrapper">
          <SweetAlert
            show={sweetShow}
            type={type}
            title={title}
            changeSweet={this.handleSweet}
          />
          <Loader show={this.state.showLoader} />
          <div className="container-fluid mb-3">
            <div className="row">
              <div className="col-md-12 mt-3 mb-2 text-center">
                <span
                  className="fs-20 cursor-point"
                  onClick={() => {
                    this.setState({ showFilter: true });
                  }}
                >
                  Lead Activity Filter
                  {this.state.showFilter === true ? (
                    <ExpandLessIcon className="fs-26" />
                  ) : (
                      <ExpandMoreIcon className="fs-26" />
                    )}
                </span>
              </div>
            </div>
            <div className={this.state.showFilter ? "show" : "hide"}>
              <Paper
                elevation={3}
                className={"py-2 " + (this.state.showFilter ? "show" : "hide")}
              >
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormLabel component="legend">Assign Type</FormLabel>
                    <RadioGroup row aria-label="assign_type" name="assign_type" value={assign_type} onChange={this.onChange}>
                      <FormControlLabel value="bulk" control={<Radio color="primary" />} label="Bulk" />
                      <FormControlLabel value="old_leads" control={<Radio color="primary" />} label="Old Leads" />
                    </RadioGroup>
                  </div>
                </div>

                {assign_type === 'bulk' &&

                  <Frag>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-2 mb-3">
                          <FormControl>
                            <Autocomplete
                              id="asynchronous-demo"
                              getOptionSelected={(magnet, value) => magnet.name === value.name}
                              getOptionLabel={magnet => magnet.name + " (" + magnet.phone + ")"}
                              options={magnet}
                              loading={isMagnetLoading}
                              onChange={(_event, magnet) => {
                                if (magnet === null) {
                                  this.setState({ magnet: [] })
                                } else {
                                  this.setState({
                                    magnet_id: magnet.id,
                                    rm_email: magnet.email,
                                  })
                                }
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Magnet"
                                  fullWidth
                                  onChange={this.handleAutoMagnetChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isMagnetLoading ? (
                                          <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    )
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                        <div className="col-md-2 mb-3">
                          <Autocomplete
                            multiple
                            key={autoKey}
                            id="projectData"
                            getOptionSelected={(option, value) =>
                              option.name === value.name
                            }
                            getOptionLabel={option => option.name}
                            options={option}
                            loading={isLoading}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  label={option.name}
                                  key={index}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(_event, option) => {
                              var projectId = option.map(ops => ops.id);
                              this.setState({
                                project_ids: projectId
                              });

                              if (option === null) {
                                this.setState({ option: [] });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Project Name"
                                fullWidth
                                onChange={this.handleAutoChange}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {isLoading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="row">
                            <div className="col-sm-6 col-6">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                autoOk={true}
                                  margin="normal"
                                  id="comming_date_form"
                                  label="Coming From"
                                  variant="inline"
                                  format="yyyy/MM/dd"
                                  maxDate={new Date()}
                                  name="commingDate_from"
                                  value={commingDate_from}
                                  onChange={(key, date) =>
                                    this.handleDateChange("commingDate_from", date)
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-sm-6 col-6">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                autoOk={true}
                                  margin="normal"
                                  id="comming_date_to"
                                  label="Coming To"
                                  variant="inline"
                                  format="yyyy/MM/dd"
                                  maxDate={new Date()}
                                  name="commingDate_to"
                                  value={commingDate_to}
                                  onChange={(key, date) =>
                                    this.handleDateChange("commingDate_to", date)
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>

                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="row">
                            <div className="col-sm-6 col-6">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                autoOk={true}
                                  margin="normal"
                                  label={`${DUMP_LEAD} From`}
                                  variant="inline"
                                  format="yyyy/MM/dd"
                                  maxDate={new Date()}
                                  name="dumpDate_from"
                                  value={dumpDate_from}
                                  onChange={(key, date) =>
                                    this.handleDateChange("dumpDate_from", date)
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-sm-6 col-6">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                autoOk={true}
                                  margin="normal"
                                  label={`${DUMP_LEAD} To`}
                                  variant="inline"
                                  format="yyyy/MM/dd"
                                  maxDate={new Date()}
                                  name="dumpDate_to"
                                  value={dumpDate_to}
                                  onChange={(key, date) =>
                                    this.handleDateChange("dumpDate_to", date)
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12">
                      <div className="row">
                        <div className="col-sm-6 text-right col-6 mt-2">
                          <div className="form-group">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.applyFilter}
                            >
                              Apply
                                  </Button>
                          </div>
                        </div>
                        <div className="col-sm-6 col-6 mt-2">
                          <div className="form-group">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={this.resetFilter}
                            >
                              Reset
                                  </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Frag>
                }

                {assign_type === 'old_leads' &&

                  <Frag>
                    <div className="col-md-12">
                      <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                          <Autocomplete
                            multiple
                            key={autoKey}
                            id="source-demo"
                            getOptionSelected={(source, value) =>
                              source.name === value.name
                            }
                            getOptionLabel={source => source.name}
                            options={source}
                            renderTags={(value, getTagProps) =>
                              value.map((source, index) => (
                                <Chip
                                  label={source.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(_event, source) => {
                              var projectId = source.map(ops => ops.id);
                              this.setState({
                                source_ids: projectId
                              });

                              if (source === null) {
                                this.setState({ source: [] });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Source"
                                fullWidth

                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-2 mb-3">
                          <Autocomplete
                            multiple
                            key={autoKey}
                            id="regions"
                            getOptionSelected={(region, value) =>
                              region.name === value.name
                            }
                            getOptionLabel={region => region.name}
                            options={region}
                            renderTags={(value, getTagProps) =>
                              value.map((region, index) => (
                                <Chip
                                  label={region.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(_event, region) => {
                              var projectId = region.map(ops => ops.id);
                              this.setState({
                                region_id: projectId
                              });

                              if (region === null) {
                                this.setState({ region: [] });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Select Region"
                                fullWidth
                                onChange={this.handleAutoChange}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-2 mb-3">
                          <Autocomplete
                            multiple
                            key={autoKey}
                            id="projectData"
                            getOptionSelected={(option, value) =>
                              option.name === value.name
                            }
                            getOptionLabel={option => option.name}
                            options={option}
                            loading={isLoading}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  label={option.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(_event, option) => {
                              var projectId = option.map(ops => ops.id);
                              this.setState({
                                project_ids: projectId
                              });

                              if (option === null) {
                                this.setState({ option: [] });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Project Name"
                                fullWidth
                                onChange={this.handleAutoChange}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {isLoading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-2 mb-3">
                          <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">Nationality</InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={nationality}
                              onChange={this.formValChange}
                              inputProps={{
                                name: 'nationality',
                                id: 'nationality',
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="1">Indian</MenuItem>
                              <MenuItem value="2">NRI</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-2 mb-3">
                          <Autocomplete
                            multiple
                            id="internalStatus"
                            key={autoKey}
                            getOptionSelected={(internal_st, value) => internal_st.name === value.internal_status_name}
                            getOptionLabel={internal_st => internal_st.name}
                            options={internal_st}
                            renderTags={(value, getTagProps) =>
                              value.map((internal_st, index) => (
                                <Chip label={internal_st.name} {...getTagProps({ index })} />
                              ))
                            }
                            onChange={(_event, internal_st) => {
                              var projectId = internal_st.map((ops) => ops.id);
                              this.setState({
                                internal_status_id: projectId,
                              })

                              if (option === null) {
                                this.setState({ internal_st: [] })
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Activity Status"
                                fullWidth
                                onChange={this.AutoChangeProject}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {isLoading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-2 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            autoOk={true}
                              margin="normal"
                              id="comming_date_form"
                              label="Coming From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="commingDate_from"
                              value={commingDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange("commingDate_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            autoOk={true}
                              margin="normal"
                              id="comming_date_to"
                              label="Coming To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="commingDate_to"
                              value={commingDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("commingDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            autoOk={true}
                              margin="normal"
                              label={`${DUMP_LEAD} From`}
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="dumpDate_from"
                              value={dumpDate_from}
                              onChange={(key, date) =>
                                this.handleDateChange("dumpDate_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            autoOk={true}
                              margin="normal"
                              label={`${DUMP_LEAD} To`}
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="dumpDate_to"
                              value={dumpDate_to}
                              onChange={(key, date) =>
                                this.handleDateChange("dumpDate_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 mb-3">
                          <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">Min Budget </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={min_budget}
                              onChange={this.formValChange}
                              inputProps={{
                                name: 'min_budget',
                                id: 'min_budget',
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {MIN_MAX_BUDGET.map((item, i)=>(
                                <MenuItem value={item.key} key={i}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-md-2 mb-3">
                          <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">Max Budget</InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={max_budget}
                              onChange={this.formValChange}
                              inputProps={{
                                name: 'max_budget',
                                id: 'max_budget',
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {MIN_MAX_BUDGET.map((item, i)=>(
                                <MenuItem value={item.key} key={i}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-2 mb-3">
                          <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">Bed Config</InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={bed_config}
                              onChange={this.formValChange}
                              inputProps={{
                                name: 'bed_config',
                                id: 'bed_config',
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {BED_CONFIG.map((item, i)=>(
                                <MenuItem value={item.key} key={i}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                      </div>
                        <div className="col-md-2 mb-3">
                          <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">Closing Reason</InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={closing_reason}
                              onChange={this.formValChange}
                              inputProps={{
                                name: 'closing_reason',
                                id: 'closing_reason',
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {CLOSING_REASON.map((item, i)=>(
                                <MenuItem value={item} key={i}>{item}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                      </div>


                      <div className="col-md-2 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              label="Closing Date From"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="closing_date_from"
                              value={closing_date_from}
                              onChange={(key, date) =>
                                this.handleDateChange("closing_date_from", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 col-6">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk={true}
                              margin="normal"
                              id="closing_date_to"
                              label="Closing Date To"
                              variant="inline"
                              format="yyyy/MM/dd"
                              maxDate={new Date()}
                              name="closing_date_to"
                              value={closing_date_to}
                              onChange={(key, date) =>
                                this.handleDateChange("closing_date_to", date)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 col-6">
                          <SelectOption
                            variant="standard"
                            label="Is Opportunity"
                            name="is_opp"
                            value={is_opp}
                            options={yesNoType}
                            onChange={this.formValChange}
                          />
                        </div>
                        <div className="col-md-2 col-6">
                          <SelectOption
                            variant="standard"
                            label="Is Pipeline"
                            name="is_pipe"
                            value={is_pipe}
                            options={yesNoType}
                            onChange={this.formValChange}
                          />
                        </div>
                        <div className="col-md-2 col-6">
                          <SelectOption
                            variant="standard"
                            label="Magnet Lead"
                            name="is_magnet"
                            value={is_magnet}
                            options={yesNoType}
                            onChange={this.formValChange}
                          />
                        </div>
                        <div className="col-md-2 mb-3">
                        <Autocomplete
                          id="UserData"
                          getOptionSelected={(closeRmName, value) =>
                            closeRmName.name === value.name
                          }
                          getOptionLabel={closeRmName =>
                            closeRmName.name + " (" + closeRmName.phone + ")"
                          }
                          options={closeRmName}
                          loading={isLoading}
                          renderTags={(value, getTagProps) =>
                            value.map((closeRmName, index) => (
                              <Chip
                                className="text-capitalize"
                                label={closeRmName.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={async (_event, closeRmName) => {
                            if (closeRmName === null) {
                              this.setState({
                                closeRmName: [],
                                closed_user_id: ""
                              });
                            } else {
                              await this.setState({
                                closed_user_id: closeRmName.id
                              });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Closed by Rm-Name"
                              fullWidth
                              onChange={this.handleRmAutoChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12">
                      <div className="row">
                        <div className="col-sm-6 text-right col-6 mt-2">
                          <div className="form-group">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.applyFilter}
                            >
                              Apply
                                  </Button>
                          </div>
                        </div>
                        <div className="col-sm-6 col-6 mt-2">
                          <div className="form-group">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={this.resetFilter}
                            >
                              Reset
                                  </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Frag>
                }



              </Paper>
            </div>
            <div className="row mt-5">
              {status === 200 && (
                <Frag>
                  <div className="col-md-12 mb-3">
                    <Frag>
                      <span className="fs-16">
                        Total Leads: {this.state.reportData.count}
                      </span>
                    </Frag>
                  </div>
                  {this.state.values.map((field, idx) => (
                    <Frag key={idx}>
                      <div className="col-md-3 mb-3">
                        <Autocomplete
                          key={`${field}-${idx}`}
                          id="UserData"
                          getOptionSelected={(userName, value) =>
                            userName.name === value.name
                          }
                          getOptionLabel={userName =>
                            userName.name + " (" + userName.phone + ")"
                          }
                          options={userName}
                          loading={isLoading}
                          renderTags={(value, getTagProps) =>
                            value.map((userName, index) => (
                              <Chip
                                className="text-capitalize"
                                label={userName.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={async (_event, userName) => {
                            if (userName === null) {
                              this.setState({
                                userName: [],
                                user_id: ""
                              });
                            } else {
                              await this.setState({
                                user_id: userName.id
                              });
                              await this.userSat(userName.id, idx);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="User Name"
                              fullWidth
                              onChange={this.handleAutoUserDumpChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </div>

                      <div
                        key={`${field}-${idx}`}
                        className={
                          "maindiv col-md-2 mt-2 mt-md-0 mmt-2" + " max_leads_" + idx
                        }
                      ><InputLabel>Max Leads</InputLabel>
                        <input
                          type="text"
                          className={"form-control" + " max_leads_" + idx}
                          id="max_leads"

                          disabled
                          name="max_leads"
                          value={field.max_leads}
                        />
                      </div>
                      <div
                        key={`${field}-${idx}`}
                        className={"maindiv col-md-2 mt-2 mt-md-0 mmt-2" + " old_" + idx}
                      ><InputLabel>Assigned Leads</InputLabel>
                        <input
                          className={"form-control " + "old_" + idx}
                          type="text"
                          id="old"

                          disabled
                          name="old"
                          value={field.old}
                        />
                      </div>
                      <div
                        key={`${field}-${idx}`}
                        className={"maindiv col-md-2  mt-2 mt-md-0" + " assign_" + idx}
                      >
                        <TextField
                          error={this.state.isError ? true : false}
                          required
                          id="assign"
                          className={"assign_" + idx}
                          name="assign"
                          label="Assign Leads"
                          onChange={e => this.handleChangeInput(idx, e)}
                          InputProps={{}}
                        />
                      </div>
                      <div className="col-md-3 pl-md-3 pl-0">
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => this.handleAddInput()}
                      >
                        <AddBoxIcon fontSize="large" />
                      </button>
                      {idx ? (
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => this.handleRemoveInput(idx)}
                        >
                          <IndeterminateCheckBoxIcon
                            fontSize="large"
                            style={{ color: "#dc3545" }}
                          />
                        </button>
                      ) : (
                          ""
                        )}
                        </div>
                    </Frag>
                  ))}
                  <div className="col-sm-6 col-6 mt-3">
                    <div className="form-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.submit}
                      >
                        submit
                      </Button>
                    </div>
                  </div>
                </Frag>
              )}
            </div>
          </div>
        </div>
      </Frag>
    );
  }
}

export default withRouter(LeadReport);
