import React from 'react'
import Button from '@material-ui/core/Button'

class ButtonSubmit extends React.Component {
	constructor(props){
		super(props)
	}
	render(){
		return(
			<div className="row justify-content-center">
				<Button
					variant="contained" 
					onClick={this.props.onClick} 
					// style={{ backgroundColor: '#007bff', color: '#fff' }}
					color="primary"
					>
					{this.props.lable ? this.props.lable : 'Submit' }
				</Button>
			</div>
		)
	}
}

export default ButtonSubmit