import React from 'react';

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Chip from '@material-ui/core/Chip';
import TextField from './TextField';
import constants from '../config/constant/index';

import './CallRecordBlock.sass'
import clsx from 'clsx';


const defaultState = {
}


const CallRecord = props => {
    return <Box><audio controls autoPlay>
        <source src={props.src} type="audio/mp3" />
        Your browser does not support the audio element.
    </audio></Box>
}

const CallRecordBlock = props => {
    const {header, date, author, agent_name, lead_status, recording_url, duration} = {...props}
    const [listen, setListen] = React.useState(false);

    const handleListen = () => {
        setListen(true);
    }

    const [state, setState] = React.useState({
        ...defaultState,
    });

    return <Box className="CallRecordBlock" position="relative">
        <Card onClick={() => {}}>
            <Box className="date">
                <Chip label={date} />
            </Box>
            <CardContent>
                <Box mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField label="RM Name" className="no-edit" value={agent_name} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Lead Status" className="no-edit" value={constants.REVERT_STAGES[lead_status]}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box align="center" mt={5}>
                    {listen 
                        ? <CallRecord  src={recording_url} />
                        : <Box style={{margin: "11px 0"}}><Chip label={`Listen (${duration})`} className='listen' onClick={handleListen} /></Box>
                    }
                </Box>
            </CardContent>
        </Card>
    </Box>
}


export default CallRecordBlock;
