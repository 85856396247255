import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import { getApproveMagnetUser } from "../../../dataParser/getListUserData";
import { getUpdateBulkLead } from "../../../dataParser/bulkLeadData";
import { autoComplete } from "../../../dataParser/commomDataApi";
import { CLOSING_REASON, REVERT_ROLES } from "../../../config/constant/index.js";
import InputField from "../controls/InputField"
import {Input, Chip, Checkbox, ListItemText} from '@material-ui/core';
import {primary, operate, leads, alternateBusiness, businessTime, yesNoType, businessOperate} from "../datas/data"
import RadioGroup from '../controls/RadioGroup'
import PopUpAlert from "../sweetAlert/popUpAlert";
import { cleanStorage } from '../../../helper';
import { magnetApproveRoles } from '../../../config/constant/crmRoleFunctionPermission.js';
import SelectOption from '../controls/SelectOption.js';
import { getRegionParentsData } from '../../../dataParser/getCpUserData.js';
import Loader from "../../common/loader/loader";
class UpdateBulkForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			project_addedby: '',
			bulkId: '',
			leadType: '',
			magnet: [],
			userName: '',
			mobileNumber: '',
			source: '',
			projectId: '',
			magneteLead: '',
			city: '',
			comment: '',
			closing_reason: '',
			activate: 1,
			rm_approved: 1,
			admin_approved: 1,
			isError: false,
			sweetShow: false,
			isLoading: false,
			type: "success",
			title: "",
			errors: {
				closing_reason: '',
				comment: ''
			},
			userInfo: this.props.userInfo,
			first_name: "",
			last_name: "",
			phone: "",
			email: "",
			total_experience: '',
			operate_location: "",
			deal_business_types: [],
            plateform_present_in : [],
            genrate_leads : [],
            alternate_business_us : [],
			devote_time: "", 
            have_team: null,
            operate_business : '',
            own_state_business: null,
			open: false,
			msg: "",
			alertType: "success"
			, region_option: []
			, work_in_region: ""
		};
	}

	componentDidMount() {
		
		const {userInfo} = this.state;
		var homesfy_lg = (localStorage.getItem("homesfy_lg"));
		var addedBy = '';
		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg));
			addedBy = user.id;
			this.setState({
				project_addedby: addedBy,
				role: user.role
			})

		} else {
			this.setState({ project_addedby: addedBy })
		}
		this.setState({
			first_name: userInfo?.first_name,
			last_name: userInfo?.last_name,
			phone: userInfo?.phone,
			email: userInfo?.email
		})
		this.getRegionOptions();	
	}

	getRegionOptions = async() =>{
		this.setState({showLoader:true})
		let getRes = await getRegionParentsData();
		this.setState({
			showLoader: false,
			region_option: getRes.data
		})
	}
	updateLeadFrom = async (e) => {

		e.preventDefault();
		let bulkId = this.props.bulkId;

		let upadateBulkReq = {};

		const addLeadRequest = (({ closing_reason, comment, activate }) => ({
			closing_reason,
			comment,
			activate
		}))(this.state);

		let bookupdateData = {};


		if (addLeadRequest.closing_reason != '') {

			bookupdateData["closing_reason"] = addLeadRequest.closing_reason;
		}

		if (addLeadRequest.comment != '') {

			bookupdateData["comment"] = addLeadRequest.comment;
		}

		if (addLeadRequest.activate != '') {

			bookupdateData["is_moved"] = parseInt(addLeadRequest.activate);
		}

		upadateBulkReq.id = bulkId;
		upadateBulkReq.data = bookupdateData;


		if (this.state.activate != '') {
			if (bookupdateData["is_moved"]) {
				var addLeadRes = await getUpdateBulkLead(upadateBulkReq);
				// console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",addLeadRes)
				if (addLeadRes.meta.status === 201) {
					this.setState({
						sweetShow: true,
						type: "success",
						title: "Lead Updated Successfully!!!"

					});
				} else if (addLeadRes.meta.status === 409) {
					this.setState({
						sweetShow: true,
						type: "error",
						title: addLeadRes.meta.message

					});
				}
				else {
					this.setState({
						sweetShow: true,
						type: "error",
						title: "Something went wrong !!!"

					});
				}
			}
			else if (!bookupdateData["is_moved"] && addLeadRequest.closing_reason != '') {

				var addLeadRes = await getUpdateBulkLead(upadateBulkReq);
				// console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",addLeadRes)
				if (addLeadRes.meta.status === 201) {
					this.setState({
						sweetShow: true,
						type: "success",
						title: "Lead Updated Successfully!!!"

					});
				} else if (addLeadRes.meta.status === 409) {
					this.setState({
						sweetShow: true,
						type: "error",
						title: addLeadRes.meta.message

					});
				}
				else {
					this.setState({
						sweetShow: true,
						type: "error",
						title: "Something went wrong !!!"

					});
				}
			} else {

				this.setState({ isError: true });
			}
		} 
	}

	updateMagnetLeadFrom = async (e) => {
		e.preventDefault();
		let addUserData = {};
		addUserData.data = {};
		addUserData.data.Usermeta = {};

		if (magnetApproveRoles.includes(this.state.role)) {
			addUserData.data["rm_approved"] = parseInt(this.state.rm_approved);
			addUserData.data["comment"] = this.state.comment;
		}
		
		if (this.state.role === "admin" || this.state.role === "magnetadmin" || this.state.role === "magnetpnl") {
			addUserData.data["admin_approved"] = parseInt(this.state.admin_approved);
			if (this.state.assign_rm_id != '') addUserData.data["service_rm_id"] = this.state.assign_rm_id;
			if(this.state.admin_approved == 1 && !this.state.assign_rm_id){
				await this.setState({
					showLoader: false,
					open: true,
					alertType: "error",
					msg: 'Please Assign Service RM.'
				});
				return false;
			}
		}
		addUserData.id = this.props.userId;

		let {first_name, last_name, phone, email, total_experience, operate_location, deal_business_types
			, plateform_present_in, genrate_leads, alternate_business_us, devote_time, have_team
			, operate_business, own_state_business, work_in_region} = this.state;

		addUserData.data.first_name = first_name;
		addUserData.data.last_name = last_name;
		addUserData.data.phone = phone;
		addUserData.data.email = email;
		if(total_experience){addUserData.data.Usermeta.total_experience = parseFloat(total_experience)};
		if(operate_location){addUserData.data.Usermeta.operate_location = operate_location};
		if(deal_business_types.length > 0){addUserData.data.Usermeta.deal_business_types = deal_business_types};
		if(plateform_present_in.length > 0){addUserData.data.Usermeta.plateform_present_in = plateform_present_in};
		if(genrate_leads.length > 0){addUserData.data.Usermeta.genrate_leads = genrate_leads};
		if(alternate_business_us.length > 0){addUserData.data.Usermeta.alternate_business_us = alternate_business_us};
		if(devote_time){addUserData.data.Usermeta.devote_time = devote_time};
		if(have_team){addUserData.data.Usermeta.have_team = have_team};
		if(operate_business){addUserData.data.Usermeta.operate_business = operate_business};
		if(own_state_business){addUserData.data.Usermeta.own_state_business = own_state_business};
		if(work_in_region){addUserData.data.Usermeta.work_in_region = work_in_region};
		
		if(first_name !== "" && last_name !== "" && phone !== "" && email !== "" && 
			(!magnetApproveRoles.includes(this.state.role) || this.state.rm_approved == 0 || this.state.rm_approved == 2 ? true :
				(total_experience !== '' && operate_location !== "" && plateform_present_in.length > 0 && operate_business !== '' && have_team !== null && work_in_region)
		)){
			var addLeadRes = await getApproveMagnetUser(addUserData);

			if (addLeadRes.meta.status === 201) {

				this.setState({
					showLoader: false,	
					sweetShow: true,
					type: "success",
					title: "Updated successfully !!!"
				});
			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: addLeadRes.meta.message
				});
		
			}
		}
		else{
			this.setState({
				showLoader: false,
				open: true,
				alertType: "error",
				msg: "Above all * fields are required !"
			});	
		}
	}

	onChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}

	handleAutoChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {};

		data.q = e.target.value;
		data.roles = [REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.CUSTOMER_SUCCESS]
		let resData = await autoComplete('users', data);

		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ magnet: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ magnet: resData.data })
		}

	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		window.location.reload();
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

    handleClose = () => {
        this.setState({ open: false });
    }
	handleNumberChange = (e) => {
		let { value } = e.target
		value = value.replace(/\D/g, "")
		this.setState({
			phone: value
		})
	}

	render() {
		const { sweetShow, type, title, closing_reason, activate, isLoading, rm_approved, admin_approved, magnet
			, first_name, last_name, phone, email, total_experience, operate_location, deal_business_types
			, plateform_present_in, genrate_leads, alternate_business_us, devote_time, have_team, operate_business
			, own_state_business, role, open, alertType, msg
			, region_option, work_in_region} = this.state;
		
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
		const MenuProps = {
			PaperProps: {
			  style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			  },
			},
		  };
		return (
			<Frag>
				<Loader show={this.state.showLoader} />
				<div className="projectForm">
                	<PopUpAlert type={alertType} msg={msg} open={open} handleClose={this.handleClose} hideTime={6000} />
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								{this.props.userId === undefined ?
									<div className="col-md-6 col-sm-6 text-center col-12 mb-3">
										<FormControl>
											<InputLabel id="demo-controlled-open-select-label">Lead Status</InputLabel>
											<Select
												labelId="demo-controlled-open-select-label"
												value={activate}
												onChange={this.onChange}
												inputProps={{
													name: 'activate',
													id: 'activate',
												}}
											>
												<MenuItem value="1">Reactivate</MenuItem>
												<MenuItem value="0">Disqualify</MenuItem>
											</Select>
										</FormControl>
									</div>
									:
									''
								}
								{this.props.userId !== undefined && magnetApproveRoles.includes(role) ?
									<div className="col-md-6 col-sm-6 text-center col-12 mb-3">
										<FormControl>
											<InputLabel id="demo-controlled-open-select-label">Magnet Status</InputLabel>
											<Select
												labelId="demo-controlled-open-select-label"
												value={rm_approved}
												onChange={this.onChange}
												inputProps={{
													name: 'rm_approved',
													id: 'rm_approved',
												}}
											>
												<MenuItem value="1">Approve</MenuItem>
												<MenuItem value="0">Disapprove</MenuItem>
												<MenuItem value="2">Not Interested</MenuItem>
											</Select>
										</FormControl>
									</div>
									:
									''
								}
								{this.props.userId !== undefined && (this.state.role === "admin" || this.state.role === "superuser" || this.state.role === "magnetadmin" || this.state.role === "magnetpnl") ?
									<Frag>
										<div className="col-md-6 col-sm-6 text-center col-12 mb-3">
											<FormControl>
												<InputLabel id="demo-controlled-open-select-label">Magnet Status</InputLabel>
												<Select
													labelId="demo-controlled-open-select-label"
													value={admin_approved}
													onChange={this.onChange}
													inputProps={{
														name: 'admin_approved',
														id: 'admin_approved',
													}}
												>
													<MenuItem value="1">Approve</MenuItem>
													<MenuItem value="0">Disapprove</MenuItem>
												</Select>
											</FormControl>
										</div>
										{admin_approved == 1 &&
											<div className="col-sm-6 mb-3">
												<FormControl>
													<Autocomplete
														id="asynchronous-demo"
														getOptionSelected={(magnet, value) => magnet.name === value.name}
														getOptionLabel={magnet => magnet.name + " (" + magnet.phone + ")"}
														options={magnet}
														loading={isLoading}
														onChange={(_event, magnet) => {
															if (magnet === null) {
																this.setState({ magnet: [] })
															} else {
																this.setState({
																	assign_rm_id: magnet.id,
																	rm_email: magnet.email,
																})
															}
														}}
														renderInput={params => (
															<TextField
																{...params}
																label="Assign Service RM *"
																fullWidth
																onChange={this.handleAutoChange}
																InputProps={{
																	...params.InputProps,
																	startAdornment: (
																		<InputAdornment position="start">
																			<BusinessRoundedIcon />
																		</InputAdornment>
																	),
																	endAdornment: (
																		<React.Fragment>
																			{isLoading ? (
																				<CircularProgress color="inherit" size={20} />
																			) : null}
																			{params.InputProps.endAdornment}
																		</React.Fragment>
																	)
																}}
															/>
														)}
													/>
												</FormControl>
											</div>
										}
									</Frag>
									:
									''
								}
								{this.state.activate == 0 ?
									<div className="col-sm-6 col-12">
										<FormControl>
											<InputLabel id="demo-controlled-open-select-label">Closing Reason</InputLabel>
											<Select
												labelId="demo-controlled-open-select-label"
												value={closing_reason}
												onChange={this.onChange}
												inputProps={{
													name: 'closing_reason',
													id: 'closing_reason',
												}}
											>
												<MenuItem value="">
													<em>None</em>
												</MenuItem>
												{CLOSING_REASON.map((item, i)=>(
													<MenuItem value={item} key={i}>{item}</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
									:
									''
								}
								</div>
								<div className="row">
									<div className="col-md-6 mb-3">
										<InputField
											required
											label="First Name"
											name="first_name"
											value={first_name}
											variant="standard"
											onChange={this.onChange}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<InputField
											required
											label="Last Name"
											name="last_name"
											value={last_name}
											variant="standard"
											onChange={this.onChange}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<InputField
											required
											label="Contact Number"
											name="phone"
											value={phone}
											variant="standard"
											onChange={this.handleNumberChange}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<InputField
											required
											label="Email"
											name="email"
											value={email}
											variant="standard"
											onChange={this.onChange}
										/>
									</div>
									{(rm_approved == 1 && magnetApproveRoles.includes(role)) &&
										<Frag>
											<div className="col-md-6 mb-3">
												<TextField 
													required
													value={total_experience}
													name="total_experience"
													type="number"
													onChange={this.onChange} 
													label = "Total Years of Experience"
													inputProps={{
														min:"0"
													}} 
												/>
											</div>
											<div className="col-md-6 mb-3">
												<TextField
													required
													name="operate_location"
													value={operate_location}
													label="Office Location"
													onChange={this.onChange}
												/> 
											</div> 
											<div className="col-md-6 mb-3">
												<SelectOption
													variant="standard"
													required
													name="work_in_region"
													options={region_option}
													value={work_in_region}
													label="Parent Region"
													onChange={this.onChange}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<FormControl>
												<InputLabel id="primary-business">Type of Real Estate</InputLabel>
												<Select
													multiple
													name="deal_business_types"
													value={deal_business_types}
													onChange={this.onChange}
													input={<Input id="primary-multiple-chip" />}
													renderValue={selected => (
													<div>
														{selected.map(value => (
														<Chip key={value} label={value}/>
														))}
													</div>
													)}
													MenuProps={MenuProps}
												>
													{primary.map(item => (
													<MenuItem key={item.id} value={item.name}>
														<Checkbox checked={this.state.deal_business_types.indexOf(item.name) > -1} />
														<ListItemText primary={item.name} />
													</MenuItem>
													))}
												</Select>
												</FormControl>
											</div>
											<div className="col-md-6 mb-3">
												<FormControl>
												<InputLabel id="secondary-business" required>Digital - platforms currently being used?</InputLabel>
												<Select
													multiple
													name="plateform_present_in"
													value={plateform_present_in}
													onChange={this.onChange}
													input={<Input id="operate-multiple-chip" />}
													renderValue={selected => (
													<div>
														{selected.map(value => (
														<Chip key={value} label={value}/>
														))}
													</div>
													)}
													MenuProps={MenuProps}
												>
													{operate.map(item => (
													<MenuItem key={item.id} value={item.name}>
														<Checkbox checked={plateform_present_in.indexOf(item.name) > -1} />
														<ListItemText primary={item.name} />
													</MenuItem>
													))}
												</Select>
												</FormControl>
											</div>
											<div className="col-md-6">
												<FormControl>
												<InputLabel id="generate-business">How do you Generate Leads ?</InputLabel>
												<Select
													multiple
													name="genrate_leads"
													value={genrate_leads}
													onChange={this.onChange}
													input={<Input id="generate-multiple-chip" />}
													renderValue={selected => (
													<div>
														{selected.map(value => (
														<Chip key={value} label={value}/>
														))}
													</div>
													)}
													MenuProps={MenuProps}
												>
													{leads.map(item => (
													<MenuItem key={item.id} value={item.name}>
														<Checkbox checked={genrate_leads.indexOf(item.name) > -1} />
														<ListItemText primary={item.name} />
													</MenuItem>
													))}
												</Select>
												</FormControl>    
											</div>
											<div className="col-md-6 mb-3">
												<FormControl>
												<InputLabel id="Alternate-business">Alternate business with us</InputLabel>
												<Select
													multiple
													name="alternate_business_us"
													value={alternate_business_us}
													onChange={this.onChange}
													input={<Input id="Alternate-multiple-chip" />}
													renderValue={selected => (
													<div>
														{selected.map(value => (
														<Chip key={value} label={value}/>
														))}
													</div>
													)}
													MenuProps={MenuProps}
												>
													{alternateBusiness.map(item => (
													<MenuItem key={item.id} value={item.name}>
														<Checkbox checked={alternate_business_us.indexOf(item.name) > -1} />
														<ListItemText primary={item.name} />
													</MenuItem>
													))}
												</Select>
												</FormControl>  
											</div>
											<div className="col-md-6 mb-3">
												<RadioGroup
													name="devote_time"
													value={devote_time}
													label="How do you devote time to business?"
													onChange={this.onChange}
													items={businessTime}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<RadioGroup
													required
													name="have_team"
													value={have_team}
													label="Do you have a Team?"
													onChange={this.onChange}
													items={yesNoType}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<RadioGroup
													name="own_state_business"
													value={own_state_business}
													label="Do you own a real-estate business?"
													onChange={this.onChange}
													items={yesNoType}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<RadioGroup
													required
													name="operate_business"
													value={operate_business}
													label="How do you operate your business?"
													onChange={this.onChange}
													items={businessOperate}
												/>
											</div>
										</Frag>
									}
									{this.state.activate == 0 || magnetApproveRoles.includes(role) ?
										<div className="col-sm-6 col-12">
											<div className="form-group">
												<TextField
													error={this.state.errors.comment !== '' ? true : false}
													id="standard-multiline-flexible"
													label="Comments"
													name="comment"
													helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
													multiline
													rowsMax="4"
													value={this.state.comment}
													onChange={this.onChange}
												/>
											</div>
										</div>
										:
										''
									}
								</div>
                       
							<div className="row">
								{this.props.userId === undefined ?
									<div className="col-md-12 col-sm-12 text-center col-12">
										<div className="form-group">
											<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>{this.state.activate == 0 ? 'Disqualify Lead' : 'Activate Lead'}</button>
										</div>
									</div>
									:
									<div className="col-md-12 col-sm-12 text-center col-12">
										<div className="form-group">
											<button type="submit" className="btn btn-success" onClick={this.updateMagnetLeadFrom}>Submit</button>
										</div>
									</div>
								}
								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Above all fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(UpdateBulkForm);
