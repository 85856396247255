import React, { useState, useEffect } from 'react'
import "./LeaderBoard.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { client } from '../../helper';
import "../magnetRegister/magnetRegister.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const LeaderBoard = () => {
    const [state, setState] = useState({ articles: null })

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        await client.getEntries({
            'content_type': 'vCard',
            'fields.isRnr[in]': true
            // 'fields.is_rnr[in]': 'true'
        })
            .then((response) => {
                if (response.items.length) {
                    setState({
                        ...state,
                        articles: response.items
                    },)
                } else {
                    //some code here
                }
            }).catch(console.error)
    }
    const renderSlides = () =>
        state.articles?.map((item, idx) => (
            <div className="Winner" key={idx}>
                <div className="AwardeeImg">
                    <img src={item?.fields?.profileImg?.fields?.file?.url} alt="" className="AwardImg" height="200" width="200" />
                    <span className="AwardIcon">
                        <FontAwesomeIcon icon={faStar} size="lg" color="orange" />
                    </span>
                </div>
                <p className="Name">{item?.fields?.name}</p>
                <div className="AwardTitle mb-2">
                    {item?.fields?.awardTitle}
                </div>
            </div>
        ))

    var settings = {
        pauseOnHover: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="LeaderBoardSection popup_hover">
                        <div className="LeaderTop">
                            <div className="LeaderHead text-center">
                                <div className="LeaderTitle text-uppercase">Stars of homesfy</div>
                            </div>
                            <Slider {...settings}>{renderSlides()}</Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { LeaderBoard }
