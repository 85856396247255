import React, { Component } from 'react'
import { autoComplete } from "../../../dataParser/commomDataApi.js";
import { AddCircle } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import { createAutoAssign, deleteAutoAssign } from '../../../dataParser/getAutoAssignData.js';
import Loader from "../../common/loader/loader";
import { getAllRM } from '../../autoAssignLead/AutoAssignRM.js';
import SweetAlert from "../sweetAlert/sweetAlertSuccess";
import PopUpAlert from "../sweetAlert/popUpAlert";
import { Box } from '@material-ui/core';
import { REVERT_ROLES } from '../../../config/constant/index.js';
import { cleanStorage } from '../../../helper';

export default class AddRmForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            rmName: [],
            assign_rm_id: null,
            selectedVal: [],
            totalPercent: 0,
            isLoading: false,
            auto_ids: [],
            showLoader: false,
            auto_assign_data: this.props.auto_assign_data,
            index: this.props.index,
            sweetShow: false,
            alertType: "success",
            msg: "",
            type: "success",
            open: false,
            title: "",
            rm_data: []
        }
    }

    componentDidMount() {
        let data = this.state.auto_assign_data
        let RMdata = getAllRM(this.props.index, data, this.props.isMagnet)
        let list = [...this.state.auto_ids]
        let listRM = [];
        for (var i = 0; i < RMdata.length; i++) {
            list[i] = {
                assign_to: RMdata[i]?.id,
                percentage: RMdata[i]?.percentage,
                is_magnet: RMdata[i]?.is_magnet
            }
            listRM[i] = {
                id: RMdata[i]?.id,
                name :  RMdata[i]?.name
            }
        }
        list.length === 0 && list.push({ assign_to: "", percentage: "" })
        this.setState({
            rm_data: RMdata,
            auto_ids: list,
            rmName: listRM[0]?.name !== undefined ? listRM : [],
            selectedVal: listRM[0]?.name !== undefined ? listRM : [],
        })
        this.totalSum(list);
    }
    handleAutoUserChange = async (e) => {
        this.setState({ isLoading: true });
		let data = {
			q: e.target.value,
			users_active: 1
		};
        data.roles = [REVERT_ROLES.MAGNET_PLATINUM ,REVERT_ROLES.SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.SALES_MANAGER ,REVERT_ROLES.SOURCING_S2 ,REVERT_ROLES.SOURCING_S3 ,REVERT_ROLES.MAGNET_TL, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD, REVERT_ROLES.MAGNET_PNL, REVERT_ROLES.SENIOR_SALES_TL]
        let resData = await autoComplete('users', data);

        if (resData.meta.status === 200) {

            this.setState({ isLoading: false });
            this.setState({ rmName: resData.data })
        } else if (resData.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");

        } else {

            this.setState({ isLoading: false });
            this.setState({ rmName: resData.data })
        }
    }
    handleInputChange = (e, i) => {
        const { name, value } = e.target
        let list = [...this.state.auto_ids]
        list[i][name] = value
        list[i]["is_magnet"] = this.props.isMagnet;
        this.setState({
            auto_ids: list,
        })
        this.totalSum(list);
    }
    handleAddClick = (i) => {
        const { auto_ids } = this.state
        // auto_ids[i].assign_to = this.state.assign_rm_id
        let list = [...auto_ids]
        let len = list.length
        let prev = len - 1
        if(!!list[prev].assign_to && !!list[prev].percentage){
        list.push({ assign_to: "", percentage: "" })
        this.setState({ auto_ids: list })
        }
        else{
            this.setState({
                open: true,
                alertType: "error",
                msg: "Please fill both the details",
            });
        }
    }

    handleRemoveClick = (i) => {
        let list = this.state.auto_ids.filter((item, index) => index !== i)
        let listRM = this.state.selectedVal.filter((item, index) => index !== i)
        this.setState({
            auto_ids: list,
            selectedVal: listRM[0]?.name !== undefined ? listRM : [],
        })
        this.totalSum(list)
    }

    totalSum = (list) => {
        let total;
        total = list.reduce((item, curr) => {
            return item + Number(curr.percentage)
        }, 0)
        this.setState({ totalPercent: total })
    }
    handleSubmit = async () => {
        let projectInfo = this.props.projectInfo
        if (projectInfo.source_id){
            if (this.state.totalPercent === 100) {
                this.setState({ showLoader: true })
                let { auto_ids } = this.state
                auto_ids = auto_ids.filter(item=>item["assign_to"] !== "")
                auto_ids.map(item=>item.percentage = parseInt(item.percentage))
                let data = { ...projectInfo, auto_ids }
                
                let resData = await createAutoAssign(data)
    
                if (resData.meta.status === 201) {
                    this.setState({
                        showLoader: false,
                        sweetShow: true,
                        alertType: "success",
                        msg: "Added Successfully !",
                    });
    
                } else {
                    let alertType = resData.meta.message === "List already exist" ? "warning" : "error"
                    this.setState({
                        showLoader: false,
                        sweetShow: true,
                        alertType: alertType,
                        msg: resData.meta.message,
                    });
                }
            }
            else{
                this.setState({
                    open: true,
                    alertType: "warning",
                    msg: "Total should be 100% !!!"
                });
            }

            // this.props.fullDetail(projectInfo.project_id)
        }
        else{
            this.setState({
                sweetShow: true,
                alertType: "warning",
                msg: "Select source first !!!"
            });        
        }
    }

    handleDelete = async (row) => {
        this.setState({ showLoader: true })
        let projectInfo = this.props.projectInfo
        let data={
            nationality: projectInfo.nationality,
            project_id: projectInfo.project_id,
            source_id: projectInfo.source_id,
            type: projectInfo.type,
            is_magnet: this.props.isMagnet
        }
        
        let resData = await deleteAutoAssign(data)
        if (resData.meta.status === 200) {
            this.setState({
                showLoader: false,
                open: true,
                alertType: "success",
                msg: "RM Removed Successfully !",
            });
        } else {
            this.setState({
                showLoader: false,
                open: true,
                alertType: "error",
                msg: resData.meta.message,
            });
        }
    	this.props.changeModal();
        this.props.fullDetail(projectInfo.project_id)
    }


	handleSweet=()=>{
        this.setState({sweetShow : !this.state.sweetShow});
    	this.props.changeModal();
        this.props.fullDetail(this.props.projectInfo.project_id)
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { rmName, totalPercent, isLoading, auto_ids, selectedVal, alertType, msg, sweetShow, open, title, type, rm_data } = this.state
        return (
            <div>
                <PopUpAlert type={alertType} msg={msg} open={open} handleClose={this.handleClose} hideTime={6000} />
                <SweetAlert show={sweetShow} type={alertType} title={msg} changeSweet={this.handleSweet} />
                <Loader show={this.state.showLoader} />
                <div className="container px-0">
                    <div className="row mb-2">
                        <div className="col-md-6 d-none d-md-block">
                            RM Name
                        </div>
                        <div className="col-md-6">
                            <span
                                style={
                                    totalPercent !== 100
                                        ? { color: "#fc0606", fontWeight: "bold" }
                                        : { color: "#64c518", fontWeight: "bold" }
                                }
                            >
                                Total - {totalPercent} %
                            </span>
                        </div>
                    </div>
                    {auto_ids.map((item, i) => (
                        <div className="row align-items-center mb-2 mb-md-1" key={i}>
                            <div className="col-md-6 mb-2">
                                <Autocomplete
                                    getOptionSelected={(rmName, value) => rmName.name === value.name}
                                    getOptionLabel={rmName =>rmName.name || ''}
                                    options={rmName}
                                    value={selectedVal[i]}
                                    loading={isLoading}
                                    onChange={(_event, rmName) => {
                                        if (rmName === null) {
                                            this.setState({ rmName: [] })
                                        } else {
                                            let listRM = selectedVal;
                                                listRM[i]= { id: rmName.id, name: rmName.name }
                                            this.setState({
                                                assign_rm_id: rmName.id,
                                                rm_name: rmName.name,
                                                selectedVal: listRM
                                            }, () => {
                                                this.state.auto_ids[i].assign_to = this.state.assign_rm_id
                                            })
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            {...params}
                                            fullWidth
                                            onChange={this.handleAutoUserChange}
                                            placeholder="RM Name"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isLoading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-md-3 mb-2">
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    name="percentage"
                                    placeholder="Percent"
                                    value={item.percentage}
                                    onChange={(e) => this.handleInputChange(e, i)}
                                />
                            </div>
                            {this.props.projectInfo?.type === "assign" && 
                            <div className="col-md-3 mb-2">
                                <Box  width={1}>
                                    <Box width="50%" className="ButtonPart" >
                                        {auto_ids.length !== 1 &&
                                            <button
                                        className="btn btn-link bg-red"
                                        type="button"
                                        onClick={() => this.handleRemoveClick(i)}
                                        >
                                        <DeleteIcon style={{color:"#dc3545"}}/>
                                        </button>
                                        }
                                    </Box>
                                    <Box width="50%" className="ButtonPart">
                                        {auto_ids.length - 1 === i && 
                                            <button
                                                className="btn btn-link bg-blue"
                                                type="button"
                                                onClick={() => this.handleAddClick(i)}
                                            >
                                                <AddCircle />
                                            </button>
                                        }
                                    </Box>
                                </Box>
                            </div>
                            }
                            </div>
                    ))}
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-6 pr-1 pr-md-3">
                            <input type="button" className="btn btn-danger w-100" value="Delete all" disabled={rm_data.length !== 0 ? false: true} onClick={this.handleDelete} />
                        </div>
                        <div className="col-md-4 col-6 pl-1 pl-md-3">
                        <input type="button" className="btn btn-primary w-100 pl-0" value="Submit" onClick={this.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
