import React, { useState, useEffect } from 'react'
import SelectOption from '../controls/SelectOption'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { getLeaderboardData } from '../../../dataParser/getDashboardData';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import moment from "moment"
import { LeaderBoardCard } from '../cards/LeaderBoardCard';
import { generateMonths, generateYears } from '../../../helper';

const MIN_THRESHOLD_TYPE = [
    { id: "lead_to_booking", name: "Lead-Booking Ratio (L->B)" }
    , { id: "lead_to_activity", name: "Lead-Activity Ratio (L->A)" }
    , { id: "lead_to_opportunity", name: "Lead-Opportunity Ratio (L->O)" }
    , { id: "lead_to_update_time", name: "Lead-Update-Time (L->U)" }
]
const THRESHOLD_FILTER_TYPE = MIN_THRESHOLD_TYPE.map(item => item.id)

/* 
1) Hide Activity -> Booking Ratio (that's why commented ) 
2) Display the min threshold value on frontend for leads
*/

const RATIO_TYPE = [
    ...MIN_THRESHOLD_TYPE
    , { id: "lead_to_followup_delay_time", name: "Follow-Up-Delay-Time (F->D)" }
    , { id: "activity_to_booking", name: "Activity-Booking Ratio (A->B)" }
    , { id: "followup_overdue_count", name: "Follow-Up-Overdue" }
    , { id: "business", name: "Business" }
    , { id: "activities", name: "Activity" }
]
/* unused code removed */

let d = new Date();

// set to Monday of this week
d.setDate(d.getDate() - (d.getDay() + 6) % 7);

// set to previous Monday
let p_monday = d.setDate(d.getDate() - 7);

let sunday = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);

const initialState = {
    filter_type: "business"
    , created_at_from: p_monday
    , created_at_to: sunday
    , RH_status: null
    , A1_status: null
    , S1_status: null
    , S2_status: null
    , S3_status: null
    , months:generateMonths()
    , month: ''
    , years: generateYears()
    , year: ''

}

const LeaderboardChart = (props) => {
    const {tab} = props;
    const [state, setState] = useState(initialState)
    const [min_lead, setMinLead] = useState(25)
    const [notFound, setNotFound] = useState(false)

    const [RH_state, setRHState] = useState({
        RH_status: null
        , RH_DATA: []
    })
    const [A1_state, setA1State] = useState({
        A1_status: null
        , A1_DATA: []
    })
    const [S1_state, setS1State] = useState({
        S1_status: null
        , S1_DATA: []
    })
    const [S2_state, setS2State] = useState({
        S2_status: null
        , S2_DATA: []
    })
    const [S3_state, setS3State] = useState({
        S3_status: null
        , S3_DATA: []
    })

    const handleDateChange = (key, date) => {
        setState({ ...state, [key]: date });
    }

    const { filter_type, created_at_from, created_at_to , year , month } = state;

    useEffect(() => {

        let data = {};
        data["filter_type"] = filter_type;
        let fromDate = moment(created_at_from).format("YYYY-MM-DD");
        let toDate = moment(created_at_to).format("YYYY-MM-DD");

        if (fromDate) {
            data["created_at_from"] = fromDate.split("/").join("-");
        } if (toDate) {
            data["created_at_to"] = toDate.split("/").join("-");
        }
        getApiCall(data)
    }, [])

    const getApiCall = async (data) => {
        setNotFound(false);
        if(tab === "team") {
            if(["closings1", "sourcings3", "magnet s3"].includes(window.$user.role)){
                data["loggedUserId"] = window?.$user?.id;
            }
            else{
                data["user_id"] = window?.$user?.id;
            }
        }
        data["role_id"] = 7
        let getRH = await getLeaderboardData(data)
        let RH_STAUS = getRH.meta.status;
        if (RH_STAUS === 200) {
            setRHState({
                RH_status: RH_STAUS
                , RH_DATA: getRH.data
            })
        }
        data["role_id"] = 8
        let getA1 = await getLeaderboardData(data)
        let A1_STAUS = getA1.meta.status;
        if (A1_STAUS === 200) {
            setA1State({
                A1_status: A1_STAUS
                , A1_DATA: getA1.data
            })
        }
        data["role_id"] = 10
        let getS1 = await getLeaderboardData(data)
        let S1_STAUS = getS1.meta.status;
        if (S1_STAUS === 200) {
            setS1State({
                S1_status: S1_STAUS
                , S1_DATA: getS1.data
            })
        }
        data["role_id"] = 14
        let getS2 = await getLeaderboardData(data)
        let S2_STAUS = getS2.meta.status;
        if (S2_STAUS === 200) {
            setS2State({
                S2_status: S2_STAUS
                , S2_DATA: getS2.data
            })
        }
        data["role_id"] = 15
        let getS3 = await getLeaderboardData(data)
        let S3_STAUS = getS3.meta.status;
        if (S3_STAUS === 200) {
            setS3State({
                S3_status: S3_STAUS
                , S3_DATA: getS3.data
            })
        }
        if(getRH.data.length === 0 && getA1.data.length === 0 && getS1.data.length === 0 && getS2.data.length === 0 && getS3.data.length === 0) {
            setNotFound(true)         
        }
        const fullData = [...getRH.data, ...getA1.data, ...getS1.data, ...getS2.data, ...getS3.data] 
        const threshold_data = fullData.filter(item=>item.lead_threshold)        
        let min_threshold_lead = threshold_data[0]?.lead_threshold || min_lead;
        setMinLead(min_threshold_lead)
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state
            , [name]: value
        })
    }

    const handleFilter = () => {
        setRHState({
            RH_status: null
            , RH_DATA: []
        })
        setA1State({
            A1_status: null
            , A1_DATA: []
        })
        setS1State({
            S1_status: null
            , S1_DATA: []
        })
        setS2State({
            S2_status: null
            , S2_DATA: []
        })
        setS3State({
            S3_status: null
            , S3_DATA: []
        })
        let data = {};
        data["filter_type"] = filter_type
        let fromDate = created_at_from ? moment(created_at_from).format("YYYY-MM-DD") : '';
        let toDate = created_at_to ? moment(created_at_to).format("YYYY-MM-DD") : '';

        if (fromDate) {
            data["created_at_from"] = fromDate.split("/").join("-");
        } if (toDate) {
            data["created_at_to"] = toDate.split("/").join("-");
        }
        if(year && month){
            data['year'] = year;
            data['month'] = month;
        }
        getApiCall(data)
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col-md-3 col-6  ">
                        Business Leaders
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-3 my-2 mb-3">
                        <SelectOption
                            label="Select Ratios"
                            name="filter_type"
                            value={filter_type}
                            options={RATIO_TYPE}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-2 col-6 pr-1">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                label="From"
                                format="yyyy/MM/dd"
                                maxDate={new Date()}
                                name="created_at_from"
                                value={created_at_from}
                                onChange={(key, date) => handleDateChange('created_at_from', date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-2 col-6 pl-1">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                label="To"
                                format="yyyy/MM/dd"
                                maxDate={new Date()}
                                name="created_at_to"
                                value={created_at_to}
                                onChange={(key, date) => handleDateChange('created_at_to', date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-2 col-6 pl-1">
                        <FormControl>
                            <InputLabel id="Presale-Lead-type">
                            Year
                            </InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            value={state.year}
                            onChange={(e) => handleChange(e)}
                            inputProps={{
                                name: "year",
                                id: "year"
                            }}
                            required
                            >
                            {state.years.map(year => (
                                <MenuItem key={year} value={year}>
                                {year}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2 col-6 pl-1">
                        <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">
                            Month
                            </InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            value={state.month}
                            onChange={(e) => handleChange(e)}
                            inputProps={{
                                name: "month",
                                id: "month"
                            }}
                            required
                            >
                            {state.months.map(month => (
                                <MenuItem key={month.key} value={month.key}>
                                {month.value}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-1 col-6 pt-3 pl-1">
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={handleFilter}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
                {THRESHOLD_FILTER_TYPE.includes(state.filter_type) &&
                    <div className="row mb-3">
                        <div className="col-12 text-md-center">
                            <span className='font-weight-bold text-muted'>Users with minimum {min_lead} Leads are considered in this Leaderboard.</span>
                        </div>
                    </div>
                }
                <div className='row'>
                    <LeaderBoardCard role={"RH"} role_id={7} status={RH_state.RH_status} data={RH_state.RH_DATA} type={state.filter_type} tab={tab}/>
                    <LeaderBoardCard role={"A1"} role_id={8} status={A1_state.A1_status} data={A1_state.A1_DATA} type={state.filter_type} tab={tab}/>
                    <LeaderBoardCard role={"S1"} role_id={10} status={S1_state.S1_status} data={S1_state.S1_DATA} type={state.filter_type} tab={tab}/>
                    <LeaderBoardCard role={"S2"} role_id={14} status={S2_state.S2_status} data={S2_state.S2_DATA} type={state.filter_type} tab={tab}/>
                    <LeaderBoardCard role={"S3"} role_id={15} status={S3_state.S3_status} data={S3_state.S3_DATA} type={state.filter_type} tab={tab}/>
                    {notFound &&
                        <div className="col-12 errorMsg">
                            Ooops Data Not Found !!!
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LeaderboardChart
