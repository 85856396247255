import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import FilePreviewer from 'react-file-previewer';

const ViewAllPreviewDoc = (props) => {
  let { show, url, handleCloseModal } = props;
  return (
    <>
      <Modal show={show} onHide={handleCloseModal} size="xl">
        <style>
          {`@media print {*{display: none;}}`}
        </style>
        <Modal.Header closeButton>
          <Modal.Title>Preview Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            url.map((x, i) => {
              if (x.s3UploadResponse !== "https://s3.amazonaws.com/" && (x.s3UploadResponse.includes('.pdf')
                || x.fileType === '.pdf')) {
                return (<>
                  <h3 style={{ textAlign: "center" }}>Document No. {i + 1}</h3>
                  < FilePreviewer
                    file={{
                      data: x.s3UploadResponse,
                      mimeType: 'application/pdf',
                      name: 'sample.pdf'
                    }}
                    hideControls
                  />
                </>)
              } else {
                if (x.s3UploadResponse && x.s3UploadResponse !== "https://s3.amazonaws.com/") {
                  const link = x.s3UploadResponse
                  return (
                    <>
                      <h3 style={{ textAlign: "center" }}>Document No. {i + 1}</h3>
                      <img src={link} width="100%" height="100%"></img>
                    </>)
                }
              }
            })
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewAllPreviewDoc;