import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import { getRegion } from "../../../dataParser/getProjectData";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { updateProject } from "../../../dataParser/getProjectData";
// import {Form} from 'react-bootstrap';
import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import InputAutoComplete from "../../common/material/inputAutoComplete";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import {getAutoCompleteProject} from "../../../dataParser/getProjectData";
// import CircularProgress from "@material-ui/core/CircularProgress";
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Loader from "../../../component/common/loader/loader";
import GoogleMaps from "../googleMap/googleMap.js";
// import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import { nameCheck, letterCase, empty, cleanStorage } from "../../../helper";

class updateForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			project_addedby: '',
			project_id: '',
			project_name: '',
			builder_name: '',
			region: '',
			region_id: '',
			region_name: '',
			location: this.props.formData.data.location || '',
			project_id_99: '',
			project_status: false,
			is_focus: false,
			sweetShow: false,
			showLoader: false,
			type: "success",
			title: "",
			error: "",
			option: [],
			isLoading: false,
			isError: {
				project_name: '',
				builder_name: '',
				location: '',
				project_id_99: '',
				region_id: ''
			}
		};
	}

	async componentDidMount() {
		// console.log("inthe user update");
		this.setState({ showLoader: true });

		var homesfy_lg = (localStorage.getItem("homesfy_lg"));
		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg));
			var addedBy = user.id;
			this.setState({ project_addedby: addedBy })

		} else {
			var addedBy = '';
			this.setState({ project_addedby: addedBy })
		}

		var regionData = await getRegion();
		// console.log("region=====>>>",regionData);
		this.setState({ region: regionData })


		// console.log("in the 11111111111111111111111111111111111",this.props.formData);
		await this.setState({
			project_name: this.props.formData.data.name || '',
			project_id: this.props.formData.data.id || '',
			builder_name: this.props.formData.data.builder_name || '',
			location: this.props.formData.data.location || '',
			project_status: this.props.formData.data.is_active || '',
			is_focus: this.props.formData.data.is_focus || '',
			project_id_99: this.props.formData.data.project_id_99 || '',
			region_id: this.props.formData.data.Region.id || '',
		})

		this.setState({ showLoader: false });
	}

	formValid = ({ isError, ...rest }) => {
		let isValid = false;
		if (empty(rest.location)) {
			this.setState(prevState => ({
				isError: {
					...prevState.isError,
					location: '*please select valid location'
				}
			}))
		}

		if (empty(rest.builder_name) || empty(rest.project_name) || empty(rest.region_id)) {
			return false;
		}

		Object.values(isError).forEach(val => {
			if (val.length > 0) {
				return false;
			} else {
				isValid = true
			}
		});
		// let f = letterCase(rest.first_name,true)
		//           console.log(rest)
		//           this.setState((prevState) => ({
		//   // count: prevState.count + props.increment
		// }));
		//       this.setState({
		//         first_name: f,
		// last_name: letterCase(prevState.last_name,true),
		// email: letterCase(prevState.email_name,true)
		// });

		// rest.forEach(val => {
		//     if (val === null) {
		//         return false;
		//         // isValid = false
		//     } else {
		//         isValid = true
		//     }
		// });

		return isValid;
	};

	formValChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		let isError = { ...this.state.isError };

		switch (name) {
			case "builder_name":
				isError.builder_name =
					value.length < 4 && nameCheck(value) ? "Atleast 4 characaters required" : "";
				break;
			case "project_name":
				isError.project_name =
					value.length < 4 ? "Atleast 4 characaters required" : "";
				break;
			default:
				break;
		}

		this.setState({
			isError,
		})
		if (typeof value !== 'number') {
			this.setState({
				[name]: letterCase(value, true)
			})
		} else {
			this.setState({
				[name]: (value)
			})
		}
	};

	updateProjectForm = async (e) => {
		e.preventDefault();
		// console.log(this.state)
		if (this.formValid(this.state)) {
			let mainData = {};
			let data = {};
			if (this.state.builder_name != '') data['builder_name'] = this.state.builder_name;
			if (this.state.project_name != '') data['name'] = this.state.project_name;
			if (this.state.project_addedby != '') data['created_by'] = this.state.project_addedby;
			if (this.state.location != '') data['location'] = this.state.location;
			if (this.state.region_id != '') data['region_id'] = this.state.region_id;
			data['is_active'] = (this.state.project_status != '') ? this.state.project_status : false;
			data['is_focus'] = (this.state.is_focus != '') ? this.state.is_focus : false;

			mainData['data'] = data;
			mainData['id'] = this.state.project_id;
			this.setState({
				showLoader: true,
			})
			//    console.log("addLeadRes XXXXXXXXXXXX",mainData);
			var addLeadRes = await updateProject(mainData);

			if (addLeadRes.meta.status === 503) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "!!! Oooops Network Error..."
				});

			} else if (addLeadRes.meta.status === 201) {

				// console.log("User Register successfully !!!");
				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Updated successfully"
				});
			} else if (addLeadRes.meta.status === 409 || addLeadRes.meta.status === 400) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "warning",
					title: addLeadRes.meta.message
				});

			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});
				// console.log(addLeadRes.meta.message); 
			}
		} else {
			this.setState({
				showLoader: false,
				error: "*Some error in the above fields or * fileds are reqiured !!!",
			})
		}
	};

	handleChange = (event) => {
		this.setState({ project_status: !this.state.project_status });
	};

	handleFocusChange = (event) => {
		this.setState({ is_focus: !this.state.is_focus });
	};

	handleGeoLocation = async (location) => {

		// console.log("<=============in the location============>",location);
		this.setState({ location: location });
	}

	handleSweet = () => {
		// console.log("in the sweet hanlde");
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList()
		// window.location.reload();
	}

	render() {

		const { error, region, sweetShow, type, title, option, isLoading, region_id, region_name } = this.state;
		// console.log("in the render------?>>>>>>>>>", this.state);

		if (this.props.formData.meta.status === 200) {
			if (this.props.formData.data.location !== null) {
				var currentlocation = this.props.formData.data.location
			}
		}

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<Loader show={this.state.showLoader} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6 project_name">
									<div className="form-group">
										<div className="input-group mb-3">
											<TextField
												error={this.state.isError.project_name !== '' ? 'error' : ''}
												required
												id="project_name"
												name="project_name"
												label="Project Name"
												value={this.state.project_name ? this.state.project_name : ''}
												helperText={this.state.isError.project_name !== '' ? this.state.isError.project_name : ''}
												onChange={this.formValChange}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<BusinessRoundedIcon />
														</InputAdornment>
													),
												}}
											/>
										</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<div className="input-group mb-3">
											<TextField
												error={this.state.isError.builder_name !== '' ? 'error' : ''}
												required
												id="builder_name"
												name="builder_name"
												value={this.state.builder_name}
												label="Builder Name"
												helperText={this.state.isError.builder_name !== '' ? this.state.isError.builder_name : ''}
												onChange={this.formValChange}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<ApartmentRoundedIcon />
														</InputAdornment>
													),
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Select Region</InputLabel>
										<Select
											error={this.state.isError.region_id !== '' ? 'error' : ''}
											labelId="demo-controlled-open-select-label"
											value={region_id}
											onChange={this.formValChange}
											inputProps={{
												name: 'region_id',
												id: 'region_id',
											}}
											helperText={this.state.isError.region_id !== '' ? this.state.isError.region_id : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{region ?
												(region.map(reg =>
													<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
												))
												:
												''
											}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<GoogleMaps handleLocation={this.handleGeoLocation} autoLocation={this.state.location} isError={this.state.isError.location} />
									</div>
								</div>
							</div>
							<div className="row">
								{/*<div className="col-sm-6">
									<div className="form-group">
										<div className="input-group mb-3">
											<TextField
												error = {this.state.isError.project_id_99 !== '' ? 'error' : ''}
										        id="project99"
										        name="project_id_99"
										        value={this.state.project_id_99 ? this.state.project_id_99 : '' }
										        label="99 Project ID"
									        	helpertext={this.state.isError.project_id_99 !== '' ? this.state.isError.project_id_99 : ''}
										        onChange={this.formValChange}
										        InputProps={{
										          startAdornment: (
										            <InputAdornment position="start">
										              <ApartmentRoundedIcon />
										            </InputAdornment>
										          ),
										        }}
									      	/>
										</div>
									</div>
								</div>*/}
								<div className="col-sm-6 p-0">
									<div className="form-group">
										<FormControlLabel
											value={`${this.state.project_status}`}
											checked={this.state.project_status}
											name="project_status"
											control={<Switch color="primary" />}
											label="Project Status"
											labelPlacement="top"
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-sm-6 p-0">
									<div className="form-group">
										<FormControlLabel
											value={`${this.state.is_focus}`}
											checked={this.state.is_focus}
											name="is_focus"
											control={<Switch color="primary" />}
											label="Focus Project"
											labelPlacement="top"
											onChange={this.handleFocusChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateProjectForm}>Submit</button>
										<p className="mb-0">
											<span className="text-center text-danger small">{error != '' ? error : ''}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(updateForm);
