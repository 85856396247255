import React, { useState, useEffect } from 'react'
import Frag from '../../utils/Frag/Frag'
import Loader from '../common/loader/loader'
import PopUpAlert from '../common/sweetAlert/popUpAlert'
import ListingView, { HalfField, OneFourthField } from '../common/listing/ListingView'
import { deleteProject, getMagnetRequest, getProjectDetail } from '../../dataParser/getProjectData'
import { useHistory } from "react-router-dom";
import ProjectFilter from './ProjectFilter'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import moment from "moment";
import SweetWarnAlert from '../common/sweetAlert/sweetAlertWarning'
import SweetAlert from '../common/sweetAlert/sweetAlertSuccess'
import { cleanStorage } from '../../helper'

const MagnetRequest = (props) => {
    let history = useHistory();

    const [state, setState] = useState({
        showLoader: false,
        type: "success",
        msg: "",
        open: false,
        data: [],
        fullDetail: [],
        showFilter: false,
        activePage: 1,
        filters:  [],
        sweetWarnShow: false,
        sweetShow: false,
        sweetType: "suceess",
        title: ""
    })

    useEffect(() => {
        getList();
    }, [])

    const getList = () => {
        let listData = {};
        listData.pageId = 1;
        getListData(listData)
    }

    const handleClose = () => {
        setState({
            ...state,
            open: false
        })
    }


    const getListData = async (params) => {
        setState({ ...state, showLoader: true });

        (async () => {
            try {
                let listData = {};
                listData.size = 8;
                listData.is_active = 0;
                listData.is_magnet = 1;
                listData.magnet_project_status = 0;
                listData.requested_project = 1;
                Object.assign(params, listData)
                let resData = await getMagnetRequest(params);
                if (resData.meta.count >= 0 && resData.meta.status == 200) {

                    setState({
                        ...state,
                        showFilter: false,
                        sweetShow: false,
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false,
                    });
                    window.scrollTo(0, 0);

                } else {
                    setState({
                        ...state,
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();

    }

    const handleAddProject = async (id) => {
        setState({ ...state, showLoader: true });

        let resData = {};
        resData = await getProjectDetail(id);

        if (resData.meta.status === 200) {
            setState({
                ...state,
                fullDetail: resData,
                showLoader: false
            })
            window.scrollTo(0, 0);
        } else if (resData.meta.status === 401) {
            cleanStorage();
            props.history.push("/login");
        } else {
            setState({
                ...state,
                fullDetail: resData,
                showLoader: false
            })
        }

        history.push(
            {
                pathname: '/projects/add-update',
                state: { projectId: id, fullDetail: resData, request: true }
            }
        )
    }


    const handleSweet = () => {
        setState({ ...state, sweetShow: !state.sweetShow  });
        handleFilter();
    }
    const handleRejectProject = async (id) => {  
        setState({ ...state, showLoader: true});
        let resData = {};
        resData = await deleteProject(id);
        if (resData.meta.status === 200) {
            await setState({
                ...state,
                showLoader: false,
                sweetWarnShow: false,
                sweetShow: true,
                sweetType: "success",
                title: "Project Rejected Successfully!!!"
            });
        } else if (resData.meta.status === 401) {
            cleanStorage();
            props.history.push("/login");
        } else {
            setState({
                ...state,
                showLoader: false,
                open: true,
                type: "error",
                msg: resData.meta.message
            })
        }
    }

    const handleSweetWarn = (value) => {
        setState({
            ...state,
            sweetWarnShow: !state.sweetWarnShow,
            rejectId: value
        });
    }
    
    const handleUpdateUser = () => {
        setState({
            ...state,
            sweetWarnShow: !state.sweetWarnShow,
        });
        handleRejectProject(state.rejectId);
    }

    const handleFilter = async (filterData) => {
        setState({ ...state, 
            showLoader: true,
            filters: filterData, 
            activePage: 1
         })
        let listData = {};
        listData.pageId = 1;

        Object.assign(listData, filterData)
        getListData(listData);

    }

    const { showLoader, type, msg, open, meta, data, showFilter, activePage, sweetWarnShow, sweetShow, sweetType, title } = state;

    const handlePopUp = () => {
        setState({
            ...state,
            showFilter: !showFilter,
        });
    }


    const handleChangePage = async (pageNumber) => {
        setState({
            ...state,
            showLoader: true,
            activePage: pageNumber
        })
        let listData = {};
        listData.pageId = pageNumber;
		getListData(listData)
    }

    return (
        <Frag>
            <div className="p-3 leadMainHeader">
                <div className="headerCount">
                    <span className="totalCount">Total Magnet Requests : </span>
                    <span className="">{meta?.status !== 200 ? "0" : data?.count}</span>
                </div>
                <div className="d-inline ml-3 filterHeader">
                    <span className="filter" onClick={handlePopUp}>
                        <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                    </span>
                    <div className={"filterMenu" + " " + (showFilter ? 'filterMenuShow' : '')} >
                        <ProjectFilter filterData={handleFilter} changeFilter={handlePopUp} />
                    </div>
                </div>
            </div>
            {(meta?.status === 200 && data?.projects?.length > 0 ) ?
            <div className="listContainer">
                {data?.projects?.map((projectsList, index) =>
                    <ListingView key={index}>
                        <OneFourthField title="Project ID" value={projectsList?.id || '-'} />
                        <OneFourthField title="Project Name" value={projectsList?.name || '-'} />
                        <OneFourthField title="Builder Name" value={projectsList?.builder_name || '-'} />
                        {/* <OneFourthField title="Region" value={projectsList?.Region?.name || '-'} /> */}
                        <OneFourthField title="Requested By" value={projectsList?.ProjectCreator?.name || '-'} />
                        <OneFourthField title="RERA Number" value={projectsList?.rera_no || '-'} />
                        <OneFourthField title="Land Area (Acres)" value={projectsList?.land_area || '-'} />
                        <OneFourthField title="Number of Towers" value={projectsList?.tower_no || '-'} />
                        <OneFourthField title="Number of Floors" value={projectsList?.storeys || '-'} />
                        <OneFourthField title="Possession Date" value={projectsList?.ready_to_move ? 'ready to move' : (projectsList?.possession_date ? moment(projectsList?.possession_date).format('ddd, ll') : '-')} />
                        <HalfField title="Location" value={projectsList?.location || '-'} />
                        <div className="container-fluid">
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-2 col-6">
                                    <button className="btn btn-success w-100" onClick={() => handleAddProject(projectsList?.id)}>Add Project</button>
                                </div>
                                <div className="col-md-2 col-6">
                                    <button className="btn btn-danger w-100" onClick={() => handleSweetWarn(projectsList?.id)}>Reject</button>
                                </div>
                            </div>
                        </div>
                    </ListingView>
                )}
                <div className="container-fluid">
	                    <div className="row justify-content-end">
	                        {meta?.count > 8 ? (
	                            <div className="paginationBlock">
	                                <Pagination
	                                    hideDisabled
	                                    activePage={activePage}
	                                    itemsCountPerPage={meta?.size}
	                                    totalItemsCount={meta?.count}
	                                    pageRangeDisplayed={5}
	                                    onChange={handleChangePage}
	                                />
	                            </div>
	                        ) : (
	                                ""
	                            )}
	                    </div>
                    </div>
            </div>
            :
            <div className="main_area_display errorMsg shadow card p-3">
                <h5>No Data Found !!!</h5>
            </div>
            }
            <Loader show={showLoader} />
            <SweetAlert show={sweetShow} type={sweetType} title={title} changeSweet={handleSweet} />
            <SweetWarnAlert show={sweetWarnShow} changeSweet={handleSweetWarn} message="Project will be Rejected!!!" handleUpdateUser={handleUpdateUser}/>
            <PopUpAlert type={type} msg={msg} open={open} handleClose={handleClose} />
       
        </Frag>
    )
}

export default MagnetRequest
