import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, Tab, TextField } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CreateRm, updateRm } from "../../dataParser/commomDataApi.js";
import { autoComplete } from "../../dataParser/commomDataApi.js";
import PopUpAlert from "../common/sweetAlert/popUpAlert.js";
import { REVERT_ROLES } from "../../config/constant/index.js";
import { cleanStorage } from "../../helper/index.js";
import { bookingRolesDetail } from "../../dataParser/getListData.js";

const UpdateRm = props => {
  const [value, setvalue] = useState("sale");
  const [show, setShow] = useState(props.show);

  const closemodel = () => {
    setShow(!show);
  };

  const handleChange = newValue => {
    setvalue(newValue);
  };

  const [state, setState] = useState({
    s1Name  : [],
    s2Name  : [],
    s3Name  : [],
    a1Name  : [],
    srA1Name: [],
    rhName  : [],
    pnlName : [],
    shName  : [],

    saleS1Rm  : {},
    saleA1Rm  : {},
    saleSrA1Rm: {},
    saleRhRm  : {},
    salePnlRm : {},
    saleShRm  : {},

    presaleS2Rm  : {},
    presaleS3Rm  : {},
    presaleRhRm  : {},
    presalePnlRm : {},
    presaleShRm  : {},

    crossSaleS1Rm   : {},
    crossSaleA1Rm   : {},
    crossSaleSrA1Rm : {},
    crossSaleRhRm   : {},
    crossSalePnlRm  : {},
    crossSaleShRm   : {},
    salelength      : null,
    preSaleLength   : null,
    crossSaleLength : null, 
  });

  const {
    a1Name,
    s1Name,
    s2Name,
    s3Name,
    srA1Name,
    rhName,
    pnlName,
    shName,
    saleS1Rm,
    saleA1Rm,
    saleSrA1Rm,
    saleRhRm,
    salePnlRm,
    saleShRm,
    presaleS2Rm,
    presaleS3Rm,
    presaleRhRm,
    presalePnlRm,
    presaleShRm,
    crossSaleS1Rm,
    crossSaleA1Rm,
    crossSaleSrA1Rm,
    crossSaleRhRm,
    crossSalePnlRm,
    crossSaleShRm,
    salelength,
    preSaleLength,
    crossSaleLength,
  } = state;

  useEffect(()=>{
    handleGetBookingRoles();
  },[])

  const handleGetBookingRoles = async() =>{
    let sale = {};
    let preSales = {};
    let crossSales = {};
    let bookingRoles ={};

    const data = await bookingRolesDetail(props.lead_id);
    bookingRoles = data.data;
    for (let i = 0; i < bookingRoles.length; i++) {
      if (bookingRoles[i] != null && bookingRoles[i].type === "sale") 
      { 
        Object.keys(bookingRoles[i]).forEach(e => {
          if (bookingRoles[i][e] !== null) {
            if (e == "type") {
              sale[e] = bookingRoles[i][e];
            } else {
              sale[e] = bookingRoles[i][e].id;
              sale[e + "_name"] = bookingRoles[i][e].first_name +" "+ bookingRoles[i][e].last_name;
            }
          } else {
            sale[e] = null;
          }
        });
      } else if (bookingRoles[i] != null && bookingRoles[i].type === "presales") 
        {
        Object.keys(bookingRoles[i]).forEach(e => {
          if (bookingRoles[i][e] !== null) {
            if (e == "type") {
              preSales[e] = bookingRoles[i][e];
            } else {
              preSales[e] = bookingRoles[i][e].id;
              preSales[e + "_name"] = bookingRoles[i][e].first_name +" "+ bookingRoles[i][e].last_name;
            }
          } else {
            preSales[e] = null;
          }
        });
      } else if (bookingRoles[i] != null && bookingRoles[i].type === "cross_from") 
        {
        Object.keys(bookingRoles[i]).forEach(e => {
          if (bookingRoles[i][e] !== null) {
            if (e == "type") {
              crossSales[e] = bookingRoles[i][e];
            } else {
              crossSales[e] = bookingRoles[i][e].id;
              crossSales[e + "_name"] = bookingRoles[i][e].first_name +" "+ bookingRoles[i][e].last_name;
            }
          } else {
            crossSales[e] = null;
          }
        });
      }
    }
    setState({
      ...state,
      salelength : Object.keys(sale).length,
      saleS1Rm  : { name: sale.s1_id_name,    id: sale.s1_id },
      saleA1Rm  : { name: sale.a1_id_name,    id: sale.a1_id },
      saleSrA1Rm: { name: sale.sr_a1_id_name, id: sale.sr_a1_id },
      saleRhRm  : { name: sale.rh_id_name,    id: sale.rh_id },
      salePnlRm : { name: sale.pnl_id_name,   id: sale.pnl_id },
      saleShRm  : { name: sale.sh_id_name,    id: sale.sh_id },
  
      preSaleLength : Object.keys(preSales).length,
      presaleS2Rm  : { name: preSales.s2_id_name,  id: preSales.s2_id },
      presaleS3Rm  : { name: preSales.s3_id_name,  id: preSales.s3_id },
      presaleRhRm  : { name: preSales.rh_id_name,  id: preSales.rh_id },
      presalePnlRm : { name: preSales.pnl_id_name, id: preSales.pnl_id },
      presaleShRm  : { name: preSales.sh_id_name,  id: preSales.sh_id },
      
      crossSaleLength : Object.keys(crossSales).length,
      crossSaleS1Rm   : { name: crossSales.s1_id_name,    id: crossSales.s1_id },
      crossSaleA1Rm   : { name: crossSales.a1_id_name,    id: crossSales.a1_id },
      crossSaleSrA1Rm : { name: crossSales.sr_a1_id_name, id: crossSales.sr_a1_id },
      crossSaleRhRm   : { name: crossSales.rh_id_name,    id: crossSales.rh_id },
      crossSalePnlRm  : { name: crossSales.pnl_id_name,   id: crossSales.pnl_id },
      crossSaleShRm   : { name: crossSales.sh_id_name,    id: crossSales.sh_id }
    })
  };
  
  const handleAutoRmChange = async (e,role) => {
    let data = {};
    data.q = e.target.value;
    data.roles = role;

    let resData = await autoComplete("users", data);
    if (resData.meta.status === 200) {
      switch (role) {
        case 10:
          setState({
            ...state,
            s1Name: resData.data
          });
          break;
        case 14:
          setState({
            ...state,
            s2Name: resData.data
          });
          break;
        case 15:
          setState({
            ...state,
            s3Name: resData.data
          });
          break;
        case 8:
          setState({
            ...state,
            a1Name: resData.data
          });
          break;
        case 37:
          setState({
            ...state,
            srA1Name: resData.data
          });
          break;
        case 7:
          setState({
            ...state,
            rhName: resData.data
          });
          break;
        case 6:
          setState({
            ...state,
            pnlName: resData.data
          });
          break;
        case 5:
          setState({
            ...state,
            shName: resData.data
          });
          break;
        default:
          setState({
            ...state
          });
      }
    } else if (resData.meta.status === 401) {
      cleanStorage();
    } else {
      setState({
        ...state
      });
    }
  };

  const handleSubmit = async(e) => {
    try{
        e.preventDefault();
        let data={
          type: value,
          booking_id: props.booking_id,
          lead_id   : props.lead_id,
        };
        switch(value){
          case 'sale' : data={ 
                              ...data,
                              s1_id : saleS1Rm ? saleS1Rm.id : null,
                              a1_id : saleA1Rm ? saleA1Rm.id : null,
                              sr_a1_id : saleSrA1Rm ? saleSrA1Rm.id : null,
                              rh_id : saleRhRm ? saleRhRm.id : null,
                              pnl_id : salePnlRm ? salePnlRm.id : null,
                              sh_id : saleShRm ? saleShRm.id : null,
                             };
                             break;
          case 'presales' : data={
                                  ...data,
                                  s2_id : presaleS2Rm ? presaleS2Rm.id : null,
                                  s3_id : presaleS3Rm ? presaleS3Rm.id : null,
                                  rh_id : presaleRhRm ? presaleRhRm.id : null,
                                  pnl_id : presalePnlRm ? presalePnlRm.id : null,
                                  sh_id : presaleShRm ? presaleShRm.id : null,
                                 };
                                break;
          case 'cross_from' : data={
                                    ...data,
                                    s1_id : crossSaleS1Rm ? crossSaleS1Rm.id : null,
                                    a1_id : crossSaleA1Rm ? crossSaleA1Rm.id : null,
                                    sr_a1_id : crossSaleSrA1Rm ? crossSaleSrA1Rm.id : null,
                                    rh_id : crossSaleRhRm ? crossSaleRhRm.id : null,
                                    pnl_id : crossSalePnlRm ? crossSalePnlRm.id : null,
                                    sh_id : crossSaleShRm ? crossSaleShRm.id : null,
                                   }
                                   break;
        }
        
        let getRes = {};
        if (value === "sale" &&  salelength == 0 || value === "presales" && preSaleLength == 0 || value === "cross_from" && crossSaleLength == 0){
            getRes = await CreateRm(data);
        } else {
            getRes = await updateRm(data.lead_id,data); 
        }
        if (getRes.meta.status === 201) {
          handleGetBookingRoles(); 
            props.setWarningMsg({
            alertShow: true,
            alertType: "success",
            alertMsg: "Booking data updated successfully!!!"
          });

        } else {
          props.setWarningMsg({
            alertShow: true,
            alertType: "error",
            alertMsg: "something went wrong",
          });
        }
      } catch (error) {
      return error;  
      }
  };

  return (
    <Modal
      show={show}
      onHide={closemodel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="unselectable"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <TabContext value={value}>
            <TabList
              onChange={(event, value) => {
                handleChange(value);
              }}
              centered
            >
              <Tab label="Sale" value="sale" />
              <Tab label="Pre Sales" value="presales" />
              <Tab label="Cross Sales" value="cross_from" />
            </TabList>
            <TabPanel value="sale" className="container">
              <form>
                <div className="row mx-md-5">
                  <div className="col-sm-4 pt-3 text-center">S1 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(s1Name, value) => s1Name.name === value.name}
                      getOptionLabel={s1Name => s1Name.name ? s1Name.name : ""}
                      options={s1Name}
                      value={saleS1Rm}
                      onChange={(_event, s1Name) => {
                        if (s1Name === null) {
                          setState({
                            ...state,
                            s1Name: [],
                            saleS1Rm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            saleS1Rm: { name: s1Name.name, id: s1Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="S1_Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.CLOSING_S1)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">A1 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(a1Name, value) => a1Name.name === value.name}
                      getOptionLabel={a1Name => a1Name.name ? a1Name.name : ""}
                      options={a1Name}
                      value={saleA1Rm}
                      onChange={(_event, a1Name) => {
                        if (a1Name === null) {
                          setState({
                            ...state,
                            a1Name: [],
                            saleA1Rm : null,                            
                          });
                        } else {
                          setState({
                            ...state,
                            saleA1Rm: { name: a1Name.name, id: a1Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="A1 Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_TL)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">Sr. A1 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(srA1Name, value) => srA1Name.name === value.name}
                      getOptionLabel={srA1Name =>srA1Name.name ? srA1Name.name : ""}
                      options={srA1Name}
                      value={saleSrA1Rm}
                      onChange={(_event, srA1Name) => {
                        if (srA1Name === null) {
                          setState({
                            ...state,
                            srA1Name: [],
                            saleSrA1Rm:null,
                          });
                        } else {
                          setState({
                            ...state,
                            saleSrA1Rm: { name: srA1Name.name, id: srA1Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Sr. A1 Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SENIOR_SALES_TL)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">RH Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(rhName, value) => rhName.name === value.name}
                      getOptionLabel={rhName => rhName.name ? rhName.name : ""}
                      options={rhName}
                      value={saleRhRm}
                      onChange={(_event, rhName) => {
                        if (rhName === null) {
                          setState({
                            ...state,
                            rhName: [],
                           saleRhRm:null,
                          });
                        } else {
                          setState({
                            ...state,
                            saleRhRm: { name: rhName.name, id: rhName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="RH Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_MANAGER)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">PNL Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(pnlName, value) => pnlName.name === value.name}
                      getOptionLabel={pnlName => pnlName.name ? pnlName.name : ""}
                      options={pnlName}
                      value={salePnlRm}
                      onChange={(_event, pnlName) => {
                        if (pnlName === null) {
                          setState({
                            ...state,
                            pnlName: [],
                            salePnlRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            salePnlRm: { name: pnlName.name, id: pnlName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="PNL Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.PNL_HEAD)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">SH Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(shName, value) => shName.name === value.name}
                      getOptionLabel={shName => shName.name ? shName.name : ""}
                      options={shName}
                      value={saleShRm}
                      onChange={(_event, shName) => {
                        if (shName === null) {
                          setState({
                            ...state,
                            shName: [],
                            saleShRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            saleShRm: { name: shName.name, id: shName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="SH Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_HEAD)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center"></div>
                  <div className="col-sm-6 mb-3">
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel value="presales" className="container">
              <form>
                <div className="row mx-md-5">
                  <div className="col-sm-4 pt-3 text-center">S3 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(s3Name, value) => s3Name.name === value.name}
                      getOptionLabel={s3Name => s3Name.name ? s3Name.name : ""}
                      options={s3Name}
                      value={presaleS3Rm}
                      onChange={(_event, s3Name) => {
                        if (s3Name === null) {
                          setState({
                            ...state,
                            s3Name: [],
                            presaleS3Rm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            presaleS3Rm: { name: s3Name.name, id: s3Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="S3 Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SOURCING_S3)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">S2 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(s2Name, value) => s2Name.name === value.name}
                      getOptionLabel={s2Name => s2Name.name ? s2Name.name : ""}
                      options={s2Name}
                      value={presaleS2Rm}
                      onChange={(_event, s2Name) => {
                        if (s2Name === null) {
                          setState({
                            ...state,
                            s2Name: [],
                            presaleS2Rm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            presaleS2Rm: { name: s2Name.name, id: s2Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="S2 Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SOURCING_S2)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">RH Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(rhName, value) => rhName.name === value.name}
                      getOptionLabel={rhName => rhName.name ? rhName.name : ""}
                      options={rhName}
                      value={presaleRhRm}
                      onChange={(_event, rhName) => {
                        if (rhName === null) {
                          setState({
                            ...state,
                            rhName: [],
                            presaleRhRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            presaleRhRm: { name: rhName.name, id: rhName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="RH Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_MANAGER)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">PNL Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(pnlName, value) => pnlName.name === value.name}
                      getOptionLabel={pnlName => pnlName.name ? pnlName.name : ""}
                      options={pnlName}
                      value={presalePnlRm}
                      onChange={(_event, pnlName) => {
                        if (pnlName === null) {
                          setState({
                            ...state,
                            pnlName: [],
                            presalePnlRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            presalePnlRm: { name: pnlName.name, id: pnlName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="PNL Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.PNL_HEAD)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">SH Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(shName, value) => shName.name === value.name}
                      getOptionLabel={shName =>shName.name ? shName.name : ""}
                      options={shName}
                      value={presaleShRm}
                      onChange={(_event, shName) => {
                        if (shName === null) {
                          setState({
                            ...state,
                            shName: [],
                            presaleShRm:null,
                          });
                        } else {
                          setState({
                            ...state,
                            presaleShRm: { name: shName.name, id: shName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="SH Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_HEAD)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center"></div>
                  <div className="col-sm-6 mb-3">
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel value="cross_from" className="container">
              <form>
                <div className="row mx-md-5">
                  <div className="col-sm-4 pt-3 text-center">S1 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(s1Name, value) => s1Name.name === value.name}
                      getOptionLabel={s1Name => s1Name.name ? s1Name.name : ""}
                      options={s1Name}
                      value={crossSaleS1Rm}
                      onChange={(_event, s1Name) => {
                        if (s1Name === null) {
                          setState({
                            ...state,
                            s1Name: [],
                            crossSaleS1Rm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            crossSaleS1Rm: { name: s1Name.name, id: s1Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="S1_Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.CLOSING_S1)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">A1 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(a1Name, value) => a1Name.name === value.name}
                      getOptionLabel={a1Name => a1Name.name ? a1Name.name : ""}
                      options={a1Name}
                      value={crossSaleA1Rm}
                      onChange={(_event, a1Name) => {
                        if (a1Name === null) {
                          setState({
                            ...state,
                            a1Name: [],
                            crossSaleA1Rm:null,
                          });
                        } else {
                          setState({
                            ...state,
                            crossSaleA1Rm: { name: a1Name.name, id: a1Name.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="A1 Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_TL)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">Sr. A1 Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(srA1Name, value) => srA1Name.name === value.name}
                      getOptionLabel={srA1Name => srA1Name.name ? srA1Name.name : ""}
                      options={srA1Name}
                      value={crossSaleSrA1Rm}
                      onChange={(_event, srA1Name) => {
                        if (srA1Name === null) {
                          setState({
                            ...state,
                            srA1Name: [],
                            crossSaleSrA1Rm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            crossSaleSrA1Rm: {name: srA1Name.name,id: srA1Name.id}
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Sr. A1 Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SENIOR_SALES_TL)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">RH Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(rhName, value) => rhName.name === value.name}
                      getOptionLabel={rhName => rhName.name ? rhName.name : ""}
                      options={rhName}
                      value={crossSaleRhRm}
                      onChange={(_event, rhName) => {
                        if (rhName === null) {
                          setState({
                            ...state,
                            rhName: [],
                            crossSaleRhRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            crossSaleRhRm: { name: rhName.name, id: rhName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="RH Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_MANAGER)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">PNL Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(pnlName, value) => pnlName.name === value.name}
                      getOptionLabel={pnlName =>pnlName.name ? pnlName.name : ""}
                      options={pnlName}
                      value={crossSalePnlRm}
                      onChange={(_event, pnlName) => {
                        if (pnlName === null) {
                          setState({
                            ...state,
                            pnlName: [],
                            crossSalePnlRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            crossSalePnlRm: {name: pnlName.name,id: pnlName.id}
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="PNL Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.PNL_HEAD)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center">SH Name</div>
                  <div className="col-sm-6 mb-3">
                    <Autocomplete
                      freeSolo
                      getOptionSelected={(shName, value) => shName.name === value.name}
                      getOptionLabel={shName =>shName.name ? shName.name : ""}
                      options={shName}
                      value={crossSaleShRm}
                      onChange={(_event, shName) => {
                        if (shName === null) {
                          setState({
                            ...state,
                            shName: [],
                            crossSaleShRm: null,
                          });
                        } else {
                          setState({
                            ...state,
                            crossSaleShRm: { name: shName.name, id: shName.id }
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="SH Name"
                          fullWidth
                          onChange={e => handleAutoRmChange(e, REVERT_ROLES.SALES_HEAD)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4 pt-3 text-center"></div>
                  <div className="col-sm-6 mb-3">
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </TabPanel>
          </TabContext>
          <PopUpAlert handleClose={closemodel} hideTime={5000} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateRm;
