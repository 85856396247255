import React, {Component} from "react";
import {getReferalUser} from "../../dataParser/magnetUser.js";
import ReferalUserTable from "../../component/common/table/referalUserTable.js";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";

class ReferalUserList extends Component {

	constructor(props) {
    	super(props);
		this.state = {
		    refDetails : '',
        	showLoader : false,
        	sideBar: false,
      		mobileSideBar: false,
		};
	}

	async componentDidMount () {
		this.setState({showLoader : true});
		var refData = {};
        refData.size = 20;
        refData.pageId = 1;

        (async () => {
            try {
    			let resData = await getReferalUser(refData);
        		await this.setState({
		        	refDetails: resData,
		        	showLoader : false
		        })
	        	window.scrollTo(0, 0);
            } catch (e) {	
                //...handle the error...
            }
            })();
	}

	changeButtonState(event) {
	    this.setState({sideBar: !this.state.sideBar})
	}

	changeSideBarState(event) {
	    this.setState({mobileSideBar: !this.state.mobileSideBar})
	}

	render(){
		return(
			<Frag>
				<Loader show={this.state.showLoader}/>
				{this.state.refDetails.meta &&
					<ReferalUserTable expand ={this.props.sideBar} refDataValue = {this.state.refDetails}/>
				}
			</Frag>
		);

	};
};

export default ReferalUserList;
