import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import { UpdateRevertData } from "../../../dataParser/getListData";
import { autoComplete } from "../../../dataParser/commomDataApi";
import { REVERT_ROLES } from '../../../config/constant';

var rmShow;

class RevertLeadForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			leadId: '',
			action: '',
			rm_name: [],
			sales_rm_id: '',
			comment: '',
			isError: false,
			sweetShow: false,
			type: "success",
			statusShow: ['sourcing', 'disinterested', 'reactivation', 'disqualify',],
			title: "",
			errors: {
				closing_reason: '',
				comment: ''
			}
		};
	}

	async componentDidMount() {

		this.setState({
			role: window.$role,
			group: window.$user.group.toLowerCase()
		})

		// console.log(this.props.userId);
	}

	updateLeadFrom = async (e) => {

		e.preventDefault();
		// console.log("NEw Form=======_------------>", this.props);
		let leadId = this.props.leadId;

		let upadateBulkReq = {};
		let mainData = {};
		if (this.state.action === '') {
			if (this.state.group === 'sales') {
				mainData["action"] = "reactivation";
			} else {
				mainData["action"] = "sourcing";
			}
		} else {
			mainData["action"] = this.state.action;
		}

		if (this.state.sales_rm_id !== '') mainData["sales_rm_id"] = this.state.sales_rm_id;

		if (this.state.comment.trim().length) {
			mainData["comment"] = this.state.comment;
		}

		upadateBulkReq.id = leadId;
		upadateBulkReq.data = mainData;

		if (this.state.comment.trim().length && rmShow ? this.state.sales_rm_id !== '' : this.state.action !== '') {
			var addLeadRes = await UpdateRevertData(upadateBulkReq);
			if (addLeadRes.meta.status === 201) {
				this.setState({
					sweetShow: true,
					type: "success",
					title: "Lead Updated Successfully!!!"

				});
			} else {
				this.setState({
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"

				});
			}
		} else {

			this.setState({ isError: true });
		}
	}

	onChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}
	AutoChangeRM = async (e) => {
		this.setState({ isLoading: true });

		(async () => {
			try {
				let data = {
					q: e.target.value,
					region_id : window.$user.region_id,	
					type : 'reactivation',
					roles: this.state.group === 'presale' ? [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD,] :
						[REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3,]
				};
				let resData = await autoComplete('users', data);
				await this.setState({
					rm_name: resData.data,
					isLoading: false
				})
			} catch (e) {

			}
		})();
	}
	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();

		// if(this.state.role === "admin" || this.state.role === "magnetadmin" && this.state.admin_approved === 1){
		//    	console.log("mail reload");
		//    }else{
		// window.location.reload();
		//    }
	}

	//    handleDateChange = (key, date) =>{

	// 	this.setState({[key] : date});
	// }

	render() {

		const { sweetShow, type, title, action, isLoading, rm_name, statusShow } = this.state;
		// console.log("action =================>",action)
		rmShow = (this.state.group === 'presale' ? this.props.from !== "reactivation" : this.props.from !== "sourcing" && this.props.from !== "reactivation")
		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								{/*this.props.from === 'sourcing' || this.props.from === 'disinterested' || this.props.from === 'reactivation' || this.props.from === 'disqualify' ?*/}
								{statusShow.includes(this.props.from) ?
									<div className="col-md-6 col-sm-6 text-center col-12 mb-3">
										<FormControl>
											<InputLabel id="demo-controlled-open-select-label" required>Status</InputLabel>
											<Select
												labelId="demo-controlled-open-select-label"
												value={action}
												onChange={this.onChange}
												inputProps={{
													name: 'action',
													id: 'action',
												}}
											>
												{this.state.group == 'presale' && <MenuItem value="interested">interested</MenuItem>}
												{this.state.group == 'presale' && <MenuItem value="disinterested">Disinterested</MenuItem>}
												{this.state.group == 'sales' && <MenuItem value="disqualify">Disqualify</MenuItem>}
												{this.state.group == 'sales' && <MenuItem value="reactivation">Send for reactivation</MenuItem>}
											</Select>
										</FormControl>
									</div>
									:
									''
								}
								{rmShow &&
									<div className="col-md-6 mb-3">
										<Autocomplete
											id="asynchronous-demo"
											getOptionSelected={(rm_name, value) => rm_name.name === value.name}
											getOptionLabel={rm_name => rm_name.name}
											options={rm_name}
											loading={isLoading}
											onChange={(_event, rm_name) => {
												if (rm_name === null) {
													this.setState({ rm_name: [] })
												} else {
													this.setState({
														sales_rm_id: rm_name.id,
													})
												}
											}}
											renderInput={params => (
												<TextField
													required
													{...params}
													label={this.state.group === 'presale' ? 'Sales Team RM' : 'Presales Team RM'}
													fullWidth
													onChange={this.AutoChangeRM}
													helperText="Please select RM"
												/>
											)}
										/>
									</div>
								}
								<div className="col-sm-6 col-12">
									<div className="form-group">
										<TextField
											required
											error={this.state.errors.comment !== '' ? 'error' : ''}
											id="standard-multiline-flexible"
											label="Comments"
											name="comment"
											helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
											multiline
											rowsMax="4"
											value={this.state.comment}
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>Send</button>
									</div>
								</div>

								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Above all fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(RevertLeadForm);
