import React from "react";
import "./main.css";
import SecondStep from "./secondstep.js"
import ThirdStep from "./thirdstep.js"
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
// import Header from "../../component/header/header.js";
// import SideBar from "../../component/side-header/side_header.js";
import Footer from "../../component/footer/footer.js";
import Loader from "../../component/common/loader/loader";
import {APP_BASE_URL} from "../../config/serverKey.js";
import {getFromStage} from "../../dataParser/magnetUser";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";

const styles = theme => ({
  root: {
    width: "90%"
  },
  button: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
});

function getSteps() {
  return ["", ""];
}



class VerticalLinearStepper extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeStep: 0,
            user_details_id: '',
            sideBar: false,
            sweetShow:false,
            showLoader:false,
            type : "success",
            title : "",
            mobileSideBar: false
        }
    }

    async componentDidMount(){
        let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
            // console.log("===================>homesfy_lg=========>");
        this.setState({showLoader:true});    
        if(homesfy_lg && homesfy_lg !== ""){
            // console.log("===========Local in the sidebar ==========")
            let user = JSON.parse(homesfy_lg);

            if(user.is_approved === true){
        
              window.location = APP_BASE_URL;
            }
            this.setState({showLoader:false});    

            this.setState({
                user_details_id: user.id,
                activeStep : user.reg_stage
            });
          let formStage = await getFromStage(user.id);
          // console.log(formStage);

          if(formStage.meta.status === 503){
               
                this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "error",    
                   title : "!!! Oooops Network Error..." 
                });
               
           }else if(formStage.meta.status === 200){
               
               // console.log("User Register successfully !!!");
               this.setState({
                    activeStep :formStage.data.reg_stage 
                });
        

           }else{
                console.log(formStage.meta.message); 
           }
        }

    }

    changeButtonState(event) {
        this.setState({sideBar: !this.state.sideBar});
        document.body.classList.add('sidebar-enable');
    }

    changeSideBarState(event) {
        this.setState({mobileSideBar: !this.state.mobileSideBar})
    }

    getStepContent=(step)=> {
   // const isLastStep = (activeStep === steps.length - 1);
        switch (step) {
          case 0:
            return <SecondStep handleNext={this.handleNext} userID={this.state.user_details_id}/>;
          case 1:
            return <ThirdStep userID={this.state.user_details_id} handleNext={this.handleNext}/>;
        
          default:
            // return "thanks registration";
            return <ThirdStep userID={this.state.user_details_id} handleNext={this.handleNext}/>;
            
        }
    }

  handleGetID=(id)=>{
    this.setState({user_details_id: id});

  }

  handleNext = () => {
    // console.log("=============handleNext Calllll===========>");
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };


   handleChange = input => event => {
    // console.log("input",input);
        this.setState({ [input] : event.target.value })
    }

  handleSweet=(type)=>{
        // console.log("type Sweet Alert ======>",type);
        this.setState({sweetShow : !this.state.sweetShow});
        // this.props.changeModal()
    }  



  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep,sweetShow,type,title} = this.state;
    // console.log("================>",this.state)

    return (
    <div>  
        <Loader show={this.state.showLoader}/>
        <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
        {/* <SideBar sideBar ={this.state.sideBar} mobileBar = {this.state.mobileSideBar} mobileBarClick={this.changeSideBarState.bind(this)}/> */}
        {/* <Header buttonClick={this.changeButtonState.bind(this)} expand ={this.state.sideBar} mobileBarClick={this.changeSideBarState.bind(this)}/>    */}
        <div className={"content-wrapper"+" "+(this.props.expand ? 'main-header-collapsed' : '')}>
            <div className="register-page">
                <div className="row registerPage">
                    <div className="col-md-12 text-center">
                          <div className="p-3">
                              <div className="row">
                                    <div className="col-md-12 registerLogo">  
                                        <h3>Complete Your Profile</h3>
                                    </div>  
                              </div>  
                                  
                            <Stepper activeStep={activeStep} >
                              {steps.map((label, index) => {
                                return (
                                  <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                  </Step>
                                );
                              })}
                            </Stepper>
                            {activeStep === steps.length ? (
                              <div>
                                <Typography className="pb-5">
                                  All steps completed - Your registration is Done.
                                </Typography>
                                <Typography className="pb-5">
                                  You will recieve the Document confirmation email shortly on your mail.
                                </Typography>
                              </div>
                            ) : (
                              <div className="col-md-12">
                                <Typography component={'span'} className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
                              </div>
                            )}
                          </div>
                    </div>
                </div> 
            </div> 
        </div>
        <Footer expand ={this.state.sideBar} /> 
    </div> 
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(VerticalLinearStepper);
