import React, { Component } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import {
  getAutoCompleteProject,
  getRegion
} from "../../dataParser/getProjectData";
import { getSalesTeamMembers } from "../../dataParser/getListUserData";
import { REVERT_ROLES } from "../../config/constant";
import { autoComplete } from "../../dataParser/commomDataApi";
import { cleanStorage } from "../../helper";
import SelectOption from "../common/controls/SelectOption";
import { yesNoType } from "../common/datas/data";

export default class BookingFilter extends Component {
  constructor(props) {
    super();

    this.state = {
      leadId: "",
      clientNumber: "",
      projects: [],
      selectedProjects: [],
      allRegions: [],
      regions: [],
      selectedRegion: "",
      salesRmMembers: [],
      selectedSalesRms: [],
      parentRmMembers: [],
      selectedParentRms: [],
      fromDate: null,
      toDate: null,
      isLoading: false,
      is_magnet:'',
      is_presale:'',
      passback:'',
      document_status:'',
      selectedBookingProjects:[],
      autoKey:true,
      presaleUser: [],
      selectedPresaleRm: [],
      presale_rm_id: "",
      is_crosssale: ""
    };
  }

  async componentDidMount() {
    var regions = await getRegion();
    this.setState({ allRegions: regions, regions: regions });
  }

  filterProjects = async e => {
    if (e.target.value) {
      let projectList = await getAutoCompleteProject(e.target.value);

      this.setState({ projects: projectList.data ? projectList.data : [] });
    } else {
      this.setState({ projects: [] });
    }
  };

  filterRegions = e => {
    let { allRegions } = this.state;
    if (e.target.value) {
      let regions = allRegions.filter(o =>
        o.name.toLowerCase().includes(e.target.value.toLowerCase())
      );

      this.setState({ regions });
    } else {
      this.setState({ regions: this.state.allRegions });
    }
  };

  filterSalesRm = async e => {
    if (e.target.value) {
      let salesRmMembers = await getSalesTeamMembers(e.target.value, 0,1);

      this.setState({ salesRmMembers });
    } else {
      this.setState({ salesRmMembers: [] });
    }
  };

  filterParentRm = async e => {
    if (e.target.value) {
      let parentRmMembers = await getSalesTeamMembers(e.target.value, 1);

      this.setState({ parentRmMembers });
    } else {
      this.setState({ parentRmMembers: [] });
    }
  };

  onFilter = async () => {
    this.props.filterBookings({
      page: 1,
      size: 10,
      leadId: this.state.leadId,
      clientNumber: this.state.clientNumber,
      projectId: this.state.selectedProjects.join(","),
      booking_project_id:this.state.selectedBookingProjects.join(","),
      regionId: this.state.selectedRegion,
      salesRm: this.state.selectedSalesRms.join(","),
      parentRm: this.state.selectedParentRms.join(","),
      presale_rm_id: this.state.presale_rm_id ? this.state.presale_rm_id : "" ,
      is_crosssale: this.state.is_crosssale !== "" ? this.state.is_crosssale : "" ,
      fromDate: this.state.fromDate
        ? this.state.fromDate.toISOString().split("T")[0]
        : "",
      toDate: this.state.toDate
        ? this.state.toDate.toISOString().split("T")[0]
        : "",
        is_magnet:this.state.is_magnet,
        is_presale:this.state.is_presale,
        passback:this.state.passback,
        document_status:this.state.document_status,
        is_lead_cancelled : this.state.under_cancellation  
    });
    this.props.toggleFilter();
  };

  onFilterReset = () => {
    this.setState(
      {
        leadId: "",
        clientNumber: "",
        selectedProjects:[],
        selectedRegion: "",
        selectedSalesRms:[],
        selectedParentRms: [],
        fromDate: null,
        toDate: null,
        is_magnet:'',
        is_presale:'',
        passback:'',
        document_status:'',
        selectedBookingProjects:[],
        autoKey:false,
        presaleUser: [],
        selectedPresaleRm: [],
        presale_rm_id: "",
        is_crosssale: "",
        under_cancellation:''
      },
      () => {
        this.props.filterReset();
      }
    );
    this.props.toggleFilter();
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  handleAutoPresaleChange = async (e) => {
    this.setState({ isPresaleLoading: true });
    let data = {};
    let resData = {};

    data.q = e.target.value;
    data.roles = [REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
    resData = await autoComplete('users', data);

    if (resData.meta.status === 200) {

      this.setState({ isPresaleLoading: false });
      this.setState({ presaleUser: resData.data })
    } else if (resData.meta.status === 401) {

      cleanStorage();
      this.props.history.push("/login");

    } else {

      this.setState({ isPresaleLoading: false });
      this.setState({ presaleUser: [] })
    }
  }

  render() {
    const { is_magnet,is_presale , passback , document_status, autoKey, presaleUser, selectedPresaleRm, is_crosssale , under_cancellation} = this.state;
    return (
      <div
        className={`filterMenu ${this.props.show ? `filterMenuShow` : ``}`}
        style={{ zIndex: 9 }}
      >
        <div className="container filter_lead filterMenuCard pt-3 p-0">
          <div className="row filterRow">
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <TextField
                  value={this.state.leadId}
                  label="Lead ID"
                  onChange={e => this.setState({ leadId: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <TextField
                  value={this.state.clientNumber}
                  label="Client Number"
                  onChange={e =>
                    this.setState({ clientNumber: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row filterRow">
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={option => option.name}
                  options={this.state.projects}
                  loading={this.state.isLoading}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(e, option) => {
                    var projectIds = option.map(ops => ops.id);
                    this.setState({
                      selectedProjects: projectIds
                    });

                    if (option === null) {
                      this.setState({ projects: [] });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Project Name"
                      fullWidth
                      onChange={this.filterProjects}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                  key={autoKey}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={option => option.name}
                  options={this.state.regions}
                  loading={this.state.isLoading}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(e, option) => {
                    this.setState({
                      selectedRegion: option ? option.id : ""
                    });

                    if (option === null) {
                      this.setState({ regions: this.state.allRegions });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Region"
                      fullWidth
                      onChange={this.filterRegions}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                  key={autoKey}
                />
              </div>
            </div>
          </div>
          <div className="row filterRow">
          <div className="col-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  key={autoKey}
                  getOptionSelected={(presaleUser, value) => presaleUser.name === value.name}
                  getOptionLabel={presaleUser => presaleUser.name + " (" + presaleUser.phone + ")"}
                  options={presaleUser}
                  value={selectedPresaleRm}
                  renderTags={(value, getTagProps) =>
                    value.map((presaleUser, index) => (
                      <Chip label={presaleUser.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(_event, option) => {
                    var id = option.map((ops) => ops.id);
                    this.setState({
                      presale_rm_id: id,
                      selectedPresaleRm: option
                    })

                    if (option === null) {
                      this.setState({ presaleUser: [] })
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Presale RM"
                      fullWidth
                      onChange={this.handleAutoPresaleChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.isPresaleLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  getOptionSelected={(option, value) => {
                    return option.sales_rm === value.sales_rm;
                  }}
                  getOptionLabel={option =>
                    `${option["SalesRMBooking.first_name"]} ${option["SalesRMBooking.last_name"]}`
                  }
                  options={this.state.salesRmMembers}
                  loading={this.state.isLoading}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={`${option["SalesRMBooking.first_name"]} ${option["SalesRMBooking.last_name"]}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(e, option) => {
                    var salesRms = option.map(ops => ops.sales_rm);
                    this.setState({
                      selectedSalesRms: salesRms
                    });

                    if (option === null) {
                      this.setState({ salesRmMembers: [] });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Sales RM"
                      fullWidth
                      onChange={this.filterSalesRm}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {window.$user?.group != 'sales' && <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  getOptionSelected={(option, value) => {
                    return option.parent_rm === value.parent_rm;
                  }}
                  getOptionLabel={option =>
                    `${option["ParentRMBooking.first_name"]} ${option["ParentRMBooking.last_name"]}`
                  }
                  options={this.state.parentRmMembers}
                  loading={this.state.isLoading}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={`${option["ParentRMBooking.first_name"]} ${option["ParentRMBooking.last_name"]}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(e, option) => {
                    var parentRms = option.map(ops => ops.parent_rm);
                    this.setState({
                      selectedParentRms: parentRms
                    });

                    if (option === null) {
                      this.setState({ parentRmMembers: [] });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="TL Name"
                      fullWidth
                      onChange={this.filterParentRm}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </div>
            </div> }
            <div className="col-6">
              <SelectOption
                variant="standard"
                label="Cross Sale Lead"
                name="is_crosssale"
                value={is_crosssale}
                options={yesNoType}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row filterRow">
            <div className="col-md-12 text-center pb-2">Booking Date</div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd-MM-yyyy"
                    margin="normal"
                    value={this.state.fromDate}
                    autoOk={true}
                    onChange={date => {
                      this.setState({ fromDate: date });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd-MM-yyyy"
                    margin="normal"
                    value={this.state.toDate}
                    autoOk={true}
                    onChange={date => {
                      this.setState({ toDate: date });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Magnet Lead</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={is_magnet}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'is_magnet',
                      id: 'is_magnet_status',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          {window.$user?.group != 'sales' &&  <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Presale Lead</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={is_presale}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'is_presale',
                      id: 'is_presale_status',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div> }
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Passback</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={passback}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'passback',
                      id: 'passback_status',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Document Verification Status</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={document_status}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'document_status',
                      id: 'document_status',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">Approved</MenuItem>
                    <MenuItem value="0">Pending</MenuItem>
                    <MenuItem value="2">Reject</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="row filterRow">
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={option => option.name}
                  options={this.state.projects}
                  loading={this.state.isLoading}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  onChange={(e, option) => {
                    var projectIds = option.map(ops => ops.id);
                    this.setState({
                      selectedBookingProjects: projectIds
                    });

                    if (option === null) {
                      this.setState({ projects: [] });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Booking Project"
                      fullWidth
                      onChange={this.filterProjects}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                  key={autoKey}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="form-group">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">Under Cancellation </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    value={under_cancellation}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'under_cancellation',
                      id: 'under_cancellation',
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="-1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 bgCommon">
          <div className="row">
            <div className="col-sm-6 text-right col-6 mt-3">
              <div className="form-group">
                <button
                  className="btn btn-success"
                  onClick={e => this.onFilter()}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="col-sm-6 col-6 mt-3">
              <div className="form-group">
                <button
                  className="btn btn-danger"
                  onClick={e => this.onFilterReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
