import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import Frag from "../../../utils/Frag/Frag.js";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import Button from '@material-ui/core/Button';
import { getUpdatePwdRes } from "../../../dataParser/auth";
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { passwordCheck, empty, cleanStorage } from "../../../helper";
import Loader from "../../../component/common/loader/loader";

class ChangeUserPass extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentpassword: "",
			showLoader: false,
			error: "",
			meta: "",
			password: "",
			confirmpassword: "",
			sweetShow: false,
			type: "success",
			title: "",
			isError: {
				confirmpassword: '',
				password: '',
			}
		};
	}

	onChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		let isError = { ...this.state.isError };

		switch (name) {
			case "confirmpassword":
				isError.password =
					passwordCheck(value) ? "" : "Please include number & special characters and aleast 6 characters";
				break;
			case "password":
				isError.password =
					passwordCheck(value) ? "" : "Please include number & special characters and aleast 6 characters";
				break;
			default:
				break;
		}

		this.setState({
			isError,
			[name]: (value)
		})
	};

	formValid = ({ isError, ...rest }) => {
		let isValid = false;

		if ((rest.password !== rest.confirmpassword) || empty(rest.confirmpassword) || empty(rest.password)) {
			return false;
		}

		Object.values(isError).forEach(val => {
			if (val.length > 0) {
				return false;
			} else {
				isValid = true
			}
		});

		return isValid;
	};

	getChangeRes = async (e) => {
		e.preventDefault();
		// console.log(this.formValid(this.state))
		if (this.formValid(this.state)) {

			let changePwdRequest = (({ currentpassword, password, confirmpassword }) => ({
				currentpassword,
				password,
				confirmpassword
			}))(this.state);

			this.setState({
				showLoader: true,
			})

			let updatePass = {};
			updatePass.data = {};
			updatePass.data["old_password"] = changePwdRequest.currentpassword
			updatePass.data["new_password"] = changePwdRequest.password
			updatePass.id = window.$user.id;
			// console.log("addLeadRes XXXXXXXXXXXX",updatePass);
			// let data = JSON.stringify(updatePass);
			var addLeadRes = await getUpdatePwdRes(updatePass);

			if (addLeadRes.meta.status === 503) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "!!! Oooops Network Error..."
				});

			} else if (addLeadRes.meta.status === 201) {

				// console.log("User Register successfully !!!");
				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Updated successfully"
				});
			} else if (addLeadRes.meta.status === 404 || addLeadRes.meta.status === 400) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "warning",
					title: addLeadRes.meta.message
				});

			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});
				console.log(addLeadRes.meta.message);
			}
		} else {
			this.setState({
				showLoader: false,
				error: "*please enter same password as above !!!",
			})
		}
	};

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
	}

	render() {

		const { error, sweetShow, type, title } = this.state;

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<Loader show={this.state.showLoader} />
					<form role="form">
						<div className="card-body">
							<form>
								<div className="col-md-10 mb-3">
									<div className="form-group">
										<TextField
											required
											id="currentpassword"
											name="currentpassword"
											label="Old Password"
											type="password"
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
								<div className="col-md-10 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.password !== '' ? 'error' : ''}
											required
											id="password"
											name="password"
											type="password"
											label="New Password"
											helperText={this.state.isError.password !== '' ? this.state.isError.password : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
								<div className="col-md-10 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.isError.confirmpassword !== '' ? 'error' : ''}
											required
											id="confirmpassword"
											name="confirmpassword"
											type="password"
											label="Confirm Password"
											helperText={this.state.isError.confirmpassword !== '' ? this.state.isError.confirmpassword : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircleIcon />
													</InputAdornment>
												),
												autoComplete: 'off',
												autoSuggest: 'off',
											}}
										/>
									</div>
								</div>
								<div className="col-md-10">
									<div className="row">
										<div className="col-12">
											<Button variant="contained" className="text-capitalize" color="primary" onClick={this.getChangeRes}>Change Password</Button>
											<p className="mb-0">
												<span className="text-center text-danger small">{error != '' ? error : ''}</span>
											</p>
										</div>
									</div>
								</div>
							</form>
						</div>
					</form>
				</div>
			</Frag>
		);

	};
};

export default withRouter(ChangeUserPass);