import React, { Component } from "react";
import { getAllProjectData } from "../../dataParser/getProjectData";
import ProjectDetail from "../../component/project/projetDetail";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";

class projectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetail: "",
      showLoader: false
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    var projectData = {};
    projectData.size = 8;
    projectData.pageId = 1;
    if(window.$role === 'magnet'){
      projectData.is_active=1
    }

    (async () => {
      try {
        let resData = await getAllProjectData(projectData);
        await this.setState({
          projectDetail: resData,
          showLoader: false
        });
        window.scrollTo(0, 0);

      } catch (e) {
        //...handle the error...
      }
    })();

  }

  render() {
    return (
      <Frag>
        <Loader show={this.state.showLoader} />
        {this.state.projectDetail.meta && (
          <ProjectDetail
            expand={this.props.sideBar}
            projectDataValue={this.state.projectDetail}
          />
        )}
      </Frag>
    );
  }
}

export default projectList;
