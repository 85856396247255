import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Tooltip } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Loader from '../common/loader/loader';
import Frag from '../../utils/Frag/Frag';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddLoanForm from './AddLoanForm';
import { getHomeLoan } from '../../dataParser/getHomeLoanData';
import { changeDate, cleanStorage } from '../../helper';
import { REVERT_ROLES } from '../../config/constant';
const HomeLoanList = (props) => {
    const [state, setState] = useState({
        showLoader: false,
        showModal: false,
    })
    const { showLoader, showModal } = state;
    const StyledTableCell = withStyles(theme => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        body: {
            fontSize: 14
        }
    }))(TableCell);

    useEffect(() => {
        genericGetListData();
    }, [])

    const genericGetListData = async (params) => {
        setState({ showLoader: true, });

        // console.log("state.filters",state.filters);

        (async () => {
            try {
                let resData = await getHomeLoan(params);
                console.log(resData,">>>>>>>>>>>>>>")
                if (resData.meta.status === 200) {

                    await setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false,
                    });
                    window.scrollTo(0, 0);

                } else if (resData.meta.status == 401) {
                    cleanStorage();
                    props.history.push("/login");
                } else {
                    setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();

    }
    const handleAddModal = () => {
        setState({
            ...state,
            showModal: !showModal
        })
    }

    return (
        <Frag>
            <Loader show={showLoader} />
            <div className="p-3 leadMainHeader">
                <div className="headerCount">
                    <span className="totalCount">Total Requests : </span>
                    <span>{state?.data?.count}</span>
                </div>
                <div className="ml-3 cursor-pointer" onClick={handleAddModal}>
                    <Tooltip title="Add Home Loan Request">
                        <AddCircleIcon />
                    </Tooltip>
                </div>
            </div>
            <div className="listContainer mt-4">
                <div className="container-fluid">
                    <div className="col-md-12">
                       { state?.data?.homeLoans.length > 0 ?
                       (<TableContainer component={Paper}>
                            <Table
                                stickyHeader
                                aria-label="sticky table"
                                className="tableCustom"
                                style={{ minWidth: "650px" }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Name</StyledTableCell>
                                        <StyledTableCell align="left">Number</StyledTableCell>
                                        <StyledTableCell align="left">Added By</StyledTableCell>
                                        <StyledTableCell align='left'>Service Rm</StyledTableCell>
                                        <StyledTableCell align="left">Request Date</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state?.data?.homeLoans?.map((item, i) => (
                                        <TableRow >
                                            <TableCell align="left" className="text-capitalize">{item?.name || "-"}</TableCell>
                                            <TableCell align="left">{item?.number || "-"}</TableCell>
                                            <TableCell align="left" className="text-capitalize">{item?.User?.name || "-"}</TableCell>
                                            <TableCell align='left' className="text-capitalize">{item?.User?.role_id == REVERT_ROLES.MAGNET? item?.User?.UserInfo?.UserServiceRm?.name: '--'}</TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                {changeDate(item?.created_at) || "-"}
                                            </TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>)
                        :
                        <h3>No Data Found !!</h3>
                   }
                    </div>
                </div>
            </div>
            <AddLoanForm show={showModal} changeModal={handleAddModal} getList={genericGetListData}/>
        </Frag>
    )
}

export default HomeLoanList
