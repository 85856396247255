import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { viewAllUsers } from '../../../dataParser/getListUserData';
import Loader from '../loader/loader';
import { GROUPS, ROLES } from '../../../config/constant';
import { Redirect } from "react-router-dom";
import PopUpAlert from '../sweetAlert/popUpAlert';
import queryString from "query-string";

const ViewCRM = (props) => {

    const { userId, location } = props;

    const [showLoader, setLoader] = React.useState(false)

    const [state, setState] = React.useState({
        loggedIn: false,
        logData: "",
    })

    const [alertState, setAlertState] = React.useState({
        alertShow: false,
        alertMsg: ""
    })

    React.useEffect(() => {
        let queryParams = queryString.parse(location.search);
        if (queryParams.redirect_url !== undefined && queryParams.redirect_url !== "") setState({ ...state, redirect_url: queryParams.redirect_url });
    }, [])

    const handleViewCRM = async (id) => {
        setLoader(true);
        const resData = await viewAllUsers(id);

        if (resData.meta.status === 200) {
            resData.data.userData.role = ROLES[resData.data.userData.role]
                .split("_")
                .join("")
                .toLowerCase();
            resData.data.userData.group = GROUPS[
                resData.data.userData.group
            ].toLowerCase();
            let time = new Date();
            resData.data.userData.expire = time.setMinutes(time.getMinutes() + 60);

            window.$user = resData.data.userData;
            window.$role = window.$user.role.toLowerCase();
            localStorage.setItem(
                "homesfy_lg",
                window.btoa(JSON.stringify(resData.data.userData))
            );
            setState({
                ...state,
                loggedIn: true,
                logData: resData.data
            });
            setLoader(false);
            window.location.reload();
        }
        else {
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: resData?.meta?.message
            })
            setLoader(false)
        }
    }


    if (state.logData?.userData?.role) {
        var role = state.logData?.userData?.role.toLowerCase();
    }
    if (state.loggedIn) {
        if (state.redirect_url) {
            return <Redirect to={{ pathname: state.redirect_url }} />;
        } else {
            if (role === "admin" || role === "magnetplatinum") {
                return <Redirect to="/leads/new" />;
            } else if (role === "hrhead") {
                return <Redirect to="/users" />;
            }else if (role === "financehead" || role === "financeanalyst" || role === "financeassociate" || role === "operationshead") {
                return <Redirect to="/leads/booked" />;
            } else if (role === "operationsanalyst") {
                return <Redirect to="/leads/audit" />;
            } else {
                return <Redirect to="/" />;
            }
        }
    }

    const { alertShow, alertType, alertMsg } = alertState;

    const handleAlertClose = () => {
        setAlertState({
            alertShow: false
        })
    }

    return (
        <>
            <Loader show={showLoader} />
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
            <a
                className="ProjectDetail Action"
                data-toggle="tooltip"
                data-placement="bottom"
                title="View CRM"
                onClick={e => handleViewCRM(userId)}
            >
                <FontAwesomeIcon
                    icon={faEye}
                    className="text-white"
                />
            </a>
        </>
    )
}

export default ViewCRM