import React from 'react'

export const DetailView = ({title, value, capitalize}) => {
	return(
		<div className="col-md-3 mb-1">
			<div className="row">
			  <div className="col-md-12 detailProject">
			    <span className="font-small text-muted  text-capitalize">{title}: </span>
			    <span className={"valueText " + (capitalize ? "" : "text-capitalize")}>{value}</span>
			  </div>
			</div>
		</div>
	)
}
