import React, {Component} from 'react'
import FormControl from '@material-ui/core/FormControl';
import {Autocomplete as MuiAutocomplete} from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

class Autocomplete extends Component{
	constructor(props){
		super(props)
		this.state = {
			option_id: '',
			option: this.props.options || [],
			selectedVal: this.props.selectedVal || {},
		}
	}

    handleChange = async (_event, option) => {
		if (option === null) {
			this.setState({ option: [] })
		} else {
			this.setState({
				option_id: option.id,
			})
		}
		this.props.onChange(option)
	}
	render(){
		const {label, options, value, handleAutoChange, variant} = this.props
		return(
			<FormControl variant={variant ? variant : "outlined"}>
				<MuiAutocomplete
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={option => option.name}
					options={options}
					value={value}
					onChange={this.handleChange}
					renderInput={params => (
						<TextField
							{...params}
							variant={variant ? variant : "outlined"}						
							label={label}
							fullWidth
							onChange={handleAutoChange}
						/>
					)}
				/>
			</FormControl>
		)
	}
}
export default Autocomplete