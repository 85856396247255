import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

import { Formik, Form } from 'formik';
import moment from 'moment';

import api, {engage_api} from '../../api';
import constants, { REVERT_GROUPS, REVERT_ROLES_ID } from '../../config/constant/index';

import Box from '@material-ui/core/Box';
import Button from '../../core/Button';
import Grid from '../../core/Grid';
import {UserPortContext} from '../../core/Providers';

import ActivityBlock from '../../core/ActivityBlock';
import CallRecordBlock from '../../core/CallRecordBlock';
import TextField, {FormikTextField} from '../../core/TextField';
import Typography from '../../core/Typography';
import Loader from "../../component/common/loader/loader";
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import { dateTimeConverter, getStartDateMax } from '../../helper';

const defaultState = {
    activeSection: 'Profile'
    ,activities: []
	, loader: true
}


let _a_opts = {};

for (let s in constants.LEAD_INTERNAL_STATUS){
	_a_opts[constants.LEAD_INTERNAL_STATUS[s]] = s
}

const activity_options = {..._a_opts, 9: "Follow Up" }

// let f = {
// 	"hpop_arranged": "HPOP Arranged"
// 	, "hpop_done": "HPOP Done"
// 	, "visit_arranged": "Visit Arranged"
// 	, "visit_done": "Visit Done"
// 	, "revisit_arranged": "Revisit Arranged"
// 	, "revisit_done": "Revisit Done"
// 	, "video_call_arranged": "Video Call Arranged"
// 	, "video_call_done": "Video Call Done"
// 	, "followup": "Follow Up"
// }

const companions = {
    "": "Select Companion"
    , "alone" : "Alone"
	, "group": "Group"
	, "family": "Family"
}

const defaultStateForCreateActivity = {
        option: ''
        , visited_project: {}
        , activity_done_by: {}
        , activity_date: new Date()
        , followup_date: new Date()
		, companion: ''
}

const createActivityInitalValues = {
    option: defaultStateForCreateActivity.option
    , companion: ""
    , activity_date: new Date()
    , followup_date: null
    , visited_project: {}
    , activity_done_by: {}
	, activity_done_by_presale_rm: {}
    , comment: ""
}

const defaultPopState = {
    type: 'success',
    open: false,
    msg: ''
}
const CreateActivity = ({lead, profile, onClose, onCreate}) => {

	const [loader, setLoader] = React.useState(false);
	const [state, setState] = React.useState({...defaultPopState});

    return <>
        <Formik enableReinitialize initialValues={{...createActivityInitalValues}} 
            validate= {values => {
				let errors = {}
                
				if (!values.option || !values.activity_date || !values.followup_date){
					errors['message']= "Please enter required fields";
				}

				return errors;

            }}
            onSubmit= {(values, formik) => {
				let payload = {};

				if(values.option == "9"){
					payload = {
						// Activity Date
						comment: values.comment.toString()
						// Follow Up Date
						, followup_date: dateTimeConverter(values.followup_date)
					}

					setLoader(true)
					api.put(`internal_status_history/leads/${lead.id}`, {
						...payload
					}).then(res => {
						setLoader(false)
						formik.resetForm({...createActivityInitalValues})
						onCreate();
						// handleBookingChange(values)
					}).catch(err => {
						setLoader(false)
						setState({
							...state,
							open: true,
							type: "error",
							msg: err.response?.data?.message ? err.response?.data?.message : "Failed",
						});
					})
				}else{
					payload = {
						// Activity Date
						action_date: moment(values.activity_date).format("YYYY/MM/DD HH:mm")
						, comment: values.comment.toString()
						// Companion
						, companion: values.companion.toString()
						// Follow Up Date
						, followup_date: dateTimeConverter(values.followup_date)
						// Option
						, internal_status_id: Number(values.option)
						// Activity Done By
						, lead_id: Number(lead.id)
						// Visited Project
						, project_id: Number(values.visited_project?.id)
						, user_id: Number(values?.activity_done_by?.id)
						, presale_id: Number(values.activity_done_by_presale_rm?.id)
						, is_magent: 0
					}
					setLoader(true)
					api.post(`internal_status_history`, {
						...payload
					}).then(res => {
						setLoader(false)
						formik.resetForm({...createActivityInitalValues})
						onCreate();
						// handleBookingChange(values)
					}).catch(err => {
						setLoader(false)
						setState({
							...state,
							open: true,
							type: "error",
							msg: err.response?.data?.message ? err.response?.data?.message : "Failed",
						});
					})
				}

            }}
		>
			{props => (<Form>
                <Typography variant="h5">Create Activity</Typography>
                <Grid container spacing={5}>
                    <Grid item sm={6} xs={12}>
                        <Box mt={3}>
                            <FormikTextField 
                                isEditable={true}
                                label="Select Option*"
                                name="option"
                                isDropDown
                                options={activity_options}/> 
                        </Box>
                        {props.values.option !== "9" && <Box mt={3}>
                            <FormikTextField
                                isEditable={true}
                                isAutocomplete
                                optionPresenter={ option => option.name ? `${option.name} (${option?.Region?.name})` : "" }
                                callBackUrl={query => `projects/auto_complete/?q=${query}`}
                                autoCompleteText={option => option?.name}
                                label="Visited Project"
                                name="visited_project" /> 
						</Box>}
                        {props.values.option !== "9" && <Box mt={3}> 
                            <FormikTextField
                                isEditable={true}
                                isAutocomplete
								optionPresenter={ option => option.name ? option.name : "" }
                                callBackUrl={query => `/users/auto_complete?q=${query}&roles=5,6,7,8,10,13,14,15,20,21`}
                                autoCompleteText={option => option?.name}
                                label="Activity Done By (Sale)"
                                name="activity_done_by" /> 
                        </Box>}
                        {props.values.option !== "9" && (['admin', 'presale'].indexOf(window.$user.group.toLowerCase()) != -1) && <Box mt={3}>
                            <FormikTextField
                                isEditable={true}
                                isAutocomplete
								optionPresenter={ option => option.name ? option.name : "" }
                                callBackUrl={query => `/users/auto_complete?q=${query}&roles=12,13,14,15`}
                                autoCompleteText={option => option?.name}
                                label="Activity Done By (PresSale RM)"
                                name="activity_done_by_presale_rm" /> 
                        </Box>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        {props.values.option !== "9" && <Box mt={3}>
                            <FormikTextField
                                isEditable={true}
                                isDateTime 
                                label="Activity Date*" 
                                name="activity_date" /> 
                        </Box>}
                        <Box mt={3}>
                            <FormikTextField 
                                isEditable={true}
                                label="Followup Date*" 
                                isFollowUp
                                minDate={moment()}
                                maxDate={getStartDateMax()}
                                name="followup_date" />
                        </Box>
						{_a_opts[props.values.option]?.split(' ').pop() === "Done" && <Box mt={3}> 
                            <FormikTextField 
                                isEditable={true}
                                label="Companion" 
                                name="companion" 
                                isDropDown 
                                options={companions}/> 
                        </Box>}
                    </Grid>

                    <Grid item sm={12}>
                        <Box mt={0}>
                            <FormikTextField 
                                isEditable={true}
                                multiline
                                fullWidth
                                variant="outlined"
                                rows={4}
                                label="Comment" 
                                name="comment" /> 
                        </Box>

                    </Grid>
                </Grid>
                <Box align="center" mt={6}>
                    <Button variant="contained" color="primary" type="submit">Save</Button>
                    <Button variant="contained" color="primary" style={{marginLeft: 10}} onClick={onClose}>Close</Button>
                </Box>
				{Boolean(props.errors.message) === true && <Box>
					<p className="mb-0"><span className="text-center text-danger small">*{props.errors.message}</span></p>
				</Box>}
            </Form>)}
        </Formik>
		<PopUpAlert type={state.type} msg={state.msg} open={state.open} handleClose={() => setState({...state, open:false })} hideTime={6000} />
		<Loader show={loader} />
    </>
}


const CallRecordList = ({callRecords = []}) => {
    return <Box>
        {callRecords.map( (record) => {
			if (record.recording_url === "None") { return <React.Fragment key={record.id}></React.Fragment> }
            return <Box position="relative" mt={4} key={record.id} className='CallRecordItem'>
                <CallRecordBlock
                    agent_name={record?.agentUserInfo?.name}
                    date={moment(`${record.call_date} ${record.call_time}`).format('ddd, MMM DD YYYY h:mm A')}
                    lead_status={record.CallLog?.stage_id}
                    recording_url={(record.recording_url && record.recording_url !== "None") ? record.recording_url: ""}
                    duration={record.conversation_duration}
                />
            </Box>
        } )}
    </Box>
}

const ActivityPage = ({lead, profile}) => {

    const matches = React.useContext(UserPortContext)
    const [state, setState] = React.useState({...defaultState,callRecords: [], lead:lead, profile:profile, view: "listing"});
    const [callRecordOpen, setCallRecordOpen] = React.useState(false);
    
    const [alertState, setAlertState] = React.useState({
        alertShow: false,
        alertType: "warning",
        alertMsg: ""
    });
    const {alertShow, alertType, alertMsg} = alertState;

    React.useEffect(() => {
		if (!lead.id) {return}
        //api.get(`leads/${state.lead.id}/internal_status_history`).then(res => {
        api.get(`updateLogs/${lead.id}`).then(res => {
			let _state =  {...state, activities:res.data, loader: false}
            //axios.get(`http://staging-engage.homesfy.in/api/callDetail?object_id=${state.lead.id}&size=4&pageId=1`).then(res => {
            
            let user_id = window.$user.id;
            let role_id = REVERT_ROLES_ID[window.$user.role];
            let group_id = REVERT_GROUPS[window.$user.group.toUpperCase()];	
            engage_api.get(`callDetail?object_id=${lead.id}&size=100&pageId=1&user_id=${user_id}&role_id=${role_id}&group_id=${group_id}`).then(res => {
                    if (res.data.rows){
                    setState({..._state, callRecords: res.data.rows})
                }else{
                    setState({..._state})
                }
            })
        })

		refreshActivity();
        {/*http://staging-engage.homesfy.in/api/callDetail?object_id=659349&size=4&pageId=1*/} 
    }, [lead])


    React.useEffect(() => {
        setState({...state, lead, profile})
    }, [])


    {/*if(!state.activities) return <></>*/}
    const onCloseActivity = () => {
        setState({...state, view: 'listing'})
    }

    const refreshActivity = () => {
        api.get(`updateLogs/${lead.id}`).then(res => {
            setState({...state, activities:res.data})
        })
    }
    const handleCallRecord = () =>{
        let recordingURL = state.callRecords.map(item=>item.recording_url)
        let checkNone = recordingURL.every( (val, i, arr) => val === arr[0] )
        if(state.callRecords.length === 0 || checkNone){
            setAlertState({
                alertShow: true,
                alertType: "warning",
                alertMsg: "Oops! Call Records Not Found"
            })
        }
        else(
            setCallRecordOpen(true)
        )
    }
    return <Box className="ActivityPage" mt={-2}>
		<PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={() => setAlertState({...alertState, alertShow:false })} />
        <Grid container spacing={4}>
            <Grid item sm={8} xs={12}>
                {/*<Box display="flex" justifyContent="flex-end">
                    <NavigationButtonHeaderGroup />
                </Box>*/}
                <Box display="flex" mb={4} className="action-buttons">
                    <Box mr={2}>
                        {window.$user.role !== "magnet" && 
                            <Button variant="contained" color="primary" size="large" onClick={() => {setState({...state, view: "create-activity"})}}>Create Activity</Button>
                        }
                    </Box>
                    <Box>
                        {matches === "xs" && 
                        <Button variant="contained" color="primary" size="large" onClick={handleCallRecord}>Call Records</Button>
                        } 
                    </Box>
                </Box>
                <Collapse timeout={300} in={state.view === "create-activity"}><Paper elevation={2} style={{marginBottom: 48}}>
                    <Box p={5} style={{backgroundColor: "#fff"}}>
                        <CreateActivity lead={lead} profile={profile} onClose={onCloseActivity} onCreate={refreshActivity}/>
                    </Box>
                </Paper></Collapse>
                {state.view =="create-followup" && <Box pb={6}>
                    <Box p={2} style={{backgroundColor: "#fff"}}>
                        <Typography variant="h5">Create Followup</Typography>
                        <Box>
                            <Box mt={2}> <TextField isEditable isDate label="Date" /> </Box>
                            <Box mt={2}> <TextField style={{maxWidth: "100%", width: "100%"}} isEditable isTextArea label="Comment" multiline
          rows={4} /> </Box>
                        </Box>
                        <Box align="center" mt={6}>
                            <Button variant="contained" color="primary">Create Followup</Button>
                            <Button variant="contained" color="primary" style={{marginLeft: 10}} onClick={() => {setState({...state, view: 'listing'})}}>Close</Button>
                        </Box>
                    </Box>
                </Box>}
                <Box pb={6}>
            
                    {state.activities.map((activity) => {
                        return <Box mb={4} position="relative" key={activity.id}>
                            <Fade in={true} timeout={1000}><ActivityBlock
                                author={activity?.User?.name}
                                date={moment(activity.created_at).format("ddd, MMM DD YYYY h:mm A")}
                                header={activity.action?.split('_').join(' ')}
                            >
								<Box>
									{activity.comment && <blockquote className="comment">
										{activity.comment}
									</blockquote>}
									{activity.activity_message && <div className="comment">
										{activity.activity_message}
									</div>}
								</Box>
                            </ActivityBlock></Fade>
                        </Box>

                    })}
                </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
                {matches !== "xs" && state.callRecords.length !== 0 && <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <Paper elevation={0} position="relative" style={{backgroundColor: 'transparent'}}>
                        <CallRecordList callRecords={state.callRecords} />
                    </Paper>
                </Slide>}
                {matches === "xs" && state.callRecords.length !== 0 &&
                <Dialog onClose={() => setCallRecordOpen(false) } open={callRecordOpen}>
                    <CallRecordList callRecords={state.callRecords} />
                </Dialog>}
            </Grid>
        </Grid>
		<Loader show={state.loader} />
    </Box>
}


export default ActivityPage;
