import React, { Component } from "react";
import * as _ from "lodash";
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import PopUpAlert from "../../../component/common/sweetAlert/popUpAlert";
import {
  getLeadSourceData,
  autoComplete,
  getSourceData
} from "../../../dataParser/commomDataApi";
import {
  getAddLeadData,
  checkDublicateLead
} from "../../../dataParser/getListData";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Loader from "../../../component/common/loader/loader";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import EmailIcon from "@material-ui/icons/Email";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import { BUDGET_OPTIONS, REVERT_ROLES,REFERAL_SOURCE_IDS, chat_option, NOTIFY_MSG, BED_CONFIG } from "../../../config/constant";
import { assignLeadRmPermission } from "../../../config/constant/crmRoleFunctionPermission.js";
import { cleanStorage, phoneCheck } from "../../../helper";
import countryCodes from "../../../config/constant/countryCodes";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import RadioOption from "../controls/RadioGroup.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import MagnetPlanCards from "../../Leads/magnetPlanCards.js";
import constants from "../../../config/constant/index.js";
import ButtonSubmit from "../controls/ButtonSubmit.js";

const validateName = RegExp(/^[a-zA-Z ]+$/);

const clubbedPlans = ["basic", "lite", "plus"];

export const letterCase = (str, lower = false) => {
  if (str !== "")
    return lower
      ? str.toLowerCase()
      : str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  // return false;
};

const GROUP_1 = ["magnetadmin", "magnetpnl", "magnettl", "magnetrm"];
const GROUP_2 = [ "admin", ...GROUP_1];
class addLeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min_budget: "",
      max_budget: "",
      action_date: null,
      comment: "",
      project_name: "",
      project_id: "",
      country_code: "+91",
      sweetShow: false,
      showLoader: false,
      type: "success",
      title: "",
      p_assignby: "",
      assign_status: "",
      nationality: 1,
      is_magnet: 0,
      admin_message: "",
      magnetName: [],
      isLoading: false,
      userName: [],
      assign_id: "",
      name: "",
      email: "",
      number: "",
      magnet_ids: "",
      source_id: "",
      source: [],
      project: [],
      selectedVal: {},
      showHideFileds: false,
      errors: {
        project_name: "",
        name: "",
        email: "",
        project_id: "",
        country_code: "",
        number: "",
        source_id: "",
        admin_message: ""
      },
      alertShow: false,
      alertType: "success",
      alertTitle: "",
      magnetSelectedPlan: {},
      possessionTimeline: "",
      required_bhk: "",
      referredBy: "",
      referalLeadId: ""
      , chat_source: ""
    };
  }

  componentDidMount() {
    this.setState({ role: window.$user.role.toLowerCase() });
    if (this.props.proc_id) {
      let selectedName = [
        { id: this.props.proc_id, name: this.props.proc_name }
      ];
      this.setState({
        addedBy: window.$user.id,
        project_name: this.props.proc_name,
        project_id: this.props.proc_id,
        project: selectedName[0].name !== undefined ? selectedName : [],
        selectedVal: selectedName[0].name !== undefined ? selectedName[0] : {},
        countryCodes: countryCodes
      });
    } else {
      this.setState({
        addedBy: window.$user.id
      });
    }

    (async () => {
      try {
        let allSourceData;
        if (["magnet", "magnetplatinum"].includes(window.$user?.role.toLowerCase())) {
          allSourceData = await getSourceData("magnet limited");
          let id = allSourceData.data.id;
          let name = allSourceData.data.name;

          await this.setState({
            source_id: allSourceData.data.id,
            source: [{ id, name }],
            is_magnet: 1
          });
        } else {
          allSourceData = await getLeadSourceData();
          const removed = ["facebook", "instagram", "fb-whatsapp"];
          let newSource = allSourceData.data.filter(
            item => !removed.includes(item.name.toLowerCase())
          );
          await this.setState({
            source: newSource,
            is_magnet: 0
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();

    this.setState({ magnetSelectedPlan: this.props.magnetSelectedPlan });
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.magnetSelectedPlan, this.props.magnetSelectedPlan)
    ) {
      this.setState({ magnetSelectedPlan: this.props.magnetSelectedPlan });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  formValChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };

     this.setState({
        isError,
    })
    if(typeof value !== 'referalLeadId'){
      this.setState({
        [name]: letterCase(value,true)
      })
    }else{
      this.setState({
        [name]: (value)
      })
    }	
  };

  handleNumberChange = async e => {
    let checkPhone = false;
    if (this.state.country_code == "+91") {
      checkPhone = phoneCheck(e.target.value);
      this.state.errors.number = checkPhone ? "" : "phone is invalid";
    } else if (this.state.country_code != "+91" && e.target.value.length > 6) {
      checkPhone = true;
    }
    let value = e.target.value.replace(/\D/g, "");
    this.setState({ number: value, showLoader: true });
    if (checkPhone && this.state.project_id) {
      await this.checkDublicateLead(value);
    } else {
      this.setState({ showLoader: false });
    }
  };

  addNewLead = async e => {
    e.preventDefault();

    const addLeadRequest = (({
      country_code,
      magnet_ids,
      is_magnet,
      addedBy,
      p_assignby,
      name,
      email,
      project_id,
      number,
      source_id,
      admin_message,
      nationality,
      assign_status,
      assign_id,
      referalLeadId
    }) => ({
      name,
      number,
      email,
      addedBy,
      source_id,
      country_code,
      project_id,
      admin_message,
      nationality,
      assign_status,
      p_assignby,
      assign_id,
      magnet_ids,
      is_magnet,
      referalLeadId
    }))(this.state);

    let mainData = {};

    if (addLeadRequest.admin_message !== "") {
      mainData["admin_message"] = addLeadRequest.admin_message;
    }

    mainData["is_magnet"] = parseInt(addLeadRequest.is_magnet);
    if (
      window.$role === "magnet" ||
      window.$role === "magnettl" ||
      window.$role === "magnetrm"
    ) {
      mainData["is_magnet"] = 1;
    }

    if (addLeadRequest.assign_id !== "") {
      mainData["assign_to"] = addLeadRequest.assign_id;
    }

    if (window.$role !== "magnet") {
      if (addLeadRequest.magnet_ids !== "") {
        mainData["magnet_id"] = addLeadRequest.magnet_ids;
      }
    } else {
      mainData["magnet_id"] = addLeadRequest.addedBy;
    }

    if (addLeadRequest.name !== "") {
      mainData["name"] = addLeadRequest.name;
    }

    if (addLeadRequest.number !== "") {
      mainData["number"] = addLeadRequest.number;
    }

    if (addLeadRequest.email !== "") {
      mainData["email"] = addLeadRequest.email;
    }

    if (addLeadRequest.country_code !== "") {
      mainData["country_code"] = (addLeadRequest.country_code);
    }

    if (addLeadRequest.addedBy !== "") {
      mainData["created_by"] = addLeadRequest.addedBy;
    }

    if (addLeadRequest.source_id !== "") {
      mainData["source_id"] = addLeadRequest.source_id;
    }
    if (addLeadRequest.referalLeadId !== "") {
      mainData["referal_lead_id"] = addLeadRequest.referalLeadId;
    }
    if (addLeadRequest.project_id !== "") {
      mainData["project_id"] = addLeadRequest.project_id;
    }

    if (addLeadRequest.nationality !== "") {
      mainData["nationality"] = parseInt(addLeadRequest.nationality);
    } else if (parseInt(addLeadRequest.nationality) === 2) {
      mainData["nationality"] = 2;
    }

    if (
      addLeadRequest.country_code !== "+91" ||
      parseInt(addLeadRequest.country_code) !== 91
    ) {
      mainData["nationality"] = 2;
    }
    mainData.more_fields = {};
    let moreFieldsValid = true;
    if (this.state.magnetSelectedPlan.key === "basic") {
      if (
        this.state.min_budget !== "" &&
        this.state.max_budget !== "" &&
        this.state.possessionTimeline !== "" &&
        this.state.required_bhk !== ""
      ) {
        mainData.more_fields = {
          percentage: this.state.magnetSelectedPlan.percentage,
          ClientBudget: {
            min_budget: this.state.min_budget,
            max_budget: this.state.max_budget,
            referred_by: this.state.referredBy,
            possession_timeline: this.state.possessionTimeline,
            required_bhk: Number(this.state.required_bhk)
          }
        };
      } else {
        moreFieldsValid = false;
      }
    } else if (this.state.magnetSelectedPlan.key === "plus") {
      if (
        this.state.min_budget !== "" &&
        this.state.max_budget !== "" &&
        this.state.action_date &&
        this.state.comment !== "" &&
        this.state.possessionTimeline !== "" &&
        this.state.required_bhk !== ""
      ) {
        mainData.more_fields = {
          percentage: this.state.magnetSelectedPlan.percentage,
          ClientBudget: {
            min_budget: this.state.min_budget,
            max_budget: this.state.max_budget,
            referred_by: this.state.referredBy,
            possession_timeline: this.state.possessionTimeline,
            required_bhk: Number(this.state.required_bhk)
          },
          ActivityInfo: {
            action_date: this.state.action_date,
            comment: this.state.comment,
            internal_status_id: 3,
            project_id: addLeadRequest.project_id,
            created_by: window.$user.id
          }
        };
      } else {
        moreFieldsValid = false;
      }
    }
    if (this.state.magnetSelectedPlan.key === "lite") {
      mainData.more_fields = {percentage: this.state.magnetSelectedPlan.percentage};
    }
    if (this.state.chat_source) {
      mainData.more_fields.chat_source = this.state.chat_source;
    }
    const {referalLeadId , source_id } = addLeadRequest;
    if (
      addLeadRequest.project_name !== "" &&
      validateName.test(addLeadRequest.project_name) &&
      validateName.test(addLeadRequest.name) &&
      addLeadRequest.name !== "" &&
      addLeadRequest.project_id !== "" &&
      addLeadRequest.number !== "" &&
      addLeadRequest.source_id !== "" &&
      (REFERAL_SOURCE_IDS.includes(source_id) ? referalLeadId !== "" : true) &&
      (GROUP_2.includes(window.$role) 
      ? (this.state.is_magnet == 1 ||
        GROUP_1.includes(window.$role) 
        ? addLeadRequest.magnet_ids !== "" 
        : true ) 
      : true ) &&
      moreFieldsValid
    ) {
      this.setState({
        showLoader: true
      });

      var addLeadRes = await getAddLeadData(mainData);

      if (addLeadRes.meta.status === 201) {
        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "success",
          title:
            (this.state.magnetSelectedPlan.key === "pro"
              ? "Lead has been added successfully. The lead will be assigned to you and NOT to Homesfy Sales RMs"
              : clubbedPlans.includes(this.state.magnetSelectedPlan.key)
                ? `Lead has been added successfully. The split percentage is ${this.state.magnetSelectedPlan.percentage}%.`
                : "Lead Added Successfully!!!") + NOTIFY_MSG
        });
      } else if (addLeadRes.meta.status === 409) {
        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "Warning",
          title:
            "Lead Already Present in our system " +
            "Lead owner is" +
            addLeadRes.data.AssignLead.Assignee.name +
            " and this Lead in " +
            addLeadRes.data.ObjectStage.Stage.name +
            " stage."
        });
      } else if (addLeadRes.meta.status === 401) {
        cleanStorage();
        this.props.history.push("/login");
      } else {
        this.setState({
          showLoader: false,
          sweetShow: true,
          type: "error",
          title: addLeadRes.meta.message
        });
      }
    } else {
      if (this.state.name !== "" && validateName.test(this.state.name)) {
        this.setState({
          alertShow: true,
          alertType: "error",
          alertTitle: "Above all * fields are reqiured !!!"
        });
      } else {
        this.setState({
          alertShow: true,
          alertType: "error",
          alertTitle: "*Please enter valid client name"
        });
      }
    }
  };

  handleAutoMagnetChange = async e => {
    this.setState({ isMagnetLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET]
    };
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ isMagnetLoading: false });
      this.setState({ magnetName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isMagnetLoading: false });
      this.setState({ magnetName: resData.data });
    }
  };

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    if (this.props.callFrom !== "dashboard" && this.props.getList) {
      this.props.getList();
    }
    this.props.changeModal();
  };

  handleChange = event => {
    this.setState({ assign_status: !this.state.assign_status });
  };

  AutoChangeProject = async e => {
    this.setState({ isLoading: true });

    (async () => {
      try {
        if (window.$user?.role.toLowerCase() === "magnet") {
          let data = {
            q: e.target.value,
            is_masks: 1
          };
          var resData = await autoComplete('projects', data);
          this.setState({
            isLoading: false,
            project: resData.data
          });
        } else {
          let data = {
            q: e.target.value
          };
          var resData = await autoComplete("projects", data);
          await this.setState({
            isLoading: false,
            project: resData.data
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  AutoUserChangeUser = async e => {
    this.setState({ isLoading: true });

    (async () => {
      try {
        let data = {};
        let resData = {};

        data.q = e.target.value;
        data.users_active = 1;
        data.roles = [
          REVERT_ROLES.SALES_TL,
          REVERT_ROLES.SENIOR_SALES_TL,
          REVERT_ROLES.CLOSING_S1,
          REVERT_ROLES.PRESALES_TL,
          REVERT_ROLES.MAGNET_RM,
          REVERT_ROLES.SALES_MANAGER,
          REVERT_ROLES.SOURCING_S2,
          REVERT_ROLES.SOURCING_S3,
          REVERT_ROLES.MAGNET_TL,
          REVERT_ROLES.PNL_HEAD,
          REVERT_ROLES.SALES_HEAD,
          REVERT_ROLES.MAGNET_S2,
          REVERT_ROLES.MAGNET_S3,
        ];
        resData = await autoComplete("users", data);

        await this.setState({
          userName: resData.data,
          isLoading: false
        });
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  showHideLead = () => {
    this.setState({ showHideFileds: true });
  };

  handleClose = () => {
    this.setState({ alertShow: false });
  };

  checkDublicateLead = async number => {
    if (this.state.project_id && number) {
      let data = { number: number, project_id: this.state.project_id };
      let duplicatLeadRes = await checkDublicateLead(data);
      if (duplicatLeadRes.meta.status === 409) {
        this.setState({
          showLoader: false,
          alertShow: true,
          alertType: "warning",
          alertTitle: duplicatLeadRes.meta.message
        });
      } else {
        this.setState({ showLoader: false });
      }
    } else {
      this.setState({ showLoader: false });
    }
  };

  // addNewLead = async e => {
  //   e.preventDefault();

  //   const addLeadRequest = (({
  //     country_code,
  //     magnet_ids,
  //     is_magnet,
  //     addedBy,
  //     p_assignby,
  //     name,
  //     email,
  //     project_id,
  //     number,
  //     source_id,
  //     admin_message,
  //     nationality,
  //     assign_status,
  //     assign_id
  //   }) => ({
  //     name,
  //     number,
  //     email,
  //     addedBy,
  //     source_id,
  //     country_code,
  //     project_id,
  //     admin_message,
  //     nationality,
  //     assign_status,
  //     p_assignby,
  //     assign_id,
  //     magnet_ids,
  //     is_magnet
  //   }))(this.state);

  //   let mainData = {};

  //   if (addLeadRequest.admin_message !== "") {
  //     mainData["admin_message"] = addLeadRequest.admin_message;
  //   }

  //   mainData["is_magnet"] = parseInt(addLeadRequest.is_magnet);
  //   if (
  //     window.$role === "magnet" ||
  //     window.$role === "magnettl" ||
  //     window.$role === "magnetrm"
  //   ) {
  //     mainData["is_magnet"] = 1;
  //   }

  //   if (addLeadRequest.assign_id !== "") {
  //     mainData["assign_to"] = addLeadRequest.assign_id;
  //   }

  //   if (window.$role !== "magnet") {
  //     if (addLeadRequest.magnet_ids !== "") {
  //       mainData["magnet_id"] = addLeadRequest.magnet_ids;
  //     }
  //   } else {
  //     mainData["magnet_id"] = addLeadRequest.addedBy;
  //   }

  //   if (addLeadRequest.name !== "") {
  //     mainData["name"] = addLeadRequest.name;
  //   }

  //   if (addLeadRequest.number !== "") {
  //     mainData["number"] = addLeadRequest.number;
  //   }

  //   if (addLeadRequest.email !== "") {
  //     mainData["email"] = addLeadRequest.email;
  //   }

  //   if (addLeadRequest.country_code !== "") {
  //     mainData["country_code"] = addLeadRequest.country_code;
  //   }

  //   if (addLeadRequest.addedBy !== "") {
  //     mainData["created_by"] = addLeadRequest.addedBy;
  //   }

  //   if (addLeadRequest.source_id !== "") {
  //     mainData["source_id"] = addLeadRequest.source_id;
  //   }

  //   if (addLeadRequest.project_id !== "") {
  //     mainData["project_id"] = addLeadRequest.project_id;
  //   }

  //   if (addLeadRequest.nationality !== "") {
  //     mainData["nationality"] = parseInt(addLeadRequest.nationality);
  //   } else if (parseInt(addLeadRequest.nationality) === 2) {
  //     mainData["nationality"] = 2;
  //   }

  //   if (
  //     addLeadRequest.country_code !== "+91" ||
  //     parseInt(addLeadRequest.country_code) !== 91
  //   ) {
  //     mainData["nationality"] = 2;
  //   }

  //   if (
  //     addLeadRequest.project_name !== "" &&
  //     validateName.test(addLeadRequest.project_name) &&
  //     validateName.test(addLeadRequest.name) &&
  //     addLeadRequest.name !== "" &&
  //     addLeadRequest.project_id !== "" &&
  //     addLeadRequest.number !== "" &&
  //     addLeadRequest.source_id !== ""
  //   ) {
  //     this.setState({
  //       showLoader: true
  //     });

  //     var addLeadRes = await getAddLeadData(mainData);

  //     if (addLeadRes.meta.status === 201) {
  //       this.setState({
  //         showLoader: false,
  //         sweetShow: true,
  //         type: "success",
  //         title:
  //           this.state.magnetSelectedPlan.key === "pro"
  //             ? "Lead has been added successfully. The lead will be assigned to you and NOT to Homesfy Sales RMs"
  //             : clubbedPlans.includes(this.state.magnetSelectedPlan.key)
  //             ? `Lead has been added successfully. The split percentage is ${this.state.magnetSelectedPlan.percentage}%.`
  //             : "Lead Added Successfully!!!"
  //       });
  //     } else if (addLeadRes.meta.status === 409) {
  //       this.setState({
  //         showLoader: false,
  //         sweetShow: true,
  //         type: "Warning",
  //         title:
  //           "Lead Already Present in our system " +
  //           "Lead owner is" +
  //           addLeadRes.data.AssignLead.Assignee.name +
  //           " and this Lead in " +
  //           addLeadRes.data.ObjectStage.Stage.name +
  //           " stage."
  //       });
  //     } else if (addLeadRes.meta.status === 401) {
  //       cleanStorage();
  //       this.props.history.push("/login");
  //     } else {
  //       this.setState({
  //         showLoader: false,
  //         sweetShow: true,
  //         type: "error",
  //         title: addLeadRes.meta.message
  //       });
  //     }
  //   } else {
  //     if (this.state.name !== "" && validateName.test(this.state.name)) {
  //       this.setState({
  //         alertShow: true,
  //         alertType: "error",
  //         alertTitle: "Above all * fields are reqiured !!!"
  //       });
  //     } else {
  //       this.setState({
  //         alertShow: true,
  //         alertType: "error",
  //         alertTitle: "*Please enter valid client name"
  //       });
  //     }
  //   }
  // };

  handleAutoMagnetChange = async e => {
    this.setState({ isMagnetLoading: true });
    let data = {
      q: e.target.value,
      roles: [REVERT_ROLES.MAGNET]
    };
    let resData = await autoComplete("users", data);

    if (resData.meta.status === 200) {
      this.setState({ isMagnetLoading: false });
      this.setState({ magnetName: resData.data });
    } else if (resData.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({ isMagnetLoading: false });
      this.setState({ magnetName: resData.data });
    }
  };

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    if (this.props.callFrom !== "dashboard" && this.props.getList) {
      this.props.getList();
    }
    this.props.changeModal();
  };

  handleChange = event => {
    this.setState({ assign_status: !this.state.assign_status });
  };

  AutoChangeProject = async e => {
    this.setState({ isLoading: true });

    (async () => {
      try {
        if (window.$user?.role.toLowerCase() === "magnet") {
          let data = {
            q: e.target.value,
            is_masks: 1
          };
          var resData = await autoComplete('projects', data);
          this.setState({
            project: resData.data
          });
        } else {
          let data = {
            q: e.target.value
          };
          var resData = await autoComplete("projects", data);
          await this.setState({
            isLoading: false,
            project: resData.data
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  showHideLead = () => {
    this.setState({ showHideFileds: true });
  };
  handleClose = () => {
    this.setState({ alertShow: false });
  };

  onChange = e => {
    let changedState = { [e.target.name]: e.target.value };
    if (e.target.name === "budget") {
      changedState.min_budget = e.target.value.minValue;
      changedState.max_budget = e.target.value.maxValue;
    }

    this.setState(changedState);
  };

  handleDateChange = (key, date) => {
    this.setState({ [key]: date });
  };

  selectPlan = plan => {
    this.setState({
      magnetSelectedPlan: plan
    });
  };

  getPossessionTimelineOptions = () => {
    let dropdown = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i < currentYear + 7; i++) {
      dropdown.push(<MenuItem value={i}>{i}</MenuItem>);
    }

    return dropdown;
  };

  render() {
    const {
      selectedVal,
      country_code,
      magnetName,
      sweetShow,
      source,
      type,
      title,
      assign_status,
      nationality,
      is_magnet,
      project,
      isLoading,
      userName,
      addedBy,
      source_id,
      alertShow,
      alertType,
      alertTitle,
      action_date,
      required_bhk,
      possessionTimeline,
      budget
      , chat_source
    } = this.state;

    return (
      <Frag>
        <div className="projectForm">
          <SweetAlert
            show={sweetShow}
            type={type}
            title={title}
            changeSweet={this.handleSweet}
          />
          <Loader show={this.state.showLoader} />
          <PopUpAlert
            type={alertType}
            msg={alertTitle}
            open={alertShow}
            handleClose={this.handleClose}
          />
          <form role="form">
            <div className="card-body">
              {constants.MAGNET_PLAN_ROLES.includes(window.$role)
               ? (
                <div className="row">
                  <div className="col-12">
                    <MagnetPlanCards
                      minified={true}
                      selectPlan={this.selectPlan}
                      magnetSelectedPlan={this.state.magnetSelectedPlan}
                      magnetPlans={this.props.availablePlans || {}}
                    />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <TextField
                      error={this.state.errors.name !== "" ? true : false}
                      required
                      id="name"
                      name="name"
                      label="Client Name"
                      helperText={
                        this.state.errors.name !== ""
                          ? this.state.errors.name
                          : ""
                      }
                      onChange={this.onChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <Autocomplete
                    id="asynchronous-demo"
                    getOptionSelected={(project, value) =>
                      project.name === value.name
                    }
                    getOptionLabel={project => project.name}
                    options={project}
                    value={selectedVal}
                    loading={isLoading}
                    onChange={(_event, project) => {
                      if (project === null) {
                        this.setState({ project: [] });
                      } else {
                        this.setState({
                          project_name: project.name,
                          project_id: project.id,
                          showLoader: true,
                          selectedVal: { name: project.name, id: project.id }
                        });
                        this.checkDublicateLead(this.state.phone);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Interest in Project"
                        required
                        fullWidth
                        onChange={this.AutoChangeProject}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <BusinessRoundedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <FormControl>
                    <Autocomplete
                      error={
                        this.state.errors.country_code !== "" ? true : false
                      }
                      labelId="demo-controlled-open-select-label"
                      getOptionLabel={option =>
                        `${option.name} (${option.code})`
                      }
                      loading={isLoading}
                      options={countryCodes}
                      onChange={(e, options) =>
                        options && this.setState({ country_code: options.code })
                      }
                      value={(() => {
                        for (
                          let index = 0;
                          index < countryCodes.length;
                          index++
                        ) {
                          const selected_country = countryCodes[index];

                          if (selected_country.code === country_code) {
                            return selected_country;
                          }
                        }
                        return {};
                      })()}
                      helperText={
                        this.state.errors.country_code !== ""
                          ? this.state.errors.country_code
                          : ""
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          reqiured
                          fullWidth
                          label="Country Code *"
                          name="country_code"
                          id="country_code"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    ></Autocomplete>
                  </FormControl>
                </div>
                <div className="col-sm-6 mb-3">
                  <FormControl>
                    <TextField
                      error={this.state.errors.number !== "" ? true : false}
                      required
                      id="number"
                      name="number"
                      value={this.state.number}
                      label="Client Number"
                      helperText={
                        this.state.errors.number !== ""
                          ? this.state.errors.number
                          : ""
                      }
                      onChange={this.handleNumberChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIphoneIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <TextField
                      id="email"
                      name="email"
                      label="Client Email"
                      onChange={this.onChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
                {window.$role !== "magnet" && window.$role !== "magnetplatinum" ? (
                  <div className="col-sm-6 mb-3">
                    <FormControl>
                      <InputLabel
                        id="demo-controlled-open-select-label"
                        required
                      >
                        Select Source
                      </InputLabel>
                      <Select
                        error={
                          this.state.errors.source_id !== "" ? true : false
                        }
                        labelId="demo-controlled-open-select-label"
                        value={source_id}
                        onChange={this.onChange}
                        inputProps={{
                          name: "source_id",
                          id: "source_id"
                        }}
                        helperText={
                          this.state.errors.source_id !== ""
                            ? this.state.errors.source_id
                            : ""
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {source
                          ? source.map(reg => (
                            <MenuItem
                              className="text-capitalize"
                              value={reg.id}
                              key={reg.id}
                            >
                              {reg.name}
                            </MenuItem>
                          ))
                          : ""}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                {GROUP_2.includes(window.$role) ? (
                  this.state.is_magnet == 1 ||
                  GROUP_1.includes(window.$role) ? (
                    <div className="col-sm-6 mt-1 mb-3">
                      <Autocomplete
                        id="asynchronous-demo"
                        getOptionSelected={(magnetName, value) =>
                          magnetName.name === value.name
                        }
                        getOptionLabel={magnetName =>
                          magnetName.name + " (" + magnetName.phone + ")"
                        }
                        options={magnetName}
                        loading={isLoading}
                        onChange={(_event, magnetName) => {
                          if (magnetName === null) {
                            this.setState({
                              magnetName: []
                            });
                          } else {
                            this.setState({
                              magnet_ids: magnetName.id
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            reqiured
                            label="Magnet Name *"
                            fullWidth
                            onChange={this.handleAutoMagnetChange}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <BusinessRoundedIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {assign_status === true ? (
                  <div className="col-sm-6 mt-1">
                    <Autocomplete
                      id="asynchronous-demo"
                      getOptionSelected={(userName, value) =>
                        userName.name === value.name
                      }
                      getOptionLabel={userName => userName.name}
                      options={userName}
                      loading={isLoading}
                      onChange={(_event, userName) => {
                        if (userName === null) {
                          this.setState({
                            userName: [],
                            p_assignby: ""
                          });
                        } else {
                          this.setState({
                            assign_id: userName.id,
                            p_assignby: addedBy
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select RM"
                          fullWidth
                          onChange={this.AutoUserChangeUser}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <BusinessRoundedIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                ) : (
                  ""
                )}
                {this.state.source_id == "5" &&
                  <div className="col-md-6 mt-1">
                    <RadioOption 
                      name="chat_source"
                      label="Chat Source"
                      value={chat_source}
                      items={chat_option}
                      onChange={this.onChange}
                      />
                  </div>
                }
                <div className="col-md-6">
                  <div className="form-group">
                    <FormLabel component="legend">Nationality</FormLabel>
                    <RadioGroup
                      row
                      aria-label="nationality"
                      name="nationality"
                      value={nationality}
                      onChange={this.onChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            color="primary"
                            checked={this.state.nationality == 1}
                          />
                        }
                        label="Indian"
                      />
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            color="primary"
                            checked={this.state.nationality == 2}
                          />
                        }
                        label="NRI"
                      />
                    </RadioGroup>
                  </div>
                </div>

                {window.$user.group !== "magnet" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <FormLabel component="legend">Magnet Lead</FormLabel>
                      <RadioGroup
                        row
                        aria-label="is_magnet"
                        name="is_magnet"
                        value={is_magnet}
                        onChange={this.onChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              color="primary"
                              checked={
                                window.$user.group === "magnet"
                                  ? true
                                  : this.state.is_magnet == 1
                              }
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={
                            <Radio
                              color="primary"
                              checked={
                                window.$user.group === "magnet"
                                  ? false
                                  : this.state.is_magnet == 0
                              }
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                )}

                {assignLeadRmPermission.includes(window.$role) && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <FormControlLabel
                        value={assign_status}
                        className="ml-0"
                        name="assign_status"
                        control={<Switch color="primary" />}
                        label="Assign to RM"
                        labelPlacement="top"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                )}
               { REFERAL_SOURCE_IDS.includes(source_id) && (
                  <div className="col-sm-6">
                    <div className="form-group">
                         <FormControl>
                         <TextField
                            required
                            type="number"
                            id="referalLeadId"
                            name="referalLeadId"
                            label="Referal Lead ID"
                            onChange={this.formValChange}
                          />
                          </FormControl>
                    </div>
                  </div>
                )}
              </div>

              {/* new Date(
                window.$user.created_at.replace("T", " ").replace("Z", "")
              ) > constants.SHOW_MAGNET_PLAN_BEYOND_DATE  */
              true
              ? (
                <>
                  {this.state.magnetSelectedPlan.key === "plus" ? null : (
                    <div className="row mb-3">
                      <div className="col-sm-6 col-12">
                        <div className="form-group">
                          <TextField
                            error={
                              this.state.errors.admin_message !== ""
                                ? true
                                : false
                            }
                            id="standard-multiline-flexible"
                            label="Comments"
                            name="admin_message"
                            helpertext={
                              this.state.errors.admin_message !== ""
                                ? this.state.errors.admin_message
                                : ""
                            }
                            multiline
                            rowsMax="4"
                            value={this.state.admin_message}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row mb-3">
                    {["basic", "plus"].includes(
                      this.state.magnetSelectedPlan.key
                    ) ? (
                      <>
                        <div className="col-sm-6 mb-3">
                          <FormControl>
                            <InputLabel
                              id="demo-controlled-open-select-label"
                              required
                            >
                              Possession Timeline
                            </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={possessionTimeline}
                              onChange={this.onChange}
                              inputProps={{
                                name: "possessionTimeline",
                                id: "possessionTimeline"
                              }}
                            >
                              {this.getPossessionTimelineOptions()}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <FormControl>
                            <InputLabel
                              id="demo-controlled-open-select-label"
                              required
                            >
                              Budget
                            </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={budget}
                              onChange={this.onChange}
                              inputProps={{
                                name: "budget",
                                id: "budget"
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {BUDGET_OPTIONS.map(o => (
                                <MenuItem selected="" value={o}>
                                  {`${o.min}${o.max === "3 Crores" ? `+` : ` - ${o.max}`
                                    }`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <FormControl>
                            <InputLabel
                              id="demo-controlled-open-select-label"
                              required
                            >
                              Bed Config
                            </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={required_bhk}
                              onChange={this.onChange}
                              inputProps={{
                                name: "required_bhk",
                                id: "required_bhk"
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {BED_CONFIG.map(item => (
          											<MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <FormControl>
                            <TextField
                              id="referredBy"
                              name="referredBy"
                              value={this.state.referredBy}
                              label="Reference"
                              onChange={this.onChange}
                            />
                          </FormControl>
                        </div>
                      </>
                    ) : null}

                    {this.state.magnetSelectedPlan.key === "plus" ? (
                      <>
                        <div className="col-sm-6 mb-3">
                          <FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDateTimePicker
                                margin="normal"
                                id="action_date_form"
                                label="Activity Date*"
                                ampm={false}
                                format="yyyy/MM/dd HH:mm"
                                minDate={moment().subtract(15, "days")}
                                name="action_date"
                                value={action_date}
                                onChange={(key, date) =>
                                  this.handleDateChange("action_date", date)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </div>
                        <div className="col-sm-12 col-12">
                          <div className="form-group">
                            <TextField
                              id="standard-multiline-flexible"
                              required
                              label="Comments"
                              name="comment"
                              value={this.state.comment}
                              multiline
                              rowsMax="4"
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}

              <div className="row">
                <div className="col-sm-12 text-center col-12">
                  <ButtonSubmit onClick={this.addNewLead} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Frag>
    );
  }
}

export default withRouter(addLeadForm);
