export default {
	name: 'Super Admin',
	pathProps: {className: 'black'},
	colo:'black',
	textProps: {x: -25, y: 25},
	children: [{
		name: 'Admin',
		pathProps: {className: 'blue'},
		textProps: {x: -25, y: 25},
		color: 'blue',
		children: [{
			name: 'Admin',
			textProps: {x: -25, y: 25},
			color: 'aquamarine',
			children: []
		}, {
			name: 'Mini Admin',
			textProps: {x: -25, y: 25},
			color: 'cyan',
			children: []
		}, {
			name: 'Bangalore Admin',
			textProps: {x: -25, y: 25},
			color: 'navy',
			children: []
		}, {
			name: 'Pune Admin',
			textProps: {x: -25, y: 25},
			color: 'turquoise',
			children: []
		}]
	}, {
		name: 'Finance',
		pathProps: {className: 'red'},
		textProps: {x: -25, y: 25},
		color: 'red',
		children: [{
			name: 'Finance Head',
			textProps: {x: -25, y: 25},
			color: 'indigo',
			children: []
		}]
	}, {
		name: 'Digital',
		pathProps: {className: 'green'},
		colo:'green',
		textProps: {x: -25, y: 25},
		children: [{
			name: 'Digital Head',
			textProps: {x: -25, y: 25},
			color: 'indigo',
			children: []
		}]
	}, {
		name: 'HR',
		pathProps: {className: 'yellow'},
		colo:'yellow',
		textProps: {x: -25, y: 25},
		children: [{
			name: 'HR Head',
			textProps: {x: -25, y: 25},
			color: 'indigo',
			children: []
		}]
	}, {
		name: 'IT',
		pathProps: {className: 'grey'},
		colo:'grey',
		textProps: {x: -25, y: 25},
		children: [{
			name: 'IT Head',
			textProps: {x: -25, y: 25},
			color: 'indigo',
			children: []
		}]
	}, {
		name: 'Magnet',
		pathProps: {className: 'blue'},
		textProps: {x: -25, y: 25},
		color: 'blue',
		children: [{
			name: 'Magnet Admin',
			textProps: {x: -25, y: 25},
			color: 'aquamarine',
			children: []
		}, {
			name: 'Magnet TLs',
			textProps: {x: -25, y: 25},
			color: 'cyan',
			children: []
		}, {
			name: 'Magnet RM',
			textProps: {x: -25, y: 25},
			color: 'navy',
			children: []
		}, {
			name: 'Magnet',
			textProps: {x: -25, y: 25},
			color: 'turquoise',
			children: []
		}]
	}, {
		name: 'Presale',
		pathProps: {className: 'blue'},
		textProps: {x: -25, y: 25},
		color: 'blue',
		children: [{
			name: 'Presale Head',
			textProps: {x: -25, y: 25},
			color: 'aquamarine',
			children: []
		}, {
			name: 'Presale TL',
			textProps: {x: -25, y: 25},
			color: 'aquamarine',
			children: []
		}, {
			name: 'Sourcing S2 (Presale RM)',
			textProps: {x: -25, y: 25},
			color: 'cyan',
			children: []
		}, {
			name: 'Sourcing S3 (WHF Caller/ BYOB)',
			textProps: {x: -25, y: 25},
			color: 'navy',
			children: []
		}]
	}, {
		name: 'Sales',
		pathProps: {className: 'blue'},
		textProps: {x: -25, y: 25},
		color: 'blue',
		children: [{
			name: 'Pnt Head',
			textProps: {x: -25, y: 25},
			color: 'aquamarine',
			children: []
		}, {
			name: 'Sales TL',
			textProps: {x: -25, y: 25},
			color: 'cyan',
			children: []
		}, {
			name: 'Losing RM(Sales RM)',
			textProps: {x: -25, y: 25},
			color: 'navy',
			children: []
		}, {
			name: 'Sourcing S1',
			textProps: {x: -25, y: 25},
			color: 'turquoise',
			children: []
		}]
	}]
}