import React from 'react'
import MainHeader from './CliManagement/MainHeader'
import Loader from './common/loader/loader'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import { updateIncentives, getBookingLeadsData, getSalesTeamUsers, getDownloadLink, getAllDocDownloadLink } from '../dataParser/getListUserData';
import Pagination from "react-js-pagination";
import pdf2base64 from 'pdf-to-base64';

import TextField from '@material-ui/core/TextField';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import ButtonSubmit from './common/controls/ButtonSubmit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye,
  faFilter,
  faEdit, faCheck
} from "@fortawesome/free-solid-svg-icons";
import FilterDrawer from "@material-ui/core/Drawer";
import SelectOption from './common/controls/SelectOption';
import InputField from './common/controls/InputField';
import { documentStatus } from './common/datas/data';
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopUpAlert from "./common/sweetAlert/popUpAlert";
import ViewAllPreviewDoc from './common/modal/viewAllPreviewDoc';
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import moment from "moment";
import { AV_LIMIT_LOWER, AV_LIMIT_UPPER, AV_VALIDATION_MSG_UPPER, AV_VALIDATION_MSG_LOWER, DECIMAL_REGEX } from '../config/constant';
import InputUnitField from './common/controls/InputUnitField';
import { indianRupeeCommaSeparated, removeCommas } from '../helper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const INCENTIVE_STAGE_STATUS = [
    { flag: false, key_name: "invoice_not_raised_status", title: "Invoice Not Raised Status", is_magnet: false }//4aug22//4aug22
  , { flag: false, key_name: "sdr_payment_status", title: "Stamp Duty Status", is_magnet: false }//4aug22
  , { flag: false, key_name: "registration_status", title: "Registration Status", is_magnet: false }
  , { flag: false, key_name: "sales_agreement_status", title: "Sales Agreement Status", is_magnet: false }
  , { flag: false, key_name: "eligible_for_invoice_status", title: "Eligible for Invoice", is_magnet: false }
  , { flag: false, key_name: "partial_invoice_raised_status", title: "Partial Invoice Raised Status", is_magnet: false }//4aug22
  , { flag: false, key_name: "invoice_raised_status", title: "Invoice Raised", is_magnet: false }
  , { flag: false, key_name: "invoice_cleared_status", title: "Invoice Cleared", is_magnet: false }
  , { flag: false, key_name: "magnet_invoice_cleared_status", title: "Magnet Invoice Cleared", is_magnet: true }
];

const fields = ["Booking Id", "Lead Id", "Builder Name", "Project Name", "Document Status", "Client Payment Percentage", "Stage", "Agreement Value", "Magnet Split%", "Action"]

let initialState = {
  showLoader: false
  , data: []
  , fullData: null
  , count: 0
  , size: 10
  , bookingDocStage: -1
  , clientPaymentPercentage: 0
  , clientPaymentDate: null
  , agreementValue: 0
  , magnetSplitPercentage: 0
  , editId: ""
  , showAllDoc: false
  , url:[]
}

const initialFilter = {
  leadId: ""
  , fromDate: null
  , toDate: null
  , document_status: ""
}

const IncentiveUpdate = () => {

  const [state, setState] = React.useState(initialState);
  const { url, showAllDoc, showLoader, data, count, size, bookingDocStage, clientPaymentPercentage, clientPaymentDate, agreementValue, magnetSplitPercentage, editId } = state;

  const [filters, setFilter] = React.useState(initialFilter);
  const { leadId, fromDate, toDate, document_status } = filters;
  
  const [filteredData, setFilteredData] = React.useState({});

  const [activePage, setActivePage] = React.useState(1);

  const [alertState, setAlertState] = React.useState({
    alertShow: false
    , alertMsg: ""
  });

  const { alertShow, alertType, alertMsg } = alertState;

  const [open, setOpen] = React.useState(false);

  const [stage, setStage] = React.useState([]);

  React.useEffect(() => {

    let listData = {};
    listData.size = size;
    listData.page = 1;
    getApi(listData);
  }, [])

  const handleDrawer = () => {
    setOpen(true);
  };

  const getApi = async (queryParam) => {
    setState({ ...state, showLoader: true })
    let getRes = await getBookingLeadsData(queryParam);
    setState({
      ...state
      , showLoader: false
      , data: getRes.results
      , count: getRes.meta.totalElements
      , size: getRes.meta.size
      , editId: "" 
    })
  }

  const handleEdit = (item) => {
    setState({
      ...state
      , bookingDocStage: item?.booking_document_stage
      , clientPaymentPercentage: item?.ClientPaymentPercentages[0]?.percentage
      , clientPaymentDate: item.ClientPaymentPercentages[0]?.date_of_payment
      , agreementValue: item?.agreement_value
      , magnetSplitPercentage: item?.magnet_percentage_split
      , editId: item.id
    })
    let value = [
      { flag: item.sales_agreement_status, key_name: "sales_agreement_status", title: "Sales Agreement Status" }  //4aug22
      , { flag: item.sdr_payment_status, key_name: "sdr_payment_status", title: "SDR Payment Status" }
      , { flag: item.registration_status, key_name: "registration_status", title: "Registration Status" }
      , { flag: item.eligible_for_invoice_status, key_name: "eligible_for_invoice_status", title: "Eligible for Invoice" }
      , { flag: item.invoice_not_raised_status, key_name: "invoice_not_raised_status", title: "Invoice Not Raised Status" }//4aug22
      , { flag: item.partial_invoice_raised_status, key_name: "partial_invoice_raised_status", title: "Partial Invoice Raised Status" }//4aug22
      , { flag: item.invoice_raised_status, key_name: "invoice_raised_status", title: "Invoice Raised" }
      , { flag: item.invoice_cleared_status, key_name: "invoice_cleared_status", title: "Invoice Cleared" }
      , { flag: item.magnet_invoice_cleared_status, key_name: "magnet_invoice_cleared_status", title:  "Magnet Invoice Cleared" }
    ];
    value = value.filter(item => item.flag)
    value = value.map(item => {
      item.flag = false;
      return item;
    });
    setDoneStatus(value);
  }

  const handleSelectChange = (e) => {
     let {value} = e.target;
     setState({
       ...state,
       bookingDocStage: value
     })
  }
  const handleNumberChange = (e) => {
    let {name, value, validity: {valid}} = e.target;
    if (valid || value === ""){
      setState({ 
        ...state,
        [name]: value
      });
    }
  };

  const handleSave = async (item) => {
    let updateData = {};
    updateData.client_payment_percentages = {};
    if(bookingDocStage) updateData.booking_document_stage = bookingDocStage;
    if (clientPaymentPercentage) updateData.client_payment_percentages.percentage = clientPaymentPercentage;
    if (clientPaymentDate) updateData.client_payment_percentages.date_of_payment = clientPaymentDate;
    if (agreementValue) updateData.agreement_value = removeCommas(agreementValue);
    if (magnetSplitPercentage) updateData.magnet_percentage_split = magnetSplitPercentage;

    let selected_key = stage.map(item=>item.key_name)
    for(let i = 0; i < INCENTIVE_STAGE_STATUS.length; i++){
      if(selected_key.includes(INCENTIVE_STAGE_STATUS[i].key_name)){
        updateData[INCENTIVE_STAGE_STATUS[i].key_name] = true;
      }
      else{
        updateData[INCENTIVE_STAGE_STATUS[i].key_name] = false;
      }
    }
    if(removeCommas(agreementValue) < AV_LIMIT_LOWER){
      setAlertState({
        alertShow: true
        , alertType: "error"
        , alertMsg: AV_VALIDATION_MSG_LOWER
      })
      return false
    }
    else if(removeCommas(agreementValue) > AV_LIMIT_UPPER){
      setAlertState({
        alertShow: true
        , alertType: "error"
        , alertMsg: AV_VALIDATION_MSG_UPPER
      })
      return false     
    }
    const res = await updateIncentives(item.id, updateData);
    if (res.meta.status === 201) {
      setState({ ...state, editId: "" })
      setAlertState({
        alertShow: true
        , alertType: "success"
        , alertMsg: res?.data?.success?.message || "Updated successfully!!!"
      })
      handleFilter();
    }
    else {
      setAlertState({
        alertShow: true
        , alertType: "error"
        , alertMsg: res?.meta?.message || "Something went wrong!!!"
      })
    }
  }
  const handleValueChange = (e) => {
    let { name, value } = e.target
    if (value === '' || DECIMAL_REGEX.test(value)) {
    setState({
      ...state,
      [name]: value
    })
  }
  }
  const handleMultiSelect = (e) => {
    let { value } = e.target;
    setDoneStatus(value);
  };
  
  const handleViewDocument = async (id) => {
    let url = [];
    if (id) {
      url = await getAllDocDownloadLink(id);
    }
    let i;
    for (i = 0; i < url.length; i++) {
      if (url[i].s3UploadResponse !== "https://s3.amazonaws.com/" && (url[i].s3UploadResponse.includes('.pdf')
        || url[i].fileType === '.pdf')) {
        await pdf2base64(url[i].s3UploadResponse).then((response) => {
          url[i].s3UploadResponse = response;
        }).catch((error) => {
          console.error(error); //Exepection error....
        })
      }
    }
    if (i == url.length) {
      setState({ ...state, showAllDoc: true, url: url })
    }
  } 
  const setDoneStatus = (value) => {
    let selected_item = value[value.length - 1];
    let last_item_index;
    if (value.length) {
      let last_item = INCENTIVE_STAGE_STATUS.filter(item => item.key_name === selected_item.key_name)[0]
      last_item_index = INCENTIVE_STAGE_STATUS.indexOf(last_item);
    }
    else {
      last_item_index = INCENTIVE_STAGE_STATUS.indexOf(selected_item);
    }
    let selected_items = [];

    let unselected_item;
    stage.map(item => {
      item["flag"] = false
    })
    for (let i = 0; i <= last_item_index; i++) {
      let selected = INCENTIVE_STAGE_STATUS[i]
      selected["flag"] = true
      selected_items.push(selected)
    }
    if (stage.length > value.length) {
      unselected_item = getDifference(stage, value)
      let start_index = selected_items.indexOf(unselected_item[0]) + 1;
      selected_items.splice(start_index)
      for (let i = start_index; i < stage.length; i++) {
        stage[i].flag = false
      }
    };
    setStage(selected_items);
  }

  const showLastStage = (booking) => {
    let status = "N/A";
    if(booking?.sales_agreement_status) status= "Sales Agreement Status";//4aug22
    if(booking?.sdr_payment_status) status = "SDR Payment Status";
    if(booking?.registration_status) status = "Registration Status";
    if(booking?.eligible_for_invoice_status) status = "Eligible for Invoice";
    if(booking?.invoice_not_raised_status) status = "Invoice Not Raised";//4aug22
    if(booking?.partial_invoice_raised_status) status = "Partial Invoice Raised";//4aug22
    if(booking?.invoice_raised_status) status = "Invoice Raised";
    if(booking?.invoice_cleared_status) status = "Invoice Cleared";
    if(booking?.magnet_invoice_cleared_status) status = "Magnet Invoice Cleared";
    return status;
  }

  const showDocumentStatus = (document_stage) => {
    for(let i = 0; i< documentStatus.length; i++){
      if(documentStatus[i].id === document_stage){
        return documentStatus[i].name
      }
    }
  }

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber)
    let listData = {};
    listData.size = size;
    listData.page = pageNumber;
    Object.assign(listData, filteredData)
    getApi(listData);
  }
  const handleDisabled = (value) => {
    if(value == -1 || value == 0){
      return true;
    }
    else{
      return false
    }
  }
  const handleAlertClose = () => {
    setAlertState({
      ...alertState
      , alertShow: !alertShow
    })
  }
  const handleFilter = (filtersData = null) => {
    setOpen(false);
    setActivePage(1);
    let filteredData = {};
    let listData = {};

    listData.size = size;
    listData.page = 1;

    if (leadId) {
      listData["leadId"] = leadId
      setFilteredData({
        ...filteredData
        , leadId: leadId
      })
    }
    if (fromDate) {
      listData["fromDate"] = fromDate.toISOString().split("T")[0]
      filteredData.fromDate = fromDate.toISOString().split("T")[0];
    }
    if (toDate) {
      listData["toDate"] = toDate.toISOString().split("T")[0]
      filteredData.toDate =  toDate.toISOString().split("T")[0];
    }
    if (document_status !== "") {
      listData["document_status"] = document_status;
      filteredData.document_status = document_status
    }
    const selectedSalesRms = filtersData?.selectedSalesRms || [];
    if(selectedSalesRms.length > 0) {
      listData["salesRm"] = selectedSalesRms;
    }
    setFilteredData(filteredData);
    getApi(listData);
  }

  const handleReset = () => {
    setFilter(initialFilter);
  };
  const handleCloseModal = () => {
    setState({...state, showAllDoc: false });
  }
  const IncentiveFilter = (props) => {

    const [rmState, setRmState] = React.useState({
      salesRmMembers: [],
      selectedSalesRms: [],
    })
    const { salesRmMembers } = rmState;
    const handleAutoRmChange = async (e) => {
      let value = e.target.value;
      let getData = await getSalesTeamUsers(value);
      if (getData.meta.status === 200) {
        setRmState({
          ...rmState
          , salesRmMembers: getData?.data?.filter(item => item["SalesRMBooking.first_name"])
        })
      }
      else {
        setRmState({
          ...rmState
          , salesRmMembers: []
        })
      }
    }

    return (
      <div
        className={`filterMenu ${props.show ? `filterMenuShow` : ``}`}
        style={{ zIndex: 9 }}
      >
        <div className="container filter_lead filterMenuCard py-4 px-0">
          <div className="row filterRow">
            <div className="col-md-6">
              <SelectOption
                name="document_status"
                value={document_status}
                label="Document Status"
                options={documentStatus}
                variant="standard"
                onChange={(e) => setFilter({ ...filters, document_status: e.target.value })}
              />
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  getOptionSelected={(option, value) => {
                    return option.sales_rm === value.sales_rm;
                  }}
                  getOptionLabel={option =>
                    `${option["SalesRMBooking.first_name"]} ${option["SalesRMBooking.last_name"]}`
                  }
                  options={salesRmMembers}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={`${option["SalesRMBooking.first_name"]} ${option["SalesRMBooking.last_name"]}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(e, option) => {
                    var salesRms = option.map(ops => ops.sales_rm);
                    setRmState({
                      ...rmState,
                      selectedSalesRms: salesRms
                    });

                    if (option === null) {
                      setRmState({ ...rmState, salesRmMembers: [] });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Sales RM"
                      fullWidth
                      onChange={handleAutoRmChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 bgCommon">
          <div className="row">
            <div className="col-sm-6 text-right col-6 mt-3">
              <div className="form-group">
                <button
                  className="btn btn-success"
                  onClick={() => handleFilter(Object.assign(rmState, filters))}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="col-sm-6 col-6 mt-3">
              <div className="form-group">
                <button
                  className="btn btn-danger"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Loader show={showLoader} />
      <MainHeader list="Incentive" count={count}
        child={
          <>
            <FontAwesomeIcon
              icon={faFilter}
              className="nav-icon"
              style={{ fontSize: "25px", cursor: "pointer" }}
              onClick={handleDrawer}
            />
            <FilterDrawer
              anchor="right"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <IncentiveFilter show={open} />
            </FilterDrawer>
          </>
        } />
      <div className="listContainer">
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col-md-4">
              <TextField
                value={leadId}
                label="Lead ID"
                variant="standard"
                onChange={(e) => setFilter({ ...filters, leadId: e.target.value })}
              />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="From Date"
                        disableToolbar
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={fromDate}
                        autoOk={true}
                        onChange={date => {
                          setFilter({ ...filters, fromDate: date });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-6">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        label="To Date"
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={toDate}
                        autoOk={true}
                        onChange={date => {
                          setFilter({ ...filters, toDate: date });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <ButtonSubmit lable="Filter" onClick={() => handleFilter(filters)} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                {data.length > 0 ?
                  <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }}>
                    <TableHead>
                      <TableRow>
                        {fields.map((item, idx) => (
                          <StyledTableCell key={idx}>{item}</StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((booking, idx) => (
                        <TableRow key={idx} className="text-capitalize">
                          <TableCell>{booking.id || "-"}</TableCell>
                          <TableCell>{booking?.Lead?.id || booking?.lead_id}</TableCell>
                          <TableCell>{booking?.Project?.builder_name || "-"}</TableCell>
                          <TableCell>{booking?.Project?.name || "-"}</TableCell>
                          {editId === booking.id
                            ?
                            <>
                              <TableCell>
                                <div className = 'row'>
                                  <div className = 'col-8'>
                                <Select
                                  labelId="demo-controlled-open-select-label"
                                  value={bookingDocStage}
                                  onChange = {(e) => handleSelectChange(e)}
                                >
                                    {documentStatus.map(x => {
                                      return (
                                        <MenuItem value={x.id} disabled = {handleDisabled(x.id)}>{x.name}</MenuItem>)
                                      })
                                    }
                                </Select>
                                </div>
                                <div className = 'col-4'>
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="fs-20 cursor-pointer"
                                  onClick={() => handleViewDocument(booking.id)}  
                                  style={{marginTop:4}}                                
                                />
                                </div>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="d-flex justify-conent-center align-items-end" >
                                  <InputField
                                    variant="standard"
                                    className="w-50"
                                    name="clientPaymentPercentage"
                                    onChange={handleValueChange}
                                    value={clientPaymentPercentage}
                                  />
                                  <span>% as on</span>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      variant="inline"
                                      format="dd-MM-yyyy"
                                      margin="normal"
                                      value={clientPaymentDate}
                                      autoOk={true}
                                      onChange={date =>
                                        setState({ ...state, clientPaymentDate: date })
                                      }
                                      KeyboardButtonProps={{
                                        "aria-label": "change date"
                                      }}
                                      style={{ width: "140px", marginLeft: "1rem" }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                              </TableCell>
                              <TableCell>
                                <Select
                                  fullWidth
                                  multiple
                                  value={stage}
                                  onChange={handleMultiSelect}
                                  input={<Input id="operate-multiple-chip" />}
                                  renderValue={(selected) => (
                                    <div>
                                      {/* {selected.map((value, index) => (
                                        <Chip key={index} label={value.title} />
                                        ))} */}
                                      <Chip label={selected[selected.length - 1].title} />
                                    </div>
                                  )}
                                // MenuProps={MenuProps}
                                >
                                  {INCENTIVE_STAGE_STATUS.map((item, index) => (
                                    booking.is_magnet ?
                                      <MenuItem key={index} value={item}>
                                        <Checkbox checked={item.flag} color="default" />
                                        <ListItemText primary={item.title} />
                                      </MenuItem>
                                      :
                                      (
                                        !item.is_magnet &&
                                        <MenuItem key={index} value={item}>
                                          <Checkbox checked={item.flag} color="default" />
                                          <ListItemText primary={item.title} />
                                        </MenuItem>)
                                  ))}
                                </Select>
                              </TableCell>
                              <TableCell>
                              <InputUnitField 
                                name="agreementValue" 
                                value={agreementValue} 
                                onChange={handleNumberChange}
                              />
                              </TableCell>
                              {
                                booking?.is_magnet ?
                                  <TableCell>
                                    <InputField
                                      name="magnetSplitPercentage"
                                      value={magnetSplitPercentage}
                                      onChange={handleValueChange}
                                    />
                                  </TableCell>
                                  :
                                  <TableCell>-</TableCell>
                              }
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="fs-14 cursor-pointer"
                                  onClick={() => handleSave(booking)}
                                />
                              </TableCell>
                            </>
                            :
                            <>
                              <TableCell>{documentStatus[documentStatus.findIndex(x => x.id == booking?.booking_document_stage)].name}</TableCell>
                              <TableCell>
                                {booking?.ClientPaymentPercentages.length ? booking?.ClientPaymentPercentages[0]?.percentage : "-"}
                                <span className='mx-2'>% as on</span>
                                {booking?.ClientPaymentPercentages[0]?.date_of_payment ? moment(booking?.ClientPaymentPercentages[0]?.date_of_payment).format("DD/MM/YYYY") : "-"}
                              </TableCell>
                              <TableCell>{showLastStage(booking)}</TableCell>
                              <TableCell>{indianRupeeCommaSeparated(booking?.agreement_value) || "-"}</TableCell>
                              <TableCell>{booking?.is_magnet ? booking?.magnet_percentage_split : "-"}</TableCell>
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="fs-14 cursor-pointer"
                                  onClick={() => handleEdit(booking)}
                                />
                              </TableCell>
                            </>
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  : <div className="main_area_display shadow card p-3">
                    <h5>{showLoader ? "Loading.." : "No Data Found !!!"}</h5>
                  </div>
                }
              </TableContainer>
            </div>
          </div>

          <div className="row justify-content-end">
            {count > size ?
              <div className="paginationBlock">
                <Pagination
                  hideDisabled
                  activePage={activePage}
                  itemsCountPerPage={size}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  onChange={handleChangePage}
                />
              </div>
              :
              ''
            }
          </div>
        </div>
      </div>
      <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
      {showAllDoc ?
        <ViewAllPreviewDoc
          show={showAllDoc} 
          url= {url}
          handleCloseModal ={handleCloseModal}         
        />
      : null}
    </>
  )
}

const getDifference = (array1, array2) => {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.key_name === object2.key_name;
    });
  });
}
export default IncentiveUpdate