import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FilePreviewer from 'react-file-previewer';

class BookingDocPreviewer extends Component {
    render()  {
        return (
            <>
                <Modal
					show={this.props.showModal}
					onHide={this.props.handleModalClose}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="viewPdf"
				>
					<style>
					    {`@media print {*{display: none;}}`}
				  	</style>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{this.props.modalName}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="viewPdfBody">
					{ this.props.ViewFilePath && this.props.docExten === 'pdf' ?
						<FilePreviewer 
				            file={{
				                data: this.props.ViewFilePath,
				                mimeType: 'application/pdf',
				                name: 'sample.pdf' // for download
				            }}
				            hideControls
				        /> : <img src={this.props.ViewFilePath} width="100%" height="100%"></img>}
					</Modal.Body>
				</Modal>
            </>
        );

    }
}

export default BookingDocPreviewer;