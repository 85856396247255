import React from 'react'

export const HomesfyRM = ({ data }) => {
    const homesfy = data.AutoAssigns?.map((item, i) => {
        return (item.is_magnet === false) &&
            <button type="button" className="btn btn-primary mr-2 my-1 text-capitalize" key={i}>
                {item.first_name} <span className="badge badge-light">{item.percentage}</span>
            </button>
    })
    return homesfy
}


export const MagnetRM = ({ data }) => {
    const magnet = data.AutoAssigns?.map((item, i) => {
        return (item.is_magnet === true) &&
            <button type="button" className="btn btn-primary mr-2 my-1 text-capitalize" key={i}>
                {item.first_name} <span className="badge badge-light">{item.percentage}</span>
            </button>
    })
    return magnet
}

export const getAllRM = (i, fullData, isMagnet) => {
        const allRM = fullData[i].AutoAssigns?.filter(item => {
                if(isMagnet === false){
                    return (item.is_magnet === false) && item
                }
                else if(isMagnet === true){
                    return (item.is_magnet === true) && item  
                }
            })
        return allRM
    }

