import React, {useState} from 'react'
import ButtonSubmit from '../common/controls/ButtonSubmit';
import Loader from '../common/loader/loader';
import { MyModal } from '../common/modal/MyModal'
import PopUpAlert from '../common/sweetAlert/popUpAlert'
import InputField from '../common/controls/InputField'
import { addLoanDetail } from '../../dataParser/getHomeLoanData';

const AddLoanForm = ({ show, changeModal, getList }) => {

    const [state, setState] = useState({
        name: "",
        number: "",
        showLoader: "",
        open: false,
        alertType: "success",
        msg: ""
    })

    
    const handleChange = e => {
        let { name, value } = e.target;
        if (name === "number") {
            value = value.replace(/\D/g, "")
        }
        setState({
            ...state,
            [name]: value
        })
    }
    const {name, number, showLoader, open, alertType, msg} = state;


    const handleSubmit = () => {
        if (!!name && !!number) {
            if(number.length === 10){
                addHomeLoanData();
            }
            else{
                setState({
                    ...state,
                    alertType: "error",
                    open: true,
                    msg: "Number must be 10 digits only!"
                })                
            }
        }
       else {
            setState({
                ...state,
                alertType: "error",
                open: true,
                msg: "Please fill, Above all fields are required !"
            })
        }
    }

    const addHomeLoanData = async() =>{
        setState({ ...state, showLoader: true });
        let mainData = {};
        mainData["name"] = name
        mainData["number"] = number;

        const resData = await addLoanDetail(mainData);
        if (resData.meta.status == 201) {
            setState({
                ...state,
                showLoader: false,
                alertType: "success",
                open: true,
                msg: "Request Added Successfully!!!"
            })
            changeModal();
            getList();
        } else if(resData.meta.status === 409) {
            setState({
                ...state,
                showLoader: false,
                alertType: "warning",
                open: true,
                msg: "Request Already Exist!!!"
            })
        }
        else {
            setState({
                ...state,
                showLoader: false,
                alertType: "error",
                open: true,
                msg: "Something Went Wrong!!!"
            })
        }

    }

    const handleClose = () => {
        setState({
            ...state,
            open: false
        })
    }
    return (
        <div>
            <MyModal title="Add Home Loan Request" size="md" show={show} changeModal={changeModal}>
                <div className="row">
                    <div className="container-fluid">
                        <div className="col-md-12 mb-3 px-0">
                            <InputField
                                label="Client Name"
                                name="name"
                                value={name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-12 mb-3 px-0">
                            <InputField
                                label="Client Number"
                                name="number"
                                value={number}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <ButtonSubmit
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </MyModal>
            <Loader show={showLoader} />
            <PopUpAlert type={alertType} msg={msg} open={open} handleClose={handleClose} hideTime={6000} />
        </div>
    )
}

export default AddLoanForm
