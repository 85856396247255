import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage} from 'formik';
import MomentUtils from "@date-io/date-fns";
import AutocompleteMaterial from '@material-ui/lab/Autocomplete';

import TextFieldMaterial from '@material-ui/core/TextField';
import SelectMaterial from '@material-ui/core/Select';
import MenuItemMaterial from '@material-ui/core/MenuItem';
import InputLabelMaterial from '@material-ui/core/InputLabel';
import FormControlMaterial from '@material-ui/core/FormControl';
import FollowupControl from './FollowUpControl';

import './TextField.sass';
import clsx from 'clsx';
import {
  MuiPickersUtilsProvider,
  DatePicker,
    DateTimePicker
} from '@material-ui/pickers';
import api from '../api';

const DATE_FORMAT = "dd/MM/yyy";
const DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm";



function validateEmail(label, value) {
    let error;
    
    return error;
}

function validatePhone(label, value) {
    let error;
    
    return error;
}

function validateMoney(label, value){
    let error;
    
    return error;
}

function validateNumber(label, value){
    let error;
    
    return error;
}

function validateEmpty(label,value) {
    let error;
    if(!value){
        error = `${label} is required`
    }
    
    return error;
}

const DropDown = ({field, form, ...props}) => {
    const {className, name, label} = props;
    const {
        isEmail, isPhone, isMobile, 
        isState, isCountry, isCity, 
        isDate, isDateTime, isDropDown,
        isUser, 
        isNumber, isMoney, isString,
        isEditable, isRequired,
        ...r_props} = props;

    const selectedValue = field.value;

    if(form){
        return <>
            <FormControlMaterial
                className={clsx('Dropdown', className, {['no-edit']: !isEditable})}
            >
                <InputLabelMaterial shrink={true} htmlFor={field.name}>{label}</InputLabelMaterial>
                <SelectMaterial
                    {...r_props}
                    id={field.name}
                    {...form.getFieldProps(field.name)}
                >
                    {Object.keys(props.options).map(function(keyName, keyIndex) {
                        return <MenuItemMaterial disabled={keyName === ""} value={keyName} key={keyName}>{props.options[keyName]}</MenuItemMaterial>
                    })}
                </SelectMaterial>
            </FormControlMaterial>
        </>
    }
    return <SelectMaterial
        {...r_props}
        className={clsx('Dropdown', className, {['no-edit']: !isEditable})}
        shrink={true}
        >
        <MenuItemMaterial value="10">Ten</MenuItemMaterial>
        <MenuItemMaterial value="20">Twenty</MenuItemMaterial>
    </SelectMaterial>
}
const TextField = ({field, form, ...props}) => {
    const {className, name, label} = props;
    const {
        isEmail, isPhone, isMobile, 
        isState, isCountry, isCity, isCountryCode, isLanguage,
        isDate, isDateTime, 
        isUser, 
        isNumber, isMoney, isString,
		isEditable, isRequired, isIPAddress,
		isUtmSource, isUtmContent, isBrowser, isUtmTerm, isUtmCampaign, 
        ...r_props } = props;

	if(!isEditable){
		r_props['placeholder'] = "";
	}

    if(form){
        return <TextFieldMaterial 
            {...r_props}
			value={typeof r_props.value === undefined? "" :  r_props.value}
            className={clsx('TextField', className, {['no-edit']: !isEditable})}
            InputLabelProps={{
                shrink: true,
            }}
            {...form.getFieldProps(field.name)}
        />
    }
    return <TextFieldMaterial 
        {...r_props}
        InputLabelProps={{
            shrink: true,
        }}
        className={clsx('TextField', className, {['no-edit']: !isEditable})}
    />
}

const DateTextField = ({field, form, ...props}) => {
    const {className, name, label} = props;
    const {
        isEmail, isPhone, isMobile, 
        isState, isCountry, isCity, 
        isDate, isDateTime, isFollowUp,
        isUser, 
        isNumber, isMoney, isString,
        minDate, maxDate,
        isEditable, isRequired } = props;
    const extraProps = {};
    if(minDate){
        extraProps.minDate=minDate;
    }
    if(maxDate){
        extraProps.maxDate=maxDate;
    }
    if(form){
         return <MuiPickersUtilsProvider utils={MomentUtils}>
             {isDate === true && <DatePicker
                 className={clsx('TextField', className, {['no-edit']: !isEditable})}
                 variant="inline"
                 format={DATE_FORMAT}
                 margin="normal"
				 id={`date-picker-${field.name}`}
                 label={label}
                {...form.getFieldProps(field.name)}
                 onChange={(date) => {
                     form.setFieldValue(field.name, date)
                 }}
                 {...extraProps}
                 />}
             {isDateTime === true && <DateTimePicker
                 className={clsx('TextField', className, {['no-edit']: !isEditable})}
                 variant="inline"
                 format={DATE_TIME_FORMAT}
				 ampm={false}
                 margin="normal"
                 id="date-picker-inline"
                 label={label}
                 {...form.getFieldProps(field.name)}
                 onChange={(date) => {
					 console.log(date)
                     form.setFieldValue(field.name, date)
                    }}
                {...extraProps}
                    />}
             {isFollowUp === true && <FollowupControl
                required
                 className={clsx('TextField', className, {['no-edit']: !isEditable})}
                 format={DATE_TIME_FORMAT}
				 ampm={false}
                 margin="normal"
                 id="date-picker-inline"
                 label={label}
                 {...form.getFieldProps(field.name)}
                 onChange={(date) => {
                     form.setFieldValue(field.name, date)
                    }}
                {...extraProps}
                    />}
         </MuiPickersUtilsProvider>
    }
    return <MuiPickersUtilsProvider utils={MomentUtils}>
             <DatePicker
                 className={clsx('TextField', className, {['no-edit']: !isEditable})}
                 variant="inline"
                 format="MM/dd/yyyy"
                 margin="normal"
                 id="date-picker-inline"
                 label={label}
                 onChange={(date) => {
                     //form.setFieldValue(field.name, date)
                    }}
                {...extraProps}
             />
         </MuiPickersUtilsProvider>
}
const Autocomplete = ({field, form, ...props}) => {
    const {className, name, label, optionPresenter, callBackUrl, autoCompleteText} = props;
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState(field.value);
    const [options, setOptions] = React.useState([]);
    const {
        isEmail, isPhone, isMobile, 
        isState, isCountry, isCity, 
        isDate, isDateTime, 
        isUser, 
        isNumber, isMoney, isString,
        isEditable, isRequired, isAutocomplete } = props;


    React.useEffect(() => {
        if(!inputValue){
            setOptions([]);
            return;
        }
        api.get(callBackUrl(inputValue)).then(res => setOptions(res.data))
    }, [inputValue])

    React.useEffect(() => {
        if(!field.value){
            setInputValue('')
            setOptions([])
        }
        setValue(field.value)
    }, [field.value])

    return <>
        <AutocompleteMaterial
            id={field.name}
            getOptionLabel={optionPresenter}
            options={options}
            autoComplete
            autoHighlight
            filterSelectedOptions
            onChange={(event, newInputValue) => {
                form.setFieldValue(field.name, newInputValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue.trimLeft());
            }}
            value={field.value}
            className={clsx('TextField', className, {['no-edit']: !isEditable})}
            renderInput={(params) => {
                return <TextFieldMaterial 
                    {...params} 
                    label={label} 
                    autoComplete='off'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            }}
        />
    </>
}

export const FormikTextField = ({form, ...props}) => {
    const {className, name, label} = props;
    const {
        isEmail, isPhone, isMobile, 
        isEditable,
        isState, isCountry, isCity, 
        isDate, isDateTime, isFollowUp,
        isAutocomplete, callBackUrl, optionPresenter,
        isUser, 
        isDropDown,
        isNumber, isMoney, isString,
        isRequired, ...r_props } = props;


    let TextComponent = TextField;

    if(isDate || isDateTime || isFollowUp){
        TextComponent = DateTextField;
    }else if(isDropDown) {
        TextComponent = DropDown;
    }else if(isAutocomplete) {
        TextComponent = Autocomplete;
    }

	if(!isEditable){
		props['placeholder'] = "";
	}
    return <>
            <Field
                name={props.name}
                type={props.type}
                component={TextComponent} 
                validate={
                    (value) => {
                        let err = false;
                        err = isRequired && validateEmpty(label, value)

                        if (!err){
                            err = isEmail && validateEmail(label, value);
                        }

                        if (!err){
                            err = isPhone && validateEmail(label, value);
                        }

                        if (!err){
                            err = isMoney && validateMoney(label, value);
                        }

                        if (!err){
                            err = isNumber && validateNumber(label, value);
                        }
                        return err;
                    }
                }
                {...props}
            />
        <ErrorMessage name={props.name} >{msg => <div className="error">{msg}</div>}</ErrorMessage>
    </>
}

TextField.propTypes = {
    /**
    Specifies a short hint that describes the expected value of an input field
    */
    placeholder: PropTypes.string
    /**
    Defines a label for TextField
    */
    , label: PropTypes.string
}

TextField.defaultProps = {
    placeholder: "Enter a text"
}

export default TextField;
