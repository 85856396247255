import React from 'react'
import {Helmet} from "react-helmet";
import { AMAZON_URL } from '../../../config/serverKey';

const MetaData = () => {
    let magnetTitle = "Magnet"
    return (
        <div>
            <Helmet>
                <title>{magnetTitle}</title>
                <meta name="description" content={magnetTitle} />
                <meta property="og:title" content={magnetTitle}/>
                <meta property="og:description" content={magnetTitle} />
                <meta property="og:image" content={AMAZON_URL+"/logo.svg"} />
            </Helmet>
        </div>
    )
}

export default MetaData
