import React, { Component } from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getAutoCompleteProject, getRegion } from "../../dataParser/getProjectData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cleanStorage, priceConverter, priceReverser } from "../../helper";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

class UserFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			region: [],
			option: [],
			name: '',
			project_id: '',
			proj_id: '',
			region_ids: '',
			region_id: '',
			bhk: '',
			min_price: '',
			max_price: '',
			project_status: '',
			is_focus: '',
			fact_sheet:'',
			current_fy_ladder: '',
			ladder:''
		};
	}

	async componentDidMount(){

		var regionData = await getRegion();
        this.setState({region : regionData})

	}

	handleAutoChange = async (e) =>{
		this.setState({isLoading:true});
		let value = e.target.value

	    var projectListData = await getAutoCompleteProject(value);

	    if(projectListData.meta.status === 200){

			this.setState({isLoading:false});
	        this.setState({option : projectListData.data})
	    }else if(projectListData.meta.status === 401){

    		cleanStorage();
    		this.props.history.push("/login");

    	}else{

			this.setState({isLoading:false});
	        this.setState({option : []})
	    }

	}

	handleChange = event => {
		this.setState({[event.target.name]: event.target.value});
  	};
	onChange = async (e) => {

		await this.setState({ [e.target.name]: e.target.value });
	}
  	applyFilter= ()=>{

    	let filterData = {};

		if(this.state.project_id !== ''){
			if(this.props.location.pathname === "/focus-projects"){
				filterData["project_id"] = this.state.project_id;
			}
			else{
				filterData["ids"] = this.state.project_id;
			}
    	}

		if(this.state.proj_id !== ''){

        	filterData["ids"] = this.state.proj_id;
    	}

    	if(this.state.project_status !== ''){

        	filterData["is_active"] = parseInt(this.state.project_status);
    	}

    	if(this.state.is_focus !== ''){

        	filterData["is_focus"] = parseInt(this.state.is_focus);
    	}

		if(this.state.fact_sheet !== ''){

        	filterData["fact_sheet"] = parseInt(this.state.fact_sheet);
    	}

    	if(this.state.bhk !== ''){

        	filterData["bhk"] = parseInt(this.state.bhk);
    	}

		if (this.state.min_price !== '') {

			filterData["min_price"] = priceReverser(this.state.min_price);
		}

		if (this.state.max_price !== '') {

			filterData["max_price"] = priceReverser(this.state.max_price);
    	}

    	if(this.state.region_ids.length > 0){

        	filterData["region_ids"] = this.state.region_ids;
    	}

    	if(!!this.state.region_id){
        	filterData["region_id"] = this.state.region_id;
		}
		if(this.state.current_fy_ladder != ''){
			filterData['current_fy_ladder'] = this.state.current_fy_ladder;
		}
		if(this.state.ladder){
			filterData['ladder'] = this.state.ladder;
		}
    	this.props.changeFilter();
        this.props.filterData(filterData);

    }

  	resetFilter = () =>{

  		this.setState({
			autoKey: !this.state.autoKey,
  			option: [],
            project_name : '',
            project_id : '',
			proj_id : '',
            project_status : '',
            is_focus : '',
			fact_sheet : '',
			min_price: '',
			max_price: '',
            bhk : '',
			region_id: "",
			region_ids: ""
  		})
  	}

	render(){

		const {option,autoKey,isLoading,region,project_status,is_focus,fact_sheet, current_fy_ladder, ladder} = this.state;

		return(

			<Frag>
				<div className="container filter_lead filterMenuCard pt-3 p-0" ref={this.props.fltr}>
					<div className="row filterRow">
						{/*{this.props.location.pathname !== "/focus-projects" &&
							<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<TextField
								value={this.state.proj_id}
								id="proj_id"
								name="proj_id"
								label="Project Id"
								onChange={this.handleChange}
								defaultValue=""
								helperText=""
								/>
							</div>
						</div>
						}*/}
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<Autocomplete
								  key={autoKey}
							      id="projectData"
							      getOptionSelected={(option, value) => option.name === value.name}
							      getOptionLabel={option => option.name}
							      options={option}
							      loading={isLoading}
							      onChange={(_event, option) => {
							        if (option === null) {
							        	this.setState({
							        		option: [],
								        	project_id : '',
							        	})
							        }else{
								        var projectId = option?.id;
								        this.setState({
								        	project_id : projectId,
								        })
							        }

							      }}
							      renderInput={params => (
							        <TextField
							          {...params}
							          label="Project Name"
							          fullWidth
							          onChange={this.handleAutoChange}
							          InputProps={{
							            ...params.InputProps,
							            endAdornment: (
							              <React.Fragment>
							                {isLoading ? (
							                  <CircularProgress color="inherit" size={20} />
							                ) : null}
							                {params.InputProps.endAdornment}
							              </React.Fragment>
							            )
							          }}
							        />
							      )}
							    />
								<span id="firstName-error" className="error invalid-feedback">Please enter First Name</span>
							</div>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<Autocomplete
							  multiple = {this.props.location.pathname !== "/focus-projects"}
							  key={autoKey}
						      id="regions"
						      getOptionSelected={(region, value) => region.name === value.name}
						      getOptionLabel={region => region.name}
						      options={region}
						      renderTags={(value, getTagProps) =>
							    value.map((region, index) => (
							      <Chip label={region.name} {...getTagProps({ index })}/>
							    ))
							  }
						      onChange={(_event, region) => {
								if (region === null) {
									this.setState({
										region: [],
										region_id : '',
										region_ids : '',
									})
								}
								else{
									if(this.props.location.pathname === "/focus-projects"){
										this.setState({
											region_id : region?.id,
										})
									}
									else{
										var projectId = region.map((ops) => ops.id);
										this.setState({
											region_ids : projectId,
										})
									}
								}
						      }}
						      renderInput={params => (
						        <TextField
						          {...params}
						          label="Select Region"
						          fullWidth
						          onChange={this.handleAutoChange}
						          InputProps={{
						            ...params.InputProps,
						            endAdornment: (
						              <React.Fragment>
						                {params.InputProps.endAdornment}
						              </React.Fragment>
						            )
						          }}
						        />
						      )}
						    />
						</div>
						{this.props.location.pathname !== "/focus-projects" &&
						<Frag>
						{(window.$role !== "magnet") &&
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Project Status</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={project_status}
						          onChange={this.handleChange}
						          inputProps={{
						            name: 'project_status',
						            id: 'project_status',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Active</MenuItem>
									<MenuItem value="0">In-Active</MenuItem>
						        </Select>
							</FormControl>

						</div>
	                    }
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Focus</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={is_focus}
						          onChange={this.handleChange}
						          inputProps={{
						            name: 'is_focus',
						            id: 'is_focus',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Yes</MenuItem>
									<MenuItem value="0">No</MenuItem>
						        </Select>
							</FormControl>
						</div>
                            {/*<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
						        <TextField
						          value={this.state.min_price}
						          id="min_price"
						          name="min_price"
						          label="Min Price"
						          onChange={this.handleChange}
						          helperText=""
						        />
							</FormControl>

						</div>*/}
								<div className="col-lg-6 col-sm-6 col-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Min Price</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={this.state.min_price}
											onChange={this.onChange}
											inputProps={{
												name: 'min_price',
												id: 'min_price',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem selected="" value="20 Lacs">20 Lacs</MenuItem>
											<MenuItem value="25 Lacs">25 Lacs</MenuItem>
											<MenuItem value="30 Lacs">30 Lacs</MenuItem>
											<MenuItem value="35 Lacs">35 Lacs</MenuItem>
											<MenuItem value="40 Lacs">40 Lacs</MenuItem>
											<MenuItem value="45 Lacs">45 Lacs</MenuItem>
											<MenuItem value="50 Lacs">50 Lacs</MenuItem>
											<MenuItem value="55 Lacs">55 Lacs</MenuItem>
											<MenuItem value="60 Lacs">60 Lacs</MenuItem>
											<MenuItem value="65 Lacs">65 Lacs</MenuItem>
											<MenuItem value="70 Lacs">70 Lacs</MenuItem>
											<MenuItem value="75 Lacs">75 Lacs</MenuItem>
											<MenuItem value="80 Lacs">80 Lacs</MenuItem>
											<MenuItem value="85 Lacs">85 Lacs</MenuItem>
											<MenuItem value="90 Lacs">90 Lacs</MenuItem>
											<MenuItem value="1 Crores">1 Crores</MenuItem>
											<MenuItem value="1.2 Crores">1.2 Crores</MenuItem>
											<MenuItem value="1.5 Crores">1.5 Crores</MenuItem>
											<MenuItem value="2 Crores">2 Crores</MenuItem>
											<MenuItem value="2.3 Crores">2.3 Crores</MenuItem>
											<MenuItem value="2.6 Crores">2.6 Crores</MenuItem>
											<MenuItem value="3 Crores">3 Crores</MenuItem>
											<MenuItem value="3.5 Crores">3.5 Crores</MenuItem>
											<MenuItem value="4 Crores">4 Crores</MenuItem>
											<MenuItem value="4.5 Crores">4.5 Crores</MenuItem>
											<MenuItem value="5 Crores">5 Crores</MenuItem>
											<MenuItem value="10 Crores">10 Crores</MenuItem>
											<MenuItem value="20 Crores">20 Crores</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-lg-6 col-sm-6 col-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Max price</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={this.state.max_price}
											onChange={this.onChange}
											inputProps={{
												name: 'max_price',
												id: 'max_price',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem selected="" value="20 Lacs">20 Lacs</MenuItem>
											<MenuItem value="25 Lacs">25 Lacs</MenuItem>
											<MenuItem value="30 Lacs">30 Lacs</MenuItem>
											<MenuItem value="35 Lacs">35 Lacs</MenuItem>
											<MenuItem value="40 Lacs">40 Lacs</MenuItem>
											<MenuItem value="45 Lacs">45 Lacs</MenuItem>
											<MenuItem value="50 Lacs">50 Lacs</MenuItem>
											<MenuItem value="55 Lacs">55 Lacs</MenuItem>
											<MenuItem value="60 Lacs">60 Lacs</MenuItem>
											<MenuItem value="65 Lacs">65 Lacs</MenuItem>
											<MenuItem value="70 Lacs">70 Lacs</MenuItem>
											<MenuItem value="75 Lacs">75 Lacs</MenuItem>
											<MenuItem value="80 Lacs">80 Lacs</MenuItem>
											<MenuItem value="85 Lacs">85 Lacs</MenuItem>
											<MenuItem value="90 Lacs">90 Lacs</MenuItem>
											<MenuItem value="1 Crores">1 Crores</MenuItem>
											<MenuItem value="1.2 Crores">1.2 Crores</MenuItem>
											<MenuItem value="1.5 Crores">1.5 Crores</MenuItem>
											<MenuItem value="2 Crores">2 Crores</MenuItem>
											<MenuItem value="2.3 Crores">2.3 Crores</MenuItem>
											<MenuItem value="2.6 Crores">2.6 Crores</MenuItem>
											<MenuItem value="3 Crores">3 Crores</MenuItem>
											<MenuItem value="3.5 Crores">3.5 Crores</MenuItem>
											<MenuItem value="4 Crores">4 Crores</MenuItem>
											<MenuItem value="4.5 Crores">4.5 Crores</MenuItem>
											<MenuItem value="5 Crores">5 Crores</MenuItem>
											<MenuItem value="10 Crores">10 Crores</MenuItem>
											<MenuItem value="20 Crores">20 Crores</MenuItem>
										</Select>
									</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
										{/*    <TextField
						          value={this.state.bhk}
						          id="bhk"
						          name="bhk"
						          label="Bhk"
						          onChange={this.handleChange}
						          helperText=""
						        />*/}
										<InputLabel id="demo-controlled-open-select-label">BHK</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={this.state.bhk}
											onChange={this.onChange}
											inputProps={{
												name: 'bhk',
												id: 'bhk',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="1">1 BHK</MenuItem>
											<MenuItem value="2">2 BHK</MenuItem>
											<MenuItem value="3">3 BHK</MenuItem>
											<MenuItem value="4">4 BHK</MenuItem>
											<MenuItem value="5">5 BHK</MenuItem>
											<MenuItem value="6">6 BHK</MenuItem>
											<MenuItem value="7">7 BHK</MenuItem>
											<MenuItem value="8">8 BHK</MenuItem>
											<MenuItem value="9">9 BHK</MenuItem>
											<MenuItem value="10">10 BHK</MenuItem>
											<MenuItem value="101">Plot</MenuItem>
											<MenuItem value="102">Commercial</MenuItem>
										</Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">factsheet</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={fact_sheet}
						          onChange={this.handleChange}
						          inputProps={{
						            name: 'fact_sheet',
						            id: 'fact_sheet',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Yes</MenuItem>
									<MenuItem value="0">No</MenuItem>
						        </Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Ladder Doc (Current FY)</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={current_fy_ladder}
						          onChange={this.handleChange}
						          inputProps={{
						            name: 'current_fy_ladder',
						            id: 'current_fy_ladder',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Yes</MenuItem>
									<MenuItem value="0">No</MenuItem>
						        </Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Ladder Document</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={ladder}
						          onChange={this.handleChange}
						          inputProps={{
						            name: 'ladder',
						            id: 'ladder',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Yes</MenuItem>
									<MenuItem value="0">No</MenuItem>
						        </Select>
							</FormControl>
						</div>
						</Frag>
						}
					</div>
					<div className="col-md-12 bgCommon">
						<div className="row">
							<div className="col-sm-6 text-right col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
								</div>
							</div>
							<div className="col-sm-6 col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Frag>
		);
	};
};

export default withRouter(UserFilter)
