import React from 'react';
import EligibilityPercentages from "../component/EligibilityPercentages";

const EligibilityPercentagesPage = () => {
  return (
    <div className="content-wrapper">
        <EligibilityPercentages />
    </div>
  )
}

export default EligibilityPercentagesPage