import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import Loader from "../common/loader/loader";
import {
  getBookingDocuments,
  saveBookingDocuments,
  saveDocumentVerification,
  getDownloadLink,
  deleteBookingDocument
} from "../../dataParser/getListUserData";
import BookingDocPreviewer from "./bookingDocPreviewer";
import pdf2base64 from 'pdf-to-base64';
import PopUpAlert from "../common/sweetAlert/popUpAlert"
import ReactDOM from 'react-dom'
import SweetAlert from "../common/sweetAlert/sweetAlertSuccess";
import { REJECTION_REASON } from "../../config/constant";
import SelectOption from "../common/controls/SelectOption";
import InputField from "../common/controls/InputField";
import FormControl from "@material-ui/core/FormControl";
import { changeDate } from "../../helper";

export default class BookingDocumentsModal extends Component {
  constructor(props) {
    super();

    this.state = {
      showLoader: false,
      applicationForm: [],
      costSheet: [],
      swipeDetails: [],
      emailDetails: [],
      otherDocuments: [{ alias: "" }, { alias: "" }, { alias: "" }],
      documentVerification: {
        applicationForm: 0,
        costSheet: 0,
        swipeDetails: 0,
        emailDetails: 0,
        others_0: 0,
        others_1: 0,
        others_2: 0
      },
      emailConfirmation:null,
      showModal: false,
      View_file_path: false,
      alertShow: false,
      sweetShow: false,
      sweetTitle: "",
      docExten: 'pdf'
      , rejectionReason: []
      , rejectionComment: '',
      exception_booking_status: '',
      exception_booking_comment: '',
      deleteDocId: []
    };
  }

  componentDidMount() {
    this.getUploadedFiles();
  }

  getUploadedFiles = async () => {
    this.setState({ showLoader: true });
    let bookingDocuments = await getBookingDocuments(
      this.props.selectedBooking.id
    );

    let emailConfirmation = false;
    if (bookingDocuments.bookingDetails) {
      if(bookingDocuments.bookingDetails.email_confirmation == null){
        emailConfirmation = null;
      }
      else{
      emailConfirmation =
        bookingDocuments.bookingDetails.email_confirmation === 1 ? true : false;
      }
    }

    if (bookingDocuments.bookingDocs.length > 0) {
      let state = {
        applicationForm: [],
        costSheet: [],
        swipeDetails: [],
        otherDocuments: [],
        emailDetails: [],
        documentVerification: {},
        showLoader: false,
        emailConfirmation,
        exception_booking_status: bookingDocuments.bookingDetails.exception_booking_status,
        exception_booking_comment: bookingDocuments.bookingDetails.exception_booking_comment
      };
      if (this.props.selectedBooking.rejection_reason) {
        if (this.props.selectedBooking.rejection_reason.includes("Comment: ")) {
          let reject_reason = this.props.selectedBooking.rejection_reason.split("Comment: ");
          state.rejectionReason = reject_reason[0].split(", ");
          state.rejectionComment = reject_reason[1];
        } else {
          let reject_reason = this.props.selectedBooking.rejection_reason.split(", ");
          state.rejectionReason = reject_reason;
        }
      }
      bookingDocuments.bookingDocs.forEach(async (o, key) => {
        if (o.document_type === 1 && o.document_name !== null) {
          state.applicationForm.push({
            id: o.id,
            fileName: o.document_name,
            approvalStatus: o.approval_status,
            upload_date: o.created_at
          });
          state.documentVerification.applicationForm = o.approval_status;
        } else if (o.document_type === 2 && o.document_name !== null) {
          state.costSheet.push({
            id: o.id,
            fileName: o.document_name,
            approvalStatus: o.approval_status,
            upload_date: o.created_at
          });
          state.documentVerification.costSheet = o.approval_status;
        } else if (o.document_type === 3 && o.document_name !== null) {
          state.swipeDetails.push({
            id: o.id,
            fileName: o.document_name,
            approvalStatus: o.approval_status,
            upload_date: o.created_at
          });
          state.documentVerification.swipeDetails = o.approval_status;
        } else if (o.document_type === 0) {
          state.documentVerification[`others_${state.otherDocuments.length}`] = o.approval_status
          state.otherDocuments.push({
            id: o.id,
            fileName: o.document_name ? o.document_name : "",
            alias: o.document_alias ? o.document_alias : "",
            upload_date: o.created_at
          });
        } else if (o.document_type === 4 && o.document_name != null) {
          state.emailDetails.push({
            id: o.id,
            fileName: o.document_name,
            approvalStatus: o.approval_status,
            upload_date: o.created_at
          });
          state.documentVerification.emailDetails = o.approval_status;
        }
      });

      let count = state.otherDocuments.length;
      for (let i = 0; i < 3 - count; i++) {
        state.otherDocuments[state.otherDocuments.length] = {};
      }
      this.setState(state);
    } else {
      this.setState({
        applicationForm: [],
        costSheet: [],
        swipeDetails: [],
        otherDocuments: [{}, {}, {}],
        emailConfirmation,
        showLoader: false,
        emailDetails: [],
        exception_booking_status: bookingDocuments.bookingDetails.exception_booking_status,
        exception_booking_comment: bookingDocuments.bookingDetails.exception_booking_comment
      });
    }
  };

  onFileSelect = (e, key = null) => {
    if (e.target.files.length > 0) {
      if (key || key === 0) {
        let otherDocuments = [...this.state.otherDocuments];
        otherDocuments[key].fileName = e.target.files[0].name;
        otherDocuments[key].file = e.target.files[0];

        this.setState({ otherDocuments: otherDocuments });
      } else if (e.target.name === "applicationForm") {
        let { applicationForm } = this.state;
        let obj = {}
        obj.fileName = e.target.files[0].name;
        obj.file = e.target.files[0];
        applicationForm.push(obj);
        this.setState({ applicationForm });
      } else if (e.target.name === "costSheet") {
        let { costSheet } = this.state;
        let obj = {}
        obj.fileName = e.target.files[0].name;
        obj.file = e.target.files[0];
        costSheet.push(obj);
        this.setState({ costSheet });
      } else if (e.target.name === "swipeDetails") {
        let { swipeDetails } = this.state;
        let obj = {}
        obj.fileName = e.target.files[0].name;
        obj.file = e.target.files[0];
        swipeDetails.push(obj);
        this.setState({ swipeDetails });
      } else if (e.target.name === 'emailDetails') {
        let { emailDetails } = this.state;
        let obj = {}
        obj.fileName = e.target.files[0].name;
        obj.file = e.target.files[0];
        emailDetails.push(obj);
        this.setState({ emailDetails });
      }
    }
  };

  removeFile = async (documentType, key=null) => {
    if (documentType === "other") {
      let otherDocuments = [...this.state.otherDocuments];
      if (otherDocuments[key].id) {
        let deleteDocId = {idDelete: otherDocuments[key].id};
        let getRes = await deleteBookingDocument(deleteDocId, this.props.selectedBooking.id);
        if (getRes.meta.status === 200) {
          this.setState({
            sweetShow: true,
            sweetType: "success",
            sweetTitle: "Deleted successfully !!!"
          })
          otherDocuments[key] = {};
        }
      } else {
        otherDocuments[key] = {};
      }
      this.setState({ otherDocuments: otherDocuments });
    } 
    else if(documentType === "emailDetails" && key === null && this.state.emailDetails.length > 0)
    {
      const doc_id = this.state.emailDetails.map(value => value.id);
      let deleteDocId = { idDelete: doc_id };
      let getRes = await deleteBookingDocument(deleteDocId, this.props.selectedBooking.id);
      if (getRes.meta.status === 200) {
        this.setState({
          sweetShow: true,
          sweetType: "success",
          sweetTitle: "Deleted successfully !!!"
        })
      }
      this.setState({emailDetails:[]})
     }
    else {
      let document = [...this.state[documentType]];
      if(document[key]){
      let deleteDocId = { idDelete: document[key].id };
      if(deleteDocId.idDelete){
        let getRes = await deleteBookingDocument(deleteDocId, this.props.selectedBooking.id);
        if (getRes.meta.status === 200) {
          this.setState({
            sweetShow: true,
            sweetType: "success",
            sweetTitle: "Deleted successfully !!!"
          })
        }
      }
      document.splice(key, 1);
      let obj ={};
      obj[documentType] = document;
      this.setState(obj);
     }
    }
  };

  downloadFile = async (id, documentType = null) => {
    let url = "";
    if (id) {
      url = await getDownloadLink(id);
    } else if (documentType) {
      url = await getDownloadLink(this.state[`${documentType}`].id);
    }

    if (url.s3UploadResponse !== "https://s3.amazonaws.com/") {
      window.open(url.s3UploadResponse, "_blank");
    }
  };

  renderFileItem = (documentType, heading) => {
    
    return (
      <div className="form-group">
        <div className='row'>
          <label className="text-muted font-weight-bold" style={{marginLeft: 5}}>{heading}</label>
        </div>
        <div className='row'>
          {(this.props.documentModalType === "verify" && this.state[`${documentType}`].length != 0) &&
            <div className="col-lg-3 col-6 pl-5">
              <input
                className="form-check-input"
                type="checkbox"
                checked={
                  this.state.documentVerification[`${documentType}`] === 1
                }
                onClick={() => this.verifyDocument(1, `${documentType}`)}
              />
              <label
                onClick={() => this.verifyDocument(1, `${documentType}`)}
                className="form-check-label"
              >
                Accept
              </label>
            </div>}
          {(this.props.documentModalType === "verify" && this.state[`${documentType}`].length != 0) &&
            <div className="col-lg-3 col-6 pl-5">
              <input
                className="form-check-input"
                type="checkbox"
                checked={
                  this.state.documentVerification[`${documentType}`] === 2
                }
                onClick={() => this.verifyDocument(2, `${documentType}`)}
              />
              <label
                onClick={() => this.verifyDocument(2, `${documentType}`)}
                className="form-check-label"
              >
                Reject
              </label>
            </div>}
        </div>
        {this.state[`${documentType}`].length != 0 ? (
          this.state[`${documentType}`].map((item, index) => {
            return (
              <div className="row">
                <div
                  className={`${this.props.documentModalType === "verify" || this.props.documentModalType === 'viewDoc' ? `col-lg-6` : ``
                    } col-md-6 col-12`}
                >

                  {item.fileName}
                  {(this.props.documentModalType === "verify" || this.props.documentModalType === 'viewDoc') && item.fileName != null ? (
                    <span
                      className="fa-link"
                      onClick={() => this.downloadFile(item.id, null)}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="nav-icon ml-2"
                      />
                    </span>
                  ) : null}
                  {(this.props.documentModalType === "verify" || this.props.documentModalType === 'viewDoc') && item.fileName != null ? (
                    <span className="fa-link ml-1" onClick={() => this.viewDocData(item.id, null)}>
                    <FontAwesomeIcon icon={faEye} className="nav-icon" />
                    </span> 
                   ) : null}
                  {(this.props.documentModalType === "upload" && item.fileName !== '') ? (
                    <span
                      className="fa-link ml-1"
                      onClick={() => this.removeFile(`${documentType}`, index)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="nav-icon" />
                    </span>
                  ) : null}
                </div>
                {item.fileName !== '' ?
                  (<div className="col-md-6 col-12">
                    <span className="text-muted font-weight-bold">Upload Date: </span><span>{changeDate(item.upload_date)}</span>
                  </div>) : null}
              </div>
            )
          })
        ) : null}
        {this.props.documentModalType === "upload" ? (
          <>
            <input
              type="file"
              className="form-control-file"
              name={`${documentType}`}
              onChange={this.onFileSelect}
              inputProps={{
                multiple: true
              }}
              disabled={this.state[`${documentType}`].filter(x => !x.id).length >= 4}
            />
            {this.state[`${documentType}`].filter(x => !x.id).length >= 4 && <p style={{ color: "red" }}>**4 documents can be uploaded at a time</p>}
          </>
          )
          : (this.state[`${documentType}`].length == 0 ?
            (<div className="row">
              <div className="col-12">No file uploaded.</div>
            </div>) :
            null
          )}
      </div>
    );
  };

  verifyDocument = (approvedOrRejected, documentType) => {
    let documentVerification = { ...this.state.documentVerification };
    if(documentVerification[documentType] == 2 || documentVerification[documentType] == 1){
      approvedOrRejected = 0;
    }
    documentVerification[documentType] = approvedOrRejected;
    this.setState({ documentVerification });
    if(Object.values(documentVerification).every(x => x == 0 || x == 1)){
      this.setState({rejectionReason: [],rejectionComment: ""})
    }
  };

  saveSelectedDocuments = () => {
    if (this.state.emailConfirmation && Object.keys(this.state.emailDetails).length === 0) {
      this.setState({
        alertShow: true,
        alertType: "warning",
        alertMsg: "Email document fields are reqiured !!!",
      });
      return false;
    }
    this.setState({ showLoader: true }, async () => {
      let formData = new FormData();
      let params = {
        application_form: [],
        cost_sheet: [],
        swipe_details: [],
        others: [],
        email_confirmation: 0,
        email_document: [],
      }

      if (this.state.applicationForm.length > 0) {
        this.state.applicationForm.map(item => {
          if (item.id != null) {
            return false;
          }
          let obj =
          {
            id: item.id
              ? item.id
              : null,
            document_name: item.fileName
          }
          params.application_form.push(obj)
        })
      }
      if (this.state.costSheet.length > 0) {
        this.state.costSheet.map(item => {
          if (item.id != null) {
            return false;
          }
          let obj = {
            id: item.id ? item.id : null,
            document_name: item.fileName
          }
          params.cost_sheet.push(obj)
        })
      }

      if (this.state.swipeDetails.length > 0) {
        this.state.swipeDetails.map(item => {
          if (item.id != null) {
            return false;
          }
          let obj =
          {
            id: item.id
              ? item.id
              : null,
            document_name: item.fileName
          }
          params.swipe_details.push(obj)
        })
      }
      this.state.otherDocuments.map(o => {
        let obj = o.id
          ? {
            id: o.id,
            document_name: o.fileName,
            document_alias: o.alias
          }
          : {
            document_name: o.fileName,
            document_alias: o.alias
          };
        params.others.push(obj)
      })
      if(this.state.emailConfirmation == null){
        params.email_confirmation = null;
      }
      else{
      params.email_confirmation = this.state.emailConfirmation ? 1 : 0;
      }
      if (this.state.emailDetails.length !== 0) {
        this.state.emailDetails.map(item => {
          if (item.id != null) {
            return false;
          }
          let obj =
          {
            id: item.id
              ? item.id
              : null,
            document_name: item.fileName
          }
          params.email_document.push(obj)
        })
      }
      if (this.state.applicationForm.length != 0) {
        const l = this.state.applicationForm.length;
        for (let i = 0; i < l; i++) {
          formData.append(`application_form`, this.state.applicationForm[i].file);
        }
      }
      if (this.state.costSheet.length != 0) {
        const l = this.state.costSheet.length;
        for (let i = 0; i < l; i++) {
          formData.append(`cost_sheet`, this.state.costSheet[i].file);
        }
      }
      if (this.state.swipeDetails.length != 0) {
        const l = this.state.swipeDetails.length;
        for (let i = 0; i < l; i++) {
          formData.append(`swipe_details`, this.state.swipeDetails[i].file);
        }
      }
      this.state.otherDocuments.forEach((otherDocument, idx) => {
        if (otherDocument.file) {
          formData.append(`other_file_${idx}`, otherDocument.file);
        }
      });
      if (this.state.emailDetails.length !== 0) {
        const l = this.state.emailDetails.length;
        for (let i = 0; i < l; i++) {
          formData.append(`email_document`, this.state.emailDetails[i].file);
        }
      }
      formData.append("data", JSON.stringify(params));
      let getRes = await saveBookingDocuments(formData, this.props.selectedBooking.id);
      if (getRes.meta.status === 201) {
        this.setState({
          sweetShow: true,
          sweetType: "success",
          sweetTitle: "Uploaded successfully !!!"
        })
      }
      // await this.getUploadedFiles();
      this.setState({ showLoader: false });
      //this.props.getList();
    });
  };

  saveVerifiedDocuments = async () => {
    if (this.state.exception_booking_status && !this.state.exception_booking_comment) {
      this.setState({
        alertShow: true,
        alertType: "warning",
        alertMsg: "Exception booking comment fields are reqiured !!!",
      });
      return false;
    }
    this.setState({ showLoader: true });
    const { rejectionReason, rejectionComment } = this.state;
    let rejection_reason = (!!rejectionReason ? rejectionReason.join(", ") : "") + (rejectionComment ? "Comment: " + rejectionComment : "");
    let params = {
      rejectionReason:
        this.state.documentVerification.applicationForm === 2 ||
          this.state.documentVerification.costSheet === 2 ||
          this.state.documentVerification.swipeDetails === 2 || this.state.documentVerification.others_0 === 2 
          || this.state.documentVerification.others_1 === 2 || this.state.documentVerification.others_2 === 2
          || this.state.documentVerification.emailDetails === 2
          ? rejection_reason
          : null,
      emailConfirmation: this.state.emailConfirmation ? 1 : 0,
      ...this.state.documentVerification,
      exception_booking_status: this.state.exception_booking_status,
      exception_booking_comment: this.state.exception_booking_comment
    };
    // let { applicationForm, costSheet, emailConfirmation, emailDetails, others_0, others_1, others_2, swipeDetails, exception_booking_status, exception_booking_comment } = params;
    // if (applicationForm || costSheet || emailConfirmation || emailDetails || others_0 || others_1 || others_2 || swipeDetails || exception_booking_status) {
      let getRes = await saveDocumentVerification(params, this.props.selectedBooking.id);
      if (getRes.meta.status === 201) {
        this.setState({
          showLoader: false,
          sweetShow: true,
          sweetType: "success",
          sweetTitle: "Verified successfully !!!"
        })
      }

    //}
   /* else {
      this.setState({
        showLoader: false
        , alertShow: true
        , alertType: "error"
        , alertMsg: "Please accept or reject to verify!"
      })
    }*/
    // this.props.toggleDocumentModal();
  };

  onDocumentAliasChange = (e, key) => {
    let otherDocuments = [...this.state.otherDocuments];
    otherDocuments[key].alias = e.target.value;

    this.setState({ otherDocuments });
  };
  viewDocData = async (id, documentType = null) => {
    let url = "";
    if (id) {
      url = await getDownloadLink(id);
    } else if (documentType) {
      url = await getDownloadLink(this.state[`${documentType}`].id);
    }
    if (url.s3UploadResponse !== "https://s3.amazonaws.com/" && (url.s3UploadResponse.includes('.pdf') || url.filyType === '.pdf')) {
      pdf2base64(url.s3UploadResponse).then((response) => {
        this.setState({ 'View_file_path': response, docExten: 'pdf' });
        this.setState({ showModal: true });
      }).catch((error) => {
        console.error(error); //Exepection error....
      })
    } else {
      if (url.s3UploadResponse && url.s3UploadResponse !== "https://s3.amazonaws.com/") {
        this.setState({ 'View_file_path': url.s3UploadResponse, docExten: 'image' });
        this.setState({ showModal: true });
      }
    }
  }
  handleModalClose = () => {
    this.setState({ showModal: false });
  }
  handleClose = () => {
    this.setState({ alertShow: false });
  }
  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    if(!this.state.sweetTitle.includes("Deleted successfully"))
    {this.props.toggleDocumentModal();
      this.props.getList()
    }
  }
  render() {
    const { alertShow, alertType, alertMsg, sweetShow, sweetType, sweetTitle, rejectionReason, rejectionComment } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.toggleDocumentModal(false, null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="unselectable"
      >
        <Loader show={this.state.showLoader} />
        <BookingDocPreviewer
          showModal={this.state.showModal}
          handleModalClose={this.handleModalClose}
          ViewFilePath={this.state.View_file_path} docExten={this.state.docExten} 
          modalName ="Booking Docs Preview"/>

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.documentModalType === "verify"
              ? `Verify Documents`
              : `Upload Documents`}
            {this.props.documentModalType === "viewDoc" ? "View Documents" : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFileItem("applicationForm", "Application Form Document")}
          {this.renderFileItem("costSheet", "Cost Sheet Document")}
          {this.renderFileItem("swipeDetails", "Swipe details Document")}

          <div className="row mb-3">
            <div className="col-md-3 col-6">
              <label className="text-muted font-weight-bold">
                Email Confirmation
              </label>
            </div>

            {this.props.documentModalType === "verify" ? (
              <div className="col-1">
                {this.state.emailConfirmation ? "Yes" : "No"}
              </div>
            ) : (
              <>
                <div className="col-md-1 col-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.emailConfirmation ? true : false}
                    onChange={(e) => {this.setState({ emailConfirmation: e.target.checked ? true: false});
                       if(this.state.emailConfirmation){
                         this.removeFile("emailDetails");
                       }
                      }
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => {this.setState({ emailConfirmation: e.target.checked ? true: false});
                      if(this.state.emailConfirmation){
                      this.removeFile("emailDetails");
                      }
                     }
                    }
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-1 col-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.emailConfirmation == false ? true : false}
                    onChange={(e) => { this.setState({ emailConfirmation: e.target.checked ? false: true }); 
                     if(this.state.emailConfirmation){
                        this.removeFile("emailDetails");
                        }
                        if(!this.state.emailConfirmation && this.state.emailDetails.length == 0){
                          this.setState({emailConfirmation:null})
                        }
                      }
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => {
                      this.setState({ emailConfirmation: e.target.checked ? false: true });
                      if(this.state.emailConfirmation){
                        this.removeFile("emailDetails");
                        }
                        if(!this.state.emailConfirmation && this.state.emailDetails.length == 0){
                          this.setState({emailConfirmation:null})
                        }
                      }
                    }
                  >
                    No
                  </label>
                </div>
                {this.state.emailConfirmation && <div className="col-12" style={{ marginTop: -20 }}>
                  {this.renderFileItem("emailDetails", "")}
                </div>}
              </>
            )}
          </div>
          {this.props.documentModalType === 'verify' ? (
            <div style={{ marginTop: -30 }}>
              {this.renderFileItem("emailDetails", "")}
            </div>
          ) : ("")}
          <div className="form-group">
            <label className="text-muted font-weight-bold">
              Other Documents
            </label>
            {this.state.otherDocuments.map((otherDocument, key) => {
              return (
                <div className="row mb-3" key={key}>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Document Name"
                      onChange={e => this.onDocumentAliasChange(e, key)}
                      value={otherDocument.alias}
                      disabled={this.props.documentModalType === "verify" || otherDocument.id}
                    />
                  </div>
                  <div className="col-md-5">
                    {otherDocument.fileName ? (
                      <div key={key}>
                        {otherDocument.fileName}
                        {this.props.documentModalType === "verify" ? (
                          <span
                            className="fa-link"
                            onClick={() => this.downloadFile(otherDocument.id)}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="nav-icon ml-2"
                            />
                          </span>
                        ) : (
                          <span
                            className="fa-link ml-1"
                            onClick={() => this.removeFile("other", key)}
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="nav-icon"
                            />
                          </span>
                        )}
                        <div className="inline-block">
                          <span className="text-muted font-weight-bold font-small">Upload Date: </span><span className="font-small">{changeDate(otherDocument.upload_date)}</span>
                        </div>
                      </div>
                    ) : this.props.documentModalType === "upload" ? (
                      <input
                        key={key}
                        type="file"
                        className="form-control-file"
                        id="exampleFormControlFile1"
                        onChange={e => this.onFileSelect(e, key)}
                      />
                    ) : (
                      <div className="row">
                        <div className="col-12">No file uploaded.</div>
                      </div>
                    )}
                  </div>
                  {this.props.documentModalType == "verify" || this.props.documentModalType == "viewDoc" ?
                    <div className="col-md-1 col-4"><button className="m-t-15 mb-2" onClick={() => this.viewDocData(otherDocument.id)}>View</button></div> : ''}
                  {this.props.documentModalType == "verify" ?
                    <div className="col-md-1 col-4 ml-md-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={
                          this.state.documentVerification[`others_${key}`] === 1
                        }
                        onClick={() => {
                          this.verifyDocument(1, `others_${key}`)
                        }}
                      />
                      <label
                        onClick={() => this.verifyDocument(1, `others_${key}`)}
                        className="form-check-label"
                      >
                        Accept
                      </label>
                    </div>
                    : ""}
                  {this.props.documentModalType == "verify" ? <div className="col-md-1 col-4 ml-md-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        this.state.documentVerification[`others_${key}`] === 2
                      }
                      onClick={() => this.verifyDocument(2, `others_${key}`)}
                    />
                    <label
                      onClick={() => this.verifyDocument(2, `others_${key}`)}
                      className="form-check-label"
                    >
                      Reject
                    </label>
                  </div> : ""}
                </div>
              );
            })}
          </div>

          {this.props.documentModalType === "verify" &&
            (this.state.documentVerification.applicationForm === 2 ||
              this.state.documentVerification.costSheet === 2 ||
              this.state.documentVerification.swipeDetails === 2 || this.state.documentVerification.others_0 === 2 || this.state.documentVerification.others_1 === 2 
              || this.state.documentVerification.others_2 === 2  || this.state.documentVerification.emailDetails === 2) ? (
            <div className="row">
              <div className="col-12 mb-3 mt-2">

                <SelectOption
                  multiple
                  label="Select Rejection Reason"
                  name="rejectionReason"
                  value={rejectionReason}
                  options={REJECTION_REASON}
                  onChange={e =>
                    this.setState({ rejectionReason: e.target.value })
                  }
                />
                <FormControl className="mt-3">
                  <InputField
                    label="Enter Comment"
                    name="rejectionComment"
                    value={rejectionComment}
                    multiline={true}
                    onChange={e =>
                      this.setState({ rejectionComment: e.target.value })
                    }
                  />
                </FormControl>
              </div>
            </div>
          ) : null}
          {this.props.documentModalType === "verify" ? (
            <div className="row">
              <div className="col-md-3 col-6">
                <label className="text-muted font-weight-bold">
                  Exception Booking
                </label>
              </div>
              <div className="col-md-1 col-4 ml-md-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={
                    this.state.exception_booking_status ? 1 : 0
                  }
                  onClick={(e) => {
                    this.setState({ exception_booking_status: e.target.checked ? 1 : 0 })
                  }}
                />
                <label
                  onClick={(e) => this.setState({ exception_booking_status: e.target.checked ? 1 : 0 })}
                  className="form-check-label exeption-lable"
                >
                  Yes
                </label>
              </div>
              {this.state.exception_booking_status ? <div className="col-12 mb-3 mt-2">
                <FormControl className="mt-3">
                  <InputField
                    required
                    label="Enter Exception Comment"
                    name="exceptionBookingComment"
                    value={this.state.exception_booking_comment}
                    multiline={true}
                    onChange={e =>
                      this.setState({ exception_booking_comment: e.target.value })
                    }
                  />
                </FormControl>
              </div> : null}
            </div>
          ) : null}
          {this.props.documentModalType != 'viewDoc' &&
            <div className="row">
              <div className="col-md-12 col-sm-12 text-center col-12">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-success text-capitalize"
                    onClick={
                      this.props.documentModalType === "verify"
                        ? this.saveVerifiedDocuments
                        : this.saveSelectedDocuments
                    }
                  >
                    {this.props.documentModalType}
                  </button>
                </div>
              </div>
            </div>}
          <SweetAlert show={sweetShow} type={sweetType} title={sweetTitle} changeSweet={this.handleSweet} />
          <PopPortal open={alertShow} type={alertType} msg={alertMsg} handleClose={this.handleClose} hideTime={6000} />
        </Modal.Body>
      </Modal>
    );
  }
}
const PopPortal = (props) => {
  return ReactDOM.createPortal(
    <PopUpAlert {...props} />
    , document.getElementsByTagName('body')[0]
  )
}
