import React from 'react'
// import { Button } from 'react-bootstrap'
import { MyModal } from '../modal/MyModal'

export const Payment = ({show, changeModal, payment_url }) => {
    const customStyle = {
        width: "100%",
        height: "80vh"
    }
    return (
        <div>
            <MyModal size="md" title="Digital Spend" show={show} changeModal={changeModal}>
                <iframe src={payment_url} style={customStyle}></iframe>
                {/* <Button variant="danger" onClick={changeModal}>Close</Button>  */}
            </MyModal>
        </div>
    )
}
