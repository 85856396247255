import {get, post, patch,put, getComment, putEngageCall} from "../utils/methods.js";

export const addIvr = async (value) =>{
	let getIvrData = await post('/ivr',value)
	return  getIvrData;
}

export const getIvrList = async (value) =>{
	let getIvrData = await get('/ivr',value)
	return  getIvrData;
}

export const getIvrDetail = async (value) =>{
	let getIvrData = await get('/ivr/'+value)
	return  getIvrData;
}

export const updateIvr = async (id,value) =>{

	let getIvrData = await put('/ivr/'+id, value)
	// console.log('getIvrData ===>', getIvrData);
	return  getIvrData;
} 

export const getIvrLogList = async (value) =>{
	let getIvrData = await get('/ivrLog/ivr_logs',value);
	return  getIvrData;
}

export const getCliManagement = async (value) =>{
	let getCliData = await getComment('/callNumber?size=24&pageId=1',value);
	return  getCliData;
}

export const setCliManagement = async (id, value) =>{
	let setCliData = await putEngageCall('/callNumber/'+id,value);
	return  setCliData;
}