import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {getUserInfoData} from "../../dataParser/magnetUser";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import Loader from "../../component/common/loader/loader";
import FormHelperText from '@material-ui/core/FormHelperText';
import { cleanStorage } from '../../helper';

const validateAadhar = RegExp(/^\d{4}\d{4}\d{4}$/);

class ThirdStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader : false,
            user_details_id :'',
            pancard_no :'',
            adharcard_no :'',
            gst_no:'',
            adhar_file: [], 
            pen_file: [], 
            rera_no:'',
            pancard_img:'',
            adharcard_img:'',
            isError:false,
            sweetShow:false,
            type : "success",
            title : "",
            errors:{
                adharcard_no : '',
                pancard_no : '',
                p_message : '',
                pen_file : '',
                adhar_file : '',
            }, 
           
          };
    }

    componentDidMount(){
        let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
        if(homesfy_lg && homesfy_lg !== ""){
            let user = JSON.parse(homesfy_lg);
            this.setState({
                user_details_id: user.id,
            });
        }
    }

    componentWillUnmount(){
    }

    onChange = (f) => {

        this.setState({ [f.target.name]: f.target.value });
     }
   
    continue = e => {
        e.preventDefault();

    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    handleSubmit = async (e) =>{

       const addUserRequest = (({user_details_id,adhar_file,pen_file,pancard_no , adharcard_no ,  gst_no,  rera_no }) => ({
           user_details_id ,
           pancard_no ,
           adharcard_no ,
           gst_no,
           adhar_file,
           pen_file,
           rera_no,
       }))(this.state);

       let addUserData = {};
       addUserData.data = {};
       let mainData = {};

        if(addUserRequest.adhar_file != ''){
            addUserData.adhar_file = addUserRequest.adhar_file[0];             
        }

        if(addUserRequest.pen_file != ''){
            addUserData.pen_file = addUserRequest.pen_file[0];             
        }

        if(addUserRequest.pancard_no != ""){
            addUserData.data.pancard_no = addUserRequest.pancard_no;             
        }

        if(addUserRequest.adharcard_no != ""){
            addUserData.data.adharcard_no = addUserRequest.adharcard_no;             
        }

        if(addUserRequest.gst_no != ""){
            addUserData.data.gst_no = addUserRequest.gst_no;             
        }

        if(addUserRequest.rera_no != ""){
            addUserData.data.rera_no = addUserRequest.rera_no;             
        }

        addUserData.data.reg_stage = 2; 

        addUserData.data = JSON.stringify(addUserData.data);
        
        mainData.data = addUserData;
        mainData.id = addUserRequest.user_details_id;

       if(addUserRequest.adharcard_no != "" && validateAadhar.test(this.state.adharcard_no) && addUserRequest.pancard_no != '') {

           this.setState({showLoader:true}); 
           var addLeadRes = await getUserInfoData(mainData);

           if(addLeadRes.meta.status === 503){
               
                this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "error",    
                   title : "!!! Oooops Network Error..." 
                });
               
           }else if(addLeadRes.meta.status === 200){
               
               setTimeout(()=>{
                    this.setState({showLoader:false}); 
                    this.props.handleNext();
               },500)

           }else if(addLeadRes.meta.status === 208){
               
               this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "warning",    
                   title : "User with same number already exists!!!"               
               });
               
           }else if(addLeadRes.meta.status === 401){
               
               cleanStorage();
               this.props.history.push("/login");
               
           }else{
               
               this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "error",
                   title : "Something went wrong !!!" 
                });
                console.log(addLeadRes.meta.message); 
           }
       }else{

            this.setState({isError:true});

           if(this.state.pancard_no == ""){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       pancard_no:"*please enter valid Pancard number"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       pancard_no:""
                   }
               }))
           }
           
           if(this.state.adharcard_no == "" || validateAadhar.test(this.state.adharcard_no) === false){
               
               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       adharcard_no:"*please enter valid Aadhar number"
                   }
               }))
           }else{

               this.setState(prevState => ({
                   errors: {                
                       ...prevState.errors,    
                       adharcard_no:""
                   }
               }))
           }
       }
    }

    handleSweet=(type)=>{
        this.setState({sweetShow : !this.state.sweetShow});
    }

    handleInit() {
    }

    render()  {
        const {sweetShow,type,title} = this.state;

        return (

          <React.Fragment>
          <Container maxWidth="lg">
            <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
            <Loader show={this.state.showLoader}/>
            <div className="row">
                <form className="w-100">
                    {window.$role !== "magnet" &&
                    <>
                   <div className="row mb-3">
                        <div className="col-md-6">
                            <TextField label="PAN ID No."
                                required
                                error = {this.state.errors.pancard_no !== '' ? 'error' : ''}
                                value={this.state.pancard_no}
                                id="pancard_no"
                                helperText={this.state.errors.pancard_no !== '' ? this.state.errors.pancard_no : ''}
                                name="pancard_no"
                                onChange={this.onChange} 
                                inputProps={{
                                    maxLength:"10",
                                    id:"pen_card",
                                    className:"text-uppercase"
                                }}   
                            />
                        </div>
                        <div className="col-md-6 ">
                          <FormControl error={this.state.errors.pen_file !== '' && 'error'}>
                            <FilePond
                              ref={ref => (this.pond = ref)}
                              pen_file={this.state.pen_file}
                              labelIdle='Drag & Drop your PAN Image or <span class="filepond--label-action">Browse</span>'
                              allowMultiple={false}
                               server={{
                                    process: (fieldName, file, metadata, load) => {
                                        setTimeout(() => {
                                            load(Date.now())
                                        }, 1500);
                                    },
                                    load: (source, load) => {
                                        fetch(source).then(res => res.blob()).then(load);
                                    }
                                }}
                              oninit={() => this.handleInit()}
                              onupdatefiles={fileItems => {
                                this.setState({
                                  pen_file: fileItems.map(fileItem => fileItem.file),
                                });
                              }}
                            />
                            {this.state.errors.pen_file !== '' &&
                                <FormHelperText>{this.state.errors.pen_file}</FormHelperText>
                            }    
                          </FormControl>
                        </div>
                   </div>
                   <div className="row mb-3">
                        <div className="col-md-6">
                            <TextField id="aadhar_card" label="Aadhar No. (12 digit)" 
                                required
                                error = {this.state.errors.adharcard_no !== '' ? 'error' : ''}
                                id="adharcard_no"
                                name="adharcard_no"
                                value={this.state.adharcard_no}
                                helperText={this.state.errors.adharcard_no !== '' ? this.state.errors.adharcard_no : ''}
                                onChange={this.onChange}
                                inputProps={{
                                    maxLength:"12",
                                }}  
                            />
                        </div>
                        <div className="col-md-6 ">
                          <FormControl error={this.state.errors.adhar_file !== '' && 'error'}>
                           <FilePond
                              ref={ref => (this.pond = ref)}
                              adhar_file={this.state.adhar_file}
                              labelIdle='Drag & Drop your Aadhar Image or <span class="filepond--label-action">Browse</span>'
                              allowMultiple={false}
                               server={{
                                    process: (fieldName, file, metadata, load) => {
                                        setTimeout(() => {
                                            load(Date.now())
                                        }, 1500);
                                    },
                                    load: (source, load) => {
                                        fetch(source).then(res => res.blob()).then(load);
                                    }
                                }}
                              oninit={() => this.handleInit()}
                              onupdatefiles={fileItems => {
                                this.setState({
                                  adhar_file: fileItems.map(fileItem => fileItem.file)
                                });
                              }}
                            />
                            {this.state.errors.adhar_file !== '' &&
                                <FormHelperText>{this.state.errors.adhar_file}</FormHelperText>
                            } 
                          </FormControl>
                        </div>
                   </div>
                   </>
                    }
                   <div className="row mb-3">
                      <div className="col-md-6">
                          <TextField id="standard-basic" label="GSTIN (If Applicable)"
                          value={this.state.gst_no}
                          id="gst_no"
                          name="gst_no"
                          onChange={this.onChange}  />
                      </div>
                        <div className="col-md-6">
                            <TextField id="standard-basic" label="RERA No."
                            value={this.state.rera_no}
                            id="rera_no"
                            name="rera_no"
                           onChange={this.onChange}  />
                        </div>
                   </div>
                   <div className="row mb-2">
                        <div className="col-md-12 text-center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </div>
                        <div className="col-md-12 text-center mt-2">
                            {this.state.isError === true &&
                                <span className="text-danger">Above all fields are mandatory, Please fill the details*</span>
                            }
                        </div>
                   </div>
                </form>
            </div>
          </Container>
        </React.Fragment>

        );

    }
}

export default ThirdStep;