import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import AuditLeadForm from "../form/AuditLeadForm";

class AuditLeadModal extends Component {
    render() {

        return (
            <Frag>
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size="lg"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			        	Audit Lead
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			        <AuditLeadForm leadDetail={this.props.leadDetail} getList={this.props.getList} changeModal={this.props.changeModal}/>
			      </Modal.Body>
			    </Modal>
            </Frag>
        );
    };
};    

export default AuditLeadModal;