import React from 'react'
import {TextField} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';

function Input(props) {
	const {label, name, value, onChange, required, variant, icon, multiline, error, helperText, type, className, disabled, endIcon} = props
	return (
		<TextField
			type={type}
			className={className}
			required={required ? required : false}
			disabled={disabled ? disabled : false}
			variant={variant ? variant : "outlined"}
			label={label}
			name={name}
			value={value}
			onChange={onChange}	
			multiline={multiline?multiline:false}
			InputProps={icon ? {
				startAdornment: (
					<InputAdornment position="start">
						{icon}
					</InputAdornment>
				),
			}
			:
			endIcon &&
			{
				endAdornment: (
					<InputAdornment position='end'>
					  {endIcon}
					</InputAdornment>
				  ),
			}
		}
			error={error || false}		
			helperText={helperText || ''}
		/>
	)
}
			
export default Input