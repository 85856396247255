import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import AddLeadForm from "../form/addLeadForm.js";
import RevertLeadFrom from "../form/revertLead.js";
import CrossSaleLeadForm from "../form/crossSaleLeadForm.js";


class LeadModal extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const FROMS_TYPES = {
			"addLead": AddLeadForm,
			"revertLead": RevertLeadFrom,
			"crossLead": CrossSaleLeadForm,
		}

		const Form_name = FROMS_TYPES[this.props.fromType];

		return (
			<Frag>
				<Modal
					show={this.props.show}
					onHide={this.props.changeModal}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="unselectable"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{(this.props.fromType === 'addLead') ? 'Add Lead' : (this.props.fromType === 'crossLead' ? 'Cross Sale Lead' : (window.$user.group.toLowerCase() === 'sales' ? "Send for Reactivation" : "Send to Sales Team"))}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form_name changeModal={this.props.changeModal} getList={this.props.getList} from={this.props.from} leadId={this.props.leadId} />
					</Modal.Body>
				</Modal>
			</Frag>
		);
	};
};

export default LeadModal;