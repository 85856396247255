import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  getFacebookAds,
  getMagnetList,
  saveCampaignSplits,
  updateCampaignSplits,
  getCampaignSplitsByAdId,
  deleteCampaignSplit,
  // getCampaignSplits
} from "../../dataParser/getListUserData";

import Loader from "../../component/common/loader/loader";
import SweetWarnAlert from "../../component/common/sweetAlert/sweetAlertWarning";

import {REVERT_ROLES} from "../../config/constant";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faEdit} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { objEmpty } from "../../helper";
import ProjectSplit from "./ProjectSplit";
import Pagination from "react-js-pagination";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "capitalize"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

let pageSize = 8

export default class CampaignSplit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      isLoading: false,
      sweetWarnShow: false,
      deleteSplitKey: null,
      splits: {},
      facebookAds: [],
      allFacebookAds: [],
      magnets: [],
      selectedAd: {},
      fromDate: null,
      toDate: null,
      campaignSplitsByAdId: [],
      open: [],
      editAd: null,
      showSuccess: false,
      successMessage: "",
      showError: false,
      errorMessage: "",
      // campaignSplits: []
      activePage: 1,
      totalCount: 0
    };
  }

  componentDidMount() {
    this.getFacebookAds();
    // this.handleCampaignSplits();
  }
  /* handleCampaignSplits = async() => {
      this.setState({
        showLoader: true
      })
      let getData = await getCampaignSplits();
      this.setState({
        campaignSplits: getData.data,
        showLoader: false
      })
  } */
  changeDate = date => {
    if (date !== null && date !== undefined) return moment(date,'DD-MM-YYYY').format('YYYY-MM-DD') ;
    return null;
  }
  getFacebookAds = async () => {
    var d = new Date();
    let param = {
      to_date: `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`,
      from_date: `${d.getFullYear()}-${d.getMonth()}-${d.getDate() - 1}`
    };
    let facebookAds = await getFacebookAds(param);

    this.setState({
      allFacebookAds: facebookAds.data
        ? Object.values(facebookAds.data).length > 0
          ? facebookAds.data
          : []
        : [],
      facebookAds: facebookAds.data
        ? Object.values(facebookAds.data).length > 0
          ? facebookAds.data
          : []
        : [],
      showLoader: false
    });
  };

  filterAds = e => {
    this.setState({facebookAds: this.state.allFacebookAds});
  };

  onAdSelect = value => {
    this.setState({selectedAd: value, showLoader: true}, async () => {
      if(value) {
        let query = {};
        query.size = pageSize;
        query.pageId = 1
        let campaignSplitsByAdId = await getCampaignSplitsByAdId(value.id, query);
        this.setState({
          adId: value.id,
          campaignSplitsByAdId: campaignSplitsByAdId.data.data,
          totalCount: campaignSplitsByAdId.data.count,
          splits: {},
          showLoader: false
        });
      } else {
        this.setState({
          campaignSplitsByAdId: [],
          splits: {},
          showLoader: false
        });
      }

      this.resetCampaignSplits();
    });
  };

  getMagnets = (searchString = null) => {
    let params = {
      roles: REVERT_ROLES.MAGNET
    };

    if(searchString) {
      params.q = searchString;
      this.setState({isLoading: true}, async () => {
        let magnets = await getMagnetList(params);
        this.setState({
          magnets: magnets.data ? magnets.data : [],
          isLoading: false
        });
      });
    }
  };

  filterMagnets = (e, splitKey) => {
    let searchString = e.target.value;
    let splits = {...this.state.splits};
    splits[splitKey]["selectedMagnet"] = {id: null, name: searchString};

    this.setState({splits}, () => {
      this.getMagnets(searchString);
    });
  };

  addMagnetToSplit = () => {
    let splits = {...this.state.splits};
    let newSplit = Object.keys(splits).length + 1;

    splits[newSplit] = {
      isDeleted: false
    };

    this.setState({splits});
  };

  deleteSplit = () => {
    let splits = {...this.state.splits};
    splits[this.state.deleteSplitKey].isDeleted = true;

    this.setState({splits, deleteSplitKey: null, sweetWarnShow: false});
  };

  onMagnetSelect = (value, splitKey) => {
    let splits = {...this.state.splits};

    if(value) {
      splits[splitKey]["selectedMagnet"] = {id: value.id, name: value.name};
    } else {
      splits[splitKey]["selectedMagnet"] = {id: null, name: ""};
    }

    this.setState({splits});
  };

  onPercentageChange = (e, splitKey) => {
    let splits = {...this.state.splits};
    splits[splitKey]["percentage"] = e.target.value;

    this.setState({splits});
  };

  getPercentageTotal = (html = true) => {
    let total = 0;
    Object.values(this.state.splits).forEach(split => {
      if(!split.isDeleted) {
        total += split.percentage ? Number(split.percentage) : 0;
      }
    });

    if(html) {
      return (
        <span
          style={
            total !== 100
              ? {color: "#fc0606", fontWeight: "bold"}
              : {color: "#64c518", fontWeight: "bold"}
          }
        >
          Total - {total}
        </span>
      );
    } else {
      return total;
    }
  };

  onDateChange = (from, date) => {
    this.setState({[from]: date});
  };

  saveCampaignSplits = () => {
    if(this.state.fromDate > this.state.toDate) {
      this.setState({
        showLoader: false,
        showError: true,
        errorMessage: "From date should be less than end date"
      });
      return;
    }

    let totalPercentage = this.getPercentageTotal(false);

    if(
      totalPercentage === 100 &&
      this.state.selectedAd.id &&
      Object.values(this.state.splits).filter(o => o.isDeleted === false)
        .length > 0 &&
      this.state.fromDate &&
      this.state.toDate
    ) {
      let adInfo = this.state.selectedAd.name.split("_");
      let params = {
        ad_id: this.state.selectedAd.id,
        ad_name: this.state.selectedAd.name,
        adset_id: this.state.selectedAd.adset_id,
        campaign_id: this.state.selectedAd.campaign_id,
        campaign_type: 2,
        project_id: adInfo[0],
        nationality: adInfo[adInfo.length - 1],
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        magnets: []
      };

      if(this.state.editAd) {
        params.id = this.state.editAd.id;
      }

      Object.values(this.state.splits).forEach(split => {
        params.magnets.push({
          magnet_id: split.selectedMagnet.id,
          percentages: split.percentage
        });
      });

      this.setState(
        {showLoader: true, showError: false, errorMessage: ""},
        async () => {
          let response;
          if(this.state.editAd) {
            response = await updateCampaignSplits(params);
          } else {
            response = await saveCampaignSplits(params);
          }

          if(response.meta.status === 201 || response.meta.status === 200) {
            if(response.data.error) {
              this.setState({
                showLoader: false,
                showError: true,
                errorMessage: response.data.error.message
              });
            } else {

              let query = {};
              query.size = pageSize;
              query.pageId = this.state.activePage
              let campaignSplitsByAdId = await getCampaignSplitsByAdId(
                this.state.selectedAd.id, query
              );

              this.setState({
                campaignSplitsByAdId: campaignSplitsByAdId.data.data,
                totalCount: campaignSplitsByAdId.data.count,
                splits: {},
                fromDate: null,
                toDate: null,
                showLoader: false,
                showSuccess: true,
                successMessage: "Campaign split saved successfully."
              });
            }
          } else {
            this.setState({
              showLoader: false,
              showError: true,
              errorMessage: "Something went wrong."
            });
          }
        }
      );
    } else {
      if(totalPercentage !== 100) {
        this.setState({
          showError: true,
          errorMessage: "Split percentage total should be exactly 100."
        });
      } else {
        this.setState({
          showError: true,
          errorMessage: "Please fill all fields to save."
        });
      }
    }
  };

  setOpen = (newFlag, index) => {
    let open = {...this.state.open};
    open[index] = newFlag;

    this.setState({open});
  };

  deleteCampaignSplit = ad => {
    this.setState({showLoader: true}, async () => {
      let deleteCampaignSplitResponse = await deleteCampaignSplit(ad.id);

      if(
        deleteCampaignSplitResponse.data.error &&
        deleteCampaignSplitResponse.data.error.message === "Not allowed"
      ) {
        this.setState({
          showLoader: false,
          showError: true,
          errorMessage: "Deleting old campaign split is not allowed."
        });
      } else if(deleteCampaignSplitResponse.meta.status === 201) {

        let query = {};
        query.size = pageSize;
        query.pageId = this.state.activePage;
        let campaignSplitsByAdId = await getCampaignSplitsByAdId(ad.ad_id, query);

        this.setState({
          campaignSplitsByAdId: campaignSplitsByAdId.data.data,
          totalCount: campaignSplitsByAdId.data.count,
          showLoader: false,
          showSuccess: true,
          successMessage: "Campaign split deleted successfully."
        });

        this.resetCampaignSplits();
      }
    });
  };

  editCampaignSplit = ad => {
    let splits = {};
    ad.magnets.forEach((magnet, idx) => {
      splits[idx + 1] = {
        isDeleted: false,
        percentage: magnet.percentages,
        selectedMagnet: {
          id: magnet.magnet_id,
          name: magnet.magnet_name
        }
      };
    });

    this.setState({
      splits,
      fromDate: this.changeDate(ad.from_date),
      toDate: this.changeDate(ad.to_date),
      editAd: ad
    });
  };

  tomorrowsDate = () => {
    let d = new Date();
    d.setDate(d.getDate() + 1);
    return d;
  };

  resetCampaignSplits = () => {
    this.setState({
      splits: {},
      fromDate: null,
      toDate: null,
      editAd: null
    });
  };

  lessThanToday = dateToCheck => {
    if(this.state.editAd && new Date(dateToCheck) < new Date()) {
      return true;
    }
    return false;
  };

  getStartDateMin = () => {
    if(this.state.editAd) {
      return null;
    } else {
      let d = new Date();
      d.setDate(d.getDate() + 1);
      return d;
    }
  };

  handleCampaignSplits = async(id, params) => {
    this.setState({showLoader: true});
    let campaignSplitsByAdId = await getCampaignSplitsByAdId(id, params);
    console.log(id, "id", params, "params", campaignSplitsByAdId);
    if(campaignSplitsByAdId.meta.status === 200){
      this.setState({
        campaignSplitsByAdId: campaignSplitsByAdId.data.data,
        totalCount: campaignSplitsByAdId.data.count,
        splits: {},
        showLoader: false
      });
    }
    else{
      this.setState({
        campaignSplitsByAdId: [],
        splits: {},
        showLoader: false
      });
    }
  }
  
  handleChangePage= async (pageNumber)=>{
    this.setState({
      activePage: pageNumber
    })
    let listData = {};
    listData.size = pageSize;
    listData.pageId = pageNumber;
    
    this.handleCampaignSplits(this.state.adId, listData);
  }
  

  render() {
    const {selectedAd, activePage, totalCount} = this.state;
    return (
      <div className="p-1 p-lg-3 mt-3">
        <Loader show={this.state.showLoader} />

        <SweetWarnAlert
          show={this.state.sweetWarnShow}
          changeSweet={() =>
            this.setState({
              sweetWarnShow: false
            })
          }
          message="Are you sure you want to delete this split ?"
          handleUpdateUser={this.deleteSplit}
        />

        <SweetAlert
          success
          show={this.state.showSuccess}
          title="Success"
          onConfirm={() =>
            this.setState({
              showSuccess: false,
              successMessage: ""
            })
          }
          onCancel={() =>
            this.setState({
              showSuccess: false,
              successMessage: ""
            })
          }
        >
          {this.state.successMessage}
        </SweetAlert>

        <SweetAlert
          danger
          show={this.state.showError}
          title="Error"
          onConfirm={() =>
            this.setState({
              showError: false,
              errorMessage: ""
            })
          }
          onCancel={() =>
            this.setState({
              showError: false,
              errorMessage: ""
            })
          }
        >
          {this.state.errorMessage}
        </SweetAlert>

        <div className="row mb-3 mt-3">
          <div className="col-lg-7 col-12 text-left">
            <div className="form-group">
              <Autocomplete
                options={this.state.facebookAds}
                getOptionLabel={option => `${option.name} - ${option.id}`}
                onChange={(e, value) => this.onAdSelect(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search Ad"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-7 col-12 text-right">
            <button
              type="submit"
              className="btn btn-success"
              onClick={this.addMagnetToSplit}
              disabled={this.lessThanToday(this.state.fromDate)}
            >
              Add Split
            </button>
          </div>
        </div>

        {Object.keys(this.state.splits).map(splitKey =>
          this.state.splits[splitKey].isDeleted ? null : (
            <div className="row mt-2" key={splitKey}>
              <div className="col-lg-4 col-6 text-left">
                <div className="form-group">
                  <Autocomplete
                    options={this.state.magnets}
                    getOptionLabel={option => `${option.id} - ${option.name} (${option.phone})`}
                    onChange={(e, value) =>
                      this.onMagnetSelect(value, splitKey)
                    }
                    value={this.state.splits[splitKey].selectedMagnet?.name}
                    inputValue={
                      this.state.splits[splitKey].selectedMagnet?.name
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Search Magnet"
                        variant="outlined"
                        onChange={e => this.filterMagnets(e, splitKey)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                    disabled={this.lessThanToday(this.state.fromDate)}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-5 text-left">
                <div className="form-group">
                  <TextField
                    label="Percentage"
                    type="number"
                    variant="outlined"
                    error={
                      this.state.splits[splitKey]["percentage"] < 1 ||
                        this.state.splits[splitKey]["percentage"] > 100
                        ? true
                        : false
                    }
                    value={this.state.splits[splitKey]["percentage"]}
                    onChange={e => this.onPercentageChange(e, splitKey)}
                    InputProps={{inputProps: {min: 1, max: 100}}}
                    disabled={this.lessThanToday(this.state.fromDate)}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-1 text-left mt-2">
                {this.lessThanToday(this.state.fromDate) ? null : (
                  <span
                    onClick={e =>
                      this.setState({
                        sweetWarnShow: true,
                        deleteSplitKey: splitKey
                      })
                    }
                    style={{cursor: "pointer"}}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="fs-14" />
                  </span>
                )}
              </div>
            </div>
          )
        )}

        <div className="row mb-3">
          <div className="offset-lg-4 col-lg-6 offset-6 col-6 text-left">
            {this.getPercentageTotal()}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-2 col-6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                label="From"
                variant="inline"
                format="yyyy-MM-dd"
                minDate={this.getStartDateMin()}
                value={this.state.fromDate || null}
                autoOk={true}
                onChange={(key, date) => this.onDateChange("fromDate", date)}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                disabled={this.lessThanToday(this.state.fromDate)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-lg-2 col-6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                label="To"
                variant="inline"
                format="yyyy-MM-dd"
                minDate={
                  this.lessThanToday(`${this.state.toDate} 23:59:59`)
                    ? null
                    : this.state.editAd
                      ? new Date()
                      : this.state.fromDate
                }
                value={this.state.toDate || null}
                autoOk={true}
                onChange={(key, date) => this.onDateChange("toDate", date)}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                disabled={this.lessThanToday(`${this.state.toDate} 23:59:59`)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-1 text-left col-6">
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-success w-100"
                onClick={this.saveCampaignSplits}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="col-md-1 text-left col-6">
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-danger w-100"
                onClick={this.resetCampaignSplits}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-12 text-left">
            { ( selectedAd !== null && objEmpty(selectedAd) === false) ?
              (this.state.campaignSplitsByAdId.length ? (
                <>
              <TableContainer component={Paper} style={{maxHeight: 600}}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="tableCustom"
                  style={{minWidth: "650px"}}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell />
                      {Object.keys(this.state.campaignSplitsByAdId[0]).map(
                        (values, index) =>
                          values === "magnets" ||
                            values === "id" ||
                            values === "ad_id" ||
                            values === "ad_name" ? null : (
                              <StyledTableCell key={index} align="left">
                                {values.replace("_", " ")}
                              </StyledTableCell>
                            )
                      )}
                      <StyledTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.campaignSplitsByAdId.map((ad, index) => (
                      <>
                        <TableRow key={index}>
                          <StyledTableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                this.setOpen(!this.state.open[index], index)
                              }
                            >
                              {this.state.open[index] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                          </StyledTableCell>
                          {Object.keys(ad).map((key, idx) =>
                            key === "magnets" ||
                              key === "id" ||
                              key === "ad_id" ||
                              key === "ad_name" ? null : (
                                <StyledTableCell key={idx} align="left">
                                  {key === "campaign_type"
                                    ? Number(ad[key]) === 2
                                      ? "Facebook"
                                      : "Google"
                                    : key === "nationality"
                                      ? Number(ad[key]) === 1
                                        ? "Domestic"
                                        : "International"
                                      : key === 'from_date' || key === 'to_date' 
                                        ? this.changeDate(ad[key])
                                        : ad[key]}
                                </StyledTableCell>
                              )
                          )}
                          <StyledTableCell>
                            <span
                              onClick={e => this.editCampaignSplit(ad)}
                              style={{cursor: "pointer"}}
                              className="mr-3"
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="fs-14"
                              />
                            </span>
                            <span
                              onClick={e => this.deleteCampaignSplit(ad)}
                              style={{cursor: "pointer"}}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="fs-14"
                              />
                            </span>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{paddingBottom: 0, paddingTop: 0}}
                            colSpan={6}
                          >
                            <Collapse
                              in={this.state.open[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Table aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Magnet</TableCell>
                                      <TableCell>Magnet Name</TableCell>
                                      <TableCell align="right">
                                        Percentage
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {ad.magnets.map((magnet, ky) => (
                                      <TableRow key={ky}>
                                        <TableCell component="th" scope="row">
                                          {magnet.magnet_id}
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          {magnet.magnet_name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {magnet.percentages}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="row justify-content-end">
                {totalCount > pageSize &&
                  <div className="paginationBlock">
                      <Pagination
                        hideDisabled    
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={this.handleChangePage}
                    />
                  </div>
                }
              </div>
              </>
              ) : null)
              :
              <ProjectSplit splitType="facebook"/>
            }
          </div>
        </div>
      </div>
    );
  }
}
