import React, { Component } from "react";
import { HashRouter as Router } from "react-router-dom";
import BreakPoints from './core/Breakpoint';

import Drawer from "./Drawer";

class App extends Component {
    render() {
        return (
            <BreakPoints>
                <Router>
                    <Drawer />
                </Router>
            </BreakPoints>
        );
    }
}

export default App;
