import React, {Component} from 'react';
// import Frag from "../../../utils/Frag/Frag.js";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import {APP_BASE_URL} from "../../config/serverKey.js"
import queryString from 'query-string';
import {getEmailValidation} from "../../dataParser/magnetUser";

class EmailVerify extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            sweetShow:false,
            type : "success",
            title : "",
        }    
	}

	async componentDidMount(){
        console.log("verify-email >>>>>")
		const projectName  = queryString.parse(this.props.location.search);
        // console.log("lllllllllllllllllll",projectName);
        const procName = projectName.token;
        console.log(procName);
        let resData = await getEmailValidation(procName);

        	if(resData.meta.status === 503){
               
                this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "error",    
                   title : "!!! Oooops Network Error..." 
                });
               
           }else if(resData.meta.status === 201){
               
               // console.log("User Register successfully !!!");
               this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "success",    
                   title : "Email varification Done" 
                });
           }else{
                this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "error",
                   title : resData.meta.message 
                });
                console.log(resData.meta.message); 
           }
	}

	handleSweet=(type)=>{
        // console.log("type On the first =========>",type)
        this.setState({sweetShow : !this.state.sweetShow});
        window.location = APP_BASE_URL+"/login";
    }

	render() {

		const{sweetShow,type,title} = this.state;

		return (
			<div>
                <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
			</div>
		)
	}
}

export default EmailVerify		
