import React, {Component} from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import {withRouter} from "react-router-dom";
import FileBase64 from 'react-file-base64';
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import {getbulkLeadUpload} from "../../../dataParser/bulkLeadData";
import {autoComplete} from "../../../dataParser/commomDataApi";
import {getAutoCompleteProject} from "../../../dataParser/getProjectData";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import {REVERT_ROLES} from "../../../config/constant/index.js";
import Loader from "../loader/loader";
import PopUpAlert from "../sweetAlert/popUpAlert";
import ReactDOM from "react-dom"
import { cleanStorage } from '../../../helper';

class FileUpload extends Component {

	constructor(props) {
    	super(props);
        this.state = {
            showLoader : false,
            isLoading : false,
            isMagnetLoading : false,
            bulk_file: [], 
            option: [], 
            magnet: [], 
            project_id : "",
            magnet_id : "",
            isError:false,
            sweetShow:false,
            type : "success",
            title : "",
            err : ""
            , alertShow: false
            , uploading: false
	    };
    }

    componentDidMount(){
      console.log(this.props);
      this.setState({group : window.$user.group.toLowerCase()})
    }

    handleBulkUpload= async(e)=>{

    	 e.preventDefault();
       this.setState({
        showLoader:true
        , alertType: "success"
        , alertMsg: "Upload in process.."
        , alertShow: true
        , uploading: true
       });
    	let addUserData = {};
        addUserData.data = {};
        addUserData.data.file = {};

        if(this.state.bulk_file != ''){
            let strToReplace = this.state.bulk_file.base64;
            addUserData.data.file["filename"] = this.state.bulk_file.name;
            addUserData.data.file["base64"] = strToReplace.substr(strToReplace.indexOf(',') + 1);;
        }

        if(this.state.project_id !== ''){
            addUserData.data["project_id"] = this.state.project_id;
        }

        if(this.state.magnet_id !== ''){
            addUserData.data["magnet_id"] = this.state.magnet_id;
        }
        if(window.$user.role.toLowerCase() === 'magnet'){
          addUserData.data["magnet_id"] = window.$user.id;
        }
        if(this.state.bulk_file != '' && this.state.project_id != ''){
				
  		   	let addLeadRes = await getbulkLeadUpload(addUserData.data);

           	if(addLeadRes.meta.status === 200){
               
               this.setState({
                   showLoader:false,
                   sweetShow:true,
                   type : "success",    
                   title :"Total leads : "+addLeadRes.data.total+"  "+"Not inserted Leads : "+addLeadRes.data.failed                
               });

           	}else if(addLeadRes.meta.status === 401){
               
               cleanStorage();
               this.props.history.push("/login");
               
           	}else{
               
               this.setState({
                    showLoader:false,
                   sweetShow:true,
                   type : "error",
                   title : addLeadRes.meta.message
               });
           	}
        }else{

        	this.setState({isError:true});
        }

    }

    handleAutoProjectChange = async (e) =>{
        this.setState({isLoading:true});
        let value = e.target.value

        var regionData = await getAutoCompleteProject(value);

        if(regionData.meta.status === 200){

            this.setState({isLoading:false});
            this.setState({option : regionData.data})
        }
    }

    handleAutoMagnetChange = async (e) =>{
        this.setState({isMagnetLoading:true});
        let data = {
          q:e.target.value,
          roles:[REVERT_ROLES.MAGNET]
        };
        let resData = await autoComplete('users',data);

        if(resData.meta.status === 200){

            this.setState({isMagnetLoading:false});
            this.setState({magnet : resData.data})
        }

    }

    handleSweet=()=>{
        this.setState({sweetShow : !this.state.sweetShow});
    	  this.props.changeModal();
        if(this.props.getList){
          this.props.getList();
        }
    }

    handleInit = (files) =>{
        this.setState({isError:false});
        if(files.name.includes('.xlsx') || files.name.includes('.xls')){
          this.setState({bulk_file: files})

        }else{
          this.setState({isError:true});

        }
    }

    render() {

    	const {type,sweetShow,title,option,magnet,isLoading,isMagnetLoading, showLoader
        , alertShow, alertType, alertMsg, uploading} = this.state;
        
        return (
            <Frag>
	            <div className="bulkUpload">
                    <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
                <Loader show={showLoader} />
                <PopUpPortal type={alertType} msg={alertMsg} open={alertShow} handleClose={this.handleClose} hideTime={3000} />
                <form>
						<div className="card-body">
							<div className="row">
                                <div className="col-md-12 col-12 bulk_file mb-3">
                                    <span>Sample bulk upload file.</span>
                                    <a href = "https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/Sample.xlsx"><InsertDriveFileIcon/>Sample.xlsx</a>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <Autocomplete
                                              id="projectData"
                                              getOptionSelected={(option, value) => option.name === value.name}
                                              getOptionLabel={option => option.name}
                                              options={option}
                                              loading={isLoading}
                                              onChange={(_event, option) => {
                                                console.log(option);
                                                if (option === null) {
                                                    this.setState({
                                                        option: [],
                                                        project_id : '',
                                                    })
                                                }else{
                                                    var projectId = option.id;
                                                    this.setState({
                                                        project_id : projectId,
                                                    })
                                                }

                                              }}
                                              renderInput={params => (
                                                <TextField
                                                  {...params}
                                                  label="Project Name"
                                                  fullWidth
                                                  onChange={this.handleAutoProjectChange}
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                      <React.Fragment>
                                                        {isLoading ? (
                                                          <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                      </React.Fragment>
                                                    )
                                                  }}
                                                />
                                              )}
                                            />  
                                    </div>
                                </div>
                               {window.$user.role.toLowerCase() != 'magnet' &&  <div className="col-md-6 col-12 mb-3">
                                    <FormControl>
                                        <Autocomplete
                                          id="asynchronous-demo"
                                          getOptionSelected={(magnet, value) => magnet.name === value.name}
                                          getOptionLabel={magnet => magnet.name +" ("+magnet.phone+")"}
                                          options={magnet}
                                          loading={isMagnetLoading}
                                          onChange={(_event, magnet) => {
                                            if (magnet === null) {
                                                this.setState({magnet: [], magnet_id:'', rm_email:''})
                                            }else{
                                                this.setState({
                                                    magnet_id : magnet.id,
                                                    rm_email : magnet.email,
                                                })
                                            }
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Magnet"
                                              fullWidth
                                              onChange={this.handleAutoMagnetChange}
                                              InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                      <BusinessRoundedIcon />
                                                    </InputAdornment>
                                                  ),
                                                endAdornment: (
                                                  <React.Fragment>
                                                    {isMagnetLoading ? (
                                                      <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                  </React.Fragment>
                                                )
                                              }}
                                            />
                                          )}
                                        />
                                    </FormControl>    
                                </div>} 
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <FileBase64
                                            multiple={false}
                                            onDone={this.handleInit} 
                                        />
                                    </div>
                                </div>  
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button className="btn btn-success" onClick={this.handleBulkUpload} disabled={uploading ? true : false}>Submit</button>
									</div>
								</div>
								<div className="col-md-12 text-center mt-2">
		                            {this.state.isError === true &&
		                                <span className="text-danger">Please Add .xlsx or .xls file and select project*</span>
		                            }
		                        </div>
							</div>
						</div>
					</form>
	            </div>	
            </Frag>
        );
    };
};
const PopUpPortal = (props) =>{
  return ReactDOM.createPortal(
      <PopUpAlert {...props}/>
      , document.getElementsByTagName('body')[0]
  )
}
export default withRouter(FileUpload);     
