import React, { Component } from 'react';

class MetaBaseReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideBar: false,
            mobileSideBar: false,
        };

    }

    componentDidMount() {
        this.update();
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };

    changeButtonState(event) {
        this.setState({ sideBar: !this.state.sideBar })
    }

    changeSideBarState(event) {
        this.setState({ mobileSideBar: !this.state.mobileSideBar })
    }

    render() {

        return (
            <div>
                <div className={"content-wrapper " + (this.props.expand ? "main-header-collapsed" : "")}>
                    <div className="listContainer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <iframe
                                        src="#"
                                        frameborder="0"
                                        width="800"
                                        height="600"
                                        allowtransparency
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default MetaBaseReport;





















