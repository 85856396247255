import React, {Component} from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import {withRouter} from "react-router-dom";
import "./form.css";
import {getRegion, addProject, updateProject ,projectImages,deleteProjectImages } from "../../../dataParser/getProjectData";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomizedSnackbars from '../../autoLogout/snackBar';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Loader from "../../../component/common/loader/loader";
import FormControl from '@material-ui/core/FormControl';
import GoogleMaps from "../googleMap/googleMap.js";
import { nameCheck, letterCase, empty, cleanStorage} from "../../../helper";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button'
import PopUpAlert from "../sweetAlert/popUpAlert";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProjectPdfPreview from '../../../component/pdfGenrete/ProjectPdfPreview';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {projectActiveFocus, projectEditRole} from "../../../config/constant/crmRoleFunctionPermission"
import SelectOption from '../controls/SelectOption.js';
import { othersType } from '../datas/data.js';

const slug_reg = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
let ERROR_MSG  = "";
class addProjectFrom extends Component {

	constructor(props) {
    	super(props);
		this.state = {
			project_addedby : '',
      		builder_name : '',
      		project_name : '',
      		region : '',
      		region_id : '',
      		location : '',
      		project_id_99 : '',
            rera_no:'',
            possession_date: '',
            land_area:'',
            towers:'',
            elevation_struc:'',
            ivr_no:'',
            map:'',
            overview:'',
            video_link:'',
            site_url:'',
            housing_project_id:null,
            project_id: this.props.updateProjectId,
            data: this.props.projectDetail,
            isDateSelect: false,
            alertShow: false,
            type : "success",
            title : "",
            isDisabled: false,
            possession:'',
            btnText:'submit',
            error : "",
            // errorInput: false,
            isError : {
            	project_name : '',
            	builder_name : '',
            	location : '',
            	project_id_99 : '',
            	region_id : ''
            },
			whatsapp_event: false,
			is_adwords:false,
			fact_sheet:false,
			is_masks:false,
			previewProjectId:false,
			showModal:false,
			otherFields: [{lable: "", value: "", type: "faq"}],
			banerImages: {},
			projectBrochure: {},
			highlightsImages: {},
			galaryImages: [],
			removeMultipulImg:[],
			clickId:0,
			is_active : false,
			is_focus: false,
			mgProjStatus: this.props.mgProjStatus,
			externalUserData: [{name: "", number: "",designation: ""}]
			, slab_min: null
			, slab_max: null,
			is_dashboard:false
			, floorPlanData: [{floorImages: {}, tag: ""}]
			, slug: ""
		};
	}

	async componentDidMount(){

		await this.setState({
			builder_name: this.state.data?.builder_name || '',
			project_id: this.state.data?.id || '',
			project_name: this.state.data?.name || '',
			location: this.state.data?.location || '',
			is_active: this.state.data?.is_active || false,
			is_focus: this.state.data?.is_focus || false,
			project_id_99: this.state.data?.project_id_99 || '',
	        rera_no: this.state.data?.rera_no || '',
	        possession : this.state.data?.ready_to_move ? 'ready' : (this.state.data?.possession_date ? 'date' : '') ,
	        possession_date:  this.state.data?.possession_date || null,
	        land_area: parseInt(this.state.data?.land_area) || '',
	        towers: this.state.data?.tower_no || '',
	        elevation_struc: this.state.data?.storeys || '',
			ivr_no: this.state.data?.ivr_no || '',
			map: this.state.data?.map || '',
			overview: this.state.data?.overview || '',
			video_link: this.state.data?.video_link || '',
			site_url: this.state.data?.site_url || '',
			housing_project_id: this.state.data?.housing_project_id || null,
			whatsapp_event:this.state.data?.whatsapp_event || false,
			is_masks:this.state.data?.is_masks || false,
			fact_sheet:this.state.data?.fact_sheet || false,
			is_adwords : this.state.data?.is_adwords || false,
			otherFields:this.state.data?.others ? JSON.parse(this.state.data?.others):[{lable: "", value: "", type: "faq"}] ,
			externalUserData:this.state.data?.external_project_rm ? JSON.parse(this.state.data?.external_project_rm):[{name: "", number: "",designation: ""}]
			, slab_min: this.state.data?.slab_min || ''
			, slab_max: this.state.data?.slab_max || '',
			is_dashboard:this.state.data?.is_dashboard || false
			, slug: this.state.data?.slug || ''

		});

		let floorPlanDatas = [];
		if(this.state.data && this.state.data.ProjectImages.length){
			let gallaryImges = [];
			this.state.data.ProjectImages.map((item) => {
				if(item.type === 'baner'){
					this.setState({banerImages:{id:item.id,name:item.filename}});	
				}else if(item.type === 'brochure'){
					this.setState({projectBrochure:{id:item.id,name:item.filename}});
				}else if(item.type === 'highlight'){
					this.setState({highlightsImages:{id:item.id,name:item.filename}});
				}else if(item.type === 'gallary'){
					gallaryImges.push({id:item.id,name:item.filename});
				}
				else if(item.type === 'floor_plans'){
					floorPlanDatas.push({floorImages: {fileName:item?.filename || "", id: item.id}, tag: item?.image_tags || ""});
				}
			});
			if(floorPlanDatas.length === 0){
				floorPlanDatas = [...this.state.floorPlanData]
			}
			this.setState({galaryImages:gallaryImges, floorPlanData: floorPlanDatas});
		}

		var regionData = await getRegion();
        this.setState({region : regionData})
     
        this.setState({
			region_id: this.state.data?.Region?.id || '',
		})

		var homesfy_lg =(localStorage.getItem("homesfy_lg"));
		let addedBy;
		if(homesfy_lg && homesfy_lg !== ""){
	        let user = JSON.parse(window.atob(homesfy_lg));
	        addedBy = user.id;
	        this.setState({project_addedby : addedBy})

	    }else{
	        addedBy = '';
	        this.setState({project_addedby : addedBy})
	    }
	}

	onChange = async (e) => {
	    await this.setState({ possession: e.target.value });
 	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

 	formValid = ({ isError, ...rest }) => {
      let isValid = false;

      if (empty(rest.builder_name) ||empty(rest.project_name) || empty(rest.region_id) || empty(rest.location)){
        ERROR_MSG = "Above all * fields are reqiured !!!"
		return false;
      }
	  if(rest.slug !== "" && !slug_reg.test(rest.slug)){
		  ERROR_MSG = "Please enter valid slug"
		  return false
	  }

      Object.values(isError).forEach(val => {
          if (val.length > 0) {
              return false;
          } else {
              isValid = true
          }
      });
      return isValid;
  };


 	formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let isError = { ...this.state.isError };

        switch (name) {
            case "builder_name":
                isError.builder_name =
                    value.length < 4 && nameCheck(value) ? "Atleast 4 characaters required" : "";
                break;
            case "project_name":
                isError.project_name =
                    value.length < 4 ? "Atleast 4 characaters required" : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
        })
		if(name === "overview"){
        	this.setState({
	        	[name]: value
	        })
		}
        else if(typeof value !== 'number'){
	        this.setState({
	        	[name]: letterCase(value,true)
	        })
        }else{
        	this.setState({
	        	[name]: (value)
	        })
        }	
    }

 	addNewProject = async (e) => {
        e.preventDefault();
        this.setState({
			showLoader:true,
		})	
		let {builder_name, project_name, project_addedby, location, region_id, is_active, rera_no, land_area, towers, elevation_struc,ivr_no, map, overview, video_link, site_url, housing_project_id, project_id_99, possession_date, possession,whatsapp_event,fact_sheet, is_focus,is_masks} = this.state
        if(this.state.fact_sheet){
			if(this.state.banerImages.file){
				this.setState({
					showLoader : false,
					alertShow: true,
					type: "warning",
					title : "Banner Image fields are reqiured !!!",
				});
				return false;			
			}
			if(this.state.highlightsImages.file){
				this.setState({
					showLoader : false,
					alertShow: true,
					type: "warning",
					title : "Highlights Image fields are reqiured !!!",
				});
				return false;			
			}
		}
		if(this.state.fact_sheet || this.state.galaryImages.length){
			if([0,3,6].indexOf(this.state.galaryImages.length) === -1){
				let message = this.state.galaryImages.length === 0 ? 'Gallery Images fields are reqiured !!!' : 'Gallery Images fields select only 3 either 6 images !!!' 
				this.setState({
					showLoader : false,
					alertShow: true,
					type: "warning",
					title : message,
				});
				return false;			
			}
		}
		if (this.formValid(this.state)) {
            let data = {}

              if (builder_name) { data["builder_name"] =  builder_name}
              if (project_name) { data["name"] =  project_name}
              if (project_addedby) { data["created_by"] =  project_addedby}
              if (location) { data["location"] =  location}
              if (region_id) { data["region_id"] =  region_id}
              if (is_active) { data["is_active"] =  is_active}
			  if (is_focus) { data["is_focus"] = is_focus }
              if (rera_no) { data["rera_no"] =  rera_no}
              if (land_area) { data["land_area"] =  parseInt(land_area)}
              if (towers) { data["tower_no"] =  parseInt(towers)}
              if (elevation_struc) { data["storeys"] =  parseInt(elevation_struc)}
              if (ivr_no) { data["ivr_no"] =  ivr_no}
              if (map) { data["map"] =  map}
              if (overview) { data["overview"] =  overview}
              if (video_link) { data["video_link"] =  video_link}
              if (site_url) { data["site_url"] =  site_url}
              if (housing_project_id) { data["housing_project_id"] =  housing_project_id}
              if (project_id_99) { data["project_id_99"] =  parseInt(project_id_99)}
				if (possession === 'date'){
				   data.possession_date = possession_date
				}
				else{
				  data.ready_to_move =  possession
				}
			data['whatsapp_event'] = whatsapp_event ? 1 : 0;
			data['fact_sheet']	= fact_sheet ? 1 : 0 ;
			data['is_adwords']  = this.state.is_adwords ? 1 : 0 ;
			data['is_masks']  = this.state.is_masks ? 1 : 0 ;
			data['others'] = this.state.otherFields;
			data['external_project_rm'] = this.state.externalUserData;

			if(this.state.slab_min) data['slab_min'] = this.state.slab_min;
			if(this.state.slab_max) data['slab_max'] = this.state.slab_max;
			if(this.state.slug) data['slug'] = this.state.slug;
			
            let addLeadRes = await addProject(data);
			if(addLeadRes.meta.status === 201){
               await this.uploadProjectImages(addLeadRes.data.id);
               await this.setState({
                    showLoader:false,
                    alertShow: true,
					isDisabled: true,
					btnText:'submitted',
					type: "success",
                    title : "Project Added successfully !!!",
					project_id:addLeadRes.data.id     
                });

               this.props.setProjectId(addLeadRes.data.id)
           } 
			else {
			    this.setState({
			        showLoader: false,
			        alertShow: true,
			        type: "error",
			        title: addLeadRes.meta.message
			    });
			}
           
       }else {
            this.setState({
	    		showLoader : false,
				alertShow: true,
				type: "warning",
	    		title : ERROR_MSG,
	    	})
        }
    }

	updateProjectForm = async (e) => {
		const {is_active, is_focus} = this.state
		e.preventDefault();
		let newGalleryImg = this.state.galaryImages.filter((item) => item.hasOwnProperty('file'));
		let oldGalleryImg = this.state.galaryImages.filter((item) => item.hasOwnProperty('id')); 
		
		if(this.state.fact_sheet || newGalleryImg.length){
			let isValid = false;
			let message = this.state.galaryImages.length === 0 ? 'Gallery Images fields are reqiured !!!' : 'Gallery Images fields select only 3 either 6 images !!!';
			if(newGalleryImg.length && oldGalleryImg.length === 6 || (newGalleryImg.length === 6 && oldGalleryImg.length === 3)){
				message = 'Please first remove gallery old images';
				isValid = true;
			}
			if(oldGalleryImg.length === 0){
				if([3,6].indexOf(this.state.galaryImages.length) === -1){
					message = this.state.galaryImages.length === 0 ? 'Gallery Images fields are reqiured !!!' : 'Gallery Images fields select only 3 either 6 images !!!' 
					isValid = true;			
				}
			}
			if(isValid){
				this.setState({
					showLoader : false,
					alertShow: true,
					type: "warning",
					title : message,
				});
				return false;
			}
		}
		if (this.formValid(this.state)) {
			let mainData = {};
			let data = {};
			if (this.state.builder_name !== '') data['builder_name'] = this.state.builder_name;
			if (this.state.project_name !== '') data['name'] = this.state.project_name;
			if (this.state.project_addedby !== '') data['created_by'] = this.state.project_addedby;
			if (this.state.location !== '') data['location'] = this.state.location;
			if (this.state.region_id !== '') data['region_id'] = this.state.region_id;
			data['is_active'] = is_active ? true : false;
			data['is_focus'] = is_focus ? true : false;
			if (this.state.ivr_no !== '') data['ivr_no'] = this.state.ivr_no
	        if (this.state.rera_no !== '') data['rera_no'] = this.state.rera_no
	        if (this.state.land_area !== '') data['land_area'] = parseInt(this.state.land_area)
	        if (this.state.towers !== '') data['tower_no'] = parseInt(this.state.towers)
	        if (this.state.elevation_struc !== '') data['storeys'] = parseInt(this.state.elevation_struc)
	        if (this.state.possession_date !== null) data['possession_date'] =  this.state.possession_date
	        if (this.state.map !== '') data['map'] = this.state.map
	        if (this.state.overview !== '') data['overview'] = this.state.overview
	        if (this.state.video_link !== '') data['video_link'] = this.state.video_link
	        if (this.state.site_url !== '') data['site_url'] = this.state.site_url
	        if (this.state.housing_project_id !== '') data['housing_project_id'] = this.state.housing_project_id
	        if (this.state.project_id_99 !== '') data['project_id_99'] = parseInt(this.state.project_id_99)			
            if (this.state.possession === 'date'){
               data.possession_date = this.state.possession_date
            }
            else
              data.ready_to_move =  this.state.possession;
			data['whatsapp_event'] = this.state.whatsapp_event ? 1 : 0;
			data['fact_sheet']	= this.state.fact_sheet ? 1 : 0 ;  
			data['is_adwords']  = this.state.is_adwords ? 1 : 0 ;
			data['is_masks']  = this.state.is_masks ? 1 : 0 ;
			data['is_dashboard'] = this.state.is_dashboard ? 1:0;
			mainData['data'] = data;
			mainData['id'] = this.state.project_id;
			if(this.state.mgProjStatus){
				data['magnet_project_status'] = 1
			}
			if(this.state.slab_min) data['slab_min'] = this.state.slab_min;
			if(this.state.slab_max) data['slab_max'] = this.state.slab_max;
			if(this.state.slug) data['slug'] = this.state.slug;

			this.setState({
				showLoader: true,
			})
			//
			data['others'] = this.state.otherFields;
			data['external_project_rm'] = this.state.externalUserData;
			let resData = await updateProject(mainData);

			if (resData.meta.status === 201) {
				await this.uploadProjectImages(this.state.project_id);
				this.setState({
					showLoader: false,
					alertShow: true,
					// isDisabled: true,
					// btnText:'updated',
					type: "success",
					title: "Project Updated successfully"
				});
			} else if (resData.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					alertShow: true,
					type: "error",
					title: resData.meta.message
				});
			}
		} else {
			this.setState({
				showLoader: false,
				alertShow: true,
				type: "warning",
				title: ERROR_MSG,
			})
		}
	};

 	handleGeoLocation = async (location) => {

 		this.setState({location:location});
 	}
	handleChange = async (event,checked,type) => {
		switch (type) {
			case 'whatsapp':
				if(checked){
					this.setState({whatsapp_event:1});
				}else{
					this.setState({whatsapp_event:0});
				}
				break;
			case 'fact_sheet':
				if(checked){
					this.setState({fact_sheet:1});
				}else{
					this.setState({fact_sheet:0});
				}
				break;
			case 'adwords':
				if(checked){
					this.setState({is_adwords:1});
				}else{
					this.setState({is_adwords:0});
				}
				break;	
			case 'masks':
			if(checked){
				this.setState({is_masks:1});
				}else{
					this.setState({is_masks:0});
				}
				break;
			case 'status':
				if(checked){
					this.setState({is_active: true});
				}else{
					this.setState({is_active: false});
				}
				break;
			case 'is_dashboard':
				if(checked){
					this.setState({is_dashboard: true});
				}else{
					this.setState({is_dashboard: false});
				}
				break;	
			case 'focus':
				if(checked){
					const {rera_no, site_url, data } = this.state
					if( rera_no && site_url && data?.ProjectMeta.length > 0){
						this.setState({is_focus: true});
					}
					else{
						this.setState({
							type: "error",
							alertShow: true,
							title: "Please update RERA, Project Website and Floor Plan first!!!",
						});
					}
				}else{
					this.setState({is_focus: false});
				}
				break;	
			default:
				break;
		}	
	}

    handleClose = () => {
        this.setState({ alertShow: false });
    }

	handleModalClose = () => {
        this.setState({ showModal: false,clickId:0 });
    }
	pdfPreview = (projectId) => {
		let clickId = Math.floor((Math.random() * 10) + 1);
        this.setState({ showModal: true ,previewProjectId:projectId,clickId:clickId});
    } 
	handleChangeInput = (i, event) => {
		let val = [...this.state.otherFields]
		const { name, value } = event.target
		val[i][name] = value
		this.setState({ otherFields: val })
	}
	addOtherField= async(index) => {
		if(this.state.otherFields.length === 10){
			this.setState({
		        alertShow: true,
		        type: "warning",
		        title: "add only 10 others fields for factsheet !"
		    });
		}
		const val = [...this.state.otherFields];
		var i = val.length - 1;
	    if (val[i].value !== "") {
			const values = [...this.state.otherFields];
			values.push({lable: "", value: "", type: "faq"});
			this.setState({ otherFields: values });
		}else {
		    this.setState({
		        alertShow: true,
		        type: "warning",
		        title: "Please fill details !"
		    });
		}
	}
	handleRemoveInput = async (i) => {
		
		if(this.state.otherFields.length === 1){
			return false;
		}
		const values = [...this.state.otherFields];
		values.splice(i, 1);
		this.setState({ otherFields: values });
    }
	ExternaluserInput = (i, event) => {
		let val = [...this.state.externalUserData]
		const { name, value } = event.target
		if(typeof value !== 'referalLeadId'){
			this.setState({
				[name]: letterCase(value,true)
			})
		}
		val[i][name] = value
		this.setState({ externalUserData: val })
	};
	externalRemoveInput = async (i) => {		
		if(this.state.externalUserData.length === 1){
			return false;
		}
		const values = [...this.state.externalUserData];
		values.splice(i, 1);
		this.setState({ externalUserData: values });
    };
	addExternaluserField= async(index) => {		
		const val = [...this.state.externalUserData];
		var i = val.length - 1;
	    // if (val[i].name !== "" && val[i].number !== "" && val[i].designation != "") {
	    if (val[i].value !== "") {
			const values = [...this.state.externalUserData];
			values.push({name: "", number: "", designation: ""});
			this.setState({ externalUserData: values });
		}else {
		    this.setState({
		        alertShow: true,
		        type: "warning",
		        title: "Please fill details !"
		    });
		}
	};
	floorPlanInput = (i, e) => {
		let val = [...this.state.floorPlanData]
		const { name, value, files } = e.target;
		if(name === "floorImages")
		{
			val[i]["floorImages"].fileName = files[0].name;
			val[i]["floorImages"].file = files[0];
		}
		if(name ==="tag") val[i]["tag"] = value;
		this.setState({ floorPlanData: val })
	};
	removeFloorPlan = async (i, id) => {	
		if(this.state.floorPlanData.length === 1){
			return false;
		}
		const values = [...this.state.floorPlanData];
		if(id){
			this.setState({showLoader: true})
			let deleteImages = await deleteProjectImages({id:id});
			if(deleteImages.meta.status === 201){
				this.setState({
					showLoader: false,
					alertShow: true,
					type: "success",
					title: "Project image deleted successfully"
				});
			}
		}
		values.splice(i, 1);
		this.setState({ floorPlanData: values });
    };
	addFloorPlan = async(index) => {		
		const val = [...this.state.floorPlanData];
		var i = val.length - 1;
	    if (val[i].tag !== "") {
			const values = [...this.state.floorPlanData];
			values.push({floorImages: {}, tag: ""});
			this.setState({ floorPlanData: values });
		}else {
		    this.setState({
		        alertShow: true,
		        type: "warning",
		        title: "Please fill details !"
		    });
		}
	};
	onFileSelect = async (e, key = null) => {
		if (e.target.files.length > 0) {
			if(e.target.name === "project_baner_image"){
				let banerImages = { ...this.state.banerImages };
				banerImages.fileName = e.target.files[0].name;
				banerImages.file = e.target.files[0];

				this.setState({ banerImages });
			}else if(e.target.name === "project_brochure"){
				let projectBrochure = { ...this.state.projectBrochure };
				projectBrochure.fileName = e.target.files[0].name;
				projectBrochure.file = e.target.files[0];
				this.setState({ projectBrochure });
			}else if(e.target.name === "project_highlights_image"){
				let highlightsImages = { ...this.state.highlightsImages };
				highlightsImages.fileName = e.target.files[0].name;
				highlightsImages.file = e.target.files[0];
				this.setState({ highlightsImages });
			}else if(e.target.name === "project_galry_image"){
				let galaryImages = this.state.galaryImages.filter((item) => item.hasOwnProperty('id'));
				for (let index = 0; index < e.target.files.length; index++) {
					let galaryImage = {};
					galaryImage.fileName = e.target.files[index].name;
					galaryImage.file = e.target.files[index];
					galaryImages.push(galaryImage);
				}
				await this.setState({galaryImages:galaryImages});
			}
		}
	}
	uploadProjectImages = async (projectId) => {
		let formData = new FormData();
		if(this.state.banerImages.file){
			formData.append('project_baner_image',this.state.banerImages.file);
		}
		if(this.state.highlightsImages.file){
			formData.append('project_highlight',this.state.highlightsImages.file);
		}
		if(this.state.projectBrochure.file){
			formData.append('project_brochure',this.state.projectBrochure.file);
		}
		
		this.state.galaryImages.forEach((project_gallary, idx) => {
			if (project_gallary.file) {
			  formData.append(`project_gallary`, project_gallary.file);
			}
		});

		this.state.floorPlanData.forEach(floor_plan => {
			let floorPlanFiles = floor_plan.floorImages.file;
			if (floorPlanFiles) {
			  formData.append(`project_floor_plans`, floorPlanFiles);
			}
		});

		formData.append('project_id',projectId);

		let floorPlanTags = [];
		for (let index = 0; index < this.state.floorPlanData.length; index++) {
			if(this.state.floorPlanData[index].tag  !== ""){
				floorPlanTags.push({tag: this.state.floorPlanData[index].tag}); 	
			}	
		}
		formData.append("floor_plans_tags", JSON.stringify(floorPlanTags));

		if(this.state.floorPlanData.length || this.state.banerImages.file || this.state.projectBrochure.file || this.state.highlightsImages.file || this.state.galaryImages.length){
		 let projectImg = await projectImages(formData);
		 if(projectImg.meta.status === 201){
			try{
				if(projectImg.data){
					let gallaryImges = [];
					projectImg.data.map((item) => {
						if(item.type === 'gallary'){
							gallaryImges.push({id:item.id,name:item.filename});
						}
					});
					if(gallaryImges.length){
						let getOldImg = this.state.galaryImages.filter((item) => !item.hasOwnProperty('file'))
						await this.setState({galaryImages:[...getOldImg , ...gallaryImges]});
					}
				} 
			}
			catch (e) {
            }
		 }
		}
	} 
	removeFile = async (id,type) => {
		this.setState({showLoader:true});
		if(type === 'gallary'){
			if([3,6].indexOf(this.state.removeMultipulImg.length) === -1){
				this.setState({
					alertShow: true,
					showLoader:false,
					type: "warning",
					title: "Please select only 3 either 6 images !"
				});
				return false;
			}
		}
		let deleteImages = await deleteProjectImages({id:id});
		if(deleteImages.meta.status === 201){
			let stateObj = {
				showLoader: false,
				alertShow: true,
				type: "success",
				title: "Project image deleted successfully"
			};
			if(type === 'baner'){
				stateObj.banerImages = {};	
			}
			if(type === 'brochure'){
				stateObj.projectBrochure = {};	
			}else if(type === 'highlight'){
				stateObj.highlightsImages = {};
			}else if(type === 'gallary'){
				stateObj.galaryImages = this.state.galaryImages.filter((item) => this.state.removeMultipulImg.indexOf(item.id) === -1);
				stateObj.removeMultipulImg = [];
			}
			await this.setState(stateObj);
		}else{
			this.setState({
				showLoader: false,
				alertShow: true,
				type: "error",
				title: deleteImages.meta.message
			});
		}
	}
	rmeoveGalaryImg = (id) => {
		let imgIds = this.state.removeMultipulImg;
		let checkIndex = imgIds.indexOf(id); 
		if(checkIndex === -1){
			imgIds.push(id);
		}else{
			imgIds.splice(checkIndex, 1);
		}
		this.setState({removeMultipulImg:imgIds});
	}
    render() {
	 	
	    const {error,region,builder_name,project_name,region_id,project_id_99, rera_no, possession_date, land_area
			, towers, elevation_struc, ivr_no, map, overview, video_link, site_url, housing_project_id, alertShow
			, type, title, isDisabled, btnText, possession,whatsapp_event,fact_sheet,is_adwords,is_masks, otherFields
			,externalUserData, is_active, is_focus, slab_min, slab_max,is_dashboard
			, floorPlanData, slug} = this.state
		return (
            <Frag>
            	<div className="projectForm">
					<h4 className="p-2">{this.props.updateProjectId ? 'Update Project' : 'Add Project'}</h4>
					<Loader show={this.state.showLoader}/>
					<PopUpAlert type={type} msg={title} open={alertShow} handleClose={this.handleClose}/>
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
											error = {this.state.isError.builder_name !== '' ? true : false}
											required
									        id="builder_name"
									        name="builder_name"
									        value={builder_name}
									        label="Builder Name"
									        helpertext={this.state.isError.builder_name !== '' ? this.state.isError.builder_name : ''}
									        onChange={this.formValChange}
									        InputProps={{
									          startAdornment: (
									            <InputAdornment position="start">
									              <ApartmentRoundedIcon />
									            </InputAdornment>
									          ),
									        }}
								      	/>	
									</div>
								</div>
								<div className="col-md-3 mb-1">
									<div className="form-group">
										<TextField
											error = {this.state.isError.project_name !== '' ? true : false}
											required
											disabled={this.state.project_id && !projectEditRole.includes(window.$role) ? true : false}								
									        id="project_name"
									        name="project_name"
									        value={project_name}
									        label="Project Name"
									        helpertext={this.state.isError.project_name !== '' ? this.state.isError.project_name : ''}
									        onChange={this.formValChange}
									        InputProps={{
									          startAdornment: (
									            <InputAdornment position="start">
									              <BusinessRoundedIcon />
									            </InputAdornment>
									          ),
									        }}
								      	/>		
									</div>
								</div>
								<div className="col-md-3 mb-3">
								  	<FormControl>
									    <InputLabel id="demo-controlled-open-select-label">Select Region*</InputLabel>
								        <Select
											error = {this.state.isError.region_id !== '' ? true : false}
								          labelId="demo-controlled-open-select-label"
								          value={region_id}
								          onChange={this.formValChange}
								          inputProps={{
								            name: 'region_id',
								            id: 'region_id',
								          }}
									      helpertext={this.state.isError.region_id !== '' ? this.state.isError.region_id : ''}
								        >
								          <MenuItem value="">
								            <em>None</em>
								          </MenuItem>
								          {region ?
					    						(region.map(reg=>
							          				<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
				    							))   	
										      	:
										      	''
										    }  	
								        </Select>
									</FormControl>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								      	<GoogleMaps handleLocation={this.handleGeoLocation} autoLocation={this.state.location} isError={this.state.isError.location}/>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
											error = {this.state.isError.project_id_99 !== '' ? true : false}
									        id="project99"
									        name="project_id_99"
									        value={project_id_99}
									        helpertext={this.state.isError.project_id_99 !== '' ? this.state.isError.project_id_99 : ''}
									        label="99 Project ID"
									        onChange={this.formValChange}
									        InputProps={{
									          startAdornment: (
									            <InputAdornment position="start">
									              <BusinessRoundedIcon />
									            </InputAdornment>
									          ),
									        }}
								      	/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
									        id="rera"
									        value={rera_no}
									        name="rera_no"
									        label="RERA Number"
									        onChange={this.formValChange}
								      	/>	
									</div>
								</div>
						        {this.state.possession === 'date' &&
						        <div className="col-md-3 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
          										variant="inline"
												margin="normal"
												id="date-picker-inline"
												format="yyyy/MM/dd"
												// minDate={new Date()}
												name="possession_date"
												label="Possesion Date"
												value={possession_date}
												onChange={(key, date) => this.handleDateChange('possession_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
						        	</FormControl>
								</div>
					        	}
								<div className="col-md-3 mb-3">
						            <FormControl>
        								<InputLabel id="demo-simple-select-outlined-label">Possession Date</InputLabel>
								          <Select
								           labelId="demo-simple-select-outlined-label"
          								   id="demo-simple-select-outlined"
								           value={possession} name="possession" onChange={this.onChange}>
								            <MenuItem value="">None</MenuItem>
								            <MenuItem value="ready">Ready to move</MenuItem>
								            <MenuItem value="date">Select date</MenuItem>
								          </Select>
						            </FormControl>
						        </div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
									        id="land_area"
									        type="number"									        
									        value={land_area}
									        name="land_area"
									        label="Land Area (Acre)"
									        onChange={this.formValChange}
								      	/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
									        id="towers"
									        type="number"									        
									        value={towers}
									        name="towers"
									        label="Number of Towers"
									        onChange={this.formValChange}
								      	/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
											id="elevation_struc"
											name="elevation_struc"
											value={elevation_struc}
											type="number"
											onChange={this.formValChange}
											label="Elevation Structure"
											inputProps={{
												min: "0"
											}}
										/>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
											type="number"											
									        id="ivr_no"
									        value={ivr_no}
									        name="ivr_no"
									        label="IVR Contact"
									        onChange={this.formValChange}
								      	/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
										<TextField
									        id="map"
									        value={map}
									        name="map"
									        label="Map"
									        onChange={this.formValChange}
								      	/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											name="video_link"
											value={video_link}																																	
											label="Video Link"
											onChange={this.formValChange}
										/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											name="slug"
											value={slug}																																	
											label="Slug"
											onChange={this.formValChange}
										/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											name="site_url"
											value={site_url}																																	
											label="Project Website"
											onChange={this.formValChange}
										/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											name="housing_project_id"
											value={housing_project_id}																																	
											label="Housing Project Id"
											onChange={this.formValChange}
										/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											type="number"
											name="slab_min"
											value={slab_min}																																	
											label="Min Slab"
											onChange={this.formValChange}
										/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											type="number"
											name="slab_max"
											value={slab_max}																																	
											label="Max Slab"
											onChange={this.formValChange}
										/>	
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="form-group">
								        <TextField
											// id="outlined-multiline-static"
											name="overview"
											value={overview}																																	
											label="Overview"
											// multiline
											// rows={4}
											onChange={this.formValChange}
											// variant="outlined"
										/>	
									</div>
								</div>
								<div className="col-md-9 px-0">
									<div className="row">
										<div className="col-md-6 mt-2">
											<div className="row">
												<div className="col-md-7">
													<div className="col-12">
														Banner Image
													</div>
													
													<div className="col-12">
														<input type="file"
														className="w-100"
														name='project_baner_image'
														onChange={e => this.onFileSelect(e,'baner_image')} accept="image/*"
														/>
													</div>
												</div>
												{ (this.state.banerImages?.id && this.state.banerImages.id ) ? <div className="col-md-5 px-4 mx-2">
													{this.state.banerImages.name}
													<span className="fa-link ml-1"onClick={() => this.removeFile(this.state.banerImages?.id,'baner')}>
														<FontAwesomeIcon icon={faTimesCircle} className="nav-icon" />
													</span>
												</div> : '' }
											</div>
										</div>
										<div className="col-md-6 mt-2">
											<div className="row">
												<div className="col-md-7">
													<div className="col-12">
														Highlights Image
													</div>
													<div className="col-12">
														<input type="file"
														className="w-100"
															name='project_highlights_image'
															onChange={e => this.onFileSelect(e,'project_highlights_image')} accept="image/*"
														/>
													</div>
												</div>
												{ (this.state.highlightsImages?.id && this.state.highlightsImages.id ) ? <div className="col-md-5 px-4 mx-2">
													{this.state.highlightsImages.name}
													<span className="fa-link ml-1"onClick={() => this.removeFile(this.state.highlightsImages?.id,'highlight')}>
														<FontAwesomeIcon icon={faTimesCircle} className="nav-icon" />
													</span>
												</div> : '' }
											</div>
										</div>
										<div className="col-md-6 mt-2">
											<div className="row">
												<div className="col-md-7">
													<div className="col-12">
														Gallery Images
													</div>
													<div className="col-12">
														<input type="file"
														className="w-100"
														name='project_galry_image'
														multiple
														onChange={e => this.onFileSelect(e,'project_galary_image')} accept="image/*"
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6 mt-2 pl-4 ml-3 pl-md-0 ml-md-0">
											{ this.state.galaryImages.length ? this.state.galaryImages.map((item) => ( 
											item.id ? 
											<div className="col-md-4">
												<input
												className="form-check-input"
												type="checkbox"
												checked={this.state.removeMultipulImg.indexOf(item.id) != -1}
												onClick={() => this.rmeoveGalaryImg(item.id)}
												/>
													{item.name}
											</div> : '')) : ''}
											{ this.state.removeMultipulImg.length ?  <div className="col-md-4"> 
												<span className="fa-link ml-1" onClick={() => this.removeFile(this.state.removeMultipulImg,'gallary')}>
													Remove Images <FontAwesomeIcon icon={faTimesCircle} className="nav-icon" />
												</span>
											</div> : ''}	
										</div>
										<div className="col-md-6 mt-2">
											<div className="row">
												<div className="col-md-7">
													<div className="col-12">
														Upload Brochure
													</div>
													
													<div className="col-12">
														<input type="file"
														className="w-100"
														name='project_brochure'
														onChange={e => this.onFileSelect(e,'project_brochure')}
														/>
													</div>
												</div>
												{ (this.state.projectBrochure?.id && this.state.projectBrochure.id ) ? <div className="col-md-5 px-4 mx-2">
													{this.state.projectBrochure.name}
													<span className="fa-link ml-1"onClick={() => this.removeFile(this.state.projectBrochure?.id,'brochure')}>
														<FontAwesomeIcon icon={faTimesCircle} className="nav-icon" />
													</span>
												</div> : '' }
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								{projectActiveFocus.includes(window.$role)&&
								(<Frag>
									<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={is_active}
										checked={is_active}
										name="is_active"
										control={<Switch color="primary" />}
										label={is_active ? "Active" : "In-active"}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'status')}
									/>
								</div>
								<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={is_focus}
										checked={is_focus}
										name="is_focus"
										control={<Switch color="primary" />}
										label={is_focus ? "Focused" : "Not Focused"}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'focus')}
									/>
								</div>
								</Frag>	)
								}
								<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={whatsapp_event || false}
										checked={whatsapp_event || false}
										name="whatsapp_event"
										control={<Switch color="primary" />}
										label={whatsapp_event ? "Whatsapp Yes" : "Whatsapp No"}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'whatsapp')}
									/>	
								</div>
								<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={is_adwords || false}
										checked={is_adwords || false}
										name="is_adwords"
										control={<Switch color="primary" />}
										label={is_adwords ? "Adwords Yes" : "Adwords No"}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'adwords')}
									/>	
								</div>
								<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={fact_sheet || false}
										checked={fact_sheet || false}
										name="fact_sheet"
										control={<Switch color="primary" />}
										label={fact_sheet ? "Fact Sheet Yes" : "Fact Sheet No"}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'fact_sheet')}
									/>	
								</div>
								<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={is_masks || false}
										checked={is_masks || false}
										name="is_masks"
										control={<Switch color="primary" />}
										label={is_masks ? "Visible to Magnet"  : "Visible to Magnet "}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'masks')}
									/>	
								</div>
								<div className="col-md-2 col-6 px-0">
									<FormControlLabel
										value={is_dashboard || false}
										checked={is_dashboard || false}
										name="is_dashboard"
										control={<Switch color="primary" />}
										label={is_dashboard ? "Visible to Dashboard"  : "Visible to Dashboard "}
										labelPlacement="top"
										onChange={(event, checked) => this.handleChange(event, checked,'is_dashboard')}
									/>	
								</div>
								<div className="col-md-2 col-6 px-0">
									{this.state.project_id?<button type="button" onClick={() => this.pdfPreview(this.state.project_id)} className="btn btn-outline-primary m-t-15 mb-2">Pdf Preview</button>:''}	
								</div>
							</div>

							<div className="row mb-3">
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-3 col-6 mt-1">Floor Plan <sub>(file)</sub> </div>
											<div className="col-md-3 col-6 mt-1">Floor Plan <sub>(tag)</sub></div>
											<div className="col-md-3 col-6 mt-1">Floor Plan <sub>(file name)</sub></div>
										</div>
									</div>
									<div className="col-md-12">
										{floorPlanData.map((field, idx) => (
										<div className="row mb-2" key={idx}>
											<div className="col-md-3 col-6 mt-1 align-self-end">
												<input type="file"
													className="w-100"
													name="floorImages"
													onChange={e=>this.floorPlanInput(idx,e)} accept="image/*"
												/>
											</div>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													type="text"
													id="name"
													name="tag"
													label="Tag"
													value={field?.tag || ""}
													onChange={e => this.floorPlanInput(idx, e)}
												/>	
											</div>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													disabled
													label="File Name"
													// name="fileName"
													value={field.floorImages?.fileName || ""}
												/>	
											</div>
											<div className="col-md-2 pl-md-0">
											{alertShow && <CustomizedSnackbars type={type} msg={title}/> }
											<Box  width={1} my={1}>
												<Box width="50%" className="ButtonPart">
												<button
													className="btn btn-link bg-blue"
													type="button"
													onClick={() => this.addFloorPlan(idx)}
												>
													<AddCircleIcon />
												</button>
											</Box>
												<Box width="50%" className="ButtonPart "
												>
													<button
												className="btn btn-link bg-red"
												type="button"
												onClick={() => this.removeFloorPlan(idx, field.floorImages?.id)}
												>
												<CancelIcon style={{color:"#dc3545"}}/>
												</button>
												</Box>
											</Box>
											</div>		
										</div>
										))}
									</div>	
							</div>
							<div className="row mb-3">
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-3 col-6 mt-1">Manager Name <sub>(name)</sub></div>
											<div className="col-md-3 col-6 mt-1">Manager Number <sub>(number)</sub> </div>
											<div className="col-md-3 col-6 mt-1">Manager Designation <sub>(designation)</sub> </div>
										</div>
									</div>
									<div className="col-md-12">
										{externalUserData.map((field, idx) => (
										<div className="row" key={idx}>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													type="text"
													id="name"
													name="name"
													label="Name"
													value={field.name}
													onChange={e => this.ExternaluserInput(idx, e)}
												/>
											</div>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													type="number"
													id="number"
													name="number"
													label="Number"
													value={field.number}
													onChange={e => this.ExternaluserInput(idx, e)}
												/>
											</div>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													type="text"
													id="designation"
													name="designation"
													label="Designation"
													value={field.designation}
													onChange={e => this.ExternaluserInput(idx, e)}
												/>
											</div>
											<div className="col-md-2 pl-md-0">
											{alertShow && <CustomizedSnackbars type={type} msg={title}/> }
											<Box  width={1} my={1}>
												<Box width="50%" className="ButtonPart">
												<button
													className="btn btn-link bg-blue"
													type="button"
													onClick={() => this.addExternaluserField(idx)}
												>
													<AddCircleIcon />
												</button>
											</Box>
												<Box width="50%" className="ButtonPart "
												>
													<button
												className="btn btn-link bg-red"
												type="button"
												onClick={() => this.externalRemoveInput(idx)}
												>
												<CancelIcon style={{color:"#dc3545"}}/>
												</button>
												</Box>
											</Box>
											</div>		
										</div>
										))}
									</div>	
							</div>
							<div className="row mb-3">
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-3 col-6 mt-1">Others Field<sub>(label)</sub></div>
											<div className="col-md-3 col-6 mt-1">Others Field <sub>(value)</sub> </div>
											<div className="col-md-3 col-6 mt-1">Others Field <sub>(type)</sub> </div>
										</div>
									</div>
									<div className="col-md-12">
										{otherFields.map((field, idx) => (
										<div className="row mb-2" key={idx}>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													type="text"
													id="lable"
													name="lable"
													label="Label"
													value={field.lable}
													onChange={e => this.handleChangeInput(idx, e)}
												/>
											</div>
											<div className="col-md-3 col-6 mt-1">
												<TextField
													type="text"
													id="value"
													name="value"
													label="Value"
													value={field.value}
													onChange={e => this.handleChangeInput(idx, e)}
												/>
											</div>
											<div className="col-md-3 col-6 mt-1">
												<SelectOption
													name="type"
													value={field.type}
													label="Type"
													options={othersType}
													variant="standard"
													onChange={e => this.handleChangeInput(idx, e)}
												/>
											</div>
											<div className="col-md-2 pl-md-0">
											{alertShow && <CustomizedSnackbars type={type} msg={title}/> }
											<Box  width={1} my={1}>
												<Box width="50%" className="ButtonPart">
												<button
													className="btn btn-link bg-blue"
													type="button"
													onClick={() => this.addOtherField(idx)}
												>
													<AddCircleIcon />
												</button>
											</Box>
												<Box width="50%" className="ButtonPart "
												>
													<button
												className="btn btn-link bg-red"
												type="button"
												onClick={() => this.handleRemoveInput(idx)}
												>
												<CancelIcon style={{color:"#dc3545"}}/>
												</button>
												</Box>
											</Box>	
											</div>		
										</div>	
										))}
									</div>		
							</div>
							<div className="row">
								<div className="col-md-12 text-center col-12 mt-3">
									<div className="form-group">
										<Button disabled={isDisabled}
												variant="contained" 
												onClick={this.props.updateProjectId ? this.updateProjectForm : this.addNewProject } 
												style={{ backgroundColor: '#007bff', color: '#fff' }}
												>
												{btnText}
										</Button>
										<p className="mb-0 mt-3">
									        <span className="text-center text-danger">{error !== '' ? error : ''}</span>
									    </p>
									</div>
								</div>
							</div>									
						</div>
					</form>
	            </div>
				{this.state.previewProjectId ? 
                <ProjectPdfPreview 
                    showModal={this.state.showModal}
                    handleModalClose={this.handleModalClose} 
                    projectId={this.state.previewProjectId}
					clickId={this.state.clickId}
                />: ""}
            </Frag>
        );
    };
}

export default withRouter(addProjectFrom);
