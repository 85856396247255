import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { PrivateRoute } from "./privateRouter";
import { user_routes } from "../config/config.js";
import { APP_BASE_URL } from "../config/serverKey.js";
// import BasicUserDetails from "../pages/register/userinfo.js";
import TermsConditions from "../pages/termsCondtions/termsConditions.js";
import EmailVerify from "../pages/emailVerify/emailVerify.js";
import Login from "../pages/login/login";
import Dashboard from "../pages/dashboard/dashboard";
import Users from "../pages/userPage/userListPage";
import Forget from "../pages/forget/forget";
import CreatePass from "../component/magnetRegister/MagnetRegister";
import ResetPass from "../pages/reset-password/reset_pass";
import ChangePass from "../pages/reset-password/reset_pass";
import LeadList from "../pages/leadPage/leadPageList.js";
import BulkLeadList from "../pages/bulkLeadPage/bulkLeadPage";
import Projects from "../pages/project/projectList";
import AddProject from "../pages/project/addProjectPage";
import IvrManagement from "../pages/IvrManagement/IvrManagementPage";
import AddUpdateIVR from "../pages/IvrManagement/AddUpdateIvrPage";
import IvrLogs from "../pages/IvrManagement/IvrLogsPage";
import KnowlarityCallLogs from "../pages/IvrManagement/KnowlarityCallLogs";
import ReferPage from "../pages/refer/referPage";
import CpUserPage from "../pages/cpUserPage/cpUserPage";
import Register from "../pages/register/registerform";
import FocusProject from "../pages/focusProject/focusProjectPage";
import LeadReport from "../pages/reports/reports";
import UserFocusProjects from "../pages/userFocusPage/userFocusPage.js";
import MagnetTempUser from "../pages/magnetTempUser/tempUserPage";
import ReferalUserList from "../pages/referalUsers/referalUsersPage.js";
import Role from "../pages/roleTree/roleTree.js";
import AutoAssignPage from "../pages/autoAssignPage/autoAssignPage.js";
import MetaBasePage from "../pages/metaBaseReport/metaBasePage.js";
import LeadInternalPage from "../pages/leadInternal/leadInternalPage.js";
import MagnetBudget from "../pages/magnetBudget";
import VerifyBooking from "../pages/booking";
import HrDocs from "../component/hrDocs/hrDocs.js";
import ViewHrDocs from "../component/hrDocs/viewHrDocs.js";
import NotFoundComponent from "../static/404.js";
import { Helmet } from "react-helmet";
import genericSearch from "../component/search/genericSearch";
import LeadHomePage from '../pages/leadDetails/HomePage';
import { ThankYou } from "../pages/thankyou/ThankYou";
import { DownloadFactsheet } from "../pages/DownloadFactsheet";
import { RerportErrorPage } from "../pages/reportErrorPage/RerportErrorPage";
import HomeLoan from "../component/homeLoan/HomeLoan";
import RequestProject from "../pages/project/RequestProjectPage";
import MagnetRequest from "../pages/project/MagnetRequestPage";
import CliManagementPage from "../pages/CliManagementPage";
import BulkFilesPage from "../pages/BulkFilesPage";
import { GROUPS, ROLES } from "../config/constant";
import IncentiveUpdatePage from "../pages/IncentiveUpdatePage";
import FinanceCalendarPage from "../pages/FinanceCalendarPage";
import EligibilityPercentagesPage from "../pages/EligibilityPercentagesPage";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hashUrl: "",
      isLocalEmpty: false,
		configured: false
    };
    var homesfy_lg = null;
    window.$user = null;
    window.$role = null;
    // window.$isLocalEmpty = true;
    let key_value = window.location.href?.split('?')[1]?.split('=')
    if(key_value) localStorage.setItem(key_value[0],key_value[1])
    if (localStorage.getItem("homesfy_lg")) {
      homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
      let user_data = JSON.parse(homesfy_lg);
      user_data.role =  typeof user_data?.role !== "string" ? ROLES[user_data?.role]?.split("_")?.join("")?.toLowerCase() : user_data?.role ;
      user_data.group = typeof user_data?.group !== "string" ? GROUPS[user_data?.group]?.toLowerCase() : user_data?.group;
      window.$user = user_data;

      window.$role = window.$user.role.toLowerCase();
      // console.log("#######################3",window.$user)
    }
  }

  componentDidMount() {
    let hashUrl = window.location.hash.replace("#", "").split("?")[0];
    if(hashUrl.startsWith("/magnet-set-password")){
      hashUrl = window.location.hash.split("#")[1];
    }
    let projectId = hashUrl.split('/');
    projectId = projectId[projectId.length-1];
    this.setState({project_id: projectId})
    if (window.$user == null)
      this.setState({ isLocalEmpty: true, hashUrl: hashUrl });
    if(hashUrl == "/terms-and-conditions" || hashUrl == "/thankyou"  || hashUrl.startsWith("/factsheet") || hashUrl.startsWith("/forget-password") || hashUrl.startsWith("/magnet-set-password")){
      window.location.href = APP_BASE_URL + hashUrl;
    } 
    else if (hashUrl != "/login" && hashUrl !== "/" && hashUrl !== null && window.$user == null) {
      window.location.href = APP_BASE_URL + "/login?redirect_url=" + hashUrl;
    } else if (window.$user == null) {
      window.location.href = APP_BASE_URL + "/login";
    } else {
      window.location.href = APP_BASE_URL + hashUrl;
    }

	  this.setState({configured: true})
    document.addEventListener("contextmenu", e => {
      e.preventDefault();
    });
  }

  render() {
	  if(!this.state.configured) return <></>
    const compo = {
      MagnetTempUser: MagnetTempUser,
      LeadReport: LeadReport,
      ReferalUserList: ReferalUserList,
      UserFocusProjects: UserFocusProjects,
      FocusProject: FocusProject,
      ReferPage: ReferPage,
      Register: Register,
      CpUserPage: CpUserPage,
      Dashboard: Dashboard,
      Users: Users,
      magnetList: LeadList,
      BulkLeadList: BulkLeadList,
      Projects: Projects,
      AddProject: AddProject,
      IvrManagement: IvrManagement,
      AddUpdateIVR: AddUpdateIVR,
      IvrLogs:IvrLogs,
      AutoAssignPage: AutoAssignPage,
      MetaBasePage: MetaBasePage,
      LeadInternalPage: LeadInternalPage,
      Role: Role,
      HrDocs: HrDocs,
      ViewHrDocs: ViewHrDocs,
      MagnetBudget: MagnetBudget,
      VerifyBooking: VerifyBooking,
      genericSearch: genericSearch,
      LeadHomePage,
      RerportError: RerportErrorPage,
      HomeLoan,
      KnowlarityCallLogs,
      RequestProject,
      MagnetRequest
      , CliManagementPage
      , BulkFilesList: BulkFilesPage
      , IncentiveUpdate: IncentiveUpdatePage
      , FinanceCalendar: FinanceCalendarPage
      , EligibilityPercentages:EligibilityPercentagesPage
    };

    let role = "";
    if (window.$user) role = window.$role;
    const { hashUrl } = this.state;

    return (
      <div className="unselectable">
        <Helmet>
          <meta http-equiv="cache-control" content="max-age=0" />
          <meta
            http-equiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </Helmet>
        <Switch>
          {user_routes.length > 0 &&
            user_routes.map(
              (userData, index) =>
                userData.user_role.includes(role) && (
                  <PrivateRoute
                    key={userData.component}
                    exact
                    path={userData.url}
                    component={withRouter(compo[userData.component])}
                  />
                )
            )}
          <PrivateRoute exact path="/" component={withRouter(Dashboard)} type="Dashboard" />
          <PrivateRoute
            exact
            path="/register-update"
            component={withRouter(Register)}
          />
          {/* <Route
            exact
            path="/register:ref_id"
            component={withRouter(BasicUserDetails)}
          /> 
          <Route
            exact
            path="/register"
            component={withRouter(BasicUserDetails)}
          /> */}
          {/* <Route
            exact
            path="/register:ref_id"
            component={NotFoundComponent}
          />
          <Route path="/register" component={NotFoundComponent} /> */}
          <Route
            exact
            path="/verify-email"
            component={withRouter(EmailVerify)}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={withRouter(TermsConditions)}
          />
          <Route
            exact
            path="/login:redirect_url"
            component={withRouter(Login)}
          />
          <Route exact path="/login" component={withRouter(Login)} />
          <Route exact path="/forget-password/:token?" component={withRouter(Forget)} />
          <Route exact path="/magnet-set-password" component={withRouter(CreatePass)} />
          <Route
            exact
            path="/reset_password"
            component={withRouter(ResetPass)}
          />
          <Route
            exact
            path="/change_password"
            component={withRouter(ChangePass)}
          />
          <Route exact path="/thankyou" component={ThankYou} />
          <Route exact path="/auto-dialer.html" render={() => {window.location.href="auto-dialer.html"}} />
          <Route exact path="/factsheet/:id" component={DownloadFactsheet} />
          <Route component={NotFoundComponent} />
        </Switch>
      </div>
    );
  }
}
export default Routes;
