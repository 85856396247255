import axios from "axios";
import { useHistory } from "react-router";
import {getToken} from "./helper/";

import {APP_URL,CORE_API_URL,ENGAGE_API_URL} from "./config/serverKey.js";

/*export const getToken = () => {
	return sessionStorage.getItem("token");
};
*/

const api = axios.create({
	baseURL: `${CORE_API_URL}/`,
	headers: {},
});

export const engage_api = axios.create({
    baseURL: `${ENGAGE_API_URL}/`,
    headers: {}
})

const configHandler = config => {
    let jwtToken = getToken();

	if (jwtToken) {
		config.headers.common["Content-Type"] = `application/json`;
		config.headers.common["Authorization"] = `${jwtToken.token}`;
	}
	return config;
};

api.interceptors.request.use(configHandler);
/*

const errorHandler = error => {
	if (error.response) {
		switch (error.response.status) {
			case 401:
				window.location.replace("/signup");
				break;
			case 503:
				window.location.replace("/maintenance");
				break;
		}
	}
	return Promise.reject({ ...error });
};



const successHandler = response => {
	return response;
};
api.interceptors.response.use(successHandler, errorHandler);
*/
export default api;
