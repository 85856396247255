const colorConfig = {

	"all_leads": "#17a2b8",
	"new_leads": "#A800FF",
	"closed_leads": "#dc3545",
	"open_leads": "#ffc107",
	"assign_leads": "#ff6500",
	"cancel_leads": "#cf7500",
	"booked_leads": "#28a745",
	"pipeline_leads": "#ff7171",
	"prospect_leads": "#197163",
	"opportunity_leads": "#b52b65",
	"followup_leads": "#ffa36c",
	"magnet_leads": "#5CEF0D",
	"magnet_booked": "#410C46",
	"magnet_pipeline": "#DE4064",
	"magnet_current": "#9290B8",
	"presale_booked": "#C99A93",
	"presale_current": "#D71B84",
	"presale_leads": "#0DA0EF",
	"presale_update": "#DA0DEF",
	"presale_lose": "#EFC60D",
	"dump_report": "#404040",
	"ratio_report": "#ACC8E5",
	"followup_report": "#23238E",
	"video_call":"#23238E",
	"site_visit":"#D71B84",
	"hpop_leads": "#C99A93",
	"revisit_leads": "#28a745",
	"grey": "#787878",
	"golden": "#DAA520",
	"green": "#28a745",
	"brown": "#800000"
}

const backetName = "cp-user-docs/live";
// const backetName = "cp-user-docs/testing";
const hrBacketName = "cp-hr-docs/live";

export { colorConfig, backetName, hrBacketName };