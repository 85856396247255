import React from 'react'

const LeadProgress = ({ title, total, lead }) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <span className="pl-1 pr-0 py-1 col-sm-4 float-left leadsBlock sublinkName">{title}</span>
                <div className="progress col-sm-8 mt-2" style={{ height: '15px', padding: '0' }}>
                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: total !== 0 ? Math.round((lead / total) * 100) : 0 + '%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                        <span className="text-left pl-2 progressCount">{lead}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeadProgress
