const APP_URL = "http://localhost:3000";
const APP_BASE_URL = "http://localhost:3000/#";
// const APP_BASE_URL = "http://new.homesfy.in/#";
// // const APP_BASE_URL = "http://localhost:3006/#";
// // const APP_BASE_URL = "http://35.154.76.18:3006/#";
// // const CORE_API_URL = "http://35.154.76.18:4000/api";
// //  const CORE_API_URL = "http://test-api.homesfy.in/api";
const CORE_API_URL = "https://staging-api.homesfy.in/api";
// // const CORE_API_URL = "http://localhost:3071/api";
//
const OLD_API_URL = "https://staging-api.mymagnet.io/api";
// const ENGAGE_API_URL = "http://35.154.76.18:7004/api";
const ENGAGE_API_URL = "https://staging-engage.homesfy.in/api";
//
const AMAZON_URL = "https://refrance.s3.ap-south-1.amazonaws.com/mymagnet";
const SITE_LOGO = "https://refrance.s3.ap-south-1.amazonaws.com/HomesfyLogo.jpg";
//
// // if (window.location.host == "LIVE URL" || CHECK FOR THE ENV){
//
// //      const APP_URL = "http://localhost:5000";
// //      const CORE_API_URL = "http://localhost:4000/api/";
//
// // }
//

const SITE_FAVICON = AMAZON_URL+"/mg-favicon.svg";


// const REDIRECT_URL = 'https://app.mymagnet.io';
const REDIRECT_URL = 'https://staging-app.mymagnet.io';
// const REDIRECT_URL = 'https://dev1.mymagnet.io';

// const NEW_CRM_URL = "https://keys.mymagnet.io/login.html#"
const NEW_CRM_URL = "https://testing-app.mymagnet.io/login.html#"
// const NEW_CRM_URL = "http://localhost:4000/login.html#/"

export { APP_URL, CORE_API_URL, APP_BASE_URL, OLD_API_URL, AMAZON_URL, ENGAGE_API_URL, SITE_LOGO, REDIRECT_URL, NEW_CRM_URL }

