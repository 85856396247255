import React, {Component} from "react";
import MetaBaseReport from "../../component/metabaseCompo/metaBaseReport.js";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";

class MetaBasePage extends Component {

	constructor(props){
		super(props);
		this.state = {
      		sideBar: false,
      		mobileSideBar: false,
      		userDetail : '',
      		showLoader : false,
    	};
	}

	changeButtonState(event) {
	    this.setState({sideBar: !this.state.sideBar});
	    document.body.classList.add('sidebar-enable');
	}

	changeSideBarState(event) {
	    this.setState({mobileSideBar: !this.state.mobileSideBar})
	}

	render(){

		return( 
			<Frag>
				<Loader show={this.state.showLoader}/>
				<MetaBaseReport expand ={this.state.sideBar}/>
			</Frag>	
		);
	};
};	

export default MetaBasePage;	