import React from 'react';
import IncentiveUpdate from "../component/IncentiveUpdate";

const IncentiveUpdatePage = () => {
  return (
    <div className="content-wrapper">
        <IncentiveUpdate />
    </div>
  )
}

export default IncentiveUpdatePage