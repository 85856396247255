const constants = {
  GROUPS: {
    1: "ADMIN",
    2: "SALES",
    3: "FINANCE",
    4: "PRESALE",
    5: "DIGITAL",
    6: "HR",
    7: "IT",
    8: "MAGNET",
    9: "CP",
    10: "EXCEPTION",
  },
  REVERT_GROUPS: {
    "ADMIN":1,
    "SALES":2,
    "FINANCE":3,
    "PRESALE":4,
    "DIGITAL":5,
    "HR":6,
    "IT":7,
    "MAGNET":8,
    "CP":9,
    "EXCEPTION":10,
  },
  STAGE_WISE_PAGE: {
    1: "all",
    2: "all",
    3: "opportunity",
    4: "decision-delayed",
    5: "pipeline",
    6: "gross-eoi-application",
    7: "booked",
    8: "cancel",
    9: "closed",
    10: "suspect",
    15: "gross-eoi-non-refundable"
  },
  ROLES: {
    1: "ADMIN",
    5: "SALES_HEAD",
    6: "PNL_HEAD",
    7: "SALES_MANAGER",
    8: "SALES_TL",
    10: "CLOSING_S1",
    11: "FINANCE_HEAD",
    12: "PRESALE_HEAD",
    13: "PRESALES_TL",
    14: "SOURCING_S2",
    15: "SOURCING_S3",
    16: "MEDIA_MANAGERS",
    17: "HR_HEAD",
    18: "IT_HEAD",
    19: "MAGNET_ADMIN",
    20: "MAGNET_TL",
    21: "MAGNET_RM",
    22: "MAGNET",
    26: "OPERATIONS_HEAD",
    27: "OPERATIONS_ANALYST",
    28: "FINANCE_ANALYST",
    29: "FINANCE_ASSOCIATE",
    30: "CAMPAIGN_MANAGERS",
    31: "CAMPAIGN_OPERATIONS",
    32: "LEAD_OPERATIONS",
    33: "OPERATIONS_MANAGER",
    34: "CUSTOMER_SUCCESS",
    35: "MAGNET_PNL",
    36: "MAGNET_PLATINUM",
    37: "SENIOR_SALES_TL",
    38: "MAGNET S2",
    39: "MAGNET S3",
    40: "MAGNET_MIS"
  },

  REVERT_ROLES: {
    ADMIN: 1,
    SALES_HEAD: 5,
    PNL_HEAD: 6,
    SALES_MANAGER: 7,
    SALES_TL: 8,
    CLOSING_S1: 10,
    FINANCE_HEAD: 11,
    PRESALE_HEAD: 12,
    PRESALES_TL: 13,
    SOURCING_S2: 14,
    SOURCING_S3: 15,
    MEDIA_MANAGERS: 16,
    HR_HEAD: 17,
    IT_HEAD: 18,
    MAGNET_ADMIN: 19,
    MAGNET_TL: 20,
    MAGNET_RM: 21,
    MAGNET: 22,
    OPERATIONS_HEAD: 26,
    OPERATIONS_ANALYST: 27,
    FINANCE_ANALYST: 28,
    FINANCE_ASSOCIATE: 29,
    CAMPAIGN_MANAGERS: 30,
    CAMPAIGN_OPERATIONS: 31,
    LEAD_OPERATIONS: 32,
    OPERATIONS_MANAGER: 33,
    CUSTOMER_SUCCESS:34,
    MAGNET_PNL: 35,
    MAGNET_PLATINUM: 36,
    SENIOR_SALES_TL: 37,
    MAGNET_S2: 38,
    MAGNET_S3 : 39,
    MAGNET_MIS: 40
  },

  REVERT_ROLES_ID: {
    admin: 1,
    saleshead: 5,
    pnlhead: 6,
    salesmanager: 7,
    salestl: 8,
    closings1: 10,
    financehead: 11,
    presalehead: 12,
    presalestl: 13,
    sourcings2: 14,
    sourcings3: 15,
    mediamanagers: 16,
    hrhead: 17,
    ithead: 18,
    magnetadmin: 19,
    magnettl: 20,
    magnetrm: 21,
    magnet: 22,
    operationshead: 26,
    operationsanalyst: 27,
    financeanalyst: 28,
    financeassociate: 29,
    campaignmanagers: 30,
    campaignoperations: 31,
    leadoperations: 32,
    operationsmanager: 33,
    customersuccess: 34,
    magnetpnl: 35,
    magnetplatinum: 36,
    seniorsalestl: 37,
    magnets2:38,
    magnets3:39,
    magnetmis:40
  },

  NO_HEADER_PAGES: [
    "#/login",
    "#/forget-password",
    "#/reset-password",
    "#/reset-password",
    "#/register"
  ],

  leadAssignCheck: [
    "mediamanagers",
    "closings1",
    "sourcings3",
    "magnet",
  ],

  OBJECT_TYPES: {
    LEAD: 1
  },
  STAGES: {
    NOT_UPDATED: 1,
    REMIND_ME_LATER: 2,
    OPPORTUNITY: 3,
    DECISION_DELAYED: 4,
    PIPELINE: 5,
    GROSS_EOI: 6,
    BOOKED: 7,
    CANCEL: 8,
    CLOSED: 9,
    SUSPECT: 10,
    GROSS_EOI_NON_REFUNDABLE: 15
  },
  LEAD_INTERNAL_STATUSID: {
    HPOP: 1,
    HPOP_DONE: 2,
    VISIT_ARRANGED: 3,
    VISIT_DONE: 4,
    REVISIT_ARRANGED: 5,
    REVISIT_DONE: 6,
    VIDEO_CALL_ARRANGED: 7,
    VIDEO_CALL_DONE: 8,
  },
  ONESIGNAL: {
    development: '2a8345fd-9d3d-4b63-94b2-fba769246644',
    staging: 'dfccb2e6-3123-4a81-abd5-4b4c1d4bb502',
    production: '9fbfd8bf-d0cd-41d5-bc41-ffcf68f55467',
  },
  ENGAGE_TOKEN: {
    staging: 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImVuZ2FnZUBob21lc2Z5LmluIiwiZG9tYWluX25hbWUiOiJob21lc2Z5LmluIiwiZG9tYWluX2lwIjoiMTI3LjAuMC4xIiwiaWF0IjoxNjE1MzYzMjQ0fQ.6zckcSlJy7qwTSCO8mPfjEXj_kAeuj6YuIOodMS4mVc',
    production: 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImVuZ2FnZUBob21lc2Z5LmluIiwiZG9tYWluX25hbWUiOiJob21lc2Z5LmluIiwiZG9tYWluX2lwIjoiMTI3LjAuMC4xIiwiaWF0IjoxNjE1MzYzMzg3fQ.0pF3imZdWAoXIAX0ALvTfDL_iQIJszU_EbEsgoeALJ0',
  },
  REVERT_STAGES: {
    1: "Not Updated",
    2: "Prospect",
    3: "Opportunity",
    4: "Decision Delayed",
    5: "Pipeline",
    6: "Gross EOI Application",
    7: "Booked",
    8: "Cancel",
    9: "Closed",
    10: "Suspect",
    15: "Gross EOI Non-Refundable"
  },
  DUMP_STATUS: {
    11: "Reactivate",
    12: "Disqualify",
    13: "In Progress",
    14: "Withdrawn"
  },
  
  REFERAL_SOURCE_IDS : [41,22],

  MONTHS: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  LEAD_INTERNAL_STATUS: {
    "HPOP Done": 2,
    "HPOP arranged": 1,
    "Revisit Done": 6,
    "Revisit arranged": 5,
    "Visit Done": 4,
    "Visit arranged": 3,
    "video call arranged": 7,
    "video call done": 8,
  },
  REPORT_STAGE_IDS_STRING: {
    "Prospect": 2,
    "booked": 7,
    "cancel": 8,
    "closed": 9,
    "decision delayed": 4,
    "gross eoi application": 6,
    "gross eoi non refundable": 15,
    "not update": 1,
    "opportunity": 3,
    "pipeline": 5,
    "suspect": 10,
  },

  REPORT_FOLLOWUP_KEYS: {
    "future_followup": "future_followup",
    "today": "today",
    "overdue": "overdue",
    "today_past": "today_past",
    "today_future": "today_future",
  },
  DEFAULTRESPONSE: {
    meta: {
      status: 200,
      error: 0,
      message: "OK"
    },
    data: {}
  },
  CLOSING_REASON: [
    "Broking Advisor",
    "Low Budget",
    "Invalid Number",
    "Not Enquired",
    "Wrong Number",
    "Already Bought",
    "Duplicate Lead",
    "Budget Issue",
    "Location Issue",
    "Layout Issue",
    "Test Lead",
    "Possession Issue",
    "Not Contactable",
    "Payment Plan",
    "Decision Delayed",
    "Looking in Resale",
    "Looking in Rental",
    "Looking in Commercial",
    "Builder's Employee",
    "Not Responding (4 Weeks)",
    "Interested In Other Location",
    "Interested In Other Project",
    "Not interested",
    "Plan On Hold",
    "Purchase In Some Other Location",
    "Not Looking For Property",
    "Not Inquired",
    "Dropped The Plan",
    "Financial Problem",
    "Incoming Facility Not Available",
    "Switched off",
    "Incomplete Number",
    "Blank",
    "Ringing",
    "Call Back",
    "Call Disconnected",
    "Busy"
  ],
  BED_CONFIG: [
    { key: 1, value: "1 BHK" },
    { key: 1.5, value: "1.5 BHK" },
    { key: 2, value: "2 BHK" },
    { key: 2.5, value: "2.5 BHK" },
    { key: 3, value: "3 BHK" },
    { key: 3.5, value: "3.5 BHK" },
    { key: 4, value: "4 BHK" },
    { key: 4.5, value: "4.5 BHK" },
    { key: 5, value: "5 BHK" },
    { key: 5.5, value: "5.5 BHK" },
    { key: 101, value: "Plot" },
    { key: 102, value: "Commercial" },
  ],
  MIN_MAX_BUDGET: [
    { key: 2000000, value: "20 Lacs" },
    { key: 2500000, value: "25 Lacs" },
    { key: 3000000, value: "30 Lacs" },
    { key: 3500000, value: "35 Lacs" },
    { key: 4000000, value: "40 Lacs" },
    { key: 4500000, value: "45 Lacs" },
    { key: 5000000, value: "50 Lacs" },
    { key: 5500000, value: "55 Lacs" },
    { key: 6000000, value: "60 Lacs" },
    { key: 6500000, value: "65 Lacs" },
    { key: 7000000, value: "70 Lacs" },
    { key: 7500000, value: "75 Lacs" },
    { key: 8000000, value: "80 Lacs" },
    { key: 8500000, value: "85 Lacs" },
    { key: 9000000, value: "90 Lacs" },
    { key: 10000000, value: "1 Crores" },
    { key: 12000000, value: "1.2 Crores" },
    { key: 12500000, value: "1.25 Crores" },
    { key: 15000000, value: "1.5 Crores" },
    { key: 17500000, value: "1.75 Crores" },
    { key: 20000000, value: "2 Crores" },
    { key: 23000000, value: "2.3 Crores" },
    { key: 25000000, value: "2.5 Crores" },
    { key: 26000000, value: "2.6 Crores" },
    { key: 30000000, value: "3 Crores" },
    { key: 35000000, value: "3.5 Crores" },
    { key: 40000000, value: "4 Crores" },
    { key: 45000000, value: "4.5 Crores" },
    { key: 50000000, value: "5 Crores" },
    { key: 100000000, value: "10 Crores" },
    { key: 200000000, value: "20 Crores" },
  ],
  // 40-50, 50-60, 60-70, 70-80, 80-100, 100-125, 125-150, 150-175, 175-200, 200-250, 250+
BUDGET_OPTIONS : [
  { min: "40 Lacs", minValue: 4000000, max: "50 Lacs", maxValue: 5000000 },
  { min: "50 Lacs", minValue: 5000000, max: "60 Lacs", maxValue: 6000000 },
  { min: "60 Lacs", minValue: 6000000, max: "70 Lacs", maxValue: 7000000 },
  { min: "70 Lacs", minValue: 7000000, max: "80 Lacs", maxValue: 8000000 },
  { min: "80 Lacs", minValue: 8000000, max: "1 Crores", maxValue: 10000000 },
  { min: "1 Crores", minValue: 10000000, max: "1.25 Crores", maxValue: 12500000 },
  { min: "1.25 Crores", minValue: 12500000, max: "1.50 Crores", maxValue: 15000000 },
  { min: "1.50 Crores", minValue: 15000000, max: "1.75 Crores", maxValue: 17500000 },
  { min: "1.75 Crores", minValue: 17500000, max: "2 Crores", maxValue: 20000000 },
  { min: "2 Crores", minValue: 20000000, max: "2.50 Crores", maxValue: 25000000 },
  { min: "2.50 Crores", minValue: 25000000, max: "3 Crores", maxValue: 30000000 }
],
  CALLER_TYPE: {
    SALES: 'sales',
    DUMP: 'dump',
    AUDIT: 'audit'
  },
  KALEYRA_CLICK_TO_CALL_ROLES : ['salestl','closings1','salesmanager'],
  CRM_VIDEOS : {
    admin : "https://classroom.google.com/u/0/w/MjcxNDI2OTQ0NjM1/t/all",
    presalestl : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTMy/t/all",
    presalehead : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTQw/t/all",
    closings1 : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTQ4/t/all",
    sourcings2 : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTA2/t/all",
    sourcings3 : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTE3/t/all",
    saleshead : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTkw/t/all",
    salestl : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTYx/t/all",
    salesmanager : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTcy/t/all",
    pnlhead : "https://classroom.google.com/u/0/w/Mjg0Mzg0MDY4OTgx/t/all",
    mediamanagers : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTUw/t/all",
    financehead : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTYy/t/all",
    hrhead : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTMy/t/all",
    magnet : "https://drive.google.com/folderview?id=1YPKToHQuWC9tyT0MUC3fcEfU057oaMvm",
    magnettl : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTI0/t/all",
    magnetrm : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTE1/t/all",
    magnetadmin : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTQy/t/all",
    magnetpnl : "https://classroom.google.com/u/0/w/Mjg0Mzk3MjI4OTQy/t/all",
  },
  // SHOW_MAGNET_PLAN_BEYOND_DATE: new Date("2021-07-01 00:00:00"),
  MAGNET_PLAN_ROLES: [
    "admin",
    "magnetadmin",
    "magnetpnl",
    "magnettl",
    "magnetrm",
    "magnet",
  ]
  , REJECTION_REASON:[
      {id:"Craft Name is not mentioned in the Application form.", 
      name: "1.Craft Name is not mentioned in the Application form."}
    , {id:"AV/Unit details are not available in the Cost Sheet.", 
      name: "2.AV/Unit details are not available in the Cost Sheet."}
    , {id:"If Application form is not available,need an email regarding Developer Confirmation and PNL Head Approval.", 
      name: "3.If Application form is not available,need an email regarding Developer Confirmation and PNL Head Approval."}
    , {id:"Document uploaded is not Clear - lacks visibility", 
      name: "4.Document uploaded is not Clear - lacks visibility"}
    , {id: "Wrong document uploaded ", 
      name: "5.Wrong document uploaded "}
    , {id: "Application form- Missing ", 
      name: "6.Application form- Missing "}
    , {id: "Cost-sheet – Missing ", 
      name: "7.Cost-sheet – Missing "}
    , {id: "Swipe Details - Missing", 
      name: "8.Swipe Details - Missing"}
  ]
  , chat_option : [{id: "search", name: "Search"}, {id: "discovery", name: "Discovery"}]
  , FOLLOWUP_DATE_TIME_FORMAT : "DD/MM/YYYY HH:mm"
  , DUMP_LEAD: "Data Bank"
  , BULK_FILE_STATUS: [
    {id: "Calling in Progress", name: "Calling in Progress"},
    {id: "Calling Completed", name: "Calling Completed"},
    {id: "Low Quality", name: "Low Quality"},
    // {id: "Pending (initially)", name: "Pending (initially)"},
    {id: "Low Connectivity", name: "Low Connectivity"},
  ]
  , NOTIFY_MSG: "Added lead will reflect in CRM in a few minutes"
  , AV_LIMIT_LOWER: 500000
  , AV_VALIDATION_MSG_LOWER: "Agreement value should not be less than 5 lakh"
  , AV_LIMIT_UPPER: 90000000
  , AV_VALIDATION_MSG_UPPER: "Agreement value should not be more than 9 crore"
  , BOOKING_AMOUNT_LIMIT: 1000000
  , BOOKING_AMOUNT_MSG: "Booking Amount should not be more than 10 lakh"
  , DECIMAL_REGEX : /^\d*\.?\d{0,2}$/
  , QUARTERS : [
      { id: "1", name: "Quarter 1" },
      { id: "2", name: "Quarter 2" },
      { id: "3", name: "Quarter 3" },
      { id: "4", name: "Quarter 4" }
  ]
  , PROJECT_DOCUMENT_LAST_YEAR: 2050
};

module.exports = constants;
