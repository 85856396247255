import React, { Component } from "react";

import Frag from "../../utils/Frag/Frag.js";
import "./header.css";
import { Link } from "react-router-dom";
import { AMAZON_URL } from "../../config/serverKey.js";
// import SimplePopover from "./headerPopOver.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {genericSearch} from "../../dataParser/commomDataApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from '@material-ui/icons/Search';
import TryNewCRM from "../common/form/magnets/TryNewCRM.js";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: false,
      mobileSideBar: false,
      notify: false,
      screenWidth: 0,
      client_id: '',
      selectUser: '',
      searchBarFlag: false,
      option: [],
      mobileSearch: false
    };
    window.addEventListener("resize", this.update);
  }

  componentDidUpdate(prevProps, nextState) {
    let url = window.location.hash;
    if(!url.includes("search/genericSearch/") &&
     prevProps.location?.pathname !== undefined &&
      url !== prevProps.location?.pathname &&
      prevProps.location?.pathname.includes("search/genericSearch/")){
        if(this.inputField !== undefined && this.inputField.getElementsByClassName('MuiAutocomplete-clearIndicator') !== undefined){
          let btn = this.inputField.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
          let field = document.getElementsByClassName('MuiAutocomplete-input')[0];
          btn.click();
          field.blur();

        }
        this.setState({client_id: '',option: [],searchBarFlag:false })
    }

  }
  componentDidMount() {
        // console.log("Error occurred when parsing 'homesfy_lg'");
    this.update();
    let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
    if (homesfy_lg && homesfy_lg !== "") {
      let user = {};
      try {
        user = JSON.parse(homesfy_lg);
      } catch (err) {
        console.log("Error occurred when parsing 'homesfy_lg'");
      }

      this.setState({
        userName: user.name,
        role: window.$role,
        user_id: user.id,
        group: window?.$user?.group
      });
    }
  }
  update = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };

  handlesearch = () => {
    this.setState(prevState =>{
     return{
          ...prevState,
          searchBarFlag : !prevState.searchBarFlag
     }
    })
  };

  toggleDropdown() {
    this.setState({ mobileSearch: !this.state.mobileSearch });
  }

  handleAutoChange = async (e) =>{
    let value = e.target.value.trim()
    if(value !== ''){
      if(isNaN(value) && value.length%2 !== 0) return false
        this.setState({isLoading:true});
        let autoComplete = await genericSearch(value);

        if(autoComplete.meta.status === 200){

        this.setState({isLoading:false});
            this.setState({option : autoComplete.data})
        }else{

        this.setState({isLoading:false});
            this.setState({option : autoComplete})
        }
    }
  }

  clientSelected = async (id) =>{
    if(id !== undefined && id !== null ){
      this.props.history.push(
          {
              pathname: '/search/genericSearch/'+id,
              state: { client_id:id }
          }
      )
    }

  }

  handleMobileDrawer = event => {
    this.setState({ mobileSideBar: !this.state.mobileSideBar });
    document.body.classList.add("sidebar-enable");
  };

  render() {
    const isDesktop = this.state.screenWidth;
    const {option,autoKey,isLoading, role} = this.state;
    const hideSearchRole = ["magnet", "mediamanagers"];
    return (
      <Frag>
          {this.state.role === 'magnet' &&
            <Helmet>
              <script type="text/javascript">
              {`{
                window.$zopim || (function (g, a) {
                    var f = $zopim = function (d) {
                        f._.push(d)
                    },
                        b = f.s = g.createElement(a),
                        c = g.getElementsByTagName(a)[0];
                    f.set = function (d) {
                        f.set._.push(d)
                    };
                    f._ = [];
                    f.set._ = [];
                    b.async = !0;
                    b.setAttribute("charset", "utf-8");
                    b.src = "//v2.zopim.com/?2F4uasrDz8AwB7cxrCz3igHZtZovK0w4";
                    f.t = +new Date;
                    b.type = "text/javascript";
                    c.parentNode.insertBefore(b, c)
                })(document, "script");
                $zopim(function () {
                    $zopim.livechat.button.setOffsetVerticalMobile(55);
                    $zopim.livechat.button.setOffsetHorizontalMobile(10)
                })
              }`}
              </script>
            </Helmet>
          }
        {isDesktop > 991 ? (
          <nav
            id="myHeader"
            className={
              "main-header navbar sticky-top navbar-expand navbar-white navbar-light " +
              (hideSearchRole.includes(role) ? "headerPadding" : "") +
              (this.props.expand ? "main-header-collapsed" : "")
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item desktop" onClick={this.props.buttonClick}>
                <span className="nav-link headerBar p-3" data-widget="pushmenu">
                </span>
              </li>
            </ul>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item d-none d-sm-inline-block">
                  <div className={role === "magnet" ? "" : "search-group"}>
                  {!hideSearchRole.includes(role) ?
                    <FormControl variant="outlined">
                      <Autocomplete
                          key={autoKey} 
                          id="client_id"
                          freeSolo
                          className={"MuiAutocomplete-header"}
                          ref={elem => this.inputField = elem}
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={option => `${option ? option.id : ''} ${option.Client ? option.Client.name : ''} ${option.Client ? option.Client.number : ''}`}
                          options={option}
                          loading={isLoading}
                          onChange={(_event, option) => {
                            if (option === null) {
                              this.setState({
                                option: [],
                              })
                            }else{
                              let clientId = option.Client.id;
                              this.setState({
                                client_id : clientId,
                              })
                              this.clientSelected(clientId)
                            }

                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search Lead"
                              fullWidth
                              onChange={this.handleAutoChange}
                              InputProps={{
                                ...params.InputProps,
                                onKeyDown(e) {
                                    if (e.keyCode === 13 && e.target.value) {
                                      return false
                                    }
                                  },
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                      />  
                    </FormControl>
                    : 
                    role === "magnet" && <li className="nav-item"><TryNewCRM text="Try our new look"/></li>
                  }
                    <span id="firstName-error" className="error invalid-feedback">Please enter First Name</span>
                  </div>
                </li>
              </ul>

          </nav>
        ) : (
          <nav
            id="myHeader"
            className={
              "main-header navbar sticky-top navbar-expand navbar-white navbar-light" +
              " " +
              (this.props.expand ? "main-header-collapsed" : "")+""+(this.state.mobileSideBar ? "mobile-side-open" : "")
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                {this.state.group === "magnet" ? (
                  <Link
                    to={
                      this.state.role == "superuser" ||
                      this.state.role == "admin" ||
                      this.state.role == "magnettl" ||
                      this.state.role !== "caller" ||
                      (this.state.role == "magnet" &&
                        this.state.is_approved === true)
                        ? "/"
                        : "#"
                    }
                  >
                    <img
                      className="site_img"
                      src={`${AMAZON_URL}/logo.svg`}
                      width="110"
                      alt=" site_image"
                    />
                  </Link>
                ) : (
                  <Link
                    to={
                      this.state.role == "superuser" ||
                      this.state.role == "admin" ||
                      this.state.role == "magnettl" ||
                      this.state.role !== "caller" ||
                      (this.state.role == "magnet" &&
                        this.state.is_approved === true)
                        ? "/"
                        : "#"
                    }
                  >
                    <img
                      className="site_img"
                      src={`${AMAZON_URL}/ColorHomesfyLogo.svg`}
                      alt=" site_image"
                    />
                  </Link>
                )}
              </li>
              {!hideSearchRole.includes(role) ?
              <li
                className="nav-item mobile"
                onClick={this.handlesearch}
              >
                <span className="nav-link pl-2 pr-0" >
                  <SearchIcon className="nav-icon search_icon" />
                </span>
              </li>
              : role === "magnet" && <li className="nav-item"><TryNewCRM text="Try new look"/></li>
              }
              <li
                className="nav-item mobile"
                onClick={this.handleMobileDrawer}
              >
                <span className="nav-link pl-2 pr-0" data-widget="pushmenu">
                  <FontAwesomeIcon icon={faBars} className="nav-icon" />
                </span>
              </li>
            </ul>
          </nav>
        )}
        {this.state.searchBarFlag === true &&
          <div className="search-group mobile_search d-md-none">

                    <FormControl variant="outlined">
                      <Autocomplete
                        key={autoKey} 
                          id="client_id"
                          freeSolo
                          className={"MuiAutocomplete-header"}
                          ref={elem => this.inputField = elem}
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={option => `${option ? option.id : ''} ${option.Client ? option.Client.name : ''} ${option.Client ? option.Client.number : ''}`}
                          options={option}
                          loading={isLoading}
                          onChange={(_event, option) => {
                            if (option === null) {
                              this.setState({
                                option: [],
                              })
                            }else{
                              let clientId = option.Client.id;
                              this.setState({
                                client_id : clientId,
                              })
                              this.clientSelected(clientId)
                            }

                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search Lead"
                              fullWidth
                              onChange={this.handleAutoChange}
                              InputProps={{
                                ...params.InputProps,
                                onKeyDown(e) {
                                  if (e.keyCode === 13 && e.target.value) {
                                    return false
                                  }
                                },
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />  
                    </FormControl>
                      <span id="firstName-error" className="error invalid-feedback">Please enter First Name</span>
                    </div>
        }
      </Frag>
    );
  }
}

export default Header;
