import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import CloseupdateForm from "../form/closeUpdateForm";
import RemindUpdateForm from "../form/remindUpdateForm.js";
import DesDelayUpdateForm from "../form/DesDelayUpdateForm.js";
import OpportunityUpdateForm from "../form/opportunityUpdateFrom.js";
import PipelineUpdateForm from "../form/pipelineUpdateForm";
import EoiUpdateForm from "../form/eoiUpdateForm";
import EoiNonRefundable from "../form/eoiNonRefundable.js";
import CancelUpdateForm from "../form/cancelUpdateForm";
import BookedUpdateForm from "../form/bookedUpdateForm";
import { getLeadStatusData } from "../../../dataParser/commomDataApi";
import { REVERT_STAGES } from "../../../config/constant";
import Modal, {ModalTitle, ModalBody} from "../../../core/Dialog";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { getClientData, getFullLeadData } from "../../../dataParser/getListData";
import { dataSort, removeDuplicates } from "../../../helper";
import FormControl from '@material-ui/core/FormControl';

class LeadModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			status: [],
			clientData: {},
			selected_status_id: "",
			current_status_id: ""
		}
	}

	checkstate(state) {
		if (state > 20 && state < 35) {
			return state;
		}
	}

	async componentDidMount() {

		let resData = await getClientData(this.props.leadDetail?.data?.Client?.id);
		this.setState({ clientData: resData });

		if (this.props.leadDetail.ObjectStage !== null) {

			const current_status_id = this.props.leadDetail.data?.ObjectStage?.stage_id;
			var getStatusData = await getLeadStatusData();

			if (getStatusData.meta.status === 200) {
				let result = getStatusData.data.map(obj => {
					if (current_status_id === obj.id) {
						let actions = [];
						obj.Actions.map(act => {
							if (act.stage_id !== 10) {
								actions.push({ status_id: act.stage_id, status: REVERT_STAGES[act.stage_id] })
							}
						})

						if (current_status_id !== 1 && current_status_id !== 10) {
							let current_lead_stage = { status_id: this.props.leadDetail.data?.ObjectStage?.Stage?.id, status: this.props.leadDetail.data?.ObjectStage?.Stage?.name }
							actions.unshift(current_lead_stage);
							this.setState({ selected_status_id: current_status_id });
						}

						actions.sort(dataSort("status"));
						actions = removeDuplicates(actions, "status");

						this.setState({ status: actions });
					}
				})

			} else {
				this.setState({ status: getStatusData })
			}
		}

	}

	async componentDidUpdate(nextProps, prevProps) {

		if (nextProps.leadDetail.data.id !== this.props.leadDetail.data.id) {

			if (this.props.leadDetail.ObjectStage !== null) {

				const current_status_id = this.props.leadDetail.data?.ObjectStage?.stage_id;
				var getStatusData = await getLeadStatusData();

				if (getStatusData.meta.status === 200) {
					let result = getStatusData.data.map(obj => {
						if (current_status_id === obj.id) {
							let actions = [];
							obj.Actions.map(act => {
								if (act.stage_id !== 10) {
									actions.push({ status_id: act.stage_id, status: REVERT_STAGES[act.stage_id] })
								}
							})

							if (current_status_id !== 1 && current_status_id !== 10) {
								let current_lead_stage = { status_id: this.props.leadDetail.data?.ObjectStage?.Stage?.id, status: this.props.leadDetail.data?.ObjectStage?.Stage?.name }
								actions.unshift(current_lead_stage);
								this.setState({ selected_status_id: current_status_id });
							}

							actions.sort(dataSort("status"));

							actions = removeDuplicates(actions, "status");

							this.setState({ status: actions })
						}
					})

				} else {

					this.setState({ status: getStatusData })
				}
			}

			(async () => {
				try {
					let resData = await getClientData(this.props.leadDetail?.data?.Client?.id);
					await this.setState({
						clientData: resData
					});
				} catch (e) {
					//...handle the error...
				}
			})();
		}


	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleClose = () => {
		this.setState({ selected_status_id: this.props.leadDetail.data?.ObjectStage?.stage_id });
	}

	render() {
		const current_status_id = this.props.leadDetail.data?.ObjectStage?.stage_id
		const FROMS_TYPES = {
			9: CloseupdateForm,
			2: RemindUpdateForm,
			3: OpportunityUpdateForm,
			4: DesDelayUpdateForm,
			5: PipelineUpdateForm,
			8: CancelUpdateForm,
			7: BookedUpdateForm,
			6: EoiUpdateForm,
			15: EoiNonRefundable,
		}
		var FormName = '';
		var { selected_status_id, status } = this.state;

		if (selected_status_id !== "") {
			FormName = FROMS_TYPES[this.state.selected_status_id];
			selected_status_id = this.state.selected_status_id;
		} else {
			FormName = FROMS_TYPES[this.props.formStatus];
			selected_status_id = this.props.formStatus;
		}

		return (
			<Frag>
				<Modal
					show={this.props.show}
					onHide={(value) => { this.props.changeModal(this.props.fromType); this.handleClose(); }}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					id='LeadUpdate'
					backdrop="static"
				>
					<ModalTitle id="contained-modal-title-vcenter" onHide={(value) => { this.props.changeModal(this.props.fromType); this.handleClose(); }} >
							Update Lead
			        </ModalTitle>
					<ModalBody>
						<div className="col-12 mb-3">
							<FormControl>
								<InputLabel id="demo-controlled-open-select-label">Select Lead Status</InputLabel>
								<Select
									labelId="demo-controlled-open-select-label"
									value={`${selected_status_id}`}
									onChange={this.handleChange}
									inputProps={{
										name: 'selected_status_id',
										id: 'selected_status_id',
									}}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{status ?
										(status.map(reg =>
											<MenuItem className="text-capitalize" value={reg.status_id} key={reg.status_id}>{reg.status}</MenuItem>
										))
										:
										''
									}
								</Select>
							</FormControl>
						</div>
						{FormName !== undefined ?
							<FormName changeModal={(value) => this.props.changeModal(this.props.fromType)}
							 getList={this.props.getList}
							 leadDetail={this.props.leadDetail}
							 clientData={this.state.clientData}
							 selectedFormId={current_status_id}
							  />
							:
							<div className="col-12 mb-3">

								<span className="text-danger small">*Form is not available for this request!!!</span>
							</div>
						}
					</ModalBody>
				</Modal>
			</Frag>
		);
	};
};


const checkState = (state) => {
	if (state > 20 && state < 35) return state;
}

const FROMS_TYPES = {
	9: CloseupdateForm,
	2: RemindUpdateForm,
	3: OpportunityUpdateForm,
	4: DesDelayUpdateForm,
	5: PipelineUpdateForm,
	8: CancelUpdateForm,
	7: BookedUpdateForm,
	6: EoiUpdateForm,
	15: EoiNonRefundable,
}

export const LeadUpdateModal =  (props) => {
	const [state, setState] = React.useState({
		status: []
		, clientData: {}
		, selected_status_id: ""
		, current_status_id: ""
		, fullDetails: {}
	})


	const { show, onHide, onSuccess, lead_id} = props

	const initialCall = async () => {

		let _state = {}

		let resData = await getFullLeadData(lead_id);
		_state = {
			..._state
			, fullDetails: {...resData }
		};

		resData = await getClientData(_state.fullDetails?.data?.Client?.id)
		_state = {..._state, clientData: resData};

		if(_state.fullDetails?.data.ObjectStage === null) return;

		const current_status_id = _state.fullDetails?.data?.ObjectStage?.stage_id;

		const getStatusData = await getLeadStatusData();

		if (getStatusData.meta.status !== 200){
			setState({...state, ..._state})
			return;
		}


		getStatusData.data.map(obj => {
			if (current_status_id !== obj.id) return true;

			let actions = [];

			obj.Actions.map(act => {
				if (act.stage_id === 10) return true;

				actions.push({ status_id: act.stage_id, status: REVERT_STAGES[act.stage_id] })
			})

			if (current_status_id !== 1 && current_status_id !== 10) {
				let current_lead_stage = { 
					status_id: _state.fullDetails.data?.ObjectStage?.Stage?.id
					, status: _state.fullDetails.data?.ObjectStage?.Stage?.name 
				}
				actions.unshift(current_lead_stage);
				_state = {..._state, selected_status_id: current_status_id };
			}

			actions.sort(dataSort("status"));
			actions = removeDuplicates(actions, "status");

			_state = {..._state, status: actions};

			setState({...state, ..._state});

			return true;
		})

		setState({...state, ..._state})
	}

	React.useEffect(() => {
		initialCall();
	}, [])

	const handleChange = event => setState({...state, [event.target.name]: event.target.value})

	const current_status_id = state.fullDetails?.data?.ObjectStage?.stage_id
	const formStatus = state.fullDetails?.data?.ObjectStage?.Stage?.name

	let FormName = '';
	let { selected_status_id, status } = state;

	if (selected_status_id !== "") {
		FormName = FROMS_TYPES[state.selected_status_id];
		selected_status_id = state.selected_status_id;
	} else {
		FormName = FROMS_TYPES[formStatus];
		selected_status_id = formStatus;
	}

	if (Boolean(state.fullDetails?.data?.id) === false) {
		return <></>
	}
	return 	<Modal
		show={show}
		onHide={onHide}
		size="lg"
		aria-labelledby="contained-modal-title-vcenter"
		centered
		id='LeadUpdate'
	>
			<ModalTitle id="contained-modal-title-vcenter" onHide={onHide}>
				Update Lead
			</ModalTitle>
		<ModalBody>
			<div className="col-lg-6 col-sm-12 col-12 mb-3">
				<FormControl>
					<InputLabel id="demo-controlled-open-select-label">Select Lead Status</InputLabel>
					<Select
						labelId="demo-controlled-open-select-label"
						value={`${selected_status_id}`}
						onChange={handleChange}
						inputProps={{
							name: 'selected_status_id',
							id: 'selected_status_id',
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{status ?
								(status.map(reg =>
								<MenuItem className="text-capitalize" value={reg.status_id} key={reg.status_id}>{reg.status}</MenuItem>
								))
								:
								''
						}
					</Select>
				</FormControl>
			</div>
			{(FormName !== undefined) ?
				<FormName changeModal={() => true}
					getList={onSuccess}
					leadDetail={state.fullDetails}
					clientData={state.clientData}
					selectedFormId={current_status_id}
				/>
				:
				<div className="col-12 mb-3">
					<span className="text-danger small">*Form is not available for this request!!!</span>
				</div>
			}
		</ModalBody>
	</Modal>

}

export default LeadModal;
