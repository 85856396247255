import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';

import { businessOperate, businessTime, leads, MenuProps, mgOperateIn, operate, primary, yesNoType } from '../../datas/data';
import RadioGroup from '../../controls/RadioGroup';
import InputField from '../../controls/InputField';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FormHelperText from '@material-ui/core/FormHelperText';

import Autocomplete from '@material-ui/lab/Autocomplete';

import Button from '@material-ui/core/Button';
import { useEffect } from 'react';
import { getRegionParentsData, getSingleCpUser, getUpdateCpUser } from '../../../../dataParser/getCpUserData';
import { cleanStorage, objEmpty } from '../../../../helper';

import PopUpAlert from '../../sweetAlert/popUpAlert';
import Loader from '../../loader/loader';

const validateName = RegExp(/^[a-zA-Z ]+$/);

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
const MagnetForm = ({ activeTab, hideInactiveTab, changeModal }) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = React.useState(activeTab);
    const [showLoader, setLoaderStatus] = React.useState(false);

    const [state, setState] = React.useState({
        first_name: ""
        , last_name: ""
        , phone: ""
        , email: ""

        , alternate_business_us: ""
        , region: []
        , selectedVal: []
        , parent_region_ids: []

        , office_location: ""

        , gst_no: ""
        , rera_no: ""
        , deal_business_types: []
        , devote_time: ""
        , operate_business: ""
        , have_team: ""
        , total_experience: null
        , own_state_business: ""
        , plateform_present_in: []
        , genrate_leads: []
        , list_properties: null
        , profile_pic: []
        , pincode: null
        , visiting_card: []

        , errors: {
            first_name: '',
            phone: '',
            email: '',
            last_name: '',
            p_message: '',
        }
        , alertShow: false
        , alertType: "success"
        , alertTitle: ""

        , user_id: window.$user?.id
        , work_in_region: ""
    })

    const [region, setRegion] = React.useState([]);

    let { first_name, last_name, phone, email
        , alternate_business_us, selectedVal, parent_region_ids, office_location
        , gst_no, rera_no, deal_business_types, devote_time, operate_business, have_team, total_experience, own_state_business, plateform_present_in, genrate_leads, list_properties
        , profile_pic, pincode, visiting_card, errors, work_in_region
        , alertShow, alertType, alertMsg
    } = state;

    useEffect(() => {
        handleSingleUser(state.user_id)
        getRegionData()
    }, [])

    const getRegionData = async () => {
        let regionData = await getRegionParentsData();
        setRegion(regionData.data)
    }

    const onChange = (e) => {
        let { name, value } = e.target;

        setState({
            ...state,
            [name]: value
        })
    }
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };


    const handleSingleUser = async id => {
        setLoaderStatus(true)

        let singleUser = await getSingleCpUser(id);
        let getData = singleUser.data;
        let CpData = getData.UserInfo;

        let arrParent = getData.UserRegions !== null ? getData.UserRegions : [];

        let userDataArr = [];
        let parentUser = [];
        arrParent.map((cur, index) => {
            let parent = {};
            parent.id = cur.RegionParent?.id
            parent.name = cur.RegionParent?.name
            userDataArr.push(parent);
            parentUser.push(cur.RegionParent?.id);
        })
        if (singleUser.meta.status === 200) {
            setLoaderStatus(false)
            
            // setRegion(userDataArr || [])
            setState({
                ...state,
                alertShow: false,
                singleUser: singleUser,
                first_name: getData.first_name,
                last_name: getData.last_name,
                phone: getData.phone,
                email: getData.email,

                alternate_business_us: CpData.alternate_business_us === null ? "" : CpData.alternate_business_us || [],
                selectedVal: userDataArr || [],
                parent_region_ids: parentUser,
                office_location: CpData.office_location === null ? "" : CpData.office_location,
                total_experience: CpData.total_experience === null ? "" : CpData.total_experience,
                
                own_state_business: CpData.own_state_business === null ? null : CpData.own_state_business,
                list_properties: CpData.list_properties === null ? null : CpData.list_properties,
                operate_business: CpData.operate_business === null ? "" : CpData.operate_business,
                
                pincode: CpData.pincode === null ? "" : CpData.pincode,
                
                gst_no: CpData.gst_no === null ? "" : CpData.gst_no,
                rera_no: CpData.rera_no === null ? "" : CpData.rera_no,
                devote_time: CpData.devote_time === null ? "" : CpData.devote_time,
                have_team: CpData.have_team === null ? null : CpData.have_team,

                deal_business_types: typeof CpData.deal_business_types === 'string' ? JSON.parse(CpData.deal_business_types) : CpData.deal_business_types || [],
                genrate_leads: typeof CpData.genrate_leads === 'string' ? JSON.parse(CpData.genrate_leads) : CpData.genrate_leads || [],
                plateform_present_in: typeof CpData.plateform_present_in === 'string' ? JSON.parse(CpData.plateform_present_in) : CpData.plateform_present_in || [],
                work_in_region: CpData.WorkRegion ? CpData.WorkRegion.name : ""                   
            });
        } else {

            setLoaderStatus(false)

            setState({
                ...state,
                alertShow: true,
                alertType: "error",
                alertTitle: "User not found !!!"
            });
        }
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        let addUserData = {};
        let updateCpData = {};
        addUserData.data = {};
        addUserData.data.Usermeta = {};
        if(currentTab === 0){
            if (first_name) addUserData.data["first_name"] = first_name;
            if (last_name) addUserData.data["last_name"] = last_name;
            if (email) addUserData.data["email"] = email;            
        }

        if(currentTab === 1) {
            if(alternate_business_us && parent_region_ids.length > 0 && office_location){
                addUserData.data.Usermeta["alternate_business_us"] = alternate_business_us;
                addUserData.data.Usermeta["parent_region_ids"] = parent_region_ids;
                addUserData.data.Usermeta["office_location"] = office_location;
            }
            else{
                setState({
                    ...state,
                    alertShow: true,
                    alertType: "error",
                    alertMsg: "*Above all fields are required!"
                });      
                return false
            }
        }
        if(currentTab === 0 || currentTab === 2){

            if (gst_no) addUserData.data.Usermeta["gst_no"] = gst_no;
            if (rera_no) addUserData.data.Usermeta["rera_no"] = rera_no;
            if (devote_time) addUserData.data.Usermeta["devote_time"] = devote_time;
            if (have_team) addUserData.data.Usermeta["have_team"] = parseInt(have_team);
            if (total_experience) addUserData.data.Usermeta["total_experience"] = parseFloat(total_experience);
            if (list_properties) addUserData.data.Usermeta["list_properties"] = parseInt(list_properties);
            if (own_state_business) addUserData.data.Usermeta["own_state_business"] = own_state_business == "1" ? true : false;
            if (operate_business) addUserData.data.Usermeta["operate_business"] = operate_business;
            if (deal_business_types.length > 0) addUserData.data.Usermeta["deal_business_types"] = deal_business_types;
            if (plateform_present_in.length > 0) addUserData.data.Usermeta["plateform_present_in"] = plateform_present_in;
            if (genrate_leads.length > 0) addUserData.data.Usermeta["genrate_leads"] = genrate_leads;
            if (profile_pic.length > 0) addUserData["profile_pic"] = profile_pic[0];
            if (pincode) addUserData.data.Usermeta["pincode"] = pincode;
            if (visiting_card.length > 0) addUserData["visiting_card"] = visiting_card[0];
            if (objEmpty(addUserData.data.Usermeta)) delete addUserData.data.Usermeta;
        }
        addUserData.data = JSON.stringify(addUserData.data);
        
        updateCpData.data = addUserData;
        updateCpData.id = window.$user.id;
        
        if (first_name !== "" && last_name !== "") {
            setLoaderStatus(true)
            let addLeadRes = await getUpdateCpUser(updateCpData);
            
            if (addLeadRes.meta.status === 201) {

                setLoaderStatus(false)

                setState({
                    ...state,
                    alertShow: true,
                    alertType: "success",
                    alertMsg: "User Updated Successfully!!!"
                });
            } else if (addLeadRes.meta.status === 401) {

                setLoaderStatus(false)

                cleanStorage();
                this.props.history.push("/login");

            } else {

                setLoaderStatus(false)

                setState({
                    ...state,
                    alertShow: true,
                    alertType: "error",
                    alertMsg: addLeadRes.meta.message || "Something went wrong !!!"
                });
            }
            if(activeTab !== 1){
                handleNextTab();
            }
            if(activeTab == 1 || currentTab == 2){
                setTimeout(()=>{
                    changeModal()
                }, 3000)
            }
        } else {

            setState({ ...state, isError: true });

            if (first_name == "" || validateName.test(first_name) === false) {

                setState(prevState => ({
                    ...state,
                    errors: {
                        ...prevState.errors,
                        first_name: "*Please enter valid client name"
                    }
                }))
            } else {

                setState(prevState => ({
                    ...state,
                    errors: {
                        ...prevState.errors,
                        first_name: ""
                    }
                }))
            }

            if (last_name == "" || validateName.test(last_name) === false) {

                setState(prevState => ({
                    ...state,
                    errors: {
                        ...prevState.errors,
                        last_name: "*Please enter valid Last name"
                    }
                }))
            } else {

                setState(prevState => ({
                    ...state,
                    errors: {
                        ...prevState.errors,
                        last_name: ""
                    }
                }))
            }

        }
    }
    const handleNextTab = () => {
        if(currentTab !== 2){
            setCurrentTab(currentTab + 1)
        }
    }
    const handleClose = () => {
        setState({ ...state, alertShow: false })
    }
    return (
        <div className={classes.root}>
            <AppBar position="static">
                {hideInactiveTab
                    ? <Tabs value={currentTab} onChange={handleChange} aria-label="simple tabs example">
                        {(activeTab === 0) && <Tab label="Account Details" {...a11yProps(0)} />}
                        {(activeTab === 1) && <Tab label="Preferences" {...a11yProps(1)} />}
                        {(activeTab === 2) && <Tab label="Additional Details" {...a11yProps(2)} />}
                    </Tabs>
                    : <Tabs value={currentTab} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Account Details" {...a11yProps(0)} />
                        <Tab label="Preferences" {...a11yProps(1)} />
                        <Tab label="Additional Details" {...a11yProps(2)} />
                    </Tabs>
                }
            </AppBar>
            <TabPanel value={currentTab} index={0}>
                <div className='row mb-3' id="SectionOne">
                    <div className="col-md-6 mb-3">
                        <TextField
                            error={errors.first_name !== '' ? true : false}
                            required
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            value={first_name ? first_name : ''}
                            helperText={errors.first_name !== '' ? errors.first_name : ''}
                            onChange={onChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            error={errors.last_name !== '' ? true : false}
                            required
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            value={last_name ? last_name : ''}
                            helperText={errors.last_name !== '' ? errors.last_name : ''}
                            onChange={onChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <TextField
                                error={errors.phone !== '' ? true : false}
                                required
                                disabled={true}
                                id="phone"
                                name="phone"
                                value={phone ? phone : ''}
                                helperText={errors.phone !== '' ? errors.phone : ''}
                                label="Mobile Number"
                                onChange={onChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIphoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-1">
                        <div className="form-group">
                            <TextField
                                error={errors.email !== '' ? true : false}
                                required
                                id="email"
                                name="email"
                                label="Email"
                                value={email ? email : ''}
                                helperText={errors.email !== '' ? errors.email : ''}
                                onChange={onChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-10 text-center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                        >
                            Submit
                        </Button>
                    </div>
                    {/* <div className="col-md-1">
                        <Button onClick={handleNextTab}
                            variant="contained"
                            color="primary">Next</Button>
                    </div> */}
                </div>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <div className='row mb-3' id="SectionTwo">
                    <div className="col-md-6 mb-3">
                        <RadioGroup
                            name="alternate_business_us"
                            value={alternate_business_us}
                            label="Do you operate in Resale or Rental? *"
                            onChange={onChange}
                            items={mgOperateIn}
                        />
                    </div>

                    <div className="col-md-6">
                        <Autocomplete
                            multiple
                            getOptionSelected={(region, value) =>
                                region.name === value.name
                            }
                            getOptionLabel={region => region.name}
                            options={region}
                            value={selectedVal}
                            renderTags={(value, getTagProps) =>
                                value.map((region, index) => (
                                    <Chip
                                        label={region.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            onChange={(_event, region) => {
                                let regionIds = region.map(ops => ops.id);
                                setState({
                                    ...state,
                                    parent_region_ids: regionIds,
                                    selectedVal: region
                                });

                                if (region === null) {
                                    setState([]);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Select the (multiple) regions you are active in *"
                                    fullWidth
                                    onChange={onChange}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl className="mt-3">

                            <TextField
                                required
                                id="office_location"
                                name="office_location"
                                value={office_location}
                                label="Office Location?"
                                onChange={onChange}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-10 text-center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                        >
                            Submit
                        </Button>
                    </div>
                    {/* <div className="col-md-1">
                        <Button onClick={handleNextTab}
                            variant="contained"
                            color="primary">Next</Button>
                    </div> */}
                </div>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <div className='row mb-3' id="SectionThree">
                    <div className="col-md-6 mb-3">
                        <TextField
                            error={!!gst_no ? false : true}
                            label="GSTIN (If Applicable)"
                            value={gst_no ? gst_no : ''}
                            id="gst_no"
                            name="gst_no"
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            error={!!rera_no ? false : true}
                            label="RERA No."
                            value={rera_no}
                            id="rera_no"
                            name="rera_no"
                            onChange={onChange} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            value={total_experience}
                            id="total_experience"
                            name="total_experience"
                            type="number"
                            onChange={onChange}
                            label="Total Experience In Years"
                            inputProps={{
                                min: "0"
                            }}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl>
                            <InputLabel id="primary-business">Type of Real Estate Business you deal with</InputLabel>
                            <Select
                                labelId="primary-business"
                                id="demo-mutiple-checkbox"
                                multiple
                                name="deal_business_types"
                                value={deal_business_types}
                                onChange={onChange}
                                input={<Input id="primary-multiple-chip" />}
                                renderValue={selected => (
                                    <div>
                                        {selected.map(value => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {primary.map(item => (
                                    <MenuItem key={item.id} value={item.name}>
                                        <Checkbox checked={deal_business_types.indexOf(item.name) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl>
                            <InputLabel id="secondary-business">What platform are you present in?</InputLabel>
                            <Select
                                labelId="operate-business"
                                id="operate-checkbox"
                                multiple
                                name="plateform_present_in"
                                value={plateform_present_in}
                                onChange={onChange}
                                input={<Input id="operate-multiple-chip" />}
                                renderValue={selected => (
                                    <div>
                                        {selected.map(value => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {operate.map(item => (
                                    <MenuItem key={item.id} value={item.name}>
                                        <Checkbox checked={plateform_present_in.indexOf(item.name) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl>
                            <InputLabel id="generate-business">How do you Generate Leads ?</InputLabel>
                            <Select
                                labelId="generate-business"
                                id="generate-checkbox"
                                multiple
                                name="genrate_leads"
                                value={genrate_leads}
                                onChange={onChange}
                                input={<Input id="generate-multiple-chip" />}
                                renderValue={selected => (
                                    <div>
                                        {selected.map(value => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {leads.map(item => (
                                    <MenuItem key={item.id} value={item.name}>
                                        <Checkbox checked={genrate_leads.indexOf(item.name) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <TextField
                                type="number"
                                name="pincode"
                                value={pincode ? pincode : null}
                                label="Pin Code"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl>
                            <FilePond
                                // ref={ref => (this.pond = ref)}
                                visiting_card={visiting_card}
                                labelIdle='Drag & Drop your Visiting Card or <span class="filepond--label-action">Browse</span>'
                                allowMultiple={false}
                                server={{
                                    process: (fieldName, file, metadata, load) => {
                                        setTimeout(() => {
                                            load(Date.now())
                                        }, 1500);
                                    },
                                    load: (source, load) => {
                                        fetch(source).then(res => res.blob()).then(load);
                                    }
                                }}
                                onupdatefiles={fileItems => {
                                    setState({
                                        ...state,
                                        visiting_card: fileItems.map(fileItem => fileItem.file)
                                    });
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl>
                            <FilePond
                                // ref={ref => (this.pond = ref)}
                                profile_pic={profile_pic}
                                labelIdle='Drag & Drop your Profile Image or <span class="filepond--label-action">Browse</span>'
                                allowMultiple={false}
                                server={{
                                    process: (fieldName, file, metadata, load) => {
                                        setTimeout(() => {
                                            load(Date.now())
                                        }, 1500);
                                    },
                                    load: (source, load) => {
                                        fetch(source).then(res => res.blob()).then(load);
                                    }
                                }}
                                onupdatefiles={fileItems => {
                                    setState({
                                        ...state,
                                        profile_pic: fileItems.map(fileItem => fileItem.file)
                                    });
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-6">
                        <RadioGroup
                            name="devote_time"
                            value={devote_time}
                            label="How do you devote time to business?"
                            onChange={onChange}
                            items={businessTime}
                        />
                    </div>
                    <div className="col-md-6">
                        <RadioGroup
                            required
                            name="operate_business"
                            value={operate_business}
                            label="How do you operate your business?"
                            onChange={onChange}
                            items={businessOperate}
                        />
                    </div>
                    <div className="col-md-6">
                        <RadioGroup
                            required
                            name="have_team"
                            value={have_team}
                            label="Do you have a Team?"
                            onChange={onChange}
                            items={yesNoType}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <RadioGroup
                            name="own_state_business"
                            value={own_state_business}
                            label="Do you own a real-estate business?"
                            onChange={onChange}
                            items={yesNoType}
                        />
                    </div>
                    <div className="col-md-6 pr-md-0">
                        <RadioGroup
                            name="list_properties"
                            value={list_properties}
                            label="Would you be interested in Listing of Properties?"
                            onChange={onChange}
                            items={yesNoType}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <InputField
                            disabled
                            variant="standard"
                            label="Parent Region"
                            value={work_in_region}
                        />
                    </div>
                    <div className="col-md-12 text-center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </TabPanel >
            <PopUpAlert type={alertType} msg={alertMsg} open={alertShow} handleClose={handleClose} hideTime={6000} />
            <Loader show={showLoader} />
        </div >
    );
}

export default MagnetForm
