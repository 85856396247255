import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import ListingView, {OneFourthField} from '../listing/ListingView';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck , faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { assignLeads } from "../../../dataParser/getListData";
import ModalAssignRM from "../modal/modal";
import Loader from "../loader/loader";
import SweetAlert from "../sweetAlert/sweetAlertSuccess";
class AssignLeadsList extends Component {
	constructor(props){
		super(props);
		this.state = {
			showLoader:false,
			ids: this.props.lead_ids,
            AssignUser: null,
            reAssign: null,
            reAssignLeadID: null,
            is_focus: {},
			directAssign:true,
			sweetShow: false,
        	type: "success",
        	title: ""
        }
	}
	componentDidMount(){
		
	}
	assignCurrentRm = async (leadId,rm) => {
		if(leadId && rm){
			this.setState({ showLoader: true });
			var addLeadRes = await assignLeads({ ids: [leadId], assign_rm_id: rm.id });
			if (addLeadRes.meta.status === 201) {
				let idIndex = this.state.ids.indexOf(leadId)
				if (idIndex !== -1) {
					this.state.ids.splice(idIndex, 1);
				}
                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "success",
                    title: "Assigned successfully !!!"
                });
            }
		}
	}
	handleUpdateCpModal = () => {
        this.setState({
            modalCpUpdateShow: !this.state.modalCpUpdateShow,
            AssignUser: null,
            reAssign: null,
            reAssignLeadID: null,
            is_focus: {}
        });
        // window.location.reload();
    }
	handleAssingUserPopup(id){
		this.setState({ids:[id],modalCpUpdateShow: true, AssignUser: 'lead_assign'});
	}
	handleSweet = (type) => {
        // console.log("type On the first =========>",type)
		this.setState({sweetShow:false});
    }
	render() {
		return (	
			<Frag>
				<Modal
					show={this.props.show}
					onHide={this.props.changeModal}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="unselectable"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Same Client
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<SweetAlert show={this.state.sweetShow} type={this.state.type} title={this.state.title} changeSweet={this.handleSweet} />	
					<Loader show={this.state.showLoader} />	
					<div className="listContainer">
						{this.props?.leads && this.props?.leads?.map((item, i)=>(
						<ListingView key={i}>
							<OneFourthField title="Lead ID" value={item?.id || '-'}/>
							<OneFourthField title="Project Name" value={item?.Project?.name || '-'}/>
							<OneFourthField title="RM Name" value={this.state.ids.indexOf(item.id) != -1 ? this.props.assign_rm.name : item?.AssignLead?.Assignee?.name}/>
							<OneFourthField title="Lead Status" value={item?.ObjectStage?.Stage?.name}/>
							<OneFourthField title="Contact" value={item?.Client?.number}/>
							{this.state.ids && this.state.ids.indexOf(item.id) != -1 ? (<div className = "action_box" style={{width:'62px',bottom: '3px'}}>
									<a className="Action"
										data-toggle="tooltip" data-placement="bottom" title="Assign RM"
										onClick={() => { this.assignCurrentRm(item?.id,this.props.assign_rm)}}
										data={item.id}
									>
										<FontAwesomeIcon icon={faCheck} className="text-white" />   
									</a>
									<a className="Action"
										data-toggle="tooltip" data-placement="bottom" title="Assign RM"
										onClick={() => { this.handleAssingUserPopup(item?.id)}}
										data={item.id}
									>
										<FontAwesomeIcon icon={faUserCheck} className="text-white" />
									</a>
							</div>):''}
						</ListingView>
						))}
					</div>
					</Modal.Body>
				</Modal>
				<ModalAssignRM show={this.state.modalCpUpdateShow} reAssign={this.state.reAssign} reAssignLeadID={this.state.reAssignLeadID} changeModal={this.handleUpdateCpModal} handleInterestUser={this.state.ids} from={this.state.AssignUser} directAssign={this.state.directAssign} filter = {this.props.filter} getList={this.props.getList}/>
			</Frag>
		);
	};
};

export default AssignLeadsList;