import React, { Component } from 'react';
// import { renderToStaticMarkup } from 'react-dom/server';
import SweetAlert from 'react-bootstrap-sweetalert'; // eslint-disable-line import/no-extraneous-dependencies
// import 'sweetalert/dist/sweetalert.css';
import Frag from "../../../utils/Frag/Frag.js";

class sweetWarnAlert extends Component {

	// constructor(props){
	// 	super(props);
	// 	this.state = {
	//       sweetShow: false,
	// 	};
	// };

	constructor(props){
		super(props);
		this.state = {
	      reason: '',
		};
	};

	handleChange = e => {
	    this.setState({ reason: e.target.value });
	};
	// onOutsideClick={this.props.changeSweet} ===== For Making the alert hide onClick out Side.
   // onCancel={this.props.changeSweet}
                  // showCancelButton
    handleConfirm = () => {
    	console.log('handleconfirm...')
    	if(this.props.closingReason){
	    	if(this.state.reason !== ''){
		    	this.props.handleUpdateUser(this.state.reason);
		    	this.setState({reason:''})
	    	}
	    }
    	else{
    		this.props.handleUpdateUser()
    	}
    }
	render(){
		// console.log("this.props.Sweetshow",this.props.changeSweet);
		return (

			<Frag>
				<SweetAlert
				  warning
				  show={this.props.show}
				  showCancel = {this.props.showCancel != undefined ? this.props.showCancel : true }
				  showConfirm = {this.props.showConfirm != undefined ? this.props.showConfirm : true }
				  confirmBtnText= { this.props?.confirmBtnText ||  `Yes`}
				  cancelBtnText= { this.props?.showCancelTxt || `No`}
				  confirmBtnBsStyle= { this.props?.confirmBtnBsStyle || `danger`}
				  cancelBtnBsStyle={ this.props?.cancelBtnBsStyle || `info`}
				  title="Are you sure?"
				  onConfirm={this.handleConfirm}
				  onCancel={this.props.changeSweet}
				  focusCancelBtn
				>
				{this.props.message}
				{this.props.customeBtn ? <div className="col-md-12">
					<div className="row">
						<div className="col-sm-6 text-right col-6 mt-3">
						<div className="form-group">
							<button
							className="btn btn-success"
							onClick={e => this.props.handleUpdateUser()}
							>
							{this.props?.confirmBtnText}
							</button>
						</div>
						</div>
						<div className="col-sm-6 col-6 mt-3">
						<div className="form-group">
							<button
							className="btn btn-danger"
							onClick={e => this.props.handleCancel()}
							>
							{this.props?.cancelBtnText}
							</button>
						</div>
						</div>
					</div>
				</div> : null}
				<br/><br/>
				{this.props.closingReason === 'true' && <Dropdown handleChange={this.handleChange}/>}
				</SweetAlert>
			</Frag>

		);
	};

};


class Dropdown extends Component {
	
	constructor(props){
		super(props);
	};
	
	render(){
		return(
			<Frag>
				<form>
					<select 
				    	onChange = {this.props.handleChange}
				    >
				    	<option value='' selected disabled>Select Delete Reason*</option>
				    	<option value='Test Lead'>Test Lead</option>
				    	<option value='Duplicate Lead'>Duplicate Lead</option>
				    	<option value='Wrong Number'>Wrong Number</option>
				    </select>
				</form>
			</Frag>

		);
	};

};


export default sweetWarnAlert;