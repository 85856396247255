import React from 'react';

import Box from '../../core/Box';
import Grid from '../../core/Grid';
// import Button from '../../core/Button';
import {FormikTextField} from '../../core/TextField';
import Typography from '../../core/Typography';
import { Formik, Form } from 'formik';
import api from '../../api';
import { BED_CONFIG } from '../../config/constant';
import { plotCommercial } from '../../helper';


const defaultState = {
    profile: {}
    , lead: {}
}

const BudgetPage = ({lead, profile, handleBudgetChange}) => {
    const [state, setState] = React.useState(defaultState);

    const enableForm = (e) =>{
        e.preventDefault();
        setState({...state, isEditable: true})
    }

    React.useEffect(() => {
        setState({...state, profile, lead})
    }, [lead, profile])

    const save = () =>{
    }

    if(!profile.id) return <></>
    
    const bed_config = plotCommercial(state.lead.ClientBudgetInfo?.required_bhk);
    return <Box className="BudgetPage">
        <Formik enableReinitialize initialValues={{
            agreement_value: state.lead.ClientBudgetInfo?.agreement_value,
            interested_location: state.lead.ClientBudgetInfo?.interested_location,
            loan_required: state.lead.load_required ? "Yes": "No",
            loan_sanction: state.lead.ClientBudgetInfo?.loan_sanction, 
            max_budget: state.lead.ClientBudgetInfo?.max_budget,
            min_budget: state.lead.ClientBudgetInfo?.min_budget,
            property_for: state.lead.ClientBudgetInfo?.property_for,
            required_bhk: bed_config,
        }} 
            validate= {values => {
            }}
            onSubmit= {(values, {resetForm})=> {
                const payload = {
                    Client:{
                        name: state.lead.Client.name,
                        id: state.lead.Client.id,
						ClientBudgetInfo: {
							interested_location: values.interested_location.toString(),
							max_budget: Number(values.max_budget),
							min_budget: Number(values.min_budget),
							property_for: values.property_for.toString(),
							required_bhk: Number(values.required_bhk),
                            loan_required: values.loan_required  == "Yes"? 1 : 0,
                            loan_sanction: Number(values.loan_sanction), 
						}
                    },
                    Booking:{
                        agreement_value: values.agreement_value
                    },
                    ObjectStage: {
                        stage_id: lead.ObjectStage.stage_id
                    }
                }

                api.put(`/leads/${lead.id}`, payload).then(res => {
                    /*
                    * This will show newly updated data from state loaded from handleBudgetChange()
                    */
                    resetForm({})
                    handleBudgetChange()
                } )
            }}
        >
            <Form>
               <Box pl={2}><Typography variant="h5">Budget</Typography></Box>
                <Grid container spacing={4}>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Maximum Budget" name="max_budget" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Loan Required" name="loan_required" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Loan Sanction" name="loan_sanction" /> </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Property For" name="property_for" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Required BHK" name="required_bhk" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Agreement Value" name="agreement_value" /> </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Minimum Budget" name="min_budget" /> </Box>
                        <Box mt={3}> <FormikTextField isEditable={state.isEditable} label="Interested Location" name="interested_location" /> </Box>
                    </Grid>
                </Grid>
                {/* <Box align="center" mt={6}> */}
                {/*     {state.isEditable */} 
                {/*         ? <Button type="submit" onClick={save}>Confirm</Button> */}
                {/*         :<Button onClick={enableForm}>Edit</Button> */}
                {/*     } */}
                {/* </Box> */}
            </Form>
        </Formik>
    </Box>
}


export default BudgetPage;
