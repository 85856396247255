import React from 'react';

import MenuItem_ from '@material-ui/core/MenuItem';

import './MenuItem.sass'
import clsx from 'clsx';


const defaultState = {
}


const MenuItem = props => <MenuItem_ className="MenuItem" {...props}>{props.children}</MenuItem_>

export default MenuItem;
