import React,{Component} from 'react'
import Input from '../common/controls/InputField'
import { Paper } from '@material-ui/core';
import Autocomplete from '../common/controls/Autocomplete'
import SelectOption from '../common/controls/SelectOption'
import ButtonSubmit from '../common/controls/ButtonSubmit'
import {addIvr, updateIvr} from "../../dataParser/getIvrData"
import Loader from "../../component/common/loader/loader";
import {getLeadSourceData, autoComplete} from "../../dataParser/commomDataApi";
import {national, ivrStatus, isMagnet} from '../common/datas/data';
import {Autocomplete as MuiAutocomplete} from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import PopUpALert from '../common/sweetAlert/popUpAlert';
import { objEmpty } from "../../helper";

class AddUpdateIVR extends Component{
	constructor(props){
		super(props)
		this.state={
			showLoader: false,
            alertShow: false,
            type: 'success',
            title: '',
            source: [],
			source_id: '',
            project: [],
            selectedProj: {},
			project_id: '',
			number: '',
			region_id: '',
			nationality: 1,
			status: 0,
			is_magnet: 0,
            user: [],
            selectedUserName: {},
			whatsup_user:1,
			selectedUser: [{}],
			data: this.props.fullDetail,
			id: this.props.updateId,
		}
	    this.handleChange = this.handleChange.bind(this);
	}
		
	async componentDidMount(){
		const {data} = this.state
		let resData = await getLeadSourceData();
        this.setState({source : resData.data})
        let selectedProject = []
        let selectedUser = this.state.selectedUser;
        if(data !== null && data !== undefined){
        	if(data.Project !== null && data.Project !== undefined){
        		selectedProject = [{name: data?.Project?.name, id: data?.Project?.id}]
        	}
        	else{
				selectedProject=[]
			}
        	if(data.whatsupUser !== null && data.whatsupUser !== undefined){
        		selectedUser[0]["name"] = data?.whatsupUser?.name;
				selectedUser[0]["id"] = data?.whatsupUser?.id;
        	}
        	else{
				selectedUser=[]
			}
		}
		this.setState({
    		project : selectedProject,
    		selectedProj : selectedProject[0] || {},
			number: data?.number || '',
			region_id: '',
			source_id: data?.source_id ||'',
			project_id: data?.Project?.id || '',
			status: data?.status || 0,
			is_magnet: data?.is_magnet || 0,
			nationality: data?.nationality || 1,
			user:selectedUser[0].name !== undefined ? selectedUser : [],
			selectedUserName: selectedUser[0].name !== undefined ? selectedUser[0] : {}
		})
	}
	handleAutoProjectChange = async (e) => {
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				this.setState({
					project: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}
	handleAutoUserChange = async (e) => {
		(async () => {
			try {
				let data = {
					q: e.target.value,
            		roles: [5, 6, 7, 8, 10, 13, 14, 15, 20, 21]
				};
				let resData = await autoComplete('users', data);
				this.setState({
					user: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}
	handleResponse=(res,action)=>{
		if(res.meta.status === 201){
            this.setState({
                showLoader: false,
                alertShow: true,
				type: "success",
                title: `${action} IVR successfully !!!`,
            })
        }
		else {
		    this.setState({
		        showLoader: false,
		        alertShow: true,
		        type: "error",
		        title: res.meta.message
		    });
		}
	}

	handleChange = (e) => {
		const {name, value} = e.target
		this.setState({[name]: value});
	}

	sendIvrData = async (e) => {
		this.setState({
            showLoader: true
        })
		e.preventDefault();
		// e.preventDefault();
		const {number, region_id, project_id,source_id, status, is_magnet, nationality, whatsup_user} = this.state
		let data = {}
		if (number !== "") {
			data["number"] = number;
		}
		if (region_id !== "") {
			data["region_id"] = region_id;
		}
		if (source_id !== "") {
			data["source_id"] = source_id;
		}
		if (project_id !== '') {
			data["project_id"] = project_id;
		}
		data["status"] = status ? 1 : 0;
		data["is_magnet"] = is_magnet ? 1 : 0;
		if (nationality !== "") {
			data["nationality"] = nationality;
		}
		if (whatsup_user !== "") {
			data["whatsup_user"] = whatsup_user;
		}
		let resData;
		if(!(number && source_id && project_id && whatsup_user && nationality)){
			this.setState({
                showLoader: false,
                alertShow: true,
				type: "error",
                title: `Above all fields are required !!!`,
            })
		}
		
		else if (this.state.id) { 
			resData = await updateIvr(this.state.id,data) 
			this.handleResponse(resData,'Updated')
		}
		else{
			resData = await addIvr(data)
			this.handleResponse(resData,'Added')
		}
	} 

	changeProjectData=(project)=>{
		if (project === null) {
			project = {}
		}
		this.setState({
			project_id: project.id,
			selectedProj: project,
		})
	}

    handleClose = () => {
        this.setState({ alertShow: false });
    }

	render(){
		const {number, source_id, status, is_magnet, nationality, showLoader, alertShow, type, title, user, selectedUser, selectedUserName} = this.state
		return (
	        <div>
            	<Loader show={showLoader} />
            	<Paper className="px-4 py-3" >
		            <h5>{this.state.id ? 'Update' : 'Add'} IVR</h5>
		            <form action="">
			            <div className="row mt-4">
			            	<div className="col-md-3 mb-4">
				            	<Input
				            		name="number"
				            		value={number}
				            		label="Number *"
				            		onChange={this.handleChange}
				            	/>
		            		</div>
			            	<div className="col-md-3 mb-4">
			            		<SelectOption
			            			label="Select Source *"
			            			name='source_id'
			            			options={this.state.source}
			            			onChange={this.handleChange} 
			            			value={source_id}
			            		/>
		            		</div>
			            	<div className="col-md-3 mb-4">
		            			<Autocomplete 
		            				label='Project Name *'
		            				options={this.state.project}
									value={this.state.selectedProj}
		            				onChange={this.changeProjectData}	
		            				handleAutoChange={this.handleAutoProjectChange}	            						            						            				
		            			/>
		            		</div>
			            	<div className="col-md-3 mb-4">
								<FormControl variant="outlined">
									<MuiAutocomplete
										getOptionSelected={(option, value) => option.name === value.name}
										getOptionLabel={option => objEmpty(option) === false ? option.phone !== undefined ? option.name +' ('+option.phone+')' : option.name : option?.name }
										options={user}
										value={selectedUserName}
										onChange={(_event, user) => {
											if (user === null) {
												this.setState({ user: [] })
											} else {
												this.setState({
													whatsup_user: user.id,
													selectedUserName: { name: user.name, id: user.id, phone: user.phone }
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												variant="outlined"
												label="WhatsApp User *"
												fullWidth
												onChange={this.handleAutoUserChange}
											/>
										)}
									/>
								</FormControl>
		            		</div>
			            	<div className="col-md-3 mb-4">
		            			<SelectOption
		            				options={isMagnet}
		            				label="Is Magnet *"
		            				name="is_magnet"		            				
		            				value={is_magnet}
		            				onChange={this.handleChange}
		            				/>
		            		</div>
			            	<div className="col-md-3 mb-4">
		            			<SelectOption
		            				options={national}
		            				label="Nationality *"
		            				name="nationality"		            				
		            				value={nationality}
		            				onChange={this.handleChange}
		            				/>
		            		</div>
			            	<div className="col-md-3 mb-4">
		            			<SelectOption
		            				options={ivrStatus}
		            				label="Status *"
		            				name="status"		            				
		            				value={status}
		            				onChange={this.handleChange}
		            				/>
		            		</div>
		            	</div>
		            	<div className="col-md-12">
							{alertShow && 
							<PopUpALert type={type} msg={title} open={alertShow} handleClose={this.handleClose} hideTime={6000} />
							 }
		            		<ButtonSubmit
		            			onClick={this.sendIvrData}
		            		/>
	            		</div>
		            </form>
            	</Paper>
	        </div>
	    )
	}
}

export default AddUpdateIVR
