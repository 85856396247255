import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Frag from "../../../utils/Frag/Frag.js";
import { getCityWiseProjectData } from "../../../dataParser/getDashboardData";
import AddLeadModal from "../../../component/common/modal/addLeadModal.js";
import { Card, Tabs, Tab } from 'react-bootstrap';
import Loader from "../../../component/common/loader/loader";
import ProjectPdfContainer from "../../pdfGenrete/ProjectPdfContainer";
import ProjectPdfPreview from "../../pdfGenrete/ProjectPdfPreview";
import { randomNumber }from "../../../helper/index"
import { PreviewButton } from '../controls/PreviewButton.js';
import { DigitalForm } from '../../common/form/digitalForm';
import {Button} from '@material-ui/core'
import RatioChart from '../chart/RatioChart.js';

class MagnetTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addModalShow: false,
            showLoader: false,
            data: [],
            homeData: [],
            tab: 0,
            tabName: 'mumbai',
            isFetching: false,
            downloadPdf:0,
            downloadProjectId:null,
            setPage: 0,
            isFetching: false,
            showModal:false,
            previewProjectId:false,
            projectCount:0,
            clickId:0,
            preViewClickId:0,
            digitalModalShow: false
        };
    }
    componentDidMount() {
        (async () => {
            try {
               await this.setState({ setPage: this.state.setPage + 1 });
                this.setState({ showLoader: true })
                let id = window.$user.id;
                var resData = await getCityWiseProjectData(this.state.tabName,this.state.setPage);
                resData.data.rows.map( async (item) => {
                    item.succesRate = this.focusProjectRatio(item.bookingCount,item.siteVisiteCount);
                    if(item?.ProjectMeta.length){
                        let price = Math.min(...item?.ProjectMeta.map(item=>item?.price));
                        item.price =  this.numDifferentiation(price);
                        item.ProjectMeta = await this.unique(item.ProjectMeta);
                    }else{
                        item.price = '-';
                    }

                });
                this.setState({ homeData: resData.data.rows, showLoader: false });
                
                window.scrollTo(0, 0);
                window.addEventListener('scroll', this.handleScroll);
            } catch (e) {
                //...handle the error...
            }
        })();
    }
   async componentDidUpdate(prevProps, prevState) {
        if ((prevState.isFetching !== this.state.isFetching)) {
            await this.setState({ setPage: this.state.setPage + 1 });
            if(this.state.projectCount == this.state.homeData.length){
                return false;
            }
            this. getCityWiseProject(this.state.tabName);
        }
    }

    getCityWiseProject = async (tabName) => {
        let tabPos;
        if (tabName) {
            tabPos = tabName;
            this.setState({tabName:tabPos})
        } else {
            tabPos = this.state.tabName;
        }

        var resData = await getCityWiseProjectData(tabPos,this.state.setPage);
        resData.data.rows.map(async (item) => {
            item.succesRate = this.focusProjectRatio(item.bookingCount,item.siteVisiteCount);
            if(item?.ProjectMeta.length){
                let price = Math.min(...item?.ProjectMeta.map(item=>item?.price));
                item.price =  this.numDifferentiation(price);
                item.ProjectMeta = await this.unique(item.ProjectMeta);
            }else{
                item.price = '-';
            }
        });
        
        let homeData = await this.state.homeData;
        this.setState({ homeData: [...homeData,...resData.data.rows], showMaterialLoader: true,});
        if (resData.meta.status === 200) {   
            this.setState({
                isLoading: false,
                showMaterialLoader: false,
                resSize: resData.data.length,
                cityData: resData.data,
                cityMeta: resData.meta,
                projectCount:resData.data.count
            })
        } else {
            this.setState({
                isLoading: false,
                resSize: 0,
                showMaterialLoader: false,
                cityData: resData.data,
                cityMeta: resData.meta
            })
        }
        this.setState({ isFetching: false })

    }

    handleChange = async (event) => {
        let tabName1 = event === 0 ? 'mumbai' : event === 1 ? 'bangalore' : event === 2 ? 'pune' : event === 3 ? 'pune' : 'mumbai';
        await this.setState({homeData:[],setPage:1});
        this.setState({ tab: event, tabName: tabName1 });
        this.getCityWiseProject(event);
    };

    handleModal = (id, name) => {
        this.setState({ addModalShow: !this.state.addModalShow });
        this.setState({
            proc_id: id,
            proc_name: name,
        });
    }

    handleScroll = () => {
        if (
            Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
            this.state.isFetching
        )
        return;
        this.setState({ isFetching: true })
    };

    pdfGenrete = (projectId) => {
        let clickId = Math.floor((Math.random() * 10) + 1);
        this.setState({showLoader:true,downloadPdf:1,downloadProjectId:projectId,clickId:clickId});
    }
    focusProjectRatio(bookingCount,visiteCount){
        
        if(bookingCount > 0 && visiteCount > 0 && (bookingCount === visiteCount || bookingCount > visiteCount)){
            return 100;
        }
        let projectRatio = 0;
        if(bookingCount > 0 && visiteCount > 0 ){
            projectRatio = (bookingCount / visiteCount) * 100; 
        }
        if(projectRatio < 10){
            projectRatio = randomNumber(10,16);
        }
        
        return Number(projectRatio).toFixed(0);
    }
    pdfPreview = (projectId) => {
        let clickId = Math.floor((Math.random() * 10) + 1);
        this.setState({ showLoader:true,showModal: true ,previewProjectId:projectId,preViewClickId:clickId});
    }
    handleModalClose = () => {
        this.setState({ showModal: false,clickId:0,showLoader:false});
    }
    numDifferentiation(value) {
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' Lac';
        }
        return val;
    }
    hideLoader = () => {
        this.setState({showLoader:false,preViewClickId:0});
    }
    unique = async (arr) => { 
        //To store the unique sub arrays
        let uniques = [];
        arr.map((item) => {
            if(uniques.findIndex((itemObj) => itemObj.bhk === item.bhk) === -1){
                uniques.push(item);
            }
        });
        //Return the unique list
        return uniques;
    }
    handleDigitalModal = (data, type) =>{
        this.setState({
            digitalModalShow: !this.state.digitalModalShow,
            fullDetail: data,
            digiRestType: type
        })
    }
    render() {

        const { homeData } = this.state;
        const tabContent = (
            <Frag>
                {homeData?.length ?
                    (homeData.map((projectData, index) => (
                        <div className="path-16 mb-2" key={index}>
                            <Card.Body style={{ padding: '10px 15px' }} component={Paper}>
                                <div className="row align-items-center justify-content-left card-active">
                                    <div className="col-md-3 col-6">
                                        <div className="row mt-1">
                                            <span className="col-12 mr-1 tab_text_head">Project Name</span><br />
                                            <h6 className="col-12 mb-2 tab_text_sub_head text-capitalize">{projectData?.name || "-"}</h6>
                                        </div>

                                        <div className="row">
                                            <span className="col-12 mr-1 tab_text_head">Builder Name</span><br />
                                            <h6 className="col-12 tab_text_sub_head text-capitalize">{projectData?.builder_name || "-"}</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6 left_border">
                                        <div className="row mt-1">
                                            <span className="col-12 mr-1 tab_text_head">Configuration</span><br />
                                            <h6 className="col-12 mb-2 tab_text_sub_head">{projectData.ProjectMeta.length>0 ? (projectData?.ProjectMeta.map(item=>(item?.bhk))).sort(function(a, b){return a-b}).join(', ') + ' BHK' : '-'}</h6>
                                        </div>

                                        <div className="row">
                                            <span className="col-12 mr-1 tab_text_head">Price</span><br />
                                            <h6 className="col-12 tab_text_sub_head">{projectData?.ProjectMeta.length ? projectData?.price + ' Onwards' : projectData?.price} {/* {projectData?.ProjectMeta.length === 1 ? 'Rs. ' + projectData?.ProjectMeta[0].price : Math.min(...projectData?.ProjectMeta.map(item=>item?.price)) == 'Infinity' ? '-' : 'Rs. ' + Math.min(...projectData?.ProjectMeta.map(item=>item?.price)) + ' Onwards'} */}</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6 left_border">
                                        <div className="row mt-1">
                                            <span className="col-12 mr-1 tab_text_head">Area/Region</span><br />
                                            <h6 className="col-12 mb-2 tab_text_sub_head">{projectData?.Region?.name}</h6>
                                        </div>

                                        <div className="row">
                                            <span className="col-12 mr-1 tab_text_head">Success Rate</span><br />
                                            <h6 className="col-12 tab_text_sub_head">{projectData?.succesRate} %</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6 left_border text-center">

                                        <Button variant="contained" color="primary" className="w-75 mb-2 px-0"
                                            onClick={() => this.handleModal(projectData?.id, projectData?.name)}
                                        >
                                            <span className="text-capitalize">Add Lead</span>
                                        </Button>
                                        <br />
                                            <Button variant="contained" color="primary" className="w-75 mb-2"
                                                disabled={(projectData?.is_adwords && window.$role === "magnet") ? false : true}
                                                onClick={() => this.handleDigitalModal(projectData, 'digital')}
                                            >
                                                <span className="text-capitalize">Invest Now</span>
                                            </Button>
                                            
                                        <br />
                                        { projectData?.FocusProjects && projectData?.fact_sheet &&
                                            <Frag>
                                                <PreviewButton onClick={() => this.pdfGenrete(projectData.id)} btnText="Download"/>
                                                <br/>
                                                <PreviewButton onClick={() => this.pdfPreview(projectData.id)} btnText="Factsheet"/>
                                            </Frag>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </div>
                    )))
                    :
                    <div className="path-16 mb-2">
                        <Card.Body style={{ padding: '10px 15px' }}>
                            <div className="row align-items-center justify-content-left card-active">
                                <div className='col-md-12 text-center py-5'>
                                    <div className="errorMsg ">
                                        Ooops Project Not Found !!!
                        </div>
                                </div>
                            </div>
                        </Card.Body>
                    </div>

                }
                <DigitalForm  show={this.state.digitalModalShow} changeModal={this.handleDigitalModal} fullDetail={this.state.fullDetail} handleProjectData={this.getCityWiseProject} digiRestType={this.state.digiRestType} />

            </Frag>
        );

        return (
            <Frag>
                <Loader show={this.state.showLoader} />
                <Tabs defaultActiveKey="mumbai" id="uncontrolled-tab-example" onSelect={(k) => this.handleChange(k)}>
                    <Tab eventKey="mumbai" title="Mumbai">
                        {tabContent}
                    </Tab>
                    <Tab eventKey="bangalore" title="Bengaluru">
                        {tabContent}
                    </Tab>
                    <Tab eventKey="pune" title="Pune">
                        {tabContent}
                    </Tab>
                    <Tab eventKey="ratios" title="Ratios">
                        <RatioChart />
                    </Tab>
                </Tabs>
                {/* <MagnetLeadModel show={this.state.addModalShow} changeModal={this.handleModal}  callFrom="dashboard" proc_id={this.state.proc_id} proc_name={this.state.proc_name} /> */}
                <AddLeadModal show={this.state.addModalShow} from={"addLead"} callFrom="dashboard" changeModal={this.handleModal} proc_id={this.state.proc_id} proc_name={this.state.proc_name}/>
                {(this.state.downloadPdf === 1 && this.state.downloadProjectId > 0) &&
                    <ProjectPdfContainer projectId={this.state.downloadProjectId} clickId={this.state.clickId} hideLoader={this.handleModalClose}/>
                }
                {this.state.previewProjectId ? 
                <ProjectPdfPreview 
                    showModal={this.state.showModal}
                    handleModalClose={this.handleModalClose} 
                    projectId={this.state.previewProjectId}
                    clickId={this.state.preViewClickId}
                    hideLoader={this.hideLoader}
                />: ""}
            </Frag>
        );
    }
}

export default MagnetTab;
