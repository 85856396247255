import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import TCModel from "../termsConditionModel.js";
import MagnetForm from '../../form/magnets/MagnetForm.js';

const MagnetModel = (props) => {
    const [state, setState] = useState({
        tcModalShow: false
    })

    const handleTcModal = () => {
        setState({
            ...state,
            tcModalShow: !state.tcModalShow
        })
    }
    return (
        <Modal
            show={props.show}
            onHide={props.changeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="unselectable"
            backdrop={`${props.activeTab && 'static'}`}
            >
            <Modal.Header closeButton={props.activeTab ? false : true}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="mr-md-5 mr-0">Update Details </span>
                    <span className="d-md-inline d-block">
                        <a className="h6 mr-2 cursor-pointer" onClick={handleTcModal}>Terms & Conditions</a>
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MagnetForm changeModal={props.changeModal}  activeTab={props.activeTab} hideInactiveTab={props.hideInactiveTab} />
                <TCModel show={state.tcModalShow} fromType="termsCondition" changeModal={handleTcModal} />
            </Modal.Body>
        </Modal>
    )
}

export default MagnetModel
