import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getMagnetList,
  getMagnetMoneyExchange,
  saveMagnetMoneyExchange
} from "../../dataParser/getListUserData";

import Loader from "../../component/common/loader/loader";

import { REVERT_ROLES } from "../../config/constant";
import {  MuiPickersUtilsProvider,  KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export default class AddMoney extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      isLoading: false,
      magnets: [],
      magnetMoney: [],
      selectedMagnet: {},
      fromDate:null
    };
  }

  componentDidMount() {}

  getMagnets = (searchString = null) => {
    let params = {
      role_ids: REVERT_ROLES.MAGNET
    };

    if (searchString) {
      params.q = searchString;
      this.setState({ isLoading: true }, async () => {
        let magnets = await getMagnetList(params);
        this.setState({
          magnets: magnets.data ? magnets.data : [],
          isLoading: false
        });
      });
    }
  };

  onMagnetSelect = value => {
    if (value) {
      this.setState({ showLoader: true }, async () => {
        let magnetMoney = await getMagnetMoneyExchange({
          magnet_id: value.id
        });

        this.setState({
          selectedMagnet: value,
          magnetMoney: this.formatMagnetMoneyListData(magnetMoney),
          showLoader: false
        });
      });
    } else {
      this.setState({
        selectedMagnet: {},
        magnetMoney: []
      });
    }
  };

  formatMagnetMoneyListData = magnetMoney => {
    return magnetMoney.data
      ? magnetMoney.data.map(money => {
          let date = new Date(money.date);

          return {
            date: `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()} ${String(date.getHours()).padStart(
              2,
              "0"
            )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
              date.getSeconds()
            ).padStart(2, "0")}`,
            amount: money.amount
          };
        })
      : [];
  };

  saveMoneyExchange = () => {
    if (
      this.state.selectedMagnet.name &&
      this.state.selectedMagnet.id &&
      this.state.amount
    ) {
      this.setState({ showLoader: true }, async () => {
        let date = new Date();
        let params = {
          magnet_name: this.state.selectedMagnet.name,
          magnet_id: this.state.selectedMagnet.id,
          amount: this.state.amount,
          date: this.state.fromDate
        };

        let saveResponse = await saveMagnetMoneyExchange(params);

        if (saveResponse.meta.status === 201) {
          let magnetMoney = await getMagnetMoneyExchange({
            magnet_id: this.state.selectedMagnet.id
          });

          this.setState({
            magnetMoney: this.formatMagnetMoneyListData(magnetMoney),
            showLoader: false
          });
        } else {
          this.setState({ showLoader: false });
        }
      });
    }
  };

  filterMagnets = e => {
    this.getMagnets(e.target.value);
  };

	handleDateChange = (key, date) => {
		this.setState({ [key]: date });
	}
  render() {
    return (
      <div className="p-1 p-lg-3 mt-3">
        <Loader show={this.state.showLoader} />
        <div className="row mb-3 mt-3">
          <div className="col-lg-4 col-12 text-left">
            <div className="form-group">
              <Autocomplete
                options={this.state.magnets}
                getOptionLabel={option =>
                  `${option.id} - ${option.name} (${option.phone})`
                }
                onChange={(e, value) => this.onMagnetSelect(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search Magnet"
                    variant="outlined"
                    onChange={e => this.filterMagnets(e)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-2 col-12 text-left">
            <div className="form-group">
              <TextField
                // style={{ width: "20rem" }}
                label="Amount"
                type="number"
                variant="outlined"
                value={this.state.amount}
                onChange={e => this.setState({ amount: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                label="Select Date"
                variant="inline"
                format="yyyy-MM-dd"
                /* minDate={
                  this.lessThanToday(`${this.state.toDate} 23:59:59`)
                    ? null
                    : this.state.editAd
                      ? new Date()
                      : this.state.fromDate
                } */
                value={this.state.fromDate || null}
                autoOk={true}
                onChange={(key, date) => this.handleDateChange("fromDate", date)}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                // disabled={this.lessThanToday(`${this.state.toDate} 23:59:59`)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-12 text-left col-12">
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.saveMoneyExchange}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-12 text-left">
            {this.state.magnetMoney.length ? (
              <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="tableCustom"
                  style={{ minWidth: "650px" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {Object.keys(this.state.magnetMoney[0]).map(
                        (values, index) => (
                          <StyledTableCell key={index} align="left">
                            {values.replace(/(^|_)./g, s =>
                              s.slice(-1).toUpperCase()
                            )}
                          </StyledTableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.magnetMoney.map((money, index) => (
                      <TableRow key={index}>
                        {Object.values(money).map((values, key) => (
                          <StyledTableCell key={key} align="left">
                            {values}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
