import {getBasic,get} from "../utils/methods.js";

export const getDashboardData = async () =>{

	let getDashboard = await getBasic('/dashboard')
	return  getDashboard;
}

export const getDigitalSpendData = async (id) =>{
	let getSpend;
	if(!!id){
		getSpend = await getBasic('/dashboard/digital-spend?magnet_id='+id)
	}
	else{
		getSpend = await getBasic('/dashboard/digital-spend')
	}
	return  getSpend;
}

export const getBusinessData = async (value) =>{
	// console.log(value,"dfghjkkkkkkkkkk")
	let getBusinessData = await get('/dashboard/business/'+value.id,value.data);
	return  getBusinessData;
}

export const getBookingDate = async (value) =>{
	// console.log(value,"dfghjkkkkkkkkkk")
	let getBookingDate = await get('/dashboard/financial_year_business/'+value);
	return  getBookingDate;
}

export const getActivityData = async (value) =>{

	let getActivityData = await get('/dashboard/activities/'+value.id,value.data)
	return  getActivityData;
}

export const getUpdateData = async (value) =>{

	let getUpdateData = await get('/dashboard/current_updates/'+value.id,value.data)
	return  getUpdateData;
}

export const getOpenLeadData = async (value) =>{

	let getUpdateData = await getBasic('/dashboard/open_leads/'+value)
	return  getUpdateData;
}

export const getOpenLeadActivityData = async (value) =>{

	let getUpdateData = await getBasic('/dashboard/open_lead_activities/'+value)
	return  getUpdateData;
}

export const getLeadRatioData = async (value) =>{

	let getUpdateData = await getBasic('/dashboard/ratios/'+value)
	return  getUpdateData;
}

export const getCityWiseProjectData = async (value,page_id) =>{

	let getUpdateData = await getBasic(`/projects/city_wise/${value}?page_id=${page_id}`)
	return  getUpdateData;
}

export const getProjectWiseData = async (projectId) =>{

	let getUpdateData = await getBasic('/projects/'+projectId)
	return  getUpdateData;
}


export const getFutureActivityData = async (id) =>{
	let getData = await get('/dashboard/future-activity/'+id)
	return  getData;
}

export const getRatiosData = async (id,data={}) =>{
	let getData = await get('/dashboard/newratios/'+id,data)
	return  getData;
}


export const getLeaderboardData = async (data) =>{
	let getData = await get('/leaderboard',data);
	return  getData;
}