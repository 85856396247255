import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Frag from "../../../utils/Frag/Frag.js";
import AddLeadForm from "../form/addLeadForm.js";
import AddFbAdLead from "../form/addFbAdLead";
import RadioGroup from "../controls/RadioGroup";
import MagnetPlanCards from "../../Leads/magnetPlanCards.js";

import { getMagnetPlans } from "../../../dataParser/commomDataApi";

import constants from "../../../config/constant/index.js";
import { digitalGroup } from "../../../config/constant/crmRoleFunctionPermission.js";

const mb = {
  marginBottom: "-10px"
};

class AddLeadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "google",
      userPlan: {},
      magnetSelectedPlan: null,
      magnetPlans: {}
    };
  }

  componentDidMount() {
    this.getMagnetPlans();
  }

  getMagnetPlans = async () => {
    let plans = await getMagnetPlans();
    let magnetPlans = [];
    plans.data.forEach(o => {
      magnetPlans[o.plan_name.toLowerCase()] = {
        key: o.plan_name.toLowerCase(),
        name: o.plan_name,
        percentage: o.percentage
      };
    });

    this.setState({ magnetPlans });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  selectPlan = plan => {
    this.setState({
      magnetSelectedPlan: plan
    });
  };

  onCloseModal = args => {
    this.setState({ magnetSelectedPlan: null }, () =>
      this.props.changeModal(args)
    );
  };

  render() {
    const { status } = this.state;
    const roles = [
      "admin",
      ...digitalGroup
    ];
    const is_status = [
      { id: "google", name: "Google Adwords split" },
      { id: "campaign", name: "Facebook" },
      { id: "manual", name: "Manual" }
    ];

    return (
      <Frag>
        <Modal
          show={this.props.show}
          onHide={this.onCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="unselectable"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={mb}>
              <p style={mb}>Add Lead</p>
              {roles.includes(window.$role) ? (
                !this.state.magnetSelectedPlan &&
                (constants.MAGNET_PLAN_ROLES.includes(window.$role)) 
                ? null : (
                  <RadioGroup
                    name="status"
                    value={status}
                    onChange={this.handleChange}
                    items={is_status}
                  />
                )
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.magnetSelectedPlan &&
              (constants.MAGNET_PLAN_ROLES.includes(window.$role))
              ? (
              <MagnetPlanCards
                minified={false}
                selectPlan={this.selectPlan}
                magnetPlans={this.state.magnetPlans || {}}
              />
            ) : status === "manual" || !roles.includes(window.$role) ? (
              <AddLeadForm
                changeModal={this.props.changeModal}
                getList={this.props.getList}
                from={this.props.from}
                leadId={this.props.leadId}
                magnetSelectedPlan={this.state.magnetSelectedPlan || {}}
                availablePlans={this.state.magnetPlans || {}}
                proc_id={this.props?.proc_id} proc_name={this.props?.proc_name}
              />
            ) : (
              <AddFbAdLead
                changeModal={this.props.changeModal}
                status={status}
                getList={this.props.getList}
              />
            )}
          </Modal.Body>
        </Modal>
      </Frag>
    );
  }
}

export default AddLeadModal;
