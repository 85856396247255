import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import CopyText from '../action/CopyText.js';
import UpdateCpUserInfo from "../form/updateCpUserInfo";
import TCModel from "../../common/modal/termsConditionModel.js";

class CpUserModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tcModalShow: false
		}
	}

	handleTcModal = () => {
		this.setState({
			tcModalShow: !this.state.tcModalShow
		})
	}

	render() {
		let vCardUrl = null;
		if(this.props.user){
			const { first_name, id } = this.props.user;
			vCardUrl = `https://www.homesfy.in/vcard/?me=${first_name}&user=${id}`
		}
		
		return (
			<Frag>
				<Modal
					show={this.props.show}
					onHide={this.props.changeModal}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="unselectable"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							<span className="mr-md-5 mr-0">Update Details </span>
							<span className="d-md-inline d-block">
							{(window.$user.role !== 'magnet' && vCardUrl ) ?
							<>
								<a className="h6 mr-2" href={vCardUrl} target="_blank">(Copy My VCard)</a>
								<CopyText text={vCardUrl} />
							</>
								:
								<a className="h6 mr-2 cursor-pointer" onClick={this.handleTcModal}>Terms & Conditions</a>

							}
								</span>

						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<UpdateCpUserInfo profileUpdate={this.props.profileUpdate} changeModal={this.props.changeModal} getCpList={this.props.getCpList} CpUpdateDetail={this.props.CpUpdateDetail} />
						<TCModel  show={this.state.tcModalShow} fromType="termsCondition" changeModal={this.handleTcModal}   />
					</Modal.Body>

				</Modal>
			</Frag>
		);
	};
};

export default CpUserModal;