import React, { Component } from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getAutoCompleteProject } from "../../dataParser/getProjectData";
import {  autoComplete,roleWiseAutoComplete } from "../../dataParser/commomDataApi";
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { CLOSING_REASON, REVERT_ROLES } from "../../config/constant/index.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { cleanStorage } from "../../helper";

class bulkLeadFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			option: [],
			project_name: '',
			project_ids: '',
			closing_reason: '',
			is_moved: [],
			userName: [],
			selectedValLeadRM: [],
			uploaded_by: '',
			magnetRm:[],
			selectedMagnetRM: [],
			magnet_ids: [],
		};
	}

	handleAutoChange = async (e) => {
		this.setState({ isLoading: true });
		let value = e.target.value

		var projectListData = await getAutoCompleteProject(value);

		if (projectListData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData.data })
		} else {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData })
		}

	}

	handleAutoMagnetChange = async (e) =>{
        let data = {
			q:e.target.value,
			roles:[REVERT_ROLES.MAGNET]
		};
        let resData = await autoComplete('users',data);

        if(resData.meta.status === 200){
            this.setState({magnetRm : resData.data})
        }

    }

	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {};
		let resData = {};

		if (this.state.group === 'magnet') {
			data.q = e.target.value;
			data.roles = [REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.MAGNET_TL]
			resData = await autoComplete('users', data);
		} else {
			data.q = e.target.value;
			resData = await roleWiseAutoComplete(data);
		}

		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: [] })
		}
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	applyFilter = () => {

		let filterData = {};

		if (this.state.project_ids.length > 0) {

			filterData["project_ids"] = this.state.project_ids;
		}

		if (this.state.is_moved.length > 0) {

			filterData["is_moved"] = this.state.is_moved;
		}

		if (this.state.closing_reason != '') {

			filterData["closing_reason"] = this.state.closing_reason;
		}

		if (this.state.uploaded_by != '') {

			filterData["uploaded_by"] = this.state.uploaded_by;
		}
		if (this.state.magnet_ids.length > 0) {

			filterData["magnet_ids"] = this.state.magnet_ids;
		}

		this.props.changeFilter();
		this.props.filterData(filterData);

	}

	handleMultiSelect = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	resetFilter = () => {

		this.setState({
			autoKey: !this.state.autoKey,
			option: [],
			project_name: '',
			project_ids: '',
			closing_reason: '',
			is_moved: [],
			userName: [],
			selectedValLeadRM: [],
			uploaded_by: '',
			magnetRm:[],
			selectedMagnetRM: [],
			magnet_ids: [],
		})
	}

	render() {

		const { option, autoKey, isLoading, is_moved, selectedValLeadRM, userName,magnetRm,selectedMagnetRM } = this.state;

		const ITEM_HEIGHT = 48;
		const ITEM_PADDING_TOP = 8;
		const MenuProps = {
			PaperProps: {
				style: {
					maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
					width: 250,
				},
			},
		};

		const status = {
			0: "Disqualify",
			1: "Activated",
		};

		return (

			<Frag>
				<div className="container filter_lead filterMenuCard pt-3 p-0">
					<div className="row filterRow">
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<Autocomplete
									multiple
									key={autoKey}
									id="projectData"
									getOptionSelected={(option, value) => option.name === value.name}
									getOptionLabel={option => option.name}
									options={option}
									loading={isLoading}
									renderTags={(value, getTagProps) =>
										value.map((option, index) => (
											<Chip label={option.name} {...getTagProps({ index })} />
										))
									}
									onChange={(_event, option) => {
										var projectId = option.map((ops) => ops.id);
										this.setState({
											project_ids: projectId,
										})

										if (option === null) {
											this.setState({ option: [] })
										}
									}}
									renderInput={params => (
										<TextField
											{...params}
											label="Project Name"
											fullWidth
											onChange={this.handleAutoChange}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{isLoading ? (
															<CircularProgress color="inherit" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</div>
						</div>
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<FormControl>
									<InputLabel id="demo-controlled-open-select-label">Close Reason</InputLabel>
									<Select
										labelId="demo-controlled-open-select-label"
										value={this.state.closing_reason}
										onChange={this.handleChange}
										inputProps={{
											name: 'closing_reason',
											id: 'closing_reason',
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{CLOSING_REASON.map((item, i)=>(
											<MenuItem key={i} value={item}>{item}</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
								<InputLabel id="secondary-business">Status</InputLabel>
								<Select
									labelId="operate-business"
									id="operate-checkbox"
									multiple
									name="is_moved"
									value={is_moved}
									onChange={this.handleMultiSelect}
									input={<Input id="select-multiple-chip" />}
									renderValue={selected => (
										<div>
											{selected.map(value => (
												<Chip key={value} label={status[value]} />
											))}
										</div>
									)}
									MenuProps={MenuProps}
								>
									{Object.keys(status).map(name => (
										<MenuItem key={name} value={name}>
											<Checkbox checked={is_moved.indexOf(name) > -1} />
											<ListItemText primary={status[name]} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<Autocomplete
								multiple
								key={autoKey}
								id="uploadRmName"
								getOptionSelected={(userName, value) => userName.name === value.name}
								getOptionLabel={userName => userName.name + " (" + userName.phone + ")"}
								options={userName}
								value={selectedValLeadRM}
								renderTags={(value, getTagProps) =>
									value.map((userName, index) => (
										<Chip label={userName.name} {...getTagProps({ index })} />
									))
								}
								onChange={(_event, userName) => {
									var uploadRmId = userName.map((ops) => ops.id);
									this.setState({
										uploaded_by: uploadRmId,
										selectedValLeadRM: userName
									})

									if (option === null) {
										this.setState({ userName: [] })
									}
								}}
								renderInput={params => (
									<TextField
										{...params}
										label="Uploaded By"
										fullWidth
										onChange={this.handleAutoUserChange}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<Autocomplete
								multiple
								key={autoKey}
								id="MagnetRmName"
								getOptionSelected={(magnetRm, value) => magnetRm.name === value.name}
								getOptionLabel={magnetRm => magnetRm.name + " (" + magnetRm.phone + ")"}
								options={magnetRm}
								value={selectedMagnetRM}
								renderTags={(value, getTagProps) =>
									value.map((magnetRm, index) => (
										<Chip label={magnetRm.name} {...getTagProps({ index })} />
									))
								}
								onChange={(_event, magnetRm) => {
									var magnetIds = magnetRm.map((ops) => ops.id);
									console.log(magnetIds,"id....")
									this.setState({
										magnet_ids: magnetIds,
										selectedMagnetRM: magnetRm
									})

									if (option === null) {
										this.setState({ magnetRm: [] })
									}
								}}
								renderInput={params => (
									<TextField
										{...params}
										label="Magnet Name"
										fullWidth
										onChange={this.handleAutoMagnetChange}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</div>	
					</div>
					<div className="col-md-12 bgCommon">
						<div className="row">
							<div className="col-sm-6 text-right col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
								</div>
							</div>
							<div className="col-sm-6 col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Frag>
		);
	};
};

export default withRouter(bulkLeadFilter)
