import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./magnetList.css";
import Frag from "../../utils/Frag/Frag.js";
import { changeDate, objEmpty } from "../../helper";
import Pagination from "react-js-pagination";
import Loader from "../../component/common/loader/loader";
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from "../../component/footer/footer";
import Tooltip from '@material-ui/core/Tooltip';
import { genericSearchResult, genericSearchResultCount } from "../../dataParser/commomDataApi";
import LinkSharpIcon from '@material-ui/icons/LinkSharp';
import { STAGE_WISE_PAGE } from "../../config/constant/index.js";
import { OneSixthField } from "../common/listing/ListingView";
import { fieldHiddenSales } from "../../config/constant/crmRoleFunctionPermission";

class LeadList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meta: {},
            data: {},
            leadCount: 0,
            activePage: 1,
            modalShow: false,
            modalLeadShow: false,
            showOpenLeadLoader: false,
            modalLeadInternalShow: false,
            sweetWarnShow: false,
            auditModalShow: false,
            showLoader: false,
            showFilter: false,
            isLoading: false,
            isDesktop: 0,
            sweetShow: false,
            client_id: '',
            type: "success",
            title: "",
            role: window.$user.role,
            loggeduser: window.$user,
            group: window.$user.group.toLowerCase(),
            allowedroles: ['admin',
                "saleshead",
                "pnlhead",
                "salesmanager",
                "salestl",
                "seniorsalestl",
                "presalehead",
                "presalestl",
                "magnetadmin",
                "magnetpnl",
                "magnetrm",
                "magnet"
            ]

        };
        window.addEventListener("resize", this.update);
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth, listData: this.props.listData, client_id: this.props.match.params.client_id });
        if (this.props.match.params.client_id !== '') {
            let listData = {};
            listData.size = 24;
            listData.pageId = 1;
            listData.client_id = this.props.match.params.client_id;

            (async () => {
                try {
                    let resData = await genericSearchResult(listData);
                    if (resData.meta.status === 200) {
                        await this.setState({
                            meta: resData.meta,
                            data: resData.data.leads,
                            showLoader: false
                        })
                        window.scrollTo(0, 0);
                    } else {
                        this.setState({
                            meta: resData.meta,
                            data: resData.data,
                            showLoader: false
                        })
                    }
                    let leadCount = await genericSearchResultCount(listData);
                    if (leadCount.meta.status === 200) {
                        await this.setState({
                            leadCount: leadCount.data,
                            showOpenLeadLoader: false
                        })
                        window.scrollTo(0, 0);
                    } else {
                        this.setState({
                            leadCount: 0,
                            showOpenLeadLoader: false
                        })
                    }

                } catch (e) {
                    //...handle the error...
                }
                this.setState({ showOpenLeadLoader: false });
            })();
        }
    }

    handleChangePage = async (pageNumber) => {
        this.setState({
            showLoader: true,
            activePage: pageNumber
        })
        var listData = {};
        listData.size = 24;
        listData.pageId = pageNumber;
        listData.client_id = this.state.client_id;
        this.genericGetListData(listData);
    }

    handleRedirect = async (leadData) => {
        let fltr = {}
        fltr.filter = {};
        fltr.filter.ids = [leadData.id];
        fltr.filter.redirect_from = 'genericSearchResult';
        let redirectPage = STAGE_WISE_PAGE[leadData.ObjectStage.stage_id]
        this.props.history.push(
            {
                pathname: '/leads/' + redirectPage,
                state: { fltr }
            }
        )
    }

    genericGetListData = async (params) => {
        await this.setState({ showLoader: true, showOpenLeadLoader: true });
        var leadDataType = {};
        leadDataType.data = {};

        (async () => {
            try {
                let resData = await genericSearchResult(params);
                if (resData.meta.status === 200) {
                    // this.setDynamicSwitchState(resData.data['leads']);

                    await this.setState({
                        meta: resData.meta,
                        data: resData.data['leads'],
                        // listDetail: -1,
                        showLoader: false
                    })
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false
                    })
                }
                let leadCount = await genericSearchResultCount(params);
                if (leadCount.meta.status === 200) {
                    await this.setState({
                        leadCount: leadCount.data,
                        showOpenLeadLoader: false
                    })
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        leadCount: 0,
                        showOpenLeadLoader: false
                    })
                }

            } catch (e) {
                //...handle the error...
            }
            this.setState({ showOpenLeadLoader: false });
        })();
    }
    getNumber = (data) => {
        let number = (data.Client !== null && data.Client?.country_code !== null && data.Client?.country_code !== '' ? (data.Client?.country_code.includes('+') ? data.Client?.country_code : '+' + data.Client?.country_code) : '+91') + ' ' + (data.Client !== null && data.Client?.number)
        return number
    }

    render() {
        const {role} = this.state;
        const { size, status } = this.state.meta;
        let count = this.state.leadCount !== 0 ? this.state.leadCount : this.state.meta;
        const isDesktop = this.state.screenWidth;
        return (
            <Frag>
                    <div className="content-wrapper">
                        <Loader show={this.state.showLoader} />
                        <div className="p-3 pt-4 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total Leads : </span>
                                {this.state.showOpenLeadLoader ?
                                    (<CircularProgress color="secondary" size={15} />
                                    ) :
                                    <span className="">{status === 400 ? "0" : objEmpty(count) === false ? count.count : '0'}</span>
                                }
                            </div>
                        </div>
                        {this.state.data.length ?
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {this.state.data.map((listVal, index) =>
                                            <div className="MainList" key={listVal.id}>
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1 pt-2">
                                                            <OneSixthField title="ID" value={listVal.id !== null ? listVal.id : '-'} />
                                                            <OneSixthField title="Project Name" value={listVal?.Project?.name || "-"} />
                                                            {!fieldHiddenSales.includes(role) &&
                                                            <OneSixthField title="Source" value={listVal?.LeadSource?.name || "-"} />
                                                            }
                                                            <OneSixthField title="Region" value={listVal.Project?.Region?.name || '-'} />
                                                            <OneSixthField title="Status" value={listVal.ObjectStage?.Stage?.name || "-"} />
                                                            <OneSixthField title="RM Name" value={listVal.AssignLead?.Assignee?.name || "-"} />
                                                            <OneSixthField title="Client Name" value={listVal.Client?.name || "-"} />
                                                            {this.state.role !== "leadoperations" && <OneSixthField title="Number" value={this.getNumber(listVal)} /> }
                                                            <OneSixthField title="Created At" value={changeDate(listVal.AssignLead?.created_at) || "-"} />
                                                        </div>
                                                        {/* ((this.state.allowedroles.includes(this.state.role) && listVal.ObjectStage?.Stage?.name === "Closed") || (this.state.role === "admin") || (listVal.AssignLead !== null && listVal.AssignLead.Assignee !== null && listVal.AssignLead.Assignee.id === this.state.loggeduser.id)) && */}
                                                        {this.state.role !== "leadoperations" && <div className="action_box">
                                                                <Frag>
                                                                    <Tooltip title="Redirect">
                                                                        <a onClick={(value) => this.handleRedirect(listVal)} className="revertLead Action" >
                                                                            <LinkSharpIcon className="text-white" />
                                                                        </a>
                                                                    </Tooltip>
                                                                </Frag> 
                                                            </div> }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count?.count > 24 ?
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count?.count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="main_area_display shadow card p-3">
                                <h5>No Leads Found !!!</h5>
                            </div>
                        }
                    </div>
                    
                <Footer expand={this.props.expand} />


            </Frag>
        );
    };
};

export default withRouter(LeadList);
