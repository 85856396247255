import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import { engageInstance as ENAPI } from './utils/api';
import { ONESIGNAL } from './config/constant';

ReactDOM.render(<App />, document.getElementById("root"));
var homesfy_token = null;

window.oneSignalPlayerId = null;
var url = window.location.href
var arr = url.split("/");
const domain = arr[2];
const ENV = domain.includes('dev') ? 'staging' : 'production';
const credentials = ONESIGNAL[ENV];
console.log('ENV', ENV);
console.log('credentials', credentials);
// window.$isLocalEmpty = true;
if (localStorage.getItem("homesfy_lg")) {
    homesfy_token = window.atob(localStorage.getItem("homesfy_lg"));
}
console.log('homesfy_token', homesfy_token);
// As of now notification enabled for staging only for testing
    if (homesfy_token !== null) {
        const OneSignal = window.OneSignal || [];
        OneSignal.push(() => {
            OneSignal.init({
                appId: credentials,
                allowLocalhostAsSecureOrigin: true,
                autoRegister: true,
                notifyButton: {
                    enable: false,
                },
            });

            if (!window.oneSignalPlayerId) {
                console.log('oneSignalPlayerId')
                OneSignal.getUserId((pid) => {
                    console.log('pid', pid)
                    if (pid){
                        ENAPI.post('/notificationSubscriptions', {
                            player_id: pid,
                            user_id: window.$user.id,
                        })
                            .then(() => window.oneSignalPlayerId = pid);
                    }
                });
            }
        });
    }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
