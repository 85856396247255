import React from 'react';
import Box from '../../core/Box';
import Grid from '../../core/Grid';
import TextField, {FormikTextField} from '../../core/TextField';
import Typography from '../../core/Typography';
import { Formik, Form } from 'formik';
import api from '../../api';
import { fieldHiddenSales } from '../../config/constant/crmRoleFunctionPermission';


const defaultState = {
}

const DetailsPage = ({profile, lead, handleDetailsChange}) => {
    const [state, setState] = React.useState({...defaultState, lead:lead});
    const enableForm = (e) =>{
        e.preventDefault();
        setState({...state, isEditable: true})
    }

    React.useEffect(() => {
        setState({...state, lead, isEditable: false})
    }, [lead])

    const save = () =>{
    }
    const role = window.$user?.role;
    return <Box className="DetailsPage">
        <Formik enableReinitialize initialValues={{
            project_name: state.lead.Project,
            region: state.lead.Project?.Region?.name,
            alternate_project: state.lead.AlternateProject,
            assign_date: state.lead?.AssignLead?.created_at,
            coming_date: state.lead?.created_at,
            assign_msg: state.lead.admin_message,
            pre_sales_lead: state.lead.is_presale ? "Yes" : "No",
            updated_date: state.lead?.updated_at,
            follow_up_date: state.lead?.InternalStatusHistory?.followup_date,
            lead_activity_status: state.lead.InternalStatusHistory?.LeadInternalStatus?.name,
            revert_status: state.lead?.revert_status === true ?  'Yes': 'No',
            added_by: state.lead?.LeadCreator?.name,
            presale_rm_id: state.lead?.PresaleRm?.name,
            cross_sale_lead: state.lead.is_crosssale ? 'Yes' : 'No',
            assigned_by: state.lead?.AssignLead?.Assigneer?.name,
            lead_state: state.lead?.ObjectStage?.Stage?.name,
            closing_reason: state.lead.closing_reason,
            // rm: state.rm, // state.presale_rm_id
            lead_owner: state.lead?.LeadOwner?.name,
            source: state.lead?.LeadSource?.name,
            // cross_sale_rm: state.cross_sale_rm,
            visited_project:state.lead?.InternalStatusHistory?.OtherProject?.name,
            reactivation_leads:state.lead.AssignLead.type == "reactivation" ? "Yes" : "No"
        }} 
            validate= { (values) => {
            }}
            onSubmit= {(values, {resetForm}) => {
                const payload = {
                    // project name
                    project_id: values.project_name.id,
                    // alternate project
                    alternate_project_id: values.alternate_project.id
                    , ObjectStage: {
                        "stage_id": lead.ObjectStage.stage_id
                    },
                    Client:{
                        name: state.lead.Client.name,
                        id: state.lead.Client.id,
                    },
                    //
                };

                api.put(`/leads/${lead.id}`, payload).then(res => {
                    resetForm({})
                    handleDetailsChange() 
                })
            }}
        >
            <Form>
               <Box pl={2}><Typography variant="h5">Details</Typography></Box>
                <Grid container spacing={4}>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}>
                            <FormikTextField
                                isEditable={state.isEditable && state.lead.source_id == 13}
                                isAutocomplete
                                optionPresenter={ option => option.name }
                                callBackUrl={query => `projects/auto_complete/?q=${query}`}
                                autoCompleteText={option => option?.name}
                                label="Project Name"
                                name="project_name" /> 
                        </Box>
                        <Box mt={3}> <FormikTextField label="Region" name="region" /> </Box>
                        <Box mt={3}>
                            <FormikTextField
                                isEditable={state.isEditable}
                                isAutocomplete
                                optionPresenter={ option => option.name }
                                callBackUrl={query => `projects/auto_complete/?q=${query}`}
                                autoCompleteText={option => option?.name}
                                label="Alternate Project"
                                name="alternate_project" /> 
                           </Box>
                        <Box mt={3}> <FormikTextField label="Assign Date" name="assign_date" isDateTime /> </Box>
                        <Box mt={3}> <FormikTextField label="Coming Date" name="coming_date" isDateTime /> </Box>
                        <Box mt={3}> <FormikTextField label="Assign Msg" name="assign_msg" /> </Box>
                        <Box mt={3}> <FormikTextField label="Pre Sales Lead" name="pre_sales_lead" /> </Box>
						{!fieldHiddenSales.includes(role) &&<Box mt={3}> <TextField label="Is Magnet" name="magnet_lead" value={Boolean(state.lead?.is_magnet) ? "Yes" : "No" } /> </Box>}
                        <Box mt={3} mb={5}> <FormikTextField label="Visited project" name="visited_project" value={state.lead?.InternalStatusHistory?.OtherProject?.name} /> </Box>

                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box mt={3}> <FormikTextField label="Updated Date" name="updated_date" isDateTime /> </Box>
                        <Box mt={3}> <FormikTextField label="Follow Up Date" name="follow_up_date" isDateTime /> </Box>
                        <Box mt={3}> <FormikTextField label="Lead Activity Status" name="lead_activity_status" /> </Box>
                        <Box mt={3}> <FormikTextField label="Revert Status" name="revert_status" /> </Box>
                        {/* <Box mt={3}> <FormikTextField label="Added By" name="added_by" /> </Box> */}
                        <Box mt={3}> <FormikTextField label="Pre Sales RM" name="presale_rm_id" /> </Box>
                        <Box mt={3}> <FormikTextField label="Cross Sale Lead" name="cross_sale_lead" /> </Box>
						{!fieldHiddenSales.includes(role) &&<Box mt={3}> <TextField label="Magnet" name="magnet" value={state.lead?.LeadMagnet?.name} /> </Box>}
                        <Box mt={3} mb={5}> <FormikTextField label="Reactivation Lead" name="reactivation_leads" value={state.lead.AssignLead.type=="reactivation" ? "Yes" : "No"} /> </Box>

                    </Grid>
                    <Grid item sm={4} xs={12}>
                        {!fieldHiddenSales.includes(role) &&<Box mt={3}> <FormikTextField label="Assigned By" name="assigned_by" /> </Box>}
                        <Box mt={3}> <FormikTextField label="Lead State" name="lead_state" /> </Box>
                        <Box mt={3}> <FormikTextField label="Closing Reason" name="closing_reason" /> </Box>
                        <Box mt={3}> <FormikTextField label="RM" name="rm" /> </Box>
                        <Box mt={3}> <FormikTextField label="Lead Owner" name="lead_owner" /> </Box>
                        {!fieldHiddenSales.includes(role) &&<Box mt={3}> <FormikTextField label="Source" name="source" /> </Box>}
                        <Box mt={3}> <FormikTextField label="Cross Sale RM" name="cross_sale_rm" value={state.lead.is_crosssale ? 'Yes' : 'No'}/> </Box>
                        {!fieldHiddenSales.includes(role) &&<Box mt={3}> <TextField label="Added By" name="added_by" value={state.lead?.LeadCreator?.name} /> </Box>}
                    </Grid>
                  
                </Grid>
            </Form>
        </Formik>
    </Box>
}


export default DetailsPage;
