// Updated way to make use of custom theme 
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import themeConfig from './../config/theme.js';
import {UserPortContext} from './../core/Providers';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const theme = createTheme(themeConfig);
// End of imports from Update way....



const Breakpoints = ({children}) => {
    let breakpoints = ['', 'xs', 'sm', 'md', 'lg', 'xl']
    let _match = '';
    if(useMediaQuery(theme.breakpoints.down(''))){
        _match = ''
    }
    if(useMediaQuery(theme.breakpoints.down('xl'))){
        _match = 'xl'
    }
    if(useMediaQuery(theme.breakpoints.down('lg'))){
        _match = 'lg'
    }
    if(useMediaQuery(theme.breakpoints.down('md'))){
        _match = 'md'
    }
    if(useMediaQuery(theme.breakpoints.down('sm'))){
        _match = 'sm'
    }
    if(useMediaQuery(theme.breakpoints.down('xs'))){
        _match = 'xs'
    }

    // _match = breakpoints.reduce((a, c) => {
    //     if(useMediaQuery(theme.breakpoints.down(c))) {
    //         if(a) {
    //             return a;
    //         }
    //         return c
    //     }else{
    //         return a
    //     }
    // } ) 
    if(window.location.hash.includes("#/users")){
        return children;
    }
    return (<UserPortContext.Provider value={_match}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </UserPortContext.Provider>)

}

export default Breakpoints;
