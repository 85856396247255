import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { APP_BASE_URL } from "../../config/serverKey.js";
import DashboardBody from "../../component/dashboard-body/dashboard_body.js";
import { Helmet } from "react-helmet";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // document.addEventListener('mousedown', this.handleClickOutside);
    // let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
    // console.log(homesfy_lg);
    // if(homesfy_lg && homesfy_lg !== ""){
    //     // console.log("===========Local in the sidebar ==========")
    //     let user = JSON.parse(homesfy_lg);
    //     if(user.role == "magnet" && user.is_approved === false){
    //     	window.location = APP_BASE_URL + "/register-update";
    //     }else if(user.role == "caller"){
    //     	window.location = APP_BASE_URL + "/bulk-leads";
    //     }
    // }
  }

  render() {
    // console.log("sidebarrrrrr");
    // console.log(this.state.mobileSideBar);

    return (
      <div>
        {/* <Helmet>
          <title>Magnet CRM</title>
          <meta
            name="description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta name="theme-color" content="#000000" />
          <meta property="og:url" content={APP_BASE_URL} />
          <meta property="og:title" content="Magnet CRM" />
          <meta
            property="og:description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta property="og:site_name" content="MagnetRefer" />
          <meta
            property="og:image"
            content="https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/MobReferBannerMagnet.svg"
          />
        </Helmet> */}
        <DashboardBody expand={this.props.sideBar} />
      </div>
    );
  }
}

export default Dashboard;
