import "../pdfGenrete/ProjectPdf.css";
import { Container } from "@material-ui/core";
import { Card, CardContent, Grid } from "@material-ui/core";
import * as React from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { getProjectWiseData } from "../../dataParser/getDashboardData";
import { AMAZON_URL } from "../../config/serverKey";

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1
  },
  BrandLogo: {
    width: 150
  }
}));

class ProjectPdfPreview extends React.Component {
  pdfExportComponent;
  constructor(props) {
    super(props);
    this.state = {
      projectData: {}
    };
  }
  componentDidMount() {
    (async () => {
      try {
        let projectRes = await getProjectWiseData(this.props.projectId);
        if (projectRes && projectRes.meta.status === 200 && projectRes.data) {
          projectRes.data.otherFields = projectRes.data.others
            ? JSON.parse(projectRes.data.others)
            : [];
          let gallaryImges = [];
          projectRes.data.ProjectImages.map(async item => {
            if (item.type === "baner") {
              projectRes.data.banerImages = item.fileUrl;
            } else if (item.type === "highlight") {
              projectRes.data.highlightsImages = item.fileUrl;
            } else if (item.type === "gallary") {
              gallaryImges.push(item.fileUrl);
            }
          });
          projectRes.data.gallaryImges = gallaryImges;
          var lowest = Number.POSITIVE_INFINITY;
          var highest = Number.NEGATIVE_INFINITY;
          var tmp;
          for (var i = projectRes.data.ProjectMeta.length - 1; i >= 0; i--) {
            tmp = projectRes.data.ProjectMeta[i].price;
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
          }
          projectRes.data.startingPrice = this.numDifferentiation(lowest);
          projectRes.data.landPrice = this.numDifferentiation(highest);
        }
        await this.setState({ projectData: projectRes ? projectRes.data : {} });
        if (this.props.hideLoader) {
          this.props.hideLoader();
        }
      } catch (e) {
        // if(this.props.hideLoader){this.props.hideLoader();}
        console.log("error >>>>", e);
        //...handle the error...
      }
    })();
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.projectId !== this.props.projectId ||
      (this.props.clickId && prevProps.clickId != this.props.clickId)
    ) {
      let projectRes = await getProjectWiseData(this.props.projectId);

      if (projectRes && projectRes.meta.status === 200 && projectRes.data) {
        projectRes.data.otherFields = projectRes.data.others
          ? JSON.parse(projectRes.data.others)
          : [];
        let gallaryImges = [];
        projectRes.data.ProjectImages.map(item => {
          if (item.type === "baner") {
            projectRes.data.banerImages = item.fileUrl;
          } else if (item.type === "highlight") {
            projectRes.data.highlightsImages = item.fileUrl;
          } else if (item.type === "gallary") {
            gallaryImges.push(item.fileUrl);
          }
        });
        projectRes.data.gallaryImges = gallaryImges;
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = projectRes.data.ProjectMeta.length - 1; i >= 0; i--) {
          tmp = projectRes.data.ProjectMeta[i].price;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        projectRes.data.startingPrice = this.numDifferentiation(lowest);
        projectRes.data.landPrice = this.numDifferentiation(highest);
      }
      await this.setState({ projectData: projectRes ? projectRes.data : {} });
      if (this.props.hideLoader) {
        this.props.hideLoader();
      }
    }
  }
  numDifferentiation(value) {
    let val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    }
    return val;
  }
  render() {
    return (
      <>
        <Modal
          show={this.props.showModal}
          onHide={this.props.handleModalClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="viewPdf"
        >
          <style>{`@media print {*{display: none;}}`}</style>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="viewPdfBody">
            <div>
              <div component={Paper} className="pdfPreviewSection">
                <PDFExport
                  paperSize="A4"
                  margin="1cm"
                  fileName="project.pdf"
                  ref={component => (this.pdfExportComponent = component)}
                >
                  <>
                    <Box className="PdfPreiview Max">
                      <Box width={1} className="FactBanner">
                        <img
                          src={this.state.projectData?.banerImages}
                          loading="lazy"
                          width="100%"
                          alt=" site_image"
                        />
                        <Box className="ProjectName">
                          <h1>{this.state.projectData?.name}</h1>
                          <p>
                            {this.state.projectData?.rera_no
                              ? `Rera Number- ${this.state.projectData?.rera_no}`
                              : ``}
                          </p>
                        </Box>
                      </Box>
                      <Box width={1} my={4}>
                        <Container maxWidth="lg">
                          <Grid container direction="row">
                            <Grid item md={12}>
                              <p className="Overview">
                                {this.state.projectData?.overview}
                              </p>
                            </Grid>
                          </Grid>
                        </Container>
                      </Box>
                      <Box width={1} my={4}>
                        <Container maxWidth="lg">
                          <Grid container direction="row">
                            <Box width={1} mb={4} className="HeadingTitle">
                              Project Highlights
                            </Box>
                          </Grid>
                          <Grid container direction="row" spacing={4}>
                            <Grid
                              item
                              md={7}
                              xs={12}
                              lg={7}
                              m={4}
                              className="ProjectHeightImage"
                              style={{ position: "relative" }}
                            >
                              <img
                                src={this.state.projectData?.highlightsImages}
                                loading="lazy"
                                width="100%"
                                alt=" site_image"
                                style={{ borderRadius: "10px" }}
                              />
                            </Grid>
                            <Grid item md={5} xs={12} lg={5} m={4}>
                              {this.state.projectData?.tower_no ? (
                                <Card className="FactCard" variant="outlined">
                                  <CardContent>
                                    <h4>No. of Towers</h4>
                                    <h6>{this.state.projectData?.tower_no}</h6>
                                  </CardContent>
                                </Card>
                              ) : null}
                              {this.state.projectData?.land_area ? (
                                <Card className="FactCard" variant="outlined">
                                  <CardContent>
                                    <h4>Land Parcel</h4>
                                    <h6>
                                      {this.state.projectData?.land_area
                                        ? this.state.projectData?.land_area +
                                          " Acre"
                                        : "-"}
                                    </h6>
                                  </CardContent>
                                </Card>
                              ) : null}
                              {this.state.projectData?.possession_date &&
                              !this.state.projectData?.ready_to_move ? (
                                <Card className="FactCard" variant="outlined">
                                  <CardContent>
                                    <h4>Possession Date</h4>
                                    <h6>
                                      {this.state.projectData
                                        ?.possession_date &&
                                      !this.state.projectData?.ready_to_move
                                        ? moment(
                                            this.state.projectData
                                              ?.possession_date
                                          ).format("MMMM-YYYY")
                                        : this.state.projectData?.ready_to_move}
                                    </h6>
                                  </CardContent>
                                </Card>
                              ) : null}
                              {this.state.projectData?.startingPrice ? (
                                <Card className="FactCard" variant="outlined">
                                  <CardContent>
                                    <h4>Starting Price</h4>
                                    <h6>
                                      {this.state.projectData?.startingPrice
                                        ?.toString()
                                        .includes("Infinity")
                                        ? "-"
                                        : this.state.projectData
                                            ?.startingPrice}{" "}
                                      {/* (All Inclusive) */}
                                    </h6>
                                  </CardContent>
                                </Card>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Container>
                      </Box>
                      {this.state.projectData?.ProjectMeta?.length && (
                        <Box width={1} my={4}>
                          <Container maxWidth="lg">
                            <Grid container direction="row">
                              <Box width={1} mb={4} className="HeadingTitle">
                                Floor Plans
                              </Box>
                            </Grid>
                            <Grid container direction="row" spacing={8}>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                lg={12}
                                m={4}
                                style={{
                                  position: "relative",
                                  margin: "0 0 30px 0"
                                }}
                              >
                                <TableContainer component={Paper}>
                                  <Table
                                    className="PriceTable"
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="center">
                                          Type
                                        </TableCell>
                                        <TableCell align="center">
                                          Carpet Area (Sq.Ft.)
                                        </TableCell>
                                        <TableCell align="center">
                                          Price (all inclusive)
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.projectData?.ProjectMeta &&
                                        this.state.projectData?.ProjectMeta
                                          .length &&
                                        this.state.projectData.ProjectMeta.map(
                                          (row, i) => (
                                            <TableRow key={i} align="center">
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                align="center"
                                              >
                                                {row.bhk}BHK
                                              </TableCell>
                                              <TableCell align="center">
                                                {row.area}
                                              </TableCell>
                                              <TableCell align="center">
                                                {this.numDifferentiation(
                                                  row.price
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </Container>
                        </Box>
                      )}
                      {this.state.projectData?.gallaryImges?.length && (
                        <Box width={1} my={4}>
                          <Container maxWidth="lg">
                            <List
                              component="list"
                              className="GallerySection"
                              aria-label="Gallery Section"
                            >
                              {this.state.projectData?.gallaryImges &&
                              this.state.projectData?.gallaryImges.length
                                ? this.state.projectData?.gallaryImges.map(
                                    (item, i) => (
                                      <ListItem key={i}>
                                        <img
                                          src={item}
                                          loading="lazy"
                                          width="100%"
                                          alt=" site_image"
                                          style={{ borderRadius: "10px" }}
                                        />
                                      </ListItem>
                                    )
                                  )
                                : ""}
                            </List>
                          </Container>
                        </Box>
                      )}
                      {this.state.projectData?.ProjectAmenities?.length && (
                        <Box width={1} my={4}>
                          <Container maxWidth="lg">
                            <Grid container direction="row">
                              <Box width={1} mb={1} className="HeadingTitle">
                                Amenities
                              </Box>
                            </Grid>
                            <Grid container direction="row">
                              <List
                                component="list"
                                className="AmenitiesSection"
                              >
                                {this.state.projectData?.ProjectAmenities &&
                                  this.state.projectData?.ProjectAmenities.map(
                                    (item, index) =>
                                      item?.Amenity && (
                                        <ListItem key={index}>
                                          {item?.Amenity?.name}
                                        </ListItem>
                                      )
                                  )}
                              </List>
                            </Grid>
                          </Container>
                        </Box>
                      )}
                      {this.state?.projectData?.otherFields?.length && (
                        <Box width={1} my={4}>
                          <Container maxWidth="lg">
                            <Grid container direction="row">
                              <Box width={1} mb={4} className="HeadingTitle">
                                Location
                              </Box>
                            </Grid>
                            {this.state?.projectData?.otherFields &&
                              this.state?.projectData?.otherFields.map(
                                (item, index) => (
                                  <Grid container direction="row" key={index}>
                                    <Grid item md={4} xs={12}>
                                      <h4 className="LocationTitle" key={index}>
                                        {item.lable !== "" && item.lable + " :"}
                                      </h4>
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                      <h5
                                        className="LocationContent text-capitalize"
                                        key={index}
                                      >
                                        {item.value !== "" && item.value}
                                      </h5>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                          </Container>
                        </Box>
                      )}
                      <Box width={1} my={4}>
                        <Container maxWidth="lg">
                          <Grid container direction="row">
                            <Box width={1} mb={4} className="HeadingTitle">
                              Project Link
                            </Box>
                          </Grid>

                          {this.state?.projectData?.rera_no && (
                            <Grid container direction="row">
                              <Grid item md={4} xs={12}>
                                <h4 className="LocationTitle">RERA # :</h4>
                              </Grid>

                              <Grid item md={8} xs={12}>
                                <h4 className="LocationTitle">
                                  {this.state?.projectData?.rera_no}
                                </h4>
                              </Grid>
                            </Grid>
                          )}

                          <Grid container direction="row">
                            {this.state.projectData?.map && (
                              <Grid item md={4} xs={12}>
                                <h4 className="LocationTitle">
                                  Location Map :
                                </h4>
                              </Grid>
                            )}
                            {this.state.projectData?.map && (
                              <Grid item md={8} xs={12}>
                                <a
                                  href={this.state.projectData?.map}
                                  className="LocationContent"
                                >
                                  Click Here To View Map
                                </a>
                              </Grid>
                            )}
                            <>
                              <Grid item md={4} xs={12}>
                                <h4 className="LocationTitle">Contact Us :</h4>
                              </Grid>
                              <Grid item md={8} xs={12}>
                                <a
                                  href={
                                    "tel:" + this.state.projectData?.ivr_no ||
                                    ""
                                  }
                                  style={{ fontSize: "1.4em" }}
                                >
                                  {this.state.projectData?.ivr_no || "-"}
                                </a>
                              </Grid>
                            </>
                            {window.$user.role === "magnet" &&
                              window.$user.phone && (
                                <>
                                  <Grid item md={4} xs={12}>
                                    <h4 className="LocationTitle">
                                      Contact Number :
                                    </h4>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <a
                                      href={"tel:" + window.$user.phone}
                                      style={{ fontSize: "1.4em" }}
                                    >
                                      {window.$user.phone || "-"}
                                    </a>
                                  </Grid>
                                </>
                              )}
                            {window.$user.role === "magnet" &&
                              (window.$user?.first_name ||
                                window.$user?.last_name) && (
                                <>
                                  <Grid item md={4} xs={12}>
                                    <h4 className="LocationTitle">
                                      Contact Name :
                                    </h4>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <span
                                      className="LocationContent text-capitalize"
                                      style={{ fontSize: "1.4em" }}
                                    >
                                      {window.$user?.first_name +
                                        " " +
                                        window.$user?.last_name || "-"}
                                    </span>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </Container>
                      </Box>
                      <Box width={1} my={4}>
                        <Container maxWidth="lg">
                          <p className="Disclaimer">
                            Disclaimer:The content is for information purposes
                            only and does not constitute an offer to avail of
                            any service. Prices mentioned are subject to change
                            without notice and properties mentioned are subject
                            to availability. Images for representation purpose
                            only.
                          </p>
                        </Container>
                      </Box>
                    </Box>
                  </>
                </PDFExport>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ProjectPdfPreview;
