import React, { Component } from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import { withRouter } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getAutoCompleteProject,getRegion } from "../../dataParser/getProjectData";
import { roleWiseAutoComplete } from "../../dataParser/commomDataApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

class bulkLeadFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			option: [],
			project_name: '',
			project_ids: '',
			assign_to: '',
			status: '',
			userName: [],
			region: [],
			region_ids: [],
			selectedValRegion: [],

		};
	}

	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			q: e.target.value,
		};
		let resData = await roleWiseAutoComplete(data);

		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	handleAutoChange = async (e) => {
		this.setState({ isLoading: true });
		let value = e.target.value

		var projectListData = await getAutoCompleteProject(value);

		if (projectListData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData.data })
		} else {

			this.setState({ isLoading: false });
			this.setState({ option: projectListData })
		}

	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	applyFilter = () => {

		let filterData = {};

		if (this.state.project_ids.length > 0) {

			filterData["project_ids"] = this.state.project_ids;
		}

		if (this.state.assign_to != '') {

			filterData["assign_to"] = this.state.assign_to;
		}

		if (this.state.status != '') {

			filterData["status"] = this.state.status;
		}

		if(this.state.region_ids.length > 0){
			filterData["region_ids"] = this.state.region_ids;
		  }

		this.props.changeFilter();
		this.props.filterData(filterData);

	}

	handleMultiSelect = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	resetFilter = () => {

		this.setState({
			autoKey: !this.state.autoKey,
			option: [],
			project_name: '',
			project_ids: '',
			status: '',
			assign_to: '',
			region_ids: [],
			selectedValRegion: [],


		})
	}

	async componentDidMount() {
		let numArr = [{ name: this.props.filters?.selectedValNumber?.name, number: this.props.filters?.selectedValNumber?.number }]
		this.setState({
		  ...this.props.filters,
		  role: window.$role,
		  group: window.$user.group.toLowerCase(),
		  selectedValRegion: this.props?.filters?.selectedValRegion || [], 
		});
	    var regionData = await getRegion();
		this.setState({ region: regionData })
	
	  }
	render() {

		const { userName, option, autoKey, isLoading,region,selectedValRegion } = this.state;

		return (

			<Frag>
				<div className="container filter_lead filterMenuCard pt-3 p-0">
					<div className="row filterRow">
						<div className="col-md-6 mb-3">
							<Autocomplete
								key={autoKey}
								id="UserData"
								getOptionSelected={(userName, value) => userName.name === value.name}
								getOptionLabel={userName => userName.name + " (" + userName.phone + ")"}
								options={userName}
								loading={isLoading}
								onChange={(_event, userName) => {
									if (userName === null) {
										this.setState({
											userName: [],
											assign_to: '',
										})
									} else {
										this.setState({
											assign_to: userName.id,
										})
									}

								}}
								renderInput={params => (
									<TextField
										{...params}
										label="Assigned To"
										fullWidth
										onChange={this.handleAutoUserChange}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</div>
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<FormControl>
									<InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
									<Select
										labelId="demo-controlled-open-select-label"
										value={this.state.status}
										onChange={this.handleChange}
										inputProps={{
											name: 'status',
											id: 'status',
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										<MenuItem value="11">Reactivate</MenuItem>
										<MenuItem value="12">Disqualify</MenuItem>
										<MenuItem value="13">In Progress</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div className="col-md-6 mb-3">
							<Autocomplete
								multiple
								key={autoKey}
								id="projectData"
								getOptionSelected={(option, value) => option.name === value.name}
								getOptionLabel={option => option.name}
								options={option}
								loading={isLoading}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip label={option.name} {...getTagProps({ index })} />
									))
								}
								onChange={(_event, option) => {
									var projectId = option.map((ops) => ops.id);
									this.setState({
										project_ids: projectId,
									})

									if (option === null) {
										this.setState({ option: [] })
									}
								}}
								renderInput={params => (
									<TextField
										{...params}
										label="Project Name"
										fullWidth
										onChange={this.handleAutoChange}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Autocomplete
								multiple
								key={autoKey}
								getOptionSelected={(region, value) =>
								  region.name === value.name
								}
								getOptionLabel={region => region.name}
								options={region}
								value={selectedValRegion}
								renderTags={(value, getTagProps) =>
								  value.map((region, index) => (
									<Chip
									label={region.name}
									{...getTagProps({ index })}
									/>
									))
								  }
								onChange={(_event, region) => {
									var regionId = region.map(ops => ops.id);
									this.setState({
									  region_ids: regionId,
									  selectedValRegion: region
									});									
									if (region === null) {
									  this.setState({ region: [] });
									}
								  }}
								  renderInput={params => (
									<TextField
									{...params}
									label="Region"
									fullWidth
									onChange={this.handleAutoChange}
									InputProps={{
									  ...params.InputProps,
									  endAdornment: (
										<React.Fragment>
										  {params.InputProps.endAdornment}
										</React.Fragment>
									  )
									}}
									/>
								)}
							/>
						</div>
					</div>
					<div className="col-md-12 bgCommon">
						<div className="row">
							<div className="col-sm-6 text-right col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
								</div>
							</div>
							<div className="col-sm-6 col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Frag>
		);
	};
};

export default withRouter(bulkLeadFilter)
