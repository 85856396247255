import React, { useState } from 'react'
import Frag from "../../utils/Frag/Frag.js";
import AddProjectForm from '../common/form/addProjectFrom'
import AddProjectAmenities from '../common/form/addProjectAmenities'
import AddProjectFloor from '../common/form/addProjectFloor'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class AddProject extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			projectId: this.props.updateProjectId ? this.props.updateProjectId : '',
			updateProjectId: this.props.updateProjectId,
			fullDetail: this.props.fullDetail,
			filterData: this.props.filterData,
			mgProjStatus: this.props.mgProjStatus,
		}
		this.handleProjectId = this.handleProjectId.bind(this)
		this.handleAddProject = this.handleAddProject.bind(this)
	}

	handleProjectId (id) {
		this.setState({	projectId: id })
	}


	handleAddProject() {
		window.location.reload();
	}

	render(){

		return(
			<Frag>
				<Grid container spacing={3}>
		            <Grid item md={12}>
						<Paper><AddProjectForm setProjectId = {this.handleProjectId} updateProjectId={this.state.updateProjectId} projectDetail={this.state.fullDetail} mgProjStatus={this.state.mgProjStatus}/></Paper>
					</Grid>
					{(this.state.projectId) &&
		            <Frag>
			            <Grid item md={6}>
				          <Paper ><AddProjectFloor projectId={this.state.projectId} updateProjectId={this.state.updateProjectId} filterData={this.state.filterData} /></Paper>
				        </Grid>
				        <Grid item md={6}>
				          <Paper ><AddProjectAmenities projectId={this.state.projectId} updateProjectId={this.state.updateProjectId} amenitiesDetail={this.state.fullDetail?.ProjectAmenities}/></Paper>
				        </Grid>
			        </Frag>
			    	}
					{ ( !this.state.updateProjectId && this.state.projectId !== '') &&
						<div className="container">
							<div className="row justify-content-center">
								<Button variant="contained"
										onClick={this.handleAddProject}
										style={{ backgroundColor: '#007bff', color: '#fff' }}
								>
								Add More Project
								</Button>
							</div>
						</div>
					}
				</Grid>
			</Frag>
		)
	}
}

export default AddProject
