import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Frag from "../../../utils/Frag/Frag.js";
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { getAssignRmInterested } from "../../../dataParser/getCpUserData";
import { postEngageComment } from "../../../dataParser/getListData";
import { assignLeads } from "../../../dataParser/getListData";
import { autoComplete } from "../../../dataParser/commomDataApi";
import { roleWiseAutoComplete } from '../../../dataParser/commomDataApi';
import Loader from "../../../component/common/loader/loader";
import { REVERT_ROLES } from "../../../config/constant/index.js";
import  AssignLeadsList  from "../../common/modal/assignLeadsModal";
import "./form.css";
import { cleanStorage } from '../../../helper';

class BasicForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            magnet: [],
            assign_rm_id: '',
            comment: '',
            selected_ids: this.props.handleInterestUser,
            rm_email: '',
            is_approved: false,
            sweetShow: false,
            type: "success",
            title: "",
            isError: false,
            assign_rm:false,
            leads:false,
            show:false,
            role: window.$user.role
        }
    }

    componentDidMount() {
        this.setState({ group: window.$user.group.toLowerCase(), reAssignLeadID: [this.props.reAssignLeadID] })
    }

    handleSubmit = async (e) => {

        if (this.props.reAssign !== 'reAssign') {
            e.preventDefault();
        }
        if (this.state.assign_rm_id !== '') {

            this.setState({ showLoader: true });
            let checkSameNumberLeads = false;
            if(this.props.checkSameNumberLeads){
                checkSameNumberLeads = true;
            }
            let addLeadRes;
            if (this.props.reAssign === 'reAssign') {
                addLeadRes = await assignLeads({ ids: this.state.reAssignLeadID, assign_rm_id: this.state.assign_rm_id, type: 'revert' });
            } else {
                addLeadRes = await assignLeads({ ids: this.state.selected_ids, assign_rm_id: this.state.assign_rm_id,same_number_check:checkSameNumberLeads });
            }

            if (addLeadRes.meta.status === 201) {
                let sweetShow = true;
                if(addLeadRes.data && addLeadRes.data.leads.length && this.props.directAssign){
                    sweetShow = false;
                    this.setState({show:true,leads:addLeadRes.data.leads});
                }
                this.setState({
                    showLoader: false,
                    sweetShow: sweetShow,
                    type: "success",
                    title: "Assigned successfully !!!"
                });
            }else if (addLeadRes.meta.status === 422) {

                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "warning",
                    title: addLeadRes.meta.message,
                });
            }
             else {

                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "error",
                    title: "Something went wrong !!!"
                }); 
            }
        } else {

            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "error",
                title: "Please select RM and ids"
            });
        }
    }

    handleReAssignLead = async (e) => {
        e.preventDefault();

        if (this.state.comment.trim().length) {
            this.handleSubmit();
            this.addCommentsData();
        } else {
            this.setState({ isError: true });
        }
    }
    addCommentsData = async (id) => {

        this.setState({ comment: '' });
        let commentReq = {};
        commentReq.data = {};

        commentReq.id = this.state.reAssignLeadID;
        commentReq.data["user_id"] = window.$user.id;
        commentReq.data["comments"] = this.state.comment;

        if (this.state.comment.trim().length) {
            this.setState({ showLoader: true });
            let commentRes = await postEngageComment(commentReq);

            if (commentRes.meta.status === 201) {

                this.setState({ showLoader: false });
            }
        } else {
            this.setState({ isError: true });
        }
    }

    handleAssignMagnet = async (e) => {
        e.preventDefault();
        
        if (this.state.assign_rm_id !== "" && this.state.selected_ids.length) {

            this.setState({ showLoader: true });
            var addLeadRes = await getAssignRmInterested({ ids: this.state.selected_ids, enroll_rm_id: this.state.assign_rm_id });

            if (addLeadRes.meta.status === 201) {

                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "success",
                    title: "Assigned successfully !!!"
                });
            } else if (addLeadRes.meta.status === 401) {

                cleanStorage();
                this.props.history.push("/login");

            } else {

                this.setState({
                    showLoader: false,
                    sweetShow: true,
                    type: "error",
                    title: "Something went wrong !!!"
                });
            }
        } else {

            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "error",
                title: "Please select RM and try again !!!"
            });
        }

    }

    handleAutoChange = async (e) => {
        this.setState({ isLoading: true });
        let data = {};
        let resData = {};
        data.users_active = 1;
        // debugger        
        if (this.state.group === 'magnet') {
            data.q = e.target.value;
            if (this.state.role === 'magnettl'){
                data.roles = [REVERT_ROLES.MAGNET_S2, REVERT_ROLES.MAGNET_S3, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
                resData = await autoComplete('users',data);
            }else{
                data.roles = this.props?.location?.pathname === "/magnet-enroll" ? [REVERT_ROLES.MAGNET_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.CUSTOMER_SUCCESS] : [REVERT_ROLES.MAGNET_S2, REVERT_ROLES.MAGNET_S3, REVERT_ROLES.MAGNET_PLATINUM , REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.MAGNET_TL]
                resData = await autoComplete('users',data);
            }
        } else if (this.props.reAssign === 'reAssign') {
            data.q = e.target.value;
            data.roles = [REVERT_ROLES.PRESALE_HEAD, REVERT_ROLES.SALES_MANAGER, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.SOURCING_S2, REVERT_ROLES.SOURCING_S3]
            resData = await autoComplete(data);
        } else {
            data.q = e.target.value;
            if(this.state.role === 'saleshead') {
                data.roles = [REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.SALES_MANAGER ,REVERT_ROLES.SOURCING_S2 ,REVERT_ROLES.SOURCING_S3 , REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD] 
            }
            else{
                data.roles = [REVERT_ROLES.MAGNET_S2, REVERT_ROLES.MAGNET_S3, REVERT_ROLES.SALES_TL, REVERT_ROLES.SENIOR_SALES_TL, REVERT_ROLES.CLOSING_S1, REVERT_ROLES.PRESALES_TL, REVERT_ROLES.MAGNET_RM, REVERT_ROLES.SALES_MANAGER ,REVERT_ROLES.SOURCING_S2 ,REVERT_ROLES.SOURCING_S3 ,REVERT_ROLES.MAGNET_TL, REVERT_ROLES.PNL_HEAD, REVERT_ROLES.SALES_HEAD]
            }
            resData = await autoComplete('users',data);
        }

        if (resData.meta.status === 200) {

            this.setState({ isLoading: false });
            this.setState({ magnet: resData.data })
        } else if (resData.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");

        } else {

            this.setState({ isLoading: false });
            this.setState({ magnet: [] })
        }

    }

    onChange = (f) => {
        
        this.setState({ [f.target.name]: f.target.value });
    }

    handleSweet = (type) => {
        this.setState({ sweetShow: !this.state.sweetShow });
        if(this.props.directAssign && !this.props.leadAssign){
            let path = window.location.href.split('#/');
            this.props.history.push(
                {
                    pathname: `/${path[1]}`,
                    state: {},
                }
            ) 
            return false;
        }        
        this.props.getList();
        this.props.changeModal();
    }
    handleModal = () => {
        this.setState({
            show: false
        });
        let path = window.location.href.split('#/');
        this.props.history.push(
            {
                pathname: `/${path[1]}`,
                state: {},
            }
        ) 
    }
    render() {

        const { sweetShow, type, title, magnet, isLoading, isError, comment,show, leads, assign_rm, selected_ids } = this.state;

        return (
            <Frag>
                <div className="formContainer">
                    <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
                    <Loader show={this.state.showLoader} />
                    <form role="form">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <FormControl>
                                        <Autocomplete
                                            id="asynchronous-demo"
                                            getOptionSelected={(magnet, value) => magnet.name === value.name}
                                            getOptionLabel={magnet => magnet.name + " (" + magnet.phone + ")"}
                                            options={magnet}
                                            loading={isLoading}
                                            onChange={(_event, magnet) => {
                                                if (magnet === null) {
                                                    this.setState({ magnet: [] })
                                                } else {
                                                    this.setState({
                                                        assign_rm_id: magnet.id,
                                                        rm_email: magnet.email,
                                                        assign_rm:magnet
                                                    })
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label="Assign RM"
                                                    fullWidth
                                                    onChange={this.handleAutoChange}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <BusinessRoundedIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {isLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                                {this.props.reAssign === 'reAssign' &&
                                    <div className="col-sm-12 mt-3">
                                        <div className="form-group">
                                            <TextField
                                                required
                                                error={isError ? true : false}
                                                id="standard-multiline-flexible"
                                                label="Comments"
                                                name="comment"
                                                helpertext={isError ? 'Please Enter SomeThing!' : null}
                                                multiline
                                                rowsMax="4"
                                                value={comment}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button
                                        variant="contained"
                                        onClick={this.props.from === 'lead_assign' ? this.handleSubmit : (this.props.reAssign === 'reAssign') ? this.handleReAssignLead : this.handleAssignMagnet}
                                        className="btn btn-success"
                                    >
                                        Assign
		                          </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <AssignLeadsList show={show} leads={leads} changeModal={this.handleModal} assign_rm={assign_rm} handleAssingUserPopup={this.handleAssingUserPopup} lead_ids={selected_ids} filter={this.props.filter}/>
            </Frag>
        );
    };
};

export default withRouter(BasicForm);
