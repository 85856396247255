import React from 'react'
// import MainHeader from './CliManagement/MainHeader'
import Loader from './common/loader/loader'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import { postEligibilityPercentages, getEligibilityPercentagesData } from '../dataParser/getListUserData';
import Pagination from "react-js-pagination";

// import TextField from '@material-ui/core/TextField';
// import DateFnsUtils from "@date-io/date-fns";
/* import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers"; */
// import ButtonSubmit from './common/controls/ButtonSubmit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faFilter,
  faEdit, faCheck
} from "@fortawesome/free-solid-svg-icons";
// import FilterDrawer from "@material-ui/core/Drawer";
// import SelectOption from './common/controls/SelectOption';
import InputField from './common/controls/InputField';
// import { documentStatus } from './common/datas/data';

// import Chip from "@material-ui/core/Chip";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import PopUpAlert from "./common/sweetAlert/popUpAlert";

// import Checkbox from "@material-ui/core/Checkbox";
// import Select from "@material-ui/core/Select";
// import Input from "@material-ui/core/Input";
// import MenuItem from "@material-ui/core/MenuItem";
// import ListItemText from "@material-ui/core/ListItemText";

import moment from "moment";
import { DECIMAL_REGEX } from '../config/constant';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const fields = ["Id", "Type", "Slab From", "Slab To", "Percentage", "Effective From" ,"Effective Till", "Action"]

let initialState = {
  showLoader: false
  , data: []
  , fullData: null
  , count: 0
  , size: 10
  ,  percentage:""
  , editId: ""
}


const EligibilityPercentages = () => {
  const [state, setState] = React.useState(initialState);
  const { showLoader, data, count, size, editId,percentage } = state;
  
  const [activePage, setActivePage] = React.useState(1);

  const [alertState, setAlertState] = React.useState({
    alertShow: false
    , alertMsg: ""
  });

  const { alertShow, alertType, alertMsg } = alertState;

  React.useEffect(() => {

    let listData = {};
    listData.size = size;
    listData.page = 1;
    getApi(listData);
  }, [])

  const getApi = async (queryParam) => {
    setState({ ...state, showLoader: true })
    let getRes = await getEligibilityPercentagesData(queryParam);
     
    setState({
      ...state
      , showLoader: false
      , data: getRes?.data?.rows
      , count: getRes.meta.count
      , size: getRes.meta.size
      , editId: "" 
    })
  }

  const handleEdit = (item) => {
    setState({
      ...state
      , percentage: item?.percentage
      , editId: item.id
    });
  }

  const handleSave = async (item) => {
    let postData = item;
    if (percentage) postData.percentage = percentage;
    const res = await postEligibilityPercentages(postData);
    if (res.meta.status === 201) {
      setState({ ...state, editId: "" })
      setAlertState({
        alertShow: true
        , alertType: "success"
        , alertMsg: res?.data?.success?.message || "Updated successfully!!!"
      })
      handleChangePage(activePage);
    }
    else {
      setAlertState({
        alertShow: true
        , alertType: res.meta.status === 409 ? "warning" :"error"
        , alertMsg: res?.meta?.message || "Something went wrong!!!"
      })
    }
  }
  const handleValueChange = (e) => {
    let { name, value } = e.target
    if (value === '' || DECIMAL_REGEX.test(value)) {
      setState({
        ...state,
        [name]: value
      })
    }
  }

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber)
    let listData = {};
    listData.size = size;
    listData.page = pageNumber;
    getApi(listData);
  }
  const handleAlertClose = () => {
    setAlertState({
      ...alertState
      , alertShow: !alertShow
    })
  }
  
  return (
    <>
      <Loader show={showLoader} />
      <div className="listContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                {data.length > 0 ?
                  <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }}>
                    <TableHead>
                      <TableRow>
                        {fields.map((item, idx) => (
                          <StyledTableCell key={idx}>{item}</StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item, idx) => (
                        <TableRow key={idx} className="text-capitalize">
                          <TableCell>{item.id || "-"}</TableCell>
                          <TableCell>{item?.percentage_type}</TableCell>
                          <TableCell>{item?.slab_from|| "-"}</TableCell>
                          <TableCell>{item?.slab_to || "-"}</TableCell>
                          {editId === item.id
                            ?
                            <>
                              <TableCell>
                                <div className="d-flex justify-conent-center align-items-end">
                                  <InputField
                                    className="w-50"
                                    name="percentage"
                                    onChange={handleValueChange}
                                    value={percentage}
                                  />
                                  <span>%</span>
                                </div>
                              </TableCell>
                              <TableCell>
                               { item.created_at ? moment(item.created_at).format("DD/MM/YYYY") : '-' }
                              </TableCell>
                              <TableCell>
                               { item.effective_till ? moment(item.effective_till).format("DD/MM/YYYY") : '-' }
                              </TableCell>
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="fs-14 cursor-pointer"
                                  onClick={() => handleSave(item)}
                                />
                              </TableCell>
                            </>
                            :
                            <>
                            <TableCell>{item?.percentage}</TableCell>
                            <TableCell>
                               { item.created_at ? moment(item.created_at).format("DD/MM/YYYY") : '-' }
                            </TableCell>
                            <TableCell>
                               { item.effective_till ? moment(item.effective_till).format("DD/MM/YYYY") : '-' }
                            </TableCell>
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="fs-14 cursor-pointer"
                                  onClick={() => handleEdit(item)}
                                />
                              </TableCell>
                            </>
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  : <div className="main_area_display shadow card p-3">
                    <h5>{showLoader ? "Loading.." : "No Data Found !!!"}</h5>
                  </div>
                }
              </TableContainer>
            </div>
          </div>

          <div className="row justify-content-end">
            {count > size ?
              <div className="paginationBlock">
                <Pagination
                  hideDisabled
                  activePage={activePage}
                  itemsCountPerPage={size}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  onChange={handleChangePage}
                />
              </div>
              :
              ''
            }
          </div>
        </div>
      </div>
      <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
    </>
  )
}

export default EligibilityPercentages