import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Routes from "./router/router.js";

import Header from "./component/header/header.js";
import SideBar from "./component/side-header/side_header.js";
import ReactGA from 'react-ga'; 
import {Helmet} from "react-helmet";
import { AMAZON_URL, REDIRECT_URL, NEW_CRM_URL } from "./config/serverKey.js";
import ReactDOM from "react-dom";
import FeedbackRating from "./component/common/action/FeedbackRating.js";
import queryString from "query-string";
import { redirectLogin } from "./helper/index.js";

ReactGA.initialize('UA-193488414-1', {
  debug: true,
  titleCase: false,
  // gaOptions: {
  //   userId: window.$user ? window.$user.id:123
  // }
});
ReactGA.pageview(window.location.pathname + window.location.hash + window.location.search);
var SITE_FAVICON="";
var SITE_TITLE="";
var SITE_DESCRIPTION="";

class Drawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sideBar: false,
      mobileSideBar: false,
      public_path: []
    };
  }

  componentDidMount() {
    (function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "c318b5cecf174d56730a4d7ea0ede3f1";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");
    let public_path = this.props.location.pathname.split('/')
    this.testMagnet();
    public_path = public_path.filter(item=>item=="factsheet")
    this.setState({public_path: public_path})
    this.update();
  }
  update = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };
  componentDidUpdate(){
    this.testMagnet();
  }
  testMagnet = () =>{
    const homesfy_lg = localStorage.getItem('homesfy_lg');
    const url = window.location.hash.replace("#", "").split("?")[0];
    if(url.startsWith("/register") ){
      window.location.href = NEW_CRM_URL+"/register";
    }
    let DYNAMIC_METADATA_URL = window.location.host
    let hashUrl = window.location.hash.replace("#", "").split("?")[0];
    if(DYNAMIC_METADATA_URL.includes("mymagnet.io")){
      SITE_FAVICON = AMAZON_URL+"/mg-favicon.svg";
      switch(hashUrl){
        case "/login":
          SITE_TITLE = "Magnet Platform Login";
          SITE_DESCRIPTION = "Login with Magnet platform and use our hightech CRM for better lead management and client servicing";
          break;
        case "/register":
          SITE_TITLE = "Magnet Platform Registration";
          SITE_DESCRIPTION = "Register at Magnet to use our platform that offers hightech CRM for better lead management and client servicing";
          break;
        default:
          SITE_TITLE = "Magnet"
          SITE_DESCRIPTION = SITE_TITLE;
      }
      let queryParams = queryString.parse(this.props.location.search);
      if(queryParams?.homesfy_lg) {
        localStorage.setItem('homesfy_lg',queryParams.homesfy_lg);
        window.location.reload();
      }	
      redirectLogin(NEW_CRM_URL, homesfy_lg);
    }
    else{
      if(window.location.href.includes('myapp')){
        ReactGA.set({userId:window.$user ? window.$user.id :123 });
        ReactGA.pageview(window.location.pathname + window.location.hash + window.location.search);
      }
      SITE_FAVICON = AMAZON_URL+"/favicon.ico"
      SITE_TITLE = "Homesfy Platform"
      SITE_DESCRIPTION = SITE_TITLE;
      redirectLogin(REDIRECT_URL, homesfy_lg);
    }
  }
  render() {
	if(!this.state.screenWidth) return <></>
    const isDesktop = this.state.screenWidth;
    const {public_path} = this.state;
    return (
      <>
      <Helmet>
        <title>{SITE_TITLE}</title>
        <link rel="icon" href={SITE_FAVICON} />
        <link rel="apple-touch-icon" href={SITE_FAVICON} />
        <meta name="description" content={SITE_DESCRIPTION} />
        <meta property="og:title" content={SITE_TITLE}/>
        <meta property="og:description" content={SITE_DESCRIPTION} />
        {/* <meta property="og:image" content={MAGNET_APP_URL+"/logo.svg"} /> */}
      </Helmet>
      {isDesktop > 991 ?
        <>
          {this.props.location &&
          public_path[0] === "factsheet" || this.props.location.pathname === "/magnet-set-password" || this.props.location.pathname === "/thankyou" || this.props.location.pathname === "/login" || this.props.location.pathname.includes("/forget-password") || this.props.location.pathname === "/register" || this.props.location.pathname === "/terms-and-conditions" || this.props.location.pathname === '/verify-email' ? null : (
            <SideBar/>
          )}
          {this.props.location &&
          public_path[0] === "factsheet" || this.props.location.pathname === "/magnet-set-password" || this.props.location.pathname === "/thankyou" || this.props.location.pathname === "/login" || this.props.location.pathname.includes("/forget-password") || this.props.location.pathname === "/register" || this.props.location.pathname === "/terms-and-conditions" || this.props.location.pathname === '/verify-email' ? null : (
            <Header  {...this.props}/>
          )}
        </>
        :
        <>
          {this.props.location &&
          public_path[0] === "factsheet" || this.props.location.pathname === "/magnet-set-password" || this.props.location.pathname === "/thankyou" || this.props.location.pathname === "/login" || this.props.location.pathname.includes("/forget-password") || this.props.location.pathname === "/register" || this.props.location.pathname === "/terms-and-conditions" || this.props.location.pathname === '/verify-email'? null : (
            <Header  {...this.props}/>
          )}
          {this.props.location &&
          public_path[0] === "factsheet" || this.props.location.pathname === "/magnet-set-password" || this.props.location.pathname === "/thankyou" || this.props.location.pathname === "/login" || this.props.location.pathname.includes("/forget-password") || this.props.location.pathname === "/register" || this.props.location.pathname === "/terms-and-conditions" || this.props.location.pathname === '/verify-email' ? null : (
            <SideBar/>
          )}
        </>
      }  
      <Routes />
      {window?.$user?.is_user_rating ==false && <FeedbackRatingPortal />}
      </>
    );
  }
}
const FeedbackRatingPortal = () =>{
  return ReactDOM.createPortal(
      <FeedbackRating />
      , document.getElementsByTagName('body')[0]
  )
}
export default withRouter(Drawer);
