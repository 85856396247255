import React, { Component } from 'react'
import Frag from "../../../utils/Frag/Frag";
import InputField from '../controls/InputField'
import ButtonSubmit from '../controls/ButtonSubmit'
import Autocomplete from '../controls/Autocomplete'
import SelectOption from '../controls/SelectOption'
import RadioGroup from '../controls/RadioGroup'
import { national, facebookPlatform, googlePlatform } from '../datas/data'
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import TextField from "@material-ui/core/TextField";
import {
	getFacebookAds,
	sendFbAdLead, checkDublicateFbAdLead
} from "../../../dataParser/getListUserData";
import { autoComplete } from "../../../dataParser/commomDataApi";
import Loader from "../../../component/common/loader/loader";
// import CustomizedSnackbars from '../../autoLogout/snackBar';
import countryCodes from '../../../config/constant/countryCodes';
import InputAdornment from '@material-ui/core/InputAdornment';
import PublicIcon from '@material-ui/icons/Public';
import { phoneCheck } from "../../../helper";
import PopUpAlert from "../sweetAlert/popUpAlert";
import { chat_option, NOTIFY_MSG } from '../../../config/constant';
import ReactDOM from 'react-dom'

class AddFbAdLead extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showLoader: false,
			open: false,
			msg: '',
			type: 'success',
			ad_name: '',
			name: '',
			email: '',
			country_code: '+91',
			phone: '',
			platform: '',
			team_feedback: '',
			ad_id: null,
			nationality: 1,
			region_id: '',
			added_by: window.$user.id,
			project: [],
			project_id: '',
			facebookAds: [],
			selectedAd: {},
			error: '',
			errors: {
				phone: '',
			},
			alertShow: false,
			alertType: "success",
			alertTitle: "",
			chat_source: ""
		}
	}

	componentDidMount() {
		this.getFacebookAds();
	}

	handleAutoProjectChange = async (e) => {
		(async () => {
			try {
				let data = {
					q: e.target.value,
					is_adwords: 1
				};
				var resData = await autoComplete('projects', data);
				this.setState({
					project: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}

	changeProjectData = (project) => {
		if (project === null) {
			project = {}
		}
		this.setState({
			project_id: project.id,
			selectedProj: project,
			showLoader: true,
		})
		this.checkDublicateLead(this.state.phone);
	}

	getFacebookAds = async () => {
		var d = new Date();
		let param = {
			to_date: `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`,
			from_date: `${d.getFullYear()}-${d.getMonth()}-${d.getDate() - 1}`
		};
		let facebookAds = await getFacebookAds(param);

		this.setState({
			facebookAds: facebookAds.data
				? Object.values(facebookAds.data).length > 0
					? facebookAds.data
					: []
				: [],
			showLoader: false
		});
	};

	onAdSelect = value => {
		this.setState({
			showLoader: true,
			selectedAd: value,
			ad_name: value?.name,
			ad_id: value?.id,
		});
		this.checkDublicateLead(this.state.phone);
	}

	handleNumberChange = async (e) => {
		let checkPhone = false;
		if (this.state.country_code == "+91") {
			checkPhone = phoneCheck(e.target.value);
			this.state.errors.phone = checkPhone ? "" : "phone is invalid";
		} else if (this.state.country_code != "+91" && e.target.value.length > 6) {
			checkPhone = true;
		}
		let value = e.target.value.replace(/\D/g, "")
		this.setState({ phone: value });
		if (checkPhone && this.state.project_id) {
			await this.checkDublicateLead(value);
		} else {
			this.setState({ phone: value, showLoader: false });
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	handleClose = () => {
		this.setState({ alertShow: false });
		this.props.getList() //refresh page with filter
		this.props.changeModal() //close modal
	}

	handleResponse = (res) => {
		if (res.meta.status === 201) {
			this.setState({
				showLoader: false,
				open: true,
				msg: `Lead Added successfully*, ` + NOTIFY_MSG,
			})
		}
		else {
			this.setState({
				showLoader: false,
				open: true,
				type: "error",
				msg: res.meta.message
			});
		}
		/* setTimeout( ()=>{
			this.props.changeModal() //close modal
			} , 3000 ) */
	}

	sendFbAdLeadData = async (e) => {
		this.setState({
			showLoader: true
		})
		e.preventDefault();
		// e.preventDefault();
		const {
			name, email, country_code, phone, platform, team_feedback, nationality, region_id, project_id,
			added_by, ad_name, ad_id, selectedAd, chat_source
		} = this.state

		if (name !== "" && phone !== "" && platform !== "" && (this.props.status === 'campaign' ? selectedAd !== null : project_id !== '')) {
			let data = {}

			data["name"] = name;

			if (email !== "") {
				data["email"] = email;
			}

			data["phone"] = country_code + phone;

			data["platform"] = platform;

			if (team_feedback !== "") {
				data["team_feedback"] = team_feedback;
			}

			data["nationality"] = nationality;

			if (region_id !== '') {
				data["region_id"] = region_id;
			}
			if (project_id !== '') {
				data["project_id"] = project_id;
			}
			if (added_by !== "") {
				data["added_by"] = added_by;
			}
			if (ad_name !== "") {
				data["ad_name"] = ad_name;
			}
			if (ad_id !== null) {
				data["ad_id"] = ad_id;
			}

			if(chat_source) data["chat_source"] = chat_source;
			// console.log(data,'data')
			let resData;
			resData = await sendFbAdLead(data)
			this.handleResponse(resData)
			this.setState({ showLoader: false })
		}
		else {
			this.setState({
				showLoader: false,
				error: "Above all * fields are reqiured !!!",
			})
		}
	}
	checkDublicateLead = async (number) => {
		if (!number && !this.state.project_id) {
			this.setState({ showLoader: false });
			return false;
		}
		let data = { phone: number };
		if (this.state.project_id) {
			data.project_id = this.state.project_id;
		}
		if (this.state.ad_id) {
			data.ad_id = this.state.ad_id;
		}
		let duplicatLeadRes = await checkDublicateFbAdLead(data);
		if (duplicatLeadRes.meta.status === 409) {
			this.setState({
				showLoader: false,
				alertShow: true,
				alertType: "warning",
				alertTitle: duplicatLeadRes.meta.message,
			});
		} else {
			this.setState({ showLoader: false });
		}

	}
	render() {
		const {
			name, email, phone, platform, team_feedback, nationality, region_id, project_id,
			added_by, ad_name, ad_id, showLoader, type, error, msg, open, chat_source
		} = this.state
		const variant = 'standard'
		return (
			<Frag>
				<PopPortal type={type} msg={msg} open={open} handleClose={this.handleClose} hideTime={6000} />
				<Loader show={showLoader} />
				<PopUpAlert type={this.state.alertType} msg={this.state.alertTitle} open={this.state.alertShow} handleClose={this.handleClose} />
				<form action="">
					<div className="container py-3">
						<div className="row">
							<div className="col-md-6 mb-3">
								<InputField
									variant={variant}
									label='Client Name*'
									name='name'
									value={name}
									onChange={this.handleChange}
									icon={<AccountCircle />}
								/>
							</div>
							<div className="col-md-6 mb-3">
								{this.props.status === 'campaign' ?
									<MuiAutocomplete
										options={this.state.facebookAds}
										getOptionLabel={option => `${option.name} - ${option.id}`}
										onChange={(e, value) => this.onAdSelect(value)}
										renderInput={params => (
											<TextField
												{...params}
												label="Ad Name*"
											/>
										)}
									/>
									:
									<Autocomplete
										label='Project Name*'
										variant={variant}
										options={this.state.project}
										// value={this.state.selectedProj}
										onChange={this.changeProjectData}
										handleAutoChange={this.handleAutoProjectChange}
									/>
								}
							</div>
							<div className="col-md-6 mb-3">
								<MuiAutocomplete
									getOptionLabel={option => `${option.name} (${option.code})`}
									options={countryCodes}
									onChange={(e, options) => options && this.setState({ 'country_code': options.code })}
									value={
										(() => {
											for (let index = 0; index < countryCodes.length; index++) {
												const selected_country = countryCodes[index];

												if (selected_country.code === this.state.country_code) {
													return selected_country;
												}
											}
											return {};
										})()
									}
									renderInput={params => (
										<TextField
											{...params}
											reqiured
											fullWidth
											label="Country Code *"
											name='country_code'
											id='country_code'
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment position="start">
														<PublicIcon />
													</InputAdornment>
												),
											}}
										/>
									)}
								>
								</MuiAutocomplete>
							</div>

							<div className="col-md-6 mb-3">
								<InputField
									error={this.state.errors.phone !== '' ? 'error' : ''}
									variant={variant}
									label='Client Number*'
									name='phone'
									value={phone}
									onChange={this.handleNumberChange}
									helperText={this.state.errors.phone !== '' ? this.state.errors.phone : ''}
									icon={<PhoneIphoneIcon />}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<InputField
									variant={variant}
									label='Client Email'
									name='email'
									value={email}
									onChange={this.handleChange}
									icon={<EmailIcon />}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<SelectOption
									options={this.props.status === 'campaign' ? facebookPlatform : googlePlatform}
									variant={variant}
									label="Platform*"
									name="platform"
									value={platform}
									onChange={this.handleChange}
								/>
							</div>
							{platform == "5" &&
								<div className="col-md-6">
									<RadioGroup
										name="chat_source"
										label="Chat Source"
										value={chat_source}
										items={chat_option}
										onChange={this.handleChange}
									/>
								</div>
							}
							<div className="col-md-6">
								<RadioGroup
									name="nationality"
									value={nationality}
									label="Nationality"
									onChange={this.handleChange}
									items={national}
								/>
							</div>
							<div className="col-md-12 mb-3">
								<InputField
									variant={variant}
									label='Team Feedback'
									name='team_feedback'
									value={team_feedback}
									multiline={true}
									onChange={this.handleChange}
								/>
							</div>
						</div>
						<div className="col-12 text-center">
							{/*alertShow && <CustomizedSnackbars type={type} msg={title}/> */}
							<ButtonSubmit
								onClick={this.sendFbAdLeadData}
							/>
							<p className="mb-0 mt-3">
								<span className="text-center text-danger">{error !== '' ? error : ''}</span>
							</p>
						</div>
					</div>
				</form>
			</Frag>
		)
	}
}

export default AddFbAdLead

const PopPortal = (props) =>{
	return ReactDOM.createPortal(
	  <PopUpAlert {...props} />
	  , document.getElementsByTagName('body')[0]
	  )
  }