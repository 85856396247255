const permission = {

  assignLeadRmPermission: ["admin","saleshead","pnlhead","presalehead", "magnetadmin", "magnetpnl", "magnettl","presalestl","salestl", "seniorsalestl","salesmanager","closings1","sourcings2", "magnets2","sourcings3", "magnets3"],
  updateRolePermission : ["admin","saleshead","pnlhead","presalehead","hrhead"],
  mySelfRoles : ["sourcings2", "magnets2", "salestl", "seniorsalestl"],
  myTeamRoles : ["presalestl", "presalehead", "salesmanager", "pnlhead", "saleshead"],
  assignToTabs : ["all","open", "suspect", "decision-delayed", "opportunity", "pipeline", "gross-eoi-application", "gross-eoi-non-refundable"],
  callRoles: ["admin", "presalehead", "sourcings2", "magnets2", "sourcings3","magnets3", "presalestl","salestl","closings1","salesmanager"],
  reOpenRoles : ['admin', 'pnlhead', 'presalehead', 'saleshead', 'magnetadmin',"magnetpnl", "salesmanager"],
  projectActiveFocus: ['admin', 'magnetadmin', "magnetpnl", 'saleshead', 'mediamanagers'],
  projectEditRole: ['admin', 'mediamanagers'],
  magnetCallRoles : ["magnettl", "magnetrm", "magnetadmin", "magnetpnl", "admin","customersuccess"]
  , magnetApproveRoles: ["magnettl","magnetrm","customersuccess"]
  , digitalGroup: ["mediamanagers", "campaignmanagers", "campaignoperations", "leadoperations"]
  , magnetGroup: ["magnet", "magnettl", "magnetrm", "magnetadmin", "magnetpnl", "customersuccess"]
  , leaderboardAccess: ["admin", "saleshead", "pnlhead"]
  , fieldHiddenSales: ["closings1","salestl", "magnets3", "seniorsalestl", "magnets2"]
  , sendToAuditAccess: ["admin", "operationshead" ,"financehead" ,"operationsanalyst"]
  , showMagnetPlatinum: ["admin", "magnetadmin"]
  , leadOwnerAccess: ['admin', 'saleshead', 'pnlhead', 'presaleshead', 'presalestl']
  }

module.exports = permission;
