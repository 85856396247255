import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

function loadScript(src, position, id, callback) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);

  if (callback) {
    script.onload = () => callback(script);
  }
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMapsIntLoc(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [placesLoaded, setPlacesLoaded] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyCDV3_jKqZ-NiYNJz9Ki100mRgp0iMDQcg&libraries=places&region=IN',
          document.querySelector('head'),
          'google-maps',
          () => setPlacesLoaded(true),
        );
        loaded.current = true;
      } else {
        setPlacesLoaded(true);
      }
    }
  }, []);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    // console.log(props.autoLocation,"in the set Interesetlocation");

    if (props.autoLocation != null) {
      const tempArr = [props.autoLocation];
      setOptions(tempArr);
      setValue(tempArr[0]);
    } else {
      setOptions([]);
      setValue(null);
    }

    // console.log(placesLoaded, "vvvvvvvvvvvvv")

    if (!placesLoaded) return;

    if (!autocompleteService.current && window.google) {
      try{
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
      catch(e){
        // console.log('==============>ERROR =====>', e)
      }
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, placesLoaded]);

  return (
    <FormControl error={props.isError !== false && true}>
      <Autocomplete
        id="google-map-interested"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          props.handleLocation(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Interested Location" required fullWidth />
        )}
        renderOption={(option) => {
          let matches = option.structured_formatting?.main_text_matched_substrings;
          matches = matches !== undefined ? matches : []
          const parts = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      {props.isError !== '' &&
        <FormHelperText>{props.isError}</FormHelperText>
      }
    </FormControl>
  );
}
