import React from 'react'

const MainHeader = (props) => {
    const {list, count, child} = props;
    return (
        <div className="p-3 leadMainHeader">
            <div className="headerCount">
                <span className="totalCount">Total {list} : </span>
                <span>{count}</span>
            </div>
            {child}
        </div>
    )
}

export default MainHeader
