import React, {Component} from 'react'
import {FormControl, InputLabel, Select as MuiSelect, MenuItem} from '@material-ui/core'

class SelectOption extends Component {
	constructor(props){
		super(props)
	}
	render(){
		const {label, name, value, onChange, options, variant, required, multiple} = this.props
		return (
			<FormControl variant={variant?variant:'outlined'} required={required ? required : false}>
				<InputLabel>{label}</InputLabel>
				<MuiSelect   			
					label={label}
					name={name}
					multiple={multiple ? true : false}
					value={value}
					onChange={onChange}
					>
					{!window.location.hash.includes("auto-assign-lead") || !window.location.hash.includes("audit") && 
						<MenuItem value=''>None</MenuItem>
					}
					{options.map((item)=>(
						<MenuItem key={item.id} value={item.id} disabled={item.isDisabled} 
							style={{ whiteSpace: "unset", wordBreak: "break-all"}}
						>
						{item.name}</MenuItem>
					))}
				</MuiSelect>
			</FormControl>
		)
	}
}

export default SelectOption