import React from 'react';
import "./donut_chart.css";
import { withRouter } from "react-router-dom";
import { getBusinessData, getUpdateData, getActivityData } from "../../../dataParser/getDashboardData";
import Chart from "react-google-charts";
import Frag from "../../../utils/Frag/Frag.js";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { MONTHS } from "../../../config/constant/index.js"
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateMonths, generateYears } from '../../../helper';
const curr_month = new Date().getMonth()+1;

class BarChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      homeData: [],
      assignedDate_from: null,
      assignedDate_to: null,
      months:generateMonths(),
      month: '',
      years: generateYears(),
      year: '',
    };
  }

  async componentDidMount() {
    this.setState({ showLoader: true, type: this.props.type })
    let id;
    if (this.props.userWiseData) {
      this.setState({ roleId: this.props.userWiseData })
      id = this.props.userWiseData;
    } else {
      id = window.$user.id;
    }
    let resData, i;
    if (this.props.type === 'Business') {
      resData = await getBusinessData({ id: id, data: {} });

      let arr = [...resData.data];
      let i = arr.indexOf(arr.filter(x=>x.createdOn == curr_month).pop())
      let arr_last = arr.splice(i+1)
      let final_arr = [...arr.reverse(), ...arr_last.reverse()];
      resData.data = final_arr;
      
      for (i = 0; i < resData.data?.length; i++) {
        resData.data[i].createdOn = MONTHS[resData.data[i].createdOn];
      }

    } else if (this.props.type === 'Activity') {

      resData = await getActivityData({ id: id, data: {} });
      let arr = Object.entries(resData.data[0]);
      var my = [];
      for (i = 0; i < arr.length; i++) {
        my[i] = { type: arr[i][0], value: arr[i][1] };
      }
      resData.data.pop();
      resData.data = my;
    } else {
      resData = await getUpdateData({ id: id, data: {} });
    }
    setTimeout(() => {
      this.setState({ homeData: resData.data, showLoader: false });
    }, 500)
  }

  componentDidUpdate(nextProps) {
    if (nextProps.userWiseData !== this.props.userWiseData) {
      this.handleActionChild(this.props.userWiseData);
    }

  }

  handleDateChange = (key, date) => {
    this.setState({ [key]: date });
  }

  handleActionChild = async (userId) => {
    let id = userId;
    let resData; 
    if (this.state.type === 'Business') {
      if (this.state.assignedDate_from !== null) {
        resData = await getBusinessData({ id: id, data: { form: this.state.assignedDate_from.split("/").join("-"), to: this.state.assignedDate_to.split("/").join("-") } });
      } else {
        resData = await getBusinessData({ id: id, data: {} });
      }

      for (let i = 0; i < resData.data.length; i++) {
        resData.data[i].createdOn = MONTHS[resData.data[i].createdOn];
      }

    } else if (this.state.type === 'Activity') {
      if (this.state.assignedDate_from !== null) {
        resData = await getActivityData({ id: id, data: { created_at_from: this.state.assignedDate_from.split("/").join("-"), created_at_to: this.state.assignedDate_to.split("/").join("-") } });
        let arr = Object.entries(resData.data[0]);
        var my = [];
        for (let i = 0; i < arr.length; i++) {
          my[i] = { type: arr[i][0], value: arr[i][1] };
        }
        resData.data.pop();
        resData.data = my;
      } else {
        resData = await getActivityData({ id: id, data: {} });
        let arr = Object.entries(resData.data[0]);
        var my = [];
        for (let i = 0; i < arr.length; i++) {
          my[i] = { type: arr[i][0], value: arr[i][1] };
        }
        resData.data.pop();
        resData.data = my;
      }

    } else {

      if (this.state.assignedDate_from !== null) {
        resData = await getUpdateData({ id: id, data: { created_at_from: this.state.assignedDate_from.split("/").join("-"), created_at_to: this.state.assignedDate_to.split("/").join("-") } });
      } else {
        resData = await getUpdateData({ id: id, data: {} });
      }
    }
    setTimeout(() => {
      this.setState({ homeData: resData.data, showLoader: false });
    }, 500)
  }

  handleSubmit = async () => {
    this.setState({ showLoader: true })
    let id;
    let resData;
    let my = [];
    if (this.state.roleId) {
      id = this.state.roleId;
    } else {
      id = window.$user.id;
    }
    if (this.state.type === 'Business') {
      resData = await getBusinessData({ id: id, data: { form: this.state.assignedDate_from.split("/").join("-"), to: this.state.assignedDate_to.split("/").join("-") ,year:this.state.year,month:this.state.month}});

      for (let i = 0; i < resData.data.length; i++) {
        resData.data[i].createdOn = MONTHS[resData.data[i].createdOn];
      }

    } else if (this.state.type === 'Activity') {
      if (this.state.assignedDate_from !== null) {
        resData = await getActivityData({ id: id, data: { created_at_from: this.state.assignedDate_from.split("/").join("-"), created_at_to: this.state.assignedDate_to.split("/").join("-") , year:this.state.year, month:this.state.month} });
        let arr = Object.entries(resData.data[0]);
        for (let i = 0; i < arr.length; i++) {
          my[i] = { type: arr[i][0], value: arr[i][1] };
        }
        resData.data.pop();
        resData.data = my;
      } else {
        resData = await getActivityData({ id: id, data: {year:this.state.year, month:this.state.month} });
        let arr = Object.entries(resData.data[0]);
        for (let i = 0; i < arr.length; i++) {
          my[i] = { type: arr[i][0], value: arr[i][1] };
        }
        resData.data.pop();
        resData.data = my;
      }
    } else {
      resData = await getUpdateData({ id: id, data: { created_at_from: this.state.assignedDate_from.split("/").join("-"), created_at_to: this.state.assignedDate_to.split("/").join("-") ,year:this.state.year, month:this.state.month} });
    }
    setTimeout(() => {
      this.setState({ homeData: resData.data, showLoader: false });
    }, 500)
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const { homeData, type, assignedDate_from, assignedDate_to, showLoader } = this.state;
    var data = [];
    for (let i = 0; i < homeData?.length; i++) {
      if (i === 0) {
        var header = Object.keys(homeData[0]);
        data.push([...header]);
      }
      var values = Object.values(homeData[i]);
      data.push([...values]);
    }
    const options = {
      title: type,
      legend: { position: 'bottom', alignment: 'center', orientation: 'vertical', },
      colors: ['#41A9C1'],
    };
    return (
      <Frag>
        {showLoader ?
          (<CircularProgress color="primary" className="dashLoader" size={40} />
          ) : null
        }
        {this.state.type !== 'Business' &&
          <div className="date_filters">
            <div className="row filterRow mb-2">
              <div className="col-lg-3 col-sm-3 col-6">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk={true}
                    margin="normal"
                    id="assigned_date_form"
                    variant="inline"
                    label="From"
                    format="yyyy/MM/dd"
                    maxDate={new Date()}
                    name="assignedDate_from"
                    value={assignedDate_from}
                    onChange={(key, date) => this.handleDateChange('assignedDate_from', date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-lg-3 col-sm-3 col-6">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk={true}
                    margin="normal"
                    id="assigned_date_to"
                    variant="inline"
                    label="to"
                    format="yyyy/MM/dd"
                    maxDate={new Date()}
                    name="assignedDate_to"
                    value={assignedDate_to}
                    onChange={(key, date) => this.handleDateChange('assignedDate_to', date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-lg-2 col-sm-2 col-6">
                <FormControl>
                    <InputLabel id="Presale-Lead-type">
                      Year
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={this.state.year}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "year",
                        id: "year"
                      }}
                      required
                    >
                      {this.state.years.map(year => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </div>
              <div className="col-lg-2 col-sm-2 col-6">
                <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                    Month
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      value={this.state.month}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "month",
                        id: "month"
                      }}
                      required
                    >
                      {this.state.months.map(month => (
                        <MenuItem key={month.key} value={month.key}>
                          {month.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </div>
              <div className="col-lg-2 col-sm-2 col-6  pt-3">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Submit
                    </Button>
              </div>
            </div>
          </div>
        }
        <div className="App">
          {data.length > 0 ?
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={data}
              options={options}
              loader={<div>Loading Data...</div>}
            />
            :
            <div className="errorMsg ">
              Ooops Data Not Found !!!
                </div>
          }
        </div>
      </Frag>
    );
  }
}

export default withRouter(BarChart);