import React, {Component} from 'react'
import AddUpdateIVR from '../../component/IvrManagement/AddUpdateIVR'

class AddUpdateIvrPage extends Component {

	constructor(props){
		super(props)
		this.state={
			id: this.props.location?.state?.id,
			fullDetail: this.props.location?.state?.fullDetail,
		}
	}

	render(){
	    return (
			<div
				className={`bookings-container content-wrapper ${
				this.props.sideBar ? "main-header-collapsed" : ""
				}`}
				>
				<AddUpdateIVR fullDetail={this.state.fullDetail?.data} updateId={this.state.id}/>
	        </div>
	    )
	}
}

export default AddUpdateIvrPage

