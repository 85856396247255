import React, { useState } from 'react'
import { setCliManagement } from '../../dataParser/getIvrData';
import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUpAlert from "../common/sweetAlert/popUpAlert"
import Loading from '../common/loader/loader';
const initialState = {
    showLoader: false
    , alertShow: false
    , alertType: "success"
    , alertMsg: ""
}
const CliAction = ({ editId, itemId, onEdit, number, getListingData }) => {
    const [state, setState] = useState(initialState)

    const setCliData = async (cliId) => {
        setState({
            ...state
            , showLoader: true
        })
        let data = {}
        data["updated_by"] = window.$user.id;
        data["knowlarity_caller_id"] = number;
        if (number.split("+")[1].length === 12 && number.startsWith("+91")) {
            console.log(number, "|Number|", number.length, "|length|")
            let getRes = await setCliManagement(cliId, data);
            if (getRes.meta.status === 201) {
                setState({
                    ...state
                    , showLoader: false
                    , alertShow: true
                    , alertType: "success"
                    , alertMsg: getRes.data.message
                })
            }
            else {
                setState({
                    ...state
                    , showLoader: false
                    , alertShow: true
                    , alertType: "error"
                    , alertMsg: getRes.meta.message
                })
            }
            getListingData();
        }
        else {
            setState({
                ...state
                , showLoader: false
                , alertShow: true
                , alertType: "error"
                , alertMsg: "Please check number startswith +91 and of 10 digit!"
            })
        }
    }
    const handleClose = () => {
        setState({
            ...state,
            alertShow: false
        })
    }
    const { showLoader, alertShow, alertType, alertMsg } = state;
    return (
        <>
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleClose} />
            <Loading show={showLoader} />
            {
                editId === itemId ?
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="fs-14 cursor-pointer"
                        onClick={() => setCliData(itemId)}
                    />
                    :
                    <FontAwesomeIcon
                        icon={faEdit}
                        className="fs-14 cursor-pointer"
                        onClick={onEdit}
                    />
            }
        </>
    )
}

export default CliAction
