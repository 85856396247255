import React, {Component} from 'react';
import Modal, {ModalTitle, ModalBody} from "../../../core/Dialog";
import Frag from "../../../utils/Frag/Frag.js";
import LeadInternalStForm from "../form/leadInternalStForm";


class LeadInternalState extends Component {
	constructor(props){
		super(props)
	}
    render() {

        return (
            <Frag>
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size="sm"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			        <ModalTitle id="contained-modal-title-vcenter" onHide={this.props.changeModal}>
			        	Update Lead Activity State
			        </ModalTitle>
			      <ModalBody>
			        <LeadInternalStForm leadDetail={this.props.leadDetail} getList={this.props.getList} changeModal={this.props.changeModal}/>
			      </ModalBody>
			      
			    </Modal>
            </Frag>
        );
    };
};    

export default LeadInternalState;
