import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Frag from "../../utils/Frag/Frag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faTrashAlt,
  faEdit,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import { getDeleteUser } from "../../dataParser/getListUserData";
import { getSingleUser } from "../../dataParser/getListUserData";
import { getSingleCpUser } from "../../dataParser/getCpUserData";
import { getUsersDataList } from "../../dataParser/getListUserData";
import Loader from "../common/loader/loader";
import "./CpUserList.css";
import SweetWarnAlert from "../../component/common/sweetAlert/sweetAlertWarning";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import Pagination from "react-js-pagination";
import UserModal from "../../component/common/modal/userModal";
import CpUserDetail from "../../component/detailLead/cpUserdetail";
import CpUserModal from "../../component/common/modal/CpUserModel";
import CpUserFilter from "../../component/filter/CpUserFilter.js";
import { REVERT_ROLES } from "../../config/constant/index.js";
import CallAction from "../common/action/CallAction";
import { magnetCallRoles } from "../../config/constant/crmRoleFunctionPermission";
import { changeDate, cleanStorage } from "../../helper";

class CpUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleUser: "",
      singleTlUser: "",
      listDetail: -1,
      activePage: 1,
      showLoader: false,
      showFilter: false,
      sideBar: false,
      meta: props.userDetail.meta,
      data: props.userDetail.data["users"],
      mobileSideBar: false,
      sweetWarnShow: false,
      sweetShow: false,
      type: "success",
      title: "",
      modalShow: false,
      modalTlShow: false,
      modalCpUpdateShow: false,
      CpUpdateDetail: ""
    };
    window.addEventListener("resize", this.update);
    this.fltr = React.createRef()
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    let homesfy_lg = window.atob(localStorage.getItem("homesfy_lg"));
    if (homesfy_lg && homesfy_lg !== "") {
      let user = JSON.parse(homesfy_lg);
      this.setState({
        role: user.role
      });
    }
    this.update();
  }

  handleClickOutside = (e) => {
    if (this.node !== undefined && this.node !== null &&
      !this.node.contains(e.target) && this.state.showFilter &&
      e.target.className !== 'MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button'
      && e.target.className !== 'MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit'
      && e.target.className !== 'MuiAutocomplete-option' && e.target.className !== 'MuiAutocomplete-noOptions') {
      this.setState({
        showFilter: false,
      });
    }

  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        meta: nextProps.userDetail.meta,
        data: nextProps.userDetail.data["users"],
        activePage: 1
      });
    }
  }

  update = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };

  genericGetListData = async params => {
    this.setState({ showLoader: true });
    (async () => {
      try {
        let resData = await getUsersDataList(params);
        if (resData.meta.count >= 0 && resData.meta.status === 200) {
          this.setState({
            meta: resData.meta,
            data: resData.data["users"],
            listDetail: -1,
            showLoader: false
          });
          window.scrollTo(0, 0);
          this.setState({});
        } else if (resData.meta.status == 401) {
          cleanStorage();
          this.props.history.push("/login");
        } else {
          this.setState({
            meta: resData.meta,
            data: resData.data,
            showLoader: false
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  handleChangePage = async pageNumber => {
    this.setState({
      showLoader: true,
      activePage: pageNumber
    });
    let listData = {};
    // if (window.$role === "admin" || window.$role === "magnetadmin" || window.$role === "magnetpnl") {
    //   listData.role_ids = [
    //     REVERT_ROLES.MAGNET,
    //     REVERT_ROLES.MAGNET_TL,
    //     REVERT_ROLES.MAGNET_RM
    //   ];
    // } else if (window.$role === "magnettl") {
    //   listData.role_ids = [REVERT_ROLES.MAGNET, REVERT_ROLES.MAGNET_RM];
    // } else if (window.$role === "magnetrm") {
      listData.role_ids = [REVERT_ROLES.MAGNET];
    // } else {
    //   listData.role_ids = [REVERT_ROLES.MAGNET, REVERT_ROLES.MAGNET_TL];
    // }

    listData.type = 'magnet';
    listData.size = 8;
    listData.pageId = pageNumber;
    Object.assign(listData, this.state.filters);
    this.genericGetListData(listData);
  };

  handleFilter = async filterData => {
    this.setState({ showLoader: true });
    this.setState({ filters: filterData, activePage: 1 });

    let listData = {};
    // if (window.$role === "admin" || window.$role === "magnetadmin" || window.$role === "magnetpnl") {
    //   listData.role_ids = [
    //     REVERT_ROLES.MAGNET,
    //     REVERT_ROLES.MAGNET_TL,
    //     REVERT_ROLES.MAGNET_RM
    //   ];
    // } else if (window.$role === "magnettl") {
    //   listData.role_ids = [REVERT_ROLES.MAGNET, REVERT_ROLES.MAGNET_RM];
    // } else if (window.$role === "magnetrm") {
      listData.role_ids = [REVERT_ROLES.MAGNET];
    // } else {
    //   listData.role_ids = [REVERT_ROLES.MAGNET, REVERT_ROLES.MAGNET_TL];
    // }

    listData.type = 'magnet';
    listData.size = 8;
    listData.pageId = 1;
    Object.assign(listData, filterData);

    this.genericGetListData(listData);
  };

  handleSingleUser = async id => {
    this.setState({ showLoader: true });
    let singleUser = await getSingleCpUser(id);

    if (singleUser.meta.status === 200) {
      this.setState({
        showLoader: false,
        sweetShow: false,
        singleUser: singleUser
      });
    } else if (singleUser.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({
        showLoader: false,
        sweetShow: true,
        type: "error",
        title: "User not found !!!"
      });
    }
  };

  handleTlUser = async id => {
    this.setState({ showLoader: true });
    let singleUser = await getSingleUser(id);

    if (singleUser.meta.status === 200) {
      this.setState({
        showLoader: false,
        sweetShow: false,
        singleTlUser: singleUser
      });
    } else if (singleUser.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({
        showLoader: false,
        sweetShow: false,
        singleTlUser: singleUser
      });
    }
  };

  handlePopUp = () => {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter
    }));
  };

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
    this.handleFilter();
  };

  handleUpdateCpModal = () => {
    this.setState({
      modalCpUpdateShow: !this.state.modalCpUpdateShow,
      singleUser: ""
    });
  };

  handleDeleteUser = async id => {
    this.setState({ showLoader: true });

    let deleteRes = await getDeleteUser(id);

    if (deleteRes.meta.status === 200) {
      this.setState({
        showLoader: false,
        sweetShow: true,
        type: "success",
        title: "User Deleted successfully!!!"
      });
    } else if (deleteRes.meta.status === 401) {
      cleanStorage();
      this.props.history.push("/login");
    } else {
      this.setState({
        showLoader: false,
        sweetShow: true,
        type: "error",
        title: deleteRes.meta.message
      });
    }
  };

  handleSweetWarn = value => {
    this.setState({
      sweetWarnShow: !this.state.sweetWarnShow,
      deleteUser: value
    });
  };

  handleUpdateUser = () => {
    this.setState({
      sweetWarnShow: !this.state.sweetWarnShow
    });
    this.handleDeleteUser(this.state.deleteUser);
  };

  handleModal = () => {
    this.setState({
      modalShow: !this.state.modalShow,
      singleUser: "",
      singleTlUser: ""
    });
  };

  handleTlModal = () => {
    this.setState({
      modalTlShow: !this.state.modaltlShow,
      singleTlUser: ""
    });
  };

  detailView(id) {

    if (this.state.listDetail == id) {
      this.setState({ listDetail: null });
    } else {
      this.setState({ listDetail: id });
    }
  }

  render() {
    const { count, size, status, } = this.state.meta;
    const {
      data,
      type,
      title,
      sweetShow,
      singleUser,
      sweetWarnShow
    } = this.state;
    const isDesktop = this.state.screenWidth;

    return (
      <Frag>
        {isDesktop > 991 ? (
          <div
            className="content-wrapper">
            <SweetWarnAlert
              show={sweetWarnShow}
              changeSweet={this.handleSweetWarn}
              message="User get deleted permanently!!!"
              handleUpdateUser={this.handleUpdateUser}
            />
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Users : </span>
                <span className="">{status === 400 ? "0" : count}</span>
              </div>
              <div className="d-inline ml-3 filterHeader">
                <span className="filter" onClick={this.handlePopUp}>
                  <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                </span>
                <div
                  className={
                    "filterMenu" +
                    " " +
                    (this.state.showFilter ? "filterMenuShow" : "")
                  }
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <CpUserFilter
                    fltr={this.fltr}
                    filterData={this.handleFilter}
                    changeFilter={this.handlePopUp}
                  />
                </div>
              </div>
            </div>
            <Loader show={this.state.showLoader} />
            {status === 200 && this.state.data.length ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {data.map((userData, index) => (
                      <div className="MainList" key={index}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3 pb-4">
                            <div className="row">
                              <div className="col-12">
                                <div className="row mb-1">
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">ID </span>
                                      </div>
                                      <div className="col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.id != null
                                            ? userData.id
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">Name</span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.name != null
                                            ? userData.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Email
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.email != null
                                            ? userData.email
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Mobile Number
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.phone != null
                                            ? userData.phone
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Service RM
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText text-capitalize">
                                          {userData.UserInfo.UserServiceRm?.name || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {this.props.magnetType === "magnet-new-users" &&
                                  <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Created Date
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText text-capitalize">
                                          {changeDate(userData?.created_at) || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  }
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-3">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Enrollment RM
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText text-capitalize">
                                          {userData?.InterestedMagnet?.IntEnrollRm?.name || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Role{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.Role != null
                                            ? userData.Role.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-6 col-12  mb-2">
                                    <div className="row">
                                      <div className="col-12">
                                        <span className="text-muted">
                                          Max Upload Limit{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        {userData.UserInfo?.max_lead != null ? (
                                          <span className="font-small text-capitalize valueText">
                                            {userData.UserInfo.max_lead}
                                          </span>
                                        ) : (
                                            <span className="font-small text-capitalize valueText">
                                              -
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          City{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        {userData.UserInfo != null ? (
                                          <span className="valueText text-capitalize">
                                            {userData.UserInfo.city !== null
                                              ? userData.UserInfo.city.toLowerCase()
                                              : "-"}
                                          </span>
                                        ) : (
                                            <span className="valueText">-</span>
                                          )}
                                      </div>
                                    </div>
                                  </div> */}
                                  {/*<div className="col-md-2 col-sm-12 col-12 mb-1">
									                            			<div className="row">
													                            <div className="col-12 fold-lead">
													                                <span className="text-muted">Region </span>
													                            </div>
													                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
													                                <span className="text-capitalize valueText">{userData.region != null ? userData.region_name : '-'}</span>
													                            </div>
													                        </div>
									                            		</div>*/}

                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Last Login{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.last_log_in != null
                                            ? userData.last_log_in
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Deleted By{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.deletedby != null
                                            ? userData.deletedby.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Is Active{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        {userData.is_active != null ? (
                                          <span className="valueText">
                                            {" "}
                                            {userData.is_active === true
                                              ? "Active"
                                              : "In-active"}
                                          </span>
                                        ) : (
                                            <span className="valueText">-</span>
                                          )}
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Is Online{" "}
                                        </span>
                                      </div>
                                      <div className="col-12 fold-lead">
                                        {userData.is_online != null ? (
                                          <span className="valueText">
                                            {" "}
                                            {userData.is_online === true
                                              ? "Yes"
                                              : "No"}
                                          </span>
                                        ) : (
                                            <span className="valueText">-</span>
                                          )}
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Created Date{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.created_at != null
                                            ? changeDate(userData.created_at)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-2 col-sm-12 col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Updated Date{" "}
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.updated_at != null
                                            ? changeDate(userData.updated_at)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                {/* <div
                                  className={
                                    "ShortContent" +
                                    " " +
                                    (this.state.listDetail === index
                                      ? "show"
                                      : "hide")
                                  }
                                >
                                  <div className="row mb-1">
                                    
                                  </div>
                                </div> */}
                                {/* <div className="row d-block">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    className="detailText col-md-12 d-flex  justify-content-center align-items-center"
                                    onClick={() => this.detailView(index)}
                                  >
                                    {this.state.listDetail === index ? (
                                      <div>
                                        <span className="">Less Details</span>{" "}
                                        <ExpandLessIcon />
                                      </div>
                                    ) : (
                                        <div>
                                          <span className="">More Details</span>{" "}
                                          <ExpandMoreIcon />
                                        </div>
                                      )}
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="action_box">
                              {userData.Role.id !== 21 && userData.Role.id !== 20 ? (
                                <a
                                  className="Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="User Details"
                                  onClick={e => {
                                    this.handleModal();
                                    this.handleSingleUser(userData.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faInfo}
                                    className="text-white"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {this.state.role !== "magnettl" || this.state.role !== "magnetrm" ? (
                                <a
                                  className="deleteUser Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Deactive User"
                                  onClick={value => {
                                    this.handleSweetWarn(userData.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className="text-white"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {userData.Role.id !== 21 && userData.Role.id !== 20 ? (
                                <a
                                  className="UpdateCp Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Update User"
                                  onClick={e => {
                                    this.handleUpdateCpModal();
                                    this.handleSingleUser(userData.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="text-white"
                                  />
                                </a>
                              ) : (
                                <a
                                  className="UpdateCp Action"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Update User"
                                  onClick={e => {
                                    this.handleTlModal();
                                    this.handleTlUser(userData.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="text-white"
                                  />
                                </a>
                              )}
                              <CallAction data={userData} roleAccess={magnetCallRoles} type={2} callingType="magnet" stage={2} device="desktop" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-end">
                    {count > 8 ? (
                      <div className="paginationBlock">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={size}
                          totalItemsCount={count}
                          pageRangeDisplayed={5}
                          onChange={this.handleChangePage}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No User Found !!!</h5>
              </div>
            )}
          </div>
        ) : (
          <div
            className="content-wrapper">
            <SweetWarnAlert
              show={sweetWarnShow}
              changeSweet={this.handleSweetWarn}
              message="User get deleted permanently!!!"
              handleUpdateUser={this.handleUpdateUser}
            />
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Users : </span>
                <span className="">{status === 400 ? "0" : count}</span>
              </div>
              <div className="d-inline ml-3 filterHeader">
                <span className="filter" onClick={this.handlePopUp}>
                  <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                </span>
                <div
                  className={
                    "filterMenu" +
                    " " +
                    (this.state.showFilter ? "filterMenuShow" : "")
                  }
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <CpUserFilter
                    fltr={this.fltr}
                    filterData={this.handleFilter}
                    changeFilter={this.handlePopUp}
                  />
                </div>
              </div>
            </div>
            <Loader show={this.state.showLoader} />
            {status === 200 && this.state.data.length ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {data.map((userData, index) => (
                      <div className="MainList" key={index}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3">
                            <div className="row mb-1">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12 mb-1">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">ID </span>
                                      </div>
                                      <div className="col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.id != null
                                            ? userData.id
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-2">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">Name</span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="text-capitalize valueText">
                                          {userData.name != null
                                            ? userData.name
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-2">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Email
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.email != null
                                            ? userData.email
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-2">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Mobile Number
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText">
                                          {userData.phone != null
                                            ? userData.phone
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-2">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Service RM
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText text-capitalize">
                                          {userData.UserInfo.UserServiceRm?.name || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {this.props.magnetType === "magnet-new-users" &&
                                  <div className="col-6 mb-2">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Created Date
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText text-capitalize">
                                          {changeDate(userData.created_at) || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  }
                                  {/* <div className="col-6 mb-2">
                                    <div className="row">
                                      <div className="col-12 fold-lead">
                                        <span className="text-muted">
                                          Enrollment RM
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                        <span className="valueText text-capitalize">
                                          {userData?.InterestedMagnet?.IntEnrollRm?.name || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Role{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          <span className="text-capitalize valueText">
                                            {userData.Role != null
                                              ? userData.Role.name
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6  mb-2">
                                      <div className="row">
                                        <div className="col-12">
                                          <span className="text-muted">
                                            Max Upload Limit{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          {userData.UserInfo != null ? (
                                            <span className="font-small text-capitalize valueText">
                                              {userData.UserInfo.max_lead !=
                                                null &&
                                                userData.UserInfo.max_lead}
                                            </span>
                                          ) : (
                                              <span className="font-small text-capitalize valueText">
                                                -
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                    </div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            City{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          {userData.UserInfo != null ? (
                                            <span className="valueText text-capitalize">
                                              {userData.UserInfo.city !== null
                                                ? userData.UserInfo.city.toLowerCase()
                                                : "-"}
                                            </span>
                                          ) : (
                                              <span className="valueText">-</span>
                                            )}
                                        </div>
                                      </div>
                                    </div> */}
                                  {/*<div className="col-6 mb-2">
									                            			<div className="row">
													                            <div className="col-12 fold-lead">
													                                <span className="text-muted">Region </span>
													                            </div>
													                            <div className="col-12 fold-lead">
													                                <span className="text-capitalize valueText">{userData.region != null ? userData.region_name : '-'}</span>
													                            </div>
													                        </div>
									                            		</div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Is Active{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          {userData.is_active != null ? (
                                            <span className="valueText">
                                              {" "}
                                              {userData.is_active === true
                                                ? "Active"
                                                : "In-active"}
                                            </span>
                                          ) : (
                                              <span className="valueText">-</span>
                                            )}
                                        </div>
                                      </div>
                                    </div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Last Login{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          <span className="valueText">
                                            {userData.last_log_in != null
                                              ? userData.last_log_in
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Deleted By{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          <span className="text-capitalize valueText">
                                            {userData.deletedby != null
                                              ? userData.deletedby.name
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Is Online{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          {userData.is_online != null ? (
                                            <span className="valueText">
                                              {" "}
                                              {userData.is_online === true
                                                ? "Yes"
                                                : "No"}
                                            </span>
                                          ) : (
                                              <span className="valueText">-</span>
                                            )}
                                        </div>
                                      </div>
                                    </div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Created Date{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          <span className="valueText">
                                            {userData.created_at != null
                                              ? changeDate(userData.created_at)
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                  {/* <div className="col-6 mb-2">
                                      <div className="row">
                                        <div className="col-12 fold-lead">
                                          <span className="text-muted">
                                            Updated Date{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 fold-lead">
                                          <span className="valueText">
                                            {userData.updated_at != null
                                              ? changeDate(userData.updated_at)
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                  <div className="action_box">
                                    {userData.Role.id !== 21 && userData.Role.id !== 20 ? (
                                      <a
                                        className="Action"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="User Details"
                                        onClick={e => {
                                          this.handleModal();
                                          this.handleSingleUser(userData.id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faInfo}
                                          className="text-white"
                                        />
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.role !== "magnettl" ||
                                      this.state.role !== "magnetrm" ? (
                                      <a
                                        className="deleteUser Action"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Deactive User"
                                        onClick={value => {
                                          this.handleSweetWarn(userData.id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className="text-white"
                                        />
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    {userData.Role.id !== 21 && userData.Role.id !== 20 ? (
                                      <a
                                        className="UpdateCp Action"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Update User"
                                        onClick={e => {
                                          this.handleUpdateCpModal();
                                          this.handleSingleUser(userData.id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="text-white"
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        className="UpdateCp Action"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Update User"
                                        onClick={e => {
                                          this.handleTlModal();
                                          this.handleTlUser(userData.id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="text-white"
                                        />
                                      </a>
                                    )}
                                  </div>
                                </div>
                                <CallAction data={userData} roleAccess={magnetCallRoles} type={2} callingType="magnet" stage={2} device="mobile" />
                                {/* <div
                                    className={
                                      "ShortContent" +
                                      " " +
                                      (this.state.listDetail === index
                                        ? "show"
                                        : "hide")
                                    }
                                  >
                                    <div className="row">
                                      

                                    </div>
                                  </div> */}

                                {/* <div className="row d-block">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="detailText col-md-12 d-flex  justify-content-center align-items-center"
                                      onClick={() => this.detailView(index)}
                                    >
                                      {this.state.listDetail === index ? (
                                        <div>
                                          <span className="">Less Details</span>{" "}
                                          <ExpandLessIcon />
                                        </div>
                                      ) : (
                                          <div>
                                            <span className="">More Details</span>{" "}
                                            <ExpandMoreIcon />
                                          </div>
                                        )}
                                    </div>
                                  </div> */}

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-end">
                    {count > 8 ? (
                      <div className="paginationBlock">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={size}
                          totalItemsCount={count}
                          pageRangeDisplayed={5}
                          onChange={this.handleChangePage}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No User Found !!!</h5>
              </div>
            )}
          </div>
        )}
        {singleUser.meta && this.state.modalCpUpdateShow === true ? (
          <CpUserModal
            show={this.state.modalCpUpdateShow}
            changeModal={this.handleUpdateCpModal}
            CpUpdateDetail={singleUser}
            getCpList={this.handleFilter}
          />
        ) : (
          ""
        )}
        {singleUser.meta && this.state.modalShow === true ? (
          <CpUserDetail
            show={this.state.modalShow}
            changeModal={this.handleModal}
            singleUserData={singleUser}
          />
        ) : (
          ""
        )}
        {this.state.singleTlUser.meta && (
          <UserModal
            show={this.state.modalTlShow}
            changeModal={this.handleTlModal}
            fromType="updateUser"
            singleUserData={this.state.singleTlUser}
            getList={this.handleFilter}
          />
        )}
      </Frag>
    );
  }
}

export default withRouter(CpUserList);
