import React, {Component} from 'react'
import KnowlarityCallLog from '../../component/IvrManagement/KnowlarityCallLog'

class KnowlarityCallLogs extends Component {

	constructor(props){
		super(props)
		this.state={
		}
	}

	render(){
	    return (
			<div
				className={`content-wrapper ${
				this.props.sideBar ? "main-header-collapsed" : ""
				}`}
				>
				<KnowlarityCallLog history={this.props.history}/>
	        </div>
	    )
	}
}

export default KnowlarityCallLogs;

