import {get, post, patch,put} from "../utils/methods.js";

export const userLogin = async (value) => {

	let loginData = await post('/users/login',value)
	return  loginData;

}

export const getLogout = async () =>{

	let logOutData = await get('/users/logout/clean')
	return  logOutData;
}


export const getFrogetPwd = async (value) => {

	let forgetpwData = await put('/users/forgot_password',value)
	return  forgetpwData;

}

export const getChangePwdRes = async (value) => {

	let changepwData = await put('/users/reset_password',value)
	return  changepwData;

}

export const getUpdatePwdRes = async (value) => {

	let getUpdatePwdResData = await put('/users/'+value.id+'/change_password',value.data)
	return  getUpdatePwdResData;

}
export const setTermsCondition = async (value) => {
	let setTermsCondition = await put('/users/' + value.id + '/terms_conditions', value)
	return setTermsCondition;
}


export const sendOtp = async (value) =>{
	let sendOtpData = await get('/otp/send/'+value)
	return  sendOtpData;
}

// export const getSliderData = async (value)=>{
// 	var params ={
// 		"section":value
// 	}
// 	let sliderData = await get('/sectionlist',params)

// 	return  sliderData

// }
