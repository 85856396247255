import React from 'react'
import MagnetRequest from '../../component/project/MagnetRequest'

const MagnetRequestPage = ({ sideBar }) => {
    return (
        <div className={`content-wrapper ${sideBar ? "main-header-collapsed" : ""
            }`}>
            <MagnetRequest/>
        </div>
    )
}

export default MagnetRequestPage
