import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import { getLeadSourceData, autoComplete } from "../../../dataParser/commomDataApi.js";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import { updateLeadAutoAssign } from "../../../dataParser/getListData.js";
// import {Form} from 'react-bootstrap';
// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
// import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Loader from "../../../component/common/loader/loader";
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
// import GoogleMaps from "../googleMap/googleMap.js";
// import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
// import {phoneCheck, emailCheck, nameCheck, letterCase, objEmpty, empty,Region} from "../../../helper";
import { cleanStorage, empty } from "../../../helper";

class AutoAssignForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			project_id: '',
			project_name: '',
			source: [],
			userName: [],
			selectedVal: {},
			source_id: '',
			source_name: '',
			sweetShow: false,
			showLoader: false,
			type: "success",
			title: "",
			error: "",
			option: [],
			isLoading: false,
			isError: {
				project_name: '',
				source_id: ''
			}
		};
	}

	async componentDidMount() {
		// console.log("inthe user update");
		this.setState({ showLoader: true });

		var homesfy_lg = (localStorage.getItem("homesfy_lg"));
		var addedBy = '';
		if (homesfy_lg && homesfy_lg !== "") {
			let user = JSON.parse(window.atob(homesfy_lg));
			addedBy = user.id;
			this.setState({ project_addedby: addedBy })

		} else {
			this.setState({ project_addedby: addedBy })
		}

		var allSourceData = await getLeadSourceData();
		this.setState({ source: allSourceData.data })

		// console.log("in the 11111111111111111111111111111111111",this.props.formData);
		if (this.props.formData !== null && this.props.formData !== undefined) {
			// console.log("in the 11111111111111111111111111111111111",this.props.formData);
			let autoFill = this.props.formData;
			// console.log("XXXXXXXXXXXXXXXXXXXXXXX",autoFill);
			let selectedName = [{ name: autoFill.assign_rm, id: autoFill.assign_rm_id }]
			this.setState({
				id: autoFill.id === null ? '' : autoFill.id,
				project_name: autoFill.project_name === null ? '' : autoFill.project_name,
				project_id: autoFill.project_id === null ? '' : autoFill.project_id,
				is_magnet: autoFill.is_magnet === null ? false : autoFill.is_magnet,
				source_id: autoFill.source_id === null ? '' : autoFill.source_id,
				source_name: autoFill.source_name === null ? '' : autoFill.source_name,
				assign_rm_id: autoFill.assign_rm_id === null ? '' : autoFill.assign_rm_id,
				assign_rm: autoFill.assign_rm === null ? '' : autoFill.assign_rm,
				nationality: autoFill.nationality === null ? '' : autoFill.nationality,
				type: autoFill.type === null ? '' : autoFill.type,
				userName: selectedName,
				selectedVal: selectedName[0],
			})
		} else {

			this.setState({
				project_name: "",
				project_id: "",
				builder_name: "",
				location: "",
				project_id_99: "",
				region_id: "",
			})
		}

		this.setState({ showLoader: false });
	}

	formValid = ({ isError, ...rest }) => {
		let isValid = false;

		if (empty(rest.project_id) || empty(rest.source_id) || empty(rest.is_magnet) || empty(rest.assign_rm_id)) {
			return false;
		}

		Object.values(isError).forEach(val => {
			if (val.length > 0) {
				return false;
			} else {
				isValid = true
			}
		});

		return isValid;
	};


	formValChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}

	updateProjectForm = async (e) => {
		e.preventDefault();
		// console.log(this.formValid(this.state))
		if (this.formValid(this.state)) {
			let mainData = {};
			// let data = {
			//   builder_name: this.state.builder_name,
			//   name: this.state.project_name,
			//   created_by: this.state.project_addedby,
			//   location: this.state.location,
			//   region_id: this.state.region_id,
			// }
			let data = {}
			if (this.state.project_id != '') data['project_id'] = this.state.project_id;
			if (this.state.source_id != '') data['source_id'] = this.state.source_id;
			if (this.state.assign_rm_id != '') data['assign_to'] = this.state.assign_rm_id;
			if (this.state.type != '') data['type'] = this.state.type;
			if (this.state.is_magnet != '') data['is_magnet'] = parseInt(this.state.is_magnet);
			if (this.state.nationality != '') data['nationality'] = parseInt(this.state.nationality);
			mainData['data'] = data;
			mainData['id'] = this.state.id;
			this.setState({
				showLoader: true,
			})
			// console.log("addLeadRes XXXXXXXXXXXX",mainData);
			var addLeadRes = await updateLeadAutoAssign(mainData);

			if (addLeadRes.meta.status === 503) {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "!!! Oooops Network Error..."
				});

			} else if (addLeadRes.meta.status === 201) {

				// console.log("User Register successfully !!!");
				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "success",
					title: "Updated successfully"
				});
			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					showLoader: false,
					sweetShow: true,
					type: "error",
					title: "Something went wrong !!!"
				});
				console.log(addLeadRes.meta.message);
			}
		} else {
			this.setState({
				showLoader: false,
				error: "*Some error in the above fields or fileds are reqiured !!!",
			})
		}
	};

	// handleChange = (event) => {
	//    this.setState({ project_status: !this.state.project_status});
	// 	};

	// 	handleFocusChange = (event) => {
	//    this.setState({ is_focus: !this.state.is_focus});
	// 	};

	handleAutoUserChange = async (e) => {
		this.setState({ isLoading: true });
		let data = {
			q: e.target.value,
		};
		let resData = await autoComplete('users', data);

		// console.log("resData.dataXXXXXXXXXXXXXXXX",resData.data);
		if (resData.meta.status === 200) {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		} else if (resData.meta.status === 401) {

			cleanStorage();
			this.props.history.push("/login");

		} else {

			this.setState({ isLoading: false });
			this.setState({ userName: resData.data })
		}
	}

	handleSweet = () => {
		// console.log("in the sweet hanlde");
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();
	}

	render() {

		const { error, source, sweetShow, type, title, isLoading, source_id, userName, is_magnet, selectedVal } = this.state;
		// console.log("in the render------?>>>>>>>>>", this.state);

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<Loader show={this.state.showLoader} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6 project_name">
									<div className="form-group">
										<div className="input-group mb-3">
											<TextField
												error={this.state.isError.project_name !== '' ? 'error' : ''}
												required
												disabled
												id="project_name"
												name="project_name"
												label="Project Name"
												value={this.state.project_name ? this.state.project_name : ''}
												helperText={this.state.isError.project_name !== '' ? this.state.isError.project_name : ''}
												onChange={this.formValChange}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<BusinessRoundedIcon />
														</InputAdornment>
													),
												}}
											/>
										</div>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Select Source</InputLabel>
										<Select
											error={this.state.isError.source_id !== '' ? 'error' : ''}
											labelId="demo-controlled-open-select-label"
											value={source_id}
											onChange={this.formValChange}
											inputProps={{
												name: 'source_id',
												id: 'source_id',
											}}
											helperText={this.state.isError.source_id !== '' ? this.state.isError.source_id : ''}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{source ?
												(source.map(reg =>
													<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
												))
												:
												''
											}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Select Type</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={type}
											onChange={this.formValChange}
											inputProps={{
												name: 'type',
												id: 'type',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="assign">Assign</MenuItem>
											<MenuItem value="sourcing">Sourcing</MenuItem>
											<MenuItem value="reactivation">Reactivation</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 col-12">
									<div className="form-group">
										<Autocomplete
											id="UserData"
											getOptionSelected={(userName, value) => userName.name === value.name}
											getOptionLabel={userName => userName.name}
											options={userName}
											value={selectedVal}
											loading={isLoading}
											onChange={(_event, userName) => {
												if (userName === null) {
													this.setState({
														userName: [],
														assign_rm_id: '',
													})
												} else {
													this.setState({
														assign_rm_id: userName.id,
													})
												}

											}}
											renderInput={params => (
												<TextField
													{...params}
													label="User Name"
													fullWidth
													onChange={this.handleAutoUserChange}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{isLoading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														)
													}}
												/>
											)}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="col-md-12 p-0">
										<FormLabel component="legend">Magnet Lead</FormLabel>
									</div>
									<RadioGroup row aria-label="" name="is_magnet" onChange={this.formValChange}>
										<div className="col-6 p-0">
											<FormControlLabel value="1" control={<Radio color="primary" checked={is_magnet == "1"} />} label="Yes" />
										</div>
										<div className="col-6 p-0">
											<FormControlLabel value="0" control={<Radio color="primary" checked={is_magnet == "0"} />} label="No" />
										</div>
									</RadioGroup>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend">Nationality</FormLabel>
										<RadioGroup row aria-label="nationality" name="nationality" value={this.state.nationality} onChange={this.formValChange}>
											<div className="col-6 p-0">
												<FormControlLabel value="1" control={<Radio color="primary" checked={this.state.nationality == 1} />} label="Indian" />
											</div>
											<div className="col-6 p-0">
												<FormControlLabel value="2" control={<Radio color="primary" checked={this.state.nationality == 2} />} label="NRI" />
											</div>
										</RadioGroup>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateProjectForm}>Submit</button>
										<p className="mb-0">
											<span className="text-center text-danger small">{error != '' ? error : ''}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(AutoAssignForm);
