import React from 'react'
import CliManagement from '../component/CliManagement/CliManagement'

const CliManagementPage = () => {
    return (
        <div className="content-wrapper">
            <CliManagement />
        </div>
    )
}

export default CliManagementPage
