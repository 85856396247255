import React, { Component } from "react";
import Frag from "../../utils/Frag/Frag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashAlt,
    faEdit,
    faFilter
} from "@fortawesome/free-solid-svg-icons";
import {
    getDeleteUser,
    getUsersDataList,
    getSingleUser
} from "../../dataParser/getListUserData";
import Loader from "../common/loader/loader";
import "./usersList.css";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import SweetWarnAlert from "../../component/common/sweetAlert/sweetAlertWarning";
import Pagination from "react-js-pagination";
import UserModal from "../../component/common/modal/userModal";
import UserFilter from "../../component/filter/userFilter";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from '@material-ui/core/Tooltip';
import {
    changeDate, cleanStorage
} from "../../helper";
import Footer from "../../component/footer/footer";
import ViewCRM from "../common/action/ViewCRM";

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleUser: "",
            listDetail: -1,
            activePage: 1,
            showLoader: false,
            showFilter: false,
            sideBar: false,
            meta: props.userDetail.meta,
            data: props.userDetail.data["users"],
            mobileSideBar: false,
            sweetShow: false,
            sweetWarnShow: false,
            type: "success",
            title: "",
            modalShow: false,
            addModalShow: false
        };

        window.addEventListener("resize", this.update);
        this.fltr = React.createRef()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidMount() {
        this.update();
    }

    handleClickOutside = (e) => {
        if (this.node !== undefined && this.node !== null &&
            !this.node.contains(e.target) && this.state.showFilter &&
            e.target.className !== 'MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button'
            && e.target.className !== 'MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit'
            && e.target.className !== 'MuiAutocomplete-option' && e.target.className !== 'MuiAutocomplete-noOptions') {
            this.setState({
                showFilter: false,
            });
        }
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };
    componentDidUpdate(nextProps) {
        if (this.props !== nextProps) {
            this.setState({
                meta: nextProps.userDetail.meta,
                data: nextProps.userDetail.data["users"],
                activePage: 1
            });
        }
    }

    genericGetListData = async params => {
        this.setState({ showLoader: true });

        (async () => {
            try {
                let resData = await getUsersDataList(params);
                if (resData.meta.status === 200) {
                    this.setState({
                        meta: resData.meta,
                        data: resData.data["users"],
                        listDetail: -1,
                        showLoader: false
                    });
                    window.scrollTo(0, 0);
                    this.setState({});
                } else if (resData.meta.status === 401) {
                    cleanStorage();
                    this.props.history.push("/login");
                } else {
                    this.setState({
                        meta: resData.meta,
                        data: resData.data,
                        showLoader: false
                    });
                }
            } catch (e) {
                //...handle the error...
            }
        })();
    };

    handleChangePage = async pageNumber => {
        this.setState({
            showLoader: true,
            activePage: pageNumber
        });
        let listData = {};
        listData.size = 8;
        listData.pageId = pageNumber;
        Object.assign(listData, this.state.filters);
        this.genericGetListData(listData);
    };

    handleFilter = async filterData => {
        this.setState({ showLoader: true });
        if(filterData !== undefined){
            await this.setState({ filters: filterData});
        }
        else{
            filterData = {}
        }
        this.setState({ activePage: 1 });
        let listData = {};
        listData.size = 8;
        listData.pageId = 1;
        Object.assign(listData, {...this.state.filters}, filterData);
        this.genericGetListData(listData);
    };

    handleDeleteUser = async id => {
        this.setState({ showLoader: true });

        var deleteRes = await getDeleteUser(id);

        if (deleteRes.meta.status === 200) {
            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "success",
                title: "User Deactivated successfully!!!"
            });
        } else if (deleteRes.meta.status === 401) {
            cleanStorage();
            this.props.history.push("/login");
        } else {
            this.setState({
                showLoader: false,
                sweetShow: true,
                type: "error",
                title: deleteRes.meta.message
            });
        }
    };

    handleSingleUser = async id => {
        this.setState({ showLoader: true });
        var singleUser = await getSingleUser(id);

        if (singleUser.meta.status === 200) {
            this.setState({
                showLoader: false,
                sweetShow: false,
                singleUser: singleUser
            });
        } else if (singleUser.meta.status === 401) {
            cleanStorage();
            this.props.history.push("/login");
        } else {
            this.setState({
                showLoader: false,
                sweetShow: false,
                singleUser: singleUser
            });
        }
    };

    detailView(id) {
        if (this.state.listDetail == id) {
            this.setState({ listDetail: null });
        } else {
            this.setState({ listDetail: id });
        }
    }

    handlePopUp = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    };

    handleSweet = () => {
        this.setState({ sweetShow: !this.state.sweetShow });
        this.handleFilter();
    };

    handleSweetWarn = value => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow,
            deleteUser: value
        });
    };

    handleUpdateUser = () => {
        this.setState({
            sweetWarnShow: !this.state.sweetWarnShow
        });
        this.handleDeleteUser(this.state.deleteUser);
    };

    handleModal = () => {
        this.setState({
            modalShow: !this.state.modalShow,
            singleUser: ""
        });
    };
    handleAddUserModal=()=>{
        this.setState({
            addModalShow : !this.state.addModalShow
        });
    }
    render() {
        const { count, size, status } = this.state.meta;
        const {
            data,
            type,
            title,
            sweetShow,
            singleUser,
            sweetWarnShow
        } = this.state;
        const isDesktop = this.state.screenWidth;

        return (
            <Frag>
                {isDesktop > 991 ? (
                    <div
                        className="content-wrapper">
                        <SweetAlert
                            show={sweetShow}
                            type={type}
                            title={title}
                            changeSweet={this.handleSweet}
                        />
                        <SweetWarnAlert
                            show={sweetWarnShow}
                            changeSweet={this.handleSweetWarn}
                            message="User get deleted permanently!!!"
                            handleUpdateUser={this.handleUpdateUser}
                        />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total Users : </span>
                                <span className="">{status === 400 ? "0" : count}</span>
                            </div>
                            <div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div
                                    className={
                                        "filterMenu" +
                                        " " +
                                        (this.state.showFilter ? "filterMenuShow" : "")
                                    }
                                    ref={node => {
                                        this.node = node;
                                    }}
                                >
                                    <UserFilter
                                        filterData={this.handleFilter}
                                        fltr={this.fltr}
                                        changeFilter={this.handlePopUp}
                                    />
                                </div>
                            </div>
                            {window.$role === "admin" ||
                                window.$role === "saleshead" ||
                                window.$role === "hrhead" ||
                                window.$role === "magnetadmin" ||
                                window.$role === "magnetpnl" ? (
                                    <div
                                        className="assign_magnet filter"
                                        onClick={this.handleAddUserModal}
                                    >
                                        <Tooltip title="Add User">
                                            <AddCircleIcon />
                                        </Tooltip>
                                    </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <Loader show={this.state.showLoader} />
                        {status === 200 && this.state.data.length ? (
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((userData, index) => (
                                            <div className="MainList" key={index}>
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1">
                                                            <div className="col-12">
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">ID</span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.id || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">Name</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {userData?.name || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Email
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.email || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Mobile Number
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.phone || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">Role</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {userData?.Role?.name || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Max Lead Count
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.UserInfo?.max_lead || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead text-capitalize">
                                                                                <span className="text-muted">City</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.UserInfo?.city || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Deleted By
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {userData?.deletedby?.name ||  "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Is Active
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.is_active === true
                                                                                        ? "Active"
                                                                                        : "In-active"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Created Date
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.created_at ? changeDate(userData.created_at) : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Updated Date
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.updated_at ? changeDate(userData.updated_at) : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Region
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.UserInfo?.Region?.name || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12 mb-1">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Parent Name
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.ParentUser.map(e => e?.ParentUserInfo.name).join(', ') || '-'}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12 col-12 mb-1">
                                                                        <div className="action_icons">
                                                                            <a
                                                                                className="ProjectDetail Action"
                                                                                data-toggle="tooltip"
                                                                                data-placement="bottom"
                                                                                title="Update User"
                                                                                onClick={e => {
                                                                                    this.handleModal();
                                                                                    this.handleSingleUser(userData.id);
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faEdit}
                                                                                    className="text-white"
                                                                                />
                                                                            </a>
                                                                            {window.$role === "admin" ||
                                                                                window.$role === "magnetadmin" ||
                                                                                window.$role === "magnetpnl" ? (
                                                                                <a
                                                                                    className="deleteUser Action"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="bottom"
                                                                                    title="Deactive User"
                                                                                    onClick={value => {
                                                                                        this.handleSweetWarn(userData.id);
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrashAlt}
                                                                                        className="text-white"
                                                                                    />
                                                                                </a>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {
                                                                                window.$role === "admin" &&
                                                                                <ViewCRM userId={userData.id} location={this.props.location}/>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="main_area_display shadow card p-3">
                                <h5>No User Found !!!</h5>
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className="content-wrapper">
                        <SweetAlert
                            show={sweetShow}
                            type={type}
                            title={title}
                            changeSweet={this.handleSweet}
                        />
                        <SweetWarnAlert
                            show={sweetWarnShow}
                            changeSweet={this.handleSweetWarn}
                            message="Are you sure you want to Deactivate user!!!"
                            handleUpdateUser={this.handleUpdateUser}
                        />
                        <div className="p-3 leadMainHeader">
                            <div className="headerCount">
                                <span className="totalCount">Total Users : </span>
                                <span className="">{status === 400 ? "0" : count}</span>
                            </div>
                            <div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div
                                    className={
                                        "filterMenu" +
                                        " " +
                                        (this.state.showFilter ? "filterMenuShow" : "")
                                    }
                                    ref={node => {
                                        this.node = node;
                                    }}
                                >
                                    <UserFilter
                                        filterData={this.handleFilter}
                                        fltr={this.fltr}
                                        changeFilter={this.handlePopUp}
                                    />
                                </div>
                            </div>
                            {window.$role === "admin" ||
                                window.$role === "saleshead" ||
                                window.$role === "hrhead" ||
                                window.$role === "magnetadmin" ||
                                window.$role === "magnetpnl" ? (
                                <div
                                    className="assign_magnet filter"
                                    onClick={this.handleAddUserModal}
                                >
                                    <Tooltip title="Add User">
                                        <AddCircleIcon />
                                    </Tooltip>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <Loader show={this.state.showLoader} />
                        {status === 200 && this.state.data.length ? (
                            <div className="listContainer">
                                <div className="container-fluid">
                                    <div className="row">
                                        {data.map((userData, index) => (
                                            <div className="MainList" key={index}>
                                                <div className="container-fluid">
                                                    <div className="ListContent card mb-3">
                                                        <div className="row mb-1">
                                                            <div className="col-12">
                                                                <div className="row mb-1">
                                                                    <div className="col-12 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">ID</span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.id || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">Name</span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {userData?.name || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Email
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 pr-0 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.email ||  "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Mobile Number
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-6 col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.phone || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">Role</span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {userData?.Role?.name || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Max Lead Count
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.UserInfo?.max_lead || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead text-capitalize">
                                                                                <span className="text-muted">City</span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.UserInfo?.city || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Is Active
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.is_active === true
                                                                                        ? "Active"
                                                                                        : "In-active"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Deleted By
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-capitalize valueText">
                                                                                    {userData?.deletedby?.name ||  "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Created Date
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData?.created_at ? changeDate(userData.created_at) : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 pr-0 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Updated Date
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                    {userData.updated_at !== null
                                                                                        ? changeDate(userData.updated_at)
                                                                                        : "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 pr-0 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Region
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                {userData?.UserInfo?.Region?.name || "-"}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 mb-2">
                                                                        <div className="row">
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="text-muted">
                                                                                    Parent Name
                                                                                    </span>
                                                                            </div>
                                                                            <div className="col-12 fold-lead">
                                                                                <span className="valueText">
                                                                                {userData?.ParentUser.map(e => e?.ParentUserInfo.name).join(', ') || '-'}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="action_box">
                                                            <a
                                                                className="ProjectDetail Action"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Update User"
                                                                onClick={e => {
                                                                    this.handleModal();
                                                                    this.handleSingleUser(userData.id);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    className="text-white"
                                                                />
                                                            </a>
                                                            {window.$role === "admin" ||
                                                                window.$role === "magnetadmin" ||
                                                                window.$role === "magnetpnl" ? (
                                                                <a
                                                                    className="deleteUser Action"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Delete User"
                                                                    onClick={value => {
                                                                        this.handleSweetWarn(userData.id);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashAlt}
                                                                        className="text-white"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {
                                                                window.$role === "admin" &&
                                                                <ViewCRM userId={userData.id} location={this.props.location}/>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row justify-content-end">
                                        {count > 8 ? (
                                            <div className="paginationBlock">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={size}
                                                    totalItemsCount={count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handleChangePage}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="main_area_display shadow card p-3">
                                <h5>No User Found !!!</h5>
                            </div>
                        )}
                    </div>
                )}
                <Footer expand={this.props.expand} />
                {singleUser.meta && (
                    <UserModal
                        show={this.state.modalShow}
                        changeModal={this.handleModal}
                        fromType="updateUser"
                        getList={this.handleFilter}
                        singleUserData={singleUser}
                    />
                )}
            <UserModal show={this.state.addModalShow} getList= {this.handleFilter} fromType="addUser" changeModal={this.handleAddUserModal}/>
            </Frag>
        );
    }
}

export default UserList;
