import React from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function PopAlert(props) {
  const {type, msg, open, handleClose, hideTime} = props
  const classes = useStyles();
  if(!open){
    return <></>
  }
  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={hideTime ? hideTime : 3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type ? type : 'success'}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default function PopUpAlert(props){
  return ReactDOM.createPortal(
      <PopAlert {...props}/>
      , document.getElementsByTagName('body')[0]
  )
}