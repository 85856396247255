import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import TermsCondition from "../form/termsAndCondition.js";


class TermsAndCondition extends Component {
    render() {
        const FROMS_TYPES = {
			"termsCondition" : TermsCondition,
		}
		const Form_name = FROMS_TYPES[this.props.fromType];

        return (
            <Frag>
 				<Modal
 				  show={this.props.show}
 				  onHide={this.props.changeModal}
			      size="lg"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
                  backdrop="static"
			    >
			      <Modal.Body>
			        <Form_name changeModal={this.props.changeModal} formData={this.props.formData}/>
			      </Modal.Body>
			    </Modal>
            </Frag>
        );
    };
};

export default TermsAndCondition;
