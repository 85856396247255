import React, {Component} from 'react';
import {Modal,} from 'react-bootstrap';
import {magnet_admin_bcc_mail_id} from "../../config/config.js"
import Frag from "../../utils/Frag/Frag.js";
import "./detailLead.css";
import {getEmailTamplateData} from "../../dataParser/magnetUser";
import Loader from "../../component/common/loader/loader";
import SweetAlert from "../../component/common/sweetAlert/sweetAlertSuccess";
import {getUpdateCpUser} from "../../dataParser/getCpUserData";
import ModalAssignRM from "../../component/common/modal/modal";
import BrokenImageIcon from '@material-ui/icons/BrokenImage'
import AWS from 'aws-sdk';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {backetName} from "../../config/colorConstant";
import {changeDate, cleanStorage} from "../../helper";

var bucketName = backetName;
AWS.config.region = 'ap-south-1';

AWS.config.update({
      accessKeyId: 'AKIA4JFQSO265PU23DHK',
      secretAccessKey: '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'
  });


var bucket = new AWS.S3({
    params: { Bucket: bucketName }
});

const DEF_ALTERNATE_BUSINESS = ['Resale', 'Rental']

class CpUserDetail extends Component {

	constructor(props) {
    	super(props);
    	this.state = {
      		showLoader : false,
		    photoIndex: 0,
      		modalCpUpdateShow : false,
      		user_img : null,
      		adhar_img : null,
      		pan_img : null,
      		is_approved : '',
      		assign_rm : '',
      		sweetShow:false,
            type : "success",
            title : "",
    	};
  	}

    componentDidMount(){

      let cpBasic = this.props.singleUserData.data;
      let CpData = this.props.singleUserData.data.UserInfo;

      if(CpData !== null){
	      this.setState({
	      	email : cpBasic.email,
	        user_id : cpBasic.id  === null ? "" : cpBasic.id,
	        assign_rm : cpBasic.assign_rm === null ? "" : cpBasic.assign_rm,
	        is_approved : cpBasic.is_approved  === null ? "" : cpBasic.is_approved,
	      })
	      if(CpData.profile_pic !== null){
	      	this.handleGetAWSImage(CpData.profile_pic,"user_img")
	      }
	      if(CpData.adharcard_img !== null){
	      	this.handleGetAWSImage(CpData.adharcard_img,"adhar_img")
	      }

	      if(CpData.pancard_img !== null){
	      	this.handleGetAWSImage(CpData.pancard_img,"pan_img")
	      }
	   }else{
	      this.setState({
	      	email : cpBasic.email,
	        is_approved : cpBasic.is_approved  === null ? "" : cpBasic.is_approved,
	      })
	   }
    }

  	handleEmail = async () =>{

        var emailData = {};
        emailData.mail_details = {};

        emailData.mail_details.to = this.state.email;
        emailData.mail_details.cc = this.state.assign_rm.email;
        emailData.mail_details.bcc = magnet_admin_bcc_mail_id;
        emailData.mail_details.subject = 'Welcome To Homesfy Family';
        emailData.user_id = this.state.user_id;
        emailData.email_type = "MAGNET_APPROVAL";

        var resData = await getEmailTamplateData(emailData);

           if(resData.meta.status === 503){

                this.setState({
                    showLoader:false,
                    sweetShow:true,
                   type : "error",
                   title : "!!! Oooops Network Error..."
                });

           }else if(resData.meta.status === 200){

               console.log("Mail send successfully !!!");
           }else{
                console.log(resData.meta.message);
           }
    }

    handleSubmit = async (e) =>{

    	await this.setState({
  			is_approved : true,
  		});

    	let addUserData = {};
       	let updateCpData = {};
       	addUserData.data = {};
       	addUserData.data.basic = {};

    	addUserData.data.basic["is_approved"] = this.state.is_approved === true ? 1 : 0;


		addUserData.data = JSON.stringify(addUserData.data);

       	updateCpData.data = addUserData;
       	updateCpData.id = this.state.user_id;

       	if(this.state.assign_rm != ""){

	       	this.setState({showLoader:true});
	           var addLeadRes = await getUpdateCpUser(updateCpData);

	           if(addLeadRes.meta.status === 200){

	           		this.setState({
	                    showLoader:false,
	                    sweetShow:true,
	                   type : "success",
	                   title : "Approved"
	                });

	                this.handleEmail();

	           }else if(addLeadRes.meta.status === 401){

	               cleanStorage();
	               this.props.history.push("/login");

	           }else{

	               this.setState({
	                    showLoader:false,
	                   sweetShow:true,
	                   type : "error",
	                   title : "Something went wrong !!!"
	                });
	           }
	    }else{

	    	this.setState({
            	showLoader:false,
               	modalCpUpdateShow:true,
               	is_approved:false,
            });
	    }

    }

    handleUpdateCpModal=()=>{
      this.setState({
      	modalCpUpdateShow : !this.state.modalCpUpdateShow,
      });
    }

    handleSweet=()=>{
        this.setState({sweetShow : !this.state.sweetShow});
    }

    handleGetAWSImage = (img_key,img_name) =>{

    	bucket.getObject({

              Bucket: bucketName,
              Key: img_key

            },function(err,data){
                if (err) {
                    console.log(err);

                } else {
                    var str = data.Body.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
                    var image = btoa(str).replace(/.{76}(?=.)/g,'$&\n');

                    var elem = "data:image/png;base64,"+image;
                    this.setState({[img_name]:elem});
                }
            }.bind(this)

        );
    }

    render() {

    	const {showLoader,sweetShow,type,title,isOpen,photoIndex,isOpenPan,isOpenUser} = this.state;
    	let singleUser = this.props.singleUserData;
    	let singleUserMeta = singleUser.meta;
    	let singleUserData = singleUser.data;
		if(singleUserData && singleUserData.UserInfo){
			singleUserData.UserInfo.alternate_business_us =  typeof singleUserData.UserInfo.alternate_business_us === 'string' ? (singleUserData.UserInfo.alternate_business_us === "Both" ? DEF_ALTERNATE_BUSINESS : (DEF_ALTERNATE_BUSINESS.includes(singleUserData.UserInfo.alternate_business_us) ? [singleUserData.UserInfo.alternate_business_us] : singleUserData.UserInfo.alternate_business_us !=="None" ? JSON.parse(singleUserData.UserInfo.alternate_business_us) : [])) : singleUserData.UserInfo.alternate_business_us
			singleUserData.UserInfo.deal_business_types = typeof singleUserData.UserInfo.deal_business_types === 'string' ? JSON.parse(singleUserData.UserInfo.deal_business_types) : singleUserData.UserInfo.deal_business_types
			singleUserData.UserInfo.genrate_leads = typeof singleUserData.UserInfo.genrate_leads === 'string' ? JSON.parse(singleUserData.UserInfo.genrate_leads) : singleUserData.UserInfo.genrate_leads
			singleUserData.UserInfo.plateform_present_in = typeof singleUserData.UserInfo.plateform_present_in === 'string' ? JSON.parse(singleUserData.UserInfo.plateform_present_in) : singleUserData.UserInfo.plateform_present_in
		}
    	const adhar_images = [
			this.state.adhar_img,
		];

		const pan_images = [
			this.state.pan_img,
		];

		const profile_images = [
			this.state.user_img,
		];

        return (
            <Frag>
 				<Modal
 				  show={this.props.show}
 				  onHide={this.props.changeModal}
			      size="xl"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			          Magnet CP detail
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
            		<Loader show={this.state.showLoader}/>
		            <SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet}/>
			      	{singleUserMeta.status == 200 ?
			      	<div className="detailProject">
			      		<div className="row">
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">ID : </span>
		                                {singleUserData.id !=null  ?
		                                	<span className="valueText">{singleUserData.id}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Name : </span>
		                                {singleUserData.name !=null ?
		                                	<span className="valueText text-capitalize">{singleUserData.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Email : </span>
		                                {singleUserData.email !=null ?
		                                	<span className="valueText">{singleUserData.email}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Mobile Number : </span>
		                                {singleUserData.phone !=null ?
		                                	<span className="valueText text-capitalize">{singleUserData.phone}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      		</div>
			      		<div className="row">
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Role : </span>
		                                {singleUserData.Role !=null ?
		                                	<span className="valueText text-capitalize">{singleUserData.Role.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Daily Lead Limit : </span>
		                                {singleUserData.UserInfo != null ?
		                                	<span className="valueText text-capitalize">{singleUserData.UserInfo.max_lead != null && singleUserData.UserInfo.max_lead}</span>
		                                	:
		                                	<span className="font-small text-capitalize">-</span>
		                            	}
		                            </div>
		                        </div>
	                        </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Rating : </span>
		                                {singleUserData.rating !=null ?
		                                	<span className="valueText text-capitalize">{singleUserData.rating}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">City : </span>
		                                {singleUserData.UserInfo !=null ?
		                                	<span className="valueText text-capitalize">{singleUserData.UserInfo.city !=null && singleUserData.UserInfo.city.toLowerCase()}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      		</div>
			      		<div className="row">
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-s m-12 col-12">
		                                <span className="font-small text-muted">Last LogIn : </span>
		                                {singleUserData.last_log_in !=null ?
		                                	<span className="valueText">{changeDate(singleUserData.last_log_in)}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Region : </span>
		                                {singleUserData.UserInfo !== null ? 
		                                	<span className="text-capitalize valueText">{singleUserData.UserInfo.Region !== null &&  singleUserData.UserInfo.Region.name}</span>
		                                	:
		                                	<span className="text-capitalize valueText">-</span>
		                                }	
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Deleted By : </span>
		                                {singleUserData.deletedby !=null ?
		                                	<span className="valueText text-capitalize">{singleUserData.deletedby.name}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Online Status : </span>
		                                {singleUserData.is_online !=null ?
		                                	<span className="valueText">{singleUserData.is_online}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
			      		</div>
			      		<div className="row">
			      			<div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Active Status : </span>
		                                {singleUserData.is_active !=null ?
		                                	<span className="valueText">{singleUserData.is_active === true ? 'Active' : 'In-active'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Approved Status : </span>
		                                {singleUserData.is_approved !=null ?
		                                	<span className="valueText">{singleUserData.is_approved === true ? 'Done' : 'Pending'}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Created Date : </span>
		                                {singleUserData.created_at !=null ?
		                                	<span className="valueText text-capitalize">{changeDate(singleUserData.created_at)}</span>
		                                	:
		                                	<span className="valueText">-</span>
		                                }
		                            </div>
		                        </div>
		                    </div>
		                    <div className="col-md-3 col-sm-6 col-12  mb-2">
		                        <div className="row">
		                            <div className="col-md-12 col-sm-12 col-12">
		                                <span className="font-small text-muted">Updated Date : </span>
		                                <span className="valueText text-capitalize">{singleUserData.updated_at != null ? changeDate(singleUserData.updated_at) : '-'}</span>
		                            </div>
		                        </div>
		                    </div>
			      		</div>
			      		{singleUserData.UserInfo != null &&
			      		<Frag>
				      		<div className="row">
				      			<div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">PAN Number : </span>
			                                {singleUserData.UserInfo !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.pancard_no !=null && singleUserData.UserInfo.pancard_no}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Aadhar Number : </span>
			                                {singleUserData.UserInfo !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.adharcard_no !=null ? singleUserData.UserInfo.adharcard_no : '-'}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">GST Number : </span>
			                                {singleUserData.UserInfo !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.gst_no !=null ? singleUserData.UserInfo.gst_no : '-'}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">RERA Number : </span>
			                                {singleUserData.UserInfo.rera_no !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.rera_no}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
				      		</div>
				      		<div className="row">
				      			<div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Total Experience : </span>
			                                {singleUserData.UserInfo.total_experience !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.total_experience} Years</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Devote Time : </span>
			                                {singleUserData.UserInfo.devote_time !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.devote_time}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-s m-12 col-12">
			                                <span className="font-small text-muted">Pin Code : </span>
											<span className="valueText">{singleUserData?.UserInfo?.pincode || "-"}</span>
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Deal In Business : </span>
			                                {singleUserData.UserInfo.deal_business_types !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.deal_business_types.map((value,i) => value +", ")}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
				      		</div>
				      		<div className="row">
				      			<div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Team : </span>
			                                {singleUserData.UserInfo.have_team !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.have_team === true ? "Yes" : "No"}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">List Properties : </span>
			                                {singleUserData.UserInfo.list_properties !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.list_properties === 1 ? "Yes" : "No"}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Own Real State Business : </span>
			                                {singleUserData.UserInfo.own_state_business !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.own_state_business === true ? "Yes" : "No"}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Lead Generation : </span>
			                                {singleUserData.UserInfo.genrate_leads !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.genrate_leads.map((value,i) => value +", ")}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
				      		</div>
				      		<div className="row">
				      			<div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Association Member : </span>
			                                {singleUserData.UserInfo.assoc_member !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.assoc_member === true ? "Yes" : "No"}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Office Location : </span>
			                                {singleUserData.UserInfo.office_location !=null ?
			                                	<span className="valueText text-capitalize">{singleUserData.UserInfo.office_location}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Plateform Present : </span>
			                                {singleUserData.UserInfo.plateform_present_in !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.plateform_present_in.map(value => value +", ")}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-3 col-sm-6 col-12  mb-2">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Operate Business : </span>
			                                {singleUserData.UserInfo.operate_business !=null ?
			                                	<span className="valueText">{singleUserData.UserInfo.operate_business}</span>
			                                	:
			                                	<span className="valueText">-</span>
			                                }
			                            </div>
			                        </div>
			                    </div>
				      		</div>
				      		{singleUserData.UserInfo.UserServiceRm != null &&
					      		<div className="row">
					      			<div className="col-md-3 col-sm-6 col-12  mb-2">
				                        <div className="row">
				                            <div className="col-md-12 col-sm-12 col-12">
				                                <span className="font-small text-muted">RM Name : </span>
				                                <span className="valueText text-capitalize">{singleUserData.UserInfo?.UserServiceRm?.name != null ? singleUserData.UserInfo?.UserServiceRm?.name : '-'}</span>
				                            </div>
				                        </div>
				                    </div>
				                    <div className="col-md-3 col-sm-6 col-12  mb-2">
				                        <div className="row">
				                            <div className="col-md-12 col-sm-12 col-12">
				                                <span className="font-small text-muted">RM Email : </span>
				                                <span className="valueText">{singleUserData.UserInfo?.UserServiceRm?.email != null ? singleUserData.UserInfo?.UserServiceRm?.email : '-'}</span>
				                            </div>
				                        </div>
			                        </div>
			                        <div className="col-md-3 col-sm-6 col-12  mb-2">
				                        <div className="row">
				                            <div className="col-md-12 col-sm-12 col-12">
				                                <span className="font-small text-muted">RM Mobile : </span>
				                                <span className="valueText text-capitalize">{singleUserData.UserInfo?.UserServiceRm?.phone != null ? singleUserData.UserInfo?.UserServiceRm?.phone : '-'}</span>
				                            </div>
				                        </div>
			                        </div>
									<div className="col-md-3 col-sm-6 col-12  mb-2">
										<div className="row">
											<div className="col-md-12 col-sm-12 col-12">
												<span className="font-small text-muted">Email Status : </span>
												{singleUserData.email_verified !=null ?
													<span className="valueText">{singleUserData.email_verified === true ? 'Done' : 'Pending'}</span>
													:
													<span className="valueText">-</span>
												}
											</div>
										</div>
									</div>
								</div>
				      		}
	                        <div className="row">
			                    <div className="col-md-3 col-sm-6 col-12">
			                        <div className="row">
			                            <div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Aadhar Image</span>
		                        		</div>
			                            <div className="col-md-12 col-sm-12 col-12 personal_image">
					                    	{this.state.adhar_img !== null ?
											    <img src={this.state.adhar_img} width="400" height="150" alt="aadhar_img" onClick={() => this.setState({ isOpen: true })}/>
											    :
											    <div className="text-dark">
											    	<BrokenImageIcon/>
											    </div>
											}
										    {isOpen && (
									          <Lightbox
									            mainSrc={adhar_images[photoIndex]}
									            onCloseRequest={() => this.setState({ isOpen: false })}
									          />
									        )}
		                        		</div>
		                        	</div>
		                        </div>
		                        <div className="col-md-3 col-sm-6 col-12">
			                        <div className="row">
			                        	<div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Pancard Image</span>
		                        		</div>
			                        	<div className="col-md-12 col-sm-12 col-12 personal_image">
					                    	{this.state.pan_img !== null ?
											    <img src={this.state.pan_img} width="400" height="150" alt="pan_img" onClick={() => this.setState({ isOpenPan: true })}/>
											    :
											    <div className="text-dark" onClick={this.handleGetAWSImage}>
											    	<BrokenImageIcon/>
											    </div>
											}
											{isOpenPan && (
									          <Lightbox
									            mainSrc={pan_images[photoIndex]}
									            onCloseRequest={() => this.setState({ isOpenPan: false })}
									          />
									        )}
		                        		</div>
	                        		</div>
		                        </div>
		                        <div className="col-md-3 col-sm-6 col-12">
			                        <div className="row">
			                        	<div className="col-md-12 col-sm-12 col-12">
			                                <span className="font-small text-muted">Profile Image</span>
		                        		</div>
			                        	<div className="col-md-12 col-sm-12 col-12 personal_image">
					                    	{this.state.user_img !== null ?
											    <img src={this.state.user_img} width="400" height="150" alt="user_img" onClick={() => this.setState({ isOpenUser: true })}/>
											    :
											    <div className="text-dark">
											    	<BrokenImageIcon/>
											    </div>
											}
											{isOpenUser && (
									          <Lightbox
									            mainSrc={profile_images[photoIndex]}
									            onCloseRequest={() => this.setState({ isOpenUser: false })}
									          />
									        )}
		                        		</div>
	                        		</div>
		                        </div>
				      		</div>
							<ModalAssignRM show={this.state.modalCpUpdateShow} changeModal={this.handleUpdateCpModal} userId ={this.state.user_id} userEmail={this.state.email}/>
			      		</Frag>

				      	}
			      	</div>
			      	:
			      		<div className="row">
			      			<div className="col-md-12">
			      				<span className="mr-2">Couldn't get detail, Please try again !!!</span>
		      				</div>
			      		</div>
			      	}
			      </Modal.Body>

			    </Modal>
            </Frag>
        );
    };
};

export default CpUserDetail;
