import React, {Component} from "react";
import "./filter.css";
import Frag from "../../utils/Frag/Frag";
import {withRouter} from "react-router-dom";
// import { Multiselect } from 'multiselect-react-dropdown';
// import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {getLeadSourceData,autoComplete} from "../../dataParser/commomDataApi.js";
import {getAutoCompleteProject,getRegion} from "../../dataParser/getProjectData";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
// import {  MuiPickersUtilsProvider,  KeyboardDatePicker,} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { cleanStorage } from "../../helper";
// import InputAdornment from '@material-ui/core/InputAdornment';
// import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';

class AutoAssignFilter extends Component { 

	constructor(props) {
    	super(props);
		this.state = {
            source : [],
            option: [],
            userName: [],
            assign_rm_id : '',
            project_id : '',
            source_id : '',
            project_status : '',
            is_magnet : '',
            nationality : '',
            region : [] ,
            region_id : [] ,
            status : '',
            type : '',
		};
	}

	async componentDidMount(){

		var allSourceData = await getLeadSourceData();
		// console.log("in the auto filter",allSourceData);
		if(allSourceData.meta.status === 200){
        	this.setState({source: allSourceData.data})
		}else{
        	this.setState({source: []})
		}

		var regionData = await getRegion();
		this.setState({region : regionData})
	}

	
	handleAutoChange = async (e) =>{
		this.setState({isLoading:true});
		let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);

	    var projectListData = await getAutoCompleteProject(value);

		// console.log("projectListData.dataXXXXXXXXXXXXXXXX",projectListData.data);
	    if(projectListData.meta.status === 200){

			this.setState({isLoading:false});
	        this.setState({option : projectListData.data})
	    }else if(projectListData.meta.status === 401){
    		
    		cleanStorage();
    		this.props.history.push("/login");
    		
    	}else{

			this.setState({isLoading:false});
	        this.setState({option : projectListData})
	    }

	}

	handleAutoUserChange = async (e) =>{
        this.setState({isLoading:true});
        let data = {
                        q:e.target.value,
                    };
        let resData = await autoComplete('users',data);

        // console.log("resData.dataXXXXXXXXXXXXXXXX",resData.data);
        if(resData.meta.status === 200){

            this.setState({isLoading:false});
            this.setState({userName : resData.data})
        }else if(resData.meta.status === 401){
            
            cleanStorage();
            this.props.history.push("/login");
            
        }else{

            this.setState({isLoading:false});
            this.setState({userName : resData.data})
        }
    }

	formValChange = event => {
		this.setState({[event.target.name]: event.target.value});
  	};

  	applyFilter= ()=>{

    	let filterData = {};

		if(this.state.project_id != ''){
        	
        	filterData["ids"] = this.state.project_id;
    	}

    	if(this.state.assign_rm_id != ''){
        	
        	filterData["assign_to"] = this.state.assign_rm_id;
    	}

    	if(this.state.source_id != ''){
        	
        	filterData["source_id"] = this.state.source_id;
    	}

    	if(this.state.is_magnet != ''){
        	
        	filterData["is_magnet"] = parseInt(this.state.is_magnet);
    	}

    	if(this.state.nationality != ''){
        	
        	filterData["nationality"] = (this.state.nationality);
    	}

    	if(this.state.region_id != ''){
        	
        	filterData["region_ids"] = (this.state.region_id);
    	}

    	if(this.state.status != ''){
        	
        	filterData["status"] = parseInt(this.state.status);
    	}

    	if(this.state.type != ''){
        	
        	filterData["type"] = this.state.type;
    	}
	
    	// this.setState({activeClear : false});
    	this.props.changeFilter();
        // console.log("=== state of the filter ====",filterData);
        this.props.filterData(filterData);  

    }

  	resetFilter = () =>{

  		this.setState({
			autoKey: !this.state.autoKey,
            project_name : '',
            project_id : '',
            assign_rm_id : '',
            source_id : '',
            is_magnet : '',
            nationality : '',
            region_id : [] ,
            status : '',
            type : '',
  		})
  	}

	render(){

		const {option,autoKey,isLoading,source_id,source,userName,is_magnet,nationality,region,region_id,status,type} = this.state;
		// console.log("Project ==== FilterXXXXXXXXXXXX")

		return(

			<Frag>
				<div className="container filter_lead filterMenuCard pt-3 p-0">
					<div className="row filterRow">
						<div className="col-lg-6 col-sm-6 col-6">
							<div className="form-group">
								<Autocomplete
								  key={autoKey} 
							      id="projectData"
							      getOptionSelected={(option, value) => option.name === value.name}
							      getOptionLabel={option => option.name}
							      options={option}
							      loading={isLoading}
							      onChange={(_event, option) => {
							        if (option === null) {
							        	this.setState({
							        		option: [],
								        	project_id : '',
							        	})
							        }else{
								        var projectId = option.id;
								        this.setState({
								        	project_id : projectId,
								        })
							        }

							      }}
							      renderInput={params => (
							        <TextField
							          {...params}
							          label="Project Name"
							          fullWidth
							          onChange={this.handleAutoChange}
							          InputProps={{
							            ...params.InputProps,
							            endAdornment: (
							              <React.Fragment>
							                {isLoading ? (
							                  <CircularProgress color="inherit" size={20} />
							                ) : null}
							                {params.InputProps.endAdornment}
							              </React.Fragment>
							            )
							          }}
							        />
							      )}
							    />	
								<span id="firstName-error" className="error invalid-feedback">Please enter First Name</span>
							</div>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
					        <Autocomplete
					          key={autoKey} 		
						      id="UserData"
						      getOptionSelected={(userName, value) => userName.name === value.name}
						      getOptionLabel={userName => userName.name}
						      options={userName}
						      loading={isLoading}
						      onChange={(_event, userName) => {
						        if (userName === null) {
						        	this.setState({
						        		userName: [],
							        	assign_rm_id : '',
						        	})
						        }else{
							        this.setState({
							        	assign_rm_id : userName.id,
							        })
						        }

						      }}
						      renderInput={params => (
						        <TextField
						          {...params}
						          label="User Name"
						          fullWidth
						          onChange={this.handleAutoUserChange}
						          InputProps={{
						            ...params.InputProps,
						            endAdornment: (
						              <React.Fragment>
						                {isLoading ? (
						                  <CircularProgress color="inherit" size={20} />
						                ) : null}
						                {params.InputProps.endAdornment}
						              </React.Fragment>
						            )
						          }}
						        />
						      )}
						    />	
						</div>
					{/* 
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Select Source</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={source_id}
						          onChange={this.formValChange}
						          inputProps={{
						            name: 'source_id',
						            id: 'source_id',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          {source ?
			    						(source.map(reg=>
					          				<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
		    							))   	
								      	:
								      	''
								    }  	
						        </Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Magnet Lead</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={is_magnet}
						          onChange={this.formValChange}
						          inputProps={{
						            name: 'is_magnet',
						            id: 'is_magnet',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Yes</MenuItem>
									<MenuItem value="0">No</MenuItem>
						        </Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Nationality</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={nationality}
						          onChange={this.formValChange}
						          inputProps={{
						            name: 'nationality',
						            id: 'nationality',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">Indian</MenuItem>
									<MenuItem value="2">NRI</MenuItem>
						        </Select>
							</FormControl>
						</div>
					*/}
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Region</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={region_id}
						          onChange={this.formValChange}
						          inputProps={{
						            name: 'region_id',
						            id: 'region_id',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          {region ?
			    						(region.map(reg=>
					          				<MenuItem value={reg.id} key={reg.id}>{reg.name}</MenuItem>
		    							))   	
								      	:
								      	''
								    }  	
						        </Select>
							</FormControl>
						</div>
					{/* 
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={status}
						          onChange={this.formValChange}
						          inputProps={{
						            name: 'status',
						            id: 'status',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="1">ON</MenuItem>
									<MenuItem value="0">OFF</MenuItem>
						        </Select>
							</FormControl>
						</div>
						<div className="col-lg-6 col-sm-6 col-6 mb-3">
							<FormControl>
							    <InputLabel id="demo-controlled-open-select-label">Type</InputLabel>
						        <Select
						          labelId="demo-controlled-open-select-label"
						          value={type}
						          onChange={this.formValChange}
						          inputProps={{
						            name: 'type',
						            id: 'type',
						          }}
						        >
						          <MenuItem value="">
						            <em>None</em>
						          </MenuItem>
						          	<MenuItem value="assign">Assign</MenuItem>
									<MenuItem value="sourcing">Sourcing</MenuItem>
									<MenuItem value="reactivation">Reactivation</MenuItem>
						        </Select>
							</FormControl>
						</div>
					 */}
					</div>
					<div className="col-md-12 bgCommon">
						<div className="row">
							<div className="col-sm-6 text-right col-6 mt-3">
								<div className="form-group">
									<button type="submit" className="btn btn-success" onClick={this.applyFilter}>Apply</button>
								</div>
							</div>
							<div className="col-sm-6 col-6 mt-3">	
								<div className="form-group">
									<button type="submit" className="btn btn-danger" onClick={this.resetFilter}>Reset</button>
								</div>
							</div>	
						</div>	
					</div>	
				</div>	
			</Frag>
		);
	};
};

export default withRouter(AutoAssignFilter)
