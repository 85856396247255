import React, { Component } from "react";

import AutoAssignLead from "../../component/autoAssignLead/autoAssignList";
import { getLeadAutoAssignList } from "../../dataParser/getListData.js";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";
import { getAllProjectData } from "../../dataParser/getProjectData";
import { cleanStorage } from "../../helper";

class AutoAssignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: "",
      showLoader: false
    };
  }

  async componentDidMount() {
    this.setState({ showLoader: true });

    var userData = {};
    userData.size = 8;
    userData.pageId = 1;
    userData.is_active = 1;
    // userData.filters = [];

    (async () => {
      try {
        // let resData = await getLeadAutoAssignList(userData);

        let resData = await getAllProjectData(userData);
        if (resData.meta.count >= 0 && resData.meta.status === 200) {
          this.setState({
            userDetail: resData,
            showLoader: false
          });
          window.scrollTo(0, 0);
          this.setState({});
        } else if (resData.meta.status == 401) {
          cleanStorage();
          this.props.history.push("/login");
        } else {
          this.setState({
            userDetail: resData,
            showLoader: false
          });
        }
      } catch (e) {
        //...handle the error...
      }
    })();
  }

  render() {
    // console.log("sidebarrrrrr");
    // console.log(this.state.mobileSideBar);

    return (
      <Frag>
        <Loader show={this.state.showLoader} />
        {this.state.userDetail.meta && (
          <AutoAssignLead
            expand={this.props.sideBar}
            userDetail={this.state.userDetail}
          />
        )}
      </Frag>
    );
  }
}

export default AutoAssignPage;
