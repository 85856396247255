import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { colorConfig } from "../../config/colorConstant";
import { APP_BASE_URL } from "../../config/serverKey.js";
import LeadListing from "./LeadListing";

class genericSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSideBar: false,
      leadCount: 0,
      lead: {},
      client_id: "",
      loggedUserRole: window.$user.role,
      loggedUser: window.$user
    };
  }

  async componentDidMount() {
    let listData ={};
    listData.size = 24;
    listData.pageId = 1;

     if(this.props.location.state  !== undefined && this.props.location.state.client_id !== undefined){
      let client_id = this.props.location.state.client_id
        this.setState({
          client_id: client_id,
        });
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Homesfy Platform</title>
          <meta
            name="description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta name="theme-color" content={colorConfig['all_leads']} />
          <meta property="og:url" content={APP_BASE_URL} />
          <meta property="og:title" content="Magnet CRM" />
          <meta
            property="og:description"
            content="Magnet is Homesfy initiative to join brokers to Homesfy family and give them better opportunity expand their business"
          />
          <meta property="og:site_name" content="MagnetRefer" />
          <meta
            property="og:image"
            content="https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/MobReferBannerMagnet.svg"
          />
          <meta http-equiv="cache-control" content="max-age=0" />
          <meta
            http-equiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </Helmet>
          <LeadListing
            expand={this.props.sideBar}
            client_id={this.state.client_id}
          />
      </div>
    );
  }
}

export default genericSearch;
