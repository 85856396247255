import React from 'react'
import Modal from 'react-bootstrap/Modal';
import AuditUpdateForm from '../form/AuditUpdateForm';

const UpdateAuditLeadModal = ({ show, changeModal, leadId, getList, leadDetailProp }) => {
    return (
        <Modal
            show={show}
            onHide={changeModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="unselectable"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Lead
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AuditUpdateForm
                    changeModal={changeModal}
                    leadId={leadId}
                    getList={getList}
                    leadDetailProp={leadDetailProp}
                />
            </Modal.Body>

        </Modal>
    )
}

export default UpdateAuditLeadModal
