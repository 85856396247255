import React, { useState } from "react";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import ProjectPdfContainer from "../pdfGenrete/ProjectPdfContainer.js";
import Tooltip from "@material-ui/core/Tooltip";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  downloadIcon: {
    padding: 0,
    "& svg": {
      fontSize: 32,
      color: "#245983"
    }
  }
}));

const DownloadFactsheet = ({ id }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    downloadPdf: 0,
    downloadProjectId: null
  });
  const pdfGenrete = projectId => {
    setState({
      ...state,
      downloadPdf: 1,
      downloadProjectId: projectId
    });
  };
  
  const { downloadPdf, downloadProjectId } = state;
  return (
    <>
      <Tooltip title="Download" arrow>
        <div onClick={() => pdfGenrete(id)}>
          <IconButton className={classes.downloadIcon}>
            <AssignmentReturnedIcon />
          </IconButton>
        </div>
      </Tooltip>
      {downloadPdf === 1 && downloadProjectId > 0 && (
        <ProjectPortal projectId={downloadProjectId} />
      )}
    </>
  );
};

const ProjectPortal = props => {
  return ReactDOM.createPortal(
    <ProjectPdfContainer {...props} />,
    document.getElementsByTagName("body")[0]
  );
};
export default DownloadFactsheet;
