import React from "react";
import ReactDOM from "react-dom";
import CustomizedSnackbars from './snackBar.js';
import {cleanStorage} from '../../helper';
import {Link, withRouter} from "react-router-dom";

// import "./styles.css";

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logginStatus: true,showSnac : false };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    //55 mins
    this.warnTimeout = setTimeout(this.warn, 3300000);

    //60 mins
    this.logoutTimeout = setTimeout(this.logout, 3600000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn = () => {
    this.setState({showSnac : true});
    // alert("You will be logged out automatically in 1 minute.");
  }

  logout() {
    // Send a logout request to the API
    // console.log("Sending a logout request to the API...");
    this.setState({ logginStatus: false });
    this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
    cleanStorage()
    // this.props.history.push("/login");
  }
        // <h6>Status :{this.state.logginStatus ? "Loggin" : "Logout"}</h6>
  render() {
    return (
      <div className="logout">
         {this.state.showSnac ? <CustomizedSnackbars msg = 'You will be logged out automatically in 5 minutes due to idle window'/> : ''}
      </div>
    );
  }
}
export default withRouter(AutoLogout);
