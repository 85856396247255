import React, { useState, useEffect } from 'react'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
// import moment from 'moment';
import { getBulkFilesList } from '../dataParser/bulkLeadData';
import Loader from './common/loader/loader';
import MainHeader from './CliManagement/MainHeader';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BulkFileStatusModal from './common/modal/BulkFileStatusModal';
import { changeDate } from '../helper';
import Pagination from "react-js-pagination";

const NoActionRole = ["sourcings2", "magnet s2"]

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const fields = ["ID", "Magnet Name", "File", "Region", "Project", "Uploaded Date"
  , "Status", "Status Updated On", "No. of Calls Connected", "Leads Qualified", "Action"
];
let initialState = {
  showLoader: false
  , data: []
  , showModal: false
  , fullData: null
  , size: 16
}
const BulkFilesList = () => {

  const [state, setState] = useState(initialState);

  const { showLoader, data, count, showModal, fullData, fullRes, size } = state;

  const [activePage, setActivePage] = React.useState(1);

  useEffect(() => {

    let listData = {};
    listData.size = size;
    listData.pageId = 1;
    getBulkFilesApi(listData);
  }, [])

  const getBulkFilesApi = async (queryParam) => {
    setState({ ...state, showLoader: true })
    let getRes = await getBulkFilesList(queryParam);
    if (getRes.meta.status === 200) {
      setState({
        ...state
        , showLoader: false
        , data: getRes.data.bulkLeads
        , count: getRes.data.count
        , fullRes: getRes
      })
    }
    else {
      setState({
        ...state
        , showLoader: false
      })
    }
  }

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber)
    let listData = {};
    listData.size = size;
    listData.pageId = pageNumber;
    getBulkFilesApi(listData);
  }

  const changeModal = (data) => {
    setState({
      ...state
      , showModal: !state.showModal
      , fullData: data
    })
  }
  const onDownload = (item) => {
    const link = document.createElement("a");
    let filename = item.id + "." + item.file_name.split('.').pop();
    link.download = filename;
    link.href = item.file_url;
    // link.href = "./test.txt";
    link.click();
  };

  return (
    <>
      <Loader show={showLoader} />
      <MainHeader list="Bulk Leads Files" count={count || "0"} />
      {fullRes?.meta?.status === 200 ?
      <div className="p-4">
        <div className="row">
          <div className="col-md-12">
            <TableContainer component={Paper} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" className="tableCustom" style={{ minWidth: "650px" }} >
                <TableHead>
                  <TableRow>
                    {fields.map((f, i) => (
                      <StyledTableCell key={i}>{f}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(item => (
                    <TableRow key={item.id} className="text-capitalize ">
                      <TableCell>{item?.id || "-"}</TableCell>
                      <TableCell>{item?.MagnetInfo?.name || "-"}</TableCell>
                      <TableCell >
                        {item?.file_url ?
                          (!NoActionRole.includes(window.$user.role) ?
                            <Tooltip title="Download" placement="bottom" arrow>
                              <GetAppIcon fontSize="medium" style={{ cursor: "pointer" }} onClick={() => onDownload(item)} />
                            </Tooltip>
                            : <GetAppIcon fontSize="medium" style={{ cursor: "not-allowed" }} />
                          )
                          : <span className='ml-2'>{" -"}</span>
                        }
                      </TableCell>
                      <TableCell>{item?.Region?.name || "-"}</TableCell>
                      <TableCell>{item?.Project?.name || "-"}</TableCell>
                      {/* <TableCell>{moment(item?.created_at).format('L') || "-"}</TableCell> */}
                      <TableCell>{changeDate(item?.created_at) || "-"}</TableCell>
                      <TableCell>{item?.status || "Pending"}</TableCell>
                      {/* <TableCell>{moment(item?.updated_at).format('L') || "-"}</TableCell> */}
                      <TableCell>{changeDate(item?.updated_at) || "-"}</TableCell>
                      <TableCell>{item?.no_calls_connected || "-"}</TableCell>
                      <TableCell>{item?.no_leads_qualified || "-"}</TableCell>
                      <TableCell>
                        {!NoActionRole.includes(window.$user.role) ?
                          <Tooltip title="Edit File Status" placement="bottom" arrow>
                            <span>
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="fs-14 cursor-pointer"
                                onClick={() => changeModal(item)}
                              />
                            </span>
                          </Tooltip>
                          :
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="fs-14"
                            style={{ cursor: "not-allowed" }}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className="row justify-content-end">
            {count > size ?
              <div className="paginationBlock">
                <Pagination
                  hideDisabled
                  activePage={activePage}
                  itemsCountPerPage={size}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  onChange={handleChangePage}
                />
              </div>
              :
              ''
            }
        </div>
      </div>
      : <div className="main_area_display shadow card p-3">
          <h5>{showLoader? "Loading.." : "No Data Found !!!"}</h5>
      </div>
      }
      {showModal && <BulkFileStatusModal show={showModal} changeModal={changeModal} fullData={fullData} getListingData={getBulkFilesApi} />}
    </>
  )
}

export default BulkFilesList
