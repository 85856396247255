import {get,patch,post,put} from "../utils/methods.js";

export const getUpdateBulkLead = async (value) =>{

	let getUpdateBulkData = await put('/bulk_leads/'+value.id,value.data)
	return  getUpdateBulkData;
}

export const UpdateDumpLead = async (value) =>{

	let getUpdateBulkData = await put('/dump_lead/'+value.id,value.data)
	return  getUpdateBulkData;
}

export const getbulkLeadDataList = async (value) =>{

	let getbulkLeadData = await get('/bulk_leads',value)
	return  getbulkLeadData;
}

export const getBulkFilesList = async (value) =>{

	let getBulkFilesData = await get('/bulkLeadFile',value)
	return  getBulkFilesData;
}

export const updateBulkFiles = async (value) =>{

	let getUpdateData = await put('/bulkLeadFile/'+value.id,value.data)
	return  getUpdateData;
}
export const getdumpLeadDataList = async (value) =>{

	let getdumpLeadData = await get('/dump_lead',value)
	return  getdumpLeadData;
}

export const getClosedLeadCount = async (value) =>{

	let getdumpLeadData = await get('/dumpRawLeads',value)
	return  getdumpLeadData;
}

export const getBulkLeadCount = async (value) =>{

	let getBulkLeadCount = await get('/bulk_leads/count',value)
	return  getBulkLeadCount;
}

export const getbulkLeadUpload = async (value) =>{

	let getbulkLeadUploadData = await post('/bulk_leads/upload',value)
	return  getbulkLeadUploadData;
}
