import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from "../../../component/common/loader/loader";
import { addProjectFloor, removeFloorPlan, updateFloorPlan, getProjectDetail } from "../../../dataParser/getProjectData"
import CustomizedSnackbars from '../../autoLogout/snackBar';
import Box from '@material-ui/core/Box';
import { cleanStorage } from '../../../helper';

class AddProjectFloor extends Component {

	constructor(props) {
    	super(props)
		this.state = {
			floor_addedby : '',
			showLoader: false,
			alertShow: false,
			type: 'success',
			title: '',
			values: [{id: "", bhk: "", area: "", price: "" }],
			project_id: this.props.updateProjectId,
			filterData: this.props.filterData,
		}
	}

	fullProjectDetail = async (id) => {
		this.setState({ showLoader: true });
		var resData = {};
		var projectMeta;
		resData = await getProjectDetail(id);
		if (resData.meta.status === 200) {
			projectMeta = resData.data.ProjectMeta;
			projectMeta.push({ id: "", bhk: "", area: "", price: "" });
			await this.setState({
				values: projectMeta,
				showLoader: false
			})
		} else if (resData.meta.status === 401) {
			cleanStorage();
			this.props.history.push("/login");
		} else {
			this.setState({
				values: resData.data.ProjectMeta,
				showLoader: false
			})
		}
	}

	async componentDidMount(){
		this.state.project_id && this.fullProjectDetail(this.state.project_id)
		let homesfy_lg =(localStorage.getItem("homesfy_lg"));
		let addedBy;
		if(homesfy_lg && homesfy_lg !== ""){
			let user = JSON.parse(window.atob(homesfy_lg));
			addedBy = user.id;
	        this.setState({floor_addedby : addedBy})

	    }else{
			addedBy = '';
	        this.setState({floor_addedby : addedBy})
		}

	}

	handleChangeInput = (i, event) => {
		let val = [...this.state.values]
		const { name, value } = event.target
		val[i][name] = value
		this.setState({ values: val })
	}

	addFloorPlan= async() => {
		const val = [...this.state.values];
		var i = val.length - 1;
		if (val[i].bhk != "" && val[i].area != "" && val[i].price != "") {
			this.setState({
	                showLoader:true,
			})

			let data = {}
			data["bhk"] = val[i].bhk
			data["area"] = parseFloat(val[i].area)
			data["price"] = parseFloat(val[i].price)
			data["created_by"] = this.state.floor_addedby
			if (!!this.props.projectId) data["project_id"] = parseInt(this.props.projectId)

			var resData = await addProjectFloor(data);

			val[i].id = resData?.data?.id
			const values = [...this.state.values];
			values.push({id: "", bhk: "", area: "", price: "" });
			this.setState({ values: values });

           if(resData.meta.status === 201){
				this.setState({
					showLoader: false,
					alertShow: true,
					title: "Added floor plan successfully !!!",
				})
			}
			else {
				this.setState({
					showLoader: false,
					alertShow: true,
					type: "error",
					title: resData.meta.message
				});
			}

		}
		else {
			this.setState({
				alertShow: true,
				type: "warning",
				title: "Please fill details !"
			});
		}
	}
	updateFloorPlan= async(id) => {
		const val = [...this.state.values];
		let i = val.map(item=>item.id).indexOf(id)
		if (val[i].bhk != "" && val[i].area != "" && val[i].price != "") {
			this.setState({
                showLoader:true,
			})

			let data = {}
			data["bhk"] = val[i].bhk
			data["area"] = parseFloat(val[i].area)
			data["price"] = parseFloat(val[i].price)
			data["created_by"] = this.state.floor_addedby
			if (!!this.props.projectId) data["project_id"] = parseInt(this.props.projectId)

            var resData = await updateFloorPlan(id,data);
			this.fullProjectDetail(this.state.project_id)
           	if(resData.meta.status === 201){
				this.setState({
					showLoader: false,
					alertShow: true,
					title: "Updated floor plan successfully !!!",
				})
			}
			else {
				this.setState({
					showLoader: false,
					alertShow: true,
					type: "error",
					title: resData.meta.message
				});
			}
			const values = [...this.state.values];
			this.setState({ values: values });
		}
	}

	handleRemoveInput = async (i,id) => {
		const values = [...this.state.values];
		values.splice(i, 1);

		this.setState({ showLoader: true });
		var deleteRes = await removeFloorPlan(id);

		if (deleteRes.meta.status === 200) {
			this.setState({
				showLoader: false,
				alertShow: true,
				title: "Removed floor plan successfully !!!",
				values: values,
			});
		}
		else {
			this.setState({
				showLoader: false,
				alertShow: true,
				type: "error",
				title: deleteRes.meta.message
			});
		}

	}

	render() {
	 	const {values, showLoader, alertShow, type, title} = this.state

		return (
			<Frag>
				<div className="projectForm">
            		<Loader show={showLoader}/>
					<form role="form">
						<div className="card-body px-2">
							<div className="row mb-1">
								<div className="col-3 floor-title offset-1 pl-xs-5">Typology <sub>(BHK)</sub></div>
								<div className="col-3 floor-title">Area <sub>(sq.ft)</sub> </div>
								<div className="col-3 floor-title">Price <sub>(Rs.)</sub></div>
							</div>
							{values.map((field, idx) => (
								<div className="row" key={idx}>
									<div className="col-1 mt-2">
									<span>{idx+1})</span>
									</div>
									<div className="col-3 mt-1">
										<TextField
											type="text"
											id="bhk"
											name="bhk"
											value={field.bhk}
											onChange={e => this.handleChangeInput(idx, e)}
										/>
									</div>
									<div className="col-3 mt-1">
										<TextField
											type="number"
											id="area"
											name="area"
											value={field.area}
											onChange={e => this.handleChangeInput(idx, e)}
										/>
									</div>
									<div className="col-3 mt-1">
										<TextField
											type="number"
											id="price"
											name="price"
											value={field.price}
											onChange={e => this.handleChangeInput(idx, e)}
										/>
									</div>
									<div className="col-md-2 pl-md-0">
								{alertShow && <CustomizedSnackbars type={type} msg={title}/> }
								<Box  width={1} my={1}>
											<Box width="50%" className="ButtonPart">
												<button
													className="btn btn-link bg-blue"
													type="button"
								        onClick={() => this.props.updateProjectId ? (field.id ? this.updateFloorPlan(field.id) : this.addFloorPlan(field.id) ): this.addFloorPlan(field.id)}
												>
													<AddCircleIcon />
												</button>
											</Box>
											<Box width="50%" className="ButtonPart "
											>
												<button
													className="btn btn-link bg-red"
													type="button"
									  onClick={() => this.handleRemoveInput(idx,field.id)}
												>
									  <CancelIcon style={{color:"#dc3545"}}/>
												</button>
											</Box>
										</Box>


									</div>
								</div>
							))}
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(AddProjectFloor);
