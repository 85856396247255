import React, {Component} from 'react'
import Loader from "../../component/common/loader/loader";
import {getIvrLogList} from "../../dataParser/getIvrData"
import Frag from "../../utils/Frag/Frag";
import ListingView, {OneSixthField} from '../common/listing/ListingView'
import moment from 'moment';
import Pagination from "react-js-pagination";
import { cleanStorage } from '../../helper';

class IvrLogs extends Component{
	constructor(props){
		super(props)
		this.state = {
			showLoader: false,
			data: [],
            activePage: 1,
		}
	}

	componentDidMount() {
		this.getUserDetail()
        let data = {};
        data.size = 24;
        data.pageId = 1;
		this.getListData(data)
	}

    handleChangePage = async (pageNumber) => {
        this.setState({
            showLoader: true,
            activePage: pageNumber
        })
        var listData = {};
        listData.size = 24;
        listData.pageId = pageNumber;
		this.getListData(listData)
    }

    getUserDetail = () => {
		var homesfy_lg = localStorage.getItem("homesfy_lg")
		if (homesfy_lg && homesfy_lg !== "") {
		  let user = JSON.parse(window.atob(homesfy_lg))
			this.setState({
				loggedInUser: user.id,
				role: user.role,
			});
		}
    }
    getListData = async (params) => {
		this.setState({ showLoader: true, });
        (async () => {
			try {
				let resData = await getIvrLogList(params);
                if (resData.meta.status === 200) {

                    await this.setState({
                        showLoader: false,
                        meta: resData.meta,
                        data: resData.data.rows,
                    });
                    window.scrollTo(0, 0);

                } 
                else if (resData.meta.status == 401) {
                    cleanStorage();
                    this.props.history.push("/login");
                } 
                else {
                    this.setState({
                        showLoader: false,
                        meta: resData.meta,
                        data: resData.data,
                    })
                }
            } catch (e) {
                //...handle the error...
            }
        })();

    }
	pad(num) {
		return ("0"+num).slice(-2);
	}
	hhmmss(secs) {
		var minutes = Math.floor(secs / 60);
		secs = secs%60;
		var hours = Math.floor(minutes/60)
		minutes = minutes%60;
		return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
		// return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
	}
	render(){
		
		document.addEventListener('play', function(e){
			var audios = document.getElementsByTagName('audio');
			for(var i = 0, len = audios.length; i < len;i++){
				if(audios[i] != e.target){
					audios[i].pause();
				}
			}
		}, true);

		const {showLoader, data} = this.state
		const mainData = data;

	    return (
			<Frag>
        		<Loader show={showLoader} />
				<div className="p-3 leadMainHeader">
					<div className="headerCount">
						<span className="totalCount">Total IVR Logs : </span>
						<span className="">{this.state.meta?.status === 400 && this.state.data.length ? "0" : this.state.meta?.count}</span>
					</div>
				</div>
				<div className="listContainer">
		            {mainData?.map((item, i)=>(
		            <ListingView key={i}>
					    <OneSixthField title="Lead Id" value={item?.lead_id || '-'}/>
		            	<OneSixthField title="Ivr Number" value={item?.number || '-'}/>
		            	<OneSixthField title="Agent Number" value={item?.callee}/>
		            	<OneSixthField title="Client Number" value={item?.caller || '-'}/>
		            	<OneSixthField title="Project Name" value={item?.Ivr?.Project?.name || '-'}/>
		            	<OneSixthField title="Region" value={item?.Ivr?.Project?.Region?.name || '-'}/>
						<OneSixthField title="Call Start" value={moment(item?.call_time).format("llll")}/>
						<OneSixthField title="Call End" value={moment(item?.end_time).format("llll")}/>
						<OneSixthField title="Duration" value={this.hhmmss(item?.duration) || '-'}/>
						<div className = "col-md-4 col-12">
							<audio controls controlsList="nodownload">
								<source 
									src={item?.recording_url} 
									type="audio/mpeg"
								/>
							</audio>
						</div>
		            </ListingView>
		            ))}
		            <div className="container-fluid">
	                    <div className="row justify-content-end">
	                        {this.state.meta?.count > 8 ? (
	                            <div className="paginationBlock">
	                                <Pagination
	                                    hideDisabled
	                                    activePage={this.state.activePage}
	                                    itemsCountPerPage={this.state.meta?.size}
	                                    totalItemsCount={this.state.meta?.count}
	                                    pageRangeDisplayed={5}
	                                    onChange={this.handleChangePage}
	                                />
	                            </div>
	                        ) : (
	                                ""
	                            )}
	                    </div>
                    </div>
	            </div>
	        </Frag>
	    )
	}
}

export default IvrLogs
