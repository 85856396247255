import {get, post, formPut} from "../utils/methods.js";

export const getCpUsersList = async (value) =>{

	let getCpUsersListData = await post('/cp/list',value)
	return  getCpUsersListData;
}

export const getAssignRmInterested = async (value) =>{

	let getAssignRmInterestedData = await post('/interested_magnets/assign-rm',value);
	return  getAssignRmInterestedData;
}


// export const getDeleteUser = async (value) =>{

// 	let getDeleteUser = await Delete('/users/'+value)
// 	return  getDeleteUser;
// }

export const getUpdateCpUser = async (value) =>{

	let getUpdateCpUserData = await formPut('/users/'+value.id,value.data)
	return  getUpdateCpUserData;
}

export const getTlCpUser = async (value) =>{

	let getTlCpUserData = await get('/cp/rmdetails/'+value)
	return  getTlCpUserData;
}

export const getSingleCpUser = async (value) =>{

	let getSingleCpUserData = await get('/users/'+value)
	return  getSingleCpUserData;
}

export const getRegionParentsData = async () =>{

	let getRegionData = await get('/regionparents')
	return  getRegionData;
}