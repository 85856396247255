import React, {Component} from 'react';
// import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Frag from "../../../utils/Frag/Frag.js";
import BarChart from "../chart/column_Chart.js"
import RatioChart from '../chart/RatioChart.js';
import LastBookingDate from '../cards/LastBookingDate.js';
import LeaderboardChart from '../chart/LeaderboardChart.js';
// import { leaderboardAccess } from '../../../config/constant/crmRoleFunctionPermission.js';

class userFocusProjectTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader : false,
            data : [],
            tab : 0,
        };
    }

    // componentDidMount() {
     // console.log('componentWillReceiveProps xxxxxxxxxx');
    // }
    handleActionParent=(userId)=>{
        // console.log("int he ref userrrrrrr",userId);
        this.setState({userId:userId,tab:0});
        // this.callChildFunction(userId);
    }

    callChildFunction = (userId) => {
       this.child.handleActionChild(userId);  ///calling a child function here
    } 

    handleChange = (event, newValue) => {
        this.setState({tab : newValue});
    };

    render() {

        const {data,tab} = this.state;

        return (
            <Frag>
                <AppBar position="static" color="default">
                    <Tabs
                      value={tab}
                      onChange={this.handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="bussiness-flow-tab"
                    >
                      <Tab label="Business" />
                      <Tab label="Activity" />
                      {/*<Tab label="Updates" />*/}
                      <Tab label="Ratios" />
                      {!(["closings1", "sourcings3", "magnet s3"].includes(window.$role))  && <Tab label="Leaderboard" /> }
                      {/* <Tab label="Team Leaderboard" value="team-leaderboard"/> */}
                    </Tabs>
                </AppBar>
                <Paper
                  role="tabpanel"
                  hidden={tab !== 0}
                  id={`scrollable-auto-tabpanel-${0}`}
                  aria-labelledby={`scrollable-auto-tab-${0}`}
                 
                >
                  {tab === 0 && (
                    <Box p={3}>
                        <div>
                            <LastBookingDate/>
                            <BarChart userWiseData={this.state.userId} type="Business"/>
                        </div>
                    </Box>
                  )}
                </Paper>
                <Paper
                  role="tabpanel"
                  hidden={tab !== 1}
                  id={`scrollable-auto-tabpanel-${1}`}
                  aria-labelledby={`scrollable-auto-tab-${1}`}
                 
                >
                  {tab === 1 && (
                    <Box p={3}>
                        <div>
                            <BarChart userWiseData={this.state.userId} type="Activity"/>
                        </div>
                    </Box>
                  )}
                </Paper>
                {/* <Paper
                  role="tabpanel"
                  hidden={tab !== 2}
                  id={`scrollable-auto-tabpanel-${2}`}
                  aria-labelledby={`scrollable-auto-tab-${2}`}
                 
                >
                  {tab === 2 && (
                    <Box p={3}>
                        <div>
                            <BarChart userWiseData={this.state.userId} type="Updates"/>
                        </div>
                    </Box>
                  )}
                </Paper> */}
                <Paper
                  role="tabpanel"
                  hidden={tab !== 2}
                  id={`scrollable-auto-tabpanel-${2}`}
                  aria-labelledby={`scrollable-auto-tab-${2}`}
                 
                >
                  {tab === 2 && (
                    <Box p={2}>
                        <div>
                          <RatioChart/>
                        </div>
                    </Box>
                  )}
                </Paper>
                <Paper
                  role="tabpanel"
                  hidden={tab !== 3}
                  id={`scrollable-auto-tabpanel-${3}`}
                  aria-labelledby={`scrollable-auto-tab-${3}`}
                 
                >
                  {tab === 3 && (
                    <Box p={1}>
                        <div>
                            <LeaderboardChart/>
                        </div>
                    </Box>
                  )}
                </Paper>
                <Paper
                  role="tabpanel"
                  // hidden={tab !== 4} 
                  id={`scrollable-auto-tabpanel-${4}`}
                  aria-labelledby={`scrollable-auto-tab-${4}`}
                 
                >
                  {tab === "team-leaderboard" && (
                    <Box p={1}>
                        <div>
                            <LeaderboardChart tab="team"/>
                        </div>
                    </Box>
                  )}
                </Paper>
            </Frag>
        );
    }
}

export default userFocusProjectTable;