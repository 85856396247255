import React, { Component } from "react";
import Tree from "react-tree-graph";
import data from "./data";
import "./role.css";

class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: ''
    }
  }

  componentDidMount() {
    this.update();
  }

  update = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };

  render() {
    const isDesktop = this.state.screenWidth;

    return (
      <div>
        {isDesktop > 991 ? (
          <div
            className={
              "content-wrapper" +
              " " +
              (this.props.expand ? "main-header-collapsed" : "")
            }
          >
            <div className="listContainer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <Tree
                      animated={true}
                      data={data}
                      nodeRadius={15}
                      margins={{ top: 20, bottom: 10, left: 20, right: 200 }}
                      height={1000}
                      width={1000}
                      gProps={{
                        className: "node"
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              "content-wrapper" +
              " " +
              (this.props.expand ? "main-header-collapsed" : "")
            }
          >
            <div className="listContainer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <Tree
                      animated={true}
                      data={data}
                      nodeRadius={15}
                      margins={{ top: 20, bottom: 10, left: 20, right: 200 }}
                      height={1000}
                      width={500}
                      gProps={{
                        className: "node"
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Role;
