import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import UserUpdateFrom from "../form/UserUpdateFrom";
import UserAddFrom from "../form/addUserForm";


class UserModal extends Component {
    render() {

    	const FROMS_TYPES = {
			"addUser" : UserAddFrom,
			"updateUser" : UserUpdateFrom,
		}
		const Form_name = FROMS_TYPES[this.props.fromType];	

        return (
            <Frag>
 				<Modal
 				  show={this.props.show} 
 				  onHide={this.props.changeModal}	
			      size="lg"
			      aria-labelledby="contained-modal-title-vcenter"
			      centered
			      className="unselectable"
			    >
			      <Modal.Header closeButton>
			        <Modal.Title id="contained-modal-title-vcenter">
			        {Form_name === UserAddFrom ? "Add User" : "Update User"}
			        </Modal.Title>
			      </Modal.Header>
			      <Modal.Body>
			        <Form_name changeModal={this.props.changeModal} getList={this.props.getList} singleUserData={this.props.singleUserData}/>
			      </Modal.Body>
			      
			    </Modal>
            </Frag>
        );
    };
};    

export default UserModal;