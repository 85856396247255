import { colorConfig } from "./colorConstant.js";
import { digitalGroup, magnetApproveRoles, magnetGroup } from "./constant/crmRoleFunctionPermission.js";
import { DUMP_LEAD } from "./constant/index.js";
const magnet_rm_cc_mail_id = "";

const magnet_admin_bcc_mail_id = "shitij.k@homesfy.in";

const user_access = [
  {
    name: "HR Docs",
    url: "#",
    icon: "atlassian",
    has_group: 1,
    sub_url: [
      {
        name: "HR Docs",
        url: "/hr-docs",
        icon: "ApartmentIcon",
        color: colorConfig.assign_leads,
        user_role: [
          "admin",
          "hrhead",
        ]
      },
      {
        name: "View HR Docs",
        url: "/hr-doc-view",
        icon: "ApartmentIcon",
        color: colorConfig.new_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "financehead",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "magnetmis",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "financeanalyst",
          "financeassociate",
          "operationsanalyst",
          "operationshead",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead",
          "operationsanalyst",
          "hrhead",
          "ithead",
        ]
      },
      {
        name: "HR Knowledge Piece",
        url: "/hr-knowledge-piece",
        icon: "ApartmentIcon",
        color: colorConfig.new_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "financehead",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "magnetmis",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "financeanalyst",
          "financeassociate",
          "operationsanalyst",
          "operationshead",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead",
          "operationsanalyst",
          "hrhead",
          "ithead",
        ]
      }
    ],
    user_role: [
    "admin",
    "miniadmin",
    "bangaloreadmin",
    "puneminiadmin",
    "saleshead",
    "pnlhead",
    "salesmanager",
    "salestl", 
    "seniorsalestl",
    "closings1",
    "financehead",
    "presalehead",
    "presalestl",
    "sourcings2",
    "sourcings3",
    "magnet s2",
    "magnet s3",
    "magnetmis",
    "mediamanagers",
    "magnetadmin", "magnetpnl",
    "magnettl",
    "magnetrm",
    "financeanalyst",
    "financeassociate",
    "operationsanalyst",
    "operationshead",
    "campaignmanagers",
    "campaignoperations",
    "leadoperations",
    "operationsmanager",
    "operationshead",
    "operationsanalyst",
    "hrhead",
    "ithead",
  ]
  },
  {
    name: "Dashboard",
    url: "/",
    icon: "DashboardIcon",
    has_group: 0,
    sub_url: [],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "presalehead",
      "presalestl",
      "closings1",
      "sourcings2",
      "sourcings3",
      "magnet s2",
      "magnet s3",
      "magnetadmin", "magnetpnl",
      "magnettl",
      "magnetrm",
      "magnet",
      "operationshead"
      ,"operationsmanager"
      , "financeassociate"
    ]
  },
  {
    name: "Leads",
    url: "#",
    icon: "ApartmentIcon",
    has_group: 0,
    sub_url: [
      {
        name: "All Leads",
        url: "/leads/all",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead",
          "operationsanalyst",
          "magnetplatinum"
        ]
      },

      {
        name: "New Leads",
        url: "/leads/new",
        icon: "ApartmentIcon",
        color: colorConfig.new_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead",
          "operationsanalyst",
          "magnetplatinum"
        ]
      },

      {
        name: "Assigned",
        url: "/leads/assigned",
        icon: "ApartmentIcon",
        color: colorConfig.assign_leads,
        user_role: ["admin", "superuser", "magnettl", "saleshead", "presalehead", "salesmanager", "salestl", 
        "seniorsalestl", "presalestl", 
        "sourcings2", "magnet s2",  "pnlhead",,"campaignoperations","leadoperations","operationsmanager",
        "magnetplatinum"
        ]
      },
      {
        name: "Home Loan Leads",
        url: "/leads/home-loan-leads",
        icon: "ApartmentIcon",
        color: colorConfig.assign_leads,
        user_role: ["admin","operationshead","financehead","financeanalyst", "operationsmanager", "saleshead"]
      },
      {
        name: "Open Leads",
        url: "#",
        icon: "ApartmentIcon",
        color: colorConfig.open_leads,
        sub_url: [
          {
            name: "All Open Leads",
            url: "/leads/open",
            icon: "ApartmentIcon",
            color: colorConfig.open_leads,
            user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
            "seniorsalestl", 
            "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2","magnet s3", "mediamanagers", "magnetadmin", "magnettl", 
            "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead",
            "operationsanalyst","magnetpnl",
            "magnetplatinum"
            ]
          },
          {
            name: "Suspect Leads",
            url: "/leads/suspect",
            icon: "ApartmentIcon",
            color: colorConfig.open_leads,
            user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl",
              "seniorsalestl",
              "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers", "magnetadmin", "magnettl",
              "magnetrm", "magnet", "campaignmanagers", "campaignoperations", "leadoperations", "operationsmanager", "operationshead",
              "operationsanalyst", "magnetpnl",
              "magnetplatinum"
            ]
          },

          /*       {
                  name: "D Delayed Leads",
                  url: "/leads/decision-delayed",
                  icon: "ApartmentIcon",
                  color: colorConfig.open_leads,
                  user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
                  "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers"]
                }, */
          {
            name: "Opportunity Leads",
            url: "/leads/opportunity",
            icon: "ApartmentIcon",
            color: colorConfig.open_leads,
            user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
            "seniorsalestl", 
            "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers", "magnetadmin", "magnettl", 
            "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead",
            "operationsanalyst","magnetpnl",
            "magnetplatinum"
            ]
          },
          {
            name: "Pipeline Leads",
            url: "/leads/pipeline",
            icon: "ApartmentIcon",
            color: colorConfig.open_leads,
            user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
            "seniorsalestl", 
            "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers", "magnetadmin", "magnettl", 
            "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead",
            "operationsanalyst","magnetpnl",
            "magnetplatinum"
            ]
          },
          {
            name: "Gross EOI Application",
            url: "/leads/gross-eoi-application",
            icon: "ApartmentIcon",
            color: colorConfig.open_leads,
            user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
            "seniorsalestl",
            "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers", "magnetadmin", "magnettl",
            "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead",
            "operationsanalyst","magnetpnl",
            "magnetplatinum"
            ]
          },
          {
            name: "Gross EOI Non Refundable",
            url: "/leads/gross-eoi-non-refundable",
            icon: "ApartmentIcon",
            color: colorConfig.open_leads,
            user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
            "seniorsalestl",
            "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers", "magnetadmin", "magnettl",
            "magnetrm", "magnet","campaignmanagers",,"campaignoperations","leadoperations","operationsmanager","operationshead",
            "operationsanalyst","magnetpnl",
            "magnetplatinum"
            ]
          }
        ],
        user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
        "seniorsalestl", 
        "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers", "magnetadmin", "magnettl", "magnetrm", 
        "magnet","campaignmanagers",,"campaignoperations","leadoperations","operationsmanager","operationshead","operationsanalyst",
        "magnetplatinum","magnetpnl"
      ]
      },

      {
        name: "Closed Leads",
        url: "/leads/closed",
        icon: "ApartmentIcon",
        color: colorConfig.closed_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "presalehead",
          "presalestl",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "magnetmis",
          "mediamanagers",
          "salesmanager",
          "operationsanalyst",
          "operationshead",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "magnetplatinum"
        ]
      },

      {
        name: "Booked Leads",
        url: "/leads/booked",
        icon: "ApartmentIcon",
        color: colorConfig.booked_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "magnetmis",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "financeanalyst",
          "financeassociate",
          "operationsanalyst",
          "operationshead",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "magnetplatinum"
        ]
      },

      {
        name: "Cancel Leads",
        url: "/leads/cancel",
        icon: "ApartmentIcon",
        color: colorConfig.cancel_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "magnetmis",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "financeanalyst",
          "financeassociate",
          "operationsanalyst",
          "operationshead",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "magnetplatinum"
        ]
      },
      {
        name: "Cross Sale IN Leads",
        url: "/leads/cross_sale_in",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "presalehead",
          "presalestl",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "operationsmanager",
        ]
      },
      {
        name: "Cross Sale OUT Leads",
        url: "/leads/cross_sale_out",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "presalehead",
          "presalestl",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2",
          "magnet s3",
          "operationsmanager",
        ]
      },
      {
        name: "Sourcing Leads",
        url: "/leads/sourcing",
        icon: "ApartmentIcon",
        color: colorConfig.pipeline_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "operationsmanager"
          ,"magnetadmin", "magnetpnl"
        ]
      },

      {
        name: "Disinterested Leads",
        url: "/leads/disinterested",
        icon: "ApartmentIcon",
        color: colorConfig.prospect_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "operationsmanager"
          ,"magnetadmin", "magnetpnl"
        ]
      },

      {
        name: "Reactivation Leads",
        url: "/leads/reactivation",
        icon: "ApartmentIcon",
        color: colorConfig.opportunity_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "operationsanalyst",
          "operationshead",
          "operationsmanager"
          ,"magnetadmin", "magnetpnl"
        ]
      },

      {
        name: "Disqualified Leads",
        url: "/leads/disqualify",
        icon: "ApartmentIcon",
        color: colorConfig.followup_leads,
        user_role: [
          "pnlhead",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
        ]
      }
    ],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "financehead",
      "presalehead",
      "presalestl",
      "closings1",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      "mediamanagers",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "magnet",
      "financeanalyst",
      "financeassociate",
      // "operationsanalyst",
      "operationshead",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "operationsmanager",
      "magnetplatinum"
    ]
  },
  {
    name: "Activity",
    url: "#",
    icon: "ApartmentIcon",
    has_group: 0,
    sub_url: [
      {
        name: "Site Visit",
        url: "/leads/sitevisit",
        icon: "ApartmentIcon",
        color: colorConfig.site_visit,
        user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
        "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead","operationsanalyst"]
      },
      {
        name: "Video Call",
        url: "/leads/videocall",
        icon: "ApartmentIcon",
        color: colorConfig.video_call,
        user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
        "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead","operationsanalyst"]
      },
      {
        name: "HPOP",
        url: "/leads/hpop",
        icon: "ApartmentIcon",
        color: colorConfig.hpop_leads,
        user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
        "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead","operationsanalyst"]
      },
      {
        name: "Revisit",
        url: "/leads/revisit",
        icon: "ApartmentIcon",
        color: colorConfig.revisit_leads,
        user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
        "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations","operationsmanager","operationshead","operationsanalyst"]
      },

    ],
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager"
    , "salestl", 
    "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers"
    ,"magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers",,"campaignoperations","leadoperations"
    ,"operationsmanager","operationshead"
    // ,"operationsanalyst"
  ]
  },
  {
    name: "Lead Pool",
    url: "#",
    icon: "ApartmentIcon",
    has_group: 0,
    sub_url: [
      {
        name: "Bulk Leads",
        url: "/lead-pool/bulk-leads",
        icon: "ApartmentIcon",
        color: colorConfig.closed_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "closings1",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "magnetmis",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationshead"
          ,"operationsmanager"
        ]
      },
      {
        name: "Bulk Leads(Files)",
        url: "/lead-pool/bulk-files",
        icon: "ApartmentIcon",
        color: colorConfig.closed_leads,
        user_role: [ "admin","magnetadmin", "magnetmis", "magnetpnl", "sourcings2", "magnet s2"]
      },
      {
        name: DUMP_LEAD+" Assign",
        url: "/lead-pool/dump-leads-assign",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "magnetadmin",
          "magnetmis",
          "magnetpnl",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "sourcings2",
          "magnet s2"
          , "mediamanagers","campaignmanagers","campaignoperations","leadoperations"
          , "operationshead", "operationsmanager"
        ]
      },
      {
        name: DUMP_LEAD+" Withdraw",
        url: "/lead-pool/dump-leads-withdraw",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "sourcings2",
          "magnet s2"
          , "operationshead"
          , "operationsmanager"
        ]
      },
      {
        name: DUMP_LEAD,
        url: "/lead-pool/dump-leads",
        icon: "ApartmentIcon",
        color: colorConfig.open_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "closings1",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "magnet"
          , "operationshead"
          , "operationsmanager"
        ]
      }
    ],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "presalehead",
      "presalestl",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "magnet",
      "closings1",
      "mediamanagers",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "operationshead"
      , "operationsmanager"
    ]
  },
  {
    name: "Reports",
    url: "#",
    icon: "faChartLine",
    has_group: 1,
    sub_url: [
      {
        name: "Activity Report",
        url: "/report/activity",
        icon: "ApartmentIcon",
        color: colorConfig.assign_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "presalehead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "closings1",
          "mediamanagers",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager"
          ,"magnetadmin",
          "magnetpnl",
          "magnetmis"
          , "magnettl"
          , "operationshead"
        ]
      },
      {
        name: "Booking Report",
        url: "/report/booking",
        icon: "ApartmentIcon",
        color: colorConfig.new_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "presalehead",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "closings1",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl",
          "magnetmis"
          , "pnlhead"
          , "hrhead"
        ]
      },

      {
        name: "Current Status Report",
        url: "/report/current-status",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "presalehead",
          "closings1",
          "mediamanagers",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin",
          "magnetpnl",
          "magnetmis"
        ]
      },
      {
        name: "Databank Report",
        url: "/report/databank",
        icon: "ApartmentIcon",
        color: colorConfig.dump_report,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Followup Report",
        url: "/report/followup",
        icon: "ApartmentIcon",
        color: colorConfig.followup_report,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "presalestl",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Audit Report",
        url: "/report/audit",
        icon: "ApartmentIcon",
        color: colorConfig.followup_report,
        user_role: [
          "admin",
          "financehead",
          "financeassociate",
          "financeanalyst",
          "operationshead",
          "operationsanalyst",
          "operationsmanager"
          , "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Hourly Update Report",
        url: "/report/hourly-update",
        icon: "ApartmentIcon",
        color: colorConfig.followup_report,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "closings1",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Lost Sales Report",
        url: "/report/lost-sales",
        icon: "ApartmentIcon",
        color: colorConfig.prospect_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
          , "mediamanagers"
          , "campaignmanagers"
          , "campaignoperations"
          , "leadoperations"
        ]
      },
      {
        name: "Project Lost Sales Report",
        url: "/report/project-lost-sales",
        icon: "ApartmentIcon",
        color: colorConfig.prospect_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
          , "mediamanagers"
          , "campaignmanagers"
          , "campaignoperations"
          , "leadoperations"
        ]
      },
      {
        name: "Project Report",
        url: "/report/project-rm",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "saleshead",
          "salestl", 
          "seniorsalestl",
          "salesmanager",
          "closings1",
          "pnlhead",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "operationsmanager",
          "operationshead"
          , "sourcings2",
          "magnet s2"
          ,"presalehead"
        ]
      },
      {
        name: "Project Activity Report",
        url: "/report/project-activity-rm",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "saleshead",
          "salestl", 
          "seniorsalestl",
          "salesmanager",
          "closings1",
          "pnlhead",
          "operationsmanager",
          "operationshead",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "magnetadmin", "magnetpnl",
          "magnetmis"
        ]
      },

      {
        name: "Update Report",
        url: "/report/update",
        icon: "ApartmentIcon",
        color: colorConfig.open_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Source Activity Report",
        url: "/report/source-activity",
        icon: "ApartmentIcon",
        color: colorConfig.closed_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager"
          , "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Source Booking Report",
        url: "/report/source-booking",
        icon: "ApartmentIcon",
        color: colorConfig.booked_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager"
          , "operationshead"
          ,"magnetadmin", "magnetpnl",
          "magnetmis"
        ]
      },
      {
        name: "Source Current Status Report",
        url: "/report/source-current-status",
        icon: "ApartmentIcon",
        color: colorConfig.cancel_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager"
          ,"operationshead"
          ,"magnetadmin", "magnetpnl",
          "magnetmis"
        ]
      },
      {
        name: "Source Update Report",
        url: "/report/source-update",
        icon: "ApartmentIcon",
        color: colorConfig.pipeline_leads,
        user_role: [
          "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager"
          , "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },

      {
        name: "Magnet Activity Report",
        url: "/report/magnet-activity",
        icon: "ApartmentIcon",
        color: colorConfig.magnet_leads,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "operationsmanager"
          , "operationshead"
        ]
      },
      {
        name: "Magnet Booking Report",
        url: "/report/magnet-booking",
        icon: "ApartmentIcon",
        color: colorConfig.magnet_current,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "operationsmanager"
          , "operationshead"
        ]
      },
      {
        name: "Magnet Bulk Upload Report",
        url: "/report/magnet-bulk-upload",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "operationsmanager"
          , "operationshead"
        ]
      },
      {
        name: "Magnet Current Status Report",
        url: "/report/magnet-current-status",
        icon: "ApartmentIcon",
        color: colorConfig.magnet_booked,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "operationsmanager"
          , "operationshead"
          , "presalehead",
        ]
      },
      {
        name: "Magnet Databank Report",
        url: "/report/magnet-databank",
        icon: "ApartmentIcon",
        color: colorConfig.dump_report,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "operationsmanager"
          , "operationshead"
        ]
      },
      {
        name: "Magnet Lead Report",
        url: "/report/magnet-lead",
        icon: "ApartmentIcon",
        color: colorConfig.golden,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "customersuccess"
          , "operationsmanager"
          , "operationshead"
        ]
      },

      /* {
        name: "Magnet Spend Report",
        url: "/report/magnet-spend",
        icon: "ApartmentIcon",
        color: colorConfig.magnet_leads,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "operationsmanager"
          , "operationshead"
        ]
      },
      {
        name: "Magnet Users Report",
        url: "/report/magnet-list",
        icon: "ApartmentIcon",
        color: colorConfig.followup_leads,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager"
          , "operationshead"
        ]
      }, */

      {
        name: "Sourcing Activity Report",
        url: "/report/presale-activity",
        icon: "ApartmentIcon",
        color: colorConfig.presale_leads,
        user_role: [
          "admin",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "pnlhead",
          "salesmanager",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },
      {
        name: "Sourcing Booking Report",
        url: "/report/presale-booking",
        icon: "ApartmentIcon",
        color: colorConfig.presale_current,
        user_role: [
          "admin",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "pnlhead",
          "salesmanager",
          "operationsmanager",
          "operationshead"
        ]
      },
      {
        name: "Sourcing Current Status Report",
        url: "/report/presale-current-status",
        icon: "ApartmentIcon",
        color: colorConfig.presale_booked,
        user_role: [
          "admin",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "pnlhead",
          "salesmanager",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
          , "magnettl"
        ]
      },

      {
        name: "Sourcing Lost Sales Report",
        url: "/report/presale-lost-sales",
        icon: "ApartmentIcon",
        color: colorConfig.presale_lose,
        user_role: [
          "admin",
          "pnlhead",
          "salesmanager",
          "operationsmanager",
          "operationshead"
          ,"magnetadmin", "magnetpnl"
        ]
      },

    ],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "financehead",
      "presalehead",
      "presalestl",
      "closings1",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      "mediamanagers",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "financeassociate",
      "financeanalyst",
      "operationshead",
      "operationsanalyst",
      "operationsmanager",
      "customersuccess"
      , "hrhead"
    ]
  },
  {
    name: "Users",
    url: "/users",
    icon: "PeopleIcon",
    has_group: 0,
    sub_url: [],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "presalehead",
      "presalestl",
      "hrhead",
      "magnetadmin", "magnetpnl"
      , "operationsmanager"
      , "operationshead"
    ]
  },
  {
    name: "Miscellaneous",
    url: "#",
    icon: "ApartmentIcon",
    has_group: 1,
    sub_url: [
      {
        name: "Role",
        url: "/miscellaneous/role",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "magnetadmin", "magnetpnl"
        ]
      }
    ],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "magnetadmin", "magnetpnl"
    ]
  },
  {
    name: "Auto Assign Panel",
    url: "/auto-assign-lead",
    icon: "atlassian",
    has_group: 0,
    sub_url: [],
    user_role: ["saleshead", "pnlhead", "salesmanager", "presalehead", "presalestl", "admin", "magnetadmin", "magnetpnl", "mediamanagers","campaignmanagers","campaignoperations","leadoperations",
    "financehead",
    "financeanalyst",
    "financeassociate",
    "operationsanalyst",
    "operationshead"
  , "operationsmanager"
    ]
  },

  {
    name: "Projects",
    url: "#",
    icon: "Building",
    has_group: 0,
    sub_url: [
      {
        name: "Projects",
        url: "/projects",
        icon: "ApartmentIcon",
        color: colorConfig.followup_report,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "financeanalyst",
          "financeassociate",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "magnet",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations"
          , "operationshead"
          , "operationsmanager"
          , "operationsanalyst"
        ]
      },
      {
        name: "Add/Update Project",
        url: "/projects/add-update",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "miniadmin",
          "bangaloreadmin",
          "puneminiadmin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "financehead",
          "presalehead",
          "presalestl",
          "closings1",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnettl",
          "magnetrm",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations"
          , "operationshead"
          , "operationsmanager"
        ]
      },
      {
        name: "Focus Projects",
        url: "/focus-projects",
        icon: "focus",
        color: colorConfig.pink,
        user_role: [...magnetGroup, "admin", "operationshead", "operationsmanager", "operationsanalyst"]
      },
      {
        name:"Request Project",
        url: "/request-project",
        color: colorConfig.green,
        user_role: ["admin", "magnet"]
      },
      {
        name: "Magnet Requests",
        url: "/magnet-request",
        color: colorConfig.golden,
        user_role: ["admin", "mediamanagers","magnetadmin", "magnetpnl"]
      },
    ],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "financehead",
      "financeanalyst",
      "financeassociate",
      "presalehead",
      "presalestl",
      "closings1",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      "mediamanagers",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      , "operationshead"
      , "operationsmanager"
      , "operationsanalyst"
      , ...magnetGroup
    ]
  },

  {
    name: "IVR Management",
    url: "#",
    icon: "Phone",
    has_group: 1,
    sub_url: [
      {
        name: "IVR Management",
        url: "/ivr-management",
        icon: "ApartmentIcon",
        color: colorConfig.followup_report,
        user_role: ["admin", "presalehead", "salesmanager", "pnlhead", "saleshead",
        "operationsmanager", "operationshead", ...digitalGroup]
      },
      {
        name: "Add/Update IVR",
        url: "/ivr-management/add-update",
        icon: "ApartmentIcon",
        color: colorConfig.golden,
        user_role: ["admin", "operationsmanager", "operationshead", ...digitalGroup]
      },
      {
        name: "IVR Logs",
        url: "/ivr-management/call-logs",
        icon: "ApartmentIcon",
        color: colorConfig.golden,
        user_role: ["admin", "mediamanagers", "presalehead", "salesmanager", "pnlhead", "saleshead","campaignmanagers","operationsmanager","leadoperations", "operationshead"]
      },
      {
        name: "Call Logs",
        url: "/ivr-management/knowlarity-call-logs",
        icon: "ApartmentIcon",
        color: colorConfig.golden,
        user_role: ["admin", "operationshead", "operationsmanager", "sourcings2", "magnet s2",  "salesmanager", "pnlhead", "saleshead","magnetadmin", "magnetpnl","mediamanagers"]
      },
      {
        name: "CLI Management",
        url: "/ivr-management/cli",
        icon: "ApartmentIcon",
        color: colorConfig.golden,
        user_role: ["admin", "operationshead", "operationsmanager","mediamanagers"]
      },
    ],
    user_role: ["admin", "presalehead", "salesmanager", "pnlhead", "saleshead"
    ,"operationsmanager", , "operationshead", "sourcings2", "magnet s2", "magnetadmin", "magnetpnl"
    , ...digitalGroup] 
  },

  {
    name: "Magnets",
    url: "/magnet/magnet-users",
    icon: "SupervisorAccountIcon",
    has_group: 0,
    sub_url: [],
    user_role: ["admin","magnetadmin", "magnetmis", "magnetpnl", "magnettl", "magnetrm", "customersuccess"]
  },
  {
    name: "New Magnets",
    url: "/magnet/magnet-new-users",
    icon: "SupervisorAccountIcon",
    has_group: 0,
    sub_url: [],
    user_role: ["admin","magnetadmin", "magnetmis", "magnetpnl", ...magnetApproveRoles]
  },
  {
    name: "Inactive Magnets",
    url: "/magnet/magnet-inactive-users",
    icon: "SupervisorAccountIcon",
    has_group: 0,
    sub_url: [],
    user_role: ["admin","magnetadmin", "magnetmis", "magnetpnl", "magnettl", "magnetrm", "customersuccess"]
  },
/*   {
    name: "Prospects",
    url: "/magnet-enroll",
    icon: "faUserPlus",
    has_group: 0,
    sub_url: [],
    user_role: ["admin","magnetadmin", "magnetpnl", "magnettl", "magnetrm","customersuccess"]
  }, */

  {
    name: "Magnet Interests",
    url: "/user-focus-project",
    icon: "focus",
    has_group: 0,
    sub_url: [],
    user_role: ["admin","magnetadmin", "magnetpnl"]
  },

  {
    name: "Referrals",
    url: "#",
    icon: "Link",
    has_group: 0,
    sub_url: [
      {
        name: "Refer & Earn",
        url: "/referral",
        icon: "Link",
        color: colorConfig.brown,
        user_role: ["admin","magnet"]
      },
      {
        name: "Referred Users",
        url: "/referral-user",
        icon: "faPaperclip",
        color: colorConfig.green,
        user_role: ["admin","magnet","magnetadmin", "magnetpnl"]
      },
    ],
    user_role: ["admin","magnet","magnetadmin", "magnetpnl"]
  },

  {
    name: "Magnet Budget",
    url: "#",
    icon: "ApartmentIcon",
    has_group: 1,
    sub_url: [
      {
        name: "Add Money",
        url: "/magnet-budget/add",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "campaignoperations",
          "leadoperations"
        ]
      },

      {
        name: "Campaign Splits",
        url: "/magnet-budget/campaign-splits",
        icon: "ApartmentIcon",
        color: colorConfig.assign_leads,
        user_role: [
          "admin",
          "magnetadmin", "magnetpnl",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "campaignoperations",
          "leadoperations"
        ]
      }
    ],
    user_role: [
      "admin",
      "magnetadmin", "magnetpnl",
      "mediamanagers",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "campaignoperations",
      "leadoperations"
    ]
  },

  {
    name: "Post Sales",
    url: "#",
    icon: "ApartmentIcon",
    has_group: 1,
    sub_url: [
      {
        name: "Bookings",
        url: "/booking/verify",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "financehead",
          "operationshead",
          "financeanalyst",
          "financeassociate",
          "saleshead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "magnet",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "pnlhead"
        ]
      },

      {
        name: "Finance Calendar",
        url: "/finance-calendar",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "financehead",
          "financeanalyst",
          "financeassociate",
          "operationshead"
        ]
      },
      {
        name: "Update Incentives",
        url: "/incentive-update",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin"
          , "financeanalyst"
          , "financeassociate"
          , "financehead"
          , "operationshead"
        ]
      },
      {
        name: "Eligibility Percentages",
        url: "/eligibility-percentages",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin"
          , "financeanalyst"
          , "financeassociate"
          , "financehead"
        ]
      },
      {
        name: "Incentive Report",
        url: "/booking/report",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "financehead",
          "saleshead",
          "financeanalyst",
          "financeassociate",
          "operationshead"
          ,"operationsmanager"
          ,"operationsanalyst"
          ,"salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "presalehead",
          // "presalestl", 
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "pnlhead"
        ]
      },,
      {
        name: "Payment History",
        url: "/booking/paid-report",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "financehead",
          "saleshead",
          "financeanalyst",
          "financeassociate",
          "operationshead"
          ,"operationsmanager"
          ,"operationsanalyst"
          ,"salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "presalehead",
          // "presalestl", 
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "pnlhead"
        ]
      },
      {
        name: "Raise Invoice",
        url: "/booking/raise-invoice",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "financehead",
          "financeanalyst",
          "financeassociate"
        ]
      },
      {
        name: "Audit Leads",
        url: "/leads/audit",
        icon: "ApartmentIcon",
        color: colorConfig.grey,
        user_role: [
          "admin",
          "financehead",
          "operationsmanager",
          "operationshead",
          "operationsanalyst"
        ]
      },
      {
        name: "Booking Details Report",
        url: "/report/booking-detail",
        icon: "ApartmentIcon",
        color: colorConfig.booked_leads,
        user_role: [
          "admin",
          "financehead",
          "mediamanagers",
          "operationsanalyst",
          "operationshead",
          "financeanalyst",
          "financeassociate",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations"
          , "pnlhead"
          , "operationsmanager"
        ]
      },
      {
        name: "Digital Booking Report",
        url: "/report/digital-booking",
        icon: "ApartmentIcon",
        color: colorConfig.booked_leads,
        user_role: [
          "admin",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations"
        ]
      },
      {
        name: "Digital Activity Report",
        url: "/report/digital-activity",
        icon: "ApartmentIcon",
        color: colorConfig.booked_leads,
        user_role: [
          "admin",
          "mediamanagers",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations"
        ]
      },
      {
        name: "Bookings Passback",
        url: "/booking/passback",
        icon: "ApartmentIcon",
        color: colorConfig.all_leads,
        user_role: [
          "admin",
          "financehead",
          "operationshead",
          "financeanalyst",
          "financeassociate",
        ]
      },
    ],
    user_role: [
      "admin",
      "financehead",
      "saleshead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "closings1",
      "financeanalyst",
      "financeassociate",
      "operationsanalyst",
      "operationshead",
      "mediamanagers",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "magnet",
      "presalehead",
      "presalestl",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      ,"pnlhead"
      , "operationsmanager"
    ]
  },
  {
    name:"Report Issue",
    url: "/error",
    icon: "Error",
    has_group: 0,
    sub_url: [],
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "financehead",
      "presalehead",
      "presalestl",
      "mediamanagers",
      "magnetadmin", "magnetpnl",
      "magnettl",
      "magnetrm",
      "sourcings2",
      "financeanalyst",
      "financeassociate",
      "operationsanalyst",
      "operationshead",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "customerexperiencemanager",
      "hrhead",
      "magnet"
      ,"sourcings3",
      "magnet s2", 
      "magnet s3",
      "magnetmis"
      ,"closings1"
      ,"ithead"
      ,"operationsmanager"
      ,"customersuccess"
      ,"customerexperiencemanager"
      ,"magnetplatinum"
    ]
  },
  {
    name:"Home Loan",
    url: "/home-loan",
    icon: "Home",
    has_group: 1,
    sub_url: [],
    user_role: [
      "admin",
      "magnetadmin", "magnetpnl",
      "magnettl",
      "magnetrm",
      "magnet"
      ,"financeanalyst", "operationshead","operationsmanager", "saleshead"
    ]
  }
];

const user_routes = [
  {
    component: "magnetList",
    url: "/leads/:leadType/:contactNumber?",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
    "seniorsalestl", "financehead", "mediamanagers", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "magnetadmin", "magnetmis", "magnettl", "magnetrm", "magnet","operationsanalyst","operationshead","financeanalyst","financeassociate","operationsanalyst","operationsanalyst","campaignmanagers","campaignoperations","leadoperations","operationsmanager", "magnetplatinum","magnetpnl"]
  },
  {
    component: "HomeLoan",
    url: "/home-loan",
    user_role: ["admin","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet", "operationshead", "operationsmanager"
    ,"financeanalyst", "saleshead"
  ]
  },
  {
    component: "LeadInternalPage",
    url: "/lead-internal-history/:leadId",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
    "seniorsalestl", "financehead", "presalehead", "presalestl", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet", "operationsanalyst","financeanalyst","operationshead","financeassociate","campaignmanagers","campaignoperations","leadoperations"]
  },
  {
    component: "Projects",
    url: "/projects",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
    "seniorsalestl", "financehead","financeanalyst", "financeassociate", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet","campaignmanagers","campaignoperations","leadoperations"
    , "operationshead"
    , "operationsmanager"
    , "operationsanalyst"
  ]
  },
  {
    component: "AddProject",
    url: "/projects/add-update",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
    "seniorsalestl", "financehead", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetpnl", "magnettl", "magnetrm","campaignmanagers","campaignoperations","leadoperations","magnet"
    , "operationshead"
    , "operationsmanager"
  ]
  },
  {
    component: "AutoAssignPage",
    url: "/auto-assign-lead",
    user_role: ["saleshead", "pnlhead", "salesmanager", "presalehead", "presalestl", "admin","magnetadmin", "magnetpnl", "mediamanagers","campaignmanagers","campaignoperations","leadoperations","financehead",
    "financeanalyst",
    "financeassociate",
    "operationsanalyst",
    "operationshead"
  , "operationsmanager"]
  },
  {
    component: "LeadReport",
    url: "/report/:reportType",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
    "seniorsalestl", "financehead", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "mediamanagers","magnetadmin", "magnetmis", "magnetpnl", "magnettl", "magnetrm", "operationsanalyst","operationshead","operationsmanager","financeanalyst","financeassociate","campaignmanagers","campaignoperations","leadoperations", "customersuccess", "hrhead"]
  },
  {
    component: "MagnetTempUser",
    url: "/magnet-enroll",
    user_role: ["admin","magnetadmin", "magnetpnl", "magnettl", "magnetrm","customersuccess"]
  },
  {
    component: "Users",
    url: "/users",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager"
    , "salestl", 
    "seniorsalestl", "presalehead", "presalestl", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "hrhead","magnetadmin", "magnetpnl"
    , "operationsmanager", "operationshead"]
  },
  {
    component: "BulkFilesList",
    url: "/lead-pool/bulk-files",
    user_role: [ "admin","magnetadmin", "magnetmis", "magnetpnl", "sourcings2"]
  },
  {
    component: "BulkLeadList",
    url: "/lead-pool/:poolType",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager"
    , "salestl", 
    "seniorsalestl", "presalehead", "presalestl", "sourcings2", "sourcings3","magnetadmin", "magnetmis", "magnet s2", "magnet s3", "magnetpnl", "magnettl", "magnetrm"
    , "magnet", "closings1", "mediamanagers", "campaignmanagers", "campaignoperations", "leadoperations"
    , "operationshead", "operationsmanager"]
  },
  {
    component: "CpUserPage",
    url: "/magnet/:magnetType",
    user_role: ["admin","magnetadmin", "magnetpnl", "magnetmis", "magnettl", "magnetrm", "customersuccess"]
  },
  {
    component: "Dashboard",
    url: "/",
    user_role: ["admin", "miniadmin", "bangaloreadmin", "puneminiadmin", "saleshead", "pnlhead", "salesmanager", "salestl", 
    "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2", "sourcings3", "magnet s2", "magnet s3", "magnetadmin", "magnetpnl", "magnettl", "magnetrm", "magnet", 
    "operationshead"
    ,"operationsmanager"
    , "financeassociate"
  ] 
  },
  {
    component: "Register",
    url: "/register-update",
    user_role: ["magnet"]
  },
  {
    component: "HrDocs",
    url: "/hr-docs",
    user_role: ["admin", "hrhead"]
  },
  {
    component: "ViewHrDocs",
    url: "/hr-doc-view",
    user_role: [
      "admin",
          "saleshead",
          "pnlhead",
          "salesmanager",
          "salestl", 
          "seniorsalestl",
          "closings1",
          "presalehead",
          "presalestl",
          "sourcings2",
          "sourcings3",
          "magnet s2", 
          "magnet s3",
          "mediamanagers",
          "magnetadmin", "magnetpnl",
          "magnetmis",
          "magnettl",
          "magnetrm",
          "financehead",
          "financeanalyst",
          "financeassociate",
          "operationsanalyst",
          "operationshead",
          "campaignmanagers",
          "campaignoperations",
          "leadoperations",
          "operationsmanager",
          "hrhead",
          "ithead",
    ]
  },
  {
    component:"ViewHrDocs",
    url:"/hr-knowledge-piece",
    user_role: [
      "admin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "closings1",
      "financehead",
      "presalehead",
      "presalestl",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      "mediamanagers",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "financeanalyst",
      "financeassociate",
      "operationsanalyst",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "operationsmanager",
      "operationshead",
      "operationsanalyst",
      "hrhead",
      "ithead",
    ]
  },
  {
    component: "IvrManagement",
    url: "/ivr-management",
    user_role: ["admin", "presalehead", "salesmanager", "pnlhead", "saleshead"
    ,"operationsmanager", "operationshead", ...digitalGroup] 
  },
  {
    component: "AddUpdateIVR",
    url: "/ivr-management/add-update",
    user_role: ["admin", "operationsmanager", "operationshead", ...digitalGroup]
  },
  {
    component: "MagnetRequest",
    url: "/magnet-request",
    user_role: ["admin", "magnet", "mediamanagers","magnetadmin", "magnetpnl"]
  },
  {
    component: "RequestProject",
    url: "/request-project",
    user_role: ["admin", "magnet"]
  },
  {
    component: "IvrLogs",
    url: "/ivr-management/call-logs",
    user_role: ["admin", "mediamanagers", "presalehead", "salesmanager", "pnlhead", "saleshead","campaignmanagers","operationsmanager","leadoperations", "operationshead"]
  },
  {
    component: "KnowlarityCallLogs",
    url: "/ivr-management/knowlarity-call-logs",
    user_role: ["admin", "operationshead", "operationsmanager", "sourcings2", "salesmanager", "pnlhead", "saleshead","magnetadmin", "magnetpnl","mediamanagers"]
  },
  {
    component: "CliManagementPage",
    url: "/ivr-management/cli",
    user_role: ["admin", "operationshead", "operationsmanager","mediamanagers"]
  },
  {
    component: "ReferPage",
    url: "/referral",
    user_role: ["admin", "magnet"]
  },
  {
    component: "FocusProject",
    url: "/focus-projects",
    user_role: [...magnetGroup, "admin", "operationshead", "operationsmanager", "operationsanalyst"]
  },
  {
    component: "ReferalUserList",
    url: "/referral-user",
    user_role: ["admin", "magnet","magnetadmin", "magnetpnl"]
  },
  {
    component: "UserFocusProjects",
    url: "/user-focus-project",
    user_role: ["admin", "superuser","magnetadmin", "magnetpnl"]
  },
  {
    component: "IncentiveUpdate",
    url: "/incentive-update",
    user_role: [
      "admin"
      , "financeanalyst"
      , "financeassociate"
      , "financehead"
      , "operationshead"
    ]
  },
  {
    component: "EligibilityPercentages",
    url: "/eligibility-percentages",
    user_role: [
      "admin"
      , "financeanalyst"
      , "financeassociate"
      , "financehead"
    ]
  },
  {
    component: "VerifyBooking",
    url: "/booking/:section",
    user_role: [
      "admin",
      "financehead",
      "financeanalyst",
      "financeassociate",
      "operationshead",
      "saleshead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "closings1",
      "presalehead",
      "presalestl",
      "sourcings2",
      "sourcings3",
      "magnet s2", 
      "magnet s3",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "magnet",
      "pnlhead"
      , "operationsmanager"
      , "operationsanalyst"
    ]
  },

  {
    component: "FinanceCalendar",
    url: "/finance-calendar",
    user_role: [
      "admin",
      "financeanalyst",
      "financeassociate",
      "financehead",
      "operationshead"
    ]
  },
  {
    component: "MagnetBudget",
    url: "/magnet-budget/:section",
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "magnetadmin", "magnetpnl",
      "mediamanagers",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations"
    ]
  },
  {
    component: "Role",
    url: "/miscellaneous/role",
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "magnetadmin", "magnetpnl"
    ]
  }, {
    component: "genericSearch",
    url: "/search/genericSearch/:client_id",
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "magnetadmin", "magnetpnl",
      "pnlhead", "salesmanager", "salestl", 
      "seniorsalestl", "presalehead", "presalestl", "closings1", "sourcings2"
      , "sourcings3", "magnet s2", "magnet s3", "magnettl", "magnetrm", "magnet","operationsmanager", "operationsanalyst","operationshead","leadoperations"
    ]
  }, {
    component: "LeadHomePage",
    url: "/lead/details/:client_id/:lead_id",
    user_role: [
        "admin",
        "miniadmin",
        "bangaloreadmin",
        "puneminiadmin",
        "saleshead",
        "pnlhead",
        "salesmanager",
        "salestl", 
        "seniorsalestl",
        "presalehead",
        "presalestl",
        "closings1",
        "sourcings2",
        "sourcings3",
        "magnet s2", 
        "magnet s3",
        "mediamanagers",
        "magnetadmin", "magnetpnl",
        "magnettl",
        "magnetrm",
        "magnet",
        "campaignmanagers",
        "campaignoperations",
		"leadoperations",
		"operationsmanager",
		"operationshead",
        "operationsanalyst",
        "magnetplatinum"
    ]
  },
  {
    component:"RerportError",
    url:"/error",
    user_role: [
      "admin",
      "miniadmin",
      "bangaloreadmin",
      "puneminiadmin",
      "saleshead",
      "pnlhead",
      "salesmanager",
      "salestl", 
      "seniorsalestl",
      "financehead",
      "presalehead",
      "presalestl",
      "mediamanagers",
      "magnetadmin", "magnetpnl",
      "magnetmis",
      "magnettl",
      "magnetrm",
      "sourcings2",
      "financeanalyst",
      "financeassociate",
      "operationsanalyst",
      "operationshead",
      "campaignmanagers",
      "campaignoperations",
      "leadoperations",
      "customerexperiencemanager",
      "hrhead",
      "magnet"
      ,"sourcings3",
      "magnet s2", 
      "magnet s3",
      ,"closings1"
      ,"ithead"
      ,"operationsmanager"
      ,"customersuccess"
      ,"customerexperiencemanager"
      , "magnetplatinum"
    ]
  }
];

export {
  magnet_rm_cc_mail_id,
  magnet_admin_bcc_mail_id,
  user_access,
  user_routes
};
