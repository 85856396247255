import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { PROJECT_DOCUMENT_LAST_YEAR, QUARTERS } from "../../config/constant";
import { saveProjectDocuments, getProjectDocuments } from "../../dataParser/getProjectData";
import SweetAlert from "../common/sweetAlert/sweetAlertSuccess";
import pdf2base64 from 'pdf-to-base64';
import BookingDocPreviewer from "../booking/bookingDocPreviewer";
import {getCurrentQuarter} from "../../helper";
import moment from "moment";

let initialState = {
    year: Number(new Date().getFullYear()) ,
    quarter: getCurrentQuarter(),
    project_document: [],
    sweetShow: false,
    sweetTitle: "",
    sweetType: "",
    showViewDocumentModal: false,
    View_file_path: '',
    docExten: ''
}

const ProjectDocModal = (props) => {
    const {show, project_id, documentModalType, handleDocumentClose} = props;

    const [state, setState] = useState(initialState);
    useEffect (() => {
        if(documentModalType === 'view'){
        getDocumentList()
        }
        else{
            setState({...state, project_document: []})
        }
    },[])
    const getDocumentList = async() => {
        const res = await getProjectDocuments(project_id, state.year, state.quarter, documentModalType);
        const projectDoc = [...res.data];
        setState({...state, project_document: [...projectDoc]})
    }
    const handleSweet = () => {
        setState({ ...state, sweetShow: !state.sweetShow });
        handleDocumentClose();

    }
    const handleChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const handleModalClose = () => {
        setState({...state, showViewDocumentModal: false });
      }
    const onFileSelect = (e) => {
        let {project_document} = state;
        let obj ={};
        obj.filename = e.target.files[0].name;
        obj.file = e.target.files[0];
        project_document.push(obj)
        setState({...state, project_document})
    }
    const viewDocument = async (url) => {
          if(url.includes("pdf")){
            pdf2base64(url).then((response) => {
                setState({ ...state, View_file_path: response, docExten: 'pdf', showViewDocumentModal: true });
              }).catch((error) => {
                console.error(error); //Exepection error....
              })
          }
          else{
              setState({...state, View_file_path: url, docExten: 'image', showViewDocumentModal: true});
          }
    }
    const handleCancelButton = (value) =>{
        let {project_document} = state;
        project_document.splice(value, 1)
        setState({...state, project_document})
    }
    const saveDocument = async(e) => {
        // e.preventDefault()
        let formData = new FormData();
        if (state.project_document.length > 0) {
            state.project_document.map(item => {
              if (item.id != null) {
                return false;
              }
              let obj =
              {
                id: item.id
                  ? item.id
                  : null,
                name: item.filename
              }
              formData.append(`project_document`, item.file);
            })
          }
         
          formData.append("year", state.year);
          formData.append("quarter", state.quarter);
          formData.append("project_id", props.project_id)
          formData.append("is_ladder_document", 1)
          let getRes = await saveProjectDocuments(formData);
          if (getRes.meta.status === 201) {
            setState({
              ...state,
              sweetShow: true,
              sweetType: "success",
              sweetTitle: "Uploaded successfully !!!"
            })
          }
    }
    const yearArray = [];
    for(let i = 2020; i<=PROJECT_DOCUMENT_LAST_YEAR; i++){
            yearArray.push(i);
    }
    return(
        <>
            <Modal show={show}
                onHide={handleDocumentClose}
                size="xl"
                className="projectDoc">
               <Modal.Header closeButton>
                   <Modal.Title>Project Ladder Document</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <div className='row'>
                       <div className='col-2'>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="year"
                                value={state.year}
                                label="year"
                                onChange={handleChange}
                            >
                                {yearArray.map(item => {
                                    return (<MenuItem value={item}>{item}</MenuItem>)
                                })}
                            </Select>
                       </div>
                       <div className="col-2">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="quarter"
                                value={state.quarter}
                                label="quarter"
                                onChange={handleChange}
                            >
                                {QUARTERS.map(item => {
                                    return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                                })
                                }
                            </Select>
                       </div>
                        {documentModalType === "view" &&
                            <div className='col-2'>
                                <button
                                    type="button"
                                    className="btn btn-success text-capitalize"
                                    onClick={getDocumentList}
                                >
                                    Search
                                </button>
                            </div>}
                   </div>
                    {state.project_document.map((item, index) => {
                        return (
                            <div className='row'>
                                <div className={documentModalType==='upload'?'col-12 mt-4': 'col-5 mt-4'}>
                                    {item.filename}
                                    <span
                                        className="fa-link ml-1"
                                    >
                                        {documentModalType == 'view' ? (
                                            <FontAwesomeIcon icon={faEye} className="nav-icon" onClick={() => viewDocument(item.file_name_url)} />
                                        ) :
                                            <FontAwesomeIcon icon={faTimesCircle} className="nav-icon" onClick={() => handleCancelButton(index)} />
                                        }
                                    </span>
                                </div>
                                {documentModalType ==='view' &&
                                    <div className='col-4 mt-4'>
                                        Upload date: {moment(item.created_at).format('DD/MM/YYYY, HH:mm:ss')}
                                    </div>}
                                    {documentModalType ==='view' &&
                                    <div className='col-3 mt-4'>
                                        Added by: {item.Created_By.name}
                                    </div>}
                            </div>)
                    })}
                    {(state.project_document.length == 0) &&
                        <div className='row'>
                            <span className="text-muted mt-3 ml-2">
                                Document Not Available !!!
                            </span>
                        </div>
                    }
                    {documentModalType == 'upload' &&
                        (<>
                            <div className='row'>
                                <div className='m-3'>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        name={`${state.project_document}`}
                                        onChange={(e) => onFileSelect(e)}
                                        inputProps={{
                                            multiple: true
                                        }}
                                        disabled={state.project_document.length >= 4}
                                    />
                                </div>
                            </div>
                            {state.project_document.length >= 4 &&
                                <div className='row'>
                                    <span style={{ color: "red" }}>**Cannot upload more than 4 documents at a time</span>
                                </div>}
                        </>)}
               </Modal.Body>
               <Modal.Footer className='justify-content-center'>
                    <div className='row'>
                        <div className='col-md-12 col-sm-12 text-center col-12'>
                            {documentModalType == "upload" &&
                            <button
                                type="submit"
                                className="btn btn-success text-capitalize"
                                onClick={saveDocument}
                            >
                                Submit
                            </button>}
                        </div>
                    </div>
               </Modal.Footer>
           </Modal>
           {state.showViewDocumentModal &&
            <BookingDocPreviewer
                showModal={state.showViewDocumentModal}
                handleModalClose={handleModalClose}
                ViewFilePath={state.View_file_path} docExten={state.docExten}
                modalName="Project's Ladder Document View" />}
            <SweetAlert show={state.sweetShow} type={state.sweetType} title={state.sweetTitle} changeSweet={handleSweet} />
        </>
    );
}

export default ProjectDocModal;