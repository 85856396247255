import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Frag from "../../../utils/Frag/Frag.js";
import AssignForm from "../form/assignForm";

class AssignModalForm extends Component {
	render() {
		return (
			<Frag>
				<Modal
					show={this.props.show}
					onHide={this.props.changeModal}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="unselectable"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Assign RM
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AssignForm 
						changeModal={this.props.changeModal} 
						leadId={this.props.leadId} 
						getList={this.props.getList}
					/>
					</Modal.Body>
				</Modal>
			</Frag>
		);
	};
};

export default AssignModalForm;