import React, { Component } from "react";
import Loading from "../../component/common/loader/loader.js";
import { userLogin } from "../../dataParser/auth.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Redirect } from "react-router-dom";
import "./login.css";
import { Link } from "react-router-dom";
import "../../assets/css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie, setCookie } from "../../utils/cookie.js";
import { faMobileAlt, faLock } from "@fortawesome/free-solid-svg-icons";
import { GROUPS, ROLES } from "../../config/constant";
import queryString from "query-string";
import { SITE_LOGO ,APP_BASE_URL} from "../../config/serverKey";
// import {cleanStorage} from "../../helper";

class Login extends Component {
  constructor(props) {
    super(props);

    let login = getCookie("login");
    let password = getCookie("pwd");
    let remember = getCookie("remember");

    this.state = {
      loggedIn: false,
      phone: login !== "" && login !== null ? login : "",
      password: password !== "" && password !== null ? password : "",
      rememberMe: remember === "1" ? true : false,
      loginError: false,
      loginErrorMessage: "",
      logData: "",
      showLoader: false
    };

    let homesfy_lg = localStorage.getItem("homesfy_lg");
    if (homesfy_lg && homesfy_lg !== "") {
      this.props.history.push("/");
    }
  }

  componentDidMount(){
      // let leadName = this.props.match.params.ref_id;
      let queryParams  = queryString.parse(this.props.location.search);
      
      if(queryParams.redirect_url !== undefined && queryParams.redirect_url !== "") this.setState({redirect_url : queryParams.redirect_url});
  }

  onChange = e => {
    if (e.target.name === "rememberMe") {
      this.setState({ rememberMe: !this.state.rememberMe });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onLogin = async e => {
    e.preventDefault();
    const loginRequest = (({ phone, password }) => ({
      phone,
      password
    }))(this.state);

    if (
      loginRequest.phone !== "" &&
      loginRequest.phone.length === 10 &&
      loginRequest.password !== "" &&
      loginRequest.password.length >= 6
    ) {
      if (this.state.rememberMe) {
        setCookie("login", loginRequest.phone, 1);
        setCookie("pwd", loginRequest.password, 1);
        setCookie("remember", 1, 1);
      }
      loginRequest.phone = parseInt(loginRequest.phone);
      this.setState({ showLoader: true });
      var resData = await userLogin(loginRequest);

      if (resData.meta.status === 200) {
        resData.data.userData.role = ROLES[resData.data.userData.role]
          .split("_")
          .join("")
          .toLowerCase();
        resData.data.userData.group = GROUPS[
          resData.data.userData.group
        ].toLowerCase();
        // console.log(resData.data)
        let time = new Date();
        resData.data.userData.expire = time.setMinutes(time.getMinutes()+60);

        window.$user = resData.data.userData;
        window.$role = window.$user.role.toLowerCase();
        localStorage.setItem(
          "homesfy_lg",
          window.btoa(JSON.stringify(resData.data.userData))
        );
        this.setState({
          showLoader: false,
          loggedIn: true,
          logData: resData.data
        });
      } else if (resData.meta.error) {
        this.toggleLoginError(resData.meta.message);
      }
    } else {
      this.toggleLoginError();
    }

    // e.preventDefault();
  };

  toggleLoginError = message => {
    // console.log("meassageXXXXXXXXXXXX",message);
    if (message && message !== "") {
      this.setState({
        loginErrorMessage: message,
        showLoader: false,
        loginError: true
      });
    } else {
      this.setState({
        loginErrorMessage: "",
        showLoader: false,
        loginError: true
      });
    }

    // let loginFailedInterval = setInterval(() => {
    //   this.setState({ loginError: false });
    //   clearInterval(loginFailedInterval);
    // }, 5000);
  };

  render() {
    let errorMessageElement;

    // console.log("loginnnnnnnnnnn>>>>>>>>",this.state.redirect_url);

    if (this.state.logData?.userData?.role) {
      // console.log(this.state.logData?.userData?.role,"=============hr==================");
      var role = this.state.logData?.userData?.role.toLowerCase();
    }

    if (this.state.loggedIn) {
      if(this.state.redirect_url){
         return <Redirect to={{pathname: this.state.redirect_url}} />;
      }else{
          if (role === "admin" || role === "magnetplatinum") {
            return <Redirect to="/leads/new" />;
          } else if (role === "hrhead") {
            return <Redirect to="/users" />;
          } else if (role === "financehead" || role === "financeanalyst" || role === "financeassociate" || role === "operationshead") {
            return <Redirect to="/leads/booked" />;
          } else if (role === "operationsanalyst") {
            return <Redirect to="/leads/audit" />;
          } else {
            return <Redirect to="/" />;
          }
      }
      // else{
      //     		let message = "please verify your email first then try logged In !!!";
      //     		cleanStorage();
      // 	errorMessageElement = (
      //        	<div className="col-12 text-center mt-2">
      //          		<small className="text-danger font-weight-bold">{message}</small>
      //      		 	</div>
      //      	);
      // }
    }

    //   if (this.state.loggedIn) {
    // console.log("in the erroroororrooror 0000000000");
    //     		return <Redirect to="/register-update" />;
    //   }

    if (this.state.loginError) {
      let message =
        this.state.loginErrorMessage.length > 0
          ? this.state.loginErrorMessage
          : "Please enter a valid username and password.";
          errorMessageElement = (
          message == "Your Password Has Expired and Must Be Changed" ?
        <div className="col-12 text-center px-0 mt-2">
         <small className="text-danger font-weight-bold">
              <div>{message}</div>  
              <a href={APP_BASE_URL+"magnet-set-password"}>Click Here</a>              
              <span className="text-secondary">&nbsp;&nbsp;to change Password</span> 
          </small>
        </div>
         :
         <div className="col-12 text-center px-0 mt-2">
         <small className="text-danger font-weight-bold">
              <div></div>{message}
          </small>
          </div>
       
      );
    }

    return (
      <div className="login-page">
        <div className="login-box">
          <Loading show={this.state.showLoader} />
          <div className="card">
            <div className="card-header p-0">
              <div className="login-logo pt-3 pb-3">
                <img className="from_img" src={SITE_LOGO} alt="siteImage" />
              </div>
            </div>
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form action="#" method="post">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className={
                      "form-control" +
                      " " +
                      (this.state.loginError ? "is-invalid" : "")
                    }
                    onChange={this.onChange}
                    value={this.state.phone}
                    placeholder="Mobile Number"
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon
                        icon={faMobileAlt}
                        className="text-success fs-20"
                      />
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className={
                      "form-control" +
                      " " +
                      (this.state.loginError ? "is-invalid" : "")
                    }
                    onChange={this.onChange}
                    value={this.state.password}
                    placeholder="Password"
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faLock} className="text-success" />
                    </div>
                  </div>
                  {errorMessageElement}
                </div>
                <div className="row pb-2">
                  <div className="col-8">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.is_tc_agree}
                          onChange={this.onChange}
                          name="rememberMe"
                          color="primary"
                        />
                      }
                      label="Remember Me"
                    />
                  </div>
                  <div className="col-4">
                    <button
                      type="submit"
                      onClick={this.onLogin}
                      className="btn btn-success btn-block"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
              <p className="mb-1">
                <Link to="/magnet-set-password">I forgot my password</Link>
              </p>
              {/* <p className="mb-0">
                <Link to="/register">Register as Magnet</Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
