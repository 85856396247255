import React from 'react'
import { getLeadSourceData } from '../../dataParser/commomDataApi';
import SelectOption from '../common/controls/SelectOption';
import InputField from '../common/controls/InputField';
import { getAutoCompleteProject, getRegion } from '../../dataParser/getProjectData';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CircularProgress from "@material-ui/core/CircularProgress";

const keys = {
    number: "",
    source_id: "",
    region_id: "",
    project_id: ""
}
const initialState = {
    ...keys,
    source: [],
    region: [],
    project: [],
    selectedVal: {}
}
const IvrFilter = (props) => {
    const [state, setState] = React.useState(initialState);

    const { number, source, source_id, region, region_id, project, project_id, selectedVal } = state;

    React.useEffect(() => {
        getApiData();
    }, [])

    const getApiData = async () => {
        
        let sourceResp = await getLeadSourceData();

        if (sourceResp.meta.status === 200) {
            setState({
                ...state, source: sourceResp.data,
                ...props.selectedFilters,
            });
        } else {
            setState({ ...state, source: [] })
        }

        let regionResp = await getRegion();
        setState({
            ...state, region: regionResp, 
            source: sourceResp.data,
            ...props.selectedFilters
        });

    }

    const handleProjectChange = async e => {
        setState({ ...state, isLoading: true });
        let value = e.target.value;
        let getData = await getAutoCompleteProject(value);
        if (getData.meta.status === 200) {
            setState({ ...state, isLoading: false, project: getData.data })
        } else {
            setState({ ...state, isLoading: false, project: [] })
        }
    };

    const handleChange = e => {
        let { name, value } = e.target;
        if (name === "number") {
            value = value.replace(/\D/g, "")
        }
        setState({ ...state, [name]: value })
    }

    const variant = "standard";

    const applyFilter = () => {
        let filterData = {};
        if (number) filterData['number'] = number;
        if (source_id) filterData['source_id'] = source_id;
        if (region_id) filterData['region_id'] = region_id;
        if (project_id) filterData['project_id'] = project_id;
        props.changeFilter();
        props.filterData(filterData, state.selectedVal);
    }

    const resetFilter = () => {
        setState({
            ...state,
            ...keys,
            selectedVal: {},
        })
    }

    return (
        <div
            className={`filterMenu ${props.show ? `filterMenuShow` : ``}`}
            style={{ zIndex: 9 }}
        >
            <div className="container filter_lead filterMenuCard py-4 px-0">
                <div className="row filterRow">
                    <div className="col-6 mb-3">
                        <InputField
                            variant={variant}
                            name="number"
                            value={number}
                            label="IVR Number"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-6 mb-3">
                        <SelectOption
                            variant={variant}
                            name="source_id"
                            value={source_id}
                            label="Source"
                            options={source}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-6 mb-3">
                        <SelectOption
                            variant={variant}
                            name="region_id"
                            value={region_id}
                            label="Region"
                            options={region}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <Autocomplete
                            // key={autoKey}
                            id="projectData"
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={option => option.name}
                            options={project}
                            value={selectedVal}
                            // loading={isLoading}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip label={option.name} {...getTagProps({ index })} />
                                ))
                            }
                            onChange={(_event, option) => {
                                console.log(option,"option");
                                if (option === null) {
                                    setState({...state, project: [], project_id: "", selectedVal: {} })
                                }
                                else{
                                    setState({...state,
                                        project_id: option.id,
                                        selectedVal: {id: option?.id, name: option?.name}
                                    })
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Project Name"
                                    fullWidth
                                    onChange={handleProjectChange}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {state.isLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-12 bgCommon">
                <div className="row">
                    <div className="col-sm-6 text-right col-6 mt-3">
                        <div className="form-group">
                            <button
                                className="btn btn-success"
                                onClick={applyFilter}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-6 mt-3">
                        <div className="form-group">
                            <button
                                className="btn btn-danger"
                                onClick={resetFilter}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IvrFilter