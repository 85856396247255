import React from 'react';

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Box from './Box';
import Grid from './Grid';
import Typography from './Typography';
import InputLabel from './InputLabel';
import MenuItem from './MenuItem';
import FormControl from './FormControl';
import Select from './Select';

import './NavigationButtonSidebarGroup.sass';
import clsx from 'clsx';

import {UserPortContext} from './Providers';
import { fieldHiddenSales } from '../config/constant/crmRoleFunctionPermission';


const SALES_TEAM = [...fieldHiddenSales, "salesmanager"];

const defaultState = {
}


const NavigationButtonSidebarGroup = (props) => {
    const {active, handleChange} = {...props}
    const [isSticky, setSticky] = React.useState(false);

    const [state, setState] = React.useState({
        ...defaultState,
        active
    });
    
    const _handleChange = (event) => {
        setActive(event.target.value)
    }

    const setActive = section => {
        handleChange(section)
    }

    const matches = React.useContext(UserPortContext)

    const stick = React.useRef();

    const handleScroll = () => {
        if(stick.current){
            setSticky(stick.current.getBoundingClientRect().top <= 0);
        }
    }

    React.useEffect(() => {
        if(matches !== "xs"){
            return
        }
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', () => handleScroll)

    }, [matches])

    if(matches === "xs"){
        return <Box className="NavigationButtonSidebarGroup">
            <div className={clsx({['stick-me']: isSticky})} ref={stick}>
                <div className="dd-w">
                    <div>
                    <FormControl fullWidth variant="outlined" className="drop-down">
                        <Select
                            className="drop-down-comp"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={active}
                            onChange={_handleChange}
                        >
                            <MenuItem value="Activity">Activity</MenuItem>
                            <MenuItem value="Booking">Booking</MenuItem>
                            <MenuItem value="Budget">Budget</MenuItem>
                            <MenuItem value="Details">Details</MenuItem>
                            {!SALES_TEAM.includes(window.$user.role) && <MenuItem value="Marketing">Marketing</MenuItem>}
                            <MenuItem value="Profile">Profile</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                </div>
            </div>
        </Box>
    }

    return <Box className="NavigationButtonSidebarGroup">
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Card className={clsx('card-button', {['active']: active === 'Activity'})} onClick={() => setActive('Activity')}>
                    <CardContent>
                        <Typography variant="h6" align="center" className="button-caption">Activity</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={clsx('card-button', {['active']: active === 'Booking'})} onClick={() => setActive('Booking') }>
                    <CardContent>
                        <Typography variant="h6" align="center" className="button-caption">Booking</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={clsx('card-button', {['active']: active === 'Budget'})} onClick={() => setActive('Budget') }>
                    <CardContent>
                        <Typography variant="h6" align="center" className="button-caption">Budget</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={clsx('card-button', {['active']: active === 'Details'})} onClick={() => setActive('Details')}>
                    <CardContent>
                        <Typography variant="h6" align="center" className="button-caption">Details</Typography>
                    </CardContent>
                </Card>
            </Grid>
            {!SALES_TEAM.includes(window.$user.role) && 
            <Grid item xs={6}>
                <Card className={clsx('card-button', {['active']: active === 'Marketing'})} onClick={() => setActive('Marketing') }>
                    <CardContent>
                        <Typography variant="h6" align="center" className="button-caption">Marketing</Typography>
                    </CardContent>
                </Card>
            </Grid>
            }
            <Grid item xs={6}>
                <Card className={clsx('card-button', {['active']: active === 'Profile'})} onClick={() => setActive('Profile') }>
                    <CardContent>
                        <Typography variant="h6" align="center" className="button-caption">Profile</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>
}


export default NavigationButtonSidebarGroup;
