import React, { Component } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import Frag from "../../../utils/Frag/Frag.js";
import {
  getUserFocus
} from "../../../dataParser/getProjectData";
import { letterCase } from "../../../helper";
import Loader from "../../../component/common/loader/loader";
import Pagination from "react-js-pagination";
import Tooltip from "@material-ui/core/Tooltip";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

class userFocusProjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      sweetShow: false,
      type: "success",
      title: "",
      showLoader: false,
      addModalShow: false,
      showFilter: false,
      meta: props.projectDataValue.meta,
      data: props.projectDataValue.data["projects"],
      modalShow: false,
      fullDetail: "",
      updateModalShow: false,
      isDesktop: 0
    };
    window.addEventListener("resize", this.update);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        meta: nextProps.projectDataValue.meta,
        data: nextProps.projectDataValue.data["projects"],
        activePage: 1
      });
    }
  }

  update = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };

  genericGetListData = async (...params) => {
    this.setState({ showLoader: true });
    let listData = {};
    listData.size = 8;
    listData.pageId = params[0].pageNumber || params[0].pageId || 1;

    (async () => {
      try {
        let resData = await getUserFocus(listData);
        await this.setState({
          meta: resData.meta,
          data: resData.data["projects"],
          showLoader: false
        });
        window.scrollTo(0, 0);
      } catch (e) {
        //...handle the error...
      }
    })();
  };

  handleChangePage = async pageNumber => {
    this.setState({
      showLoader: true,
      activePage: pageNumber
    });
    let listData = {};
    listData.filters = [];
    listData.size = 8;
    listData.pageId = pageNumber;

    this.genericGetListData(listData);
  };

  handleSweet = () => {
    this.setState({ sweetShow: !this.state.sweetShow });
  };

  render() {
    const { count, size, status } = this.state.meta;
    const { sweetShow, type, title } = this.state;
    const isDesktop = this.state.screenWidth;

    return (
      <Frag>
        {isDesktop > 991 ? (
          <div
            className={
              "content-wrapper" +
              " " +
              (this.props.expand ? "main-header-collapsed" : "")
            }
          >
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <Loader show={this.state.showLoader} />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Interested : </span>
                <span>{status === 400 ? "0" : count}</span>
              </div>
              {/*<div className="d-inline ml-3 filterHeader">
                                <span className="filter" onClick ={this.handlePopUp}>
                                    <FontAwesomeIcon icon={faFilter} className="nav-icon" />
                                </span>
                                <div className={"filterMenu" + " "+ (this.state.showFilter ? 'filterMenuShow' : '')} ref={node => { this.node = node; }}>
                                    <ProjectFilter filterData ={this.handleFilter} changeFilter={this.handlePopUp}/>
                                </div>
                        </div>*/}
              {/*<SpeedDialProject/>*/}
            </div>
            {status === 200 ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <Row>
                    <Col>
                      <Card>
                        <Card.Header>
                          <Card.Title as="h5">Magnet Interests</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Project Name</th>
                                <th>User Name</th>
                                <th>Mobile Number</th>
                                <th>Campaign Type</th>
                                <th className="text-center">Invest Now</th>
                                <th className="text-center">Interested</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.map((projectsList, index) => (
                                <tr key={index}>
                                  <th scope="row">{projectsList.project_id}</th>
                                  <td className="text-capitalize">
                                    {projectsList.Project != null
                                      ? letterCase(projectsList.Project.name)
                                      : "-"}
                                  </td>
                                  <td className="text-capitalize">
                                    {projectsList.Requester != null
                                      ? letterCase(projectsList.Requester.name)
                                      : "-"}
                                  </td>
                                  <td className="text-capitalize">
                                    {projectsList.Requester != null
                                      ? projectsList.Requester.phone
                                      : "-"}
                                  </td>
                                  <td className="text-capitalize">
                                  {projectsList?.magnetDigitalSpends?.length > 0 ?
                                    projectsList?.magnetDigitalSpends?.map((item) => item?.CampaignType?.name).join(', ')
                                    :
                                    "-"
                                  }
                                  </td>
                                  <td className="text-capitalize text-center">
                                    {projectsList.invest === true ? (
                                      <CheckCircleOutlineIcon className="text-success" />
                                    ) : (
                                      <HighlightOffIcon className="text-danger" />
                                    )}
                                  </td>
                                  <td className="text-capitalize text-center">
                                    {projectsList.is_interested === true ? (
                                      <CheckCircleOutlineIcon className="text-success" />
                                    ) : (
                                      <HighlightOffIcon className="text-danger" />
                                    )}
                                  </td>
                                  <td className="text-capitalize">
                                    <Tooltip title="Notify Digital">
                                      <button className="btn btn-success">
                                        <NotificationsActiveIcon />
                                      </button>
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <div className="row justify-content-end">
                    {this.state.meta["count"] > 8 ? (
                      <div className="paginationBlock">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={size}
                          totalItemsCount={count}
                          pageRangeDisplayed={5}
                          onChange={this.handleChangePage}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No Projects Found !!!</h5>
              </div>
            )}
          </div>
        ) : (
          <div
            className={
              "content-wrapper" +
              " " +
              (this.props.expand ? "main-header-collapsed" : "")
            }
          >
            <SweetAlert
              show={sweetShow}
              type={type}
              title={title}
              changeSweet={this.handleSweet}
            />
            <Loader show={this.state.showLoader} />
            <div className="p-3 leadMainHeader">
              <div className="headerCount">
                <span className="totalCount">Total Interested : </span>
                <span>{status === 400 ? "0" : count}</span>
              </div>
            </div>
            {status === 200 ? (
              <div className="listContainer">
                <div className="container-fluid">
                  <div className="row">
                    {this.state.data.map((projectsList, index) => (
                      <div className="MainList" key={projectsList.project_id}>
                        <div className="container-fluid">
                          <div className="ListContent card mb-3">
                            <div className="row mb-2">
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="text-muted">ID </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="valueText">
                                      {projectsList.project_id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead pl-0">
                                    <span className="text-muted">
                                      Project Name
                                    </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 fold-lead pl-0">
                                    <span className="valueText text-capitalize">
                                      {projectsList.Project != null
                                        ? letterCase(projectsList.Project.name)
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="text-muted">
                                      User Name{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 pr-0 fold-lead">
                                    <span className="valueText text-capitalize">
                                      {projectsList.Requester != null
                                        ? projectsList.Requester.name
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead pl-0">
                                    <span className="text-muted">
                                      Mobile Number
                                    </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 fold-lead pl-0">
                                    <span className="valueText text-capitalize">
                                      {projectsList.Requester != null
                                        ? projectsList.Requester.phone
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row pt-2">
                                  <div className="col-md-12 col-sm-12 fold-lead">
                                    <span className="text-muted">
                                      Campaign Type
                                    </span>
                                  </div>
                                  <div className="col-md-12 col-sm-12 fold-lead">
                                    <span className="valueText text-capitalize">
                                      {projectsList?.magnetDigitalSpends?.length > 0 ?
                                        projectsList?.magnetDigitalSpends?.map((item) => item?.CampaignType?.name).join(', ')
                                        :
                                        "-"
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-5  pt-2">
                                <div className="row">
                                  <div className="col-12 pr-0">
                                    <span className="fs-14 pr-2">
                                      Invest Now
                                    </span>
                                    {projectsList.invest === true ? (
                                      <CheckCircleOutlineIcon className="text-success" />
                                    ) : (
                                      <HighlightOffIcon className="text-danger" />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-5  pt-2">
                                <div className="row">
                                  <div className="col-12 pl-1 text-center">
                                    <span className="fs-14 pr-2">
                                      Interested
                                    </span>
                                    {projectsList.is_interested === true ? (
                                      <CheckCircleOutlineIcon className="text-success" />
                                    ) : (
                                      <HighlightOffIcon className="text-danger" />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="row">
                                  <div className="col-12 p-0">
                                    <Tooltip title="Notify Digital">
                                      <button className="btn btn-success">
                                        <NotificationsActiveIcon />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="container-fluid">
                      <div className="row justify-content-end">
                        {this.state.meta["count"] > 8 ? (
                          <div className="paginationBlock">
                            <Pagination
                              hideDisabled
                              activePage={this.state.activePage}
                              itemsCountPerPage={size}
                              totalItemsCount={count}
                              pageRangeDisplayed={5}
                              onChange={this.handleChangePage}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="main_area_display shadow card p-3">
                <h5>No Leads Found !!!</h5>
              </div>
            )}
          </div>
        )}
      </Frag>
    );
  }
}

export default userFocusProjectTable;
