import React, { Component } from "react";

import Role from "../../component/treeView/role.js";
import Loader from "../../component/common/loader/loader";
import Frag from "../../utils/Frag/Frag";

class RoleListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: "",
      showLoader: false
    };
  }

  render() {
    return (
      <Frag>
        <Loader show={this.state.showLoader} />
        <Role expand={this.props.sideBar} />
      </Frag>
    );
  }
}

export default RoleListPage;
