import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import TranslateIcon from '@material-ui/icons/Translate';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { autoComplete, getUserAutoComplete } from "../../../dataParser/commomDataApi.js";
import { UpdateLeadData } from "../../../dataParser/getListData";
import { cleanStorage, objEmpty, removeCommas } from "../../../helper";
import { STAGES, MIN_MAX_BUDGET, AV_LIMIT_LOWER, AV_VALIDATION_MSG_LOWER, AV_LIMIT_UPPER, AV_VALIDATION_MSG_UPPER, BED_CONFIG, BOOKING_AMOUNT_LIMIT, BOOKING_AMOUNT_MSG } from "../../../config/constant";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import EmailIcon from '@material-ui/icons/Email';
import GoogleMaps from "../googleMap/googleMap.js";
import GoogleMapsIntLoc from "../googleMap/googleMapIntLoc";
import RadioButtonGroup from '../controls/RadioGroup';
import { yesNoType } from '../datas/data.js';
import InputUnitField from '../controls/InputUnitField.js';
import PopUpAlert from '../sweetAlert/popUpAlert.js';
import sweetWarnAlert from '../sweetAlert/sweetAlertWarning.js';
class bookedUpdateForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			project_addedby: '',
			lead_status_id: '',
			leadId: '',
			name: '',
			number: '',
			alternate_number: '',
			alternateProject: [],
			alternate_project_id: '',
			selectedVal: {},
			interest_in_project: '',
			project: [],
			project_id: '',
			bookedProj: {},
			email: '',
			property_for: '',
			nationality: null,
			current_location: '',
			interested_location: '',
			company: '',
			occupation: '',
			designation: '',
			language: '',
			is_married: '',
			// loan_sanction: '',
			birth_date: null,
			anniversary_date: null,
			loan_sanction: '',
			loan_required: 0,
			// revert_status: '',
			// sitevisit_date :null,
			// closed_date :null,
			// lead_sattus  :'',
			// occupation :'',
			// ethinicity :'',
			max_budget: '',
			min_budget: '',
			flat_type: '',
			carpet_area: '',
			booking_amount: '',
			flat_no: '',
			building_number: '',
			agreement_value: '',
			booking_amount_detail: '',
			loan_status: '',
			loan_amount: '',
			special_discount: '',
			passback: '',
			booking_date: null,
			required_bhk: '',
			option: [],
            bookingRm: [],
            booking_rm_id: '',
            bookedRM: {},
			isError: false,
			sweetShow: false,
			type: "success",
			title: "",
			errors: {
				name: '',
				number: '',
				alternate_number: '',
				email: '',
				// comment: '',
				property_for: '',
				nationality: '',
				current_location: '',
				interested_location: '',
				company: '',
				occupation: '',
				designation: '',
				language: '',
				is_married: '',
				// loan_sanction: '',
				birth_date: '',
				anniversary_date: '',
				loan_sanction: '',
				// revert_status: '',
				flat_type: '',
				carpet_area: '',
				booking_amount: '',
				flat_no: '',
				building_number: '',
				agreement_value: '',
				booking_amount_detail: '',
				loan_status: '',
				booking_date: '',
				loan_amount: '',
				special_discount: '',	
			},
			alertShow: false,
			alertMsg: "",
			readOnly:false,
			showCancel: true,
			showConfirm: true,
			passback_msg: '',
			sweetPassbackShow: false
		};
	}

	componentDidMount() {
		let userDataArr = [{ name: this.props.leadDetail.data?.AlternateProject?.name, id: this.props.leadDetail.data?.AlternateProject?.id }]
		let bookingProject = [{ name: this.props.leadDetail.data?.Booking?.BookingProject?.name, id: this.props.leadDetail.data?.Booking?.BookingProject?.id }]
		let interestedProject = [{ name: this.props.leadDetail.data.Project?.name, id: this.props.leadDetail.data.Project?.id }]
		let SalesRMBooking = [{name: this.props.leadDetail.data.Booking?.SalesRMBooking?.name, id: this.props.leadDetail.data.Booking?.SalesRMBooking?.id, phone: this.props.leadDetail.data.Booking?.SalesRMBooking?.phone}]
		if(this.props.leadDetail.data.Booking){
			this.props.leadDetail.data.ClientBudgetInfo.agreement_value = this.props.leadDetail.data.Booking.agreement_value; 
		}
		this.setState({
			leadDetail: this.props.leadDetail.data,
			name: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client?.name || '',
			number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client?.number || '',
			alternate_number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client?.alternate_number || '',
			email: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client?.email || '',
			revert_status: this.props.leadDetail.data.revert_status || '',
			lead_status_id: STAGES.BOOKED,
			property_for: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo?.property_for || '',
			min_budget: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo?.min_budget || '',
			max_budget: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo?.max_budget || '',
			required_bhk: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo?.required_bhk || '',
			interested_location: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo?.interested_location || '',
			nationality: this.props.clientData.data.nationality || null,
			current_location: this.props.clientData.data.current_location || '',
			occupation: this.props.clientData.data.occupation || '',
			ethinicity: this.props.clientData.data.ethinicity || '',
			company: this.props.clientData.data.company || '',
			designation: this.props.clientData.data.designation || '',
			language: this.props.clientData.data.language || '',
			is_married: this.props.clientData.data.is_married || '',
			birth_date: this.props.clientData.data.birth_date || null,
			anniversary_date: this.props.clientData.data.anniversary_date || null,
			alternateProject: userDataArr[0].name !== undefined ? userDataArr : [],
			selectedVal: userDataArr[0].name !== undefined ? userDataArr[0] : {},
			interest_in_project: this.props.leadDetail.data.Project !== null && this.props.leadDetail.data.Project?.name || '',
			// nationality : this.props.leadDetail.data.Client.nationality,
			project: bookingProject[0].name !== undefined ? bookingProject : [],
			bookedProj: bookingProject[0].name !== undefined ? bookingProject[0] : {},
			project_id: bookingProject[0].id !== undefined ? bookingProject[0].id : '',
			agreement_value: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.agreement_value || '',
			booking_amount: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.booking_amount || '',
			booking_amount_detail: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.booking_amount_detail || '',
			booking_date: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.booking_date || null,
			building_number: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.building_number || '',
			carpet_area: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.carpet_area || '',
			flat_type: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.flat_type || '',
			flat_no: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.flat_no || '',
			loan_sanction: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.loan_sanction || '',
			loan_status: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.loan_status || '',
			special_discount: this.props.leadDetail.data.Booking !== null && this.props.leadDetail.data.Booking.special_discount || '',
			passback: (this.props.leadDetail.data.Booking != null && this.props.leadDetail.data.Booking.passback !=null) ? this.props.leadDetail.data.Booking.passback: null,
			readOnly:(this.props.leadDetail.data.Booking != null && this.props.leadDetail.data.Booking.passback !=null)? true: false,
			loan_required: this.props.leadDetail.data.loan_required ? 1 : 0,
			loan_amount: this.props.leadDetail.data.loan_amount || '',
			comment: this.props.leadDetail.data.ObjectStage !== null && this.props.leadDetail.data.ObjectStage.comments || '',
			comment_added: this.props.leadDetail.data.ObjectStage !== null ? this.props.leadDetail.data.ObjectStage.comments : '',
			bookingRM: SalesRMBooking[0].name !== undefined ? SalesRMBooking : [],
			bookedRM: SalesRMBooking[0]?.name !== undefined ? SalesRMBooking[0] : {},
			// lead_status_id : this.props.leadDetail.data.ObjectStage.stage_id,
		});
	}

	updateLeadFrom = async (e) => {
		if(this.state.readOnly){
			e.preventDefault();
		}
		let updateData = {};
		updateData.Client = {};
		updateData.Client.ClientBudgetInfo = {};
		updateData.ObjectStage = {};
		updateData.Booking = {};

		if (this.state.agreement_value !== '') {
			updateData.Client.ClientBudgetInfo["agreement_value"] = removeCommas(this.state.agreement_value);
			updateData.Booking["agreement_value"] = removeCommas(this.state.agreement_value);
		}

		if (this.state.booking_amount_detail !== '') {

			updateData.Booking["booking_amount_detail"] = this.state.booking_amount_detail;
		}

		if (this.state.loan_status !== '') {

			updateData.Booking["loan_status"] = parseInt(this.state.loan_status);
		}

		if (this.state.booking_date !== null) {

			updateData.Booking["booking_date"] = this.state.booking_date;
		}

		if (this.state.loan_sanction !== '') {

			updateData.Client.ClientBudgetInfo["loan_sanction"] = parseInt(this.state.loan_sanction);
		}

		if (this.state.is_married !== '') {

			updateData.Client["is_married"] = parseInt(this.state.is_married);
		}

		updateData["loan_required"] = parseInt(this.state.loan_required) ? 1 : 0;

		if (this.state.language !== '') {

			updateData.Client["language"] = this.state.language;
		}

		if (this.state.name !== '') {

			updateData.Client["name"] = this.state.name;
		}

		if (this.state.interested_location !== '') {

			updateData.Client.ClientBudgetInfo["interested_location"] = this.state.interested_location;
		}

		if (this.state.company !== '') {

			updateData.Client["company"] = this.state.company;
		}

		if (this.state.designation !== '') {

			updateData.Client["designation"] = this.state.designation;
		}

		// if(this.state.ethinicity !== ''){

		//    	updateData.Client["interested_location"] = this.state.interested_location;
		// }

		if (this.state.email !== '') {

			updateData.Client["email"] = this.state.email;
		}

		if (this.state.number !== '') {

			updateData.Client["number"] = this.state.number;
		}
		if (this.state.alternate_number !== '') {

			updateData.Client["alternate_number"] = this.state.alternate_number;
		}

		if (this.state.property_for !== '') {

			updateData.Client.ClientBudgetInfo["property_for"] = this.state.property_for;
		}

		if (this.state.nationality !== '') {

			updateData.Client["nationality"] = this.state.nationality;
		}

		if (this.state.current_location !== '') {

			updateData.Client["current_location"] = this.state.current_location;
		}


		if (this.state.required_bhk !== "") {

			updateData.Client.ClientBudgetInfo["required_bhk"] = this.state.required_bhk;
		}

		if (this.state.max_budget !== "") {

			updateData.Client.ClientBudgetInfo["max_budget"] = this.state.max_budget;
		}

		if (this.state.min_budget !== "") {

			updateData.Client.ClientBudgetInfo["min_budget"] = this.state.min_budget;
		}

		if (this.state.occupation !== "") {

			updateData.Client["occupation"] = this.state.occupation;
		}

		if (this.state.birth_date !== null) {

			updateData.Client["birth_date"] = this.state.birth_date.split("/").join("-");
		}

		if (this.state.anniversary_date !== null) {

			updateData.Client["anniversary_date"] = this.state.anniversary_date.split("/").join("-");
		}

		if (this.state.comment !== '') {

			updateData.ObjectStage["comment"] = this.state.comment;
		}

		updateData.ObjectStage["stage_id"] = STAGES.BOOKED;

		updateData.Booking["passback"] = this.state.passback? this.state.passback: null;
		
		if (this.state.special_discount !== "") {
			updateData.Booking["special_discount"] = removeCommas(this.state.special_discount);
		   
		}
		if (this.state.flat_type !== "") {

			updateData.Booking["flat_type"] = this.state.flat_type;
		}

		if (this.state.carpet_area !== "") {

			updateData.Booking["carpet_area"] = this.state.carpet_area;
		}

		if (this.state.booking_amount !== "") {
			
			updateData.Booking["booking_amount"] = removeCommas(this.state.booking_amount);
		}

		if (this.state.flat_no !== "") {

			updateData.Booking["flat_no"] = this.state.flat_no;
		}

		if (this.state.building_number !== "") {

			updateData.Booking["building_number"] = this.state.building_number;
		}

		// if (this.state.revert_status !== null && this.state.revert_status !== "") {

		// updateData["revert_status"] = parseInt(this.state.revert_status);
		// }
		if (this.state.alternate_project_id !== null && this.state.alternate_project_id !== "") {

			updateData["alternate_project_id"] = parseInt(this.state.alternate_project_id);
		}

		if (this.state.loan_amount !== "") {

			updateData["loan_amount"] = removeCommas(this.state.loan_amount);
		}
		updateData.Booking["booking_project_id"] = parseInt(this.state.project_id)

        if (this.state.booking_rm_id !== "") {

            updateData.Booking["booking_rm_id"] = this.state.booking_rm_id;
        }
        // console.log(this.state.passback === 1 ? this.state.special_discount !== "" : true)
        // console.log(updateData,'data')
		if (this.state.name !== "" &&
			this.state.number !== "" &&
			// this.state.email !== "" &&
			this.state.property_for !== "" &&
			this.state.company !== "" &&
			this.state.required_bhk !== "" &&
			this.state.interested_location !== "" &&
			this.state.current_location !== "" &&
			this.state.booking_amount !== "" &&
			this.state.min_budget !== "" &&
			this.state.max_budget !== "" &&
			this.state.flat_type !== "" &&
			this.state.flat_no !== "" &&
			this.state.building_number !== "" &&
			this.state.booking_date !== null &&
			this.state.carpet_area !== "" &&
			this.state.language !== "" &&
			this.state.agreement_value !== "" &&
			this.state.comment !== "" &&
			this.state.project_id !== "" &&
			(this.state.passback === 1 ? this.state.special_discount !== "" : true)
		) {
			if(removeCommas(this.state.agreement_value) < AV_LIMIT_LOWER){
				this.setState({
					alertShow: true,
					alertType: "error",
					alertMsg: AV_VALIDATION_MSG_LOWER
				})
				return false
			}
			else if(removeCommas(this.state.agreement_value) > AV_LIMIT_UPPER){
				this.setState({
					alertShow: true,
					alertType: "error",
					alertMsg: AV_VALIDATION_MSG_UPPER
				})
				return false				
			}
			if(removeCommas(this.state.booking_amount) > BOOKING_AMOUNT_LIMIT){
				this.setState({
					alertShow: true,
					alertType: "error",
					alertMsg: BOOKING_AMOUNT_MSG
				})
				return false
			}
			let addLeadRes = await UpdateLeadData(this.state.leadDetail.id, updateData);
			// console.log("addLeadRes XXXXXXXXXXXX",addLeadRes);

			if (addLeadRes.meta.status === 201) {

				// console.log("Lead insert successfully !!!");

				this.setState({
					sweetShow: true,
					type: "success",
					title: "Lead Updated Successfully!!!"

				});

			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					sweetShow: true,
					type: "error",
					title: addLeadRes.meta.message
				});
			}

		} else {
			this.setState({
				alertShow: true,
				alertType: "error",
				alertMsg: "Above * fields are mandatory, Please fill the details*"
			})
		}
  }

	indianRupeeFormat = (param) => {
		param = param.toLocaleString('en-IN');
		return param
	}
	onChange = async(e) => {

		await this.setState({ [e.target.name]: e.target.value });
	}
	getDisabled = (value) => {
		if(value){
			return {disabled:true};}
		else
		return {}
	}
	handleNumberChange = (e) => {
		let {name, value, validity: {valid}} = e.target;
		if (valid || value === ""){
			this.setState({ [name]: value });
		}
	};	

	handleSweet = () => {
		this.setState({ sweetShow: false, sweetPassbackShow: false });
		if(this.state.type !== "info"){
		this.props.changeModal()
		this.props.getList();
		}
		if(this.state.type === "info"){
			this.updateLeadFrom()
		}
		// window.location.reload();
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

	handleGeoLocation = async (current_location) => {

		// console.log("<=============in the current_location============>",current_location);
		this.setState({ current_location: current_location });
	}

	AutoChangeProject = async (e) => {
		this.setState({ isLoading: true });
		// let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				await this.setState({
					isLoading: false,
					alternateProject: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}

	AutoBookingProject = async (e) => {
		this.setState({ isLoading: true });
		// let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				await this.setState({
					isLoading: false,
					project: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}
	handleGeoLocationIntrest = async (interested_location) => {

		// console.log("<=============in the interested_location============>",interested_location);
		this.setState({ interested_location: interested_location });
	}

    handleAutoEnrollChange = async (e) => {
        let data = {
            q: e.target.value,
            roles: [5, 6, 7, 8, 10, 13, 14, 15, 20, 21]
        };
        let resData = await getUserAutoComplete(data);
        // console.log("resData.dataXXXXXXXXXXXXXXXX",resData.data);
        if (resData.meta.status === 200) {

            this.setState({ bookingRm: resData.data })
        } else if (resData.meta.status === 401) {

            cleanStorage();
            this.props.history.push("/login");

        } else {

            this.setState({ bookingRm: resData.data })
        }
    }
	handleAlertClose = () =>{
		this.setState({ alertShow: false })
	}
	openSweetAlert = (e) => {
		e.preventDefault();
		const temp = parseInt(removeCommas(this.state.special_discount))
	    if(this.state.passback && this.state.special_discount == 0){
			this.setState({ sweetPassbackShow: true, title: `You cannot enter yes in passback and 0 in special discount`, type: "info" })
		}
		else if (!temp) {
			this.setState({ sweetPassbackShow: true, title: `Do you want to add Passback/Special discount?`, type: "info" })
		}
		else {
			const sp_comma_separated = this.indianRupeeFormat(temp);
			this.setState({ sweetPassbackShow: true, title: `Is the passback amount ${sp_comma_separated} entered correct?`, type: "info" });
		}
	}
	handleCancel = () => {
		this.setState({sweetShow: false, sweetPassbackShow: false})
	}
	// handleConfirm = () =>{
	// 	this.setState({sweetShow: !this.state.sweetWarnShow});
	// 	this.updateLeadFrom();
	// }
	render() {
		let {
			sweetShow,
			type,
			title,
			nationality,
			current_location,
			interested_location,
			company,
			occupation,
			designation,
			language,
			is_married,
			birth_date,
			anniversary_date,
			loan_sanction,
			loan_required,
			revert_status,
			isLoading,
			alternateProject,
			selectedVal,
			interest_in_project,
			project,
			bookedProj,
			flat_type,
			carpet_area,
			booking_amount,
			flat_no,
			building_number,
			required_bhk,
			min_budget,
			max_budget,
			agreement_value,
			booking_amount_detail,
			loan_status,
			booking_date,
            autoKey,
            option,
			loan_amount,
			bookingRm,
			bookedRM,
			passback,
			special_discount,
			readOnly,
			alertShow,
			alertType,
			alertMsg,
			sweetPassbackShow
		} = this.state;
		// console.log("<=============in the render============>",this.state.lead_status_id +'  -  '+ this.props.selectedFormId);
        
		if(passback == true)
		{
			passback = 1;
		}
		else if(passback == false)
		{
			passback = 0;
		}
		return (
			<Frag>
				<div className="projectForm">
					{sweetPassbackShow && (
						<SweetAlert
							type={type}
							show={sweetPassbackShow}
							title={title}
							html
							showCancel={type = "info" ? true : false}
							showConfirm={(passback && special_discount==0)?false:true}
							changeSweet={this.handleSweet}
							onCancel={this.handleCancel}
							confirmButtonColor="#a5dc85"
							confirmBtnText={!passback? "No" : "OK"}
							cancelBtnText={!passback? "Yes" : "Cancel"}
						>
							{title}
						</SweetAlert>
					)
					}
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={this.handleAlertClose} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.errors.name !== '' ? true : false}
											required
											id="name"
											name="name"
											label="Client Name"
											value={this.state.name ? this.state.name : ''}
											helperText={this.state.errors.name !== '' ? this.state.errors.name : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircle />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										required
										disabled
										error={this.state.errors.number !== '' ? true : false}
										id="number"
										name="number"
										value={this.state.number ? this.state.number : ''}
										label="Client Number"
										helperText={this.state.errors.number !== '' ? this.state.errors.number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											// error = {this.state.errors.email !== '' ? true : false}
											// required
											id="email"
											name="email"
											label="Client Email"
											value={this.state.email ? this.state.email : ''}
											// helperText={this.state.errors.email !== '' ? this.state.errors.email : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										id="alternate_number"
										name="alternate_number"
										value={this.state.alternate_number ? this.state.alternate_number : ''}
										label="Alternate Number"
										helperText={this.state.isError.alternate_number !== '' ? this.state.isError.alternate_number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										error={this.state.errors.company !== '' ? true : false}
										required
										id="company"
										name="company"
										value={this.state.company ? this.state.company : ''}
										label="Client company"
										helperText={this.state.errors.company !== '' ? this.state.errors.company : ''}
										onChange={this.onChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										error={this.state.errors.designation !== '' ? true : false}
										id="designation"
										name="designation"
										value={this.state.designation ? this.state.designation : ''}
										label="Client Designation"
										helperText={this.state.errors.designation !== '' ? this.state.errors.designation : ''}
										onChange={this.onChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
									/>
								</div>
                                <div className="col-sm-6 mb-3">
                                    <FormControl>
                                        <Autocomplete
                                            getOptionSelected={(bookingRm, value) => bookingRm.name === value.name}
                                            getOptionLabel={bookingRm => objEmpty(bookingRm) === false && bookingRm.name+'('+bookingRm?.phone+')'}
                                            options={bookingRm}
                                            value={bookedRM}                                                
                                            onChange={(_event, bookingRm) => {
                                                if (bookingRm === null) {
														this.setState({
															bookingRm: [],
														})
													} else {
														this.setState({
															booking_rm_id: bookingRm.id,
															bookedRM: { name: bookingRm?.name, id: bookingRm?.id, phone: bookingRm?.phone }
														})
													}
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label="Booking RM"
                                                    fullWidth
                                                    onChange={this.handleAutoEnrollChange}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Bed Config</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={required_bhk}
											onChange={this.onChange}
											inputProps={{
												name: 'required_bhk',
												id: 'required_bhk',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{BED_CONFIG.map(item => (
												<MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                              				))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<GoogleMaps handleLocation={this.handleGeoLocation} autoLocation={this.state.current_location} errors={this.state.errors.current_location} />
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<GoogleMapsIntLoc handleLocation={this.handleGeoLocationIntrest} autoLocation={this.state.interested_location} errors={this.state.errors.interested_location} />
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											error={this.state.errors.name !== '' ? true : false}
											required
											placeholder="eg. Card, Cash etc."
											id="booking_amount_detail"
											name="booking_amount_detail"
											label="Booking Amount Detail"
											value={this.state.booking_amount_detail ? this.state.booking_amount_detail : ''}
											helperText={this.state.errors.booking_amount_detail !== '' ? this.state.errors.booking_amount_detail : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountBalanceWalletIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<InputUnitField
										required
										name="booking_amount"
										value={booking_amount}
										onChange={this.handleNumberChange}
										label="Booking Amount"	
									/>										
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Min budget</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={min_budget}
											onChange={this.onChange}
											inputProps={{
												name: 'min_budget',
												id: 'min_budget',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{MIN_MAX_BUDGET.map(item=>(
												<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Max budget</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={max_budget}
											onChange={this.onChange}
											inputProps={{
												name: 'max_budget',
												id: 'max_budget',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{MIN_MAX_BUDGET.map(item=>(
												<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											required
											id="flat_type"
											name="flat_type"
											value={flat_type ? flat_type : ''}
											onChange={this.onChange}
											label="Flat type"
											defaultValue=""
											helperText=""
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<ApartmentRoundedIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											required
											id="flat_no"
											name="flat_no"
											value={flat_no ? flat_no : ''}
											onChange={this.onChange}
											label="Flat Number"
											defaultValue=""
											helperText=""
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<BusinessRoundedIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											required
											id="building_number"
											name="building_number"
											value={building_number ? building_number : ''}
											onChange={this.onChange}
											label="Wing Number"
											defaultValue=""
											helperText=""
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<BusinessRoundedIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											required
											id="carpet_area"
											name="carpet_area"
											value={carpet_area ? carpet_area : ''}
											onChange={this.onChange}
											label="Carpet Area"
											defaultValue=""
											helperText=""
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<ApartmentRoundedIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>

								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Pass Back</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											name="passback"
											value={passback}
											onChange={this.onChange}
											{...this.getDisabled(readOnly)}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value={1}>Yes</MenuItem>
											<MenuItem value={0}>No</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-md-6 mb-3">
									<InputUnitField
										name="special_discount"
										value={special_discount}
										required={passback ? true : false}
										label="Special Discount"
										onChange={this.handleNumberChange}
										disabled={(readOnly || passback==false)?true:false}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Occupation</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={occupation}
											onChange={this.onChange}
											inputProps={{
												name: 'occupation',
												id: 'occupation',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="Admin/Secretarial">Admin/Secretarial</MenuItem>
											<MenuItem value="Advertising/ Entertainment/ Media">Advertising/ Entertainment/ Media</MenuItem>
											<MenuItem value="Agriculture">Agriculture</MenuItem>
											<MenuItem value="Architecture and Design">Architecture and Design</MenuItem>
											<MenuItem value="Artists,">Artists,</MenuItem>
											<MenuItem value="Animators and Web Designers">Animators and Web Designers</MenuItem>
											<MenuItem value="Banking,">Banking,</MenuItem>
											<MenuItem value="Insurance and Financial Services">Insurance and Financial Services</MenuItem>
											<MenuItem value="Beauty">Beauty</MenuItem>
											<MenuItem value="Fashion and Jewellery Designers">Fashion and Jewellery Designers</MenuItem>
											<MenuItem value="Business Owner / Entrepreneur">Business Owner / Entrepreneur</MenuItem>
											<MenuItem value="Civil Services / Law Enforcement">Civil Services / Law Enforcement</MenuItem>
											<MenuItem value="Construction">Construction</MenuItem>
											<MenuItem value="Customer Service/ Call Centre/BPO">Customer Service/ Call Centre/BPO</MenuItem>
											<MenuItem value="Defence">Defence</MenuItem>
											<MenuItem value="Education/ Training">Education/ Training</MenuItem>
											<MenuItem value="Electronics">Electronics</MenuItem>
											<MenuItem value="Export/ Import">Export/ Import</MenuItem>
											<MenuItem value="Finance and Accounts">Finance and Accounts</MenuItem>
											<MenuItem value="Government Employee">Government Employee</MenuItem>
											<MenuItem value="Health Care">Health Care</MenuItem>
											<MenuItem value="Hotels/ Restaurants">Hotels/ Restaurants</MenuItem>
											<MenuItem value="Human Resource">Human Resource</MenuItem>
											<MenuItem value="IT">IT</MenuItem>
											<MenuItem value="Legal">Legal</MenuItem>
											<MenuItem value="Loss Prevention Manager">Loss Prevention Manager</MenuItem>
											<MenuItem value="Management / Corporate Professionals">Management / Corporate Professionals</MenuItem>
											<MenuItem value="Manufacturing/ Engineering/ R&amp;D">Manufacturing/ Engineering/ R&amp;D</MenuItem>
											<MenuItem value="Marketing and Communications">Marketing and Communications</MenuItem>
											<MenuItem value="Merchant Navy">Merchant Navy</MenuItem>
											<MenuItem value="Non Working">Non Working</MenuItem>
											<MenuItem value="Oil &amp; Gas">Oil &amp; Gas</MenuItem>
											<MenuItem value="Others">Others</MenuItem>
											<MenuItem value="Pharmaceutical/ Biotechnology">Pharmaceutical/ Biotechnology</MenuItem>
											<MenuItem value="Purchase/ Logistics/ Supply chain">Purchase/ Logistics/ Supply chain</MenuItem>
											<MenuItem value="Real Estate">Real Estate</MenuItem>
											<MenuItem value="Retail Chains">Retail Chains</MenuItem>
											<MenuItem value="Sales/ Business Development">Sales/ Business Development</MenuItem>
											<MenuItem value="Science">Science</MenuItem>
											<MenuItem value="Telecom/ ISP">Telecom/ ISP</MenuItem>
											<MenuItem selected="" value="Travel/ Airlines">Travel/ Airlines</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Language</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={language}
											onChange={this.onChange}
											inputProps={{
												name: 'language',
												id: 'language',
												startAdornment: (
													<InputAdornment position="start">
														<TranslateIcon />
													</InputAdornment>
												),
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="Bengali">Bengali</MenuItem>
											<MenuItem value="Gujrati">Gujrati</MenuItem>
											<MenuItem value="Hindi">Hindi</MenuItem>
											<MenuItem value="Punjabi">Punjabi</MenuItem>
											<MenuItem value="Sindhi">Sindhi</MenuItem>
											<MenuItem value="Tamil">Tamil</MenuItem>
											<MenuItem value="Kannada">Kannada</MenuItem>
											<MenuItem value="Malyalam">Malyalam</MenuItem>
											<MenuItem value="Marathi">Marathi</MenuItem>
											<MenuItem value="Telugu">Telugu</MenuItem>
											<MenuItem value="Marwadi">Marwadi</MenuItem>
											<MenuItem value="Urdu">Urdu</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
												margin="normal"
												id="assigned_date_form"
												label="Birth Date"
												format="yyyy/MM/dd"
												maxDate={new Date()}
												name="birth_date"
												value={birth_date}
												onChange={(key, date) => this.handleDateChange('birth_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
												margin="normal"
												id="anniversary_date"
												label="Anniversary Date"
												format="yyyy/MM/dd"
												maxDate={new Date()}
												name="anniversary_date"
												value={anniversary_date}
												onChange={(key, date) => this.handleDateChange('anniversary_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disabled={(window.$role !== 'financehead' && window.$role !== 'admin' && window.$role !== "financeanalyst" && window.$role !== "financeassociate") && this.state.lead_status_id === this.props.selectedFormId ? true : false}																							
												autoOk={true}
												required
												margin="normal"
												id="booking_date"
												label="Booking Date"
												format="yyyy/MM/dd"
												maxDate={new Date()}
												name="booking_date"
												value={booking_date}
												onChange={(key, date) => this.handleDateChange('booking_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
								<div className="col-md-6 mb-3">
									<InputUnitField
										name="loan_amount"
										value={loan_amount}
										onChange={this.handleNumberChange}
										label="Loan Amount"
									/>
								</div>
								<div className="col-md-6 mb-3">
									<InputUnitField 
										name="agreement_value" 
										value={agreement_value} 
										required 
										label="Agreement Value" 
										onChange={this.handleNumberChange}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<FormLabel component="legend">Married</FormLabel>
										<RadioGroup row aria-label="is_married" name="is_married" value={is_married} onChange={this.onChange}>
											<FormControlLabel value="1" control={<Radio color="primary" checked={this.state.is_married == 1} />} label="Yes" />
											<FormControlLabel value="0" control={<Radio color="primary" checked={this.state.is_married == 0} />} label="No" />
										</RadioGroup>
									</div>
								</div>
								<div className="col-6 mb-3">
									<RadioButtonGroup
										name="loan_required"
										value={loan_required}
										label="Loan Required"
										onChange={this.onChange}
										items={yesNoType}
									/>
								</div>
								{/*<div className="col-sm-6 mb-3">
																	<div className="form-group">
																		<FormLabel component="legend">Revert Status</FormLabel>
																	      <RadioGroup row aria-label="revert_status" name="revert_status" value={revert_status} onChange={this.onChange}>
																	        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Yes" />
																	        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
																	      </RadioGroup> 
																	</div>
																</div>*/}
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<FormLabel component="legend">Nationality</FormLabel>
										<RadioGroup row aria-label="nationality" name="nationality" value={nationality} onChange={this.onChange}>
											<FormControlLabel value="1" control={<Radio color="primary" />} label="Indian" />
											<FormControlLabel value="2" control={<Radio color="primary" />} label="NRI" />
										</RadioGroup>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<FormLabel component="legend">Loan Sanction</FormLabel>
										<RadioGroup row aria-label="loan_sanction" name="loan_sanction" value={loan_sanction} onChange={this.onChange}>
											<FormControlLabel value="1" control={<Radio color="primary" checked={this.state.loan_sanction == 1} />} label="Yes" />
											<FormControlLabel value="0" control={<Radio color="primary" checked={this.state.loan_sanction == 0} />} label="No" />
										</RadioGroup>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<TextField id="standard-basic"
										disabled
										value={interest_in_project}
										label="Interest In Project"
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(project, value) => project.name === value.name}
										getOptionLabel={project => project.name}
										options={project}
										value={bookedProj}
										loading={isLoading}
										onChange={(_event, project) => {
											if (project === null) {
												this.setState({ project: [] })
											} else {
												this.setState({
													project_name: project.name,
													project_id: project.id,
													bookedProj: { name: project.name, id: project.id }
												})
											}
										}}
										renderInput={params => (
											<TextField
												required
												{...params}
												label="Booked Project"
												fullWidth
												onChange={this.AutoBookingProject}
												InputProps={{
													...params.InputProps,
													startAdornment: (
														<InputAdornment position="start">
															<BusinessRoundedIcon />
														</InputAdornment>
													),
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(alternateProject, value) => alternateProject.name === value.name}
										getOptionLabel={alternateProject => alternateProject.name}
										options={alternateProject}
										value={selectedVal}
										loading={isLoading}
										onChange={(_event, alternateProject) => {
											if (alternateProject === null) {
												this.setState({ alternateProject: [] })
											} else {
												this.setState({
													alternate_project_name: alternateProject.name,
													alternate_project_id: alternateProject.id,
													selectedVal: { name: alternateProject.name, id: alternateProject.id }
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												label="Alternate Project"
												fullWidth
												onChange={this.AutoChangeProject}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<TextField
											required
											select
											id="property_for"
											name="property_for"
											value={this.state.property_for ? this.state.property_for : ''}
											label="Property For"
											onChange={this.onChange}
										>
											<MenuItem value="Self">Self</MenuItem>
											<MenuItem value="Investment">Investment</MenuItem>
										</TextField>
									</div>
								</div>
								<div className="col-sm-12 col-12">
									<div className="form-group">
										<TextField
											disabled={(this.state.lead_status_id === this.props.selectedFormId && this.state.comment_added) ? true : false}
											// error={this.state.errors.comment !== '' ? true : false}
											required											
											id="standard-multiline-flexible"
											label="Comments"
											name="comment"
											value={this.state.comment}
											// helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
											multiline
											rowsMax="4"
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={readOnly? this.updateLeadFrom: this.openSweetAlert}>Submit</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(bookedUpdateForm);
