import React from 'react'
import RequestProject from '../../component/project/RequestProject'

const RequestProjectPage = ({ sideBar }) => {
    return (
        <div className={`bookings-container content-wrapper ${sideBar ? "main-header-collapsed" : ""
        }`}>
            <RequestProject />
        </div>
    )
}

export default RequestProjectPage
