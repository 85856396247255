import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";

import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import {
  getUserIncentiveReport,
  getSalesTeamMembers
} from "../../dataParser/getListUserData";

import Loader from "../common/loader/loader";

import {
  indianRupeeCommaSeparated,
  roundToTwoDecimalPlaces
} from "../../helper";

const group = { 4: "- Presale", 2: "- Sales", 1: "- Admin" };

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

class ExpandableRow extends Component {
  constructor() {
    super();

    this.state = {
      togglSpotAndComment: false
    };
  }

  render() {
    let o = this.props.rowObject;

    return (
      <>
        <TableRow>
          <TableCell className={`text-capitalize `}>
            {o.lead_id || "-"}
          </TableCell>
          <TableCell className={`text-capitalize`}>
            {o.client_name || "-"}
          </TableCell>
          <TableCell>{o.project_name || "-"}</TableCell>
          <TableCell>{o.booking_date || "-"}</TableCell>
          <TableCell>{o.eligibility_percentage || "-"}</TableCell>
          <TableCell className={`text-capitalize`}>
            {o.payment_amount || "-"}
          </TableCell>
          <TableCell>
            <div className="row">
              <div className="col-6 blue-pill">Part {o.payment_phase}</div>
              <div className="col-6">
                <FontAwesomeIcon
                  icon={
                    this.state.togglSpotAndComment ? faCaretUp : faCaretDown
                  }
                  className="fs-14 cursor-pointer"
                  onClick={() =>
                    this.setState({
                      togglSpotAndComment: !this.state.togglSpotAndComment
                    })
                  }
                />
              </div>
            </div>
          </TableCell>
        </TableRow>

        {this.state.togglSpotAndComment && (
          <TableRow>
            <TableCell className="p-1" colSpan={6}>
              <div className="row pl-4 py-3">
                <div className="col-6 p-1">
                  <strong>Spot Adjustment Amount</strong> -{" "}
                  {o.spot_adjustment_amount}
                </div>
                <div className="col-6 p-1">
                  <strong>Comments</strong> - {o.comments}
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }
}

export default class PaidIncentiveReport extends Component {
  constructor() {
    super();

    this.state = {
      showLoader: false,
      searchString: "",
      // userList: [],
      saleTeamMembers: [],
      selectedUser: null,
      month: "",
      months: this.generateMonths(),
      years: this.generateYears(),
      year: "",
      userIncentiveReport: []
    };
  }

  async componentDidMount() {
    var homesfy_lg = localStorage.getItem("homesfy_lg");
    if (homesfy_lg && homesfy_lg !== "") {
      let user = JSON.parse(window.atob(homesfy_lg));
      await this.setState({
        loggedInUser: user.id,
        role: user.role,
        selectedUser:
          user.role === "closings1" || user.role === "sourcings3" || user.role === "magnet s3" ? user : null,
        fromDate: moment().subtract(1, "months").startOf("month"),
        toDate: moment().subtract(1, "months").endOf("month")
      });
    }
  }

  generateMonths = () => {
    let months = [];
    for (let i = 1; i < 13; i++) {
      months.push({
        key: `${i}`.padStart(2, "0"),
        value: new Date(i.toString()).toLocaleString("default", {
          month: "long"
        })
      });
    }

    return months;
  };

  generateYears = () => {
    let years = [];
    for (
      let i = new Date().getFullYear() - 15;
      i <= new Date().getFullYear();
      i++
    ) {
      years.push(i);
    }

    return years;
  };

  getSalesTeamMembers = async q => {
    let foundSalesTeamMembers = await getSalesTeamMembers(q, this.state.parent);

    this.setState({
      saleTeamMembers: foundSalesTeamMembers ? foundSalesTeamMembers : []
    });
  };

  filterSalesTeamMembers = e => {
    if (e.target.value) {
      this.setState({ searchterm: e.target.value });
      this.getSalesTeamMembers(e.target.value);
    } else {
      this.setState({ searchterm: "", saleTeamMembers: [] });
    }
  };

  onUserSelect = user => {
    if (user) {
      this.setState({ selectedUser: { id: user.sales_rm } });
    } else {
      this.setState({ selectedUser: null, userIncentiveReport: [] });
    }
  };

  getUserIncentivesReport = () => {
    if (this.state.selectedUser && this.state.selectedUser.id) {
      this.setState({ showLoader: true }, async () => {
        let userIncentiveReport = await getUserIncentiveReport(
          this.state.selectedUser.id,
          this.state.month,
          this.state.year
        );
        this.setState({
          userIncentiveReport: userIncentiveReport.data
            ? userIncentiveReport.data
            : [],
          showLoader: false
        });
      });
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getTotalBookingIncentive = () => {
    let totalUserIncentive = 0;
    this.state.userIncentiveReport.forEach(o => {
      totalUserIncentive += o.payment_amount;
    });

    return totalUserIncentive;
  };

  getTotalBookings = () => {
    if (this.state.userIncentiveReport.length > 0) {
      return _.uniqBy(this.state.userIncentiveReport, "booking_id").length;
    } else {
      return 0;
    }
  };

  toggleSpotAndComment = () => {};

  render() {
    return (
      <div className="container-fluid">
        <Loader show={this.state.showLoader} />

        <div className="row pt-2 pl-1">
          {this.state.role !== "closings1" &&
          this.state.role !== "sourcings3"  &&
          this.state.role !== "magnet s3"? (
            <div className="col-md-3">
              <Autocomplete
                options={this.state.saleTeamMembers}
                getOptionLabel={option =>
                  `${
                    option["SalesRMBooking.first_name"]
                      ? option["SalesRMBooking.first_name"]
                      : ""
                  } ${
                    option["SalesRMBooking.last_name"]
                      ? option["SalesRMBooking.last_name"]
                      : ""
                  } ${
                    option["CrossSalesRMBooking.first_name"]
                      ? option["CrossSalesRMBooking.first_name"]
                      : ""
                  } ${
                    option["CrossSalesRMBooking.last_name"]
                      ? option["CrossSalesRMBooking.last_name"] +
                        " " +
                        group[option["CrossSalesRMBooking.group_id"]]
                      : ""
                  } ${
                    option["PresaleRm.first_name"]
                      ? option["PresaleRm.first_name"]
                      : ""
                  } ${
                    option["PresaleRm.last_name"]
                      ? option["PresaleRm.last_name"]
                      : ""
                  }`
                }
                onChange={(e, value) => this.onUserSelect(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    onChange={e => this.filterSalesTeamMembers(e)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="col-md-1">
            <FormControl>
              <InputLabel id="demo-controlled-open-select-label">
                Month
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                value={this.state.month}
                onChange={this.handleChange}
                inputProps={{
                  name: "month",
                  id: "month"
                }}
                className={"mt-0"}
              >
                {this.state.months.map(month => (
                  <MenuItem key={month.key} value={month.key}>
                    {month.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-md-1">
            <FormControl>
              <InputLabel id="demo-controlled-open-select-label">
                Year
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                value={this.state.year}
                onChange={this.handleChange}
                inputProps={{
                  name: "year",
                  id: "year"
                }}
                className={"mt-0"}
              >
                {this.state.years.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-md-1 ml-2">
            <button
              type="submit"
              className="btn btn-success text-capitalize"
              onClick={this.getUserIncentivesReport}
            >
              Search
            </button>
          </div>
        </div>

        <div
          className="row mt-3 pt-3 pb-1"
          style={{
            backgroundColor: "#000",
            color: "#fff"
          }}
        >
          <div className="col-md-4 col-4 text-center font-weight-bold">
            <h5>Total Amount Released</h5>{" "}
            <h3>
              {indianRupeeCommaSeparated(
                roundToTwoDecimalPlaces(this.getTotalBookingIncentive())
              )}
            </h3>
          </div>

          <div className="offset-4 col-md-4 col-4 text-center font-weight-bold">
            <h5>Number of Bookings</h5> <h3>{this.getTotalBookings()}</h3>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <TableContainer component={Paper} style={{ maxHeight: 600 }}>
              <Table
                aria-label="sticky table"
                className="tableCustom"
                style={{ minWidth: "650px" }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Client Name</StyledTableCell>
                    <StyledTableCell>Project</StyledTableCell>
                    <StyledTableCell>Booking Date</StyledTableCell>
                    <StyledTableCell>Eligibility Percentage</StyledTableCell>
                    <StyledTableCell>Incentive</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.userIncentiveReport.map((o, key) => (
                    <ExpandableRow key={key} rowObject={o} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}
