import { red } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react'
import { getBookingDate } from '../../../dataParser/getDashboardData';
import { numDifferentiation } from '../../../helper';

const LastBookingDate = () => {
    const [state, setState] = useState({
        bookingdate: []
    })
    useEffect(() => {
        handleBookingDate()
    }, [])
        
    const handleBookingDate = async () => {
        let getRes = await getBookingDate(window.$user.id);

        if (getRes.meta.status === 200) {
            setState({
                bookingdate: getRes.data
            })

        }
    }
    const { bookingdate } = state;
    return (
        <div className="d-flex">
            <div className="card py-1 text-center col-md-5 col-12" >
                <span className="text-capitalize" > Current Financial year Business : &nbsp; {numDifferentiation(bookingdate?.total_Count) ? numDifferentiation(bookingdate?.total_Count) : "No booking yet"}</span>
            </div>
            <div className="card py-1 text-center col-md-7 col-12" style={{marginLeft: 5}}>
                <span className="text-capitalize"> Current Financial year Business(Excluding Magnet) : &nbsp; {numDifferentiation(bookingdate?.magnet_lead_total) ? numDifferentiation(bookingdate?.magnet_lead_total) : "No booking yet"}</span>
            </div>
        </div>
    )
}

export default LastBookingDate
