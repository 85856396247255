import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
// import {Form} from 'react-bootstrap';
// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
// import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
// import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import TranslateIcon from '@material-ui/icons/Translate';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import {
	UpdateLeadData
} from "../../../dataParser/getListData";
import { BED_CONFIG, MIN_MAX_BUDGET, STAGES } from "../../../config/constant";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import EmailIcon from '@material-ui/icons/Email';
import GoogleMaps from "../googleMap/googleMap.js";
import GoogleMapsIntLoc from "../googleMap/googleMapIntLoc";
import { autoComplete } from "../../../dataParser/commomDataApi.js";
import {cleanStorage, removeCommas} from "../../../helper"
import RadioButtonGroup from '../controls/RadioGroup';
import { yesNoType } from '../datas/data.js';
import InputUnitField from '../controls/InputUnitField.js';

class cancelUpdateForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isError: false,
			lead_status_id: '',
			leadId: '',
			name: '',
			number: '',
			alternate_number: '',
			alternetProject: [],
			alternate_project_id: '',
			selectedVal: {},
			email: '',
			comment: '',
			property_for: '',
			nationality: null,
			current_location: '',
			company: '',
			interested_location: '',
			occupation: '',
			designation: '',
			language: '',
			is_married: '',
			loan_amount: '',
			birth_date: null,
			anniversary_date: null,
			loan_sanction: '',
			loan_required: 0,
			revert_status: '',
			cancellation_reason: '',
			// sitevisit_date :null,
			// closed_date :null,
			// lead_sattus  :'',
			// occupation :'',
			// ethinicity :'',
			max_budget: '',
			min_budget: '',
			// cancellation_reason :'',
			required_bhk: '',
			sweetShow: false,
			type: "success",
			title: "",
			errors: {
				name: '',
				number: '',
				alternate_number: '',
				email: '',
				// comment: '',
				property_for: '',
				nationality: '',
				current_location: '',
				company: '',
				interested_location: '',
				occupation: '',
				designation: '',
				language: '',
				is_married: '',
				loan_amount: '',
				birth_date: '',
				anniversary_date: '',
				loan_sanction: '',
				revert_status: '',
				cancellation_reason: '',
			}
		};
	}

	componentDidMount() {
		var userDataArr = [{ name: this.props.leadDetail.data?.AlternateProject?.name, id: this.props.leadDetail.data?.AlternateProject?.id }]
		this.setState({
			leadDetail: this.props.leadDetail.data,
			name: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.name || '',
			number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.number || '',
			alternate_number: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.alternate_number || '',
			email: this.props.leadDetail.data.Client !== null && this.props.leadDetail.data.Client.email || '',
			lead_status_id: STAGES.CANCEL,
			property_for: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.property_for || '',
			min_budget: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.min_budget || '',
			max_budget: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.max_budget || '',
			required_bhk: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.required_bhk || '',
			interested_location: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.interested_location || '',
			loan_sanction: this.props.leadDetail.data.ClientBudgetInfo !== null && this.props.leadDetail.data.ClientBudgetInfo.loan_sanction || '',
			nationality: this.props.clientData.data.nationality || null,
			current_location: this.props.clientData.data.current_location || '',
			occupation: this.props.clientData.data.occupation || '',
			ethinicity: this.props.clientData.data.ethinicity || '',
			company: this.props.clientData.data.company || '',
			designation: this.props.clientData.data.designation || '',
			language: this.props.clientData.data.language || '',
			is_married: this.props.clientData.data.is_married || '',
			cancellation_reason: this.props.leadDetail.data !== null && this.props.leadDetail.data.cancellation_reason || '',
			loan_amount: this.props.leadDetail.data.loan_amount || '',
			loan_required: this.props.leadDetail.data.loan_required ? 1 : 0,
			birth_date: this.props.clientData.data.birth_date || null,
			anniversary_date: this.props.clientData.data.anniversary_date || null,
			alternetProject: userDataArr[0].name !== undefined ? userDataArr : [],
			selectedVal: userDataArr[0].name !== undefined ? userDataArr[0] : {},
			comment: this.props.leadDetail.data.ObjectStage !==null && this.props.leadDetail.data.ObjectStage.comments || '',
			comment_added: this.props.leadDetail.data.ObjectStage !==null ? this.props.leadDetail.data.ObjectStage.comments : '',
			// nationality : this.props.leadDetail.data.Client.nationality,
			// lead_status_id : this.props.leadDetail.data.ObjectStage.stage_id,
		});

		// (async () => {
		//        try {
		// 		let resData = await getClientData(this.props.leadDetail.data.Client.id);
		//      await this.setState({
		//      	nationality : resData.data.nationality || null,
		//      	current_location : resData.data.current_location || '',
		//      	occupation : resData.data.occupation || '',
		// ethinicity : resData.data.ethinicity || '',
		// company: resData.data.company || '',
		// designation: resData.data.designation || '',
		// language: resData.data.language || '',
		// is_married: resData.data.is_married || '',
		// birth_date: resData.data.birth_date || null,
		// anniversary_date: resData.data.anniversary_date || null,
		// // loan_sanction: resData.data.loan_sanction || '',
		// // loan_required: resData.data.loan_required || '',
		//      }) ;
		//        } catch (e) {	
		//            //...handle the error...
		//        }
		//    })();
	}

	updateLeadFrom = async (e) => {

		e.preventDefault();
		// let upadateLeadReq = {};
		let leadId = this.state.leadId;

		// const addLeadRequest = (({lead_status_id,cancellation_reason,lead_sattus ,marital_status,current_interested_location,occupation,property_type,required_bhk,max_budget,min_budget,carpet_area,special_discount,booking_amonut,flat_type,wing_number,flat_number,ethinicity,sitevisit_date,closed_date}) => ({
		// 	lead_sattus ,
		// 	sitevisit_date,
		// 	lead_status_id,
		// 	closed_date,
		// 	ethinicity,
		// 	required_bhk,
		// 	max_budget,
		// 	min_budget,
		// 	cancellation_reason,
		// 	occupation
		//    }))(this.state);

		// console.log("addLeadRequest XXXXXXXXXXXX",addLeadRequest)

		let updateData = {};
		updateData.Client = {};
		updateData.Client.ClientBudgetInfo = {};
		updateData.ObjectStage = {};
		// updateData.Booking = {};

		// if(this.state.lead_status_id){

		//       	updateData["lead_status_id"] = this.state.lead_status_id;
		//   	}

		// if(this.state.lead_sattus != ""){

		//    	updateData["lead_sattus"] = this.state.lead_sattus;
		// }

		if (this.state.cancellation_reason != '') {

			updateData["cancellation_reason"] = this.state.cancellation_reason;
		}
		if (this.state.is_married != '') {

			updateData.Client["is_married"] = this.state.is_married;
		}

		// if(this.state.loan_sanction != ''){

		//    	updateData.Client.ClientBudgetInfo["loan_sanction"] = this.state.loan_sanction;
		// }

		if (this.state.language != '') {

			updateData.Client["language"] = this.state.language;
		}

		updateData["loan_required"] = parseInt(this.state.loan_required) ? 1 : 0;

		if (this.state.name != '') {

			updateData.Client["name"] = this.state.name;
		}

		if (this.state.interested_location != '') {

			updateData.Client.ClientBudgetInfo["interested_location"] = this.state.interested_location;
		}

		if (this.state.company != '') {

			updateData.Client["company"] = this.state.company;
		}

		if (this.state.designation != '') {

			updateData.Client["designation"] = this.state.designation;
		}

		// if(this.state.ethinicity != ''){

		//    	updateData.Client["interested_location"] = this.state.interested_location;
		// }

		if (this.state.email != '') {

			updateData.Client["email"] = this.state.email;
		}

		if (this.state.number != '') {

			updateData.Client["number"] = this.state.number;
		}
		if (this.state.alternate_number != '') {

			updateData.Client["alternate_number"] = this.state.alternate_number;
		}

		if (this.state.property_for != '') {

			updateData.Client.ClientBudgetInfo["property_for"] = this.state.property_for;
		}

		if (this.state.nationality != null) {

			updateData.Client["nationality"] = this.state.nationality;
		}

		if (this.state.current_location != '') {

			updateData.Client["current_location"] = this.state.current_location;
		}

		if (this.state.ethinicity != "") {

			updateData.Client["ethinicity"] = this.state.ethinicity;
		}

		if (this.state.required_bhk != "") {

			updateData.Client.ClientBudgetInfo["required_bhk"] = this.state.required_bhk;
		}

		if (this.state.max_budget != "") {

			updateData.Client.ClientBudgetInfo["max_budget"] = this.state.max_budget;
		}

		if (this.state.min_budget != "") {

			updateData.Client.ClientBudgetInfo["min_budget"] = this.state.min_budget;
		}

		if (this.state.occupation != "") {

			updateData.Client["occupation"] = this.state.occupation;
		}

		if (this.state.birth_date != null) {

			updateData.Client["birth_date"] = this.state.birth_date.split("/").join("-");
		}

		if (this.state.anniversary_date != null) {

			updateData.Client["anniversary_date"] = this.state.anniversary_date.split("/").join("-");
		}

		if (this.state.revert_status != null && this.state.revert_status != "") {

			updateData["revert_status"] = parseInt(this.state.revert_status);
		}

		if (this.state.alternate_project_id != null && this.state.alternate_project_id != "") {

			updateData["alternate_project_id"] = parseInt(this.state.alternate_project_id);
		}

		if (this.state.loan_amount != "") {

			updateData["loan_amount"] = removeCommas(this.state.loan_amount);
		}

		if (this.state.comment != '') {

			updateData.ObjectStage["comment"] = this.state.comment;
		}

		updateData.ObjectStage["stage_id"] = STAGES.CANCEL;

		// console.log("updateData.Client=======>",updateData)

		// upadateLeadReq.id = leadId;
		// upadateLeadReq.data = updateData;

		if (this.state.name != "" &&
			this.state.number != '' &&
			this.state.min_budget != '' &&
			this.state.max_budget != '' &&
			this.state.required_bhk != '' &&
			this.state.cancellation_reason != '' &&
			this.state.property_for != '' &&
			this.state.interested_location != '' &&
			this.state.current_location != '' &&
			this.state.comment != '') {

			var addLeadRes = await UpdateLeadData(this.state.leadDetail.id, updateData);
			// console.log("addLeadRes XXXXXXXXXXXX",addLeadRes);

			if (addLeadRes.meta.status === 201) {

				// console.log("Lead insert successfully !!!");

				this.setState({
					sweetShow: true,
					type: "success",
					title: "Lead Updated Successfully!!!"

				});

			} else if (addLeadRes.meta.status === 401) {

				cleanStorage();
				this.props.history.push("/login");

			} else {

				this.setState({
					sweetShow: true,
					type: "error",
					title: addLeadRes.meta.message
				});
			}
		} else {

			this.setState({ isError: true });
		}

	}

	handleGeoLocation = async (current_location) => {

		// console.log("<=============in the current_location============>",current_location);
		this.setState({ current_location: current_location });
	}

	handleGeoLocationIntrest = async (interested_location) => {

		// console.log("<=============in the interested_location============>",interested_location);
		this.setState({ interested_location: interested_location });
	}

	onChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}	

	handleNumberChange = (e) => {
		let {name, value, validity: {valid}} = e.target;
		if (valid || value === ""){
			this.setState({ [name]: value });
		}
	};	

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		this.props.getList();

		// window.location.reload();
	}

	AutoChangeProject = async (e) => {
		this.setState({ isLoading: true });
		// let value = e.target.value 
		// console.log("valueXXXXXXXXXXXXXXXX",value);
		(async () => {
			try {
				let data = {
					q: e.target.value
				};
				var resData = await autoComplete('projects', data);
				await this.setState({
					isLoading: false,
					alternetProject: resData.data
				});
			} catch (e) {
				//...handle the error...
			}
		})();
	}

	handleDateChange = (key, date) => {

		this.setState({ [key]: date });
	}

	render() {
		// nationality: '',
		// current_location: '',
		// interested_location: '',
		// company: '',
		// interested_location: '',
		// occupation: '',
		// designation: '',
		// language: '',
		// is_married: '',
		// // loan_sanction: '',
		// birth_date: '',
		// anniversary_date: '',
		// loan_sanction: '',
		// loan_required: '',
		// revert_status: '',
		// cancellation_reason: '',
		const { sweetShow, type, title, alternetProject, selectedVal, isLoading, loan_required, occupation, designation, language, is_married, birth_date, anniversary_date, loan_sanction, revert_status, cancellation_reason, company, nationality, current_location, required_bhk, max_budget, min_budget, interested_location, ethinicity, loan_amount} = this.state;
		// console.log("in the render", this.state);


		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											error={this.state.errors.name !== '' ? 'error' : ''}
											required
											id="name"
											name="name"
											label="Client Name"
											value={this.state.name ? this.state.name : ''}
											helperText={this.state.errors.name !== '' ? this.state.errors.name : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircle />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										required
										disabled
										error={this.state.errors.number !== '' ? 'error' : ''}
										id="number"
										name="number"
										value={this.state.number ? this.state.number : ''}
										label="Client Number"
										helperText={this.state.errors.number !== '' ? this.state.errors.number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											// error = {this.state.errors.email !== '' ? 'error' : ''}
											// required
											id="email"
											name="email"
											label="Client Email"
											value={this.state.email ? this.state.email : ''}
											// helperText={this.state.errors.email !== '' ? this.state.errors.email : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										id="alternate_number"
										name="alternate_number"
										value={this.state.alternate_number ? this.state.alternate_number : ''}
										label="Alternate Number"
										helperText={this.state.isError.alternate_number !== '' ? this.state.isError.alternate_number : ''}
										onChange={this.handleNumberChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
										inputProps={{pattern: "[0-9]*"}}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Bed Config</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={required_bhk}
											onChange={this.onChange}
											inputProps={{
												name: 'required_bhk',
												id: 'required_bhk',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{BED_CONFIG.map(item => (
												<MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                              				))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										error={this.state.errors.company !== '' ? 'error' : ''}
										required
										id="company"
										name="company"
										value={this.state.company ? this.state.company : ''}
										label="Client company"
										helperText={this.state.errors.company !== '' ? this.state.errors.company : ''}
										onChange={this.onChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Min budget</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={min_budget}
											onChange={this.onChange}
											inputProps={{
												name: 'min_budget',
												id: 'min_budget',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{MIN_MAX_BUDGET.map(item=>(
												<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label" required>Max budget</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={max_budget}
											onChange={this.onChange}
											inputProps={{
												name: 'max_budget',
												id: 'max_budget',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{MIN_MAX_BUDGET.map(item=>(
												<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
											))}
								        </Select>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Occuption</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={occupation}
											onChange={this.onChange}
											inputProps={{
												name: 'occupation',
												id: 'occupation',
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="Admin/Secretarial">Admin/Secretarial</MenuItem>
											<MenuItem value="Advertising/ Entertainment/ Media">Advertising/ Entertainment/ Media</MenuItem>
											<MenuItem value="Agriculture">Agriculture</MenuItem>
											<MenuItem value="Architecture and Design">Architecture and Design</MenuItem>
											<MenuItem value="Artists,">Artists,</MenuItem>
											<MenuItem value="Animators and Web Designers">Animators and Web Designers</MenuItem>
											<MenuItem value="Banking,">Banking,</MenuItem>
											<MenuItem value="Insurance and Financial Services">Insurance and Financial Services</MenuItem>
											<MenuItem value="Beauty">Beauty</MenuItem>
											<MenuItem value="Fashion and Jewellery Designers">Fashion and Jewellery Designers</MenuItem>
											<MenuItem value="Business Owner / Entrepreneur">Business Owner / Entrepreneur</MenuItem>
											<MenuItem value="Civil Services / Law Enforcement">Civil Services / Law Enforcement</MenuItem>
											<MenuItem value="Construction">Construction</MenuItem>
											<MenuItem value="Customer Service/ Call Centre/BPO">Customer Service/ Call Centre/BPO</MenuItem>
											<MenuItem value="Defence">Defence</MenuItem>
											<MenuItem value="Education/ Training">Education/ Training</MenuItem>
											<MenuItem value="Electronics">Electronics</MenuItem>
											<MenuItem value="Export/ Import">Export/ Import</MenuItem>
											<MenuItem value="Finance and Accounts">Finance and Accounts</MenuItem>
											<MenuItem value="Government Employee">Government Employee</MenuItem>
											<MenuItem value="Health Care">Health Care</MenuItem>
											<MenuItem value="Hotels/ Restaurants">Hotels/ Restaurants</MenuItem>
											<MenuItem value="Human Resource">Human Resource</MenuItem>
											<MenuItem value="IT">IT</MenuItem>
											<MenuItem value="Legal">Legal</MenuItem>
											<MenuItem value="Loss Prevention Manager">Loss Prevention Manager</MenuItem>
											<MenuItem value="Management / Corporate Professionals">Management / Corporate Professionals</MenuItem>
											<MenuItem value="Manufacturing/ Engineering/ R&amp;D">Manufacturing/ Engineering/ R&amp;D</MenuItem>
											<MenuItem value="Marketing and Communications">Marketing and Communications</MenuItem>
											<MenuItem value="Merchant Navy">Merchant Navy</MenuItem>
											<MenuItem value="Non Working">Non Working</MenuItem>
											<MenuItem value="Oil &amp; Gas">Oil &amp; Gas</MenuItem>
											<MenuItem value="Others">Others</MenuItem>
											<MenuItem value="Pharmaceutical/ Biotechnology">Pharmaceutical/ Biotechnology</MenuItem>
											<MenuItem value="Purchase/ Logistics/ Supply chain">Purchase/ Logistics/ Supply chain</MenuItem>
											<MenuItem value="Real Estate">Real Estate</MenuItem>
											<MenuItem value="Retail Chains">Retail Chains</MenuItem>
											<MenuItem value="Sales/ Business Development">Sales/ Business Development</MenuItem>
											<MenuItem value="Science">Science</MenuItem>
											<MenuItem value="Telecom/ ISP">Telecom/ ISP</MenuItem>
											<MenuItem selected="" value="Travel/ Airlines">Travel/ Airlines</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Language</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={language}
											onChange={this.onChange}
											inputProps={{
												name: 'language',
												id: 'language',
												startAdornment: (
													<InputAdornment position="start">
														<TranslateIcon />
													</InputAdornment>
												),
											}}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value="Bengali">Bengali</MenuItem>
											<MenuItem value="Gujrati">Gujrati</MenuItem>
											<MenuItem value="Hindi">Hindi</MenuItem>
											<MenuItem value="Punjabi">Punjabi</MenuItem>
											<MenuItem value="Sindhi">Sindhi</MenuItem>
											<MenuItem value="Tamil">Tamil</MenuItem>
											<MenuItem value="Kannada">Kannada</MenuItem>
											<MenuItem value="Malyalam">Malyalam</MenuItem>
											<MenuItem value="Marathi">Marathi</MenuItem>
											<MenuItem value="Telugu">Telugu</MenuItem>
											<MenuItem value="Marwadi">Marwadi</MenuItem>
											<MenuItem value="Urdu">Urdu</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<GoogleMaps handleLocation={this.handleGeoLocation} autoLocation={this.state.current_location} errors={this.state.errors.current_location} />
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
												margin="normal"
												id="assigned_date_form"
												label="Birth Date"
												format="yyyy/MM/dd"
												maxDate={new Date()}
												name="birth_date"
												value={birth_date}
												onChange={(key, date) => this.handleDateChange('birth_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 mb-3">
									<FormControl>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
												margin="normal"
												id="closed_date"
												label="Anniversary Date"
												format="yyyy/MM/dd"
												maxDate={new Date()}
												name="anniversary_date"
												value={anniversary_date}
												onChange={(key, date) => this.handleDateChange('anniversary_date', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<GoogleMapsIntLoc handleLocation={this.handleGeoLocationIntrest} autoLocation={this.state.interested_location} errors={this.state.errors.interested_location} />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend">Married</FormLabel>
										<RadioGroup row aria-label="is_married" name="is_married" value={is_married} onChange={this.onChange}>
											<FormControlLabel value="1" control={<Radio color="primary" checked={this.state.is_married == 1}/>} label="Yes" />
											<FormControlLabel value="0" control={<Radio color="primary" checked={this.state.is_married == 0}/>} label="No" />
										</RadioGroup>
									</div>
								</div>
								<div className="col-sm-6">
									<RadioButtonGroup
										name="loan_required"
										value={loan_required}
										label="Loan Required"
										onChange={this.onChange}
										items={yesNoType}
									/>
								</div>
								{/*<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend">Revert Status</FormLabel>
									      <RadioGroup row aria-label="revert_status" name="revert_status" value={revert_status} onChange={this.onChange}>
									        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Yes" />
									        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
									      </RadioGroup> 
									</div>
								</div>*/}
								<div className="col-sm-6">
									<div className="form-group">
										<FormLabel component="legend">Nationality</FormLabel>
										<RadioGroup row aria-label="nationality" name="nationality" value={nationality} onChange={this.onChange}>
											<FormControlLabel value="1" control={<Radio color="primary" />} label="Indian" />
											<FormControlLabel value="2" control={<Radio color="primary" />} label="NRI" />
										</RadioGroup>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<InputUnitField 
										name="loan_amount" 
										value={loan_amount} 
										label="Loan Amount" 
										onChange={this.handleNumberChange}
									/>
								</div>
								<div className="col-sm-6 mb-3">
									<TextField
										error={this.state.errors.designation !== '' ? 'error' : ''}
										id="designation"
										name="designation"
										value={this.state.designation ? this.state.designation : ''}
										label="Client designation"
										helperText={this.state.errors.designation !== '' ? this.state.errors.designation : ''}
										onChange={this.onChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneRoundedIcon />
												</InputAdornment>
											),
										}}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											error={this.state.errors.name !== '' ? 'error' : ''}
											required
											id="cancellation_reason"
											name="cancellation_reason"
											label="Cancellation Reason"
											value={cancellation_reason ? cancellation_reason : ''}
											helperText={this.state.errors.cancellation_reason !== '' ? this.state.errors.cancellation_reason : ''}
											onChange={this.onChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AccountCircle />
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-sm-6 mb-3">
									<Autocomplete
										id="asynchronous-demo"
										getOptionSelected={(alternetProject, value) => alternetProject.name === value.name}
										getOptionLabel={alternetProject => alternetProject.name}
										options={alternetProject}
										value={selectedVal}
										loading={isLoading}
										onChange={(_event, alternetProject) => {
											if (alternetProject === null) {
												this.setState({ alternetProject: [] })
											} else {
												this.setState({
													alternet_project_name: alternetProject.name,
													alternate_project_id: alternetProject.id,
													selectedVal: { name: alternetProject.name, id: alternetProject.id }
												})
											}
										}}
										renderInput={params => (
											<TextField
												{...params}
												label="Alternate Project"
												fullWidth
												onChange={this.AutoChangeProject}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{isLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													)
												}}
											/>
										)}
									/>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<TextField
											required
											select
											id="property_for"
											name="property_for"
											value={this.state.property_for ? this.state.property_for : ''}
											label="Property For"
											onChange={this.onChange}
										>
											<MenuItem value="Self">Self</MenuItem>
											<MenuItem value="Investment">Investment</MenuItem>
										</TextField>
									</div>
								</div>
								<div className="col-sm-12 col-12">
									<div className="form-group">
										<TextField
											disabled={(this.state.lead_status_id === this.props.selectedFormId && this.state.comment_added) ? true : false}
											// error={this.state.errors.comment !== '' ? 'error' : ''}
											required
											id="standard-multiline-flexible"
											label="Comments"
											name="comment"
											value={this.state.comment}
											// helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
											multiline
											rowsMax="4"
											onChange={this.onChange}
										/>
									</div>
								</div>
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>Submit</button>
									</div>
								</div>
								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Above all * fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(cancelUpdateForm);
