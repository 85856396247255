import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button'
import CalendarIcon from '@material-ui/icons/InsertInvitation';

import moment from 'moment';
import {
  DatePicker
} from '@material-ui/pickers';
import api from '../api';
import { FOLLOWUP_DATE_TIME_FORMAT } from '../config/constant';


const DATE_TIME_FORMAT = FOLLOWUP_DATE_TIME_FORMAT;

const timeFormat = time =>{
	let H = time;
	let h = H % 12 || 12;
	let ampm = (H < 12 || H === 24) ? "AM" : "PM";
	time = h + " " + ampm;
	return time
}


export default props => {
	const {inputProps, value, name, onChange, format, required, disabled, id: cid, ...other} = {inputProps: {}, id: 'follow-up', ...props};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [state, setState] = React.useState({
		date: value? moment(value).format(DATE_TIME_FORMAT): null
		, hours: value ? moment(value, DATE_TIME_FORMAT).format("H"): "0"
		, mins: value ? moment(value, DATE_TIME_FORMAT).format("m"): 0
		, slots: value ?  {
			[moment(value, DATE_TIME_FORMAT).format("H").toString()]: [parseInt(moment(value, DATE_TIME_FORMAT).format("m"))]
		} : {"0":[]}
		, value: value? moment(value).format(DATE_TIME_FORMAT): null
	})

	const handleClick = (event) => {
		if(!disabled) setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'transitions-popper' : undefined;

	React.useEffect(() => {
		let params=moment(state.date, DATE_TIME_FORMAT).format("YYYY-MM-DD");
		if(!state.date) return
		api.get(`/internal_status_history/followup_slot?followup_date=${params}`).then(response => {
			let slots = response.data.data;
			slots[state.hours] = [...slots[state.hours], ...state.slots[state.hours]].sort().filter((v, i, self) => self.indexOf(v) === i )
			setState({...state, slots})
		})
	}, [])

	const handleDate = (value) => {
		// let hrs = Object.keys(slots)[0]
		// let min = slots[hrs][0]
		let params=moment(value).format("YYYY-MM-DD");
		api.get(`/internal_status_history/followup_slot?followup_date=${params}`).then(response => {
			let slots = response.data.data;
			slots[state.hours] = [...slots[state.hours], ...state.slots[state.hours]].sort((a, b) => a - b).filter((v, i, self) => self.indexOf(v) === i )
			let hrs = Object.keys(slots)[0]
			let min = slots[hrs][0]
			setState({...state, slots})
			setState({...state, date: moment(value).format(DATE_TIME_FORMAT), slots:  slots, hours: hrs, mins: min })
		})
	}
	return 		<Box>
			<InputLabel htmlFor="follow-up" required={required ? true : false}>Follow Up Date</InputLabel>
			<Input
				disabled={disabled?disabled:false}
				id={cid}
				type='text'
				name={name}
				value={value}
				onClick={handleClick}
				autocomplete="off"
				{...inputProps}
				endAdornment={
					<InputAdornment position="end">
						<IconButton disabled={disabled?disabled:false}
						>
							<CalendarIcon />
						</IconButton>
					</InputAdornment>
				}
				className="w-100"
			/>
		<Popover id={id} open={open} anchorEl={anchorEl} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
		}} onClose={() => setAnchorEl(null)}>
				<Box style={{backgroundColor: "#fff"}}>
					<DatePicker
						autoOk
						variant="static"
						minDate={new Date()}
						onChange={handleDate}
						value={moment(state.date, DATE_TIME_FORMAT).toDate()}
						disableToolbar
						{...other}
					/>
					<Box display="flex">
						<Box width="50%">
							<Select
								variant="outlined"
								value={state.hours}
								onChange={
									(e) => {
										let v = e.target.value;
										setState({...state, hours: v, mins: state.slots[v][0]}) 
									}
								}
								disabled={!Boolean(state.date)}
								
								style={{width: "100%"}}
							>
								{Object.keys(state.slots).map(slot => <MenuItem key={slot} value={slot}>{timeFormat(slot)}</MenuItem>)}
							</Select>
						</Box>
						<Box width="50%">
							<Select
								variant="outlined"
								value={state.mins}
								disabled={!Boolean(state.date)}
								onChange={(e) => {
									let v = e.target.value
									setState({...state, mins: v})}
								}
								style={{width: "100%"}}
							>
								{state.slots[state.hours].map(slot => <MenuItem key={slot} value={slot}>{slot}</MenuItem>)}
							</Select>
						</Box>
					</Box>
					<Button color="primary"	disabled={!Boolean(state.date)} variant="contained" style={{width: "100%"}} onClick={() => {
						let v =  `${moment(state.date, DATE_TIME_FORMAT).format("DD/MM/YYYY")} ${state.hours.toString().padStart(2, 0)}:${state.mins.toString().padStart(2,0)}`
						setState({...state, value:v}) 
						// onChange(moment(v, DATE_TIME_FORMAT).toDate())
						onChange(v)
						setAnchorEl(null)
					}
						}>Done</Button>
				</Box>
		</Popover>
		</Box>
}
