import React from 'react'
import HomeLoanList from './HomeLoanList'

const HomeLoan = (props) => {
    return (
        <div className={`content-wrapper ${
            props.sideBar ? "main-header-collapsed" : ""
        }`}>
            <HomeLoanList/>
        </div>
    )
}

export default HomeLoan
