import React from 'react'
import BulkFilesList from '../component/BulkFilesList'

const BulkFilesPage = () => {
    return (
        <div className="content-wrapper">
            <BulkFilesList />
        </div>
    )
}

export default BulkFilesPage
