import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddMoney from "../../component/magnetBudget/addMoney";
import CampaignSplit from "../../component/magnetBudget/campaignSplit";
import "./magnet.css";
import GoogleSplit from "../../component/magnetBudget/googleSplit";
import ComingSoon from "../../component/common/cards/ComingSoon";

export default class MagnetBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  componentDidMount() {
    if (this.props.match.params.section === "add") {
      this.setState({ value: 0 });
    } else if (this.props.match.params.section === "campaign-splits") {
      this.setState({ value: 1 });
    }else if (this.props.match.params.section === "google-splits") {
      this.setState({ value: 2 });
    }else if (this.props.match.params.section === "portal-splits") {
      this.setState({ value: 3 });
    }else if (this.props.match.params.section === "sms-splits") {
      this.setState({ value: 4 });
    }else if (this.props.match.params.section === "email-splits") {
      this.setState({ value: 5 });
    }
  }

  a11yProps = index => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <div
        className={`magnet-budget content-wrapper ${
          this.props.sideBar ? "main-header-collapsed" : ""
        }`}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Add Money"
              id="scrollable-auto-tab-0"
              aria-controls="scrollable-auto-tabpanel-0"
            />
            <Tab
              label="Facebook Splits"
              id="scrollable-auto-tab-2"
              aria-controls="scrollable-auto-tabpanel-2"
            />
            <Tab
              label="Google Splits"
              id="scrollable-auto-tab-2"
              aria-controls="scrollable-auto-tabpanel-2"
            />
            <Tab
              label="Portal Splits"
              id="scrollable-auto-tab-3"
              aria-controls="scrollable-auto-tabpanel-3"
            />
            <Tab
              label="SMS Splits"
              id="scrollable-auto-tab-4"
              aria-controls="scrollable-auto-tabpanel-4"
            />
            <Tab
              label="Email Splits"
              id="scrollable-auto-tab-5"
              aria-controls="scrollable-auto-tabpanel-5"
            />
          </Tabs>
        </AppBar>
        <div hidden={this.state.value !== 0}>
          {this.state.value === 0 ? <AddMoney /> : null}
        </div>
        <div hidden={this.state.value !== 1}>
          {this.state.value === 1 ? <CampaignSplit /> : null}
        </div>
        <div hidden={this.state.value !== 2}>
          {this.state.value === 2 ? <GoogleSplit /> : null}
        </div>

        <div hidden={this.state.value !== 3}>
          {this.state.value === 3 ? <ComingSoon type="Portal"/> : null}
        </div>
        <div hidden={this.state.value !== 4}>
          {this.state.value === 4 ? <ComingSoon type="SMS"/> : null}
        </div>
        <div hidden={this.state.value !== 5}>
          {this.state.value === 5 ? <ComingSoon type="Email"/> : null}
        </div>
      </div>
    );
  }
}
