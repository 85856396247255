import React from 'react'
import SelectOption from '../controls/SelectOption';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import ButtonSubmit from '../controls/ButtonSubmit';
import { getFinanceCalendarRecordData, postFinanceCalendar, updateFinanceCalendar } from '../../../dataParser/getListUserData';
import Loader from '../loader/loader'
import PopUpAlert from "../sweetAlert/popUpAlert";
import moment from "moment";
import { QUARTERS } from '../../../config/constant';

const todayDate = new Date();
const curr_year = todayDate.getFullYear() 
const curr_month = todayDate.getMonth()+1 

const generateMonths = () => {
    let months = [];
    for (let i = 1; i < 13; i++) {
        months.push({
            id: `${i}`.padStart(2, "0"),
            name: new Date(i.toString()).toLocaleString("default", {
                month: "long"
            })
        });
    }

    return months;
};

const generateYears = () => {
    let years = [];
    for (
        let i = curr_year - 15;
        i <= curr_year;
        i++
    ) {
        years.push({
            id: i,
            name: i
        });
    }
    return years;
};

const initialState = {
    year: "",
    month: "",
    startDate: null,
    endDate: null,
    quarter: "",
    minDate: null
}

const variant = "standard";

const userId = window?.$user?.role?.id

const MonthCalendar = (props) => {
    const { onSuccess, recordId } = props;

    const [state, setState] = React.useState(initialState);
    const { year, month, startDate, endDate, quarter, minDate } = state;

    const [alertState, setAlertState] = React.useState({});

    const { alertShow, alertType, alertMsg } = alertState;

    const [showLoader, setShowLoader] = React.useState(false);


    const getRecordData = async () => {
        setShowLoader(true)
        let getRes = await getFinanceCalendarRecordData(recordId);
        if (getRes.meta.status === 201) {
            setShowLoader(false)
            setState({
                ...state,
                year: getRes?.data?.year,
                month: getRes?.data?.month,
                startDate: getRes?.data?.start_date,
                endDate: getRes?.data?.end_date,
                quarter: getRes?.data?.quarter,
            })
        }
        else {
            setShowLoader(false)
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: getRes.meta?.message || "Something went wrong!"
            });
        }
    }

    React.useEffect(() => {
        if (!recordId) return
        getRecordData()
    }, [recordId])

    const handleChange = e => {
        const { name, value } = e.target;
        let setData = {};
        let start_date, minDate;
        
        if(name === "month") {
            start_date = moment(new Date(`${year || curr_year}-${value}-01`)).format("YYYY-MM");
            start_date = moment(start_date).startOf(`month`).format("YYYY-MM-DD");
            setData = {
                month: value,
                year: year || curr_year,
                startDate: start_date,
                endDate: moment(start_date).endOf(`month`).format("YYYY-MM-DD"),
                minDate: start_date
            }
        }
        if(name === "year") {
            let selected_month = month || curr_month;
            selected_month = selected_month.toString().length === 2 ?  ""+ selected_month : "0" + selected_month;
            start_date = moment(new Date(`${value}-${selected_month}-01`)).format("YYYY-MM");
            start_date = moment(start_date).startOf(`month`).format("YYYY-MM-DD");
            setData = {
                year: value,
                month: selected_month,
                startDate: start_date,
                endDate: moment(start_date).endOf(`month`).format("YYYY-MM-DD"),
                minDate: start_date
            }
        }
        setState({
            ...state,
            [name]: value,
            ...setData
        })
    }

    const handleSubmit = async () => {
        let data;
        if (year && month && startDate && endDate && quarter) {
            setShowLoader(true)
            data = {
                "year": year,
                "month": month,
                "quarter": quarter,
                "end_date": endDate,
                "created_by": userId, // need to be changes
                "start_date": startDate
            };
            const res = await postFinanceCalendar(data);
            if (res.meta.status === 201) {
                setShowLoader(false)
                setAlertState({
                    alertShow: true,
                    alertType: "success",
                    alertMsg: "Reacord Added Successfully"
                })
            }
            else {
                setShowLoader(false)
                setAlertState({
                    alertShow: true,
                    alertType: "error",
                    alertMsg: res.meta?.message || "Something went wrong!!!"
                })
            }
        }

        else {
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: "All fields are mandatory!!!"
            })
        }
    }

    const handleEdit = async () => {
        let data;
        if (year && month && startDate && endDate && quarter) {
            setShowLoader(true)
            data = {
                "year": year,
                "month": month,
                "quarter": quarter,
                "end_date": endDate,
                "updated_by": userId,
                "start_date": startDate
            };
            const res = await updateFinanceCalendar(recordId, data);
            if (res.meta.status === 201) {
                setShowLoader(false)
                setAlertState({
                    alertShow: true
                    , alertType: "success"
                    , alertMsg: res?.data?.success?.message || "Record Updated successfully!!!"
                })
            }
            else {
                setShowLoader(false)
                setAlertState({
                    alertShow: true
                    , alertType: "error"
                    , alertMsg: res?.meta?.message || "Something went wrong!!!"
                })
            }

        }
        else {
            setAlertState({
                alertShow: true,
                alertType: "error",
                alertMsg: "All fields are mandatory!!!"
            })
        }
    }

    const handleAlertClose = () => {
        setAlertState({
            ...alertState
            , alertShow: false
        })
        if(alertType === "success"){
            setState(initialState)
            onSuccess(1);
        }
    }

    const handleReset = () => {
        setState(initialState)
    }

    return (
        <>
            <Loader show={showLoader} />

            <div className="container my-3 ">
                <div className="row">
                    <div className="col-md-2 col-6 my-2">
                        <SelectOption
                            options={generateYears()}
                            required={true}
                            variant={variant}
                            label="Year"
                            name="year"
                            value={year}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-2 col-6 my-2">
                        <SelectOption
                            options={generateMonths()}
                            required={true}
                            variant={variant}
                            label="Months"
                            name="month"
                            value={month}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-2 col-6 my-2">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required={true}
                                label="Start Date"
                                disableToolbar
                                variant={variant}
                                format="dd-MM-yyyy"
                                margin="normal"
                                value={startDate}
                                autoOk={true}
                                minDate={minDate}
                                onChange={date => {
                                    setState({ ...state, startDate: date });
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-2 col-6 my-2">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required={true}
                                label="End Date"
                                disableToolbar
                                variant={variant}
                                format="dd-MM-yyyy"
                                margin="normal"
                                value={endDate}
                                autoOk={true}
                                minDate={minDate}
                                onChange={date => {
                                    setState({ ...state, endDate: date });
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-md-2 col-6 my-2">
                        <SelectOption
                            options={QUARTERS}
                            required={true}
                            variant={variant}
                            label="Quarter"
                            name="quarter"
                            value={quarter}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-1 col-3 align-self-end my-2">
                        <ButtonSubmit onClick={!recordId ? handleSubmit : handleEdit} />
                    </div>
                    <div className="col-md-1 col-3 align-self-end my-2">
                        <ButtonSubmit onClick={handleReset} lable="Reset" />
                    </div>
                </div>
            </div>
            <PopUpAlert open={alertShow} type={alertType} msg={alertMsg} handleClose={handleAlertClose} />
        </>
    )
}

export default MonthCalendar