import React, { Component } from 'react';
import { getRoleList } from "../../dataParser/commomDataApi";
import { addDocumentApi, getDocDataApi } from "../../dataParser/hrDocs";
import Footer from "../../component/footer/footer";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import PopUpAlert from "../../component/common/sweetAlert/popUpAlert";
import FormHelperText from '@material-ui/core/FormHelperText';
import Loader from "../../component/common/loader/loader";
import "./hrDocs.css";
import HrDocsTable from "./hrDocsTable.js";

class HrDocs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            role: [],
            docListData: [],
            file_name: "",
            hr_doc: null,
            type: "success",
            title: "",
            sweetShow: false,
            showLoader: false,
            open: false,
            msg: '',
            errorObj: {},
            document_type: ""
        };
        this.fileRef = React.createRef();
    }

    componentDidUpdate(nextProps) {
        // console.log('componentWillReceiveProps xxxxxxxxxx', nextProps.userDetail.data);
    }

    getListData = async () => {
        this.setState({ showLoader: true });
        let docListData = await getDocDataApi();
        // console.log(docListData,"SSSSSSSSSSSSSSSS")
        if (docListData.meta.status === 201) {
            this.setState({ docListData: docListData.data, showLoader: false })
        } else {
            this.setState({ docListData: [], showLoader: false })
        }
    }

    async componentDidMount() {
        var roleListData = await getRoleList();
        this.setState({ role: roleListData })
        this.update();
        this.getListData();
    }

    update = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleFile = (e) => {
        // console.log(e.target.files[0],"aaaaaaaaaaa" );
        this.setState({ hr_doc: e.target.files[0] });
    }

    checkFile = (fileName) => {
        let arr = fileName.split('.');
        if (arr[arr.length - 1] !== 'pdf') {
            return false;
        }
        return true;
    }
    handleError = () => {
        let errorObj = {};
        let role_ids = [];
        this.state.roles.map((cur) => { role_ids.push(cur.id) })

        if (this.state.file_name === "") {
            errorObj['file_name'] = "Please enter file name";
        } else if (this.state.hr_doc === null) {
            errorObj['hr_doc'] = "Please upload file with ext. pdf"
        } else if (role_ids.length === 0) {
            errorObj['role_id'] = "Please select user"
        } else if (this.state.document_type === "") {
            errorObj['document_type'] = "Please document type"
        } else if (this.state.hr_doc !== null) {
            if (!this.checkFile(this.state.hr_doc.name)) {
                errorObj['hr_doc'] = "please upload file with ext. pdf"
            }
        }
        this.setState({ errorObj: errorObj });
        if (Object.keys(errorObj).length === 0) {
            return true
        }
        return false;

    }

    addDocuments = async () => {
        let err = this.handleError();
        if (err) {
            this.setState({ showLoader: true });
            let docData = {};
            docData.data = {};
            let role_ids = [];
            this.state.roles.map((cur) => { role_ids.push(cur.id) })
            docData.data["file_name"] = this.state.file_name;
            docData.data["role_id"] = role_ids;
            docData["hr_doc"] = this.state.hr_doc;
            docData.data['document_type'] = this.state.document_type;
            docData.data = JSON.stringify(docData.data);
            let resData = await addDocumentApi(docData);
            if (resData.meta.status === 201) {
                this.setState({
                    showLoader: false,
                    open: true,
                    type: "success",
                    msg: "Document Added successfully",
                    file_name: "",
                    hr_doc: "",
                    roles: [],
                    document_type: ""
                });
                this.fileRef.current.value = "";
            } else {
                this.setState({
                    showLoader: false,
                    type: "error",
                    open: true,
                    msg: "Some Error Happend!!!",
                });
            }
        }
    }

    handleSweet = () => {
        this.setState({ open: false });
        this.getListData()
        // window.location.reload();
    }
    handleChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value });
      };
    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        const { type, msg, open } = this.state;
        return (
            <div>
                <div className="content-wrapper">
                    <PopUpAlert type={type} msg={msg} open={open} handleClose={this.handleSweet} />
                    <Loader show={this.state.showLoader} />
                    <div className="p-3 leadMainHeader">
                        <div className="headerCount">
                            <span className="hrHeader">HR Documents: </span>
                            {/*<span className="totalCount">{meta.status === 200 ? count : 0}</span>*/}
                        </div>
                    </div>
                    <div>
                        <div className="listContainer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="MainContainer">
                                        <div className="container-fluid holderContainer">
                                            <div className="uploadHeader">
                                                Upload Documents
                                            </div>
                                            <div className="col-md-12 formContainer">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-container">
                                                            <div className="fileName">File Name*</div>
                                                            <div className="fileNameInput">
                                                                <TextField
                                                                    error={this.state.errorObj?.file_name ? true : false}
                                                                    id="file_name"
                                                                    name="file_name"
                                                                    value={this.state.file_name}
                                                                    variant="outlined"
                                                                    onChange={this.handleChange}
                                                                    helperText={this.state.errorObj.file_name ? this.state.errorObj.file_name : ''}
                                                                    InputProps={{
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-container">
                                                            <div className="fileName">Available For*</div>
                                                            <div className="fileNameInput">
                                                                <FormControl>
                                                                    <Select
                                                                        error={this.state.errorObj?.role_id ? true : false}
                                                                        labelId="primary-business"
                                                                        id="permission-checkbox"
                                                                        variant="outlined"
                                                                        multiple
                                                                        name="roles"
                                                                        value={this.state.roles}
                                                                        onChange={this.handleChange}
                                                                        input={<Input id="select-multiple-chip" />}
                                                                        renderValue={selected => (
                                                                            <div>
                                                                                {selected.map(value => (
                                                                                    <Chip key={value.id} label={value.name} />
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                        MenuProps={MenuProps}
                                                                        >
                                                                        {this.state.role.map(name => (
                                                                            <MenuItem key={name.id} value={name}>
                                                                                <Checkbox checked={this.state.roles.indexOf(name) > -1} />
                                                                                <ListItemText primary={`${name.name} (${name.alias})`} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {this.state.errorObj?.role_id ? <FormHelperText className="error" error>{this.state.errorObj?.role_id}</FormHelperText> : null}
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-6 mt-4 mb-2">
                                                        <FormControl>
                                                            <InputLabel id="role-magnet" htmlFor="role-magnet" required>Select Document Type</InputLabel>
                                                            <Select
                                                                error={this.state.errorObj?.document_type ? true : false}
                                                                labelId="demo-controlled-open-select-label"
                                                                value={this.state.document_type}
                                                                onChange={this.handleChange}
                                                                inputProps={{
                                                                    name: 'document_type',
                                                                    id: 'ducument-type',
                                                                }}
                                                            >
                                                                <MenuItem value="" disabled>None</MenuItem>
                                                                <MenuItem value="hrdocument">Hr Document</MenuItem>
                                                                <MenuItem value="hrknowledgepiece">Hr Knowledge Piece</MenuItem>
                                                            </Select>
                                                            {this.state.errorObj?.document_type && <FormHelperText className="error" error>{this.state.errorObj?.document_type}</FormHelperText>}
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 col-6 mt-4 mb-2">
                                                        <div className="col-md-12">
                                                            <input
                                                                name="hr_doc"
                                                                type="file"
                                                                ref={this.fileRef}
                                                                className="form-control-file"
                                                                id="exampleFormControlFile1"
                                                                onChange={this.handleFile}
                                                            />
                                                            {this.state.errorObj?.hr_doc ? <FormHelperText className="error" error>{this.state.errorObj?.hr_doc}</FormHelperText> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12 text-center col-12 hrDocSubmit">
                                                    <div className="form-group">
                                                        <button className="btn btn-secondary" onClick={this.addDocuments}>Upload Document</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                        <HrDocsTable docListData={this.state.docListData} getListData={this.getListData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer expand={this.state.sideBar} />
            </div>
        );

    }
}

export default HrDocs;