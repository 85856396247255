import React from 'react'
import { DetailView } from '../common/listing/DetailView'
import { MyModal } from '../common/modal/MyModal'

export const MagnetDetail = ({ show, changeModal, fullDetail }) => {
    let alternate_business_us, deal_business_types, genrate_leads, plateform_present_in;
    alternate_business_us = typeof fullDetail?.data?.UserMeta?.alternate_business_us === 'string' ? JSON.parse(fullDetail?.data?.UserMeta?.alternate_business_us).join(', ') : fullDetail?.data?.UserMeta?.alternate_business_us;
    deal_business_types = typeof fullDetail?.data?.UserMeta?.deal_business_types === 'string' ? JSON.parse(fullDetail?.data?.UserMeta?.deal_business_types).join(', ') : fullDetail?.data?.UserMeta?.deal_business_types;
    genrate_leads = typeof fullDetail?.data?.UserMeta?.genrate_leads === 'string' ? JSON.parse(fullDetail?.data?.UserMeta?.genrate_leads).join(', ') : fullDetail?.data?.UserMeta?.genrate_leads;
    plateform_present_in = typeof fullDetail?.data?.UserMeta?.plateform_present_in === 'string' ? JSON.parse(fullDetail?.data?.UserMeta?.plateform_present_in).join(', ') : fullDetail?.data?.UserMeta?.plateform_present_in;
    let total_experience = fullDetail?.data?.UserMeta?.total_experience;
    let operate_location = fullDetail?.data?.UserMeta?.operate_location;
    let devote_time = fullDetail?.data?.UserMeta?.devote_time;
    let have_team = fullDetail?.data?.UserMeta?.have_team ? "yes" : "no";
    let own_state_business = fullDetail?.data?.UserMeta?.own_state_business ? "yes" : "no";
    let operate_business = fullDetail?.data?.UserMeta?.operate_business;
    
    return (
        <MyModal title="Magnet Detail" size="xl" show={show} changeModal={changeModal}>    
            <div className="row">
                <DetailView title="id" value={fullDetail?.data?.id} />
                <DetailView title="name" value={fullDetail?.data?.name} />
                <DetailView title="phone" value={fullDetail?.data?.phone} />
                <DetailView title="email" value={fullDetail?.data?.email} capitalize="no" />
                <DetailView title="experience" value={total_experience ? total_experience+" years" : "-"} />
                <DetailView title="alternate business" value={alternate_business_us||"-"} />
                <DetailView title="deal in business" value={deal_business_types||"-"} />
                <DetailView title="lead generation" value={genrate_leads||"-"} />
                <DetailView title="present plateform" value={plateform_present_in||"-"} />
                <DetailView title="location" value={operate_location||"-"} />
                <DetailView title="devote time" value={devote_time||"-"} />
                <DetailView title="team" value={have_team} />
                <DetailView title="own real state business" value={own_state_business} />
                <DetailView title="operate business" value={operate_business||"-"} />
            </div>
        </MyModal>
    )
}