import React from 'react';

import Box from '../../core/Box';
import Grid from '../../core/Grid';

import ProfileBox from '../../core/ProfileBox';
import NavigationButtonSidebarGroup from '../../core/NavigationButtonSidebarGroup';
import SocialBlockGroup from '../../core/SocialBlockGroup';
import ActivityPage from './ActivityPage';
import ProfilePage from './ProfilePage';
import DetailsPage from './DetailsPage';
import BudgetPage from './BudgetPage';
import MarketingPage from './MarketingPage';
import BookingPage from './BookingPage';
import api from '../../api';
import axios from 'axios';
import './HomePage.sass';
import Loader from "../../component/common/loader/loader";



const defaultState = {
    activeSection: 'Activity'
	, loader: false
}

function numDifferentiation(value) {
  var val = Math.abs(Number(value))
  if (val >= 10000000) {
    val = (val / 10000000) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000) + ' Lac';
  }
  return val;
}

const HomePage = ({match, location, history}) => {
    const clientId = match.params.client_id
    const leadId = match.params.lead_id
    const [state, setState] = React.useState({
        defaultState
        , activeSection: 'Activity'
        , profile: {}
        , lead: {}
    });

    const [refresh, setRefresh] = React.useState(false);

    const [activeSocialItem, setActiveSocialItem] = React.useState("Assign")

    const handleProfileChange = ( values ) => setRefresh(true)
    const handleDetailsChange = ( values ) => setRefresh(true) 
    const handleBudgetChange = ( values ) => setRefresh(true) 
    const handleBookingChange = ( values ) => setRefresh(true)


    const makeApiCalls = () => {
		let _state = {loader: false}


		setState({...state, loader: true})
        axios.all([
            api.get(`clients/${clientId}`)
            , api.get(`leads/${leadId}`)
        ]).then( responses => {
            _state = {
                ..._state 
                , profile: responses[0].data
                , lead: responses[1].data
            }
            setState({...state, ..._state})
        })
    }

    React.useEffect(() => {
        makeApiCalls()
    }, [])

    React.useEffect(() => {
        if (!refresh) return;
        setRefresh(false);
        makeApiCalls()
    }, [])
    // }, [leadId, clientId])

    return <Box className="content-wrapper">
        <Box className="HomePage">
        <Box display="flex" justifyContent="flex-end" className="social-block-group-w">
            <SocialBlockGroup pushChange={activeSocialItem} lead={state.lead.id ? state.lead : false} makeApiCalls={makeApiCalls} />
        </Box>
        <Grid container spacing={4} >
            <Grid item sm={3} xs={12} style={{position: "relative", zIndex: 1}}>
                <Box className="Sidebox" p={2}>
                    <ProfileBox 
                        name={state.profile.name}
                        leadId={leadId}
                        project={{
                            name: state.lead.Project?.name, 
                            budget: `${numDifferentiation(state.lead.ClientBudgetInfo?.min_budget)} - ${numDifferentiation(state.lead.ClientBudgetInfo?.max_budget)}`, 
                            leadStatus: state.lead.ObjectStage?.Stage?.name, 
                            type: state.lead.ClientBudgetInfo?.required_bhk
                        }} />
                    <Box position="relative">
                        <Box mt={2} position="sticky" top={0}>
                            <NavigationButtonSidebarGroup active={state.activeSection} handleChange={(section) => {setState({...state, activeSection: section})} }/>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={9} xs={12}>
                <Box className={`active-page-space active-page-${state.activeSection}`} p={2}>
                    {(() => { 
                        switch(state.activeSection) {
                            case 'Activity':
                                return <ActivityPage lead={state.lead} profile={state.profile} />
                            case 'Profile':
                                return <ProfilePage leadId={leadId} profile={state.profile} handleProfileChange={handleProfileChange} />
                            case 'Details':
                                return <DetailsPage profile={state.profile} lead={state.lead} handleDetailsChange={handleDetailsChange} />
                            case 'Budget':
                                return <BudgetPage lead={state.lead} profile={state.profile} handleBudgetChange={handleBudgetChange} />
                            case 'Marketing':
                                return <MarketingPage lead={state.lead} profile={state.profile} lead={state.lead} />
                            case 'Booking':
                                return <BookingPage profile={state.profile} lead={state.lead} handleBookingChange={handleBookingChange} />
                            default:
                                return <ActivityPage lead={state.lead} profile={state.profile} />

                        }
                    })() }
                </Box>
            </Grid>
        </Grid>
		<Loader show={state.loader} />
    </Box>
    </Box>
}


export default HomePage;
