import React, { Component } from 'react';
import Frag from "../../../utils/Frag/Frag.js";
import { withRouter } from "react-router-dom";
import "./form.css";
import SweetAlert from "../../../component/common/sweetAlert/sweetAlertSuccess";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import { UpdateDumpLead } from "../../../dataParser/bulkLeadData";


class UpdateDumpForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			leadId: '',
			leadType: '',
			userName: '',
			mobileNumber: '',
			source: '',
			projectId: '',
			magneteLead: '',
			city: '',
			comment: '',
			closing_reason: '',
			status: '',
			rm_approved: 1,
			admin_approved: 1,
			isError: false,
			sweetShow: false,
			type: "success",
			title: "",
			errors: {
				closing_reason: '',
				comment: ''
			}
		};
	}

	async componentDidMount() {

		this.setState({
			role: window.$role,
			status: '',

		})
		// console.log("NEw Form=======_------------>", this.props);


	}

	updateLeadFrom = async (e) => {

		e.preventDefault();
		let leadId = this.props.leadID;
		let bulkId = this.props.bulkId;

		let leadType = this.props.leadType;

		let mobileNumber = this.props.mobileNumber;

		let projectId = this.props.projectId;

		let upadateBulkReq = {};
		// let leadId = this.state.leadId;

		// const addLeadRequest = (({closing_reason,comment,status}) => ({
		// 	closing_reason,
		// 	comment,
		// 	status
		//    }))(this.state);

		// console.log("addLeadRequest XXXXXXXXXXXX",addLeadRequest)
		let mainData = {};

		if (leadType != "") {
			mainData["lead_id"] = leadId;
		}
		if (this.state.closing_reason != "") {
			mainData["closing_reason"] = this.state.closing_reason;
		}
		if (this.state.comment != "") {
			mainData["comment"] = this.state.comment;
		}

		if (mobileNumber != "") {
			mainData["number"] = mobileNumber;
		}

		if (projectId != "") {
			mainData["project_id"] = projectId;
		}

		mainData["dump_type"] = this.props.dumptype;

		// var leadReqData = JSON.stringify(mainData);

		// console.log("addLeadRequest XXXXXXXXXXXX",mainData)




		if (this.state.status != '') {

			mainData["status"] = parseInt(this.state.status);
		}

		upadateBulkReq.id = bulkId;
		upadateBulkReq.data = mainData;

		if (this.state.status != '') {
			if (this.state.status === '12' && this.state.closing_reason === '') {
				this.setState({ isError: true });

			} else {
				if(this.props.showLoader){
					this.props.showLoader();
				}
				var addLeadRes = await UpdateDumpLead(upadateBulkReq);
				if(this.props.hideLoader){
					this.props.hideLoader();
				}
				if (addLeadRes.meta.status === 201) {
					
					this.setState({
						sweetShow: true,
						type: "success",
						title: "Lead Updated Successfully!!!"

					});
				} else if (addLeadRes.meta.status === 409) {
					this.setState({
						sweetShow: true,
						type: "error",
						title: addLeadRes.meta.message

					});
				}
				else {
					this.setState({
						sweetShow: true,
						type: "error",
						title: "Something went wrong !!!"

					});
				}

				// this.setState({isError: true});
			}

			// console.log("leadReqData XXXXXXXXXXXX",leadReqData);


		} else {

			this.setState({ isError: true });
		}
	}

	onChange = (e) => {

		this.setState({ [e.target.name]: e.target.value });
	}

	handleSweet = () => {
		this.setState({ sweetShow: !this.state.sweetShow });
		this.props.changeModal()
		// if(this.state.role === "admin" || this.state.role === "magnetadmin" && this.state.admin_approved === 1){
		//    	console.log("mail reload");
		//    }else{
		//    }
		this.props.getList();
	}

	//    handleDateChange = (key, date) =>{

	// 	this.setState({[key] : date});
	// }

	render() {

		const { sweetShow, type, title, status, closing_reason } = this.state;

		return (
			<Frag>
				<div className="projectForm">
					<SweetAlert show={sweetShow} type={type} title={title} changeSweet={this.handleSweet} />
					<form role="form">
						<div className="card-body">
							<div className="row">
								<div className="col-md-6 col-sm-6 text-center col-12 mb-3">
									<FormControl>
										<InputLabel id="demo-controlled-open-select-label">Lead Status</InputLabel>
										<Select
											labelId="demo-controlled-open-select-label"
											value={status}
											onChange={this.onChange}
											inputProps={{
												name: 'status',
												id: 'status',
											}}
										>
											<MenuItem value="11">Reactivate</MenuItem>
											<MenuItem value="12">Disqualify</MenuItem>
										</Select>
									</FormControl>
								</div>
								{this.state.status == '12' ?
									<div className="col-sm-6 col-12">
										<FormControl>
											<InputLabel id="demo-controlled-open-select-label">Closing Reason*</InputLabel>
											<Select
												labelId="demo-controlled-open-select-label"
												value={closing_reason}
												onChange={this.onChange}
												inputProps={{
													name: 'closing_reason',
													id: 'closing_reason',
												}}
											>
												<MenuItem value="">
													<em>None</em>
												</MenuItem>
												<MenuItem value="Broking Advisor">Broking Advisor</MenuItem>
												<MenuItem value="Low Budget">Low Budget</MenuItem>
												<MenuItem value="Invalid Number">Invalid Number</MenuItem>
												<MenuItem value="Not Enquired">Not Enquired</MenuItem>
												<MenuItem value="Wrong Number">Wrong Number</MenuItem>
												<MenuItem value="Already Bought">Already Bought</MenuItem>
												<MenuItem value="Duplicate Lead">Duplicate Lead</MenuItem>
												<MenuItem value="Budget Issue">Budget Issue</MenuItem>
												<MenuItem value="Location Issue">Location Issue</MenuItem>
												<MenuItem value="Layout Issue">Layout Issue</MenuItem>
												<MenuItem value="Test Lead">Test Lead</MenuItem>
												<MenuItem value="Possession Issue">Possession Issue</MenuItem>
												<MenuItem value="Not Contactable">Not Contactable</MenuItem>
												<MenuItem value="Payment Plan">Payment Plan</MenuItem>
												<MenuItem value="Decision Delayed">Decision Delayed</MenuItem>
												<MenuItem value="Looking in Resale">Looking in Resale</MenuItem>
												<MenuItem value="Looking in Rental">Looking in Rental</MenuItem>
												<MenuItem value="Looking in Commercial">Looking in Commercial</MenuItem>
												<MenuItem value="Builder's Employee">Builder's Employee</MenuItem>
												<MenuItem value="Not Responding (4 Weeks)">Not Responding (4 Weeks)</MenuItem>
												<MenuItem value="Interested In Other Location">Interested In Other Location</MenuItem>
												<MenuItem value="Interested In Other Project">Interested In Other Project</MenuItem>
											</Select>
										</FormControl>
									</div>
									:
									''
								}
								{this.state.status == '12' ?
									<div className="col-sm-6 col-12">
										<div className="form-group">
											<TextField
												error={this.state.errors.comment !== '' ? 'error' : ''}
												id="standard-multiline-flexible"
												label="Comments"
												name="comment"
												helpertext={this.state.errors.comment !== '' ? this.state.errors.comment : ''}
												multiline
												rowsMax="4"
												value={this.state.comment}
												onChange={this.onChange}
											/>
										</div>
									</div>
									:
									''
								}
							</div>

							<div className="row">
								<div className="col-md-12 col-sm-12 text-center col-12">
									<div className="form-group">
										<button type="submit" className="btn btn-success" onClick={this.updateLeadFrom}>{this.state.status == '11' ? 'Activate Lead' : this.state.status == '12' ? 'Disqualify Lead' : 'Select'}</button>
									</div>
								</div>

								<div className="col-md-12 text-center mt-2">
									{this.state.isError === true &&
										<span className="text-danger">Above all fields are mandatory, Please fill the details*</span>
									}
								</div>
							</div>
						</div>
					</form>
				</div>
			</Frag>
		);
	};
};

export default withRouter(UpdateDumpForm);
