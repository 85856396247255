import React from 'react';

import WhatsAppIconWrapper from '@material-ui/icons/WhatsApp';
import PhoneIconWrapper from '@material-ui/icons/Phone';
import MailOutlineIconWrapper from '@material-ui/icons/MailOutline';
import EventNoteIconWrapper from '@material-ui/icons/EventNote';
import RepeatIconWrapper from '@material-ui/icons/Repeat';
import IconButtonWrapper from '@material-ui/core/IconButton';
import FilterIcon_ from '@material-ui/icons/FilterList';
import ChatBubbleOutlineIcon_ from '@material-ui/icons/ChatBubbleOutline';
import AddCircleOutlineIcon_ from '@material-ui/icons/AddCircleOutline';
import RedoIcon_ from '@material-ui/icons/Redo';
import UndoIcon_ from '@material-ui/icons/Undo';
import HeadsetMicIcon_ from '@material-ui/icons/HeadsetMic';


const IconButton = props => <IconButtonWrapper {...props}>{props.children}</IconButtonWrapper>
export const WhatsAppIcon = props =>  <WhatsAppIconWrapper {...props} />
export const CallIcon = props =>  <PhoneIconWrapper {...props} />
export const MailIcon = props =>  <MailOutlineIconWrapper {...props} />
export const ChangeStatusIcon = props =>  <EventNoteIconWrapper {...props} />
export const AssignIcon = props =>  <RepeatIconWrapper {...props} />
export const FilterIcon = props =>  <FilterIcon_ {...props} />
export const ChatBubbleIcon = props => <ChatBubbleOutlineIcon_ {...props} />
export const AddIcon = props => <AddCircleOutlineIcon_ {...props} />
export const SentIcon = props => <RedoIcon_ {...props} />
export const RecieveIcon = props => <UndoIcon_ {...props} />
export const HeadsetMicIcon = props => <HeadsetMicIcon_ {...props} />

export default IconButton;
