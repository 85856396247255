import axios from 'axios';
import {APP_URL,CORE_API_URL,ENGAGE_API_URL} from "../config/serverKey.js";
import {NO_HEADER_PAGES} from "../config/constant";
import {getToken, getEngageToken} from "../helper/";
import {ENGAGE_TOKEN} from "../config/constant";

const instance = axios.create({
  baseURL: CORE_API_URL
});
const engageInstance = axios.create({
  baseURL: ENGAGE_API_URL
});

const instanceBasic = axios.create({
  baseURL: CORE_API_URL
});

instance.defaults.timeout = 35000;
engageInstance.defaults.timeout = 35000;

let headers = {};
// if(NO_HEADER_PAGES.includes(window.location.pathname)){
//     headers = {
//       'Content-Type': 'application/json',
//     }
// }else {
    let jwtToken = getToken();

    headers = {
      'Content-Type': 'application/json',
      'Authorization': jwtToken.token ,
      // 'Access-Control-Allow-Origin':'*'
    };
// }

instance.defaults.headers.common = headers;
headers.Authorization = getEngageToken();
engageInstance.defaults.headers.common = headers

export {instance, engageInstance, instanceBasic};